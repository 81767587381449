import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import CardButton from '../../../common/buttons/CardButton'
import Common from '../../../../constants/CommonPlan'
import CommonFunc from '../../../common/function/CommonFunc'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    [theme.breakpoints.up('md')]: {
      height: `calc(100vh - ${theme.header.height} * 2 - 1rem)`,
      minHeight: '310px',
    },
  },
  center: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '257px',
      margin: '0 auto',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
    },
  },
}))

/**
 * @param {Object} props - 以下
 * @param {string} bookingReqPath - BookingRequest画面へのパス
 * @param {string} bookingListPath - BookingList画面へのパス
 * @param {string} bidSearchPath - BidSearch画面へのパス
 * @param {string} billingReportPath - BillingReport画面へのパス
 * @returns {*} - 混載業者のTOP画面のボタン
 */
const FwdrTopButtons = (props) => {
  const classes = useStyles()
  const planNameId = CommonFunc.getLoginUserData().planNameId

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item md xs={12}>
        <div className={classes.center}>
          <CardButton path={props.bookingReqPath} />
        </div>
      </Grid>
      <Grid item md xs={12}>
        <div className={classes.center}>
          <CardButton path={props.bookingListPath} />
        </div>
      </Grid>
      <Grid item md xs={12}>
        <div className={classes.center}>
          <CardButton
            path={props.bidSearchPath}
            disabled={planNameId === Common.FREE.ID}
          />
        </div>
      </Grid>
      <Grid item md xs={12}>
        <div className={classes.center}>
          <CardButton path={props.billingReportPath} disabled />
        </div>
      </Grid>
    </Grid>
  )
}

export default FwdrTopButtons
