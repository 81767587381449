import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'

import Accordion from '../../../common/accordion/Accordion'
import AccordionSummaryLightBookingDetails from './AccordionSummaryLightBookingDetails'
import AccordionDetails from '../../../common/accordion/AccordionDetails'

/**
 * @param {Object} props 下記内容
 * @param {String} props.label アコーディオンのタイトル文言
 * @param {*} props.children アコーディオン内部に表示する内容
 * @return {JSX} アコーディオンコンポーネント
 */
const AccordionLightBookingDetails = ({ label, children }) => {
  return (
    <Grid item md={10} xs={10}>
      <Accordion>
        <AccordionSummaryLightBookingDetails label={label} />
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </Grid>
  )
}

AccordionSummaryLightBookingDetails.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.any,
}

export default AccordionLightBookingDetails
