import moment from 'moment'

import Common from '../../../constants/Common'
import Paths from '../../../constants/Paths'
import CommonValidation from './CommonValidation'
import restFacade from '../../../actions/rest-facade'
import ApiPaths from '../../../constants/ApiPaths'
import { DIALOG, TABLES } from '../../../actions'
import { setErrorMessage } from '../dialog/ErrorDialog'
import CommonFuncPlan from './CommonFuncPlan'

const CommonFunc = () => {
  const env = import.meta.env.NODE_ENV

  /**
   * ページパスが指定のカテゴリにあるかチェック
   * @param {String} pageCategory Common.PAGE_CATEGORIES
   * @return {Boolean} カテゴリ内にある場合true, ない場合false
   */
  const checkAccessPage = (pageCategory) => {
    // ページパス
    const pagePath = window.location.pathname
      .toLowerCase()
      .split('/')
      .filter((v) => v)[0]
    // 指定カテゴリのパス一覧オブジェクト
    const pageList = Object.values(Paths[pageCategory]).join(',').toLowerCase()
    // 指定ページがあるかチェック
    // eslint-disable-next-line no-extra-parens
    return pageList.indexOf(pagePath) !== -1
  }

  /**
   * 登録中の画面を判別
   * @return {Boolean} 登録中の画面の場合true
   */
  const checkRegisterPage = () => {
    return checkAccessPage(Common.PAGE_CATEGORIES.REGISTER_PAGE)
  }

  /**
   * 業者権限チェック
   * ページアクセス権限がある場合は true
   * 別の業者の権限のない画面に遷移しようとした場合はfalse
   * @return {Boolean} ページアクセス権限(true/false)
   */
  const checkAuthentication = () => {
    // 業者関係なく入れるかチェック
    const freeeAuthCheck = checkAccessPage(Common.PAGE_CATEGORIES.FREE_ACCESS)
    if (!freeeAuthCheck) {
      // 業者チェックが必要な場合
      //ユーザーデータ取得
      const userData = getLoginUserData()
      const userType = userData.userType

      // 業者チェック
      return checkAccessPage(Common.PAGE_CATEGORIES[userType])
    }
    // チェックが必要ない場合は無条件でtrue
    return true
  }

  /**
   * ページコンテンツの表示非表示を変更
   * @param {Boolean} isHidden 非表示にする場合true
   * @return {void}
   */
  const changePageContentDisplay = (isHidden) => {
    const pageContents = Array.from(
      document.getElementsByClassName(Common.CLASS_NAME.PAGE_CONTENTS)
    )
    pageContents.forEach((pageContent) => (pageContent.hidden = isHidden))
  }

  /**
   * 中身を表示
   * @return {void}
   */
  const showPage = () => {
    // loaderが表示されているときは非表示のままにする
    const loaderElement = document.getElementById('loading')
    const isHidden = loaderElement ? !loaderElement.hidden : true
    changePageContentDisplay(isHidden)
  }

  /**
   * ページ遷移のチェック エカデジ内の機能以外でページ遷移した場合エラー
   * @param {*} history react-router-domのuseHistory
   * @return {void}
   */
  const checkPageTransition = (history) => {
    // ページパス取得
    const pagePath = window.location.pathname
    // URL直打ちでもアクセス可能なページかチェック
    const accessFlg = checkAccessPage(Common.PAGE_CATEGORIES.FREE_LOGIN)
    // URL直打ちでもアクセス可能な場合は表示
    if (accessFlg) {
      showPage()
      return
    } else {
      // ページチェック
      const pageCheck = pagePath === getStorage(Common.KEY.CURRENT_PATH)
      // 権限チェック
      const authenticationCheck = checkAuthentication()

      // プランチェック
      const planCheck = CommonFuncPlan.checkCurrentPagePlan()

      // プランによる制限で遷移できない場合はエラー画面に遷移
      if (!planCheck) {
        // エラーメッセージはAccess deniedを表示
        const errorObj = {
          code: Common.ERROR_CODE.FORBIDDEN,
          message: 'Access denied',
        }
        transPage({ path: Paths.OTHERS.ERROR, error: errorObj }, history)
        return
      }

      // 指定されたページに遷移していない or 権限がない場合はTOPページ
      if (!pageCheck || !authenticationCheck) {
        if (!pageCheck) {
          setStorage(Common.KEY.ERROR_PATH, pagePath)
        }
        // TOPページに遷移
        topTranslation(history)
      } else {
        // 正規のページに遷移している場合は表示
        showPage()
      }
    }
  }

  /**
   * storageKeyから対象のストレージを取得
   * @param {String} storageKey ストレージキー Common.js のKEYを使用
   * @return {*} sessionStorage or localStorage
   */
  const getTargetStorage = (storageKey) => {
    const isSessionStorage = Common.SESSION_STORAGE_KEY_ARR.includes(storageKey)
    return isSessionStorage ? sessionStorage : localStorage
  }

  /**
   * ストレージに保存(ローカルストレージ)
   * @param {String} storageKey ストレージキー Common.js のKEYを使用
   * @param {*} itemData 保存するデータ
   * @return {void}
   */
  const setStorage = (storageKey, itemData) => {
    const targetStorage = getTargetStorage(storageKey)
    targetStorage.setItem(storageKey, itemData)
    return
  }

  /**
   * ストレージに保存されているデータの取得(ローカルストレージ)
   * @param {*} storageKey ストレージキー Common.js のKEYを使用
   * @return {String} ストレージに保存されているデータの取得
   */
  const getStorage = (storageKey) => {
    const targetStorage = getTargetStorage(storageKey)
    return targetStorage.getItem(storageKey) === 'undefined'
      ? undefined
      : targetStorage.getItem(storageKey)
  }

  /**
   * 開発用
   * ストレージのデータを確認(ローカルストレージ)
   * @return {void}
   */
  const getAllStorage = () => {
    const allDataObj = {}
    for (const key in Common.KEY) {
      const keyWord = Common.KEY[key]
      allDataObj[keyWord] = getStorage(keyWord)
    }
    // 開発用に必要なためESLintのエラーを無視
    // eslint-disable-next-line no-console
    console.log(allDataObj)
  }

  const resetAllStorage = () =>
    Object.values(Common.KEY).forEach((key) => setStorage(key, ''))

  /**
   * clickイベントとページ遷移
   * @param {object} props Object要素は下記参照
   * clickevent 押下時のイベント
   * path 押下時に遷移するページのパス
   * state 遷移先に渡すデータ
   * error エラー用データ
   * @param {*} history react-router-domのuseHistory
   * @return {void}
   */
  const clickEvent = (props, history) => {
    // 設定されているクリックイベントを行った後
    if (props.clickevent) {
      props.clickevent()
    }
    // パスが指定されている場合はページ遷移する
    if (props.path) {
      // 遷移前遷移後のページを保存
      const beforePath = getStorage(Common.KEY.CURRENT_PATH)
      setStorage(Common.KEY.BEFORE_PATH, beforePath)
      setStorage(Common.KEY.CURRENT_PATH, props.path)
      setStorage(Common.KEY.CURRENT_STATE, JSON.stringify(props.state ?? {}))
      // 遷移先か遷移元がエラーページでない場合
      if (
        beforePath !== Paths.OTHERS.ERROR &&
        props.path !== Paths.OTHERS.ERROR
      ) {
        // stateをpushして遷移先に情報を引き継ぐ
        history.push(props.path, { state: props.state })
      } else {
        //エラーページの場合、stateとerrorをpushする
        history.push(props.path, { state: props.state, error: props.error })
      }
      // ページの先頭に移動
      window.scrollTo(0, 0)
    }
  }

  /**
   * ページ遷移(ボタンによる遷移以外はこっちを使用する. ボタンによる遷移はclickEvent()使用)
   * @param {object} props Object要素は下記参照
   * path 押下時に遷移するページのパス
   * state 遷移先に渡すデータ
   * error エラー用データ
   * @param {*} history react-router-domのuseHistory
   * @return {void}
   */
  const transPage = (props, history) => {
    // パスが指定されている場合はページ遷移する
    if (props.path) {
      const beforePath = getStorage(Common.KEY.CURRENT_PATH)
      setStorage(Common.KEY.BEFORE_PATH, beforePath)
      setStorage(Common.KEY.CURRENT_PATH, props.path)
      setStorage(Common.KEY.CURRENT_STATE, JSON.stringify(props.state ?? {}))

      // 遷移先か遷移元がエラーページでない場合
      if (
        beforePath !== Paths.OTHERS.ERROR &&
        props.path !== Paths.OTHERS.ERROR
      ) {
        // stateをpushして遷移先に情報を引き継ぐ
        history.push(props.path, { state: props.state })
      } else {
        // エラーページの場合、stateとerrorをpushする
        setStorage(Common.KEY.ERROR_STATE, JSON.stringify(props.error))
        history.push(props.path, { state: props.state, error: props.error })
      }
      // ページの先頭に移動
      window.scrollTo(0, 0)
    }
  }

  /**
   * 現在表示している言語を表示
   * @param {*} i18n const { i18n } = useTranslation()
   * @return {String} 現在の言語 'en' or 'ja'
   */
  const getLang = (i18n) => {
    if (i18n.language) {
      return i18n.language
    } else {
      return i18n.options.fallbackLng[0]
    }
  }

  /**
   * データの検索
   * @param {Array} dataArray データオブジェクトの配列
   * @param {String} word 検索ワード
   * @return {Array} 検索ワードに一致するデータオブジェクトの配列
   */
  const searchData = (dataArray, word) => {
    // データの中身を文字列化して検索
    // 両方小文字化することによって大文字小文字関係なく検索
    return dataArray.filter(function (dataObj) {
      //検索するデータと除外するデータを分ける
      // 除外するために宣言しているのでESLintのエラーを無視
      // eslint-disable-next-line no-unused-vars
      const { icon, id, ...rest } = dataObj
      const result = []

      //入れ子の分も含めデータを配列にして返す
      const func = (obj) => {
        for (const key in obj) {
          //不要なプロパティは除外
          // eslint-disable-next-line no-prototype-builtins
          if (obj.hasOwnProperty(key)) {
            // eslint-disable-next-line no-extra-parens
            if (typeof obj[key] !== 'object') {
              result.push(obj[key])
            } else {
              func(obj[key])
            }
          }
        }
      }
      //検索して結果を返す(オブジェクト入れ子対応)
      func(rest)
      return (
        Object.values(result)
          .join(',')
          .toLowerCase()
          .indexOf(word.toLowerCase()) !== -1
      )
    })
  }

  /**
   * データの検索
   * @param {Array} dataArray データオブジェクトの配列
   * @param {String} key 抽出対象のkey名称
   * @param {String} value 抽出対象の値
   * @return {Array} 検索条件(key,value)に一致するデータオブジェクトの配列
   */
  const searchKeyData = (dataArray, key, value) => {
    if (key !== '') {
      return dataArray.filter(function (dataObj) {
        //検索して結果を返す
        return dataObj[key] === value
      })
    }
    return dataArray
  }

  /**
   * データの並び替え
   * @param {Array} dataArray データオブジェクトの配列
   * @param {String} terms 並び替え条件
   * @param {String} sortDataName 並び替え時に確認する要素
   * @return {Array} 並び替えたデータオブジェクトの配列
   */
  const sortData = (dataArray, terms, sortDataName) => {
    let returnDataArray = []

    // 数値を昇順で並び替える
    const ascendingOrder = (a, b) => {
      return a[sortDataName] - b[sortDataName]
    }
    // 数値を降順で並び替える
    // const descendingOrder = (a, b) => {
    //   return b[sortDataName] - a[sortDataName]
    // }

    /**
     * FSCを昇順で並び替える
     * @param {Object} a 比較する要素1
     * @param {Object} b 比較する要素2
     * @returns {Number} 比較した結果
     */
    const fscAscendingOrder = (a, b) => {
      const fixedFscA = a[sortDataName] === '' ? -1 : a[sortDataName]
      const fixedFscB = b[sortDataName] === '' ? -1 : b[sortDataName]
      return fixedFscA - fixedFscB
    }

    //transitTimeの短い順で並び替える
    const transitTimeOrder = (a, b) => {
      const valueA = parseInt(a.days.time.charAt(0))
      const valueB = parseInt(b.days.time.charAt(0))
      return valueA - valueB
    }

    //通貨、価格の低い順に並び替える
    const lowPriceOrder = (a, b) => {
      //通貨で並び替え
      if (a.currency !== b.currency) {
        return a.currency > b.currency ? 1 : -1
      }
      //価格の低い順で並び替え
      return (
        parseInt(a[sortDataName].replace(/,/g, '')) -
        parseInt(b[sortDataName].replace(/,/g, ''))
      )
    }

    //通貨、価格の高い順に並び替える
    const highPriceOrder = (a, b) => {
      //通貨で並び替え
      if (a.currency !== b.currency) {
        return a.currency > b.currency ? 1 : -1
      }
      //価格の高い順で並び替え
      return (
        parseInt(b[sortDataName].replace(/,/g, '')) -
        parseInt(a[sortDataName].replace(/,/g, ''))
      )
    }

    //bestFlightsで並び替える
    const bestFlightsOrder = (a, b) => {
      //TransitTimeが短い順
      const valueA = parseInt(a.days.time.charAt(0))
      const valueB = parseInt(b.days.time.charAt(0))
      if (valueA !== valueB) {
        return valueA - valueB
      }
      // 費用が安い順
      if (
        parseInt(a.cost.replace(/,/g, '')) !==
        parseInt(b.cost.replace(/,/g, ''))
      ) {
        return (
          parseInt(a.cost.replace(/,/g, '')) -
          parseInt(b.cost.replace(/,/g, ''))
        )
      }
    }
    //bestFlightsで並び替える
    const bestFlights = (a, b) => {
      // 離陸日時が早い順
      const valueA = Date.parse(a.sortFlight)
      const valueB = Date.parse(b.sortFlight)
      if (valueA !== valueB) {
        return valueA > valueB ? 1 : -1
      }
      //通貨で並び替え
      if (a.currency !== b.currency) {
        return a.currency > b.currency ? 1 : -1
      }
      // 費用が安い順
      if (
        parseInt(a.cost.replace(/,/g, '')) !==
        parseInt(b.cost.replace(/,/g, ''))
      ) {
        return (
          parseInt(a.cost.replace(/,/g, '')) -
          parseInt(b.cost.replace(/,/g, ''))
        )
      }
    }
    // 出荷日の古い順で並び変える
    const readyDateOrder = (a, b) => {
      return a.readyDate > b.readyDate ? 1 : -1
    }
    // 出荷日の新しい順で並び変える
    const latelyOrder = (a, b) => {
      return a.readyDate < b.readyDate ? 1 : -1
    }

    // 文字で並び変える（荷主・混載業者・ステータス・SHCコード・出発空港・到着空港・ETD）
    const characterOrder = (a, b) => {
      return a[sortDataName] > b[sortDataName] ? 1 : -1
    }
    // 文字で並び変える（ETD）
    const characterDescendinOrder = (a, b) => {
      return a[sortDataName] < b[sortDataName] ? 1 : -1
    }

    // 問い合わせ有無で並び変える(有りを上にする：荷主・混載業者・航空会社)
    const inquiriesOrder = (a, b) => {
      let inquiriesArray = []
      inquiriesArray = sortDataName.split('.')
      if (inquiriesArray.length === 1) {
        return a[sortDataName] < b[sortDataName] ? 1 : -1
      } else {
        return a[inquiriesArray[0]][inquiriesArray[1]] <
          b[inquiriesArray[0]][inquiriesArray[1]]
          ? 1
          : -1
      }
    }
    // フライト日で並び替える
    const FlightDateOrder = (a, b) => {
      return a.code > b.code ? 1 : -1
    }
    //takeoffDateで並び替える
    const takeoffDateOrder = (a, b) => {
      return a[sortDataName] > b[sortDataName] ? 1 : -1
    }
    const takeoffDateDescendingOrder = (a, b) => {
      return a[sortDataName] < b[sortDataName] ? 1 : -1
    }
    //createdAt(登録日時)で並び替える
    const createdAtOrder = (a, b) => {
      const valueA = new Date(a[sortDataName].replace('///g', ''))
      const valueB = new Date(b[sortDataName].replace('///g', ''))
      return valueB - valueA
    }
    const createdAtDescendingOrder = (a, b) => {
      const valueA = new Date(a[sortDataName].replace('///g', '-'))
      const valueB = new Date(b[sortDataName].replace('///g', '-'))
      return valueA - valueB
    }
    // awbNoを昇順で並び替える
    const awbNoAscendingOrder = (a, b) => {
      return a[sortDataName].replace(/(-|\s+)/g, '') >
        b[sortDataName].replace(/(-|\s+)/g, '')
        ? 1
        : -1
    }
    // awbNoを降順で並び替える
    const awbNoDescendingOrder = (a, b) => {
      return b[sortDataName].replace(/(-|\s+)/g, '') >
        a[sortDataName].replace(/(-|\s+)/g, '')
        ? 1
        : -1
    }
    // Cass日付を並び替える
    const cassDateOrder = (a, b) => {
      const valueA = new Date(a[sortDataName].replace('///g', ''))
      const valueB = new Date(b[sortDataName].replace('///g', ''))
      return valueA - valueB
    }
    // Cass日付を昇順で並び替える
    const cassDateAscendingOrder = (a, b) => {
      const valueA = new Date(a[sortDataName].replace('///g', ''))
      const valueB = new Date(b[sortDataName].replace('///g', ''))
      return valueB - valueA
    }
    //価格の低い順に並び替える
    const planPriceOrder = (a, b) => {
      //価格の低い順で並び替え
      return (
        parseInt(a[sortDataName].replace(/,/g, '')) -
        parseInt(b[sortDataName].replace(/,/g, ''))
      )
    }
    // Common.jsに検索条件を記録し各jsで使用する

    // 並び替え条件で分岐
    switch (terms) {
      case Common.SORT_TERMS.PAST:
      case Common.SORT_TERMS.READYDATE_ORDER:
        returnDataArray = dataArray.sort(readyDateOrder)
        break
      case Common.SORT_TERMS.LATERY:
        returnDataArray = dataArray.sort(latelyOrder)
        break
      case Common.SORT_TERMS.PCS_ORDER:
      case Common.SORT_TERMS.WGT_ORDER:
      case Common.SORT_TERMS.MAX_WGT_ORDER:
      case Common.SORT_TERMS.VOL_ORDER:
      case Common.SORT_TERMS.MAX_VOL_ORDER:
      case Common.SORT_TERMS.TOTAL_USER:
      case Common.SORT_TERMS.CLIENT_LIST_STATUS:
      case Common.SORT_TERMS.STATUS_ID_ORDER:
      case Common.SORT_TERMS.NO_ORDER:
      case Common.SORT_TERMS.MINRATE_ORDER:
      case Common.SORT_TERMS.NORMAL_ORDER:
      case Common.SORT_TERMS.MIN_ORDER:
      case Common.SORT_TERMS.LIGHT_ORDER:
      case Common.SORT_TERMS.MEDIUM_ORDER:
      case Common.SORT_TERMS.LARGE_ORDER:
      case Common.SORT_TERMS.MAX_ORDER:
      case Common.SORT_TERMS.SELECT_ORDER:
        returnDataArray = dataArray.sort(ascendingOrder)
        break
      case Common.SORT_TERMS.LOW_PRICE:
      case Common.SORT_TERMS.RATE_ORDER:
      case Common.SORT_TERMS.FSC_CURRENCY_ORDER:
        returnDataArray = dataArray.sort(lowPriceOrder)
        break
      case Common.SORT_TERMS.HIGH_PRICE:
        returnDataArray = dataArray.sort(highPriceOrder)
        break
      case Common.SORT_TERMS.TRANSIT_TIME:
        returnDataArray = dataArray.sort(transitTimeOrder)
        break
      case Common.SORT_TERMS.BEST_FLIGHTS:
        returnDataArray = dataArray.sort(bestFlightsOrder)
        break
      case Common.SORT_TERMS.BEST_FLIGHTS_ETD:
        returnDataArray = dataArray.sort(bestFlights)
        break
      case Common.SORT_TERMS.TAKE_OFF_DATE:
        returnDataArray = dataArray.sort(takeoffDateOrder)
        break
      case Common.SORT_TERMS.TAKE_OFF_DATE_DESENDING:
        returnDataArray = dataArray.sort(takeoffDateDescendingOrder)
        break
      case Common.SORT_TERMS.CREATED_AT:
        returnDataArray = dataArray.sort(createdAtOrder)
        break
      case Common.SORT_TERMS.CREATED_AT_DESENDING:
      case Common.SORT_TERMS.VALIDITY_ASCENDING:
      case Common.SORT_TERMS.DEADLINE_ASCENDING:
        returnDataArray = dataArray.sort(createdAtDescendingOrder)
        break
      case Common.SORT_TERMS.MAWB_NO_ASCENDING:
      case Common.SORT_TERMS.HAWB_NO_ASCENDING:
        returnDataArray = dataArray.sort(awbNoAscendingOrder)
        break
      case Common.SORT_TERMS.MAWB_NO_DESCENDING:
      case Common.SORT_TERMS.HAWB_NO_DESCENDING:
        returnDataArray = dataArray.sort(awbNoDescendingOrder)
        break
      case Common.SORT_TERMS.FLIGHT_NAME_ORDER:
      case Common.SORT_TERMS.FLIGHT_NUMBER_ORDER:
      case Common.SORT_TERMS.ORG_ORDER:
      case Common.SORT_TERMS.DST_ORDER:
      case Common.SORT_TERMS.COMPANYNAME_ORDER:
      case Common.SORT_TERMS.FWDR_ORDER:
      case Common.SORT_TERMS.CAR_ORDER:
      case Common.SORT_TERMS.SHIPPER_ORDER:
      case Common.SORT_TERMS.STATUS_ORDER:
      case Common.SORT_TERMS.SHC_ORDER:
      case Common.SORT_TERMS.ETD_ASCENDING:
      case Common.SORT_TERMS.CARGO_READY_ORDER:
      case Common.SORT_TERMS.SHIPPER_ASCENDING:
      case Common.SORT_TERMS.CONSIGNEE_ASCENDING:
      case Common.SORT_TERMS.PIC_ASCENDING:
      case Common.SORT_TERMS.DETAILS_ASCENDING:
      case Common.SORT_TERMS.TRANSIT_ORDER:
      case Common.SORT_TERMS.FLIGHT_TYPE_ORDER:
      case Common.SORT_TERMS.COMMODITY_ORDER:
      case Common.SORT_TERMS.TERMS_ORDER:
      case Common.SORT_TERMS.CURRENCY_ORDER:
      case Common.SORT_TERMS.REMARK_ORDER:
      case Common.SORT_TERMS.TRANSIT_TIME_ORDER:
      case Common.SORT_TERMS.LT_ORDER:
      case Common.SORT_TERMS.COMPANYNAME_ASCENDING:
      case Common.SORT_TERMS.CODE:
      case Common.SORT_TERMS.AIRLINE_ORDER:
      case Common.SORT_TERMS.EMAIL_ASCENDING:
      case Common.SORT_TERMS.TEL_ASCENDING:
        returnDataArray = dataArray.sort(characterOrder)
        break
      case Common.SORT_TERMS.COMPANYNAME_DESCENDING:
      case Common.SORT_TERMS.ETD_DESCENDING:
        returnDataArray = dataArray.sort(characterDescendinOrder)
        break
      case Common.SORT_TERMS.SHIPPER_INQUIRIES_ORDER:
      case Common.SORT_TERMS.CARRIER_INQUIRIES_ORDER:
      case Common.SORT_TERMS.INQUIRIES_ORDER:
      case Common.SORT_TERMS.NOTIFY_ASCENDING:
        returnDataArray = dataArray.sort(inquiriesOrder)
        break
      case Common.SORT_TERMS.CASS_DATA_DATE:
        returnDataArray = dataArray.sort(cassDateOrder)
        break
      case Common.SORT_TERMS.CASS_DATA_DATE_ASCENDING:
        returnDataArray = dataArray.sort(cassDateAscendingOrder)
        break
      case Common.SORT_TERMS.FSC_ORDER:
        returnDataArray = dataArray.sort(fscAscendingOrder)
        break
      case Common.SORT_TERMS.FLIGHT_DATE_ORDER:
        // dataArrayからフライト情報を取り出す
        // eslint-disable-next-line no-case-declarations
        const arr = []
        dataArray.forEach(function (value) {
          arr.push(value.takeoffDate)
        })
        // arrから日付を取り出す
        // eslint-disable-next-line no-case-declarations
        const splitDate = []
        arr.forEach(function (value) {
          splitDate.push(value.split('/')[1])
        })
        // 日付をアルファベットから数字に変換して前後入れ替え
        // eslint-disable-next-line no-case-declarations
        const newDate = []
        // eslint-disable-next-line no-case-declarations
        let name = {}
        for (let i = 0; i < splitDate.length; i++) {
          if (splitDate[i].match('JAN')) {
            name = splitDate[i].replace('JAN', '01')
          } else if (splitDate[i].match('FEB')) {
            name = splitDate[i].replace('FEB', '02')
          } else if (splitDate[i].match('MAR')) {
            name = splitDate[i].replace('MAR', '03')
          } else if (splitDate[i].match('APR')) {
            name = splitDate[i].replace('APR', '04')
          } else if (splitDate[i].match('MAY')) {
            name = splitDate[i].replace('MAY', '05')
          } else if (splitDate[i].match('JUN')) {
            name = splitDate[i].replace('JUN', '06')
          } else if (splitDate[i].match('JUL')) {
            name = splitDate[i].replace('JUL', '07')
          } else if (splitDate[i].match('AUG')) {
            name = splitDate[i].replace('AUG', '08')
          } else if (splitDate[i].match('SEP')) {
            name = splitDate[i].replace('SEP', '09')
          } else if (splitDate[i].match('OCT')) {
            name = splitDate[i].replace('OCT', '10')
          } else if (splitDate[i].match('NOV')) {
            name = splitDate[i].replace('NOV', '11')
          } else if (splitDate[i].match('DEC')) {
            name = splitDate[i].replace('DEC', '12')
          }
          const nameA = name.substring(0, 2)
          const nameB = name.substring(4, 2)
          const names = nameB + nameA
          newDate.push(names)
        }
        // 修正した日付をdataArrayに追加
        for (let i = 0; i < dataArray.length; i++) {
          for (let j = 0; j < newDate.length; j++) {
            dataArray[i].code = newDate[i]
          }
        }
        returnDataArray = dataArray.sort(FlightDateOrder)
        break
      case Common.SORT_TERMS.PLAN_ORDER:
        returnDataArray = dataArray.sort(planPriceOrder)
        break
      default:
        returnDataArray = dataArray
        break
    }

    return returnDataArray
  }

  /**
   * データオブジェクトの配列を1ページ毎に分割する
   * @param {Array} dataArray データオブジェクトの配列
   * @param {Number} pageAmount 1ページに表示するデータの件数(任意)
   * @return {Array} ページ単位で分割されたデータオブジェクトの配列
   */
  const splitData = (dataArray, pageAmount) => {
    const num = pageAmount ? pageAmount : Common.PAGE
    const resultArray = []
    for (let i = 0; i < dataArray.length; i += num) {
      resultArray.push(dataArray.slice(i, i + num))
    }
    return resultArray
  }

  /**
   * データオブジェクトの配列にID(index番号)を付与する
   * @param {Array} dataArray データオブジェクトの配列
   * @return {Array} ID(index番号)を付与したデータオブジェクトの配列
   */
  const addDataID = (dataArray) => {
    const resultArray = dataArray.map((row, index) => {
      return { ...row, id: index }
    })
    return resultArray
  }

  /**
   * 検索データを並び替えて1ページ目を返却
   * @param {Array} dataArray データオブジェクトの配列
   * @param {String} searchWord 検索ワード
   * @param {String} sortTerms 並び替え条件
   * @param {String} sortDataName 並び替え時に確認する要素
   * @param {Number} pageAmount 1ページに表示するデータの個数(任意)
   * @return {Object} 初期データ(initData)、1ページ毎の表示データの配列(showData)、データ個数(amount)をオブジェクトで返却
   */
  const searchSortData = (
    dataArray,
    searchWord,
    sortTerms,
    sortDataName,
    pageAmount
  ) => {
    const dataArraywID = addDataID(dataArray)
    const returnObject = {
      initData: dataArraywID, // 毎回DBからデータを取得しなくてもいいように保存
      showData: [], // 表示する用のデータ
      amount: 0, // ページ数を出すのに必要
    }
    // 検索ワードからデータを抽出
    returnObject.showData = searchData(dataArraywID, searchWord)
    returnObject.amount = returnObject.showData.length

    // 並び替え
    returnObject.showData = sortData(
      returnObject.showData,
      sortTerms,
      sortDataName
    )

    // ページ毎に切り分け
    returnObject.showData = splitData(returnObject.showData, pageAmount)

    //ページが1ページもなかった場合に空のページ(空の配列)を追加する
    if (returnObject.showData.length === 0) {
      returnObject.showData.push([])
    }
    return returnObject
  }

  /**
   * 検索データを並び替えて1ページ目を返却
   * @param {Array} dataArray データオブジェクトの配列
   * @param {String} defaultWord デフォルトチェックボックスで絞り込むワード
   * @param {String} searchWord 検索ワード
   * @param {String} sortTerms 並び替え条件
   * @param {String} sortDataName 並び替え時に確認する要素
   * @param {Number} pageAmount 1ページに表示するデータの個数(任意)
   * @return {Object} 初期データ(initData)、1ページ毎の表示データの配列(showData)、データ個数(amount)をオブジェクトで返却
   */
  const searchSortDefaultData = (
    dataArray,
    defaultWord,
    searchWord,
    sortTerms,
    sortDataName,
    pageAmount
  ) => {
    const dataArraywID = addDataID(dataArray)
    const returnObject = {
      initData: dataArraywID, // 毎回DBからデータを取得しなくてもいいように保存
      showData: [], // 表示する用のデータ
      amount: 0, // ページ数を出すのに必要
    }

    // 検索ワードからデータを抽出
    defaultWord = defaultWord === undefined ? '' : defaultWord
    returnObject.showData = searchData(dataArraywID, defaultWord)
    returnObject.showData = searchData(returnObject.showData, searchWord)
    returnObject.amount = returnObject.showData.length

    // 並び替え
    returnObject.showData = returnObject.showData = sortData(
      returnObject.showData,
      sortTerms,
      sortDataName
    )

    // ページ毎に切り分け
    returnObject.showData = splitData(returnObject.showData, pageAmount)

    //ページが1ページもなかった場合に空のページ(空の配列)を追加する
    if (returnObject.showData.length === 0) {
      returnObject.showData.push([])
    }
    return returnObject
  }

  /**
   * 検索データを並び替えて1ページ目を返却(NotifyList画面用)
   * @param {Array} dataArray データオブジェクトの配列
   * @param {Object} searchWords 検索ワード
   * @param {String} sortTerms 並び替え条件
   * @param {String} sortDataName 並び替え時に確認する要素
   * @param {Number} pageAmount 1ページに表示するデータの個数(任意)
   * @return {Object} 初期データ(initData)、1ページ毎の表示データの配列(showData)、データ個数(amount)をオブジェクトで返却
   */
  const searchSortNotifyData = (
    dataArray,
    searchWords,
    sortTerms,
    sortDataName,
    pageAmount
  ) => {
    const returnObject = {
      initData: dataArray, // 毎回DBからデータを取得しなくてもいいように保存
      showData: [], // 表示する用のデータ
      amount: 0, // ページ数を出すのに必要
    }

    // 検索ワードからデータを抽出
    returnObject.showData = searchData(dataArray, searchWords.search)
    if (searchWords.read === 'unread') {
      returnObject.showData = searchKeyData(returnObject.showData, 'readFlg', 0)
    }
    //areaは1~3の複数検索
    if (searchWords.area1 || searchWords.area2 || searchWords.area3) {
      let area1 = []
      let area2 = []
      let area3 = []
      if (searchWords.area1 === true) {
        area1 = searchKeyData(returnObject.showData, 'area', 1) //area1を絞り込み
      }
      if (searchWords.area2 === true) {
        area2 = searchKeyData(returnObject.showData, 'area', 2) //area2を絞り込み
      }
      if (searchWords.area3 === true) {
        area3 = searchKeyData(returnObject.showData, 'area', 3) //area3を絞り込み
      }
      returnObject.showData = area1.concat(area2).concat(area3) //area1~3を連結
    }

    // senderの複数検索
    if (
      searchWords.sameCompany !== '' ||
      searchWords.acdigi ||
      searchWords.fwdr !== '' ||
      searchWords.shipper !== '' ||
      searchWords.carrier !== ''
    ) {
      let sameCompany = []
      let acdigi = []
      let fwdr = []
      let shipper = []
      let carrier = []

      if (searchWords.sameCompany !== '') {
        sameCompany = searchKeyData(
          returnObject.showData,
          'companyName',
          searchWords.sameCompany
        )
      }
      if (searchWords.acdigi === true) {
        acdigi = searchKeyData(returnObject.showData, 'page', null)
      }
      if (searchWords.fwdr !== '') {
        //空文字は検索しない
        if (searchWords.fwdr === 'All') {
          fwdr = searchKeyData(returnObject.showData, 'sender', 2) // Allは全て
        } else {
          fwdr = searchKeyData(
            returnObject.showData,
            'companyName',
            searchWords.fwdr
          )
        }
      }
      if (searchWords.shipper !== '') {
        if (searchWords.shipper === 'All') {
          shipper = searchKeyData(returnObject.showData, 'sender', 1)
        } else {
          shipper = searchKeyData(
            returnObject.showData,
            'companyName',
            searchWords.shipper
          )
        }
      }
      if (searchWords.carrier !== '') {
        if (searchWords.shipper === 'All') {
          carrier = searchKeyData(returnObject.showData, 'sender', 3)
        } else {
          carrier = searchKeyData(
            returnObject.showData,
            'companyName',
            searchWords.carrier
          )
        }
      }
      returnObject.showData = sameCompany
        .concat(acdigi)
        .concat(fwdr)
        .concat(shipper)
        .concat(carrier) //連結
    }

    if (searchWords.date !== '') {
      returnObject.showData = searchKeyData(
        returnObject.showData,
        'searchDate',
        searchWords.date
      )
    }

    returnObject.amount = returnObject.showData.length

    //日付で並び替え
    returnObject.showData = returnObject.showData = sortData(
      returnObject.showData,
      sortTerms,
      sortDataName
    )

    // ページ毎に切り分け
    returnObject.showData = splitData(returnObject.showData, pageAmount)

    //ページが1ページもなかった場合に空のページ(空の配列)を追加する
    if (returnObject.showData.length === 0) {
      returnObject.showData.push([])
    }
    return returnObject
  }

  /**
   * 検索データを並び替えて1ページ目を返却
   * @param {Array} dataArray データオブジェクトの配列
   * @param {String} key 抽出対象のkey名称
   * @param {String} value 抽出対象の値
   * @param {String} searchWord 検索ワード
   * @param {Number} pageAmount 1ページに表示するデータの個数(任意)
   * @return {Object} 初期データ(initData)、1ページ毎の表示データの配列(showData)、データ個数(amount)をオブジェクトで返却
   */
  const searchSortKeyData = (dataArray, key, value, searchWord, pageAmount) => {
    const dataArraywID = addDataID(dataArray)
    const returnObject = {
      initData: dataArraywID, // 毎回DBからデータを取得しなくてもいいように保存
      showData: [], // 表示する用のデータ
      amount: 0, // ページ数を出すのに必要
    }

    // 検索ワードからデータを抽出
    returnObject.showData = searchKeyData(dataArraywID, key, value)
    returnObject.showData = searchData(returnObject.showData, searchWord)
    returnObject.amount = returnObject.showData.length

    // ページ毎に切り分け
    returnObject.showData = splitData(returnObject.showData, pageAmount)

    //ページが1ページもなかった場合に空のページ(空の配列)を追加する
    if (returnObject.showData.length === 0) {
      returnObject.showData.push([])
    }
    return returnObject
  }

  /**
   * 既に登録済みユーザだった場合のメッセージ
   * ページによって判定箇所が変わるので現在ページで分岐
   * @param {String} errEmails 重複したメールアドレス
   * @return {String} 文言番号
   */
  const getAlreadyUserMessage = (errEmails) => {
    const pagePath = window.location.pathname.toLowerCase()
    switch (pagePath) {
      // メールアドレスとパスワードが同一
      case Paths.MASTER.JH_MY_PROFILE.toLowerCase():
      case Paths.MASTER.SHIP_MY_PROFILE.toLowerCase():
      case Paths.MASTER.LIGHT_FWDR_MY_PROFILE.toLowerCase():
      case Paths.MASTER.LIGHT_CAR_MY_PROFILE.toLowerCase():
        return 'D301V0241'
      // IataCassNoが同一
      case Paths.AUTHENTICATION.LIGHT_AIRLINE_REGISTER_NEW_ACCOUNT.toLowerCase():
      case Paths.AUTHENTICATION.LIGHT_FWDR_REGISTER_NEW_ACCOUNT.toLowerCase():
      case Paths.MASTER.LIGHT_FWDR_COMPANY_INFO.toLowerCase():
      case Paths.MASTER.LIGHT_CAR_COMPANY_INFO.toLowerCase():
        return 'D301V0242'
      // Shipper/ConsigneeIdが同一
      case Paths.AUTHENTICATION.LIGHT_SHIP_REGISTER_NEW_ACCOUNT.toLowerCase():
      case Paths.MASTER.SHIP_COMPANY_INFOM.toLowerCase():
        return 'D301V0243'
      // E-mailが同一
      case Paths.MASTER.LIGHT_FWDR_ACCOUNT_INFO.toLowerCase():
      case Paths.MASTER.LIGHT_CAR_ACCOUNT_INFO.toLowerCase():
      case Paths.MASTER.SHIP_ACCOUNT_INFO.toLowerCase():
      case Paths.MASTER.JH_ACCOUNT_INFO.toLowerCase():
        return { errEmails, message: 'D301V0246' }
      // それ以外の場合既に登録済みであることのみ伝える
      default:
        return 'D301V0244'
    }
  }

  /**
   * エラーコードに対応するメッセージを返却
   * @param {Number} errCode エラーコード
   * @param {String} errEmails 重複したメールアドレス
   * @return {String} 文言番号
   */
  const getErrorMessage = (errCode, errEmails) => {
    let message = ''
    switch (errCode) {
      case Common.ERROR_CODE.NORMAL: // 正常
        message = 'D301V0153'
        break
      case Common.ERROR_CODE.NOT_UPDATED: // 既にデータ更新されていた
        message = 'D301V0154'
        break
      case Common.ERROR_CODE.INVALID_PARAMS: // パラメータエラー
        message = 'D301V0155'
        break
      case Common.ERROR_CODE.WRONG_PASSWORD: // パスワード不一致
        message = 'D301V0159'
        break
      case Common.ERROR_CODE.ALREADY_USER: // ユーザが登録済みの場合
        message = getAlreadyUserMessage(errEmails)
        break
      case Common.ERROR_CODE.RELOAD_NEW_INFO: // リロードする必要がある場合
        message = 'D301V0255'
        break
      case Common.ERROR_CODE.ALREADY_REGISTERED_CNSL_CENTER: // 混載センターが同一
      case Common.ERROR_CODE.ALREADY_REGISTERED_CNSL_BRANCH: // 混載支店があったら、混載センターが登録できない
      case Common.ERROR_CODE.ALREADY_REGISTERED_FWDR_COMPANY: // 同一の支店・混載支店がある
        message = 'D301V0270'
        break
      case Common.ERROR_CODE.ALREADY_REGISTERED_SERVICE_OFFICE: // 対応可能都市重複
        message = 'D301V0271'
        break
      case Common.ERROR_CODE.ALREADY_REGISTERED_AIRPORT: // 同一のHubsがある場合
        message = 'D301V0272'
        break
      case Common.ERROR_CODE.DUPLICATED_WITH_OTHER_USERS: // 他の混載業者がHAWBを作成済み
        message = 'D301V0274'
        break
      case Common.ERROR_CODE.ALREADY_REGISTERED_EMAIL: //メールアドレスが重複している場合
        message = 'D301V0254'
        break
      case Common.ERROR_CODE.MAXMUM_USER: //最大人数を超過している場合
        message = '5th0004'
        break
      case Common.ERROR_CODE.FWDR_FREE_PLAN: //最大人数を超過している場合
        message = '5th0005'
        break
      case Common.ERROR_CODE.FWDR_STANDARD_PLAN: //最大人数を超過している場合
        message = '5th0006'
        break
      case Common.ERROR_CODE.CAR_STANDARD_PLAN: //最大人数を超過している場合
        message = '5th0007'
        break
      case Common.ERROR_CODE.INVALID_USER: //ユーザーが見つからない場合
        message = '5th0008'
        break
      case Common.ERROR_CODE.NO_TOKEN: // トークン空
      case Common.ERROR_CODE.INVALID_TOKEN: // トークン不正
        message = 'D301V0156'
        break
      case Common.ERROR_CODE.MAIL_SYSTEM_ERROR: // メールシステムがエラーを返したとき
        message = 'D301V0275'
        break
      case Common.ERROR_CODE.TIMEOUT_ERROR: // タイムアウトが発生したとき
        message = 'D301V0276'
        break
      case Common.ERROR_CODE.NETWORK_ERROR: // バックエンドに接続できない
        message = 'D301V0157'
        break
      case Common.ERROR_CODE.ALREADY_DELETE_ACCOUNT:
        message = 'D501V0143'
        break
      default: // その他エラー
        message = 'D301V0158'
        break
    }

    return message
  }

  /**
   * SignInのエラーコードに対応するメッセージを返却
   * @param {Number} errCode エラーコード
   * @return {String} 文言番号
   */
  const getSignInMessage = (errCode) => {
    let message = ''
    switch (errCode) {
      case Common.ERROR_CODE.BAD_REQUEST: // パスワード又はメールアドレスの一致エラー
        message = '5th0002'
        break
      case Common.ERROR_CODE.PASSWORD_LIMIT: // パスワード入力回数制限
        message = 'D501V0089'
        break
      case Common.ERROR_CODE.TIMEOUT_ERROR: // タイムアウトが発生したとき
        message = 'D301V0276'
        break
      case Common.ERROR_CODE.NETWORK_ERROR: // バックエンドに接続できない
        message = 'D301V0157'
        break
      default: // その他エラー
        message = 'D501V0090'
        break
    }

    return message
  }

  /**
   * ログインしたユーザ情報を取得
   * @return {Object} ログイン時に取得したユーザデータオブジェクト
   */
  const getLoginUserData = () =>
    JSON.parse(getStorage(Common.KEY.USER_INFO) || '{}')

  /**
   * ログインしたユーザのユーザタイプを取得
   * @param {Object} userData ユーザ情報
   * @return {String} ユーザタイプ Common.USER_TYPE
   */
  const getLoginUserType = (userData) => {
    let userType = ''
    if (userData.shipperJhId !== null) {
      if (userData.shipperJhFlg) {
        userType = Common.USER_TYPE.JH
      } else {
        userType = Common.USER_TYPE.SHIPPER
      }
    } else if (userData.fwdrId !== null) {
      userType = Common.USER_TYPE.FWDR
    } else if (userData.carrierId !== null) {
      userType = Common.USER_TYPE.AIRLINE
    }
    return userType
  }

  /**
   * 指定したurlのPDFファイルを開く
   * @param {String} url PDFファイルのURL
   * @param {String} name 開くタブのタイトル
   * @param {Boolean} isDownload ダウンロードするか
   * @return {void} 新規タブでPDFファイルを開く
   */
  const openPdfFile = (url, name, isDownload) => {
    //urlの先頭4文字でdataURLかURLかを切り分ける
    if (url.substring(0, 4) === 'data') {
      //dataURLのとき（ユーザがアップロードしたファイル）
      if (isDownload) {
        const link = document.createElement('a')
        link.download = name
        link.href = url
        link.click() //ダウンロード
        link.remove()
      } else {
        const pdf = window.open('') //PDF表示
        pdf.document.open()
        pdf.document.write('<html>\n')
        pdf.document.write('<head>\n')
        // eslint-disable-next-line prefer-template
        pdf.document.write('<title>' + name + '</title>\n')
        pdf.document.write('</head>\n')
        pdf.document.write('<body style="margin:0px">\n')
        // eslint-disable-next-line prefer-template
        pdf.document.write(
          '<object type="application/pdf" data=' +
            url +
            ' style="width:100%; height:100%" >\n'
        )
        // eslint-disable-next-line prefer-template
        pdf.document.write(
          '<embed style="position:absolute; left: 0; top: 0;" width="100%" height="100%" src=' +
            url +
            '>\n'
        )
        pdf.document.write('</object>\n')
        pdf.document.write('</body>\n')
        pdf.document.write('</html>\n')
        pdf.document.close()
      }
    } else {
      //URLのとき（S3のファイル）
      window.open(url) //PDF表示・ダウンロード
    }
  }

  /**
   * @param {*} history react-router-domのuseHistory
   * @return {void} Top画面に遷移
   */
  const topTranslation = (history) => {
    const userType = getLoginUserData().userType
    let topPath = ''
    switch (userType) {
      case Common.USER_TYPE.SHIPPER:
        topPath = Paths.SHIPPER.TOP
        break
      case Common.USER_TYPE.FWDR:
        topPath = Paths.FWDR.TOP
        break
      case Common.USER_TYPE.AIRLINE:
        topPath = Paths.AIRLINE.TOP
        break
      case Common.USER_TYPE.JH:
        topPath = Paths.MASTER.JH_TOP
        break
      default:
        break
    }
    clickEvent({ path: topPath }, history)
  }

  /**
   * @param {*} history react-router-domのuseHistory
   * @return {void} MasterTopTop画面に遷移
   */
  const masterTopTranslation = (history) => {
    const userType = getLoginUserData().userType
    let topPath = ''
    switch (userType) {
      case Common.USER_TYPE.SHIPPER:
        topPath = Paths.MASTER.SHIP_MASTER_TOP
        break
      case Common.USER_TYPE.FWDR:
        topPath = Paths.MASTER.FWDR_MASTER_TOP
        break
      case Common.USER_TYPE.AIRLINE:
        topPath = Paths.MASTER.CAR_MASTER_TOP
        break
      case Common.USER_TYPE.JH:
        topPath = Paths.MASTER.JH_MASTER_TOP
        break
      default:
        break
    }
    clickEvent({ path: topPath }, history)
  }

  /**
   * 日付のフォーマットを変換する
   * @param {String} date DBから取得した日付
   * @param {String} lang 言語
   * @return {String} 日付
   */
  const convertDate = (date, lang) => {
    if (lang === 'en') {
      const result = date.split('-')
      switch (result[1]) {
        case '01':
          result[1] = 'Jan.'
          break
        case '02':
          result[1] = 'Feb.'
          break
        case '03':
          result[1] = 'Mar.'
          break
        case '04':
          result[1] = 'Apr.'
          break
        case '05':
          result[1] = 'May'
          break
        case '06':
          result[1] = 'Jun.'
          break
        case '07':
          result[1] = 'Jul.'
          break
        case '08':
          result[1] = 'Aug.'
          break
        case '09':
          result[1] = 'Sep.'
          break
        case '10':
          result[1] = 'Oct.'
          break
        case '11':
          result[1] = 'Nov.'
          break
        case '12':
          result[1] = 'Dec.'
          break
        default:
          break
      }
      // eslint-disable-next-line prefer-template
      return result[1] + ' ' + result[2] + ', ' + result[0]
    } else {
      return date.replace(/-/g, '/')
    }
  }

  /**
   * 画面のスクロールを非表示
   * @return {void}
   */
  const hideScroll = () => {
    if (document.body.style.overflow !== 'hidden') {
      // overflow設定を保存
      setStorage(Common.KEY.OVERFLOW_SETTING, document.body.style.overflow)
      document.body.style.overflow = 'hidden'
    }
  }
  /**
   * 画面のスクロールを表示
   * @return {void}
   */
  const showScroll = () => {
    // 保存されているoverflow設定を再現
    const currentOverflowSetting = getStorage(Common.KEY.OVERFLOW_SETTING)
    document.body.style.overflow = currentOverflowSetting
  }

  /**
   * エラーダイアログを閉じる
   * @return {void}
   */
  const closeErrorDialog = () => {
    document.getElementById('error-dialog').hidden = true
  }

  /**
   * エラーダイアログを表示
   * @param {Number} code コード番号
   * @param {string} [message=null] メッセージ
   * @param {Array} errEmails エラーメールアドレス
   * @return {void}
   */
  const openErrorDialog = (code, message = null, errEmails) => {
    // ポップアップにメッセージまたはエラー文を設定
    setErrorMessage(message ?? getErrorMessage(code, errEmails))

    // ポップアップを表示
    const errorDialogElement = document.getElementById('error-dialog')
    if (errorDialogElement) {
      errorDialogElement.hidden = false
    }
  }

  /**
   * コールバック関数とエラー処理を実行
   * historyを指定しない場合ポップアップになります
   * @param {Object} res responseを指定
   * @param {Func} callback 成功時のコールバック関数を指定
   * @param {*} history react-router-domのuseHistory
   * @param {Array} errEmails エラーメールアドレス
   * @return {void}
   */
  const callbackFunc = (res, callback, history, errEmails) => {
    // ステータスを見て成功、失敗を判断
    switch (res.data.status) {
      case Common.RESPONSE_STATUS_CODE.OK:
        // 成功時
        callback(res)
        break
      case Common.RESPONSE_STATUS_CODE.NG:
        // 失敗時
        // eslint-disable-next-line no-case-declarations
        const code = res.data.info.ErrorCode
        // eslint-disable-next-line no-case-declarations
        let message = res.data.info.ErrorMessage
        if (typeof res.data.info.ErrorMessage === 'object') {
          message = JSON.stringify(message)
        }
        // eslint-disable-next-line no-case-declarations
        const error = {
          code,
          message,
        }
        /** ポップアップを絶対表示するエラーコード配列 */
        // eslint-disable-next-line no-case-declarations
        const popupErrorCodeArr = [
          Common.ERROR_CODE.NOT_UPDATED,
          Common.ERROR_CODE.RELOAD_NEW_INFO,
          Common.ERROR_CODE.ALREADY_USER,
          Common.ERROR_CODE.ALREADY_REGISTERED_EMAIL,
          Common.ERROR_CODE.ALREADY_DELETE_ACCOUNT,
          Common.ERROR_CODE.DUPLICATED_WITH_OTHER_USERS,
          Common.ERROR_CODE.WRONG_PASSWORD,
          Common.ERROR_CODE.ALREADY_REGISTERED_AIRPORT,
          Common.ERROR_CODE.ALREADY_REGISTERED_FWDR_COMPANY,
          Common.ERROR_CODE.MAXMUM_USER,
          Common.ERROR_CODE.FWDR_FREE_PLAN,
          Common.ERROR_CODE.FWDR_STANDARD_PLAN,
          Common.ERROR_CODE.CAR_STANDARD_PLAN,
          Common.ERROR_CODE.INVALID_USER,
        ]
        // eslint-disable-next-line no-case-declarations
        const isPopup = popupErrorCodeArr.includes(code)
        if (history && !isPopup) {
          if (code === Common.ERROR_CODE.DELETED_USER) {
            // サインイン画面に遷移
            setStorage(Common.KEY.TOKEN, null)
            transPage({ path: Paths.AUTHENTICATION.SIGN_IN }, history)
          } else if (
            getStorage(Common.KEY.CURRENT_PATH) !== Paths.OTHERS.ERROR
          ) {
            //エラーページに遷移
            transPage(
              {
                path: Paths.OTHERS.ERROR,
                state: history.location.state.state,
                error,
              },
              history
            )
          }
        } else {
          //ポップアップを表示
          openErrorDialog(error.code, null, errEmails)
        }
        break
      // メンテナンス画面に遷移
      case Common.RESPONSE_STATUS_CODE.CREATED:
        transPage({ path: Paths.OTHERS.MAINTENANCE }, history)
        break
      default:
        // ステータス200と400以外は来ない想定
        break
    }
  }

  /**
   * 非同期でAPIを実行
   * @param {Array} apiInfoArr APIの情報API key,method,path,reqを持つオブジェクトの配列
   * @param {*} [obj={}] 今回の結果を他の結果に上乗せしたい場合はオブジェクトを追加
   * @return {Object} 結果をオブジェクトで返却
   */
  const execApiAsync = async (
    apiInfoArr = [{ key: '', method: '', path: '', req: '' }],
    obj = {}
  ) => {
    // APIのPromiseを配列で収納し、一括で実行
    const promiseArr = apiInfoArr.map(({ method, path, req = {} }) =>
      restFacade[method](path, '', req)
    )
    const resArr = await Promise.all(promiseArr)
    // 配列をオブジェクトに変換
    const resObj = Object.fromEntries(
      resArr.map((value, index) => [apiInfoArr[index].key, value])
    )
    return { ...obj, ...resObj }
  }

  /**
   * APIが成功しているか確認 1つでも失敗していたら共通処理を実行
   * @param {Object} resObj APIのレスポンスの一覧オブジェクト
   * @param {*} history react-router-domのuseHistory
   * @param {Array} errEmails エラーメールアドレス
   * @return {boolean} すべて成功時はtrue、1つでも失敗していたらfalse
   */
  const checkApiResponseObj = (resObj, history, errEmails = null) => {
    // エラーページの場合、APIのレスポンスがないときは失敗判定とする
    const isErrorPage = window.location.pathname === Paths.OTHERS.ERROR
    const isResponseEmpty = !Object.values(resObj).length
    if (isErrorPage || isResponseEmpty) return false

    // NGの結果を検索、検索結果がない場合は成功
    const ngResultArr = Object.values(resObj).filter(
      (res) => res?.data?.status === Common.RESPONSE_STATUS_CODE.NG
    )
    const isSuccessful = ngResultArr.length === 0
    // NGがある場合は共通処理
    if (!isSuccessful)
      callbackFunc(ngResultArr[0], () => {}, history, errEmails)
    return isSuccessful
  }

  /**
   * エラー発生時の共通処理
   * @param {Number} errorCode バックエンドから帰ってきたエラーコード
   * @return {void}
   */
  const errorHandling = (errorCode) => {
    switch (errorCode) {
      case Common.ERROR_CODE.INVALID_TOKEN: // トークン不正
        // 情報削除
        resetAllStorage()
        break
      default:
        break
    }
  }

  /**
   * profileのバリデーションチェック振り分け
   * @param {string} element 要素名
   * @param {string} value チェックする文字列
   * @return {Object} バリデーションチェックの結果Object
   */
  const profileCheckFunc = (element, value) => {
    switch (element) {
      case 'name':
      case 'lastName':
        return CommonValidation.name(value)
      case 'email':
        return CommonValidation.mail(value)
      case 'tel':
        return CommonValidation.telephone(value)
      case 'position':
        return CommonValidation.position(value)
      case 'department':
        return CommonValidation.department(value)
      default:
        return CommonValidation.initValue()
    }
  }

  /**
   * addprofitのバリデーションチェック振り分け
   * @param {string} element 要素名
   * @param {string} value チェックする文字列
   * @return {Object} バリデーションチェックの結果Object
   */
  const addprofitCheckFunc = (element, value) => {
    switch (element) {
      case 'fwdrmin':
      case 'fwdrlight':
      case 'fwdrmedium':
      case 'fwdrlarge':
      case 'fwdrmax':
      case 'rate':
        return CommonValidation.priceOptional(value)
      default:
        return CommonValidation.initValue()
    }
  }

  /**
   * awbNumberのバリデーションチェック振り分け
   * @param {string} element 要素名
   * @param {string} value チェックする文字列
   * @return {Object} バリデーションチェックの結果Object
   */
  const awbNumberCheckFunc = (element, value) => {
    switch (element) {
      case 'awbNumber1':
      case 'prefix':
        return CommonValidation.awbNumberFirstHarf(value)
      case 'awbNumber2':
      case 'awbNumber3':
      case 'min2':
      case 'min3':
      case 'max2':
      case 'max3':
        return CommonValidation.awbNumberSecondHalf(value)
      default:
        return CommonValidation.initValue()
    }
  }

  /**
   * 日付を09FEBの形に変換
   * @param {Array} data 日付
   * @return {String} 09FEBの形の日付
   */
  const getDate = (data) => {
    let month = data.split('-')[1]
    const date = data.split('-')[2]
    const monthList = [
      { num: '01', eng: 'JAN' },
      { num: '02', eng: 'FEB' },
      { num: '03', eng: 'MAR' },
      { num: '04', eng: 'APR' },
      { num: '05', eng: 'MAY' },
      { num: '06', eng: 'JUN' },
      { num: '07', eng: 'JUL' },
      { num: '08', eng: 'AUG' },
      { num: '09', eng: 'SEP' },
      { num: '10', eng: 'OCT' },
      { num: '11', eng: 'NOV' },
      { num: '12', eng: 'DEC' },
    ]
    month = monthList.find((v) => month === v.num)
    return date + month.eng
  }

  /**
   * viaの組み合わせ表示を作成
   * @param {object} info viaOne viaTwo 中継地
   * @return {String} viaの組み合わせ表示 主にTracingで共通使用
   */
  const createVia = (info) => {
    const viaOne = info.viaOne ? info.viaOne : '-'
    const viaTwo = info.viaTwo ? info.viaTwo : '-'

    return viaOne !== '-' && viaTwo === '-' ? viaOne : `${viaOne}/${viaTwo}`
  }

  /**
   * 標準時(UTC)をユーザーのローカル時刻に変換する
   * @param {string} utc 変換する時刻
   * @param {string} format 変換する時刻のフォーマット情報
   * @param {string} retformat 変換した時刻のフォーマット情報
   * @return {string} 変換した時刻
   */
  const chgUTC2LocalTime = (utc, format, retformat) => {
    const date = moment(utc, format)
    let utcDiff = {}
    let ret = utc
    try {
      // 時差取得
      utcDiff = JSON.parse(getStorage(Common.KEY.UTC_INFO))
    } catch (e) {
      //console.log(e)
    }

    if (date?.isValid() === true) {
      if (utcDiff?.code === '+') {
        // eslint-disable-next-line no-extra-parens
        ret = date
          .add(utcDiff.hours * 60 + utcDiff.minutes, 'minutes')
          .format(retformat)
      } else if (utcDiff?.code === '-') {
        // eslint-disable-next-line no-extra-parens
        ret = date
          .subtract(utcDiff.hours * 60 + utcDiff.minutes, 'minutes')
          .format(retformat)
      }
    }

    if (retformat === 'HH:mm' || retformat === 'HH:mm:ss') {
      //24時を超えた場合0時に変換、秒数も取り除く
      let hour = parseInt(ret.split(':')[0])
      if (parseInt(hour) > 23) {
        hour -= 24
      }
      // eslint-disable-next-line prefer-template
      return hour.toString() + ':' + ret.split(':')[1]
    }

    //May.の場合Mayにする
    if (ret?.split(' ')[0] === 'May.') {
      const splitRet = ret.split(' ')
      splitRet[0] = 'May'
      ret = splitRet.join(' ')
    }
    return ret
  }

  /**
   * UTC情報をストレージに保持する
   * @param {Object} res APIからのレスポンス
   * @returns {void} なし
   */
  const setUtcData = (res) => {
    const result = res.data.results
    // UTC情報をストレージに保存
    setStorage(Common.KEY.UTC_INFO, JSON.stringify(result))
  }

  /**
   * ユーザーのローカル時刻を標準時(UTC)に変換する
   * @param {string} local 変換する時刻
   * @param {string} format 変換する時刻のフォーマット情報
   * @param {string} retformat 変換した時刻のフォーマット情報
   * @return {string} 変換した時刻
   */
  const chgLocalTime2UTC = (local, format, retformat) => {
    const date = moment(local, format)
    let utcDiff = {}
    let ret = local
    try {
      // 時差取得
      utcDiff = JSON.parse(getStorage(Common.KEY.UTC_INFO))
    } catch (e) {
      //console.log(e)
    }

    if (date?.isValid() === true) {
      if (utcDiff?.code === '+') {
        // eslint-disable-next-line no-extra-parens
        ret = date
          .subtract(utcDiff.hours * 60 + utcDiff.minutes, 'minutes')
          .format(retformat)
      } else if (utcDiff?.code === '-') {
        // eslint-disable-next-line no-extra-parens
        ret = date
          .add(utcDiff.hours * 60 + utcDiff.minutes, 'minutes')
          .format(retformat)
      }
    }

    //May.の場合Mayにする
    if (ret?.split(' ')[0] === 'May.') {
      const splitRet = ret.split(' ')
      splitRet[0] = 'May'
      ret = splitRet.join(' ')
    }
    return ret
  }

  /**
   * Tracing画面の進捗のローカル時間を取得
   * @param {Object} progressObj 進捗の時間Object(APIから取得)
   * @return {Array} 進捗のローカル時間の配列
   */
  const getTracingLocalDateArr = (progressObj) => {
    return Common.TRACING_STATUS_ARR.map((targetStatus) => {
      const UTCDate = progressObj[targetStatus]
      // UTCDateがある場合ローカル時間に変換、nullの場合はそのままnullをセット
      return UTCDate
        ? chgUTC2LocalTime(UTCDate, 'YYYY/MM/DD HH:mm:ss', 'MMM. DD')
        : UTCDate
    })
  }

  /**
   * 全ステータスから対象外の配列のステータスを取り除く
   * @param {Array} excludedArr 取り除く対象配列
   * @return {Array} 残りの配列
   */
  const getExcludedStatusArr = (excludedArr) =>
    Common.STATUS_ARR.filter((STATUS) => !excludedArr.includes(STATUS))

  /**
   * orgDstFlgから対象の文字列を取得
   * @param {Number} orgDstFlg Common.ORG_DST_FLGの値
   * @return {String} orgDstFlgに対応する文字列
   */
  const getOrgDstStr = (orgDstFlg) => {
    const [orgDstStr] = Object.entries(Common.ORG_DST_FLG).find(
      ([, ORG_DST_FLG]) => ORG_DST_FLG === orgDstFlg
    )
    return orgDstStr
  }

  /**
   * @param {Boolean} documentDeficiencyFlg 書類不備フラグ
   * @param {Number} statusFlg 自分がステータス変更をしたかの判別フラグ
   * @return {Number} DocumentDeficiencyの場合：16  CargoDamageの場合：17
   */
  const getSpecialStatus = (documentDeficiencyFlg, statusFlg) => {
    if (statusFlg && documentDeficiencyFlg) {
      return Common.STATUS.DOCUMENT_DEFICIENCY.ID
    } else if (statusFlg && !documentDeficiencyFlg) {
      return Common.STATUS.CARGO_DAMAGE.ID
    } else {
      return ''
    }
  }

  /**
   * バックエンドからの情報をもとに機能用のステータスを取得
   * @param {*} documentDeficiencyFlg 書類不備フラグ
   * @param {*} changeableFlg ステータスを変更可能かの判別フラグ
   * @param {*} displayStatus 表示用のステータス
   * @return {*} DocumentDeficiencyの場合：16  CargoDamageの場合：17 どちらでもない場合は表示用のステータス
   */
  const getFunctionStatus = (
    documentDeficiencyFlg,
    changeableFlg = 1,
    displayStatus
  ) => {
    if (documentDeficiencyFlg) {
      return Common.STATUS.DOCUMENT_DEFICIENCY.ID
    } else if (!changeableFlg && !documentDeficiencyFlg) {
      return Common.STATUS.CARGO_DAMAGE.ID
    } else {
      return displayStatus
    }
  }

  /**
   * BulkSearch画面のCGO Ready Date欄・Preferred Dateで言語・日付を元にフォーマットを取得
   * @param {*} selectedDate 選択されている日付
   * @param {*} lang 選択されている言語
   * @return {String} フォーマットタイプ
   */
  const getFormatType = (selectedDate, lang) => {
    let formatType = ''
    if (selectedDate !== null) {
      if (selectedDate.toString().split(' ')[1] === 'May') {
        formatType = 'MAY_EN'
      } else {
        formatType = lang.toUpperCase()
      }
      return formatType
    }
  }

  /**
   * IDかTEXTからCommonの該当Objを返す
   * @param {String} target commonObjのID or TEXT
   * @param {Object} commonObj Commonの定数 ex)Common.STATUS
   * @return {Object} Commonの該当Obj
   */
  // eslint-disable-next-line no-extra-parens
  const getCommonObj = (target, commonObj) =>
    Object.entries(commonObj).find(
      (statusArr) => statusArr[1].ID === target || statusArr[1].TEXT === target
    )[1]

  /**
   * 他の余計なファイルパスを表示しないため追加 最後のファイル名のみ取得
   * @param {String} pdfName ファイル名
   * @return {String} 最後のファイル名
   */
  const getFileName = (pdfName) =>
    pdfName ? pdfName.split('/').slice(-1)[0] : ''

  const separationFileName = (fileName) => {
    if (fileName) {
      const extension = fileName.split('.').slice(-1)[0]
      const originalName = fileName.replace(`.${extension}`, '')
      return {
        extension,
        originalName,
      }
    } else {
      return {
        extension: null,
        originalName: null,
      }
    }
  }
  /**
   * 小数第2位桁まで表示
   * @param {Number} value 変換したい値
   * @return {String} 小数第2位を含めた文字列
   */
  const changeFloat = (value) => {
    if (!isNaN(parseInt(value))) {
      return Number.parseFloat(value).toFixed(2)
    } else {
      return value
    }
  }

  /**
   * 小数第2位桁まで表示(デザイン崩れの可能性があるので分担)
   * @param {Number} value 変換したい値
   * @return {String} 小数第2位を含めた文字列
   */
  const changeFloatSub = (value) => changeFloat(value)

  /**
   * ダイアログを開いているかを取得
   * @return {Boolean} 開いている場合true
   */
  const getIsDialog = () =>
    document.querySelector('.MuiDialog-root:not(#error-dialog)')

  /**
   *日付選択時に取得したvalueの表示切り分け
   * @param {String} dateValue 選択した日付
   * @param {String} lang 言語
   * @param {String} formatType formatType
   * @param {Event} setFormatType formatTypeを設定する関数
   * @return {String} 言語別に整形した日付
   */
  const splitDateValue = (dateValue, lang, formatType, setFormatType) => {
    if (dateValue) {
      let newDate = ''
      let splitData = ''
      if (lang === Common.LANGUAGE.EN) {
        if (
          dateValue.split(' ')[0] === 'May.' &&
          (formatType === 'EN' || formatType === undefined)
        ) {
          // May.の場合 '.'が不要なので'May.'と'May'を置き換え
          splitData = dateValue.split(' ')
          splitData[0] = splitData[0].replace('May.', 'May')
          newDate = splitData.join(' ')
          setFormatType('MAY_EN')
        } else if (
          dateValue.split(' ')[0] !== 'May' &&
          formatType === 'MAY_EN'
        ) {
          //3文字でMayでは無い場合、'.'を追加
          splitData = dateValue.split(' ')
          // eslint-disable-next-line prefer-template
          splitData[0] = splitData[0] + '.'
          newDate = splitData.join(' ')
          setFormatType('EN')
        } else {
          // monthの形を整える必要がない場合newDateに取得した日付をそのまま渡す
          newDate = dateValue
        }
      }
      return newDate
    }
  }

  /**
   * formatに渡すフォーマット
   * @param {String} selectedDate 選択した日付
   * @param {String} lang 言語
   * @param {String} formatType フォーマットタイプ
   * @return {String} format
   */
  const getFormat = (selectedDate, lang, formatType) => {
    if (selectedDate !== null) {
      let format = ''
      if (lang === Common.LANGUAGE.JA) {
        format = 'yyyy/MM/dd'
      } else if (lang === Common.LANGUAGE.EN) {
        // 日付入力時（May）にformatTypeがMAY_ENの場合formatを'MMM dd, yyyy'にする
        if (formatType === 'MAY_EN') {
          format = 'MMM dd, yyyy'
        } else {
          format = 'MMM. dd, yyyy'
        }
      }
      return format
    } else {
      return 'MMM. dd, yyyy'
    }
  }

  /**
   * 単語を複数形に変換する
   * @param {String} word -変換したい単語
   * @param {Number} pcs 個数
   * @return {String} 複数形に変換後の単語
   */
  const convertToPlural = (word, pcs) => {
    //例外の単語一覧
    const exceptionWordArr = [
      { word: 'Box of Nature Wood', pluralWord: 'Boxes of Nature Wood' },
      { word: 'TYPE A PACKAGE', pluralWord: 'TYPE A PACKAGES' },
      { word: 'TYPE B(U) PACKAGE', pluralWord: 'TYPE B(U) PACKAGES' },
    ]

    //単数の場合はそのまま返す
    pcs = parseInt(pcs)
    if (pcs === 1 || isNaN(pcs) || word === '') {
      return word
    }

    //例外の単語か判定
    const exceptionWordObj = exceptionWordArr.find(
      (exceptionWord) => exceptionWord.word === word
    )
    // eslint-disable-next-line no-extra-boolean-cast
    if (Boolean(exceptionWordObj)) {
      return exceptionWordObj.pluralWord
    }

    //複数形に変換
    if (
      word.endsWith('s') ||
      word.endsWith('sh') ||
      word.endsWith('ch') ||
      word.endsWith('o') ||
      word.endsWith('x')
    ) {
      // eslint-disable-next-line prefer-template
      return word + 'es'
    } else if (word.endsWith('f') || word.endsWith('fe')) {
      return word.replaceAll('fe?$', 'ves')
    } else if (word.match('[^aiueo]y$')) {
      return word.replaceAll('y$', 'ies')
    } else {
      // eslint-disable-next-line prefer-template
      return word + 's'
    }
  }

  /**
   * AccountInfo画面の登録済みメールアドレス取得(本番環境のみ)
   * @param {Array} editData 編集中データ一覧
   * @param {Number} index 現在操作中データのindex
   * @return {Array} 登録済みメールアドレス配列 (本番環境以外は空の配列)
   */
  const getAccountInfoEmailArr = (editData, index) => {
    if (env === 'production') {
      // 本番環境のみ適用
      return editData
        .filter((v, i) => !v.delFlg && i !== index)
        .map((value) => value.email)
    } else {
      return []
    }
  }

  /**
   * fileNameの末尾に(XX.XX)の形で日付があるかチェック
   * @param {String} fileName ファイル名
   * @return {Boolean} 末尾に日付がある場合true, ない場合false
   */
  const checkFileNameEndDate = (fileName) => {
    const filenameStr = separationFileName(fileName).originalName
    const matchStr = /\(\d{2}.\d{2}\)$/
    return matchStr.test(filenameStr)
  }

  /**
   * HAWB, MAWBプレビュー画面用
   * OtherChargesを3つに分ける
   * @param {Array} dueAgent 代理店への支払い
   * @param {Array} dueCarrier 航空会社への支払い
   * @return {Array} 分割したOtherCharges配列
   */
  const separateOtherCharges = (dueAgent, dueCarrier) => {
    const CONSTANTS = {
      DUE_AGENT_CODE: 'A',
      DUE_CARRIER_CODE: 'C',
      ARRAY_MAX_LENGTH: 4,
    }
    const newDueAgent = dueAgent
      .filter((v) => !v.delFlg)
      .map((otherCharge) => ({
        ...otherCharge,
        displayOtherCode: otherCharge.otherCode + CONSTANTS.DUE_AGENT_CODE,
      }))
    const newDueCarrier = dueCarrier
      .filter((v) => !v.delFlg)
      .map((otherCharge) => ({
        ...otherCharge,
        displayOtherCode: otherCharge.otherCode + CONSTANTS.DUE_CARRIER_CODE,
      }))
    const otherChargeArr = [...newDueAgent, ...newDueCarrier]
    const separatedOtherChargeArr = [[], [], []]
    let count = 0
    let splitIndex = 0
    otherChargeArr.forEach((otherCharge, index) => {
      separatedOtherChargeArr[splitIndex].push({
        ...otherCharge,
        id: index + 1,
      })
      count++
      if (count === CONSTANTS.ARRAY_MAX_LENGTH) {
        splitIndex++
        count = 0
      }
    })
    return separatedOtherChargeArr
  }

  /**
   * 配列をオブジェクトに変換
   * @param {Array} keyArr key配列
   * @param {Array} valueArr value配列
   * @return {Object} {key: value}の形で返却
   */
  const convertArrToObj = (keyArr, valueArr) => {
    // 開発用に必要なためESLintのエラーを無視
    // eslint-disable-next-line no-console
    if (keyArr.length !== valueArr.length && env === 'development')
      console.log('keyとvalueの数が違います。')
    // [[key, value], ...]の形に変換
    const keyAndValueArr = keyArr.map((key, index) => [key, valueArr[index]])
    // objectに変換し返却
    return Object.fromEntries(keyAndValueArr)
  }

  /**
   * Document画面用、選択したAuthorizationのみ表示
   * @param {Array} authorizationArr Authorizationの配列
   * @return {String} 表示用のAuthorizationの文字列
   */
  const createAuthorization = (authorizationArr) => {
    const selectAuthorizationArr = authorizationArr.filter(
      (authorization) => authorization.selectFlg === Common.SELECT_FLG.ON
    )
    const selectAuthorizationStr = selectAuthorizationArr
      .map((authorization) => authorization.authorization)
      .join('\r\n')
    return selectAuthorizationArr.length > 0 ? selectAuthorizationStr : '-'
  }

  /**
   * Dateオブジェクトの正常、異常(InvalidDate等)をチェック
   * @param {Object} dateObj Dateオブジェクト
   * @return {Boolean} 正常値の場合true,異常値の場合false
   */
  const checkDateObj = (dateObj) => {
    // Dateオブジェクトかどうか確認
    const isDataObj =
      Object.prototype.toString.call(dateObj) === '[object Date]'
    return isDataObj ? !Number.isNaN(dateObj.getHours()) : false
  }

  /**
   * 2桁の0埋め
   * @param {number} num 数値
   * @param {boolean} fillZeroFlg 0埋めフラグ
   * @return {String} 2桁の0埋めの文字列
   */
  // eslint-disable-next-line prefer-template
  const fillZero = (num, fillZeroFlg) =>
    fillZeroFlg ? num : ('0' + num).slice(-2)

  /**
   * 現在時刻の取得
   * @param {String} format フォーマットYYYY MM DD hh mm ss
   * @param {boolean} fillZeroFlg 0埋めフラグ
   * @return {String} formatの形に合わせた現在時刻
   */
  const getCurrentDate = (format, fillZeroFlg) => {
    // 各現在時刻取得
    const date = new Date()
    const year = date.getFullYear()
    const month = fillZero(date.getMonth() + 1, fillZeroFlg)
    const day = fillZero(date.getDate(), fillZeroFlg)
    const hour = fillZero(date.getHours(), fillZeroFlg)
    const minute = fillZero(date.getMinutes(), fillZeroFlg)
    const second = fillZero(date.getSeconds(), fillZeroFlg)

    // formatの形に合わせて時間に置き換える
    const currentDate = format
      .replace(/YYYY/g, year)
      .replace(/MM/g, month)
      .replace(/DD/g, day)
      .replace(/hh/g, hour)
      .replace(/mm/g, minute)
      .replace(/ss/g, second)
    return currentDate
  }

  /**
   * Dateオブジェクトを指定のフォーマットに変換
   * @param {Date} date Dateオブジェクト
   * @param {String} format 変換したいフォーマットYYYY MM DD hh mm ss
   * @param {Boolean} fillZeroFlg 0埋めフラグ
   * @return {String} formatの形に合わせた時刻
   */
  const convertFormatDate = (date, format, fillZeroFlg) => {
    const isDataObj = Object.prototype.toString.call(date) === '[object Date]'
    if (isDataObj) {
      // 各時刻取得
      const year = fillZeroFlg
        ? date.getFullYear()
        : `0000${date.getFullYear()}`.slice(-4)
      const month = fillZero(date.getMonth() + 1, fillZeroFlg)
      const day = fillZero(date.getDate(), fillZeroFlg)
      const hour = fillZero(date.getHours(), fillZeroFlg)
      const minute = fillZero(date.getMinutes(), fillZeroFlg)
      const second = fillZero(date.getSeconds(), fillZeroFlg)

      // formatの形に合わせて時間に置き換える
      const convertDate = format
        .replace(/YYYY/g, year)
        .replace(/MM/g, month)
        .replace(/DD/g, day)
        .replace(/hh/g, hour)
        .replace(/mm/g, minute)
        .replace(/ss/g, second)
      return convertDate
    } else {
      return date
    }
  }

  /**
   * 保存用ファイル名に変更
   * @param {String} fileName ファイル名
   * @param {String} fileKey オブジェクトのプロパティ
   * @return {String} fileKey_現在時刻.拡張子
   */
  const convertFileName = (fileName) => {
    const { extension, originalName } = separationFileName(fileName)
    const currentDate = getCurrentDate('MM.DD')
    return `${originalName}(${currentDate}).${extension}`
  }

  /**
   * Tracing画面のファイル名重複チェック
   * @param {Object} stateDocumentObj state.Document
   * @param {String} fileName 現在ファイル名
   * @param {Boolean} isMawb MAWBの場合true
   * @param {Number} accIndex アコーディオンindex
   * @return {Boolean} 同じアコーディオン内同日、同名のファイルがいる場合true
   */
  const checkDuplicateFileName = (
    stateDocumentObj,
    fileName,
    isMawb,
    accIndex
  ) => {
    const getFileNameStr = (documentObj) =>
      documentObj?.delFlg ? '' : getFileName(documentObj.documentPathName)
    const getCovertFileName = (documentObj) =>
      convertFileName(documentObj?.name)
    // stateから全ファイル名を取得 ファイル名は'元ファイル名(日付).拡張子'で統一
    const { deleteData, mawbFiles, hawbDeleteData, hawbFiles } =
      stateDocumentObj
    const mawbDocumentNameArr = deleteData.map(getFileNameStr)
    const addMawbDocumentNameArr = mawbFiles.map(getCovertFileName)
    const hawbDocumentNameArr = hawbDeleteData.map((documentArr) =>
      documentArr.map(getFileNameStr)
    )
    const addHawbDocumentNameArr = hawbFiles.map((documentArr) =>
      documentArr.map(getCovertFileName)
    )
    const allMawbDocumentNameArr = [
      ...mawbDocumentNameArr,
      ...addMawbDocumentNameArr,
    ]
    const allHawbDocumentNameArr = hawbDocumentNameArr.map(
      (documentArr, index) => [...documentArr, ...addHawbDocumentNameArr[index]]
    )

    // 現在ファイル名に日付を付けて重複が無いか確認
    const currentDocumentNameArr = isMawb
      ? allMawbDocumentNameArr
      : allHawbDocumentNameArr[accIndex]
    const convertedFileName = convertFileName(fileName)
    const isDuplicate = currentDocumentNameArr.includes(convertedFileName)
    return isDuplicate
  }

  /**
   * 対象AWB以外のドキュメントを操作しているか確認
   * @param {Object} stateDocument state.Document
   * @param {Number} currentHawbIndex HAWBで確認を行う場合の対象index
   * @return {Boolean} 操作中の場合true
   */
  const checkEditOtherAwb = (stateDocument, currentHawbIndex) => {
    /**
     * MAWBのドキュメントを操作しているか確認
     * @param {Object} stateDocument state.Document
     * @return {Boolean} 操作中の場合true
     */
    const checkOperateMawb = (stateDocument) => {
      const { mawbFiles, deleteData, mawbInitData } = stateDocument
      /** MAWBDocumentsアコーディオンでドキュメントの追加があったかチェック */
      const isAddMawb = mawbFiles.length > 0
      /** MAWBDocumentアコーディオン内で更新・削除が行われたかチェック */
      const isEditMawb = deleteData.some((edit, index) => {
        return JSON.stringify(edit) !== JSON.stringify(mawbInitData[index])
      })

      return isAddMawb || isEditMawb
    }
    /**
     * HAWBのドキュメントを操作しているか確認
     * @param {Object} stateDocument state.Document
     * @param {Number} currentHawbIndex 対象のHAWBのindex
     * @return {Boolean} 操作中の場合true
     */
    const checkOperateHawb = (stateDocument, currentHawbIndex) => {
      const { hawbFiles, hawbDeleteData, hawbInitData } = stateDocument

      /** ドキュメントの追加があったかチェック */
      const isAddHawb =
        hawbFiles
          .filter((_, hawbIndex) => currentHawbIndex !== hawbIndex)
          .flat().length > 0
      /** ドキュメントの編集があったかチェック */
      const isEditHawb = hawbDeleteData.some((editDataObj, hawbIndex) => {
        // 現在操作中のHAWBは対象外
        if (currentHawbIndex === hawbIndex) return false
        return editDataObj.some((editObj, documentIndex) => {
          const isChangeDocument =
            JSON.stringify(editObj) !==
            JSON.stringify(hawbInitData[hawbIndex][documentIndex])
          return isChangeDocument
        })
      })
      return isAddHawb || isEditHawb
    }

    // currentHawbIndexを受け取っていない場合はMAWBを対象AWBとみなす
    const isTargetMawb = currentHawbIndex === undefined

    // 対象AWBがMAWBの場合はMAWBの確認を無視する
    const isOperateMawb = isTargetMawb ? false : checkOperateMawb(stateDocument)
    const isOperateHawb = checkOperateHawb(stateDocument, currentHawbIndex)

    return isOperateMawb || isOperateHawb
  }

  /**
   * 日付が一致しているかミリ秒に変換して確認
   * @param {String} dateStr 日付文字列
   * @param {String} compareDateStr 比較する日付文字列
   * @return {Boolean} ミリ秒が一致している場合true
   */
  const checkDateMatch = (dateStr, compareDateStr) => {
    /** 日付をDateオブジェクトに変換 */
    const newDateObj = new Date(dateStr)
    /** 比較する日付をDateオブジェクトに変換 */
    const newCompareDateObj = new Date(compareDateStr)

    /** 比較結果 */
    const matchResult = newDateObj.getTime() === newCompareDateObj.getTime()

    return matchResult
  }

  /**
   * 入力されているレートタイプを取得
   * @param {Object} rateDataObj レート情報オブジェクト
   * @return {String} gen,dg,iceのいずれか 該当しない場合は空文字
   */
  const getInputRateType = (rateDataObj) =>
    Object.values(Common.RATE.TYPE).find((rateType) => rateDataObj[rateType]) ??
    ''

  /**
   * 文字列をキャメルケースに変換する
   * @param {Array} arr 文字列の配列
   * @returns {String} キャメルケースに変換後の文字列
   */
  const arrToCamelCaseStr = (arr) => {
    return arr
      .map((word, index) => {
        if (index === 0) {
          return word.toLowerCase()
        } else {
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        }
      })
      .join('')
  }

  /**
   * key配列で指定した値をObjectから取り出す
   * @param {Array} targetKeyArr 抽出したいKey配列
   * 未定義の場合の初期値を設定したい場合 {key:,initVal:}で渡す
   * @param {Object} targetObj 抽出したいObject
   * @return {Array} [[key, value],...]の配列
   */
  const getValFromObject = (targetKeyArr, targetObj) =>
    targetKeyArr.map((targetKey) => {
      // Objectの場合は未定義の場合の初期値を設定
      const isObj = typeof targetKey === 'object'
      const { key, initVal } = targetKey
      const targetKeyStr = isObj ? key : targetKey
      const targetKeyVal = targetObj[targetKeyStr] ?? initVal
      return [targetKeyStr, targetKeyVal]
    })

  /**
   * アカウント情報から配列で指定したものを抽出
   * @param {Array} apiDataArr アカウント情報APIの配列
   * @param {Array} displayKeyArr 抽出したいKey配列
   * @return {Array} 抽出したアカウント情報
   */
  const getShowAccountDataArr = (apiDataArr, displayKeyArr) =>
    apiDataArr
      .map((accountInfoObj, dataIndexNum) => {
        // 表示に必要なものを取り出し
        const displayValArr = getValFromObject(displayKeyArr, accountInfoObj)
        // 紐づけ用dataIndexNumを追加して、Object化して返却
        return Object.fromEntries([
          ...displayValArr,
          ['dataIndexNum', dataIndexNum],
        ])
      })
      // 削除済は取り除く
      .filter(({ delFlg }) => !delFlg)

  /**
   * AccountInfoTableのテーブルデータの初期値を設定
   * @param {Object} action dispatchで受け取ったaction
   * @param {Array} keyArr 抽出したいKey配列
   * @return {Object} テーブルデータの初期値オブジェクト
   */
  const initCreateTable = (action, keyArr) => {
    const { data, amount } = action
    // 初期設定なのでどちらもAPIの値のdata
    const editData = data
    const apiDataArr = data
    // 表示用のデータ抽出後、検索
    const showAccountDataArr = getShowAccountDataArr(editData, keyArr)
    const { showData } = searchSortKeyData(
      showAccountDataArr,
      '',
      '',
      '',
      amount
    )
    return {
      showData,
      editData,
      contentNum: amount,
      apiDataArr,
    }
  }

  /**
   * AccountInfoTableのテーブルデータにデータを追加
   * @param {Object} action dispatchで受け取ったaction
   * @param {Object} state reducer内で保管しているstate
   * @return {Object} 追加後のテーブルデータのオブジェクト
   */
  const addTable = (action, state) => {
    const { row } = action
    const { editData } = state
    // 編集データに追加
    const addEditDataArr = [...editData, row]
    return {
      ...state,
      editData: addEditDataArr,
    }
  }

  /**
   * AccountInfoTableのテーブルデータを更新
   * @param {Object} action dispatchで受け取ったaction
   * @param {Object} state reducer内で保管しているstate
   * @return {Object} 更新後のテーブルデータのオブジェクト
   */
  const updateTable = (action, state) => {
    const { row, index } = action
    const { editData } = state
    // 指定されたindexの値のみ更新
    const updateEditDataArr = editData.map((editDataObj, editDataIndex) => {
      const isTargetIndex = editDataIndex === index
      return isTargetIndex ? row : editDataObj
    })
    return {
      ...state,
      editData: updateEditDataArr,
    }
  }

  /**
   * AccountInfoTableのテーブルデータを削除
   * @param {Object} action dispatchで受け取ったaction
   * @param {Object} state reducer内で保管しているstate
   * @return {Object} 削除後のテーブルデータのオブジェクト
   */
  const deleteTable = (action, state) => {
    const { index } = action

    const { editData } = state
    const deleteEditDataArr = editData
      .map((editDataObj, editDataIndex) => {
        const isTargetIndex = editDataIndex === index
        if (isTargetIndex) {
          // 更新時間が無い場合は追加データ
          const isAddData = !editDataObj.updatedAt
          // 追加データを削除する場合は空に、それ以外はdelFlgをONに変更
          return isAddData
            ? null
            : { ...editDataObj, delFlg: Common.DEL_FLG.ON }
        } else {
          return editDataObj
        }
      })
      .filter((v) => v)
    return {
      ...state,
      editData: deleteEditDataArr,
    }
  }

  /**
   * AccountInfoTableのテーブルデータを検索
   * @param {Object} action dispatchで受け取ったaction
   * @param {Object} state reducer内で保管しているstate
   * @param {Array} keyArr 抽出したいKey配列
   * @return {Object} 検索後のテーブルデータのオブジェクト
   */
  const searchTable = (action, state, keyArr) => {
    const { authority, word } = action
    const { editData, contentNum } = state
    // 表示用のデータ抽出後、検索
    const showAccountDataArr = getShowAccountDataArr(editData, keyArr)
    const searchKey = authority ? 'authorityFlg' : ''
    const searchValue = authority
      ? Common.AUTHORITY_FLG.ON
      : Common.AUTHORITY_FLG.OFF
    const { showData } = searchSortKeyData(
      showAccountDataArr,
      searchKey,
      searchValue,
      word,
      contentNum
    )
    return {
      ...state,
      showData,
    }
  }

  /**
   * '!'を改行コードに置換する
   * @param {String} string 置換する文字列
   * @returns {String} 改行後の文字列
   */
  const replaceNewLine = (string) => {
    const regExp = new RegExp(Common.CONVERSION_SYM.NEW_LINE.SYM, 'g')
    return string.replace(regExp, '\r\n')
  }
  /**
   * UTCと現在言語から現地時刻表示に変換
   * @param {String} utcStr UTC時間
   * @param {*} i18n useTranslationのi18n
   * @return {String} 現地時刻
   */
  const convertUtcToLocalStr = (utcStr, i18n) => {
    // 時差変換
    const localTimeStr = chgUTC2LocalTime(
      utcStr,
      'YYYY/MM/DD HH:mm:ss',
      'YYYY-MM-DD'
    )
    const lang = getLang(i18n)
    // 言語に合わせて表示を変更
    return convertDate(localTimeStr, lang)
  }

  /** ↓ 表示用に変換する関数一覧 ************************************************************************************************************************/
  // [[key, value], ...]で返してObject.fromEntriesでObjectに変換する
  const convertValidityStr = (apiDataObj, cellKey, _, i18n) => {
    const { startDate, finishDate } = apiDataObj
    const lang = getLang(i18n)
    const validityStartStr = convertDate(startDate, lang)
    const validityEndStr = convertDate(finishDate, lang)
    const validityStr = `${validityStartStr} - ${validityEndStr}`
    return [cellKey, validityStr]
  }
  const convertConsigneeStr = (apiDataObj, cellKey) => {
    const { companyName } = apiDataObj
    return [cellKey, companyName]
  }
  const convertSetFwdrStr = (apiDataObj, cellKey) => {
    const { setForwarderArr } = apiDataObj
    const isUnspecified = !setForwarderArr[0]
    const setForwarderStr = isUnspecified
      ? 'Unspecified'
      : setForwarderArr.join('/')
    return [cellKey, setForwarderStr]
  }
  const convertPicStr = (apiDataObj, cellKey) => {
    const { picName } = apiDataObj
    return [cellKey, picName]
  }
  const convertDeadLineStr = (apiDataObj, cellKey, _, i18n) => {
    const { firstDead } = apiDataObj
    // 表示用のデータに変換
    const lang = getLang(i18n)
    const deadlineStr = convertDate(firstDead, lang)
    return [cellKey, deadlineStr]
  }
  const convertNotifyStr = (apiDataObj, cellKey) => {
    const { shipperReadFlg } = apiDataObj
    const hasNotify = shipperReadFlg === Common.READ_FLG.UNREAD
    return [cellKey, hasNotify]
  }

  const convertDetailsStr = (_apiDataObj, cellKey) => {
    return [cellKey, '']
  }
  const convertCodeStr = (apiDataObj, cellKey) => {
    const { fwdrthreeCode } = apiDataObj
    return [cellKey, fwdrthreeCode]
  }
  const convertCompanyStr = (apiDataObj, cellKey) => {
    const { companyName } = apiDataObj
    return [cellKey, companyName]
  }
  const convertCheckBoxStr = (apiDataObj, cellKey) => {
    const { checkBox } = apiDataObj
    return [cellKey, checkBox]
  }
  const convertShipperStr = (apiDataObj, cellKey) => {
    const { shipper } = apiDataObj
    return [cellKey, shipper]
  }
  const convertFwdrNotifyStr = (apiDataObj, cellKey) => {
    const { forwarderReadFlg } = apiDataObj
    const hasNotify = forwarderReadFlg === Common.READ_FLG.UNREAD
    return [cellKey, hasNotify]
  }
  const convertFwdrDetailsStr = (_apiDataObj, cellKey) => {
    return [cellKey, '']
  }
  /** ↑ 表示用に変換する関数一覧 ************************************************************************************************************************/

  /** ↓ 並び替え用に変換する関数一覧 ************************************************************************************************************************/

  const convertSortNotify = (shipperReadFlg) => {
    return shipperReadFlg === Common.READ_FLG.UNREAD ? 1 : 0
  }

  const convertSortDetails = (shipperDraftFlg) => {
    return shipperDraftFlg
      ? Common.DETAILS_BUTTON.DRAFT
      : Common.DETAILS_BUTTON.CHECK
  }
  /** ↑ 並び替え用に変換する関数一覧 ************************************************************************************************************************/

  // 表用の表示、並び替え変換処理
  const convertTables = {
    // 表示用変換処理
    display: {
      validity: convertValidityStr,
      shipper: convertShipperStr,
      consignee: convertConsigneeStr,
      setFwdr: convertSetFwdrStr,
      pic: convertPicStr,
      deadLine: convertDeadLineStr,
      notify: convertNotifyStr,
      details: convertDetailsStr,
      checkBox: convertCheckBoxStr,
      fwdrthreeCode: convertCodeStr,
      companyName: convertCompanyStr,
      fwdrNotify: convertFwdrNotifyStr,
      fwdrDetails: convertFwdrDetailsStr,
    },
    // 並び替え用変換処理
    sort: {
      notify: convertSortNotify,
      details: convertSortDetails,
    },
  }

  /**
   * ShipperAddressに表示用配列
   * @param {String} address アドレス
   * @param {String} companyName 会社名
   * @param {String} tel 電話番号
   * @param {String} firstName 名
   * @param {String} lastName 性
   * @return {Array} 行ごとの配列
   */
  const createAddressArr = (address, companyName, tel, firstName, lastName) => {
    const firstRowStr = companyName
    const secondRowStr = address
    const thirdRowFrontStr = `Tel. ${tel}`
    const thirdRowBackStr =
      firstName && lastName ? ` Attn: ${firstName} ${lastName}` : ''
    const thirdRowStr = `${thirdRowFrontStr}${thirdRowBackStr}`

    return [firstRowStr, secondRowStr, thirdRowStr]
  }

  /**
   * BidInfo画面遷移時のページ間データ作成(selectFwdrObj)
   * @param {Object} currentSelectFwdrObj ページ遷移時の現在のselectFwdrObj
   * @param {Object} state BidInfo画面で管理しているstate
   * @return {Object} setFwdrFlgのみ更新したselectFwdrObj
   */
  const createSelectFwdrObj = (currentSelectFwdrObj, state) => {
    const { setFwdrFlg } = state.SetFwdr
    return {
      ...currentSelectFwdrObj,
      setFwdrFlg,
    }
  }

  /**
   * BidInfo画面遷移時のページ間データ作成(BidInfo)
   * @param {Object} currentBidInfoObj ページ遷移時の現在のBidInfoObj
   * @param {Object} state BidInfo画面で管理しているstate
   * @return {Object} 各入力値を更新したBidInfo
   */
  const createBidInfoObj = (currentBidInfoObj, state) => {
    const { picArr } = state.Pic
    const { consigneeInfoObj } = state.Consignee
    const { startDate, finishDate } = state.Validity
    const { date: firstDead } = state.Deadline
    const { specialNoteArr } = state.SpecialNotes

    // 先頭のdelFlgのついていないPICのみmainPersonFlgを立てる
    let mainPersonFlgCount = 0
    const mainPersonFlgPicArr = picArr.map((picObj) => {
      const { delFlg } = picObj
      if (delFlg || mainPersonFlgCount) {
        return picObj
      } else {
        mainPersonFlgCount++
        return {
          ...picObj,
          mainPersonFlg: 1,
        }
      }
    })

    const newBidInfoObj = {
      ...currentBidInfoObj,
      picArr: mainPersonFlgPicArr,
      ...consigneeInfoObj,
      startDate,
      finishDate,
      firstDead,
      specialNoteArr,
      shipperReadFlg: true,
    }
    return newBidInfoObj
  }

  /**
   * BidInfo画面遷移時のページ間データ作成(BidInfo)
   * @param {Object} PageData ページ遷移時のページ間データ
   * @param {Object} state BidInfo画面で管理しているstate
   * @return {Object} 各入力値を更新したBidInfo
   */
  const createShipperBidInfoStateObj = (PageData, state) => {
    // BidInfo画面で操作するデータのみ更新
    const bidInfoObj = createBidInfoObj(PageData.bidInfoObj, state)
    const selectFwdrObj = createSelectFwdrObj(PageData.selectFwdrObj, state)

    const stateObj = {
      ...PageData,
      bidInfoObj,
      selectFwdrObj,
    }

    return stateObj
  }

  /**
   * @param {Object} state reducer内で保管しているstate
   * @param {*} history react-router-domのuseHistory
   * @param {String} pathStr 遷移先のパス
   * @return {void} 荷主BidInfo画面のページ遷移
   */
  const shipperBidInfoTransitionPage = (state, history, pathStr) => {
    const { PageData } = state

    const stateObj = createShipperBidInfoStateObj(PageData, state)

    const clickEventObj = {
      path: pathStr,
      state: stateObj,
    }
    clickEvent(clickEventObj, history)
  }

  /**
   * 入札の排他チェック
   * @param {Array} updatedAtArr backend\routes\shipper\bidUpdatedAt.jsから取得した一覧
   * @param {*} history react-router-domのuseHistory
   * @return {Object} エラー状態とエラーコード
   */
  const checkUpdatedAtBid = async (updatedAtArr, history) => {
    // リクエスト
    const req = {
      updatedAtList: updatedAtArr,
    }
    const { userType } = getLoginUserData()
    const path =
      userType === Common.USER_TYPE.FWDR
        ? ApiPaths.FORWARDER.UPDATEDAT_CHECK_BID
        : ApiPaths.SHIPPER.UPDATEDAT_CHECK_BID
    // 排他処理情報を取得
    const apiInfoArr = [{ key: 'bidMgmtArr', method: 'post', path, req }]
    const resObj = await execApiAsync(apiInfoArr)

    // エラーコード
    const { bidMgmtArr } = resObj
    const errorCode = bidMgmtArr.data.info.ErrorCode

    // エラー状態
    const isSuccessful = checkApiResponseObj(resObj, history)
    const isError = !isSuccessful

    // 使用しない共通ダイアログが表示されるので非表示に変更
    document.getElementById('error-dialog').hidden = true

    return {
      errorCode,
      isError,
    }
  }

  /**
   * 更新日時確認情報の基本形を作成
   * @param {String} tableName 更新チェックを行うテーブル名
   * @param {String} tableIdColumn テーブルを特定するIDのカラム名
   * @param {Number} tableId テーブルを特定するID
   * @param {String} updatedAt 更新日時
   * @return {Object} 更新日時確認情報オブジェクト
   */
  const createUpdatedAtObj = (tableName, tableIdColumn, tableId, updatedAt) => {
    return {
      tableName,
      tableIdColumn,
      tableId,
      updatedAt,
    }
  }

  /**
   * 現在ページの通知、一時保存状態をセット
   * @param {Array} apiDataArr APIで取得した値一覧
   * @param {Array} showDataArr 表示用データ一覧
   * @param {String} notifyApiPath 通知確認用APIのパス
   * @param {String} draftApiPath 一時保存状態確認用APIのパス
   * @param {Function} dispatch useContextのdispatch
   * @param {*} history react-router-domのuseHistory
   * @return {void}
   */
  const setNotifyAndDraft = (
    apiDataArr,
    showDataArr,
    notifyApiPath,
    draftApiPath,
    dispatch,
    history
  ) => {
    ;(async () => {
      // 現在表示しているデータのbidId
      const bidIdArr = showDataArr.map(({ apiIndexNum }) => {
        const { bidId } = apiDataArr[apiIndexNum]
        return bidId
      })
      // 通知情報を取得
      const req = { params: { bidIdArrStr: JSON.stringify(bidIdArr) } }
      const apiInfoArr = [
        { key: 'bidMgmtNotify', method: 'get', path: notifyApiPath, req },
        { key: 'bidMgmtDraft', method: 'get', path: draftApiPath, req },
      ]
      const resObj = await execApiAsync(apiInfoArr)
      const isApiSuccessful = checkApiResponseObj(resObj, history)
      if (isApiSuccessful) {
        const notifyBidIdArr = resObj.bidMgmtNotify.data.results
        const draftBidIdArr = resObj.bidMgmtDraft.data.results
        dispatch({
          type: TABLES.SET_NOTIFY,
          notifyBidIdArr,
          draftBidIdArr,
        })
      }
    })()
  }

  /**
   * 未読通知を既読にする
   * @param {Number} bidId 押下した入札のbidId
   * @param {*} history react-router-domのuseHistory
   * @returns {Boolean} 更新に成功したか
   */
  const updateUnreadNotify = async (bidId, history) => {
    const req = {
      bidId,
    }
    const { userType } = getLoginUserData()
    const path =
      userType === Common.USER_TYPE.FWDR
        ? ApiPaths.FORWARDER.ALREADY_READ_NOTIFY_BID
        : ApiPaths.SHIPPER.ALREADY_READ_NOTIFY_BID
    const alreadyReadResObj = await execApiAsync([
      { key: 'notifyArr', method: 'post', path, req },
    ])
    return checkApiResponseObj(alreadyReadResObj, history)
  }

  /**
   * bidIdの一覧からapiIndexNumを特定
   * @param {Array} bidIdArr bidId一覧
   * @param {Array} apiDataArr APIのデータ一覧
   * @return {Array} apiIndexNum一覧
   */
  const getApiIndexNumArr = (bidIdArr, apiDataArr) =>
    bidIdArr.map((targetBidId) =>
      // bidIdからapiIndexNumを特定
      apiDataArr.findIndex(({ bidId }) => bidId === targetBidId)
    )

  /** @type {Object} ダイアログのdispatchを行う動作を共通で定義 */
  const dispatchDialog = {
    // ダイアログのOKボタン押下時の動作
    // APIを投げて成功のダイアログを開く
    clickOk: async (dispatch, history, path, req) => {
      const apiInfoArr = [{ key: 'apiResultObj', method: 'put', path, req }]
      const resObj = await execApiAsync(apiInfoArr)
      const isApiSuccessful = checkApiResponseObj(resObj, history)
      if (isApiSuccessful) {
        const { apiResultObj } = resObj
        dispatch({
          type: DIALOG.OPEN_SUCCESS,
          response: apiResultObj.data.results,
        })
      }
    },
  }

  /**
   * ダイアログを閉じる
   * @param {Event} event - onCloseのイベント
   * @param {Boolean} isDialogBackGround - ダイアログ背景をクリックしているかの判定
   * @param {Number} pageX - pageX
   * @returns {void}
   */
  const handleCloseCancelData = (event, isDialogBackGround, pageX) =>
    isDialogBackGround ? event.target.clientWidth - pageX < 0 : false

  /**
   * クリックした瞬間の位置を保存
   * @param {Number} pageX - pageX
   * @param {Object} setPageX - setPageX
   * @returns {void}
   */
  const savePageXPosition = (pageX, setPageX) => setPageX(pageX)

  return {
    checkPageTransition,
    setStorage,
    getStorage,
    getAllStorage,
    clickEvent,
    getLang,
    searchSortData,
    searchSortDefaultData,
    searchSortNotifyData,
    searchSortKeyData,
    getErrorMessage,
    getLoginUserData,
    getLoginUserType,
    openPdfFile,
    convertDate,
    callbackFunc,
    checkAccessPage,
    topTranslation,
    masterTopTranslation,
    profileCheckFunc,
    addprofitCheckFunc,
    awbNumberCheckFunc,
    getDate,
    createVia,
    checkRegisterPage,
    chgUTC2LocalTime,
    getTracingLocalDateArr,
    chgLocalTime2UTC,
    getSignInMessage,
    errorHandling,
    resetAllStorage,
    getExcludedStatusArr,
    getOrgDstStr,
    getSpecialStatus,
    getFunctionStatus,
    splitData,
    getFormatType,
    getCommonObj,
    closeErrorDialog,
    openErrorDialog,
    hideScroll,
    showScroll,
    changePageContentDisplay,
    getFileName,
    changeFloat,
    changeFloatSub,
    getIsDialog,
    splitDateValue,
    getFormat,
    convertToPlural,
    separationFileName,
    getAccountInfoEmailArr,
    checkApiResponseObj,
    execApiAsync,
    checkFileNameEndDate,
    separateOtherCharges,
    convertArrToObj,
    createAuthorization,
    checkDateObj,
    convertFileName,
    checkDuplicateFileName,
    checkEditOtherAwb,
    checkDateMatch,
    getInputRateType,
    arrToCamelCaseStr,
    getValFromObject,
    initCreateTable,
    addTable,
    updateTable,
    deleteTable,
    searchTable,
    replaceNewLine,
    convertTables,
    convertFormatDate,
    createAddressArr,
    shipperBidInfoTransitionPage,
    checkUpdatedAtBid,
    createUpdatedAtObj,
    setNotifyAndDraft,
    updateUnreadNotify,
    getApiIndexNumArr,
    setUtcData,
    convertUtcToLocalStr,
    dispatchDialog,
    handleCloseCancelData,
    savePageXPosition,
  }
}

export default CommonFunc()
