import { Grid } from '@material-ui/core'
import React from 'react'

import Paths from '../../../../../constants/Paths'
import BackButton from '../../../../common/buttons/BackButton'

/**
 * @return {JSX} Backボタン
 */
const BackButtonLightFwdrBookingList = () => {
  return (
    <Grid container justify="center">
      <Grid item>
        <BackButton path={Paths.FWDR.TOP} />
      </Grid>
    </Grid>
  )
}

export default BackButtonLightFwdrBookingList
