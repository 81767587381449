import React, { useContext } from 'react'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'

import EditButton from '../../../../common/buttons/EditButton'
import AppContext from '../../../../../contexts/AppContext'
import { PIC } from '../../../../../actions'

/**
 * @param {Object} props 以下
 * @param {Number} userId PICのユーザID
 * @return {JSX} PICEditボタンコンポーネント
 */
const EditButtonPicBidInfo = ({ userId }) => {
  const { dispatch } = useContext(AppContext)

  /**
   * ダイアログを表示
   * @return {void}
   */
  const dialogOpen = () => {
    dispatch({
      type: PIC.OPEN_EDIT_DIALOG,
      editUserId: userId,
    })
  }

  return (
    <Grid container item xs={1} justify="center" alignItems="center">
      <Grid item>
        <EditButton onClick={dialogOpen} />
      </Grid>
    </Grid>
  )
}

EditButtonPicBidInfo.propTypes = {
  userId: PropTypes.number.isRequired,
}

export default EditButtonPicBidInfo
