import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

import ConstantsLightAirlineBookingList from '../ConstantsLightAirlineBookingList'
import TableCell from '../../../../common/table/TableCell'
import TableRow from '../../../../common/table/TableRow'
import AppContext from '../../../../../contexts/AppContext'

const useStyles = makeStyles((theme) => ({
  tableHead: theme.commonTableHeader,
}))

/**
 * 定数から作成するヘッダーコンポーネント(1列分)
 * 変更したい場合は定数ConstantsLightAirlineBookingListを操作する
 * @return {JSX} ヘッダーコンポーネント(1列分)
 */
const HeaderLightAirlineBookingList = () => {
  const { tableHead } = useStyles()
  const { t } = useTranslation()
  const { state } = useContext(AppContext)

  const { GSA_TABLE_ARR, AIRLINE_TABLE_ARR } = ConstantsLightAirlineBookingList
  const tableArr = state.Gsa ? GSA_TABLE_ARR : AIRLINE_TABLE_ARR

  return (
    <TableRow>
      {tableArr.map(({ headerKey }, index) => (
        <TableCell key={index} className={tableHead}>
          {t(headerKey)}
        </TableCell>
      ))}
    </TableRow>
  )
}

export default HeaderLightAirlineBookingList
