import React from 'react'
import { useTranslation } from 'react-i18next'
import { FormHelperText } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import Theme from '../../common/theme/Theme'
import AuthenticationTableContainer from '../../common/table/AuthenticationTableContainer'

const useStyles = makeStyles(() => ({
  helper: Theme.signup.helper,
}))

/**
 * @returns {JSX} - 必須入力ラベルコンポーネント
 */
const RequireDisp = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <AuthenticationTableContainer>
      <FormHelperText className={classes.helper}>
        *{t('D501V0022')}
      </FormHelperText>
    </AuthenticationTableContainer>
  )
}

export default RequireDisp
