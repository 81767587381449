const ConstantsBidInfo = {
  // PICのObjectのkeyとポップアップのタイトル表示
  PIC: {
    DISPLAY_ARR: [
      { key: 'name', titleKey: 'D601V0010' },
      { key: 'position', titleKey: 'D301V0023' },
      { key: 'department', titleKey: 'D301V0024' },
      { key: 'email', titleKey: 'D301V0010' },
      { key: 'tel', titleKey: 'D301V0011' },
    ],
    LIMIT_COUNT: 5,
  },
}

export default ConstantsBidInfo
