// Display + 画面名
const DisplayFwdrConfirmBid = {
  // 画面ID + 文言ID
  D115V0001: {
    en: 'CONFIRM BID',
    ja: '入札確定',
  },
  D115V0002: {
    en: 'Owner',
    ja: 'オーナー',
  },
  D115V0003: {
    en: 'Representative',
    ja: '代表',
  },
  D115V0004: {
    en: 'Validity',
    ja: '有効期間',
  },
  D115V0005: {
    en: 'Set FWDR',
    ja: '混載業者を設定',
  },
  D115V0006: {
    en: 'Currency',
    ja: '通貨',
  },
  D115V0007: {
    en: '1st Deadline',
    ja: '一次締め切り',
  },
  D115V0008: {
    en: '2st Deadline',
    ja: '二次締め切り',
  },
  D115V0009: {
    en: '3rd Deadline',
    ja: '三次締め切り',
  },
  D115V0010: {
    en: 'Special Notes',
    ja: '特記事項',
  },
  D115V0011: {
    en: '1. Air Freight (JL)',
    ja: '航空貨物便(JL)',
  },
  D115V0012: {
    en: 'ORG/DST',
    ja: '出発/到着',
  },
  D115V0013: {
    en: 'Via',
    ja: 'Via',
  },
  D115V0014: {
    en: 'TTL Wgt.',
    ja: 'TTL Wgt.',
  },
  D115V0015: {
    en: 'TTL Vol.',
    ja: 'TTL Vol.',
  },
  D115V0016: {
    en: 'SHC',
    ja: 'SHC',
  },
  D115V0017: {
    en: '+45kg',
    ja: '+45kg',
  },
  D115V0018: {
    en: '+100kg',
    ja: '+100kg',
  },
  D115V0019: {
    en: '+300kg',
    ja: '+300kg',
  },
  D115V0020: {
    en: '+500kg',
    ja: '+500kg',
  },
  D115V0021: {
    en: '+1,000kg',
    ja: '+1,000kg',
  },
  D115V0022: {
    en: '2. Air Freight (NH)',
    ja: '航空貨物便(NH)',
  },
  D115V0023: {
    en: '3. Air Freight (UA)',
    ja: '航空貨物便(UA)',
  },
  D115V0024: {
    en: 'Panasonic Corporation',
    ja: 'Panasonic Corporation',
  },
  D115V0025: {
    en: '1006, Oaza Kadoma, Kadoma-shi, Osaka 571-8501, Japan',
    ja: '1006, Oaza Kadoma, Kadoma-shi, Osaka 571-8501, Japan',
  },
  D115V0026: {
    en: 'Tel. +81-6-6908-1121',
    ja: 'Tel. +81-6-6908-1121',
  },
  D115V0027: {
    en: 'Konosuke Matsushita',
    ja: 'Konosuke Matsushita',
  },
  D115V0028: {
    en: 'kmatsushita@panasonic.co.jp / +81-X-XXXX-XXXX',
    ja: 'kmatsushita@panasonic.co.jp / +81-X-XXXX-XXXX',
  },
  D115V0029: {
    en: 'Aug 1, 2020 to Jan 31, 2021',
    ja: '2020/8/1~2021/1/31',
  },
  D115V0030: {
    en: 'DZK/KNJ/EIK/NEC/YLK/KWE/HEI',
    ja: 'DZK/KNJ/EIK/NEC/YLK/KWE/HEI',
  },
  D115V0031: {
    en: 'JPY - Japanese Yen',
    ja: 'JPY-日本円',
  },
  D115V0032: {
    en: 'July 31,2020',
    ja: '2020/7/31',
  },
  D115V0033: {
    en: 'August 31,2020',
    ja: '2020/8/31',
  },
  D115V0034: {
    en: '・Please follow the schedule strictly.',
    ja: '・スケジュールは厳守してください。',
  },
  D115V0035: {
    en: '・We’ll inform you as soon as we decide.',
    ja: '・決定次第お知らせします。',
  },
  D115V0036: {
    en: 'Norikuni Inaba',
    ja: 'Norikuni Inaba',
  },
  D115V0037: {
    en: 'Account Executive',
    ja: '取引先担当',
  },
  D115V0038: {
    en: 'ninaba@jal.co.jp / +81-X-XXXX-XXXX',
    ja: 'ninaba@jal.co.jp / +81-X-XXXX-XXXX',
  },
  D115V0039: {
    en: 'XXX',
    ja: 'XXX',
  },
  D115V0040: {
    en: 'kmatsushita@panasonic.co.jp / +81-X-XXXX-XXXX',
    ja: 'kmatsushita@panasonic.co.jp / +81-X-XXXX-XXXX',
  },
  D115V0041: {
    en: 'NRT/MNL',
    ja: 'NRT/MNL',
  },
  D115V0042: {
    en: 'NRT/LON',
    ja: 'NRT/LON',
  },
  D115V0043: {
    en: 'NRT/ORD',
    ja: 'NRT/ORD',
  },
  D115V0044: {
    en: '-',
    ja: '-',
  },
  D115V0045: {
    en: 'FRA',
    ja: 'FRA',
  },
  D115V0046: {
    en: 'ANC',
    ja: 'ANC',
  },
  D115V0047: {
    en: '1,000kg - 3000kg',
    ja: '1,000kg - 3000kg',
  },
  D115V0048: {
    en: '300m3 - 500m3',
    ja: '300m3 - 500m3',
  },
  D115V0049: {
    en: 'GEN',
    ja: 'GEN',
  },
  D115V0050: {
    en: 'DG',
    ja: 'DG',
  },
  D115V0051: {
    en: 'ICE',
    ja: 'ICE',
  },
  D115V0052: {
    en: '600',
    ja: '600',
  },
  D115V0053: {
    en: '540',
    ja: '540',
  },
  D115V0054: {
    en: '510',
    ja: '510',
  },
  D115V0055: {
    en: '480',
    ja: '480',
  },
  D115V0056: {
    en: '850',
    ja: '850',
  },
  D115V0057: {
    en: '820',
    ja: '820',
  },
  D115V0058: {
    en: '780',
    ja: '780',
  },
  D115V0059: {
    en: '720',
    ja: '720',
  },
  D115V0060: {
    en: '680',
    ja: '680',
  },
  D115V0061: {
    en: '650',
    ja: '650',
  },
  D115V0062: {
    en: '620',
    ja: '620',
  },
  D115V0063: {
    en: '580',
    ja: '580',
  },
  D115V0064: {
    en: '530',
    ja: '530',
  },
  D115V0065: {
    en: '440',
    ja: '440',
  },
  D115V0066: {
    en: 'Please click the box if you agree to be unable to cancel/change the offered lanes during the BID term.',
    ja: '入札期間中に提供されたレーンをキャンセルまたは変更できないことに同意される場合は、ボックスをクリックしてくださ い。',
  },
  D115V0067: {
    en: 'BID',
    ja: '入札',
  },
  D115V0068: {
    en: '1006, Oaza Kadoma,',
    ja: '大字門真 1006',
  },
  D115V0069: {
    en: 'Kadoma-shi,',
    ja: '門真市',
  },
  D115V0070: {
    en: 'Osaka 571-8501,',
    ja: '571-8501 大阪府',
  },
  D115V0071: {
    en: 'Japan',
    ja: '日本',
  },
  D115V0072: {
    en: 'kmatsushita@panasonic.co.jp',
    ja: 'kmatsushita@panasonic.co.jp',
  },
  D115V0073: {
    en: '+81-X-XXXX-XXXX',
    ja: '+81-X-XXXX-XXXX',
  },
  D115V0074: {
    en: 'Manager - Lighting Dept.',
    ja: 'マネージャー - Lighting Dept.',
  },
  D115V0075: {
    en: 'Supervisor - Lighting Dept.',
    ja: 'スーパバイザー - Lighting Dept.',
  },
  D115V0076: {
    en: 'Leader - Lighting Dept.',
    ja: 'リーダー - Lighting Dept.',
  },
}
// Display + 画面名
export default DisplayFwdrConfirmBid
