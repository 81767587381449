import Common from '../../../../constants/Common'
import CommonFunc from '../../../common/function/CommonFunc'

const convertDisplayFunc = (type) => CommonFunc.convertTables.display[type]

const ConstantsBidMgmt = {
  // テーブル作成に必要な値
  TABLE_ARR: [
    {
      headerKey: '3rd0007',
      cellKey: 'validityStr',
      convertFunc: convertDisplayFunc('validity'),
    },
    {
      headerKey: '3rd0003',
      cellKey: 'setFwdrStr',
      convertFunc: convertDisplayFunc('setFwdr'),
    },
    {
      headerKey: 'D302V0033',
      cellKey: 'picStr',
      convertFunc: convertDisplayFunc('pic'),
    },
    {
      headerKey: '3rd0004',
      cellKey: 'deadLineStr',
      convertFunc: convertDisplayFunc('deadLine'),
    },
    {
      headerKey: '3rd0006',
      cellKey: 'notifyStr',
      convertFunc: convertDisplayFunc('notify'),
      cellCategory: Common.CELL_CATEGORY.NOTIFY,
    },
    {
      headerKey: 'D103V0062',
      cellKey: 'detailsStr',
      convertFunc: convertDisplayFunc('details'),
      cellCategory: Common.CELL_CATEGORY.DETAILS,
    },
  ],
  // valueはすべてCommon.jsのものを使うようにして並び替えの動作をCommonFunc.jsに追記する
  SORT_ITEM_ARR: [
    {
      sortTerm: Common.SORT_TERMS.CREATED_AT,
      wordKey: 'D005V0003',
      sortKey: 'createdAt',
    },
    {
      sortTerm: Common.SORT_TERMS.CREATED_AT_DESENDING,
      wordKey: 'D102V0054',
      sortKey: 'createdAt',
    },
    {
      sortTerm: Common.SORT_TERMS.VALIDITY_ASCENDING,
      wordKey: '3rd0007',
      sortKey: 'startDate',
    },
    {
      sortTerm: Common.SORT_TERMS.CONSIGNEE_ASCENDING,
      wordKey: 'D014V0015',
      sortKey: 'companyName',
    },
    {
      sortTerm: Common.SORT_TERMS.FWDR_ORDER,
      wordKey: '3rd0003',
      sortKey: 'setForwarderArr',
    },
    {
      sortTerm: Common.SORT_TERMS.PIC_ASCENDING,
      wordKey: 'D302V0033',
      sortKey: 'picName',
    },
    {
      sortTerm: Common.SORT_TERMS.DEADLINE_ASCENDING,
      wordKey: '3rd0004',
      sortKey: 'firstDead',
    },
  ],
}

export default ConstantsBidMgmt
