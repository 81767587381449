import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'

import AppContext from '../../../../contexts/AppContext'
import AccordionLightBookingDetails from '../Accordion/AccordionLightBookingDetails'
import EditButtonFlightLightBookingDetails from './EditButtonFlightLightBookingDetails'
import FlightInfoLightBookingDetails from './FlightInfoLightBookingDetails'
import FlightPopup from './FlightPopup'
import InterLineLightBookingDetails from './InterLineLightBookingDetails'

/**
 * @param {Object} props 下記内容
 * @param {Boolean} props.editable インターライン欄の編集可否
 * @param {Boolean} props.isShow インターライン欄の表示有無
 * @return {JSX} Flight欄
 */
const FlightLightBookingDetails = ({ editable, isShow }) => {
  const { t } = useTranslation()
  const { state, dispatch } = useContext(AppContext)

  const { flightChangeInfoA, flightChangeInfoB, flightChangeInfoC } =
    state.Flight

  return useMemo(() => {
    const createEditButton = (valueName, interLine) => {
      return (
        <EditButtonFlightLightBookingDetails
          valueName={valueName}
          interLine={interLine}
          dispatch={dispatch}
        />
      )
    }
    return (
      <AccordionLightBookingDetails label={t('D015V0008')}>
        <Grid container justify="space-between" alignItems="center" spacing={1}>
          {state.Flight.flightArr.map((flightInfo, index) => (
            <FlightInfoLightBookingDetails
              key={index}
              flightInfo={flightInfo}
            />
          ))}
          {isShow ? (
            <InterLineLightBookingDetails value={flightChangeInfoA} />
          ) : null}
          {editable
            ? createEditButton('flightChangeInfoA', flightChangeInfoA)
            : null}
          {isShow ? (
            <InterLineLightBookingDetails value={flightChangeInfoB} />
          ) : null}
          {editable
            ? createEditButton('flightChangeInfoB', flightChangeInfoB)
            : null}
          {isShow ? (
            <InterLineLightBookingDetails value={flightChangeInfoC} />
          ) : null}
          {editable
            ? createEditButton('flightChangeInfoC', flightChangeInfoC)
            : null}
        </Grid>
        {editable ? <FlightPopup /> : null}
      </AccordionLightBookingDetails>
    )
  }, [t, state.Flight, editable]) //eslint-disable-line
}
FlightLightBookingDetails.propTypes = {
  editable: PropTypes.bool.isRequired,
  isShow: PropTypes.bool.isRequired,
}
export default FlightLightBookingDetails
