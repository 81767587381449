import React, { useContext } from 'react'

import Common from '../../../constants/Common'
import AppContext from '../../../contexts/AppContext'
import { PAGING, TABLES, TABS } from '../../../actions'
import CommonTabs from '../../common/tab/CommonTabs'

/**
 * @return {JSX} Light版JHClientReg画面のタブコンポーネント
 */
const ClientTabsLightJhClientReg = () => {
  const { state, dispatch } = useContext(AppContext)
  const { pageAmountArr } = state.Tables
  const selectTabIndex = state.Tabs

  /**
   * タブ変更時のイベント
   * stateのタブとテーブルデータを更新し1ページ目に戻る
   * @param {Event} _e 不使用
   * @param {Number} index 変更後のタブindex
   * @returns {void}
   */
  const handleChange = (_e, index) => {
    dispatch({
      type: TABS.SET,
      select: index,
    })
    dispatch({
      type: TABLES.SET,
      select: index,
    })
    dispatch({
      type: PAGING.SET,
    })
  }

  // タブ設定
  const { SHIPPER, FWDR, CARRIER } = Common.CLIENT_TAB
  const tabSettingObj = {
    [SHIPPER]: {
      label: 'D401V0003',
      notificationCount: pageAmountArr[SHIPPER],
      girdSetting: { xs: 2 },
    },
    [FWDR]: {
      label: 'D401V0058',
      notificationCount: pageAmountArr[FWDR],
      girdSetting: { xs: 2 },
    },
    [CARRIER]: {
      label: 'D401V0005',
      notificationCount: pageAmountArr[CARRIER],
      girdSetting: { xs: 2 },
    },
  }
  const tabSettingArr = Object.values(tabSettingObj)

  return (
    <CommonTabs
      tabSettingArr={tabSettingArr}
      selectTabIndex={selectTabIndex}
      onChange={handleChange}
    />
  )
}

export default ClientTabsLightJhClientReg
