import React, { useContext, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import PropTypes from 'prop-types'

import Theme from '../../common/theme/Theme'
import AppContext from '../../../contexts/AppContext'
import { LIGHT_REGISTER_NEW_ACCOUNT } from '../../../actions'
import AuthenticationTableContainer from '../../common/table/AuthenticationTableContainer'

const useStyles = makeStyles(() => ({
  inputLabel: Theme.signup.inputLabel,
  root: Theme.signup.root,
  container: Theme.signup.container,
  autoComp: Theme.signup.autoComp,
}))

/**
 * @param {Object} props - props
 * @returns {JSX} - 空港選択コンポーネント
 */
const AirportSelect = (props) => {
  const { state, dispatch } = useContext(AppContext)
  const classes = useStyles()
  const { label } = props

  return useMemo(() => {
    /**
     * 空港を更新
     * @param {*} _e - event
     * @param {*} value - value
     * @returns {void}
     */
    const updateAirport = (_e, value) => {
      dispatch({
        type: LIGHT_REGISTER_NEW_ACCOUNT.AIRPORT.UPDATE_TEXT,
        value: value?.airportId ?? '',
      })
    }

    return (
      <AuthenticationTableContainer>
        <form noValidate>
          <Autocomplete
            options={state.SelectData.airportArr}
            getOptionLabel={(option) => option.airport}
            className={`${classes.root} ${classes.autoComp}`}
            onChange={updateAirport}
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{
                  classes: {
                    root: classes.inputLabel,
                    focused: 'focused',
                    shrink: 'shrink',
                  },
                }}
                className={classes.root}
                label={label}
                margin="normal"
                fullWidth
                required
                variant="outlined"
              />
            )}
          />
        </form>
      </AuthenticationTableContainer>
    )
  }, [
    dispatch,
    state.SelectData.airportArr,
    classes.autoComp,
    classes.inputLabel,
    classes.root,
    label,
  ])
}

AirportSelect.propTypes = {
  label: PropTypes.string.isRequired,
}

export default AirportSelect
