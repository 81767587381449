import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Badge from '@material-ui/core/Badge'

import CardButton from '../../../common/buttons/CardButton'
import ApiPaths from '../../../../constants/ApiPaths'
import CommonFunc from '../../../common/function/CommonFunc'
import restFacade from '../../../../actions/rest-facade'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    [theme.breakpoints.up('md')]: {
      height: `calc(100vh - ${theme.header.height} * 2 - 1rem)`,
      minHeight: '310px',
    },
    '& .MuiBadge-badge': {
      height: '60px',
      minWidth: '60px',
      borderRadius: '40px',
      paddingLeft: '10px',
      fontWeight: 'bold',
      fontSize: '30px',
      padding: '0 10px',
      right: 60,
    },
    '& .MuiBadge-root': {
      width: '100%',
    },
    '& .MuiBadge-colorSecondary': {
      color: theme.palette.info.main,
      backgroundColor: theme.palette.required,
    },
  },
  center: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '257px',
      margin: '0 auto',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
    },
  },
}))

/**
 * @param {Object} props - props
 * @returns {*} - ClientReg. 画面のボタン
 */
const ClientRegButtons = (props) => {
  const classes = useStyles()
  const reactHistory = useHistory()
  const [clientCount, setClientCount] = useState({
    signUp: '',
    deletAccount: '',
  })

  const setClientCountData = (res) => {
    setClientCount(res.data.results)
  }

  useEffect(() => {
    const setData = (res, callback) => {
      CommonFunc.callbackFunc(res, callback, reactHistory)
    }

    restFacade.get(ApiPaths.JH.CLIENT_COUNT, (res) =>
      setData(res, setClientCountData)
    )
  }, []) // eslint-disable-line

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item md xs={12}>
        <div className={classes.center}>
          {clientCount.NewAccountRequesting === '0' ? (
            <CardButton path={props.signuppath} />
          ) : (
            <Badge
              color="secondary"
              badgeContent={clientCount.NewAccountRequesting}
            >
              <CardButton path={props.signuppath} />
            </Badge>
          )}
        </div>
      </Grid>
      <Grid item md xs={12}>
        <div className={classes.center}>
          {clientCount.DeleteAccountRequest === '0' ? (
            <CardButton path={props.deletepath} />
          ) : (
            <Badge
              color="secondary"
              badgeContent={clientCount.DeleteAccountRequest}
            >
              <CardButton path={props.deletepath} />
            </Badge>
          )}
        </div>
      </Grid>
    </Grid>
  )
}

export default ClientRegButtons
