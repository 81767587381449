import { GET_TABLE_DATA } from '../../../actions'
import CommonFunc from '../../../components/common/function/CommonFunc'
import Common from '../../../constants/Common'

/**
 * 表示・並び替え用のデータに変換
 * @param {Array} apiDataArr APIから受け取った貨物情報配列
 * @param {*} i18n useTranslationのi18n
 * @returns {Array} 表示用貨物情報配列
 */
const convertShowCargoDataArr = (apiDataArr, i18n) =>
  apiDataArr.map((cargoDataObj) => {
    // 表示用のデータに変換
    // コンポーネント側を見ればどのように変換しているか分かるのでそのまま持ってくる
    const {
      showDate,
      searchDate,
      name,
      lastName,
      companyName,
      title,
      date,
      id,
      area,
      readFlg,
      sender,
      updatedAt,
      index,
      page,
    } = cargoDataObj

    const showDateStr = `${CommonFunc.convertDate(showDate.split(' ')[0], CommonFunc.getLang(i18n))} ${showDate.split(' ')[1]}`
    const nameStr = `${name}_${lastName}`
    const companyNameStr = companyName
    const titleStr = title

    return {
      // 表示用
      showDateStr,
      nameStr,
      companyNameStr,
      titleStr,

      // 並び替え用
      date,
      id,
      area,
      readFlg,
      sender,
      companyName,
      searchDate,
      updatedAt,
      index,
      page, //FilterbyのAir Cargo Sharing Digital Platformの検索に使用(page===nullで検索)
    }
  })

/**
 * APIの値、検索ワード、並び替えから保持しておく表データとAPIを作成
 * @param {Object} action dispatchで受け取ったaction
 * @param {Object} state ここで保持している表データとAPI
 * @return {Object} 表データとAPI
 */
const createTableData = (action, state) => {
  // 先に分割代入しておく
  const { data = undefined, sort, i18n } = action
  // 新規のAPIデータがない場合は最初に受け取ったAPIの値を渡す
  const apiDataArr = data ?? state.apiDataArr

  // 表示・並び替え用にデータを変換した後、検索、並び替え
  const showCargoDataArr = convertShowCargoDataArr(apiDataArr, i18n)
  const searchSortData = CommonFunc.searchSortNotifyData(
    showCargoDataArr,
    sort,
    Common.SORT_TERMS.CREATED_AT,
    'date'
  )

  // 表示用データとAPIの素の値を保持
  return {
    ...searchSortData,
    apiDataArr,
  }
}

/**
 * 表データとAPIを保持
 * @param { Object } [state = {}] 表データとAPI
 * @param { Object } action 表データの検索に必要な値
 * @return { Object } 表データとAPI
 */
const Tables = (state = {}, action) => {
  switch (action.type) {
    case GET_TABLE_DATA:
      return createTableData(action, state)
    default:
      return state
  }
}

export default Tables
