import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import TextField from '../../../common/text/TextField'
import { TABLES, ERROR } from '../../../../actions'

const useStyles = makeStyles(() => ({
  ttlWidth: {
    minWidth: 100,
  },
  remarkWidth: {
    minWidth: 200,
  },
  padding: {
    '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"]': {
      padding: '0px 4px',
    },
  },
}))

/**
 * 表に表示するインプットコンポーネント
 * @param {Number} cellRequiredObj showDataのインデックス
 * @param {Function} validation バリデーション関数
 * @param {string} placeholder placeholder
 * @param {Function} valueCheckFunc エラー判定用の関数
 * @param {boolean} isDisabled 非活性
 * @return {JSX} インプットボックス
 */
const TextFieldCellBidDetails = (
  cellRequiredObj,
  validation,
  placeholder,
  valueCheckFunc,
  isDisabled
) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [error, setError] = useState(false)
  const [value, setValue] = useState('')
  const { index, cellKey, cellValue, apiRequiredObj, relationValue } =
    cellRequiredObj
  const { dispatch } = apiRequiredObj

  /**テキストフィールドのID */
  const id = `textField${cellKey}${index}`

  const isRemark = cellKey === 'remark'
  const style = isRemark ? classes.remarkWidth : classes.ttlWidth

  /**
   * 入力されたときのイベント
   * バリデーションチェックを行う
   * @param {string} value 入力値
   * @returns {void} なし
   */
  const onChange = (value) => {
    const { checkResult } = validation(value)
    const validationResult = value === '' ? false : checkResult
    const valueCheck = valueCheckFunc
      ? valueCheckFunc(value, relationValue)
      : false
    const isError = validationResult || valueCheck
    const isChangeError = error !== isError
    setError(isError)
    setValue(value)
    if (isChangeError) {
      onChangeError(isError)
    }
  }

  /**
   * エラー状態を変更する
   * @param {boolean} error エラー
   * @returns {void} なし
   */
  const onChangeError = (error) => {
    dispatch({
      type: ERROR.SET,
      name: id,
      error,
    })
  }

  /**
   * フォーカスが外れたときのイベント
   * stateを入力値で更新する
   * @returns {void} なし
   */
  const onBlur = () => {
    dispatch({
      type: TABLES.EDIT,
      index,
      cellKey,
      value,
    })
  }

  useEffect(() => {
    onChange(cellValue)
  }, [cellValue, relationValue]) // eslint-disable-line

  return (
    <TextField
      className={style}
      id={id}
      error={error}
      value={value}
      placeholder={t(placeholder)}
      defalutvalue={cellValue}
      onChange={(event) => onChange(event.target.value)}
      onBlur={onBlur}
      disabled={isDisabled}
    />
  )
}

TextFieldCellBidDetails.propTypes = {
  cellRequiredObj: PropTypes.object.isRequired,
  validation: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  valueCheckFunc: PropTypes.func,
  isDisabled: PropTypes.bool,
}

export default TextFieldCellBidDetails
