import React from 'react'

import SearchSort from '../../../../commonUseContext/SearchSort'

/**
 * @return {JSX} サーチコンポーネント
 */
const SearchLightCarAirFreight = () => {
  return <SearchSort noGridContainer />
}

export default SearchLightCarAirFreight
