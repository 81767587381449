import React, { useMemo } from 'react'

import SearchSort from '../../../../commonUseContext/SearchSort'

/**
 * @return {JSX} サーチコンポーネント
 */
const SearchLightJhClientList = () => {
  return useMemo(() => <SearchSort />, [])
}

export default SearchLightJhClientList
