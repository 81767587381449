import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import TextField from '../../common/text/TextField'
import CommonValidation from '../../common/function/CommonValidation'
import {
  UPDATE_FWDR_MAIL,
  UPDATE_FWDR_TEL,
  UPDATE_CAR_MAIL,
  UPDATE_CAR_TEL,
  UPDATE_FWDR_MAIL_INPUTERR,
  UPDATE_FWDR_TEL_INPUTERR,
  UPDATE_CAR_MAIL_INPUTERR,
  UPDATE_CAR_TEL_INPUTERR,
} from '../../../actions'

const useStyles = makeStyles((theme) => ({
  head: theme.textBoldBLU,
  textBlk: {
    ...theme.textNormalBLK,
    marginLeft: '1rem',
    wordWrap: 'break-word',
  },
  textBlu: theme.textNormalBLU,
  margin: { marginTop: '15px' },
}))

/**
 * コンポーネントを更新するか判定する
 * @param {Object} prev 前回の値
 * @param {Object} next 最新の値
 * @returns {Boolean} falseの場合更新する
 */
const areEqual = (prev, next) => {
  // 各入力欄の値を比較
  const mailValue = prev.companyinfo.mail.value === next.companyinfo.mail.value
  const telValue = prev.companyinfo.tel.value === next.companyinfo.tel.value
  const representativeFlgValue =
    prev.companyinfo.representativeFlg.value ===
    next.companyinfo.representativeFlg.value

  // 全て一致していれば更新無し
  return mailValue && telValue && representativeFlgValue
}

const ContactInfo = React.memo(({ dispatch, companyinfo, carrier }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [inputErrs, setInputErrs] = useState({ mail: false, tel: false })
  const [helperTxts, setHelperTxts] = useState({ mail: '', tel: '' })
  const { mail, tel, representativeFlg } = companyinfo
  const representativeFlgValue = representativeFlg.value

  const handleChangeMail = (event) => {
    const value = event.target.value
    // 入力チェック
    const validationCheck = CommonValidation.mail(value)
    const err = validationCheck.checkResult
    setInputErrs({ ...inputErrs, mail: err })
    setHelperTxts({ ...helperTxts, mail: validationCheck.errMsg })

    dispatch({
      type: carrier ? UPDATE_CAR_MAIL : UPDATE_FWDR_MAIL,
      mail: value,
    })

    // 入力エラーの場合、Savaボタン無効
    dispatch({
      type: carrier ? UPDATE_CAR_MAIL_INPUTERR : UPDATE_FWDR_MAIL_INPUTERR,
      inputErr: err,
    })
  }

  const handleChangeTel = (event) => {
    const value = event.target.value
    // 入力チェック
    const validationCheck = CommonValidation.telephone(value)
    const err = validationCheck.checkResult
    setInputErrs({ ...inputErrs, tel: err })
    setHelperTxts({ ...helperTxts, tel: validationCheck.errMsg })

    dispatch({
      type: carrier ? UPDATE_CAR_TEL : UPDATE_FWDR_TEL,
      tel: value,
    })

    // 入力エラーの場合、Savaボタン無効
    dispatch({
      type: carrier ? UPDATE_CAR_TEL_INPUTERR : UPDATE_FWDR_TEL_INPUTERR,
      inputErr: err,
    })
  }

  // 編集用
  const createMailTextField = () => {
    return (
      <Grid item md={4} xs={10}>
        <TextField
          id="mail"
          error={inputErrs.mail}
          helperText={t(helperTxts.mail)}
          value={mail.value}
          onChange={handleChangeMail}
        />
      </Grid>
    )
  }

  // 表示用
  const createMailTextLabel = () => {
    return (
      <Grid item md={10} xs={10}>
        <div className={classes.textBlk}>{mail.value}</div>
      </Grid>
    )
  }

  // 編集用
  const createTelTextField = () => {
    return (
      <TextField
        id="tel"
        error={inputErrs.tel}
        helperText={t(helperTxts.tel)}
        value={tel.value}
        onChange={handleChangeTel}
      />
    )
  }

  // 表示用
  const createTelTextLabel = () => {
    return <div className={classes.textBlk}>{tel.value}</div>
  }

  return (
    <div className={classes.formControl}>
      <Grid container>
        <Grid item md={10} xs={10}>
          <div className={classes.head}>{t('D301V0009')}</div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={10} xs={10}>
          <div className={classes.textBlu}>{t('D301V0010')}</div>
        </Grid>
        {representativeFlgValue ? createMailTextField() : createMailTextLabel()}
        <Grid item md={10} />
        <Grid item md={4} xs={10}>
          <div
            className={
              representativeFlgValue
                ? classes.textBlu
                : `${classes.textBlu} ${classes.margin}`
            }
          >
            {t('D301V0011')}
          </div>
          {representativeFlgValue ? createTelTextField() : createTelTextLabel()}
        </Grid>
      </Grid>
    </div>
  )
}, areEqual)

ContactInfo.displayName = 'ContactInfo'

ContactInfo.propTypes = {
  dispatch: PropTypes.func.isRequired,
  companyinfo: PropTypes.object.isRequired,
  carrier: PropTypes.bool,
}

export default ContactInfo
