import React, { useContext, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import AppContext from '../../../../../contexts/AppContext'
import CommonTableContainerNone from '../../../../common/table/CommonTableContainerNone'
import TableContentsLightCarAirFreight from './TableContentsLightCarAirFreight'
import HeaderLightCarAirFreight from './HeaderLightCarAirFreight'

const useStyles = makeStyles(() => ({
  table: {
    tableLayout: 'fixed', //指定していないカラムは等幅にする
    width: '100%',
    '& td': {
      //全てのセルに適用
      padding: '0.5rem',
    },
    '& td:last-child': {
      //右端のセルのみに適用
      padding: '0.5rem',
    },
    '& tr:not(:first-child):not(:last-child) td': {
      // 最初と最後の行以外の全てのセルに適用
      borderBottom: '0px solid !important',
    },
    '& td:nth-last-child(8)': {
      //Type欄の幅
      width: '2em',
    },
    '& td:nth-last-child(10)': {
      //ORG/DST欄の幅
      width: '7em',
    },
    '& td:nth-last-child(11)': {
      //Airline欄の幅
      width: '3em',
    },
  },
}))

/**
 * @return {JSX} 表コンポーネント(全体)
 */
const TableLightCarAirFreight = () => {
  const classes = useStyles()
  const { state } = useContext(AppContext)

  return useMemo(() => {
    return (
      <CommonTableContainerNone
        isNoResult={state.Tables.isNoResult}
        className={classes.table}
      >
        <HeaderLightCarAirFreight />
        <TableContentsLightCarAirFreight />
      </CommonTableContainerNone>
    )
  }, [state.Tables.isNoResult, classes.table])
}

export default TableLightCarAirFreight
