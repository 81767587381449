import React, { useContext } from 'react'

import CommonDialog from '../../../../common/dialog/commonDialog/CommonDialog'
import Common from '../../../../../constants/Common'
import AppContext from '../../../../../contexts/AppContext'
import ConstantsBidInfo from '../ConstantsBidInfo'
import DialogTextPicBidInfo from './DialogTextPicBidInfo'
import { PIC } from '../../../../../actions'

/**
 * @return {JSX} PICダイアログコンポーネント
 */
const DialogPicBidInfo = () => {
  const { state, dispatch } = useContext(AppContext)

  // stateの値から表示したいダイアログの内容を取得
  const isOpen = state.Pic.isOpenDialog
  const { editPicObj, selectPicObj } = state.Pic

  /**
   * ダイアログを閉じる
   * @return {void}
   */
  const closeDialog = () => {
    dispatch({
      type: PIC.CLOSE_DIALOG,
    })
  }
  /**
   * 現在編集中のPICを削除＆ダイアログを閉じる
   * @return {void}
   */
  const clickDelete = () => {
    dispatch({
      type: PIC.DELETE,
    })
  }
  /**
   * 現在編集中のPICの編集内容を適用＆ダイアログを閉じる
   * @return {void}
   */
  const clickOkUpdate = () => {
    dispatch({
      type: PIC.UPDATE,
    })
  }
  /**
   * 現在編集中のPICの編集内容を追加＆ダイアログを閉じる
   * @return {void}
   */
  const clickOkAdd = () => {
    dispatch({
      type: PIC.ADD,
    })
  }

  // セレクトボックスで誰も選択していない場合OKボタンを非活性
  const isOkDisabled = !selectPicObj.userId

  // Editボタン押下時のダイアログ設定
  const editDialogFuncObj = {
    [Common.DIALOG.BUTTON_CATEGORY.DELETE]: { onClick: clickDelete },
    [Common.DIALOG.BUTTON_CATEGORY.CANCEL]: { onClick: closeDialog },
    [Common.DIALOG.BUTTON_CATEGORY.OK]: {
      onClick: clickOkUpdate,
      disabled: isOkDisabled,
    },
  }
  // Addボタン押下時のダイアログ設定
  const addDialogFuncObj = {
    [Common.DIALOG.BUTTON_CATEGORY.CANCEL]: { onClick: closeDialog },
    [Common.DIALOG.BUTTON_CATEGORY.OK]: {
      onClick: clickOkAdd,
      disabled: isOkDisabled,
    },
  }
  const isClickEdit = Boolean(editPicObj.userId)
  const dialogFuncObj = isClickEdit ? editDialogFuncObj : addDialogFuncObj

  return (
    <CommonDialog
      open={isOpen}
      closeFunc={closeDialog}
      dialogFuncObj={dialogFuncObj}
    >
      {ConstantsBidInfo.PIC.DISPLAY_ARR.map(({ key, titleKey }) => (
        <DialogTextPicBidInfo
          key={titleKey}
          titleKey={titleKey}
          textKey={key}
        />
      ))}
    </CommonDialog>
  )
}

export default DialogPicBidInfo
