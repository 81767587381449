import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'

import AccordionLightBookingDetails from '../Accordion/AccordionLightBookingDetails'
import RadioButtonBookingLightBookingDetails from './RadioButtonBookingLightBookingDetails'
import LabelBookingLightBookingDetails from './LabelBookingLightBookingDetails'

/**
 * @param {Object} props 下記内容
 * @param {Boolean} props.disabled 非活性状態
 * @return {JSX} Cancel欄
 */
const CancelBookingLightBookingDetails = ({ disabled }) => {
  const { t } = useTranslation()

  return useMemo(() => {
    return (
      <AccordionLightBookingDetails label={t('D111V0105')}>
        <Grid container spacing={2}>
          <LabelBookingLightBookingDetails />
          <RadioButtonBookingLightBookingDetails disabled={disabled} />
        </Grid>
      </AccordionLightBookingDetails>
    )
  }, [t, disabled])
}

CancelBookingLightBookingDetails.propTypes = {
  disabled: PropTypes.bool.isRequired,
}

export default CancelBookingLightBookingDetails
