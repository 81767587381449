import React, { useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'

import Question from './Question'
import CheckBoxes from './CheckBoxes'
import TextBox from './TextBox'
import AppContext from '../../../../../contexts/AppContext'
import Constants from '../ConstantsLightDeleteAccount'
import {
  UPDATE_QUESTION1,
  INITIALIZE_QUESTION2_TEXT,
} from '../../../../../actions'
/**
 * 質問文と選択肢を表示
 * @returns {*} - Question
 */
const Q1 = () => {
  const { t } = useTranslation()
  const { state, dispatch } = useContext(AppContext)
  const { Q1 } = state
  const { texts, choices, text, check, errors, helpers } = Q1

  const onChangeCheckBox = (e, value) => {
    dispatch({
      type: UPDATE_QUESTION1,
      answer: value,
      index: e.target.value ?? null,
    })
    // Q2のテキストボックス
    if (Number(e.target.value) === Constants.DISSATISFIED && !value) {
      dispatch({
        type: INITIALIZE_QUESTION2_TEXT,
      })
    }
  }

  const onChangeTextBox = (e) => {
    dispatch({
      type: UPDATE_QUESTION1,
      answer: e.target.value,
      index: null,
    })
  }

  const isTextDisabled = choices.length > 0 && !check[choices.length - 1]

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      spacing={2}
    >
      {/* 質問文 */}
      <Question texts={texts} />
      <CheckBoxes choices={choices} onChange={onChangeCheckBox} />
      <TextBox
        onChange={onChangeTextBox}
        value={text}
        disabled={isTextDisabled}
        error={errors}
        helperText={t(helpers)}
      />
    </Grid>
  )
}

export default Q1
