import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'

import DetailButton from '../../buttons/DetailButton'

/**
 * @param {Object} props Deleteボタンに追加したい設定
 * @returns {JSX} 共通ダイアログ削除ボタンコンポーネント
 */
const DeleteButtonCommonDialog = (props) => {
  const { t } = useTranslation()

  return (
    <Grid container item xs justify="center">
      <Grid item>
        <DetailButton {...props}>{t('D004V0060')}</DetailButton>
      </Grid>
    </Grid>
  )
}

export default DeleteButtonCommonDialog
