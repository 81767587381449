import React from 'react'

import Layout from './Layout'

/**
 * レイアウトを適用します。
 * @param {Object} props - 以下
 * @param {int} airlineStepbar - AirlineStepbarを表示(数値でstep指定)
 * @param {int} stepbar - Stepbarを表示(数値でstep指定)
 * @returns {JSX.Element} - LayoutBox
 */
const LayoutBox = (props) => {
  return (
    <Layout box {...props}>
      {props.children}
    </Layout>
  )
}

export default LayoutBox
