import { LIGHT_REGISTER_NEW_ACCOUNT } from '../../../actions'
import CommonValidation from '../../../components/common/function/CommonValidation'

const initDepartment = {
  value: '',
  errorObj: {
    checkResult: false,
    errMsg: 'VALIDATION000',
  },
  require: true,
}

/**
 * 内容を保持
 * @param {Object} action - dispatchで受け取ったaction
 * @param {Object} state - state
 * @return {Object} - state
 */
const updateText = (action, state) => {
  const { value } = action
  const errorObj = CommonValidation.departmentCarrier(value)
  return {
    ...state,
    value,
    errorObj,
  }
}

/**
 * GSA用に初期化、必須をfalse
 * @param {Object} state - state
 * @return {Object} - state
 */
const updateGsa = (state) => {
  return {
    ...state,
    errorObj: {
      checkResult: false,
      errMsg: 'VALIDATION000',
    },
    value: '',
    require: false,
  }
}

/**
 * 航空会社用に必須をtrue
 * @param {Object} state - state
 * @return {Object} - state
 */
const updateCarrier = (state) => {
  return {
    ...state,
    require: true,
  }
}

/**
 * Department情報を保持
 * @param {Object} [state=initDepartment] - state
 * @param {Object} action - action
 * @return {Object} - state
 */
const Department = (state = initDepartment, action) => {
  switch (action.type) {
    case LIGHT_REGISTER_NEW_ACCOUNT.DEPARTMENT.UPDATE:
      return updateText(action, state)
    case LIGHT_REGISTER_NEW_ACCOUNT.DEPARTMENT.GSA:
      return updateGsa(state)
    case LIGHT_REGISTER_NEW_ACCOUNT.DEPARTMENT.CARRIER:
      return updateCarrier(state)
    default:
      return state
  }
}

export default Department

export { initDepartment }
