import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import ApiPaths from '../../../../constants/ApiPaths'
import Paths from '../../../../constants/Paths'
import CommonFunc from '../../../common/function/CommonFunc'
import CommonValidation from '../../../common/function/CommonValidation'
import PasswordField from './PasswordField'
import Expired from './Expired'
import PrimaryButton from '../../../common/buttons/PrimaryButton'

import restFacade from '../../../../actions/rest-facade'
const useStyles = makeStyles((theme) => ({
  // eslint-disable-next-line camelcase
  text_wht: {
    fontFamily: theme.typography.fontFamily,
    color: theme.fontColor.secondary,
    fontSize: 16,
    lineHeight: 1.2,
  },
  okButton: {
    width: theme.buttonSize.width.large,
    height: theme.buttonSize.height.large,
    border: 'none',
    marginTop: '3rem',
    marginBottom: '3rem',
  },
  required: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.fontSize.paging,
    color: theme.palette.required,
    marginTop: '50px',
  },
}))

let passReset = () => {}
let successReset = () => {}
// let displayTranslation = () => { }
const Password = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const [key, setKey] = useState('')
  const [newPass, setNewpass] = useState('')
  const [retypePass, setRetypePass] = useState('')
  const [error, setError] = useState(false)
  const [expired, setExpired] = useState(false)
  const [errFlg, setErrFlg] = useState({ newPass: false, retypePass: false })
  const [errMsg, setErrMsg] = useState({ newPass: '', retypePass: '' })

  // New Passwordが入力されたときの挙動
  const newPassData = (event) => {
    const value = event.target.value
    setNewpass(value)
    const validationCheck = CommonValidation.password(value)
    setErrFlg({ ...errFlg, newPass: validationCheck.checkResult })
    setErrMsg({ ...errMsg, newPass: t(validationCheck.errMsg) })
  }

  // RetypePasswordが入力されたときの挙動
  const retypePassData = (event) => {
    const value = event.target.value
    setRetypePass(value)
    const validationCheck = CommonValidation.password(value)
    setErrFlg({ ...errFlg, retypePass: validationCheck.checkResult })
    setErrMsg({ ...errMsg, retypePass: t(validationCheck.errMsg) })
  }

  // newPassとretypePassが一致したときのみボタン活性
  const passCheck = () => {
    if (newPass === '' && retypePass === '') {
      return true
    } else if (
      newPass === retypePass &&
      !errFlg.newPass &&
      !errFlg.retypePass
    ) {
      return false
    } else {
      return true
    }
  }

  // OKボタン押下（reissuePassword'PUT'）
  passReset = () => {
    // 入力されたパスワードの文字数チェック(8桁以上)
    if (newPass.length < 8 && retypePass.length < 8) {
      setError(true)
    } else {
      const req = {
        password: newPass,
        key,
      }
      restFacade.put(
        ApiPaths.AUTHENTICATION.REISSUE_PASSWORD,
        (res) => successReset(res),
        req
      )
    }
  }

  // リセット成功時（reissuePassword'PUT'）
  successReset = (res) => {
    switch (res.data.status) {
      case 200:
        // eslint-disable-next-line no-case-declarations
        const resetFlg = true
        CommonFunc.clickEvent(
          { path: Paths.AUTHENTICATION.SIGN_IN, state: resetFlg },
          history
        )
        break
      // 期限切れ
      case 400:
        setExpired(true)
        break
      default:
        break
    }
  }

  // PasswordReset画面遷移時（reissuePassword'GET'）
  // displayTranslation = (res) => {
  //   switch (res.data.status) {
  //     // 期限切れ
  //     case 400:
  //       setExpired(true)
  //       break
  //     default:
  //       break
  //   }
  // }

  // URLを取得してKey部分取り出し、reqにセット（reissuePassword'GET'）
  useEffect(() => {
    const url = window.location.href
    const data = url.split('/').slice(-1)[0]
    setKey(data)
    // const req = {
    //   params: {
    //     'key': data
    //   }
    // }
    // restFacade.get(ApiPaths.AUTHENTICATION.REISSUE_PASSWORD, (res) => displayTranslation(res), req)
  }, []) // eslint-disable-next-line

  return (
    <form>
      {' '}
      {/* 警告解消のためformタグを追加 */}
      <div className={classes.formControl}>
        <Grid container justify="center">
          <Grid item style={{ marginBottom: '3rem' }} md={10} xs={10} />
          <Grid item md={4} xs={10}>
            {/* 警告解消のため非表示のe-mail欄を追加 */}
            <input
              type="text"
              name="email"
              autoComplete="username email"
              hidden
            />
            <div className={classes.text_wht}>{t('D501V0068')}</div>
            <PasswordField
              id="newPass"
              err={errFlg.newPass}
              helperTxt={errMsg.newPass}
              handleChange={newPassData}
              autoComplete="new-password"
            />
          </Grid>
          <Grid item style={{ marginBottom: '3rem' }} md={10} xs={10} />
          <Grid item md={4} xs={10}>
            <div className={classes.text_wht}>{t('D501V0069')}</div>
            <PasswordField
              id="retypePass"
              err={errFlg.retypePass}
              helperTxt={errMsg.retypePass}
              handleChange={retypePassData}
              autoComplete="new-password"
            />
          </Grid>
          {expired ? <Expired /> : ''}
          <Grid container justify="center">
            {error ? (
              <span className={classes.required}>{t('D501V0072')}</span>
            ) : (
              <></>
            )}
          </Grid>
          <Grid container justify="center">
            <Grid item>
              <PrimaryButton
                addclass={classes.okButton}
                disabled={passCheck()}
                onClick={passReset}
              >
                {t('D501V0070')}
              </PrimaryButton>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </form>
  )
}

export default Password
