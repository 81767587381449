import React, { useReducer, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import LayoutLog from '../../../common/layout/LayoutLog'
import AppContext from '../../../../contexts/AppContext'
import CommonFunc from '../../../common/function/CommonFunc'
import ApiPaths from '../../../../constants/ApiPaths'
import reducer, {
  initialState,
} from '../../../../reducers/authentication/lightFwdrRegisterNewAccount'
import { LIGHT_REGISTER_NEW_ACCOUNT } from '../../../../actions'
import TitleLightFwdrRegisterNewAccount from './00TitleLightFwdrRegisterNewAccount/TitleLightFwdrRegisterNewAccount'
import CompanyLightFwdrRegisterNewAccount from './01CompanyLightFwdrRegisterNewAccount/CompanyLightFwdrRegisterNewAccount'
import IataCassNoLightFwdrRegisterNewAccount from './02IataCassNoLightFwdrRegisterNewAccount/IataCassNoLightFwdrRegisterNewAccount'
import FirstNameLightFwdrRegisterNewAccount from './03FirstNameLightFwdrRegisterNewAccount/FirstNameLightFwdrRegisterNewAccount'
import LastNameLightFwdrRegisterNewAccount from './04LastNameLightFwdrRegisterNewAccount/LastNameLightFwdrRegisterNewAccount'
import PositionLightFwdrRegisterNewAccount from './05PositionLightFwdrRegisterNewAccount/PositionLightFwdrRegisterNewAccount'
import EmailLightFwdrRegisterNewAccount from './06EmailLightFwdrRegisterNewAccount/EmailLightFwdrRegisterNewAccount'
import TelLightFwdrRegisterNewAccount from './07TelLightFwdrRegisterNewAccount/TelLightFwdrRegisterNewAccount'
import PostalCodeLightFwdrRegisterNewAccount from './08PostalCodeLightFwdrRegisterNewAccount/PostalCodeLightFwdrRegisterNewAccount'
import AddressLightFwdrRegisterNewAccount from './09AddressLightFwdrRegisterNewAccount/AddressLightFwdrRegisterNewAccount'
import BondInAddressLightFwdrRegisterNewAccount from './10BondInAddressLightFwdrRegisterNewAccount/BondInAddressLightFwdrRegisterNewAccount'
import BranchLightFwdrRegisterNewAccount from './11BranchLightFwdrRegisterNewAccount/BranchLightFwdrRegisterNewAccount'
import AirportLightFwdrRegisterNewAccount from './12AirportLightFwdrRegisterNewAccount/AirportLightFwdrRegisterNewAccount'
import IconLightFwdrRegisterNewAccount from './13IconLightFwdrRegisterNewAccount/IconLightFwdrRegisterNewAccount'
import RequireLightFwdrRegisterNewAccount from './14RequireLightFwdrRegisterNewAccount/RequireLightFwdrRegisterNewAccount'
import CheckLightFwdrRegisterNewAccount from './15CheckLightFwdrRegisterNewAccount/CheckLightFwdrRegisterNewAccount'
import ButtonLightFwdrRegisterNewAccount from './16ButtonLightFwdrRegisterNewAccount/ButtonLightFwdrRegisterNewAccount'
import PlanLightFwdrRegisterNewAccount from './17PlanLightFwdrRegisterNewAccount/PlanLightFwdrRegisterNewAccount'

const LightFwdrRegisterNewAccount = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const history = useHistory()

  /** 初期処理 */
  useEffect(() => {
    ;(async () => {
      const apiInfoArr = [
        {
          key: 'companyArr',
          method: 'get',
          path: ApiPaths.AUTHENTICATION.FWDR_NAME,
        },
        { key: 'cityArr', method: 'get', path: ApiPaths.MASTER_JH.CITY_CODE },
        { key: 'airportArr', method: 'get', path: ApiPaths.MASTER_JH.AIRPORT },
        {
          key: 'planArr',
          method: 'get',
          path: ApiPaths.COMMON_API.LIGHT_FWDR_PLAN,
        },
      ]
      const resObj = await CommonFunc.execApiAsync(apiInfoArr)
      const isApiSuccessful = CommonFunc.checkApiResponseObj(resObj, history)

      if (isApiSuccessful) {
        const { planArr } = resObj
        const resArr = Object.entries(resObj).map(([keyStr, res]) => [
          keyStr,
          res.data.results,
        ])
        const stateObj = Object.fromEntries(resArr)
        dispatch({
          type: LIGHT_REGISTER_NEW_ACCOUNT.SELECT_DATA.INIT,
          data: stateObj,
        })
        dispatch({
          type: LIGHT_REGISTER_NEW_ACCOUNT.PLAN.INIT,
          planArr: planArr.data.results,
        })
      }
    })()
  }, []) // eslint-disable-line

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <LayoutLog>
        <TitleLightFwdrRegisterNewAccount />
        <PlanLightFwdrRegisterNewAccount />
        <CompanyLightFwdrRegisterNewAccount />
        <IataCassNoLightFwdrRegisterNewAccount />
        <FirstNameLightFwdrRegisterNewAccount />
        <LastNameLightFwdrRegisterNewAccount />
        <PositionLightFwdrRegisterNewAccount />
        <EmailLightFwdrRegisterNewAccount />
        <TelLightFwdrRegisterNewAccount />
        <PostalCodeLightFwdrRegisterNewAccount />
        <AddressLightFwdrRegisterNewAccount />
        <BondInAddressLightFwdrRegisterNewAccount />
        <BranchLightFwdrRegisterNewAccount />
        <AirportLightFwdrRegisterNewAccount />
        <IconLightFwdrRegisterNewAccount />
        <RequireLightFwdrRegisterNewAccount />
        <CheckLightFwdrRegisterNewAccount />
        <ButtonLightFwdrRegisterNewAccount />
      </LayoutLog>
    </AppContext.Provider>
  )
}

export default LightFwdrRegisterNewAccount
