import React, { useContext, useState } from 'react'
import { Grid } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import Paths from '../../../../../constants/Paths'
import NextButton from '../../../../common/buttons/NextButton'
import CommonFunc from '../../../../common/function/CommonFunc'
import AppContext from '../../../../../contexts/AppContext'
import SaveCompleteDialog from '../../../../common/dialog/SaveCompleteDialog'
import Common from '../../../../../constants/Common'

/**
 * @return {JSX} Nextボタンとエラー時のポップアップコンポーネント
 */
const NextButtonBidInfo = () => {
  const history = useHistory()
  const { state } = useContext(AppContext)

  const [isErr, setIsErr] = useState(false)
  const [errCode, setErrCode] = useState(Common.ERROR_CODE.NORMAL)

  // stateに1つでもエラーが有れば非活性
  const isDisabled = Object.values(state).some(({ isError }) => isError)

  /**
   * ページ遷移
   * @return {void}
   */
  const transitionPage = async () => {
    const { updatedAtArr } = state.PageData
    // updatedAtのチェック
    const { errorCode, isError } = await CommonFunc.checkUpdatedAtBid(
      updatedAtArr,
      history
    )
    if (isError) {
      // エラー状態を保存しポップアップを表示
      setIsErr(isError)
      setErrCode(errorCode)
    } else {
      // ページデータ作成&ページ遷移
      CommonFunc.shipperBidInfoTransitionPage(
        state,
        history,
        Paths.SHIPPER.BID_DETAILS
      )
    }
  }

  return (
    <Grid item>
      <NextButton onClick={transitionPage} disabled={isDisabled} />
      {/* 排他チェック失敗時のポップアップ */}
      <SaveCompleteDialog
        savecomp={isErr.toString()}
        path={Paths.SHIPPER.BID_MGMT}
        errcode={errCode}
      />
    </Grid>
  )
}

export default NextButtonBidInfo
