import { combineReducers } from 'redux'

import SearchSort, { initSearchSort } from '../../common/SearchSort'
import TablesBidMgmt, { initTablesBidMgmt } from './TablesBidMgmt'
import Paging, { initPaging } from '../../common/Paging'

const initialState = {
  SearchSort: initSearchSort,
  Tables: initTablesBidMgmt,
  Paging: initPaging,
}

export default combineReducers({
  SearchSort,
  Tables: TablesBidMgmt,
  Paging,
})

export { initialState }
