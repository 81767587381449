import React, { useContext, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import AppContext from '../../../../../contexts/AppContext'
import { LIGHT_FLIGHT_MGMT } from '../../../../../actions'
import XsmallButton from '../../../../common/buttons/XsmallButton'
import CommonCalc from '../../../../common/function/CommonCalc'

const useStyles = makeStyles((theme) => ({
  editButton: {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
}))

/**
 * @param {*} props 下記内容
 * @param {Number} props.index ページ上のインデックス
 * @return {JSX} Editボタン
 */
const EditButtonLightFlightMgmt = (props) => {
  const { editButton } = useStyles()
  const { t } = useTranslation()
  const { index } = props
  const { state, dispatch } = useContext(AppContext)
  const { setOpen } = state.Popup

  return useMemo(() => {
    /**
     * ボタン押下時のイベント
     * @returns {void} なし
     */
    const onClick = () => {
      /** @type {Number} showDataのインデックス */
      const page = CommonCalc.calcShowDataIndex(
        state.Paging,
        state.Tables.showData.length
      )
      const { initDataIndex } = state.Tables.showData[page][index]
      // ポップアップに編集データをセット
      dispatch({
        type: LIGHT_FLIGHT_MGMT.EDIT.INIT,
        targetData: state.Tables.initData.find(
          (v) => v.initDataIndex === initDataIndex
        ),
        add: false,
      })

      // ポップアップを開く
      setOpen(true)
    }

    /** @type{String} ボタンに表示するテキスト */
    const text = state.Auth ? t('D301V0035') : t('D301V0240')

    return (
      <XsmallButton addclass={editButton} onClick={onClick}>
        {text}
      </XsmallButton>
    )
  }, [
    editButton,
    dispatch,
    state.Tables,
    setOpen,
    index,
    t,
    state.Paging,
    state.Auth,
  ])
}

EditButtonLightFlightMgmt.propTypes = {
  index: PropTypes.number.isRequired,
}

export default EditButtonLightFlightMgmt
