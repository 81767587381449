import { combineReducers } from 'redux'

import SelectData, { initData } from './SelectData'
import CarrierName, { initCarrierName } from '../common/CarrierName'
import IataCassNo, { initIataCassNo } from '../common/IataCassNo'
import Department, { initDepartment } from '../common/Department'
import FirstName, { initFirstName } from '../common/FirstName'
import LastName, { initLastName } from '../common/LastName'
import Position, { initPosition } from '../common/Position'
import Email, { initEmail } from '../common/Email'
import Tel, { initTel } from '../common/Tel'
import PostalCode, { initPostalCode } from '../common/PostalCode'
import Address, { initAddress } from '../common/Address'
import Branch, { initBranch } from '../common/Branch'
import Airport, { initAirport } from '../common/Airport'
import Icon, { initIcon } from '../common/Icon'
import AgreeCheck, { initIsAgreeChecked } from '../common/AgreeCheck'
import Plan, { initPlan } from '../common/Plan'
import CarPlan, { initCarPlan } from '../common/CarPlan'

const initialState = {
  CarrierName: initCarrierName,
  IataCassNo: initIataCassNo,
  Branch: initBranch,
  Department: initDepartment,
  Airport: initAirport,
  FirstName: initFirstName,
  LastName: initLastName,
  Position: initPosition,
  Email: initEmail,
  Tel: initTel,
  PostalCode: initPostalCode,
  Address: initAddress,
  Icon: initIcon,
  AgreeCheck: initIsAgreeChecked,
  SelectData: initData,
  CarPlan: initCarPlan,
  Plan: initPlan,
}

export default combineReducers({
  CarrierName,
  IataCassNo,
  Branch,
  Department,
  Airport,
  FirstName,
  LastName,
  Position,
  Email,
  Tel,
  PostalCode,
  Address,
  Icon,
  AgreeCheck,
  SelectData,
  CarPlan,
  Plan,
})

export { initialState }
