import { PLAN } from '../../actions'

const initUserPlan = {}

/**
 * ページ状態を保持
 * @param {Object} [state=initPlan] -
 * @param {Object} action - dispatchで受け取ったaction
 * @return {Object} - セレクトボックスオブジェクト
 */
const UserPlan = (state = initUserPlan, action) => {
  const { type, planData } = action
  switch (type) {
    case PLAN.SET:
      return { ...state, ...planData }
    default:
      return state
  }
}

export default UserPlan

export { initUserPlan }
