import React, { useContext } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import TextField from '../../../../common/text/TextField'
import ConstantsBidInfo from '../ConstantsBidInfo'
import DialogSelectBoxPicBidInfo from './DialogSelectBoxPicBidInfo'
import AppContext from '../../../../../contexts/AppContext'

const useStyles = makeStyles((theme) => ({
  title: theme.textNormalBLU,
}))

/**
 * @param {Object} props 以下
 * @param {String} titleKey タイトルに表示したいKey
 * @param {String} textKey stateに保存されている選択中のPICの表示のためのkey
 * @returns {JSX} PICダイアログのテキスト(1行目はセレクトボックス)コンポーネント
 */
const DialogTextPicBidInfo = ({ titleKey, textKey }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { state } = useContext(AppContext)
  const { selectPicObj } = state.Pic

  // 先頭のみセレクトボックスで表示、それ以外は非活性のテキストボックス
  const isSelectBox = titleKey === ConstantsBidInfo.PIC.DISPLAY_ARR[0].titleKey

  // 登録されていないものは‐で表示
  const textStr = selectPicObj[textKey]
  const isEmpty = !textStr
  const displayText = isEmpty ? '-' : textStr

  return (
    <Grid container justify="center">
      <Grid item md={10} xs={10} className={classes.title}>
        {t(titleKey)}
      </Grid>
      <Grid item md={10} xs={10}>
        {isSelectBox ? (
          <DialogSelectBoxPicBidInfo />
        ) : (
          <TextField value={displayText} multiline disabled />
        )}
      </Grid>
    </Grid>
  )
}

DialogTextPicBidInfo.propTypes = {
  titleKey: PropTypes.string.isRequired,
  textKey: PropTypes.string.isRequired,
}

export default DialogTextPicBidInfo
