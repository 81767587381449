import React from 'react'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import CheckBox from '../../../../common/checkBox/CheckBox'

const useStyles = makeStyles({
  checkBoxes: {
    width: '500px',
  },
  textField: {
    '& .MuiFilledInput-inputMultiline': {
      minHeight: '137px',
    },
    '& .MuiFilledInput-root': {
      marginLeft: '2rem',
    },
  },
})

/**
 * チェックボックスを表示
 * @param {Object} props MUIのCheckBoxの設定
 * @param {Array} choices - 選択肢の配列
 * @param {Array} check - チェック状態の配列
 * @returns {*} - チェックボックス
 */
const CheckBoxes = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { choices, check, ...restProps } = props

  return (
    <Grid container item className={classes.checkBoxes}>
      {/* 選択肢 */}
      {choices.map((choice, index) => (
        <Grid item xs={12} key={index}>
          <CheckBox
            label={t(choice)}
            value={index}
            checked={check ? check[index] : undefined}
            {...restProps}
          />
        </Grid>
      ))}
    </Grid>
  )
}

CheckBoxes.propTypes = {
  choices: PropTypes.array.isRequired,
}

export default CheckBoxes
