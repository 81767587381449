import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

import LayoutBox from '../../../common/layout/LayoutBox'
import Theme from '../../../common/theme/Theme'
import Paths from '../../../../constants/Paths'
import MasterJhTopButtons from './MasterJhTopButtons'

const useStyles = makeStyles(() => ({
  root: Theme.topInner,
  outer: Theme.topOuter,
}))

const MasterJhMasterTop = () => {
  const classes = useStyles()

  const paths = {
    companyInfoG: Paths.MASTER.JH_COMPANY_INFOG,
    companyInfoM: Paths.MASTER.JH_COMPANY_INFOM,
    myProfile: Paths.MASTER.JH_MY_PROFILE,
    accountInfo: Paths.MASTER.JH_ACCOUNT_INFO,
  }

  return (
    <LayoutBox>
      <div className={classes.outer}>
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.root}
        >
          <Grid item md={10} xs={10}>
            <MasterJhTopButtons paths={paths} />
          </Grid>
        </Grid>
      </div>
    </LayoutBox>
  )
}

export default MasterJhMasterTop
