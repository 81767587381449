import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import PrimaryButton from '../../common/buttons/PrimaryButton'

const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.buttonSize.width.large,
    height: theme.buttonSize.height.large,
    [theme.breakpoints.down('sm')]: {
      width: theme.buttonSize.width.medium,
      height: theme.buttonSize.height.medium,
    },
  },
}))

/**
 * @param {Object} props - 以下
 * @param {string} path 押下後の遷移先
 * @param {Event} clickevent - 押下後のイベント
 * @param {object} state - 遷移先に渡すデータ
 * @returns {JSX.Element} - searchボタンコンポーネント
 */
const SearchButton = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <PrimaryButton addclass={classes.root} {...props}>
      {t('D004V0056')}
    </PrimaryButton>
  )
}

export default SearchButton
