import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

import ConstantsLightFwdrBookingList from '../ConstantsLightFwdrBookingList'
import TableCell from '../../../../common/table/TableCell'
import TableRow from '../../../../common/table/TableRow'

const useStyles = makeStyles((theme) => ({
  tableHead: theme.commonTableHeader,
}))

/**
 * 定数から作成するヘッダーコンポーネント(1列分)
 * 変更したい場合は定数ConstantsLightFwdrBookingListを操作する
 * @return {JSX} ヘッダーコンポーネント(1列分)
 */
const HeaderLightFwdrBookingList = () => {
  const { tableHead } = useStyles()
  const { t } = useTranslation()

  const { TABLE_ARR } = ConstantsLightFwdrBookingList

  return (
    <TableRow>
      {TABLE_ARR.map(({ headerKey }, index) => (
        <TableCell key={index} className={tableHead}>
          {t(headerKey)}
        </TableCell>
      ))}
    </TableRow>
  )
}

export default HeaderLightFwdrBookingList
