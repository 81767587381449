import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import AppContext from '../../../../../contexts/AppContext'
import DetailButton from '../../../../common/buttons/DetailButton'
import Paths from '../../../../../constants/Paths'

/**
 * @param {object} props 下記内容
 * @param {object} props.contents 予約情報
 * @return {JSX} Select Flightボタン
 */
const SelectFlightSearchResult = ({ contents }) => {
  const { t } = useTranslation()
  const { state } = useContext(AppContext)

  // 次画面に渡すデータを整形
  const { commodity, commId, orgId, dstId, dst, org } = state.ShippingDetails
  const {
    laneId,
    allInRate,
    currency,
    fuelSurcharge,
    large,
    light,
    max,
    medium,
    min,
    minRate,
    normal,
    flightArr,
    disabled,
  } = contents
  const resData = {
    cargoDetailArr: state.cargoDetailArr,
    commodity,
    commId,
    orgId,
    dstId,
    dst,
    org,
    flightObj: {
      flightArr: flightArr.map(({ eta, etd, ...rest }) => {
        return {
          eta: `${eta.split(':')[0]}:${eta.split(':')[1]}`,
          etd: `${etd.split(':')[0]}:${etd.split(':')[1]}`,
          ...rest,
        }
      }),
    },
    laneObj: {
      laneId,
      allInRate,
      currency,
      fuelSurCharge: fuelSurcharge,
      large,
      light,
      max,
      medium,
      min,
      minrate: minRate,
      normal,
      rate: null,
    },
  }

  // 航空運賃が10桁以下の場合活性、11桁以上の場合非活性
  return (
    <DetailButton
      path={Paths.FWDR.LIGHT_BOOKING_DETAILS}
      state={resData}
      disabled={disabled}
    >
      {t('D009V0021')}
    </DetailButton>
  )
}

SelectFlightSearchResult.propTypes = {
  contents: PropTypes.object.isRequired,
}

export default SelectFlightSearchResult
