import { LIGHT_BOOKING_DETAILS } from '../../../actions'

/**
 * @typedef {Object} Shc Shc情報
 * @property {Number} shcId
 * @property {String} shc
 * @property {Number} bulkSearchFlg
 * @property {Number} dgdFlg
 * @property {Number} SpecialLoadFlg
 * @property {String} createdAt
 * @property {String} updatedAt
 * @property {Number} delFlg
 */

/** @type{Shc[]} SHCの配列 */
let shcs = []

/**
 * ShcIdからShcを取得
 * @param {Number} shcId shcId
 * @returns {Shc|null} shcIdに紐づくShc
 */
const getShc = (shcId) => shcs.find((shc) => shc.shcId === shcId) ?? null

/** @type {Object} 初期データ */
const initShc = {
  shcs,
  getShc,
}

/**
 * Shcデータを保持する
 * @param {Object} action action
 * @param {Object} state stateの値
 * @returns {Object} state
 */
const setShc = (action, state) => {
  shcs = action.shcs
  return { ...state, shcs }
}

/**
 * Shcデータを保持
 * @param { Object } [state = initShc] state
 * @param { Object } action action
 * @return { Object } state
 */
const Shc = (state = initShc, action) => {
  const { type, ...rest } = action
  switch (type) {
    case LIGHT_BOOKING_DETAILS.SPECIAL_HANDLING_CODE.SET:
      return setShc(rest, state)
    default:
      return state
  }
}

export default Shc

export { initShc, getShc }
