import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import CommonDialog from '../commonDialog/CommonDialog'
import WarningRoundedIcon from '../../icon/WarningRoundedIcon'

const useStyles = makeStyles((theme) => ({
  text: theme.saveText,
}))

/**
 * 確認ダイアログ
 * @param {Boolean} warning 警告アイコンを表示する場合はtrue
 * @param {Any} restProps CommonDialogと同じ以下を設定
 * @param {Boolean} open ダイアログの開閉状態
 * @param {Function} closeFunc ダイアログ外をクリックした時のcloseイベント
 * @param {Object} dialogFuncObj ダイアログのボタン設定
 * @param {JSX} additionalElements 追加で表示する要素
 * @return {JSX} 確認ダイアログコンポーネント
 */
const AreYouSureDialog = ({
  warning,
  additionalElements = null,
  ...restProps
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <CommonDialog {...restProps}>
      <Grid container>
        {/* 警告アイコン */}
        {warning && (
          <Grid container item xs={12} justify="center">
            <WarningRoundedIcon />
          </Grid>
        )}
        {/* 確認文章 */}
        <Grid container item xs={12} justify="center" className={classes.text}>
          {t('D401V0050')}
        </Grid>
        <Grid container item xs={12} justify="center">
          {additionalElements}
        </Grid>
      </Grid>
    </CommonDialog>
  )
}

AreYouSureDialog.propTypes = {
  warning: PropTypes.bool,
  // 以下CommonDialogと同様の設定
  open: PropTypes.bool.isRequired,
  closeFunc: PropTypes.func.isRequired,
  dialogFuncObj: PropTypes.objectOf(PropTypes.object).isRequired,
  additionalElements: PropTypes.any,
}

export default AreYouSureDialog
