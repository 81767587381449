import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import CommonCalc from '../../../../common/function/CommonCalc'

/**
 * @param {object} props 下記内容
 * @param {object} props.contents 予約情報
 * @return {JSX} 燃油サーチャージ
 */
const FscLightSearchResult = ({ contents }) => {
  const { t } = useTranslation()
  const fsc = CommonCalc.addComma(Number(contents.fuelSurcharge), true)

  return (
    <>
      <div>{`${t('2nd0017')}: ${contents.currency} ${fsc} / ${t('D009V0053')}`}</div>
    </>
  )
}

FscLightSearchResult.propTypes = {
  contents: PropTypes.object.isRequired,
}

export default FscLightSearchResult
