import React from 'react'

import SearchSort from '../../../../commonUseContext/SearchSort'

/**
 * @return {JSX} サーチソートコンポーネント
 */
const SearchSortLightCarSelectForwarder = () => {
  return <SearchSort />
}

export default SearchSortLightCarSelectForwarder
