import React, { useReducer } from 'react'

import LayoutBox from '../../../common/layout/LayoutBox'
import AppContext from '../../../../contexts/AppContext'
import reducer, {
  initialState,
} from '../../../../reducers/master/lightMasterCarSelectForwarder'

import TitleLightCarSelectForwarder from './00TitleLightCarSelectForwarder/TitleLightCarSelectForwarder'
import SearchSortLightCarSelectForwarder from './01SearchSortLightCarSelectForwarder/SearchSortLightCarSelectForwarder'
import TableLightCarSelectForwarder from './02TableLightCarSelectForwarder/TableLightCarSelectForwarder'
import PagingLightCarSelectForwarder from './03PagingLightCarSelectForwarder/PagingLightCarSelectForwarder'
import BackButtonLightCarSelectForwarder from './04BackButtonLightCarSelectForwarder/BackButtonLightCarSelectForwarder'

/**
 * @return {JSX} 航空会社LightSelectFwdr画面コンポーネント
 */
const LightCarSelectForwarder = () => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <LayoutBox>
        <TitleLightCarSelectForwarder />
        <SearchSortLightCarSelectForwarder />
        <TableLightCarSelectForwarder />
        <PagingLightCarSelectForwarder />
        <BackButtonLightCarSelectForwarder />
      </LayoutBox>
    </AppContext.Provider>
  )
}

export default LightCarSelectForwarder
