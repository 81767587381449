import { CHECKBOX } from '../../../actions'

/** @type{Boolean} 初期表示 */
const initCheckbox = false

/**
 * チェックボックス状態を保持
 * @param {Boolean} [state=initCheckbox] state
 * @param {Object} action action
 * @return {Boolean} チェックボックス状態
 */
const Checkbox = (state = initCheckbox, action) => {
  const { type } = action
  switch (type) {
    case CHECKBOX.SET:
      return action.checked
    default:
      return state
  }
}

export default Checkbox

export { initCheckbox }
