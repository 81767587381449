import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import CardButton from '../../../common/buttons/CardButton'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    [theme.breakpoints.up('md')]: {
      height: `calc(100vh - ${theme.header.height} * 2 - 1rem)`,
      minHeight: '310px',
    },
  },
  center: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '257px',
      margin: '0 auto',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
    },
  },
}))

/**
 * @param {*} props - 以下
 * @param {string} bookingpath - bookingList画面へのパス
 * @param {string} spacepath - spaceControl画面へのパス
 * @param {string} cargotpath - cargoTrace画面へのパス
 * @param {string} bidrpath - bidRates画面へのパス
 * @param {string} casspath - cassUpload画面へのパス
 * @returns {*} - 航空会社のTOP画面のボタン
 */
const AirlineTopButtons = (props) => {
  const classes = useStyles()

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item md xs={12}>
        <div className={classes.center}>
          <CardButton path={props.bookingListPath} />
        </div>
      </Grid>
      <Grid item md xs={12}>
        <div className={classes.center}>
          <CardButton path={props.billingReportPath} disabled />
        </div>
      </Grid>
    </Grid>
  )
}

export default AirlineTopButtons
