import { ERROR } from '../../../actions'

/** 初期データ */
const initErrorData = {}

/**
 * 入力欄のエラー状態を編集
 * @param {Object} state state
 * @param {Object} action dispatchで受け取ったaction
 * @return {Object} 編集後のstate
 */
const setError = (state, action) => {
  const { name, error } = action
  state[name] = error
  return { ...state }
}

/**
 * 入力欄のエラー状態を保持
 * @param {Object} [state=initPaging] 初期表示データ
 * @param {Object} action name, error
 * @return {Object} 入力欄のエラー状態
 */
const Error = (state = initErrorData, action) => {
  const { type } = action
  switch (type) {
    case ERROR.SET:
      return setError(state, action)
    default:
      return state
  }
}

export default Error

export { initErrorData }
