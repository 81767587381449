/* eslint-disable prefer-template */
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '-0.5rem',
  },
  radio: {
    height: '32px',
    width: 'fit-content',
    margin: '2px',
  },
  box: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '32px',
    border: '1px solid',
    borderColor: theme.palette.letter,
    borderRadius: '5px',
    backgroundColor: theme.palette.info.main,
    color: theme.fontColor.main,
  },
  checkedBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '32px',
    border: '1px solid',
    borderColor: theme.palette.letter,
    borderRadius: '5px',
    backgroundColor: theme.palette.primary.main,
    color: theme.fontColor.secondary,
  },
  icon: {
    lineHeight: '1rem',
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.fontSize.regular,
    width: 'fit-content',
    padding: '0 0.5rem',
    whiteSpace: 'nowrap',
  },
}))

const MobileRadio = (props) => {
  const classes = useStyles()
  return (
    <FormControlLabel
      className={classes.root + ' ' + props.className}
      control={
        <Radio
          color="primary"
          className={classes.radio}
          value={props.value}
          checkedIcon={
            <div className={classes.checkedBox}>
              <Typography variant="subtitle1" className={classes.icon}>
                {props.label}
              </Typography>
            </div>
          }
          icon={
            <div className={classes.box}>
              <Typography variant="subtitle1" className={classes.icon}>
                {props.label}
              </Typography>
            </div>
          }
        />
      }
      value={props.value}
    />
  )
}

export default MobileRadio
