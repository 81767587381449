/* eslint-disable prefer-template */
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import LayoutBox from '../../../common/layout/LayoutBox'
import Theme from '../../../common/theme/Theme'
import Title from '../../../common/title/Title'

const useStyles = makeStyles(() => ({
  center: Theme.center,
  marginTop: {
    marginTop: '2rem',
  },
  text: Theme.textBoldBLK,
}))

const Error = (props) => {
  const classes = useStyles()
  const error = props.history.location.state?.error ?? { code: '', message: '' }

  return (
    <LayoutBox>
      <Title>ERROR</Title>
      <Grid container justify="center">
        <Grid item md={10} xs={10}>
          <div className={classes.center}>
            <div className={classes.text}>{'code: ' + error.code}</div>
            <div className={classes.text}>{'message: ' + error.message}</div>
          </div>
        </Grid>
      </Grid>
    </LayoutBox>
  )
}

export default Error
