import React, { useContext, useEffect, useMemo, useState } from 'react'
import Grid from '@material-ui/core/Grid'

import AppContext from '../../../../contexts/AppContext'
import { LIGHT_BOOKING_DETAILS } from '../../../../actions'
import CommonDialog from '../../../common/dialog/commonDialog/CommonDialog'
import Common from '../../../../constants/Common'
import ShcSelectSpecialHandlingCodePopup from './ShcSelectSpecialHandlingCodePopup'

/**
 * @returns {JSX} SHCポップアップ
 */
const SpecialHandlingCodePopup = () => {
  const { dispatch, state } = useContext(AppContext)
  const [open, setOpen] = useState(false)

  // ポップアップを開く関数をreducerに登録
  useEffect(() => {
    dispatch({
      type: LIGHT_BOOKING_DETAILS.SHC_POPUP.SET_OPEN_FUNC,
      setOpen,
    })
  }, [dispatch])

  return useMemo(() => {
    /**
     * SHCを登録
     * @returns {void} なし
     */
    const registShc = () => {
      dispatch({
        type: LIGHT_BOOKING_DETAILS.SHC_ARR.EDIT,
        shc: state.ShcPopup.shc,
      })
      setOpen(false)
    }

    // ダイアログのボタン設定
    const dialogFuncObj = {
      [Common.DIALOG.BUTTON_CATEGORY.CANCEL]: { onClick: () => setOpen(false) },
      [Common.DIALOG.BUTTON_CATEGORY.OK]: {
        onClick: registShc,
        disabled: state.ShcPopup.shc === null,
      },
    }

    return (
      <CommonDialog
        open={open}
        closeFunc={() => setOpen(false)}
        dialogFuncObj={dialogFuncObj}
      >
        <Grid
          container
          direction="row"
          justify="center"
          alignContent="center"
          alignItems="center"
          spacing={2}
        >
          <ShcSelectSpecialHandlingCodePopup />
        </Grid>
      </CommonDialog>
    )
  }, [open, dispatch, state.ShcPopup.shc])
}
export default SpecialHandlingCodePopup
