import { Grid, makeStyles } from '@material-ui/core'
import React, { useContext } from 'react'

import AppContext from '../../../../../contexts/AppContext'
import AddButtonPicBidInfo from './AddButtonPicBidInfo'
import DialogPicBidInfo from './DialogPicBidInfo'
import HeaderPicBidInfo from './HeaderPicBidInfo'
import TextEditPicBidInfo from './TextEditPicBidInfo'

const useStyles = makeStyles((theme) => ({
  childrenPadding: theme.childrenPaddingSmall,
}))

/**
 * @return {JSX} PICコンポーネント
 */
const PicBidInfo = () => {
  const { state } = useContext(AppContext)
  const classes = useStyles()

  return (
    <Grid item container justify="flex-start">
      <HeaderPicBidInfo />
      <Grid
        item
        container
        justify="flex-start"
        className={classes.childrenPadding}
      >
        {state.Pic.filterPicArr.map((picObj) => (
          <TextEditPicBidInfo key={picObj.userId} picObj={picObj} />
        ))}
        <AddButtonPicBidInfo />
      </Grid>
      <DialogPicBidInfo />
    </Grid>
  )
}

export default PicBidInfo
