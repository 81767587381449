import { LIGHT_BOOKING_DETAILS } from '../../../actions'

/** @type {Object} SHCポップアップの初期値 */
const initShcPopup = {
  setOpen: () => {},
  shc: null,
}

/**
 * SHCポップアップを開く関数を登録
 * @param {Object} action action
 * @param {Function} action.setOpen ポップアップを開く関数
 * @param {Object} state state
 * @returns {Object} state
 */
const setOpenFunc = (action, state) => {
  const { setOpen } = action
  return { ...state, setOpen }
}

/**
 * SHCポップアップを開く
 * @param {Object} state state
 * @returns {Object} state
 */
const open = (state) => {
  state.setOpen(true)
  return { ...state, shc: null }
}

/**
 * SHCを編集
 * @param {Object} action action
 * @param {Object} state state
 * @returns {Object} state
 */
const edit = (action, state) => {
  const { shc } = action
  return { ...state, shc }
}

/**
 * SHCポップアップの編集データを保持
 * @param { Object } [state = initShcPopup] state
 * @param { Object } action action
 * @return { Object } state
 */
const ShcPopup = (state = initShcPopup, action) => {
  const { type, ...rest } = action
  switch (type) {
    case LIGHT_BOOKING_DETAILS.SHC_POPUP.SET_OPEN_FUNC:
      return setOpenFunc(rest, state)
    case LIGHT_BOOKING_DETAILS.SHC_POPUP.OPEN:
      return open(state)
    case LIGHT_BOOKING_DETAILS.SHC_POPUP.EDIT:
      return edit(rest, state)
    default:
      return state
  }
}

export default ShcPopup

export { initShcPopup }
