import React, { useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import AppContext from '../../../../contexts/AppContext'
import { LIGHT_BOOKING_DETAILS } from '../../../../actions'
import AddButton from '../../../common/buttons/AddButton'
import Common from '../../../../constants/Common'
import CommonValidation from '../../../common/function/CommonValidation'
import FileDuplicateDialog from '../../../common/dialog/commonDesignDialog/FileDuplicateDialog'
import { Grid } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  addButtonPopup: theme.inputFileBtnHide,
  helperText: theme.textNormalRED,
}))

/**
 * @param {Object} props 下記内容
 * @param {String} props.documentArrName Documentの配列名
 * @returns {JSX} Document欄のAddボタン
 */
const AddButtonDocumentLightBookingDetails = ({ documentArrName }) => {
  const { state, dispatch } = useContext(AppContext)
  const { t } = useTranslation()
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [helperText, setHelperText] = useState('')

  const length = state.Document[documentArrName].filter(
    ({ delFlg }) => !delFlg
  ).length

  return useMemo(() => {
    /**
     *ファイル名の重複チェック
     * @param {String} fileName チェックするファイル名
     * @returns {Boolean} 重複している場合true
     */
    const checkDuplicateFileName = (fileName) => {
      return state.Document[documentArrName].some(
        (v) => v.documentPath === fileName
      )
    }

    /**
     *ファイルを読み込む
     * @param {Object} event - event
     * @returns {void} なし
     */
    const readFile = (event) => {
      const files = event.target.files

      if (files.length > 0) {
        const file = files[0]
        //ファイルサイズ確認
        const { checkResult, errMsg } = CommonValidation.docSize(file)
        /** @type {Boolean} ファイル名の重複 */
        const isDuplicate = checkDuplicateFileName(file.name)
        if (isDuplicate) {
          setOpen(true) //警告ポップアップを表示
        } else if (!checkResult) {
          //ファイル読み込み
          const reader = new FileReader()
          reader.onloadend = () => {
            const document = {
              documentPath: file.name,
              url: reader.result,
              file,
            }
            dispatch({
              type: LIGHT_BOOKING_DETAILS.DOCUMENT.ADD,
              document,
              documentArrName,
            })
            setHelperText('')
          }
          reader.readAsDataURL(file)
        } else {
          setHelperText(t(errMsg))
        }
      }
      // 同名ファイルをアップロードされてもいいようにvalueをリセット
      event.target.value = ''
    }

    const dialogFuncObj = {
      [Common.DIALOG.BUTTON_CATEGORY.OK]: { onClick: () => setOpen(false) },
    }

    return (
      <Grid item container alignItems="flex-end" direction="column">
        <Grid item>
          <AddButton disabled={length === Common.MAX_DOCUMENT}>
            <input
              type="file"
              accept={Common.FILE_TYPE.DOC}
              className={classes.addButtonPopup}
              onChange={(event) => readFile(event, true)}
            />
          </AddButton>
        </Grid>
        <Grid item className={classes.helperText}>
          {helperText}
        </Grid>
        <FileDuplicateDialog
          open={open}
          closeFunc={() => setOpen(false)}
          dialogFuncObj={dialogFuncObj}
        />
      </Grid>
    )
  }, [
    dispatch,
    t,
    documentArrName,
    state.Document,
    length,
    classes,
    helperText,
    open,
  ])
}
AddButtonDocumentLightBookingDetails.propTypes = {
  documentArrName: PropTypes.string.isRequired,
}

export default AddButtonDocumentLightBookingDetails
