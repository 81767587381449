import { combineReducers } from 'redux'

import ShippingDetails, {
  initShippingDetails,
} from '../../common/lightBookingDetails/ShippingDetails'
import Cargo, { initCargo } from '../../common/lightBookingDetails/Cargo'
import MeasureAndWeightPopup, {
  initMeasureAndWeightPopup,
} from '../../common/lightBookingDetails/MeasureAndWeightPopup'
import Rate, { initRate } from '../../common/lightBookingDetails/Rate'
import Flight, { initFlight } from '../../common/lightBookingDetails/Flight'
import FlightPopup, {
  initFlightPopup,
} from '../../common/lightBookingDetails/FlightPopup'
import MawbNumber, {
  initMawbNumber,
} from '../../common/lightBookingDetails/MawbNumber'
import Shc, { initShc } from '../../common/lightBookingDetails/Shc'
import ShcPopup, {
  initShcPopup,
} from '../../common/lightBookingDetails/ShcPopup'
import ShcObj, { initShcObj } from '../../common/lightBookingDetails/ShcObj'
import Remark, { initRemark } from '../../common/lightBookingDetails/Remark'
import RemarkPopup, {
  initRemarkPopup,
} from '../../common/lightBookingDetails/RemarkPopup'
import Document, {
  initDocument,
} from '../../common/lightBookingDetails/Document'
import CancelBooking, {
  initCancelBooking,
} from '../../common/lightBookingDetails/CancelBooking'
import Status, { initStatus } from '../../common/lightBookingDetails/Status'

const initialState = {
  ShippingDetails: initShippingDetails,
  Cargo: initCargo,
  MeasureAndWeightPopup: initMeasureAndWeightPopup,
  Rate: initRate,
  Flight: initFlight,
  FlightPopup: initFlightPopup,
  MawbNumber: initMawbNumber,
  Shc: initShc,
  ShcPopup: initShcPopup,
  ShcObj: initShcObj,
  Remark: initRemark,
  RemarkPopup: initRemarkPopup,
  Document: initDocument,
  CancelBooking: initCancelBooking,
  Status: initStatus,
}

export default combineReducers({
  ShippingDetails,
  Cargo,
  MeasureAndWeightPopup,
  Rate,
  Flight,
  FlightPopup,
  MawbNumber,
  Shc,
  ShcPopup,
  ShcObj,
  Remark,
  RemarkPopup,
  Document,
  CancelBooking,
  Status,
})

export { initialState }
