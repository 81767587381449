import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

import TableCell from '../../../../common/table/TableCell'
import TableButtonLightCarSelectForwarder from './TableButtonLightCarSelectForwarder'

const useStyles = makeStyles((Theme) => ({
  companyName: Theme.textBoldBLK,
  fwdrCell: {
    textAlign: 'left',
    borderRight: '0px solid !important',
  },
  buttonCell: {
    textAlign: 'right',
  },
}))

/**
 * 1行分の表データを作成
 * @param {*} props 下記要素
 * @param {Object} tableContentObj 表示するテーブルの1行分の要素
 * @return {JSX} TableCellコンポーネント(1行分)
 */
const TableCellsLightCarSelectFwdr = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { company, fwdrNameId } = props.tableContentObj

  return (
    <>
      <TableCell padding="default" className={classes.fwdrCell}>
        <span>{`${t('D301V0038')}: `}</span>
        <span className={classes.companyName}>{company}</span>
      </TableCell>
      <TableCell padding="default" className={classes.buttonCell}>
        <TableButtonLightCarSelectForwarder fwdrNameId={fwdrNameId} />
      </TableCell>
    </>
  )
}

TableCellsLightCarSelectFwdr.propTypes = {
  tableContentObj: PropTypes.object.isRequired,
}

export default TableCellsLightCarSelectFwdr
