// Display + 画面名
const DisplayFwdrDocumentsConfirm = {
  // 画面ID + 文言ID
  D119V0001: {
    en: 'Documents Confirm',
    ja: '書類確認',
  },
  D119V0002: {
    en: 'Accounting Information',
    ja: '会計情報',
  },
  D119V0003: {
    en: 'Currency',
    ja: '通貨',
  },
  D119V0004: {
    en: 'Handling Information',
    ja: '取扱情報',
  },
  D119V0005: {
    en: 'Nature and Quantity of Goods (Incl. Dimensions or Volume)',
    ja: '商品の性質と量(寸法または体積を含む)',
  },
  D119V0006: {
    en: 'Declared Value for Carriage',
    ja: '運送申告価格',
  },
  D119V0007: {
    en: 'Declared Value for Custom',
    ja: '税関申告価格',
  },
  D119V0008: {
    en: 'The shipment is within the limitations prescribed for: (delete non-applicabled)',
    ja: 'この発送は、次の規定された制限内です。（該当しないものを削除）',
  },
  D119V0009: {
    en: 'Shipment type (delete non-applicable)',
    ja: '配送タイプ（該当しないものを削除）',
  },
  D119V0010: {
    en: 'CARGO AIRCRAFT ONLY',
    ja: '貨物機のみ',
  },
  D119V0011: {
    en: 'PASSENGER AND CARGO AIRCRAFT',
    ja: '乗客および貨物航空機',
  },
  D119V0012: {
    en: 'NON-RADIOACTIVE',
    ja: '非放射性',
  },
  D119V0013: {
    en: 'RADIOACTIVE',
    ja: '放射性',
  },
  D119V0014: {
    en: 'Cargo Details',
    ja: '貨物詳細',
  },
  D119V0015: {
    en: 'UN or ID NO.',
    ja: 'UNまたはID番号',
  },
  D119V0016: {
    en: 'Proper Shipping Name',
    ja: '適切な輸送名',
  },
  D119V0017: {
    en: 'PackingInst',
    ja: '包装基準',
  },
  D119V0018: {
    en: 'Authorization',
    ja: '認可',
  },
  D119V0019: {
    en: 'Quantity and type of packing',
    ja: '梱包の数量とタイプ',
  },
  D119V0020: {
    en: 'Pcs.',
    ja: '個',
  },
  D119V0021: {
    en: 'Type of packing',
    ja: '梱包のタイプ',
  },
  D119V0022: {
    en: 'Quantity',
    ja: '量',
  },
  D119V0023: {
    en: 'Unit',
    ja: '単位',
  },
  D119V0024: {
    en: 'Additional Type',
    ja: '追記事項',
  },
  D119V0025: {
    en: 'Additional Handling Information',
    ja: '取扱情報の追加',
  },
  D119V0026: {
    en: '24-hour emergency telephone number:',
    ja: '24時間対応の緊急電話番号：',
  },
  D119V0027: {
    en: '(including country and area code)',
    ja: '（国および市外局番を含む）',
  },
  D119V0028: {
    en: 'Name of Signatory',
    ja: '署名者の名前',
  },
  D119V0029: {
    en: 'Quote#',
    ja: '見積もり',
  },
  D119V0030: {
    en: 'Valid until',
    ja: '有効期限',
  },
  D119V0031: {
    en: 'Origin Charges',
    ja: '原産地料金',
  },
  D119V0032: {
    en: 'Freight Charges',
    ja: '運送費用',
  },
  D119V0033: {
    en: 'Destination Charges',
    ja: '到着地料金',
  },
  D119V0034: {
    en: 'Rate',
    ja: '割合',
  },
  D119V0035: {
    en: 'Per',
    ja: '単位',
  },
  D119V0036: {
    en: 'Amount',
    ja: '料金',
  },
  D119V0037: {
    en: 'Sub Total',
    ja: '小計',
  },
  D119V0038: {
    en: 'Total',
    ja: '合計',
  },
  D119V0039: {
    en: 'Terms and Conditions',
    ja: '利用規約',
  },
  D119V0040: {
    en: 'HouseAWB & MFST',
    ja: 'HouseAWB & MFST',
  },
  D119V0041: {
    en: 'Signature',
    ja: '署名',
  },
  D119V0042: {
    en: 'MAWB',
    ja: 'MAWB',
  },
  D119V0043: {
    en: 'DGD',
    ja: 'DGD',
  },
  D119V0044: {
    en: 'Quote',
    ja: '見積',
  },
  D119V0045: {
    en: 'Preview',
    ja: 'プレビュー',
  },
  D119V0046: {
    en: 'Upload',
    ja: '更新',
  },
  D119V0047: {
    en: 'Are you sure?',
    ja: '更新してもよろしいですか？',
  },
  D119V0048: {
    en: 'Cancel',
    ja: 'キャンセル',
  },
  D119V0049: {
    en: 'OK',
    ja: 'OK',
  },
  D119V0050: {
    en: 'HouseAWB',
    ja: 'HouseAWB',
  },
  D119V0051: {
    en: 'MFST',
    ja: 'MFST',
  },
  D119V0052: {
    en: 'HTS Code',
    ja: 'HTSコード',
  },
  D119V0053: {
    en: 'Class or div.',
    ja: 'クラス',
  },
  D119V0054: {
    en: 'Packing Group',
    ja: '梱包グループ',
  },
}
// Display + 画面名
export default DisplayFwdrDocumentsConfirm
