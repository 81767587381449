import { UPDATE_SHIP_SELECT_LIST } from '../../../actions'

/**
 * state: 表のデータを保持
 * @param {Object} [state={}] -
 * @param {Object} action -
 * @returns {*} -
 */
const SelectBox = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_SHIP_SELECT_LIST:
      return { ...state, selData: action.data }
    default:
      return state
  }
}

export default SelectBox
