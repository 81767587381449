import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import PersonAddIcon from '@material-ui/icons/PersonAdd'

import PrimaryButton from '../../../../common/buttons/PrimaryButton'
import SecondaryButton from '../../../../common/buttons/SecondaryButton'
import XsmallButton from '../../../../common/buttons/XsmallButton'
import AddButton from '../../../../common/buttons/AddButton'
import Dialog from '../../../../common/dialog/Dialog'
import EditIconButton from '../../../../common/buttons/EditIconButton'
import AppContext from '../../../../../contexts/AppContext'
import CommonFunc from '../../../../common/function/CommonFunc'
import {
  SEARCH_CAR_TABLE,
  ADD_CAR_TABLE,
  UPDATE_CAR_TABLE,
  DELETE_CAR_TABLE,
} from '../../../../../actions'
import Common from '../../../../../constants/Common'
import InputField from './InputField'
import Authority from './Authority'
import CommonValidation from '../../../../common/function/CommonValidation'

const useStyles = makeStyles((theme) => ({
  text: theme.textNormalBLU,
  editbutton: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primary.main,
    fontSize: theme.fontSize.header,
    backgroundColor: theme.palette.info.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.fontColor.secondary,
    },
    '&:active': {
      backgroundColor: theme.palette.primary.light,
    },
    boxShadow: 'none',
    borderRadius: 25,
    border: '1px solid #595959',
    boxSizing: 'border-box',
    whiteSpace: 'nowrap',
    padding: '0px',
    textTransform: 'none',
  },
  addIcon: {
    color: theme.palette.primary.main,
    fontSize: 24,
  },
  blackText: theme.textNormalBLK,
  marginBottom: '1rem',
}))

/**
 * @param {Object} props 下記内容のProps
 * @param {Number} index 行数
 * @param {array} row 1行分のテーブルデータ
 * @param {Number} accountType ユーザー指定
 * @param {Boolean} add 新規追加かどうか
 * @returns {JSX} 編集ダイアログコンポーネント
 */
const EditDialog = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [inputErrs, setInputErrs] = useState({
    name: false,
    lastName: false,
    email: false,
    tel: false,
    position: false,
    department: false,
    createdAt: false,
  })
  const [helperTxts, setHelperTxts] = useState({
    name: '',
    lastName: '',
    email: '',
    tel: '',
    position: '',
    department: '',
    createdAt: '',
  })
  const [row, setRow] = useState({
    name: '',
    lastName: '',
    email: '',
    tel: '',
    position: '',
    department: '',
    authorityFlg: 0,
    createdAt: '',
    searchAuthority: '',
  })
  const { state, dispatch } = useContext(AppContext)

  // OKボタン有効無効チェック
  const disabledChk = () => {
    // エラー判定trueを検索
    const hasError = Object.values(inputErrs).some((value) => value)

    // ADD時は1つでも未入力があればOKボタンを無効にする
    const notEmpty = {
      name: row.name,
      lastName: row.lastName,
      email: row.email,
      tel: row.tel,
    }
    const hasEmpty = Object.values(notEmpty).some((value) => value === '')
    return hasError || hasEmpty
  }

  // テキストボックス入力変更時の動作
  const handleChange = (e) => {
    const value = e.target.value
    const id = e.target.id

    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = `0${currentDate.getMonth()}${1}`
    const date = `0${currentDate.getDate()}`.slice(-2)
    const hours = `0${currentDate.getHours()}`.slice(-2)
    const minutes = `0${currentDate.getMinutes()}`.slice(-2)
    const seconds = `0${currentDate.getSeconds()}`.slice(-2)
    const dateData = `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`

    setRow({ ...row, [id]: value, createdAt: dateData })
    // 入力チェック
    const validationCheck = CommonFunc.profileCheckFunc(id, value)
    const err = validationCheck.checkResult
    setInputErrs({ ...inputErrs, [id]: err })
    setHelperTxts({ ...helperTxts, [id]: t(validationCheck.errMsg) })
  }

  const handleChangeCode = (e) => {
    const value = e.target.value
    const id = e.target.id

    setRow({ ...row, [id]: value })
    // 入力チェック
    const validationCheck = CommonValidation.departmentCode(value)
    const err = validationCheck.checkResult
    setInputErrs({ ...inputErrs, [id]: err })
    setHelperTxts({ ...helperTxts, [id]: t(validationCheck.errMsg) })
  }

  // Save前のE-mail重複チェック
  const handleChangeEmail = (e) => {
    const value = e.target.value
    const id = e.target.id

    setRow({ ...row, [id]: value })
    // 入力チェック
    const validationCheck = CommonFunc.profileCheckFunc(id, value)
    const err = validationCheck.checkResult
    setInputErrs({ ...inputErrs, [id]: err })
    setHelperTxts({ ...helperTxts, [id]: t(validationCheck.errMsg) })

    // 重複チェック(delFlgが立っているものと表示中のindexは除く)
    const emailArr = CommonFunc.getAccountInfoEmailArr(
      state.Tables.editData,
      props.index
    )
    if (emailArr.includes(value)) {
      setInputErrs({ ...inputErrs, [id]: true })
      setHelperTxts({ ...helperTxts, [id]: t('D301V0254') })
    }
  }

  // チェックボックス入力変更時の動作
  const handleChangeCheckBox = (e) => {
    const authorityFlg = e.target.checked
      ? Common.AUTHORITY_FLG.ON
      : Common.AUTHORITY_FLG.OFF
    const searchAuthority = e.target.checked ? 'authority' : ''
    setRow({ ...row, authorityFlg, searchAuthority })
  }

  // OKボタン処理
  const updateTable = () => {
    if (props.add !== undefined) {
      // テーブル追加時
      dispatch({
        type: ADD_CAR_TABLE,
        row,
        defaultChecked: state.SearchSort.defaultChecked,
      })
    } else {
      // テーブル編集時
      dispatch({
        type: UPDATE_CAR_TABLE,
        index: props.index,
        editIndex: props.editIndex,
        defaultChecked: state.SearchSort.defaultChecked,
        row,
      })
    }

    dispatch({
      type: SEARCH_CAR_TABLE,
      word: state.SearchSort.defaultSearchWord,
      authority: state.SearchSort.defaultChecked,
    })
  }

  // Deleteボタン処理
  const deleteTable = () => {
    dispatch({
      type: DELETE_CAR_TABLE,
      index: props.index,
      editIndex: props.editIndex,
    })
    dispatch({
      type: SEARCH_CAR_TABLE,
      word: state.SearchSort.defaultSearchWord,
      authority: state.SearchSort.defaultChecked,
    })
  }

  // ダイアログ表示時 初期化処理
  const initialize = () => {
    setInputErrs({
      name: false,
      lastName: false,
      email: false,
      tel: false,
      position: false,
      department: false,
      createdAt: false,
    })
    setHelperTxts({
      name: '',
      lastName: '',
      email: '',
      tel: '',
      position: '',
      department: '',
      createdAt: '',
    })
    if (props.row === undefined) {
      setRow({
        name: '',
        lastName: '',
        email: '',
        tel: '',
        position: '',
        department: '',
        authorityFlg: 0,
        createdAt: '',
        searchAuthority: '',
      })
    } else {
      setRow(props.row)
    }
  }

  // ユーザー数≧最大人数の場合Addボタンを非活性
  const addDisabled = () => {
    // ユーザー数取得
    const dataLength = state.Tables.editData.filter((data) => !data.delFlg)
    const dataLengthTotal = dataLength.length

    if (state.UserPlan.maxAccount !== null) {
      // ユーザー数≧最大人数の場合true、その他の場合falseを返す
      return dataLengthTotal >= state.UserPlan.maxAccount
    } else {
      // 最大人数がない場合falseを返す
      return false
    }
  }

  return (
    <Dialog
      buttonPC={
        props.add ? (
          <AddButton onClick={initialize} disabled={addDisabled()} />
        ) : (
          <XsmallButton addclass={classes.editbutton} onClick={initialize}>
            {t('D004V0061')}
          </XsmallButton>
        )
      }
      buttonMobile={
        props.add ? (
          <AddButton onClick={initialize} />
        ) : (
          <EditIconButton onClick={initialize} disabled={addDisabled()} />
        )
      }
      primary={
        <PrimaryButton disabled={disabledChk()} onClick={updateTable}>
          {t('D004V0062')}
        </PrimaryButton>
      }
      secondary={<SecondaryButton>{t('D004V0063')}</SecondaryButton>}
      primaryDisabled={disabledChk()}
      delete={props.add ? undefined : deleteTable}
      row={props.row}
      accountType={
        state.GsaFlg !== Common.GSA_FLG.GSA
          ? Common.ACCOUNT_TYPE.CARRIER
          : Common.ACCOUNT_TYPE.GSA
      }
      account
      noborder
      buttonDisabled={props.add ? addDisabled() : false}
    >
      <Grid container>
        <Grid item md={12} xs={12} align="center">
          {props.add ? <PersonAddIcon className={classes.addIcon} /> : ''}
        </Grid>
        <Grid
          item
          className={classes.marginBottom}
          md={12}
          xs={12}
          align="center"
        >
          {props.add ? (
            <div className={classes.blackText}>{t('D301V0164')}</div>
          ) : (
            ''
          )}
        </Grid>
        <InputField
          data={t('D301V0007')}
          id="name"
          error={inputErrs.name}
          helperText={helperTxts.name}
          value={row.name}
          onChange={handleChange}
        />
        <InputField
          data={t('D301V0008')}
          id="lastName"
          error={inputErrs.lastName}
          helperText={helperTxts.lastName}
          value={row.lastName}
          onChange={handleChange}
        />
        <InputField
          data={t('D301V0010')}
          id="email"
          error={inputErrs.email}
          helperText={helperTxts.email}
          value={row.email}
          onChange={handleChangeEmail}
        />
        <InputField
          data={t('D301V0011')}
          id="tel"
          error={inputErrs.tel}
          helperText={helperTxts.tel}
          value={row.tel}
          onChange={handleChange}
        />
        <InputField
          data={t('D301V0023')}
          id="position"
          error={inputErrs.position}
          helperText={helperTxts.position}
          value={row.position}
          onChange={handleChange}
        />
        {state.GsaFlg !== Common.GSA_FLG.GSA ? (
          <InputField
            data={t('D301V0209')}
            id="department"
            error={inputErrs.department}
            helperText={helperTxts.department}
            value={row.department}
            onChange={handleChangeCode}
            placeholder={t('D301V0250')}
          />
        ) : (
          false
        )}
        <Authority
          data={t('D301V0034')}
          onChange={handleChangeCheckBox}
          checked={Boolean(row.authorityFlg)}
        />
      </Grid>
    </Dialog>
  )
}

export default EditDialog
