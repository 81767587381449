import React, { useContext, useMemo } from 'react'

import AppContext from '../../../../contexts/AppContext'
import { LIGHT_BOOKING_DETAILS } from '../../../../actions'
import AddButton from '../../../common/buttons/AddButton'

/**
 * @returns {JSX} SHC欄のAddボタン
 */
const AddButtonSpecialHandlingCode = () => {
  const { dispatch } = useContext(AppContext)

  return useMemo(() => {
    /**
     * ポップアップを開く
     *@returns {void} なし
     */
    const openPopup = () => {
      dispatch({
        type: LIGHT_BOOKING_DETAILS.SHC_POPUP.OPEN,
      })
    }

    return <AddButton onClick={openPopup} />
  }, [dispatch])
}
export default AddButtonSpecialHandlingCode
