import { TABLES, LIGHT_JH_CLIENT_LIST } from '../../../actions'
import CommonFunc from '../../../components/common/function/CommonFunc'
import ConstantsLightJhClientList, {
  TabName,
} from '../../../components/pages/master/LightJhClientList/ConstantsLightJhClientList'

/** @type {Object} 初期表示のテーブル */
const initTableData = {
  fwdrInitData: [],
  carrierInitData: [],
  fwdrShowData: [[]],
  carrierShowData: [[]],
  showData: [[]],
  isNoResult: true,
  tab: TabName.forwarder,
}

/**
 * テーブルデータを保持
 * @param {Object} action action
 * @param {Object[]} action.tableData 登録するテーブルデータ
 * @param {String} action.search 検索ワード
 * @param {Object} state state
 * @returns {Object} テーブルデータ
 */
const setTableData = (action, state) => {
  const { tableData = {}, search = '', sort = '' } = action
  const { PAGE_AMOUNT, SORT_ITEM_ARR } = ConstantsLightJhClientList

  // sort名から並び替えの対象となるkeyを取得
  const { sortKey } = SORT_ITEM_ARR.find(({ sortTerm }) => sortTerm === sort)

  /** @type {Array} 表示用に編集後のデータ */
  const editedFwdrData = tableData.FWDR ?? state.fwdrInitData
  const editedCarrierData = tableData.carrier ?? state.carrierInitData

  // データを検索して結果をページごとに分割する
  const { showData: fwdrShowData, initData: fwdrInitData } =
    CommonFunc.searchSortData(
      editedFwdrData,
      search,
      sort,
      sortKey,
      PAGE_AMOUNT
    )
  const { showData: carrierShowData, initData: carrierInitData } =
    CommonFunc.searchSortData(
      editedCarrierData,
      search,
      sort,
      sortKey,
      PAGE_AMOUNT
    )

  const showData =
    state.tab === TabName.forwarder ? fwdrShowData : carrierShowData

  /** @type {Boolean} NoResult判定 */
  const isNoResult = showData[0].length <= 0

  return {
    ...state,
    showData,
    fwdrShowData,
    carrierShowData,
    fwdrInitData,
    carrierInitData,
    isNoResult,
  }
}

/**
 * ShowDataを切り替える
 * @param {Object} action action
 * @param {TabName} action.tab タブの値
 * @param {Object} state state
 * @returns {Object} state
 */
const changeShowData = (action, state) => {
  const { tab } = action
  const { fwdrShowData, carrierShowData } = state
  const showData = tab === TabName.forwarder ? fwdrShowData : carrierShowData
  /** @type {Boolean} NoResult判定 */
  const isNoResult = showData[0].length <= 0
  return {
    ...state,
    showData,
    isNoResult,
    tab,
  }
}

/**
 * テーブルデータを保持
 * @param { Object } [state = initTableData] テーブルデータ
 * @param { Object } action action
 * @return { Object } テーブルデータ
 */
const Tables = (state = initTableData, action) => {
  switch (action.type) {
    case TABLES.SET:
      return setTableData(action, state)
    case LIGHT_JH_CLIENT_LIST.TABLES.CHANGE_SHOW_DATA:
      return changeShowData(action, state)
    default:
      return state
  }
}

export default Tables

export { initTableData }
