// Display + 画面名
const DisplayAirlineBidComfirm = {
  // 画面ID + 文言ID
  D216V0001: {
    en: 'BID CONFIRM',
    ja: '入札確定',
  },
  D216V0002: {
    en: 'Owner',
    ja: 'オーナー',
  },
  D216V0003: {
    en: 'Representative',
    ja: '代表',
  },
  D216V0004: {
    en: 'Validity',
    ja: '有効期間',
  },
  D216V0005: {
    en: 'Set FWDR',
    ja: '混載業者を設定',
  },
  D216V0006: {
    en: 'Currency',
    ja: '通貨',
  },
  D216V0007: {
    en: '1st Deadline',
    ja: '一次締め切り',
  },
  D216V0008: {
    en: '2st Deadline',
    ja: '二次締め切り',
  },
  D216V0009: {
    en: '3rd Deadline',
    ja: '三次締め切り',
  },
  D216V0010: {
    en: 'Special Notes',
    ja: '特記事項',
  },
  D216V0011: {
    en: '1. DZK Rates - FORECAST',
    ja: '1. DZKレート-予測',
  },
  D216V0012: {
    en: 'ORG/DST',
    ja: 'ORG/DST',
  },
  D216V0013: {
    en: 'Via',
    ja: 'Via',
  },
  D216V0014: {
    en: 'TTL Wgt.',
    ja: 'TTL Wgt.',
  },
  D216V0015: {
    en: 'TTL Vol.',
    ja: 'TTL Vol.',
  },
  D216V0016: {
    en: 'SHC',
    ja: 'SHC',
  },
  D216V0017: {
    en: '+45kg',
    ja: '+45kg',
  },
  D216V0018: {
    en: '+100kg',
    ja: '+100kg',
  },
  D216V0019: {
    en: '+300kg',
    ja: '+300kg',
  },
  D216V0020: {
    en: '+500kg',
    ja: '+500kg',
  },
  D216V0021: {
    en: '+1,000kg',
    ja: '+1,000kg',
  },
  D216V0022: {
    en: '2. KNJ Rates - FINAL',
    ja: '2. KNJレート-最終',
  },
  D216V0023: {
    en: '3. EIK Rates - FORECAST',
    ja: '3. EIKレート-予測',
  },
  D216V0024: {
    en: '4. NEC Rates - FINAL RVSD',
    ja: '4. NECレート-最終RVSD',
  },
  D216V0025: {
    en: 'Panasonic Corporation',
    ja: 'Panasonic Corporation',
  },
  D216V0026: {
    en: '1006, Oaza Kadoma, Kadoma-shi, Osaka 571-8501, Japan',
    ja: '1006, Oaza Kadoma, Kadoma-shi, Osaka 571-8501, Japan',
  },
  D216V0027: {
    en: 'Tel. +81-6-6908-1121',
    ja: 'Tel. +81-6-6908-1121',
  },
  D216V0028: {
    en: 'Konosuke Matsushita',
    ja: 'Konosuke Matsushita',
  },
  D216V0029: {
    en: 'Manager - Lighting Dept.',
    ja: 'マネージャー- Lighting Dept.',
  },
  D216V0030: {
    en: 'Supervisor - Lighting Dept.',
    ja: 'スーパーバイザー- Lighting Dept.',
  },
  D216V0031: {
    en: 'Leader - Lighting Dept.',
    ja: 'リーダー- Lighting Dept.',
  },
  D216V0032: {
    en: 'kmatsushita@panasonic.co.jp / +81-X-XXXX-XXXX',
    ja: 'kmatsushita@panasonic.co.jp / +81-X-XXXX-XXXX',
  },
  D216V0033: {
    en: 'August 1, 2020 to January 31, 2021',
    ja: '2020/8/1~2021/1/31',
  },
  D216V0034: {
    en: 'DZK/KNJ/EIK/NEC/YLK/KWE/HEI',
    ja: 'DZK/KNJ/EIK/NEC/YLK/KWE/HEI',
  },
  D216V0035: {
    en: 'JPY - Japanese Yen',
    ja: 'JPY-日本円',
  },
  D216V0036: {
    en: '31-Jul-20',
    ja: '2020/7/31',
  },
  D216V0037: {
    en: '31-Aug-20',
    ja: '2020/8/31',
  },
  D216V0038: {
    en: '・Please follow the schedule strictly.',
    ja: '・スケジュールは厳守してください。',
  },
  D216V0039: {
    en: '・We’ll inform you as soon as we decide.',
    ja: '・決定次第お知らせします。',
  },
  D216V0040: {
    en: 'Norikuni Inaba',
    ja: 'Norikuni Inaba',
  },
  D216V0041: {
    en: 'Air Export Manager',
    ja: '空輸マネージャー',
  },
  D216V0042: {
    en: 'Supervisor',
    ja: 'スーパーバイザー',
  },
  D216V0043: {
    en: 'Leader',
    ja: 'リーダー',
  },
  D216V0044: {
    en: 'ninaba@dzk.co.jp / +81-X-XXXX-XXXX',
    ja: 'ninaba@dzk.co.jp / +81-X-XXXX-XXXX',
  },
  D216V0045: {
    en: 'NRT/MNL',
    ja: 'NRT/MNL',
  },
  D216V0046: {
    en: 'NRT/LON',
    ja: 'NRT/LON',
  },
  D216V0047: {
    en: 'NRT/ORD',
    ja: 'NRT/ORD',
  },
  D216V0048: {
    en: '-',
    ja: '-',
  },
  D216V0049: {
    en: 'FRA',
    ja: 'FRA',
  },
  D216V0050: {
    en: 'ANC',
    ja: 'ANC',
  },
  D216V0051: {
    en: '1,000kg - 3000kg',
    ja: '1,000kg - 3000kg',
  },
  D216V0052: {
    en: '300m3 - 500m3',
    ja: '300m3 - 500m3',
  },
  D216V0053: {
    en: 'GEN',
    ja: 'GEN',
  },
  D216V0054: {
    en: 'DG',
    ja: 'DG',
  },
  D216V0055: {
    en: 'ICE',
    ja: 'ICE',
  },
  D216V0056: {
    en: '600',
    ja: '600',
  },
  D216V0057: {
    en: '540',
    ja: '540',
  },
  D216V0058: {
    en: '510',
    ja: '510',
  },
  D216V0059: {
    en: '480',
    ja: '480',
  },
  D216V0060: {
    en: '850',
    ja: '850',
  },
  D216V0061: {
    en: '820',
    ja: '820',
  },
  D216V0062: {
    en: '780',
    ja: '780',
  },
  D216V0063: {
    en: '720',
    ja: '720',
  },
  D216V0064: {
    en: '680',
    ja: '680',
  },
  D216V0065: {
    en: '650',
    ja: '650',
  },
  D216V0066: {
    en: '620',
    ja: '620',
  },
  D216V0067: {
    en: '580',
    ja: '580',
  },
  D216V0068: {
    en: '530',
    ja: '530',
  },
  D216V0069: {
    en: '440',
    ja: '440',
  },
  D216V0070: {
    en: 'Please click the box if you agree to be unable to cancel/change the offered lanes during the BID term.',
    ja: '入札期間中に提供されたレーンをキャンセルまたは変更できないことに同意される場合は、ボックスをクリックしてください。',
  },
  D216V0071: {
    en: 'BID',
    ja: '入札',
  },
  D216V0072: {
    en: 'Panasonic Corporation',
    ja: 'Panasonic Corporation',
  },
  D216V0073: {
    en: '1006, Oaza Kadoma,',
    ja: '大字門真 1006',
  },
  D216V0074: {
    en: 'Kadoma-shi,',
    ja: '門真市',
  },
  D216V0075: {
    en: 'Osaka 571-8501,',
    ja: '571-8501 大阪府',
  },
  D216V0076: {
    en: 'Japan',
    ja: '日本',
  },
  D216V0077: {
    en: 'Tel. +81-6-6908-1121',
    ja: 'Tel. +81-6-6908-1121',
  },
  D216V0078: {
    en: 'kmatsushita@panasonic.co.jp',
    ja: 'kmatsushita@panasonic.co.jp',
  },
  D216V0079: {
    en: '+81-X-XXXX-XXXX',
    ja: '+81-X-XXXX-XXXX',
  },
  D216V0080: {
    en: 'DZK',
    ja: 'DZK',
  },
  D216V0081: {
    en: 'NEC',
    ja: 'NEC',
  },
  D216V0082: {
    en: 'EIK',
    ja: 'EIK',
  },
  D216V0083: {
    en: 'KNJ',
    ja: 'KNJ',
  },
}
// Display + 画面名
export default DisplayAirlineBidComfirm
