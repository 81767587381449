// Display + 画面名
const DisplayFwdrConfirmBidCarrier = {
  // 画面ID + 文言ID
  D114V0001: {
    en: 'CONFIRM BID',
    ja: '詳細を表示',
  },
  D114V0002: {
    en: 'Owner',
    ja: 'オーナー',
  },
  D114V0003: {
    en: 'Panasonic Corporation',
    ja: 'パナソニック株式会社',
  },
  D114V0004: {
    en: '1006, Oaza Kadoma, Kadoma-shi, Osaka 571-8501, Japan',
    ja: '571-8501大阪府門真市大字門真1006',
  },
  D114V0005: {
    en: 'Tel.',
    ja: '電話.',
  },
  D114V0006: {
    en: '+81-6-6908-1121',
    ja: '+81-6-6908-1121',
  },
  D114V0007: {
    en: 'Representative',
    ja: '代表',
  },
  D114V0008: {
    en: 'Konosuke Matsushita',
    ja: '松下幸之助',
  },
  D114V0009: {
    en: 'Manager',
    ja: 'マネージャー',
  },
  D114V0010: {
    en: 'Supervisor',
    ja: 'スーパーバイザー',
  },
  D114V0011: {
    en: 'Leader',
    ja: 'リーダー',
  },
  D114V0012: {
    en: '-',
    ja: '-',
  },
  D114V0013: {
    en: 'Lighting Dept.',
    ja: '照明部',
  },
  D114V0014: {
    en: 'kmatsushita@panasonic.co.jp / +81-X-XXX-XXXX',
    ja: 'kmatsushita@panasonic.co.jp / +81-X-XXX-XXXX',
  },
  D114V0015: {
    en: 'Validity',
    ja: '有効',
  },
  D114V0016: {
    en: 'August 1, 2020',
    ja: '2020年8月1日',
  },
  D114V0017: {
    en: 'to',
    ja: '～',
  },
  D114V0018: {
    en: 'January 31, 2021',
    ja: '2021年1月31日',
  },
  D114V0019: {
    en: 'Set FWDR',
    ja: 'FWDRを設定',
  },
  D114V0020: {
    en: 'DZK/KNJ/EIK/NEC/YLK/KWE/HEI',
    ja: 'DZK/KNJ/EIK/NEC/YLK/KWE/HEI',
  },
  D114V0021: {
    en: 'Set Carrier',
    ja: 'キャリアを設定',
  },
  D114V0022: {
    en: 'Speciafied',
    ja: '特定',
  },
  D114V0023: {
    en: 'Currency',
    ja: '通貨',
  },
  D114V0024: {
    en: 'JPY - Japanese Yen',
    ja: 'JPY-日本円',
  },
  D114V0025: {
    en: '1st Deadline',
    ja: '一次締め切り',
  },
  D114V0026: {
    en: 'July 31, 2020',
    ja: '2020年7月31日',
  },
  D114V0027: {
    en: 'Special Notes',
    ja: '特記事項',
  },
  D114V0028: {
    en: '・Please follow the schedule strictly.',
    ja: '・スケジュールは厳守してください。',
  },
  D114V0029: {
    en: "・We'll inform you as soon as we decide.",
    ja: '・決定次第お知らせします。',
  },
  D114V0030: {
    en: 'Request Lanes',
    ja: 'レーンをリクエスト',
  },
  D114V0031: {
    en: 'ORG/DST',
    ja: '出発/到着',
  },
  D114V0032: {
    en: 'Via',
    ja: '経由',
  },
  D114V0033: {
    en: 'TTL Wgt.',
    ja: 'TTL Wgt.',
  },
  D114V0034: {
    en: 'TTL Vol.',
    ja: 'TTL Vol.',
  },
  D114V0035: {
    en: 'SHC',
    ja: 'SHC',
  },
  D114V0036: {
    en: '+45kg',
    ja: '+45kg',
  },
  D114V0037: {
    en: '+100kg',
    ja: '+100kg',
  },
  D114V0038: {
    en: '+300kg',
    ja: '+300kg',
  },
  D114V0039: {
    en: '+500kg',
    ja: '+500kg',
  },
  D114V0040: {
    en: '+1,000kg',
    ja: '+1,000kg',
  },
  D114V0041: {
    en: 'NRT/MNL',
    ja: 'NRT/MNL',
  },
  D114V0042: {
    en: 'NRT/LON',
    ja: 'NRT/LON',
  },
  D114V0043: {
    en: 'NRT/ORD',
    ja: 'NRT/ORD',
  },
  D114V0044: {
    en: '1,000kg - 3000kg',
    ja: '1,000kg - 3000kg',
  },
  D114V0045: {
    en: '300m3 - 500m3',
    ja: '300m3 - 500m3',
  },
  D114V0046: {
    en: 'GEN',
    ja: 'GEN',
  },
  D114V0047: {
    en: 'DG',
    ja: 'DG',
  },
  D114V0048: {
    en: 'ICE',
    ja: 'ICE',
  },
  D114V0049: {
    en: '600',
    ja: '600',
  },
  D114V0050: {
    en: '850',
    ja: '850',
  },
  D114V0051: {
    en: '650',
    ja: '650',
  },
  D114V0052: {
    en: '540',
    ja: '540',
  },
  D114V0053: {
    en: '820',
    ja: '820',
  },
  D114V0054: {
    en: '620',
    ja: '620',
  },
  D114V0055: {
    en: '510',
    ja: '510',
  },
  D114V0056: {
    en: '780',
    ja: '780',
  },
  D114V0057: {
    en: '580',
    ja: '580',
  },
  D114V0058: {
    en: '480',
    ja: '480',
  },
  D114V0059: {
    en: '720',
    ja: '720',
  },
  D114V0060: {
    en: '530',
    ja: '530',
  },
  D114V0061: {
    en: '680',
    ja: '680',
  },
  D114V0062: {
    en: '440',
    ja: '440',
  },
  D114V0063: {
    en: 'Disclose',
    ja: '公開',
  },
  D114V0064: {
    en: 'JL/PR/5J/CX',
    ja: 'JL/PR/4J/CX',
  },
  D114V0065: {
    en: 'Undisclose',
    ja: '非公開',
  },
  D114V0066: {
    en: 'UA/DL/JL/NH',
    ja: 'UA/DL/JL/NH',
  },
  D114V0067: {
    en: 'Please click the box if you agree the above conditions.',
    ja: '上記の条件に同意される場合は、ボックスをクリックしてください。',
  },
  D114V0068: {
    en: 'BID',
    ja: '入札',
  },
  D114V0069: {
    en: '2nd Deadline',
    ja: '二次締め切り',
  },
  D114V0070: {
    en: '3rd Deadline',
    ja: '三次締め切り',
  },
  D114V0071: {
    en: 'August 31, 2020',
    ja: '2020年8月31日',
  },
}
// Display + 画面名
export default DisplayFwdrConfirmBidCarrier
