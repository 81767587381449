import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import AppContext from '../../../../../contexts/AppContext'
import Common from '../../../../../constants/Common'
import TableCell from '../../../../common/table/TableCell'
import ConstantsLightBookingRequest from '../ConstantsLightBookingRequest'
import EditLightBookingRequest from './EditLightBookingRequest'
import TextField from '../../../../common/text/TextField'

/** @typedef {import('../ConstantsLightBookingRequest').TableInfo} TableInfo テーブル情報 */

/**
 * 1行分の表データを作成
 * @param {Object} props 下記内容
 * @param {Number} props.index ページ上のインデックス
 * @return {JSX} TableCellコンポーネント(1行分)
 */
const TableCellsLightBookingRequest = ({ index }) => {
  const { state } = useContext(AppContext)

  /** @type {Object} 1行分のデータ */
  const contents = state.cargoDetailArr[index]
  const no = index + 1

  /**
   * 定数で定義した表データのコンポーネントを作成
   * @param {TableInfo} tableInfo テーブル情報
   * @param {Number} index mapのindex
   * @return {JSX} TableCellコンポーネント(1個分)
   */
  const createTableCell = (tableInfo, index) => {
    const { cellKey, cellCategory } = tableInfo
    const { CARGO_FLG } = ConstantsLightBookingRequest
    /** @type {*} セルに表示する内容 */
    let displayContent = null
    switch (cellCategory) {
      case Common.CELL_CATEGORY.NO:
        displayContent = `${no}.`
        break
      case Common.CELL_CATEGORY.TEXT_FIELD.DETAILS:
        displayContent = (
          <TextField
            value={contents[cellKey]}
            disabled={true}
            style={{ width: '90%' }}
          />
        )
        break
      case Common.CELL_CATEGORY.TEXT:
        displayContent =
          contents[cellKey] === Common.CARGO_DETAILS_FLG.YES
            ? CARGO_FLG.YES
            : CARGO_FLG.NO
        break
      case Common.CELL_CATEGORY.BUTTON.EDIT:
        displayContent = <EditLightBookingRequest row={contents} index={no} />
        break
      default:
        displayContent = null
    }
    return <TableCell key={index}>{displayContent}</TableCell>
  }

  return ConstantsLightBookingRequest.TABLE_ARR.map(createTableCell)
}

TableCellsLightBookingRequest.propTypes = {
  index: PropTypes.number.isRequired,
}

export default TableCellsLightBookingRequest
