import React from 'react'
import PropTypes from 'prop-types'

import EditDialog from '../06EditDialogLightFwdrAccountInfo/EditDialog'
import CommonFunc from '../../../../common/function/CommonFunc'

/**
 * @param {object} props 下記内容
 * @param {Number} props.row 貨物ID
 * @param {Number} props.index showDataインデックス
 * @param {Number} props.editIndex editDataインデックス
 * @return {JSX} EDITボタン
 */
const EditLightFwdrAccountInfo = (props) => {
  const { row, index, editIndex } = props

  // ログインユーザーチェック
  const loginUserChk = (id) => {
    const user = CommonFunc.getLoginUserData()
    return id === user.userId
  }

  return loginUserChk(row.userId) ? (
    ''
  ) : (
    <EditDialog row={row} index={index} editIndex={editIndex} />
  )
}

EditLightFwdrAccountInfo.propTypes = {
  row: PropTypes.object.isRequired,
  index: PropTypes.number,
  editIndex: PropTypes.number,
}

export default EditLightFwdrAccountInfo
