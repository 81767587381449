import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import CardButton from '../../../common/buttons/CardButton'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    [theme.breakpoints.up('md')]: {
      height: `calc(100vh - ${theme.header.height} * 2 - 1rem)`,
      minHeight: '310px',
    },
  },
  center: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '257px',
      margin: '0 auto',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
    },
  },
}))

/**
 * @param {Object} props - 以下
 * @param {string} joiningPath joiningBid画面へのパス
 * @returns {*} - ShipperTopButtons
 */
const ShipperTopButtons = (props) => {
  const classes = useStyles()

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item md={3} xs={12}>
        <div className={classes.center}>
          <CardButton path={props.joiningPath} />
        </div>
      </Grid>
    </Grid>
  )
}

export default ShipperTopButtons
