import { SET_FWDR } from '../../../actions'

import Common from '../../../constants/Common'

const initSetFwdrBidInfo = {
  // specifiedの場合true
  setFwdrFlg: Common.SET_FWDR_FLG.SPECIFIED,
  // selectForwarder画面で選択したFwdr一覧
  selectFwdrArr: [],
  // エラー状態 specifiedを選択しFwdrを誰も選択していない場合エラー
  isError: true,
}

/**
 * エラー状態を取得
 * @param {Number} bidStatusId 現在選択中の入札のステータスID
 * @param {Boolean} setFwdrFlg specifiedの選択状態
 * @param {Array} selectFwdrArr 選択したFwdr一覧
 * @return {Boolean} エラー状態
 */
const getIsError = (bidStatusId, setFwdrFlg, selectFwdrArr) => {
  // bidStatusがnullの時は入力中扱い
  const isInput = bidStatusId === null
  if (setFwdrFlg && isInput) {
    // 入力中でSpecifiedを選択した場合エラーチェック
    const fwdrCountNum = selectFwdrArr
      .map(({ fwdrthreeCode, delFlg }) => !delFlg && fwdrthreeCode)
      .filter((code) => code).length
    // 混載業者選択状態で1件も選択していないときはエラー
    return setFwdrFlg ? fwdrCountNum === 0 : false
  } else {
    // 確認時orUnspecifiedを選択時はエラーチェックする必要なし
    return false
  }
}

/**
 * BidInfo画面遷移時の初期state設定
 * @param {Object} action dispatchで受け取ったaction
 * @return {Object} SetFwdr情報を保持したstate
 */
const createAddressArr = (action) => {
  // 現在選択中の入札のステータスID
  const { bidStatusId } = action
  const { setFwdrFlg, selectFwdrArr } = action.data

  const isError = getIsError(bidStatusId, setFwdrFlg, selectFwdrArr)

  return {
    setFwdrFlg,
    selectFwdrArr,
    isError,
  }
}

/**
 * ラジオボタンを変更した場合のSetFwdr情報を更新
 * @param {Object} action dispatchで受け取ったaction
 * @param {Object} state SetFwdrBidInfoのstate
 * @return {Object} SetFwdr情報を保持したstate
 */
const updateSetFwdrFlg = (action, state) => {
  // ラジオボタンの選択、現在選択中の入札のステータスID
  const { selectStr, bidStatusId } = action
  const { selectFwdrArr } = state

  const setFwdrFlg = selectStr === Common.RADIO_SELECT.SPECIFIED
  const isError = getIsError(bidStatusId, setFwdrFlg, selectFwdrArr)

  return {
    ...state,
    setFwdrFlg,
    isError,
  }
}

/**
 * SetFwdr情報を保持
 * @param {Object} [state=initSetFwdrBidInfo] SetFwdrBidInfoのstate
 * @param {Object} action dispatchで受け取ったaction
 * @return {Object} SetFwdr情報を保持したstate
 */
const SetFwdrBidInfo = (state = initSetFwdrBidInfo, action) => {
  switch (action.type) {
    case SET_FWDR.INIT:
      return createAddressArr(action)
    case SET_FWDR.SELECT_RADIO:
      return updateSetFwdrFlg(action, state)
    default:
      return state
  }
}

export default SetFwdrBidInfo

export { initSetFwdrBidInfo }
