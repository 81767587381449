import { Grid } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

import HeaderTextRequired from '../../../../common/text/HeaderTextRequired'

/**
 * @return {JSX} SetForwarderヘッダーコンポーネント
 */
const RequiredHeaderSetForwarderBidInfo = () => {
  const { t } = useTranslation()
  return (
    <Grid item md={12} xs={12}>
      <HeaderTextRequired>{t('3rd0003')}</HeaderTextRequired>
    </Grid>
  )
}

export default RequiredHeaderSetForwarderBidInfo
