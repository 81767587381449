import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import PersonAddIcon from '@material-ui/icons/PersonAdd'

import PrimaryButton from '../../../common/buttons/PrimaryButton'
import SecondaryButton from '../../../common/buttons/SecondaryButton'
import XsmallButton from '../../../common/buttons/XsmallButton'
import AddButton from '../../../common/buttons/AddButton'
import Dialog from '../../../common/dialog/Dialog'
import EditIconButton from '../../../common/buttons/EditIconButton'
import TextField from '../../../common/text/TextField'
import CheckBox from '../../../common/checkBox/CheckBox'
import AppContext from '../../../../contexts/AppContext'
import CommonFunc from '../../../common/function/CommonFunc'
import {
  SEARCH_JH_TABLE,
  ADD_JH_TABLE,
  UPDATE_JH_TABLE,
  DELETE_JH_TABLE,
  UPDATE_JH_PAGING_DATA,
} from '../../../../actions'
import Common from '../../../../constants/Common'

const useStyles = makeStyles((theme) => ({
  text: theme.textNormalBLU,
  editbutton: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primary.main,
    fontSize: theme.fontSize.header,
    backgroundColor: theme.palette.info.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.fontColor.secondary,
    },
    '&:active': {
      backgroundColor: theme.palette.primary.light,
    },
    boxShadow: 'none',
    borderRadius: 25,
    border: '1px solid #595959',
    boxSizing: 'border-box',
    whiteSpace: 'nowrap',
    padding: '0px',
    textTransform: 'none',
  },
  addIcon: {
    color: theme.palette.primary.main,
    fontSize: 24,
  },
  blackText: theme.textNormalBLK,
}))

/**
 * 編集ダイアログコンポーネント
 * @param {Object} props 下記内容のProps
 * @param {Number} index 行数
 * @param {array} row 1行分のテーブルデータ
 * @param {Number} accountType ユーザー指定
 * @return {JSX} 編集ダイアログコンポーネント
 */
const EditDialog = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [inputErrs, setInputErrs] = useState({
    name: false,
    lastName: false,
    email: false,
    tel: false,
  })
  const [helperTxts, setHelperTxts] = useState({
    name: '',
    lastName: '',
    email: '',
    tel: '',
    signPicturePath: '',
  })
  const [row, setRow] = useState({
    name: '',
    lastName: '',
    email: '',
    tel: '',
    authorityFlg: false,
  })
  const { state, dispatch } = useContext(AppContext)

  // OKボタン有効無効チェック
  const disabledChk = () => {
    let error = false

    // エラー判定trueを検索
    error = Object.values(inputErrs).find((value) => value)

    // ADD時は1つでも未入力があればOKボタンを無効にする
    if (props.add !== undefined) {
      if (
        row.name === '' ||
        row.lastName === '' ||
        row.email === '' ||
        row.tel === ''
      ) {
        error = true
      }
    }
    return error
  }

  // テキストボックス入力変更時の動作
  const handleChange = (e) => {
    const value = e.target.value
    const id = e.target.id

    setRow({ ...row, [id]: value })
    // 入力チェック
    const validationCheck = CommonFunc.profileCheckFunc(id, value)
    const err = validationCheck.checkResult
    setInputErrs({ ...inputErrs, [id]: err })
    setHelperTxts({ ...helperTxts, [id]: t(validationCheck.errMsg) })
  }

  // Save前のE-mail重複チェック
  const handleChangeEmail = (e) => {
    const value = e.target.value
    const id = e.target.id

    setRow({ ...row, [id]: value })
    // 入力チェック
    const validationCheck = CommonFunc.profileCheckFunc(id, value)
    const err = validationCheck.checkResult
    setInputErrs({ ...inputErrs, [id]: err })
    setHelperTxts({ ...helperTxts, [id]: t(validationCheck.errMsg) })

    // 重複チェック(delFlgが立っているものと表示中のindexは除く)
    const emailArr = CommonFunc.getAccountInfoEmailArr(
      state.Tables.editData,
      props.index
    )
    if (emailArr.includes(value)) {
      setInputErrs({ ...inputErrs, [id]: true })
      setHelperTxts({ ...helperTxts, [id]: t('D301V0254') })
    }
  }

  // チェックボックス入力変更時の動作
  const handleChangeCheckBox = (e) => {
    const authorityFlg = e.target.checked
      ? Common.AUTHORITY_FLG.ON
      : Common.AUTHORITY_FLG.OFF
    setRow({ ...row, authorityFlg })
  }

  // OKボタン処理
  const updateTable = () => {
    if (props.add !== undefined) {
      // テーブル追加時
      dispatch({
        type: ADD_JH_TABLE,
        row,
      })
    } else {
      // テーブル編集時
      dispatch({
        type: UPDATE_JH_TABLE,
        index: props.index,
        row,
      })
    }

    dispatch({
      type: SEARCH_JH_TABLE,
      word: state.Search.word,
      authority: state.Search.authority,
    })
  }

  // Deleteボタン処理
  const deleteTable = () => {
    dispatch({
      type: DELETE_JH_TABLE,
      index: props.index,
    })
    dispatch({
      type: SEARCH_JH_TABLE,
      word: state.Search.word,
      authority: state.Search.authority,
    })

    if (state.Tables.showData[state.Paging.page].length === 1) {
      if (state.Paging.page !== 0) {
        // ページ内のデータがなくなったらページを1つ前に戻す
        dispatch({
          type: UPDATE_JH_PAGING_DATA,
          page: state.Paging.page - 1,
        })
      }
    }
  }
  // ダイアログ表示時 初期化処理
  const initialize = () => {
    setInputErrs({ name: false, lastName: false, email: false, tel: false })
    setHelperTxts({ name: '', lastName: '', email: '', tel: '' })
    if (props.row === undefined) {
      setRow({
        name: '',
        lastName: '',
        email: '',
        tel: '',
        authorityFlg: false,
        signPicturePath: '',
      })
    } else {
      setRow(props.row)
    }
  }

  return (
    <Dialog
      buttonPC={
        props.add ? (
          <AddButton onClick={initialize} />
        ) : (
          <XsmallButton addclass={classes.editbutton} onClick={initialize}>
            {t('D004V0061')}
          </XsmallButton>
        )
      }
      buttonMobile={
        props.add ? (
          <AddButton onClick={initialize} />
        ) : (
          <EditIconButton onClick={initialize} />
        )
      }
      primary={
        <PrimaryButton disabled={disabledChk()} onClick={updateTable}>
          {t('D004V0062')}
        </PrimaryButton>
      }
      secondary={<SecondaryButton>{t('D004V0063')}</SecondaryButton>}
      primaryDisabled={disabledChk()}
      delete={props.add ? undefined : deleteTable}
      row={props.row}
      accountType={props.accountType}
      account
      noborder
    >
      <div className={classes.formControl}>
        <Grid container>
          <Grid item md={12} xs={12} align="center">
            {props.add ? <PersonAddIcon className={classes.addIcon} /> : ''}
          </Grid>
          <Grid
            item
            style={{ marginBottom: '1rem' }}
            md={12}
            xs={12}
            align="center"
          >
            {props.add ? (
              <div className={classes.blackText}>{t('D301V0164')}</div>
            ) : (
              ''
            )}
          </Grid>
          <Grid item style={{ marginLeft: '5%' }} md={10} xs={10}>
            <div className={classes.text}>{t('D301V0007')}</div>
            <TextField
              id="name"
              error={inputErrs.name}
              helperText={helperTxts.name}
              value={row.name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item style={{ marginBottom: '2rem' }} md={10} xs={10} />
          <Grid item style={{ marginLeft: '5%' }} md={10} xs={10}>
            <div className={classes.text}>{t('D301V0008')}</div>
            <TextField
              id="lastName"
              error={inputErrs.lastName}
              helperText={helperTxts.lastName}
              value={row.lastName}
              onChange={handleChange}
            />
          </Grid>
          <Grid item style={{ marginBottom: '2rem' }} md={10} xs={10} />
          <Grid item style={{ marginLeft: '5%' }} md={10} xs={10}>
            <div className={classes.text}>{t('D301V0010')}</div>
            <TextField
              id="email"
              error={inputErrs.email}
              helperText={helperTxts.email}
              value={row.email}
              onChange={handleChangeEmail}
            />
          </Grid>
          <Grid item style={{ marginBottom: '2rem' }} md={10} xs={10} />
          <Grid item style={{ marginLeft: '5%' }} md={10} xs={10}>
            <div className={classes.text}>{t('D301V0011')}</div>
            <TextField
              id="tel"
              error={inputErrs.tel}
              helperText={helperTxts.tel}
              value={row.tel}
              onChange={handleChange}
            />
          </Grid>
          <Grid item style={{ marginBottom: '2rem' }} md={10} xs={10} />
          <Grid item style={{ marginLeft: '5%' }} md={10} xs={10}>
            <div className={classes.text}>
              {t('D301V0034')}
              <CheckBox
                onChange={handleChangeCheckBox}
                checked={Boolean(row.authorityFlg)}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  )
}

export default EditDialog
