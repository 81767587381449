import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import FindInPageIcon from '@material-ui/icons/FindInPage'

import AppContext from '../../../../../contexts/AppContext'
import { Badge, IconButton } from '@material-ui/core'
import ErrorOutline from '@material-ui/icons/ErrorOutline'
import CommonCalc from '../../../../common/function/CommonCalc'
import { LIGHT_FLIGHT_MGMT } from '../../../../../actions'

const useStyles = makeStyles((theme) => ({
  signIcon: {
    color: theme.palette.primary.main,
    fontSize: 36,
  },
  outlineIcon: {
    color: theme.palette.required,
    fontSize: 18,
    marginLeft: '-24px',
    marginTop: '-24px',
  },
}))

/**
 * @param {object} props 下記内容
 * @param {Number} props.index ページ上のインデックス
 * @return {JSX} アイコンボタン
 */
const IconButtonLightFlightMgmt = (props) => {
  const classes = useStyles()
  const { index } = props
  const { state, dispatch } = useContext(AppContext)

  /** @type {Number} state.showDataのインデックス */
  const page = CommonCalc.calcShowDataIndex(
    state.Paging,
    state.Tables.showData.length
  )

  /** @type {Object} アイコン情報オブジェクト */
  const iconObj = state.Tables.showData[page][index]

  /**
   * ボタン押下時のイベント
   * @returns {void} なし
   */
  const onClick = () => {
    // ポップアップに編集データをセット
    dispatch({
      type: LIGHT_FLIGHT_MGMT.ICON_POPUP.SET,
      iconObj,
    })

    // ポップアップを開く
    state.IconPopup.setOpen(true)
  }

  return (
    <>
      <IconButton onClick={onClick}>
        <FindInPageIcon className={classes.signIcon} />
      </IconButton>
      {iconObj.iconPath === '' ? (
        <Badge>
          <ErrorOutline className={classes.outlineIcon} />
        </Badge>
      ) : null}
    </>
  )
}

IconButtonLightFlightMgmt.propTypes = {
  index: PropTypes.number.isRequired,
}

export default IconButtonLightFlightMgmt
