import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import Common from '../../../../../constants/Common'
import AreYouSureDialog from '../../../../common/dialog/commonDesignDialog/AreYouSureDialog'
import AppContext from '../../../../../contexts/AppContext'
import CommonFunc from '../../../../common/function/CommonFunc'
import ApiPaths from '../../../../../constants/ApiPaths'
import { DIALOG } from '../../../../../actions'

/**
 * @return {JSX} Acceptダイアログコンポーネント
 */
const AcceptDialogLightJhClientRegDeleteAccount = () => {
  const { state, dispatch } = useContext(AppContext)
  const history = useHistory()

  /**
   * @returns {void} ダイアログを閉じる
   */
  const closeDialog = () =>
    dispatch({
      type: DIALOG.CLOSE,
    })

  /**
   * @returns {void} Acceptダイアログを閉じAPIを投げ成功時に保存完了ダイアログを表示
   */
  const acceptRequest = async () => {
    closeDialog()

    const { req } = state.Dialog
    CommonFunc.dispatchDialog.clickOk(
      dispatch,
      history,
      ApiPaths.JH.LIGHT_APPROVE_WITHDRAWAL,
      req
    )
  }

  const dialogFuncObj = {
    [Common.DIALOG.BUTTON_CATEGORY.CANCEL]: { onClick: closeDialog },
    [Common.DIALOG.BUTTON_CATEGORY.OK]: { onClick: acceptRequest },
  }

  const { isOpenAccept } = state.Dialog

  return (
    <AreYouSureDialog
      open={isOpenAccept}
      closeFunc={closeDialog}
      dialogFuncObj={dialogFuncObj}
    />
  )
}

export default AcceptDialogLightJhClientRegDeleteAccount
