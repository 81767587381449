import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import XsmallButton from '../../../common/buttons/XsmallButton'
import { LIGHT_BOOKING_DETAILS } from '../../../../actions'

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
}))

/**
 * @param {Object} props 下記内容
 * @param {Number} props.index 初期値
 * @param {Object} props.cargoDetail 貨物情報
 * @param {Function} props.dispatch dispatch
 * @return {JSX} Measure&Weight欄のEditボタン
 */
const EditButtonMeasureAndWeightLightBookingDetails = ({
  index,
  cargoDetail,
  dispatch,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  /**
   * ポップアップを開く
   * @returns {void} なし
   */
  const open = () => {
    dispatch({
      type: LIGHT_BOOKING_DETAILS.MEASURE_AND_WEIGHT_POPUP.OPEN,
      cargoDetail,
      index,
    })
  }

  return (
    <XsmallButton addclass={classes.button} onClick={open}>
      {t('D301V0035')}
    </XsmallButton>
  )
}
EditButtonMeasureAndWeightLightBookingDetails.propTypes = {
  index: PropTypes.number.isRequired,
  cargoDetail: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
}
export default EditButtonMeasureAndWeightLightBookingDetails
