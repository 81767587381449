import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import EditDialog from '../06EditDialogLightCarAccountInfo/EditDialog'
import AppContext from '../../../../../contexts/AppContext'
import CommonCalc from '../../../../common/function/CommonCalc'

const useStyles = makeStyles((theme) => ({
  marginTotal: {
    marginLeft: '10%',
    marginTop: '0.5rem',
  },
  marginAdd: {
    marginRight: '10%',
    marginTop: '0.5rem',
  },
  black: theme.textNormalBLK,
}))

const TotalAndAddLightCarAccountInfo = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { state } = useContext(AppContext)

  // delFlgがないもの
  const dataLength = state.Tables.editData.filter((data) => !data.delFlg)
  const dataLengthTotal = dataLength.length

  let displayContent = null
  if (state.UserPlan.maxAccount !== null) {
    // 最大人数がある場合「ユーザー数/最大人数」
    displayContent = `${t('D301V0036')} ${CommonCalc.addComma(dataLengthTotal)} / ${state.UserPlan.maxAccount}`
  } else {
    // 最大人数がない場合「ユーザー数」
    displayContent = `${t('D301V0036')} ${CommonCalc.addComma(dataLengthTotal)}`
  }

  return (
    <Grid container justify="center">
      {/* Total表示 */}
      <Grid item className={classes.marginTotal}>
        <div className={classes.black}>{displayContent}</div>
      </Grid>
      <Grid item xs />
      {/* ADDボタン */}
      <Grid item className={classes.marginAdd}>
        <EditDialog add />
      </Grid>
    </Grid>
  )
}

export default TotalAndAddLightCarAccountInfo
