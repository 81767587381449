/**
 * @property {string} DATA_CATEGORY DATA_CATEGORYの値
 */

const ConstantsLightCarCompanyInfo = {
  DATA_CATEGORY: {
    PLAN: 'plan',
    COMPANY: 'company',
    TWO_LETTER_CODE: 'twoLetterCode',
    POSTAL_CODE: 'postalCode',
    ADDRESS: 'address',
    DEPARTMENT: 'department',
    HUBS: 'hubs',
    COUNTRY: 'country',
    REPRESENTATIVE: 'Representative',
    POSITION: 'position',
    CONTACT_INFO: 'contactInformation',
    IATA_CASS_NUMBER: 'iataCassNo',
    ICON: 'icon',
  },
}

export default ConstantsLightCarCompanyInfo
