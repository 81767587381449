import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '400px',
    height: '90px',
    marginTop: '50px',
    borderColor: theme.palette.required,
  },
  icon: {
    color: theme.palette.required,
  },
  text: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.fontSize.regular,
    color: theme.palette.required,
    marginTop: '10px',
  },
}))

const ResetMessage = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <Grid container justify="center">
        <Card className={classes.root} variant="outlined">
          <CardContent>
            <Grid container justify="center" item md={12}>
              <ErrorOutlineIcon className={classes.icon} />
            </Grid>
            <Grid container justify="center" item md={12}>
              <div className={classes.text}>{t('D501V0077')}</div>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  )
}

export default ResetMessage
