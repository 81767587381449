import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import HeaderText from './HeaderText'

const useStyles = makeStyles((theme) => ({
  required: {
    color: theme.palette.required,
  },
}))

const HeaderTextRequired = (props) => {
  const classes = useStyles()
  return (
    <HeaderText>
      <span className={classes.required}>*</span>
      {props.children}
    </HeaderText>
  )
}

export default HeaderTextRequired
