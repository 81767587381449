import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'

import AppContext from '../../../../contexts/AppContext'
import AccordionLightBookingDetails from '../Accordion/AccordionLightBookingDetails'
import AirFreightShippingDetailsLightBookingDetails from './AirFreightShippingDetailsLightBookingDetails'
import CommodityShippingDetailsLightBookingDetails from './CommodityShippingDetailsLightBookingDetails'
import TitleAndContentShippingDetailsLightBookingDetails from './TitleAndContentShippingDetailsLightBookingDetails'

/**
 * @param {Object} props 下記内容
 * @param {Boolean} props.disabled 非活性状態
 * @param {Boolean} props.isShowRate A/F欄の入力欄を表示するか
 * @return {JSX} ShippingDetails欄
 */
const ShippingDetailsLightBookingDetails = ({ disabled, isShowRate }) => {
  const { t } = useTranslation()
  const { state, dispatch } = useContext(AppContext)
  const { org, dst, commodity } = state.ShippingDetails
  const {
    totalPcsStr,
    totalGrossWgtStr,
    totalVolumeStr,
    totalChargeableWgtStr,
    totalChargeableWgt,
  } = state.Cargo
  const { fuelSurChargeStr, currency } = state.Rate.rateObj

  /** @type{String} FSC欄に表示する内容 */
  const displayFuelSurCharge = state.Rate.isAllInRate
    ? t('2nd0018')
    : `${currency} ${fuelSurChargeStr} / kg`
  /** @type {String} AirFreight欄に表示する内容 */
  const airFreightStr = `${currency} ${state.Rate.getRateStr(totalChargeableWgt)} / kg`
  /** @type {String} TotalAirFreight欄に表示する内容 */
  const totalAirFreightStr = `${currency} ${state.Rate.getTotalRateStr(totalChargeableWgt)}`
  /** @type {String} レートの重量帯 */
  const rateName = t(state.Rate.getRateNameKey(totalChargeableWgt))

  // 数値の場合は小数点第2位までにし、nullの場合はそのまま
  const rateNum = state.Rate.rateObj.rate?.toFixed(2) ?? state.Rate.rateObj.rate
  const rateDefaultValue = disabled ? state.Rate.rateObj.rateStr : rateNum

  // A/FがMin料金より小さいか判定
  const isLessThanMinRate = state.Rate.getIsLessThanMinRate(totalChargeableWgt)

  // AirFreight欄
  const airFreight = useMemo(() => {
    return (
      <AirFreightShippingDetailsLightBookingDetails
        title={`${t('4th0011')} (${rateName})`}
        content={airFreightStr}
        defaultValue={isShowRate ? rateDefaultValue : undefined}
        dispatch={dispatch}
        disabled={disabled}
        isLessThanMinRate={isLessThanMinRate}
      />
    )
  }, [
    rateDefaultValue,
    dispatch,
    rateName,
    airFreightStr,
    isShowRate,
    disabled,
    isLessThanMinRate,
    t,
  ])

  return useMemo(
    () => (
      <AccordionLightBookingDetails label={t('D301V0067')}>
        <Grid container spacing={4}>
          <TitleAndContentShippingDetailsLightBookingDetails
            title={t('D301V0223')}
            content={org}
          />
          <TitleAndContentShippingDetailsLightBookingDetails
            title={t('D301V0224')}
            content={dst}
          />
          <CommodityShippingDetailsLightBookingDetails
            title={t('D301V0071')}
            content={commodity}
          />
          <TitleAndContentShippingDetailsLightBookingDetails
            title={t('D103V0003')}
            content={totalPcsStr}
          />
          <TitleAndContentShippingDetailsLightBookingDetails
            title={t('D103V0004')}
            content={totalGrossWgtStr}
          />
          <TitleAndContentShippingDetailsLightBookingDetails
            title={t('D103V0005')}
            content={totalVolumeStr}
          />
          <TitleAndContentShippingDetailsLightBookingDetails
            title={t('D103V0006')}
            content={totalChargeableWgtStr}
          />
          {airFreight}
          <TitleAndContentShippingDetailsLightBookingDetails
            title={t('2nd0017')}
            content={displayFuelSurCharge}
          />
          <TitleAndContentShippingDetailsLightBookingDetails
            title={t('4th0012')}
            content={totalAirFreightStr}
          />
        </Grid>
      </AccordionLightBookingDetails>
    ),
    [
      //eslint-disable-line
      org,
      dst,
      commodity,
      totalPcsStr,
      totalGrossWgtStr,
      totalVolumeStr,
      totalChargeableWgtStr,
      displayFuelSurCharge,
      totalAirFreightStr,
      t,
      disabled,
    ]
  )
}
ShippingDetailsLightBookingDetails.propTypes = {
  disabled: PropTypes.bool.isRequired,
  isShowRate: PropTypes.bool.isRequired,
}
export default ShippingDetailsLightBookingDetails
