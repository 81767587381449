import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles((Theme) => ({
  label: {
    ...Theme.textBoldBLU,
    textAlign: 'center',
  },
}))

/**
 * @param {Object} props 下記内容
 * @param {string} props.label 表示するテキスト
 * @return {JSX} ラベル
 */
const LabelEditPopup = ({ label }) => {
  const classes = useStyles()
  return <div className={classes.label}>{label}</div>
}
LabelEditPopup.propTypes = {
  label: PropTypes.string.isRequired,
}
export default LabelEditPopup
