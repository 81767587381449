import React, { useContext } from 'react'
import AppContext from '../../../../../contexts/AppContext'

import CommonTableContainer from '../../../../common/table/CommonTableContainer'
import HeaderBidDetails from '../../../../commonUseContext/Bid/Table/HeaderBidDetails'
import TableContentsConfirmation from './TableContentsConfirmation'
import { getTableArr } from '../ConstantsConfirmation'

/**
 * @return {JSX} 表コンポーネント(全体)
 */
const TableConfirmation = () => {
  const { state } = useContext(AppContext)
  /** @type {Boolean} 表データがない場合true */
  const isNoResult = state.IsNew ? false : state.Tables.showData[0].length <= 0

  /**テーブル情報の配列 */
  const tableArr = getTableArr(state.IsNew)

  return (
    <CommonTableContainer isNoResult={isNoResult}>
      <HeaderBidDetails tableArr={tableArr} getIsShowColumn={() => true} />
      <TableContentsConfirmation />
    </CommonTableContainer>
  )
}

export default TableConfirmation
