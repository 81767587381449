/* eslint-disable no-extra-parens */
import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import StarIcon from '@material-ui/icons/Star'

import DeleteDialog from './DeleteDialog'
import CommonFunc from '../../../common/function/CommonFunc'
import AppContext from '../../../../contexts/AppContext'

const useStyles = makeStyles((theme) => ({
  paper: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.info.main,
  },
  '@global': {
    '.MuiTableCell-root': {
      padding: '5px 0px',
    },
  },
  table: {
    minWidth: 230,
  },
  cell: {
    color: theme.palette.letter,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    borderLeft: `1px solid ${theme.palette.primary.main}`,
    fontSize: 16,
  },
  cellHead: {
    color: theme.palette.primary.main,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    borderLeft: `1px solid ${theme.palette.primary.main}`,
    fontWeight: 'bold',
    fontSize: 16,
  },
  cellBtn: {
    color: theme.palette.primary.main,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    borderLeft: `1px solid ${theme.palette.primary.main}`,
    fontSize: 16,
    width: 120,
  },
  cellFirst: {
    color: theme.palette.letter,
    border: `1px solid ${theme.palette.primary.main}`,
    fontSize: 16,
  },
  cellFirstHead: {
    color: theme.palette.primary.main,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    fontSize: 16,
    fontWeight: 'bold',
  },
  whitebg: {
    backgroundColor: theme.palette.info.main,
  },
  button: {
    padding: '0px 0px',
  },
  starIcon: {
    color: theme.palette.primary.main,
    fontSize: 24,
  },
}))

/**
 * @param {Object} props - 以下
 * @param {array} rows - テーブルデータ
 * @returns {*} - ConsigneeInfoの表コンポーネント
 */
const ConsigneeInfoTable = (props) => {
  const classes = useStyles()
  const { state } = useContext(AppContext)

  // rowsをスプレッド構文で分離
  const { rows } = props

  return rows[state.Paging.page].length > 0 ? (
    <TableContainer component={Paper} elevation={0} className={classes.paper}>
      <Table className={classes.table} aria-label="simple table">
        {rows[state.Paging.page].map((row, index) => (
          <TableBody key={index}>
            {row.delFlg !== 1 ? (
              <TableRow>
                <TableCell className={classes.cellFirst} align="center">
                  <div>
                    <Grid container alignItems="center">
                      <Grid
                        item
                        xs={1}
                        style={{ marginLeft: '3%', marginRight: '3%' }}
                      >
                        {row.favoriteFlg === 1 ? (
                          <StarIcon className={classes.starIcon} />
                        ) : (
                          ''
                        )}
                      </Grid>
                      <Grid item xs>
                        <Grid container alignItems="center">
                          <Grid
                            item
                            style={{ textAlign: 'left', marginRight: '5%' }}
                          >
                            {row.companyName}
                            <br />
                            {row.address} {row.postalCode}, {row.country}
                            <br />
                            Tel. {row.tel} Attn: {row.firstName} {row.lastName}
                          </Grid>
                        </Grid>
                        <Grid container justify="flex-end">
                          <Grid item style={{ marginRight: '5%' }}>
                            {CommonFunc.getLoginUserData().authorityFlg ===
                              1 && <DeleteDialog row={row} index={index} />}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              <></>
            )}
          </TableBody>
        ))}
      </Table>
    </TableContainer>
  ) : (
    ''
  )
}

export default ConsigneeInfoTable
