import { UPDATE_QUESTION3 } from '../../../actions'
import CommonValidation from '../../../components/common/function/CommonValidation'

const initQ3 = {
  text: '',
  texts: ['D501V0116'],
  choices: [],
  errors: false,
  helpers: '',
}
const QuestionData = (state, action) => {
  const check = CommonValidation.withdrawalReason(action.answer)
  const errors = check.checkResult
  const helpers = check.checkResult ? check.errMsg : ''
  const text = action.answer
  return { ...state, text, errors, helpers }
}

/**
 * state: Q3の回答を保持
 * @param {Object} [state={}] -
 * @param {Object} action -
 * @returns {*} -
 */
const Q3 = (state = initQ3, action) => {
  switch (action.type) {
    case UPDATE_QUESTION3:
      return QuestionData(state, action)
    default:
      return state
  }
}

export default Q3

export { initQ3 }
