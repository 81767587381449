import React, { useReducer, useEffect } from 'react'
import PropTypes from 'prop-types'

import LayoutBox from '../../../common/layout/LayoutBox'
import TitleBidDetails from './00TitleBidDetails/TitleBidDetails'
import CheckboxesBidDetails from './01CheckboxBidDetails/CheckboxesBidDetails'
import SearchSortBidDetails from './02SearchSortBidDetails/SearchSortBidDetails'
import TableBidDetails from './03TableBidDetails/TableBidDetails'
import AddBidDetails from './04AddButtonBidDetails/AddBidDetails'
import PagingBidDetails from './05PagingBidDetails/PagingBidDetails'
import ButtonBidDetails from './06ButtonBidDetails/ButtonBidDetails'
import AppContext from '../../../../contexts/AppContext'
import reducer, {
  initialState,
} from '../../../../reducers/shipper/shipperBidDetails'
import ConstantsBidDetails from './ConstantsBidDetails'
import {
  SHIPPER_BID_DETAILS,
  TABLES,
  SEARCH_SORT,
  API_DATA,
} from '../../../../actions'

/**
 * @param {*} props 前画面から取得したデータ
 * @return {JSX} 荷主BidDetails画面コンポーネント
 */
const ShipperBidDetails = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const data = props.history.location.state?.state

  /**新規画面・混載業者選択画面フラグ */
  const isNew =
    data.bidStatusObj.bidStatusId === null ||
    data.bidStatusObj.shipperDraftFlg === 1

  useEffect(() => {
    dispatch({
      type: API_DATA.DELETE,
    })

    //混載業者選択画面の場合、空欄のTransitを'-'に変更する
    if (!isNew) {
      data.bidDetailArr = data.bidDetailArr.map((data) => ({
        ...data,
        transitStr: data.transitStr === '' ? '-' : data.transitStr,
      }))
    }

    //テーブルの初期値を設定
    dispatch({
      type: TABLES.INIT,
      data:
        data.bidDetailArr.length === 0
          ? [ConstantsBidDetails.CREATE_INIT_DATA_OBJ()]
          : data.bidDetailArr,
      sort: ConstantsBidDetails.SORT_ITEM_ARR[
        ConstantsBidDetails.DEFAULT_SORT_TERM
      ].sortTerm,
    })

    //新規画面・混載業者選択画面フラグを設定
    dispatch({
      type: SHIPPER_BID_DETAILS.IS_NEW.SET,
      isNew,
    })

    //SearchSortの初期値を設定
    dispatch({
      type: SEARCH_SORT.SET,
      search: '',
      sort: ConstantsBidDetails.SORT_ITEM_ARR[
        ConstantsBidDetails.DEFAULT_SORT_TERM
      ].sortTerm,
    })

    dispatch({
      type: SHIPPER_BID_DETAILS.DATA.SET,
      data,
    })
  }, []) // eslint-disable-line

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <LayoutBox>
        <TitleBidDetails />
        <CheckboxesBidDetails />
        <SearchSortBidDetails />
        <TableBidDetails />
        <AddBidDetails />
        <PagingBidDetails />
        <ButtonBidDetails />
      </LayoutBox>
    </AppContext.Provider>
  )
}

ShipperBidDetails.propTypes = {
  history: PropTypes.object.isRequired,
}

export default ShipperBidDetails
