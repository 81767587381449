import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import TextField from '../../../common/text/TextField'
import AppContext from '../../../../contexts/AppContext'
import CommonValidation from '../../../common/function/CommonValidation'
import {
  UPDATE_JH_COMPANY_ADDRESS,
  UPDATE_JH_COMPANY_ADDRESS_INPUTERR,
} from '../../../../actions'

const useStyles = makeStyles((theme) => ({
  head: theme.textBoldBLU,
  // eslint-disable-next-line camelcase
  text_blk: theme.textNormalBLK,
}))

const CompanyAddress = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [inputErr, setInputErr] = useState(false)
  const [helperTxt, setHelperTxt] = useState('')
  const { state, dispatch } = useContext(AppContext)

  const handleChange = (event) => {
    const value = event.target.value
    // 入力チェック
    const validationCheck = CommonValidation.address(value)
    const err = validationCheck.checkResult
    setHelperTxt(validationCheck.errMsg)
    setInputErr(err)

    dispatch({
      type: UPDATE_JH_COMPANY_ADDRESS,
      address: value,
    })

    // 入力エラーの場合、Savaボタン無効
    dispatch({
      type: UPDATE_JH_COMPANY_ADDRESS_INPUTERR,
      inputErr: err,
    })
  }

  return (
    <div className={classes.formControl}>
      <Grid container>
        <Grid item md={10} xs={10}>
          <div className={classes.head}>{t('D301V0003')}</div>
        </Grid>
        <Grid item md={9} xs={10}>
          <TextField
            id="companyAddress"
            error={inputErr}
            helperText={t(helperTxt)}
            value={state.companyinfo.address.value}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default CompanyAddress
