import { UPDATE_QUESTION4 } from '../../../actions'
import Common from '../../../constants/Common'

const initQ4 = {
  check: '0',
  texts: ['D501V0117'],
  radios: [
    { text: 'D501V0118', value: Common.SATISFACTION.SATISFACTION.ID },
    { text: 'D501V0119', value: Common.SATISFACTION.SOMEWHAT_SATISFIED.ID },
    { text: 'D501V0120', value: Common.SATISFACTION.USUALLY.ID },
    { text: 'D501V0121', value: Common.SATISFACTION.SOMEWHAT_DISSATISFIED.ID },
    { text: 'D501V0122', value: Common.SATISFACTION.DISSATISFACTION.ID },
  ],
  type: UPDATE_QUESTION4,
}

/**
 * state: Q4の回答を保持
 * @param {Object} [state={}] -
 * @param {Object} action -
 * @returns {*} -
 */
const Q4 = (state = initQ4, action) => {
  switch (action.type) {
    case UPDATE_QUESTION4:
      return { ...state, check: action.answer }
    default:
      return state
  }
}

export default Q4

export { initQ4 }
