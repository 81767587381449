import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import { useHistory } from 'react-router-dom'

import AppContext from '../../../contexts/AppContext'
import Paths from '../../../constants/Paths'
import Theme from '../theme/Theme'
import CommonFunc from '../function/CommonFunc'

const useStyles = makeStyles(() => ({
  red: Theme.textNormalRED,
}))

const DeleteAccount = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const { state } = useContext(AppContext)
  const representativeFlg = state.companyinfo.representativeFlg.value

  // 編集用
  const createTextField = () => {
    return (
      <Grid item>
        <Link
          className={classes.red}
          onClick={() =>
            CommonFunc.clickEvent(
              { path: Paths.NOTIFY.LIGHT_DELETE_ACCOUNT },
              history
            )
          }
        >
          {t('D301V0020')}
        </Link>
      </Grid>
    )
  }

  return representativeFlg ? createTextField() : false
}

export default DeleteAccount
