// Display + 画面名
const DisplayShipperCargoTrace = {
  // 画面ID + 文言ID
  D005V0001: {
    en: 'CARGO TRACE',
    ja: '貨物追跡',
  },
  D005V0002: {
    en: 'Shipper, ORG/DST, etc…',
    ja: '荷送人、出発/到着など…',
  },
  D005V0003: {
    en: 'Lately',
    ja: '最近',
  },
  D005V0004: {
    en: 'Ready Date',
    ja: '出荷日',
  },
  D005V0005: {
    en: 'AWB No.',
    ja: 'AWB番号',
  },
  D005V0006: {
    en: 'Pcs./Wgt./Vol.',
    ja: '個数/重量/容積',
  },
  D005V0007: {
    en: 'ORG/DST',
    ja: '出発/到着',
  },
  D005V0008: {
    en: 'FWDR',
    ja: '混載業者',
  },
  D005V0009: {
    en: 'Status',
    ja: 'ステータス',
  },
  D005V0010: {
    en: 'Tracing',
    ja: '追跡',
  },
  D005V0011: {
    en: 'August 11, 2020',
    ja: '2020年8月11日',
  },
  D005V0012: {
    en: 'August 10, 2020',
    ja: '2020年8月10日',
  },
  D005V0013: {
    en: 'August 9, 2020',
    ja: '2020年8月9日',
  },
  D005V0014: {
    en: 'August 8, 2020',
    ja: '2020年8月8日',
  },
  D005V0015: {
    en: 'August 6, 2020',
    ja: '2020年8月6日',
  },
  D005V0016: {
    en: 'August 5, 2020',
    ja: '2020年8月5日',
  },
  D005V0017: {
    en: 'August 3, 2020',
    ja: '2020年8月3日',
  },
  D005V0018: {
    en: 'August 1, 2020',
    ja: '2020年8月1日',
  },
  D005V0019: {
    en: 'July 31, 2020',
    ja: '2020年7月31日',
  },
  D005V0020: {
    en: 'July 30, 2020',
    ja: '2020年7月30日',
  },
  D005V0021: {
    en: '-',
    ja: '-',
  },
  D005V0022: {
    en: 'XXX-XXXX XXXXX',
    ja: 'XXX-XXXX XXXXX',
  },
  D005V0023: {
    en: '10pcs/300kg/2.05m3',
    ja: '10pcs/300kg/2.05m3',
  },
  D005V0024: {
    en: 'KIX/SYD',
    ja: 'KIX/SYD',
  },
  D005V0025: {
    en: 'KIX/YYZ',
    ja: 'KIX/YYZ',
  },
  D005V0026: {
    en: 'KIX/MNL',
    ja: 'KIX/MNL',
  },
  D005V0027: {
    en: 'HND/SFO',
    ja: 'HND/SFO',
  },
  D005V0028: {
    en: 'KIX/HKG',
    ja: 'KIX/HKG',
  },
  D005V0029: {
    en: 'NRT/SVO',
    ja: 'NRT/SVO',
  },
  D005V0030: {
    en: 'KIX/BKK',
    ja: 'KIX/BKK',
  },
  D005V0031: {
    en: 'KIX/PVG',
    ja: 'KIX/PVG',
  },
  D005V0032: {
    en: 'FUK/TPE',
    ja: 'FUK/TPE',
  },
  D005V0033: {
    en: 'KIX/JFK',
    ja: 'KIX/JFK',
  },
  D005V0034: {
    en: 'HND/YUL',
    ja: 'HND/YUL',
  },
  D005V0035: {
    en: 'HND/MUC',
    ja: 'HND/MUC',
  },
  D005V0036: {
    en: 'KIX/HNL',
    ja: 'KIX/HNL',
  },
  D005V0037: {
    en: 'KIX/DMM',
    ja: 'KIX/DMM',
  },
  D005V0038: {
    en: 'KIX/DUB',
    ja: 'KIX/DUB',
  },
  D005V0039: {
    en: 'Yusen Logistics',
    ja: '郵船ロジスティクス',
  },
  D005V0040: {
    en: 'Nippon Express',
    ja: '日通',
  },
  D005V0041: {
    en: 'Expeditors',
    ja: 'エクスペダイターズジャパン',
  },
  D005V0042: {
    en: 'Panalpina',
    ja: 'パナルピナ',
  },
  D005V0043: {
    en: 'DB Schenker',
    ja: 'DBシェンカー',
  },
  D005V0044: {
    en: 'Hanshin Hankyu',
    ja: '阪神阪急',
  },
  D005V0045: {
    en: 'NNR Global Logistics',
    ja: 'NNRグローバルロジスティクス',
  },
  D005V0046: {
    en: 'DHL Global Fowarding',
    ja: 'DHLグローバルフォワーディング',
  },
  D005V0047: {
    en: 'Kuehne + Nagel',
    ja: 'キューネ+ナーゲル',
  },
  D005V0048: {
    en: 'CEVA Logistics',
    ja: 'CEVAロジスティクス',
  },
  D005V0049: {
    en: 'Kintetsu Express',
    ja: '近鉄エクスプレス',
  },
  D005V0050: {
    en: 'Nisshin',
    ja: '日清',
  },
  D005V0051: {
    en: 'K-Line Logistics',
    ja: 'K-Lineロジスティクス',
  },
  D005V0052: {
    en: 'Booking Request',
    ja: '予約リクエスト',
  },
  D005V0053: {
    en: 'Booking Confirmed',
    ja: '予約確認済',
  },
  D005V0054: {
    en: 'Space Request',
    ja: 'スペースリクエスト',
  },
  D005V0055: {
    en: 'Rejected',
    ja: '予約不可',
  },
  D005V0056: {
    en: 'Cancelled',
    ja: 'キャンセル',
  },
  D005V0057: {
    en: 'Booked',
    ja: '予約確定',
  },
  D005V0058: {
    en: 'In transit',
    ja: '輸送中',
  },
  D005V0059: {
    en: 'Arrived',
    ja: '到着済',
  },
  D005V0060: {
    en: 'Delivered',
    ja: '配送済',
  },
  D005V0061: {
    en: 'Check',
    ja: '確認',
  },
  D005V0062: {
    en: 'Back',
    ja: '戻る',
  },
  D005V0063: {
    en: 'NRT/CDG',
    ja: 'NRT/CDG',
  },
  D005V0064: {
    en: 'Sort by',
    ja: '並び替え',
  },
  D005V0065: {
    en: 'Inquiries',
    ja: '問い合わせ有無',
  },
  D005V0066: {
    en: 'Airline',
    ja: '航空会社',
  },
  D005V0067: {
    en: 'Ready Date',
    ja: '出荷日順',
  },
  D005V0068: {
    en: 'MAWB No. Ascending',
    ja: 'AwbNo昇順',
  },
  D005V0069: {
    en: 'MAWB No. Descending',
    ja: 'AwbNo降順',
  },
  D005V0070: {
    en: 'Pieces',
    ja: '個数順',
  },
  D005V0071: {
    en: 'Weight',
    ja: '重量順',
  },
  D005V0072: {
    en: 'Volume',
    ja: '容積順',
  },
  D005V0073: {
    en: 'Origin',
    ja: '出発空港順',
  },
  D005V0074: {
    en: 'Destination',
    ja: '到着空港順',
  },
  D005V0075: {
    en: 'FWDR',
    ja: '混載業者順',
  },
  D005V0076: {
    en: 'Status',
    ja: 'ステータス順',
  },
  D005V0077: {
    en: 'Inquiry',
    ja: '問い合わせ順',
  },
  D005V0078: {
    en: 'MAWB No.',
    ja: 'MAWB番号',
  },
}
// Display + 画面名
export default DisplayShipperCargoTrace
