/* eslint-disable prefer-template */
import React, { useState } from 'react'
import MDialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import DeleteDialog from './DeleteDialog'
import DeleteAccountDialog from './DeleteAccountDialog'
import CommonFunc from '../function/CommonFunc'

const useStyles = makeStyles((theme) => ({
  root: theme.dialog,
  dialog: theme.dialogContent,
  title: theme.textBoldBLU,
  titleAdd: {
    textAlign: 'center',
    margin: '5px 0',
  },
  borderTop: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    padding: 5,
  },
  transparent: {
    background: 'transparent',
    border: '0px solid',
  },
  mobile: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  desktop: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  margin: {
    margin: 10,
  },
  marginTop: {
    marginTop: '0.5rem',
  },
}))

/**
 * @param {Object} props - 以下
 * @param {*} buttonPC - PCのボタンコンポーネントを指定
 * @param {*} buttonMobile - スマホのボタンコンポーネントを指定
 * @param {*} primary - ダイアログ内のPrimaryボタンを指定
 * @param {*} secondary - ダイアログ内のSecondaryボタンを指定
 * @param {*} detail - ダイアログ内のDetailボタンを指定
 * @param {String} title - タイトルテキストを指定
 * @param {*} buttonDisabled - PC,スマホのボタンコンポーネントが押下可能か
 * @param {*} primaryDisabled - ダイアログ内のPrimaryボタンが押下可能か
 * @param {event} delete - deleteボタンが追加されます
 * @param {boolean} defaultOpen - 開いている状態を初期値にします
 * @param {boolean} forceButton - ボタンを押下しないと閉じられないようにします
 * @param {boolean} dialogCloseFlg - 親側からのダイアログを閉じるフラグ
 * @returns {JSX.Element} - Dialog
 */
const Dialog = (props) => {
  const classes = useStyles()
  // eslint-disable-next-line no-unneeded-ternary
  const [open, setOpen] = useState(props.defaultOpen ? true : false)
  const [scroll, setScroll] = useState('body')
  const [pageX, setPageX] = useState(0)

  const handleClickOpen = (scrollType) => () => {
    // windowのスクロール設定を記録してから非表示
    CommonFunc.hideScroll()
    setOpen(true)
    setScroll(scrollType)
  }

  /**
   * ダイアログを閉じる
   * @param {Event} event - onCloseのイベント
   * @param {Boolean} isDialogBackGround - ダイアログ背景をクリックしているかの判定
   * @returns {void}
   */
  const handleClose = (event, isDialogBackGround) => {
    /** 現在クリック位置が要素外(スクロール部分)をクリックしているか判定 */
    const isScrollClick = isDialogBackGround
      ? event.target.clientWidth - pageX < 0
      : false
    if (isScrollClick) {
      // スクロール部分をクリックしている場合は何もしない
      return
    } else {
      // windowのスクロール設定を戻す
      CommonFunc.showScroll()
      // ダイアログを閉じる
      if (isDialogBackGround && props.onClose) {
        props.onClose()
      }
      setOpen(false)
    }
  }

  /**
   * クリックした瞬間の位置を保存
   * @param {Event} event - event onMousedownのイベント
   * @returns {void}
   */
  const savePageXPosition = (event) => setPageX(event.pageX)

  return (
    <>
      {/* ボタン */}
      <div
        onClick={props.buttonDisabled !== true ? handleClickOpen('body') : null}
        className={classes.desktop}
      >
        {props.buttonPC}
      </div>
      <div
        onClick={props.buttonDisabled !== true ? handleClickOpen('body') : null}
        className={classes.mobile}
      >
        {props.buttonMobile}
      </div>

      {/* ダイアログ */}
      <MDialog
        // 親側からダイアログを閉じるようフラグが立っている場合そちらを優先
        open={props.dialogCloseFlg ? false : open}
        scroll={scroll}
        onClose={props.forceButton ? null : (e) => handleClose(e, true)}
        onMouseDown={savePageXPosition}
        className={classes.root + ' ' + props.className}
      >
        {/* ダイアログ本文 */}
        <DialogContent>
          <div
            className={
              props.noborder !== undefined
                ? classes.dialog + ' ' + classes.transparent
                : classes.dialog
            }
          >
            {/* タイトル */}
            {props.title !== undefined ? (
              <Grid
                item
                md={12}
                xs={12}
                className={
                  props.noborder !== undefined ? '' : classes.borderTop
                }
              >
                <Typography className={classes.title + ' ' + classes.titleAdd}>
                  {props.title}
                </Typography>
              </Grid>
            ) : (
              <></>
            )}
            {/* 本文 */}
            <Grid
              container
              justify="center"
              alignContent="center"
              spacing={1}
              className={classes.marginTop}
            >
              {props.children}
            </Grid>
          </div>
        </DialogContent>

        {/* ダイアログ下部のボタン */}
        <DialogActions>
          {/* スマホ */}
          <Grid
            container
            direction="column"
            alignContent="center"
            className={classes.mobile + ' ' + classes.margin}
            spacing={3}
          >
            {props.primary !== undefined ? (
              <Grid item md={12} xs={12}>
                <div className={classes.center}>
                  <div
                    onClick={
                      props.primaryDisabled !== true ? handleClose : null
                    }
                  >
                    {props.primary}
                  </div>
                </div>
              </Grid>
            ) : (
              <></>
            )}
            {props.secondary !== undefined ? (
              <Grid item md={12} xs={12}>
                <div className={classes.center}>
                  <div onClick={handleClose}>{props.secondary}</div>
                </div>
              </Grid>
            ) : (
              <></>
            )}
            {props.detail !== undefined ? (
              <Grid item md={12} xs={12}>
                <div className={classes.center}>
                  <div
                    onClick={
                      props.primaryDisabled !== true ? handleClose : null
                    }
                  >
                    {props.detail}
                  </div>
                </div>
              </Grid>
            ) : (
              <></>
            )}
            {props.delete !== undefined ? (
              <Grid item>
                <div className={classes.center}>
                  {props.account !== undefined ? (
                    <DeleteAccountDialog
                      onDelete={props.delete}
                      onClose={handleClose}
                      row={props.row}
                    />
                  ) : (
                    <DeleteDialog
                      onDelete={props.delete}
                      onClose={handleClose}
                    />
                  )}
                </div>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
          {/* PC */}
          <Grid
            container
            justify="center"
            spacing={3}
            className={classes.desktop + ' ' + classes.margin}
          >
            {props.detail !== undefined ? (
              <Grid item>
                <div className={classes.center}>
                  <div
                    onClick={
                      props.primaryDisabled !== true ? handleClose : null
                    }
                  >
                    {props.detail}
                  </div>
                </div>
              </Grid>
            ) : (
              <></>
            )}
            {props.delete !== undefined ? (
              <Grid item>
                <div className={classes.center}>
                  {props.account !== undefined ? (
                    <DeleteAccountDialog
                      onDelete={props.delete}
                      onClose={handleClose}
                      row={props.row}
                      accountType={props.accountType}
                    />
                  ) : (
                    <DeleteDialog
                      onDelete={props.delete}
                      onClose={handleClose}
                    />
                  )}
                </div>
              </Grid>
            ) : (
              <></>
            )}
            {props.secondary !== undefined ? (
              <Grid item>
                <div className={classes.center}>
                  <div onClick={handleClose}>{props.secondary}</div>
                </div>
              </Grid>
            ) : (
              <></>
            )}
            {props.primary !== undefined ? (
              <Grid item>
                <div className={classes.center}>
                  <div
                    onClick={
                      props.primaryDisabled !== true ? handleClose : null
                    }
                  >
                    {props.primary}
                  </div>
                </div>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </DialogActions>
      </MDialog>
    </>
  )
}

export default Dialog
