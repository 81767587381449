import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'

import TimeLine from '../../../common/timeLine/TimeLine'

const useStyles = makeStyles((theme) => ({
  icon: {
    marginLeft: '8px',
    width: 25,
    height: 25,
    paddingRight: '11px',
  },
  container: theme.textBoldBLK,
}))

/**
 * @param {Object} props 下記内容
 * @param {Object} props.flightInfo 航空便情報
 * @return {JSX} 航空便情報欄
 */
const FlightInfoLightBookingDetails = ({ flightInfo }) => {
  const classes = useStyles()
  const { iconPath, info, line } = flightInfo
  const defaultIconPath = '../../../../images/CargoLabo_logo_color.svg'

  return (
    <Grid
      container
      alignItems="center"
      item
      md={12}
      className={classes.container}
    >
      <Grid item>
        <img
          className={classes.icon}
          src={iconPath ?? defaultIconPath}
          alt="icon"
        />
      </Grid>
      <Grid item>
        <span>{info}</span>
      </Grid>
      <Grid item md={12}>
        <TimeLine line={line} />
      </Grid>
    </Grid>
  )
}
FlightInfoLightBookingDetails.propTypes = {
  flightInfo: PropTypes.object.isRequired,
}
export default FlightInfoLightBookingDetails
