import {
  UPDATE_JH_COMPANY_NAME,
  UPDATE_JH_COMPANY_ADDRESS,
  UPDATE_JH_FIRST_NAME,
  UPDATE_JH_LAST_NAME,
  UPDATE_JH_MAIL,
  UPDATE_JH_TEL,
  UPDATE_JH_ICON_PATH,
  UPDATE_JH_POSTAL_CODE,
  UPDATE_JH_UPDATE_AT,
  UPDATE_JH_IS_DELETE_ICON,
  UPDATE_JH_COMPANY_NAME_INPUTERR,
  UPDATE_JH_COMPANY_ADDRESS_INPUTERR,
  UPDATE_JH_FIRST_NAME_INPUTERR,
  UPDATE_JH_LAST_NAME_INPUTERR,
  UPDATE_JH_MAIL_INPUTERR,
  UPDATE_JH_TEL_INPUTERR,
  UPDATE_JH_POSTAL_CODE_INPUTERR,
  UPDATE_JH_ICON_PATH_INPUTERR,
} from '../../../actions'

const Events = (state = {}, action) => {
  switch (action.type) {
    //------------------
    // companyNameを更新
    //------------------
    case UPDATE_JH_COMPANY_NAME:
      return {
        ...state,
        companyName: { ...state.companyName, value: action.companyName },
      }
    case UPDATE_JH_COMPANY_NAME_INPUTERR:
      return {
        ...state,
        companyName: { ...state.companyName, inputErr: action.inputErr },
      }
    //------------------
    // postalCodeを更新
    //------------------
    case UPDATE_JH_POSTAL_CODE:
      return {
        ...state,
        postalCode: { ...state.postalCode, value: action.postalCode },
      }
    case UPDATE_JH_POSTAL_CODE_INPUTERR:
      return {
        ...state,
        postalCode: { ...state.postalCode, inputErr: action.inputErr },
      }
    //------------------
    // addressを更新
    //------------------
    case UPDATE_JH_COMPANY_ADDRESS:
      return { ...state, address: { ...state.address, value: action.address } }
    case UPDATE_JH_COMPANY_ADDRESS_INPUTERR:
      return {
        ...state,
        address: { ...state.address, inputErr: action.inputErr },
      }
    //------------------
    // firstNameを更新
    //------------------
    case UPDATE_JH_FIRST_NAME:
      return {
        ...state,
        firstName: { ...state.firstName, value: action.firstName },
      }
    case UPDATE_JH_FIRST_NAME_INPUTERR:
      return {
        ...state,
        firstName: { ...state.firstName, inputErr: action.inputErr },
      }
    //------------------
    // lastNameを更新
    //------------------
    case UPDATE_JH_LAST_NAME:
      return {
        ...state,
        lastName: { ...state.lastName, value: action.lastName },
      }
    case UPDATE_JH_LAST_NAME_INPUTERR:
      return {
        ...state,
        lastName: { ...state.lastName, inputErr: action.inputErr },
      }
    //------------------
    // mailを更新
    //------------------
    case UPDATE_JH_MAIL:
      return { ...state, mail: { ...state.mail, value: action.mail } }
    case UPDATE_JH_MAIL_INPUTERR:
      return { ...state, mail: { ...state.mail, inputErr: action.inputErr } }
    //------------------
    // telを更新
    //------------------
    case UPDATE_JH_TEL:
      return { ...state, tel: { ...state.tel, value: action.tel } }
    case UPDATE_JH_TEL_INPUTERR:
      return { ...state, tel: { ...state.tel, inputErr: action.inputErr } }
    //------------------
    // iconを更新
    //------------------
    case UPDATE_JH_ICON_PATH:
      return { ...state, icon: { ...state.icon, value: action.icon } }
    case UPDATE_JH_ICON_PATH_INPUTERR:
      return { ...state, icon: { ...state.icon, inputErr: action.inputErr } }
    //------------------
    // updatedAtを更新
    //------------------
    case UPDATE_JH_UPDATE_AT:
      return {
        ...state,
        updatedAt: { ...state.updatedAt, value: action.updatedAt },
      }
    //------------------
    // isDeleteIconを更新
    //------------------
    case UPDATE_JH_IS_DELETE_ICON:
      return {
        ...state,
        isDeleteIcon: { ...state.isDeleteIcon, value: action.isDeleteIcon },
      }
    default:
      return state
  }
}

export default Events
