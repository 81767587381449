import { UPDATE_SHIP_FILE } from '../../../actions'

/**
 * state: file情報を保存
 * @param {Object} [state={}] -
 * @param {Object} action -
 * @returns {*} -
 */
const File = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_SHIP_FILE:
      return { ...state, file: action.file }
    default:
      return state
  }
}

export default File
