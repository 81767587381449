import { PIC } from '../../../actions'
import Common from '../../../constants/Common'

const initPicBidInfo = {
  // delFlg含めた全PIC
  picArr: [],
  // delFlg除いたPIC
  filterPicArr: [],
  // 登録済みPIC
  registeredPicArr: [],
  // 新規追加PIC
  addPicArr: [],
  // 選択中ユーザID一覧
  userIdArr: [],
  // 編集中PIC情報
  editPicObj: {},
  // セレクトボックスで選択中PIC情報
  selectPicObj: {},
  // ダイアログの開閉状態
  isOpenDialog: false,
  // セレクトボックスのアカウント情報
  accountInfoArr: [],
  // エラー状態
  isError: false,
}

/**
 * 登録済み、追加したPICから必要情報を作成
 * @param {Array} registeredPicArr 登録済みPIC
 * @param {Array} addPicArr 追加したPIC
 * @return {Array} 登録済み、追加したPIC、それらを合わせたPIC、エラー状態
 */
const getUpdatePicObj = (registeredPicArr, addPicArr) => {
  // 表示用にデータを合成
  const picArr = [...registeredPicArr, ...addPicArr]
  // delFlgを除く
  const filterPicArr = picArr.filter(({ delFlg }) => !delFlg)
  // 空になったらエラー
  const isError = filterPicArr.length === 0
  // 選択中ユーザID一覧
  const userIdArr = filterPicArr.map(({ userId }) => userId)
  return {
    registeredPicArr,
    addPicArr,
    picArr,
    filterPicArr,
    isError,
    userIdArr,
  }
}

/**
 * BidInfo画面遷移時の初期state設定
 * @param {Object} state PicBidInfoのstate
 * @param {Object} action dispatchで受け取ったaction
 * @return {Object} Pic情報を保持したstate
 */
const createPicObj = (state, action) => {
  // 選択中PIC一覧、選択可能アカウント一覧
  const { picArr, accountInfoArr } = action

  const convertedAccountInfoArr = accountInfoArr.map((accountInfoObj) => {
    const { name, lastName, position, department, email, tel, userId } =
      accountInfoObj
    const fullName = `${name} ${lastName}`
    return {
      name: fullName,
      position,
      department,
      email,
      tel,
      userId,
    }
  })

  // 二度目の遷移の場合登録済み、追加PICが混ざっているので分ける
  const registeredPicArr = picArr
    .map((picObj) => (picObj.bidInChargeId ? picObj : null))
    .filter((picObj) => picObj)
  const addPicArr = picArr
    .map((picObj) => (picObj.bidInChargeId ? null : picObj))
    .filter((picObj) => picObj)
  const picObj = getUpdatePicObj(registeredPicArr, addPicArr)

  return {
    ...state,
    accountInfoArr: convertedAccountInfoArr,
    ...picObj,
  }
}

/**
 * Addボタンを押した場合のダイアログを表示
 * @param {Object} state PicBidInfoのstate
 * @return {Object} Pic情報を保持したstate
 */
const openAddDialog = (state) => {
  return {
    ...state,
    isOpenDialog: true,
    editPicObj: {},
    selectPicObj: {},
  }
}

/**
 * Editボタンを押した場合のダイアログを表示
 * @param {Object} state PicBidInfoのstate
 * @param {Object} action dispatchで受け取ったaction
 * @return {Object} Pic情報を保持したstate
 */
const openEditDialog = (state, action) => {
  // Editボタンを押下したPICのユーザID
  const { editUserId } = action
  const { filterPicArr } = state
  const editPicObj =
    filterPicArr.find(({ userId }) => userId === editUserId) ?? {}

  return {
    ...state,
    isOpenDialog: true,
    editPicObj,
    selectPicObj: editPicObj,
  }
}

/**
 * 更新したPIC情報一覧を取得
 * @param {Array} picArr PIC情報一覧
 * @param {Object} editPicObj EditボタンをクリックしたPIC情報
 * @param {Object} selectPicObj セレクトボックスで選択中PIC情報
 * @return {Array} 更新したPIC情報一覧
 */
const getNewPicArr = (picArr, editPicObj, selectPicObj) =>
  picArr.map((currentPicObj) => {
    const isTarget = currentPicObj.userId === editPicObj.userId
    const newPicObj = isTarget
      ? { ...currentPicObj, ...selectPicObj }
      : currentPicObj
    return newPicObj
  })

/**
 * EditボタンをクリックしたPIC情報を更新
 * @param {Object} state PicBidInfoのstate
 * @return {Object} Pic情報を保持したstate
 */
const updatePicObj = (state) => {
  const { addPicArr, registeredPicArr, editPicObj, selectPicObj } = state
  const newRegisteredPicArr = getNewPicArr(
    registeredPicArr,
    editPicObj,
    selectPicObj
  )
  const newAddPicArr = getNewPicArr(addPicArr, editPicObj, selectPicObj)

  const picObj = getUpdatePicObj(newRegisteredPicArr, newAddPicArr)
  return {
    ...state,
    isOpenDialog: false,
    ...picObj,
  }
}

/**
 * AddボタンでPIC情報を更新
 * @param {Object} state PicBidInfoのstate
 * @return {Object} Pic情報を保持したstate
 */
const addPicObj = (state) => {
  const { addPicArr, registeredPicArr, selectPicObj } = state
  // 追加したPICに空の情報を追加
  const convertedSelectPicObj = {
    bidInChargeId: null,
    delFlg: 0,
    mainPersonFlg: 0,
    updatedAt: '',
    ...selectPicObj,
  }
  const newAddPicArr = [...addPicArr, convertedSelectPicObj]

  const picObj = getUpdatePicObj(registeredPicArr, newAddPicArr)

  return {
    ...state,
    isOpenDialog: false,
    ...picObj,
  }
}

/**
 * 登録済みのPICを削除
 * @param {Object} state PicBidInfoのstate
 * @return {Object} Pic情報を保持したstate
 */
const deleteRegisteredPicArr = (state) => {
  const { registeredPicArr, editPicObj } = state

  return registeredPicArr.map((currentPicObj) => {
    const isTarget = currentPicObj.userId === editPicObj.userId
    // 登録済みのPICはdelFlgを立てる
    const newPicObj = isTarget
      ? { ...currentPicObj, delFlg: Common.DEL_FLG.ON }
      : currentPicObj
    return newPicObj
  })
}

/**
 * 新規追加したPICを削除
 * @param {Object} state PicBidInfoのstate
 * @return {Object} Pic情報を保持したstate
 */
const deleteAddPicArr = (state) => {
  const { addPicArr, editPicObj } = state

  return addPicArr
    .map((currentPicObj) => {
      const isTarget = currentPicObj.userId === editPicObj.userId
      // 追加したPICは直接削除
      const newPicObj = isTarget ? {} : currentPicObj
      return newPicObj
    })
    .filter((picObj) => picObj.userId)
}

/**
 * PICを削除
 * @param {Object} state PicBidInfoのstate
 * @return {Object} Pic情報を保持したstate
 */
const deletePicObj = (state) => {
  const { addPicArr, registeredPicArr, editPicObj } = state

  // クリックしたPIC情報にbidInChargeIdがある場合は登録済みのPIC
  const isRegisteredPic = Boolean(editPicObj.bidInChargeId)
  const newRegisteredPicArr = isRegisteredPic
    ? deleteRegisteredPicArr(state)
    : registeredPicArr
  const newAddPicArr = isRegisteredPic ? addPicArr : deleteAddPicArr(state)

  const picObj = getUpdatePicObj(newRegisteredPicArr, newAddPicArr)

  return {
    ...state,
    isOpenDialog: false,
    ...picObj,
  }
}

/**
 * Pic情報を保持
 * @param {Object} [state=initPicBidInfo] PicBidInfoのstate
 * @param {Object} action dispatchで受け取ったaction
 * @return {Object} Pic情報を保持したstate
 */
const PicBidInfo = (state = initPicBidInfo, action) => {
  switch (action.type) {
    case PIC.INIT:
      return createPicObj(state, action)
    case PIC.OPEN_ADD_DIALOG:
      return openAddDialog(state)
    case PIC.OPEN_EDIT_DIALOG:
      return openEditDialog(state, action)
    case PIC.CLOSE_DIALOG:
      return { ...state, isOpenDialog: false }
    case PIC.SELECT:
      return { ...state, selectPicObj: action.accountInfoObj }
    case PIC.UPDATE:
      return updatePicObj(state)
    case PIC.ADD:
      return addPicObj(state)
    case PIC.DELETE:
      return deletePicObj(state)
    default:
      return state
  }
}

export default PicBidInfo

export { initPicBidInfo }
