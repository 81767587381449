import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  noResult: theme.noResult,
}))

/**
 * 表のデータがない時のNo Resultコンポーネント
 * @return {JSX} No Resultコンポーネント
 */
const CommonNoResult = () => {
  const classes = useStyles()

  return <div className={classes.noResult}>No Result</div>
}

export default CommonNoResult
