import React from 'react'
import { Grid } from '@material-ui/core'

import DatePickerValidityBidInfo from './DatePickerValidityBidInfo'
import HeaderValidityBidInfo from './HeaderValidityBidInfo'

/**
 * @return {JSX} Validityコンポーネント
 */
const ValidityBidInfo = () => {
  return (
    <Grid item container justify="flex-start">
      <HeaderValidityBidInfo />
      <DatePickerValidityBidInfo />
    </Grid>
  )
}

export default ValidityBidInfo
