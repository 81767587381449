import React, { useContext } from 'react'

import AppContext from '../../../../../contexts/AppContext'
import RemarkLightBookingDetails from '../../../../commonUseContext/LightBookingDetails/Remark/RemarkLightBookingDetails'

/**
 * @return {JSX} Remarkアコーディオン
 */
const RemarkFwdrLightBookingDetails = () => {
  const { state } = useContext(AppContext)
  const editable = !(state.Status.isCancel || state.Status.isClose)
  return <RemarkLightBookingDetails editable={editable} />
}

export default RemarkFwdrLightBookingDetails
