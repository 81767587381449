import React, { useContext } from 'react'
import { Grid, makeStyles } from '@material-ui/core'

import AddButton from '../../../../common/buttons/AddButton'
import ConstantsBidDetails from '../ConstantsBidDetails'
import AppContext from '../../../../../contexts/AppContext'
import { TABLES } from '../../../../../actions'
import Common from '../../../../../constants/Common'

const useStyles = makeStyles((theme) => ({
  margin: theme.addMargin,
  right: theme.addRight,
}))

/**
 * @return {*} Addボタンコンポーネント
 */
const AddBidDetails = () => {
  const classes = useStyles()
  const { state, dispatch } = useContext(AppContext)

  /** 非活性判定 */
  const isDisabled =
    state.Tables.editData.filter(({ delFlg }) => delFlg !== Common.DEL_FLG.ON)
      .length >= ConstantsBidDetails.MAX_AMOUNT

  /**
   * 1行追加
   * @return {void}
   */
  const addRow = () => {
    dispatch({
      type: TABLES.EDIT,
      isAdd: true,
    })
  }

  /**
   * Addボタンを作成する
   * @returns {JSX} Addボタン
   */
  const createAddButton = () => (
    <Grid container justify="center" className={classes.margin}>
      <Grid item md={10} xs={10} className={classes.right}>
        <AddButton onClick={addRow} disabled={isDisabled} />
      </Grid>
    </Grid>
  )

  return state.IsNew ? createAddButton() : null
}

export default AddBidDetails
