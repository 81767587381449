import React, { useReducer, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import LayoutLog from '../../../common/layout/LayoutLog'
import AppContext from '../../../../contexts/AppContext'
import CommonFunc from '../../../common/function/CommonFunc'
import ApiPaths from '../../../../constants/ApiPaths'
import reducer, {
  initialState,
} from '../../../../reducers/authentication/lightShipperRegisterNewAccount'
import { LIGHT_REGISTER_NEW_ACCOUNT } from '../../../../actions'
import TitleLightShipperRegisterNewAccount from './00TitleLightShipperRegisterNewAccount/TitleLightShipperRegisterNewAccount'
import CompanyLightShipperRegisterNewAccount from './01CompanyLightShipperRegisterNewAccount/CompanyLightShipperRegisterNewAccount'
import ShipperConsigneeIdLightShipperRegisterNewAccount from './02ShipperConsigneeIdLightShipperRegisterNewAccount/ShipperConsigneeIdLightShipperRegisterNewAccount'
import BranchLightShipperRegisterNewAccount from './03BranchLightShipperRegisterNewAccount/BranchLightShipperRegisterNewAccount'
import FirstNameLightShipperRegisterNewAccount from './04FirstNameLightShipperRegisterNewAccount/FirstNameLightShipperRegisterNewAccount'
import LastNameLightShipperRegisterNewAccount from './05LastNameLightShipperRegisterNewAccount/LastNameLightShipperRegisterNewAccount'
import PositionLightShipperRegisterNewAccount from './06PositionLightShipperRegisterNewAccount/PositionLightShipperRegisterNewAccount'
import EmailLightShipperRegisterNewAccount from './07EmailLightShipperRegisterNewAccount/EmailLightShipperRegisterNewAccount'
import TelLightShipperRegisterNewAccount from './08TelLightShipperRegisterNewAccount/TelLightShipperRegisterNewAccount'
import PostalCodeLightShipperRegisterNewAccount from './09PostalCodeLightShipperRegisterNewAccount/PostalCodeLightShipperRegisterNewAccount'
import AddressLightShipperRegisterNewAccount from './10AddressLightShipperRegisterNewAccount/AddressLightShipperRegisterNewAccount'
import AirportLightShipperRegisterNewAccount from './11AirportLightShipperRegisterNewAccount/AirportLightShipperRegisterNewAccount'
import IconLightShipperRegisterNewAccount from './12IconLightShipperRegisterNewAccount/IconLightShipperRegisterNewAccount'
import RequireLightShipperRegisterNewAccount from './13RequireLightShipperRegisterNewAccount/RequireLightShipperRegisterNewAccount'
import CheckLightShipperRegisterNewAccount from './14CheckLightShipperRegisterNewAccount/CheckLightShipperRegisterNewAccount'
import ButtonLightShipperRegisterNewAccount from './15ButtonLightShipperRegisterNewAccount/ButtonLightShipperRegisterNewAccount'

const ShipperRegisterNewAccount = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const history = useHistory()

  /** 初期処理 */
  useEffect(() => {
    ;(async () => {
      const apiInfoArr = [
        { key: 'cityArr', method: 'get', path: ApiPaths.MASTER_JH.CITY_CODE },
        { key: 'airportArr', method: 'get', path: ApiPaths.MASTER_JH.AIRPORT },
      ]
      const resObj = await CommonFunc.execApiAsync(apiInfoArr)
      const isApiSuccessful = CommonFunc.checkApiResponseObj(resObj, history)

      if (isApiSuccessful) {
        const resArr = Object.entries(resObj).map(([keyStr, res]) => [
          keyStr,
          res.data.results,
        ])
        const stateObj = Object.fromEntries(resArr)
        dispatch({
          type: LIGHT_REGISTER_NEW_ACCOUNT.SELECT_DATA.INIT,
          data: stateObj,
        })
      }
    })()
  }, []) // eslint-disable-line

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <LayoutLog>
        <TitleLightShipperRegisterNewAccount />
        <CompanyLightShipperRegisterNewAccount />
        <ShipperConsigneeIdLightShipperRegisterNewAccount />
        <BranchLightShipperRegisterNewAccount />
        <FirstNameLightShipperRegisterNewAccount />
        <LastNameLightShipperRegisterNewAccount />
        <PositionLightShipperRegisterNewAccount />
        <EmailLightShipperRegisterNewAccount />
        <TelLightShipperRegisterNewAccount />
        <PostalCodeLightShipperRegisterNewAccount />
        <AddressLightShipperRegisterNewAccount />
        <AirportLightShipperRegisterNewAccount />
        <IconLightShipperRegisterNewAccount />
        <RequireLightShipperRegisterNewAccount />
        <CheckLightShipperRegisterNewAccount />
        <ButtonLightShipperRegisterNewAccount />
      </LayoutLog>
    </AppContext.Provider>
  )
}

export default ShipperRegisterNewAccount
