import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import Common from '../../../../../constants/Common'
import ConstantsBidDetails, { getIsShowColumn } from '../ConstantsBidDetails'
import SelectCellBidDetails from '../../../../commonUseContext/Bid/Table/SelectCellBidDetails'
import TextFieldCellBidDetails from '../../../../commonUseContext/Bid/Table/TextFieldCellBidDetails'
import TableCellBidDetails from '../../../../commonUseContext/Bid/Table/TableCellBidDetails'
import CheckboxCellBidDetails from './CheckboxCellBidDetails'
import CommonValidation from '../../../../common/function/CommonValidation'
import AppContext from '../../../../../contexts/AppContext'

/**
 * コンポーネントを更新するか判定する
 * @param {Object} prev 前回の値
 * @param {Object} next 最新の値
 * @returns {Boolean} falseの場合更新する
 */
const areEqual = (prev, next) => {
  /** 1.セルの値を比較 */
  const relationValue =
    prev.cellRequiredObj.relationValue.join('') ===
    next.cellRequiredObj.relationValue.join('')
  const cellValue =
    prev.cellRequiredObj.cellValue === next.cellRequiredObj.cellValue &&
    relationValue

  /** 2.ページ数・検索・ソート・APIデータ数・ステータスを比較 */
  const checkUpdateStr = prev.checkUpdateStr === next.checkUpdateStr

  /** 3.インデックスの値を比較 */
  const index = prev.cellRequiredObj.index === next.cellRequiredObj.index

  //1.2.3.が全て一致していれば更新無し
  return cellValue && checkUpdateStr && index
}

/**
 * 定数で定義した表データのコンポーネントを作成
 * @param {Object} tableConstantsObj ConstantsBidDetailsで定義したテーブルの1要素
 * @param {Object} tableContentObj 表示するテーブルの1行分の要素
 * @param {Object} stateApiData state.ApiDataの値
 * @param {Object} cellRequiredObj 列を表示するか
 * @param {Boolean} isDisabled セレクトとテキストボックスを非活性にするか
 * @param {Boolean} isGrayout グレーアウトするか
 * @return {JSX} TableCellコンポーネント(1個分)
 */
const CeateTableCell = React.memo((props) => {
  // 定数に紐づいている表示用のcell要素を取り出す
  const {
    tableConstantsObj,
    tableContentObj,
    stateApiData,
    cellRequiredObj,
    isDisabled,
    isGrayout,
  } = props
  const { cellKey, cellCategory, rightBorder } = tableConstantsObj
  const { flightTypeId } = tableContentObj
  const { index } = cellRequiredObj
  const key = `${index}${cellKey}`
  const API_DATA = ConstantsBidDetails.API_DATA

  const isDirect = flightTypeId === Common.FLIGHT_TYPE.DIRECT

  /** セルに表示する内容 */
  let displayContent = null

  // cellCategoryから各セルの内容を取得
  switch (cellCategory) {
    case Common.CELL_CATEGORY.CHECKBOX.NA:
      displayContent = CheckboxCellBidDetails(cellRequiredObj, key)
      break
    case Common.CELL_CATEGORY.NO:
      displayContent = `${index + 1}.`
      break
    case Common.CELL_CATEGORY.SELECT.AIRPORT:
      displayContent = isDirect
        ? '-'
        : SelectCellBidDetails(
            cellRequiredObj,
            stateApiData,
            API_DATA.AIRPORT,
            false,
            isDisabled
          )
      break
    case Common.CELL_CATEGORY.SELECT.AIRLINE:
      displayContent = SelectCellBidDetails(
        cellRequiredObj,
        stateApiData,
        API_DATA.AIRLINE,
        false,
        isDisabled
      )
      break
    case Common.CELL_CATEGORY.SELECT.TT:
      displayContent = SelectCellBidDetails(
        cellRequiredObj,
        stateApiData,
        API_DATA.TT,
        false,
        isDisabled
      )
      break
    case Common.CELL_CATEGORY.TEXT_FIELD.FSC:
      displayContent = TextFieldCellBidDetails(
        cellRequiredObj,
        CommonValidation.fscZeroAccept,
        '',
        null,
        isDisabled
      )
      break
    case Common.CELL_CATEGORY.TEXT_FIELD.RATE:
      displayContent = TextFieldCellBidDetails(
        cellRequiredObj,
        CommonValidation.priceOptional,
        '',
        null,
        isDisabled
      )
      break
    default:
      displayContent = (
        <Typography noWrap align="center">
          {cellRequiredObj.cellValue}
        </Typography>
      )
      break
  }

  return (
    <TableCellBidDetails
      key={key}
      displayContent={displayContent}
      isGrayOut={isGrayout}
      rightBorder={rightBorder}
    />
  )
}, areEqual)

CeateTableCell.displayName = 'CeateTableCell'

/**
 * 1行分の表データを作成
 * @param {*} props 下記要素
 * @param {Object} tableContentObj 表示するテーブルの1行分の要素
 * @param {Number} index showDataの番号
 * @return {JSX} TableCellコンポーネント(1行分)
 */
const TableCellsBidDetails = (props) => {
  const { tableContentObj, index, tableArr } = props
  const { statusStr, apiIndexNum } = tableContentObj
  const { state, dispatch } = useContext(AppContext)
  const history = useHistory()

  /** 編集後のstatusId */
  const editedStatusId = Object.entries(Common.BID_STATUS).find(
    (status) => status[1].TEXT === statusStr
  )[1].ID

  /** 非活性にするか */
  const isDisabled =
    editedStatusId === Common.BID_STATUS.NA.ID ||
    editedStatusId === Common.BID_STATUS.LOST.ID

  /** グレーアウトするか */
  const isGrayout =
    ConstantsBidDetails.GRAY_OUT_STATUS_ID_ARR.includes(editedStatusId)

  /** APIの更新判定用 */
  const apiDataKeyLength = Object.keys(state.ApiData).length

  /** 更新判定用の値をまとめた文字列 */
  const checkUpdateStr = `${state.SearchSort.search}${state.SearchSort.sort}${state.Paging}${apiDataKeyLength}${editedStatusId}`

  /** APIの呼び出しに必要なオブジェクト */
  const apiRequiredObj = { dispatch, history }

  return tableArr.map((tableConstantsObj, tableIndex) => {
    /** 表示判定結果 */
    const isShowColumn = getIsShowColumn(
      tableConstantsObj.checkboxName,
      state.Checkbox
    )
    if (isShowColumn) {
      const cellKey = tableConstantsObj.cellKey
      const relationCellKey = tableConstantsObj.relationCellKey
      // cellKeyから値を取得して格納する
      const cellValue = tableContentObj[cellKey]
      const relationValue = relationCellKey.map(
        (cellKey) => tableContentObj[cellKey]
      )
      // 主要な値をオブジェクトにまとめる
      const cellRequiredObj = {
        apiIndexNum,
        cellKey,
        relationValue,
        cellValue,
        apiRequiredObj,
        index,
      }
      return (
        <CeateTableCell
          key={`createTableCell${index}${tableIndex}`}
          tableConstantsObj={tableConstantsObj}
          tableContentObj={tableContentObj}
          stateApiData={state.ApiData}
          cellRequiredObj={cellRequiredObj}
          isDisabled={isDisabled}
          checkUpdateStr={checkUpdateStr}
          isGrayout={isGrayout}
        />
      )
    }
    return null
  })
}

TableCellsBidDetails.propTypes = {
  tableContentObj: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  tableArr: PropTypes.array.isRequired,
}

CeateTableCell.propTypes = {
  tableConstantsObj: PropTypes.object.isRequired,
  tableContentObj: PropTypes.object.isRequired,
  stateApiData: PropTypes.object.isRequired,
  cellRequiredObj: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isGrayout: PropTypes.bool.isRequired,
}

export default TableCellsBidDetails
