import { LIGHT_AIR_FREIGHT } from '../../../actions'

/** @type{String} 混載業者名の初期値 */
const initForwarder = ''

/**
 * 混載業者情報を保持
 * @param {Object} action - dispatchで受け取ったaction
 * @param {String} action.companyName 会社名
 * @param {String} action.fwdrthreeCode 混載業者3桁コード
 * @return {Object} - state
 */
const setForwarder = (action) => {
  const { companyName, fwdrthreeCode } = action
  return `${companyName} (${fwdrthreeCode})`
}

/**
 * 混載業者名情報
 * @param {Object} [state=initForwarder] - state
 * @param {Object} action - action
 * @return {Object} - state
 */
const Forwarder = (state = initForwarder, action) => {
  switch (action.type) {
    case LIGHT_AIR_FREIGHT.FORWARDER.SET:
      return setForwarder(action)
    default:
      return state
  }
}

export default Forwarder

export { initForwarder }
