import { UPDATE_CAR_FILE } from '../../../actions'

const initFile = {
  file: '',
}
/**
 * state: file情報を保存
 * @param {Object} [state=initFile] -
 * @param {Object} action -
 * @returns {*} -
 */
const File = (state = initFile, action) => {
  switch (action.type) {
    case UPDATE_CAR_FILE: {
      const data = { ...state, file: action.file }
      return data
    }
    default:
      return state
  }
}

export default File

export { initFile }
