import React from 'react'
import Grid from '@material-ui/core/Grid'

import BackButton from '../../../common/buttons/BackButton'
import Paths from '../../../../constants/Paths'

const Buttons = (props) => {
  return (
    <Grid container justify="center">
      <Grid item>
        <BackButton path={Paths.NOTIFY.NOTIFY_LIST} state={props.sort} />
      </Grid>
    </Grid>
  )
}

export default Buttons
