// Display + 画面名
const DisplayApiSample = {
  // 画面ID + 文言ID
  D015V0001: {
    en: 'Air Freight Quote',
    ja: '航空貨物見積書',
  },
  D015V0002: {
    en: 'ADDRESS',
    ja: '住所',
  },
  D015V0003: {
    en: 'XXXX Corporation',
    ja: 'XXXX 株式会社',
  },
  D015V0004: {
    en: '1-8-3 Takakuradai, Minami-ku, Sakai-shi, Osaka, 590-0117 Japan',
    ja: '日本大阪府大阪市堺市南区高倉台1-8-3、590-0117',
  },
  D015V0005: {
    en: 'To',
    ja: '送付先',
  },
  D015V0006: {
    en: '327 St. Margarets Street, Charlotte, NC 28205 United States of America',
    ja: '327 St. Margarets Street, Charlotte, NC 28205 United States of America',
  },
  D015V0007: {
    en: 'Tel:',
    ja: '電話番号:',
  },
  D015V0008: {
    en: 'Flight',
    ja: 'フライト',
  },
  D015V0009: {
    en: "UAXXX/XXSEP *Narita Int'l Airport to Charlotte Douglas Int'l Airport",
    ja: '成田国際空港からシャーロットダグラス国際空港まで',
  },
  D015V0010: {
    en: 'Quote prepared by',
    ja: '見積書の作成者',
  },
  D015V0011: {
    en: 'Norikuni Inaba, Air Export Manager, XXXXXX Logistics',
    ja: '稲葉憲邦、航空輸出マネージャー、XXXXXXロジスティクス',
  },
  D015V0012: {
    en: 'DATE',
    ja: '日付',
  },
  D015V0013: {
    en: 'Quote',
    ja: '見積もり',
  },
  D015V0014: {
    en: '7/31/2020',
    ja: '2020年7月31日',
  },
  D015V0015: {
    en: '2020090101',
    ja: '2020090101',
  },
  D015V0016: {
    en: 'Origin Charges',
    ja: '原産地料金',
  },
  D015V0017: {
    en: 'Rate',
    ja: '割合',
  },
  D015V0018: {
    en: 'Per',
    ja: 'PER',
  },
  D015V0019: {
    en: 'Amount',
    ja: '料金',
  },
  D015V0020: {
    en: 'Pickup charge *From: 1-8-3 Takakuradai, Minami-kuSakai-shi, Osaka 590-0117 Japan',
    ja: '送迎料金 *日本大阪府大阪市堺市南区高倉台1-8-3、590-0117から',
  },
  D015V0021: {
    en: 'Packing Fee *Dim (LxWxH): XXXcm x XXXcm x XXXcm',
    ja: '梱包料金 *寸法(LxWxH): XXXcm x XXXcm x XXXcm',
  },
  D015V0022: {
    en: 'Airline Registration Fee',
    ja: '航空会社登録料',
  },
  D015V0023: {
    en: 'Airline Heading Fee',
    ja: '航空会社手数料',
  },
  D015V0024: {
    en: 'House Air Waybill Data Transmission Fee',
    ja: 'HAWBデータ送信料',
  },
  D015V0025: {
    en: 'Labeling Fee',
    ja: 'ラベル貼付費',
  },
  D015V0026: {
    en: 'Inspection Fee',
    ja: '検査料',
  },
  D015V0027: {
    en: 'XX, XXX',
    ja: 'XX, XXX',
  },
  D015V0028: {
    en: 'kg',
    ja: 'kg',
  },
  D015V0029: {
    en: 'Contact later',
    ja: '後で連絡する',
  },
  D015V0030: {
    en: 'Shipment',
    ja: '発送',
  },
  D015V0031: {
    en: 'HAWB',
    ja: 'HAWB',
  },
  D015V0032: {
    en: 'Sub Total',
    ja: '小計',
  },
  D015V0033: {
    en: 'Freight Charges',
    ja: '運送費用',
  },
  D015V0034: {
    en: 'Air Freight *Chargeable Weight XXX.XXkg',
    ja: '航空貨物*有料重量XXX.XXkg',
  },
  D015V0035: {
    en: 'Fuel Surcharge *Chargeable Weight XXX.XXkg',
    ja: '燃油サーチャージ*有料重量XXX.XXkg',
  },
  D015V0036: {
    en: 'Screening Fee',
    ja: '選考料',
  },
  D015V0037: {
    en: 'Destination Charges',
    ja: '到着地料金',
  },
  D015V0038: {
    en: 'Import Customs Clearance Charges',
    ja: '輸入通関料金',
  },
  D015V0039: {
    en: 'Transfer Fee',
    ja: '転送料',
  },
  D015V0040: {
    en: 'Handover Fee *Over X,XXXkg',
    ja: '引渡手数料*XXXkg 以上',
  },
  D015V0041: {
    en: 'Collect Charge Fee',
    ja: '集荷料金',
  },
  D015V0042: {
    en: 'Warehouse Storage Fee',
    ja: '倉庫保管料',
  },
  D015V0043: {
    en: 'Delivery Charge *To: 327 St. Margarets Street, Charlotte, NC 28205 United States of America',
    ja: '配送料 *To: 327 St. Margarets Street, Charlotte, NC 28205 United States of Americaまで',
  },
  D015V0044: {
    en: 'TERMS AND CONDITIONS',
    ja: '取引条件',
  },
  D015V0045: {
    en: '1. XXXXXXXXXXXXXXXXXXX',
    ja: '1. XXXXXXXXXXXXXXXXXXX',
  },
  D015V0046: {
    en: 'To accept this quotation, Please sign here and return:',
    ja: 'この見積もりを受け入れるには、ここに署名して返送してください：',
  },
  D015V0047: {
    en: 'If you have any questions about this quotation, Please contact',
    ja: 'この見積もりについてご不明な点がございましたら、稲葉紀邦にご連絡ください',
  },
  D015V0048: {
    en: 'Norikuni Inaba, Air Export Manager - XXXXXX Logistics, +81-0-0000-0000.',
    ja: '航空輸出マネージャー、XXXXXXロジスティクス +81-0-0000-0000.',
  },
  D015V0049: {
    en: 'Back',
    ja: '戻る',
  },
  D015V0050: {
    en: 'Attn:',
    ja: '連絡先:',
  },
  D015V0051: {
    en: 'Valid until',
    ja: '有効期限',
  },
  D015V0052: {
    en: '2. XXXXXXXXXXXXXXXXXXX',
    ja: '2. XXXXXXXXXXXXXXXXXXX',
  },
  D015V0053: {
    en: '3. XXXXXXXXXXXXXXXXXXX',
    ja: '3. XXXXXXXXXXXXXXXXXXX',
  },
  D015V0054: {
    en: 'Total',
    ja: '会計',
  },
  D015V0055: {
    en: 'Currency:',
    ja: '通貨:',
  },
  D015V0056: {
    en: 'BOND-IN ADDRESS',
    ja: '搬入先住所',
  },
}
// Display + 画面名
export default DisplayApiSample
