import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import TextField from '../../common/text/TextField'
import CommonValidation from '../../common/function/CommonValidation'
import {
  UPDATE_FWDR_COMPANY_ADDRESS,
  UPDATE_FWDR_COMPANY_ADDRESS_INPUTERR,
  UPDATE_BOND_IN_ADDRESS,
  UPDATE_BOND_IN_ADDRESS_INPUTERR,
} from '../../../actions'

const useStyles = makeStyles((theme) => ({
  head: theme.textBoldBLU,
  textBlk: {
    ...theme.textNormalBLK,
    marginLeft: '1rem',
    wordWrap: 'break-word',
  },
}))

/**
 * コンポーネントを更新するか判定する
 * @param {Object} prev 前回の値
 * @param {Object} next 最新の値
 * @returns {Boolean} falseの場合更新する
 */
const areEqual = (prev, next) => {
  // 各入力欄の値を比較
  const addressValue =
    prev.companyinfo.address.value === next.companyinfo.address.value
  const bondInAddressValue =
    prev.companyinfo.bondInAddress.value ===
    next.companyinfo.bondInAddress.value
  const authorityFlgValue =
    prev.companyinfo.authorityFlg.value === next.companyinfo.authorityFlg.value

  // 全て一致していれば更新無し
  return addressValue && bondInAddressValue && authorityFlgValue
}

const CompanyAddress = React.memo(({ dispatch, companyinfo }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [inputErr, setInputErr] = useState({})
  const [helperTxt, setHelperTxt] = useState({})
  const { address, bondInAddress, authorityFlg } = companyinfo
  const authorityFlgValue = authorityFlg.value

  const CONSTANTS = {
    ADDRESS: {
      id: 'D301V0003',
      value: address.value,
      updateType: UPDATE_FWDR_COMPANY_ADDRESS,
      errType: UPDATE_FWDR_COMPANY_ADDRESS_INPUTERR,
    },
    BOND_IN_ADDRESS: {
      id: 'D301V0265',
      value: bondInAddress.value,
      updateType: UPDATE_BOND_IN_ADDRESS,
      errType: UPDATE_BOND_IN_ADDRESS_INPUTERR,
    },
  }

  const handleChange = (event, data) => {
    const value = event.target.value
    // 入力チェック
    const validationCheck = CommonValidation.address(value)
    const err = validationCheck.checkResult
    setHelperTxt({ ...helperTxt, [data.id]: validationCheck.errMsg })
    setInputErr({ ...inputErr, [data.id]: err })
    dispatch({
      type: data.updateType,
      address: value,
    })

    // 入力エラーの場合、Saveボタン無効
    dispatch({
      type: data.errType,
      inputErr: err,
    })
  }

  // 編集用
  const createTextField = (data) => {
    return (
      <Grid item md={9} xs={10}>
        <TextField
          id={data.id}
          error={inputErr[data.id]}
          helperText={t(helperTxt[data.id])}
          value={data.value}
          onChange={(event) => handleChange(event, data)}
        />
      </Grid>
    )
  }

  // 表示用
  const createTextLabel = (data) => {
    return (
      <Grid item md={10} xs={10}>
        <div className={classes.textBlk}>{data.value}</div>
      </Grid>
    )
  }

  return Object.values(CONSTANTS).map((data, index) => (
    <Grid
      container
      key={index}
      style={
        Object.keys(CONSTANTS).length - 1 > index
          ? { marginBottom: '3rem' }
          : null
      }
    >
      {/* タイトル */}
      <Grid item md={10} xs={10}>
        <div className={classes.head}>{t(data.id)}</div>
      </Grid>
      {/* 入力欄 */}
      {authorityFlgValue ? createTextField(data) : createTextLabel(data)}
    </Grid>
  ))
}, areEqual)

CompanyAddress.displayName = 'CompanyAddress'

CompanyAddress.propTypes = {
  companyinfo: PropTypes.object.isRequired,
}

export default CompanyAddress
