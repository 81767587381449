import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: 5,
    },
  },
  input: {
    flex: 1,
    '& .MuiInputBase-root': {
      height: 48,
      paddingTop: '0px',
      paddingLeft: '48px',
      border: '1px solid',
      borderColor: theme.palette.border.white,
      borderBottomColor: 'transparent',
      color: theme.fontColor.main,
    },
    '& .MuiAutocomplete-endAdornment': {
      paddingRight: '8px',
    },
  },
  iconButton: {
    color: theme.fontColor.main,
    padding: 10,
  },
  noWidth: {
    width: '0px',
  },
}))

const SearchBox = (props) => {
  const classes = useStyles()
  const [text, setText] = useState('')
  const [isChange, setIsChange] = useState(false)
  const [oldText, setOldText] = useState('')
  const [value, setValue] = useState(null)

  const change = (event, newValue) => {
    let isVaild = true
    for (let i = 0; i < props.options.length; i++) {
      if (props.options[i].name === newValue.name) {
        isVaild = false
      }
    }
    if (newValue !== null && isVaild === false) {
      setIsChange(true)
      if (props.onChange !== undefined) {
        props.onChange(event, newValue)
      }

      setOldText(text)
    } else {
      setOldText('')
    }
  }

  const changeInput = (_event, newValue) => {
    setText(newValue)
  }

  useEffect(() => {
    if (isChange) {
      setText(oldText)
      setIsChange(false)
      setValue(null)
    }
  }, [isChange, oldText])

  return (
    <div className={classes.root}>
      <div className={classes.noWidth}>
        <SearchIcon className={classes.iconButton} />
      </div>
      <Autocomplete
        value={value}
        inputValue={text}
        freeSolo
        id={props.id}
        className={classes.input}
        options={props.options}
        getOptionLabel={props.getOptionLabel}
        getOptionSelected={(option, value) => option.name === value}
        onClick={props.onClick}
        onFocus={props.onFocus}
        onChange={change}
        onInputChange={changeInput}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={props.placeholder}
            className={classes.input}
          />
        )}
      />
    </div>
  )
}

export default SearchBox
