// Display + 画面名
const DisplayShipperRegister = {
  // 画面ID + 文言ID
  D002V0001: {
    en: 'Register New Account',
    ja: 'Register New Account',
  },
  D002V0002: {
    en: 'A confirmation e-mail will be sent automatically to your e-mail address.',
    ja: 'A confirmation e-mail will be sent automatically to your e-mail address.',
  },
  D002V0003: {
    en: 'Company',
    ja: 'Company',
  },
  D002V0004: {
    en: 'Branch',
    ja: 'Branch',
  },
  D002V0005: {
    en: 'First Name',
    ja: 'First Name',
  },
  D002V0006: {
    en: 'Last Name',
    ja: 'Last Name',
  },
  D002V0007: {
    en: 'Position',
    ja: 'Position',
  },
  D002V0008: {
    en: 'E-mail',
    ja: 'E-mail',
  },
  D002V0009: {
    en: 'Telephone',
    ja: 'Telephone',
  },
  D002V0010: {
    en: 'Required fields',
    ja: 'Required fields',
  },
  D002V0011: {
    en: 'Sign Up',
    ja: 'Sign Up',
  },
  D002V0012: {
    en: 'Company Name Required',
    ja: 'Company Name Required',
  },
  D002V0013: {
    en: 'Company Name must be less than 50 characters',
    ja: 'Company Name must be less than 50 characters',
  },
  D002V0014: {
    en: 'Company Name must only include letters',
    ja: 'Company Name must only include letters',
  },
  D002V0015: {
    en: 'Branch Name Required',
    ja: 'Branch Name Required',
  },
  D002V0016: {
    en: 'Branch Name must be less than 50 characters',
    ja: 'Branch Name must be less than 50 characters',
  },
  D002V0017: {
    en: 'Branch Name must only include letters',
    ja: 'Branch Name must only include letters',
  },
  D002V0018: {
    en: 'First Name Required',
    ja: 'First Name Required',
  },
  D002V0019: {
    en: 'First Name must be less than 50 characters',
    ja: 'First Name must be less than 50 characters',
  },
  D002V0020: {
    en: 'First Name must only include letters',
    ja: 'First Name must only include letters',
  },
  D002V0021: {
    en: 'Last Name Required',
    ja: 'Last Name Required',
  },
  D002V0022: {
    en: 'Last Name must be less than 50 characters',
    ja: 'Last Name must be less than 50 characters',
  },
  D002V0023: {
    en: 'Last Name must only include letters',
    ja: 'Last Name must only include letters',
  },
  D002V0024: {
    en: 'Email Required',
    ja: 'Email Required',
  },
  D002V0025: {
    en: 'Email must be less than 20 characters',
    ja: 'Email must be less than 20 characters',
  },
  D002V0026: {
    en: 'Incorrect entry.',
    ja: 'Incorrect entry.',
  },
  D002V0027: {
    en: 'Telephone Required',
    ja: 'Telephone Required',
  },
  D002V0028: {
    en: 'Telephone must be less than 50 characters',
    ja: 'Telephone must be less than 50 characters',
  },
  D002V0029: {
    en: 'Telephone must only include numbers',
    ja: 'Telephone must only include numbers',
  },
  D002V0030: {
    en: 'Country',
    ja: 'Country',
  },
  D002V0031: {
    en: 'Address',
    ja: 'Address',
  },
  D002V0032: {
    en: 'Address Required',
    ja: 'Address Required',
  },
  D002V0033: {
    en: 'Country Required',
    ja: 'Country Required',
  },
}
// Display + 画面名
export default DisplayShipperRegister
