import { LIGHT_BOOKING_DETAILS } from '../../../actions'
import CommonFunc from '../../../components/common/function/CommonFunc'
import Common from '../../../constants/Common'

/** @enum {DocumentArrName} ドキュメントの配列名 */
const DocumentArrName = {
  fwdrDocumentArr: 'fwdrDocumentArr',
  carDocumentArr: 'carDocumentArr',
}

/** @type {Object} Documentの初期値 */
const initDocument = {
  [DocumentArrName.fwdrDocumentArr]: [],
  [DocumentArrName.carDocumentArr]: [],
}

/**
 * リクエストデータを作成する
 * @param {Array} fwdrDocumentArr 混載業者ドキュメントの配列
 * @param {Array} carDocumentArr 航空会社ドキュメントの配列
 * @returns {Array} リクエストデータ
 */
const createRequestData = (fwdrDocumentArr, carDocumentArr) => {
  const deleteFwdrDocumentArr = fwdrDocumentArr.filter(
    (v) => v.delFlg === Common.DEL_FLG.ON
  )
  const deleteCarDocumentArr = carDocumentArr.filter(
    (v) => v.delFlg === Common.DEL_FLG.ON
  )
  return deleteFwdrDocumentArr.concat(deleteCarDocumentArr)
}

/**
 * filesのリクエストデータを作成する
 * @param {Array} fwdrDocumentArr 混載業者ドキュメントの配列
 * @param {Array} carDocumentArr 航空会社ドキュメントの配列
 * @returns {Object} filesのリクエストデータ
 */
const createFilesRequestData = (fwdrDocumentArr, carDocumentArr) => {
  const fileFwdrDocumentArr = fwdrDocumentArr.filter((v) => v.file)
  const fileCarDocumentArr = carDocumentArr.filter((v) => v.file)
  const fileArr = fileFwdrDocumentArr
    .concat(fileCarDocumentArr)
    .map((file) => file.file)
  const keyArr = Common.PDF_ARR.filter((_, index) => index < fileArr.length)
  return CommonFunc.convertArrToObj(keyArr, fileArr)
}

/**
 * Documentを保持
 * @param { Object } action action
 * @param { Object } state state
 * @return { Object } state
 */
const setDocument = (action, state) => {
  const { fwdrDocumentArr = [], carDocumentArr = [] } = action
  const getRequestData = () =>
    createRequestData(fwdrDocumentArr, carDocumentArr)
  const getFilesRequestData = () =>
    createFilesRequestData(fwdrDocumentArr, carDocumentArr)
  return {
    ...state,
    ...action,
    fwdrDocumentArr,
    carDocumentArr,
    getRequestData,
    getFilesRequestData,
  }
}

/**
 * Documentを追加
 * @param { Object } action action
 * @param { Object } state state
 * @return { Object } state
 */
const addDocument = (action, state) => {
  const { documentArrName, document } = action
  state[documentArrName].push(document)
  return { ...state }
}

/**
 * Documentを削除
 * @param { Object } action action
 * @param { Object } state state
 * @return { Object } state
 */
const deleteDocument = (action, state) => {
  const { documentArrName, index } = action
  if (state[documentArrName][index].updatedAt) {
    state[documentArrName][index].delFlg = Common.DEL_FLG.ON
  } else {
    state[documentArrName].splice(index, 1)
  }
  return { ...state }
}

/**
 * Document情報を保持
 * @param { Object } [state = initDocument] state
 * @param { Object } action action
 * @return { Object } state
 */
const Document = (state = initDocument, action) => {
  const { type, ...rest } = action
  switch (type) {
    case LIGHT_BOOKING_DETAILS.DOCUMENT.SET:
      return setDocument(rest, state)
    case LIGHT_BOOKING_DETAILS.DOCUMENT.ADD:
      return addDocument(rest, state)
    case LIGHT_BOOKING_DETAILS.DOCUMENT.DELETE:
      return deleteDocument(rest, state)
    default:
      return state
  }
}

export default Document

export { initDocument, DocumentArrName }
