import { combineReducers } from 'redux'

import Tabs, { initTabs } from '../../common/Tabs'
import Paging, { initPaging } from '../../common/Paging'
import Tables, { initTableData } from './Tables'
import SearchSort, { initSearchSort } from '../../common/SearchSort'

const initialState = {
  Tabs: initTabs,
  Paging: initPaging,
  Tables: initTableData,
  SearchSort: initSearchSort,
}

export default combineReducers({
  Tabs,
  Paging,
  Tables,
  SearchSort,
})

export { initialState }
