import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { LIGHT_FLIGHT_MGMT } from '../../../../../actions'
import AppContext from '../../../../../contexts/AppContext'
import InputSelect from '../../../../common/select/InputSelect'
import Content from '../03AccordionCarFlightMgmt/Content'

/**
 * @returns {JSX} CarrierNameSelect欄
 */
const CarrierSelect = () => {
  const { t } = useTranslation()
  const { state, dispatch } = useContext(AppContext)

  /** @type {Function} flightInfoの値を取得する */
  const getFlightInfoParam = state.Popup.getFlightInfoParam

  /** @type {Number} carrierNameId */
  const carrierNameId = getFlightInfoParam('iconObj')?.carrierNameId ?? null

  return useMemo(() => {
    /** @type {Object|null} 選択中の空港情報 */
    const selectedCarrier =
      state.CarrierName.getCarrierNameInfo(carrierNameId) ?? null

    /**
     * 航空会社更新
     * @param {Object} value 選択した値
     * @returns {void} なし
     */
    const onChange = (value) => {
      /** @type {Object} コピーしたiconObjの値 */
      const iconObj = Object.assign({}, getFlightInfoParam('iconObj'))
      iconObj.carrierNameId = value?.carrierNameId ?? null
      iconObj.carriertwoCode = value?.carriertwoCode ?? null
      dispatch({
        type: LIGHT_FLIGHT_MGMT.EDIT.FLIGHT_INFO,
        iconObj,
      })
    }

    return (
      <Content
        title={t('D301V0039')}
        content={
          <InputSelect
            options={state.CarrierName.carrierNames}
            getOptionLabel={(option) => option.companyName}
            value={selectedCarrier}
            onChange={(_, value) => onChange(value)}
            disabled={!state.Auth || !state.Popup.add}
          />
        }
      />
    )
  }, [
    carrierNameId,
    state.Auth,
    dispatch,
    state.CarrierName,
    getFlightInfoParam,
    state.Popup.add,
    t,
  ])
}
export default CarrierSelect
