import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { useHistory } from 'react-router-dom'

import AppContext from '../../../../../contexts/AppContext'
import {
  UPDATE_CAR_FIRST_NAME,
  UPDATE_CAR_LAST_NAME,
  UPDATE_CAR_MAIL,
  UPDATE_CAR_TEL,
  UPDATE_CAR_POSITION,
  UPDATE_CAR_PASSWORD,
  UPDATE_CAR_MAIL_SETTING,
  UPDATE_CAR_CURRENTPASS_ERR,
  UPDATE_CAR_PASS_VERIFY_ERR,
  UPDATE_CAR_BACKUP,
  UPDATE_CAR_UPDATE_AT,
  UPDATE_CAR_PASS_DIGIT_ERR,
  UPDATE_CAR_CURRENTPASS,
  UPDATE_CAR_NEWPASS,
  UPDATE_CAR_NEWPASS_VERIFY,
  UPDATE_CAR_DEPARTMENT,
} from '../../../../../actions'
import Paths from '../../../../../constants/Paths'
import SaveAndBack from '../../../../common/buttons/SaveAndBack'
import ApiPaths from '../../../../../constants/ApiPaths'
import CommonFunc from '../../../../common/function/CommonFunc'
import Common from '../../../../../constants/Common'

const useStyles = makeStyles(() => ({
  buttons: {
    justifyContent: 'center',
    marginBottom: 30,
    marginTop: 30,
  },
  down: {
    marginBottom: 30,
    margin: 'auto',
  },
}))

// パスワードエラーコード
const PasswordError = {
  NotMatchCurrentPassword: 0x01,
  NotMatchNewPassword: 0x02,
  NotDigitNewPassword: 0x04,
}

const ButtonLightCarMyProfile = () => {
  const classes = useStyles()
  const history = useHistory()
  const [saveComp, setSaveComp] = useState(false)
  const [errCode, setErrCode] = useState(Common.ERROR_CODE.NORMAL)
  const { state, dispatch } = useContext(AppContext)

  // Password変更入力判定
  const chkPassword = (res) => {
    const myprofile = state.myprofile
    const passwordErrCode = res
      ? res.data.info.ErrorCode
      : Common.ERROR_CODE.NORMAL
    let passerr = Common.ERROR_CODE.NORMAL // Errorなし

    if (
      myprofile.currentPassword.value !== '' ||
      myprofile.newPassword.value !== '' ||
      myprofile.newPassVerify.value !== ''
    ) {
      // newPasswordチェック
      const isNotMatchNewPassword =
        myprofile.newPassword.value !== myprofile.newPassVerify.value
      const isEmptyNewPassword = myprofile.newPassword.value === ''
      const isEmptyNewPassVerify = myprofile.newPassVerify.value === ''
      const isEmptyPassword = isEmptyNewPassword && isEmptyNewPassVerify
      if (isNotMatchNewPassword || isEmptyPassword) {
        passerr |= PasswordError.NotMatchNewPassword // 確認用パスワード不一致
      }
      // currentPasswordチェック
      if (myprofile.currentPassword.value === '') {
        passerr |= PasswordError.NotMatchCurrentPassword // 現在パスワード不一致(空の場合はフロントで弾く。入力がある場合はバックエンドで判定)
      }
    }
    if (passwordErrCode === Common.ERROR_CODE.WRONG_PASSWORD) {
      passerr |= PasswordError.NotMatchCurrentPassword // 現在パスワード不一致
    }
    const nonEmptyNewPassword = myprofile.newPassword.value !== ''
    const passwordLengthMin =
      myprofile.newPassword.value.length < Common.PASSWORD_LENGTH.MIN
    const passwordLengthMax =
      myprofile.newPassword.value.length > Common.PASSWORD_LENGTH.MAX
    const passwordLength = passwordLengthMin || passwordLengthMax
    if (nonEmptyNewPassword && passwordLength) {
      passerr |= PasswordError.NotDigitNewPassword // 新パスワード桁数エラー
    }
    return passerr
  }
  // Password入力エラー状態更新()
  const updatePasswordErrState = (res) => {
    const passerr = chkPassword(res)

    if (passerr & PasswordError.NotMatchCurrentPassword) {
      dispatch({
        type: UPDATE_CAR_CURRENTPASS_ERR,
        errflg: true,
      })
    }
    if (passerr & PasswordError.NotMatchNewPassword) {
      dispatch({
        type: UPDATE_CAR_PASS_VERIFY_ERR,
        errflg: true,
      })
    }
    if (passerr & PasswordError.NotDigitNewPassword) {
      dispatch({
        type: UPDATE_CAR_PASS_DIGIT_ERR,
        errflg: true,
      })
    }
  }

  // Saveボタンの有効無効判定
  // 旧パスワードが空のとき新パスワードが入力されている場合にtrueが返される条件
  const currentPassword = state.myprofile.currentPassword.value === ''
  const newPassVerify = state.myprofile.newPassVerify.value !== ''
  const newPassword = state.myprofile.newPassword.value !== ''

  // 入力欄バリデーションエラーチェック
  const chkInputErr = Object.values(state.myprofile).some(
    ({ inputErr }) => inputErr
  )
  // パスワードエラーチェック
  const chkCurrentPass = newPassVerify && newPassword && currentPassword
  // パスワード欄入力チェック
  const chkPassErr =
    state.myprofile.passwordErr.current ||
    state.myprofile.passwordErr.digit ||
    state.myprofile.passwordErr.verify
  const chkSaveDisable = () => chkInputErr || chkCurrentPass || chkPassErr

  // 入力変更ありなし判定
  const chkInputChg = () => {
    const sta = state.myprofile
    const bk = state.backup
    if (
      bk.name !== sta.name.value ||
      bk.lastName !== sta.lastName.value ||
      bk.email !== sta.email.value ||
      bk.tel !== sta.tel.value ||
      bk.position !== sta.position.value ||
      bk.department !== sta.department.value ||
      sta.currentPassword.value !== '' ||
      sta.newPassword.value !== '' ||
      sta.newPassVerify.value !== '' ||
      bk.mailSetting !== sta.mailSetting.value
    ) {
      return true
    }
    return false
  }

  // 保存完了後の処理
  const saveComplete = async (res, code) => {
    setSaveComp(false)
    if (code === Common.ERROR_CODE.NORMAL) {
      setMyProfileCB(res)
      // エラー状態クリア
      dispatch({
        type: UPDATE_CAR_CURRENTPASS_ERR,
        errflg: false,
      })
      dispatch({
        type: UPDATE_CAR_PASS_VERIFY_ERR,
        errflg: false,
      })
      dispatch({
        type: UPDATE_CAR_PASS_DIGIT_ERR,
        errflg: false,
      })
      // 再表示の際、パスワードを空にしておく
      dispatch({
        type: UPDATE_CAR_CURRENTPASS,
        currentPassword: '',
      })
      dispatch({
        type: UPDATE_CAR_NEWPASS,
        newPassword: '',
      })
      dispatch({
        type: UPDATE_CAR_NEWPASS_VERIFY,
        newPassVerify: '',
      })
    }
  }

  // 保存処理
  const saveData = async () => {
    const req = {
      name: state.myprofile.name.value,
      lastName: state.myprofile.lastName.value,
      email: state.myprofile.email.value,
      tel: state.myprofile.tel.value,
      position: state.myprofile.position.value,
      department: state.myprofile.department.value,
      mailSetting: state.myprofile.mailSetting.value,
      currentPassword: state.myprofile.currentPassword.value,
      newPassword: state.myprofile.newPassword.value,
      updatedAt: state.myprofile.updatedAt.value,
    }
    // API実行
    const apiInfoArr = [
      {
        key: 'resData',
        method: 'post',
        path: ApiPaths.MASTER_CAR.LIGHT_MY_PROFILE,
        req,
      },
    ]
    const resObj = await CommonFunc.execApiAsync(apiInfoArr)
    const isApiSuccessful = CommonFunc.checkApiResponseObj(resObj, history)
    // コンソールエラー箇所
    updatePasswordErrState(resObj.resData)
    setErrCode(resObj.resData.data.info.ErrorCode)
    if (isApiSuccessful) {
      saveComplete(
        resObj.resData.data.results,
        resObj.resData.data.info.ErrorCode
      )
      setSaveComp(true)
    }
  }

  const setMyProfileCB = (res) => {
    if (res) {
      dispatch({
        type: UPDATE_CAR_FIRST_NAME,
        name: res.name,
      })
      dispatch({
        type: UPDATE_CAR_LAST_NAME,
        lastName: res.lastName,
      })
      dispatch({
        type: UPDATE_CAR_MAIL,
        email: res.email,
      })
      dispatch({
        type: UPDATE_CAR_TEL,
        tel: res.tel,
      })
      dispatch({
        type: UPDATE_CAR_POSITION,
        position: res.position,
      })
      dispatch({
        type: UPDATE_CAR_DEPARTMENT,
        department: res.department,
      })
      dispatch({
        type: UPDATE_CAR_PASSWORD,
        password: res.password,
      })
      dispatch({
        type: UPDATE_CAR_MAIL_SETTING,
        mailSetting: res.mailSetting,
      })
      dispatch({
        type: UPDATE_CAR_UPDATE_AT,
        updatedAt: res.updatedAt,
      })

      // 初回データをBackup
      dispatch({
        type: UPDATE_CAR_BACKUP,
        info: res,
      })

      // 先頭にスクロール
      window.scrollTo(0, 0)
    }
  }

  // Backコンポーネントに渡したいものをまとめる
  const backFactors = {
    path: Paths.MASTER.CAR_MASTER_TOP,
    inputchg: chkInputChg(),
    clickevent: '',
  }

  // Saveコンポーネントに渡したいものをまとめる
  const saveFactors = {
    path: Paths.MASTER.LIGHT_CAR_MY_PROFILE,
    saveevent: saveData,
    comp: saveComp,
    compevent: saveComplete,
    disabled: chkSaveDisable(),
    errcode: errCode,
    passerr: chkPassword(),
    passerrevent: updatePasswordErrState,
  }

  return (
    <Grid
      container
      justify="center"
      direction="column"
      alignItems="center"
      className={classes.down}
    >
      <Grid item md={10} xs={10} className={classes.buttons}>
        <SaveAndBack backFactors={backFactors} saveFactors={saveFactors} />
      </Grid>
    </Grid>
  )
}

export default ButtonLightCarMyProfile
