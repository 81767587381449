import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import TextField from '../../common/text/TextField'
import CommonValidation from '../../common/function/CommonValidation'
import {
  UPDATE_FWDR_FIRST_NAME,
  UPDATE_FWDR_LAST_NAME,
  UPDATE_FWDR_FIRST_NAME_INPUTERR,
  UPDATE_FWDR_LAST_NAME_INPUTERR,
  UPDATE_CAR_FIRST_NAME,
  UPDATE_CAR_LAST_NAME,
  UPDATE_CAR_FIRST_NAME_INPUTERR,
  UPDATE_CAR_LAST_NAME_INPUTERR,
} from '../../../actions'

const useStyles = makeStyles((theme) => ({
  head: theme.textBoldBLU,
}))

/**
 * コンポーネントを更新するか判定する
 * @param {Object} prev 前回の値
 * @param {Object} next 最新の値
 * @returns {Boolean} falseの場合更新する
 */
const areEqual = (prev, next) => {
  // /** 各入力欄の値を比較 */
  const nameValue = prev.name.value === next.name.value
  const lastNameValue = prev.lastName.value === next.lastName.value

  // 全て一致していれば更新無し
  return nameValue && lastNameValue
}

const Name = React.memo(({ dispatch, name, lastName, carrier }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [inputErrs, setInputErrs] = useState({ name: false, lastName: false })
  const [helperTxts, setHelperTxts] = useState({ name: '', lastName: '' })

  const handleChangeLastName = (event) => {
    const value = event.target.value
    // 入力チェック
    const validationCheck = CommonValidation.name(value)
    const err = validationCheck.checkResult
    setInputErrs({ ...inputErrs, lastName: err })
    setHelperTxts({ ...helperTxts, lastName: validationCheck.errMsg })

    dispatch({
      type: carrier ? UPDATE_CAR_LAST_NAME : UPDATE_FWDR_LAST_NAME,
      lastName: value,
    })

    // 入力エラーの場合、Savaボタン無効
    dispatch({
      type: carrier
        ? UPDATE_CAR_LAST_NAME_INPUTERR
        : UPDATE_FWDR_LAST_NAME_INPUTERR,
      inputErr: err,
    })
  }

  const handleChangeFirstName = (event) => {
    const value = event.target.value
    // 入力チェック
    const validationCheck = CommonValidation.name(value)
    const err = validationCheck.checkResult
    setInputErrs({ ...inputErrs, name: err })
    setHelperTxts({ ...helperTxts, name: validationCheck.errMsg })

    dispatch({
      type: carrier ? UPDATE_CAR_FIRST_NAME : UPDATE_FWDR_FIRST_NAME,
      name: value,
    })

    // 入力エラーの場合、Savaボタン無効
    dispatch({
      type: carrier
        ? UPDATE_CAR_FIRST_NAME_INPUTERR
        : UPDATE_FWDR_FIRST_NAME_INPUTERR,
      inputErr: err,
    })
  }

  return (
    <div className={classes.formControl}>
      <Grid container>
        <Grid item md={4} xs={10}>
          <div className={classes.head}>{t('D301V0007')}</div>
          <TextField
            id="name"
            error={inputErrs.name}
            helperText={t(helperTxts.name)}
            value={name.value}
            onChange={handleChangeFirstName}
            autoComplete="off"
          />
        </Grid>
        <Grid item md={1} />
        <Grid item md={4} xs={10}>
          <div className={classes.head}>{t('D301V0008')}</div>
          <TextField
            id="lastName"
            error={inputErrs.lastName}
            helperText={t(helperTxts.lastName)}
            value={lastName.value}
            onChange={handleChangeLastName}
            autoComplete="off"
          />
        </Grid>
      </Grid>
    </div>
  )
}, areEqual)

Name.displayName = 'Name'

Name.propTypes = {
  dispatch: PropTypes.func.isRequired,
  name: PropTypes.object.isRequired,
  lastName: PropTypes.object.isRequired,
  carrier: PropTypes.bool,
}

export default Name
