import React, { useContext } from 'react'
import Grid from '@material-ui/core/Grid'

import CompanyName from '../../../../common/companyInfo/CompanyName'
import ThreeLetterCode from '../../../../common/companyInfo/ThreeLetterCode'
import PostalCode from '../../../../common/companyInfo/PostalCode'
import Address from '../../../../common/companyInfo/Address'
import Branch from '../../../../common/companyInfo/Branch'
import BranchCode from '../../../../common/companyInfo/BranchCode'
import Airport from '../../../../common/companyInfo/Airport'
import Representative from '../../../../common/companyInfo/Representative'
import Position from '../../../../common/companyInfo/Position'
import ContactInfo from '../../../../common/companyInfo/ContactInfo'
import IataCassNo from '../../../../common/companyInfo/IataCassNo'
import Icon from '../../../../common/companyInfo/Icon'
import DeleteAccount from '../../../../common/companyInfo/DeleteAccount'
import AppContext from '../../../../../contexts/AppContext'
import PlanSelect from '../../../../common/companyInfo/PlanSelect'

const TableLightFwdrCompanyInfo = () => {
  const { state, dispatch } = useContext(AppContext)
  return (
    <Grid container justify="center">
      <Grid container item justify="center" spacing={4} md={12} xs={12}>
        <Grid item md={10} xs={10}>
          <PlanSelect fwdr />
        </Grid>
        <Grid item md={10} xs={10}>
          <CompanyName />
        </Grid>
        <Grid item md={10} xs={10}>
          <ThreeLetterCode />
        </Grid>
        <Grid item md={10} xs={10}>
          <PostalCode dispatch={dispatch} companyinfo={state.companyinfo} />
        </Grid>
        <Grid item md={10} xs={10}>
          <Address dispatch={dispatch} companyinfo={state.companyinfo} />
        </Grid>
        <Grid item md={10} xs={10}>
          <Branch />
        </Grid>
        <Grid item md={10} xs={10}>
          <BranchCode />
        </Grid>
        <Grid item md={10} xs={10}>
          <Airport />
        </Grid>
        <Grid item md={10} xs={10}>
          <Representative dispatch={dispatch} companyinfo={state.companyinfo} />
        </Grid>
        <Grid item md={10} xs={10}>
          <Position dispatch={dispatch} companyinfo={state.companyinfo} />
        </Grid>
        <Grid item md={10} xs={10}>
          <ContactInfo dispatch={dispatch} companyinfo={state.companyinfo} />
        </Grid>
        <Grid item md={10} xs={10}>
          <IataCassNo dispatch={dispatch} companyinfo={state.companyinfo} />
        </Grid>
        <Grid item md={10} xs={10}>
          <Icon dispatch={dispatch} companyinfo={state.companyinfo} />
        </Grid>
        <Grid container item justify="flex-end" md={10} xs={10}>
          <DeleteAccount />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default TableLightFwdrCompanyInfo
