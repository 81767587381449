import { combineReducers } from 'redux'

import topPath, { initTopPath } from './topPath'

const initialState = {
  topPath: initTopPath,
}

export default combineReducers({
  topPath,
})

export { initialState }
