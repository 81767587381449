import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import AuthenticationText from '../../../../commonUseContext/Authentication/AuthenticationText'
import AppContext from '../../../../../contexts/AppContext'
import { LIGHT_REGISTER_NEW_ACCOUNT } from '../../../../../actions'

const FirstNameLightAirlineRegisterNewAccount = () => {
  const { state } = useContext(AppContext)
  const { t } = useTranslation()
  const { checkResult, errMsg } = state.FirstName.errorObj

  return (
    <AuthenticationText
      label={t('D501V0015')}
      required
      error={checkResult}
      helperText={t(errMsg)}
      dispatchType={LIGHT_REGISTER_NEW_ACCOUNT.FIRST_NAME.UPDATE_TEXT}
    />
  )
}

export default FirstNameLightAirlineRegisterNewAccount
