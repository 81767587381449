import React, { useReducer, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import LayoutBox from '../../../common/layout/LayoutBox'
import AppContext from '../../../../contexts/AppContext'
import reducer, {
  initialState,
} from '../../../../reducers/fwdr/lightFwdrSearchResult'
import CommonFunc from '../../../common/function/CommonFunc'
import ApiPaths from '../../../../constants/ApiPaths'
import { LIGHT_BOOKING_REQUEST, SEARCH_SORT, TABLES } from '../../../../actions'
import ConstantsLightFwdrSearchResult from './ConstantsLightFwdrSearchResult'
import TitleLightSearchResult from './00TitleLightSearchResult/TitleLightSearchResult'
import SearchSortLightFwdrSearchResult from './01SearchSortLightFwdrSearchResult/SearchSortLightFwdrSearchResult'
import TableLightFwdrSearchResult from './02TableLightFwdrSearchResult/TableLightFwdrSearchResult'
import PagingLightFwdrSearchResult from './03PagingLightFwdrSearchResult/PagingLightFwdrSearchResult'
import BackButtonLightFwdrSearchResult from './04BackButtonLightFwdrSearchResult/BackButtonLightFwdrSearchResult'

/**
 * @param {Object} props 下記内容
 * @param {Object} props.bookingInfo 貨物情報のオブジェクト
 * @return {JSX} Light版CSearchResult画面コンポーネント
 */
const LightFwdrSearchResult = (props) => {
  const history = useHistory()
  const { org, dst, commodity, cargoDetailArr } =
    props.history.location.state.state
  const origin = props.history.location.state.state?.orgId
  const destination = props.history.location.state.state?.dstId
  const rateType = props.history.location.state.state?.commId
  // 扱いたいデータstateとデータを操作するための処理dispatch
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    ;(async () => {
      // API取得
      const req = {
        params: {
          origin,
          destination,
          rateType,
        },
      }
      const apiInfoArr = [
        {
          key: 'resData',
          method: 'get',
          path: ApiPaths.MASTER_FWDR.LIGHT_SEARCH_RESULT,
          req,
        },
      ]
      const resObj = await CommonFunc.execApiAsync(apiInfoArr)
      const isApiSuccessful = CommonFunc.checkApiResponseObj(resObj, history)
      if (isApiSuccessful) {
        const res = resObj.resData.data.results
        // ソートの初期値は定数の最初の値で固定
        const initSort =
          ConstantsLightFwdrSearchResult.SORT_ITEM_ARR[0].sortTerm

        dispatch({
          type: LIGHT_BOOKING_REQUEST.CARGO_DETAILS.SET,
          cargoDetailArr,
        })
        dispatch({
          type: LIGHT_BOOKING_REQUEST.SELECT.SET,
          org,
          dst,
          commodity,
          orgId: origin,
          dstId: destination,
          commId: rateType,
        })
        dispatch({
          type: TABLES.SET,
          sort: initSort,
          tableData: res,
          cargoDetailArr,
        })
        //SearchSortの初期値を設定
        dispatch({
          type: SEARCH_SORT.SET,
          search: '',
          sort: initSort,
        })

        // 先頭にスクロール
        window.scrollTo(0, 0)
      }
    })()
  }, []) // eslint-disable-line

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <LayoutBox>
        <TitleLightSearchResult />
        <SearchSortLightFwdrSearchResult />
        <TableLightFwdrSearchResult />
        <PagingLightFwdrSearchResult />
        <BackButtonLightFwdrSearchResult
          details={props.history.location.state.state}
        />
      </LayoutBox>
    </AppContext.Provider>
  )
}

export default LightFwdrSearchResult
