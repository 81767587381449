/* eslint-disable prefer-template */
import React from 'react'
import MTableRow from '@material-ui/core/TableRow'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  row: {
    height: '58px',
  },
}))

const SetColor = (value) => {
  let color = ''
  switch (value) {
    case 'yellow':
      color = 'rgba(255, 199, 53, 0.6)'
      break

    case 'gray':
      color = 'rgba(51, 51, 51, 0.06)'
      break

    case 'transparent':
      color = 'transparent'
      break

    default:
      color = '#FAFAFA'
  }

  return color
}

/**
 * @param {Object} props - 以下
 * @param {*} className - スタイルを指定
 * @param {String} color - 背景色を変更する yellow gray
 * @returns {JSX.Element} - TableRow
 */
const TableRow = (props) => {
  const classes = useStyles()

  return (
    <MTableRow
      className={classes.row + ' ' + props.className}
      style={{
        background: SetColor(props.color),
      }}
    >
      {props.children}
    </MTableRow>
  )
}

export default TableRow
