import { UPDATE_QUESTION1 } from '../../../actions'
import ConstantsLightDeleteAccount from '../../../components/pages/authentication/LightDeleteAccount/ConstantsLightDeleteAccount'
import CommonValidation from '../../../components/common/function/CommonValidation'

/** @type {Number} 定数で設定しているQ1の質問の個数 */
const checkCount = ConstantsLightDeleteAccount.QUESTIONS_Q1_ARR.length

const initQ1 = {
  check: Array(checkCount).fill(0),
  text: '',
  texts: ['D501V0101'],
  choices: [
    'D501V0102',
    'D501V0103',
    'D501V0104',
    'D501V0105',
    'D501V0106',
    'D501V0107',
    'D501V0108',
    'D501V0109',
    'D501V0110',
  ],
  errors: false,
  helpers: '',
}

const QuestionData = (state, action) => {
  if (action.index !== null) {
    //チェックボックスが変更された時
    const check = [...state.check]
    check[action.index] = action.answer
    if (Number(action.index) === state.choices.length - 1) {
      const errors = false
      const helpers = ''
      const text = ''
      return { ...state, check, text, errors, helpers }
    } else {
      return { ...state, check }
    }
  } else {
    //テキストが変更された時
    const check = CommonValidation.withdrawalReason(action.answer)
    const errors = check.checkResult
    const helpers = check.checkResult ? check.errMsg : ''
    const text = action.answer
    return { ...state, text, errors, helpers }
  }
}

/**
 * state: Q1の回答を保持
 * @param {Object} [state={}] -
 * @param {Object} action -
 * @returns {*} -
 */
const Q1 = (state = initQ1, action) => {
  switch (action.type) {
    case UPDATE_QUESTION1:
      return QuestionData(state, action)
    default:
      return state
  }
}

export default Q1

export { initQ1 }
