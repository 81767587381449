import { CHECKBOX } from '../../../actions'
import ConstantsBidDetails from '../../../components/pages/fwdr/fwdrBidDetails/ConstantsBidDetails'

/** チェックボックス名の配列 */
const nameArr = ConstantsBidDetails.CHECKBOX_ARR.map(({ name }) => name)

/** 初期表示データ */
const initCheckbox = Object.assign(
  {},
  ...nameArr.map((name) => ({ [name]: true }))
)

/**
 * チェックボックスの状態を編集
 * @param {Object} state state
 * @param {Object} action dispatchで受け取ったaction
 * @return {Object} 編集後のstate
 */
const editCheckbox = (state, action) => {
  const { name, checked } = action

  //チェック状態を変更
  return { ...state, [name]: checked }
}

/**
 * チェックボックスの状態を保持
 * @param {Object} [state=initPaging] 初期表示データ
 * @param {Object} action name, checked
 * @return {Object} チェックボックスの状態
 */
const Checkbox = (state = initCheckbox, action) => {
  const { type } = action
  switch (type) {
    case CHECKBOX.SET:
      return editCheckbox(state, action)
    default:
      return state
  }
}

export default Checkbox

export { initCheckbox }
