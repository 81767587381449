import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'

import Theme from '../../common/theme/Theme'
import AppContext from '../../../contexts/AppContext'
import { LIGHT_REGISTER_NEW_ACCOUNT } from '../../../actions'
import AuthenticationTableContainer from '../../common/table/AuthenticationTableContainer'
import CommonCalc from '../../../components/common/function/CommonCalc'
import Common from '../../../constants/Common'

const useStyles = makeStyles(() => ({
  inputLabel: Theme.signup.inputLabel,
  root: Theme.signup.root,
  container: Theme.signup.container,
  autoComp: Theme.signup.autoComp,
}))

/**
 * @returns {JSX} - プランを更新
 */
const PlanSelect = ({ shipper, carrier, forwarder }) => {
  const { state, dispatch } = useContext(AppContext)
  const classes = useStyles()
  const { t } = useTranslation()

  return useMemo(() => {
    /**
     * プランを更新
     * @param {*} _e - event
     * @param {*} value - value
     * @returns {void}
     */
    const updatePlan = (_e, value) => {
      if (carrier) {
        dispatch({
          type: LIGHT_REGISTER_NEW_ACCOUNT.CAR_PLAN.UPDATE,
          value: value?.carPlanId ?? '',
        })
      } else if (forwarder) {
        dispatch({
          type: LIGHT_REGISTER_NEW_ACCOUNT.FWDR_PLAN.UPDATE,
          value: value?.fwdrPlanId ?? '',
        })
      } else if (shipper) {
        dispatch({
          type: LIGHT_REGISTER_NEW_ACCOUNT.SHIP_PLAN.UPDATE,
          value: value?.shipPlanId ?? '',
        })
      }
    }

    return (
      <AuthenticationTableContainer>
        <form noValidate>
          <Autocomplete
            options={state.Plan.planArr.filter(
              (v) => v.displayFlg === Common.DISPLAY_FLG.ON
            )}
            getOptionLabel={(option) =>
              `${option.planName} - ${option.currency} ${CommonCalc.addComma(option.fee, true)}`
            }
            className={`${classes.root} ${classes.autoComp}`}
            onChange={updatePlan}
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{
                  classes: {
                    root: classes.inputLabel,
                    focused: 'focused',
                    shrink: 'shrink',
                  },
                }}
                className={classes.root}
                label={t('5th0003')}
                margin="normal"
                fullWidth
                required
                variant="outlined"
              />
            )}
          />
        </form>
      </AuthenticationTableContainer>
    )
  }, [
    dispatch,
    state.Plan.planArr,
    classes.root,
    classes.inputLabel,
    classes.autoComp,
    shipper,
    carrier,
    forwarder,
    t,
  ])
}

export default PlanSelect
