import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

import AirlineTopButtons from './AirlineTopButtons'
import LayoutBox from '../../../common/layout/LayoutBox'
import Theme from '../../../common/theme/Theme'
import Paths from '../../../../constants/Paths'

const useStyles = makeStyles(() => ({
  root: Theme.topInner,
  outer: Theme.topOuter,
}))

const AirlineTop = () => {
  const classes = useStyles()

  return (
    <LayoutBox>
      <div className={classes.outer}>
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.root}
        >
          <Grid item md={10} xs={10}>
            <AirlineTopButtons
              bookingListPath={Paths.AIRLINE.LIGHT_BOOKING_LIST}
              billingReportPath={Paths.AIRLINE.LIGHT_BILLING_REPORT}
            />
          </Grid>
        </Grid>
      </div>
    </LayoutBox>
  )
}

export default AirlineTop
