import { makeStyles } from '@material-ui/core'
import React, { useContext } from 'react'

import Paths from '../../../../../constants/Paths'
import SearchButton from '../../../../common/buttons/SearchButton'
import AppContext from '../../../../../contexts/AppContext'

const useStyles = makeStyles((theme) => ({
  ok: theme.backButtonCenter,
}))

/**
 * @return {JSX} Searchボタンコンポーネント
 */
const SearchButtonLightBookingRequest = () => {
  const classes = useStyles()
  const { state } = useContext(AppContext)

  // 必須項目の入力チェック
  const checkInput = () => {
    const hasNull = Object.values(state.ShippingDetails).some((v) => v === null)
    const notAddedCargoDetail = state.cargoDetailArr.length === 0
    return hasNull || notAddedCargoDetail
  }

  // 次画面に渡すデータの整形
  const commData = state.SelectBox.commodity.find(
    (v) => v.commId === state.ShippingDetails.commodity
  )
  const dstData = state.Airport.getAirportInfo(state.ShippingDetails.dst)
  const orgData = state.Airport.getAirportInfo(state.ShippingDetails.org)

  const resData = {
    cargoDetailArr: state.cargoDetailArr,
    commodity: commData ? commData.comm : null,
    commId: state.ShippingDetails.commodity,
    dst: dstData ? `${dstData.airportCode} - ${dstData.airport}` : null,
    org: orgData ? `${orgData.airportCode} - ${orgData.airport}` : null,
    orgId: state.ShippingDetails.org,
    dstId: state.ShippingDetails.dst,
    flightObj: {},
    laneObj: {},
  }

  return (
    <div className={classes.ok}>
      <SearchButton
        path={Paths.FWDR.LIGHT_SEARCH_RESULT}
        state={resData}
        disabled={checkInput()}
      />
    </div>
  )
}

export default SearchButtonLightBookingRequest
