import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import AppContext from '../../../../../contexts/AppContext'
import Common from '../../../../../constants/Common'
import TableCell from '../../../../common/table/TableCell'
import ConstantsLightJhClientList, {
  TabName,
} from '../ConstantsLightJhClientList'
import { useTranslation } from 'react-i18next'

/** @typedef {import('../ConstantsLightJhClientList').TableInfo} TableInfo テーブル情報 */

/**
 * 1行分の表データを作成
 * @param {Object} props 下記内容
 * @param {Number} props.page 表示中のページ番号
 * @param {Number} props.index ページ上のインデックス
 * @return {JSX} TableCellコンポーネント(1行分)
 */
const TableCellsLightJhClientList = ({ page, index }) => {
  const { state } = useContext(AppContext)
  const { t } = useTranslation()

  const { FWDR_TABLE_ARR, CARRIER_TABLE_ARR } = ConstantsLightJhClientList

  const tableArr =
    state.Tabs === TabName.forwarder ? FWDR_TABLE_ARR : CARRIER_TABLE_ARR

  /** @type {Object} 1行分のデータ */
  const contents = state.Tables.showData[page][index]

  /**
   * 定数で定義した表データのコンポーネントを作成
   * @param {TableInfo} tableInfo テーブル情報
   * @param {Number} index mapのindex
   * @return {JSX} TableCellコンポーネント(1個分)
   */
  const createTableCell = (tableInfo, index) => {
    const { cellKey, cellCategory } = tableInfo

    /** @type {*} セルに表示する内容 */
    let displayContent = null

    switch (cellCategory) {
      case Common.CELL_CATEGORY.TEXT:
        displayContent = contents[cellKey]
        break
      case Common.CELL_CATEGORY.STATUS:
        displayContent = t(contents[cellKey])
        break
      default:
        displayContent = null
    }
    return <TableCell key={index}>{displayContent}</TableCell>
  }

  return tableArr.map(createTableCell)
}

TableCellsLightJhClientList.propTypes = {
  page: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
}

export default TableCellsLightJhClientList
