import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import IconBox from '../../common/info/IconBox'
import DeleteSmallDialog from '../../common/dialog/DeleteSmallDialog'
import {
  UPDATE_FWDR_ICON_PATH,
  UPDATE_FWDR_FILE,
  UPDATE_FWDR_IS_DELETE_ICON,
  UPDATE_FWDR_ICON_PATH_INPUTERR,
  UPDATE_CAR_ICON_PATH,
  UPDATE_CAR_FILE,
  UPDATE_CAR_IS_DELETE_ICON,
  UPDATE_CAR_ICON_PATH_INPUTERR,
} from '../../../actions'
import CommonValidation from '../../common/function/CommonValidation'

const useStyles = makeStyles((theme) => ({
  head: theme.textBoldBLU,
  textBlk: theme.textNormalBLK,
  errText: theme.textNormalRED,
}))

/**
 * コンポーネントを更新するか判定する
 * @param {Object} prev 前回の値
 * @param {Object} next 最新の値
 * @returns {Boolean} falseの場合更新する
 */
const areEqual = (prev, next) => {
  // 各入力欄の値を比較
  // 一致していれば更新無し
  const iconValue = prev.companyinfo.icon.value === next.companyinfo.icon.value
  const authorityFlgValue =
    prev.companyinfo.authorityFlg.value === next.companyinfo.authorityFlg.value

  return iconValue && authorityFlgValue
}

const Icon = React.memo(({ dispatch, companyinfo, carrier }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [errText, setErrText] = useState('')
  const { icon, authorityFlg } = companyinfo
  const authorityFlgValue = authorityFlg.value

  const handleUploadIcon = (event) => {
    const files = event.target.files

    if (files.length > 0) {
      const file = files[0]
      const check = CommonValidation.imgSize(file)
      setErrText(check.checkResult ? check.errMsg : '')
      if (!check.checkResult) {
        const reader = new FileReader()
        reader.onload = (event) => {
          dispatch({
            type: carrier ? UPDATE_CAR_ICON_PATH : UPDATE_FWDR_ICON_PATH,
            icon: event.target.result,
          })
          dispatch({
            type: carrier ? UPDATE_CAR_FILE : UPDATE_FWDR_FILE,
            file,
          })
          dispatch({
            type: carrier
              ? UPDATE_CAR_ICON_PATH_INPUTERR
              : UPDATE_FWDR_ICON_PATH_INPUTERR,
            inputErr: false,
          })
          dispatch({
            type: carrier
              ? UPDATE_CAR_IS_DELETE_ICON
              : UPDATE_FWDR_IS_DELETE_ICON,
            isDeleteIcon: '',
          })
        }
        reader.readAsDataURL(file)
      }
    }
  }

  const handleDeleteIcon = () => {
    dispatch({
      type: carrier ? UPDATE_CAR_ICON_PATH : UPDATE_FWDR_ICON_PATH,
      icon: '',
    })
    dispatch({
      type: carrier ? UPDATE_CAR_IS_DELETE_ICON : UPDATE_FWDR_IS_DELETE_ICON,
      isDeleteIcon: 1,
    })
    dispatch({
      type: carrier
        ? UPDATE_CAR_ICON_PATH_INPUTERR
        : UPDATE_FWDR_ICON_PATH_INPUTERR,
      inputErr: true,
    })
    setErrText('VALIDATION011')
  }

  // 編集用
  const createIconBox = () => {
    return (
      <Grid container>
        <Grid item md={10} xs={10}>
          <div className={classes.head}>
            {carrier ? t('D301V0207') : t('D301V0019')}
          </div>
          <span className={classes.errText}>{t(errText)}</span>
        </Grid>
        <Grid item md={10} xs={10}>
          <IconBox
            btnflg={true}
            iconPath={icon.value}
            onAddIcon={handleUploadIcon}
          />
        </Grid>
        <Grid item md={10} xs={10}>
          {icon.value === '' || icon.value === null ? (
            ''
          ) : (
            <DeleteSmallDialog onDelete={handleDeleteIcon} />
          )}
        </Grid>
      </Grid>
    )
  }

  // 表示用
  const createIconLabel = () => {
    return (
      <Grid container>
        <Grid item md={10} xs={10}>
          <div className={classes.head}>
            {carrier ? t('D301V0207') : t('D301V0019')}
          </div>
        </Grid>
        <Grid item md={10} xs={10}>
          <IconBox
            btnflg={false}
            icon={icon.value}
            iconPath={icon.value}
            id="documentIcon"
          />
        </Grid>
      </Grid>
    )
  }

  return (
    <div className={classes.formControl}>
      {authorityFlgValue ? createIconBox() : createIconLabel()}
    </div>
  )
}, areEqual)

Icon.displayName = 'Icon'

Icon.propTypes = {
  dispatch: PropTypes.func.isRequired,
  companyinfo: PropTypes.object.isRequired,
  carrier: PropTypes.bool,
}

export default Icon
