import { Grid } from '@material-ui/core'
import React from 'react'

import SearchLightJhClientList from './SearchLightJhClientList'
import TabLightCarFlightMgmt from './TabLightJhClientList'
import SortLightJhClientList from './SortLightJhClientList'

/**
 * @return {JSX} サーチソートコンポーネントとタブ
 */
const TabsAndSearchSortLightJhClientList = () => {
  return (
    <Grid container justify="center">
      <SearchLightJhClientList />
      <Grid container item md={10} justify="space-between">
        <TabLightCarFlightMgmt />
        <SortLightJhClientList />
      </Grid>
    </Grid>
  )
}

export default TabsAndSearchSortLightJhClientList
