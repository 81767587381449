import { Grid } from '@material-ui/core'
import React, { useContext } from 'react'

import BackButtonBidDetails from './BackButtonBidDetails'
import SaveAsDraftButtonBidDetails from './SaveAsDraftButtonBidDetails'
import ConfirmButtonBidDetails from './ConfirmButtonBidDetails'
import AppContext from '../../../../../contexts/AppContext'

import Common from '../../../../../constants/Common'
import ConstantsBidDetails from '../ConstantsBidDetails'

/**
 * @return {JSX} ボタンコンポーネント
 */
const ButtonBidDetails = () => {
  const { state } = useContext(AppContext)

  /** 必須項目のcellKeyの配列 */
  const requiredKeyArr = ConstantsBidDetails.TABLE_ARR.filter(
    (table) => table.required
  )

  /** 入力チェックを行うデータの配列 */
  const checkDataArr = state.Tables.editData.filter(({ statusStr }) => {
    // NA/Lost以外のデータが対象
    const isNa = statusStr === Common.BID_STATUS.NA.TEXT
    const isLost = statusStr === Common.BID_STATUS.LOST.TEXT
    return !isNa && !isLost
  })

  /**
   * 必須項目に空欄があるか確認
   * @returns {boolean} 空欄があればtrue
   */
  const getHasEmptyCell = () => {
    /** 各行に未入力欄があるかの配列 */
    const hasEmptyCells = checkDataArr.map((data) =>
      // 未入力欄があればtrueを返す
      requiredKeyArr
        .map(({ cellKey }) => {
          // transit欄はFlightがDirectの場合入力欄無し
          const isDirect = data.flightTypeId === Common.FLIGHT_TYPE.DIRECT
          const isTransit = cellKey === 'transitId'
          if (isDirect && isTransit) {
            return false
          }
          // 上記以外は入力欄有り
          return !data[cellKey]
        })
        .includes(true)
    )
    /** 1行でも未入力欄がある行があればtrue */
    const hasEmptyCell = hasEmptyCells.includes(true)
    return hasEmptyCell
  }

  /**
   * エラーの入力欄があるか確認
   * @returns {boolean} エラーがあればtrue
   */
  const getHasErrorCell = () => Object.values(state.Error).includes(true)

  /**
   * Confirmボタンの非活性判定
   * @returns {boolean} 非活性判定
   */
  const getConfirmDisabled = () => {
    let isDisabled = false
    // 新規入札時の非活性判定
    if (state.IsNew) {
      /** 未入力欄があるか確認 */
      const hasEmptyCell = getHasEmptyCell()
      /** エラーの入力欄があるか確認 */
      const hasErrorCell = getHasErrorCell()

      // 1つでもtrueがあれば非活性
      isDisabled = hasEmptyCell || hasErrorCell
    }
    return isDisabled
  }

  /**
   *SaveAsDraftボタンの非活性判定
   * @returns {boolean} 非活性判定
   */
  const getSaveAsDraftDisabled = () => {
    /** エラーの入力欄があるか確認 */
    const hasErrorCell = getHasErrorCell()

    // エラーの入力欄があれば非活性
    return hasErrorCell
  }

  return (
    <Grid item container justify="center" md={12} spacing={2}>
      <Grid item>
        <BackButtonBidDetails />
      </Grid>
      {state.IsNew ? (
        <>
          <Grid item>
            <SaveAsDraftButtonBidDetails disabled={getSaveAsDraftDisabled()} />
          </Grid>
          <Grid item>
            <ConfirmButtonBidDetails disabled={getConfirmDisabled()} />
          </Grid>
        </>
      ) : null}
    </Grid>
  )
}

export default ButtonBidDetails
