import React, { useReducer, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

import LayoutBox from '../../../common/layout/LayoutBox'
import TitleSelectForwarder from './00TitleSelectForwarder/TitleSelectForwarder'
import AppContext from '../../../../contexts/AppContext'
import reducer, {
  initialState,
} from '../../../../reducers/shipper/shipperSelectForwarder'
import SearchSortSelectForwarder from './01SearchSortSelectForwarder/SearchSortSelectForwarder'
import TableSelectForwarder from './02TableSelectForwarder/TableSelectForwarder'
import ButtonsSelectForwarder from './03ButtonsSelectForwarder/ButtonsSelectForwarder'
import PagingSelelctForwarder from './04PagingSelelctForwarder/PagingSelelctForwarder'
import ConstantsSelectForwarder from './ConstantsSelectForwarder'
import { TABLES, SEARCH_SORT, CHECKBOX } from '../../../../actions'
import ApiPaths from '../../../../constants/ApiPaths'
import CommonFunc from '../../../common/function/CommonFunc'
import CommonPlan from '../../../../constants/CommonPlan'
import Common from '../../../../constants/Common'

/**
 * @param {Object} props - 以下
 * @param {Object} history 前ページから受け取った情報
 * @return {JSX} 荷主SelectForwarder画面コンポーネント
 */
const ShipperSelectForwarder = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { i18n } = useTranslation()
  const history = useHistory()

  useEffect(() => {
    ;(async () => {
      // 選択中の混載業者を取得
      const selectFwdrArr =
        props.history.location.state?.state.selectFwdrObj.selectFwdrArr.filter(
          (v) => v.delFlg !== Common.DEL_FLG.ON
        ) ?? []
      // API取得
      const req = {
        params: {
          planArr: [
            CommonPlan.STANDARD.ID,
            CommonPlan.PROFESSIONAL.ID,
            CommonPlan.ENTERPRISE.ID,
          ],
          selectFwdrArr: selectFwdrArr.map(
            (selectFwdr) => selectFwdr.fwdrNameId
          ),
        },
      }
      const apiInfoArr = [
        {
          key: 'registerForwarderNameArr',
          method: 'get',
          path: ApiPaths.COMMON_API.REGISTER_FORWARDER_NAME,
          req,
        },
      ]
      const resObj = await CommonFunc.execApiAsync(apiInfoArr)
      const isApiSuccessful = CommonFunc.checkApiResponseObj(resObj, history)
      if (isApiSuccessful) {
        const { registerForwarderNameArr } = resObj

        // ソートの初期値は定数の最初の値で固定
        const initSort = ConstantsSelectForwarder.SORT_ITEM_ARR[0].sortTerm

        dispatch({
          type: TABLES.INIT,
          data: registerForwarderNameArr.data.results,
          sort: initSort,
          i18n,
        })

        //SearchSortの初期値を設定
        dispatch({
          type: SEARCH_SORT.SET,
          search: '',
          sort: initSort,
        })

        // チェックボックスのインデックスを取得
        const apiIndexNumArr = selectFwdrArr
          // 削除済を取り除く
          .filter(({ delFlg }) => !delFlg)
          // idの一致するAPIのindexを配列で取得
          .map(({ fwdrNameId }) => {
            const apiIndexNum = registerForwarderNameArr.data.results.findIndex(
              (forwarderNameObj) => {
                return forwarderNameObj.fwdrNameId === fwdrNameId
              }
            )
            return String(apiIndexNum)
          })
        dispatch({
          type: CHECKBOX.SET,
          data: apiIndexNumArr,
          i18n,
        })
      }
    })()
  }, []) // eslint-disable-line

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <LayoutBox>
        <TitleSelectForwarder />
        <SearchSortSelectForwarder />
        <TableSelectForwarder />
        <PagingSelelctForwarder />
        <ButtonsSelectForwarder fwdrArr={props} />
      </LayoutBox>
    </AppContext.Provider>
  )
}

ShipperSelectForwarder.propTypes = {
  history: PropTypes.object.isRequired,
}

export default ShipperSelectForwarder
