import React, { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'

import AirLineCellLightCarAirFreight from './AirLineCellLightCarAirFreight'
import AirportCellLightCarAirFreight from './AirportCellLightCarAirFreight'
import FscCellLightCarAirFreight from './FscCellLightCarAirFreight'
import RateCellsLightCarAirFreight from './RateCells/RateCellsLightCarAirFreight'
import Common from '../../../../../constants/Common'
import TableCell from '../../../../common/table/TableCell'
import AllInRateCellLightCarAirFreight from './AllInRateCellLightCarAirFreight'
import AppContext from '../../../../../contexts/AppContext'

/**
 * 1行分の表データを作成
 * @param {*} props 下記要素
 * @param {Number} props.index ページ上のインデックス
 * @param {String} props.rateType レートタイプ
 * @return {JSX} TableCellコンポーネント(1行分)
 */
const TableCellsLightCarAirFreight = ({ index, rateType }) => {
  const { state } = useContext(AppContext)

  return useMemo(() => {
    /**
     * AirlineCellを呼び出す
     * @returns {JSX|null} Airline欄またはnull
     */
    const createAirlineCell = () => {
      if (state.Gsa) {
        if (rateType === Common.RATE.TYPE.DG) {
          return <AirLineCellLightCarAirFreight index={index} />
        } else {
          return <TableCell />
        }
      }
      return null
    }

    /**
     * FSCセルを呼び出す
     * @returns {JSX|null} FSC欄またはnull
     */
    const createFscCell = () => {
      switch (rateType) {
        case Common.RATE.TYPE.GEN:
          return <TableCell />
        case Common.RATE.TYPE.DG:
          return <FscCellLightCarAirFreight index={index} />
        case Common.RATE.TYPE.ICE:
          return <AllInRateCellLightCarAirFreight index={index} />
        default:
          return null
      }
    }

    return (
      <>
        {createAirlineCell()}
        {rateType === Common.RATE.TYPE.DG ? (
          <AirportCellLightCarAirFreight index={index} />
        ) : (
          <TableCell />
        )}
        {createFscCell()}
        <RateCellsLightCarAirFreight index={index} rateType={rateType} />
      </>
    )
  }, [index, rateType, state.Gsa])
}

TableCellsLightCarAirFreight.propTypes = {
  index: PropTypes.number.isRequired,
  rateType: PropTypes.string.isRequired,
}

export default TableCellsLightCarAirFreight
