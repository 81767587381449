import React, { useContext, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { LIGHT_FLIGHT_MGMT } from '../../../../../../actions'
import AppContext from '../../../../../../contexts/AppContext'
import CommonValidation from '../../../../../common/function/CommonValidation'
import TextField from '../../../../../common/text/TextField'
import Content from '../../03AccordionCarFlightMgmt/Content'

/**
 * @param {*} props 下記内容のProps
 * @param {Number} props.index Routingのインデックス
 * @returns {JSX} FlightNumber入力欄
 */
const FlightNumber = (props) => {
  const { index } = props
  const { t } = useTranslation()
  const { state, dispatch } = useContext(AppContext)
  const [error, setError] = useState(false)
  const [helperText, setHelperText] = useState('')
  /** @type {Function} Routingの情報を取得する */
  const getRoutingParam = state.Popup.getRoutingParam

  return useMemo(() => {
    /** @type {String} 航空便番号 */
    const flightNumber = getRoutingParam(index, 'flightNumber')

    /**
     * FlightNumber更新
     * @param {String} value - 入力値
     * @returns {void} なし
     */
    const onChange = (value) => {
      const { checkResult, errMsg } = CommonValidation.flightNumber(value)
      setError(checkResult)
      setHelperText(t(errMsg))
      dispatch({
        type: LIGHT_FLIGHT_MGMT.EDIT.ROUTING,
        index,
        flightNumber: checkResult ? '' : value,
      })
    }

    return (
      <Content
        title={t('D301V0131')}
        content={
          <TextField
            defaultValue={flightNumber}
            onChange={(event) => onChange(event.target.value)}
            error={error}
            helperText={helperText}
            disabled={!state.Auth}
          />
        }
      />
    )
  }, [error, dispatch, helperText, index, t, getRoutingParam, state.Auth])
}

FlightNumber.propTypes = {
  index: PropTypes.number.isRequired,
}
export default FlightNumber
