import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import Link from '@material-ui/core/Link'
import Divider from '@material-ui/core/Divider'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import Grid from '@material-ui/core/Grid'

import ApiPaths from '../../../../constants/ApiPaths'
import CommonFunc from '../../../common/function/CommonFunc'
import CommonValidation from '../../../common/function/CommonValidation'
import Common from '../../../../constants/Common'
import PrimaryButton from '../../../common/buttons/PrimaryButton'
import SecondaryButton from '../../../common/buttons/SecondaryButton'
import Paths from '../../../../constants/Paths'
import restFacade from '../../../../actions/rest-facade'

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    color: theme.palette.primary.main,

    '&.focused': {
      border: 0,
      color: theme.palette.primary.main,
    },
    '&.shrink': {
      backgroundColor: theme.palette.info.main,
      transform: `translate(${10}px, ${1}px) scale(${0.75})`,
    },
  },
  root: {
    '&.MuiFormControl-marginNormal': {
      marginTop: 15,
      marginBottom: 5,
    },
    '& .MuiFormLabel-asterisk ': {
      color: theme.palette.required,
    },
    '& label.Mui-focused': {
      color: theme.palette.primary.main,
      backgroundColor: 'transparent',
    },
    '& .MuiInputBase-input': {
      color: theme.palette.primary.main,
    },
    '& .MuiFormLabel-root': {
      color: theme.palette.primary.main,
      backgroundColor: 'transparent',
    },
    '& .MuiFormLabel-root.Mui-error': {
      color: theme.palette.required,
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 0,
      '& fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '& input:valid + fieldset': {
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        borderWidth: 2,
      },
      '& input:invalid + fieldset': {
        color: theme.palette.primary.main,
        borderColor: theme.palette.reqired,
        borderWidth: 2,
      },
      '& input': {
        backgroundColor: theme.palette.info.main,
        color: theme.palette.primary.main,
      },
      '& input:valid': {
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        borderWidth: 2,
      },
    },
  },
  sign: {
    marginTop: 20,
    marginBottom: 200,
    marginLeft: '35px',
    width: theme.buttonSize.width.large,
    height: theme.buttonSize.height.medium,
  },
  submit: {
    marginTop: 20,
    border: 'none',
    marginLeft: '75px',
    width: theme.buttonSize.width.large,
    height: theme.buttonSize.height.medium,
  },
  divider: {
    margin: '25px auto',
    width: '100%',
    background: theme.palette.info.main,
  },
  link: {
    display: 'block',
    margin: 0,
    width: 'fit-content',
    color: theme.palette.info.main,
    fontSize: theme.fontSize.header,
  },
  section: {
    marginTop: 10,
    marginBottom: 20,
  },
  text: {
    fontSize: '20px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: theme.palette.info.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.fontSize.stepbar,
    },
  },
  radio: {
    color: theme.palette.info.main,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  label: {
    marginTop: '1rem',
    display: 'block',
    color: theme.palette.info.main,
    width: 'fit-content',
    '& .MuiFormLabel-asterisk': {
      color: theme.palette.required,
    },
    '&.Mui-focused': {
      color: '#FAFAFA !important',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.fontSize.stepbar,
    },
  },
  white: {
    color: theme.palette.info.main,
  },
  required: {
    color: theme.palette.required,
    fontSize: theme.fontSize.header,
    fontWeight: 'bold',
    marginTop: '20px',
  },
  // required: {
  //   color: theme.palette.required
  // },
  // スマホ未対応のためコメントアウト
  // select: {
  //   width: '100%',
  //   height: '44px',
  //   marginTop: '0.5rem',
  //   backgroundColor: theme.palette.info.main,
  //   border: '2px solid',
  //   borderColor: theme.palette.primary.main,
  //   '&.Mui-focused': {
  //     backgroundColor: theme.palette.info.main,
  //   },
  //   '&:hover': {
  //     backgroundColor: theme.palette.info.main,
  //   },
  //   [theme.breakpoints.up('md')]: {
  //     display: 'none',
  //   },
  //   '& .MuiSelect-root': {
  //     color: theme.palette.primary.main,
  //     textAlign: 'left',
  //     height: '1rem',
  //     padding: '0.5rem 1rem',
  //   },
  // },
  // selectForm: {
  //   display: 'block',
  //   width: '100%',
  // },
}))

const Text = () => {
  let signIn = () => {}
  let signDatas = () => {}
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const { register, handleSubmit } = useForm()
  const onSubmit = () => {}
  const [value, set] = React.useState('1')
  const [mail, setMail] = useState('')
  const [password, setPassword] = useState('')
  const [inputErrs, setInputErrs] = useState({ mail: false, password: false })
  const [helperTxts, setHelperTxts] = useState({ mail: '', password: '' })
  const [errCode, setErrCode] = useState(0)

  // ラジオボタン選択時
  const handleChange = (event) => {
    set(event.target.value)
  }

  // email入力時の挙動
  const mailChange = (event) => {
    const value = event.target.value
    setMail(value)
    const validationCheck = CommonValidation.mail(value)
    setHelperTxts({ ...helperTxts, mail: t(validationCheck.errMsg) })
    setInputErrs({ ...inputErrs, mail: validationCheck.checkResult })
  }

  // password入力時
  const passwordChange = (event) => {
    const value = event.target.value
    setPassword(value)
    const validationCheck = CommonValidation.password(value)
    setHelperTxts({ ...helperTxts, password: t(validationCheck.errMsg) })
    setInputErrs({ ...inputErrs, password: validationCheck.checkResult })
  }

  const linkClick = () => {
    CommonFunc.clickEvent(
      { path: Paths.AUTHENTICATION.REISSUE_PASSWORD },
      history
    )
  }

  // パスワード・email入力時のみSignInボタン活性
  const buttonCheck = () => {
    // eslint-disable-next-line no-unneeded-ternary
    const emptyFlg = mail && password ? false : true
    // eslint-disable-next-line no-unneeded-ternary
    const errorFlg = !inputErrs.mail && !inputErrs.password ? false : true
    if (!emptyFlg && !errorFlg) {
      return false
    } else {
      return true
    }
  }

  // SignIn
  signDatas = (res) => {
    switch (res.data.status) {
      case 200:
        // スプレッド構文でトークンとユーザー情報を分離
        // eslint-disable-next-line no-case-declarations
        const { token, ...user } = res.data.results
        // Tokenとユーザー情報をStrageに保存
        CommonFunc.setStorage(Common.KEY.TOKEN, token)
        // eslint-disable-next-line no-case-declarations
        const userData = {
          ...user,
          userType: CommonFunc.getLoginUserType(user),
        }
        CommonFunc.setStorage(Common.KEY.USER_INFO, JSON.stringify(userData))

        // Top画面に遷移
        CommonFunc.topTranslation(history)
        break
      case 400:
        setErrCode(res.data.info.ErrorCode)
        break
      default:
        break
    }
  }

  signIn = () => {
    const req = {
      email: mail,
      password,
    }
    // SignInAPI
    restFacade.post(
      ApiPaths.AUTHENTICATION.SIGN_IN,
      (res) => signDatas(res),
      req
    )
  }

  // 選択した業種によって遷移するRegister new Account画面分ける
  const selectValue = () => {
    if (value === '1') {
      return (
        <>
          <SecondaryButton
            addclass={classes.sign}
            path={Paths.AUTHENTICATION.LIGHT_AIRLINE_REGISTER_NEW_ACCOUNT}
          >
            {t('D001V0006')}
          </SecondaryButton>
        </>
      )
    } else if (value === '2') {
      return (
        <>
          <SecondaryButton
            addclass={classes.sign}
            path={Paths.AUTHENTICATION.LIGHT_FWDR_REGISTER_NEW_ACCOUNT}
          >
            {t('D001V0006')}
          </SecondaryButton>
        </>
      )
    } else if (value === '3') {
      return (
        <>
          <SecondaryButton
            addclass={classes.sign}
            path={Paths.AUTHENTICATION.LIGHT_SHIP_REGISTER_NEW_ACCOUNT}
          >
            {t('D001V0006')}
          </SecondaryButton>
        </>
      )
    }
  }

  return (
    <Grid container justify="center">
      <Grid item>
        <FormControl>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            {/* メールアドレス */}
            <TextField
              InputLabelProps={{
                classes: {
                  root: classes.inputLabel,
                  focused: 'focused',
                  shrink: 'shrink',
                },
              }}
              className={classes.root}
              variant="outlined"
              margin="normal"
              fullWidth
              label={t('D501V0018')}
              autoComplete="email"
              name="email"
              error={inputErrs.mail}
              helperText={helperTxts.mail}
              inputRef={register({
                required: `${t('D501V0039')}`,
              })}
              autoFocus
              onChange={mailChange}
            />
            {/* パスワード */}
            <TextField
              InputLabelProps={{
                classes: {
                  root: classes.inputLabel,
                  focused: 'focused',
                  shrink: 'shrink',
                },
              }}
              className={classes.root}
              variant="outlined"
              margin="normal"
              fullWidth
              name="password"
              label={t('D501V0075')}
              type="password"
              autoComplete="current-password"
              error={inputErrs.password}
              helperText={helperTxts.password}
              inputRef={register({
                required: `${t('D501V0076')}`,
              })}
              onChange={passwordChange}
            />
            <Link variant="body2" className={classes.link} onClick={linkClick}>
              {t('5th0001')}
            </Link>
            {errCode ? (
              <div className={classes.required}>
                {t(CommonFunc.getSignInMessage(errCode))}
              </div>
            ) : (
              <></>
            )}
            <div className={classes.section}>
              <PrimaryButton
                type="submit"
                addclass={classes.submit}
                onClick={signIn}
                disabled={buttonCheck()}
              >
                {t('D501V0001')}
              </PrimaryButton>
            </div>
            <Divider variant="middle" className={classes.divider} />
            {/* ラジオボタン */}
            <Grid container justify="center">
              <div className={classes.section}>
                <Typography className={classes.text}>
                  {t('D501V0003')}
                </Typography>
                <FormLabel color="secondary" className={classes.label}>
                  <span className={classes.required}>*</span>
                  {t('D501V0004')}
                </FormLabel>
                <RadioGroup
                  className={classes.radio}
                  value={value}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio className={classes.white} />}
                    label={t('D501V0005')}
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio className={classes.white} />}
                    label={t('D501V0006')}
                  />
                  <FormControlLabel
                    value="3"
                    control={<Radio className={classes.white} />}
                    label={t('D501V0007')}
                  />
                </RadioGroup>
                {/* スマホ未対応のためコメントアウト */}
                {/* <Select
              variant='filled'
              className={classes.select}
              value={value ? value : '0'}
              onChange={handleChange}>
              <MenuItem value='0'>{t('D001V0016')}</MenuItem>
              <MenuItem value='1'>{t('D001V0013')}</MenuItem>
              <MenuItem value='2'>{t('D001V0014')}</MenuItem>
              <MenuItem value='3'>{t('D001V0015')}</MenuItem>
            </Select> */}
                {selectValue()}
              </div>
            </Grid>
          </form>
        </FormControl>
        <Typography className={classes.text}>
          Copyright © 2024 CargoLabo Inc. All Rights Reserved.{' '}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default Text
