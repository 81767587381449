/* eslint-disable prefer-template */
import React from 'react'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  text: theme.textNormalBLK,
  headText: {
    ...theme.textBoldBLK,
    fontSize: '18px',
    color: '#333333',
  },
  boldText: {
    ...theme.textBoldBLK,
    color: '#333333',
  },
  yellow: { color: theme.palette.secondary.main },
  marginLeft: {
    marginLeft: '1rem',
  },
  marginBottom: {
    marginBottom: '0.5rem',
  },
  width: {
    maxWidth: '749px',
  },
}))

const InfomationLightDeleteAccountDone = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  // '01 / SIMPLE'の部分を返します
  const getInfoHead = (numStr, string) => {
    return (
      <div className={classes.marginBottom}>
        <span className={classes.text}>{numStr + ' / '}</span>
        <span className={`${classes.boldText} ${classes.yellow}`}>
          {string.slice(0, 1)}
        </span>
        <span className={classes.boldText}>{string.slice(1)}</span>
      </div>
    )
  }

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      spacing={4}
    >
      <Grid item className={classes.headText}>
        {t('D501V0135')}
      </Grid>
      {/* 01 / SIMPLE */}
      <Grid item container xs={10} className={classes.width}>
        <Grid item xs={12}>
          {getInfoHead('01', 'SIMPLE')}
        </Grid>
        <Grid item className={`${classes.text} ${classes.marginLeft}`}>
          {t('D501V0136')}
        </Grid>
      </Grid>
      {/* 02 / SPEED */}
      <Grid item container xs={10} className={classes.width}>
        <Grid item xs={12}>
          {getInfoHead('02', 'SPEED')}
        </Grid>
        <Grid item className={`${classes.text} ${classes.marginLeft}`}>
          {t('D501V0137')}
        </Grid>
      </Grid>
      {/* 03 / SEAMLESS */}
      <Grid item container xs={10} className={classes.width}>
        <Grid item xs={12}>
          {getInfoHead('03', 'SEAMLESS')}
        </Grid>
        <Grid item className={`${classes.text} ${classes.marginLeft}`}>
          {t('D501V0138')}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default InfomationLightDeleteAccountDone
