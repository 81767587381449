import React, { useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { useHistory } from 'react-router-dom'

import LayoutBox from '../../../common/layout/LayoutBox'
import Theme from '../../../common/theme/Theme'
import Title from '../../../common/title/Title'
import AppContext from '../../../../contexts/AppContext'
import reducer from '../../../../reducers/notify/notifyList'
import NotifyTable from './NotifyTable'
import NotifyPaging from './NotifyPaging'
import NotifySearchSort from './NotifySearchSort'
import BackButton from '../../../common/buttons/BackButton'
import CommonFunc from '../../../common/function/CommonFunc'

const useStyles = makeStyles(() => ({
  center: Theme.center,
}))

const NotifyList = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const data = props.history.location.state?.state

  // データがない場合の初期値
  const initialState = {
    Tables: {
      initData: [],
      showData: [[]],
      amount: 0,
      apiDataArr: [],
    },
    Sort: data ?? {
      read: 'all',
      area1: false,
      area2: false,
      area3: false,
      search: '',
      sameCompany: '',
      acdigi: false,
      fwdr: '',
      carrier: '',
      shipper: '',
      date: '',
    },
    Paging: { page: 1 },
  }
  // 扱いたいデータstateとデータを操作するための処理dispatch
  const [state, dispatch] = useReducer(reducer, initialState)

  // Backボタンで各Top画面に遷移
  const history = useHistory()
  const topTranslation = () => {
    CommonFunc.topTranslation(history)
  }

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <LayoutBox>
        <Title>{t('D601V0001')}</Title>
        <Grid container justify="center">
          <Grid item md={10} xs={10}>
            <NotifySearchSort />
            <NotifyTable />
            <NotifyPaging />
            <div className={classes.center}>
              <BackButton onClick={topTranslation} />
            </div>
          </Grid>
        </Grid>
      </LayoutBox>
    </AppContext.Provider>
  )
}

export default NotifyList
