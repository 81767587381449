import { combineReducers } from 'redux'

import Tables from './Tables'
import Sort from './Sort'
import Paging from './Paging'

export default combineReducers({
  Tables,
  Sort,
  Paging,
})
