import { combineReducers } from 'redux'

import SearchSort, { initSearchSort } from '../../common/SearchSort'
import TableSelectForwarder, {
  initTablesSelectForwarder,
} from './TableSelectForwarder'
import Paging, { initPaging } from '../../common/Paging'
import CheckBox, { initCheckBox } from './CheckBox'

const initialState = {
  SearchSort: initSearchSort,
  Tables: initTablesSelectForwarder,
  Paging: initPaging,
  CheckBox: initCheckBox,
}

export default combineReducers({
  SearchSort,
  Tables: TableSelectForwarder,
  Paging,
  CheckBox,
})

export { initialState }
