import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'

import SelectInput from '../../../common/select/InputSelect'
import CommonFunc from '../../../common/function/CommonFunc'
import { TABLES, API_DATA, ERROR } from '../../../../actions'

const useStyles = makeStyles(() => ({
  ltWidth: {
    minWidth: 150,
  },
  normalWidth: {
    minWidth: 110,
  },
  padding: {
    '& .MuiAutocomplete-clearIndicator': {
      display: 'none',
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"]': {
      padding: '0px 22px 0px 4px !important',
    },
  },
}))

/** 呼び出したAPI名の配列 */
let calledApiArr = []

/**
 * 呼び出したAPI名の配列を初期化する
 * @param {String} name 削除するAPI名
 * @returns {void} なし
 */
const resetCalledApiArr = (name) => {
  if (name) {
    calledApiArr = calledApiArr.filter((apiName) => apiName !== name)
  } else {
    calledApiArr = []
  }
}

/**
 * 表に表示するセレクトコンポーネント
 * @param {Number} cellRequiredObj showDataのインデックス
 * @param {Object} stateApiData state.ApiData
 * @param {Object} apiData ConstantsBidDetailsで定義したAPI_DATAの1要素
 * @param {boolean} isError エラー
 * @param {boolean} isDisabled 非活性
 * @return {JSX} セレクトボックス
 */
const SelectCellBidDetails = (
  cellRequiredObj,
  stateApiData,
  apiData,
  isError,
  isDisabled
) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { path, id, name, getOptionLabel, isLongWidth } = apiData
  const { index, cellKey, cellValue, apiRequiredObj, relationValue } =
    cellRequiredObj
  const { history, dispatch } = apiRequiredObj

  /** 表示する選択肢の配列 */
  const optionArr = stateApiData[name] ?? []

  /** 選択中の値 */
  const value = optionArr?.find((data) => data[id] === cellValue) ?? null

  // スタイルを作成
  const style = `${classes.padding} ${isLongWidth ? classes.ltWidth : classes.normalWidth}`

  /**
   *APIから取得した情報を格納する
   * @param {Object} res レスポンス
   * @returns {void} なし
   */
  const setData = (res) => {
    let apiData = res.data.results
    if (name === 'airport') {
      apiData = apiData.sort((a, b) => (a.airportCode > b.airportCode ? 1 : -1))
    }
    dispatch({
      type: API_DATA.SET,
      name,
      apiData,
    })
  }

  /**
   * APIを呼び出す
   * @returns {void} なし
   */
  const callApi = async () => {
    const apiInfoArr = [{ key: 'apiData', method: 'get', path }]
    const resObj = await CommonFunc.execApiAsync(apiInfoArr)
    const isApiSuccessful = CommonFunc.checkApiResponseObj(resObj, history)
    if (isApiSuccessful) {
      const { apiData } = resObj
      setData(apiData)
    }
  }

  // APIから選択肢に表示するデータを取得
  useEffect(() => {
    if (optionArr.length === 0 && !calledApiArr.includes(name)) {
      calledApiArr.push(name)
      callApi()
    }
    // 登録済みデータが選択肢にない場合、未選択状態にする
    if (optionArr.length > 0 && cellValue && value === null) {
      onChange(null, value)
    }
  }, [optionArr]) // eslint-disable-line

  /**
   * 選択された時のイベント
   * @param {event} _ イベント
   * @param {Object} value 変更後の値
   * @returns {void} なし
   */
  const onChange = (_, value) => {
    dispatch({
      type: TABLES.EDIT,
      index,
      cellKey,
      id: value?.[id] ?? null,
      str: value ? getOptionLabel(value) : '',
    })
  }

  /**
   * エラー状態を変更する
   * @param {boolean} error エラー
   * @returns {void} なし
   */
  const onChangeError = (error) => {
    dispatch({
      type: ERROR.SET,
      name: `${id}${index}`,
      error,
    })
  }

  useEffect(() => {
    if (isError !== undefined) {
      onChangeError(isError)
    }
  }, [isError]) // eslint-disable-line

  /**
   * 選択肢の非活性状態を取得する
   * @param {Object} option 選択肢
   * @returns {Boolean} 非活性判定
   */
  const getOptionDisabled = (option) => {
    // 配列の場合は配列に含まれている選択肢を非活性にする
    if (Array.isArray(relationValue)) {
      return relationValue.includes(option[id])
    } else {
      // 配列でない場合は一致する選択肢を非活性にする
      return option[id] === relationValue
    }
  }

  return (
    <SelectInput
      className={style}
      options={optionArr}
      getOptionLabel={getOptionLabel}
      getOptionDisabled={(option) => getOptionDisabled(option)}
      value={value}
      placeholder={t('3rd0024')}
      onChange={onChange}
      error={isError}
      disabled={isDisabled}
    />
  )
}

SelectCellBidDetails.propTypes = {
  cellRequiredObj: PropTypes.object.isRequired,
  stateApiData: PropTypes.object.isRequired,
  apiData: PropTypes.objectOf({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    getOptionLabel: PropTypes.func.isRequired,
    path: PropTypes.string.isRequired,
    isLongWidth: PropTypes.bool,
  }).isRequired,
  isError: PropTypes.bool,
  isDisabled: PropTypes.bool,
}

export default SelectCellBidDetails
export { resetCalledApiArr }
