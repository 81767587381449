import { LIGHT_REGISTER_NEW_ACCOUNT } from '../../../actions'
import CommonValidation from '../../../components/common/function/CommonValidation'

const initPostalCode = {
  value: '',
  errorObj: {
    checkResult: false,
    errMsg: 'VALIDATION000',
  },
  require: true,
}

/**
 * 内容を保持
 * @param {Object} action - dispatchで受け取ったaction
 * @param {Object} state - state
 * @return {Object} - state
 */
const updateText = (action, state) => {
  const { value } = action
  const errorObj = CommonValidation.postalCode(value)
  return {
    ...state,
    value,
    errorObj,
  }
}

/**
 * PostalCode情報を保持
 * @param {Object} [state=initPostalCode] - state
 * @param {Object} action - action
 * @return {Object} - state
 */
const PostalCode = (state = initPostalCode, action) => {
  switch (action.type) {
    case LIGHT_REGISTER_NEW_ACCOUNT.POSTAL_CODE.UPDATE_TEXT:
      return updateText(action, state)
    default:
      return state
  }
}

export default PostalCode

export { initPostalCode }
