import { Grid } from '@material-ui/core'
import React, { useMemo, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import Common from '../../../../../constants/Common'
import AppContext from '../../../../../contexts/AppContext'
import PrimaryButton from '../../../../common/buttons/PrimaryButton'
import AreYouSureDialog from '../../../../common/dialog/commonDesignDialog/AreYouSureDialog'
import SavedChangesDialog from '../../../../common/dialog/commonDesignDialog/SavedChangesDialog'
import ApiPaths from '../../../../../constants/ApiPaths'
import CommonFunc from '../../../../common/function/CommonFunc'
import Paths from '../../../../../constants/Paths'

const useStyles = makeStyles((Theme) => ({
  saveButton: {
    width: Theme.buttonSize.width.large,
    height: Theme.buttonSize.height.large,
  },
  text: Theme.textNormalRED,
}))

/**
 * @return {JSX} Saveボタン
 */
const SaveButtonLightBookingDetails = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [savedChangesOpen, setSavedChangesOpen] = useState(false)
  const { state } = useContext(AppContext)

  // TotalA/Fの桁数判定を取得
  const isOverTotalAirFreightDigit = state.Rate.getIsOverTotalAirFreightDigit(
    state.Cargo.totalChargeableWgt
  )

  // A/FがMin料金より小さいか判定
  const isLessThanMinRate = state.Rate.getIsLessThanMinRate(
    state.Cargo.totalChargeableWgt
  )

  // Saveボタンの非活性判定 A/F欄入力エラー、CancelBooking欄入力エラー、TotalA/Fの桁数が10桁より大きい
  const disabled =
    state.Rate.inputError ||
    state.CancelBooking.inputError ||
    isOverTotalAirFreightDigit

  const isNotEditedCancelBooking =
    state.CancelBooking.cancelFlg === Common.CANCEL_FLG.NONE
  const isApprove = state.Status.isBookingRequest && isNotEditedCancelBooking

  const saveButtonText = isApprove ? t('D010V0088') : t('D301V0021') //ApproveまたはSave

  return useMemo(() => {
    const openFunc = () => setOpen(true)
    const closeFunc = () => setOpen(false)
    /**
     * 編集内容を保存
     * @returns {void} なし
     */
    const save = async () => {
      setOpen(false)
      /** @type {Object} リクエストデータ */
      const req = {
        data: {
          shcArr: state.ShcObj.getRequestData(),
          cancelObj: state.CancelBooking.getRequestData(),
          cargoId: state.ShippingDetails.cargoId,
          cargoUpdatedAt: state.ShippingDetails.cargoUpdatedAt,
          bookingId: state.ShippingDetails.bookingId,
          bookingUpdatedAt: state.ShippingDetails.bookingUpdatedAt,
          carDocumentArr: state.Document.getRequestData(),
          rate: state.Rate.rateObj.rate,
          flightObj: {
            flightChangeInfoA:
              state.Flight.flightChangeInfoA === ''
                ? null
                : state.Flight.flightChangeInfoA,
            flightChangeInfoB:
              state.Flight.flightChangeInfoB === ''
                ? null
                : state.Flight.flightChangeInfoB,
            flightChangeInfoC:
              state.Flight.flightChangeInfoC === ''
                ? null
                : state.Flight.flightChangeInfoC,
          },
          statusId: state.Status.statusId,
        },
        files: state.Document.getFilesRequestData(),
      }

      const apiInfoArr = [
        {
          key: 'bookingInfo',
          method: 'post',
          path: ApiPaths.AIRLINE.LIGHT_BOOKING_DETAILS,
          req,
        },
      ]
      const resObj = await CommonFunc.execApiAsync(apiInfoArr)
      const isApiSuccessful = CommonFunc.checkApiResponseObj(resObj, history)
      if (isApiSuccessful) {
        setSavedChangesOpen(true)
      }
    }

    /**
     * ページ遷移
     * @returns {void} なし
     */
    const pageTransition = () => {
      CommonFunc.clickEvent({ path: Paths.AIRLINE.LIGHT_BOOKING_LIST }, history)
    }

    /** @type {Object} ダイアログのボタン設定 */
    const dialogFuncObj = {
      [Common.DIALOG.BUTTON_CATEGORY.CANCEL]: { onClick: closeFunc },
      [Common.DIALOG.BUTTON_CATEGORY.OK]: { onClick: save },
    }

    /** @type {Object} SavedChangesダイアログのボタン設定 */
    const savedDialogFuncObj = {
      [Common.DIALOG.BUTTON_CATEGORY.OK]: { onClick: pageTransition },
    }

    // Are you sure?ポップアップに追加で表示する文章
    const additionalElements = isLessThanMinRate ? (
      <div className={classes.text}>{t('4th0024')}</div>
    ) : null

    return (
      <>
        <SavedChangesDialog
          open={savedChangesOpen}
          closeFunc={pageTransition}
          dialogFuncObj={savedDialogFuncObj}
        />
        <Grid item>
          <PrimaryButton
            onClick={openFunc}
            disabled={disabled}
            addclass={classes.saveButton}
          >
            {saveButtonText}
          </PrimaryButton>
          <AreYouSureDialog
            open={open}
            dialogFuncObj={dialogFuncObj}
            closeFunc={closeFunc}
            additionalElements={additionalElements}
          />
        </Grid>
      </>
    )
  }, [
    open,
    history,
    saveButtonText,
    savedChangesOpen,
    isLessThanMinRate,
    state.ShippingDetails,
    state.Flight,
    state.CancelBooking,
    classes.saveButton,
    classes.text,
    state.Document,
    state.Rate.rateObj.rate,
    state.ShcObj,
    state.Status.statusId,
    disabled,
    t,
  ])
}

export default SaveButtonLightBookingDetails
