import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import TextField from '../../../common/text/TextField'
import AppContext from '../../../../contexts/AppContext'
import CommonValidation from '../../../common/function/CommonValidation'
import {
  UPDATE_SHIP_POSITION,
  UPDATE_SHIP_DEPARTMENT,
  UPDATE_SHIP_POSITION_INPUTERR,
  UPDATE_SHIP_DEPARTMENT_INPUTERR,
  UPDATE_SHIP_MAIL,
  UPDATE_SHIP_MAIL_INPUTERR,
} from '../../../../actions'

const useStyles = makeStyles((theme) => ({
  head: theme.textBoldBLU,
}))

const Positions = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { state, dispatch } = useContext(AppContext)
  const [inputErrs, setInputErrs] = useState({
    position: false,
    department: false,
    email: false,
  })
  const [helperTxts, setHelperTxts] = useState({
    position: '',
    department: '',
    email: '',
  })

  const handleChangeDepartment = (event) => {
    const value = event.target.value
    // 入力チェック
    const validationCheck = CommonValidation.department(value)
    const err = validationCheck.checkResult
    setInputErrs({ ...inputErrs, department: err })
    setHelperTxts({ ...helperTxts, department: validationCheck.errMsg })

    dispatch({
      type: UPDATE_SHIP_DEPARTMENT,
      department: value,
    })

    // 入力エラーの場合、Savaボタン無効
    dispatch({
      type: UPDATE_SHIP_DEPARTMENT_INPUTERR,
      inputErr: err,
    })
  }

  const handleChangePosition = (event) => {
    const value = event.target.value
    // 入力チェック
    const validationCheck = CommonValidation.position(value)
    const err = validationCheck.checkResult
    setInputErrs({ ...inputErrs, position: err })
    setHelperTxts({ ...helperTxts, position: validationCheck.errMsg })

    dispatch({
      type: UPDATE_SHIP_POSITION,
      position: value,
    })

    // 入力エラーの場合、Savaボタン無効
    dispatch({
      type: UPDATE_SHIP_POSITION_INPUTERR,
      inputErr: err,
    })
  }

  const handleChangeMail = (event) => {
    const value = event.target.value
    // 入力チェック
    const validationCheck = CommonValidation.mail(value)
    const err = validationCheck.checkResult
    setInputErrs({ ...inputErrs, email: err })
    setHelperTxts({ ...helperTxts, email: validationCheck.errMsg })

    dispatch({
      type: UPDATE_SHIP_MAIL,
      email: value,
    })

    // 入力エラーの場合、Savaボタン無効
    dispatch({
      type: UPDATE_SHIP_MAIL_INPUTERR,
      inputErr: err,
    })
  }

  return (
    <div className={classes.formControl}>
      <Grid container>
        <Grid item md={4} xs={10}>
          <div className={classes.head}>{t('D301V0023')}</div>
          <TextField
            id="position"
            error={inputErrs.position}
            helperText={t(helperTxts.position)}
            value={state.myprofile.position.value}
            onChange={handleChangePosition}
            autoComplete="off"
          />
        </Grid>
        <Grid item md={1} />
        <Grid item md={4} xs={10}>
          <div className={classes.head}>{t('D301V0024')}</div>
          <TextField
            id="department"
            error={inputErrs.department}
            helperText={t(helperTxts.department)}
            value={state.myprofile.department.value}
            onChange={handleChangeDepartment}
            autoComplete="off"
          />
        </Grid>
        <Grid item style={{ marginBottom: '3rem' }} md={10} xs={10} />
        <Grid item md={4} xs={10}>
          <div className={classes.head}>{t('D301V0010')}</div>
          <TextField
            id="email"
            error={inputErrs.email}
            helperText={t(helperTxts.email)}
            value={state.myprofile.email.value}
            onChange={handleChangeMail}
            autoComplete="off"
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default Positions
