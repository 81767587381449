import React from 'react'
import { useTranslation } from 'react-i18next'

import LayoutLog from '../../../common/layout/LayoutLog'
import ReissuePassTitle from '../../../common/title/ReissuePassTitle'
import Text from './Text'

const ReissuePassword = () => {
  const { t } = useTranslation()

  return (
    <>
      <LayoutLog>
        <ReissuePassTitle>{t('5th0001')}</ReissuePassTitle>
        <Text />
      </LayoutLog>
    </>
  )
}

export default ReissuePassword
