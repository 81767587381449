/* eslint-disable no-extra-parens */
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Box } from '@material-ui/core'

import PlusButtonBig from '../buttons/PlusButtonBig'
import IconSize from './IconSize'

const useStyles = makeStyles((theme) => ({
  iconBox: {
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid',
    borderColor: theme.palette.border.blue,
    backgroundColor: theme.palette.info.main,
    width: 280,
    height: 120,
  },
  iconBoxForRegister: {
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid',
    borderColor: theme.palette.border.blue,
    backgroundColor: theme.palette.info.main,
    height: 120,
  },
}))

/**
 * @param {object} props - 以下
 * @param {string} iconPath - アイコン画像パス
 * @param {boolean} btnflg - アイコン画像なし時、[+]ボタン配置するかどうか(false:しない true:する)
 * @param {event} onAddIcon - [+]ボタン押下時の動作
 * @returns {JSX.Element} - [+]ボタンコンポーネント
 */
function IconBox(props) {
  const classes = useStyles()

  return (
    <div className={classes.formControl}>
      <Grid container>
        {/* register画面のみGridとCSS変更 */}
        <Grid item md={props.register ? 12 : 10} xs={props.register ? 12 : 10}>
          <Box
            display="flex"
            className={
              props.register ? classes.iconBoxForRegister : classes.iconBox
            }
          >
            {props.iconPath === '' || props.iconPath === null ? (
              <>
                {props.btnflg === true ? (
                  <PlusButtonBig handleChange={props.onAddIcon} />
                ) : (
                  props.children
                )}
              </>
            ) : (
              <IconSize id={props.id} iconPath={props.iconPath} />
            )}
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}

export default IconBox
