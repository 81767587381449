import {
  UPDATE_SHIP_COMPANY_NAME,
  UPDATE_SHIP_POSTAL_CODE,
  UPDATE_SHIP_COMPANY_ADDRESS,
  UPDATE_SHIP_AIRPORT,
  UPDATE_SHIP_BRANCH,
  UPDATE_SHIP_SHIPCON_ID,
  UPDATE_SHIP_FIRST_NAME,
  UPDATE_SHIP_LAST_NAME,
  UPDATE_SHIP_POSITION,
  UPDATE_SHIP_MAIL,
  UPDATE_SHIP_TEL,
  UPDATE_SHIP_ICON_PATH,
  UPDATE_SHIP_UPDATE_AT,
  UPDATE_SHIP_IS_DELETE_ICON,
  UPDATE_SHIP_COMPANY_NAME_INPUTERR,
  UPDATE_SHIP_POSTAL_CODE_INPUTERR,
  UPDATE_SHIP_COMPANY_ADDRESS_INPUTERR,
  UPDATE_SHIP_AIRPORT_INPUTERR,
  UPDATE_SHIP_BRANCH_INPUTERR,
  UPDATE_SHIP_SHIPCON_ID_INPUTERR,
  UPDATE_SHIP_FIRST_NAME_INPUTERR,
  UPDATE_SHIP_LAST_NAME_INPUTERR,
  UPDATE_SHIP_POSITION_INPUTERR,
  UPDATE_SHIP_MAIL_INPUTERR,
  UPDATE_SHIP_TEL_INPUTERR,
  UPDATE_SHIP_ICON_PATH_INPUTERR,
} from '../../../actions'

const Events = (state = {}, action) => {
  switch (action.type) {
    //------------------
    // companyNameを更新
    //------------------
    case UPDATE_SHIP_COMPANY_NAME:
      return {
        ...state,
        companyName: { ...state.companyName, value: action.companyName },
      }
    case UPDATE_SHIP_COMPANY_NAME_INPUTERR:
      return {
        ...state,
        companyName: { ...state.companyName, inputErr: action.inputErr },
      }
    //------------------
    // shipConIdを更新
    //------------------
    case UPDATE_SHIP_SHIPCON_ID:
      return {
        ...state,
        shipConId: { ...state.shipConId, value: action.shipConId },
      }
    case UPDATE_SHIP_SHIPCON_ID_INPUTERR:
      return {
        ...state,
        shipConId: { ...state.shipConId, inputErr: action.inputErr },
      }
    //------------------
    // postalCodeを更新
    //------------------
    case UPDATE_SHIP_POSTAL_CODE:
      return {
        ...state,
        postalCode: { ...state.postalCode, value: action.postalCode },
      }
    case UPDATE_SHIP_POSTAL_CODE_INPUTERR:
      return {
        ...state,
        postalCode: { ...state.postalCode, inputErr: action.inputErr },
      }
    //------------------
    // addressを更新
    //------------------
    case UPDATE_SHIP_COMPANY_ADDRESS:
      return { ...state, address: { ...state.address, value: action.address } }
    case UPDATE_SHIP_COMPANY_ADDRESS_INPUTERR:
      return {
        ...state,
        address: { ...state.address, inputErr: action.inputErr },
      }
    //------------------
    // airportを更新
    //------------------
    case UPDATE_SHIP_AIRPORT:
      return {
        ...state,
        airport: {
          ...state.airport,
          value: { code: action.airportId, name: action.airport },
        },
      }
    case UPDATE_SHIP_AIRPORT_INPUTERR:
      return {
        ...state,
        airport: { ...state.airport, inputErr: action.inputErr },
      }
    //------------------
    // cityNameを更新
    //------------------
    case UPDATE_SHIP_BRANCH:
      return {
        ...state,
        cityName: {
          ...state.cityName,
          value: { code: action.cityId, name: action.cityName },
        },
      }
    case UPDATE_SHIP_BRANCH_INPUTERR:
      return {
        ...state,
        cityName: { ...state.cityName, inputErr: action.inputErr },
      }
    //------------------
    // firstNameを更新
    //------------------
    case UPDATE_SHIP_FIRST_NAME:
      return {
        ...state,
        firstName: { ...state.firstName, value: action.firstName },
      }
    case UPDATE_SHIP_FIRST_NAME_INPUTERR:
      return {
        ...state,
        firstName: { ...state.firstName, inputErr: action.inputErr },
      }
    //------------------
    // lastNameを更新
    //------------------
    case UPDATE_SHIP_LAST_NAME:
      return {
        ...state,
        lastName: { ...state.lastName, value: action.lastName },
      }
    case UPDATE_SHIP_LAST_NAME_INPUTERR:
      return {
        ...state,
        lastName: { ...state.lastName, inputErr: action.inputErr },
      }
    //------------------
    // positionを更新
    //------------------
    case UPDATE_SHIP_POSITION:
      return {
        ...state,
        position: { ...state.position, value: action.position },
      }
    case UPDATE_SHIP_POSITION_INPUTERR:
      return {
        ...state,
        position: { ...state.position, inputErr: action.inputErr },
      }
    //------------------
    // mailを更新
    //------------------
    case UPDATE_SHIP_MAIL:
      return { ...state, mail: { ...state.mail, value: action.mail } }
    case UPDATE_SHIP_MAIL_INPUTERR:
      return { ...state, mail: { ...state.mail, inputErr: action.inputErr } }
    //------------------
    // telを更新
    //------------------
    case UPDATE_SHIP_TEL:
      return { ...state, tel: { ...state.tel, value: action.tel } }
    case UPDATE_SHIP_TEL_INPUTERR:
      return { ...state, tel: { ...state.tel, inputErr: action.inputErr } }
    //------------------
    // iconを更新
    //------------------
    case UPDATE_SHIP_ICON_PATH:
      return { ...state, icon: { ...state.icon, value: action.icon } }
    case UPDATE_SHIP_ICON_PATH_INPUTERR:
      return { ...state, icon: { ...state.icon, inputErr: action.inputErr } }
    //------------------
    // updatedAtを更新
    //------------------
    case UPDATE_SHIP_UPDATE_AT:
      return {
        ...state,
        updatedAt: { ...state.updatedAt, value: action.updatedAt },
      }
    //------------------
    // isDeleteIconを更新
    //------------------
    case UPDATE_SHIP_IS_DELETE_ICON:
      return {
        ...state,
        isDeleteIcon: { ...state.isDeleteIcon, value: action.isDeleteIcon },
      }
    default:
      return state
  }
}

export default Events
