import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'

import EditButtonPicBidInfo from './EditButtonPicBidInfo'
import TextPicBidInfo from './TextPicBidInfo'
import ConstantsBidInfo from '../ConstantsBidInfo'

/**
 * @param {Object} props 以下
 * @param {Object} picObj PICの情報
 * @return {JSX} PICのテキスト&Editボタンコンポーネント
 */
const TextEditPicBidInfo = ({ picObj }) => {
  return (
    <Grid item container justify="flex-start" spacing={2}>
      {ConstantsBidInfo.PIC.DISPLAY_ARR.map(({ key }) => (
        <TextPicBidInfo key={key} picStr={picObj[key]} />
      ))}
      <EditButtonPicBidInfo userId={picObj.userId} />
    </Grid>
  )
}

TextEditPicBidInfo.propTypes = {
  picObj: PropTypes.object.isRequired,
}

export default TextEditPicBidInfo
