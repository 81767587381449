import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import FindInPageIcon from '@material-ui/icons/FindInPage'
import IconButton from '@material-ui/core/IconButton'

import PrimaryButton from '../../../common/buttons/PrimaryButton'
import Dialog from '../../../common/dialog/Dialog'
import IconBox from '../../../common/info/IconBox'

const useStyles = makeStyles((theme) => ({
  root: {
    width: 'fit-content',
    height: 'fit-content',
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.fontSize.regular,
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.dark,
    },
    '&:active': {
      color: theme.palette.primary.light,
    },
    borderRadius: 25,
  },
  signIcon: {
    color: theme.palette.primary.main,
    fontSize: 36,
  },
}))

/**
 * @param {Object} props 下記内容のProps
 * @param {string} path サイン画像のパス
 * @returns {JSX} サインダイアログコンポーネント
 */
const SignatureDialog = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Dialog
      buttonPC={
        <IconButton className={classes.root}>
          <FindInPageIcon className={classes.signIcon} />
        </IconButton>
      }
      buttonMobile={
        <IconButton className={classes.root}>
          <FindInPageIcon className={classes.signIcon} />
        </IconButton>
      }
      primary={<PrimaryButton>{t('D004V0062')}</PrimaryButton>}
      title={t('D301V0030')}
      noborder
    >
      <IconBox btnflg={false} iconPath={props.path}>
        {(props.path === '' || props.path === null) && t('D301V0163')}
      </IconBox>
    </Dialog>
  )
}

export default SignatureDialog
