import { combineReducers } from 'redux'

import SearchSort, { initSearchSort } from '../../common/SearchSort'
import TableLightCarAccountInfo, {
  initTableData,
} from './TableLightCarAccountInfo'
import Paging, { initPaging } from '../../common/Paging'
import GsaFlg, { initGsa } from '../../common/Gsa'
import UserPlan, { initUserPlan } from '../../common/UserPlan'

const initialState = {
  SearchSort: initSearchSort,
  Tables: initTableData,
  Paging: initPaging,
  GsaFlg: initGsa,
  UserPlan: initUserPlan,
}

export default combineReducers({
  SearchSort,
  Tables: TableLightCarAccountInfo,
  Paging,
  GsaFlg,
  UserPlan,
})

export { initialState }
