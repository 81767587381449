import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles, withStyles } from '@material-ui/core'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Badge from '@material-ui/core/Badge'
import { fade } from '@material-ui/core/styles/colorManipulator'

import {
  SEARCH_SORT_DATA,
  GET_TABLE_DATA,
  PAGING_DATA,
} from '../../../../actions'
import AppContext from '../../../../contexts/AppContext'

const CustomTabs = withStyles(() => ({
  root: {
    minHeight: 0,
  },
  indicator: {
    display: 'none',
    minHeight: 30,
    padding: 0,
  },
}))(Tabs)

const CustomTab = withStyles((theme) => ({
  root: {
    minWidth: 120,
    minHeight: 35,
    padding: '3px 12px',
    textTransform: 'none',
    borderRadius: '10px 10px 0px 0px',
    opacity: 1,
    color: theme.fontColor.main,
    backgroundColor: fade(theme.palette.back.dark, 0.25),
    border: `1px solid ${theme.palette.border.black}`,
    '&$selected': {
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.info.main,
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />)

const useStyles = makeStyles((theme) => ({
  badge: {
    '& .MuiBadge-badge': {
      fontSize: 14,
    },
    '& .MuiBadge-colorError': {
      color: theme.palette.info.main,
      backgroundColor: theme.palette.required,
    },
  },
}))

const NotifyTabs = () => {
  const { t, i18n } = useTranslation()
  const classes = useStyles()
  const { state, dispatch } = useContext(AppContext)

  const handleChange = (_event, newValue) => {
    dispatch({
      type: SEARCH_SORT_DATA,
      sort: { read: newValue },
    })
    dispatch({
      type: GET_TABLE_DATA,
      sort: state.Sort,
      i18n,
    })
    dispatch({
      type: PAGING_DATA,
      page: 1,
    })
  }

  const getUnreadCount = () => {
    const count = state.Tables.showData.reduce(
      (acc, v) =>
        acc + v.reduce((acc, v) => (v.readFlg === 0 ? acc + 1 : acc), 0),
      0
    )
    return count
  }

  return (
    <Badge
      className={classes.badge}
      badgeContent={getUnreadCount()}
      color="error"
    >
      <CustomTabs value={state.Sort.read} onChange={handleChange}>
        <CustomTab value="all" label={t('D601V0002')} />
        <CustomTab value="unread" label={t('D601V0003')} />
      </CustomTabs>
    </Badge>
  )
}

export default NotifyTabs
