import {
  TABLES,
  ADD_FWDR_TABLE,
  UPDATE_FWDR_TABLE,
  DELETE_FWDR_TABLE,
} from '../../../actions'
import CommonFunc from '../../../components/common/function/CommonFunc'
import Common from '../../../constants/Common'
import Constants from '../../../components/pages/master/LightFwdrAccountInfo/ConstantsLightFwdrAccountInfo'

/** @type {Object} 初期表示のテーブル */
const initTableData = {
  initData: [],
  showData: [[]],
  editData: [],
  apiDataArr: [],
  isNoResult: true,
}

/**
 * テーブルに表示する形式に変更
 * @param {Array} tableData - apiから受け取ったテーブルデータ
 * @return {Array} - テーブルに表示する形式のデータ
 */
const convertData = (tableData) =>
  tableData.map((data) => Object.assign({}, data))

/**
 * テーブルに表示する形式に変更
 * @param {Array} tableData - apiから受け取ったテーブルデータ
 * @return {Array} - テーブルに表示する形式のデータ
 */
const convertTableData = (tableData) =>
  tableData.map((data) =>
    Object.assign(
      { searchAuthority: data.authorityFlg ? 'authority' : '' },
      data
    )
  )

/**
 * showDataの形式に変更
 * @param {Array} tableDataArr - apiから受け取ったテーブルデータ
 * @param {Array} showData - 表示用データ
 * @return {Array} - 表示用データ
 */
const convertShowData = (tableDataArr, showData) => {
  const flatShowData = showData.flat()
  if (
    !tableDataArr &&
    flatShowData.find((el) => el.delFlg === Common.DEL_FLG.ON)
  ) {
    return CommonFunc.splitData(
      flatShowData.filter((v) => v.delFlg !== Common.DEL_FLG.ON),
      Constants.PAGE_AMOUNT
    )
  } else {
    return showData
  }
}

/**
 * テーブルデータを保持
 * @param {Object} action action
 * @param {Object[]} action.tableData 登録するテーブルデータ
 * @param {String} action.search 検索ワード
 * @param {Object} state state
 * @returns {Object} テーブルデータ
 */
const setTableData = (action, state) => {
  const { tableData, search = '', sort = '', defaultSearchWord = '' } = action
  const { PAGE_AMOUNT, SORT_ITEM_ARR } = Constants

  // 検索用searchAuthorityを追加する
  const tableDataArr = tableData ? convertTableData(tableData) : tableData
  // sort名から並び替えの対象となるkeyを取得
  const { sortKey } = SORT_ITEM_ARR.find(({ sortTerm }) => sortTerm === sort)
  /** @type {Array} 表示用に編集後のデータ */
  const editedData = tableDataArr ? convertData(tableDataArr) : state.editData
  // データを検索して結果をページごとに分割する
  const { showData, initData } = CommonFunc.searchSortDefaultData(
    editedData,
    defaultSearchWord,
    search,
    sort,
    sortKey,
    PAGE_AMOUNT
  )
  //削除データがある場合のshowDataを編集
  const editedShowData = convertShowData(tableDataArr, showData)

  /** @type {Boolean} NoResult判定 */
  const isNoResult = editedShowData[0] ? editedShowData[0].length <= 0 : true

  return {
    ...state,
    showData: editedShowData,
    initData,
    editData: initData,
    apiDataArr: tableDataArr ? convertData(tableDataArr) : state.apiDataArr,
    isNoResult,
  }
}

/**
 * 表示用データの末尾に追加
 * @param {Number} id id
 * @param {Object} showData 表示用データ
 * @param {Object} row 編集データ
 * @return {Object} 追加後のテーブルデータのオブジェクト
 */
const addData = (id, showData, row) => {
  const flatShowData = showData.flat()
  return CommonFunc.splitData(
    [...flatShowData, { ...row, id }],
    Constants.PAGE_AMOUNT
  )
}

/**
 * AccountInfoTableのテーブルデータにデータを追加
 * @param {Object} action dispatchで受け取ったaction
 * @param {Object} state reducer内で保管しているstate
 * @return {Object} 追加後のテーブルデータのオブジェクト
 */
const addTable = (action, state) => {
  const { row } = action
  const { editData, showData } = state
  // 編集データに追加
  const addEditDataArr = [...editData, { ...row, id: editData.length }]
  // 表示用データの末尾に追加
  const addShowDataArr = addData(addEditDataArr.length - 1, showData, row)
  return {
    ...state,
    initData: addEditDataArr,
    editData: addEditDataArr,
    showData: addShowDataArr,
  }
}

/**
 * AccountInfoTableのテーブルデータを更新
 * @param {Object} action dispatchで受け取ったaction
 * @param {Object} state reducer内で保管しているstate
 * @return {Object} 更新後のテーブルデータのオブジェクト
 */
const updateTable = (action, state) => {
  const { row, index, editIndex, defaultChecked } = action
  const { editData, showData } = state
  // 指定されたindexの値のみ更新
  const updateEditDataArr = editData.map((editDataObj, editDataIndex) => {
    const isTargetIndex = editDataIndex === editIndex
    return isTargetIndex ? row : editDataObj
  })
  // 表示用データを更新
  const updateShowDataArr = showData.map((showDataObj) =>
    showDataObj
      .map((data) => {
        const isTargetIndex = data.id === index
        if (
          defaultChecked &&
          isTargetIndex &&
          row.authorityFlg === Common.AUTHORITY_FLG.ON
        ) {
          return row
        } else if (defaultChecked && isTargetIndex) {
          return null
        }
        return isTargetIndex ? row : data
      })
      .filter((v) => v)
  )
  return {
    ...state,
    initData: updateEditDataArr,
    editData: updateEditDataArr,
    showData: updateShowDataArr,
  }
}

/**
 * AccountInfoTableのテーブルデータを削除
 * @param {Object} action dispatchで受け取ったaction
 * @param {Object} state reducer内で保管しているstate
 * @param {Object} pageAmount ページ番号
 * @return {Object} 削除後のテーブルデータのオブジェクト
 */
const deleteTable = (action, state, pageAmount) => {
  const { index, editIndex } = action
  const { editData, showData } = state
  const deleteEditDataArr = editData
    .map((editDataObj, editDataIndex) => {
      const isTargetIndex = editDataIndex === editIndex
      if (isTargetIndex) {
        // 更新時間が無い場合は追加データ
        const isAddData = !editDataObj.updatedAt
        // 追加データを削除する場合は空に、それ以外はdelFlgをONに変更
        return isAddData ? null : { ...editDataObj, delFlg: Common.DEL_FLG.ON }
      } else {
        return editDataObj
      }
    })
    .filter((v) => v)
  const ShowDataArr = showData
    .map((showDataObj) =>
      showDataObj
        .map((data) => {
          const isTargetIndex = data.id === index
          if (isTargetIndex) {
            // 削除する場合は空
            return null
          } else {
            return data
          }
        })
        .filter((v) => v)
    )
    .flat()
  const deleteShowDataArr = CommonFunc.splitData(ShowDataArr, pageAmount)
  // isNoResultを判定
  const isNoResultDelete = deleteShowDataArr[0]
    ? deleteShowDataArr[0].length <= 0
    : deleteShowDataArr.length <= 0
  return {
    ...state,
    initData: deleteEditDataArr,
    editData: deleteEditDataArr,
    showData: deleteShowDataArr,
    isNoResult: isNoResultDelete,
  }
}

/**
 * テーブルデータを保持
 * @param { Object } [state = initTableData] テーブルデータ
 * @param { Object } action action
 * @return { Object } テーブルデータ
 */
const Tables = (state = initTableData, action) => {
  switch (action.type) {
    case TABLES.SET:
      return setTableData(action, state)
    case ADD_FWDR_TABLE:
      return addTable(action, state)
    case UPDATE_FWDR_TABLE:
      return updateTable(action, state)
    case DELETE_FWDR_TABLE:
      return deleteTable(action, state, Constants.PAGE_AMOUNT)
    default:
      return state
  }
}

export default Tables

export { initTableData }
