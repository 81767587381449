import React, { useContext } from 'react'
import AppContext from '../../../../../contexts/AppContext'

import CommonTableContainer from '../../../../common/table/CommonTableContainer'
import HeaderLightFwdrBookingList from './HeaderLightJhClientList'
import TableContentsLightJhClientList from './TableContentsLightJhClientList'

/**
 * @return {JSX} 表コンポーネント(全体)
 */
const TableLightJhClientList = () => {
  const { state } = useContext(AppContext)

  return (
    <CommonTableContainer isNoResult={state.Tables.isNoResult}>
      <HeaderLightFwdrBookingList />
      <TableContentsLightJhClientList />
    </CommonTableContainer>
  )
}

export default TableLightJhClientList
