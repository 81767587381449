import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

import AppContext from '../../../../../../contexts/AppContext'
import { LIGHT_FLIGHT_MGMT } from '../../../../../../actions'
import IconBox from '../../../../../common/info/IconBox'
import CommonValidation from '../../../../../common/function/CommonValidation'
import { Grid } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  errorText: theme.textNormalRED,
}))

/**
 * @returns {JSX} アイコン表示欄
 */
const IconBoxIconPopup = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { dispatch, state } = useContext(AppContext)
  const [errorText, setErrorText] = useState('')

  /**
   * アイコン選択時の処理
   * @param {*} event イベント
   * @returns {void} なし
   */
  const onSelectIcon = (event) => {
    const files = event.target.files

    if (files.length > 0) {
      const file = files[0]
      // バリデーションチェック
      const { checkResult, errMsg } = CommonValidation.imgSize(file)
      // エラーテキストを設定
      setErrorText(errMsg)
      // ファイル読み込み
      if (!checkResult) {
        const reader = new FileReader()
        reader.onload = (event) => {
          dispatch({
            type: LIGHT_FLIGHT_MGMT.ICON_POPUP.EDIT,
            iconPath: event.target.result,
            file,
          })
        }
        reader.readAsDataURL(file)
      }
    }
  }

  return (
    <Grid container alignItems="center" direction="column">
      <Grid item md={12}>
        <IconBox
          iconPath={state.IconPopup.editData.iconPath}
          btnflg={state.Auth}
          onAddIcon={onSelectIcon}
        />
      </Grid>
      <Grid item md={12} className={classes.errorText}>
        {t(errorText)}
      </Grid>
    </Grid>
  )
}
export default IconBoxIconPopup
