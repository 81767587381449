import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

import AppContext from '../../../../../contexts/AppContext'
import CommonFunc from '../../../../common/function/CommonFunc'
import ApiPaths from '../../../../../constants/ApiPaths'
import { API_DATA, SHIPPER_BID_DETAILS, TABLES } from '../../../../../actions'
import ConstantsBidDetails from '../ConstantsBidDetails'
import Common from '../../../../../constants/Common'
import SaveDialog from '../../../../common/dialog/SaveDialog'
import SaveCompleteDialog from '../../../../common/dialog/SaveCompleteDialog'
import Paths from '../../../../../constants/Paths'

/**
 * @param {*} props 下記内容
 * @param {Object} data BidInfo画面から受け取ったデータ
 * @param {Boolean} disabled 非活性判定
 * @return {JSX} SaveAsDraftボタンコンポーネント
 */
const SaveAsDraftButtonBidDetails = (props) => {
  const history = useHistory()
  const { t } = useTranslation()
  const { state, dispatch } = useContext(AppContext)
  const { disabled } = props
  const [errCode, setErrCode] = useState(0)
  const [isErr, setIsErr] = useState(false)
  const [comp, setComp] = useState(false)

  /**
   * bidDetailのリクエストデータを作成する
   * @returns {Object} リクエストデータ
   */
  const createBidDetailArr = () =>
    state.Tables.editData.map((data) => {
      const {
        bidDetailsId,
        orgId,
        dstId,
        flightTypeId,
        commId,
        termsId,
        leadTimeId,
        ttlMinWgt,
        ttlMaxWgt,
        ttlMinVol,
        ttlMaxVol,
        currencyId,
        remark,
        delFlg,
        updatedAt,
        laneInfoArr,
      } = data
      let reqObj = {}
      if (delFlg === Common.DEL_FLG.ON) {
        // 削除時は異常値が含まれる可能性があるため削除に必要なデータのみ渡す
        reqObj = {
          bidDetailsId,
          delFlg,
          updatedAt,
          laneInfoArr: laneInfoArr ?? [],
          bidStatusId: null,
        }
      } else {
        reqObj = {
          bidDetailsId,
          orgId,
          dstId,
          flightTypeId,
          commId,
          termsId,
          leadTimeId,
          ttlMinWgt,
          ttlMaxWgt,
          ttlMinVol,
          ttlMaxVol,
          currencyId,
          remark,
          delFlg,
          updatedAt,
          laneInfoArr: laneInfoArr ?? [],
          bidStatusId: null,
        }
      }
      return reqObj
    })

  /**
   * bidInfoのリクエストデータを作成する
   * @returns {Object} リクエストデータ
   */
  const createBidInfoObj = () => {
    return {
      ...state.Data.bidInfoObj,
      shipperDraftFlg: Common.DEL_FLG.ON,
      setFwdrFlg: state.Data.selectFwdrObj.setFwdrFlg,
    }
  }

  /**
   * selectForwarderのリクエストデータを作成する
   * @returns {Object} リクエストデータ
   */
  const createSelectFwdrObj = () => {
    const selectFwdrArr = state.Data.selectFwdrObj.selectFwdrArr
    const setFwdrFlg = state.Data.selectFwdrObj.setFwdrFlg
    return { selectFwdrArr, setFwdrFlg }
  }

  /**
   * stateを登録後の情報に更新する
   * @param {Object} res レスポンス
   * @returns {void} なし
   */
  const setData = (res) => {
    const { bidDetailArr, bidInfoObj, selectFwdrObj } = res.data.results

    /** bidInfoのupdatedAtObj */
    const bidInfoUpdatedAtObj = CommonFunc.createUpdatedAtObj(
      't_bid_info',
      'bidId',
      bidInfoObj.bidId,
      bidInfoObj.updatedAt
    )
    /** bidDetailのupdatedAtObjの配列 */
    const bidDetailUpdatedAtArr = bidDetailArr.map(
      ({ bidDetailsId, updatedAt }) =>
        CommonFunc.createUpdatedAtObj(
          't_bid_details',
          'bidDetailsId',
          bidDetailsId,
          updatedAt
        )
    )
    /** bidLaneのupdatedAtObjの配列 */
    const laneBidInfoUpdatedAtArr = bidDetailArr
      .map(({ laneInfoArr }) => laneInfoArr)
      .flat()
      .map(({ bidLaneId, updatedAt }) =>
        CommonFunc.createUpdatedAtObj(
          't_lane_bid_info',
          'bidLaneId',
          bidLaneId,
          updatedAt
        )
      )
    /** bidInChargeのupdatedAtObjの配列 */
    const bidInChargeUpdatedAtArr = bidInfoObj.picArr.map(
      ({ bidInChargeId, updatedAt }) =>
        CommonFunc.createUpdatedAtObj(
          't_bid_in_charge',
          'bidInChargeId',
          bidInChargeId,
          updatedAt
        )
    )

    /** 各テーブルのUpdatedAtObjの配列 */
    const updatedAtArr = [
      bidDetailUpdatedAtArr,
      bidInfoUpdatedAtObj,
      laneBidInfoUpdatedAtArr,
      bidInChargeUpdatedAtArr,
    ].flat()

    // 前画面の情報を更新
    dispatch({
      type: SHIPPER_BID_DETAILS.DATA.SET,
      data: {
        bidInfoObj,
        selectFwdrObj,
        bidStatusObj: { ...state.Data.bidStatusObj, shipperDraftFlg: 1 },
        updatedAtArr,
      },
    })

    //APIから取得したセレクトの選択肢を再取得のため全削除する
    dispatch({
      type: API_DATA.DELETE,
    })

    // テーブル情報を更新
    dispatch({
      type: TABLES.INIT,
      data: state.Tables.editData
        .filter(({ delFlg }) => delFlg !== Common.DEL_FLG.ON)
        .map((data, index) => ({
          ...data,
          bidDetailsId: bidDetailArr[index].bidDetailsId,
          updatedAt: bidDetailArr[index].updatedAt,
          laneInfoArr: bidDetailArr[index].laneInfoArr,
        })),
      sort: ConstantsBidDetails.SORT_ITEM_ARR[3].sortTerm,
    })

    setComp(true)
  }

  /**
   * クリック時のイベント
   * @returns {void} なし
   */
  const onClick = async () => {
    const req = {
      bidDetailArr: createBidDetailArr(),
      bidInfoObj: createBidInfoObj(),
      selectFwdrObj: createSelectFwdrObj(),
    }
    const apiInfoArr = [
      {
        key: 'resData',
        method: 'post',
        path: ApiPaths.SHIPPER.SHIPPER_BID_DETAILS,
        req,
      },
    ]
    const resObj = await CommonFunc.execApiAsync(apiInfoArr)
    const isApiSuccessful = CommonFunc.checkApiResponseObj(resObj, history)

    // 使用しない共通ダイアログが表示されるので非表示に変更
    document.getElementById('error-dialog').hidden = true

    setErrCode(resObj.resData.data.info.ErrorCode)
    setIsErr(!isApiSuccessful)
    if (isApiSuccessful) {
      const { resData } = resObj
      // 画面を再表示
      setData(resData)
    }
  }

  const saveFactors = {
    errcode: errCode,
    comp,
    compevent: () => setComp(false),
    passerr: null,
    path: null,
    saveevent: onClick,
    text: t('D103V0101'),
    detailsButton: true,
    disabled,
  }

  return (
    <div>
      <SaveDialog saveFactors={saveFactors} />
      <SaveCompleteDialog
        savecomp={isErr.toString()}
        path={Paths.SHIPPER.BID_MGMT}
        errcode={errCode}
      />
    </div>
  )
}

SaveAsDraftButtonBidDetails.propTypes = {
  disabled: PropTypes.bool.isRequired,
}

export default SaveAsDraftButtonBidDetails
