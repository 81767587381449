import React, { useContext, useMemo } from 'react'
import { Chip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ClearIcon from '@material-ui/icons/Clear'
import PropTypes from 'prop-types'

import { LIGHT_BOOKING_DETAILS } from '../../../../actions'
import AppContext from '../../../../contexts/AppContext'

const useStyles = makeStyles((Theme) => ({
  chip: {
    ...Theme.textNormalBLU,
    borderColor: Theme.palette.primary.main,
    height: Theme.buttonSize.height.xsmall,
    marginRight: '8px',
    '& .MuiChip-deleteIcon': {
      color: Theme.palette.primary.main,
    },
  },
}))

/**
 * @param {Object} props 下記内容
 * @param {Boolean} props.editable 編集可能
 * @return {JSX} 登録済みのSHC全て
 */
const ShcChipsSpecialHandlingCodeLightBookingDetails = ({ editable }) => {
  const classes = useStyles()
  const { state, dispatch } = useContext(AppContext)

  return useMemo(() => {
    /**
     * SHC削除
     * @param {Number} index 削除するSHCのインデックス
     * @returns {void} なし
     */
    const onDelete = (index) => {
      dispatch({
        type: LIGHT_BOOKING_DETAILS.SHC_ARR.EDIT,
        index,
      })
    }

    return state.ShcObj.shcArr.map(({ shc }, index) => (
      <Chip
        key={index}
        label={shc}
        variant="outlined"
        onDelete={editable ? () => onDelete(index) : null}
        className={classes.chip}
        deleteIcon={<ClearIcon />}
      />
    ))
  }, [state.ShcObj.shcArr.length, dispatch, classes.chip, editable]) // eslint-disable-line
}
ShcChipsSpecialHandlingCodeLightBookingDetails.propTypes = {
  editable: PropTypes.bool.isRequired,
}
export default ShcChipsSpecialHandlingCodeLightBookingDetails
