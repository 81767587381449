import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import LayoutBox from '../../../common/layout/LayoutBox'
import Title from '../../../common/title/Title'
import NotifyTable from './NotifyTable'
import Buttons from './Buttons'

const useStyles = makeStyles(() => ({
  marginBottom: {
    marginBottom: '2rem',
  },
}))

const NotificationDetails = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const data = props.history.location.state.state

  return (
    <LayoutBox>
      <Title>{t('D602V0001')}</Title>
      <Grid container justify="center" className={classes.marginBottom}>
        <Grid item md={10} xs={10}>
          <NotifyTable
            id={data.id}
            updatedAt={data.updatedAt}
            sort={data.sort}
          />
        </Grid>
      </Grid>
      <Buttons sort={data.sort} />
    </LayoutBox>
  )
}

export default NotificationDetails
