import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import SearchSort from '../../../../commonUseContext/SearchSort'
import ConstantsLightAirlineBookingList from '../ConstantsLightAirlineBookingList'
import AppContext from '../../../../../contexts/AppContext'

/**
 * @return {JSX} サーチソートコンポーネント
 */
const SearchSortLightAirlineBookingList = () => {
  const { t } = useTranslation()
  const { state } = useContext(AppContext)
  const { GSA_SORT_ITEM_ARR, AIRLINE_SORT_ITEM_ARR } =
    ConstantsLightAirlineBookingList
  // 並び替え条件の配列
  const sortItemArr = state.Gsa ? GSA_SORT_ITEM_ARR : AIRLINE_SORT_ITEM_ARR
  return <SearchSort placeholder={t('D203V0002')} sortItemArr={sortItemArr} />
}

export default SearchSortLightAirlineBookingList
