import React from 'react'
import Grid from '@material-ui/core/Grid'

import CellLightDeleteAccountDone from './CellLightDeleteAccountDone'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  box: {
    ...theme.textNormalBLK,
    backgroundColor: theme.palette.info.main,
    padding: '1rem',
    borderRadius: '100%',
    maxWidth: '660px',
  },
}))

/**
 * @return {JSX} 表コンポーネント(全体)
 */
const TableLightDeleteAccountDone = () => {
  const classes = useStyles()

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      spacing={2}
    >
      <Grid
        container
        className={classes.box}
        direction="column"
        justify="center"
        alignItems="center"
        spacing={2}
      >
        <CellLightDeleteAccountDone />
      </Grid>
    </Grid>
  )
}

export default TableLightDeleteAccountDone
