import { combineReducers } from 'redux'

import companyinfo, { initCompanyInfo } from './CompanyInfo'
import backup, { initBackup } from './Backup'
import file, { initFile } from './File'
import Plan, { initPlan } from '../../authentication/common/Plan'
import TotalAccount, { initTotalAccount } from '../../common/TotalAccount'

const initialState = {
  companyinfo: initCompanyInfo,
  backup: initBackup,
  file: initFile,
  Plan: initPlan,
  TotalAccount: initTotalAccount,
}

// すべてのreducerをここでまとめる
export default combineReducers({
  companyinfo,
  backup,
  file,
  Plan,
  TotalAccount,
})

export { initialState }
