import React, { useContext } from 'react'
import AppContext from '../../../../../contexts/AppContext'

import CommonTableContainerNone from '../../../../common/table/CommonTableContainerNone'
import HeaderSelectForwarder from './HeaderSelectForwarder'
import TableContentsSelectForwarder from './TableContentsSelectForwarder'

/**
 * @return {JSX} 表コンポーネント(全体)
 */
const TableSelectForwarder = () => {
  const { state } = useContext(AppContext)
  /** @type {Boolean} 表データがない場合true */
  const isNoResult = state.Tables.showData[0].length <= 0

  return (
    <CommonTableContainerNone isNoResult={isNoResult}>
      <HeaderSelectForwarder />
      <TableContentsSelectForwarder />
    </CommonTableContainerNone>
  )
}

export default TableSelectForwarder
