import { LIGHT_BOOKING_DETAILS } from '../../../actions'
import Common from '../../../constants/Common'

/** @type {Object} CancelBookingの初期値 */
const initCancelBooking = {
  cancelFlg: Common.CANCEL_FLG.NONE,
  reason: '',
  inputError: false,
  isOther: false,
}

/**
 * リクエストデータを作成する
 * @param {Number} cancelFlg cancelFlg
 * @param {String} reason キャンセル理由
 * @returns {Object} リクエストデータ
 */
const createRequestData = (cancelFlg, reason) => {
  if (cancelFlg === Common.CANCEL_FLG.NONE) {
    return {}
  } else {
    return { cancelFlg, reason }
  }
}

/**
 * CancelBookingを編集
 * @param { Object } action action
 * @param { Object } state state
 * @return { Object } state
 */
const edit = (action, state) => {
  const { reason, cancelFlg = state.cancelFlg } = action
  const isOther = cancelFlg === Common.CANCEL_FLG.OTHER
  const inputError = (reason === null || reason === '') && isOther
  const getRequestData = () => createRequestData(cancelFlg, reason)
  return { ...state, ...action, isOther, inputError, getRequestData }
}

/**
 * CancelBookingの編集データを保持
 * @param { Object } [state = initCancelBooking] state
 * @param { Object } action action
 * @return { Object } state
 */
const CancelBooking = (state = initCancelBooking, action) => {
  const { type, ...rest } = action
  switch (type) {
    case LIGHT_BOOKING_DETAILS.CANCEL_BOOKING.SET:
    case LIGHT_BOOKING_DETAILS.CANCEL_BOOKING.EDIT:
      return edit(rest, state)
    default:
      return state
  }
}

export default CancelBooking

export { initCancelBooking }
