import React, { useContext } from 'react'

import AppContext from '../../../../contexts/AppContext'
import { LIGHT_BOOKING_DETAILS } from '../../../../actions'
import InputSelect from '../../../common/select/InputSelect'

/**
 * @returns {JSX} SHCポップアップのSHCセレクト
 */
const ShcSelectSpecialHandlingCodePopup = () => {
  const { dispatch, state } = useContext(AppContext)

  /**
   * SHC更新
   * @param {Object} shc 選択したSHC
   * @returns {void} なし
   */
  const onChange = (shc) => {
    dispatch({
      type: LIGHT_BOOKING_DETAILS.SHC_POPUP.EDIT,
      shc,
    })
  }

  return (
    <InputSelect
      options={state.Shc.shcs}
      getOptionLabel={(shc) => shc.shc}
      getOptionDisabled={(shc) =>
        state.ShcObj.shcArr.some(({ shcId }) => shcId === shc.shcId)
      }
      onChange={(_, value) => onChange(value)}
    />
  )
}
export default ShcSelectSpecialHandlingCodePopup
