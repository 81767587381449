/* eslint-disable no-extra-parens */
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const iconWidth = 280
const iconHeight = 120

const useStyles = makeStyles(() => ({
  // eslint-disable-next-line camelcase
  img_width: {
    margin: 'auto',
    width: iconWidth,
  },
  // eslint-disable-next-line camelcase
  img_height: {
    margin: 'auto',
    height: iconHeight,
  },
}))

/**
 * @param {Object} props - 以下
 * @param {String} id - 画像のIDを指定
 * @returns {JSX.Element} - IconSize
 */
function IconSize(props) {
  const classes = useStyles()
  const id = props.id ? props.id : 'icon'

  const img = () => {
    // IDから画像の要素を取得
    const targetImg = document.getElementById(id)

    // 縦横のサイズ取得
    const imgWidth = targetImg.naturalWidth
    const imgHeight = targetImg.naturalHeight

    // 縦横のサイズ比を計算する
    const sizeHeight = imgHeight / iconHeight

    const sizeWidth = imgWidth / iconWidth

    //サイズ比に合わせてクラスを与える
    if (sizeHeight > sizeWidth) {
      targetImg.className = classes.img_height
    } else {
      targetImg.className = classes.img_width
    }
  }
  return <img id={id} onLoad={() => img()} src={props.iconPath} alt="icon" />
}

export default IconSize
