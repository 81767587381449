// Display + 画面名
const DisplayShipperSignIn = {
  // 画面ID + 文言ID
  D001V0001: {
    en: 'Sign in ',
    ja: 'Sign in ',
  },
  D001V0002: {
    en: 'Email',
    ja: 'Email',
  },
  D001V0003: {
    en: 'Password',
    ja: 'Password',
  },
  D001V0004: {
    en: 'Reissue Password',
    ja: 'Reissue Password',
  },
  D001V0005: {
    en: 'Would you like to get new account?',
    ja: 'Would you like to get new account?',
  },
  D001V0006: {
    en: 'Sign up',
    ja: 'Sign up',
  },
  D001V0007: {
    en: 'Email Required',
    ja: 'Email Required',
  },
  D001V0008: {
    en: 'Email must be less than 20 characters',
    ja: 'Email must be less than 20 characters',
  },
  D001V0009: {
    en: 'You must specify a password',
    ja: 'You must specify a password',
  },
  D001V0010: {
    en: 'Password must have at least 8 characters',
    ja: 'Password must have at least 8 characters',
  },
  D001V0011: {
    en: 'Incorrect entry.',
    ja: 'Incorrect entry.',
  },
  D001V0012: {
    en: 'Industry Segment',
    ja: 'Industry Segment',
  },
  D001V0013: {
    en: 'Airline',
    ja: 'Airline',
  },
  D001V0014: {
    en: 'Forwarder',
    ja: 'Forwarder',
  },
  D001V0015: {
    en: 'Shipper/Consignee',
    ja: 'Shipper/Consignee',
  },
  D001V0016: {
    en: 'Select',
    ja: 'Select',
  },
}
// Display + 画面名
export default DisplayShipperSignIn
