import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'

import AirportSelect from './AirportSelect'
import TransitTimeSelect from './TransitTimeSelect'
import Line from './Line'
import FlightTime from './FlightTime'
import FlightNumber from './FlightNumber'
import RegNumber from './RegNumber'
import Add from './Add'
import FlightSchedule from '../FlightSchedule/FlightSchedule'
import AppContext from '../../../../../../contexts/AppContext'
import ConstantsCarFlightMgmt from '../../ConstantsCarFlightMgmt'

const useStyles = makeStyles((theme) => ({
  titleText: theme.textBoldBLU,
}))

/**
 * @param {*} props 下記内容のProps
 * @param {Number} props.index Routingのインデックス
 * @returns {JSX} Routing欄1つ分
 */
const Routing = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { state, dispatch } = useContext(AppContext)
  const { index } = props
  /** @type {Number} Routingの配列の長さ */
  const routingLength = state.Popup.getFlightInfoParam('routingArr').length

  return useMemo(() => {
    /** @type {String} Routingの見出しテキスト */
    const routingTitle = `${t('D301V0222')}${index + ConstantsCarFlightMgmt.ROUTING.CURRENT}`
    /** @type {Boolean} Routingが最大まで追加されているか判定 */
    const isMaxLength =
      routingLength === ConstantsCarFlightMgmt.MAX_ROUTING_COUNT
    /** @type {Boolean} このRoutingが配列の最後か判定 */
    const isLastRouting = routingLength - 1 === index
    /** @type {Boolean} Addボタンの表示判定 */
    const isShowAddButton = !isMaxLength && isLastRouting && state.Popup.add

    return (
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Line />
        </Grid>
        <Grid item className={classes.titleText}>
          {routingTitle}
        </Grid>
        <Grid item>
          <AirportSelect index={index} org={true} />
        </Grid>
        <Grid item>
          <AirportSelect index={index} org={false} />
        </Grid>
        <Grid item>
          <TransitTimeSelect index={index} />
        </Grid>
        <Grid item>
          <FlightTime index={index} />
        </Grid>
        <Grid item>
          <FlightNumber index={index} />
        </Grid>
        <Grid item>
          <RegNumber index={index} />
        </Grid>
        <Grid item>
          <FlightSchedule routingIndex={index} />
        </Grid>
        <Grid item>{isShowAddButton ? <Add index={index} /> : null}</Grid>
      </Grid>
    )
  }, [routingLength, classes, dispatch, index, state.Popup.add, t])
}
Routing.propTypes = {
  index: PropTypes.number.isRequired,
}
export default Routing
