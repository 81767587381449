import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'

import CheckBox from '../../common/checkBox/CheckBox'
import {
  UPDATE_FWDR_MAIL_SETTING,
  UPDATE_CAR_MAIL_SETTING,
} from '../../../actions'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '1rem 0',
  },
  head: theme.textBoldBLU,
}))

/**
 * コンポーネントを更新するか判定する
 * @param {Object} prev 前回の値
 * @param {Object} next 最新の値
 * @returns {Boolean} falseの場合更新する
 */
const areEqual = (prev, next) => {
  // 各入力欄の値を比較
  // 全て一致していれば更新無し
  return prev.mailSetting.value === next.mailSetting.value
}

const MailSetting = React.memo(({ dispatch, mailSetting, carrier }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleChange = (event) => {
    dispatch({
      type: carrier ? UPDATE_CAR_MAIL_SETTING : UPDATE_FWDR_MAIL_SETTING,
      mailSetting: event.target.checked,
    })
  }

  return (
    <div className={classes.formControl}>
      <Grid container>
        <Grid item md={10} xs={10}>
          <div className={classes.head}>{t('D301V0031')}</div>
        </Grid>
      </Grid>
      <Grid container className={classes.root}>
        <Grid item>
          <CheckBox
            label={t('D301V0032')}
            checked={Boolean(mailSetting.value)}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </div>
  )
}, areEqual)

MailSetting.displayName = 'MailSetting'

MailSetting.propTypes = {
  dispatch: PropTypes.func.isRequired,
  mailSetting: PropTypes.object.isRequired,
  carrier: PropTypes.bool,
}

export default MailSetting
