/* eslint-disable no-extra-parens */
/* eslint-disable prefer-template */
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import MDialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'

import PrimaryButton from '../buttons/PrimaryButton'
import CommonFunc from '../function/CommonFunc'
import Common from '../../../constants/Common'

const useStyles = makeStyles((theme) => ({
  root: theme.dialog,
  dialog: theme.dialogContent,
  transparent: {
    background: 'transparent',
    border: '0px solid',
  },
  icon: {
    fontSize: 24,
    color: theme.palette.required,
  },
  text: theme.saveText,
  mobile: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  desktop: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  margin: {
    margin: 10,
  },
  marginTop: {
    marginTop: '2rem',
  },
}))

/**
 * @param {Object} props - 以下
 * @param {boolean} sendcomp - ダイアログ表示/非表示
 * @param {boolean} path - 押下後の遷移先
 * @param {Event} clickevent - 押下後のイベント
 * @param {int} errcode - エラーコード
 * @returns {JSX.Element} - 送信完了確認用Dialog
 */
const RegisterSendCompleteDialog = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [scroll, setScroll] = useState('body')
  const [message, setMessage] = useState('')
  const [message2, setMessage2] = useState('')

  const { sendcomp, errcode, ...rest } = props
  const overflow = CommonFunc.getStorage(Common.KEY.OVERFLOW_SETTING)

  let toggleDialog = () => {}

  toggleDialog = () => {
    if (sendcomp === 'true') {
      setOpen(true)
      setScroll('body')
      if (errcode === 0) {
        setMessage(t('D501V0141'))
        setMessage2(t('D501V0142'))
      } else {
        setMessage(CommonFunc.getErrorMessage(errcode))
      }
    } else {
      setOpen(false)
    }
  }

  useEffect(() => {
    toggleDialog()
    return () => (document.body.style.overflow = overflow)
  }, [sendcomp, errcode, overflow])

  return (
    <>
      {/* ダイアログ Send完了 */}
      <MDialog
        open={open}
        scroll={scroll}
        className={classes.root + ' ' + props.className}
      >
        {/* ダイアログ本文 */}
        <DialogContent>
          <div className={classes.dialog + ' ' + classes.transparent}>
            {/* タイトル */}
            {errcode === 0 ? (
              ''
            ) : (
              <Grid container item justify="center" alignItems="center">
                <WarningRoundedIcon className={classes.icon} />
              </Grid>
            )}
            {/* 本文 */}
            <Grid
              container
              justify="center"
              alignContent="center"
              spacing={1}
              className={classes.marginTop}
            >
              <Grid item xs={12}>
                {message && message2 ? (
                  <>
                    <div className={classes.text}>{t(message)}</div>
                    <div className={classes.text}>{t(message2)}</div>
                  </>
                ) : (
                  ''
                )}
              </Grid>
            </Grid>
          </div>
        </DialogContent>

        {/* ダイアログ下部のボタン */}
        <DialogActions>
          {/* スマホ */}
          <Grid
            container
            direction="column"
            alignContent="center"
            className={classes.mobile + ' ' + classes.margin}
            spacing={3}
          >
            <Grid item md={12} xs={12}>
              <div className={classes.center}>
                {errcode === 0 ? (
                  <PrimaryButton {...rest}>{t('D004V0062')}</PrimaryButton>
                ) : (
                  <PrimaryButton clickevent={props.clickevent}>
                    {t('D004V0062')}
                  </PrimaryButton>
                )}
              </div>
            </Grid>
          </Grid>
          {/* PC */}
          <Grid
            container
            justify="center"
            spacing={3}
            className={classes.desktop + ' ' + classes.margin}
          >
            <Grid item>
              <div className={classes.center}>
                {errcode === 0 ? (
                  <PrimaryButton {...rest}>{t('D004V0062')}</PrimaryButton>
                ) : (
                  <PrimaryButton clickevent={props.clickevent}>
                    {t('D004V0062')}
                  </PrimaryButton>
                )}
              </div>
            </Grid>
          </Grid>
        </DialogActions>
      </MDialog>
    </>
  )
}

export default RegisterSendCompleteDialog
