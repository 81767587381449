import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

/**
 * @param {object} props 下記内容
 * @param {object} props.contents 予約情報
 * @return {JSX} 日時情報
 */
const DateLightSearchResult = ({ contents }) => {
  const takeoffDate = moment(contents.takeoffDate).format('MMM. DD, yyyy')
  const etd = moment(`${contents.takeoffDate} ${contents.etd}`).format('HH:mm')
  const eta = moment(`${contents.takeoffDate} ${contents.eta}`).format('HH:mm')

  return (
    <>
      <div>{takeoffDate}</div>
      <div>{`${etd} - ${eta}`}</div>
      <div>{`N + ${contents.days.time}`}</div>
    </>
  )
}

DateLightSearchResult.propTypes = {
  contents: PropTypes.object.isRequired,
}

export default DateLightSearchResult
