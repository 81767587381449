import React from 'react'
import { Divider, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  blueDivider: {
    background: theme.palette.primary.main,
    width: '100%',
  },
}))

/**
 * @return {JSX} 青色境界線コンポーネント
 */
const BlueDivider = () => {
  const classes = useStyles()

  return <Divider className={classes.blueDivider} />
}

export default BlueDivider
