import React, { useContext } from 'react'

import AppContext from '../../../../../contexts/AppContext'
import TableRow from '../../../../common/table/TableRow'
import ConstantsConfirmation, { getTableArr } from '../ConstantsConfirmation'
import TableCellsConfirmation from './TableCellsConfirmation'
import Common from '../../../../../constants/Common'

/**
 * useContextのstateから表全体のデータを作成
 * @return {JSX} TableRow ~ TableCellまでのコンポーネント
 */
const TableContentsConfirmation = () => {
  const { state } = useContext(AppContext)
  /** state.showDataのインデックス */
  const page = state.Paging - 1
  /** 現在表示しているページのデータ配列 */
  const currentPageShowDataArr = state.Tables.showData[page]

  /**テーブル情報の配列 */
  const tableArr = getTableArr(state.IsNew)

  return currentPageShowDataArr.map((row, index) => {
    const { statusStr } = row
    /** 1ページ目からの累計インデックス */
    const fixedIndex = index + page * ConstantsConfirmation.PAGE_AMOUNT
    /** 編集後のstatusId */
    const editedStatusId = Object.entries(Common.BID_STATUS).find(
      (status) => status[1].TEXT === statusStr
    )[1].ID
    /** グレーアウトフラグ */
    const isGrayOut =
      ConstantsConfirmation.GRAY_OUT_STATUS_ID_ARR.includes(editedStatusId)
    return (
      <TableRow key={fixedIndex}>
        <TableCellsConfirmation
          index={fixedIndex}
          tableContentObj={row}
          isGrayOut={isGrayOut}
          tableArr={tableArr}
        />
      </TableRow>
    )
  })
}

export default TableContentsConfirmation
