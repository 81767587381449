import React from 'react'
import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'

import BackButtonLightBookingRequest from './BackButtonLightBookingRequest'
import SearchButtonLightBookingRequest from './SearchButtonLightBookingRequest'

const useStyles = makeStyles(() => ({
  margin: {
    marginBottom: 30,
    marginTop: 30,
  },
}))
/**
 * @return {JSX} ボタンコンポーネント
 */
const ButtonsSelectForwarder = () => {
  const classes = useStyles()
  return (
    <Grid container justify="center" className={classes.margin}>
      <Grid container item xs={10} justify="center" spacing={4}>
        <Grid item>
          <BackButtonLightBookingRequest />
        </Grid>
        <Grid item>
          <SearchButtonLightBookingRequest />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ButtonsSelectForwarder
