import React from 'react'

import Layout from './Layout'

/**
 * レイアウトを適用します。
 * @param {Object} props - 以下
 * @param {int} airlineStepbar - AirlineStepbarを表示(数値でstep指定)
 * @param {int} stepbar - Stepbarを表示(数値でstep指定)
 * @returns {JSX.Element} - LayoutLog
 */
const LayoutLog = (props) => {
  return (
    <Layout log {...props}>
      {props.children}
    </Layout>
  )
}

export default LayoutLog
