import React, { useContext, useState } from 'react'
import { Grid } from '@material-ui/core'

import Common from '../../../../../constants/Common'
import { SPECIAL_NOTES } from '../../../../../actions'
import AppContext from '../../../../../contexts/AppContext'
import DialogTextSpecialNotes from './DialogTextSpecialNotes'
import CommonDialog from '../../../../common/dialog/commonDialog/CommonDialog'

/**
 * @return {JSX} SpecialNotesダイアログコンポーネント
 */
const DialogSpecialNotes = () => {
  const { state, dispatch } = useContext(AppContext)
  const { isOpenDialog, specialNoteArr, selectIndexNum } = state.SpecialNotes

  // 選択したSpecialNotesの現在表示
  const currentText = specialNoteArr[selectIndexNum]
  // 入力中のSpecialNotes
  const initNewTextStr = ''
  const [newTextStr, setNewTextStr] = useState(initNewTextStr)
  // バリデーションチェックによるエラーテキスト
  const initErrorObj = { checkResult: false, errMsg: '' }
  const [errorObj, setErrorObj] = useState(initErrorObj)
  const { checkResult } = errorObj
  // DialogTextSpecialNotesに渡す値
  const textSetting = {
    newTextStr,
    setNewTextStr,
    errorObj,
    setErrorObj,
  }

  /**
   * stateの更新＆ダイアログを閉じる
   * @param {String} textStr SpecialNotesに更新したい文字列
   * @return {void}
   */
  const updateSpecialNotes = (textStr) => {
    setErrorObj(initErrorObj)
    dispatch({
      type: SPECIAL_NOTES.UPDATE_TEXT,
      textStr,
    })
  }

  /**
   * ダイアログを閉じる
   * SpecialNotesにはEditボタンクリック時の内容を反映
   * @return {void}
   */
  const closeDialog = () => updateSpecialNotes(currentText)

  /**
   * Deleteボタン押下
   * SpecialNotesには''を反映
   * @return {void}
   */
  const clickDelete = () => updateSpecialNotes(initNewTextStr)

  /**
   * Okボタン押下
   * SpecialNotesには入力中の内容を反映
   * @return {void}
   */
  const clickOk = () => updateSpecialNotes(newTextStr)

  // ダイアログのボタン設定
  const dialogFuncObj = {
    [Common.DIALOG.BUTTON_CATEGORY.DELETE]: { onClick: clickDelete },
    [Common.DIALOG.BUTTON_CATEGORY.CANCEL]: { onClick: closeDialog },
    // バリデーションチェックでエラーが出た場合非活性
    [Common.DIALOG.BUTTON_CATEGORY.OK]: {
      onClick: clickOk,
      disabled: checkResult,
    },
  }

  return (
    <CommonDialog
      open={isOpenDialog}
      closeFunc={closeDialog}
      dialogFuncObj={dialogFuncObj}
    >
      <Grid container justify="flex-start">
        <Grid item md={12} xs={12}>
          <DialogTextSpecialNotes {...textSetting} />
        </Grid>
      </Grid>
    </CommonDialog>
  )
}

export default DialogSpecialNotes
