/* eslint-disable prefer-template */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import 'date-fns'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'

import CommonFunc from '../function/CommonFunc'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    fontFamily: theme.typography.fontFamily,
    color: theme.fontColor.main,
    backgroundColor: 'rgba(51, 51, 51, 0.06)',
    '& .MuiInputBase-input': {
      color: theme.fontColor.main,
      paddingLeft: '1rem',
    },
    '& .MuiInputBase-root': {
      height: 48,
    },
  },
  desktop: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobile: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}))

/**
 * @param {Object} props - 以下
 * @param {String} label - labelを指定
 * @param {String} id - idを指定、idはid+'PC', id+'Mobile'として保持されます。
 * @param {event} onChange - onChangeを指定、引数に新しい日付が渡されます。
 * @param {Boolean} disablePast - trueで過去の日付を無効にします。
 * @param {Boolean} disableFuture - trueで未来の日付を無効にします。
 * @param {Date} defaultValue - 初期値
 * @param {Boolean} disabled - trueで編集を無効にします。
 * @returns {JSX.Element} - DatePicker
 */
const DatePicker = (props) => {
  const classes = useStyles()
  const CONSTANTS = {
    FORMAT: {
      JA: moment(props.defaultValue).format('yyyy/MM/DD'),
      EN: moment(props.defaultValue).format('MMM. DD, yyyy'),
      MAY_EN: moment(props.defaultValue).format('MMM DD, yyyy'),
    },
  }

  const [selectedDate, setSelectedDate] = React.useState(
    props.defaultValue ?? null
  )
  const { i18n, t } = useTranslation()
  const lang = CommonFunc.getLang(i18n)
  const [formatType, setFormatType] = useState(
    CommonFunc.getFormatType(selectedDate, lang)
  )
  const [inputValue, setInputValue] = useState(CONSTANTS.FORMAT[formatType])

  const handleDateChange = (date, value) => {
    setInputValue(
      CommonFunc.splitDateValue(value, lang, formatType, setFormatType)
    )
    setSelectedDate(date)
    props.onChange(date)
  }

  const onError = (isError) => {
    if (isError) {
      props.onChange(null)
    }
  }

  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={DateFnsUtils}>
      <KeyboardDatePicker
        className={classes.root + ' ' + classes.desktop}
        disableToolbar
        variant="inline"
        format={
          props.format ?? CommonFunc.getFormat(selectedDate, lang, formatType)
        }
        margin="none"
        id={props.id + 'PC'}
        label={props.label}
        placeholder={props.placeholder}
        value={selectedDate ?? null}
        inputValue={inputValue}
        onChange={handleDateChange}
        onError={props.onError ?? onError}
        rifmFormatter={(val) => val.replace(/[^[a-zA-Z0-9_]*$]+/gi, '')}
        refuse={/[^[a-zA-Z0-9_]*$]+/gi}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        autoComplete="off"
        disablePast={props.disablePast === true}
        disableFuture={props.disableFuture === true}
        disabled={props.disabled === true}
        invalidDateMessage={t('D004V0088')}
        minDateMessage={t('D004V0089')}
        maxDateMessage={t('D004V0090')}
        autoOk={true}
        maxDate={props.maxDate ?? new Date('2100-01-01')}
        minDate={props.minDate ?? new Date('1900-01-01')}
      />
      {/* <KeyboardDatePicker
        className={classes.root + ' ' + classes.mobile}
        margin='none'
        id={props.id + 'Mobile'}
        label={props.label}
        format={getFormat(selectedDate)}
        value={selectedDate}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        inputValue={inputValue}
        rifmFormatter={val => val.replace(/[^[a-zA-Z0-9_]*$]+/gi, '')}
        refuse={/[^[a-zA-Z0-9_]*$]+/gi}
        autoComplete='off'
        disablePast={props.disablePast === true ? true : false}
        disableFuture={props.disableFuture === true ? true : false}
        disabled={props.disabled === true}
      /> */}
    </MuiPickersUtilsProvider>
  )
}

DatePicker.propTypes = {
  defaultValue: PropTypes.object,
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onError: PropTypes.func,
  disablePast: PropTypes.bool,
  disableFuture: PropTypes.bool,
  disabled: PropTypes.bool,
  maxDate: PropTypes.string,
  minDate: PropTypes.string,
  onChange: PropTypes.func,
}

export default DatePicker
