import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundImage: `url(${'/images/vector.svg'})`,
    backgroundRepeat: 'repeat',
    // eslint-disable-next-line prefer-template
    minHeight: 'calc(100vh - ' + theme.header.height + 'px)',
  },
}))

const BackgroundBox = (props) => {
  const classes = useStyles()

  return <div className={classes.paper}>{props.children}</div>
}

export default BackgroundBox
