import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import AppContext from '../../../contexts/AppContext'
import InputSelect from '../../common/select/InputSelect'
import CommonValidation from '../../common/function/CommonValidation'
import CommonCalc from '../../../components/common/function/CommonCalc'
import Common from '../../../constants/Common'
import {
  UPDATE_FWDR_PLAN,
  UPDATE_FWDR_PLAN_INPUTERR,
  UPDATE_CAR_PLAN,
  UPDATE_CAR_PLAN_INPUTERR,
  UPDATE_SHIP_PLAN,
  UPDATE_SHIP_PLAN_INPUTERR,
} from '../../../actions'

const useStyles = makeStyles((theme) => ({
  head: theme.textBoldBLU,
  textBlk: {
    ...theme.textNormalBLK,
    marginLeft: '1rem',
    wordWrap: 'break-word',
  },
}))

/**
 * コンポーネントを更新するか判定する
 * @param {Object} prev 前回の値
 * @param {Object} next 最新の値
 * @returns {Boolean} falseの場合更新する
 */
const areEqual = (prev, next) => {
  // 各入力欄の値を比較
  // 一致していれば更新無し
  return prev === next
}

const PlanSelect = React.memo(({ fwdr, carrier }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [inputErr, setInputErr] = useState(false)
  const [helperTxt, setHelperTxt] = useState('')
  const { state, dispatch } = useContext(AppContext)
  const { fwdrPlanId, carPlanId, shipPlanId, authorityFlg } = state.companyinfo
  const authorityFlgValue = authorityFlg.value

  let plan = null
  let selectPlan = null
  if (fwdr) {
    plan =
      state.Plan.planArr.find((v) => v.fwdrPlanId === fwdrPlanId.value) ?? null
    selectPlan =
      state.Plan.planArr
        .filter((v) => v.displayFlg === Common.DISPLAY_FLG.ON)
        .find((v) => v.fwdrPlanId === fwdrPlanId.value) ?? null
  } else if (carrier) {
    plan =
      state.Plan.planArr.find((v) => v.carPlanId === carPlanId.value) ?? null
    selectPlan =
      state.Plan.planArr
        .filter((v) => v.displayFlg === Common.DISPLAY_FLG.ON)
        .find((v) => v.carPlanId === carPlanId.value) ?? null
  } else {
    plan =
      state.Plan.planArr.find((v) => v.shipPlanId === shipPlanId.value) ?? null
    selectPlan =
      state.Plan.planArr
        .filter((v) => v.displayFlg === Common.DISPLAY_FLG.ON)
        .find((v) => v.shipPlanId === shipPlanId.value) ?? null
  }

  const handleChange = (event) => {
    let value = ''
    let validationCheck = ''
    let updatePlan = ''
    let updatePlanInputerr = ''
    if (fwdr) {
      value = event ? event.fwdrPlanId : ''
      validationCheck = CommonValidation.fwdrPlan(
        value,
        event?.maxAccount,
        state.TotalAccount,
        event?.planNameId
      )
      updatePlan = UPDATE_FWDR_PLAN
      updatePlanInputerr = UPDATE_FWDR_PLAN_INPUTERR
      dispatch({
        type: updatePlan,
        fwdrPlanId: value,
      })
    } else if (carrier) {
      value = event ? event.carPlanId : ''
      validationCheck = CommonValidation.carPlan(
        value,
        event?.maxAccount,
        state.TotalAccount,
        event?.planNameId
      )
      updatePlan = UPDATE_CAR_PLAN
      updatePlanInputerr = UPDATE_CAR_PLAN_INPUTERR
      dispatch({
        type: updatePlan,
        carPlanId: value,
      })
    } else {
      value = event ? event.shipPlanId : ''
      updatePlan = UPDATE_SHIP_PLAN
      updatePlanInputerr = UPDATE_SHIP_PLAN_INPUTERR
      dispatch({
        type: updatePlan,
        shipPlanId: value,
      })
    }
    // 入力チェック
    const err = validationCheck.checkResult
    setHelperTxt(validationCheck.errMsg)
    setInputErr(err)

    // 入力エラーの場合、Savaボタン無効
    dispatch({
      type: updatePlanInputerr,
      inputErr: err,
    })
  }

  // 編集用
  const createTextField = () => {
    return (
      <Grid item md={4}>
        <InputSelect
          className={classes.margin}
          options={state.Plan.planArr.filter(
            (v) => v.displayFlg === Common.DISPLAY_FLG.ON
          )}
          getOptionLabel={(option) =>
            `${option.planName} - ${option.currency} ${CommonCalc.addComma(option.fee, true)}`
          }
          value={selectPlan}
          onChange={(_, value) => handleChange(value)}
          disabled={false}
          error={inputErr}
          helperText={t(helperTxt)}
        />
      </Grid>
    )
  }

  // 表示用
  const createTextLabel = () => {
    return (
      <Grid item md={10} xs={10}>
        <div
          className={classes.textBlk}
        >{`${plan?.planName} - ${plan?.currency} ${CommonCalc.addComma(plan?.fee, true)}`}</div>
      </Grid>
    )
  }

  return (
    <div className={classes.formControl}>
      <Grid container>
        <Grid item md={10} xs={10}>
          <div className={classes.head}>{t('5th0003')}</div>
        </Grid>
        {authorityFlgValue ? createTextField() : createTextLabel()}
      </Grid>
    </div>
  )
}, areEqual)

PlanSelect.displayName = 'PlanSelect'

PlanSelect.propTypes = {
  fwdr: PropTypes.bool,
  carrier: PropTypes.bool,
}

export default PlanSelect
