import React from 'react'
import { useTranslation } from 'react-i18next'

import Title from '../../../../common/title/Title'

/**
 * @return {JSX} タイトルコンポーネント
 */
const TitleLightSearchResult = () => {
  const { t } = useTranslation()
  return <Title>{t('D009V0001')}</Title>
}

export default TitleLightSearchResult
