import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import FindInPageIcon from '@material-ui/icons/FindInPage'
import IconButton from '@material-ui/core/IconButton'
import { useTranslation } from 'react-i18next'

import CommonFunc from '../function/CommonFunc'
import ShowPdfDialog from '../dialog/ShowPdfDialog'
import Common from '../../../constants/Common'

const useStyles = makeStyles((theme) => ({
  //ボタン設定
  root: theme.pdfButton,
  //ボタンアイコン設定
  icon: theme.pdfButtonIcon,
  //ボタンメッセージ設定
  text: theme.textNormalBLK,
}))

/**
 * @param {Object} props - 以下
 * @param {string} path 押下後の遷移先
 * @param {Event} clickevent - 押下後のイベント
 * @param {object} state - 遷移先に渡すデータ
 * @param {String} title - 書類のタイトル
 * @returns {JSX.Element} - PDFボタンコンポーネント
 */
const PdfButton = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const CONSTANTS = {
    /** PDFの固定名と対象となるパス */
    FIXED_NAME_ARR: [
      {
        TARGET_PATH: 'cargo-mfst-pdf/',
        SHOW_NAME: t('D111V0128'),
        KEYWORD: 'cargoManifest.pdf',
      },
      {
        TARGET_PATH: 'dgd-pdf/',
        SHOW_NAME: t('D111V0065'),
        KEYWORD: 'DGD.pdf',
      },
      {
        TARGET_PATH: 'hawb-pdf/',
        SHOW_NAME: t('D111V0062'),
        KEYWORD: 'HouseAirWayBill.pdf',
      },
      {
        TARGET_PATH: 'hmfst-pdf/',
        SHOW_NAME: t('D111V0063'),
        KEYWORD: 'HouseManifest.pdf',
      },
      {
        TARGET_PATH: 'invoice-pdf/',
        SHOW_NAME: t('D111V0067'),
        KEYWORD: 'invoice.pdf',
      },
      {
        TARGET_PATH: 'mawb-pdf/',
        SHOW_NAME: t('D111V0064'),
        KEYWORD: 'MasterAirWayBill.pdf',
      },
      {
        TARGET_PATH: 'notoc-pdf/',
        SHOW_NAME: t('D111V0129'),
        KEYWORD: 'Notoc.pdf',
      },
      {
        TARGET_PATH: 'packing-list-pdf/',
        SHOW_NAME: t('D111V0068'),
        KEYWORD: 'packingList.pdf',
      },
      {
        TARGET_PATH: 'quote-pdf/',
        SHOW_NAME: t('D111V0071'),
        KEYWORD: 'Quote.pdf',
      },
    ],
  }

  // Buttonタグに関数を渡せないのでスプレッド構文で分離
  const { clickevent, onClick, name, url, disabled, title } = props

  const openByNameAndURL = (isDownload) => {
    CommonFunc.openPdfFile(url, getFileName(url, name), isDownload)
  }

  /**
   * ファイル名を取得
   * @param {String} url - PDFのURL
   * @param {String} name - PDF名
   * @returns {String} - 固定名の場合は固定名を返す それ以外の場合ファイルの最後を取得
   */
  const getFileName = (url, name) => {
    const fixedNameObj = CONSTANTS.FIXED_NAME_ARR.find(
      ({ TARGET_PATH, KEYWORD }) => {
        const isTargetName = name ? name.indexOf(KEYWORD) !== -1 : false
        const isTargetUrl = url ? url.indexOf(TARGET_PATH) !== -1 : false
        // 表示名、URLに特定のワードが含まれている場合固定表示
        return isTargetName && isTargetUrl
      }
    )
    const fileNameStr = fixedNameObj?.SHOW_NAME ?? CommonFunc.getFileName(name)
    // 拡張子とファイル名に分ける
    const fileNameObj = CommonFunc.separationFileName(fileNameStr)
    // 拡張子がzipの場合は拡張子有り、pdfの場合は拡張子無しで返す
    return fileNameObj.extension === Common.UPLOAD_FILE_TYPE.PDF
      ? fileNameObj.originalName
      : fileNameStr
  }

  /**
   * Invoice、PackingListなど固定表示する書類のタイトルを返す
   * @returns {JSX.Element|null} -
   */
  const createInvoiceOrPackingListText = () => {
    if (title !== undefined) {
      return <Typography className={classes.text}>{title}</Typography>
    } else {
      return null
    }
  }

  return (
    <>
      <div className={'pdf'}></div>
      <ShowPdfDialog
        clickevent={clickevent ?? onClick ?? openByNameAndURL}
        disabled={disabled}
        isZip={
          CommonFunc.separationFileName(props.url ?? props.children)
            .extension === Common.UPLOAD_FILE_TYPE.ZIP
        }
        buttonPC={
          props.button ?? (
            <IconButton className={classes.root} disabled={disabled}>
              <div>
                <FindInPageIcon className={classes.icon} />
                {createInvoiceOrPackingListText()}
                <Typography className={classes.text}>
                  {getFileName(url, props.children)}
                </Typography>
              </div>
            </IconButton>
          )
        }
      />
    </>
  )
}

export default PdfButton
