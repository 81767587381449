import React, { useContext } from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import CheckIcon from '@material-ui/icons/Check'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'

import EditDialog from './EditDialog'
import SignatureDialog from './SignatureDialog'
import AppContext from '../../../../contexts/AppContext'
import CommonFunc from '../../../common/function/CommonFunc'
import Common from '../../../../constants/Common'

const useStyles = makeStyles((theme) => ({
  paper: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderBottom: 0,
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
  },
  '@global': {
    '.MuiTableCell-root': {
      padding: '5px 0px',
    },
  },
  table: {
    minWidth: 700,
  },
  cell: {
    color: theme.palette.letter,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    borderLeft: `1px solid ${theme.palette.primary.main}`,
    fontSize: 16,
  },
  cellHead: {
    color: theme.palette.primary.main,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    borderLeft: `1px solid ${theme.palette.primary.main}`,
    fontWeight: 'bold',
    fontSize: 16,
  },
  cellBtn: {
    color: theme.palette.primary.main,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    borderLeft: `1px solid ${theme.palette.primary.main}`,
    fontSize: 16,
    width: 120,
  },
  cellFirst: {
    color: theme.palette.letter,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    fontSize: 16,
  },
  cellFirstHead: {
    color: theme.palette.primary.main,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    fontSize: 16,
    fontWeight: 'bold',
  },
  whitebg: {
    backgroundColor: theme.palette.info.main,
  },
  button: {
    padding: '0px 0px',
  },
  signIcon: {
    color: theme.palette.primary.main,
    fontSize: 36,
  },
  outlineIcon: {
    color: theme.palette.required,
    fontSize: 18,
    justifyItems: '',
  },
  noResult: theme.noResult,
  signture: {
    '&.root': {
      position: 'relative',
      left: '50%',
      transform: 'translate(-50%, 0%)',
      width: 60,
    },
    '& .child': {
      position: 'absolute',
      top: '15%',
      left: '70%',
    },
  },
}))

/**
 * @param {Object} props 下記内容のProps
 * @param {array} rows 1ページ分のテーブルデータ
 * @returns {JSX} AccountInfoの表コンポーネント
 */
const AccountInfoTable = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { state } = useContext(AppContext)

  // ログインユーザーチェック
  const loginUserChk = (id) => {
    const user = CommonFunc.getLoginUserData()
    return id === user.userId
  }

  // rowsをスプレッド構文で分離
  const { rows } = props

  const createTableCells = () =>
    rows[state.Paging.page].map((showDataRow, index) => {
      const { dataIndexNum } = showDataRow
      const row = state.Tables.editData[dataIndexNum]
      return (
        <React.Fragment key={dataIndexNum}>
          {row.delFlg !== Common.DEL_FLG.ON ? (
            <TableRow key={index} className={classes.whitebg}>
              <TableCell className={classes.cellFirst} align="center">
                {row.name}
              </TableCell>
              <TableCell className={classes.cell} align="center">
                {row.lastName}
              </TableCell>
              <TableCell className={classes.cell} align="center">
                {row.email}
              </TableCell>
              <TableCell className={classes.cell} align="center">
                {row.tel}
              </TableCell>
              <TableCell className={classes.cell} align="center">
                <div className={clsx(classes.signture, 'root')}>
                  <div className="child">
                    {row.signPicturePath === '' ||
                    row.signPicturePath === null ? (
                      <ErrorOutlineIcon className={classes.outlineIcon} />
                    ) : (
                      ''
                    )}
                  </div>
                  <SignatureDialog path={row.signPicturePath} />
                </div>
              </TableCell>
              <TableCell className={classes.cell} align="center">
                {row.authorityFlg ? <CheckIcon /> : '-'}
              </TableCell>
              <TableCell className={classes.cell} align="center">
                <Grid container alignItems="center" justify="center">
                  {loginUserChk(row.userId) ? (
                    ''
                  ) : (
                    <EditDialog
                      row={row}
                      index={dataIndexNum}
                      accountType={Common.ACCOUNT_TYPE.JH}
                    />
                  )}
                </Grid>
              </TableCell>
            </TableRow>
          ) : (
            <></>
          )}
        </React.Fragment>
      )
    })

  return (
    <>
      {rows[0].length > 0 ? (
        <TableContainer
          component={Paper}
          elevation={0}
          className={classes.paper}
        >
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow className={classes.whitebg}>
                <TableCell className={classes.cellFirstHead} align="center">
                  {t('D301V0007')}
                </TableCell>
                <TableCell className={classes.cellHead} align="center">
                  {t('D301V0008')}
                </TableCell>
                <TableCell className={classes.cellHead} align="center">
                  {t('D301V0010')}
                </TableCell>
                <TableCell className={classes.cellHead} align="center">
                  {t('D301V0011')}
                </TableCell>
                <TableCell className={classes.cellHead} align="center">
                  {t('D301V0030')}
                </TableCell>
                <TableCell className={classes.cellHead} align="center">
                  {t('D301V0034')}
                </TableCell>
                <TableCell className={classes.cellHead} align="center">
                  {t('D301V0035')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{createTableCells()}</TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div className={classes.noResult}>No Result</div>
      )}
    </>
  )
}

export default AccountInfoTable
