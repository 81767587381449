/* eslint-disable prefer-template */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import MDialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'

import PrimaryButton from '../../common/buttons/PrimaryButton'
import SecondaryButton from '../../common/buttons/SecondaryButton'
import RegisterSendCompleteDialog from './RegisterSendCompleteDialog'
import CommonFunc from '../function/CommonFunc'

const useStyles = makeStyles((theme) => ({
  root: theme.dialog,
  dialog: theme.dialogContent,
  transparent: {
    background: 'transparent',
    border: '0px solid',
  },
  icon: {
    fontSize: 24,
    color: theme.palette.required,
  },
  text: theme.saveText,
  mobile: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  desktop: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  margin: {
    margin: 10,
  },
  marginTop: {
    marginTop: '2rem',
  },
  button: {
    marginTop: 20,
    border: 'none',
    width: theme.buttonSize.width.large,
    height: theme.buttonSize.height.medium,
  },
  errorText: {
    fontFamily: ['Helvetica Neue', 'MS PGothic'].join(','),
    fontSize: 22,
    color: '#595959',
    lineHeight: 1.2,
    textAlign: 'center',
  },
}))

/**
 * @param {Object} props - 以下
 * @param {int} errCode - エラーコード
 * @returns {JSX.Element} - アカウント登録確認用Dialog
 */
const RegisterNewAccountSendDialog = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [errorOpen, setErrorOpen] = useState(false)
  const [scroll, setScroll] = useState('body')
  const [pageX, setPageX] = useState(0)

  const { sendFactors, setErrCode, ...rest } = props

  useEffect(() => {
    if (sendFactors.errcode) {
      // windowのスクロール設定を記録してから非表示
      CommonFunc.hideScroll()
    }
    // eslint-disable-next-line no-unneeded-ternary
    setErrorOpen(sendFactors.errcode ? true : false)
  }, [sendFactors.errcode])

  const handleClickOpen = (scrollType) => () => {
    // windowのスクロール設定を記録してから非表示
    CommonFunc.hideScroll()
    setOpen(true)
    setScroll(scrollType)
  }
  const handleCloseOK = () => {
    setOpen(false)
  }

  /**
   * ダイアログを閉じる
   * @param {Event} event - onCloseのイベント
   * @param {Boolean} isDialogBackGround - ダイアログ背景をクリックしているかの判定
   * @returns {void}
   */
  const handleCloseCancel = (event, isDialogBackGround) => {
    /** 現在クリック位置が要素外(スクロール部分)をクリックしているか判定 */
    const isScrollClick = isDialogBackGround
      ? event.target.clientWidth - pageX < 0
      : false
    if (isScrollClick) {
      // スクロール部分をクリックしている場合は何もしない
      return
    } else {
      // windowのスクロール設定を戻す
      // CommonFunc.showScroll()
      CommonFunc.showScroll()
      // ダイアログを閉じる
      setOpen(false)
    }
  }

  const handleCloseError = () => {
    // windowのスクロール設定を戻す
    CommonFunc.showScroll()
    setErrCode('')
    setErrorOpen(false)
  }

  /**
   * クリックした瞬間の位置を保存
   * @param {Event} event - event onMousedownのイベント
   * @returns {void}
   */
  const savePageXPosition = (event) => setPageX(event.pageX)

  return (
    <>
      {/* ボタン */}
      <PrimaryButton
        addclass={classes.button}
        type="submit"
        onClick={handleClickOpen('body')}
        disabled={sendFactors.disabled}
      >
        {t('D501V0050')}
      </PrimaryButton>

      {/* ダイアログ Send確認 */}
      <MDialog
        open={open}
        scroll={scroll}
        onClose={(e) => handleCloseCancel(e, true)}
        onMouseDown={savePageXPosition}
        className={classes.root + ' ' + props.className}
      >
        {/* ダイアログ本文 */}
        <DialogContent>
          <div className={classes.dialog + ' ' + classes.transparent}>
            {/* 本文 */}
            <Grid
              container
              justify="center"
              alignContent="center"
              spacing={1}
              className={classes.marginTop}
            >
              <Grid item xs={12}>
                <div className={classes.text}>{t('D501V0080')}</div>
              </Grid>
            </Grid>
          </div>
        </DialogContent>

        {/* ダイアログ下部のボタン */}
        <DialogActions>
          {/* スマホ */}
          <Grid
            container
            direction="column"
            alignContent="center"
            className={classes.mobile + ' ' + classes.margin}
            spacing={3}
          >
            <Grid item md={12} xs={12}>
              <div className={classes.center}>
                <div onClick={handleCloseOK}>
                  <PrimaryButton onClick={sendFactors.sendevent}>
                    {t('D004V0062')}
                  </PrimaryButton>
                </div>
              </div>
            </Grid>
            <Grid item md={12} xs={12}>
              <div className={classes.center}>
                <div onClick={handleCloseCancel}>
                  <SecondaryButton>{t('D004V0063')}</SecondaryButton>
                </div>
              </div>
            </Grid>
          </Grid>
          {/* PC */}
          <Grid
            container
            justify="center"
            spacing={3}
            className={classes.desktop + ' ' + classes.margin}
          >
            <Grid item>
              <div className={classes.center}>
                <div onClick={handleCloseCancel}>
                  <SecondaryButton>{t('D004V0063')}</SecondaryButton>
                </div>
              </div>
            </Grid>
            <Grid item>
              <div className={classes.center}>
                <div onClick={handleCloseOK}>
                  <PrimaryButton onClick={sendFactors.sendevent}>
                    {t('D004V0062')}
                  </PrimaryButton>
                </div>
              </div>
            </Grid>
          </Grid>
        </DialogActions>
      </MDialog>

      {/* ダイアログ 登録エラー時 */}
      <MDialog
        open={errorOpen}
        scroll={scroll}
        onClose={handleCloseError}
        onMouseDown={savePageXPosition}
        className={classes.root + ' ' + props.className}
      >
        {/* ダイアログ本文 */}
        <DialogContent>
          <div className={classes.dialog + ' ' + classes.transparent}>
            {/* タイトル */}
            <Grid container item justify="center" alignItems="center">
              <WarningRoundedIcon className={classes.icon} />
              <div className={classes.text}>ERROR</div>
            </Grid>
            {/* 本文 */}
            <Grid
              container
              justify="center"
              alignContent="center"
              spacing={1}
              className={classes.marginTop}
            >
              <Grid item md={12} xs={12}>
                <div className={classes.errorText}>
                  {sendFactors.errcode
                    ? t(CommonFunc.getErrorMessage(sendFactors.errcode))
                    : ''}
                </div>
              </Grid>
            </Grid>
          </div>
        </DialogContent>

        {/* ダイアログ下部のボタン */}
        <DialogActions>
          {/* スマホ */}
          <Grid
            container
            direction="column"
            alignContent="center"
            className={classes.mobile + ' ' + classes.margin}
          >
            <Grid item md={12} xs={12}>
              <div className={classes.center}>
                <div onClick={handleCloseError}>
                  <PrimaryButton>{t('D004V0062')}</PrimaryButton>
                </div>
              </div>
            </Grid>
          </Grid>
          {/* PC */}
          <Grid
            container
            justify="center"
            spacing={3}
            className={classes.desktop + ' ' + classes.margin}
          >
            <Grid item>
              <div className={classes.center}>
                <div onClick={handleCloseError}>
                  <PrimaryButton>{t('D004V0062')}</PrimaryButton>
                </div>
              </div>
            </Grid>
          </Grid>
        </DialogActions>
      </MDialog>

      {/* ダイアログ Send完了 */}
      <RegisterSendCompleteDialog
        sendcomp={sendFactors.comp.toString()}
        path={sendFactors.path}
        clickevent={sendFactors.compevent}
        errcode={sendFactors.errcode}
        {...rest}
      />
    </>
  )
}

export default RegisterNewAccountSendDialog
