import { SEARCH_SORT_DATA } from '../../../actions'

/**
 * state: 検索ワード・ソート情報を保存
 * @param {Object} [state={}] -
 * @param {Object} action -
 * @returns {*} -
 */
const SearchSort = (state = {}, action) => {
  switch (action.type) {
    // 検索ワード・ソート情報を保存
    case SEARCH_SORT_DATA:
      if (action.sort.read !== undefined) {
        state.read = action.sort.read
      }
      if (action.sort.area1 !== undefined) {
        state.area1 = action.sort.area1
      }
      if (action.sort.area2 !== undefined) {
        state.area2 = action.sort.area2
      }
      if (action.sort.area3 !== undefined) {
        state.area3 = action.sort.area3
      }
      if (action.sort.search !== undefined) {
        state.search = action.sort.search
      }
      if (action.sort.sameCompany !== undefined) {
        state.sameCompany = action.sort.sameCompany
      }
      if (action.sort.acdigi !== undefined) {
        state.acdigi = action.sort.acdigi
      }
      if (action.sort.fwdr !== undefined) {
        state.fwdr = action.sort.fwdr
      }
      if (action.sort.shipper !== undefined) {
        state.shipper = action.sort.shipper
      }
      if (action.sort.carrier !== undefined) {
        state.carrier = action.sort.carrier
      }
      if (action.sort.date !== undefined) {
        state.date = action.sort.date
      }

      return state
    default:
      return state
  }
}

export default SearchSort
