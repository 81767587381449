import { makeStyles } from '@material-ui/core'
import React from 'react'

import AppContext from '../../../../contexts/AppContext'
import LayoutBox from '../../../common/layout/LayoutBox'
import TitleLightJhClientRegDeleteAccount from './00Title/TitleLightJhClientRegDeleteAccount'
import SubTitleLightJhClientRegDeleteAccount from './01SubTitle/SubTitleLightJhClientRegDeleteAccount'
import RequestTableLightJhClientRegDeleteAccount from './02RequestTable/RequestTableLightJhClientRegDeleteAccount'
import PagingLightJhClientRegDeleteAccount from './03Paging/PagingLightJhClientRegDeleteAccount'
import BackButtonLightJhClientRegDeleteAccount from './04BackButton/BackButtonLightJhClientRegDeleteAccount'
import useFetchData from './Hook/useFetchData'
import Theme from '../../../common/theme/Theme'

const useStyles = makeStyles(() => ({
  padding: Theme.childrenPadding,
}))

const LightJhClientRegDeleteAccount = () => {
  // 初期表示の値のセット
  const [state, dispatch] = useFetchData()
  const classes = useStyles()

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <LayoutBox>
        <div className={classes.padding}>
          <TitleLightJhClientRegDeleteAccount />
          <SubTitleLightJhClientRegDeleteAccount />
          <RequestTableLightJhClientRegDeleteAccount />
          <PagingLightJhClientRegDeleteAccount />
          <BackButtonLightJhClientRegDeleteAccount />
        </div>
      </LayoutBox>
    </AppContext.Provider>
  )
}

export default LightJhClientRegDeleteAccount
