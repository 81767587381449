import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

import ConstantsLightCarAirFreight from '../ConstantsLightCarAirFreight'
import TableCell from '../../../../common/table/TableCell'
import TableRow from '../../../../common/table/TableRow'
import AppContext from '../../../../../contexts/AppContext'

const useStyles = makeStyles((theme) => ({
  header: theme.commonTableHeader,
}))

/**
 * 定数から作成するヘッダーコンポーネント(1列分)
 * 変更したい場合は定数ConstantsBidMgmtを操作する
 * @return {JSX} ヘッダーコンポーネント(1列分)
 */
const HeaderLightCarAirFreight = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { state } = useContext(AppContext)

  return useMemo(
    () => (
      <TableRow>
        {ConstantsLightCarAirFreight.getHeaderKeyArr(state.Gsa).map(
          (key, index) => (
            <TableCell key={index} className={classes.header}>
              {t(key)}
            </TableCell>
          )
        )}
      </TableRow>
    ),
    [t, classes.header, state.Gsa]
  )
}

export default HeaderLightCarAirFreight
