import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import AppContext from '../../../../../contexts/AppContext'
import ConstantsLightCarAccountInfo from '../ConstantsLightCarAccountInfo'
import TableCell from '../../../../common/table/TableCell'
import TableRow from '../../../../common/table/TableRow'
import Common from '../../../../../constants/Common'

/**
 * 定数から作成するヘッダーコンポーネント(1列分)
 * 変更したい場合は定数ConstantsLightCarAccountInfoを操作する
 * @return {JSX} ヘッダーコンポーネント(1列分)
 */
const HeaderLightCarAccountInfo = () => {
  const { t } = useTranslation()
  const { state } = useContext(AppContext)
  // GSAと航空会社で表示するデータを分ける
  const tableArr =
    state.GsaFlg === Common.GSA_FLG.GSA
      ? ConstantsLightCarAccountInfo.GSA_TABLE_ARR
      : ConstantsLightCarAccountInfo.TABLE_ARR

  /**
   * 定数で定めたヘッダーコンポーネント(1個分)を作成
   * @param {String} { headerKey } 定数に設定している文言key
   * @return {JSX} ヘッダーコンポーネント(1個分)
   */
  const createHeaderCell = ({ headerKey }) => {
    return <TableCell key={headerKey}> {t(headerKey)} </TableCell>
  }

  return <TableRow>{tableArr.map(createHeaderCell)}</TableRow>
}

export default HeaderLightCarAccountInfo
