import React, { useMemo } from 'react'
import { Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  divider: {
    backgroundColor: theme.palette.primary.main,
  },
}))

/**
 * 青い区切り線を返す
 * @returns {JSX.Element} 区切り線
 */
const Line = () => {
  const classes = useStyles()
  return useMemo(() => <Divider className={classes.divider} />, [classes])
}
export default Line
