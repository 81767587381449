import React, { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'

import TableRow from '../../../common/table/TableRow'
import TableCell from '../../../common/table/TableCell'
import EditButtonMeasureAndWeightLightBookingDetails from './EditButtonMeasureAndWeightLightBookingDetails'
import AppContext from '../../../../contexts/AppContext'

/**
 * @param {Object} props 下記内容
 * @param {Number} props.index 貨物配列のインデックス
 * @param {Boolean} props.editable 編集可能
 * @return {JSX} Measure&Weight欄の1行
 */
const RowMeasureAndWeightLightBookingDetails = ({ index, editable }) => {
  const { state, dispatch } = useContext(AppContext)

  const cargoDetail = state.Cargo.cargoDetails[index]
  const { pcsStr, grossWgtStr, lengthStr, widthStr, heightStr } = cargoDetail

  return useMemo(() => {
    /**
     * Editボタンを作成する
     * @returns {JSX} Editボタン
     */
    const createEditButton = () => (
      <EditButtonMeasureAndWeightLightBookingDetails
        index={index}
        cargoDetail={cargoDetail}
        dispatch={dispatch}
      />
    )

    return (
      <TableRow>
        <TableCell>{`${index + 1}.`}</TableCell>
        <TableCell>{pcsStr}</TableCell>
        <TableCell>{`${grossWgtStr} kg`}</TableCell>
        <TableCell>{`${lengthStr} cm`}</TableCell>
        <TableCell>x</TableCell>
        <TableCell>{`${widthStr} cm`}</TableCell>
        <TableCell>x</TableCell>
        <TableCell>{`${heightStr} cm`}</TableCell>
        <TableCell>{editable ? createEditButton() : null}</TableCell>
      </TableRow>
    )
  }, [
    cargoDetail,
    pcsStr,
    grossWgtStr,
    lengthStr,
    widthStr,
    heightStr,
    index,
    dispatch,
    editable,
  ])
}
RowMeasureAndWeightLightBookingDetails.propTypes = {
  index: PropTypes.number.isRequired,
  editable: PropTypes.bool.isRequired,
}
export default RowMeasureAndWeightLightBookingDetails
