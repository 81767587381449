import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import TableCell from './TableCell'
import CheckBox from '../checkBox/CheckBox'

const useStyles = makeStyles((theme) => ({
  tableHead: theme.checkBoxLeft,
}))
/**
 * @return {JSX} CheckBoxコンポーネント
 */
const CheckBoxCell = ({ ...rest }) => {
  const classes = useStyles()

  return (
    <TableCell className={classes.tableHead}>
      <CheckBox {...rest}></CheckBox>
    </TableCell>
  )
}

export default CheckBoxCell
