import { combineReducers } from 'redux'

import myprofile, { initMyProfile } from './MyProfile'
import backup, { initBackup } from './Backup'

const initialState = {
  myprofile: initMyProfile,
  backup: initBackup,
}

// すべてのreducerをここでまとめる
export default combineReducers({
  myprofile,
  backup,
})

export { initialState }
