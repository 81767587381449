import React, { useContext } from 'react'
import AppContext from '../../../../../contexts/AppContext'

import CommonTableContainer from '../../../../common/table/CommonTableContainer'
import HeaderLightFwdrBookingList from './HeaderLightFwdrBookingList'
import ShcDialog from '../../../../common/dialog/commonDesignDialog/ShcDialog'
import TableContentsLightFwdrBookingList from './TableContentsLightFwdrBookingList'

/**
 * @return {JSX} 表コンポーネント(全体)
 */
const TableLightFwdrBookingList = () => {
  const { state } = useContext(AppContext)

  return (
    <CommonTableContainer isNoResult={state.Tables.isNoResult}>
      <HeaderLightFwdrBookingList />
      <TableContentsLightFwdrBookingList />
      <ShcDialog />
    </CommonTableContainer>
  )
}

export default TableLightFwdrBookingList
