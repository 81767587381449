import React from 'react'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'

import HeaderCompanyInfoText from './HeaderCompanyInfoText'
import DetailsCompanyInfoText from './DetailsCompanyInfoText'

/**
 * @param {Object} props - 以下
 * @param {Array} required - trueの場合必須のマークを付ける
 * @param {String} text - テキストの配列、配列1個に付き1行作成
 * @return {JSX} 荷主アドレス欄
 */
const CompanyInfoText = ({ required, textArr }) => {
  return (
    <Grid item container justify="flex-start">
      <HeaderCompanyInfoText required={required} />
      {textArr.map((text, index) => (
        <DetailsCompanyInfoText key={index} text={text} />
      ))}
    </Grid>
  )
}

CompanyInfoText.propTypes = {
  required: PropTypes.bool,
  textArr: PropTypes.array.isRequired,
}

export default CompanyInfoText
