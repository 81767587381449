import { LIGHT_BOOKING_REQUEST } from '../../../actions'

/** @type{Object} 初期表示 */
const initShippingDetails = {
  org: null,
  dst: null,
  commodity: null,
}

/**
 * ShippingDetails情報を保持
 * @param {Boolean} [state=initShippingDetails] state
 * @param {Object} action action
 * @return {Boolean} チェックボックス状態
 */
const ShippingDetails = (state = initShippingDetails, action) => {
  const { type, ...rest } = action
  switch (type) {
    case LIGHT_BOOKING_REQUEST.SELECT.SET:
      return { ...state, ...rest }
    default:
      return state
  }
}

export default ShippingDetails

export { initShippingDetails }
