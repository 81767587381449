import { DIALOG } from '../../../actions'

/** 初期表示ページ */
const initAcceptAndRejectDialogJhClientRegSignUp = {
  // 各ダイアログ開閉状態
  isOpenAccept: false,
  isOpenReject: false,
  isOpenSuccess: false,
  // APIのレスポンス
  response: {},
  // APIに渡すリクエスト
  req: { companyId: '', updatedAt: '' },
}

/**
 * @param {Object} action dispatchで受け取ったaction
 * @param {Object} state ダイアログの開閉状態とAPI用のリクエストとレスポンス
 * @param {String} openKey 開くダイアログのkey
 * @return {Object} 各ダイアログが開いた状態のstate
 */
const openDialog = (action, state, openKey) => {
  const { response = {} } = action
  const { companyId, updatedAt } =
    action.data ?? initAcceptAndRejectDialogJhClientRegSignUp.req
  const req = { companyId, updatedAt }

  return {
    ...state,
    [openKey]: true,
    response,
    req,
  }
}

/**
 * @return {Object} 各ダイアログが閉じた状態のstate
 */
const closeDialog = () => initAcceptAndRejectDialogJhClientRegSignUp

/**
 * ダイアログの開閉状態とAPI用のリクエストとレスポンスを保持
 * @param {Object} [state=initAcceptAndRejectDialogJhClientRegSignUp] ダイアログの開閉状態とAPI用のリクエストとレスポンス
 * @param {Object} action dispatchで受け取ったaction
 * @return {Object} ダイアログの開閉状態とAPI用のリクエストとレスポンス
 */
const AcceptAndRejectDialogJhClientRegSignUp = (
  state = initAcceptAndRejectDialogJhClientRegSignUp,
  action
) => {
  // ページの指定が無い場合は初期ページ
  const { type } = action
  switch (type) {
    case DIALOG.OPEN_ACCEPT:
      return openDialog(action, state, 'isOpenAccept')
    case DIALOG.OPEN_REJECT:
      return openDialog(action, state, 'isOpenReject')
    case DIALOG.OPEN_SUCCESS:
      return openDialog(action, state, 'isOpenSuccess')
    case DIALOG.CLOSE:
      return closeDialog()
    default:
      return state
  }
}

export default AcceptAndRejectDialogJhClientRegSignUp

export { initAcceptAndRejectDialogJhClientRegSignUp }
