import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'

import { LIGHT_BOOKING_DETAILS } from '../../../../actions'
import XsmallButton from '../../../common/buttons/XsmallButton'

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
}))

/**
 * @param {Object} props 下記内容
 * @param {String} props.interLine インターライン
 * @param {String} props.valueName 編集する値の名前
 * @param {Function} props.dispatch dispatch
 * @returns {JSX} Editボタン
 */
const EditButtonFlightLightBookingDetails = ({
  interLine,
  valueName,
  dispatch,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  /**
   * ポップアップを開く
   * @returns {void} なし
   */
  const openPopup = () => {
    dispatch({
      type: LIGHT_BOOKING_DETAILS.FLIGHT_POPUP.OPEN,
      interLine,
      valueName,
    })
  }

  return (
    <Grid item>
      <XsmallButton addclass={classes.button} onClick={openPopup}>
        {t('D301V0035')}
      </XsmallButton>
    </Grid>
  )
}
EditButtonFlightLightBookingDetails.propTypes = {
  interLine: PropTypes.string,
  valueName: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
}
export default EditButtonFlightLightBookingDetails
