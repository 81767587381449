import { Grid } from '@material-ui/core'
import React, { useMemo, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import Common from '../../../../../constants/Common'
import AppContext from '../../../../../contexts/AppContext'
import PrimaryButton from '../../../../common/buttons/PrimaryButton'
import AreYouSureDialog from '../../../../common/dialog/commonDesignDialog/AreYouSureDialog'
import SavedChangesDialog from '../../../../common/dialog/commonDesignDialog/SavedChangesDialog'
import { BeforePath } from '../ConstantsLightBookingDetails'
import ApiPaths from '../../../../../constants/ApiPaths'
import CommonFunc from '../../../../common/function/CommonFunc'
import Paths from '../../../../../constants/Paths'

const useStyles = makeStyles((Theme) => ({
  saveButton: {
    width: Theme.buttonSize.width.large,
    height: Theme.buttonSize.height.large,
  },
}))

/**
 * @return {JSX} Saveボタン
 */
const SaveButtonLightBookingDetails = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [savedChangesOpen, setSavedChangesOpen] = useState(false)
  const { state } = useContext(AppContext)

  // TotalA/Fの桁数判定を取得
  const isOverTotalAirFreightDigit = state.Rate.getIsOverTotalAirFreightDigit(
    state.Cargo.totalChargeableWgt
  )

  const disabled =
    state.MawbNumber.inputError ||
    state.CancelBooking.inputError ||
    isOverTotalAirFreightDigit
  const beforePath = state.ShippingDetails.beforePath

  const isNotEditedCancelBooking =
    state.CancelBooking.cancelFlg === Common.CANCEL_FLG.NONE
  const isApprove = state.Status.isSpaceConfirmed && isNotEditedCancelBooking

  const isRequest = beforePath === BeforePath.SearchResult
  let saveButtonText = isRequest ? t('4th0020') : t('D301V0021') //RequestまたはSave
  if (isApprove) {
    saveButtonText = t('D010V0088') //Approve時
  }

  return useMemo(() => {
    const openFunc = () => setOpen(true)
    const closeFunc = () => setOpen(false)
    /**
     * 編集内容を保存
     * @returns {void} なし
     */
    const save = async () => {
      setOpen(false)
      /** @type {Object} リクエストデータ */
      const req = {
        data: {
          cargoDetailArr: state.Cargo.getRequestData(),
          shcArr: state.ShcObj.getRequestData(),
          mawbObj: state.MawbNumber.getRequestData(),
          cancelObj: state.CancelBooking.getRequestData(),
          cargoId: state.ShippingDetails.cargoId,
          cargoUpdatedAt: state.ShippingDetails.cargoUpdatedAt,
          bookingId: state.ShippingDetails.bookingId,
          bookingUpdatedAt: state.ShippingDetails.bookingUpdatedAt,
          fwdrDocumentArr: state.Document.getRequestData(),
          statusId: state.ShippingDetails.statusId,
          orgId: state.Flight.orgId,
          viaOneId: state.Flight.viaOneId,
          viaTwoId: state.Flight.viaTwoId,
          dstId: state.Flight.dstId,
          flightIdA: state.Flight.flightIdA,
          flightIdB: state.Flight.flightIdB,
          flightIdC: state.Flight.flightIdC,
          commId: state.ShippingDetails.commId,
          ...Object.keys(state.Remark).reduce(
            (obj, remarkName) => ({
              ...obj,
              [remarkName]:
                state.Remark[remarkName] === ''
                  ? null
                  : state.Remark[remarkName],
            }),
            {}
          ),
          laneId: state.Rate.rateObj.laneId,
        },
        files: state.Document.getFilesRequestData(),
      }

      // リクエストがある場合API呼び出し
      const apiInfoArr = [
        {
          key: 'bookingInfo',
          method: 'post',
          path: ApiPaths.FORWARDER.LIGHT_BOOKING_DETAILS,
          req,
        },
      ]
      const resObj = await CommonFunc.execApiAsync(apiInfoArr)
      const isApiSuccessful = CommonFunc.checkApiResponseObj(resObj, history)
      if (isApiSuccessful) {
        setSavedChangesOpen(true)
      }
    }

    /**
     * ページ遷移
     * @returns {void} なし
     */
    const pageTransition = () => {
      if (beforePath === BeforePath.SearchResult) {
        CommonFunc.clickEvent(
          { path: Paths.FWDR.LIGHT_BOOKING_REQUEST },
          history
        )
      } else {
        CommonFunc.clickEvent({ path: Paths.FWDR.LIGHT_BOOKING_LIST }, history)
      }
    }

    /** @type {Object} ダイアログのボタン設定 */
    const dialogFuncObj = {
      [Common.DIALOG.BUTTON_CATEGORY.CANCEL]: { onClick: closeFunc },
      [Common.DIALOG.BUTTON_CATEGORY.OK]: { onClick: save },
    }

    /** @type {Object} SavedChangesダイアログのボタン設定 */
    const savedDialogFuncObj = {
      [Common.DIALOG.BUTTON_CATEGORY.OK]: { onClick: pageTransition },
    }

    return (
      <>
        <SavedChangesDialog
          open={savedChangesOpen}
          closeFunc={pageTransition}
          dialogFuncObj={savedDialogFuncObj}
        />
        <Grid item>
          <PrimaryButton
            onClick={openFunc}
            disabled={disabled}
            addclass={classes.saveButton}
          >
            {saveButtonText}
          </PrimaryButton>
          <AreYouSureDialog
            open={open}
            dialogFuncObj={dialogFuncObj}
            closeFunc={closeFunc}
          />
        </Grid>
      </>
    )
  }, [
    beforePath,
    open,
    history,
    saveButtonText,
    savedChangesOpen,
    state.ShippingDetails,
    state.Flight,
    state.CancelBooking,
    state.Cargo,
    classes.saveButton,
    state.Document,
    state.Rate.rateObj.laneId,
    state.Remark,
    state.ShcObj,
    state.MawbNumber,
    disabled,
  ])
}

export default SaveButtonLightBookingDetails
