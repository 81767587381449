/* eslint-disable no-extra-parens */
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import IconBox from '../../../common/info/IconBox'
import DeleteSmallDialog from '../../../common/dialog/DeleteSmallDialog'
import AppContext from '../../../../contexts/AppContext'
import {
  UPDATE_JH_ICON_PATH,
  UPDATE_JH_FILE,
  UPDATE_JH_IS_DELETE_ICON,
  UPDATE_JH_ICON_PATH_INPUTERR,
} from '../../../../actions'
import CommonValidation from '../../../common/function/CommonValidation'

const useStyles = makeStyles((theme) => ({
  head: theme.textBoldBLU,
  // eslint-disable-next-line camelcase
  text_blk: theme.textNormalBLK,
  errText: theme.textNormalRED,
}))

const Icon = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { state, dispatch } = useContext(AppContext)
  const [errText, setErrText] = useState('')

  const handleUploadIcon = (event) => {
    const files = event.target.files

    if (files.length > 0) {
      const file = files[0]
      const check = CommonValidation.imgSize(file)
      setErrText(check.checkResult ? check.errMsg : '')
      if (!check.checkResult) {
        const reader = new FileReader()
        reader.onload = (event) => {
          dispatch({
            type: UPDATE_JH_ICON_PATH,
            icon: event.target.result,
          })
          dispatch({
            type: UPDATE_JH_FILE,
            file,
          })
          dispatch({
            type: UPDATE_JH_ICON_PATH_INPUTERR,
            inputErr: false,
          })
          dispatch({
            type: UPDATE_JH_IS_DELETE_ICON,
            isDeleteIcon: '',
          })
        }
        reader.readAsDataURL(file)
      }
    }
  }

  const handleDeleteIcon = () => {
    dispatch({
      type: UPDATE_JH_ICON_PATH,
      icon: '',
    })
    dispatch({
      type: UPDATE_JH_IS_DELETE_ICON,
      isDeleteIcon: 1,
    })
    dispatch({
      type: UPDATE_JH_ICON_PATH_INPUTERR,
      inputErr: true,
    })
    setErrText('VALIDATION011')
  }

  return (
    <div className={classes.formControl}>
      <Grid container>
        <Grid item md={10} xs={10}>
          <div className={classes.head}>{t('D301V0019')}</div>
          <span className={classes.errText}>{t(errText)}</span>
        </Grid>
        <Grid item md={10} xs={10}>
          <IconBox
            btnflg={true}
            iconPath={state.companyinfo.icon.value}
            onAddIcon={handleUploadIcon}
          />
        </Grid>
        <Grid item md={10} xs={10}>
          {state.companyinfo.icon.value === '' ||
          state.companyinfo.icon.value === null ? (
            ''
          ) : (
            <DeleteSmallDialog onDelete={handleDeleteIcon} />
          )}
        </Grid>
      </Grid>
    </div>
  )
}

export default Icon
