import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'

import AppContext from '../../../../../contexts/AppContext'
import TextField from '../../../../common/text/TextField'
import CommonValidation from '../../../../common/function/CommonValidation'
import Common from '../../../../../constants/Common'

/**
 * @param {Object} props 以下
 * @param {String} newTextStr 入力中のSpecialNotes文字列
 * @param {Function} setNewTextStr 入力中のSpecialNotesセット関数
 * @param {Object} errorObj エラー状態
 * @param {Function} setErrorObj エラー状態セット関数
 * @returns {JSX} ダイアログ内のテキストコンポーネント
 */
const DialogTextSpecialNotes = (props) => {
  const { t } = useTranslation()
  const { state } = useContext(AppContext)
  const { isOpenDialog, specialNoteArr, selectIndexNum } = state.SpecialNotes
  // 選択したSpecialNotesの現在表示
  const currentText = specialNoteArr[selectIndexNum]
  const {
    // 入力中のSpecialNotes
    newTextStr,
    setNewTextStr,
    // バリデーションチェックによるエラーテキスト
    errorObj,
    setErrorObj,
  } = props
  const { checkResult, errMsg } = errorObj
  // Constantsで共通設定しているplaceHolder
  const { placeholderKeyStr } =
    Common.SPECIAL_NOTE_SETTING_ARR[selectIndexNum] ?? {}
  const placeholderStr = t(placeholderKeyStr)

  // ダイアログを開いたときに選択したSpecialNotesを反映
  useEffect(() => {
    if (isOpenDialog) setNewTextStr(currentText)
  }, [isOpenDialog, currentText, setNewTextStr])

  /**
   * 入力内容を反映、バリデーションチェック
   * @param {Event} e onChangeのイベント
   * @return {void}
   */
  const changeText = (e) => {
    const textStr = e.target.value
    const validationResultObj = CommonValidation.specialNotesOptional(textStr)
    setNewTextStr(textStr)
    setErrorObj(validationResultObj)
  }

  return (
    <Grid container justify="flex-start">
      <Grid item md={12} xs={12}>
        <TextField
          value={newTextStr}
          onChange={changeText}
          multiline
          error={checkResult}
          helperText={t(errMsg)}
          placeholder={placeholderStr}
        />
      </Grid>
    </Grid>
  )
}

DialogTextSpecialNotes.propTypes = {
  newTextStr: PropTypes.string.isRequired,
  setNewTextStr: PropTypes.func.isRequired,
  errorObj: PropTypes.object.isRequired,
  setErrorObj: PropTypes.func.isRequired,
}

export default DialogTextSpecialNotes
