import React, { useReducer, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { SEARCH_SORT, TABLES } from '../../../../actions'
import AppContext from '../../../../contexts/AppContext'
import reducer, {
  initialState,
} from '../../../../reducers/master/lightJhClientList'
import LayoutBox from '../../../common/layout/LayoutBox'
import ApiPaths from '../../../../constants/ApiPaths'
import CommonFunc from '../../../common/function/CommonFunc'

import TitleLightJhClientList from './00TitleLightJhClientList/TitleLightJhClientList'
import TabsAndSearchSortLightJhClientList from './01SearchSortLightJhClientList/TabsAndSearchSortLightJhClientList'
import TableLightJhClientList from './02TableLightJhClientList/TableLightJhClientList'
import PagingLightJhClientList from './03PagingLightJhClientList/PagingLightJhClientList'
import BackButtonLightJhClientList from './04BackButtonLightJhClientList/BackButtonLightJhClientList'
import ConstantsLightJhClientList from './ConstantsLightJhClientList'

const LightJhClientList = () => {
  const history = useHistory()

  // 扱いたいデータstateとデータを操作するための処理dispatch
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    ;(async () => {
      const apiInfoArr = [
        {
          key: 'clientInfo',
          method: 'get',
          path: ApiPaths.JH.LIGHT_CLIENT_INFO,
        },
      ]
      const resObj = await CommonFunc.execApiAsync(apiInfoArr)
      const isApiSuccessful = CommonFunc.checkApiResponseObj(resObj, history)

      if (isApiSuccessful) {
        const { clientInfo } = resObj
        // ソートの初期値は定数の最初の値で固定
        const initSort = ConstantsLightJhClientList.SORT_ITEM_ARR[0].sortTerm

        //SearchSortの初期値を設定
        dispatch({
          type: SEARCH_SORT.SET,
          search: '',
          sort: initSort,
        })
        // テーブルの初期値を設定
        dispatch({
          type: TABLES.SET,
          tableData: clientInfo.data.results,
          sort: initSort,
        })
      }
    })()
  }, []) //eslint-disable-line

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <LayoutBox>
        <TitleLightJhClientList />
        <TabsAndSearchSortLightJhClientList />
        <TableLightJhClientList />
        <PagingLightJhClientList />
        <BackButtonLightJhClientList />
      </LayoutBox>
    </AppContext.Provider>
  )
}

export default LightJhClientList
