import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import AppContext from '../../../contexts/AppContext'
import Theme from '../../common/theme/Theme'
import { LIGHT_REGISTER_NEW_ACCOUNT } from '../../../actions'
import CheckBox from '../../common/checkBox/CheckBox'
import DialogLink from '../../common/dialog/DialogLink'
import AuthenticationTableContainer from '../../common/table/AuthenticationTableContainer'

const useStyles = makeStyles(() => ({
  check: Theme.signup.check,
  white: Theme.signup.white,
}))

/**
 * @returns {JSX} - 同意チェックコンポーネント
 */
const AgreeCheck = () => {
  const { dispatch } = useContext(AppContext)
  const { t } = useTranslation()
  const classes = useStyles()

  return useMemo(() => {
    /**
     * 同意チェックを更新
     * @param {*} e - event
     * @returns {void}
     */
    const updateAgreeCheck = (e) => {
      dispatch({
        type: LIGHT_REGISTER_NEW_ACCOUNT.AGREE_CHECK.UPDATE,
        value: e.target.checked,
      })
    }

    return (
      <AuthenticationTableContainer>
        <Grid container justify="center">
          <CheckBox className={classes.check} onClick={updateAgreeCheck} />
          <span className={classes.white}>*{t('D501V0123')}</span>
          <DialogLink linkName={t('D501V0124')} />
          <span className={classes.white}>{t('D501V0125')}</span>
          <DialogLink linkName={t('D501V0126')} />
        </Grid>
      </AuthenticationTableContainer>
    )
  }, [dispatch, classes.check, classes.white, t])
}

export default AgreeCheck
