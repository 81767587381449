import { UPDATE_JH_PAGING_DATA } from '../../../actions'

/**
 * 現在ページを管理
 * @param {Object} [state={}] 現在のページ
 * @param {Object} action 現在のページの更新に必要な値
 * @return {Object} 現在ページ
 */
const Paging = (state = {}, action) => {
  const data = { page: action.page }
  switch (action.type) {
    case UPDATE_JH_PAGING_DATA:
      // 現在ページ
      return data
    default:
      return state
  }
}

export default Paging
