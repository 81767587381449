import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: theme.fontSize.regular,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
}))

const HeaderText = (props) => {
  const classes = useStyles()
  return <Typography className={classes.text}>{props.children}</Typography>
}

export default HeaderText
