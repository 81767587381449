import React, { useContext, useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'

import AppContext from '../../../../../contexts/AppContext'
import { LIGHT_FLIGHT_MGMT, TABLES } from '../../../../../actions'
import CommonDialog from '../../../../common/dialog/commonDialog/CommonDialog'
import Routing from './Routing/Routing'
import Area from './Area'
import Hide from './Hide'
import EffectiveDate from './EffectiveDate'
import Common from '../../../../../constants/Common'
import CarrierSelect from './CarrierSelect'
import CommonCalc from '../../../../common/function/CommonCalc'

/**
 * @returns {JSX} FlightInfoポップアップ
 */
const FlightInfoPopup = () => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const { dispatch, state } = useContext(AppContext)

  const { add } = state.Popup
  /** @type {Boolean} OKボタンの非活性状態 */
  const disabled = state.Popup.getIsInputError()
  /** @type {Object[]} Routingの配列 */
  const routingArr = open ? state.Popup.getFlightInfoParam('routingArr') : []

  // ポップアップを開く関数をreducerに登録
  useEffect(() => {
    dispatch({
      type: LIGHT_FLIGHT_MGMT.TABLES.SET_OPEN,
      setOpen,
    })
  }, [dispatch])

  return useMemo(() => {
    /**
     * 編集内容を反映する
     * @returns {void} なし
     */
    const editTableData = () => {
      dispatch({
        type: TABLES.EDIT,
        data: state.Popup.editData,
        defaultTwoCode:
          state.CarrierName.userCarrierNameInfo?.carriertwoCode ?? '',
        add,
        page: CommonCalc.calcShowDataIndex(
          state.Paging,
          state.Tables.showData.length
        ),
      })
      setOpen(false)
    }

    /** @type {Object} ダイアログのボタン設定 */
    const dialogFuncObj = {
      [Common.DIALOG.BUTTON_CATEGORY.CANCEL]: { onClick: () => setOpen(false) },
      [Common.DIALOG.BUTTON_CATEGORY.OK]: { onClick: editTableData, disabled },
    }

    // 一般ユーザはOKボタン非表示
    if (!state.Auth) {
      delete dialogFuncObj[Common.DIALOG.BUTTON_CATEGORY.OK]
    }

    return (
      <CommonDialog
        open={open}
        closeFunc={() => setOpen(false)}
        dialogFuncObj={dialogFuncObj}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <EffectiveDate start={true} />
          </Grid>
          <Grid item>
            <EffectiveDate start={false} />
          </Grid>
          <Grid item>
            <Area />
          </Grid>
          {!add ? (
            <Grid item>
              <Hide />
            </Grid>
          ) : null}
          {state.Gsa ? (
            <Grid item>
              <CarrierSelect />
            </Grid>
          ) : null}
          <Grid item>
            {routingArr.map((_, index) => (
              <Routing index={index} key={index} />
            ))}
          </Grid>
        </Grid>
      </CommonDialog>
    )

    // eslint-disable-next-line
  }, [open, add, dispatch, state.Gsa, t, routingArr.length, disabled])
}
export default FlightInfoPopup
