import React, { useContext, useMemo } from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

import { LIGHT_BOOKING_REQUEST } from '../../../../../actions'
import AppContext from '../../../../../contexts/AppContext'
import InputSelect from '../../../../common/select/InputSelect'

const useStyles = makeStyles((theme) => ({
  title: theme.textBoldBLU,
  red: theme.textBoldRED,
  margin: {
    marginBottom: '1rem',
  },
}))

/**
 * @returns {JSX} CommoditySelect欄
 */
const CommoditySelectLightBookingRequest = () => {
  const { state, dispatch } = useContext(AppContext)
  const { t } = useTranslation()
  const classes = useStyles()

  /** @type {Number} 選択中のレートタイプId */
  const commId = state.ShippingDetails.commodity

  return useMemo(() => {
    /** @type{Object} 選択中のレートタイプ */
    const value =
      commId && state.SelectBox.commodity
        ? state.SelectBox.commodity.find((v) => v.commId === commId)
        : null
    /**
     * レートタイプ更新
     * @param {Object} value 選択した値
     * @returns {void} なし
     */
    const onChange = (value) => {
      dispatch({
        type: LIGHT_BOOKING_REQUEST.SELECT.SET,
        commodity: value?.commId ?? null,
      })
    }

    return (
      <Grid container>
        <Grid item md={4}>
          <span className={classes.red}>*</span>
          <span className={classes.title}>{t('D004V0006')}</span>
          <InputSelect
            className={classes.margin}
            options={state.SelectBox.commodity}
            getOptionLabel={(option) => option.comm}
            value={value}
            onChange={(_, value) => onChange(value)}
            disabled={false}
          />
        </Grid>
      </Grid>
    )
  }, [dispatch, commId, state.SelectBox.commodity, classes, t])
}
export default CommoditySelectLightBookingRequest
