/* eslint-disable prefer-template */
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import MDialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'

import SecondaryButton from '../buttons/SecondaryButton'
import PrimaryButton from '../buttons/PrimaryButton'
import CommonFunc from '../function/CommonFunc'

const useStyles = makeStyles((theme) => ({
  root: theme.dialog,
  dialog: theme.dialogContent,
  transparent: {
    background: 'transparent',
    border: '0px solid',
  },
  icon: {
    fontSize: 24,
    color: theme.palette.required,
  },
  text: theme.saveText,
  mobile: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  desktop: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  margin: {
    margin: 10,
  },
  marginTop: {
    marginTop: '2rem',
  },
}))

//外部からエラー文を設定できるように関数を定義
/** エラー文を設定する
 * @returns {void} なし
 */
let setErrorMessage = () => {}

/**
 * @returns {JSX.Element} - ErrorDialog
 */
const ErrorDialog = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [scroll] = useState('body')

  /** useStateで管理するエラーコード */
  const errorMessageStateVariable = useState('')
  /** エラーコードの値 */
  const errorMessage =
    typeof errorMessageStateVariable[0] === 'object'
      ? errorMessageStateVariable[0].message
      : errorMessageStateVariable[0]
  // エラーコードを設定する関数をsetErrCodeに代入
  setErrorMessage = errorMessageStateVariable[1]
  /** エラーのメールアドレスを表示 */
  const mail = errorMessageStateVariable[0]?.errEmails ?? []

  const handleClose = () => {
    CommonFunc.closeErrorDialog()
    /** 他にダイアログを開いているかどうか */
    const isDialog = CommonFunc.getIsDialog()
    // ダイアログが開かれていなかったらスクロールを再表示
    if (isDialog) {
      CommonFunc.hideScroll()
    } else {
      CommonFunc.showScroll()
    }
  }

  return (
    <MDialog
      id="error-dialog"
      hidden
      open={true}
      scroll={scroll}
      onClose={handleClose}
      disableScrollLock
      className={classes.root}
    >
      {/* ダイアログ本文 */}
      <DialogContent>
        <div className={classes.dialog + ' ' + classes.transparent}>
          {/* タイトル */}
          <Grid container item justify="center" alignItems="center">
            <WarningRoundedIcon className={classes.icon} />
          </Grid>
          {/* 本文 */}
          <Grid
            container
            justify="center"
            alignContent="center"
            spacing={1}
            className={classes.marginTop}
          >
            <Grid item xs={12}>
              {mail.map((str) => {
                return (
                  <p className={classes.text} key={str} align="center">
                    {str}
                  </p>
                )
              })}
              {mail && <br />}
              <div className={classes.text}>{t(errorMessage)}</div>
            </Grid>
          </Grid>
        </div>
      </DialogContent>

      {/* ダイアログ下部のボタン */}
      <DialogActions>
        {/* スマホ */}
        <Grid
          container
          direction="column"
          alignContent="center"
          className={classes.mobile + ' ' + classes.margin}
          spacing={3}
        >
          <Grid item md={12} xs={12}>
            <div className={classes.center}>
              <div onClick={handleClose}>
                <SecondaryButton>{t('D004V0085')}</SecondaryButton>
              </div>
            </div>
          </Grid>
        </Grid>
        {/* PC */}
        <Grid
          container
          justify="center"
          spacing={3}
          className={classes.desktop + ' ' + classes.margin}
        >
          <Grid item>
            <div className={classes.center}>
              <div onClick={handleClose}>
                <div>
                  <PrimaryButton>{t('D004V0062')}</PrimaryButton>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </DialogActions>
    </MDialog>
  )
}

export default ErrorDialog
export { setErrorMessage }
