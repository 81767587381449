import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import AppContext from '../../../contexts/AppContext'
import HeaderText from '../../common/text/HeaderText'

const useStyles = makeStyles((theme) => ({
  head: theme.textBoldBLU,
  textBlk: {
    ...theme.textNormalBLK,
    marginLeft: '1rem',
    wordWrap: 'break-word',
  },
}))

const Airport = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { state } = useContext(AppContext)
  const { companyinfo } = state
  const airport = companyinfo.airport.value

  return (
    <Grid container spacing={3}>
      <Grid container item spacing={2}>
        <Grid item md={12} xs={12}>
          <HeaderText className={classes.head}>
            {props.carrier ? t('D301V0175') : t('D301V0186')}
          </HeaderText>
          <div className={classes.textBlk}>{airport}</div>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Airport
