import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '48px',
    height: '48px',
    color: theme.palette.primary.main,
    fontSize: '25px',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.fontColor.secondary,
    },
    '&:active': {
      backgroundColor: theme.palette.primary.light,
    },
  },
}))

const EditIconButton = (props) => {
  const classes = useStyles()
  return (
    <IconButton className={classes.root} onClick={props.onClick} {...props}>
      <EditIcon />
    </IconButton>
  )
}

export default EditIconButton
