// Display + 画面名
// JH/SHIP/FWDR/AIRLINE共用で使用
const DisplayMasterClient = {
  // 画面ID + 文言ID

  D302V0001: {
    en: 'Client List',
    ja: '顧客リスト',
  },
  D302V0002: {
    en: 'FWDR',
    ja: '混載業者',
  },
  D302V0003: {
    en: 'Carrier',
    ja: '航空会社',
  },
  D302V0004: {
    en: 'FWDR',
    ja: '混載業者',
  },
  D302V0005: {
    en: 'Plan',
    ja: 'プラン',
  },
  D302V0006: {
    en: 'Representative',
    ja: '代表者',
  },
  D302V0007: {
    en: 'Tel',
    ja: '電話番号',
  },
  D302V0008: {
    en: 'E-mail',
    ja: 'メールアドレス',
  },
  D302V0009: {
    en: 'Total User',
    ja: '総人数',
  },
  D302V0010: {
    en: 'Status',
    ja: 'ステータス',
  },
  D302V0011: {
    en: 'keyword etc...',
    ja: 'キーワードを入力してください',
  },
  D302V0012: {
    en: 'Sort by',
    ja: '並び替え',
  },
  D302V0013: {
    en: 'Edit',
    ja: '編集',
  },
  D302V0014: {
    en: 'Check',
    ja: '確認',
  },
  D302V0015: {
    en: 'New User',
    ja: '新規ユーザー',
  },
  D302V0016: {
    en: 'First Name',
    ja: '姓',
  },
  D302V0017: {
    en: 'Last Name',
    ja: '名',
  },
  D302V0018: {
    en: 'Authority',
    ja: '権限',
  },
  D302V0019: {
    en: 'Reject',
    ja: '拒否',
  },
  D302V0020: {
    en: 'Accept',
    ja: '承認',
  },
  D302V0021: {
    en: 'Cancel',
    ja: 'キャンセル',
  },
  D302V0022: {
    en: 'OK',
    ja: '決定',
  },
  D302V0023: {
    en: 'ADBANCED',
    ja: '',
  },
  D302V0024: {
    en: 'BASICINTERMEDIATE',
    ja: '',
  },
  D302V0025: {
    en: 'Latery',
    ja: '最近',
  },
  D302V0026: {
    en: 'CompanyOrder',
    ja: '会社順',
  },
  D302V0027: {
    en: 'Status Order',
    ja: 'ステータス順',
  },
  D302V0028: {
    en: 'Closed',
    ja: '停止',
  },
  D302V0029: {
    en: 'Delete account request',
    ja: '退会申請中',
  },
  D302V0030: {
    en: 'In use',
    ja: '使用中',
  },
  D302V0031: {
    en: 'New account request',
    ja: '新規登録申請',
  },
  D302V0032: {
    en: 'Forwarder',
    ja: '混載業者',
  },
  D302V0033: {
    en: 'PIC',
    ja: '代表者',
  },
}

// Display + 画面名
export default DisplayMasterClient
