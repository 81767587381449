import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import logo from '../../../../../public/images/CargoLabo_logo_white.svg'

const useStyles = makeStyles((theme) => ({
  title: {
    margin: '102px auto 42px auto',
    textAlign: 'center',
  },
  text: {
    fontSize: '72px',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 'bold',
    color: theme.palette.info.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.fontSize.cardButtonSm,
    },
  },
}))

const SignInTitle = () => {
  const classes = useStyles()
  return (
    <div className={classes.title}>
      <img className={classes.logo} src={logo} alt="footerlogo" />
    </div>
  )
}

export default SignInTitle
