import { UPDATE_SHIP_PAGING_DATA } from '../../../actions'

/**
 * @param {Object} [state={}] 現在のページ
 * @param {Object} action 現在のページの更新に必要な値
 * @return {Object} 現在ページ
 */
const Paging = (state = {}, action) => {
  switch (action.type) {
    //項目表示件数
    case UPDATE_SHIP_PAGING_DATA:
      return { page: action.page }
    default:
      return state
  }
}

export default Paging
