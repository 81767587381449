import React, { useContext } from 'react'
import { Grid, makeStyles } from '@material-ui/core'

import AppContext from '../../../../../contexts/AppContext'

const useStyles = makeStyles((theme) => ({
  text: theme.textNormalBLK,
}))

/**
 * 選択した混載業者の3桁コードの/区切りを作成
 * @param {Array} selectFwdrArr 選択した混載業者配列
 * @param {String} isEditMode 画面が入力/確認どちらなのか
 * @return {String} /で繋いだ混載業者3桁コードの文字列
 */
const createSelectFwdrStr = (selectFwdrArr, isEditMode) =>
  selectFwdrArr
    .map(({ fwdrthreeCode, delFlg }) => {
      if (isEditMode) {
        // 入力中の場合削除されたFwdrは表示しない
        return !delFlg && fwdrthreeCode
      } else {
        // それ以外(確認)の場合は全てのFwdrを表示
        return fwdrthreeCode
      }
    })
    .filter((code) => code)
    .join('/')

/**
 * @return {JSX} SetForwarder混載業者の3桁コードコンポーネント
 */
const SelectSetForwarderBidInfo = () => {
  const { state } = useContext(AppContext)
  const { selectFwdrArr } = state.SetFwdr
  const { PageData } = state

  const { bidStatusId } = PageData.bidStatusObj ?? {}
  // ステータスが未設定 → 新規追加or更新のどちらか
  const isEditMode = bidStatusId === null

  const classes = useStyles()

  return (
    <Grid container item alignItems="center" justify="flex-start" xs={10}>
      <Grid item className={classes.text}>
        {createSelectFwdrStr(selectFwdrArr, isEditMode)}
      </Grid>
    </Grid>
  )
}

export default SelectSetForwarderBidInfo
