import { TABLES } from '../../../actions'
import CommonFunc from '../../../components/common/function/CommonFunc'
import ConstantsLightFwdrSearchResult from '../../../components/pages/fwdr/LightFwdrSearchResult/ConstantsLightFwdrSearchResult'
import CommonCalc from '../../../components/common/function/CommonCalc'
import moment from 'moment'
import Common from '../../../constants/Common'

/** @type {Object} 初期表示のテーブル */
const initTableData = {
  initData: [],
  showData: [[]],
  isNoResult: true,
}

/**
 * フライト番号を取得
 * @param {Object} routes DBから受け取ったデータ
 * @returns {Object} フライト番号
 */
const convertFlightNumber = (routes) => {
  if (routes.length === 3) {
    return `${routes[0].flightNumber}/${routes[1].flightNumber}/${routes[2].flightNumber}`
  } else if (routes.length === 2) {
    return `${routes[0].flightNumber}/${routes[1].flightNumber}`
  } else {
    return `${routes[0].flightNumber}`
  }
}

/**
 * 所要日数を取得
 * @param {String} times 所要日数
 * @returns {Object} 所要日数
 */
const getDays = (times) => {
  const totalDays = times.reduce(
    (acc, v) => acc + parseInt(v.time.replace('Days', '').split('-')[1]),
    0
  )
  if (totalDays <= 2) {
    return { time: '1-2Days', id: 1 }
  } else if (totalDays <= 4) {
    return { time: '3-4Days', id: 2 }
  } else if (totalDays <= 6) {
    return { time: '5-6Days', id: 3 }
  } else {
    return { time: '7Days or more', id: 4 }
  }
}

/**
 * レートを取得
 * @param {Object} costData DBから受け取ったデータ
 * @param {Object} cargoDetailArr 貨物サイズ情報
 * @param {String} fuelSurcharge 燃油サーチャージ
 * @param {String} mode ワード
 * @returns {Object} レート
 */
const getCost = (costData, cargoDetailArr, fuelSurcharge, mode) => {
  const totalCargeableWgt = cargoDetailArr.reduce(
    (acc, cargo) => acc + parseFloat(cargo.chargeableWgt),
    0
  )
  const rate = CommonCalc.rate(
    totalCargeableWgt,
    costData.normal,
    costData.min,
    costData.light,
    costData.medium,
    costData.large,
    costData.max,
    false
  )

  // fscとminRateを数値に変換
  const minRateNum = parseFloat(costData.minRate)
  const fuelSurChargeNum = fuelSurcharge ? parseFloat(fuelSurcharge) : 0.0

  // A/F*chargeableWeightとminRateの大きい方を料金にする
  const airFreight = Math.max(rate * totalCargeableWgt, minRateNum)
  // TotalA/Fを計算
  const totalAirFreight = airFreight + fuelSurChargeNum * totalCargeableWgt

  if (mode === 'rate') {
    // 適用レートを返す
    return rate !== null ? rate.toString() : false
  } else if (mode === 'disabled') {
    // SelectFlightボタンの非活性判定を返す
    // TotalA/Fの整数部分が10桁より大きい場合は非活性
    const flooredTotalAirFreight = Math.floor(totalAirFreight)
    return flooredTotalAirFreight.toString().length > 10
  } else {
    // TotalA/Fを返す
    // コンマを付けて返す
    return CommonCalc.addComma(totalAirFreight, true)
  }
}

/**
 * テーブルに表示する形式に変更
 * @param {Array} tableData - apiから受け取ったテーブルデータ
 * @param {Array} cargoDetailArr - 貨物サイズデータ
 * @return {Array} - テーブルに表示する形式のデータ
 */
const editData = (tableData, cargoDetailArr) =>
  tableData
    .map((data) => {
      const {
        route,
        flight,
        dstAirportCode,
        orgAirportCode,
        viaOneAirportCode,
        viaTwoAirportCode,
        fuelSurcharge,
        allInRate,
      } = data
      const flightNumber = convertFlightNumber(route)

      return flight.map((flightData) => {
        const { takeoffDate, etd, eta, ...rest } = flightData
        const rate = getCost(route[0], cargoDetailArr, fuelSurcharge, 'rate')
        const cost = getCost(route[0], cargoDetailArr, fuelSurcharge, 'cost')
        return {
          ...rest,
          takeoffDate,
          etd,
          eta,
          dstAirportCode,
          orgAirportCode,
          viaOneAirportCode,
          viaTwoAirportCode,
          fuelSurcharge,
          allInRate,
          flightNumber,
          icon: route[0].icon,
          currency: route[0].currency,
          laneId: route[0].laneId,
          large: route[0].large,
          light: route[0].light,
          max: route[0].max,
          medium: route[0].medium,
          min: route[0].min,
          minRate: route[0].minRate,
          normal: route[0].normal,
          rate,
          cost,
          days: getDays(route),
          sortFlight: `${takeoffDate} ${etd}`,
          disabled: getCost(
            route[0],
            cargoDetailArr,
            fuelSurcharge,
            'disabled'
          ),
          displayTakeoffDate: moment(takeoffDate).format('MMM. DD, yyyy'),
          displayEtdEta: `${etd.slice(0, -3)} - ${eta.slice(0, -3)}`,
          displayTransitTime: `N + ${getDays(route).time}`,
          displayOrgDst: [
            orgAirportCode,
            viaOneAirportCode,
            viaTwoAirportCode,
            dstAirportCode,
          ]
            .filter((v) => v)
            .join('/'),
          displayAirFreight: `A/F: ${route[0].currency} ${CommonCalc.addComma(rate, true)} / kg`,
          displayFsc: `FSC: ${route[0].currency} ${CommonCalc.addComma(fuelSurcharge, true)} / kg`,
          displayAllInRate:
            allInRate === Common.ALL_IN_RATE.ON ? '*All-in rate' : '',
          displayTotalAirFreight: `Total A/F: ${route[0].currency} ${cost}`,
        }
      })
    })
    .flat()

/**
 * テーブルデータを保持
 * @param {Object} action action
 * @param {Object[]} action.tableData 登録するテーブルデータ
 * @param {String} action.search 検索ワード
 * @param {Object} state state
 * @returns {Object} テーブルデータ
 */
const setTableData = (action, state) => {
  const {
    tableData,
    cargoDetailArr,
    search = '',
    sort = '',
    defaultSearchWord = '',
  } = action
  const { PAGE_AMOUNT, SORT_ITEM_ARR } = ConstantsLightFwdrSearchResult

  // sort名から並び替えの対象となるkeyを取得
  const { sortKey } = SORT_ITEM_ARR.find(({ sortTerm }) => sortTerm === sort)

  /** @type {Array} 表示用に編集後のデータ */
  const editedData = tableData
    ? editData(tableData, cargoDetailArr)
    : state.initData
  // データを検索して結果をページごとに分割する
  const { showData, initData } = CommonFunc.searchSortDefaultData(
    editedData,
    defaultSearchWord,
    search,
    sort,
    sortKey,
    PAGE_AMOUNT
  )

  /** @type {Boolean} NoResult判定 */
  const isNoResult = showData[0].length <= 0

  return {
    ...state,
    showData,
    initData,
    isNoResult,
  }
}

/**
 * テーブルデータを保持
 * @param { Object } [state = initTableData] テーブルデータ
 * @param { Object } action action
 * @return { Object } テーブルデータ
 */
const Tables = (state = initTableData, action) => {
  switch (action.type) {
    case TABLES.SET:
      return setTableData(action, state)
    default:
      return state
  }
}

export default Tables

export { initTableData }
