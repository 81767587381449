import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

import { SEARCH_SORT, TABLES, PAGING } from '../../actions'
import AppContext from '../../contexts/AppContext'
import Sort from '../common/sort/Sort'
import SearchBox from '../common/searchForm/SearchBox'
import CheckBox from '../common/checkBox/CheckBox'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '1rem',
  },
  searchResults: {
    ...theme.textNormalBLK,
    marginLeft: '1rem',
    marginBottom: '0.5rem',
  },
}))
/**
 * useContextのstateのSearchSortを更新
 * @param {String} searchStr 検索ワード
 * @param {String} sortStr ソート
 * @param {Boolean} defaultChecked デフォルト欄のチェック状態
 * @param {Function} dispatch useContextのdispatch
 * @return {void}
 */
const updateSearchSort = (searchStr, sortStr, defaultChecked, dispatch) => {
  dispatch({
    type: SEARCH_SORT.SET,
    search: searchStr,
    sort: sortStr,
    defaultChecked,
  })
}
/**
 * useContextのstateのTablesを更新
 * @param {String} searchStr 検索ワード
 * @param {String} sortStr ソート
 * @param {String} defaultSearchWord デフォルト欄チェック時の検索ワード
 * @param {Function} dispatch useContextのdispatch
 * @param {*} i18n useTranslationのi18n
 * @return {void}
 */
const updateTables = (
  searchStr,
  sortStr,
  defaultSearchWord,
  dispatch,
  i18n
) => {
  dispatch({
    type: TABLES.SET,
    search: searchStr,
    sort: sortStr,
    defaultSearchWord,
    i18n,
  })
}
/**
 * useContextのstateのPageを初期値に更新
 * @param {Function} dispatch useContextのdispatch
 * @return {void}
 */
const updateInitPage = (dispatch) => {
  dispatch({
    type: PAGING.SET,
    page: 1,
  })
}

/**
 * useContextのstateを更新
 * @param {String} searchStr 検索ワード
 * @param {String} sortStr ソート
 * @param {Boolean} defaultChecked デフォルト欄のチェック状態
 * @param {String} defaultSearchWord デフォルト欄チェック時の検索ワード
 * @param {Function} dispatch useContextのdispatch
 * @param {*} i18n useTranslationのi18n
 * @return {void}
 */
const updateState = (
  searchStr,
  sortStr,
  defaultChecked,
  defaultSearchWord,
  dispatch,
  i18n
) => {
  const searchWord = defaultChecked ? defaultSearchWord : ''
  updateSearchSort(searchStr, sortStr, defaultChecked, dispatch)
  updateTables(searchStr, sortStr, searchWord, dispatch, i18n)
  updateInitPage(dispatch)
}

/**
 * サーチボックスとソート
 * @param {*} props 下記要素が必要
 * @param {Array} [sortItemArr=[]] sortTerm, wordKeyの要素の含まれるオブジェクトの配列
 * @param {String} [placeholder='Key words']  検索欄に表示するplaceholder(任意)
 * @param {Boolean} [noGridContainer=undefined] 検索欄のGridContainerを無しににする
 * @returns {JSX.Element} - サーチボックスとソートコンポーネント
 */
const SearchSort = (props) => {
  const classes = useStyles()
  const { t, i18n } = useTranslation()
  const { state, dispatch } = useContext(AppContext)
  // 入力・反映済みの値はcurrent、新規値はnewで分類
  const currentSearchStr = state.SearchSort.search
  const currentPageNum = state.Paging
  const currentSortStr = state.SearchSort.sort
  const { defaultChecked, defaultSearchWord } = state.SearchSort

  const [newSearchStr, setNewSearchStr] = useState('')

  /**
   * searchの入力ボックスの値更新時のイベント
   * @param {Event} event changeイベントのevent
   * @return {void}
   */
  const searchChange = (event) => setNewSearchStr(event.target.value)

  /**
   * searchの検索ボタン押下時のイベント
   * @return {void}
   */
  const clickSearch = () =>
    updateState(
      newSearchStr,
      currentSortStr,
      defaultChecked,
      defaultSearchWord,
      dispatch,
      i18n
    )

  /**
   * searchの✕ボタン押下時のイベント
   * @return {void}
   */
  const clickReset = () => {
    setNewSearchStr('')
    updateState(
      '',
      currentSortStr,
      defaultChecked,
      defaultSearchWord,
      dispatch,
      i18n
    )
  }

  /**
   * sort変更時のイベント
   * @param {Event} event changeイベントのevent
   * @return {void}
   */
  const changeSort = (event) => {
    const newSort = event.target.value
    updateState(
      currentSearchStr,
      newSort,
      defaultChecked,
      defaultSearchWord,
      dispatch,
      i18n
    )
  }

  /**
   * デフォルト欄変更時のイベント
   * @param {Event} event changeイベントのevent
   * @returns {void}
   */
  const changeDefaultCheck = (event) => {
    const checked = event.target.checked
    updateState(
      currentSearchStr,
      currentSortStr,
      checked,
      defaultSearchWord,
      dispatch,
      i18n
    )
  }

  /** @type {Array} propsから作成したソート一覧 */
  const sortItemArr = (props.sortItemArr ?? []).map(
    ({ sortTerm, wordKey }) => ({ value: sortTerm, word: t(wordKey) })
  )

  /** @type {Object} sortコンポーネントに渡したいものをまとめる */
  const sortFactors = {
    sortItems: sortItemArr,
    value: currentSortStr,
    changeEvent: changeSort,
  }

  /** @type {Object} searchBoxコンポーネントに渡したいものをまとめる */
  const searchFactors = {
    placeholder: props.placeholder ?? t('D301V0188'),
    clickEvent: clickSearch,
    changeEvent: searchChange,
    resetClick: clickReset,
    value: newSearchStr,
  }

  useEffect(() => {
    // ソート、ページ移動時に検索ボックス内を現在の検索している値に戻す
    setNewSearchStr(currentSearchStr)
    if (currentPageNum <= 0) {
      updateInitPage(dispatch) //ページが0以下に設定された場合は1にする
    }
  }, [currentPageNum, currentSortStr]) // eslint-disable-line

  /**
   * 検索欄を作成
   * @param {Object} className 適用するクラス名
   * @returns {JSX} 検索欄
   */
  const createSearchBox = (className) => (
    <>
      <Grid container item md={4} xs={12} className={className}>
        <SearchBox searchfactors={searchFactors} />
      </Grid>
      {state.SearchSort.defaultStr !== '' ? createDefaultCheckbox() : null}
    </>
  )

  /**
   * デフォルトチェック欄を作成
   * @returns {JSX} デフォルトチェック欄
   */
  const createDefaultCheckbox = () => (
    <Grid item md={5}>
      <CheckBox
        onChange={changeDefaultCheck}
        checked={defaultChecked}
        label={state.SearchSort.defaultStr}
      />
    </Grid>
  )

  /**
   * ソート欄を作成
   * @returns {JSX} ソート欄
   */
  const createSort = () => (
    <Grid item md={3} xs={12}>
      <Sort sortfactors={sortFactors} />
    </Grid>
  )

  /**
   * 検索欄、ソート欄を作成
   * @returns {JSX} 検索欄、ソート欄
   */
  const createSearchSort = () => (
    <Grid container justify="center" className={classes.root}>
      <Grid
        container
        item
        md={10}
        xs={10}
        justify="space-between"
        alignItems="flex-end"
      >
        {createSearchBox()}
        {sortItemArr.length > 0 ? createSort() : null}
      </Grid>
    </Grid>
  )

  return props.noGridContainer
    ? createSearchBox(classes.root)
    : createSearchSort()
}

SearchSort.propTypes = {
  sortItemArr: PropTypes.arrayOf(
    PropTypes.shape({
      sortTerm: PropTypes.string.isRequired,
      wordKey: PropTypes.string.isRequired,
      sortKey: PropTypes.string,
      convertFunc: PropTypes.func,
    })
  ),
  placeholder: PropTypes.string,
  noGridContainer: PropTypes.bool,
}

export default SearchSort
