import React from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'

import BackButtonSelectForwarder from './BackButtonSelectForwarder'
import OkButtonSelectForwarder from './OkButtonSelectForwarder'

/**
 * @param {Object} props - 以下
 * @param {Array} fwdrArr - 混載業者情報
 * @return {JSX} ボタンコンポーネント
 */
const ButtonsSelectForwarder = (props) => {
  return (
    <Grid container justify="center">
      <Grid container item xs={10} justify="center" spacing={4}>
        <Grid item>
          <BackButtonSelectForwarder fwdrArr={props.fwdrArr} />
        </Grid>
        <Grid item>
          <OkButtonSelectForwarder fwdrArr={props.fwdrArr} />
        </Grid>
      </Grid>
    </Grid>
  )
}

ButtonsSelectForwarder.propTypes = {
  fwdrArr: PropTypes.object.isRequired,
}

export default ButtonsSelectForwarder
