const ApiPaths = {
  MASTER_JH: {
    TERMS: '/api/any/terms',
    COMMODITY: '/api/any/commodity',
    SHC_CODE: '/api/any/shcCode',
    TT: '/api/any/tt',
    CURRENCY: '/api/any/currency',
    WEIGHT_UNIT: '/api/any/weightUnit',
    COMPANY_INFO: '/api/jh/jhCompanyInfo',
    MY_PROFILE: '/api/jh/jhMyProfile',
    ACCOUNT_INFO: '/api/jh/jhAccountInfo',
    PER: '/api/any/ocPers',
    AWBSTATUS: '/api/any/awbStatus',
    CITY_CODE: '/api/any/cityCode',
    AIRPORT: '/api/any/airport',
    STATUS: '/api/any/status',
    COUNTRY_CODE: '/api/any/countryCode',
    FLIGHT_TYPE: '/api/any/flightType',
  },
  MASTER_SHIPPER: {
    CONSIGNEE_ADDRESS: '/api/ship/consigneeAddress',
    ACCOUNT_INFO: '/api/ship/shipAccountInfo',
    SHIP_MY_PROFILE: '/api/ship/shipMyProfile',
    MY_PROFILE: '/api/ship/shipMyProfile',
    COMPANY_INFO: '/api/ship/shipCompanyInfo',
    SHIP_COMPANY_INFO: '/api/ship/shipCompanyInfo',
    SHIP_LIST: '/api/ship/shipList',
    SHIP_PICKUP_LOCATION: '/api/ship/shipPickUpLocation',
  },
  COMMON_API: {
    AIRPORT: '/api/any/airport',
    CARRIER_TRADER: '/api/any/carrierTrader',
    CONTOUR: '/api/any/contour',
    ULD_TYPE: '/api/any/uldType',
    DOCUMENT: '/api/any/document',
    COUNTRY_CODE: '/api/any/countryCode',
    UTC: '/api/any/utc',
    ULD_CARGO_INFO: '/api/any/uldCargoInfo',
    REGISTER_FORWARDER_NAME: '/api/any/registerForwarderName',
    LIGHT_FWDR_PLAN: '/api/any/lightFwdrPlan',
    LIGHT_CAR_PLAN: '/api/any/lightCarPlan',
    LIGHT_PLAN_DATA: '/api/any/lightPlanData',
    LIGHT_TOTAL_ACCOUNT: '/api/any/lightTotalAccount',
  },
  MASTER_FWDR: {
    FWDR_NAME: '/api/any/fwdrName',
    MY_PROFILE: '/api/fwdr/fwdrMyProfile',
    COMPANY_INFO: '/api/fwdr/fwdrCompanyInfo',
    ADD_PROFIT: '/api/fwdr/addProfit',
    ORIGIN_CHARGES: '/api/fwdr/originCharges',
    DST_CHARGES: '/api/fwdr/dstCharges',
    FWDR_COMPANY_INFO: '/api/fwdr/fwdrCompanyInfo',
    ACCOUNT_INFO: '/api/fwdr/fwdrAccountInfo',
    AWBMGMT_MAWB: 'api/fwdr/fwdrMawb',
    AWBMGMT_HAWB: 'api/fwdr/fwdrHawb',
    OTHER_CHARGES: 'api/fwdr/otherCharges',
    AWB: '/api/fwdr/fwdrMasterAwb',
    AWBMGMT_CNSL: 'api/fwdr/cnslCenterMgmt',
    SERVICE_OFFICE: '/api/fwdr/serviceOffice',
    FWDR_BRANCH: 'api/fwdr/fwdrBranch',
    ADD_PROFIT_CNSL_CENTER: '/api/fwdr/addProfitCnslCenter',
    ADD_PROFIT_BRANCH: '/api/fwdr/addProfitBranch',
    ADD_PROFIT_CNSL_BRANCH: '/api/fwdr/addProfitCnslBranch',
    FWDR_GROUP: '/api/fwdr/fwdrGroup',
    HAWB_CREATE: '/api/fwdr/fwdrHouseAwbCreate',
    AWBMGMT_CNSL_BRANCH_MAWB: '/api/fwdr/cnslBranchMawb',
    LANE_TARIFF: '/api/fwdr/laneTalif',
    LIGHT_FWDR_COMPANY_INFO: '/api/fwdr/lightFwdrCompanyInfo',
    LIGHT_ACCOUNT_INFO: '/api/fwdr/lightFwdrAccountInfo',
    LIGHT_MY_PROFILE: '/api/fwdr/lightFwdrMyProfile',
    LIGHT_SEARCH_RESULT: '/api/fwdr/lightSearchResult',
  },
  MASTER_CAR: {
    DEFAULT_AIRPORT: '/api/car/carDefaultAirPort',
    FORWARDER: '/api/car/forwarder',
    MY_PROFILE: '/api/car/carMyProfile',
    ACCOUNT_INFO: '/api/car/carAccountInfo',
    MAWB: '/api/car/carMawb',
    VOID_MAWB: '/api/car/carVoidMawb',
    CAR_AIR_FREIGHT: '/api/car/airFreight',
    CAR_AIR_CRAFT_INFO: '/api/car/aircraftInfo',
    FUEL_SURCHARGE: '/api/car/fuelSurcharge',
    COMPANY_INFO: '/api/car/carCompanyInfo',
    FLIGHT_INFO: '/api/car/flightInfo',
    AIRCRAFT_LIST: '/api/car/aircraftList',
    AIR_CRAFT: '/api/car/aircraftInfo',
    FLIGHT_MANAGEMENT: 'api/car/flightManagement',
    CAR_AIR_CRAFT_DATA: 'api/car/carAircraft',
    PREFIX: '/api/car/carPrefix',
    LANE_FORWARDER: '/api/car/laneForwarder',
    COMAT: '/api/car/comat',
    AIR_FREIGHT_FLIGHT_INFO: 'api/car/airFreightFlightInfo',
    LIGHT_FORWARDER: 'api/car/lightForwarder',
    LIGHT_FLIGHT_MANAGEMENT: '/api/car/lightFlightManagement',
    LIGHT_FLIGHT_ICON: '/api/car/lightFlightIcon',
    LIGHT_GSA: '/api/car/lightGsa',
    LIGHT_AIR_FREIGHT: 'api/car/lightAirFreight',
    LIGHT_CAR_COMPANY_INFO: '/api/car/lightCarCompanyInfo',
    LIGHT_MY_PROFILE: '/api/car/lightCarMyProfile',
    LIGHT_ACCOUNT_INFO: '/api/car/lightCarAccountInfo',
  },
  MASTER_FORWARDER: {
    ORIGIN_CHARGES: '/api/originCharges',
    DST_CHARGES: '/api/dstCharges',
  },
  SHIPPER: {
    SEARCH_RESULT: '/api/ship/searchResult',
    AIRPORT: '/api/ship/airport',
    FAVORITE_INFO: '/api/ship/favoriteInfo',
    TRACE_CARGO_INFO: '/api/ship/shipTraceCargoInfo',
    HISTORY: '/api/ship/shipHistory',
    SHIP_FLIGHT: '/api/ship/shipFlight',
    SHIP_DOCUMENT: '/api/ship/shipDocument',
    CARGO_TRACE: '/api/ship/shipCargoTrace',
    REGIST_FAVORITE_CONSIGNEE: '/api/ship/registFavoriteConsignee',
    BOOKING_REQUEST: '/api/ship/bookingRequest',
    QUOTE_INFO: '/api/ship/quoteInfo',
    CANCEL_BOOKING: '/api/ship/cancelBooking',
    DOCUMENT: '/api/any/document',
    ROUTE_INFO: '/api/ship/routeInfo',
    QUOTE_APPROVE: '/api/ship/quoteApprove',
    INVOICE_INFO: '/api/ship/invoiceInfo',
    PACKING_LIST_INFO: '/api/ship/packingListInfo',
    INVOICE_PACKING_LIST_PDF: '/api/ship/invoicePackingListPdf',
    UPDATE_SPECIAL_NOTES: 'api/ship/updateSpecialNotes',
    SHIPPER_BID_DETAILS: '/api/ship/shipperBidDetails',
    BID_MGMT: '/api/ship/bidMgmt',
    BID_MGMT_NOTIFY: '/api/ship/bidMgmtNotify',
    BID_MGMT_DRAFT: '/api/ship/bidMgmtDraft',
    BID_INFO: '/api/ship/bidInfo',
    BID_SELECT_FWDR: '/api/ship/bidSelectFwdr',
    BID_DETAILS: '/api/ship/bidDetails',
    BID_UPDATED_AT: '/api/ship/bidUpdatedAt',
    BID_STATUS: '/api/ship/bidStatus',
    CONFIRMATION: '/api/ship/confirmation',
    UPDATEDAT_CHECK_BID: '/api/ship/updatedAtCheckBid',
    ALREADY_READ_NOTIFY_BID: '/api/ship/alreadyReadNotifyBid',
  },
  CARRIER: {
    ULD_NO: '/api/car/uldNo',
    RELATED_MAWB_INFO: '/api/car/relatedMawbInfo',
    NOTOC_INFO: '/api/car/notocInfo',
    SHIPPING_DETAILS: '/api/car/shippingDetails',
    MEASURE_WEIGHT: '/api/car/measureWeight',
    ROUTING: '/api/car/routing',
    OFFER_AIR_FREIGHT: '/api/car/offerAirFreight',
    CARGO_MANIFEST: '/api/car/cargoManifest',
    CAR_DOCUMENT: '/api/car/carDocument',
    SPACE_CONTROL: '/api/car/spaceControl',
    FLIGHT_NUMBER: '/api/car/flightNumber',
    UPDATE_STATUS: '/api/car/carUpdateStatus',
    CASS_DATE_PERIOD: '/api/car/cassDataPeriod',
    CASS_INFO_HISTORY: '/api/car/cassInfoHistory',
    CASS_DATA: '/api/car/cassData',
    CREATE_CASS_FILE: 'api/car/createCassFile',
    SET_FLIGHT: '/api/car/setFlight',
    APPROVAL_SPACE_CONFIRM: '/api/car/approvalSpaceConfirm',
    MESSAGE: '/api/car/message',
    SPACE_CHECK: '/api/car/spaceCheck',
    CARGO_INFO_LIST: '/api/car/cargoInfoList',
    UPDATE_FLIGHT_STATUS: '/api/car/updateFlightStatus',
    CARGO_MANIFEST_PDF: '/api/car/cargoManifestPdf',
    NOTOC_PDF: '/api/car/notocPdf',
    AWB_DETAILS: '/api/car/awbDetails',
    HOUSE_MANIFEST_PDF: '/api/car/houseManifestPdf',
    REGIST_CASS_DATA_DETAILS: '/api/car/registCassDataDetails',
    NAME_OF_REPRESENTATIVE: '/api/car/nameOfRepresentative',
    NOTIFY_CASS_DOWNLOAD: '/api/car/notifyCassDownload',
  },
  MASTER_CARRIER: {
    CAR_ACCOUNT_INFO: '/api/car/carAccountInfo',
    COMPANY_INFO: '/api/car/carCompanyInfo',
    CAR_NAME: 'api/any/carrierName',
    CAR_AIRPORT_CODE: 'api/any/airport',
    CAR_BRANCH: 'api/any/cityCode',
    CAR_MAWB: '/api/car/carMawb',
    CAR_COMPANY_INFO: '/api/car/carCompanyInfo',
    CAR_TWOCODE: '/api/car/carTwoCode',
  },
  FORWARDER: {
    HAWB_STOCK: '/api/fwdr/hawbStock',
    MAWB_STOCK: '/api/fwdr/mawbStock',
    PICKUP_INFO: '/api/fwdr/pickUpInfo',
    AIRPLANE_FLIGHT: '/api/fwdr/airplaneFlight',
    CARGO_SIZE: '/api/fwdr/cargoSize',
    CONSOL_INFO: '/api/fwdr/consolInfo',
    REJECT_PICKUP: '/api/fwdr/rejectPickUp',
    REGIST_COMPLETE_BOOKING: '/api/fwdr/registCompleteBooking',
    OWN_BRANCH_LIST: 'api/fwdr/ownBranchList',
    BOOKING_LIST: '/api/fwdr/bookingList',
    DEFAULT_AIRPORT: '/api/fwdr/fwdrDefaultAirPort',
    DEFAULT_BRANCH: '/api/fwdr/fwdrDefaultBranch',
    CURRENCY: '/api/any/currency',
    SIGN: '/api/fwdr/fwdrMyProfile',
    DGD: '/api/any/dgd',
    PACKING_TYPE: '/api/fwdr/capacity',
    UNIT: '/api/any/weightUnit',
    ORIGIN_CHARGES: '/api/fwdr/originCharges',
    DST_CHARGES: '/api/fwdr/dstCharges',
    ADD_PROFIT: '/api/fwdr/addProfit',
    CARGO_STRAIGHT_MIX: '/api/fwdr/cargoStraightMix',
    HISTORY: '/api/any/carFwdrHistory',
    FWDR_TRACE_CARGO_INFO: '/api/fwdr/fwdrTraceCargoInfo',
    FWDR_TRACE_CARGO_INFO_BEFORE_PENDING:
      '/api/fwdr/fwdrTraceCargoInfoBeforePending',
    CAR_FWDR_FLIGHT: '/api/any/carFwdrFlight',
    STATUS: '/api/any/status',
    CARGO_TRACE: '/api/fwdr/fwdrCargoTrace',
    FWDR_DEFAULT_AIRPORT: '/api/fwdr/fwdrDefaultAirport',
    DOCS_PREPARATION: '/api/fwdr/docsPreparation',
    CANCEL_BOOKING: '/api/fwdr/cancelBooking',
    CANCEL_BOOKING_BEFORE_PENDING: '/api/fwdr/cancelBookingBeforePending',
    UPDATE_STATUS: '/api/fwdr/fwdrUpdateStatus',
    UPDATE_STATUS_BEFORE_PENDING: '/api/fwdr/fwdrUpdateStatusBeforePending',
    FWDR_DOCUMENTS: '/api/fwdr/fwdrDocuments',
    DOCUMENT: '/api/fwdr/fwdrDocuments',
    QUOTE_OFFERING: '/api/fwdr/quoteOffering',
    FUEL_SURCHARGE_BY_RESION: '/api/fwdr/fuelSurchargeByResion',
    HOUSE_AWB: '/api/fwdr/houseAwb',
    QUOTE_INFO: '/api/fwdr/quoteInfo',
    MASTER_AWB: '/api/fwdr/masterAwb',
    REGIST_FWDR_DOCUMENTS: '/api/fwdr/registFwdrDocuments',
    COMPLETE_BOOKING_AIR_FREIGHT: '/api/fwdr/completeBookingAirFreight',
    REJECT_OFFER: '/api/fwdr/rejectOffer',
    MASTER_OTHER_CHARGES: '/api/fwdr/masterOtherCharges',
    UNIT_DGD: '/api/fwdr/unitDgd',
    OC_PER: '/api/any/ocPers',
    FWDR_DOCUMENT: '/api/fwdr/fwdrDocument',
    HISTORY_WITH_CARGO: '/api/any/carFwdrHistoryWithCargo',
    CAR_FWDR_FLIGHT_WITH_CARGO: '/api/any/carFwdrFlightWithCargo',
    FWDR_CARGO_TRACE_DATA: '/api/fwdr/fwdrCargoTraceData',
    APPROVE_CONSOL_REQUEST: '/api/fwdr/approveConsolRequest',
    REJECT_CONSOL_REQUEST: '/api/fwdr/rejectConsolRequest',
    REQUEST_CONSOL: '/api/fwdr/requestConsol',
    UPDATE_DESTINATION_BRANCH: '/api/fwdr/updateDestinationBranch',
    FWDR_DOCUMENTS_BEFORE_PENDING: '/api/fwdr/fwdrDocumentsBeforePending',
    SPECIAL_NOTES: '/api/fwdr/specialNotes',
    MAWB_RESET: '/api/fwdr/mawbReset',
    BID_MGMT: '/api/fwdr/bidMgmt',
    BID_MGMT_NOTIFY: '/api/fwdr/bidMgmtNotify',
    BID_MGMT_DRAFT: '/api/fwdr/bidMgmtDraft',
    BID_INFO: '/api/fwdr/bidInfo',
    BID_DETAILS: '/api/fwdr/bidDetails',
    BID_UPDATED_AT: '/api/fwdr/bidUpdatedAt',
    BID_STATUS: '/api/fwdr/bidStatus',
    FWDR_BID_DETAILS: '/api/fwdr/fwdrBidDetails',
    CONFIRMATION: '/api/fwdr/confirmation',
    LIGHT_BOOKING_LIST: '/api/fwdr/lightBookingList',
    LIGHT_BOOKING_DETAILS: '/api/fwdr/lightBookingDetails',
    UPDATEDAT_CHECK_BID: '/api/fwdr/updatedAtCheckBid',
    ALREADY_READ_NOTIFY_BID: '/api/fwdr/alreadyReadNotifyBid',
  },
  AIRLINE: {
    HISTORY: '/api/any/carFwdrHistory',
    CARGO_TARCE: '/api/car/carCargoTrace',
    CAR_DEFAULT_AIRPORT: '/api/car/carDefaultAirport',
    CAR_FWDR_FLIGHT: '/api/any/carFwdrFlight',
    CAR_DOCUMENT: '/api/car/carDocument',
    CAR_TRACE_CRAGO_INFO: '/api/car/carTraceCargoInfo',
    CANCEL_BOOKING: '/api/car/cancelBooking',
    LIGHT_BOOKING_LIST: '/api/car/lightBookingList',
    LIGHT_BOOKING_DETAILS: '/api/car/lightBookingDetails',
  },
  AUTHENTICATION: {
    SIGN_IN: '/api/signIn',
    CONFIRM_SIGN_IN: '/api/confirmSignIn',
    LIGHT_SHIP_SIGN_UP_REQUEST: '/api/lightShipSignUpRequest',
    REISSUE_PASSWORD: '/api/reissuePassword',
    CARRIER_NAME: '/api/any/carrierName',
    CARRIER_NAME_WITHOUT_GSA: '/api/any/carrierNameWithoutGsa',
    LIGHT_CAR_SIGN_UP_REQUEST: '/api/lightCarSignUpRequest',
    CITY_CODE: '/api/any/cityCode',
    FWDR_NAME: '/api/any/fwdrName',
    AIRPORT: '/api/any/airport',
    LIGHT_FWDR_SIGN_UP_REQUEST: '/api/lightFwdrSignUpRequest',
    DELETE_ACCOUNT_REQUEST: 'api/any/deleteAccountRequest',
  },
  NOTIFICATIONS: {
    POPUP_NOTIFICATIONS: '/api/any/popupNotifications',
    NOTIFICATIONS: '/api/any/notifications',
    NOTIFICATION_INFO: '/api/any/notificationInfo',
  },
  JH: {
    CLIENT_SIGNUP_INFO: '/api/jh/clientSignUpInfo',
    CLIENT_INFO: './api/jh/clientInfo',
    WITHDRAWAL_INFO: '/api/jh/withdrawalInfo',
    CLIENT_COUNT: '/api/jh/clientCount',
    APPROVE_WITHDRAWAL: '/api/jh/approveWithdrawal',
    REJECT_WITHDRAWAL: '/api/jh/rejectWithdrawal',
    LIGHT_CLIENT_SIGNUP_INFO: '/api/jh/lightClientSignUpInfo',
    LIGHT_APPROVE_APPLICATION: '/api/jh/lightApproveApplication',
    LIGHT_REJECT_APPLICATION: '/api/jh/lightRejectApplication',
    LIGHT_WITHDRAWAL_INFO: '/api/jh/lightWithdrawalInfo',
    LIGHT_APPROVE_WITHDRAWAL: '/api/jh/lightApproveWithdrawal',
    LIGHT_REJECT_WITHDRAWAL: '/api/jh/lightRejectWithdrawal',
    LIGHT_CLIENT_INFO: '/api/jh/lightClientInfo',
  },
}

export default ApiPaths
