import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'

import Theme from '../../common/theme/Theme'
import AppContext from '../../../contexts/AppContext'
import { LIGHT_REGISTER_NEW_ACCOUNT } from '../../../actions'
import AuthenticationTableContainer from '../../common/table/AuthenticationTableContainer'
import Common from '../../../constants/Common'

const useStyles = makeStyles(() => ({
  inputLabel: Theme.signup.inputLabel,
  root: Theme.signup.root,
  container: Theme.signup.container,
  autoComp: Theme.signup.autoComp,
}))

/**
 * @returns {JSX} - 会社を更新
 */
const CarrierNameSelect = () => {
  const { state, dispatch } = useContext(AppContext)
  const classes = useStyles()
  const { t } = useTranslation()

  return useMemo(() => {
    /**
     * 航空会社名を更新
     * @param {*} _e - event
     * @param {*} value - value
     * @returns {void}
     */
    const updateCarrierName = (_e, value) => {
      dispatch({
        type: LIGHT_REGISTER_NEW_ACCOUNT.CARRIER_NAME.UPDATE,
        value: value?.carrierNameId ?? '',
        gsaFlg: value?.gsaFlg ?? Common.GSA_FLG.CARRIER,
      })
      if (value?.gsaFlg) {
        // GSA
        dispatch({
          type: LIGHT_REGISTER_NEW_ACCOUNT.IATA_CASS_NO.GSA,
        })
        dispatch({
          type: LIGHT_REGISTER_NEW_ACCOUNT.DEPARTMENT.GSA,
        })
        dispatch({
          type: LIGHT_REGISTER_NEW_ACCOUNT.ICON.GSA,
        })
      } else {
        // 航空会社
        dispatch({
          type: LIGHT_REGISTER_NEW_ACCOUNT.IATA_CASS_NO.CARRIER,
        })
        dispatch({
          type: LIGHT_REGISTER_NEW_ACCOUNT.DEPARTMENT.CARRIER,
        })
        dispatch({
          type: LIGHT_REGISTER_NEW_ACCOUNT.ICON.CARRIER,
        })
      }
    }

    return (
      <AuthenticationTableContainer>
        <form noValidate>
          <Autocomplete
            options={state.SelectData.companyArr}
            getOptionLabel={(option) => option.companyName}
            className={`${classes.root} ${classes.autoComp}`}
            onChange={updateCarrierName}
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{
                  classes: {
                    root: classes.inputLabel,
                    focused: 'focused',
                    shrink: 'shrink',
                  },
                }}
                className={classes.root}
                label={t('D501V0012')}
                margin="normal"
                fullWidth
                required
                variant="outlined"
              />
            )}
          />
        </form>
      </AuthenticationTableContainer>
    )
  }, [
    dispatch,
    state.SelectData.companyArr,
    classes.root,
    classes.inputLabel,
    classes.autoComp,
    t,
  ])
}

export default CarrierNameSelect
