import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import { LIGHT_BOOKING_DETAILS } from '../../../../actions'
import XsmallButton from '../../../common/buttons/XsmallButton'

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
}))

/**
 * @param {Object} props 下記内容
 * @param {string} props.remarkName 値の名前
 * @param {string} props.remark Remarkの内容
 * @param {Function} props.dispatch dispatch
 * @param {string} props.placeholder プレースホルダー
 * @return {JSX} Remark欄のEditボタン
 */
const EditButtonRemarkLightBookingDetails = ({
  remarkName,
  remark,
  dispatch,
  placeholder,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  /**
   * ポップアップを開く
   * @returns {void} なし
   */
  const openPopup = () => {
    dispatch({
      type: LIGHT_BOOKING_DETAILS.REMARK_POPUP.OPEN,
      remark,
      remarkName,
      placeholder,
    })
  }

  return (
    <XsmallButton addclass={classes.button} onClick={openPopup}>
      {t('D301V0035')}
    </XsmallButton>
  )
}
EditButtonRemarkLightBookingDetails.propTypes = {
  remarkName: PropTypes.string.isRequired,
  remark: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
}
export default EditButtonRemarkLightBookingDetails
