import React, { useContext } from 'react'

import AppContext from '../../../../../contexts/AppContext'
import TableRow from '../../../../common/table/TableRow'
import TableCellsSelectForwarder from './TableCellsSelectForwarder'

/**
 * useContextのstateから表全体のデータを作成
 * @return {JSX} TableRow ~ TableCellまでのコンポーネント
 */
const TableContentsSelectForwarder = () => {
  const { state } = useContext(AppContext)
  const currentPageShowDataArr = state.Tables.showData[state.Paging - 1]

  return currentPageShowDataArr.map((row, index) => {
    const { apiIndexNum } = row
    return (
      <TableRow key={index}>
        <TableCellsSelectForwarder apiIndexNum={apiIndexNum} />
      </TableRow>
    )
  })
}

export default TableContentsSelectForwarder
