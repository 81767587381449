// Display + 画面名
const DisplayAirlineUldAssign = {
  // 画面ID + 文言ID
  D217V0001: {
    en: 'SPECIAL LOAD - NOTIFICATION TO CAPTAIN',
    ja: '特別な負荷 - キャプテンへの通知',
  },
  D217V0002: {
    en: 'Station of Loading',
    ja: 'ローディングステーション',
  },
  D217V0003: {
    en: 'Flight Number',
    ja: 'フライトナンバー',
  },
  D217V0004: {
    en: 'Date',
    ja: '日付',
  },
  D217V0005: {
    en: 'Aircraft Reg.',
    ja: '機体記号',
  },
  D217V0006: {
    en: 'Prepared by',
    ja: '醸造元',
  },
  D217V0007: {
    en: 'DANGEROUS GOODS',
    ja: '危険物',
  },
  D217V0008: {
    en: 'Station of',
    ja: '荷降ろし',
  },
  D217V0009: {
    en: 'Unloading',
    ja: 'の駅',
  },
  D217V0010: {
    en: 'Air Waybill',
    ja: '航空貨物運送状',
  },
  D217V0011: {
    en: 'Number',
    ja: '番号',
  },
  D217V0012: {
    en: 'Proper',
    ja: '適切な',
  },
  D217V0013: {
    en: 'Shipping Name',
    ja: '配送名',
  },
  D217V0014: {
    en: 'Class or Div.',
    ja: 'クラスまたはDiv.',
  },
  D217V0015: {
    en: 'For Class 1',
    ja: 'クラス1互換',
  },
  D217V0016: {
    en: 'compat. Grp.',
    ja: 'の場合。 Grp.',
  },
  D217V0017: {
    en: 'UN or ID',
    ja: 'UN または ID',
  },
  D217V0018: {
    en: 'Sub',
    ja: 'サブ',
  },
  D217V0019: {
    en: 'Risk',
    ja: 'リスク',
  },
  D217V0020: {
    en: 'of',
    ja: 'の',
  },
  D217V0021: {
    en: 'Packages',
    ja: 'パッケージ',
  },
  D217V0022: {
    en: 'Net quantity',
    ja: '正味数量',
  },
  D217V0023: {
    en: 'or',
    ja: 'または',
  },
  D217V0024: {
    en: 'Transp. Lnd.',
    ja: 'Transp. Lnd.',
  },
  D217V0025: {
    en: 'Per package',
    ja: 'パッケージごと',
  },
  D217V0026: {
    en: 'Radio-',
    ja: '無線-',
  },
  D217V0027: {
    en: 'active',
    ja: 'アクティブ',
  },
  D217V0028: {
    en: 'Mat.',
    ja: 'Mat.',
  },
  D217V0029: {
    en: 'Categ.',
    ja: 'Categ.',
  },
  D217V0030: {
    en: 'Packing',
    ja: '梱包',
  },
  D217V0031: {
    en: 'Group',
    ja: 'グループ',
  },
  D217V0032: {
    en: 'Code',
    ja: 'コード',
  },
  D217V0033: {
    en: '(see',
    ja: '(逆を',
  },
  D217V0034: {
    en: 'reverse)',
    ja: '参照)',
  },
  D217V0035: {
    en: 'CAO',
    ja: 'CAO',
  },
  D217V0036: {
    en: '(X)',
    ja: '(X)',
  },
  D217V0037: {
    en: 'Loaded',
    ja: 'ロード済み',
  },
  D217V0038: {
    en: 'ULD ID',
    ja: 'ULD ID',
  },
  D217V0039: {
    en: 'POS.',
    ja: 'POS.',
  },
  D217V0040: {
    en: 'ex. XXXXX',
    ja: 'ex. XXXXX',
  },
  D217V0041: {
    en: 'ex. CR',
    ja: 'ex. CR',
  },
  D217V0042: {
    en: '*There is no evidence that any damaged or leaking packages containing dangerous goods have been loaded on the aircraft',
    ja: '*危険物を含む破損または漏れたパッケージが航空機に搭載されたという証拠はありません',
  },
  D217V0043: {
    en: 'OTHER SPECIAL LOAD',
    ja: 'その他の特別な負荷',
  },
  D217V0044: {
    en: 'Contents and Description',
    ja: '内容と説明',
  },
  D217V0045: {
    en: 'Quantity',
    ja: '量',
  },
  D217V0046: {
    en: 'Supplementary Information',
    ja: '補足情報',
  },
  D217V0047: {
    en: '*This is sentense must be shown on the NOTOC. The location is left to the discretion of the carrier.',
    ja: '*この文章はNOTOCに必ず記載してください。場所は運送会社の裁量に委ねられています。',
  },
  D217V0048: {
    en: 'ULD ASSIGN',
    ja: 'ULDASSIGN',
  },
  D217V0049: {
    en: 'Number',
    ja: '数',
  },
  D217V0050: {
    en: 'SHC Code',
    ja: 'SHCコード',
  },
}
// Display + 画面名
export default DisplayAirlineUldAssign
