import React from 'react'
import PropTypes from 'prop-types'
import { Table, TableBody, TableRow } from '@material-ui/core'

import TableCellBidDetails from '../../../../commonUseContext/Bid/Table/TableCellBidDetails'
import TextFieldCellBidDetails from '../../../../commonUseContext/Bid/Table/TextFieldCellBidDetails'
import CommonValidation from '../../../../common/function/CommonValidation'

/**
 * Wgt欄とVol欄用関数 値の大小を比較する
 * @param {String} value 入力値
 * @param {String} relationValue 比較値
 * @returns {boolean} 入力値 > 比較値の結果
 */
const checkGreater = (value, relationValue) => {
  if (isNaN(value) || isNaN(relationValue)) {
    return false
  }
  return parseFloat(relationValue) > parseFloat(value)
}

/**
 * Wgt欄とVol欄用関数 値の大小を比較する
 * @param {String} value 入力値
 * @param {String} relationValue 比較値
 * @returns {boolean} 入力値 < 比較値の結果
 */
const checkLesser = (value, relationValue) => {
  if (isNaN(value) || isNaN(relationValue)) {
    return false
  }
  return parseFloat(relationValue) < parseFloat(value)
}

/**
 * TTL Wgt, TTL Volの入力欄を作成する
 * @param {Object} cellRequiredObj セルの作成に必要な値が入ったオブジェクト
 * @param {String} placeholder placeholder
 * @param {String} key key
 * @returns {JSX} TTL Wgt, TTL Volの入力欄
 */
const TtlTextFieldCellBidDetails = (cellRequiredObj, placeholder, key) => {
  // 一時別の変数に退避
  const cellKey = cellRequiredObj.cellKey
  const cellValueArr = new Array(...cellRequiredObj.cellValue)

  // minとmaxのcellKeyがカンマ区切りになっているため配列に変換
  const cellKeyArr = cellKey.split(',')

  const newCellRequiredObj = { ...cellRequiredObj }

  // Min欄を作成
  newCellRequiredObj.cellKey = cellKeyArr[0]
  newCellRequiredObj.cellValue = cellValueArr[0]
  newCellRequiredObj.relationValue = cellValueArr[1] // Max欄と比較
  const minTextField = TextFieldCellBidDetails(
    newCellRequiredObj,
    CommonValidation.ttlWgtVol,
    placeholder,
    checkLesser
  )

  // Max欄を作成
  newCellRequiredObj.cellKey = cellKeyArr[1]
  newCellRequiredObj.cellValue = cellValueArr[1]
  newCellRequiredObj.relationValue = cellValueArr[0] //Min欄と比較
  const maxTextField = TextFieldCellBidDetails(
    newCellRequiredObj,
    CommonValidation.ttlWgtVol,
    placeholder,
    checkGreater
  )

  return (
    <Table padding="none">
      <TableBody>
        <TableRow>
          <TableCellBidDetails
            displayContent={minTextField}
            noBorder
            key={`${key}Min`}
          />
          <TableCellBidDetails displayContent={'-'} noBorder key={`${key}-`} />
          <TableCellBidDetails
            displayContent={maxTextField}
            noBorder
            key={`${key}Max`}
          />
        </TableRow>
      </TableBody>
    </Table>
  )
}

TtlTextFieldCellBidDetails.propTypes = {
  cellRequiredObj: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  key: PropTypes.string.isRequired,
}

export default TtlTextFieldCellBidDetails
