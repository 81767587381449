import React, { useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import AppContext from '../../../../../contexts/AppContext'
import DatePicker from '../../../../common/datePicker/DatePicker'
import { LIGHT_FLIGHT_MGMT } from '../../../../../actions'
import ConstantsCarFlightMgmt from '../ConstantsCarFlightMgmt'
import Content from '../03AccordionCarFlightMgmt/Content'

/**
 * @param {*} props 下記内容のProps
 * @param {Boolean} props.start startDateの場合はtrue
 * @returns {JSX} EffectiveDate欄
 */
const EffectiveDate = (props) => {
  const { t } = useTranslation()
  const { start } = props
  const [error, setError] = useState(false)
  const { dispatch, state } = useContext(AppContext)

  /** @type {String} 開始日 */
  const startDate = state.Popup.getFlightInfoParam('effectivedateStart')
  /** @type {String} 終了日 */
  const endDate = state.Popup.getFlightInfoParam('effectivedateEnd')
  /** @type {String|Date} 初期値 */
  const defaultValue = start ? startDate : endDate

  /** @type {String} タイトル文言 */
  const title = t(start ? 'D301V0225' : 'D301V0226')

  /**
   * 固定表示用の日付を返す
   * @returns {JSX} 固定表示用の日付
   */
  const displayDate = useMemo(() => {
    if (typeof defaultValue === 'string') {
      /** @type {String} 日付部分 */
      const date = defaultValue?.split('T')[0] ?? ''
      return <Content title={title} content={date.replaceAll('-', '/')} />
    }
  }, [defaultValue, title])

  /**
   * EffectiveDate欄を返す
   * @returns {JSX} EffectiveDate欄
   */
  const createDatePicker = useMemo(() => {
    /**
     * 日付の最小値を取得
     * @returns {String} 日付の最小値
     */
    const getMinDate = () => {
      if (!start && startDate) {
        const date = new Date(startDate)
        const minDate =
          date.getDate() + ConstantsCarFlightMgmt.EFFECTIVE_DATE_PERIOD
        date.setDate(minDate)
        return date.toDateString()
      }
      return null
    }

    /**
     * 日付更新
     * @param {Date} value 日付
     * @returns {void} なし
     */
    const onChange = (value) => {
      // 日付情報だけが必要なため、時間と分は0に合わせる
      if (value) {
        value.setHours(0, 0)
      }
      dispatch({
        type: LIGHT_FLIGHT_MGMT.EDIT.FLIGHT_INFO,
        [start ? 'effectivedateStart' : 'effectivedateEnd']: value ?? null,
      })
    }

    /**
     * エラー時の動作
     * @param {String} errorText エラーテキスト
     * @param {Date} value 日付の差分
     * @return {void} なし
     */
    const onError = (errorText, value) => {
      setError(errorText !== '')
      //エラーの場合は日付にnullを設定、エラー状態から直った場合は入力した日付を設定
      if (errorText !== '') {
        onChange(null)
      } else if (error) {
        onChange(value)
      }
    }

    return (
      <Content
        title={title}
        content={
          <DatePicker
            onChange={onChange}
            disablePast
            format={'yyyy/MM/dd'}
            defaultValue={
              typeof defaultValue === 'object' ? defaultValue : null
            }
            minDate={getMinDate()}
            onError={onError}
          />
        }
      />
    )
  }, [startDate, dispatch, start, defaultValue, title, error])

  return state.Popup.add ? createDatePicker : displayDate
}
EffectiveDate.propTypes = {
  start: PropTypes.bool.isRequired,
}
export default EffectiveDate
