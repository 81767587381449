// Display + 画面名
const DisplayAirlineTracing = {
  // 画面ID + 文言ID
  D213V0001: {
    en: 'TRACING',
    ja: 'トレーシング',
  },
  D213V0002: {
    en: 'MAWB No.',
    ja: 'マスター番号',
  },
  D213V0003: {
    en: 'Pcs.',
    ja: '個数',
  },
  D213V0004: {
    en: 'G/W (kg)',
    ja: '総重量(kg)',
  },
  D213V0005: {
    en: 'Vol.(m3)',
    ja: '容積(m3)',
  },
  D213V0006: {
    en: 'C/W (kg)',
    ja: '賃率適用重量(kg)',
  },
  D213V0007: {
    en: 'ORG/DST',
    ja: '出発/到着',
  },
  D213V0008: {
    en: 'Current Status',
    ja: 'ステータス',
  },
  D213V0009: {
    en: 'XXX-XXXX XXXX',
    ja: 'XXX-XXXX XXXX',
  },
  D213V0010: {
    en: '10',
    ja: '10',
  },
  D213V0011: {
    en: '300.00',
    ja: '300.00',
  },
  D213V0012: {
    en: '2.05',
    ja: '2.05',
  },
  D213V0013: {
    en: 'NRT/FRA',
    ja: 'NRT/FRA',
  },
  D213V0014: {
    en: 'Off-Loaded due to ACL Over',
    ja: 'ACLオーバーによるオフロード',
  },
  D213V0015: {
    en: 'CGO READY',
    ja: '貨物出荷',
  },
  D213V0016: {
    en: 'BOND-IN',
    ja: '搬入',
  },
  D213V0017: {
    en: 'LOAD',
    ja: '搭載中',
  },
  D213V0018: {
    en: 'DEPART',
    ja: '出発',
  },
  D213V0019: {
    en: 'IN TRANSIT',
    ja: '輸送中',
  },
  D213V0020: {
    en: 'ARRIVE',
    ja: '到着',
  },
  D213V0021: {
    en: 'UNLOAD',
    ja: '降載',
  },
  D213V0022: {
    en: 'BOND-OUT',
    ja: '搬出',
  },
  D213V0023: {
    en: 'DELIVER',
    ja: '配送',
  },
  D213V0024: {
    en: 'LOADED',
    ja: '搭載済',
  },
  D213V0025: {
    en: 'DEPARTED',
    ja: '出発済',
  },
  D213V0026: {
    en: 'IN TRANSITED',
    ja: '移行済',
  },
  D213V0027: {
    en: 'ARRIVED',
    ja: '到着済',
  },
  D213V0028: {
    en: 'UNLOADED',
    ja: '降載済',
  },
  D213V0029: {
    en: 'DELIVERD',
    ja: '配送済',
  },
  D213V0030: {
    en: 'NRT',
    ja: 'NRT',
  },
  D213V0031: {
    en: 'FRA',
    ja: 'FRA',
  },
  D213V0032: {
    en: 'August 11',
    ja: '8月11日',
  },
  D213V0033: {
    en: 'August 12',
    ja: '8月12日',
  },
  D213V0034: {
    en: 'August 13',
    ja: '8月13日',
  },
  D213V0035: {
    en: 'August 14',
    ja: '8月14日',
  },
  D213V0036: {
    en: '22:05',
    ja: '22:05',
  },
  D213V0037: {
    en: 'Flight#',
    ja: 'フライト#',
  },
  D213V0038: {
    en: 'Carrier',
    ja: '航空会社',
  },
  D213V0039: {
    en: 'Aircraft',
    ja: '航空機',
  },
  D213V0040: {
    en: 'ULD#',
    ja: 'ULD#',
  },
  D213V0041: {
    en: 'ETD/ETA',
    ja: 'ETD/ETA',
  },
  D213V0042: {
    en: 'ATD/ATA',
    ja: 'ATD/ATA',
  },
  D213V0043: {
    en: 'JAPAN AIRLINES',
    ja: '日本航空',
  },
  D213V0044: {
    en: 'JL407/12AUG',
    ja: 'JL407/12AUG',
  },
  D213V0045: {
    en: '→',
    ja: '→',
  },
  D213V0046: {
    en: 'JL407/13AUG',
    ja: 'JL407/13AUG',
  },
  D213V0047: {
    en: 'Boeing787-900',
    ja: 'ボーイング787-900',
  },
  D213V0048: {
    en: 'Details',
    ja: '詳細',
  },
  D213V0049: {
    en: '11:25/16:30',
    ja: '11:25/16:30',
  },
  D213V0050: {
    en: '-/-',
    ja: '-/-',
  },
  D213V0051: {
    en: 'Show History',
    ja: '履歴を表示',
  },
  D213V0052: {
    en: 'CONSOL BOOKING - Enter HAWB#"YLK-XXXX XXXX"',
    ja: 'CONSOL BOOKING - HAWB#"YLK-XXXXXX XXXX "を入力してください。',
  },
  D213V0053: {
    en: 'Yusen Logistics, Osaka Branch',
    ja: '郵船ロジスティクス 大阪支店',
  },
  D213V0054: {
    en: '2020/08/04',
    ja: '2020/8/4',
  },
  D213V0055: {
    en: 'XX:XX',
    ja: 'XX:XX:XX',
  },
  D213V0056: {
    en: 'NINABA',
    ja: 'NINABA',
  },
  D213V0057: {
    en: 'Message',
    ja: 'メッセージ',
  },
  D213V0058: {
    en: 'FWB',
    ja: 'FWB',
  },
  D213V0059: {
    en: 'FHL',
    ja: 'FHL',
  },
  D213V0060: {
    en: 'Check',
    ja: 'チェック',
  },
  D213V0061: {
    en: 'Document',
    ja: 'ドキュメント',
  },
  D213V0062: {
    en: 'CargoMFST',
    ja: 'CargoMFST',
  },
  D213V0063: {
    en: 'NOTOC',
    ja: 'NOTOC',
  },
  D213V0064: {
    en: 'HAWB',
    ja: 'HAWB',
  },
  D213V0065: {
    en: 'House Manifest',
    ja: 'House Manifest',
  },
  D213V0066: {
    en: 'MAWB',
    ja: 'MAWB',
  },
  D213V0067: {
    en: 'DGD',
    ja: 'DGD',
  },
  D213V0068: {
    en: 'Invoice',
    ja: 'インボイス',
  },
  D213V0069: {
    en: 'PackingList',
    ja: 'パッキングリスト',
  },
  D213V0070: {
    en: 'SDS',
    ja: 'SDS',
  },
  D213V0071: {
    en: 'Edit',
    ja: '編集',
  },
  D213V0072: {
    en: 'Status',
    ja: 'ステータス',
  },
  D213V0073: {
    en: 'Shipper/Forwarder shall be notified of the following information.',
    ja: '荷送人/混載業者には、以下の情報が通知されます。',
  },
  D213V0074: {
    en: 'Document dificiency',
    ja: '書類不備',
  },
  D213V0075: {
    en: 'Specific Content',
    ja: '具体的な内容',
  },
  D213V0076: {
    en: 'DGD-Proper Shipping Name UNXXXX',
    ja: 'DGD-プロファー発送名 UNXXXXXX',
  },
  D213V0077: {
    en: 'Update',
    ja: '更新',
  },
  D213V0078: {
    en: 'Actual Time of Arrival (ATA)',
    ja: '到着時刻(ATA)',
  },
  D213V0079: {
    en: 'Off-Loaded',
    ja: 'オフロード',
  },
  D213V0080: {
    en: 'Cargo Damage',
    ja: '貨物ダメージ',
  },
  D213V0081: {
    en: 'Cancel Booking',
    ja: '予約キャンセル',
  },
  D213V0082: {
    en: 'Other',
    ja: 'その他',
  },
  D213V0083: {
    en: 'Enter XX:XX.',
    ja: 'XX：XXと入力',
  },
  D213V0084: {
    en: 'Due to ACL Over',
    ja: 'ACLオーバーのため',
  },
  D213V0085: {
    en: 'Wetting, crushing, Scratch, etc.',
    ja: 'ぬれ、つぶれ、引っかき等',
  },
  D213V0086: {
    en: 'Enter other status',
    ja: '他のステータスを入力してください',
  },
  D213V0087: {
    en: 'HAWB No.',
    ja: 'ハウス番号',
  },
  D213V0088: {
    en: 'M: ',
    ja: 'M: ',
  },
  D213V0089: {
    en: 'XXpcs, XXX.XXkg, XX.XXm3',
    ja: 'XXpcs, XXX.XXkg, XX.XXm3',
  },
  D213V0090: {
    en: 'PMCXXXXXKZ',
    ja: 'PMCXXXXXKZ',
  },
  D213V0091: {
    en: 'XX/XXpcs',
    ja: 'XX/XXpcs',
  },
  D213V0092: {
    en: 'XXX.XX/XXX.XXkg',
    ja: 'XXX.XX/XXX.XXkg',
  },
  D213V0093: {
    en: 'XX.XX/XX.XXm3',
    ja: 'XX.XX/XX.XXm3',
  },
  D213V0094: {
    en: 'Back',
    ja: '戻る',
  },
  D213V0095: {
    en: 'via',
    ja: '経由地',
  },
  D213V0096: {
    en: 'Show',
    ja: 'Show',
  },
  D213V0097: {
    en: '- / -',
    ja: '- / -',
  },
  D213V0098: {
    en: 'No History',
    ja: '履歴はありません',
  },
}
// Display + 画面名
export default DisplayAirlineTracing
