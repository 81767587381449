import { TABLES } from '../../../actions'
import CommonFunc from '../../../components/common/function/CommonFunc'
import CommonValidation from '../../../components/common/function/CommonValidation'
import ConstantsLightCarAirFreight from '../../../components/pages/master/LightCarAirFreight/ConstantsLightCarAirFreight'
import Common from '../../../constants/Common'

/** @type {Object} テーブル情報の初期値 */
const initTable = {
  isNoResult: true,
  showData: [],
  initData: [],
  checkAnyError: () => false,
  checkAnyInput: () => false,
}

/** @type {String[]} 必須レート名の配列 */
const requiredNameArr = Object.values(Common.RATE.REQUIRED_NAME)
/** @type {String[]} 任意レート名の配列 */
const optionalNameArr = Object.values(Common.RATE.OPTIONAL_NAME)
/** @type {String[]} レート名の配列 */
const rateNameArr = Object.values(Common.RATE.RATE_NAME)
/** @type {String[]} レートタイプの配列 */
const rateTypeArr = Object.values(Common.RATE.TYPE)

/**
 * レートが入力されているか確認する
 * @param {String} rateValue 判定する値
 * @returns {Boolean} 入力有無
 */
const checkInput = (rateValue) =>
  rateValue !== '' && rateValue !== null && rateValue !== undefined

/**
 * FSC欄が入力されているか確認する
 * @param {String} fuelSurCharge FSCの値
 * @param {Number} allInRate allInRateの値
 * @returns {Boolean} 入力有無
 */
const checkFscInput = (fuelSurCharge, allInRate) =>
  checkInput(fuelSurCharge) || allInRate === Common.ALL_IN_RATE.ON

/**
 * レートが1つ以上入力されているか確認する
 * @param {Object} rateObj gen/dg/iceのレートが入ったオブジェクト
 * @returns {Boolean} 入力有無
 */
const checkAnyRateInput = (rateObj) =>
  rateTypeArr.some((type) =>
    rateNameArr.some((name) => checkInput(rateObj[type][name]))
  )

/**
 * レート欄のエラーを判定する
 * @param {Object} rate 判定するレート情報
 * @param {String} rateType 判定するレートタイプ
 * @param {String} rateName 判定するレート名
 * @returns {Boolean} エラー有無
 */
const checkRateError = (rate, rateType, rateName) => {
  const { fuelSurCharge, allInRate, rateObj } = rate

  /** @type {Boolean} 判定するレートタイプにレートが1つ以上入力されているか */
  const isInputAnyRate = rateNameArr.some((v) =>
    checkInput(rateObj[rateType][v])
  )

  // レートは削除不可のため、登録済みレートで何も入力されていない場合はエラー表示
  if (rateObj[rateType].laneId && !isInputAnyRate) {
    return true
  } else if (requiredNameArr.includes(rateName)) {
    // Minrate, Normal欄のエラー判定
    /** @type {Boolean} 判定対象のレートが入力されているか */
    const isInputRate = checkInput(rateObj[rateType][rateName])

    /** @type {Boolean} Type単位でのエラー状態 */
    const isRateTypeError = isInputAnyRate && !isInputRate

    /** @type {Boolean} Fsc欄が入力されているか */
    const isInputFsc = checkFscInput(fuelSurCharge, allInRate)

    /** @type {string[]} 判定対象以外のレートタイプの配列 */
    const otherTypeArr = rateTypeArr.filter((v) => v !== rateType)

    /** @type {Boolean} 判定対象以外のレートタイプに1つ以上入力されているか */
    const isInputOtherType = otherTypeArr.some((type) =>
      rateNameArr.some((v) => checkInput(rateObj[type][v]))
    )

    /** @type{Boolean} Fsc欄によるレート欄へのエラー */
    const isFscError = isInputFsc && !isInputOtherType && !isInputRate

    // FSC欄によるエラーまたはレートタイプ単位でのエラーを返す
    return isFscError || isRateTypeError
  } else {
    // +45kg~1000kg欄は1つ以上入力必須
    /** @type{Boolean} 任意レートが1つ以上入力されているか */
    const isInputOptionalRate = optionalNameArr.some((v) =>
      checkInput(rateObj[rateType][v])
    )
    // レートが1つ以上入力されていて、+45kg~1000kg欄が未入力の場合エラー
    return isInputAnyRate && !isInputOptionalRate
  }
}

/**
 * FSC入力欄のエラーを判定する
 * @param {Object} rate 判定するFSCが含まれるレート情報
 * @returns {Boolean} エラー有無
 */
const checkFscError = (rate) => {
  const { fuelSurCharge, allInRate, rateObj } = rate

  /** @type {Boolean} Fsc欄が入力されているか */
  const isInputFsc = checkFscInput(fuelSurCharge, allInRate)

  // バリデーションチェック
  const { checkResult } = CommonValidation.priceOptional(fuelSurCharge)

  /** @type {Boolean} 判定対象のレーンに1つ以上入力されているか */
  const isInputAnyRate = checkAnyRateInput(rateObj)

  // レートが入力されている状態でFsc欄が未入力の場合はエラー
  const fscError = isInputAnyRate && !isInputFsc

  return checkResult || fscError
}

/**
 * 全レートで1つ以上エラーがあるか判定
 * @param {Object[]} initData 画面で保持しているレート情報の配列
 * @returns {Boolean} エラー有無
 */
const checkAnyError = (initData) => {
  return initData.some((rate) => {
    const fscError = checkFscError(rate)
    const rateError = rateTypeArr.some((type) =>
      rateNameArr.some((name) => {
        const { checkResult } = CommonValidation.priceOptional(
          rate.rateObj[type][name]
        )
        return checkResult || checkRateError(rate, type, name)
      })
    )
    return fscError || rateError
  })
}

/**
 * レートが1つ以上入力されているか判定
 * @param {Object[]} initData 画面で保持しているレート情報の配列
 * @returns {Boolean} 入力有無
 */
const checkAnyInput = (initData) => {
  return initData.some((rate) => {
    const fscInput = checkFscInput(rate.fuelSurCharge, rate.allInRate)
    const rateInput = checkAnyRateInput(rate.rateObj)
    return fscInput && rateInput
  })
}

/**
 * リクエストデータを返す
 * @param {Object[]} initData 画面で保持しているレート情報の配列
 * @param {Boolean} editedCurrency 通貨を編集したか
 * @returns {Object[]} リクエストデータ
 */
const getRequestData = (initData, editedCurrency) => {
  // リクエスト用に整形したデータの配列
  const requestDataArr = initData.map((data) => {
    const {
      contractId,
      orgId,
      dstId,
      fuelSurCharge,
      allInRate,
      rateObj,
      editedAllInRate,
      editedFuelSurCharge,
    } = data
    // 元のデータを削除しないようにレートのオブジェクトをコピーして格納
    const reqRateObj = JSON.parse(JSON.stringify(rateObj))
    // レートを全てAPIに渡すか判定
    const isRequestAllRateType =
      editedAllInRate || editedFuelSurCharge || editedCurrency

    // レートを整形
    rateTypeArr.map((rateType) => {
      // 未入力レートか判定
      const isNotInput = !rateObj[rateType].minrate || !rateObj[rateType].normal
      // 未編集レートか判定
      const isNotEdited = !isRequestAllRateType && !rateObj[rateType].edited
      // 未入力レートと編集されていないレートを削除
      if (isNotInput || isNotEdited) {
        reqRateObj[rateType] = {}
      }

      // 不要なデータを削除
      delete reqRateObj[rateType].edited
      return null
    })
    return {
      contractId,
      orgId,
      dstId,
      fuelSurCharge,
      allInRate,
      rateObj: reqRateObj,
    }
  })

  // GEN・DG・ICEが全て未入力のレートを取り除いて返す
  return requestDataArr.filter(({ rateObj }) => checkAnyRateInput(rateObj))
}

/**
 * 画面表示用のデータに修正する
 * @param {Object[]} tableData APIから取得したデータ
 * @returns {Object[]} 画面表示用のデータ
 */
const fixData = (tableData) =>
  tableData.map((data, index) => ({
    ...data,
    allInRate:
      data.allInRate === null ? Common.ALL_IN_RATE.OFF : data.allInRate,
    orgDst: `${data.orgAirportCode}/${data.dstAirportCode}`,
    initDataIndex: index,
  }))

const getHasEditedData = (initData) =>
  initData.some(({ rateObj, editedAllInRate, editedFuelSurCharge }) => {
    const { gen, dg, ice } = rateObj
    return (
      editedAllInRate ||
      editedFuelSurCharge ||
      gen.edited ||
      dg.edited ||
      ice.edited
    )
  })

/**
 * 内容を保持
 * @param {Object} action - dispatchで受け取ったaction
 * @param {Object} state - state
 * @return {Object} - state
 */
const setData = (action, state) => {
  const { tableData, search = '' } = action
  /** @type {Array} 表示用に編集後のデータ */
  const editedData = tableData ? fixData(tableData) : state.initData
  /** @type {Number} 1ページに表示する件数 */
  const pageAmount = ConstantsLightCarAirFreight.PAGE_AMOUNT
  // 検索して結果をページごとに分割する
  const { showData, initData } = CommonFunc.searchSortData(
    editedData,
    search,
    '',
    '',
    pageAmount
  )
  /** @type {Boolean} NoResult判定 */
  const isNoResult = showData[0].length <= 0

  const hasEditedData = getHasEditedData(initData)

  return {
    ...state,
    apiData: tableData ? JSON.parse(JSON.stringify(editedData)) : state.apiData,
    showData,
    initData,
    isNoResult,
    hasEditedData,
    checkRateError,
    checkFscError,
    checkAnyError: () => checkAnyError(initData),
    checkAnyInput: () => checkAnyInput(initData),
    getRequestData: (editedCurrency) =>
      getRequestData(initData, editedCurrency),
  }
}

/**
 * テーブルを編集
 * @param {Object} action - dispatchで受け取ったaction
 * @param {Object} state - state
 * @return {Object} - 編集後のstate
 */
const editData = (action, state) => {
  const {
    index,
    page,
    rateType,
    rateName,
    value,
    allInRate = null,
    fuelSurCharge = null,
  } = action
  const initDataIndex = state.showData[page][index].initDataIndex

  // allInRateを更新
  if (allInRate !== null) {
    state.initData[initDataIndex].allInRate = allInRate
    state.showData[page][index].allInRate = allInRate

    // allInRateの編集フラグを立てる
    const apiValue = state.apiData[initDataIndex].allInRate
    state.initData[initDataIndex].editedAllInRate = allInRate !== apiValue
  }
  // fuelSurChargeを更新
  if (fuelSurCharge !== null) {
    state.initData[initDataIndex].fuelSurCharge = fuelSurCharge
    state.showData[page][index].fuelSurCharge = fuelSurCharge

    // fuelSurChargeの編集フラグを立てる
    const apiValue = state.apiData[initDataIndex].fuelSurCharge
    state.initData[initDataIndex].editedFuelSurCharge =
      fuelSurCharge !== apiValue
  }
  // レートを更新
  if (rateType) {
    state.initData[initDataIndex].rateObj[rateType][rateName] = value
    state.showData[page][index].rateObj[rateType][rateName] = value

    // 編集したレートタイプに編集フラグを立てる
    const apiValue =
      state.apiData[initDataIndex].rateObj[rateType][rateName] ?? null
    state.initData[initDataIndex].rateObj[rateType].edited = value !== apiValue
  }

  // 編集されたレートがあるか判定
  const hasEditedData = getHasEditedData(state.initData)

  return { ...state, hasEditedData }
}

/**
 * テーブル情報を保持
 * @param {Object} [state=initTable] - state
 * @param {Object} action - action
 * @return {Object} - state
 */
const Table = (state = initTable, action) => {
  switch (action.type) {
    case TABLES.SET:
      return setData(action, state)
    case TABLES.EDIT:
      return editData(action, state)
    default:
      return state
  }
}

export default Table

export { initTable }
