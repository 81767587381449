// Display + 画面名
const DisplayFwdrPrivateBid = {
  // 画面ID + 文言ID
  D113V0001: {
    en: 'Private',
    ja: 'プライベート',
  },
  D113V0002: {
    en: 'Public',
    ja: 'パブリック',
  },
  D113V0003: {
    en: 'Private Bid',
    ja: '指定案件',
  },
  D113V0004: {
    en: 'Keywords',
    ja: 'キーワード',
  },
  D113V0005: {
    en: 'Sort by:',
    ja: '並び替え：',
  },
  D113V0006: {
    en: 'Lately',
    ja: '最近',
  },
  D113V0007: {
    en: 'Validity',
    ja: '有効期間',
  },
  D113V0008: {
    en: 'Company',
    ja: '企業名',
  },
  D113V0009: {
    en: 'Set FWDR',
    ja: '参加FWDR',
  },
  D113V0010: {
    en: '1st Deadline',
    ja: '一次締め切り',
  },
  D113V0011: {
    en: '2nd Deadline',
    ja: '二次締め切り',
  },
  D113V0012: {
    en: 'Status',
    ja: 'ステータス',
  },
  D113V0013: {
    en: 'From SHP',
    ja: '荷主より',
  },
  D113V0014: {
    en: 'From Carrier',
    ja: '航空会社より',
  },
  D113V0015: {
    en: 'September 1, 2020 - August 31, 2020',
    ja: '2020年9月1日～2020年8月31日',
  },
  D113V0016: {
    en: 'XXXXXX Corporation',
    ja: 'XXXXXX 株式会社',
  },
  D113V0017: {
    en: 'Undisclosed',
    ja: '非公開',
  },
  D113V0018: {
    en: 'DZK/KNJ/EIK/CEV/DSV/UPS',
    ja: 'DZK/KNJ/EIK/CEV/DSV/UPS',
  },
  D113V0019: {
    en: 'NEC/YLK/KWE/HEI/NNR',
    ja: 'NEC/YLK/KWE/HEI/NNR',
  },
  D113V0020: {
    en: 'July 31, 2020',
    ja: '2020年7月31日',
  },
  D113V0021: {
    en: 'August 14, 2020',
    ja: '2020年8月14日',
  },
  D113V0022: {
    en: 'Under Contract',
    ja: '契約中',
  },
  D113V0023: {
    en: 'Not Contract',
    ja: '未契約',
  },
  D113V0024: {
    en: 'August 28, 2020',
    ja: '2020年8月28日',
  },
  D113V0025: {
    en: 'New BID',
    ja: '新規案件',
  },
  D113V0026: {
    en: 'Was Updated',
    ja: '更新済',
  },
  D113V0027: {
    en: 'Requesting',
    ja: '依頼中',
  },
  D113V0028: {
    en: 'Decline',
    ja: '不成約',
  },
  D113V0029: {
    en: 'Check',
    ja: '確認',
  },
  D113V0030: {
    en: 'Done',
    ja: '完了',
  },
  D113V0031: {
    en: '3rd Deadline',
    ja: '三次締め切り',
  },
  D113V0032: {
    en: 'Sep 1, 2020 - Aug 31, 2020',
    ja: '2020年9月1日～2020年8月31日',
  },
  D113V0033: {
    en: 'Jul 31, 2020',
    ja: '2020/7/31',
  },
  D113V0034: {
    en: 'Aug 14, 2020',
    ja: '2020/8/14',
  },
  D113V0035: {
    en: 'Aug 28, 2020',
    ja: '2020/8/28',
  },
}
// Display + 画面名
export default DisplayFwdrPrivateBid
