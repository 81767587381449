// Display + 画面名
const DisplayNotificationDetails = {
  D602V0001: {
    en: 'Notification Details',
    ja: '通知の詳細',
  },
  D602V0002: {
    en: 'Date',
    ja: '日付',
  },
  D602V0003: {
    en: 'Name',
    ja: '名前',
  },
  D602V0004: {
    en: 'Company',
    ja: '会社',
  },
  D602V0005: {
    en: 'Contents',
    ja: '内容',
  },
  D602V0006: {
    en: 'Related Page',
    ja: '関連ページ',
  },
  D602V0007: {
    en: 'Delete',
    ja: '削除',
  },
  D602V0008: {
    en: 'Back',
    ja: '戻る',
  },
  D602V0009: {
    en: 'Save',
    ja: '保存',
  },
  D602V0010: {
    en: 'Are you sure?',
    ja: '本当によろしいですか？',
  },
  D602V0011: {
    en: 'Cancel',
    ja: 'キャンセル',
  },
  D602V0012: {
    en: 'OK',
    ja: 'OK',
  },
  D602V0013: {
    en: 'Do you want to save the changes?',
    ja: '変更を保存しますか？',
  },
  D602V0014: {
    en: 'Saved changes.',
    ja: '保存されました。',
  },
  D602V0015: {
    en: 'Your changes have not been saved.',
    ja: '変更は保存されていません。',
  },
}
// Display + 画面名
export default DisplayNotificationDetails
