import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import AppContext from '../../../../../contexts/AppContext'
import EditButton from '../../../../common/buttons/EditButton'
import { SPECIAL_NOTES } from '../../../../../actions'

/**
 * @param {Object} props 以下
 * @param {Number} index SpecialNotesのindex
 * @returns {JSX} SpecialNotesEditボタンコンポーネント
 */
const SpecialNotesEditButton = ({ index }) => {
  const { dispatch } = useContext(AppContext)

  /**
   * ダイアログを開く&クリックしたindexを保存
   * @return {void}
   */
  const openDialog = () => {
    dispatch({
      type: SPECIAL_NOTES.TOGGLE_DIALOG,
      isOpenDialog: true,
      selectIndexNum: index,
    })
  }

  return <EditButton onClick={openDialog} />
}

SpecialNotesEditButton.propTypes = {
  index: PropTypes.number.isRequired,
}

export default SpecialNotesEditButton
