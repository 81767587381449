import React, { useContext } from 'react'

import AppContext from '../../../../../contexts/AppContext'
import CompanyIcon from '../../../../commonUseContext/Authentication/CompanyIcon'
import Common from '../../../../../constants/Common'

const IconLightAirlineRegisterNewAccount = () => {
  const { state } = useContext(AppContext)
  // GSAの場合、会社アイコン非表示
  if (state.CarrierName.gsaFlg === Common.GSA_FLG.GSA) return null

  return <CompanyIcon />
}

export default IconLightAirlineRegisterNewAccount
