import { LIGHT_BOOKING_REQUEST } from '../../../actions'

/** @type{Object} 初期表示 */
const initSelectBox = {
  commodity: [],
}

/**
 * BookingDetails情報を保持
 * @param {Boolean} [state=initShippingDetails] state
 * @param {Object} action action
 * @return {Boolean} チェックボックス状態
 */
const SelectBox = (state = initSelectBox, action) => {
  switch (action.type) {
    case LIGHT_BOOKING_REQUEST.COMMODITY.SET:
      return { ...state, commodity: action.data }
    default:
      return state
  }
}

export default SelectBox

export { initSelectBox }
