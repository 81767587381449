import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { useHistory } from 'react-router-dom'

import MobileSubHeader from '../subHeader/MobileSubHeader'
import Menu from './Menu'
import CommonFunc from '../../common/function/CommonFunc'
import Common from '../../../constants/Common'
import Paths from '../../../constants/Paths'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: theme.header.height,
  },
  header: {
    padding: '0 1rem',
    height: '90px',
  },
  logo: {
    width: '180px',
    cursor: 'pointer',
  },
  mobile: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  desktop: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))

const Header = (props) => {
  const classes = useStyles()
  const history = useHistory()
  // メンテナンスページはヘッダーメニュー非表示
  const isHidden = props.isMaintenance

  const PageTransition = () => {
    const token = CommonFunc.getStorage(Common.KEY.TOKEN)
    if (!token) {
      // サインアウト状態の時はサインイン画面に遷移
      CommonFunc.clickEvent({ path: Paths.AUTHENTICATION.SIGN_IN }, history)
    } else {
      // TOPに遷移
      CommonFunc.topTranslation(history)
    }
  }

  return (
    <AppBar color="primary" position="static" className={classes.root}>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className={classes.header}
      >
        <Grid item>
          <img
            className={classes.logo}
            src="./../images/CargoLabo_logo_white.svg"
            alt="acdigi-logo"
            onClick={PageTransition}
          />
        </Grid>
        <Grid item className={`${classes.desktop}`} hidden={isHidden}>
          <Menu />
        </Grid>
        <Grid item className={classes.mobile}>
          <MobileSubHeader />
        </Grid>
      </Grid>
    </AppBar>
  )
}

export default Header
