/* eslint-disable no-extra-parens */
import React, { useState, useEffect, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import { useHistory } from 'react-router-dom'

import AppContext from '../../../../contexts/AppContext'
import reducer from '../../../../reducers/master/masterShipCompanyInfoM'
import {
  UPDATE_SHIP_COMPANY_NAME,
  UPDATE_SHIP_POSTAL_CODE,
  UPDATE_SHIP_COMPANY_ADDRESS,
  UPDATE_SHIP_AIRPORT,
  UPDATE_SHIP_BRANCH,
  UPDATE_SHIP_SHIPCON_ID,
  UPDATE_SHIP_FIRST_NAME,
  UPDATE_SHIP_LAST_NAME,
  UPDATE_SHIP_POSITION,
  UPDATE_SHIP_MAIL,
  UPDATE_SHIP_TEL,
  UPDATE_SHIP_ICON_PATH,
  UPDATE_SHIP_BACKUP,
  UPDATE_SHIP_UPDATE_AT,
  UPDATE_SHIP_FILE,
} from '../../../../actions'
import Paths from '../../../../constants/Paths'
import Title from '../../../common/title/Title'
import SaveAndBack from '../../../common/buttons/SaveAndBack'
import LayoutBox from '../../../common/layout/LayoutBox'
import PostalCode from './PostalCode'
import CompanyAddress from './CompanyAddress'
import Airport from './Airport'
import Branch from './Branch'
import CompanyName from './CompanyName'
import ShipConId from './ShipConId'
import Representative from './Representative'
import Position from './Position'
import ContactInfo from './ContactInfo'
import Icon from './Icon'
import ApiPaths from '../../../../constants/ApiPaths'
import CommonFunc from '../../../common/function/CommonFunc'
import Theme from '../../../common/theme/Theme'
import restFacade from '../../../../actions/rest-facade'

const useStyles = makeStyles(() => ({
  buttons: {
    justifyContent: 'center',
    marginBottom: 30,
    marginTop: 30,
  },
  down: {
    marginBottom: 30,
    margin: 'auto',
  },
  red: Theme.textNormalRED,
}))

const MasterShipCompanyInfoM = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const [saveComp, setSaveComp] = useState(false)
  const [errCode, setErrCode] = useState(0)
  const [cityName, setCityName] = useState([])
  const [airport, setAirport] = useState([])
  const [representativeFlg, setRepresentativeFlg] = useState('')

  const initialState = {
    companyinfo: {
      companyName: { value: '', inputErr: false },
      shipConId: { value: '', inputErr: false },
      postalCode: { value: '', inputErr: false },
      address: { value: '', inputErr: false },
      airport: {
        value: { code: '', name: '' },
        inputErr: { inputErr: false },
      },
      cityName: {
        value: { code: '', name: '' },
        inputErr: { inputErr: false },
      },
      firstName: { value: '', inputErr: false },
      lastName: { value: '', inputErr: false },
      position: { value: '', inputErr: false },
      mail: { value: '', inputErr: false },
      tel: { value: '', inputErr: false },
      icon: { value: '', inputErr: false },
      updatedAt: { value: '' },
      isDeleteIcon: { value: '' },
    },
    backup: {
      companyName: '',
      shipConId: '',
      postalCode: '',
      address: '',
      cityName: '',
      airport: '',
      firstName: '',
      lastName: '',
      position: '',
      mail: '',
      tel: '',
      icon: '',
    },
    file: {
      file: '',
    },
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  // DBからCityName取得
  const setCityNameData = (res) => {
    const result = res.data.results.map((v) => ({
      code: v.cityId,
      name: v.cityName,
    }))
    setCityName(result)
  }

  // DBからAirport取得
  const setAirportData = (res) => {
    const result = res.data.results.map((v) => ({
      code: v.airportId,
      name: v.airport,
    }))
    setAirport(result)
  }

  // Saveボタンの有効無効判定
  const chkSaveDisable = () => {
    if (
      state.companyinfo.companyName.inputErr === true ||
      state.companyinfo.shipConId.inputErr === true ||
      state.companyinfo.postalCode.inputErr === true ||
      state.companyinfo.address.inputErr === true ||
      state.companyinfo.airport.inputErr === true ||
      state.companyinfo.cityName.inputErr === true ||
      state.companyinfo.firstName.inputErr === true ||
      state.companyinfo.lastName.inputErr === true ||
      state.companyinfo.position.inputErr === true ||
      state.companyinfo.mail.inputErr === true ||
      state.companyinfo.tel.inputErr === true ||
      state.companyinfo.icon.inputErr === true
    ) {
      return true
    }
    return false
  }
  // 入力変更ありなし判定
  const chkInputChg = () => {
    const sta = state.companyinfo
    const bk = state.backup
    if (
      bk.companyName !== sta.companyName.value ||
      bk.shipConId !== sta.shipConId.value ||
      bk.postalCode !== sta.postalCode.value ||
      bk.address !== sta.address.value ||
      bk.airport !== sta.airport.value.name ||
      bk.cityName !== sta.cityName.value.name ||
      bk.firstName !== sta.firstName.value ||
      bk.lastName !== sta.lastName.value ||
      bk.position !== sta.position.value ||
      bk.mail !== sta.mail.value ||
      bk.tel !== sta.tel.value ||
      bk.icon !== sta.icon.value
    ) {
      return true
    }
    return false
  }

  // 保存完了後の処理
  const saveComplete = () => {
    setSaveComp(false)
    if (errCode === 0) {
      // 保存に成功したらデータ再取得
      restFacade.get(ApiPaths.MASTER_SHIPPER.COMPANY_INFO, (res) =>
        setData(res, setCompanyInfoCB)
      )
    }
  }

  // 保存完了時のコールバック
  const saveDataCB = (response) => {
    setSaveComp(true)
    setErrCode(response.data.info.ErrorCode)
  }

  // 保存処理
  const saveData = () => {
    const req = {
      companyName: state.companyinfo.companyName.value,
      shipConId: state.companyinfo.shipConId.value,
      postalCode: state.companyinfo.postalCode.value,
      address: state.companyinfo.address.value,
      airportId: state.companyinfo.airport.value.code,
      cityId: state.companyinfo.cityName.value.code,
      firstName: state.companyinfo.firstName.value,
      lastName: state.companyinfo.lastName.value,
      position: state.companyinfo.position.value,
      mail: state.companyinfo.mail.value,
      tel: state.companyinfo.tel.value,
      updatedAt: state.companyinfo.updatedAt.value,
      file: state.file.file,
      isDeleteIcon: state.companyinfo.isDeleteIcon.value,
      representativeFlg,
    }
    restFacade.post(
      ApiPaths.MASTER_SHIPPER.SHIP_COMPANY_INFO,
      (res) => CommonFunc.callbackFunc(res, saveDataCB, history),
      req
    )
  }

  const setCompanyInfoCB = (response) => {
    const res = response.data.results

    const representativeFlg = res.representativeFlg
    setRepresentativeFlg(representativeFlg)

    dispatch({
      type: UPDATE_SHIP_COMPANY_NAME,
      companyName: res.companyName,
    })
    dispatch({
      type: UPDATE_SHIP_POSTAL_CODE,
      postalCode: res.postalCode,
    })
    dispatch({
      type: UPDATE_SHIP_COMPANY_ADDRESS,
      address: res.address,
    })
    dispatch({
      type: UPDATE_SHIP_BRANCH,
      cityName: res.cityName,
      cityId: res.cityId,
    })
    dispatch({
      type: UPDATE_SHIP_SHIPCON_ID,
      shipConId: res.shipConId,
    })
    dispatch({
      type: UPDATE_SHIP_FIRST_NAME,
      firstName: res.firstName,
    })
    dispatch({
      type: UPDATE_SHIP_LAST_NAME,
      lastName: res.lastName,
    })
    dispatch({
      type: UPDATE_SHIP_POSITION,
      position: res.position,
    })
    dispatch({
      type: UPDATE_SHIP_MAIL,
      mail: res.mail,
    })
    dispatch({
      type: UPDATE_SHIP_TEL,
      tel: res.tel,
    })
    dispatch({
      type: UPDATE_SHIP_ICON_PATH,
      icon: res.icon,
    })
    dispatch({
      type: UPDATE_SHIP_AIRPORT,
      airport: res.airport,
      airportId: res.airportId,
    })
    dispatch({
      type: UPDATE_SHIP_UPDATE_AT,
      updatedAt: res.updatedAt,
    })
    dispatch({
      type: UPDATE_SHIP_FILE,
      file: res.file,
    })

    // 初回データをBackup
    dispatch({
      type: UPDATE_SHIP_BACKUP,
      info: res,
    })

    // 先頭にスクロール
    window.scrollTo(0, 0)
  }

  // エラー処理、コールバック関数実行
  const setData = (res, callback) => {
    CommonFunc.callbackFunc(res, callback, history)
  }

  // データ取得
  useEffect(() => {
    restFacade.get(ApiPaths.MASTER_JH.AIRPORT, (res) =>
      setData(res, setAirportData)
    )
    restFacade.get(ApiPaths.MASTER_JH.CITY_CODE, (res) =>
      setData(res, setCityNameData)
    )
    restFacade.get(ApiPaths.MASTER_SHIPPER.COMPANY_INFO, (res) =>
      setData(res, setCompanyInfoCB)
    )
  }, []) // eslint-disable-line

  // Backコンポーネントに渡したいものをまとめる
  const backFactors = {
    path: Paths.MASTER.SHIP_MASTER_TOP,
    inputchg: chkInputChg(),
    clickevent: '',
  }

  // Saveコンポーネントに渡したいものをまとめる
  const saveFactors = {
    path: Paths.MASTER.SHIP_COMPANY_INFOM,
    saveevent: saveData,
    comp: saveComp,
    compevent: saveComplete,
    disabled: chkSaveDisable(),
    errcode: errCode,
  }

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <LayoutBox>
        <Title>{t('D301V0001')}</Title>
        <Grid container justify="center">
          <Grid item style={{ marginBottom: '3rem' }} md={10} xs={10}>
            <CompanyName />
          </Grid>
          <Grid item style={{ marginBottom: '3rem' }} md={10} xs={10}>
            <ShipConId />
          </Grid>
          <Grid item style={{ marginBottom: '3rem' }} md={10} xs={10}>
            <Branch cityName={cityName} />
          </Grid>
          <Grid item style={{ marginBottom: '3rem' }} md={10} xs={10}>
            <PostalCode />
          </Grid>
          <Grid item style={{ marginBottom: '3rem' }} md={10} xs={10}>
            <CompanyAddress />
          </Grid>
          <Grid item style={{ marginBottom: '3rem' }} md={10} xs={10}>
            <Airport airport={airport} />
          </Grid>
          <Grid item style={{ marginBottom: '3rem' }} md={10} xs={10}>
            <Representative representativeFlg={representativeFlg} />
          </Grid>
          <Grid item style={{ marginBottom: '3rem' }} md={10} xs={10}>
            <Position representativeFlg={representativeFlg} />
          </Grid>
          <Grid item style={{ marginBottom: '3rem' }} md={10} xs={10}>
            <ContactInfo representativeFlg={representativeFlg} />
          </Grid>
          <Grid item style={{ marginBottom: '3rem' }} md={10} xs={10}>
            <Icon />
          </Grid>
          <Grid container item justify="flex-end" md={10} xs={10}>
            <Grid item>
              <Link
                className={classes.red}
                onClick={() =>
                  CommonFunc.clickEvent(
                    { path: Paths.NOTIFY.LIGHT_DELETE_ACCOUNT },
                    history
                  )
                }
              >
                {t('D301V0020')}
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          justify="center"
          direction="column"
          alignItems="center"
          className={classes.down}
        >
          <Grid item md={10} xs={10} className={classes.buttons}>
            <SaveAndBack backFactors={backFactors} saveFactors={saveFactors} />
          </Grid>
        </Grid>
      </LayoutBox>
    </AppContext.Provider>
  )
}

export default MasterShipCompanyInfoM
