import React from 'react'
import PropTypes from 'prop-types'
import { Grid, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  text: theme.textNormalBLK,
  wordBreak: theme.wordBreak,
}))

/**
 * @param {Object} props 以下
 * @param {String} picStr 表示したい文字列
 * @return {JSX} PICのテキストコンポーネント
 */
const TextPicBidInfo = ({ picStr }) => {
  const classes = useStyles()
  const className = `${classes.text} ${classes.wordBreak}`

  // 登録されていないものは‐で表示
  const displayText = picStr ? picStr : '-'

  return (
    <Grid item xs className={className}>
      {displayText}
    </Grid>
  )
}

TextPicBidInfo.propTypes = {
  picStr: PropTypes.string,
}

export default TextPicBidInfo
