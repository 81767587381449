/* eslint-disable prefer-template */
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import MDialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

import SecondaryButton from '../buttons/SecondaryButton'
import CommonFunc from '../function/CommonFunc'

const useStyles = makeStyles((theme) => ({
  root: theme.dialog,
  dialog: theme.dialogContent,
  transparent: {
    background: 'transparent',
    border: '0px solid',
  },
  text: theme.saveText,
  mobile: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  desktop: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  margin: {
    margin: 10,
  },
  marginTop: {
    marginTop: '2rem',
  },
}))

/**
 * @param {Object} props - 以下
 * @param {*} buttonPC - ボタンコンポーネントを指定
 * @param {Event} clickevent - PDF表示・ダウンロード時の動作
 * @returns {JSX.Element} - Dialog
 */
const ShowPdfDialog = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [scroll, setScroll] = useState('body')
  const [pageX, setPageX] = useState(0)

  const handleClickOpen = (scrollType) => () => {
    // windowのスクロール設定を記録してから非表示
    CommonFunc.hideScroll()
    setOpen(true)
    setScroll(scrollType)
  }

  const handleCloseOK = () => {
    // windowのスクロール設定を戻す
    CommonFunc.showScroll()
    // ダイアログを閉じる
    setOpen(false)
  }

  /**
   * ダイアログを閉じる
   * @param {Event} event - onCloseのイベント
   * @param {Boolean} isDialogBackGround - ダイアログ背景をクリックしているかの判定
   * @returns {void}
   */
  const handleCloseCancel = (event, isDialogBackGround) => {
    /** 現在クリック位置が要素外(スクロール部分)をクリックしているか判定 */
    const isScrollClick = isDialogBackGround
      ? event.target.clientWidth - pageX < 0
      : false
    if (isScrollClick) {
      // スクロール部分をクリックしている場合は何もしない
      return
    } else {
      // windowのスクロール設定を戻す
      CommonFunc.showScroll()
      // ダイアログを閉じる
      setOpen(false)
    }
  }

  /**
   * クリックした瞬間の位置を保存
   * @param {Event} event event onMousedownのイベント
   * @returns {void}
   */
  const savePageXPosition = (event) => setPageX(event.pageX)

  return (
    <>
      {/* ボタン */}
      <div onClick={props.disabled ? null : handleClickOpen('body')}>
        {props.buttonPC}
      </div>

      {/* ダイアログ */}
      <MDialog
        open={open}
        scroll={scroll}
        onClose={(e) => handleCloseCancel(e, true)}
        onMouseDown={savePageXPosition}
        className={classes.root + ' ' + props.className}
      >
        {/* ダイアログ下部のボタン */}
        <DialogActions>
          {/* スマホ */}
          <Grid
            container
            direction="column"
            alignContent="center"
            className={classes.mobile + ' ' + classes.margin}
            spacing={3}
          >
            <Grid item md={12} xs={12}>
              <div className={classes.center}>
                <div onClick={handleCloseOK}>
                  <SecondaryButton onClick={() => props.clickevent(true)}>
                    {t('D210V0058')}
                  </SecondaryButton>
                </div>
              </div>
            </Grid>
            <Grid item md={12} xs={12}>
              <div className={classes.center}>
                <div onClick={handleCloseCancel}>
                  <SecondaryButton
                    disabled={props.isZip}
                    onClick={props.isZip ? null : () => props.clickevent(false)}
                  >
                    {t('D210V0059')}
                  </SecondaryButton>
                </div>
              </div>
            </Grid>
          </Grid>
          {/* PC */}
          <Grid
            container
            justify="center"
            spacing={3}
            className={classes.desktop + ' ' + classes.margin}
          >
            <Grid item>
              <div className={classes.center}>
                <div onClick={handleCloseCancel}>
                  <SecondaryButton onClick={() => props.clickevent(true)}>
                    {t('D210V0058')}
                  </SecondaryButton>
                </div>
              </div>
            </Grid>
            <Grid item>
              <div className={classes.center}>
                <div onClick={props.isZip ? null : handleCloseOK}>
                  <SecondaryButton
                    disabled={props.isZip}
                    onClick={() => props.clickevent(false)}
                  >
                    {t('D210V0059')}
                  </SecondaryButton>
                </div>
              </div>
            </Grid>
          </Grid>
        </DialogActions>
      </MDialog>
    </>
  )
}

export default ShowPdfDialog
