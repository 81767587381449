/* eslint-disable prefer-template */
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import SecondaryButton from '../../common/buttons/SecondaryButton'

const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.buttonSize.width.xsmall,
    height: theme.buttonSize.height.xsmall,
  },
}))

/**
 * @param {Object} props - 以下
 * @param {string} path 押下後の遷移先
 * @param {Event} clickevent - 押下後のイベント
 * @param {object} state - 遷移先に渡すデータ
 * @returns {JSX.Element} - XsmallButtonコンポーネント
 */
const XsmallButton = (props) => {
  const classes = useStyles()
  // eslint-disable-next-line no-unused-vars
  const { addclass, ...rest } = props
  return (
    <SecondaryButton
      addclass={classes.root + ' ' + props.addclass}
      disabled={props.disabled}
      {...rest}
    >
      {props.children}
    </SecondaryButton>
  )
}

export default XsmallButton
