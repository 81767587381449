import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'

import HeaderText from '../../common/text/HeaderText'

const useStyles = makeStyles((theme) => ({
  head: theme.textNormalBLU,
  text: theme.textNormalBLK,
  wordBreak: theme.wordBreak,
}))

/**
 * @param {Object} props - 以下
 * @param {Array} bidInfo - 入札情報
 * @return {JSX} Picコンポーネント
 */
const Pic = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const picArr =
    props.bidInfo.history.location.state?.state.bidInfoObj.picArr ?? []

  return (
    <Grid container spacing={3}>
      <Grid container item>
        <Grid item md={12} xs={12}>
          <HeaderText className={classes.head}>{t('D302V0033')}</HeaderText>
        </Grid>
        {picArr.map((pic, i) => (
          <Grid container item spacing={2} key={i}>
            <Grid item md={3} xs={12}>
              <div className={`${classes.text} ${classes.wordBreak}`}>
                {pic.name}
              </div>
            </Grid>
            <Grid item md={2} xs={12}>
              <div className={`${classes.text} ${classes.wordBreak}`}>
                {pic.position ? pic.position : '-'}
              </div>
            </Grid>
            <Grid item md={3} xs={12}>
              <div className={`${classes.text} ${classes.wordBreak}`}>
                {pic.department ? pic.department : '-'}
              </div>
            </Grid>
            <Grid item md={3} xs={12}>
              <div className={`${classes.text} ${classes.wordBreak}`}>
                {pic.email}
              </div>
            </Grid>
            <Grid item md={1} xs={12}>
              <div className={`${classes.text} ${classes.wordBreak}`}>
                {pic.tel}
              </div>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

Pic.propTypes = {
  bidInfo: PropTypes.object.isRequired,
}

export default Pic
