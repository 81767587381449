import React, { useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'

import Question from './Question'
import CheckBoxes from './CheckBoxes'
import TextBox from './TextBox'
import AppContext from '../../../../../contexts/AppContext'
import { UPDATE_QUESTION2 } from '../../../../../actions'

/**
 * 質問文と選択肢を表示
 * @param {Object} props - 以下
 * @param {Object} question - 以下の要素を含むオブジェクト
 * @param {string} text - 質問文
 * @param {Array} choices - 選択肢の配列
 * @param {Array} radios - ラジオボタンの配列
 * @param {string} value - 各コンポーネントの値
 * @param {Event} onChange - onChangeイベント
 * @returns {*} - Question
 */
const Q2 = () => {
  const { t } = useTranslation()
  const { state, dispatch } = useContext(AppContext)
  const { Q1, Q2 } = state
  const { texts, choices, text, check, errors, helpers } = Q2

  const onChangeCheckBox = (e, value) => {
    dispatch({
      type: UPDATE_QUESTION2,
      answer: value,
      index: e.target.value ?? null,
    })
  }

  const onChangeTextBox = (e) => {
    dispatch({
      type: UPDATE_QUESTION2,
      answer: e.target.value,
      index: null,
    })
  }

  const isTextDisabled = choices.length > 0 && !check[choices.length - 1]
  const checks = check.map((data) => {
    return data === true
  })

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      spacing={2}
    >
      {/* 質問文 */}
      <Question texts={texts} />
      <CheckBoxes
        choices={choices}
        onChange={onChangeCheckBox}
        disabled={!Q1.check[1]}
        check={checks}
      />
      <TextBox
        onChange={onChangeTextBox}
        value={text}
        disabled={isTextDisabled || !Q1.check[1]}
        error={errors}
        helperText={t(helpers)}
      />
    </Grid>
  )
}

export default Q2
