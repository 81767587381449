import { UPDATE_SHIP_FILE } from '../../../actions'

/**
 * state: file情報を保存
 * @param {Object} [state={}] -
 * @param {Object} action -
 * @returns {*} -
 */
const File = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_SHIP_FILE:
      // eslint-disable-next-line no-case-declarations
      const data = { ...state, file: action.file }
      return data
    default:
      return state
  }
}

export default File
