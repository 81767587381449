import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import Title from '../../../../common/title/Title'
import { BeforePath } from '../ConstantsLightBookingDetails'
import AppContext from '../../../../../contexts/AppContext'

/**
 * @return {JSX} タイトルコンポーネント
 */
const TitleLightBookingDetails = () => {
  const { state } = useContext(AppContext)
  const { t } = useTranslation()
  const beforePath = state.ShippingDetails.beforePath
  const title =
    beforePath === BeforePath.BookingList ? t('4th0018') : t('4th0019')
  return useMemo(() => <Title>{title}</Title>, [title])
}

export default TitleLightBookingDetails
