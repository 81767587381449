import React, { useMemo } from 'react'

import Paging from '../../../../commonUseContext/Paging'

/**
 * @return {JSX} ページコンポーネント
 */
const PagingCarFlightMgmt = () => {
  return useMemo(() => <Paging />, [])
}

export default PagingCarFlightMgmt
