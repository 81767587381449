import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'

import AccordionLightBookingDetails from '../Accordion/AccordionLightBookingDetails'
import AddButtonDocumentLightBookingDetails from './AddButtonDocumentLightBookingDetails'
import PdfButtonsDocumentLightBookingDetails from './PdfButtonsDocumentLightBookingDetails'

/**
 * @param {Object} props 下記内容
 * @param {String} props.documentArrName ドキュメントの配列名
 * @param {boolean} props.editable 編集可能
 * @return {JSX} Document欄
 */
const DocumentLightBookingDetails = ({ documentArrName, editable }) => {
  const { t } = useTranslation()

  return useMemo(
    () => (
      <AccordionLightBookingDetails label={t('D010V0058')}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item container md={10}>
            <PdfButtonsDocumentLightBookingDetails
              documentArrName={documentArrName}
              editable={editable}
            />
          </Grid>
          <Grid item md={2}>
            {editable ? (
              <AddButtonDocumentLightBookingDetails
                documentArrName={documentArrName}
              />
            ) : null}
          </Grid>
        </Grid>
      </AccordionLightBookingDetails>
    ),
    [t, documentArrName, editable]
  )
}
DocumentLightBookingDetails.propTypes = {
  documentArrName: PropTypes.string.isRequired,
  editable: PropTypes.bool.isRequired,
}

export default DocumentLightBookingDetails
