import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import AppContext from '../../../../../contexts/AppContext'
import Paths from '../../../../../constants/Paths'
import DetailsButtonCell from '../../../../common/table/DetailsButtonCell/DetailsButtonCell'
import CommonFunc from '../../../../common/function/CommonFunc'
import ApiPaths from '../../../../../constants/ApiPaths'
import Common from '../../../../../constants/Common'

/**
 * APIから入札情報を取得、作成
 * @param {Number} bidId 押下した入札のbidId
 * @param {Boolean} hasUnreadNotify 押下した入札に未読通知があるか
 * @param {*} history react-router-domのuseHistory
 * @return {Object} 入札情報オブジェクト
 */
const createBidPageInfoObj = async (bidId, hasUnreadNotify, history) => {
  // 未読通知がある場合、通知に既読を付ける
  const isAlreadyReadApiSuccessful = hasUnreadNotify
    ? await CommonFunc.updateUnreadNotify(bidId, history)
    : true

  if (isAlreadyReadApiSuccessful) {
    const req = {
      params: {
        bidId,
      },
    }
    // 既読を付けた後に各情報を取得する
    const apiInfoArr = [
      {
        key: 'bidInfoObj',
        method: 'get',
        path: ApiPaths.SHIPPER.BID_INFO,
        req,
      },
      {
        key: 'selectFwdrObj',
        method: 'get',
        path: ApiPaths.SHIPPER.BID_SELECT_FWDR,
        req,
      },
      {
        key: 'bidDetailArr',
        method: 'get',
        path: ApiPaths.SHIPPER.BID_DETAILS,
        req,
      },
      {
        key: 'updatedAtArr',
        method: 'get',
        path: ApiPaths.SHIPPER.BID_UPDATED_AT,
        req,
      },
      {
        key: 'bidStatusObj',
        method: 'get',
        path: ApiPaths.SHIPPER.BID_STATUS,
        req,
      },
    ]
    const resObj = await CommonFunc.execApiAsync(apiInfoArr)
    const isApiSuccessful = CommonFunc.checkApiResponseObj(resObj, history)
    if (isApiSuccessful) {
      // 中身だけを抽出してObject化
      const resArr = Object.entries(resObj).map(([keyStr, res]) => [
        keyStr,
        res.data.results,
      ])
      const stateObj = Object.fromEntries(resArr)
      return stateObj
    }
  }
}

/**
 * 入札情報を取得しページ遷移
 * @param {Number} bidId 押下した入札のbidId
 * @param {Boolean} hasUnreadNotify 押下した入札に未読通知があるか
 * @param {*} history react-router-domのuseHistory
 * @return {void}
 */
const transitionPage = async (bidId, hasUnreadNotify, history) => {
  const pathStr = Paths.SHIPPER.BID_INFO
  const stateObj = await createBidPageInfoObj(bidId, hasUnreadNotify, history)
  const clickEventObj = {
    path: pathStr,
    state: stateObj,
  }
  CommonFunc.clickEvent(clickEventObj, history)
}

/**
 * 表のDetailsに表示するボタンコンポーネント
 * @param {Object} { apiIndexNum } 下記の値を渡す
 * @param {Number} apiIndexNum APIの番号
 * @return {JSX} Common.DETAILS_BUTTONで定義しているボタンコンポーネント
 */
const DetailsButtonCellBidMgmt = ({ apiIndexNum }) => {
  const history = useHistory()
  const { state } = useContext(AppContext)

  // apiIndexNumとstateから必要なapiのデータを取得
  const { bidId } = state.Tables.apiDataArr[apiIndexNum]

  // 未読通知があるか
  const hasUnreadNotify = state.Tables.notifyArr.includes(apiIndexNum)

  // draft状態の確認
  const isDraftButton = state.Tables.draftArr.includes(apiIndexNum)
  const buttonType = isDraftButton
    ? Common.DETAILS_BUTTON.DRAFT
    : Common.DETAILS_BUTTON.CHECK

  return (
    <DetailsButtonCell
      buttonType={buttonType}
      onClick={() => transitionPage(bidId, hasUnreadNotify, history)}
    />
  )
}

DetailsButtonCellBidMgmt.propTypes = {
  apiIndexNum: PropTypes.number.isRequired,
}

export default DetailsButtonCellBidMgmt
