// Display + 画面名
const DisplayFwdrTop = {
  // 画面ID + 文言ID
  D101V0001: {
    en: 'BOOKING',
    ja: '予約',
  },
  D101V0002: {
    en: 'LIST',
    ja: '一覧',
  },
  D101V0003: {
    en: 'when confirming',
    ja: '確認はこちらから',
  },
  D101V0004: {
    en: 'CARGO',
    ja: '出荷',
  },
  D101V0005: {
    en: 'READY',
    ja: '準備',
  },
  D101V0006: {
    en: 'when preparing',
    ja: '準備はこちらから',
  },
  D101V0007: {
    en: 'TRACE',
    ja: '追跡',
  },
  D101V0008: {
    en: 'when tracking',
    ja: '追跡はこちらから',
  },
  D101V0009: {
    en: 'BID',
    ja: '入札',
  },
  D101V0010: {
    en: 'SEARCH',
    ja: '検索',
  },
  D101V0011: {
    en: 'when dealing',
    ja: '取引はこちらから',
  },
}
// Display + 画面名
export default DisplayFwdrTop
