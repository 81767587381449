/* eslint-disable no-extra-parens */
import React from 'react'
import { ThemeProvider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import Loading from './Loading'
import Header from '../../common/header/Header'
import Footer from '../../common/footer/Footer'
import Theme from '../../common/theme/Theme'
import BackgroundBox from '../../common/background/BackgroundBox'
import BackgroundAirplane from '../../common/background/BackgroundAirplane'
import BackgroundLog from '../../common/background/BackgroundLog'
import AirlineStepbar from '../../common/subHeader/AirlineStepbar'
import Stepbar from '../../common/subHeader/Stepbar'
import ErrorDialog from '../../common/dialog/ErrorDialog'
import Paths from '../../../constants/Paths'
import Common from '../../../constants/Common'

const useStyles = makeStyles(() => ({
  padding: {
    height: '1rem',
  },
  fit: {
    height: 'fit-content',
  },
}))

/**
 * ローディング中の画面を表示
 * @param {Object} props - 以下
 * @returns {JSX.Element} - Loadingコンポーネント
 */
const Layout = (props) => {
  const classes = useStyles()

  // Errorページ、メンテンナンスページの場合はすぐに表示する
  const currentPath = window.location.pathname
  const isErrorOrMaintenance =
    currentPath === Paths.OTHERS.ERROR ||
    currentPath === Paths.OTHERS.MAINTENANCE
  const isHidden = !isErrorOrMaintenance
  // メンテナンス画面の際、ヘッダーメニュー非表示
  const isMaintenance = currentPath === Paths.OTHERS.MAINTENANCE

  const subHeader = (props) => {
    if (props.airlineStepbar !== undefined) {
      return (
        <div className={Common.CLASS_NAME.PAGE_CONTENTS} hidden>
          <AirlineStepbar activeStep={props.airlineStepbar} />
        </div>
      )
    }
    if (props.stepbar !== undefined) {
      return (
        <div className={Common.CLASS_NAME.PAGE_CONTENTS} hidden>
          <Stepbar
            className={Common.CLASS_NAME.PAGE_CONTENTS}
            hidden
            activeStep={props.stepbar}
          />
        </div>
      )
    }
  }

  const background = (props) => {
    if (props.box === true) {
      return <BackgroundBox>{content(props)}</BackgroundBox>
    }
    if (props.air === true) {
      return <BackgroundAirplane>{content(props)}</BackgroundAirplane>
    }
    if (props.log === true) {
      return <BackgroundLog>{content(props)}</BackgroundLog>
    }
  }

  const content = (props) => {
    return (
      <div className={classes.fit}>
        {props.box === true ? <Header isMaintenance={isMaintenance} /> : <></>}
        {subHeader(props)}
        <div
          className={Common.CLASS_NAME.PAGE_CONTENTS}
          hidden={isHidden}
          style={{ height: 'fit-content' }}
        >
          {props.children}
          <div className={classes.padding} />
        </div>
      </div>
    )
  }

  return (
    <ThemeProvider theme={Theme}>
      <Loading />
      <ErrorDialog />
      {background(props)}
      {props.box === true ? <Footer /> : <></>}
    </ThemeProvider>
  )
}

export default Layout
