import { Grid } from '@material-ui/core'
import React from 'react'

import Paths from '../../../../../constants/Paths'
import BackButton from '../../../../common/buttons/BackButton'

/**
 * @return {JSX} LightJhClientRegSignUp画面Backボタンコンポーネント
 */
const BackButtonLightJhClientRegSignUp = () => {
  return (
    <Grid container justify="center">
      <BackButton path={Paths.MASTER.JH_CLIENT_REG} />
    </Grid>
  )
}

export default BackButtonLightJhClientRegSignUp
