import {
  INIT_SHIP_TABLE,
  SEARCH_SHIP_TABLE,
  ADD_SHIP_TABLE,
  DELETE_SHIP_TABLE,
} from '../../../actions'
import CommonFunc from '../../../components/common/function/CommonFunc'

/**
 * state: 表のデータを保持
 * @param {Object} [state={}] -
 * @param {Object} action -
 * @returns {*} -
 */
const Tables = (state = {}, action) => {
  switch (action.type) {
    case INIT_SHIP_TABLE:
      // eslint-disable-next-line no-case-declarations
      const initObj = CommonFunc.searchSortData(
        action.data,
        '',
        '',
        '',
        action.amount
      )
      // eslint-disable-next-line no-case-declarations
      const bkup = JSON.parse(JSON.stringify(action.data))
      // eslint-disable-next-line no-case-declarations
      const data = {
        initData: bkup,
        editData: action.data,
        showData: initObj.showData,
        contentNum: action.amount,
      }
      return data
    case SEARCH_SHIP_TABLE:
      // delFlg=1のデータは表示しないため除いておく
      // eslint-disable-next-line no-case-declarations
      const searchDataArr = action.data.filter(
        (searchData) => searchData.delFlg !== 1
      )
      // eslint-disable-next-line no-case-declarations
      const searchObj = CommonFunc.searchSortData(
        searchDataArr,
        '',
        '',
        '',
        action.amount
      )
      return { ...state, showData: searchObj.showData }
    case ADD_SHIP_TABLE:
      // 最大100件まで登録可(100件を超える場合ADDボタンを非活性にしているが念のためここでもチェック)
      if (state.editData.length >= 100) {
        return state
      }
      // 重複チェック(重複する場合、セレクトボックスでの選択非活性にしているが念のためここでもチェック)
      for (let loop = 0; loop < state.editData.length; loop++) {
        // eslint-disable-next-line no-extra-parens
        if (
          state.editData[loop].companyName === action.row.companyName &&
          // eslint-disable-next-line no-extra-parens
          state.editData[loop].countryName === action.row.countryName &&
          // eslint-disable-next-line no-extra-parens
          state.editData[loop].address === action.row.address &&
          // eslint-disable-next-line no-extra-parens
          state.editData[loop].tel === action.row.tel
        ) {
          // 重複している場合は追加しない
          return state
        }
      }
      state.editData.push(action.row)
      return { ...state }
    case DELETE_SHIP_TABLE:
      state.editData[action.index].delFlg = 1
      return { ...state }
    default:
      return state
  }
}

export default Tables
