// Display + 画面名
const DisplayAirlineCassDetails = {
  // 画面ID + 文言ID
  D218V0001: {
    en: 'CASS DETAILS',
    ja: 'CASS 詳細',
  },
  D218V0002: {
    en: 'Date Period Start',
    ja: '清算開始日',
  },
  D218V0003: {
    en: 'Date Period End',
    ja: '清算終了日',
  },
  D218V0004: {
    en: 'Base Input',
    ja: 'ベース入力',
  },
  D218V0005: {
    en: 'Commission Percentage',
    ja: '手数料',
  },
  D218V0006: {
    en: 'Rate of Exchange',
    ja: '為替レート',
  },
  D218V0007: {
    en: '%',
    ja: '%',
  },
  D218V0008: {
    en: 'Output',
    ja: '出力',
  },
  D218V0009: {
    en: 'Required',
    ja: '必須項目',
  },
  D218V0010: {
    en: 'AWB Record',
    ja: 'AWB Record',
  },
  D218V0011: {
    en: 'CCO Record',
    ja: 'CCO Record',
  },
  D218V0012: {
    en: 'CCR Record',
    ja: 'CCR Record',
  },
  D218V0013: {
    en: 'AW1 Record',
    ja: 'AW1 Record',
  },
  D218V0014: {
    en: 'CC3 Record',
    ja: 'CC3 Record',
  },
  D218V0015: {
    en: 'DC3 Record',
    ja: 'DC3 Record',
  },
  D218V0016: {
    en: 'Trailer Record',
    ja: 'Trailer Record',
  },
  D218V0017: {
    en: 'CBV Record',
    ja: 'CBV Record',
  },
  D218V0018: {
    en: 'Edit',
    ja: '編集',
  },
  D218V0019: {
    en: 'Create',
    ja: '作成',
  },
  D218V0020: {
    en: 'File Name',
    ja: 'ファイル名',
  },
  D218V0021: {
    en: 'Create at',
    ja: '作成日時',
  },
  D218V0022: {
    en: 'Download',
    ja: 'ダウンロード',
  },
  D218V0023: {
    en: '※2 needs to be edited',
    ja: '※2 は編集が必要です',
  },
  D218V0024: {
    en: '※',
    ja: '※',
  },
  D218V0025: {
    en: '※1 "?" is found. Check the above records',
    ja: '※1 「？」が見つかりました。上記のレコードを確認してください',
  },
  D218V0026: {
    en: 'Please enter within 8 digits',
    ja: '8桁以内で入力してください',
  },
  D218V0027: {
    en: 'Please enter within 4 digits',
    ja: '4桁以内で入力してください',
  },
  D218V0028: {
    en: 'Contains invalid characters.',
    ja: '無効な文字が含まれています。',
  },
  D218V0029: {
    en: 'You can only enter 1 or 16 days',
    ja: '1日または16日しか入力できません',
  },
  D218V0030: {
    en: 'DCO Record',
    ja: 'DCO Record',
  },
  D218V0031: {
    en: 'DCR Record',
    ja: 'DCR Record',
  },
  D218V0032: {
    en: 'Downloadable date',
    ja: 'ダウンロード可能な日付',
  },
  D218V0033: {
    en: 'You cannot enter a date in the past',
    ja: '過去の日付は入力できません',
  },
  D218V0034: {
    en: 'No Record',
    ja: 'レコードがありません。',
  },
  D218V0035: {
    en: 'CASS data could not be created.',
    ja: 'CASSデータが作成できませんでした。',
  },
  D218V0036: {
    en: '※3 「No Record Data」 has been selected.',
    ja: '※3 作成されていないレコードが選択されています。',
  },
  D218V0037: {
    en: 'This date has already been registered.',
    ja: 'この日付は既に登録済です。',
  },
}
// Display + 画面名
export default DisplayAirlineCassDetails
