import { UPDATE_FWDR_BACKUP } from '../../../actions'

const initBackup = {
  name: '',
  lastName: '',
  email: '',
  tel: '',
  position: '',
  mailSetting: false,
}

const Events = (state = {}, action) => {
  switch (action.type) {
    //------------------
    // backupを更新
    //------------------
    case UPDATE_FWDR_BACKUP:
      return { ...state, ...action.info }
    default:
      return state
  }
}

export default Events

export { initBackup }
