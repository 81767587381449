import Common from '../../../../constants/Common'

/** 表示する画面のフラグ */
const ShowFlg = {
  BOTH: 'both',
  NEW: 'new',
  SELECT: 'select',
}

/**
 * 画面の状態に合わせたテーブル情報を返す
 * @param {Boolean} isNew state.IsNewの値
 * @returns {Array} テーブル情報の配列
 */
const getTableArr = (isNew) =>
  ConstantsConfirmation.TABLE_ARR.filter(({ showFlg }) => {
    switch (showFlg) {
      case ShowFlg.BOTH:
        return true
      case ShowFlg.NEW:
        return isNew
      case ShowFlg.SELECT:
        return !isNew
      default:
        return true
    }
  })

/**
 * テーブル情報を作成する
 * @param {string} headerKey ヘッダーに表示する値
 * @param {string} cellKey セルに表示するデータ名
 * @param {string} showFlg ShowFlgの値
 * @param {boolean} required 必須項目か
 * @param {boolean} rightBorder 右側の罫線を表示するか
 * @param {string} [cellCategory=null] Common.CELL_CATEGORYの値
 * @param {string} [relationCellKey=null] 関連するデータ名
 * @returns {object} 1列分のテーブル情報
 */
const createTableObj = (
  headerKey,
  cellKey,
  showFlg,
  required = false,
  rightBorder = true,
  cellCategory = Common.CELL_CATEGORY.TEXT,
  relationCellKey
) => {
  return {
    headerKey,
    cellKey,
    showFlg,
    required,
    rightBorder,
    cellCategory,
    relationCellKey,
  }
}

const ConstantsConfirmation = {
  //1ページに表示する件数
  PAGE_AMOUNT: 15,

  // テーブル作成に必要な値
  TABLE_ARR: [
    createTableObj(
      'D014V0002',
      'apiIndexNum',
      ShowFlg.BOTH,
      false,
      true,
      Common.CELL_CATEGORY.NO
    ),
    createTableObj('D111V0076', 'orgStr', ShowFlg.BOTH),
    createTableObj('D111V0077', 'dstStr', ShowFlg.BOTH),
    createTableObj(
      '3rd0018',
      'transitStr',
      ShowFlg.BOTH,
      false,
      true,
      Common.CELL_CATEGORY.SELECT.AIRPORT
    ),
    createTableObj('D015V0008', 'flightTypeStr', ShowFlg.BOTH),
    createTableObj('D301V0073', 'ttStr', ShowFlg.BOTH),
    createTableObj('D501V0005', 'airlineStr', ShowFlg.BOTH),
    createTableObj('2nd0005', 'commStr', ShowFlg.BOTH),
    createTableObj('2nd0016', 'termsStr', ShowFlg.BOTH),
    createTableObj('3rd0019', 'leadTimeStr', ShowFlg.BOTH),
    createTableObj('3rd0020', 'ttlWgt', ShowFlg.BOTH),
    createTableObj('3rd0021', 'ttlVol', ShowFlg.BOTH),
    createTableObj('2nd0017', 'fscStr', ShowFlg.BOTH),
    createTableObj('D301V0089', 'minrateStr', ShowFlg.BOTH, false, false),
    createTableObj('D301V0090', 'normalStr', ShowFlg.BOTH, false, false),
    createTableObj('D301V0091', 'minStr', ShowFlg.BOTH, false, false),
    createTableObj('D301V0092', 'lightStr', ShowFlg.BOTH, false, false),
    createTableObj('D301V0093', 'mediumStr', ShowFlg.BOTH, false, false),
    createTableObj('D301V0094', 'largeStr', ShowFlg.BOTH, false, false),
    createTableObj('D301V0095', 'maxStr', ShowFlg.BOTH, false, true),
    createTableObj('D103V0103', 'currencyStr', ShowFlg.BOTH),
    createTableObj('3rd0023', 'remark', ShowFlg.BOTH),
  ],

  //グレーアウトするステータス
  GRAY_OUT_STATUS_ID_ARR: [Common.BID_STATUS.LOST.ID, Common.BID_STATUS.NA.ID],
}

export default ConstantsConfirmation
export { getTableArr }
