import React, { useContext } from 'react'
import { Grid } from '@material-ui/core'

import RejectButton from '../../../../common/buttons/RejectButton'
import AcceptButton from '../../../../common/buttons/AcceptButton'
import AppContext from '../../../../../contexts/AppContext'
import { DIALOG } from '../../../../../actions'

/**
 * @param {Object} data リクエスト情報オブジェクト
 * @return {JSX} Accept、Rejectボタンコンポーネント
 */
const RequestCardButtonsLightJhClientRegDeleteAccount = ({ data }) => {
  const { dispatch } = useContext(AppContext)

  /**
   * @returns {void} Rejectダイアログを開く
   */
  const openRejectDialog = () => {
    dispatch({
      type: DIALOG.OPEN_REJECT,
      data,
    })
  }

  /**
   * @returns {void} Acceptダイアログを開く
   */
  const openAcceptDialog = () => {
    dispatch({
      type: DIALOG.OPEN_ACCEPT,
      data,
    })
  }

  return (
    <Grid container item xs={11} justify="center" spacing={4}>
      <Grid container item xs={6} justify="center">
        <RejectButton onClick={openRejectDialog} />
      </Grid>
      <Grid container item xs={6} justify="center">
        <AcceptButton onClick={openAcceptDialog} />
      </Grid>
    </Grid>
  )
}

export default RequestCardButtonsLightJhClientRegDeleteAccount
