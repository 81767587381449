import { PAGE_DATA } from '../../../actions'

const initPageDataBidInfo = {}

/**
 * 前ページから受け取ったPageData情報を保持
 * @param {Object} [state=initPageDataBidInfo] PageDataBidInfoのstate
 * @param {Object} action dispatchで受け取ったaction
 * @return {Object} PageData情報を保持したstate
 */
const PageDataBidInfo = (state = initPageDataBidInfo, action) => {
  switch (action.type) {
    case PAGE_DATA.INIT:
      return action.data
    default:
      return state
  }
}

export default PageDataBidInfo

export { initPageDataBidInfo }
