import React from 'react'
import { Grid, makeStyles, Paper } from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  paper: {
    ...theme.requestCard,
    ...theme.childrenPaddingSmall,
  },
  padding: theme.childrenPaddingSmall,
}))

/**
 * リクエストカード背景
 * @param {JSX} children リクエストカードの中身
 * @return {JSX} リクエストカード背景コンポーネント
 */
const RequestCardBackground = ({ children }) => {
  const classes = useStyles()

  return (
    <Paper elevation={0} variant="outlined" className={classes.paper}>
      <Grid container item xs={12} justify="center" className={classes.padding}>
        {children}
      </Grid>
    </Paper>
  )
}

RequestCardBackground.propTypes = {
  children: PropTypes.node.isRequired,
}

export default RequestCardBackground
