import { Grid } from '@material-ui/core'
import React from 'react'

import BackButtonConfirmation from './BackButtonConfirmation'
import OkButtonConfirmation from './OkButtonConfirmation'

/**
 * @return {JSX} ボタンコンポーネント
 */
const ButtonConfirmation = () => {
  return (
    <Grid item container justify="center" md={12} spacing={2}>
      <Grid item md={12} />
      <Grid item>
        <BackButtonConfirmation />
      </Grid>
      <Grid item>
        <OkButtonConfirmation />
      </Grid>
    </Grid>
  )
}

export default ButtonConfirmation
