import { Grid } from '@material-ui/core'
import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { CHECKBOX } from '../../../../../actions'
import AppContext from '../../../../../contexts/AppContext'
import CheckBox from '../../../../common/checkBox/CheckBox'

/**
 * checkboxを表示
 * @return {JSX} チェックボックスコンポーネント
 */
const CheckboxCarFlightMgmt = () => {
  const { state, dispatch } = useContext(AppContext)
  const { t } = useTranslation()

  return useMemo(() => {
    /**
     * チェック状態を更新
     * @param {Boolean} checked チェック状態を更新
     * @returns {void} なし
     */
    const onChange = (checked) => {
      dispatch({
        type: CHECKBOX.SET,
        checked,
      })
    }

    return (
      <Grid container justify="center">
        <Grid item>
          <CheckBox
            label={t('4th0008')}
            checked={state.Checkbox}
            onChange={(event) => onChange(event.target.checked)}
          />
        </Grid>
      </Grid>
    )
  }, [dispatch, state.Checkbox, t])
}

export default CheckboxCarFlightMgmt
