import React from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  makeStyles,
} from '@material-ui/core'
import PropTypes from 'prop-types'

import OkButtonCommonDialog from './OkButtonCommonDialog'
import CancelButtonCommonDialog from './CancelButtonCommonDialog'
import DeleteButtonCommonDialog from './DeleteButtonCommonDialog'
import Common from '../../../../constants/Common'
import SaveButtonCommonDialog from './SaveButtonCommonDialog'
import BackButtonCommonDialog from './BackButtonCommonDialog'

const useStyles = makeStyles((theme) => ({
  childrenPadding: theme.childrenPaddingSmall,
}))

/**
 * @param {Object} props 以下
 * @param {Boolean} open ダイアログの開閉状態
 * @param {Function} closeFunc ダイアログ外をクリックした時のcloseイベント
 * @param {JSX} children ダイアログ内に表示する要素
 * @param {Object} dialogFuncObj ダイアログのボタン設定
 * @returns {JSX} 共通ダイアログコンポーネント
 */
const CommonDialog = (props) => {
  const { open, closeFunc, children, dialogFuncObj, ...restProps } = props
  const dialogFuncArr = Object.entries(dialogFuncObj)
  const classes = useStyles()

  /**
   * ボタン作成
   * dialogFuncObjに設定しているボタンを表示
   * [Common.DIALOG.BUTTON_CATEGORY.CANCEL]: { onClick: closeDialog },のように設定
   * @param {String} category Commonで設定しているカテゴリ
   * @param {Object} buttonProps ボタンに適用したい設定
   * @return {JSX} ボタンコンポーネント
   */
  const createButtons = ([category, buttonProps]) => {
    switch (category) {
      case Common.DIALOG.BUTTON_CATEGORY.OK:
        return <OkButtonCommonDialog key={category} {...buttonProps} />
      case Common.DIALOG.BUTTON_CATEGORY.CANCEL:
        return <CancelButtonCommonDialog key={category} {...buttonProps} />
      case Common.DIALOG.BUTTON_CATEGORY.DELETE:
        return <DeleteButtonCommonDialog key={category} {...buttonProps} />
      case Common.DIALOG.BUTTON_CATEGORY.SAVE:
        return <SaveButtonCommonDialog key={category} {...buttonProps} />
      case Common.DIALOG.BUTTON_CATEGORY.BACK:
        return <BackButtonCommonDialog key={category} {...buttonProps} />
      default:
        return null
    }
  }

  return (
    <Dialog open={open} onClose={closeFunc} fullWidth {...restProps}>
      <DialogContent>
        <div className={classes.childrenPadding}>{children}</div>
      </DialogContent>
      <DialogActions>
        <Grid container justify="center" className={classes.childrenPadding}>
          <Grid container item xs={10}>
            {dialogFuncArr.map(createButtons)}
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

CommonDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  closeFunc: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  dialogFuncObj: PropTypes.objectOf(PropTypes.object).isRequired,
}

export default CommonDialog
