import { CONSIGNEE } from '../../actions'
import CommonFunc from '../../components/common/function/CommonFunc'

const initConsignee = {
  // 現在選択中のConsignee情報
  consigneeInfoObj: {
    consigneeAddress: '',
    consigneeCompanyName: '',
    consigneeTel: '',
    consigneeFirstName: '',
    consigneeLastName: '',
    consigneeId: null,
  },
  // 表示用の配列
  displayArr: [],
  // APIから取得したConsigneeリスト
  consigneeArr: [],
  // エラー状態 未選択状態の場合エラー
  isError: false,
}

/**
 * APIから取得したConsigneeリストを保持
 * @param {Object} action dispatchで受け取ったaction
 * @param {Object} state Consigneeのstate
 * @return {Object} 更新したstate
 */
const setConsigneeList = (action, state) => {
  const { consigneeArr } = action
  return {
    ...state,
    consigneeArr,
  }
}

/**
 * 画面遷移時の初期state設定
 * @param {Object} action dispatchで受け取ったaction
 * @param {Object} state Consigneeのstate
 * @return {Object} 更新したstate
 */
const setInitConsignee = (action, state) => {
  // Consignee情報
  const { consigneeInfoObj } = action
  const { consigneeId } = consigneeInfoObj

  if (consigneeId) {
    // 登録済みの場合
    const {
      consigneeAddress,
      consigneeCompanyName,
      consigneeTel,
      consigneeFirstName,
      consigneeLastName,
    } = consigneeInfoObj
    const displayArr = CommonFunc.createAddressArr(
      consigneeAddress,
      consigneeCompanyName,
      consigneeTel,
      consigneeFirstName,
      consigneeLastName
    )
    return {
      ...state,
      consigneeInfoObj: {
        consigneeId,
        consigneeAddress,
        consigneeCompanyName,
        consigneeTel,
        consigneeFirstName,
        consigneeLastName,
      },
      displayArr,
      isError: false,
    }
  } else {
    // 新規登録の場合
    return {
      ...state,
      isError: true,
    }
  }
}

/**
 * 選択したConsignee情報をstateに保持
 * @param {Object} action dispatchで受け取ったaction
 * @param {Object} state Consigneeのstate
 * @return {Object} 保存、表示用データを更新したstate
 */
const setConsignee = (action, state) => {
  const { consigneeObj } = action
  const { address, companyName, tel, firstName, lastName, consigneeId } =
    consigneeObj

  const consigneeInfoObj = {
    consigneeAddress: address,
    consigneeCompanyName: companyName,
    consigneeTel: tel,
    consigneeFirstName: firstName,
    consigneeLastName: lastName,
    consigneeId,
  }

  const displayArr = CommonFunc.createAddressArr(
    address,
    companyName,
    tel,
    firstName,
    lastName
  )

  return {
    ...state,
    consigneeInfoObj,
    displayArr,
    isError: false,
  }
}

/**
 * Consignee情報を保持
 * @param {Object} [state=initConsignee] Consigneeのstate
 * @param {Object} action dispatchで受け取ったaction
 * @return {Object} Consignee情報を保持したstate
 */
const Consignee = (state = initConsignee, action) => {
  switch (action.type) {
    case CONSIGNEE.SET_LIST:
      return setConsigneeList(action, state)
    case CONSIGNEE.INIT:
      return setInitConsignee(action, state)
    case CONSIGNEE.SET:
      return setConsignee(action, state)
    default:
      return state
  }
}

export default Consignee

export { initConsignee }
