/* eslint-disable no-extra-parens */
/* eslint-disable prefer-template */
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Link from '@material-ui/core/Link'
import { Divider } from '@material-ui/core'

import SecondaryButton from '../buttons/SecondaryButton'
import CommonFunc from '../function/CommonFunc'

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.fontSize.paging,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    marginTop: '10px',
  },
  dialog: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 4,
    width: '700px',
    padding: '0px',
    margin: '1rem',
  },
  root: {
    '& .MuiDialogContent-root': {
      padding: 0,
      width: 'fit-content',
      margin: '10px auto',
    },
    '& .MuiDialogContent-root:first-child': {
      paddingBottom: 20,
    },
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      width: theme.buttonSize.width.medium,
      height: theme.buttonSize.height.medium,
    },
    [theme.breakpoints.up('md')]: {
      width: theme.buttonSize.width.small,
      height: theme.buttonSize.height.small,
    },
  },
  head: {
    fontFamily: theme.typography.fontFamily,
    color: theme.fontColor.main,
    fontSize: theme.fontSize.regular,
    fontWeight: 'bold',
    marginBottom: '10px',
    marginTop: '10px',
  },
  text: {
    fontFamily: theme.typography.fontFamily,
    color: theme.fontColor.main,
    fontSize: theme.fontSize.regular,
    marginBottom: '10px',
    marginTop: '10px',
  },
  textItem: {
    fontFamily: theme.typography.fontFamily,
    color: theme.fontColor.main,
    fontSize: theme.fontSize.regular,
    marginBottom: '10px',
    marginTop: '10px',
    marginLeft: '20px',
  },
  headJa: {
    fontFamily: theme.typography.fontFamily,
    color: theme.fontColor.main,
    fontSize: theme.fontSize.regular,
    fontWeight: 'bold',
    marginBottom: '10px',
    marginTop: '10px',
  },
  textItemJa: {
    fontFamily: theme.typography.fontFamily,
    color: theme.fontColor.main,
    fontSize: theme.fontSize.header,
    marginBottom: '10px',
    marginTop: '10px',
    marginLeft: '10px',
  },
  textJa: {
    fontFamily: theme.typography.fontFamily,
    color: theme.fontColor.main,
    fontSize: theme.fontSize.header,
    marginBottom: '10px',
    marginTop: '10px',
  },
  textIndent: {
    textIndent: '-1.5rem',
    paddingLeft: '1.5rem',
  },
  subTextIndent: {
    textIndent: '-1.2rem',
    paddingLeft: '1.2rem',
  },
  textItemsIndent: {
    textIndent: '-0.9rem',
    paddingLeft: '0.9rem',
  },
  right: {
    textAlign: 'right',
  },
  margin: {
    margin: '20px',
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
    margin: '5%',
  },
  leftIndex: {
    marginLeft: '50px',
  },
  mobile: {
    margin: 10,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  desktop: {
    margin: 10,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  link: {
    display: 'block',
    marginTop: '15px',
    marginLeft: 3,
    marginRight: 3,
    width: 'fit-content',
    color: theme.palette.back.main,
    fontSize: theme.fontSize.header,
    fontFamily: theme.typography.fontFamily,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}))

const DialogLink = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [scroll, setScroll] = useState('body')
  const [pageX, setPageX] = useState(0)

  const handleClickOpen = (scrollType) => () => {
    // windowのスクロール設定を記録してから非表示
    CommonFunc.hideScroll()
    setOpen(true)
    setScroll(scrollType)
  }

  /**
   * ダイアログを閉じる
   * @param {Event} event - onCloseのイベント
   * @param {Boolean} isDialogBackGround - ダイアログ背景をクリックしているかの判定
   * @returns {void}
   */
  const handleClose = (event, isDialogBackGround) => {
    /** 現在クリック位置が要素外(スクロール部分)をクリックしているか判定 */
    const isScrollClick = isDialogBackGround
      ? event.target.clientWidth - pageX < 0
      : false

    if (isScrollClick) {
      // スクロール部分をクリックしている場合は何もしない
      return
    } else {
      // windowのスクロール設定を戻す
      CommonFunc.showScroll()
      // ダイアログを閉じる
      setOpen(false)
    }
  }

  /**
   * クリックした瞬間の位置を保存
   * @param {Event} event - event onMousedownのイベント
   * @returns {void}
   */
  const savePageXPosition = (event) => setPageX(event.pageX)

  // 利用規約タイトル
  const titleTermsOfUseObj = {
    jaTitle: '利用規約',
    jaText:
      'この利用規約（以下、「本規約」といいます。）は、株式会社CargoLabo(以下、「当社」といいます。)が当社ウェブサイト上で提供する「CargoLabo」（以下、「本サービス」といいます。）の利用条件を定めるものです。本サービスの利用登録をし当社との間で本サービスの利用契約を締結した登録ユーザーの皆様（以下、「ユーザー」といいます。）には、本規約に従って本サービスをご利用いただきます。',
    enTitle: 'Terms of Use',
    enText:
      'These terms and conditions of use (the "Terms of Use") apply to all website users. The Terms of Use apply to the "CargoLabo" (the "Service") provided by CargoLabo Inc. (the "Company") on the company\'s website. The company provides on the company\'s website. All registered users (the "Users") who have registered to use the service and have entered a contract with the company to use the service shall use the service by this agreement. All users who have registered to use the service and have entered a contract to use the service with the company shall use the service by these Terms.',
    jaClosings: ['2021年12月1日　　制定・施行', '2024年6月1日　　改定'],
    jaSubClosing: '以上',
    enClosings: [
      'It was enacted and enforced on December 1, 2021.',
      'It was revised on June 1, 2024.',
    ],
  }

  // PrivacyPolicyタイトル
  const titlePrivacyPolicyObj = {
    jaTitle: 'プライバシーポリシー',
    jaText:
      '株式会社CargoLabo（以下、「当社」といいます。）は、当社ウェブサイト上で提供する「CargoLabo」（以下、「本サービス」といいます。）におけるユーザーの個人情報等を含むユーザー情報（以下、「個人情報等」といいます。）の取扱いについて、以下のとおり、プライバシーポリシー（以下、「本ポリシー」といいます。）を定めます。なお、「個人情報」とは、個人情報の保護に関する法律（平成15年法律第57号、以下、「個人情報保護法」といいます。）第2条1項にいう「個人情報」を指します。',
    enTitle: 'Privacy Policy',
    enText:
      'CargoLabo Inc. (the "Company") is pleased to announce that the company will use the user information, including the user\'s personal information, in the "CargoLabo" (the "Service") provided on the company\'s website. The company has established the following policies regarding handling user information, including the user\'s personal information on the service. (the "Policy").The term "personal information" refers to "personal information" as defined in Article 2, Paragraph 1 of the Act on the Protection of Personal Information (Act No. 57 of 2003, the "Personal Information Protection Act"). The term "personal information" refers to "personal information" as defined in Article 2.1 of the Act on the Protection of Personal Information.',
    jaClosings: ['2021年9月1日　　制定・施行', '2024年6月1日　　改定'],
    jaSubClosing: '以上',
    enClosings: [
      'It was enacted and enforced on December 1, 2021.',
      'It was revised on June 1, 2024.',
    ],
  }

  // 利用規約日本語版
  const termsOfUseJaArr = [
    {
      head: '第１条（利用規約の適用）',
      text: [
        '本規約は、ユーザーと当社との間の本サービスの利用に関する一切の関係に適用されるものとします。',
        '本規約の内容に同意しない場合、本サービスを利用することはできません。ユーザーは、本サービスを実際に利用することによって本規約に同意をしたものとみなされます。',
        '当社は、本サービスに関し、本規約のほか、ご利用にあたってのルールや利用マニュアル等、各種の定め（以下、「個別規定」といいます。）をすることがあります。これら個別規定は、その名称のいかんにかかわらず、本規約の一部を構成するものとします。',
        '本規約の規定が個別規定と異なる場合には、個別規定において特段の定めがない限り、個別規定が優先されるものとします。',
      ],
    },
    {
      head: '第２条（利用登録及び個人情報等の取扱い）',
      text: [
        '本サービスにおいては、本サービスの利用登録希望者（以下、「登録希望者」といいます。）が本規約に同意の上、当社の定める方法によって利用登録を申請し、当社がこれを承認することによって、利用登録が完了するものとします。',
        '登録希望者は、利用登録の申請にあたり、当社の定める一定の情報（以下、「登録事項」といいます。）について、真実、正確かつ完全な情報を提供するものとします。',
        '当社は、登録事項のほか、本規約に基づいて提供された登録希望者及びユーザーの情報（個人情報を含む）並びにユーザーの本サービスの利用により当社が取得した情報（個人情報を含む）について、別途定める当社の「プライバシーポリシー」に従い適切に取り扱うものとします。なお、当社は、登録事項につき、個人を特定できない形で統計的な情報として、当社の裁量で利用及び公開することができるものとし、ユーザーはこれに異議を唱えないものとします。',
        {
          subText:
            '当社は、登録希望者が次の各号のいずれかに該当する場合には、利用登録の申請を承認しないことがあります。また、当社は、一度利用登録の申請を承認した後でも、利用登録を取り消すことができるものとします。なお、当社は、利用登録の不承認や取消の理由につき、一切の開示義務及び損害賠償義務を負わないものとします。',
          textItem: [
            '登録事項について虚偽の情報を提供した場合',
            '本規約に違反したことがある者からの申請である場合',
            'その他当社が利用登録を適当でないと判断した場合',
          ],
        },
      ],
    },
    {
      head: '第３条（登録事項の変更等）',
      text: 'ユーザーは、登録事項に変更が生じたときは、当社の定める方法により速やかに本サービス上で登録事項を変更するものとします。登録事項の変更を行わなかったことにより生じたユーザーの損害について、当社は一切の責任を負いません。',
    },
    {
      head: '第４条（ユーザーＩＤ及びパスワードの管理）',
      text: [
        'ユーザーは、自己の責任において、本サービスのユーザーＩＤ及びパスワードを適切に管理するものとします。',
        'ユーザーは、いかなる場合にも、ユーザーＩＤ及びパスワードを第三者に開示、譲渡または貸与し、もしくは第三者と共用することはできません。当社は、ユーザーＩＤ及びパスワードの組み合わせが登録事項と一致してログインされた場合には、そのユーザーＩＤを登録しているユーザー自身による利用とみなします。',
        'ユーザーは、自身のユーザーＩＤ及びパスワードが第三者に使用されている疑いがあるときは、直ちに当社にその旨を連絡するとともに、当社の指示がある場合にはこれに従うものとします。この場合、当社は、当該ユーザーＩＤ及びパスワードの利用停止措置をとることができるものとします。',
        '当社は、一定回数以上にわたりログインに失敗したなど、ユーザーＩＤ及びパスワードに不正利用の可能性があると判断した場合は、当該ユーザーＩＤ及びパスワードの利用停止措置をとるものとし、ユーザーは、当社の指示に従い利用停止措置の解除を行うものとします。当該利用停止措置によって生じたユーザーの損害については、当社は一切の責任を負いません。',
        'ユーザーは、自身のユーザーＩＤ及びパスワードの管理に全責任を負うものとし、その管理不十分、第三者の使用等によって生じた損害について、当社は一切の責任を負いません。',
      ],
    },
    {
      head: '第５条（利用料金及び支払方法）',
      text: [
        'ユーザーは、当社が別途定めた利用料金を、当社が指定する方法により支払うものとします。',
        'ユーザーは、利用料金の支払を遅滞した場合には、年１４.６％の割合による遅延損害金を支払うものとします。',
      ],
    },
    {
      head: '第６条（禁止事項）',
      text: [
        {
          subText:
            'ユーザーは、本サービスの利用にあたり、以下の行為をしてはなりません。',
          textItem: [
            '法令または公序良俗に違反する行為',
            '犯罪行為に関連する行為',
            '本サービスの内容等、本サービスに含まれる当社または第三者の著作権、商標権その他の知的財産権を侵害する行為',
            '当社または第三者のプライバシー、名誉または信用を侵害する行為',
            'ユーザーＩＤ及びパスワードを第三者に開示、譲渡または貸与し、もしくは第三者と共用する行為',
            '当社、他のユーザーまたはその他第三者のサーバーもしくはネットワークの機能を破壊、妨害する行為',
            '本サービスによって得られた情報を商業的に利用する行為',
            '本サービスの運営を妨害するおそれのある行為',
            '不正アクセス行為の禁止等に関する法律に違反する行為、電子計算機損壊等業務妨害罪（刑法第234条の2）に該当する行為をはじめ、当社及び他人のコンピュータに対して不正な操作を行う行為',
            '他のユーザーに関する個人情報等を収集または蓄積する行為',
            '不正な目的を持って本サービスを利用する行為',
            '他のユーザーまたはその他の第三者に不利益、損害もしくは不快感を与える行為',
            '他のユーザーに成りすます行為',
            '本サービス上での宣伝、広告、勧誘または営業行為',
            '当社のサービスに関連して、反社会的勢力に対して直接または間接に利益を供与する行為',
            '前各号の行為を直接もしくは間接に惹起し、または容易にする行為',
            'その他当社が不適切と判断する行為',
          ],
        },
        'ユーザーに前項各号の違反があったときは、当社は、当該ユーザーに対し、これによって被った損害（付随的損害、間接損害、特別損害、将来の損害及び弁護士費用）の一切につき賠償請求ができるものとします。',
      ],
    },
    {
      head: '第７条（サービスの提供の停止等）',
      text: [
        {
          subText:
            '当社は、以下のいずれかの事由があると判断した場合、事前に通知することなく、本サービスの全部もしくは一部の提供を停止または中止することができるものとします。',
          textItem: [
            '本サービスにかかるコンピュータシステムの保守点検または更新を行う必要がある場合',
            '地震、落雷、火災、風水害、停電、騒乱、暴動、伝染病その他天災地変等の不可抗力により本サービスの提供が困難となった場合',
            'コンピュータまたは通信回線等が事故により停止した場合',
            'その他当社が本サービスの提供が困難と判断した場合',
          ],
        },
        '当社は、前項に基づく本サービスの提供の停止または中止によりユーザーまたは第三者が被ったいかなる不利益または損害についても、一切の責任を負いません。',
      ],
    },
    {
      head: '第８条（利用制限及び登録抹消）',
      text: [
        {
          subText:
            '当社は、ユーザーが以下のいずれかに該当する場合には、事前に通知することなく、ユーザーに対して、本サービスの全部もしくは一部の利用を制限し、または本サービスの利用契約を解除の上、ユーザーとしての登録を抹消することができるものとします。',
          textItem: [
            '本規約のいずれかの条項に違反したとき',
            '登録事項に虚偽の情報があることが判明したとき',
            '当社からの問合せその他回答を求める連絡に対し30日以上応答がないとき',
            '本サービスの最終利用から一定期間利用がないとき',
            '利用料金等の債務の不履行があったとき',
            '手形または小切手の不渡りがあったとき',
            '差押え、仮差押え、仮処分その他の強制執行手続または滞納処分を受けたとき',
            '破産手続、民事再生手続、会社更生手続または特別清算手続の開始の申立てがされたとき',
            '前4号のほかユーザーの信用状態に重大な変化が生じたとき',
            '解散または営業停止となったとき',
            '営業方法等について行政当局による注意、勧告または行政処分を受けたとき',
            'その他当社が本サービスの利用を適当でないと判断した場合',
          ],
        },
        '当社は、前項に基づき当社が行った行為によりユーザーが被ったいかなる不利益または損害についても、一切の責任を負いません。',
      ],
    },
    {
      head: '第９条（退会）',
      text: 'ユーザーは、当社の定める退会手続により、本サービスから退会できるものとします。',
    },
    {
      head: '第１０条（サービス内容の変更等）',
      text: [
        '当社は、事前に通知することなく、本サービスの内容または名称を変更することがあります。',
        '前項の変更を行った場合、その内容について、当社ウェブサイト（ＵＲＬ：https://cargolabo.com/）に掲示する方法その他当社が適当と判断する方法により速やかに周知するものとします。',
        '前項の周知後にユーザーが本サービスの利用を継続したときは、第1項の変更に同意したものとみなします。',
      ],
    },
    {
      head: '第１１条（通知または連絡）',
      text: 'ユーザーと当社との間の通知または連絡は、当社の定める方法によって行うものとします。当社は、ユーザーから連絡先について当社が別途定める方式に従った変更届出がない限り、現在登録されている連絡先が有効なものとみなして当該連絡先へ通知または連絡を行い、これらは発信時にユーザーへ到達したものとみなします。',
    },
    {
      head: '第１２条（委託）',
      text: '当社は、本サービスに関する業務の全部または一部を第三者へ委託することができるものとします。',
    },
    {
      head: '第１３条（権利義務の譲渡の禁止）',
      text: [
        'ユーザーは、当社の書面による事前の承諾なく、本サービスの利用契約上の地位または本規約に基づく権利もしくは義務について、第三者に対し、譲渡、移転、担保設定その他の処分をすることはできません。',
        '当社は、本サービスにかかる事業を他社へ譲渡した場合には、当該事業譲渡に伴い本サービスの利用契約上の地位、本規約に基づく権利及び義務並びにユーザーの登録事項その他の顧客情報を当該事業譲渡の譲受人に譲渡することができるものとし、ユーザーは、当該譲渡について、本項において予め同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず会社分割その他事業が移転するあらゆる場合を含むものとします。',
      ],
    },
    {
      head: '第１４条（知的財産権の帰属）',
      text: '本サービスに関する一切の知的財産権は当社及び正当な権利を有する第三者に帰属するものとし、ユーザーには何らの権利も移転せず、本サービスの非独占的な利用権のみが付与されるものとします。',
    },
    {
      head: '第１５条（当社の責任の範囲）',
      text: [
        '当社は、本サービスに事実上または法律上の瑕疵（安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、権利侵害などを含みます。）がないことを保証しておりません。また、当社は、これらの瑕疵に起因する当社による本サービスの提供の停止、中止、利用制限または登録事項の削除もしくは消失その他本サービスに関してユーザーが被った損害について一切の責任を負いません。',
        '前項の規定にもかかわらず当社が責任を負う場合であっても、当社がユーザーに対して負う損害賠償責任の範囲は、前項の瑕疵により影響を受けた本サービスの利用分にかかる月の利用料金を上限とします。このほかに、当社は、ユーザーに生じるいかなる損害（付随的損害、間接損害、特別損害、将来の損害及び弁護士費用）についても賠償責任を負いません。',
        '当社は、地震、落雷、火災、風水害、停電、騒乱、暴動、伝染病その他天災地変等の不可抗力によりユーザーに生じた損害については、債務不履行責任、不法行為責任その他法律上の請求原因の如何を問わず賠償責任を負いません。',
        '当社は、本サービスに関して、ユーザーと他のユーザーまたは第三者との間において生じたトラブルについて一切責任を負いません。ただし、本サービスに関連してユーザー間で生じたトラブルについては、当社は、本サービスの円滑な運営のために必要であると当社が判断した場合には、ユーザー間のトラブルの解決に向けて適切な措置を取るものとします。',
      ],
    },
    {
      head: '第１６条（利用規約の変更）',
      text: [
        {
          subText:
            '当社は、下記の場合には、ユーザーの同意を得ることなく本規約の内容を変更することができるものとします。',
          textItem: [
            '本規約の変更がユーザーの一般の利益に適合するとき。',
            '本規約の変更が本サービスの利用契約をした目的に反せず、かつ、変更の必要性、変更後の内容の相当性、変更の内容その他の変更にかかる事情に照らして合理的なものであるとき。',
          ],
        },
        {
          subText:
            '当社は、前項による本規約の変更にあたり、変更後の本規約の内容とその効力発生日について、次のとおり周知するものとします。',
          textItem: [
            '周知時期：変更後の利用規約の効力発生日の１か月前まで',
            '周知方法：当社ウェブサイト（ＵＲＬ：https://cargolabo.com/）に掲示またはユーザーへの電子メールの送信',
          ],
        },
        '変更後の本規約の効力発生日以降にユーザーが本サービスを利用したときは、本規約の変更に同意したものとみなします。',
      ],
    },
    {
      head: '第１７条（準拠法）',
      text: '本規約の成立、効力、履行及び解釈にあたっては、日本法が適用されるものとします。',
    },
    {
      head: '第１８条（規定外事項）',
      text: 'ユーザーと当社は、本規約の規定の解釈に疑義が生じたときまたは本規約に定めのない事項が生じたときは、協議の上、解決するものとします。',
    },
    {
      head: '第１９条（合意管轄）',
      text: '本サービスまたは本規約に関し当社とユーザーまたは第三者との間で生じた紛争については、大阪地方裁判所を第一審の専属的合意管轄裁判所とします。',
    },
  ]

  // 利用規約英語版
  const termsOfUseEnArr = [
    {
      head: 'Application of the Terms of Use',
      text: [
        'This agreement shall apply to all relationships between the user and the company regarding the use of the service.',
        'If you do not agree to the terms of this agreement, you may not use the service. By using the service, the user is deemed to have agreed to the Terms.',
        'In addition to Terms of Use, the company also provides rules for use, a user manual, and various other regulations (the "Individual Regulations") for the service. In addition to Terms of Use, such as rules for use and user manuals. In addition to the Terms, the company may make various provisions, such as rules for use and user manuals. Regardless of the name, these Individual Regulations shall constitute a part of these Terms.',
        'If the provisions of this agreement differ from the individual provisions, the individual provisions shall take precedence unless otherwise specified in the individual provisions.',
      ],
    },
    {
      head: 'User Registration and Handling of Personal Information, etc.',
      text: [
        'In this service, a person who wishes to register to use this service shall agree to these Terms of Use and apply for registration in a manner prescribed by the company. The Registration Applicant agrees to Terms of Use, applies for registration by the method specified by the company, and upon approval by the company, the registration shall be completed.',
        'When applying for registration, the applicant shall provide true, accurate, and complete information regarding certain information specified by the company (the "registration items"). When applying for registration, the applicant shall provide true, accurate, and complete information regarding certain information specified by the company.',
        'In addition to the registration matters, the company shall appropriately handle the information (including personal information) of the registration applicant and the user-provided by this agreement, as well as the information (including personal information) obtained by the company through the user’s use of the service, by the company’s "Privacy Policy" separately stipulated. In addition, the company may, at its discretion, use and disclose the registered information as statistical information in a form that does not identify individuals, and the user shall not object to this.',
        {
          subText:
            'The company may not approve an application for use registration if the applicant falls under any following items. In addition, the company may cancel the registration even after it has approved the application for registration. The company shall have no obligation to disclose or compensate for damages for reasons of non-approval or cancellation of registration.',
          textItem: [
            'If you provide false information regarding your registration.',
            'If the application is from a person who has violated this agreement.',
            'If the Company otherwise deems the registration of use to be inappropriate.',
          ],
        },
      ],
    },
    {
      head: 'Changes in Registered Matters, etc.',
      text: 'When there is a change in the registered information, the user shall promptly change the registered information on the service by the method specified by the company. The company shall not be liable for any damages incurred by the user due to failure to change the registered information.',
    },
    {
      head: 'Management of User ID and Password',
      text: [
        'Users shall properly manage their user ID and password for the service at their responsibility.',
        'Users may not disclose, transfer, or lend their user IDs and passwords to third parties or share them with third parties under any circumstances. If a user logs in with a user ID and password combination that matches the registered information, the company shall consider using the site by the user who has registered the user ID.',
        'If a user suspects that a third party is using his/her user ID and password, the user shall immediately notify the company to that effect and follow the company’s instructions, if any. In this case, the company may take measures to suspend the user ID and password.',
        'If the company determines that there is a possibility of unauthorized use of the user ID and password, such as failure to log in a certain number of times, the company shall take measures to suspend the use of the user ID and password, and the user shall follow the company’s instructions to cancel the suspension of use. The company shall not be liable for any damages incurred by the user due to such suspension of use.',
        'Users shall be fully responsible for managing their user IDs and passwords, and the company shall not be liable for any damage caused by poor management or use by a third party.',
      ],
    },
    {
      head: 'Usage Fees and Payment Method',
      text: [
        'The user shall pay the usage fee separately determined by the company by the method designated by the company.',
        'If the user delays payment of the usage fee, the user shall pay a late fee at the rate of 14.6% per annum.',
      ],
    },
    {
      head: 'Prohibited Matters',
      text: [
        {
          subText:
            'Users shall not engage in any of the following acts when using the service.',
          textItem: [
            'Actions that violate laws and regulations or public order and morals',
            'Acts related to criminal activities',
            'Acts that infringe on the copyrights, trademark rights, or other intellectual property rights of the company or third parties contained in the service, including the contents.',
            'Infringement on the privacy, honor, or trust of the company or a third party',
            'Disclosing, transferring, or lending your user ID and password to a third party or sharing them with a third party.',
            'Destroy or interfere with the functioning of the servers or networks of the company, other users, or other third parties.',
            'Commercial use of information obtained through this service.',
            'Actions that may interfere with the operation of this service.',
            'Acts that violate the Act on Prohibition of Unauthorized Computer Access acts that fall under the crime of obstruction of business such as destruction of a computer (Article 234-2 of the Penal Code), and other acts that perform unauthorized operations on the computers of the company or others.',
            'Collecting or accumulating personal information about other users.',
            'Use of the Service for any improper purpose.',
            'Actions that cause disadvantage, damage, or discomfort to other users or other third parties',
            'Impersonating other users.',
            'Advertising, solicitation, or sales activities on the service.',
            'Providing direct or indirect benefits to antisocial forces about our services The act of',
            'Any action that directly or indirectly causes or facilitates any of the preceding items.',
            'Other actions that the company deems inappropriate.',
          ],
        },
        'If a user violates any items in the preceding paragraph, the company may demand compensation for all damages (incidental, indirect, special, future, and attorney’s fees) incurred due to the violation.',
      ],
    },
    {
      head: 'Suspension of Provision of Services, etc.',
      text: [
        {
          subText:
            'The company may suspend or discontinue the provision of all or part of the service without prior notice if the company determines that any of the following reasons exist.',
          textItem: [
            'When necessary to perform maintenance, inspection, or update the computer system for this service.',
            'If the service provider becomes difficult due to force majeure such as earthquake, lightning, fire, wind, flood, power failure, disturbance, riot, epidemic, or another natural disaster.',
            'When the computer or communication line is stopped due to an accident',
            'If the Company deems it challenging to provide the service in any other way.',
          ],
        },
        'The company shall not be liable for any disadvantage or damage incurred by the user or any third party due to the suspension or discontinuation of the service provided under the preceding paragraph.',
      ],
    },
    {
      head: 'Restriction of Use and Cancellation of Registration',
      text: [
        {
          subText:
            'Without prior notice, the company may restrict the user from using all or part of the service or terminate the user’s registration if the user falls under any of the following.',
          textItem: [
            'When you violate any of the provisions of this agreement',
            'When it is found that there is false information in the registered information.',
            'When there is no response from the company for 30 days or more to inquiries or other communications requesting a response',
            'When there is no use for a certain period after the last use of this service.',
            'When there is a default in the payment of usage fees or other obligations',
            'When a bill or check is dishonored',
            'When subjected to a seizure, provisional seizure, provisional disposition or other compulsory execution procedure or disposition of arrears',
            'When filed a petition for commencement of bankruptcy proceedings, civil rehabilitation proceedings, corporate reorganization proceedings, or special liquidation proceedings.',
            'When there is a significant change in the user’s credit status in addition to the preceding four items.',
            ' In the event of dissolution or suspension of business',
            'The Company has received a caution, recommendation, or administrative action by the administrative authorities regarding its business methods, etc.',
            'When the Company otherwise deems the use of the service to be inappropriate.',
          ],
        },
        'The company shall not be liable for any loss or damage incurred by the user due to any action taken by the company by the preceding paragraph.',
      ],
    },
    {
      head: 'Account Cancellation',
      text: 'Users may leave the service by following the account cancellation procedures specified by the company.',
    },
    {
      head: 'Change of Service Contents, etc.',
      text: [
        'The company may change the contents or name of the service without prior notice.',
        'If the company makes any of the changes outlined in the preceding paragraph, the company shall promptly notify users of such changes by posting a notice on the company’s website (URL: https://cargolabo.com/) or by any other means deemed appropriate by the company.',
        'The user continues to use the service after the notification in the preceding paragraph have agreed to the changes in Paragraph 1',
      ],
    },
    {
      head: 'Notification or Communication',
      text: 'Any notice or communication between you and us shall be made in a manner determined by us. Unless the user notifies the company of a change in the contact information by the method specified separately by the company, the company will assume that the currently registered contact information is valid and send notifications or communications to that contact information. These notifications or communications will be deemed to have reached the user at the time they are sent.',
    },
    {
      head: 'Entrustment',
      text: 'The company may outsource all or part of the work related to this service to a third party.',
    },
    {
      head: 'Prohibition of Assignment of Rights and Obligations',
      text: [
        'The user may not assign, transfer, mortgage, or otherwise dispose of his/her position in the Service Agreement or rights or obligations under Terms of Use to a third party without the company’s prior written consent.',
        'If the company transfers the business of the service to another company, the company may transfer the status of the service’s user agreement, rights, and obligations under these Terms of Use, and the user’s registered matters and other customer information to the transferee of the transfer.The user shall be deemed to have agreed in advance to such transfer in this section. The transfer of business outlined in this section shall include ordinary business transfer and corporate separation and any other cases in which business is transferred.',
      ],
    },
    {
      head: 'Ownership of Intellectual Property Rights',
      text: 'All intellectual property rights related to the service shall belong to the company and third parties with legitimate rights, and no rights shall be transferred to the user, and only the non-exclusive right to use the service shall be granted.',
    },
    {
      head: 'Scope of the Company’s Responsibility',
      text: [
        'The company does not warrant that the service is free from defects in fact or law (including defects in safety, reliability, accuracy, completeness, effectiveness, fitness for a particular purpose, security, errors or bugs, infringement of rights, etc.). The company does not guarantee the absence of such defects. In addition, the company shall not be liable for any damages incurred by the user in connection with the service, including but not limited to the suspension, discontinuation, or restriction of the use of the service by the company, or deletion or loss of registered information, resulting from such defects.',
        'Even in cases where the company is liable despite the provisions of the preceding paragraph, the extent of the company’s liability to the user for damages shall be limited to the monthly usage fee for the portion of the service affected by the defect described in the preceding paragraph. In addition to the above, the company shall not be liable for any damages (incidental, indirect, special, future, or attorney’s fees) incurred by the user.',
        'The company shall not be liable for any damages incurred by the user due to force majeure such as earthquake, lightning, fire, windstorm, flood, power failure, disturbance, riot, epidemic, or any other natural disaster, regardless of the cause of claims such as default liability, tort liability, or any other legal cause of the claim.',
        'The company shall not be responsible for any trouble that may arise between a User and another User or third party about this service. However, if the company deems it necessary for the smooth operation of the service, the company shall take appropriate measures to resolve any problems between users.',
      ],
    },
    {
      head: 'Changes to the Terms of Use',
      text: [
        {
          subText:
            'The company reserves the right to change the contents of this agreement without the user’s consent in the following cases.',
          textItem: [
            'When changes to this agreement are compatible with the general interests of users.',
            'The change in terms of use is not contrary to the purpose for which the user contracted for the service and is reasonable in light of the necessity of the change, the reasonableness of the content after the change, the content of the change, and other circumstances related to the change.',
          ],
        },
        {
          subText:
            'In the event of a change to the Terms and Conditions by the preceding paragraph, the company shall know the contents of the revised Terms and Conditions and their effective date as follows.',
          textItem: [
            'Notification period: At least one month before the effective date of the revised Terms of Use',
            'Method of dissemination: Posting on the company’s website(URL: https://cargolabo.com/) or sending e-mails to users.',
          ],
        },
        'When a user uses the service after the effective date of the revised Terms, the user is deemed to have agreed to the revised Terms.',
      ],
    },
    {
      head: 'Governing Law',
      text: 'The laws of Japan shall apply to the formation, validity, performance, and interpretation of this agreement.',
    },
    {
      head: 'Unregulated Matters',
      text: 'If there is any doubt about the interpretation of the provisions of this agreement, or if any matter not stipulated in this agreement arises, the User and the Company shall resolve it through consultation.',
    },
    {
      head: 'Consensual Jurisdiction',
      text: 'The Osaka District Court shall be the exclusive court of the first instance for any disputes arising between the company and users or third parties regarding the Service or the Terms.',
    },
  ]

  // 個人情報保護日本語
  const privacyPolicyJaArr = [
    {
      head: '第1条（取得する個人情報等）',
      text: '本サービスにおいて当社が取得することのある個人情報等は、以下のとおりです。',
    },
    {
      text: [
        'ユーザーから提供される情報（次の各号を含みます）',
        'ユーザーＩＤ及びパスワード',
        '会社名',
        '住所',
        '支店名',
        '拠点空港名',
        '氏名',
        '部署名',
        '電話番号',
        'メールアドレス',
        '取引記録',
        '決済に関する情報',
        '請求書に記載する情報',
        '当社が定める入力フォームにユーザーが入力する情報',
        '外部サービスのプライバシー設定によりユーザーが当該外部サービス運営会社に開示を認めた情報',
        'ユーザーが本サービスを利用するにあたって当社が取得する端末情報、ログ情報、Cookie及び匿名ＩＤ、位置情報、IPアドレス、ブラウザ情報、ブラウザ言語その他ユーザーの通信に関する情報',
      ],
    },
    {
      head: '第2条（個人情報等の取得方法）',
      text: [
        '当社は、ユーザーの個人情報等を法令を遵守して適正に取得します。',
        '当社は、ユーザーが利用登録をする際に氏名、住所、電話番号、メールアドレスなどの個人情報等をお尋ねすることがあります。',
        '当社は、ユーザーと当社の提携先（情報提供元、広告主、広告配信先などを含みます。以下、｢提携先｣といいます。）などとの間でなされたユーザーの取引記録や決済に関する情報を含む個人情報等を提携先などから取得することがあります。',
      ],
    },
    {
      head: '第3条（個人情報等の利用目的）',
      text: '当社は、取得したユーザーの個人情報等を、以下の目的で利用します。',
    },
    {
      text: [
        '当社サービスの提供・運営・向上のため',
        '新サービスの開発のため',
        '本サービスに関するご案内、各種事項の連絡・情報提供、お問い合わせ等への対応のため（本人確認を行うことを含みます）',
        '本サービス、新サービスその他の各種サービスのご案内やお知らせをお届けするため',
        '電子メール配信サービスのお申し込みの確認や電子メールを配信するため',
        '当社または第三者の広告の配信・表示及び商品・サービス等の情報提供のため',
        'メンテナンス、重要なお知らせなど必要に応じたご連絡のため',
        '本サービスに関する当社の規約、本ポリシー等に違反する行為に対する対応のため',
        'ユーザーにご自身の登録情報の閲覧や変更、削除、ご利用状況等の閲覧を行っていただくため',
        '本サービスに関するご請求、お支払いとその確認をするため',
        '本サービスの利用状況や満足度等を調査・分析するため',
        '本サービスはGoogle LLCのGoogle Analyticsを利用してサイトの計測を行っております。これに付随して生成されるテキストファイル「Cookie」を通じて分析を行うことがありますが、この際、IPアドレス等のユーザー情報の一部が、Google に収集される可能性があります。サイト利用状況の分析、レポートの作成その他のサービスの提供目的に限りこれを使用します。ユーザーは、本サービスを利用することで、上記方法及び目的においてGoogleが行うこうしたデータ処理につき許可を与えたものとみなします。※Cookie情報の利用に関しては、ご利用のブラウザーや端末の設定によりCookieの拒否または機能を制限したり、第三者広告配信事業者（Google）のオプトアウト機能を利用してCookie情報等を使用しないように設定することができます。なお、Cookieの使用を拒否または禁止したりした場合は、本サービスの全部または一部を利用できなくなる場合がございます。',
        '本サービスの品質管理のためのアンケート調査及びその結果の分析のため',
        '個人を識別できない形式に加工した本サービスの統計データを作成または公表するため',
        'ご協力いただいた調査等に対する謝礼等をお送りするため',
        '調査のご協力依頼や各種イベントへのご参加をお願いし、その結果等をご報告するため',
        '本サービスに関する規約等の変更等を通知するため',
        '本サービス運営上のトラブル解決のため',
        '上記の利用目的に付随する目的のため',
      ],
    },
    {
      head: '第4条（利用目的の変更）',
      text: [
        '当社は、利用目的が変更前と関連性を有すると合理的に認められる場合に限り、個人情報等の利用目的を変更するものとします。',
        '利用目的の変更を行った場合には、変更後の目的について、当社所定の方法により、ユーザーに通知し、または当社ウェブサイト（ＵＲＬ：https://cargolabo.com/）上に掲載するものとします。',
      ],
    },
    {
      head: '第5条（個人情報等の第三者提供）',
      text: [
        {
          subText:
            '当社は、次に掲げる場合を除いて、あらかじめユーザーの同意を得ることなく、第三者に個人情報等を提供することはありません。ただし、個人情報保護法その他の法令で認められる場合を除きます。',
          textItem: [
            '人の生命、身体または財産の保護のために必要がある場合であって、ユーザーの同意を得ることが困難であるとき',
            '公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、ユーザーの同意を得ることが困難であるとき',
            '国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ユーザーの同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき',
            '外部サービスとの連携または外部サービスを利用した認証にあたり、当該外部サービス運営会社に個人情報等を提供する場合',
            '予め次の事項を告知あるいは公表し、かつ当社が個人情報保護委員会に届出をしたとき',
          ],
          textItemArr: [
            'a.	利用目的に第三者への提供を含むこと',
            'b.	第三者に提供される個人情報等の項目',
            'c.	第三者への提供の方法',
            'd.	ユーザーの求めに応じて当該ユーザーが識別される個人情報の第三者への提供を停止すること',
            'e.	ユーザーの求めを受け付ける方法',
          ],
        },
        {
          subText:
            '前項の定めにかかわらず、次に掲げる場合には、当該情報の提供先は第三者に該当しないものとします。',
          textItem: [
            '当社が利用目的の達成に必要な範囲内において個人情報等の取扱いの全部または一部を委託する場合',
            '合併その他の事由による事業の承継に伴って個人情報等が提供される場合',
            '個人情報等を特定の者との間で共同して利用する場合であって、その旨並びに共同して利用される個人情報等の項目、共同して利用する者の範囲、利用する者の利用目的および当該個人情報等の管理について責任を有する者の氏名または名称について、あらかじめユーザーに通知し、またはユーザーが容易に知り得る状態に置いた場合',
          ],
        },
      ],
    },
    {
      head: '第6条（個人情報等の開示）',
      text: [
        {
          subText:
            '当社は、ユーザーから個人情報等の開示を求められたときは、ユーザー本人からの請求であることを確認の上、当該ユーザーに対し、遅滞なくこれを開示します。ただし、開示することにより次のいずれかに該当する場合は、その全部または一部を開示しないこともあり、開示しない決定をした場合には、その旨を遅滞なく通知します。なお、個人情報等の開示に際しては、１件あたり１,０００円の手数料を申し受けます。',
          textItem: [
            'ユーザーまたは第三者の生命、身体、財産その他の権利利益を害するおそれがある場合',
            '当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合',
            'その他法令に違反することとなる場合',
          ],
        },
        '前項の定めにかかわらず、個人情報等以外の情報については、原則として開示いたしません。',
      ],
    },
    {
      head: '第7条（個人情報等の訂正および削除）',
      text: [
        'ユーザーは、当社の保有する自己の個人情報等が誤った情報である場合には、当社が定める手続により、当社に対し、当該個人情報等の訂正、追加または削除（以下、「訂正等」といいます。）を請求することができます。',
        '当社は、前項の請求を受けたときは、ユーザー本人からの請求であることを確認の上、遅滞なく必要な調査を行い、その請求に応じる必要があると判断した場合には、当該個人情報等の訂正等を行うものとします。',
        '当社は、前項の規定に基づき当該個人情報等の訂正等を行ったときまたは訂正等を行わない旨の決定をしたときは、遅滞なく、その旨（訂正等を行ったときはその内容を含みます。）をユーザーに通知します。',
      ],
    },
    {
      head: '第8条（個人情報等の利用停止等）',
      text: [
        'ユーザーは、当社の保有する自己の個人情報等が利用目的の範囲を超えて取り扱われているという理由または不正の手段により取得されたものであるという理由により、当社に対し、当該個人情報等の利用の停止または消去（以下、「利用停止等」といいます。）を請求することができます。',
        '当社は、前項の請求を受けたときは、ユーザー本人からの請求であることを確認の上、遅滞なく必要な調査を行い、その請求に応じる必要があると判断した場合には、違反を是正するために必要な限度で、遅滞なく、当該個人情報等の利用停止等を行います。',
        '当社は、前項の規定に基づき当該個人情報等の利用停止等を行ったときまたは利用停止等を行わない旨の決定をしたときは、遅滞なく、その旨をユーザーに通知します。',
        '当社は、前２項にかかわらず、当該個人情報等の利用停止等に多額の費用を要する場合その他利用停止等を行うことが困難な場合であって、ユーザーの権利利益を保護するために必要なこれに代わるべき措置をとり得る場合は、この代替策を講じるものとします。',
      ],
    },
    {
      head: '第9条（プライバシーポリシーの変更）',
      text: [
        '当社は、本ポリシーを変更したときは、当社ウェブサイト（ＵＲＬ：https://cargolabo.com/）に掲載するものとします。',
        '変更後のプライバシーポリシーは、当社が別途定める場合を除いて、当社ウェブサイトに掲載したときから効力を生じるものとします。',
        '法令上ユーザーの同意が必要となるような内容の変更については、当社所定の方法でユーザーの同意を得るものとします。',
      ],
    },
    {
      head: '第10条（お問い合わせ窓口）',
      text: '個人情報等の開示、訂正等、利用停止等、第三者への提供停止の求め及び苦情の申出に関するお問い合わせは、下記の窓口までお願いいたします。',
    },
    {
      text: '株式会社CargoLabo　個人情報保護担当窓口',
    },
    {
      text: 'Tel: 072-220-5143',
    },
    {
      text: 'E-mail: info@cargolabo.com',
    },
  ]

  // 個人情報保護英語
  const privacyPolicyEnArr = [
    {
      head: 'Personal information to be Acquired',
      text: 'Personal information, etc., that we may acquire through this service is as follows.',
    },
    {
      text: [
        'Information provided by the user (including the following items)',
        'User ID and password',
        'Company name',
        'Company Address',
        'Branch Name',
        'Airport Name',
        'Name',
        'Department',
        'Phone number',
        'E-mail address',
        'Transaction record',
        'Payment information',
        'Information to be included in the invoice',
        'Information entered by the user in the input form provided by the company',
        'Information that the user has authorized the external service operator to disclose according to the privacy settings of the external service.',
        "Terminal information, log information, cookies, and anonymous IDs, location information, IP addresses, browser information, browser language, and other information related to the company's communications that the company obtains when using the Services.",
      ],
    },
    {
      head: 'Method of Obtaining Personal Information',
      text: [
        "We will acquire users' personal information and other information appropriately in compliance with laws and regulations.",
        'The Company may ask for personal information such as name, address, telephone number, e-mail address, etc., when a user registers for use.',
        "The Company shall not be liable for any loss or damage arising out of the use of the site by the user and the company's business partners (including information providers, advertisers, and ad distributors). The company will not disclose personal information, etc., including the user's transaction records and settlement information, between the user and the company's business partners (including information providers, advertisers, and advertising distributors; the \"Business Partners\"). The company may obtain from the partner, personal information, etc., including the user's transaction records and settlement information between the user and the partner.",
      ],
    },
    {
      head: 'Purpose of Use of Personal Information, etc.',
      text: 'The company will use the acquired personal information, etc., of users for the following purposes.',
    },
    {
      text: [
        'To provide, operate, and improve our services',
        'For the development of new services',
        'To provide information about the service, to communicate and provide information on various matters, and to respond to inquiries (including identification)',
        'To send you information and announcements about the service, new services, and other various services',
        'To confirm applications for e-mail delivery services and to deliver e-mails',
        'To distribute and display advertisements of the company or third parties and provide information on products and services, etc.',
        'To contact you as necessary for maintenance, important notices, etc.',
        "To respond to actions that violate the Company's Terms of Use, this Policy regarding this service.",
        'To allow users to view, change, or delete their registered information, view their usage status, etc',
        'For billing, payment, and confirmation of payment related to this service.',
        'To survey and analyze the usage and satisfaction of this service.',
        'This service uses Google Analytics by Google LLC to measure the site. In this case, some user information, such as IP address, may be collected by Google. In this case, some user information, such as IP address, may be collected by Google. This information will use only to analyze site usage, compile reports, and providing other services. By using this service, you are granting Google permission to process this data in the manner and for the purposes described above.',
        'Concerning the use of cookie information, you can set your browser or terminal to reject cookies or restrict their function or use the opt-out function of a third-party ad-serving company (Google) to prevent the use of cookie information. Please note that if you reject or prohibit cookies, you may not be able to use all or part of the service.',
        'To conduct questionnaire surveys and analyze the results for quality control of this service',
        'To create or publish statistical data on the service in a format that does not identify individuals.',
        'To send rewards for surveys, etc., in which you have cooperated',
        'To request cooperation in surveys and participation in various events, and to report the results thereof',
        'To notify you of changes to the terms and conditions of this service.',
        'To resolve problems in the operation of this service',
        'For purposes incidental to the above purposes of use',
      ],
    },
    {
      head: 'Change of Purpose of Use',
      text: [
        'The company shall change the purpose of personal information, etc., only when the use is reasonably recognized to be related to that before the change.',
        'In the event of a change in the purpose of use, the company shall notify the user of the changed purpose in the manner prescribed by the company or post it on its website (URL: https://cargolabo.com/).',
      ],
    },
    {
      head: 'Provision of Personal Information to Third Parties',
      text: [
        {
          subText:
            "The Company will not provide personal information, etc., to any third party without the user's prior consent, except in the following cases. However, this excludes cases permitted by the Personal Information Protection Law and other laws and regulations.",
          textItem: [
            "When it is necessary to protect the life, body, or property of an individual, it is not easy to obtain the user's consent.",
            "When it is essential for improving public health or promoting the sound growth of children, it is not easy to obtain the user's consent.",
            "When it is necessary to cooperate with a national agency, a local public body, or a person or organization entrusted by either preceding in executing affairs prescribed by laws and regulations and obtaining the user's consent is likely to impede the execution of such affairs.",
            'When providing personal information to an external service operator to link with an external service or authenticate using an external service.',
            'When the following items are notified or announced in advance, and the company has submitted a notification to the Personal Information Protection Committee.',
          ],
          textItemArr: [
            'a.	The purpose of use shall include a provision to a third party.',
            'b.	Items of personal information to be provided to third parties',
            'c.	Method of provision to third parties',
            "d.	Stopping the provision of personal information that identifies the user to a third party upon the user's request.",
            "e.	How to accept a user's request",
          ],
        },
        {
          subText:
            'Notwithstanding the provisions of the preceding paragraph, the party to which the provided information shall not be considered a third party in the following cases',
          textItem: [
            'When the Company entrusts the handling of personal information in whole or in part, within the scope necessary to achieve the purpose of use.',
            'When personal information is provided in connection with the succession of a business due to a merger or other reasons',
            'In cases the personal information is used jointly with a specific party, the user shall notify in advance, or the user shall place in a position where he/she can quickly obtain information regarding that fact, the items of personal information use, the scope of parties use jointly, the purpose of parties use, and the name of party responsible for the management of personal information.',
          ],
        },
      ],
    },
    {
      head: 'Disclosure of Personal Information',
      text: [
        {
          subText:
            'When the Company receives a request from a User to disclose his/her personal information, the company will disclose such information to the user without delay, after confirming that the user makes the request. However, the company may decide not to disclose all or part of the information if disclosure would result in any of the following cases, and if the company decides not to disclose the information, it will notify the user to that effect without delay. A fee of 1,000 yen will charge for each disclosure of personal information.',
          textItem: [
            'When there is a risk of harm to the life, body, property, or other rights or interests of the user or a third party',
            'If there is a risk of a significant hindrance to the proper conduct of our business',
            'When it would violate other laws and regulations',
          ],
        },
        'Notwithstanding the provisions of the preceding paragraph, we will not, in principle, disclose any information other than personal information.',
      ],
    },
    {
      head: 'Correction and Deletion of Personal Information',
      text: [
        'The user may request the company to correct, add, or delete (the "correct") his or her Personal Information by the procedures established by the company if the Personal Information held by the company is incorrect. (2) If the personal information held by the company is incorrect, the person may request the company to correct, add, or delete (the "correct") the personal information by the procedures specified by the company.',
        'When the company receives a request as described in the preceding paragraph, the company shall conduct the necessary investigation without delay after confirming that the request is made by the user himself/herself. If the company determines that it is necessary to respond to the request, it shall correct the relevant Personal Information.',
        '(2) When the Company has made corrections to the Personal Information or has decided not to make corrections based on the provisions of the preceding paragraph, the company shall notify the user to that effect (including the details of the corrections if made) without delay. (2) If the company corrects or decides not to correct it.',
      ],
    },
    {
      head: 'Suspension of Use of Personal Information',
      text: [
        'The user may request the company to suspend the use or erase (the "suspend the use") of his/her personal information held by the company because such personal information is handled beyond the scope of the usage purpose such personal information obtained illegal means. (2) The Company may request the suspension of use or deletion (the "suspension of use") of the personal information in question.',
        'When the company receives a request as described in the preceding paragraph, it will conduct the necessary investigation without delay after confirming that the user makes the request. If the company determines that it is necessary to respond to the request, it will suspend the use of the relevant personal information without delay to the extent necessary to correct the violation.',
        'If the company suspends the use of the relevant Personal Information based on the provisions of the preceding paragraph or decides not to do so, the company shall notify the user to that effect without delay.',
        'Notwithstanding the preceding two paragraphs, in cases where the suspension of the use of the relevant Personal Information requires a large amount of money or where it is otherwise difficult to suspend use and where alternative measures necessary to protect the rights and interests of the user can take, the company shall take such alternative measures.',
      ],
    },
    {
      head: 'Changes to the Privacy Policy',
      text: [
        'We will post the changes on our website (URL: https://cargolabo.com/).',
        "The revised Privacy Policy shall become effective when posted on the company's website unless otherwise specified by the company.",
        "For changes to the content that require the user's consent under laws and regulations, the user's consent shall obtain in a manner prescribed by the company.",
      ],
    },
    {
      head: 'Contact for Inquiries',
      text: 'For inquiries regarding the disclosure, correction, suspension of the use of personal information requests for suspension of provision to third parties, and complaints, please contact the following.',
    },
    {
      text: 'Personal Information Protection, CargoLabo Inc.',
    },
    {
      text: 'Tel: 072-220-5143',
    },
    {
      text: 'E-mail: info@cargolabo.com',
    },
  ]

  /**
   * 利用規約・個人情報表示
   * @param {Object} titleObj - 利用規約・個人情報のタイトルオブジェクト
   * @param {Array} textJaArr - 利用規約・個人情報の内容日本語配列
   * @param {Array} textEnArr - 利用規約・個人情報の内容英語配列
   * @returns {JSX.Element} - 利用規約・個人情報
   */
  const showText = (titleObj, textJaArr, textEnArr) => {
    return (
      <>
        {/* 日本語 */}
        <Grid container item justify="center" alignItems="center">
          <div className={classes.title}>{titleObj.jaTitle}</div>
          <div className={classes.textJa}>{titleObj.jaText}</div>
        </Grid>
        {textJaArr.map((row, index) => (
          <div key={index}>
            {Array.isArray(row.text) ? (
              <>
                <div className={classes.headJa}>{row.head}</div>
                {row.text.map((rowText, i) => (
                  <React.Fragment key={i}>
                    {typeof rowText === 'object' ? (
                      <div className={classes.textIndent}>
                        <span className={classes.textItemJa}>
                          {i + 1 + '. '}
                        </span>
                        <span className={classes.textJa}>
                          {rowText.subText}
                        </span>
                        {rowText.textItem.map((item, num) => (
                          <div className={classes.subTextIndent} key={num}>
                            <span className={classes.textJa}>
                              {'(' + (num + 1) + ') '}
                            </span>
                            <span className={classes.textJa}>{item}</span>
                          </div>
                        ))}
                        {rowText.textItemArr ? (
                          <>
                            {rowText.textItemArr.map((textItems, indexNum) => (
                              <div className={classes.leftIndex} key={indexNum}>
                                <span className={classes.textJa}>
                                  {textItems}
                                </span>
                              </div>
                            ))}
                          </>
                        ) : (
                          ''
                        )}
                      </div>
                    ) : (
                      <div className={classes.textIndent}>
                        <span className={classes.textItemJa}>
                          {i + 1 + '. '}
                        </span>
                        <span className={classes.textJa}>{rowText}</span>
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </>
            ) : (
              <>
                <div className={classes.headJa}>{row.head}</div>
                <div className={classes.textItemJa}>{row.text}</div>
              </>
            )}
          </div>
        ))}
        {titleObj.jaClosings.map((jaClosing, index) => (
          <div key={index}>
            <div className={classes.textItemJa}>{jaClosing}</div>
          </div>
        ))}
        <div className={classes.right + ' ' + classes.text}>
          {titleObj.jaSubClosing}
        </div>

        <Divider className={classes.divider} />

        {/* 英語 */}
        <Grid container item justify="center" alignItems="center">
          <div className={classes.title}>{titleObj.enTitle}</div>
          <div className={classes.text}>{titleObj.enText}</div>
        </Grid>
        {textEnArr.map((row, index) => (
          <div key={index}>
            {Array.isArray(row.text) ? (
              <>
                <div className={classes.head}>{row.head}</div>
                {row.text.map((rowText, i) => (
                  <React.Fragment key={i}>
                    {typeof rowText === 'object' ? (
                      <div className={classes.textIndent}>
                        <span className={classes.textItem}>{i + 1 + '. '}</span>
                        <span className={classes.text}>{rowText.subText}</span>
                        {rowText.textItem.map((item, num) => (
                          <div className={classes.subTextIndent} key={num}>
                            <span className={classes.textItem}>
                              {'(' + (num + 1) + ') '}
                            </span>
                            <span className={classes.text}>{item}</span>
                          </div>
                        ))}
                        {rowText.textItemArr ? (
                          <>
                            {rowText.textItemArr.map((textItems, indexNum) => (
                              <div className={classes.leftIndex} key={indexNum}>
                                <span className={classes.text}>
                                  {textItems}
                                </span>
                              </div>
                            ))}
                          </>
                        ) : (
                          ''
                        )}
                      </div>
                    ) : (
                      <div className={classes.textIndent}>
                        <span className={classes.textItem}>{i + 1 + '. '}</span>
                        <span className={classes.text}>{rowText}</span>
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </>
            ) : (
              <>
                <div className={classes.head}>{row.head}</div>
                <div className={classes.text}>{row.text}</div>
              </>
            )}
          </div>
        ))}
        {titleObj.enClosings.map((enClosing, index) => (
          <div key={index}>
            <div className={classes.text}>{enClosing}</div>
          </div>
        ))}
      </>
    )
  }

  return (
    <>
      {/* リンク */}
      <Link className={classes.link} onClick={handleClickOpen('body')}>
        {props.linkName}
      </Link>

      {/* ダイアログ */}
      <Dialog
        open={open}
        onClose={(e) => handleClose(e, true)}
        onMouseDown={savePageXPosition}
        scroll={scroll}
        maxWidth={'lg'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        className={classes.root}
      >
        {/* ダイアログ本文 */}
        <DialogContent>
          <div className={classes.dialog + ' ' + classes.transparent}>
            {/* 本文 */}
            <Grid container justify="center" alignContent="center" spacing={1}>
              <Grid item xs={12}>
                <div className={classes.margin}>
                  {props.linkName === 'Terms of Use'
                    ? showText(
                        titleTermsOfUseObj,
                        termsOfUseJaArr,
                        termsOfUseEnArr
                      )
                    : showText(
                        titlePrivacyPolicyObj,
                        privacyPolicyJaArr,
                        privacyPolicyEnArr
                      )}
                </div>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            justify="center"
            spacing={3}
            className={classes.desktop}
          >
            <Grid item>
              <SecondaryButton addclass={classes.button} onClick={handleClose}>
                {t('D213V0094')}
              </SecondaryButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DialogLink
