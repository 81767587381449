import { TABLES } from '../../../actions'
import CommonFunc from '../../../components/common/function/CommonFunc'
import ConstantsLightCarSelectForwarder from '../../../components/pages/master/LightCarSelectForwarder/ConstantsLightCarSelectForwarder'
import Common from '../../../constants/Common'

const initTable = {
  isNoResult: true,
  showData: [],
  initData: [],
}

/**
 * テーブルに表示する形式に変更
 * @param {Array} tableData - apiから受け取ったテーブルデータ
 * @return {Array} - テーブルに表示する形式のデータ
 */
const editData = (tableData) =>
  tableData.map(({ companyName, fwdrthreeCode, fwdrNameId }) => ({
    company: `${companyName} (${fwdrthreeCode})`,
    fwdrNameId,
  }))

/**
 * 内容を保持
 * @param {Object} action - dispatchで受け取ったaction
 * @param {Object} state - state
 * @return {Object} - state
 */
const setData = (action, state) => {
  const { tableData, search = '' } = action
  /** @type {Array} 表示用に編集後のデータ */
  const editedData = tableData ? editData(tableData) : state.initData
  /** @type {Number} 1ページに表示する件数 */
  const pageAmount = ConstantsLightCarSelectForwarder.PAGE_AMOUNT
  /** @type {String} 並び順 */
  const sortTerms = Common.SORT_TERMS.COMPANYNAME_ASCENDING
  /** @type {String} 並び替え参照データ名 */
  const sortDataName = 'company'
  // 検索して結果をページごとに分割する
  const { showData, initData } = CommonFunc.searchSortData(
    editedData,
    search,
    sortTerms,
    sortDataName,
    pageAmount
  )
  /** @type {Boolean} NoResult判定 */
  const isNoResult = showData[0].length <= 0
  return { showData, initData, isNoResult }
}

/**
 * テーブル情報を保持
 * @param {Object} [state=initTable] - state
 * @param {Object} action - action
 * @return {Object} - state
 */
const Table = (state = initTable, action) => {
  switch (action.type) {
    case TABLES.SET:
      return setData(action, state)
    default:
      return state
  }
}

export default Table

export { initTable }
