import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  title: {
    margin: '170px auto 10px auto',
    textAlign: 'center',
  },
  text: {
    fontSize: theme.fontSize.title,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 'bold',
    color: theme.palette.info.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.fontSize.cardButtonSm,
    },
  },
}))

const ReissuePassTitle = (props) => {
  const classes = useStyles()
  return (
    <div className={classes.title}>
      <span className={classes.text}>{props.children}</span>
    </div>
  )
}

export default ReissuePassTitle
