import React from 'react'
import { Grid } from '@material-ui/core'

import HeaderDeadlineBidInfo from './HeaderDeadlineBidInfo'
import DatePickerDeadlineBidInfo from './DatePickerDeadlineBidInfo'

/**
 * @return {JSX} Deadlineコンポーネント
 */
const DeadlineBidInfo = () => {
  return (
    <Grid item container justify="flex-start">
      <HeaderDeadlineBidInfo />
      <DatePickerDeadlineBidInfo />
    </Grid>
  )
}

export default DeadlineBidInfo
