import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((Theme) => ({
  label: Theme.textBoldBLK,
}))

/**
 * @param {Object} props 下記内容
 * @param {string} props.label 表示内容
 * @return {JSX} ShippingDetails欄の内容部分
 */
const ContentShippingDetailsLightBookingDetails = ({ label }) => {
  const classes = useStyles()

  return <div className={classes.label}>{label}</div>
}
ContentShippingDetailsLightBookingDetails.propTypes = {
  label: PropTypes.string.isRequired,
}
export default ContentShippingDetailsLightBookingDetails
