import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'

import restFacade from '../../../../actions/rest-facade'
import Paths from '../../../../constants/Paths'
import ApiPaths from '../../../../constants/ApiPaths'
import CommonFunc from '../../../common/function/CommonFunc'
import CommonValidation from '../../../common/function/CommonValidation'
import PrimaryButton from '../../../common/buttons/PrimaryButton'
import BackButton from '../../../common/buttons/BackButton'

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    color: theme.palette.primary.main,
    '&.focused': {
      border: 0,
    },
    '&.shrink': {
      backgroundColor: 'transparent',
      transform: `translate(${10}px, ${1}px) scale(${0.75})`,
    },
  },
  root: {
    marginTop: '150px',
    '& label.Mui-focused': {
      color: theme.palette.primary.main,
      backgroundColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      border: 0,
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
    '&.Mui-focused': {
      '& input:valid + fieldset': {
        backgroundColor: 'red',
        border: 0,
        borderBottom: `1px solid ${theme.palette.primary.main}`,
      },
    },
    '& .MuiFormLabel-root': {
      '&.Mui-focused fieldset': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiFormLabel-root.Mui-error': {
      color: theme.palette.required,
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 0,
      '& fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '& input:valid + fieldset': {
        color: theme.palette.primary.main,
        borderWidth: 2,
      },
      '& input:invalid + fieldset': {
        color: theme.palette.primary.main,
        borderColor: theme.palette.reqired,
        borderWidth: 2,
        backgroundColor: 'transparent',
      },
      '& input': {
        backgroundColor: theme.palette.info.main,
        color: theme.palette.primary.main,
      },
    },
  },
  errorRoot: {
    marginTop: '80px',
    '& label.Mui-focused': {
      color: theme.palette.primary.main,
      backgroundColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      border: 0,
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
    '&.Mui-focused': {
      '& input:valid + fieldset': {
        backgroundColor: 'red',
        border: 0,
        borderBottom: `1px solid ${theme.palette.primary.main}`,
      },
    },
    '& .MuiFormLabel-root': {
      '&.Mui-focused fieldset': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiFormLabel-root.Mui-error': {
      color: theme.palette.required,
    },
    '& .MuiFormHelperText-root': {
      color: theme.palette.required,
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 0,
      '& fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '& input:valid + fieldset': {
        color: theme.palette.primary.main,
        borderWidth: 2,
      },
      '& input:invalid + fieldset': {
        color: theme.palette.primary.main,
        borderColor: theme.palette.reqired,
        borderWidth: 2,
        backgroundColor: 'transparent',
      },
      '& input': {
        backgroundColor: theme.palette.info.main,
        color: theme.palette.primary.main,
      },
    },
  },
  sign: {
    width: theme.buttonSize.width.large,
    height: theme.buttonSize.height.large,
  },
  button: {
    marginTop: '150px',
    marginBottom: '50px',
  },
  section: {
    marginTop: 10,
    marginBottom: 20,
  },
  required: {
    color: theme.palette.required,
    fontSize: theme.fontSize.header,
    fontWeight: 'bold',
    marginTop: '80px',
  },
}))

let reissuePass = () => {}
const Text = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const { register, handleSubmit } = useForm()
  const onSubmit = () => {}
  const [mail, setMail] = useState('')
  const [errorMessage, setErrorMessage] = useState(false)
  const [inputErrs, setInputErrs] = useState({ mail: false })
  const [helperTxts, setHelperTxts] = useState({ mail: '' })

  const inputCheck = () => {
    if (mail === '' || inputErrs.mail) {
      return true
    } else {
      return false
    }
  }

  const setMailData = (event) => {
    const value = event.target.value
    setMail(value)
    const validationCheck = CommonValidation.mail(value)
    setInputErrs({ mail: validationCheck.checkResult })
    setHelperTxts({ mail: t(validationCheck.errMsg) })
  }

  const sentComplete = (res) => {
    switch (res.data.status) {
      case 200:
        CommonFunc.clickEvent(
          { path: Paths.AUTHENTICATION.REISSUE_PASSWORD_SENT },
          history
        )
        break
      case 400:
        setErrorMessage(true)
        break
      default:
        break
    }
  }

  reissuePass = () => {
    const req = {
      email: mail,
    }
    restFacade.post(
      ApiPaths.AUTHENTICATION.REISSUE_PASSWORD,
      (res) => sentComplete(res),
      req
    )
  }

  return (
    <Grid container justify="center">
      <FormControl required>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Grid container justify="center">
            {errorMessage ? (
              <div className={classes.required}>{t('D501V0066')}</div>
            ) : (
              <></>
            )}
            {/* テキストフィールド */}
            {errorMessage ? (
              <Grid item md={7}>
                <TextField
                  InputLabelProps={{
                    classes: {
                      root: classes.inputLabel,
                      focused: 'focused',
                      shrink: 'shrink',
                    },
                  }}
                  className={classes.errorRoot}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="email"
                  label={t('D001V0002')}
                  autoComplete="email"
                  name="email"
                  error={inputErrs.mail}
                  helperText={helperTxts.mail}
                  inputRef={register({
                    required: `${t('D501V0039')}`,
                  })}
                  autoFocus
                  onChange={setMailData}
                />
              </Grid>
            ) : (
              <Grid item md={10}>
                <TextField
                  InputLabelProps={{
                    classes: {
                      root: classes.inputLabel,
                      focused: 'focused',
                      shrink: 'shrink',
                    },
                  }}
                  className={classes.root}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="email"
                  label={t('D001V0002')}
                  autoComplete="email"
                  name="email"
                  error={inputErrs.mail}
                  helperText={helperTxts.mail}
                  inputRef={register({
                    required: `${t('D501V0039')}`,
                  })}
                  autoFocus
                  onChange={setMailData}
                />
              </Grid>
            )}
          </Grid>
          <Grid
            container
            justify="center"
            alignItems="center"
            spacing={4}
            className={classes.button}
          >
            <Grid item>
              <BackButton path={Paths.AUTHENTICATION.SIGN_IN} />
            </Grid>
            <Grid item>
              <PrimaryButton
                addclass={classes.sign}
                disabled={inputCheck()}
                onClick={reissuePass}
              >
                {t('D501V0050')}
              </PrimaryButton>
            </Grid>
          </Grid>
        </form>
      </FormControl>
    </Grid>
  )
}

export default Text
