import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import TextField from '../../common/text/TextField'
import CommonValidation from '../../common/function/CommonValidation'
import {
  UPDATE_FWDR_MAIL,
  UPDATE_FWDR_MAIL_INPUTERR,
  UPDATE_CAR_MAIL,
  UPDATE_CAR_MAIL_INPUTERR,
} from '../../../actions'

const useStyles = makeStyles((theme) => ({
  head: theme.textBoldBLU,
}))

/**
 * コンポーネントを更新するか判定する
 * @param {Object} prev 前回の値
 * @param {Object} next 最新の値
 * @returns {Boolean} falseの場合更新する
 */
const areEqual = (prev, next) => {
  // 各入力欄の値を比較
  // 一致していれば更新無し
  return prev.email.value === next.email.value
}

const Email = React.memo(({ dispatch, email, carrier }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [inputErrs, setInputErrs] = useState({ email: false })
  const [helperTxts, setHelperTxts] = useState({ email: '' })

  const handleChangeMail = (event) => {
    const value = event.target.value
    // 入力チェック
    const validationCheck = CommonValidation.mail(value)
    const err = validationCheck.checkResult
    setInputErrs({ ...inputErrs, email: err })
    setHelperTxts({ ...helperTxts, email: validationCheck.errMsg })

    dispatch({
      type: carrier ? UPDATE_CAR_MAIL : UPDATE_FWDR_MAIL,
      email: value,
    })

    // 入力エラーの場合、Savaボタン無効
    dispatch({
      type: carrier ? UPDATE_CAR_MAIL_INPUTERR : UPDATE_FWDR_MAIL_INPUTERR,
      inputErr: err,
    })
  }

  return (
    <div className={classes.formControl}>
      <Grid container>
        <Grid item md={4} xs={10}>
          <div className={classes.head}>{t('D301V0010')}</div>
          <TextField
            id="email"
            error={inputErrs.email}
            helperText={t(helperTxts.email)}
            value={email.value}
            onChange={handleChangeMail}
            autoComplete="off"
          />
        </Grid>
      </Grid>
    </div>
  )
}, areEqual)

Email.displayName = 'Email'

Email.propTypes = {
  dispatch: PropTypes.func.isRequired,
  email: PropTypes.object.isRequired,
  carrier: PropTypes.bool,
}

export default Email
