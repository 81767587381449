import { Table, TableBody, TableContainer } from '@material-ui/core'
import React, { useContext, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import AppContext from '../../../../contexts/AppContext'
import HeaderMeasureAndWeightLightBookingDetails from './HeaderMeasureAndWeightLightBookingDetails'
import RowMeasureAndWeightLightBookingDetails from './RowMeasureAndWeightLightBookingDetails'

const useStyles = makeStyles((Theme) => ({
  container: {
    overflowY: 'hidden',
    width: 'fit-content',
  },
  table: {
    minWidth: '100%',
    width: '100%',
    '& tr': {
      height: 'fit-content',
    },
    '& td': {
      border: '0px solid !important',
      padding: '0px 8px 8px 8px',
    },
    '& tr:first-child td': Theme.textNormalBLK,
    '& tr:not(:first-child) td': Theme.textBoldBLK,
  },
}))

/**
 * @param {Object} props 下記内容
 * @param {Boolean} props.editable 編集可能
 * @return {JSX} Measure&Weight欄のテーブル
 */
const TableMeasureAndWeightLightBookingDetails = ({ editable }) => {
  const classes = useStyles()
  const { state } = useContext(AppContext)

  return useMemo(
    () => (
      <TableContainer className={classes.container}>
        <Table className={classes.table}>
          <TableBody>
            <HeaderMeasureAndWeightLightBookingDetails />
            {state.Cargo.cargoDetails.map((_, index) => (
              <RowMeasureAndWeightLightBookingDetails
                key={index}
                index={index}
                editable={editable}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    ),
    [state.Cargo.cargoDetails, editable, classes]
  )
}
TableMeasureAndWeightLightBookingDetails.propTypes = {
  editable: PropTypes.bool.isRequired,
}
export default TableMeasureAndWeightLightBookingDetails
