import React, { useReducer, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'

import LayoutBox from '../../../common/layout/LayoutBox'
import AppContext from '../../../../contexts/AppContext'
import ApiPaths from '../../../../constants/ApiPaths'
import CommonFunc from '../../../common/function/CommonFunc'
import reducer, {
  initialState,
} from '../../../../reducers/airline/lightAirlineBookingDetails'
import { remarkNameArr } from '../../../../reducers/common/lightBookingDetails/Remark'
import { LIGHT_BOOKING_DETAILS } from '../../../../actions'

import TitleLightBookingDetails from './00TitleLightBookingDetails/TitleLightBookingDetails'
import ShippingDetailsLightBookingDetails from './01ShippingDetailsLightBookingDetails/ShippingDetailsAirlineLightBookingDetails'
import MeasureAndWeightLightBookingDetails from './02MeasureAndWeightLightBookingDetails/MeasureAndWeightAirlineLightBookingDetails'
import FlightLightBookingDetails from './03FlightLightBookingDetails/FlightAirlineLightBookingDetails'
import MawbNoLightBookingDetails from './04MawbNoLightBookingDetails/MawbNoAirlineLightBookingDetails'
import SpecialHandlingCodeLightBookingDetails from './05SpecialHandlingCodeLightBookingDetails/SpecialHandlingCodeAirlineLightBookingDetails'
import DocumentLightBookingDetails from './06DocumentLightBookingDetails/DocumentAirlineLightBookingDetails'
import RemarkLightBookingDetails from './07RemarkLightBookingDetails/RemarkAirlineLightBookingDetails'
import CancelBookingLightBookingDetails from './08CancelBookingLightBookingDetails/CancelAirlineLightBookingDetails'
import ButtonsLightBookingDetails from './09ButtonLightBookingDetails/ButtonsLightBookingDetails'

/**
 * @param {Object} props 下記内容
 * @param {Number} props.cargoId 貨物ID
 * @return {JSX} 航空会社BookingDetails画面コンポーネント
 */
const LightAirlineBookingDetails = (props) => {
  const history = useHistory()
  const [state, dispatch] = useReducer(reducer, initialState)

  const cargoId = props.history.location.state?.state

  useEffect(() => {
    ;(async () => {
      const req = { params: { cargoId } }
      const apiInfoArr = [
        {
          key: 'bookingDetails',
          method: 'get',
          path: ApiPaths.AIRLINE.LIGHT_BOOKING_DETAILS,
          req,
        },
        { key: 'shc', method: 'get', path: ApiPaths.MASTER_JH.SHC_CODE },
      ]

      const resObj = await CommonFunc.execApiAsync(apiInfoArr)
      const isApiSuccessful = CommonFunc.checkApiResponseObj(resObj, history)

      if (isApiSuccessful) {
        const { bookingDetails, shc } = resObj
        const results = bookingDetails.data.results
        const {
          org,
          dst,
          commodity,
          cargoDetailArr,
          laneObj,
          flightObj,
          mawbObj,
          shcArr,
          documentObj,
          cancelObj,
          bookingId,
          bookingUpdatedAt,
          cargoUpdatedAt,
          statusId,
        } = results

        dispatch({
          type: LIGHT_BOOKING_DETAILS.SHIPPING_DETAILS.SET,
          org,
          dst,
          commodity,
          bookingId,
          cargoId,
          bookingUpdatedAt,
          cargoUpdatedAt,
        })
        dispatch({
          type: LIGHT_BOOKING_DETAILS.CARGO.SET_CARGO_DETAILS,
          cargoDetails: cargoDetailArr,
        })
        dispatch({
          type: LIGHT_BOOKING_DETAILS.RATE.SET_RATE,
          rateObj: laneObj,
        })
        dispatch({
          type: LIGHT_BOOKING_DETAILS.FLIGHT.SET,
          ...flightObj,
        })
        dispatch({
          type: LIGHT_BOOKING_DETAILS.MAWB.SET,
          ...mawbObj,
        })
        dispatch({
          type: LIGHT_BOOKING_DETAILS.SPECIAL_HANDLING_CODE.SET,
          shcs: shc.data.results,
        })
        dispatch({
          type: LIGHT_BOOKING_DETAILS.SHC_ARR.SET,
          shcArr,
        })
        dispatch({
          type: LIGHT_BOOKING_DETAILS.REMARK.SET,
          ...remarkNameArr.reduce(
            (obj, remarkName) => ({
              ...obj,
              [remarkName]: results[remarkName] ?? '',
            }),
            {}
          ),
        })
        dispatch({
          type: LIGHT_BOOKING_DETAILS.DOCUMENT.SET,
          ...documentObj,
        })
        dispatch({
          type: LIGHT_BOOKING_DETAILS.CANCEL_BOOKING.SET,
          ...cancelObj,
        })
        dispatch({
          type: LIGHT_BOOKING_DETAILS.STATUS.SET,
          statusId,
        })
      }
    })()
  }, []) //eslint-disable-line

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <LayoutBox>
        <TitleLightBookingDetails />
        <Grid container justify="center">
          <ShippingDetailsLightBookingDetails />
          <MeasureAndWeightLightBookingDetails />
          <FlightLightBookingDetails />
          <MawbNoLightBookingDetails />
          <SpecialHandlingCodeLightBookingDetails />
          <DocumentLightBookingDetails />
          <RemarkLightBookingDetails />
          <CancelBookingLightBookingDetails />
          <ButtonsLightBookingDetails />
        </Grid>
      </LayoutBox>
    </AppContext.Provider>
  )
}

LightAirlineBookingDetails.propTypes = {
  cargoId: PropTypes.number,
}

export default LightAirlineBookingDetails
