// Display + 画面名
const DisplayFwdrDocsPreparation = {
  // 画面ID + 文言ID
  D104V0001: {
    en: 'Cargo Ready',
    ja: '書類の作成',
  },
  D104V0002: {
    en: 'MAWB No., ORG/DST, etc.',
    ja: 'MAWB 番号, ORG/DSTなど',
  },
  D104V0003: {
    en: 'Default',
    ja: 'デフォルト',
  },
  D104V0004: {
    en: 'KIX',
    ja: 'KIX',
  },
  D104V0005: {
    en: 'Sort by',
    ja: '並び替え',
  },
  D104V0006: {
    en: 'Lately',
    ja: '最近',
  },
  D104V0007: {
    en: 'MAWB No.',
    ja: 'MAWB番号',
  },
  D104V0008: {
    en: 'Pcs./Wgt./Vol.',
    ja: '個数/重量/体積',
  },
  D104V0009: {
    en: 'ORG/DST',
    ja: '出発/到着',
  },
  D104V0010: {
    en: 'Flight#',
    ja: 'フライト#',
  },
  D104V0011: {
    en: 'Status',
    ja: '状態',
  },
  D104V0012: {
    en: 'Cargo Ready.',
    ja: '書類作成',
  },
  D104V0013: {
    en: '000-0000 0000',
    ja: '000-0000 0000',
  },
  D104V0014: {
    en: '10pcs/300kg/2.05m3',
    ja: '10個/300kg/2.05m3',
  },
  D104V0015: {
    en: 'KIX/SYD',
    ja: 'KIX/SYD',
  },
  D104V0016: {
    en: 'KIX/YYZ',
    ja: 'KIX/YYZ',
  },
  D104V0017: {
    en: 'KIX/MNL',
    ja: 'KIX/MNL',
  },
  D104V0018: {
    en: 'HND/SFO',
    ja: 'HND/SFO',
  },
  D104V0019: {
    en: 'KIX/HKG',
    ja: 'KIX/HKG',
  },
  D104V0020: {
    en: 'NRT/CDG',
    ja: 'NRT/CDG',
  },
  D104V0021: {
    en: 'NRT/SVO',
    ja: 'NRT/SVO',
  },
  D104V0022: {
    en: 'KIX/BKK',
    ja: 'KIX/BKK',
  },
  D104V0023: {
    en: 'KIX/PVG',
    ja: 'KIX/PVG',
  },
  D104V0024: {
    en: 'FUK/TPE',
    ja: 'FUK/TPE',
  },
  D104V0025: {
    en: 'KIX/JFK',
    ja: 'KIX/JFK',
  },
  D104V0026: {
    en: 'HND/YUL',
    ja: 'HND/YUL',
  },
  D104V0027: {
    en: 'HND/MUC',
    ja: 'HND/MUC',
  },
  D104V0028: {
    en: 'KIX/HNL',
    ja: 'KIX/HNL',
  },
  D104V0029: {
    en: 'KIX/DMM',
    ja: 'KIX/DMM',
  },
  D104V0030: {
    en: 'KIX/DUB',
    ja: 'KIX/DUB',
  },
  D104V0031: {
    en: 'LHXXX/16AUG',
    ja: 'LHXXX/16AUG',
  },
  D104V0032: {
    en: 'KZXXX/15AUG',
    ja: 'KZXXX/15AUG',
  },
  D104V0033: {
    en: 'JLXXX/15AUG',
    ja: 'JLXXX/15AUG',
  },
  D104V0034: {
    en: 'NHXXX/14AUG',
    ja: 'NHXXX/14AUG',
  },
  D104V0035: {
    en: 'CXXXX/14AUG',
    ja: 'CXXXX/14AUG',
  },
  D104V0036: {
    en: 'RUXXX/14AUG',
    ja: 'RUXXX/14AUG',
  },
  D104V0037: {
    en: 'POXXX/12AUG',
    ja: 'POXXX/12AUG',
  },
  D104V0038: {
    en: 'TPXXX/12AUG',
    ja: 'TPXXX/12AUG',
  },
  D104V0039: {
    en: 'MSXXX/12AUG',
    ja: 'MSXXX/12AUG',
  },
  D104V0040: {
    en: 'QRXXX/11AUG',
    ja: 'QRXXX/11AUG',
  },
  D104V0041: {
    en: 'BRXXX/10AUG',
    ja: 'BRXXX/10AUG',
  },
  D104V0042: {
    en: 'OZXXX/10AUG',
    ja: 'OZXXX/10AUG',
  },
  D104V0043: {
    en: 'BAXXX/10AUG',
    ja: 'BAXXX/10AUG',
  },
  D104V0044: {
    en: 'DLXXX/09AUG',
    ja: 'DLXXX/09AUG',
  },
  D104V0045: {
    en: 'ACXXX/06AUG',
    ja: 'ACXXX/06AUG',
  },
  D104V0046: {
    en: 'Space Confirmed',
    ja: 'スペース確認済み',
  },
  D104V0047: {
    en: 'Pending',
    ja: '保留中',
  },
  D104V0048: {
    en: 'Booked',
    ja: '予約済み',
  },
  D104V0049: {
    en: 'In Transit',
    ja: '輸送中',
  },
  D104V0050: {
    en: 'Arrived',
    ja: '到着済み',
  },
  D104V0051: {
    en: 'Delivered',
    ja: '配達済み',
  },
  D104V0052: {
    en: 'Not Done',
    ja: '未解決',
  },
  D104V0053: {
    en: 'TBD',
    ja: 'TBD',
  },
  D104V0054: {
    en: 'Edit',
    ja: '編集',
  },
  D104V0055: {
    en: 'Done',
    ja: '済み',
  },
  D104V0056: {
    en: 'Cancelled',
    ja: 'キャンセル',
  },
  D104V0057: {
    en: 'Departed',
    ja: '出発済み',
  },
  D104V0058: {
    en: 'Documents',
    ja: '書類',
  },
  D104V0059: {
    en: 'Confirm',
    ja: '確認',
  },
  D104V0060: {
    en: 'MAWB No. Ascending',
    ja: 'MAWB No.昇順',
  },
  D104V0061: {
    en: 'MAWB No. Descending',
    ja: 'MAWB No.降順',
  },
  D104V0062: {
    en: 'Pieces',
    ja: '個数',
  },
  D104V0063: {
    en: 'Weight',
    ja: '重量',
  },
  D104V0064: {
    en: 'Volume',
    ja: '体積',
  },
  D104V0065: {
    en: 'Origin',
    ja: '出発地',
  },
  D104V0066: {
    en: 'Destination',
    ja: '到着地',
  },
  D104V0067: {
    en: 'Flight Number',
    ja: 'フライト番号',
  },
  D104V0068: {
    en: 'Take off date',
    ja: '離陸日',
  },
  D104V0069: {
    en: 'Status',
    ja: 'ステータス',
  },
  D104V0070: {
    en: 'Cargo Ready',
    ja: '書類作成',
  },
  D104V0071: {
    en: 'Past',
    ja: '過去',
  },
}
// Display + 画面名
export default DisplayFwdrDocsPreparation
