// Display + 画面名
const DisplayShipperManagingBid = {
  // 画面ID + 文言ID

  D007V0001: {
    en: 'MANAGING BID',
    ja: 'BIDの管理',
  },
  D007V0002: {
    en: 'Validity',
    ja: '有効期間',
  },
  D007V0003: {
    en: 'Department',
    ja: '部署',
  },
  D007V0004: {
    en: 'Set FWDR',
    ja: 'FWDRを設定',
  },
  D007V0005: {
    en: '1st Deadline',
    ja: '一次締め切り',
  },
  D007V0006: {
    en: 'Details',
    ja: '詳細',
  },
  D007V0007: {
    en: 'August 1, 2020 to January 31, 2020',
    ja: '2020年8月1日～2021年1月31日',
  },
  D007V0008: {
    en: 'Lighting Dept.',
    ja: '照明部門',
  },
  D007V0009: {
    en: 'Synergy System Dept.',
    ja: 'シナジーシステム部門',
  },
  D007V0010: {
    en: 'Mobile Solutions Dept.',
    ja: 'モバイルソリューション部門',
  },
  D007V0011: {
    en: 'HMI Systems Dept.',
    ja: 'HMIシステム部門',
  },
  D007V0012: {
    en: 'Mechatronics Dept.',
    ja: '電子工学部門',
  },
  D007V0013: {
    en: 'Industrial Device Dept.',
    ja: '産業用デバイス部門',
  },
  D007V0014: {
    en: 'Enegy Solution Dept.',
    ja: 'エネルギーソリューション部門',
  },
  D007V0015: {
    en: 'Infotainments Systems Dept.',
    ja: '情報通信システム部門',
  },
  D007V0016: {
    en: 'Process Automation Dept.',
    ja: '業務自動化部門',
  },
  D007V0017: {
    en: 'DZK / KNJ / EIK / NEC / YLK / KWE / HEI',
    ja: 'DZK / KNJ / EIK / NEC / YLK / KWE / HEI',
  },
  D007V0018: {
    en: 'DZK/KNJ/EIK',
    ja: 'DZK/KNJ/EIK',
  },
  D007V0019: {
    en: 'DZK',
    ja: 'DZK',
  },
  D007V0020: {
    en: 'July 31, 2020',
    ja: '2020年7月31日',
  },
  D007V0021: {
    en: 'Closed',
    ja: '終了',
  },
  D007V0022: {
    en: 'Under contract',
    ja: '契約中',
  },
  D007V0023: {
    en: 'Draft',
    ja: '見積り',
  },
  D007V0024: {
    en: 'Check',
    ja: '確認',
  },
  D007V0025: {
    en: 'Done',
    ja: '済み',
  },
  D007V0026: {
    en: 'Key words',
    ja: 'キーワード',
  },
  D007V0027: {
    en: 'Lately',
    ja: '最近',
  },
  D007V0028: {
    en: '-',
    ja: '-',
  },
  D007V0029: {
    en: 'Device Solution Dept.',
    ja: 'デバイスソリューション部門',
  },
  D007V0030: {
    en: 'Energy Device Dept.',
    ja: 'エネルギーデバイス部門',
  },
  D007V0031: {
    en: 'Media Entertainment Dept.',
    ja: 'メディアエンターテインメント部門',
  },
  D007V0032: {
    en: 'Public',
    ja: '公共交通機関',
  },
  D007V0033: {
    en: 'NEC/YLK/KWE/HEI',
    ja: 'NEC/YLK/KWE/HEI',
  },
  D007V0034: {
    en: '2nd Deadline',
    ja: '二次締め切り',
  },
  D007V0035: {
    en: '3rd Deadline',
    ja: '三次締め切り',
  },
  D007V0036: {
    en: '28AUG20',
    ja: '28AUG20',
  },
  D007V0037: {
    en: '29MAY20',
    ja: '29MAY20',
  },
  D007V0038: {
    en: '11SEP20',
    ja: '11SEP20',
  },
  D007V0039: {
    en: '12JUN20',
    ja: '12JUN20',
  },
  D007V0040: {
    en: '25SEP20',
    ja: '25SEP20',
  },
  D007V0041: {
    en: '26JUN20',
    ja: '26JUN20',
  },
  D007V0042: {
    en: '2 Days',
    ja: '2日',
  },
  D007V0043: {
    en: '(1st Deadline)',
    ja: '(一次締め切り)',
  },
  D007V0044: {
    en: 'Days Left',
    ja: '残り日数',
  },
}
// Display + 画面名
export default DisplayShipperManagingBid
