import { useHistory } from 'react-router-dom'
import React, { useReducer, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Grid, makeStyles } from '@material-ui/core'

import Theme from '../../../common/theme/Theme'
import LayoutBox from '../../../common/layout/LayoutBox'
import AppContext from '../../../../contexts/AppContext'
import CommonFunc from '../../../common/function/CommonFunc'
import ApiPaths from '../../../../constants/ApiPaths'
import reducer, {
  initialState,
} from '../../../../reducers/shipper/shipperBidInfo'
import {
  DATEPICKER,
  SET_FWDR,
  SPECIAL_NOTES,
  CONSIGNEE,
  PIC,
  SHIPPER_ADDRESS,
  PAGE_DATA,
} from '../../../../actions'
import TitleBidInfo from './00TitleBidInfo/TitleBidInfo'
import ShipperAddressBidInfo from './01ShipperAddressBidInfo/ShipperAddressBidInfo'
import ConsigneeBidInfo from './03ConsigneeBidInfo/ConsigneeBidInfo'
import ValidityBidInfo from './04ValidityBidInfo/ValidityBidInfo'
import SetForwarderBidInfo from './05SetForwarderBidInfo/SetForwarderBidInfo'
import DeadlineBidInfo from './06DeadlineBidInfo/DeadlineBidInfo'
import PicBidInfo from './02PicBidInfo/PicBidInfo'
import SpecialNotesBidInfo from './07SpecialNotesBidInfo/SpecialNotesBidInfo'
import ButtonsBidInfo from './08ButtonsBidInfo/ButtonsBidInfo'
import Shipper from '../../../common/bidInfo/Shipper'
import Pic from '../../../common/bidInfo/Pic'
import Consignee from '../../../common/bidInfo/Consignee'
import Validity from '../../../common/bidInfo/Validity'
import Deadline from '../../../common/bidInfo/Deadline'
import SpecialNotes from '../../../common/bidInfo/SpecialNotes'

const useStyles = makeStyles(() => ({
  childrenPadding: Theme.childrenPadding,
}))

/**
 * 前ページ情報、APIの値から初期データをセット
 * @param {Function} dispatch useContextのdispatch
 * @param {Object} pageDataObj 前ページ情報
 * @param {Object} resObj APIのres
 * @return {void}
 */
const setState = (dispatch, pageDataObj, resObj) => {
  const { accountInfo } = resObj
  const { bidInfoObj } = pageDataObj
  const { startDate, finishDate, firstDead, specialNoteArr, picArr } =
    bidInfoObj
  dispatch({
    type: PAGE_DATA.INIT,
    data: pageDataObj,
  })
  dispatch({
    type: SHIPPER_ADDRESS.INIT,
    data: bidInfoObj,
  })
  dispatch({
    type: DATEPICKER.SET_START_FINISH,
    startDate,
    finishDate,
  })
  dispatch({
    type: SET_FWDR.INIT,
    data: pageDataObj.selectFwdrObj,
    bidStatusId: pageDataObj.bidStatusObj.bidStatusId,
  })
  dispatch({
    type: DATEPICKER.SET,
    date: firstDead,
  })
  dispatch({
    type: SPECIAL_NOTES.INIT,
    data: specialNoteArr,
  })
  const accountInfoArr = accountInfo.data.results
  dispatch({
    type: PIC.INIT,
    picArr,
    accountInfoArr,
  })
}

/**
 * @param {Object} props 以下
 * @param {Object} history 前ページから受け取った情報
 * @returns {JSX} 荷主BidInfo画面コンポーネント
 */
const ShipperBidInfo = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const pageDataObj = props.history.location.state?.state ?? {}
  const history = useHistory()
  const classes = useStyles()

  // ステータスが未設定 → 新規追加or更新のどちらか
  const { bidStatusId } = pageDataObj.bidStatusObj
  const isEditMode = bidStatusId === null

  useEffect(() => {
    ;(async () => {
      const apiInfoArr = [
        {
          key: 'accountInfo',
          method: 'get',
          path: ApiPaths.MASTER_SHIPPER.ACCOUNT_INFO,
        },
        {
          key: 'consigneeAddress',
          method: 'get',
          path: ApiPaths.MASTER_SHIPPER.CONSIGNEE_ADDRESS,
        },
      ]

      const resObj = await CommonFunc.execApiAsync(apiInfoArr)
      const isApiSuccessful = CommonFunc.checkApiResponseObj(resObj, history)

      if (isApiSuccessful) {
        setState(dispatch, pageDataObj, resObj)
      }
    })()
  }, []) // eslint-disable-line

  /**
   * @return {JSX} 編集用BidInfo画面コンポーネント
   */
  const createEditBidInfo = () => {
    return (
      <>
        <TitleBidInfo />
        <ShipperAddressBidInfo />
        <PicBidInfo />
        <ValidityBidInfo />
        <SetForwarderBidInfo />
        <DeadlineBidInfo />
        <SpecialNotesBidInfo />
        <ButtonsBidInfo />
      </>
    )
  }

  /**
   * @return {JSX} 表示用BidInfo画面コンポーネント
   */
  const createDisplayBidInfo = () => {
    return (
      <>
        <TitleBidInfo />
        <Shipper bidInfo={props} />
        <Pic bidInfo={props} />
        <Validity bidInfo={props} />
        <SetForwarderBidInfo />
        <Deadline bidInfo={props} />
        <SpecialNotes bidInfo={props} />
        <ButtonsBidInfo />
      </>
    )
  }

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <LayoutBox>
        <Grid container justify="center">
          <Grid item xs={10} className={classes.childrenPadding}>
            {isEditMode ? createEditBidInfo() : createDisplayBidInfo()}
          </Grid>
        </Grid>
      </LayoutBox>
    </AppContext.Provider>
  )
}

ShipperBidInfo.propTypes = {
  history: PropTypes.object.isRequired,
}

export default ShipperBidInfo
