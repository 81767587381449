import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import AppContext from '../../../../../contexts/AppContext'
import Common from '../../../../../constants/Common'
import TableCellSelectForwarder from './TableCellSelectForwarder'
import ConstantsSelectForwarder from '../ConstantsSelectForwarder'
import CheckBoxSelectForwarder from './CheckBoxSelectForwarder'

/**
 * 定数で定義した表データのコンポーネントを作成
 * @param {Object} tableConstantsObj ConstantsSelectForwarderで定義したテーブルの要素
 * @param {Number} index mapのindex
 * @param {Object} state state useContextのstate
 * @param {Number} apiIndexNum APIの番号
 * @return {JSX} TableCellコンポーネント(1個分)
 */
const createTableCell = (tableConstantsObj, index, state, apiIndexNum) => {
  // 親から受けとったapiIndexNumとstateからデータを表示用データを取得
  const tableContentObj = state.Tables.showData[state.Paging - 1].find(
    (tableObj) => tableObj.apiIndexNum === apiIndexNum
  )
  const { displayObj } = tableContentObj
  // map処理用のkeyを作成
  const key = `${apiIndexNum}${index}`
  // 定数に紐づいている表示用のcell要素を取り出す
  const { cellKey, cellCategory } = tableConstantsObj
  const cellElement = displayObj[cellKey]

  switch (cellCategory) {
    case Common.CELL_CATEGORY.CHECKBOX.SELECT:
      return (
        <CheckBoxSelectForwarder
          key={key}
          hasCheck={cellElement}
          apiIndexNum={apiIndexNum}
        />
      )
    default:
      return <TableCellSelectForwarder key={key} displayStr={cellElement} />
  }
}

/**
 * 1行分の表データを作成
 * @param {Number} { apiIndexNum } APIの番号
 * @return {JSX} TableCellコンポーネント(1行分)
 */
const TableCellsSelectForwarder = ({ apiIndexNum }) => {
  const { state } = useContext(AppContext)

  return ConstantsSelectForwarder.TABLE_ARR.map((tableConstantsObj, index) =>
    createTableCell(tableConstantsObj, index, state, apiIndexNum)
  )
}

TableCellsSelectForwarder.propTypes = {
  apiIndexNum: PropTypes.number.isRequired,
}

export default TableCellsSelectForwarder
