// Display + 画面名
const DisplayAirlineRecordDetails = {
  // 画面ID + 文言ID
  D219V0001: {
    en: 'RECORD DETAILS',
    ja: 'レコード詳細',
  },
  D219V0002: {
    en: '* 「?」Input required',
    ja: '「?」は入力が必要です',
  },
  D219V0003: {
    en: 'Edit',
    ja: '編集',
  },
  D219V0004: {
    en: 'AWB Record',
    ja: 'AWBレコード',
  },
  D219V0005: {
    en: 'CCO Record',
    ja: 'CCOレコード',
  },
  D219V0006: {
    en: 'CCR Record',
    ja: 'CCRレコード',
  },
  D219V0007: {
    en: 'AW1 Record',
    ja: 'AW1レコード',
  },
  D219V0008: {
    en: 'CC3 Record',
    ja: 'CC3レコード',
  },
  D219V0009: {
    en: 'DC3 Record',
    ja: 'DC3レコード',
  },
  D219V0010: {
    en: 'Trailer Record',
    ja: 'Trailerレコード',
  },
  D219V0011: {
    en: 'CBV Record',
    ja: 'CBVレコード',
  },
  D219V0012: {
    en: 'Contains invalid characters.',
    ja: '無効な文字が含まれています。',
  },
  D219V0013: {
    en: 'DCO Record',
    ja: 'DCOレコード',
  },
  D219V0014: {
    en: 'DCR Record',
    ja: 'DCRレコード',
  },
  D219V0015: {
    en: 'Please input the record.',
    ja: 'レコードを入力してください。',
  },
}
// Display + 画面名
export default DisplayAirlineRecordDetails
