import React from 'react'
import { Grid } from '@material-ui/core'

import SearchSort from '../../../../commonUseContext/SearchSort'

/**
 * @return {JSX} サーチソートコンポーネント
 */
const SearchSortLightFwdrAccountInfo = () => {
  return (
    <Grid container justify="center">
      <Grid container item md={10} alignItems="center">
        <SearchSort noGridContainer />
      </Grid>
    </Grid>
  )
}

export default SearchSortLightFwdrAccountInfo
