import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import TableContainer from '@material-ui/core/TableContainer'
import TableBody from '@material-ui/core/TableBody'
import { makeStyles } from '@material-ui/core/styles'

import CommonNoResult from './CommonNoResult'
import Table from './Table'
const useStyles = makeStyles((theme) => ({
  tableHead: theme.tableNone,
  container: {
    paddingRight: '1px',
  },
}))

/**
 * TableContainer ~ TableBodyまでの表の外側の共通設定コンポーネント
 * @param {Object} { isNoResult, children } 下記の値を渡す
 * @param {Boolean} isNoResult データが無くNoResultを表示したい場合true
 * @param {JSX} children 表内に表示したい要素
 * @return {JSX} TableContainer ~ TableBodyまでのコンポーネント
 */
const CommonTableContainer = ({ isNoResult, children, className = '' }) => {
  const classes = useStyles()
  /**
   * TableContainer ~ TableBodyまでの表要素コンポーネント
   * @return {JSX} TableContainer ~ TableBodyまでのコンポーネント
   */
  const createTableContainer = () => {
    return (
      <TableContainer className={classes.container}>
        <Table className={`${classes.tableHead} ${className}`}>
          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>
    )
  }

  return (
    <Grid container justify="center">
      <Grid item md={10} xs={10}>
        {isNoResult ? <CommonNoResult /> : createTableContainer()}
      </Grid>
    </Grid>
  )
}

CommonTableContainer.propTypes = {
  isNoResult: PropTypes.bool.isRequired,
  children: PropTypes.node,
}

export default CommonTableContainer
