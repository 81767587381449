import { LIGHT_BOOKING_DETAILS } from '../../../actions'

/** @type {String[]} remarkの値名の配列 */
const remarkNameArr = [
  'specialNoteA',
  'specialNoteB',
  'specialNoteC',
  'specialNoteD',
  'specialNoteE',
]

/** @type {Object} の初期値 */
const initRemark = remarkNameArr.reduce(
  (obj, remarkName) => ({ ...obj, [remarkName]: null }),
  {}
)

/**
 * Remarkの編集データを保持
 * @param { Object } [state = initRemark] state
 * @param { Object } action action
 * @return { Object } state
 */
const Remark = (state = initRemark, action) => {
  const { type, ...rest } = action
  switch (type) {
    case LIGHT_BOOKING_DETAILS.REMARK.SET:
      return { ...rest }
    case LIGHT_BOOKING_DETAILS.REMARK.EDIT:
      return { ...state, ...rest }
    default:
      return state
  }
}

export default Remark

export { initRemark, remarkNameArr }
