import React, { useReducer, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import LayoutBox from '../../../common/layout/LayoutBox'
import AppContext from '../../../../contexts/AppContext'
import CommonFunc from '../../../common/function/CommonFunc'
import ApiPaths from '../../../../constants/ApiPaths'
import reducer, {
  initialState,
} from '../../../../reducers/master/lightMasterCarAirFreight'
import { CURRENCY, GSA, LIGHT_AIR_FREIGHT, TABLES } from '../../../../actions'

import TitleLightCarAirFreight from './00TitleLightCarAirFreight/TitleLightCarAirFreight'
import SearchAndCurrencySelectLightCarAirFreight from './01SearchLightCarAirFreight/SearchAndCurrencySelectLightCarAirFreight'
import ForwarderLightCarAirFreight from './02ForwarderLightCarAirFreight/ForwarderRowLightCarAirFreight'
import TableLightCarAirFreight from './03TableLightCarAirFreight/TableLightCarAirFreight'
import PagingCarFlightMgmt from './05PagingCarFlightMgmt/PagingCarFlightMgmt'
import ButtonCarFlightMgmt from './06ButtonCarFlightMgmt/ButtonCarAirFreight'

/**
 * AirFreight画面を作成
 * @param {Object} props props
 * @returns {JSX} AirFreight画面
 */
const LightCarAirFreight = (props) => {
  const history = useHistory()
  const [state, dispatch] = useReducer(reducer, initialState)

  const fwdrNameId = props.history.location.state?.state.fwdrNameId

  useEffect(() => {
    ;(async () => {
      const req = { params: { fwdrNameId } }
      const apiInfoArr = [
        {
          key: 'airFreight',
          method: 'get',
          path: ApiPaths.MASTER_CAR.LIGHT_AIR_FREIGHT,
          req,
        },
        { key: 'currency', method: 'get', path: ApiPaths.MASTER_JH.CURRENCY },
        { key: 'gsa', method: 'get', path: ApiPaths.MASTER_CAR.LIGHT_GSA },
      ]
      const resObj = await CommonFunc.execApiAsync(apiInfoArr)
      const isApiSuccessful = CommonFunc.checkApiResponseObj(resObj, history)

      if (isApiSuccessful) {
        const { airFreight, currency, gsa } = resObj
        const { companyName, fwdrthreeCode, currencyId, rateArr } =
          airFreight.data.results

        dispatch({
          type: CURRENCY.SET,
          data: currency.data.results,
        })
        dispatch({
          type: LIGHT_AIR_FREIGHT.CURRENCY.SET,
          currencyId,
        })
        dispatch({
          type: LIGHT_AIR_FREIGHT.FORWARDER.SET,
          companyName,
          fwdrthreeCode,
        })
        dispatch({
          type: TABLES.SET,
          tableData: rateArr,
        })
        dispatch({
          type: GSA.SET,
          gsa: gsa.data.results.gsaFlg,
        })
      }
    })()
  }, []) //eslint-disable-line

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <LayoutBox>
        <TitleLightCarAirFreight />
        <SearchAndCurrencySelectLightCarAirFreight />
        <ForwarderLightCarAirFreight />
        <TableLightCarAirFreight />
        <PagingCarFlightMgmt />
        <ButtonCarFlightMgmt fwdrNameId={fwdrNameId} />
      </LayoutBox>
    </AppContext.Provider>
  )
}

export default LightCarAirFreight
