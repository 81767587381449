// Display + 画面名
// JH/SHIP/FWDR/AIRLINE共用で使用
const DisplayMasterCompanyInfo = {
  // 画面ID + 文言ID

  D301V0001: {
    en: 'COMPANY INFO',
    ja: '会社情報',
  },
  D301V0002: {
    en: 'Company',
    ja: '会社名',
  },
  D301V0003: {
    en: 'Company Address',
    ja: '会社住所',
  },
  D301V0004: {
    en: 'Branch',
    ja: '支店名',
  },
  D301V0005: {
    en: 'Country',
    ja: '国',
  },
  D301V0006: {
    en: 'Representative',
    ja: '代表',
  },
  D301V0007: {
    en: 'First Name',
    ja: '名',
  },
  D301V0008: {
    en: 'Last Name',
    ja: '姓',
  },
  D301V0009: {
    en: 'Contact Information',
    ja: '連絡先',
  },
  D301V0010: {
    en: 'E-mail',
    ja: 'Eメール',
  },
  D301V0011: {
    en: 'Tel',
    ja: '電話番号',
  },
  D301V0012: {
    en: 'Branch Code',
    ja: '支店コード',
  },
  D301V0013: {
    en: 'Three-letter code',
    ja: '3レターコード',
  },
  D301V0014: {
    en: 'Two-letter code',
    ja: '2レターコード',
  },
  D301V0015: {
    en: 'Pickup --Preferred Time--',
    ja: '集荷 --優先時間--',
  },
  D301V0016: {
    en: 'IATA CASS No.',
    ja: 'IATA CASS番号',
  },
  D301V0017: {
    en: 'Plan',
    ja: 'プラン',
  },
  D301V0018: {
    en: 'Fee',
    ja: '料金',
  },
  D301V0019: {
    en: 'Icon',
    ja: 'アイコン',
  },
  D301V0020: {
    en: 'Delete account >',
    ja: '退会 >',
  },
  D301V0021: {
    en: 'Save',
    ja: '保存',
  },
  D301V0022: {
    en: 'MY PROFILE',
    ja: 'プロフィール',
  },
  D301V0023: {
    en: 'Position',
    ja: '役職',
  },
  D301V0024: {
    en: 'Department',
    ja: '部門',
  },
  D301V0025: {
    en: 'Code',
    ja: 'コード',
  },
  D301V0026: {
    en: 'Password Setting',
    ja: 'パスワード変更',
  },
  D301V0027: {
    en: 'Current Password',
    ja: '現在のパスワード',
  },
  D301V0028: {
    en: 'New Password',
    ja: '新しいパスワード',
  },
  D301V0029: {
    en: 'Retype New Password',
    ja: '新しいパスワード再入力',
  },
  D301V0030: {
    en: 'Signature',
    ja: 'サイン',
  },
  D301V0031: {
    en: 'E-mail Setting',
    ja: 'メール配信',
  },
  D301V0032: {
    en: 'Receive notifications by email',
    ja: '通知をメールで受け取る',
  },
  D301V0033: {
    en: 'ACCOUNT INFO',
    ja: 'ユーザー情報',
  },
  D301V0034: {
    en: 'Authority',
    ja: '管理権限',
  },
  D301V0035: {
    en: 'Edit',
    ja: '編集',
  },
  D301V0036: {
    en: 'Total',
    ja: '合計',
  },
  D301V0037: {
    en: 'Trader Management',
    ja: '業者情報',
  },
  D301V0038: {
    en: 'Forwarder',
    ja: '混載業者',
  },
  D301V0039: {
    en: 'Carrier',
    ja: '航空会社',
  },
  D301V0040: {
    en: 'Official Name',
    ja: '名称',
  },
  D301V0041: {
    en: 'Base Management',
    ja: '拠点管理',
  },
  D301V0042: {
    en: 'City',
    ja: '市',
  },
  D301V0043: {
    en: 'Station',
    ja: '拠点',
  },
  D301V0044: {
    en: 'Area Code',
    ja: 'エリアコード',
  },
  D301V0045: {
    en: 'Time Zone',
    ja: '標準時間帯',
  },
  D301V0046: {
    en: 'Label Mgmt.',
    ja: 'ラベル管理',
  },
  D301V0047: {
    en: 'Client Mgmt.',
    ja: '業者情報',
  },
  D301V0048: {
    en: 'Base Mgmt.',
    ja: '拠点管理',
  },
  D301V0049: {
    en: 'Cargo Mgmt.',
    ja: '貨物管理',
  },
  D301V0050: {
    en: 'Document Mgmt.',
    ja: 'ドキュメント管理',
  },
  D301V0051: {
    en: 'Label Management',
    ja: 'ラベル管理',
  },
  D301V0052: {
    en: 'DG Label',
    ja: '危険物ラベル',
  },
  D301V0053: {
    en: 'Other Label',
    ja: 'その他ラベル',
  },
  D301V0054: {
    en: 'Class/Div.',
    ja: '分割',
  },
  D301V0055: {
    en: 'Description',
    ja: '説明',
  },
  D301V0056: {
    en: 'Label',
    ja: 'ラベル',
  },
  D301V0057: {
    en: 'Please uploadd the label.',
    ja: 'ラベルをアップロードしてください。',
  },
  D301V0058: {
    en: 'Cargo Management',
    ja: '貨物管理',
  },
  D301V0059: {
    en: 'Weight',
    ja: '重さ',
  },
  D301V0060: {
    en: 'Measurement',
    ja: '商品ジャンル',
  },
  D301V0061: {
    en: 'Currency',
    ja: '通貨',
  },
  D301V0062: {
    en: 'Status',
    ja: 'ステータス',
  },
  D301V0063: {
    en: 'Symbol',
    ja: 'シンボル',
  },
  D301V0064: {
    en: 'Unit Name',
    ja: 'ユニット名称',
  },
  D301V0065: {
    en: 'Status Name',
    ja: 'ステータス名',
  },
  D301V0066: {
    en: 'Document Management',
    ja: 'ドキュメント管理',
  },
  D301V0067: {
    en: 'Shipping Details',
    ja: '配送詳細',
  },
  D301V0068: {
    en: 'DGD',
    ja: 'DGD',
  },
  D301V0069: {
    en: 'Packing Type',
    ja: '梱包タイプ',
  },
  D301V0070: {
    en: 'Terms',
    ja: '条件',
  },
  D301V0071: {
    en: 'Commodity',
    ja: '商品',
  },
  D301V0072: {
    en: 'SHC Code',
    ja: 'SHCコード',
  },
  D301V0073: {
    en: 'T/T',
    ja: '所要日数',
  },
  D301V0074: {
    en: 'UN No.',
    ja: 'UN番号',
  },
  D301V0075: {
    en: 'Proper Shippng Name',
    ja: '輸送名',
  },
  D301V0076: {
    en: 'Class',
    ja: 'クラス',
  },
  D301V0077: {
    en: 'Packing Group',
    ja: '梱包グループ',
  },
  D301V0078: {
    en: 'Packing Inst',
    ja: '梱包リスト',
  },
  D301V0079: {
    en: 'Type Name',
    ja: 'タイプ名称',
  },
  D301V0080: {
    en: 'Consignee Information',
    ja: '荷受人情報',
  },
  D301V0081: {
    en: 'Consignee',
    ja: '荷受人住所',
  },
  D301V0082: {
    en: 'PICKUP ADDRESS',
    ja: '集荷場所一覧',
  },
  D301V0083: {
    en: 'Postal Code',
    ja: '郵便番号',
  },
  D301V0084: {
    en: 'Address',
    ja: '住所',
  },
  D301V0085: {
    en: 'CONSIGNEE INFO',
    ja: '荷受人情報',
  },
  D301V0086: {
    en: 'ADD PROFIT',
    ja: '利益管理',
  },
  D301V0087: {
    en: 'ORG/DST',
    ja: '出発/到着',
  },
  D301V0088: {
    en: 'Via',
    ja: '経由',
  },
  D301V0089: {
    en: 'Min.',
    ja: 'Min.',
  },
  D301V0090: {
    en: 'Normal',
    ja: 'Normal',
  },
  D301V0091: {
    en: '+45kg',
    ja: '+45kg',
  },
  D301V0092: {
    en: '+100kg',
    ja: '+100kg',
  },
  D301V0093: {
    en: '+300kg',
    ja: '+300kg',
  },
  D301V0094: {
    en: '+500kg',
    ja: '+500kg',
  },
  D301V0095: {
    en: '+1,000kg',
    ja: '+1,000kg',
  },
  D301V0096: {
    en: 'Please enter the selling rates.',
    ja: '販売レートを入力してください。',
  },
  D301V0097: {
    en: 'Please input the specified rate.',
    ja: '指定された通貨で入力してください。',
  },
  D301V0098: {
    en: 'Other Charges',
    ja: 'その他手数料',
  },
  D301V0099: {
    en: 'Origin Charges',
    ja: '出荷費用',
  },
  D301V0100: {
    en: 'Freight Charges',
    ja: '運送費用',
  },
  D301V0101: {
    en: 'Destination Charges',
    ja: 'コンテナ取扱費用',
  },
  D301V0102: {
    en: 'Charges Name',
    ja: 'チャージ名称',
  },
  D301V0103: {
    en: 'Price',
    ja: '価格',
  },
  D301V0104: {
    en: 'Per',
    ja: '単位',
  },
  D301V0105: {
    en: 'Due',
    ja: '支払い',
  },
  D301V0106: {
    en: 'Show Quote',
    ja: '見積もりに表示',
  },
  D301V0107: {
    en: 'AWB Mgmt.',
    ja: 'AWB管理',
  },
  D301V0108: {
    en: 'Terms and Conditions',
    ja: '利用規約管理',
  },
  D301V0109: {
    en: 'AWB Management',
    ja: 'AWB管理',
  },
  D301V0110: {
    en: 'MAWB Management',
    ja: 'MAWB管理',
  },
  D301V0111: {
    en: 'HAWB Management',
    ja: 'HAWB管理',
  },
  D301V0112: {
    en: 'MAWB No.',
    ja: 'MAWB番号',
  },
  D301V0113: {
    en: 'HAWB No.',
    ja: 'HAWB番号',
  },
  D301V0114: {
    en: 'Condition',
    ja: '使用状況',
  },
  D301V0115: {
    en: 'Select Forwarder',
    ja: '混載業者選択',
  },
  D301V0116: {
    en: 'Default:KIX',
    ja: 'デフォルト:KIX',
  },
  D301V0117: {
    en: 'Branch(Stn.)',
    ja: '拠点(空港)',
  },
  D301V0118: {
    en: 'ULD Mgmt.',
    ja: 'ULD管理',
  },
  D301V0119: {
    en: 'Flight Mgmt.',
    ja: '航空機管理',
  },
  D301V0120: {
    en: 'MAWB Mgmt.',
    ja: 'MAWB管理',
  },
  D301V0121: {
    en: 'ULD List',
    ja: 'ULD一覧',
  },
  D301V0122: {
    en: 'ULD Type',
    ja: 'ULDタイプ',
  },
  D301V0123: {
    en: 'ULD No.',
    ja: 'ULD番号',
  },
  D301V0124: {
    en: 'Stn.',
    ja: '空港',
  },
  D301V0125: {
    en: 'Flight Management',
    ja: '航空機管理',
  },
  D301V0126: {
    en: 'Aircraft Info.',
    ja: '航空機情報',
  },
  D301V0127: {
    en: 'Flight Info.',
    ja: 'フライト情報',
  },
  D301V0128: {
    en: 'Air Freight',
    ja: '航空貨物情報',
  },
  D301V0129: {
    en: 'Fuel SurCharge',
    ja: '燃油特別付加料金情報',
  },
  D301V0130: {
    en: 'ETA/ETD',
    ja: '到着予定時刻/出発予定時刻',
  },
  D301V0131: {
    en: 'Flight Number',
    ja: '航空便',
  },
  D301V0132: {
    en: 'Reg. Number',
    ja: '登録番号',
  },
  D301V0133: {
    en: 'Aircraft',
    ja: '航空機',
  },
  D301V0134: {
    en: 'Area',
    ja: 'エリア',
  },
  D301V0135: {
    en: 'Unit',
    ja: 'ユニット',
  },
  D301V0136: {
    en: 'MAX Wgt.(kg)',
    ja: '最大重量',
  },
  D301V0137: {
    en: 'MAX Vol.(m3)',
    ja: '最大容積',
  },
  D301V0138: {
    en: 'MAX ULD',
    ja: '最大ULD数',
  },
  D301V0139: {
    en: 'Main Deck',
    ja: 'メインデッキ搭載数',
  },
  D301V0140: {
    en: 'Lower Deck',
    ja: 'ロアデッキ搭載数',
  },
  D301V0141: {
    en: 'Rate Class',
    ja: 'レートクラス',
  },
  D301V0142: {
    en: 'TC1',
    ja: 'TC1',
  },
  D301V0143: {
    en: 'TC2',
    ja: 'TC2',
  },
  D301V0144: {
    en: 'TC3',
    ja: 'TC3',
  },
  D301V0145: {
    en: 'ORG',
    ja: '出発',
  },
  D301V0146: {
    en: 'DST',
    ja: '到着',
  },
  D301V0147: {
    en: 'ETA',
    ja: '到着予定時刻',
  },
  D301V0148: {
    en: 'ETD',
    ja: '出発予定時刻',
  },
  D301V0149: {
    en: 'Void AWB',
    ja: 'AWB無効化',
  },
  D301V0150: {
    en: 'Please click the box if you want to void the below AWB.',
    ja: '以下のAWBを無効にする場合はボックスをクリックしてください。',
  },
  D301V0151: {
    en: 'Your changes have not been saved.',
    ja: '変更は保存されていません。',
  },
  D301V0152: {
    en: 'Are you sure?',
    ja: '変更を保存しますか?',
  },
  D301V0153: {
    en: 'Saved changes.',
    ja: '保存しました。',
  },
  D301V0154: {
    en: 'The data has been updated by another member. Please try again, after you reload the web browser.',
    ja: 'データは別のメンバーによって更新されました。Webブラウザをリロードした後、再試行してください。',
  },
  D301V0155: {
    en: 'Please confirm the input data.',
    ja: '入力内容に誤りがないかご確認ください。',
  },
  D301V0156: {
    en: 'The Token has expired. Please sign in again.',
    ja: '認証が切れました。再度サインインを行ってください。',
  },
  D301V0157: {
    en: 'Please confirm connecting to internet.',
    ja: 'ネットワーク接続をご確認ください。',
  },
  D301V0158: {
    en: "Couldn't save.",
    ja: '保存できませんでした。',
  },
  D301V0159: {
    en: 'The password is incorrect.Retype your password.',
    ja: 'パスワードに誤りがあります。再度入力してください。',
  },
  D301V0160: {
    en: '*Password do not match.',
    ja: '*パスワードが一致しません。',
  },
  D301V0161: {
    en: '*Please enter the same value.',
    ja: '*同じ値を入力してください。',
  },
  D301V0162: {
    en: 'First Name, Last Name etc...',
    ja: '姓, 名など...',
  },
  D301V0163: {
    en: 'No image...',
    ja: '画像なし...',
  },
  D301V0164: {
    en: 'Add account',
    ja: 'アカウント追加',
  },
  D301V0165: {
    en: 'Not',
    ja: 'なし',
  },
  D301V0166: {
    en: 'Have',
    ja: 'あり',
  },
  D301V0167: {
    en: 'Do you want to Delete?',
    ja: '削除しますか? ',
  },
  D301V0168: {
    en: 'COMPANY',
    ja: '会社',
  },
  D301V0169: {
    en: 'INFO',
    ja: '情報',
  },
  D301V0170: {
    en: 'ACCOUNT',
    ja: 'ユーザー',
  },
  D301V0171: {
    en: 'PICKUP',
    ja: '集荷場所',
  },
  D301V0172: {
    en: 'ADDRESS',
    ja: '一覧',
  },
  D301V0173: {
    en: 'CONSIGNEE',
    ja: '荷受人',
  },
  D301V0174: {
    en: 'Carrier, ORG/DST...',
    ja: '航空会社, 出発/到着...',
  },
  D301V0175: {
    en: 'Hubs',
    ja: '拠点空港',
  },
  D301V0176: {
    en: 'Search for OC Code',
    ja: 'OCコードを検索',
  },
  D301V0177: {
    en: 'TERMS AND',
    ja: '利用規約',
  },
  D301V0178: {
    en: 'CONDITIONS',
    ja: '管理',
  },
  D301V0179: {
    en: 'OTHER',
    ja: 'その他',
  },
  D301V0180: {
    en: 'CHARGES',
    ja: '手数料',
  },
  D301V0181: {
    en: 'The same number is registered',
    ja: '同じ番号が登録されています',
  },
  D301V0182: {
    en: 'Enter 3 digits',
    ja: '3桁を入力',
  },
  D301V0183: {
    en: 'Enter 4 digits',
    ja: '4桁を入力',
  },
  D301V0184: {
    en: 'Not use',
    ja: '使用不可',
  },
  D301V0185: {
    en: 'Shipper/ConsigneeID',
    ja: 'Shipper/ConsigneeID',
  },
  D301V0186: {
    en: 'Airport',
    ja: '仕出し空港',
  },
  D301V0187: {
    en: '*Please select up to 5',
    ja: '*最大5件まで選択してください',
  },
  D301V0188: {
    en: 'Key words',
    ja: 'キーワード',
  },
  D301V0189: {
    en: 'Select',
    ja: '公開',
  },
  D301V0190: {
    en: 'Unselect',
    ja: '非公開',
  },
  D301V0191: {
    en: 'SELECT',
    ja: '混載業者',
  },
  D301V0192: {
    en: 'FORWARDER',
    ja: '選択',
  },
  D301V0193: {
    en: 'FLIGHT',
    ja: '航空機',
  },
  D301V0194: {
    en: 'Mgmt.',
    ja: '管理',
  },
  D301V0195: {
    en: 'MAWB',
    ja: 'MAWB',
  },
  D301V0196: {
    en: 'The nearest airport',
    ja: '最寄り空港',
  },
  D301V0197: {
    en: 'days',
    ja: '日',
  },
  D301V0198: {
    en: 'Flight Schedule',
    ja: 'フライト予定',
  },
  D301V0199: {
    en: 'None',
    ja: 'なし',
  },
  D301V0200: {
    en: 'Please enter in the format xx:xx.',
    ja: 'xx:xxの形式で入力してください',
  },
  D301V0201: {
    en: 'Please enter the correct time.',
    ja: '正しい時刻を入力してください',
  },
  D301V0202: {
    en: 'If you enter more than one, separate them with /. (ex. A000/A001 )',
    ja: '複数入力する場合は / で区切ってください(例 A000/A001 )',
  },
  D301V0203: {
    en: 'Input "0" if there is no deck.',
    ja: 'デッキがない場合は0を入力してください',
  },
  D301V0204: {
    en: 'MAWB No., Forwarder',
    ja: 'MAWB番号, 混載業者名',
  },
  D301V0205: {
    en: 'Allocate',
    ja: '割り当て',
  },
  D301V0206: {
    en: 'Select Void',
    ja: '無効選択',
  },
  D301V0207: {
    en: 'Icon for Shipper selection',
    ja: '荷主公開用アイコン',
  },
  D301V0208: {
    en: 'Icon for Document',
    ja: '書類用アイコン',
  },
  D301V0209: {
    en: 'Department Code',
    ja: '所属部門コード',
  },
  D301V0210: {
    en: 'AIR FREIGHT Mgmt.',
    ja: '航空貨物管理',
  },
  D301V0211: {
    en: 'FUEL',
    ja: '燃料',
  },
  D301V0212: {
    en: 'SURCHARGE',
    ja: '特別付加料金情報',
  },
  D301V0213: {
    en: 'SELECT',
    ja: '選択',
  },
  D301V0214: {
    en: 'FORWARDER',
    ja: '混載業者',
  },
  D301V0215: {
    en: 'AIR FREIGHT Mgmt.',
    ja: '航空貨物管理',
  },
  D301V0216: {
    en: 'Contains invalid characters.',
    ja: '無効な文字が含まれています。',
  },
  D301V0217: {
    en: 'TC1 required',
    ja: 'TC1は入力必須です',
  },
  D301V0218: {
    en: 'TC2 required',
    ja: 'TC2は入力必須です',
  },
  D301V0219: {
    en: 'TC3 required',
    ja: 'TC3は入力必須です',
  },
  D301V0220: {
    en: 'RateClass',
    ja: 'レートクラス',
  },
  D301V0221: {
    en: 'Delete',
    ja: '削除',
  },
  D301V0222: {
    en: 'Routing',
    ja: 'ルーティング',
  },
  D301V0223: {
    en: 'Origin',
    ja: '出発空港',
  },
  D301V0224: {
    en: 'Destination',
    ja: '到着空港',
  },
  D301V0225: {
    en: 'Effective Date Start',
    ja: '適用開始日',
  },
  D301V0226: {
    en: 'Effective Date End',
    ja: '適用終了日',
  },
  D301V0227: {
    en: 'Flight time',
    ja: '飛行時間',
  },
  D301V0228: {
    en: 'Number of MAWB created',
    ja: '作成されるMAWBの数',
  },
  D301V0229: {
    en: 'Routing1',
    ja: 'ルーティング1',
  },
  D301V0230: {
    en: 'Routing2',
    ja: 'ルーティング2',
  },
  D301V0231: {
    en: 'Routing3',
    ja: 'ルーティング3',
  },
  D301V0232: {
    en: 'This flight number is already in use',
    ja: 'このフライトナンバーはすでに使用されています',
  },
  D301V0233: {
    en: 'AIR FREIGHT',
    ja: 'AIR FREIGHT',
  },
  D301V0234: {
    en: 'Type',
    ja: '種類',
  },
  D301V0235: {
    en: 'GEN',
    ja: '一般',
  },
  D301V0236: {
    en: 'DG',
    ja: '危険品',
  },
  D301V0237: {
    en: 'ICE',
    ja: '冷蔵',
  },
  D301V0238: {
    en: 'Hide',
    ja: '表示なし',
  },
  D301V0239: {
    en: 'Please set the route in the FlightInfo on the FlightMgmt screen',
    ja: 'FlightMgmt画面のFlightInfoで路線を設定してください',
  },
  D301V0240: {
    en: 'Show',
    ja: '閲覧',
  },
  D301V0241: {
    en: 'The email address and password you entered have already been registered.',
    ja: '入力したメールアドレスとパスワードは既に登録されています。',
  },
  D301V0242: {
    en: 'The IATA CASS Number you entered has already been registered.',
    ja: '入力したIATA CASS Numberは既に登録されています。',
  },
  D301V0243: {
    en: 'The Shipper/ConsigneeID you entered has already been registered.',
    ja: '入力したShipper/ConsigneeIDは既に登録されています。',
  },
  D301V0244: {
    en: 'The user you entered has already been registered.',
    ja: '入力したユーザは既に登録されています。',
  },
  D301V0245: {
    en: 'Below the minimum value',
    ja: '最小値を下回っています',
  },
  D301V0246: {
    en: 'The email address you entered have already been registered.',
    ja: '入力したメールアドレスは既に登録されています。',
  },
  D301V0247: {
    en: 'Please enter the number of 11 separators',
    ja: '11区切りの数を入力してください',
  },
  D301V0248: {
    en: 'N/A',
    ja: 'N/A',
  },
  D301V0249: {
    en: 'ex. 12-3 1234/1234 → 12312341234',
    ja: 'ex. 12-3 1234/1234 → 12312341234',
  },
  D301V0250: {
    en: 'ex. SS, FR, AA, etc.',
    ja: 'ex. SS, FR, AA, etc.',
  },
  D301V0251: {
    en: 'Please enter your Local-time.',
    ja: '現地時刻で入力してください',
  },
  D301V0252: {
    en: '*Please enter your fuel surcharge in advance',
    ja: '*事前に燃油サーチャージを入力してください',
  },
  D301V0253: {
    en: 'Please enter within 23:59.',
    ja: '23:59以内で入力してください',
  },
  D301V0254: {
    en: 'The email address you entered has already been entered or registered.',
    ja: '入力したメールアドレスは既に入力または登録されています',
  },
  D301V0255: {
    en: 'Please reload and update to the latest information.',
    ja: 'リロードして最新情報に更新してください',
  },
  D301V0256: {
    en: 'Please select a range that is wider than the previously entered value.',
    ja: '前に入力した値よりも広い範囲を選択してください。',
  },
  D301V0257: {
    en: 'Please enter Number of MAWB created less than or equal to 1,000.',
    ja: '作成されるMAWBの数は1,000以下で入力してください。',
  },
  D301V0258: {
    en: 'Number of HAWB created',
    ja: '作成されるHAWBの数',
  },
  D301V0259: {
    en: 'Please enter Number of HAWB created less than or equal to 1,000.',
    ja: '作成されるHAWBの数は1,000以下で入力してください。',
  },
  D301V0260: {
    en: 'CNSL Center Mgmt',
    ja: 'CNSL Center管理',
  },
  D301V0261: {
    en: 'Total MAWB',
    ja: '割り当てMAWB合計',
  },
  D301V0262: {
    en: 'Available MAWB',
    ja: '使用可能なMAWB',
  },
  D301V0263: {
    en: 'Updated By',
    ja: '入力状況',
  },
  D301V0264: {
    en: 'Updated Date',
    ja: '更新日',
  },
  D301V0265: {
    en: 'Bond-In Address',
    ja: '搬入先住所',
  },
  D301V0266: {
    en: 'Service offices',
    ja: 'サービスオフィス',
  },
  D301V0267: {
    en: 'Please enter the number of MAWB to be assigned, less than or equal to 1,000.',
    ja: '割り振るMAWBの数は1,000以下で入力してください。',
  },
  D301V0268: {
    en: 'Please enter a value less than or equal to the Available MAWB.',
    ja: '使用可能なMAWBの数以下で入力してください。',
  },
  D301V0269: {
    en: 'Stn.',
    ja: '拠点',
  },
  D301V0270: {
    en: 'The Branch you entered has already been registered.',
    ja: '入力した拠点は既に登録されています。',
  },
  D301V0271: {
    en: 'The Service Offices you entered has already been registered.',
    ja: '入力した対応可能都市は既に登録されています。',
  },
  D301V0272: {
    en: 'The Hubs you entered has already been registered',
    ja: '入力した拠点空港は既に登録されています',
  },
  D301V0273: {
    en: 'The total number of HAWB that can be created is 108,000.',
    ja: '作成可能なHAWBの数は合計108,000件です。',
  },
  D301V0274: {
    en: 'Duplicated with other users.',
    ja: '他のユーザーと重複しています。',
  },
  D301V0275: {
    en: 'Mail system error occurred.',
    ja: 'メールシステムにエラーが発生しました。',
  },
  D301V0276: {
    en: 'Timeout error occurred.',
    ja: 'タイムアウトエラーが発生しました。',
  },
}
// Display + 画面名
export default DisplayMasterCompanyInfo
