import React, { useState } from 'react'
import FormControl from '@material-ui/core/FormControl'
import { makeStyles } from '@material-ui/core/styles'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import FilledInput from '@material-ui/core/FilledInput'
import FormHelperText from '@material-ui/core/FormHelperText'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    '& .MuiFilledInput-root': {
      backgroundColor: 'rgba(250, 250, 250, 0.65)',
    },
  },
  input: {
    '&:-webkit-autofill': {
      WebkitTextFillColor: '#595959',
    },
  },
}))

/**
 * @param {Object} props - 以下
 * @param {String} id - idを指定
 * @param {String} label - labelを指定
 * @param {useStyles} className - スタイルを指定
 * @param {String} placeholder - placeholderを指定
 * @param {Boolean} disabled - 入力不可にする
 * @param {Event} handleChange - 入力変更時のイベント
 * @param {*} etc... - その他Material-UI TextFieldを参照してください
 * @returns {*} - PasswordField
 */
const PasswordField = (props) => {
  const classes = useStyles()
  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  return (
    <FormControl
      className={classes.root}
      inputprops={{ className: classes.input }}
      variant="filled"
      error
    >
      <FilledInput
        id={props.id}
        label={props.label}
        type={showPassword ? 'text' : 'password'}
        value={props.value}
        onChange={props.handleChange}
        error={props.err}
        autoComplete={props.autoComplete}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
      <FormHelperText id="component-error-text">
        {props.helperTxt}
      </FormHelperText>
    </FormControl>
  )
}

export default PasswordField
