import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import TextField from '../../../common/text/TextField'
import { LIGHT_BOOKING_DETAILS } from '../../../../actions'
import CommonValidation from '../../../common/function/CommonValidation'

/**
 * @param {Object} props 下記内容
 * @param {Function} props.dispatch dispatch
 * @param {String} props.defaultValue 初期値
 * @param {String} props.placeholder placeholder
 * @return {JSX} Remark入力欄
 */
const RemarkPopupTextField = ({ dispatch, defaultValue, placeholder }) => {
  const { t } = useTranslation()
  const [error, setError] = useState(false)
  const [helperText, setHelperText] = useState(CommonValidation.remark(false))

  /**
   * Remarkを更新
   * @param {String} value 入力値
   * @returns {void} なし
   */
  const onChange = (value) => {
    const { checkResult, errMsg } = CommonValidation.remark(value)
    setError(checkResult)
    setHelperText(checkResult ? errMsg : CommonValidation.remark(false))
    dispatch({
      type: LIGHT_BOOKING_DETAILS.REMARK_POPUP.EDIT,
      remark: checkResult ? null : value,
    })
  }

  return (
    <TextField
      defaultValue={defaultValue}
      error={error}
      helperText={t(helperText)}
      onChange={(event) => onChange(event.target.value)}
      placeholder={placeholder}
    />
  )
}
RemarkPopupTextField.propTypes = {
  dispatch: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
}
export default RemarkPopupTextField
