import React from 'react'
import { useTranslation } from 'react-i18next'

import RegisterTitle from '../../../../common/title/RegisterTitle'
import RegisterSubTitle from '../../../../common/subTitle/RegisterSubTitle'

const TitleLightAirlineRegisterNewAccount = () => {
  const { t } = useTranslation()
  return (
    <>
      <RegisterTitle>{t('D501V0010')}</RegisterTitle>
      <RegisterSubTitle>{t('D501V0011')}</RegisterSubTitle>
    </>
  )
}

export default TitleLightAirlineRegisterNewAccount
