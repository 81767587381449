import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import PrimaryButton from './PrimaryButton'

const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.buttonSize.width.large,
    height: theme.buttonSize.height.large,
    [theme.breakpoints.down('sm')]: {
      width: theme.buttonSize.width.medium,
      height: theme.buttonSize.height.medium,
    },
  },
}))

/**
 * @param {Object} props - 以下
 * @param {string} path 押下後の遷移先
 * @param {Event} clickevent - 押下後のイベント
 * @param {Object} state - 遷移先に渡すデータ
 * @param {Boolean} isdisabledclass - backボタンで設定されているクラスを無効化するか
 * @returns {JSX.Element} - backボタンコンポーネント
 */
const OkButton = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <PrimaryButton
      disabled={!props.checked}
      addclass={props.isdisabledclass ? '' : classes.root}
      {...props}
    >
      {t('D014V0048')}
    </PrimaryButton>
  )
}

export default OkButton
