/* eslint-disable no-extra-parens */
import React, { useState, useEffect, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { useHistory } from 'react-router-dom'

import AppContext from '../../../../contexts/AppContext'
import reducer from '../../../../reducers/master/masterJhCompanyInfoM'
import {
  UPDATE_JH_COMPANY_NAME,
  UPDATE_JH_COMPANY_ADDRESS,
  UPDATE_JH_FIRST_NAME,
  UPDATE_JH_LAST_NAME,
  UPDATE_JH_MAIL,
  UPDATE_JH_TEL,
  UPDATE_JH_ICON_PATH,
  UPDATE_JH_BACKUP,
  UPDATE_JH_POSTAL_CODE,
  UPDATE_JH_UPDATE_AT,
  UPDATE_JH_FILE,
} from '../../../../actions'
import Paths from '../../../../constants/Paths'
import Title from '../../../common/title/Title'
import SaveAndBack from '../../../common/buttons/SaveAndBack'
import LayoutBox from '../../../common/layout/LayoutBox'
import CompanyName from './CompanyName'
import CompanyAddress from './CompanyAddress'
import Representative from './Representative'
import ContactInfo from './ContactInfo'
import Icon from './Icon'
import PostalCode from './PostalCode'
import ApiPaths from '../../../../constants/ApiPaths'
import CommonFunc from '../../../common/function/CommonFunc'
import restFacade from '../../../../actions/rest-facade'

const useStyles = makeStyles(() => ({
  buttons: {
    justifyContent: 'center',
    marginBottom: 30,
    marginTop: 30,
  },
  down: {
    marginBottom: 30,
    margin: 'auto',
  },
}))

const MasterJHCompanyInfoM = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const [saveComp, setSaveComp] = useState(false)
  const [errCode, setErrCode] = useState(0)
  const [representativeFlg, setRepresentativeFlg] = useState(false)
  const initialState = {
    companyinfo: {
      companyName: { value: '', inputErr: false },
      postalCode: { value: '', inputErr: false },
      address: { value: '', inputErr: false },
      firstName: { value: '', inputErr: false },
      lastName: { value: '', inputErr: false },
      mail: { value: '', inputErr: false },
      tel: { value: '', inputErr: false },
      icon: { value: '', inputErr: false },
      updatedAt: { value: '' },
      isDeleteIcon: { value: '' },
    },
    backup: {
      companyName: '',
      postalCode: '',
      address: '',
      firstName: '',
      lastName: '',
      mail: '',
      tel: '',
      icon: '',
    },
    file: {
      file: '',
    },
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  let setData = () => {}

  // Saveボタンの有効無効判定
  const chkSaveDisable = () => {
    if (
      state.companyinfo.companyName.inputErr === true ||
      state.companyinfo.postalCode.inputErr === true ||
      state.companyinfo.address.inputErr === true ||
      state.companyinfo.firstName.inputErr === true ||
      state.companyinfo.lastName.inputErr === true ||
      state.companyinfo.mail.inputErr === true ||
      state.companyinfo.tel.inputErr === true ||
      state.companyinfo.icon.inputErr === true
    ) {
      return true
    }
    return false
  }
  // 入力変更ありなし判定
  const chkInputChg = () => {
    const sta = state.companyinfo
    const bk = state.backup
    if (
      bk.companyName !== sta.companyName.value ||
      bk.postalCode !== sta.postalCode.value ||
      bk.address !== sta.address.value ||
      bk.firstName !== sta.firstName.value ||
      bk.lastName !== sta.lastName.value ||
      bk.mail !== sta.mail.value ||
      bk.tel !== sta.tel.value ||
      bk.icon !== sta.icon.value
    ) {
      return true
    }
    return false
  }

  // 保存完了後の処理
  const saveComplete = () => {
    setSaveComp(false)
    if (errCode === 0) {
      // 保存に成功したらデータ再取得
      restFacade.get(ApiPaths.MASTER_JH.COMPANY_INFO, (res) =>
        setData(res, setCompanyInfoCB)
      )
    }
  }

  // 保存完了時のコールバック
  const saveDataCB = (response) => {
    setSaveComp(true)
    setErrCode(response.data.info.ErrorCode)
  }

  // 保存処理
  const saveData = () => {
    const req = {
      companyName: state.companyinfo.companyName.value,
      postalCode: state.companyinfo.postalCode.value,
      address: state.companyinfo.address.value,
      firstName: state.companyinfo.firstName.value,
      lastName: state.companyinfo.lastName.value,
      mail: state.companyinfo.mail.value,
      tel: state.companyinfo.tel.value,
      icon: state.companyinfo.icon.value,
      updatedAt: state.companyinfo.updatedAt.value,
      file: state.file.file,
      isDeleteIcon: state.companyinfo.isDeleteIcon.value,
      representativeFlg: representativeFlg ? 1 : 0,
    }
    restFacade.post(
      ApiPaths.MASTER_JH.COMPANY_INFO,
      (res) => CommonFunc.callbackFunc(res, saveDataCB, history),
      req
    )
  }

  const setCompanyInfoCB = (response) => {
    const res = response.data.results
    dispatch({
      type: UPDATE_JH_COMPANY_NAME,
      companyName: res.companyName,
    })
    dispatch({
      type: UPDATE_JH_POSTAL_CODE,
      postalCode: res.postalCode,
    })
    dispatch({
      type: UPDATE_JH_COMPANY_ADDRESS,
      address: res.address,
    })
    dispatch({
      type: UPDATE_JH_FIRST_NAME,
      firstName: res.firstName,
    })
    dispatch({
      type: UPDATE_JH_LAST_NAME,
      lastName: res.lastName,
    })
    dispatch({
      type: UPDATE_JH_MAIL,
      mail: res.mail,
    })
    dispatch({
      type: UPDATE_JH_TEL,
      tel: res.tel,
    })
    dispatch({
      type: UPDATE_JH_ICON_PATH,
      icon: res.icon,
    })
    dispatch({
      type: UPDATE_JH_UPDATE_AT,
      updatedAt: res.updatedAt,
    })
    dispatch({
      type: UPDATE_JH_FILE,
      file: res.file,
    })

    // 初回データをBackup
    dispatch({
      type: UPDATE_JH_BACKUP,
      info: res,
    })
    setRepresentativeFlg(res.representativeFlg === 1)
    // 先頭にスクロール
    window.scrollTo(0, 0)
  }

  // エラー処理、コールバック関数実行
  setData = (res, callback) => {
    CommonFunc.callbackFunc(res, callback, history)
  }

  // データ取得
  useEffect(() => {
    restFacade.get(ApiPaths.MASTER_JH.COMPANY_INFO, (res) =>
      setData(res, setCompanyInfoCB)
    )
  }, [])

  // Backコンポーネントに渡したいものをまとめる
  const backFactors = {
    path: Paths.MASTER.JH_MASTER_TOP,
    inputchg: chkInputChg(),
    clickevent: '',
  }

  // Saveコンポーネントに渡したいものをまとめる
  const saveFactors = {
    path: Paths.MASTER.JH_COMPANY_INFOM,
    saveevent: saveData,
    comp: saveComp,
    compevent: saveComplete,
    disabled: chkSaveDisable(),
    errcode: errCode,
  }

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <LayoutBox>
        <Title>{t('D301V0001')}</Title>
        <Grid container justify="center">
          <Grid item style={{ marginBottom: '3rem' }} md={10} xs={10}>
            <CompanyName />
          </Grid>
          <Grid item style={{ marginBottom: '3rem' }} md={10} xs={10}>
            <PostalCode />
          </Grid>
          <Grid item style={{ marginBottom: '3rem' }} md={10} xs={10}>
            <CompanyAddress />
          </Grid>
          <Grid item style={{ marginBottom: '3rem' }} md={10} xs={10}>
            <Representative representativeFlg={representativeFlg} />
          </Grid>
          <Grid item style={{ marginBottom: '3rem' }} md={10} xs={10}>
            <ContactInfo representativeFlg={representativeFlg} />
          </Grid>
          <Grid item style={{ marginBottom: '3rem' }} md={10} xs={10}>
            <Icon />
          </Grid>
        </Grid>
        <Grid
          container
          justify="center"
          direction="column"
          alignItems="center"
          className={classes.down}
        >
          <Grid item md={10} xs={10} className={classes.buttons}>
            <SaveAndBack backFactors={backFactors} saveFactors={saveFactors} />
          </Grid>
        </Grid>
      </LayoutBox>
    </AppContext.Provider>
  )
}

export default MasterJHCompanyInfoM
