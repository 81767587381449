import React, { useContext } from 'react'

import AppContext from '../../../../../contexts/AppContext'
import CompanyInfoText from '../../../../common/text/CompanyInfoText/CompanyInfoText'

/**
 * @return {JSX} 荷主アドレス欄
 */
const ShipperAddressBidInfo = () => {
  const { state } = useContext(AppContext)

  return <CompanyInfoText required textArr={state.ShipperAddress} />
}

export default ShipperAddressBidInfo
