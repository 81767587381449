import { LIGHT_REGISTER_NEW_ACCOUNT } from '../../../actions'

const initIcon = {
  value: {},
  iconData: '',
  errorObj: {
    checkResult: false,
    errMsg: 'VALIDATION000',
  },
  require: true,
}

/**
 * アイコン更新
 * @param {Object} action - dispatchで受け取ったaction
 * @param {Object} state - state
 * @return {Object} - state
 */
const updateIcon = (action, state) => {
  const { value, iconData, errorObj } = action
  return {
    ...state,
    value,
    iconData,
    errorObj,
  }
}

/**
 * アイコン削除
 * @param {Object} state - state
 * @return {Object} - state
 */
const deleteIcon = (state) => {
  return {
    ...state,
    value: {},
    iconData: '',
    errorObj: {
      checkResult: true,
      errMsg: 'VALIDATION011',
    },
  }
}

/**
 * GSA用に初期化、必須をfalse
 * @param {Object} state - state
 * @return {Object} - state
 */
const updateGsa = (state) => {
  return {
    ...state,
    errorObj: {
      checkResult: false,
      errMsg: 'VALIDATION000',
    },
    value: {},
    iconData: '',
    require: false,
  }
}

/**
 * 航空会社用に必須をtrue
 * @param {Object} state - state
 * @return {Object} - state
 */
const updateCarrier = (state) => {
  return {
    ...state,
    require: true,
  }
}

/**
 * Icon情報を保持
 * @param {Object} [state=initFirstName] - state
 * @param {Object} action - action
 * @return {Object} - state
 */
const Icon = (state = initIcon, action) => {
  switch (action.type) {
    case LIGHT_REGISTER_NEW_ACCOUNT.ICON.UPDATE_ICON:
      return updateIcon(action, state)
    case LIGHT_REGISTER_NEW_ACCOUNT.ICON.DELETE_ICON:
      return deleteIcon(state)
    case LIGHT_REGISTER_NEW_ACCOUNT.ICON.GSA:
      return updateGsa(state)
    case LIGHT_REGISTER_NEW_ACCOUNT.ICON.CARRIER:
      return updateCarrier(state)
    default:
      return state
  }
}

export default Icon

export { initIcon }
