import React, { useContext, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'

import BackButtonLightBookingDetails from './BackButtonLightBookingDetails'
import SaveButtonLightBookingDetails from './SaveButtonLightBookingDetails'
import AppContext from '../../../../../contexts/AppContext'

const useStyles = makeStyles(() => ({
  margin: {
    marginTop: '2rem',
  },
}))

/**
 * @param {Object} bookingInfo SearchResult画面から受け取った内容
 * @return {JSX} BackボタンとSaveボタン
 */
const ButtonsLightBookingDetails = ({ bookingInfo }) => {
  const { state } = useContext(AppContext)
  const classes = useStyles()
  const editable = !(state.Status.isCancel || state.Status.isClose)
  return useMemo(
    () => (
      <Grid item md={10}>
        <Grid
          container
          item
          justify="center"
          className={classes.margin}
          spacing={2}
        >
          <BackButtonLightBookingDetails bookingInfo={bookingInfo} />
          {editable ? <SaveButtonLightBookingDetails /> : null}
        </Grid>
      </Grid>
    ),
    [classes, editable, bookingInfo]
  )
}

ButtonsLightBookingDetails.propTypes = {
  bookingInfo: PropTypes.object,
}

export default ButtonsLightBookingDetails
