import { SHIPPER_CONFIRMATION } from '../../../actions'

/** 初期表示ページ */
const initIsNew = true

/**
 * ページ状態を保持
 * @param {Boolean} [state=initIsNew] 初期表示ページ
 * @param {Object} action isNew
 * @return {Boolean} ページ状態
 */
const IsNew = (state = initIsNew, action) => {
  const { type } = action
  switch (type) {
    case SHIPPER_CONFIRMATION.IS_NEW.SET:
      return action.isNew
    default:
      return state
  }
}

export default IsNew

export { initIsNew }
