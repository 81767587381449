import React, { useContext } from 'react'

import AppContext from '../../../../../contexts/AppContext'
import TableRow from '../../../../common/table/TableRow'
import TableCellsBidDetails from './TableCellsBidDetails'
import { getTableArr } from '../ConstantsBidDetails'

/**
 * useContextのstateから表全体のデータを作成
 * @return {JSX} TableRow ~ TableCellまでのコンポーネント
 */
const TableContentsBidDetails = () => {
  const { state } = useContext(AppContext)
  /** state.showDataのインデックス */
  const page = state.Paging > 0 ? state.Paging - 1 : 0
  /** 現在表示しているページのデータ配列 */
  const currentPageShowDataArr = state.Tables.showData[page]

  /**テーブル情報の配列 */
  const tableArr = getTableArr(state.IsNew)

  return currentPageShowDataArr.map((row) => {
    const { apiIndexNum } = row
    const editIndex = state.Tables.editData.findIndex(
      (tableObj) => tableObj.apiIndexNum === apiIndexNum
    )
    const tableContentObj = state.Tables.editData[editIndex]

    return (
      <TableRow key={editIndex}>
        <TableCellsBidDetails
          index={editIndex}
          tableContentObj={{ ...row, ...tableContentObj }}
          tableArr={tableArr}
        />
      </TableRow>
    )
  })
}

export default TableContentsBidDetails
