// Display + 画面名
const DisplayFwdrCargoTrace = {
  // 画面ID + 文言ID
  D110V0001: {
    en: 'CARGO TRACE',
    ja: '貨物追跡',
  },
  D110V0002: {
    en: 'Shipper, ORG/DST, etc…',
    ja: '荷主、出発/行先など…',
  },
  D110V0003: {
    en: 'KIX',
    ja: 'KIX',
  },
  D110V0004: {
    en: 'Lately',
    ja: '最近',
  },
  D110V0005: {
    en: 'Shipper',
    ja: '荷主',
  },
  D110V0006: {
    en: 'MAWB No.',
    ja: 'マスター番号',
  },
  D110V0007: {
    en: 'Pcs./Wgt./Vol.',
    ja: '個数/重量/容積',
  },
  D110V0008: {
    en: 'ORG/DST',
    ja: '出発/到着',
  },
  D110V0009: {
    en: 'Ready Date',
    ja: '出荷日',
  },
  D110V0010: {
    en: 'Status',
    ja: 'ステータス',
  },
  D110V0011: {
    en: 'Inquires',
    ja: 'お問い合わせ',
  },
  D110V0012: {
    en: 'Tracing',
    ja: '追跡',
  },
  D110V0013: {
    en: 'XXXXXX Corporation',
    ja: 'XXXXXX Corporation',
  },
  D110V0014: {
    en: ' - ',
    ja: ' - ',
  },
  D110V0015: {
    en: 'XXX-XXXX XXXXX',
    ja: 'XXX-XXXX XXXXX',
  },
  D110V0016: {
    en: '10pcs/300kg/2.05m3',
    ja: '10pcs/300kg/2.05m3',
  },
  D110V0017: {
    en: 'KIX/SYD',
    ja: 'KIX/SYD',
  },
  D110V0018: {
    en: 'KIX/YYZ',
    ja: 'KIX/YYZ',
  },
  D110V0019: {
    en: 'KIX/MNL',
    ja: 'KIX/MNL',
  },
  D110V0020: {
    en: 'HND/SFO',
    ja: 'HND/SFO',
  },
  D110V0021: {
    en: 'KIX/HKG',
    ja: 'KIX/HKG',
  },
  D110V0022: {
    en: 'NRT/CDG',
    ja: 'NRT/CDG',
  },
  D110V0023: {
    en: 'NRT/SVO',
    ja: 'NRT/SVO',
  },
  D110V0024: {
    en: 'KIX/BKK',
    ja: 'KIX/BKK',
  },
  D110V0025: {
    en: 'KIX/PVG',
    ja: 'KIX/PVG',
  },
  D110V0026: {
    en: 'FUK/TPE',
    ja: 'FUK/TPE',
  },
  D110V0027: {
    en: 'KIX/JFK',
    ja: 'KIX/JFK',
  },
  D110V0028: {
    en: 'HND/YUL',
    ja: 'HND/YUL',
  },
  D110V0029: {
    en: 'HND/MUC',
    ja: 'HND/MUC',
  },
  D110V0030: {
    en: 'KIX/HNL',
    ja: 'KIX/HNL',
  },
  D110V0031: {
    en: 'KIX/DMM',
    ja: 'KIX/DMM',
  },
  D110V0032: {
    en: 'KIX/DUB',
    ja: 'KIX/DUB',
  },
  D110V0033: {
    en: 'August 9, 2020',
    ja: '2020年8月9日',
  },
  D110V0034: {
    en: 'August 8, 2020',
    ja: '2020年8月8日',
  },
  D110V0035: {
    en: 'August 6, 2020',
    ja: '2020年8月6日',
  },
  D110V0036: {
    en: 'August 5, 2020',
    ja: '2020年8月5日',
  },
  D110V0037: {
    en: 'August 3, 2020',
    ja: '2020年8月3日',
  },
  D110V0038: {
    en: 'August 1, 2020',
    ja: '2020年8月1日',
  },
  D110V0039: {
    en: 'July 31, 2020',
    ja: '2020年7月31日',
  },
  D110V0040: {
    en: 'July 30, 2020',
    ja: '2020年7月30日',
  },
  D110V0041: {
    en: 'Not Ready',
    ja: '未出荷',
  },
  D110V0042: {
    en: 'Cargo Ready',
    ja: '出荷済',
  },
  D110V0043: {
    en: 'Bond-in',
    ja: '搬入',
  },
  D110V0044: {
    en: 'Loaded',
    ja: '搭載済',
  },
  D110V0045: {
    en: 'Off-loaded',
    ja: 'オフロード',
  },
  D110V0046: {
    en: 'Departed',
    ja: '出発済',
  },
  D110V0047: {
    en: 'In transit',
    ja: '輸送中',
  },
  D110V0048: {
    en: 'Arrived',
    ja: '到着済',
  },
  D110V0049: {
    en: 'Delivered',
    ja: '配送済',
  },
  D110V0050: {
    en: 'Carrier',
    ja: '航空会社',
  },
  D110V0051: {
    en: 'Check',
    ja: '確認',
  },
  D110V0052: {
    en: 'Ready Date',
    ja: '出荷日順',
  },
  D110V0053: {
    en: 'MAWB No. Ascending',
    ja: 'AwbNo昇順',
  },
  D110V0054: {
    en: 'MAWB No. Descending',
    ja: 'AwbNo降順',
  },
  D110V0055: {
    en: 'Pieces',
    ja: '個数順',
  },
  D110V0056: {
    en: 'Weight',
    ja: '重量順',
  },
  D110V0057: {
    en: 'Volume',
    ja: '容積順',
  },
  D110V0058: {
    en: 'Origin',
    ja: '出発空港順',
  },
  D110V0059: {
    en: 'Destination',
    ja: '到着空港順',
  },
  D110V0060: {
    en: 'Fwdr Order',
    ja: '混載業者順',
  },
  D110V0061: {
    en: 'Status',
    ja: 'ステータス順',
  },
  D110V0062: {
    en: 'Shipper',
    ja: '荷主名順',
  },
  D110V0063: {
    en: 'CarrierInquiries',
    ja: '航空会社問い合わせ順',
  },
  D110V0064: {
    en: 'ShipperInquiries',
    ja: '荷主問い合わせ順',
  },
}
// Display + 画面名
export default DisplayFwdrCargoTrace
