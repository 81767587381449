import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import BackDialog from '../dialog/BackDialog'
import SaveDialog from '../dialog/SaveDialog'

const useStyles = makeStyles((theme) => ({
  desktop: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobile: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}))

/**
 * @param {Object} props - 下記を要素とするオブジェクト(backFactors or saveFactors)
 * @param {string} path - 押下後の遷移先
 * @param {Event} clickevent - 押下後のイベント
 * @param {boolean} inputchg - 入力内容に変更があるかどうか(変更あればダイアログ表示)
 * @param {Event} saveevent - saveボタン押下時のイベント
 * @param {boolean} comp - save完了通知
 * @param {Event} compevent - save完了時のイベント
 * @param {boolean} disabled - saveボタンの有効/無効
 * @param {int} errCode - save時のエラーコード
 * @returns {JSX.Element} - saveボタンとbackボタンコンポーネント
 */
const SaveAndBack = (props) => {
  const classes = useStyles()

  return (
    <>
      {/* PC */}
      <Grid container justify="center" spacing={4} className={classes.desktop}>
        <Grid item>
          <BackDialog backFactors={props.backFactors} />
        </Grid>
        <Grid item>
          <SaveDialog saveFactors={props.saveFactors} />
        </Grid>
      </Grid>
      {/* スマホ */}
      <Grid
        container
        direction="column"
        alignItems="center"
        spacing={4}
        className={classes.mobile}
      >
        <Grid item>
          <SaveDialog saveFactors={props.saveFactors} />
        </Grid>
        <Grid item>
          <BackDialog backFactors={props.backFactors} />
        </Grid>
      </Grid>
    </>
  )
}

export default SaveAndBack
