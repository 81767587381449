import React, { useContext } from 'react'
import { Grid, makeStyles } from '@material-ui/core'

import AppContext from '../../../../../contexts/AppContext'
import RequestCardLightJhClientRegSignUp from './RequestCardLightJhClientRegSignUp'
import ClientTabsLightJhClientReg from '../../../../commonUseContext/tab/ClientTabsLightJhClientReg'
import CommonNoRequest from '../../../../common/table/CommonNoRequest'
import RejectDialogLightJhClientRegSignUp from './RejectDialogLightJhClientRegSignUp'
import AcceptDialogLightJhClientRegSignUp from './AcceptDialogLightJhClientRegSignUp'
import SavedChangesDialogLightJhClientRegSignUp from './SavedChangesDialogLightJhClientRegSignUp'

const useStyles = makeStyles((theme) => ({
  paperBack: theme.clientRegPaperBack,
}))

/**
 * @return {JSX} リクエストテーブルコンポーネント
 */
const RequestTableLightJhClientRegSignUp = () => {
  const classes = useStyles()
  const { state } = useContext(AppContext)
  const currentTab = state.Tabs
  const currentPageDataAmount = state.Tables.pageAmountArr[currentTab]
  const isNoRequest = currentPageDataAmount === 0

  return (
    <Grid container justify="center">
      {/* リクエスト数付きタブ */}
      <Grid container item xs={10}>
        <ClientTabsLightJhClientReg />
      </Grid>
      {/* リクエストテーブル NoRequestの場合非表示 */}
      {isNoRequest ? (
        <></>
      ) : (
        <Grid
          container
          item
          xs={10}
          justify="center"
          spacing={4}
          className={classes.paperBack}
        >
          <RequestCardLightJhClientRegSignUp />
        </Grid>
      )}
      {/* No Request */}
      <Grid container item xs={10} justify="center">
        <CommonNoRequest show={isNoRequest} />
      </Grid>
      {/* ダイアログ */}
      <RejectDialogLightJhClientRegSignUp />
      <AcceptDialogLightJhClientRegSignUp />
      <SavedChangesDialogLightJhClientRegSignUp />
    </Grid>
  )
}

export default RequestTableLightJhClientRegSignUp
