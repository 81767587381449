import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'

import AccordionLightBookingDetails from '../Accordion/AccordionLightBookingDetails'
import MeasureAndWeightEditPopup from './MeasureAndWeightEditPopup/MeasureAndWeightEditPopup'
import TableMeasureAndWeightLightBookingDetails from './TableMeasureAndWeightLightBookingDetails'

/**
 * @param {Object} props 下記内容
 * @param {Boolean} props.editable 編集可能
 * @return {JSX} Measure&Weight欄
 */
const MeasureAndWeightLightBookingDetails = ({ editable }) => {
  const { t } = useTranslation()

  return useMemo(() => {
    return (
      <AccordionLightBookingDetails label={t('D205V0019')}>
        <Grid container item>
          <TableMeasureAndWeightLightBookingDetails editable={editable} />
        </Grid>
        {editable ? <MeasureAndWeightEditPopup /> : null}
      </AccordionLightBookingDetails>
    )
  }, [t, editable])
}
MeasureAndWeightLightBookingDetails.propTypes = {
  editable: PropTypes.bool.isRequired,
}
export default MeasureAndWeightLightBookingDetails
