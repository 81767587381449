import Common from '../../../../constants/Common'
import ApiPaths from '../../../../constants/ApiPaths'

/** 表示する画面のフラグ */
const ShowFlg = {
  BOTH: 'both',
  NEW: 'new',
  SELECT: 'select',
}

/** チェックボックスと表の紐づけに使用する名前 */
const CheckboxName = {
  ORG: 'org',
  DST: 'dst',
  TRANSIT: 'transit',
  FLIGHT: 'flight',
  TT: 'tt',
  AIRLINE: 'airline',
  COMMODITY: 'commodity',
  TERMS: 'terms',
  LT: 'lt',
  TTL_WGT: 'ttlWgt',
  TTL_VOL: 'ttlVol',
  FSC: 'fsc',
  RATE_RANGE: 'rateRange',
  CURRENCY: 'currency',
  REMARK: 'remark',
}

/**
 * 画面の状態に合わせたテーブル情報を返す
 * @param {Boolean} isNew state.IsNewの値
 * @returns {Array} テーブル情報の配列
 */
const getTableArr = (isNew) =>
  ConstantsBidDetails.TABLE_ARR.filter(({ showFlg }) => {
    switch (showFlg) {
      case ShowFlg.BOTH:
        return true
      case ShowFlg.NEW:
        return isNew
      case ShowFlg.SELECT:
        return !isNew
      default:
        return true
    }
  })

/**
 * チェックボックスの状態から表示する列か判定する
 * @param {string} checkboxName ConstantsBidDetails.TABLE_ARR.checkboxNameの値
 * @param {Object} checkbox state.Checkboxの値
 * @returns {boolean} trueの時表示する
 */
const getIsShowColumn = (checkboxName, checkbox) =>
  !checkboxName || checkbox[checkboxName]

/**
 * テーブル情報を作成する
 * @param {string} headerKey ヘッダーに表示する値
 * @param {string} cellKey セルに表示するデータ名
 * @param {string} showFlg ShowFlgの値
 * @param {boolean} required 必須項目か
 * @param {boolean} rightBorder 右側の罫線を表示するか
 * @param {string} [checkboxName=null] 紐づくチェックボックスの名前
 * @param {string} [cellCategory=null] Common.CELL_CATEGORYの値
 * @param {string} [relationCellKey=null] 関連するデータ名
 * @returns {object} 1列分のテーブル情報
 */
const createTableObj = (
  headerKey,
  cellKey,
  showFlg,
  required = false,
  rightBorder = true,
  checkboxName = null,
  cellCategory = null,
  relationCellKey
) => {
  return {
    headerKey,
    cellKey,
    showFlg,
    required,
    rightBorder,
    checkboxName,
    cellCategory,
    relationCellKey,
  }
}

/** 画面で使用する定数 */
const ConstantsBidDetails = {
  //1ページに表示する件数
  PAGE_AMOUNT: 15,
  //追加できる最大数
  MAX_AMOUNT: 100,

  // ソートの初期値
  DEFAULT_SORT_TERM: 3,

  //チェックボックスの作成に必要な値
  CHECKBOX_ARR: [
    { name: CheckboxName.ORG, label: 'D111V0076' },
    { name: CheckboxName.DST, label: 'D111V0077' },
    { name: CheckboxName.TRANSIT, label: '3rd0018' },
    { name: CheckboxName.FLIGHT, label: 'D015V0008' },
    { name: CheckboxName.TT, label: 'D301V0073' },
    { name: CheckboxName.AIRLINE, label: 'D501V0005' },
    { name: CheckboxName.COMMODITY, label: '2nd0005' },
    { name: CheckboxName.TERMS, label: '2nd0016' },
    { name: CheckboxName.LT, label: '3rd0019' },
    { name: CheckboxName.TTL_WGT, label: '3rd0020' },
    { name: CheckboxName.TTL_VOL, label: '3rd0021' },
    { name: CheckboxName.FSC, label: '2nd0017' },
    { name: CheckboxName.RATE_RANGE, label: '3rd0022' },
    { name: CheckboxName.CURRENCY, label: 'D103V0103' },
    { name: CheckboxName.REMARK, label: '3rd0023' },
  ],

  // テーブル作成に必要な値
  TABLE_ARR: [
    createTableObj(
      'D001V0016',
      'statusStr',
      ShowFlg.SELECT,
      false,
      true,
      null,
      Common.CELL_CATEGORY.CHECKBOX.SELECT
    ),
    createTableObj(
      'D014V0002',
      'apiIndexNum',
      ShowFlg.BOTH,
      false,
      true,
      null,
      Common.CELL_CATEGORY.NO
    ),
    createTableObj('D005V0009', 'statusStr', ShowFlg.SELECT),
    createTableObj(
      'D111V0076',
      'orgStr',
      ShowFlg.SELECT,
      false,
      true,
      CheckboxName.ORG
    ),
    createTableObj(
      'D111V0076',
      'orgId',
      ShowFlg.NEW,
      true,
      true,
      CheckboxName.ORG,
      Common.CELL_CATEGORY.SELECT.AIRPORT,
      'dstId'
    ),
    createTableObj(
      'D111V0077',
      'dstStr',
      ShowFlg.SELECT,
      false,
      true,
      CheckboxName.DST
    ),
    createTableObj(
      'D111V0077',
      'dstId',
      ShowFlg.NEW,
      true,
      true,
      CheckboxName.DST,
      Common.CELL_CATEGORY.SELECT.AIRPORT,
      'orgId'
    ),
    createTableObj(
      '3rd0018',
      'transitStr',
      ShowFlg.BOTH,
      false,
      true,
      CheckboxName.TRANSIT
    ),
    createTableObj(
      'D015V0008',
      'flightTypeStr',
      ShowFlg.SELECT,
      false,
      true,
      CheckboxName.FLIGHT
    ),
    createTableObj(
      'D015V0008',
      'flightTypeId',
      ShowFlg.NEW,
      true,
      true,
      CheckboxName.FLIGHT,
      Common.CELL_CATEGORY.SELECT.FLIGHT
    ),
    createTableObj(
      'D301V0073',
      'ttStr',
      ShowFlg.BOTH,
      false,
      true,
      CheckboxName.TT
    ),
    createTableObj(
      'D501V0005',
      'airlineStr',
      ShowFlg.BOTH,
      false,
      true,
      CheckboxName.AIRLINE
    ),
    createTableObj('D001V0014', 'forwarderStr', ShowFlg.SELECT),
    createTableObj(
      '2nd0005',
      'commStr',
      ShowFlg.SELECT,
      false,
      true,
      CheckboxName.COMMODITY
    ),
    createTableObj(
      '2nd0005',
      'commId',
      ShowFlg.NEW,
      true,
      true,
      CheckboxName.COMMODITY,
      Common.CELL_CATEGORY.SELECT.COMMODITY
    ),
    createTableObj(
      '2nd0016',
      'termsStr',
      ShowFlg.SELECT,
      false,
      true,
      CheckboxName.TERMS
    ),
    createTableObj(
      '2nd0016',
      'termsId',
      ShowFlg.NEW,
      true,
      true,
      CheckboxName.TERMS,
      Common.CELL_CATEGORY.SELECT.TERMS
    ),
    createTableObj(
      '3rd0019',
      'leadTimeStr',
      ShowFlg.SELECT,
      false,
      true,
      CheckboxName.LT
    ),
    createTableObj(
      '3rd0019',
      'leadTimeId',
      ShowFlg.NEW,
      true,
      true,
      CheckboxName.LT,
      Common.CELL_CATEGORY.SELECT.LT
    ),
    createTableObj(
      '3rd0020',
      'ttlWgt',
      ShowFlg.SELECT,
      false,
      true,
      CheckboxName.TTL_WGT
    ),
    createTableObj(
      '3rd0020',
      'ttlMinWgt,ttlMaxWgt',
      ShowFlg.NEW,
      true,
      true,
      CheckboxName.TTL_WGT,
      Common.CELL_CATEGORY.TEXT_FIELD.TTL_WGT
    ),
    createTableObj(
      '3rd0021',
      'ttlVol',
      ShowFlg.SELECT,
      false,
      true,
      CheckboxName.TTL_VOL
    ),
    createTableObj(
      '3rd0021',
      'ttlMinVol,ttlMaxVol',
      ShowFlg.NEW,
      true,
      true,
      CheckboxName.TTL_VOL,
      Common.CELL_CATEGORY.TEXT_FIELD.TTL_VOL
    ),
    createTableObj(
      '2nd0017',
      'fscStr',
      ShowFlg.BOTH,
      false,
      true,
      CheckboxName.FSC
    ),
    createTableObj(
      'D301V0089',
      'minrateStr',
      ShowFlg.BOTH,
      false,
      false,
      CheckboxName.RATE_RANGE
    ),
    createTableObj(
      'D301V0090',
      'normalStr',
      ShowFlg.BOTH,
      false,
      false,
      CheckboxName.RATE_RANGE
    ),
    createTableObj(
      'D301V0091',
      'minStr',
      ShowFlg.BOTH,
      false,
      false,
      CheckboxName.RATE_RANGE
    ),
    createTableObj(
      'D301V0092',
      'lightStr',
      ShowFlg.BOTH,
      false,
      false,
      CheckboxName.RATE_RANGE
    ),
    createTableObj(
      'D301V0093',
      'mediumStr',
      ShowFlg.BOTH,
      false,
      false,
      CheckboxName.RATE_RANGE
    ),
    createTableObj(
      'D301V0094',
      'largeStr',
      ShowFlg.BOTH,
      false,
      false,
      CheckboxName.RATE_RANGE
    ),
    createTableObj(
      'D301V0095',
      'maxStr',
      ShowFlg.BOTH,
      false,
      true,
      CheckboxName.RATE_RANGE
    ),
    createTableObj(
      'D103V0103',
      'currencyStr',
      ShowFlg.SELECT,
      false,
      true,
      CheckboxName.CURRENCY
    ),
    createTableObj(
      'D103V0103',
      'currencyId',
      ShowFlg.NEW,
      true,
      true,
      CheckboxName.CURRENCY,
      Common.CELL_CATEGORY.SELECT.CURRENCY
    ),
    createTableObj(
      '3rd0023',
      'remark',
      ShowFlg.SELECT,
      false,
      true,
      CheckboxName.REMARK
    ),
    createTableObj(
      '3rd0023',
      'remark',
      ShowFlg.NEW,
      false,
      true,
      CheckboxName.REMARK,
      Common.CELL_CATEGORY.TEXT_FIELD.REMARK
    ),
    createTableObj(
      'D602V0007',
      'deleteStr',
      ShowFlg.NEW,
      false,
      true,
      null,
      Common.CELL_CATEGORY.BUTTON.DELETE
    ),
  ],

  //グレーアウトするステータス
  GRAY_OUT_STATUS_ID_ARR: [Common.BID_STATUS.LOST.ID, Common.BID_STATUS.NA.ID],

  // valueはすべてCommon.jsのものを使うようにして並び替えの動作をCommonFunc.jsに追記する
  SORT_ITEM_ARR: [
    {
      sortTerm: Common.SORT_TERMS.CREATED_AT,
      wordKey: 'D005V0003',
      sortKey: 'createdAt',
    },
    {
      sortTerm: Common.SORT_TERMS.CREATED_AT_DESENDING,
      wordKey: 'D102V0054',
      sortKey: 'createdAt',
    },
    {
      sortTerm: Common.SORT_TERMS.SELECT_ORDER,
      wordKey: 'D001V0016',
      sortKey: 'sortSelectId',
    },
    {
      sortTerm: Common.SORT_TERMS.NO_ORDER,
      wordKey: 'D014V0002',
      sortKey: 'apiIndexNum',
    },
    {
      sortTerm: Common.SORT_TERMS.STATUS_ORDER,
      wordKey: 'D005V0009',
      sortKey: 'statusStr',
    },
    {
      sortTerm: Common.SORT_TERMS.ORG_ORDER,
      wordKey: 'D111V0076',
      sortKey: 'orgStr',
    },
    {
      sortTerm: Common.SORT_TERMS.DST_ORDER,
      wordKey: 'D111V0077',
      sortKey: 'dstStr',
    },
    {
      sortTerm: Common.SORT_TERMS.TRANSIT_ORDER,
      wordKey: '3rd0018',
      sortKey: 'transitStr',
    },
    {
      sortTerm: Common.SORT_TERMS.FLIGHT_TYPE_ORDER,
      wordKey: 'D015V0008',
      sortKey: 'flightTypeStr',
    },
    {
      sortTerm: Common.SORT_TERMS.TRANSIT_TIME_ORDER,
      wordKey: 'D301V0073',
      sortKey: 'ttStr',
    },
    {
      sortTerm: Common.SORT_TERMS.FLIGHT_NAME_ORDER,
      wordKey: 'D501V0005',
      sortKey: 'airlineStr',
    },
    {
      sortTerm: Common.SORT_TERMS.FWDR_ORDER,
      wordKey: 'D001V0014',
      sortKey: 'forwarderStr',
    },
    {
      sortTerm: Common.SORT_TERMS.COMMODITY_ORDER,
      wordKey: '2nd0005',
      sortKey: 'commStr',
    },
    {
      sortTerm: Common.SORT_TERMS.TERMS_ORDER,
      wordKey: '2nd0016',
      sortKey: 'termsStr',
    },
    {
      sortTerm: Common.SORT_TERMS.LT_ORDER,
      wordKey: '3rd0019',
      sortKey: 'leadTimeStr',
    },
    {
      sortTerm: Common.SORT_TERMS.WGT_ORDER,
      wordKey: '3rd0020',
      sortKey: 'ttlMinWgt',
    },
    {
      sortTerm: Common.SORT_TERMS.VOL_ORDER,
      wordKey: '3rd0021',
      sortKey: 'ttlMinVol',
    },
    {
      sortTerm: Common.SORT_TERMS.FSC_ORDER,
      wordKey: '2nd0017',
      sortKey: 'fsc',
    },
    {
      sortTerm: Common.SORT_TERMS.MINRATE_ORDER,
      wordKey: 'D301V0089',
      sortKey: 'minrate',
    },
    {
      sortTerm: Common.SORT_TERMS.NORMAL_ORDER,
      wordKey: 'D301V0090',
      sortKey: 'normal',
    },
    {
      sortTerm: Common.SORT_TERMS.MIN_ORDER,
      wordKey: 'D301V0091',
      sortKey: 'min',
    },
    {
      sortTerm: Common.SORT_TERMS.LIGHT_ORDER,
      wordKey: 'D301V0092',
      sortKey: 'light',
    },
    {
      sortTerm: Common.SORT_TERMS.MEDIUM_ORDER,
      wordKey: 'D301V0093',
      sortKey: 'medium',
    },
    {
      sortTerm: Common.SORT_TERMS.LARGE_ORDER,
      wordKey: 'D301V0094',
      sortKey: 'large',
    },
    {
      sortTerm: Common.SORT_TERMS.MAX_ORDER,
      wordKey: 'D301V0095',
      sortKey: 'max',
    },
    {
      sortTerm: Common.SORT_TERMS.CURRENCY_ORDER,
      wordKey: 'D103V0103',
      sortKey: 'currencyStr',
    },
    {
      sortTerm: Common.SORT_TERMS.REMARK_ORDER,
      wordKey: '3rd0023',
      sortKey: 'remark',
    },
  ],

  // APIの情報
  //name: データの保存名, id: idのパラメータ名, path: APIパス, getOptionLabel: セレクトで表示するパラメータ名
  API_DATA: {
    AIRPORT: {
      name: 'airport',
      id: 'airportId',
      path: ApiPaths.COMMON_API.AIRPORT,
      getOptionLabel: (option) => option.airportCode,
    },
    CURRENCY: {
      name: 'currency',
      id: 'currencyId',
      path: ApiPaths.MASTER_JH.CURRENCY,
      getOptionLabel: (option) => option.currency,
    },
    COMMODITY: {
      name: 'commodity',
      id: 'commId',
      path: ApiPaths.MASTER_JH.COMMODITY,
      getOptionLabel: (option) => option.comm.split(' ')[0],
    },
    TERMS: {
      name: 'terms',
      id: 'termsId',
      path: ApiPaths.MASTER_JH.TERMS,
      getOptionLabel: (option) => option.terms.split('-')[0],
    },
    LT: {
      name: 'lt',
      id: 'timeId',
      path: ApiPaths.MASTER_JH.TT,
      getOptionLabel: (option) => option.time,
      isLongWidth: true,
    },
    FLIGHT_TYPE: {
      name: 'flightType',
      id: 'flightTypeId',
      path: ApiPaths.MASTER_JH.FLIGHT_TYPE,
      getOptionLabel: (option) => option.flightType,
    },
  },

  //初期表示データ
  CREATE_INIT_DATA_OBJ: () => {
    return {
      apiIndexNum: 0,
      statusStr: Common.BID_STATUS.REQUEST.TEXT,
      statusId: Common.BID_STATUS.REQUEST.ID,
      orgId: null,
      orgStr: '',
      dstId: null,
      dstStr: '',
      transitId: null,
      transitStr: '',
      flightTypeId: null,
      flightTypeStr: '',
      ttId: null,
      ttStr: '',
      airlineId: null,
      airlineStr: '',
      forwarderStr: '',
      commId: null,
      commStr: '',
      termsId: null,
      termsStr: '',
      leadTimeId: null,
      leadTimeStr: '',
      ttlMinWgt: '',
      ttlMaxWgt: '',
      ttlMinVol: '',
      ttlMaxVol: '',
      fsc: '',
      minrate: '',
      normal: '',
      min: '',
      light: '',
      medium: '',
      large: '',
      max: '',
      currencyId: null,
      currencyStr: '',
      remark: '',
    }
  },
}

export default ConstantsBidDetails
export { getTableArr, getIsShowColumn }
