import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'

import Tabs from '../../../../common/tab/CommonTabs'
import { LIGHT_JH_CLIENT_LIST, PAGING, TABS } from '../../../../../actions'
import AppContext from '../../../../../contexts/AppContext'
import { TabName } from '../ConstantsLightJhClientList'

const useStyles = makeStyles(() => ({
  root: {
    marginLeft: '4px',
  },
}))

const TabLightJhClientList = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { state, dispatch } = useContext(AppContext)
  return useMemo(() => {
    const TabSize = 6

    /**
     * タブ変更時の処理
     * @param {TabName} select 選択したタブの値
     * @returns {void} 無し
     */
    const onChange = (select) => {
      dispatch({
        type: PAGING.SET,
        page: 1,
      })
      dispatch({
        type: TABS.SET,
        select,
      })
      dispatch({
        type: LIGHT_JH_CLIENT_LIST.TABLES.CHANGE_SHOW_DATA,
        tab: select,
      })
    }

    const tabSettingArr = [
      {
        label: t('D302V0032'),
        girdSetting: {
          md: TabSize,
          onClick: () => onChange(TabName.forwarder),
        },
      },
      {
        label: t('D302V0003'),
        girdSetting: { md: TabSize, onClick: () => onChange(TabName.carrier) },
      },
    ]

    return (
      <Grid
        item
        md={4}
        container
        alignItems="flex-end"
        className={classes.root}
      >
        {<Tabs selectTabIndex={state.Tabs} tabSettingArr={tabSettingArr} />}
      </Grid>
    )
  }, [state.Tabs, dispatch, classes, t])
}

export default TabLightJhClientList
