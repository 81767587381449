import { DIALOG } from '../../../actions'

/** 初期表示ページ */
const initAcceptAndRejectDialogJhClientRegDeleteAccount = {
  // 各ダイアログ開閉状態
  isOpenAccept: false,
  isOpenReject: false,
  isOpenSuccess: false,
  // APIのレスポンス
  response: {},
  // APIに渡すリクエスト
  req: {},
}

/**
 * @param {Object} action dispatchで受け取ったaction
 * @param {Object} state ダイアログの開閉状態とAPI用のリクエストとレスポンス
 * @return {Object} Acceptダイアログが開いた状態のstate
 */
const openAcceptDialog = (action, state) => {
  const { companyId, companyUpdatedAt } = action.data
  const req = { companyId, companyUpdatedAt }
  const openKey = 'isOpenAccept'
  const response = initAcceptAndRejectDialogJhClientRegDeleteAccount.response
  return openDialog(state, openKey, req, response)
}

/**
 * @param {Object} action dispatchで受け取ったaction
 * @param {Object} state ダイアログの開閉状態とAPI用のリクエストとレスポンス
 * @return {Object} Rejectダイアログが開いた状態のstate
 */
const openRejectDialog = (action, state) => {
  const { withdrawalId, withdrawalUpdatedAt } = action.data
  const req = { withdrawalId, withdrawalUpdatedAt }
  const openKey = 'isOpenReject'
  const response = initAcceptAndRejectDialogJhClientRegDeleteAccount.response
  return openDialog(state, openKey, req, response)
}

/**
 * @param {Object} action dispatchで受け取ったaction
 * @param {Object} state ダイアログの開閉状態とAPI用のリクエストとレスポンス
 * @return {Object} Successダイアログが開いた状態のstate
 */
const openSuccessDialog = (action, state) => {
  const req = initAcceptAndRejectDialogJhClientRegDeleteAccount.req
  const openKey = 'isOpenSuccess'
  const { response } = action
  return openDialog(state, openKey, req, response)
}

/**
 * @param {Object} state ダイアログの開閉状態とAPI用のリクエストとレスポンス
 * @param {String} openKey 開くダイアログのkey
 * @param {Object} req API用のリクエスト
 * @param {Object} response APIから受け取ったレスポンス
 * @return {Object} 各ダイアログが開いた状態のstate
 */
const openDialog = (state, openKey, req, response) => {
  return {
    ...state,
    [openKey]: true,
    response,
    req,
  }
}

/**
 * @return {Object} 各ダイアログが閉じた状態のstate
 */
const closeDialog = () => initAcceptAndRejectDialogJhClientRegDeleteAccount

/**
 * ダイアログの開閉状態とAPI用のリクエストとレスポンスを保持
 * @param {Object} [state=initAcceptAndRejectDialogJhClientRegDeleteAccount] ダイアログの開閉状態とAPI用のリクエストとレスポンス
 * @param {Object} action dispatchで受け取ったaction
 * @return {Object} ダイアログの開閉状態とAPI用のリクエストとレスポンス
 */
const AcceptAndRejectDialogJhClientRegDeleteAccount = (
  state = initAcceptAndRejectDialogJhClientRegDeleteAccount,
  action
) => {
  // ページの指定が無い場合は初期ページ
  const { type } = action
  switch (type) {
    case DIALOG.OPEN_ACCEPT:
      return openAcceptDialog(action, state)
    case DIALOG.OPEN_REJECT:
      return openRejectDialog(action, state)
    case DIALOG.OPEN_SUCCESS:
      return openSuccessDialog(action, state)
    case DIALOG.CLOSE:
      return closeDialog()
    default:
      return state
  }
}

export default AcceptAndRejectDialogJhClientRegDeleteAccount

export { initAcceptAndRejectDialogJhClientRegDeleteAccount }
