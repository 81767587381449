/* eslint-disable no-extra-parens */
import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow'

import PasswordField from '../../../common/text/PasswordField'
import AppContext from '../../../../contexts/AppContext'
import CommonValidation from '../../../common/function/CommonValidation'
import {
  UPDATE_SHIP_CURRENTPASS,
  UPDATE_SHIP_NEWPASS,
  UPDATE_SHIP_NEWPASS_VERIFY,
  UPDATE_SHIP_CURRENTPASS_INPUTERR,
  UPDATE_SHIP_NEWPASS_INPUTERR,
  UPDATE_SHIP_NEWPASS_VERIFY_INPUTERR,
  UPDATE_SHIP_PASS_VERIFY_ERR,
  UPDATE_SHIP_CURRENTPASS_ERR,
} from '../../../../actions'

const useStyles = makeStyles((theme) => ({
  head: theme.textBoldBLU,
  // eslint-disable-next-line camelcase
  text_blu: theme.textNormalBLU,
  icon: {
    transform: 'rotate(90deg)',
    fontSize: 50,
    color: theme.palette.primary.main,
  },
}))

const Password = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [inputErrs, setInputErrs] = useState({
    currentPassword: false,
    newPassword: false,
    newPassVerify: false,
  })
  const [helperTxts, setHelperTxts] = useState({
    currentPassword: '',
    newPassword: '',
    newPassVerify: '',
  })
  const { state, dispatch } = useContext(AppContext)

  const handleChangeCurrentPass = (event) => {
    const value = event.target.value
    // 入力チェック
    const validationCheck = CommonValidation.myProfilePassword(value)
    const err = validationCheck.checkResult
    setInputErrs({ ...inputErrs, currentPassword: err })
    setHelperTxts({ ...helperTxts, currentPassword: validationCheck.errMsg })

    dispatch({
      type: UPDATE_SHIP_CURRENTPASS,
      currentPassword: value,
    })
    dispatch({
      type: UPDATE_SHIP_CURRENTPASS_ERR,
      errflg: false,
    })

    // 入力エラーの場合、Savaボタン無効
    dispatch({
      type: UPDATE_SHIP_CURRENTPASS_INPUTERR,
      inputErr: err,
    })
    // 入力エラー、またはパスワード一致したら不一致エラー解除
    if (err === true || value === state.myprofile.password.value) {
      dispatch({
        type: UPDATE_SHIP_CURRENTPASS_ERR,
        errflg: false,
      })
    }

    // currentPasswordには値が入っていてエラーチェックがfalseでも
    // NewPassword・RetypeNewPasswordに値がない場合、値が入っていないものにエラーをtrueで入れる
    if (value && !err) {
      if (
        !state.myprofile.newPassword.value &&
        !state.myprofile.newPassVerify.value
      ) {
        dispatch({
          type: UPDATE_SHIP_NEWPASS_INPUTERR,
          inputErr: true,
        })
        dispatch({
          type: UPDATE_SHIP_NEWPASS_VERIFY_INPUTERR,
          inputErr: true,
        })
      } else if (!state.myprofile.newPassword.value) {
        dispatch({
          type: UPDATE_SHIP_NEWPASS_INPUTERR,
          inputErr: true,
        })
      } else if (!state.myprofile.newPassVerify.value) {
        dispatch({
          type: UPDATE_SHIP_NEWPASS_VERIFY_INPUTERR,
          inputErr: true,
        })
      }
    }

    // 一度入力後、入力内容を削除した場合にNewPasswordかRetypeNewPasswordに値が入っていた場合エラーをtrueにする
    if (!value) {
      // どちらかに値がある場合、currentPasswordにエラーtrueを設定
      if (
        state.myprofile.newPassword.value ||
        state.myprofile.newPassVerify.value
      ) {
        dispatch({
          type: UPDATE_SHIP_NEWPASS_INPUTERR,
          inputErr: true,
        })
        // どちらも空の場合、入力時にどちらもエラーtrueで設定しているのでfalseに設定
      } else if (
        !state.myprofile.newPassword.value &&
        !state.myprofile.newPassVerify.value
      ) {
        dispatch({
          type: UPDATE_SHIP_NEWPASS_INPUTERR,
          inputErr: false,
        })
        dispatch({
          type: UPDATE_SHIP_NEWPASS_VERIFY_INPUTERR,
          inputErr: false,
        })
      } else if (!state.myprofile.newPassword.value) {
        dispatch({
          type: UPDATE_SHIP_NEWPASS_INPUTERR,
          inputErr: false,
        })
      } else if (!state.myprofile.newPassVerify.value) {
        dispatch({
          type: UPDATE_SHIP_NEWPASS_VERIFY_INPUTERR,
          inputErr: false,
        })
      }
    }
  }

  const handleChangeNewPass = (event) => {
    const value = event.target.value
    // 入力チェック
    const validationCheck = CommonValidation.myProfilePassword(value)
    const err = validationCheck.checkResult
    setInputErrs({ ...inputErrs, newPassword: err })
    setHelperTxts({ ...helperTxts, newPassword: validationCheck.errMsg })

    dispatch({
      type: UPDATE_SHIP_NEWPASS,
      newPassword: value,
    })

    // 入力エラーの場合、Savaボタン無効
    dispatch({
      type: UPDATE_SHIP_NEWPASS_INPUTERR,
      inputErr: err,
    })

    // NewPasswordには値が入っていてエラーチェックがfalseでも
    // CurrentPassword・RetypeNewPasswordに値がない場合、値が入っていないものにエラーをtrueで入れる
    if (value && !err) {
      if (
        !state.myprofile.currentPassword.value &&
        !state.myprofile.newPassVerify.value
      ) {
        dispatch({
          type: UPDATE_SHIP_CURRENTPASS_ERR,
          inputErr: true,
        })
        dispatch({
          type: UPDATE_SHIP_NEWPASS_VERIFY_INPUTERR,
          inputErr: true,
        })
      } else if (!state.myprofile.currentPassword.value) {
        dispatch({
          type: UPDATE_SHIP_CURRENTPASS_ERR,
          inputErr: true,
        })
      } else if (!state.myprofile.newPassVerify.value) {
        dispatch({
          type: UPDATE_SHIP_NEWPASS_VERIFY_INPUTERR,
          inputErr: true,
        })
      }
    }

    // 一度入力後、入力内容を削除した場合にCurrentPasswordかRetypeNewPasswordに値が入っていた場合エラーをtrueにする
    if (!value) {
      // どちらかに値がある場合、newPasswordにエラーtrueを設定
      if (
        state.myprofile.currentPassword.value ||
        state.myprofile.newPassVerify.value
      ) {
        dispatch({
          type: UPDATE_SHIP_NEWPASS_INPUTERR,
          inputErr: true,
        })
        // どちらも空の場合、入力時に値があるかのチェックでどちらもエラーtrueで設定しているのでfalseに設定
      } else if (
        !state.myprofile.currentPassword.value &&
        !state.myprofile.newPassVerify.value
      ) {
        dispatch({
          type: UPDATE_SHIP_CURRENTPASS_ERR,
          inputErr: false,
        })
        dispatch({
          type: UPDATE_SHIP_NEWPASS_VERIFY_INPUTERR,
          inputErr: false,
        })
      } else if (!state.myprofile.currentPassword.value) {
        dispatch({
          type: UPDATE_SHIP_NEWPASS_INPUTERR,
          inputErr: false,
        })
      } else if (!state.myprofile.newPassVerify.value) {
        dispatch({
          type: UPDATE_SHIP_NEWPASS_VERIFY_INPUTERR,
          inputErr: false,
        })
      }
    }
  }

  const handleChangeNewPassVerify = (event) => {
    const value = event.target.value
    // 入力チェック
    const validationCheck = CommonValidation.myProfilePassword(value)
    const err = validationCheck.checkResult
    setInputErrs({ ...inputErrs, newPassVerify: err })
    setHelperTxts({ ...helperTxts, newPassVerify: validationCheck.errMsg })

    dispatch({
      type: UPDATE_SHIP_NEWPASS_VERIFY,
      newPassVerify: value,
    })

    // 入力エラーの場合、Savaボタン無効
    dispatch({
      type: UPDATE_SHIP_NEWPASS_VERIFY_INPUTERR,
      inputErr: err,
    })
    // 入力エラー、またはパスワード一致したら不一致エラー解除
    if (err === true || value === state.myprofile.newPassword.value) {
      dispatch({
        type: UPDATE_SHIP_PASS_VERIFY_ERR,
        errflg: false,
      })
    }

    // RetypeNewPasswordには値が入っていてエラーチェックがfalseでも
    // CurrentPassword・NewPasswordに値がない場合、値が入っていないものにエラーをtrueで入れる
    if (value && !err) {
      if (
        !state.myprofile.currentPassword.value &&
        !state.myprofile.newPassword.value
      ) {
        dispatch({
          type: UPDATE_SHIP_CURRENTPASS_ERR,
          inputErr: true,
        })
        dispatch({
          type: UPDATE_SHIP_NEWPASS_INPUTERR,
          inputErr: true,
        })
      } else if (!state.myprofile.currentPassword.value) {
        dispatch({
          type: UPDATE_SHIP_CURRENTPASS_ERR,
          inputErr: true,
        })
      } else if (!state.myprofile.newPassword.value) {
        dispatch({
          type: UPDATE_SHIP_NEWPASS_INPUTERR,
          inputErr: true,
        })
      }
    }

    // 一度入力後、入力内容を削除した場合にCurrentPasswordかNewPasswordに値が入っていた場合エラーをtrueにする
    if (!value) {
      // どちらかに値がある場合、RetypeNewPasswordにエラーtrueを設定
      if (
        state.myprofile.currentPassword.value ||
        state.myprofile.newPassword.value
      ) {
        dispatch({
          type: UPDATE_SHIP_NEWPASS_VERIFY_INPUTERR,
          inputErr: true,
        })
        // どちらも空の場合、入力時に値があるかのチェックでどちらもエラーをtrueで設定しているのでfalseに設定
      } else if (
        !state.myprofile.currentPassword.value &&
        !state.myprofile.newPassword.value
      ) {
        dispatch({
          type: UPDATE_SHIP_CURRENTPASS_ERR,
          inputErr: false,
        })
        dispatch({
          type: UPDATE_SHIP_NEWPASS_INPUTERR,
          inputErr: false,
        })
      } else if (!state.myprofile.currentPassword.value) {
        dispatch({
          type: UPDATE_SHIP_CURRENTPASS_ERR,
          inputErr: false,
        })
      } else if (!state.myprofile.newPassword.value) {
        dispatch({
          type: UPDATE_SHIP_NEWPASS_INPUTERR,
          inputErr: false,
        })
      }
    }
  }

  return (
    <form>
      {' '}
      {/* 警告解消のためformタグを追加 */}
      <div className={classes.formControl}>
        <Grid container>
          <Grid item md={10} xs={10}>
            <div className={classes.head}>{t('D301V0026')}</div>
          </Grid>
        </Grid>
        <Grid container>
          {/* 警告解消のため非表示のe-mail欄を追加 */}
          <input
            type="text"
            name="email"
            autoComplete="username email"
            hidden
          />
          <Grid item style={{ marginBottom: '3rem' }} md={4} xs={10}>
            <div className={classes.text_blu}>{t('D301V0027')}</div>
            <PasswordField
              id="currentPassword"
              err={
                state.myprofile.passwordErr.current
                  ? 'true'
                  : inputErrs.currentPassword
              }
              helperTxt={
                state.myprofile.passwordErr.current
                  ? t('D301V0160')
                  : t(helperTxts.currentPassword)
              }
              value={state.myprofile.currentPassword.value}
              handleChange={handleChangeCurrentPass}
              autoComplete="new-password"
            />
          </Grid>
        </Grid>
        <Grid
          container
          item
          md={4}
          xs={10}
          alignItems="center"
          justify="center"
        >
          <Grid item>
            <DoubleArrowIcon className={classes.icon} />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item style={{ marginBottom: '3rem' }} md={10} xs={10} />
          <Grid item md={4} xs={10}>
            <div className={classes.text_blu}>{t('D301V0028')}</div>
            <PasswordField
              id="newPassword"
              err={
                state.myprofile.passwordErr.digit
                  ? 'true'
                  : inputErrs.newPassword
              }
              helperTxt={
                state.myprofile.passwordErr.digit
                  ? t('D301V0210')
                  : t(helperTxts.newPassword)
              }
              value={state.myprofile.newPassword.value}
              handleChange={handleChangeNewPass}
              autoComplete="new-password"
            />
          </Grid>
          <Grid item style={{ marginBottom: '3rem' }} md={10} xs={10} />
          <Grid item md={4} xs={10}>
            <div className={classes.text_blu}>{t('D301V0029')}</div>
            <PasswordField
              id="newPassVerify"
              err={
                state.myprofile.passwordErr.verify
                  ? 'true'
                  : inputErrs.newPassVerify
              }
              helperTxt={
                state.myprofile.passwordErr.verify
                  ? t('D301V0161')
                  : t(helperTxts.newPassVerify)
              }
              value={state.myprofile.newPassVerify.value}
              handleChange={handleChangeNewPassVerify}
              autoComplete="new-password"
            />
          </Grid>
        </Grid>
      </div>
    </form>
  )
}

export default Password
