import { API_DATA } from '../../../actions'
import { resetCalledApiArr } from '../../../components/commonUseContext/Bid/Table/SelectCellBidDetails'

/** 初期データ */
const initApiData = {}

/**
 * Apiから取得したデータを格納
 * @param {Object} state state
 * @param {Object} action dispatchで受け取ったaction
 * @return {Object} 編集後のstate
 */
const setApiData = (state, action) => {
  const { name, apiData } = action
  state[name] = apiData
  return { ...state }
}

const deleteApiData = (state, action) => {
  const { name } = action
  if (name) {
    //指定のデータを削除
    delete state[name]
    resetCalledApiArr(name)
    return { ...state }
  } else {
    // 全削除
    resetCalledApiArr()
    return {}
  }
}

/**
 * APIから取得したデータを保持
 * @param {Object} [state=initPaging] 初期データ
 * @param {Object} action name, apiData
 * @return {Object} APIから取得したデータ
 */
const ApiData = (state = initApiData, action) => {
  const { type } = action
  switch (type) {
    case API_DATA.SET:
      return setApiData(state, action)
    case API_DATA.DELETE:
      return deleteApiData(state, action)
    default:
      return state
  }
}

export default ApiData

export { initApiData }
