import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import PrimaryButton from '../../common/buttons/PrimaryButton'

const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.buttonSize.width.large,
    height: theme.buttonSize.height.large,
    [theme.breakpoints.down('sm')]: {
      width: theme.buttonSize.width.medium,
      height: theme.buttonSize.height.medium,
    },
  },
}))

// TODO: コメント残す
const NextButton = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <PrimaryButton addclass={classes.root} {...props}>
      {t('3rd0009')}
    </PrimaryButton>
  )
}

export default NextButton
