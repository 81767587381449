import { CHECKBOX } from '../../../actions'

/** 初期表示ページ */
const initCheckBox = []

/**
 * state: 選択されたチェックボックスのindexを保持
 * @param {Object} [state=[]] -
 * @param {Object} action -
 * @returns {*} -
 */
const CheckBox = (state = initCheckBox, action) => {
  switch (action.type) {
    case CHECKBOX.SET:
      return CheckBoxSet(action)
    case CHECKBOX.TOGGLE:
      return CheckBoxToggle(action, state)
    default:
      return state
  }
}

/**
 * case CHECKBOX.SETで取得するデータ
 * @param {Object} action -
 * @returns {*} -
 */
const CheckBoxSet = (action) => {
  const resData = []
  action.data.map((data) => resData.push(data))
  return resData
}

/**
 * case CHECKBOX.SETで取得するデータ
 * @param {Object} action -
 * @param {Object} state -
 * @returns {*} -
 */
const CheckBoxToggle = (action, state) => {
  let resData = []
  resData.push(...state)
  // 配列の重複削除
  if (resData.indexOf(action.index) !== -1) {
    resData = resData.filter((item) => item !== action.index)
  } else {
    resData.push(action.index)
  }
  return resData
}

export default CheckBox

export { initCheckBox }
