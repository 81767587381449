import React, { useContext, useMemo } from 'react'

import AppContext from '../../../../../contexts/AppContext'
import TableRow from '../../../../common/table/TableRow'
import TableCellsLightCarAirFreight from './TableCellsLightCarAirFreight'
import CommonCalc from '../../../../common/function/CommonCalc'
import Common from '../../../../../constants/Common'

/**
 * useContextのstateから表全体のデータを作成
 * @return {JSX} TableRow ~ TableCellまでのコンポーネント
 */
const TableContentsLightCarAirFreight = () => {
  const { state } = useContext(AppContext)
  const { showData } = state.Tables

  /** @type {Number} state.showDataのインデックス */
  const page = CommonCalc.clamp(state.Paging - 1, 0, showData.length)

  return useMemo(() => {
    return showData[page].map((_, index) =>
      Object.values(Common.RATE.TYPE).map((rateType) => (
        <TableRow key={`${index}${rateType}`}>
          <TableCellsLightCarAirFreight index={index} rateType={rateType} />
        </TableRow>
      ))
    )
  }, [showData, page])
}

export default TableContentsLightCarAirFreight
