import React from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import HeaderText from '../../../../common/text/HeaderText'

/**
 * @return {JSX} SetForwarderヘッダーコンポーネント
 */
const HeaderSetForwarderBidInfo = () => {
  const { t } = useTranslation()
  return (
    <Grid item md={12} xs={12}>
      <HeaderText>{t('3rd0003')}</HeaderText>
    </Grid>
  )
}

export default HeaderSetForwarderBidInfo
