// Display + 画面名
const DisplayAirlineCargoManifest = {
  // 画面ID + 文言ID
  D210V0001: {
    en: 'CARGO MANIFEST',
    ja: '貨物マニフェスト',
  },
  D210V0002: {
    en: 'Flight',
    ja: 'フライト',
  },
  D210V0003: {
    en: 'ETD',
    ja: 'ETD',
  },
  D210V0004: {
    en: 'ETA',
    ja: 'ETA',
  },
  D210V0005: {
    en: 'Origin',
    ja: '出発',
  },
  D210V0006: {
    en: 'Destination',
    ja: '行先',
  },
  D210V0007: {
    en: 'Prepared by',
    ja: '作成者',
  },
  D210V0008: {
    en: 'MAWB No.',
    ja: 'MAWB No.',
  },
  D210V0009: {
    en: 'Pcs.',
    ja: 'Pcs.',
  },
  D210V0010: {
    en: 'Wgt.(kg)',
    ja: 'Wgt.(kg)',
  },
  D210V0011: {
    en: 'Vol.(CBM)',
    ja: 'Vol.(CBM)',
  },
  D210V0012: {
    en: 'Description.',
    ja: '説明',
  },
  D210V0013: {
    en: 'SPC.',
    ja: 'SPC.',
  },
  D210V0014: {
    en: 'ORG/DST',
    ja: '出発/到着',
  },
  D210V0015: {
    en: '933-XXXX XXXX',
    ja: '933-XXXX XXXX',
  },
  D210V0016: {
    en: 'XXX',
    ja: 'XXX',
  },
  D210V0017: {
    en: 'XXX.XX',
    ja: 'XXX.XX',
  },
  D210V0018: {
    en: 'XX.XX',
    ja: 'XX.XX',
  },
  D210V0019: {
    en: 'CONSOLIDATION',
    ja: '統合',
  },
  D210V0020: {
    en: 'BUP, HEA',
    ja: 'BUP, HEA',
  },
  D210V0021: {
    en: 'NRT/LAX',
    ja: 'NRT/LAX',
  },
  D210V0022: {
    en: 'e.g. 120x120x120-5pcs, KEEP COOL DRY ICE 30KG, UN1263 CL3 III 366 CAO, OVERPACK USED, etc…',
    ja: 'e.g. 120x120x120-5pcs, KEEP COOL DRY ICE 30KG, UN1263 CL3 III 366 CAO, OVERPACK USED, etc…',
  },
  D210V0023: {
    en: 'COL, ICE',
    ja: 'COL, ICE',
  },
  D210V0024: {
    en: 'RFL, CAO',
    ja: 'RFL, CAO',
  },
  D210V0025: {
    en: 'COL, FRO',
    ja: 'COL, FRO',
  },
  D210V0026: {
    en: 'OHG',
    ja: 'OHG',
  },
  D210V0027: {
    en: 'RCM',
    ja: 'RCM',
  },
  D210V0028: {
    en: 'LIVE CARP',
    ja: 'ライブカープ',
  },
  D210V0029: {
    en: 'JEWELRY',
    ja: '宝石',
  },
  D210V0030: {
    en: 'HATCHING EGG',
    ja: '孵化卵',
  },
  D210V0031: {
    en: 'MAIL',
    ja: '郵便物',
  },
  D210V0032: {
    en: 'FRESH FRUIT',
    ja: '新鮮な果物',
  },
  D210V0033: {
    en: 'VACCINE',
    ja: 'ワクチン',
  },
  D210V0034: {
    en: 'AVI',
    ja: 'AVI',
  },
  D210V0035: {
    en: 'VAL',
    ja: 'VAL',
  },
  D210V0036: {
    en: 'XPS',
    ja: 'XPS',
  },
  D210V0037: {
    en: 'HEG',
    ja: 'HEG',
  },
  D210V0038: {
    en: 'MAL',
    ja: 'MAL',
  },
  D210V0039: {
    en: 'COL,PIL',
    ja: 'COL,PIL',
  },
  D210V0040: {
    en: 'VOL',
    ja: 'VOL',
  },
  D210V0041: {
    en: 'ROX',
    ja: 'ROX',
  },
  D210V0042: {
    en: 'Total:',
    ja: '合計:',
  },
  D210V0043: {
    en: '10',
    ja: '10',
  },
  D210V0044: {
    en: 'XXXX',
    ja: 'XXXX',
  },
  D210V0045: {
    en: 'XXXXX.XX',
    ja: 'XXXXX.XX',
  },
  D210V0046: {
    en: 'COL,PER',
    ja: 'COL,PER',
  },
  D210V0047: {
    en: 'NRT/LAX/SFO',
    ja: 'NRT/LAX/SFO',
  },
  D210V0048: {
    en: 'KZ110/14APR20',
    ja: 'KZ110/14APR20',
  },
  D210V0049: {
    en: '11:00',
    ja: '11:00',
  },
  D210V0050: {
    en: '05:00',
    ja: '05:00',
  },
  D210V0051: {
    en: 'NRT',
    ja: 'NRT',
  },
  D210V0052: {
    en: 'SFO via LAX',
    ja: 'LAX 経由 SFO',
  },
  D210V0053: {
    en: 'Norikuni Inaba',
    ja: '稲葉憲邦',
  },
  D210V0054: {
    en: 'Pcs.',
    ja: 'Pcs.',
  },
  D210V0055: {
    en: 'Wgt.(kg)',
    ja: 'Wgt.(kg)',
  },
  D210V0056: {
    en: 'Vol.(CBM)',
    ja: 'Vol.(CBM)',
  },
  D210V0057: {
    en: 'NOTOC',
    ja: 'NOTOC',
  },
  D210V0058: {
    en: 'Download',
    ja: 'ダウンロード',
  },
  D210V0059: {
    en: 'View only',
    ja: '閲覧のみ',
  },
}
// Display + 画面名
export default DisplayAirlineCargoManifest
