import React, { useContext } from 'react'
import Grid from '@material-ui/core/Grid'

import PlanSelect from '../../../../common/companyInfo/PlanSelect'
import CompanyName from '../../../../common/companyInfo/CompanyName'
import TwoLetterCode from '../../../../common/companyInfo/TwoLetterCode'
import PostalCode from '../../../../common/companyInfo/PostalCode'
import Address from '../../../../common/companyInfo/CompanyAddress'
import Department from '../../../../common/companyInfo/Department'
import Airport from '../../../../common/companyInfo/Airport'
import Country from '../../../../common/companyInfo/Country'
import Representative from '../../../../common/companyInfo/Representative'
import Position from '../../../../common/companyInfo/Position'
import ContactInfo from '../../../../common/companyInfo/ContactInfo'
import IataCassNo from '../../../../common/companyInfo/IataCassNo'
import Icon from '../../../../common/companyInfo/Icon'
import DeleteAccount from '../../../../common/companyInfo/DeleteAccount'
import AppContext from '../../../../../contexts/AppContext'
import Constants from '../ConstantsLightCarCompanyInfo'

const TableLightCarCompanyInfo = () => {
  const { state, dispatch } = useContext(AppContext)
  const gsaFlg = state.companyinfo.gsaFlg.value

  const convertData = (dataCategory) => {
    /** @type {*} 表示する内容 */
    let displayContent = null

    switch (dataCategory) {
      case Constants.DATA_CATEGORY.PLAN:
        displayContent = <PlanSelect carrier />
        break
      case Constants.DATA_CATEGORY.PlanSelect:
        displayContent = <CompanyName />
        break
      case Constants.DATA_CATEGORY.TWO_LETTER_CODE:
        displayContent = <TwoLetterCode />
        break
      case Constants.DATA_CATEGORY.POSTAL_CODE:
        displayContent = (
          <PostalCode
            dispatch={dispatch}
            companyinfo={state.companyinfo}
            carrier
          />
        )
        break
      case Constants.DATA_CATEGORY.ADDRESS:
        displayContent = (
          <Address dispatch={dispatch} companyinfo={state.companyinfo} />
        )
        break
      case Constants.DATA_CATEGORY.DEPARTMENT:
        displayContent = (
          <Department dispatch={dispatch} companyinfo={state.companyinfo} />
        )
        break
      case Constants.DATA_CATEGORY.HUBS:
        displayContent = <Airport carrier />
        break
      case Constants.DATA_CATEGORY.COUNTRY:
        displayContent = <Country />
        break
      case Constants.DATA_CATEGORY.REPRESENTATIVE:
        displayContent = (
          <Representative
            dispatch={dispatch}
            companyinfo={state.companyinfo}
            carrier
          />
        )
        break
      case Constants.DATA_CATEGORY.POSITION:
        displayContent = (
          <Position
            dispatch={dispatch}
            companyinfo={state.companyinfo}
            carrier
          />
        )
        break
      case Constants.DATA_CATEGORY.CONTACT_INFO:
        displayContent = (
          <ContactInfo
            dispatch={dispatch}
            companyinfo={state.companyinfo}
            carrier
          />
        )
        break
      case Constants.DATA_CATEGORY.IATA_CASS_NUMBER:
        displayContent = (
          <IataCassNo
            dispatch={dispatch}
            companyinfo={state.companyinfo}
            carrier
          />
        )
        break
      case Constants.DATA_CATEGORY.ICON:
        displayContent = (
          <Icon dispatch={dispatch} companyinfo={state.companyinfo} carrier />
        )
        break
      default:
        displayContent = null
    }

    return (
      <Grid item md={10} xs={10}>
        {displayContent}
      </Grid>
    )
  }

  return (
    <Grid container justify="center">
      <Grid container item justify="center" spacing={4} md={12} xs={12}>
        {convertData(Constants.DATA_CATEGORY.PLAN)}
        {convertData(Constants.DATA_CATEGORY.COMPANY)}
        {!gsaFlg ? convertData(Constants.DATA_CATEGORY.TWO_LETTER_CODE) : false}
        {convertData(Constants.DATA_CATEGORY.POSTAL_CODE)}
        {convertData(Constants.DATA_CATEGORY.ADDRESS)}
        {!gsaFlg ? convertData(Constants.DATA_CATEGORY.DEPARTMENT) : false}
        {convertData(Constants.DATA_CATEGORY.HUBS)}
        {convertData(Constants.DATA_CATEGORY.COUNTRY)}
        {convertData(Constants.DATA_CATEGORY.REPRESENTATIVE)}
        {convertData(Constants.DATA_CATEGORY.POSITION)}
        {convertData(Constants.DATA_CATEGORY.CONTACT_INFO)}
        {!gsaFlg
          ? convertData(Constants.DATA_CATEGORY.IATA_CASS_NUMBER)
          : false}
        {!gsaFlg ? convertData(Constants.DATA_CATEGORY.ICON) : false}
        <Grid container item justify="flex-end" md={10} xs={10}>
          <DeleteAccount />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default TableLightCarCompanyInfo
