import React from 'react'
import Tab from '@material-ui/core/Tab'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  tabBaseSetting: theme.tab.baseSetting,
  tabNormalColor: theme.tab.normalColor,
  tabSelectColor: theme.tab.selectColor,
  tabNormalFillColor: theme.tab.normalFillColor,
  tabSelectFillColor: theme.tab.selectFillColor,
}))

/**
 * 共通タブコンポーネント(タブ1個分)
 * @param {String} label タブに表示する文字列
 * @param {Number} index 作成タブのindex
 * @param {Number} selectTabIndex 現在選択中のタブindex
 * @param {Any} restProps その他のTabコンポーネント設定
 * @return {JSX} 共通タブコンポーネント
 */
const CommonTab = ({ label, index, selectTabIndex, fill, ...restProps }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  // 選択中のタブと非選択のタブでデザインを切替
  const {
    tabBaseSetting,
    tabNormalColor,
    tabSelectColor,
    tabNormalFillColor,
    tabSelectFillColor,
  } = classes
  const isSelectTab = selectTabIndex === index
  let tabClassName = ''
  if (fill) {
    tabClassName = isSelectTab
      ? `${tabBaseSetting} ${tabSelectFillColor}`
      : `${tabBaseSetting} ${tabNormalFillColor}`
  } else {
    tabClassName = isSelectTab
      ? `${tabBaseSetting} ${tabSelectColor}`
      : `${tabBaseSetting} ${tabNormalColor}`
  }

  return (
    <Tab
      label={t(label)}
      className={tabClassName}
      // 波紋エフェクトを非活性
      disableRipple
      value={index}
      {...restProps}
    />
  )
}

CommonTab.propTypes = {
  label: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  selectTabIndex: PropTypes.number.isRequired,
}

export default CommonTab
