import React, { useMemo } from 'react'
import { Grid } from '@material-ui/core'

import BackButton from '../../../../common/buttons/BackButton'
import Paths from '../../../../../constants/Paths'

/**
 * @return {JSX} Backボタン
 */
const BackButtonLightBookingDetails = () => {
  return useMemo(
    () => (
      <Grid item>
        <BackButton path={Paths.AIRLINE.LIGHT_BOOKING_LIST} />
      </Grid>
    ),
    []
  )
}

export default BackButtonLightBookingDetails
