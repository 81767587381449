import React from 'react'

import SearchSort from '../../../../commonUseContext/SearchSort'
import ConstantsLightFwdrBookingList from '../ConstantsLightFwdrBookingList'
import { useTranslation } from 'react-i18next'

/**
 * @return {JSX} サーチソートコンポーネント
 */
const SearchSortLightFwdrBookingList = () => {
  const { t } = useTranslation()
  return (
    <SearchSort
      placeholder={t('D203V0002')}
      sortItemArr={ConstantsLightFwdrBookingList.SORT_ITEM_ARR}
    />
  )
}

export default SearchSortLightFwdrBookingList
