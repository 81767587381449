import { LIGHT_BOOKING_DETAILS } from '../../../actions'

/** @type {Object} MeasureAndWeightポップアップの初期値 */
const initMeasureAndWeightPopup = {
  setOpen: () => {},
  cargoDetail: {},
  index: 0,
  inputError: false,
}

/**
 * MeasureAndWeightポップアップを開く関数を登録
 * @param {Object} action action
 * @param {Function} action.setOpen ポップアップを開く関数
 * @param {Object} state state
 * @returns {Object} state
 */
const setOpenFunc = (action, state) => {
  const { setOpen } = action
  return { ...state, setOpen }
}

/**
 * 入力欄にエラーがあるか判定
 * @param {Object} cargoDetail 貨物情報
 * @returns {boolean} エラー判定
 */
const getHasInputError = (cargoDetail) =>
  Object.values(cargoDetail).some((v) => v === null)

/**
 * MeasureAndWeightポップアップを開く
 * @param {Object} action action
 * @param {Object} action.cargoDetail 表示する貨物情報
 * @param {Number} action.index 貨物情報の配列のインデックス
 * @param {Object} state state
 * @returns {Object} state
 */
const open = (action, state) => {
  const { cargoDetail, index } = action
  state.setOpen(true)
  const inputError = getHasInputError(cargoDetail)
  return { ...state, inputError, index, cargoDetail: { ...cargoDetail } }
}

/**
 * MeasureAndWeightポップアップを編集
 * @param {Object} action action
 * @param {Object} state state
 * @returns {Object} state
 */
const edit = (action, state) => {
  //値を書き換え
  Object.entries(action).map(([key, value]) => (state.cargoDetail[key] = value))
  //エラー判定
  const inputError = getHasInputError(state.cargoDetail)
  return { ...state, inputError }
}

/**
 * MeasureAndWeightポップアップの編集データを保持
 * @param { Object } [state = initMeasureAndWeightPopup] state
 * @param { Object } action action
 * @return { Object } state
 */
const MeasureAndWeightPopup = (state = initMeasureAndWeightPopup, action) => {
  const { type, ...rest } = action
  switch (type) {
    case LIGHT_BOOKING_DETAILS.MEASURE_AND_WEIGHT_POPUP.SET_OPEN_FUNC:
      return setOpenFunc(rest, state)
    case LIGHT_BOOKING_DETAILS.MEASURE_AND_WEIGHT_POPUP.OPEN:
      return open(rest, state)
    case LIGHT_BOOKING_DETAILS.MEASURE_AND_WEIGHT_POPUP.EDIT:
      return edit(rest, state)
    default:
      return state
  }
}

export default MeasureAndWeightPopup

export { initMeasureAndWeightPopup }
