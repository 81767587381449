import { combineReducers } from 'redux'

import Paging, { initPaging } from '../../common/Paging'
import Tables, { initTableData } from './Tables'
import ShcDialog, { initShcDialog } from '../../common/ShcDialog'
import SearchSort, { initSearchSort } from '../../common/SearchSort'
import Gsa, { initGsa } from '../../common/Gsa'

const initialState = {
  Paging: initPaging,
  Tables: initTableData,
  ShcDialog: initShcDialog,
  SearchSort: initSearchSort,
  Gsa: initGsa,
}

export default combineReducers({
  Paging,
  Tables,
  ShcDialog,
  SearchSort,
  Gsa,
})

export { initialState }
