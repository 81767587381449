import { Grid, makeStyles } from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router-dom'

import ApiPaths from '../../../../../constants/ApiPaths'
import Common from '../../../../../constants/Common'
import Paths from '../../../../../constants/Paths'
import AddButton from '../../../../common/buttons/AddButton'
import CommonFunc from '../../../../common/function/CommonFunc'

const useStyles = makeStyles((theme) => ({
  margin: theme.addMargin,
  right: theme.addRight,
}))

/**
 * 空の入札情報を作成
 * @param {*} history react-router-domのuseHistory
 * @return {Object} 空の入札情報オブジェクト
 */
const createBidPageInfoObj = async (history) => {
  const { userId, shipperJhId } = CommonFunc.getLoginUserData()

  // ログインユーザ情報を取得
  const apiInfoArr = [
    {
      key: 'myProfile',
      method: 'get',
      path: ApiPaths.MASTER_SHIPPER.SHIP_MY_PROFILE,
    },
    {
      key: 'companyInfo',
      method: 'get',
      path: ApiPaths.MASTER_SHIPPER.COMPANY_INFO,
    },
  ]
  const resObj = await CommonFunc.execApiAsync(apiInfoArr)
  const isApiSuccessful = CommonFunc.checkApiResponseObj(resObj, history)

  if (isApiSuccessful) {
    const { myProfile, companyInfo } = resObj

    const { name, lastName, position, department, email, tel } =
      myProfile.data.results
    const userFullName = `${name} ${lastName}`
    const {
      companyName: shipperCompanyName,
      address: shipperAddress,
      tel: shipperTel,
      firstName: shipperFirstName,
      lastName: shipperLastName,
    } = companyInfo.data.results

    const stateObj = {
      bidInfoObj: {
        shipperId: shipperJhId,
        shipperCompanyName,
        shipperAddress,
        shipperTel,
        shipperFirstName,
        shipperLastName,
        picArr: [
          // ログインユーザーの情報
          {
            bidInChargeId: null,
            userId,
            mainPersonFlg: 1,
            delFlg: 0,
            name: userFullName,
            position,
            department,
            email,
            tel,
          },
        ],
        consigneeId: null,
        consigneeCompanyName: '',
        consigneeAddress: '',
        consigneeTel: '',
        consigneeFirstName: '',
        consigneeLastName: '',
        startDate: '',
        finishDate: '',
        firstDead: '',
        specialNoteArr: ['', '', '', '', ''],
        shipperReadFlg: null,
        updatedAt: '',
      },
      selectFwdrObj: {
        setFwdrFlg: Common.SET_FWDR_FLG.SPECIFIED,
        selectFwdrArr: [],
      },
      bidDetailArr: [],
      updatedAtArr: [],
      bidStatusObj: {
        bidStatusId: null,
        shipperDraftFlg: null,
      },
    }

    return stateObj
  }
}

/**
 * ページ遷移
 * @param {*} history react-router-domのuseHistory
 * @return {void}
 */
const transitionPage = async (history) => {
  const pathStr = Paths.SHIPPER.BID_INFO
  const stateObj = await createBidPageInfoObj(history)
  const clickEventObj = {
    path: pathStr,
    state: stateObj,
  }
  CommonFunc.clickEvent(clickEventObj, history)
}

/**
 * @return {*} Addボタンページコンポーネント
 */
const AddBidMgmt = () => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <div>
      <Grid container justify="center" className={classes.margin}>
        <Grid item md={10} xs={10} className={classes.right}>
          <AddButton onClick={() => transitionPage(history)} />
        </Grid>
      </Grid>
    </div>
  )
}

export default AddBidMgmt
