import { SHIPPER_ADDRESS } from '../../../actions'

import CommonFunc from '../../../components/common/function/CommonFunc'

const initShipperAddressBidInfo = []

/**
 * BidInfo画面遷移時の初期state設定
 * @param {Object} action dispatchで受け取ったaction
 * @return {Object} ShipperAddress情報を保持したstate
 */
const createAddressArr = (action) => {
  const { data } = action
  const {
    shipperAddress,
    shipperCompanyName,
    shipperTel,
    shipperFirstName,
    shipperLastName,
  } = data

  return CommonFunc.createAddressArr(
    shipperAddress,
    shipperCompanyName,
    shipperTel,
    shipperFirstName,
    shipperLastName
  )
}

/**
 * ShipperAddress情報を保持
 * @param {Object} [state=initShipperAddressBidInfo] ShipperAddressBidInfoのstate
 * @param {Object} action dispatchで受け取ったaction
 * @return {Object} ShipperAddress情報を保持したstate
 */
const ShipperAddressBidInfo = (state = initShipperAddressBidInfo, action) => {
  switch (action.type) {
    case SHIPPER_ADDRESS.INIT:
      return createAddressArr(action)
    default:
      return state
  }
}

export default ShipperAddressBidInfo

export { initShipperAddressBidInfo }
