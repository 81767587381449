import React from 'react'
import PropTypes from 'prop-types'

/**
 * @param {object} props 下記内容
 * @param {object} props.contents 予約情報
 * @return {JSX} 航空便情報
 */
const AirportLightSearchResult = ({ contents }) => {
  let airport = null
  const org = contents.orgAirportCode
  const dst = contents.dstAirportCode
  const viaOne = contents.viaOneAirportCode
  const viaTwo = contents.viaTwoAirportCode

  // 経路により表示項目を分ける
  if (viaTwo) {
    airport = `${org}/${viaOne}/${viaTwo}/${dst}`
  } else if (viaOne) {
    airport = `${org}/${viaOne}/${dst}`
  } else {
    airport = `${org}/${dst}`
  }

  return (
    <>
      <div>{contents.flightNumber}</div>
      <div>{airport}</div>
    </>
  )
}

AirportLightSearchResult.propTypes = {
  contents: PropTypes.object.isRequired,
}

export default AirportLightSearchResult
