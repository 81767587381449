import React, { useContext } from 'react'
import { Grid, makeStyles } from '@material-ui/core'

import AppContext from '../../../../../contexts/AppContext'
import RequestCardLightJhClientRegDeleteAccount from './RequestCardLightJhClientRegDeleteAccount'
import ClientTabsLightJhClientReg from '../../../../commonUseContext/tab/ClientTabsLightJhClientReg'
import CommonNoRequest from '../../../../common/table/CommonNoRequest'
import RejectDialogLightJhClientRegDeleteAccount from './RejectDialogLightJhClientRegDeleteAccount'
import AcceptDialogLightJhClientRegDeleteAccount from './AcceptDialogLightJhClientRegDeleteAccount'
import SavedChangesDialogLightJhClientRegDeleteAccount from './SavedChangesDialogLightJhClientRegDeleteAccount'

const useStyles = makeStyles((theme) => ({
  paperBack: theme.clientRegPaperBack,
}))

/**
 * @return {JSX} リクエストテーブルコンポーネント
 */
const RequestTableLightJhClientRegDeleteAccount = () => {
  const classes = useStyles()
  const { state } = useContext(AppContext)
  const currentTab = state.Tabs
  const currentPageDataAmount = state.Tables.pageAmountArr[currentTab]
  const isNoRequest = currentPageDataAmount === 0

  return (
    <Grid container justify="center">
      {/* リクエスト数付きタブ */}
      <Grid container item xs={10}>
        <ClientTabsLightJhClientReg />
      </Grid>
      {/* リクエストテーブル NoRequestの場合非表示 */}
      {isNoRequest ? (
        <></>
      ) : (
        <Grid
          container
          item
          xs={10}
          justify="center"
          spacing={4}
          className={classes.paperBack}
        >
          <RequestCardLightJhClientRegDeleteAccount />
        </Grid>
      )}
      {/* No Request */}
      <Grid container item xs={10} justify="center">
        <CommonNoRequest show={isNoRequest} />
      </Grid>
      {/* ダイアログ */}
      <RejectDialogLightJhClientRegDeleteAccount />
      <AcceptDialogLightJhClientRegDeleteAccount />
      <SavedChangesDialogLightJhClientRegDeleteAccount />
    </Grid>
  )
}

export default RequestTableLightJhClientRegDeleteAccount
