import React, { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

import AppContext from '../../../../../contexts/AppContext'
import TableCell from '../../../../common/table/TableCell'
import CommonCalc from '../../../../common/function/CommonCalc'
import Common from '../../../../../constants/Common'
import CheckBox from '../../../../common/checkBox/CheckBox'
import { TABLES } from '../../../../../actions'

const useStyles = makeStyles(() => ({
  checkbox: {
    width: 'auto',
    textAlign: 'left',
    whiteSpace: 'nowrap',
  },
  tableCell: {
    padding: '0px !important',
    verticalAlign: 'top',
  },
}))

/**
 * @param {object} props 下記内容
 * @param {Number} props.index ページ上のインデックス
 * @return {JSX} All-in rateチェックボックスのセル
 */
const AllInRateCellLightCarAirFreight = ({ index }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { state, dispatch } = useContext(AppContext)

  /** @type {Number} showDataのインデックス */
  const page = CommonCalc.calcShowDataIndex(
    state.Paging,
    state.Tables.showData.length
  )
  // allInRateとfuelSurChargeを取得
  const { allInRate, fuelSurCharge } = state.Tables.showData[page][index]

  return useMemo(() => {
    // All-in rateにチェックが入っているか
    const isAllInRate = allInRate === Common.ALL_IN_RATE.ON

    /**
     * Hideチェック更新
     * @param {*} event イベント
     * @returns {void} なし
     */
    const onChange = (event) => {
      dispatch({
        type: TABLES.EDIT,
        page,
        index,
        allInRate: event.target.checked
          ? Common.ALL_IN_RATE.ON
          : Common.ALL_IN_RATE.OFF,
      })
    }

    return (
      <TableCell className={classes.tableCell}>
        <CheckBox
          className={classes.checkbox}
          checked={isAllInRate}
          label={t('2nd0018')}
          onChange={onChange}
          disabled={fuelSurCharge !== '' || !state.Auth}
        />
      </TableCell>
    )
  }, [
    allInRate,
    t,
    dispatch,
    index,
    classes.checkbox,
    classes.tableCell,
    fuelSurCharge,
    page,
    state.Auth,
  ])
}
AllInRateCellLightCarAirFreight.propTypes = {
  index: PropTypes.number.isRequired,
}

export default AllInRateCellLightCarAirFreight
