import React, { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'

import { LIGHT_FLIGHT_MGMT } from '../../../../../../actions'
import AppContext from '../../../../../../contexts/AppContext'
import InputSelect from '../../../../../common/select/InputSelect'

// 曜日情報の型定義
/** @typedef {import('../../../../../../reducers/common/Day').DayObj} DayObj */

/**
 * @param {*} props 下記内容のProps
 * @param {Number} props.routingIndex Routingのインデックス
 * @param {Number} props.index FlightScheduleのインデックス
 * @returns {JSX} 曜日選択欄
 */
const DaySelect = (props) => {
  const { routingIndex, index } = props
  const { state, dispatch } = useContext(AppContext)
  /** @type {Number} 選択中の曜日の値 */
  const day = state.Popup.getScheduleParam(routingIndex, index, 'day')

  /** @type {Boolean} 新規追加フラグ */
  const add = state.Popup.getScheduleParam(routingIndex, index, 'add')

  /** @type {Boolean} ハイドフラグ */
  const hideFlg = Boolean(
    state.Popup.getScheduleParam(routingIndex, index, 'hideFlg')
  )

  /** @type {Boolean} 非活性判定 */
  const disabled = !add || hideFlg

  return useMemo(() => {
    /** @type {DayObj} 選択中の曜日情報 */
    const selectedDay = state.Day.getDay(day)

    /**
     * 曜日更新
     * @param {DayObj} value 選択した曜日情報
     * @returns {void} 無し
     */
    const onChange = (value) => {
      dispatch({
        type: LIGHT_FLIGHT_MGMT.EDIT.FLIGHT_SCHEDULE,
        routingIndex,
        index,
        day: value?.day ?? null,
      })
    }

    return (
      <InputSelect
        options={state.Day.days}
        getOptionLabel={(option) => option.label}
        value={selectedDay}
        onChange={(_, value) => onChange(value)}
        disabled={disabled}
      />
    )
  }, [day, state.Day, dispatch, index, routingIndex, disabled])
}
DaySelect.propTypes = {
  routingIndex: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
}
export default DaySelect
