import React, { useContext, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'

import { LIGHT_FLIGHT_MGMT } from '../../../../../../actions'
import AppContext from '../../../../../../contexts/AppContext'
import TextField from '../../../../../common/text/TextField'

/**
 * @param {*} props 下記内容のProps
 * @param {Number} props.routingIndex Routingのインデックス
 * @param {Number} props.index FlightScheduleのインデックス
 * @returns {JSX} ETA欄
 */
const Eta = (props) => {
  const { routingIndex, index } = props
  const { state, dispatch } = useContext(AppContext)
  /** @type {Function} FlightScheduleのデータを取得する */
  const getScheduleParam = state.Popup.getScheduleParam
  /** @type {String} ETDの入力値 */
  const etd = getScheduleParam(routingIndex, index, 'etd')
  /** @type {String} flightTimeの入力値 */
  const flightTime = state.Popup.getRoutingParam(routingIndex, 'flightTime')
  /** @type {String} etaの値 */
  const eta = state.Popup.getEta(flightTime, etd)
  /** @type {Boolean} ハイドフラグ */
  const hideFlg = Boolean(getScheduleParam(routingIndex, index, 'hideFlg'))

  // ETAを更新
  useEffect(() => {
    dispatch({
      type: LIGHT_FLIGHT_MGMT.EDIT.FLIGHT_SCHEDULE,
      routingIndex,
      index,
      eta,
    })
  }, [eta]) //eslint-disable-line

  return useMemo(() => {
    /** @type {Boolean} 新規追加フラグ */
    const add = getScheduleParam(routingIndex, index, 'add')
    /** @type {Boolean} 非活性判定 */
    const disabled = !add || hideFlg

    return <TextField value={eta} disabled={disabled} />
  }, [eta, index, routingIndex, getScheduleParam, hideFlg])
}
Eta.propTypes = {
  routingIndex: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
}
export default Eta
