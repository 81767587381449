import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'

import LayoutBox from '../../../common/layout/LayoutBox'
import Theme from '../../../common/theme/Theme'
import Title from '../../../common/title/Title'

const useStyles = makeStyles(() => ({
  center: Theme.center,
  marginTop: {
    marginTop: '2rem',
  },
  text: Theme.textBoldBLK,
}))

const Maintenance = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <LayoutBox>
      <Title>UNDER MAINTENANCE</Title>
      <Grid container justify="center">
        <Grid item md={10} xs={10}>
          <div className={classes.center}>
            <div className={classes.text}>{t('3rd0001')}</div>
          </div>
        </Grid>
      </Grid>
    </LayoutBox>
  )
}

export default Maintenance
