import React, { useContext } from 'react'

import { PAGING_DATA } from '../../../../actions'
import Paging from '../../../common/buttons/Paging'
import AppContext from '../../../../contexts/AppContext'

/**
 * @returns {*} - ページコンポーネント
 */
const NotifyPaging = () => {
  const { state, dispatch } = useContext(AppContext)

  const pagechange = (_event, value) => {
    // ページの先頭に移動
    window.scrollTo(0, 0)

    dispatch({
      type: PAGING_DATA,
      page: value,
    })
  }

  // pagingに渡す要素
  const pagingFactors = {
    changeEvent: pagechange,
    amount: state.Tables.showData.length,
    page: state.Paging.page,
  }

  return <Paging pagingfactors={pagingFactors} />
}

export default NotifyPaging
