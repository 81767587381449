// Display + 画面名
const DisplayAirlineConfirmBooking = {
  // 画面ID + 文言ID
  D205V0001: {
    en: 'Shipping Details',
    ja: '発送詳細',
  },
  D205V0002: {
    en: 'MAWB No.',
    ja: '航空運送状 No.',
  },
  D205V0003: {
    en: 'ORG/DST',
    ja: '行先/到着',
  },
  D205V0004: {
    en: 'Pcs.',
    ja: '個',
  },
  D205V0005: {
    en: 'Gross Wgt.(kg)',
    ja: '総重量(kg)',
  },
  D205V0006: {
    en: 'Vol.(m3)',
    ja: '体積(m3)',
  },
  D205V0007: {
    en: 'Chargeable Wgt.(kg)',
    ja: '搭載可能重量(kg)',
  },
  D205V0008: {
    en: 'Rate',
    ja: 'レート',
  },
  D205V0009: {
    en: 'SHC Code',
    ja: 'SHCコード',
  },
  D205V0010: {
    en: 'Current Status',
    ja: '現在の状態',
  },
  D205V0011: {
    en: '933-XXXX XXXX',
    ja: '933-XXXX XXXX',
  },
  D205V0012: {
    en: 'NRT/ORD',
    ja: 'NRT/ORD',
  },
  D205V0013: {
    en: 'XX',
    ja: 'XX',
  },
  D205V0014: {
    en: 'XXX.XX',
    ja: 'XXX.XX',
  },
  D205V0015: {
    en: 'X.XX',
    ja: 'X.XX',
  },
  D205V0016: {
    en: 'JPYXXX/kg++',
    ja: 'JPYXXX/kg++',
  },
  D205V0017: {
    en: 'COL/ICE',
    ja: 'COL/ICE',
  },
  D205V0018: {
    en: 'Space Request',
    ja: 'スペースリクエスト',
  },
  D205V0019: {
    en: 'Measure & Weight',
    ja: '大きさ & 重量',
  },
  D205V0020: {
    en: 'XXX',
    ja: 'XXX',
  },
  D205V0021: {
    en: 'cm',
    ja: 'cm',
  },
  D205V0022: {
    en: 'kg',
    ja: 'kg',
  },
  D205V0023: {
    en: 'G/W(kg)',
    ja: '総重量(kg)',
  },
  D205V0024: {
    en: 'Length(cm)',
    ja: '長さ',
  },
  D205V0025: {
    en: 'Width(cm)',
    ja: '幅',
  },
  D205V0026: {
    en: 'Height(cm)',
    ja: '高さ',
  },
  D205V0027: {
    en: '1.',
    ja: '1.',
  },
  D205V0028: {
    en: '2.',
    ja: '2.',
  },
  D205V0029: {
    en: '3.',
    ja: '3.',
  },
  D205V0030: {
    en: '4.',
    ja: '4.',
  },
  D205V0031: {
    en: '5.',
    ja: '5.',
  },
  D205V0032: {
    en: '6.',
    ja: '6.',
  },
  D205V0033: {
    en: '7.',
    ja: '7.',
  },
  D205V0034: {
    en: '8.',
    ja: '8.',
  },
  D205V0035: {
    en: '9.',
    ja: '9.',
  },
  D205V0036: {
    en: '10.',
    ja: '10.',
  },
  D205V0037: {
    en: 'Routing',
    ja: 'ルーティング',
  },
  D205V0038: {
    en: 'ORG',
    ja: '出発港',
  },
  D205V0039: {
    en: 'DST',
    ja: '到着港',
  },
  D205V0040: {
    en: 'Carrier',
    ja: '運搬',
  },
  D205V0041: {
    en: 'Flight# ',
    ja: 'フライト#',
  },
  D205V0042: {
    en: 'Wgt.',
    ja: '重量',
  },
  D205V0043: {
    en: 'Vol.',
    ja: '体積',
  },
  D205V0044: {
    en: 'Allotment',
    ja: 'アロット',
  },
  D205V0045: {
    en: 'KIX',
    ja: '関西国際空港',
  },
  D205V0046: {
    en: 'NRT',
    ja: '成田国際空港',
  },
  D205V0047: {
    en: 'ORD',
    ja: 'シカゴ・オヘア国際空港',
  },
  D205V0048: {
    en: 'JL',
    ja: 'JL',
  },
  D205V0049: {
    en: 'TRK',
    ja: 'トラック',
  },
  D205V0050: {
    en: '303/01SEP',
    ja: '303/01SEP',
  },
  D205V0051: {
    en: 'XX-XX',
    ja: 'XX-XX',
  },
  D205V0052: {
    en: '303/02SEP',
    ja: '303/02SEP',
  },
  D205V0053: {
    en: '5',
    ja: '5',
  },
  D205V0054: {
    en: '15',
    ja: '15',
  },
  D205V0055: {
    en: '500.00',
    ja: '500.00',
  },
  D205V0056: {
    en: '750.00',
    ja: '750.00',
  },
  D205V0057: {
    en: '5.00',
    ja: '5.00',
  },
  D205V0058: {
    en: '7.25',
    ja: '7.25',
  },
  D205V0059: {
    en: '0',
    ja: '0',
  },
  D205V0060: {
    en: '-',
    ja: '-',
  },
  D205V0061: {
    en: 'Edit',
    ja: '編集',
  },
  D205V0062: {
    en: 'SplitAWB',
    ja: '航空貨物運送状を分ける',
  },
  D205V0063: {
    en: 'HAWB',
    ja: 'HAWB',
  },
  D205V0064: {
    en: 'HMFST',
    ja: 'HMFST',
  },
  D205V0065: {
    en: 'MAWB',
    ja: 'MAWB',
  },
  D205V0066: {
    en: 'DGD',
    ja: '危険物申告書',
  },
  D205V0067: {
    en: 'Label & Mark',
    ja: 'ラベル/マーク',
  },
  D205V0068: {
    en: 'Invoice',
    ja: '請求書',
  },
  D205V0069: {
    en: 'Packing & List',
    ja: 'パッキングリスト',
  },
  D205V0070: {
    en: 'SDS',
    ja: '安全データシート',
  },
  D205V0071: {
    en: 'Air Freight',
    ja: '航空貨物',
  },
  D205V0072: {
    en: 'Not negociate',
    ja: '交渉しない',
  },
  D205V0073: {
    en: 'Offer spot rates',
    ja: '直物レートを依頼する',
  },
  D205V0074: {
    en: 'Allow',
    ja: '許可',
  },
  D205V0075: {
    en: 'Reject',
    ja: '拒否',
  },
  D205V0076: {
    en: 'Are you sure you want to confirm space toward Shipper/Forwarder?',
    ja: '荷主とフォワーダーに向けてスペースの確認をしてもよろしいですか？',
  },
  D205V0077: {
    en: 'Back',
    ja: '戻る',
  },
  D205V0078: {
    en: 'Space Confirmed',
    ja: 'スペース確認',
  },
  D205V0079: {
    en: 'Document',
    ja: '書類',
  },
  D205V0080: {
    en: 'JPY350/kg++',
    ja: 'JPY350/kg++',
  },
  D205V0081: {
    en: 'CONFIRM BOOKING',
    ja: '予約確認',
  },
  D205V0082: {
    en: 'Yes',
    ja: 'はい',
  },
  D205V0083: {
    en: 'No',
    ja: 'いいえ',
  },
  D205V0084: {
    en: "KIX - Kansai Int'l Airport",
    ja: 'KIX - 関西国際空港',
  },
  D205V0085: {
    en: 'OK',
    ja: '決定',
  },
  D205V0086: {
    en: 'Cancel',
    ja: 'キャンセル',
  },
  D205V0087: {
    en: 'Do you reject?',
    ja: '拒否しますか？',
  },
  D205V0088: {
    en: 'Total',
    ja: '合計',
  },
  D205V0089: {
    en: "ORD - Chicago O'Hare Int'l Airport",
    ja: 'シカゴ・オヘア国際空港',
  },
  D205V0090: {
    en: 'L x W x H',
    ja: 'L x W x H',
  },
  D205V0091: {
    en: 'L:',
    ja: 'L:',
  },
  D205V0092: {
    en: 'W:',
    ja: 'W:',
  },
  D205V0093: {
    en: 'H:',
    ja: 'H:',
  },
  D205V0094: {
    en: 'Contains invalid characters.',
    ja: '無効な文字が含まれています。',
  },
  D205V0095: {
    en: 'Show',
    ja: '表示',
  },
  D205V0096: {
    en: 'SHC',
    ja: 'SHC',
  },
  D205V0097: {
    en: 'AWB No.',
    ja: 'ハウス番号',
  },
}
// Display + 画面名
export default DisplayAirlineConfirmBooking
