import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import PrimaryButton from '../../../common/buttons/PrimaryButton'
import Paths from '../../../../constants/Paths'

const useStyles = makeStyles((theme) => ({
  primary: {
    width: theme.buttonSize.width.large,
    height: theme.buttonSize.height.large,
    border: 'none',
    borderColor: 'none',
    fontFamily: theme.typography.fontFamily,
    marginTop: '150px',
  },
}))

const Button = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <Grid container justify="center">
        <PrimaryButton
          addclass={classes.primary}
          path={Paths.AUTHENTICATION.SIGN_IN}
        >
          {t('D501V0064')}
        </PrimaryButton>
      </Grid>
    </>
  )
}

export default Button
