import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'

/**
 * サインアップ画面の外側の共通設定コンポーネント
 * @param {Object} - { children } 下記の値を渡す
 * @param {JSX} children - 表内に表示したい要素
 * @return {JSX} - コンポーネント
 */
const AuthenticationTableContainer = ({ children }) => {
  return (
    <Grid container justify="center">
      <Grid container justify="center" item md={7}>
        <Grid item md={6}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  )
}

AuthenticationTableContainer.propTypes = {
  children: PropTypes.node,
}

export default AuthenticationTableContainer
