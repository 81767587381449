import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

import { TABLES } from '../../../../../actions'
import Checkbox from '../../../../common/checkBox/CheckBox'
import Common from '../../../../../constants/Common'

const useStyles = makeStyles(() => ({
  checkbox: {
    margin: 0,
  },
}))

/**
 * 表に表示するチェックボックスコンポーネント
 * @param {Object} cellRequiredObj showDataのインデックス
 * @param {Number} statusId 画面表示時のStatusId
 * @param {string} key key
 * @return {JSX} チェックボックス
 */
const CheckboxCellBidDetails = (cellRequiredObj, statusId, key) => {
  const { index, cellKey, cellValue, apiRequiredObj } = cellRequiredObj
  const { dispatch } = apiRequiredObj
  const classes = useStyles()

  /**チェックボックスを表示するか */
  const isShow = statusId === Common.BID_STATUS.UPDATE.ID

  /**チェックボックスの非活性判定 */
  const isDisabled = cellValue === Common.BID_STATUS.LOST.TEXT

  /** チェックボックスにチェックを入れているか */
  const checked = isShow && cellValue === Common.BID_STATUS.CLOSE.TEXT

  const id = `checkbox${key}`

  /**
   * 選択された時のイベント
   * @param {Object} event イベント
   * @returns {void} なし
   */
  const onChange = (event) => {
    dispatch({
      type: TABLES.EDIT,
      index,
      cellKey,
      checked: event.target.checked,
    })
  }

  const checkbox = (
    <Checkbox
      className={classes.checkbox}
      id={id}
      checked={checked}
      onChange={onChange}
      disabled={isDisabled}
    />
  )

  return isShow ? checkbox : null
}

CheckboxCellBidDetails.propTypes = {
  cellRequiredObj: PropTypes.object.isRequired,
  statusId: PropTypes.number.isRequired,
  key: PropTypes.string.isRequired,
}

export default CheckboxCellBidDetails
