import React from 'react'
import { useTranslation } from 'react-i18next'

import TableRow from '../../../common/table/TableRow'
import TableCell from '../../../common/table/TableCell'

/**
 * @return {JSX} Measure&Weight欄のヘッダー部分
 */
const HeaderMeasureAndWeightLightBookingDetails = () => {
  const { t } = useTranslation()
  return (
    <TableRow>
      <TableCell />
      <TableCell>{t('D103V0003')}</TableCell>
      <TableCell>{t('D103V0018')}</TableCell>
      <TableCell>{t('D103V0019')}</TableCell>
      <TableCell />
      <TableCell>{t('D103V0020')}</TableCell>
      <TableCell />
      <TableCell>{t('D103V0021')}</TableCell>
      <TableCell />
    </TableRow>
  )
}

export default HeaderMeasureAndWeightLightBookingDetails
