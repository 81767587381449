import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import AppContext from '../../../../../contexts/AppContext'
import Common from '../../../../../constants/Common'
import TableCell from '../../../../common/table/TableCell'
import ConstantsLightAirlineBookingList from '../ConstantsLightAirlineBookingList'
import ShcButtonLightAirlineBookingList from './ShcButtonLightAirlineBookingList'
import CheckButtonLightBookingList from './CheckButtonLightBookingList'

/** @typedef {import('../ConstantsLightAirlineBookingList').TableInfo} TableInfo テーブル情報 */

/**
 * 1行分の表データを作成
 * @param {Object} props 下記内容
 * @param {Number} props.page 表示中のページ番号
 * @param {Number} props.index ページ上のインデックス
 * @return {JSX} TableCellコンポーネント(1行分)
 */
const TableCellsLightAirlineBookingList = ({ page, index }) => {
  const { state } = useContext(AppContext)

  /** @type {Object} 1行分のデータ */
  const contents = state.Tables.showData[page][index]

  const { GSA_TABLE_ARR, AIRLINE_TABLE_ARR } = ConstantsLightAirlineBookingList
  const tableArr = state.Gsa ? GSA_TABLE_ARR : AIRLINE_TABLE_ARR

  /**
   * 定数で定義した表データのコンポーネントを作成
   * @param {TableInfo} tableInfo テーブル情報
   * @param {Number} index mapのindex
   * @return {JSX} TableCellコンポーネント(1個分)
   */
  const createTableCell = (tableInfo, index) => {
    const { cellKey, cellCategory } = tableInfo
    const { cargoId, shc } = contents

    /** @type {*} セルに表示する内容 */
    let displayContent = null

    switch (cellCategory) {
      case Common.CELL_CATEGORY.TEXT:
        displayContent = contents[cellKey]
        break
      case Common.CELL_CATEGORY.BUTTON.SHOW:
        displayContent = <ShcButtonLightAirlineBookingList shcArr={shc} />
        break
      case Common.CELL_CATEGORY.BUTTON.CHECK:
        displayContent = <CheckButtonLightBookingList cargoId={cargoId} />
        break
      default:
        displayContent = null
    }
    return <TableCell key={index}>{displayContent}</TableCell>
  }

  return tableArr.map(createTableCell)
}

TableCellsLightAirlineBookingList.propTypes = {
  page: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
}

export default TableCellsLightAirlineBookingList
