import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import Paths from '../../../../../constants/Paths'
import NextButton from '../../../../common/buttons/NextButton'
import CommonFunc from '../../../../common/function/CommonFunc'
import Common from '../../../../../constants/Common'
import SaveCompleteDialog from '../../../../common/dialog/SaveCompleteDialog'

/**
 * @param {Object} props 以下
 * @param {Object} pageDataObj 前ページから受け取った情報
 * @return {JSX} Nextボタンとエラー時のポップアップコンポーネント
 */
const NextButtonBidInfo = ({ pageDataObj }) => {
  const history = useHistory()

  // updatedAtのチェックの状態
  const [isErr, setIsErr] = useState(false)
  const [errCode, setErrCode] = useState(Common.ERROR_CODE.NORMAL)

  /**
   * ページ遷移
   * @return {void}
   */
  const transitionPage = async () => {
    const { updatedAtArr } = pageDataObj
    // updatedAtのチェック
    const { errorCode, isError } = await CommonFunc.checkUpdatedAtBid(
      updatedAtArr,
      history
    )
    if (isError) {
      // エラー状態を保存しポップアップを表示
      setIsErr(isError)
      setErrCode(errorCode)
    } else {
      // ページデータ作成&ページ遷移
      const pathStr = Paths.FWDR.BID_DETAILS
      const stateObj = pageDataObj
      const clickEventObj = {
        path: pathStr,
        state: stateObj,
      }
      CommonFunc.clickEvent(clickEventObj, history)
    }
  }

  return (
    <Grid item>
      <NextButton onClick={transitionPage} />
      {/* 排他チェック失敗時のポップアップ */}
      <SaveCompleteDialog
        savecomp={isErr.toString()}
        path={Paths.FWDR.BID_MGMT}
        errcode={errCode}
      />
    </Grid>
  )
}

NextButtonBidInfo.propTypes = {
  pageDataObj: PropTypes.object.isRequired,
}

export default NextButtonBidInfo
