import { LIGHT_REGISTER_NEW_ACCOUNT } from '../../../actions'

const initIsAgreeChecked = {
  value: false,
  require: true,
}

/**
 * 内容を保持
 * @param {Object} action - dispatchで受け取ったaction
 * @param {Object} state - state
 * @return {Object} - state
 */
const updateAgreeCheck = (action, state) => {
  const { value } = action
  return {
    ...state,
    value,
  }
}

/**
 * 同意チェック状態を保持
 * @param {Object} [state=initIsAgreeChecked] - state
 * @param {Object} action - action
 * @return {Object} - state
 */
const AgreeCheck = (state = initIsAgreeChecked, action) => {
  switch (action.type) {
    case LIGHT_REGISTER_NEW_ACCOUNT.AGREE_CHECK.UPDATE:
      return updateAgreeCheck(action, state)
    default:
      return state
  }
}

export default AgreeCheck

export { initIsAgreeChecked }
