import React, { useEffect, useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import { useHistory } from 'react-router-dom'

import BackButton from '../../../../common/buttons/BackButton'
import CommonFunc from '../../../../common/function/CommonFunc'
import Paths from '../../../../../constants/Paths'
import SendDialog from '../04DialogDeleteAccount/SendDialogDeleteAccount'
import ApiPaths from '../../../../../constants/ApiPaths'
import AppContext from '../../../../../contexts/AppContext'
import Constants from '../ConstantsLightDeleteAccount'

const ButtonLightDeleteAccount = () => {
  const history = useHistory()
  const { state } = useContext(AppContext)

  //Backボタンの遷移先を取得
  const getBackPath = () => {
    const userData = CommonFunc.getLoginUserData()
    let path = ''
    if (userData.shipperJhId !== null) {
      //荷主 (JHユーザーはこの画面に遷移しない)
      path = Paths.MASTER_SHIPPER.SHIP_COMPANY_INFOM
    } else if (userData.fwdrId !== null) {
      //混載業者
      path = Paths.MASTER_FWDR.LIGHT_FWDR_COMPANY_INFO
    } else if (userData.carrierId !== null) {
      //航空会社
      path = Paths.MASTER_AIRLINE.LIGHT_CAR_COMPANY_INFO
    }
    return path
  }

  //指定したQxのlengthを取得
  const getQxLength = (Qx) => {
    return Qx.check.length - 1
  }

  /**
   * DeleteAccountのリクエストデータを作成する
   * @returns {Object} リクエストデータ
   */
  const createDeleteAccountArr = () => {
    return {
      Q1: {
        check: state.Q1.check.map((check) =>
          check ? Constants.CHECK.Yes : Constants.CHECK.No
        ),
        text: state.Q1.check[getQxLength(state.Q1)] ? state.Q1.text : undefined,
      },
      Q2: {
        check: state.Q2.check.map((check) =>
          check && state.Q1.check[Constants.DISSATISFIED]
            ? Constants.CHECK.Yes
            : Constants.CHECK.No
        ),
        text:
          state.Q2.check[getQxLength(state.Q2)] &&
          state.Q1.check[Constants.DISSATISFIED]
            ? state.Q2.text
            : undefined,
      },
      Q3: {
        text: state.Q3.text,
      },
      Q4: parseInt(state.Q4.check),
    }
  }

  //Sendボタン押下時のイベント
  const onSend = async () => {
    const req = createDeleteAccountArr()

    // API実行
    const apiInfoArr = [
      {
        key: 'resData',
        method: 'post',
        path: ApiPaths.AUTHENTICATION.DELETE_ACCOUNT_REQUEST,
        req,
      },
    ]
    const resObj = await CommonFunc.execApiAsync(apiInfoArr)
    const isApiSuccessful = CommonFunc.checkApiResponseObj(resObj, history)
    if (isApiSuccessful) {
      CommonFunc.clickEvent(
        { path: Paths.NOTIFY.LIGHT_DELETE_ACCOUNT_DONE },
        history
      )
    }
  }

  useEffect(() => {}, [])

  const getDisabled = () => {
    const isQ1 =
      state.Q1.check.filter((check) => check).length === 0 || //Q1が1つも選択されていない
      // eslint-disable-next-line no-extra-parens
      (state.Q1.check[getQxLength(state.Q1)] && state.Q1.text === '') || //Q1でOtherが入力されていない
      state.Q1.errors === true //Otherにエラーが発生している

    const isQ2 =
      state.Q1.check[Constants.DISSATISFIED] && //Q1の2つ目の選択肢が選択されていることが前提
      (state.Q2.check.filter((check) => check).length === 0 || //Q2が1つも選択されていない
        // eslint-disable-next-line no-extra-parens
        (state.Q2.check[getQxLength(state.Q2)] && state.Q2.text === '') || //Q2でOtherが入力されていない
        state.Q2.errors === true) //Otherにエラーが発生している

    const isQ3 =
      state.Q3.text === '' || //Q3が入力されていない
      state.Q3.errors === true //エラーが発生している
    const isQ4 = parseInt(state.Q4.check) === 0 //Q4が選択されていない
    return isQ1 || isQ2 || isQ3 || isQ4
  }

  const sendFactors = {
    disabled: getDisabled(),
    group: state.FwdrGroup,
    sendEvent: onSend,
  }

  return (
    <Grid container justify="center" spacing={2}>
      <Grid item>
        <BackButton path={getBackPath()} />
      </Grid>
      <Grid item>
        <SendDialog sendFactors={sendFactors} />
      </Grid>
    </Grid>
  )
}

export default ButtonLightDeleteAccount
