import { GSA } from '../../actions'
import Common from '../../constants/Common'

/** @type{Boolean} GSA情報の初期値 */
const initGsa = Common.GSA_FLG.CARRIER

/**
 * state: GSA情報を保存
 * @param {Object} [state=initGsa] - state
 * @param {Object} action - action
 * @returns {Object} - state
 */
const Gsa = (state = initGsa, action) => {
  switch (action.type) {
    case GSA.SET:
      return action.gsa
    default:
      return state
  }
}

export default Gsa

export { initGsa }
