import { LIGHT_FLIGHT_MGMT } from '../../../actions'
import CommonCalc from '../../../components/common/function/CommonCalc'
import Common from '../../../constants/Common'

/**
 * FlightScheduleの初期値を作成する
 * @returns {Object} FlightScheduleの初期値
 */
const createFlightScheduleData = () => ({
  hideFlg: Common.HIDE_FLG.OFF,
  day: null,
  etd: '',
  eta: '',
  add: true,
  setOpen: () => {},
})

/**
 * Routingの初期値を作成する
 * @returns {Object} Routingの初期値
 */
const createRoutingData = () => ({
  dstId: null,
  flightNumber: '',
  flightScheduleArr: [createFlightScheduleData()],
  flightTime: '',
  orgId: null,
  regNumber: '',
  timeId: null,
})

/**
 * FlightInfoの初期値を作成する
 * @param {Boolean} gsa GSAフラグ
 * @returns {Object} FlightInfoの初期値
 */
const createInitData = (gsa) => ({
  effectivedateStart: null,
  effectivedateEnd: null,
  hide: 0,
  iconObj: gsa ? { carrierNameId: null, iconPath: '' } : null,
  routingArr: [createRoutingData()],
})

/**
 * Etaを取得する
 * @param {String} flightTime flightTimeの入力値
 * @param {String} etd ETDの入力値
 * @returns {String} eta ETAに表示する値
 */
const getEta = (flightTime, etd) => {
  // 未入力がある場合は早期return
  if (flightTime === '' || etd === '') {
    return ''
  }

  /** @type {Date} flightTimeから作成した日付 */
  const flightTimeDate = new Date(`1970-01-01 ${flightTime}`)
  /** @type {Date} Etdから作成した日付 */
  const etaDate = new Date(`1970-01-01 ${etd}`)

  try {
    // etaを計算
    etaDate.setHours(etaDate.getHours() + flightTimeDate.getHours())
    etaDate.setMinutes(etaDate.getMinutes() + flightTimeDate.getMinutes())
  } catch (error) {
    // Dateに変換できなかったときは空文字を返す
    return ''
  }

  /** @type {String} 時間を0埋めした値 */
  const hours = CommonCalc.zeroPadding(etaDate.getHours(), 2)

  /** @type {String} 分を0埋めした値 */
  const minutes = CommonCalc.zeroPadding(etaDate.getMinutes(), 2)

  /** @type {Boolean} 計算のエラー有無 */
  const isCalcError = isNaN(etaDate.getHours()) || isNaN(etaDate.getMinutes())

  return isCalcError ? '' : `${hours}:${minutes}`
}

/** @type {Object} ポップアップの初期データ */
const initPopup = {
  createInitData,
  getFlightInfoParam: (name) => createInitData()[name],
  getRoutingParam: (_, name) => createRoutingData()[name],
  getScheduleParam: (_, __, name) => createFlightScheduleData()[name],
  getEta,
  getIsInputError: () => false,
  editData: createInitData(false),
  add: true,
}

/**
 * 入力状態を取得
 * @param {Object} editData 編集したデータ
 * @returns {Boolean} 入力エラー・未入力の有無
 */
const getIsInputError = (editData) => {
  const { routingArr, ...flightInfoData } = editData
  const { effectivedateStart, effectivedateEnd, flightNumber, iconObj } =
    flightInfoData

  /**
   * NULLまたは未入力欄があるか判定
   * @param {Array} array 判定する値の配列
   * @returns {Boolean} NULLまたは未入力欄の有無
   */
  const getHasNullOrEmpty = (array) =>
    array.includes(null) || array.includes('')

  /** @type {Number|Boolean} 航空会社名ID */
  const carrierNameId = iconObj === null ? true : iconObj.carrierNameId

  /** @type {Boolean} flightInfoの入力状態 */
  const flightInfoError = getHasNullOrEmpty([
    effectivedateStart,
    effectivedateEnd,
    flightNumber,
    carrierNameId,
  ])

  /** @type {Boolean} routing欄の入力状態 */
  const routingError = routingArr
    .map((routing, index) => {
      const {
        dstId,
        timeId,
        flightTime,
        flightNumber,
        regNumber,
        flightScheduleArr,
      } = routing
      /** @type {Number} ORGID */
      const orgId = index === 0 ? routing.orgId : routingArr[index - 1].dstId

      const hasNullOrEmpty = getHasNullOrEmpty([
        orgId,
        dstId,
        timeId,
        flightTime,
        flightNumber,
        regNumber,
      ])

      /** @type {Boolean} flightScheduleが1つ以上入力されているか */
      const flightSchedule = flightScheduleArr.find(
        ({ day, etd, eta, hideFlg }) => {
          return !getHasNullOrEmpty([day, etd, eta]) && !hideFlg
        }
      )

      return hasNullOrEmpty || !flightSchedule
    })
    .includes(true)

  /** @type {Object[]} flightScheduleの配列 */
  const flightScheduleArr = routingArr
    .map(({ flightScheduleArr }) => flightScheduleArr)
    .flat()

  /** @type {Boolean} flightScheduleの入力状態 */
  const flightScheduleError = flightScheduleArr
    .map((flightSchedule) => {
      const { day, etd, eta, error } = flightSchedule
      // 全て未入力
      const isAllEmpty = !day && etd === '' && eta === ''
      // 全て未入力かつETDでバリデーションエラーが発生していない
      const isAllEmptyAndNoError = isAllEmpty && !error
      // D1~D7欄とETDが両方未入力の場合はOK
      return isAllEmptyAndNoError ? false : getHasNullOrEmpty([day, etd, eta])
    })
    .includes(true)

  return flightInfoError || routingError || flightScheduleError
}

/**
 * ポップアップの編集データを初期化する
 * @param {Object} action targetDataとadd
 * @param {Object} action.targetData 編集対象データ
 * @param {Boolean} [action.add=false] 追加フラグ
 * @param {Object} state state
 * @returns {Object} state
 */
const initEditData = (action, state) => {
  const { targetData, add = false } = action
  /** @type {Object} 編集対象データのコピー */
  const editData = Object.assign({}, JSON.parse(JSON.stringify(targetData)))

  // ポップアップ表示用にデータを整形
  editData.routingArr.map(({ flightNumber }, index) => {
    // FlightNumberから2桁コードを削除
    editData.routingArr[index].flightNumber = flightNumber.slice(2)
    return null
  })

  /**
   * Routing内のデータを取得する
   * @param {Number} routingIndex Routingのインデックス
   * @param {String} name 取得するデータ名
   * @returns {*} nameで指定したデータ
   */
  const getRoutingParam = (routingIndex, name) =>
    editData.routingArr[routingIndex][name]

  /**
   * FlightSchedule内のデータを取得する
   * @param {Number} routingIndex Routingのインデックス
   * @param {Number} index FlightScheduleのインデックス
   * @param {String} name 取得するデータ名
   * @returns {*} nameで指定したデータ
   */
  const getScheduleParam = (routingIndex, index, name) =>
    editData.routingArr[routingIndex].flightScheduleArr[index][name]

  /**
   * FlightInfo内のデータを取得する
   * @param {String} name 取得するデータ名
   * @returns {*} nameで指定したデータ
   */
  const getFlightInfoParam = (name) => editData[name]

  return {
    ...state,
    editData,
    add,
    getFlightInfoParam,
    getRoutingParam,
    getScheduleParam,
    getIsInputError: () => getIsInputError(editData),
  }
}

/**
 * FlightInfoのデータを編集する
 * @param {Object} action 編集したいデータ
 * @param {Object} state stateの値
 * @returns {Object} state
 */
const editFlightInfo = (action, state) => {
  const { editData } = state
  // 編集する
  Object.keys(action).map((key) => (editData[key] = action[key]))
  return { ...state, editData }
}

/**
 * Routingのデータを編集する
 * @param {Object} action 編集したいデータ
 * @param {Number} action.index Routingのインデックス
 * @param {Object} state state
 * @returns {Object} state
 */
const editRouting = (action, state) => {
  const { index, ...rest } = action
  const { editData } = state
  // 編集する
  Object.keys(rest).map((key) => (editData.routingArr[index][key] = rest[key]))
  return { ...state, editData }
}

/**
 * FlightScheduleのデータを編集する
 * @param {Object} action 編集したいデータ
 * @param {Number} action.routingIndex Routingのインデックス
 * @param {Number} action.index FlightScheduleのインデックス
 * @param {Object} state stateの値
 * @returns {Object} state
 */
const editFlightSchedule = (action, state) => {
  const { routingIndex, index, ...rest } = action
  const { editData } = state
  // 編集する
  Object.keys(rest).map(
    (key) =>
      (editData.routingArr[routingIndex].flightScheduleArr[index][key] =
        rest[key])
  )
  return { ...state, editData }
}

/**
 * Routingにデータを追加する
 * @param {Object} state stateの値
 * @returns {Object} state
 */
const addRouting = (state) => {
  const { editData } = state
  // Routingに新規データを追加
  editData.routingArr.push(createRoutingData())
  return { ...state, editData }
}

/**
 * FlightScheduleにデータを追加する
 * @param {Object} action action
 * @param {Number} action.routingIndex Routingのインデックス
 * @param {Object} state stateの値
 * @returns {Object} state
 */
const addFlightSchedule = (action, state) => {
  const { editData } = state
  const { routingIndex } = action
  // FlightScheduleに新規データを追加
  editData.routingArr[routingIndex].flightScheduleArr.push(
    createFlightScheduleData()
  )
  return { ...state, editData }
}

/**
 * FlightInfoポップアップを開く関数を登録
 * @param {Object} action action
 * @param {Function} action.setOpen ポップアップを開く関数
 * @param {Object} state state
 * @returns {Object} state
 */
const setOpenFunc = (action, state) => {
  const { setOpen } = action
  return { ...state, setOpen }
}

/**
 * FlightInfoポップアップの編集データを保持
 * @param { Object } [state = initPopup] state
 * @param { Object } action action
 * @return { Object } state
 */
const Popup = (state = initPopup, action) => {
  const { type, ...rest } = action
  switch (type) {
    case LIGHT_FLIGHT_MGMT.EDIT.INIT:
      return initEditData(rest, state)
    case LIGHT_FLIGHT_MGMT.EDIT.FLIGHT_INFO:
      return editFlightInfo(rest, state)
    case LIGHT_FLIGHT_MGMT.EDIT.ROUTING:
      return editRouting(rest, state)
    case LIGHT_FLIGHT_MGMT.EDIT.FLIGHT_SCHEDULE:
      return editFlightSchedule(rest, state)
    case LIGHT_FLIGHT_MGMT.ADD.ROUTING:
      return addRouting(state)
    case LIGHT_FLIGHT_MGMT.ADD.FLIGHT_SCHEDULE:
      return addFlightSchedule(rest, state)
    case LIGHT_FLIGHT_MGMT.TABLES.SET_OPEN:
      return setOpenFunc(action, state)
    default:
      return state
  }
}

export default Popup

export { initPopup }
