import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  contents: {
    ...theme.textNormalBLK,
    ...theme.wordBreak,
    ...theme.whiteSpaceLeft,
  },
}))

/**
 * @param {String} text 退会理由自由記入テキスト
 * @return {JSX} 退会理由自由記入テキストコンポーネント
 */
const WithdrawalTextAnswerLightJhClientRegDeleteAccount = ({ text }) => {
  const classes = useStyles()

  return (
    <Grid container item justify="flex-end">
      <Grid item xs={11} className={classes.contents}>
        {text || '-'}
      </Grid>
    </Grid>
  )
}

WithdrawalTextAnswerLightJhClientRegDeleteAccount.propTypes = {
  text: PropTypes.string,
}

export default WithdrawalTextAnswerLightJhClientRegDeleteAccount
