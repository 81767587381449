import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  title: {
    ...theme.textBoldBLU,
    ...theme.wordBreak,
  },
}))

/**
 * @param {String} titleKey 退会理由タイトルキー
 * @return {JSX} 退会理由タイトルコンポーネント
 */
const WithdrawalTitleLightJhClientRegDeleteAccount = ({ titleKey }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid item xs={12} className={classes.title}>
      {t(titleKey)}
    </Grid>
  )
}

WithdrawalTitleLightJhClientRegDeleteAccount.propTypes = {
  titleKey: PropTypes.string.isRequired,
}

export default WithdrawalTitleLightJhClientRegDeleteAccount
