import { Grid } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  headerText: {
    ...theme.center,
    ...theme.textNormalBLU,
  },
}))

/**
 * @returns {JSX} FlightScheduleの見出し部分
 */
const FlightScheduleHeader = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <>
      <Grid item md={2} />
      <Grid item md={3} />
      <Grid item md={3}>
        <div className={classes.headerText}>{t('D301V0148')}</div>
      </Grid>
      <Grid item md={3}>
        <div className={classes.headerText}>{t('D301V0147')}</div>
      </Grid>
    </>
  )
}
export default FlightScheduleHeader
