import { TABLES } from '../../../actions'
import CommonFunc from '../../../components/common/function/CommonFunc'
import Common from '../../../constants/Common'
import ConstantsBidDetails from '../../../components/pages/fwdr/fwdrBidDetails/ConstantsBidDetails'
import CommonCalc from '../../../components/common/function/CommonCalc'

/** 初期表示ページ */
const initTablesBidDetails = {
  // APIの元のデータを保持
  apiDataArr: [],
  // 表示用のデータを保持
  showData: [[]],
  editData: [],
}

/**
 * 表示用データを作成
 * @param {Object} bidDataObj 入札のAPIデータ
 * @param {Number} apiIndexNum APIの番号
 * @param {Boolean} isNew 新規入札の場合True
 * @return {Object} 表示用データ
 */
const createDisplayObj = (bidDataObj, apiIndexNum, isNew) => {
  const {
    airlineStr,
    commStr,
    currencyStr,
    dstStr,
    flightTypeStr,
    forwarderStr,
    fsc,
    large,
    leadTimeStr,
    light,
    max,
    medium,
    min,
    minrate,
    normal,
    orgStr,
    remark,
    statusStr,
    termsStr,
    transitStr,
    ttStr,
    ttlMaxVol,
    ttlMaxWgt,
    ttlMinVol,
    ttlMinWgt,
  } = bidDataObj

  //ttlWgtとttlVolを修正
  const ttlVol = `${CommonCalc.addComma(ttlMinVol, true)} m3 - ${CommonCalc.addComma(ttlMaxVol, true)} m3`
  const ttlWgt = `${CommonCalc.addComma(ttlMinWgt, true)} kg - ${CommonCalc.addComma(ttlMaxWgt, true)} kg`

  //apiIndexNumから検索用のNo欄の文字列を取得
  const apiIndexNumStr = `${apiIndexNum + 1}.`

  //各レートにカンマを付ける
  const numberObj = { fsc, minrate, normal, min, light, medium, large, max }
  const addedCommaNumberObj = {}
  Object.keys(numberObj).map((key) => {
    addedCommaNumberObj[`${key}Str`] = isNew
      ? numberObj[key]
      : CommonCalc.addComma(numberObj[key], true)
    return null
  })

  return {
    apiIndexNum,
    apiIndexNumStr,
    airlineStr,
    commStr,
    currencyStr,
    dstStr,
    flightTypeStr,
    forwarderStr,
    leadTimeStr,
    orgStr,
    remark,
    statusStr,
    termsStr,
    transitStr,
    ttStr,
    ttlVol,
    ttlWgt,
    ...addedCommaNumberObj,
  }
}

/**
 * 定数のソート一覧からソートに必要なデータを抜き出して作成
 * @param {Object} bidDataObj 入札のAPIデータ
 * @return {Object} ソート用データ
 */
const createSortObj = (bidDataObj) => {
  const sortArr = ConstantsBidDetails.SORT_ITEM_ARR.map(({ sortKey }) => {
    const apiVal = bidDataObj[sortKey]
    return [sortKey, apiVal]
  })
  return Object.fromEntries(sortArr)
}

/**
 * 表示用のデータに変換
 * @param {Array} apiDataArr 入札のAPIデータ
 * @param {Boolean} isNew 新規入札の場合True
 * @returns {Array} 表示、並び替え用情報配列
 */
const convertShowCargoDataArr = (apiDataArr, isNew) =>
  apiDataArr.map((bidDataObj, apiIndexNum) => {
    const displayObj = createDisplayObj(bidDataObj, apiIndexNum, isNew)
    const sortObj = createSortObj(bidDataObj)
    delete sortObj.statusStr

    return {
      // 表示用
      ...displayObj,
      // 並び替え用
      ...sortObj,
      // API特定用番号
      apiIndexNum: bidDataObj.apiIndexNum ?? apiIndexNum,
    }
  })

/**
 *
 * @param {Array} apiDataArr 入札のAPIデータ
 * @param {String} search 検索ワード
 * @param {String} sort ソート
 * @param {Boolean} [isNew=false] 新規入札の場合True
 * @return {Object} 更新したTablesBidDetailsのstate
 */
const createCommonTableDataObj = (apiDataArr, search, sort, isNew = false) => {
  // sort名から並び替えの対象となるkeyを取得
  const sortDataName = ConstantsBidDetails.SORT_ITEM_ARR.find(
    ({ sortTerm }) => sortTerm === sort
  ).sortKey
  // 検索、並び替え用にAPIの値を変換した後表示用データを作成
  const showCargoDataArr = convertShowCargoDataArr(apiDataArr, isNew)
  const { showData } = CommonFunc.searchSortData(
    showCargoDataArr,
    search,
    sort,
    sortDataName,
    ConstantsBidDetails.PAGE_AMOUNT
  )

  const editData = apiDataArr.map((data, index) => ({
    ...data,
    apiIndexNum: index,
    fscStr: CommonCalc.addComma(data.fsc, true),
    minrateStr: CommonCalc.addComma(data.minrate, true),
    normalStr: CommonCalc.addComma(data.normal, true),
    minStr: CommonCalc.addComma(data.min, true),
    lightStr: CommonCalc.addComma(data.light, true),
    mediumStr: CommonCalc.addComma(data.medium, true),
    largeStr: CommonCalc.addComma(data.large, true),
    maxStr: CommonCalc.addComma(data.max, true),
  }))

  // 表示用データとAPIの値全部と必須に分けて保持
  return {
    // 表示用データ
    showData,
    // APIデータ
    apiDataArr,
    // 編集用データ
    editData,
  }
}

/**
 * TablesBidDetailsのstateの初期設定を作成
 * @param {Object} action dispatchで受け取ったaction
 * @return {Object} 初期設定のTablesBidDetailsのstate
 */
const createInitTableDataObj = (action) => {
  const { data, sort, isNew } = action
  const { showData, apiDataArr, editData } = createCommonTableDataObj(
    data,
    '',
    sort,
    isNew
  )

  // 表示用データとAPIの値を全部と必須に分けて保持
  return {
    // 表示用データ
    showData,
    // APIデータ
    apiDataArr,
    // 編集用データ
    editData,
  }
}

/**
 * 変更された検索ワード、ソートからTablesBidDetailsのstateを作成
 * @param {Object} action dispatchで受け取ったaction
 * @param {Object} state TablesBidDetailsのstate
 * @return {Object} 表示のみ更新したTablesBidDetailsのstate
 */
const createSetTableDataObj = (action, state) => {
  const { search, sort } = action
  const { editData } = state
  const { showData } = createCommonTableDataObj(editData, search, sort, false)

  // 表示用データとAPIの値を全部と必須に分けて保持
  return {
    ...state,
    // 表示用データ
    showData,
  }
}

/**
 * 編集時の処理
 * @param {Object} action dispatchで受け取ったaction
 * @param {Object} state TablesBidDetailsのstate
 * @return {Object} 表示のみ更新したTablesBidDetailsのstate
 */
const editTableDataObj = (action, state) => {
  const {
    index,
    cellKey,
    id = undefined,
    str = undefined,
    value = undefined,
    checked = undefined,
  } = action

  //選択肢のidを更新
  if (id !== undefined) {
    state.editData[index][cellKey] = id
  }
  //選択肢の文字列を更新
  if (str !== undefined) {
    const strCellKey = `${cellKey.split('Id')[0]}Str`
    state.editData[index][strCellKey] = str
  }
  //テキストを更新
  if (value !== undefined) {
    state.editData[index][cellKey] = value
  }
  //Selectを更新
  if (checked !== undefined) {
    if (checked) {
      //チェックを入れた入札をNAにする
      state.editData[index][cellKey] = Common.BID_STATUS.NA.TEXT
    } else {
      //チェックを外した入札をUPDATEにする
      state.editData[index][cellKey] = Common.BID_STATUS.UPDATE.TEXT
    }
  }

  return { ...state }
}

/**
 * 表データを保持
 * @param {Object} [state=initTablesBidDetails] 表データ
 * @param {Object} action 表データの検索に必要な値
 * @return {Object} 表データ
 */
const TablesBidDetails = (state = initTablesBidDetails, action) => {
  switch (action.type) {
    case TABLES.INIT:
      return createInitTableDataObj(action)
    case TABLES.SET:
      return createSetTableDataObj(action, state)
    case TABLES.EDIT:
      return editTableDataObj(action, state)
    default:
      return state
  }
}

export default TablesBidDetails

export { initTablesBidDetails }
