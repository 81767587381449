import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'

import HeaderTextRequired from '../../../../common/text/HeaderTextRequired'

/**
 * @return {JSX} Validityヘッダーコンポーネント
 */
const HeaderValidityBidInfo = () => {
  const { t } = useTranslation()
  return (
    <Grid item md={12} xs={12}>
      <HeaderTextRequired>{t('3rd0007')}</HeaderTextRequired>
    </Grid>
  )
}

export default HeaderValidityBidInfo
