import { LIGHT_FLIGHT_MGMT } from '../../../actions'

/** @type {Object} ポップアップの初期データ */
const initIconPopup = {
  setOpen: () => {},
  editData: {},
  noIcon: true,
}

/**
 * ポップアップを開く関数を登録する
 * @param {Object} action action
 * @param {Function} action.setOpen ポップアップを開く関数
 * @param {Object} state state
 * @returns {Object} state
 */
const setOpenFunc = (action, state) => {
  const { setOpen } = action
  return { ...state, setOpen }
}

/**
 * ポップアップの編集データを初期化する
 * @param {Object} action action
 * @param {Object} action.iconObj 編集するiconObj
 * @param {Object} state state
 * @returns {Object} state
 */
const initEditData = (action, state) => {
  const { iconObj } = action
  /** @type {Object} 編集対象データのコピー */
  const editData = Object.assign({}, JSON.parse(JSON.stringify(iconObj)))

  return { ...state, editData, noIcon: editData.iconPath === '' }
}

/**
 * ポップアップのデータを編集する
 * @param {*} action 編集したいデータ
 * @param {*} state stateの値
 * @returns {Object} state
 */
const editIconObj = (action, state) => {
  const { editData } = state
  // 編集する
  Object.keys(action).map((key) => (editData[key] = action[key]))
  return { ...state, editData, noIcon: editData.iconPath === '' }
}

/**
 * アイコンポップアップの編集データを保持
 * @param { Object } [state = initIconPopup] state
 * @param { Object } action action
 * @return { Object } state
 */
const IconPopup = (state = initIconPopup, action) => {
  const { type, ...rest } = action
  switch (type) {
    case LIGHT_FLIGHT_MGMT.ICON_POPUP.SET_OPEN:
      return setOpenFunc(rest, state)
    case LIGHT_FLIGHT_MGMT.ICON_POPUP.SET:
      return initEditData(rest, state)
    case LIGHT_FLIGHT_MGMT.ICON_POPUP.EDIT:
      return editIconObj(rest, state)
    default:
      return state
  }
}

export default IconPopup

export { initIconPopup }
