import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import PageContent from './contexts/PageContent'
import './constants/Display'
import './index.scss'
import Paths from './constants/Paths'

//荷主
import ShipperTop from './components/pages/shipper/shipperTop/ShipperTop'
import ShipperBidMgmt from './components/pages/shipper/shipperBidMgmt/ShipperBidMgmt'
import ShipperBidInfo from './components/pages/shipper/shipperBidInfo/ShipperBidInfo'
import ShipperSelectForwarder from './components/pages/shipper/shipperSelectForwarder/ShipperSelectForwarder'
import ShipperBidDetails from './components/pages/shipper/shipperBidDetails/ShipperBidDetails'
import ShipperConfirmation from './components/pages/shipper/shipperConfirmation/ShipperConfirmation'

//混載業者
import FwdrTop from './components/pages/fwdr/fwdrTop/FwdrTop'
import FwdrBidMgmt from './components/pages/fwdr/fwdrBidMgmt/FwdrBidMgmt'
import FwdrBidInfo from './components/pages/fwdr/fwdrBidInfo/FwdrBidInfo'
import FwdrBidDetails from './components/pages/fwdr/fwdrBidDetails/FwdrBidDetails'
import FwdrConfirmation from './components/pages/fwdr/fwdrConfirmation/FwdrConfirmation'
import LightFwdrBookingList from './components/pages/fwdr/LightFwdrBookingList/LightFwdrBookingList'
import LightFwdrBookingDetails from './components/pages/fwdr/lightFwdrBookingDetails/LightFwdrBookingDetails'
import LightFwdrBookingRequest from './components/pages/fwdr/lightFwdrBookingRequest/LightFwdrBookingRequest'
import LightFwdrSearchResult from './components/pages/fwdr/LightFwdrSearchResult/LightFwdrSearchResult'

//航空会社
import AirlineTop from './components/pages/airline/airlineTop/AirlineTop'
import LightAirlineBookingList from './components/pages/airline/LightAirlineBookingList/LightAirlineBookingList'
import LightAirlineBookingDetails from './components/pages/airline/lightAirlineBookingDetails/LightAirlineBookingDetails'

// マスター画面
import MasterJHCompanyInfoG from './components/pages/master/masterJhCompanyInfoG/MasterJHCompanyInfoG'
import MasterJHCompanyInfoM from './components/pages/master/masterJhCompanyInfoM/MasterJHCompanyInfoM'
import MasterJHMyProfile from './components/pages/master/masterJhMyProfile/MasterJHMyProfile'
import MasterJHAccountInfo from './components/pages/master/masterJhAccountInfo/MasterJHAccountInfo'
import MasterShipCompanyInfoG from './components/pages/master/masterShipCompanyInfoG/MasterShipCompanyInfoG'
import MasterShipCompanyInfoM from './components/pages/master/masterShipCompanyInfoM/MasterShipCompanyInfoM'
import MasterShipMyProfile from './components/pages/master/masterShipMyProfile/MasterShipMyProfile'
import MasterShipMasterTop from './components/pages/master/masterShipMasterTop/MasterShipMasterTop'
import JhTop from './components/pages/master/jhTop/JhTop'
import JhClientReg from './components/pages/master/JhClientReg/JhClientReg'
import LightJhClientRegSignUp from './components/pages/master/lightJhClientRegSignUp/LightJhClientRegSignUp'
import LightJhClientRegDeleteAccount from './components/pages/master/lightJhClientRegDeleteAccount/LightJhClientRegDeleteAccount'
import MasterShipConsigneeInfo from './components/pages/master/masterShipConsigneeInfo/MasterShipConsigneeInfo'
import MasterShipAccountInfo from './components/pages/master/masterShipAccountInfo/MasterShipAccountInfo'
import MasterFwdrMasterTop from './components/pages/master/masterFwdrMasterTop/MasterFwdrMasterTop'
import MasterCarMasterTop from './components/pages/master/masterCarMasterTop/MasterCarMasterTop'
import MasterJhMasterTop from './components/pages/master/masterJhMasterTop/MasterJhMasterTop'
import LightCarFlightMgmt from './components/pages/master/LightCarFlightMgmt/LightCarFlightMgmt'
import LightCarSelectForwarder from './components/pages/master/LightCarSelectForwarder/LightCarSelectForwarder'
import LightCarAirFreight from './components/pages/master/LightCarAirFreight/LightCarAirFreight'
import LightFwdrCompanyInfo from './components/pages/master/LightFwdrCompanyInfo/LightFwdrCompanyInfo'
import LightFwdrAccountInfo from './components/pages/master/LightFwdrAccountInfo/LightFwdrAccountInfo'
import LightFwdrMyProfile from './components/pages/master/LightFwdrMyProfile/LightFwdrMyProfile'
import LightJhClientList from './components/pages/master/LightJhClientList/LightJhClientList'
import LightCarCompanyInfo from './components/pages/master/LightCarCompanyInfo/LightCarCompanyInfo'
import LightCarMyProfile from './components/pages/master/LightCarMyProfile/LightCarMyProfile'
import LightCarAccountInfo from './components/pages/master/LightCarAccountInfo/LightCarAccountInfo'

//通知
import NotifyList from './components/pages/notify/notifyList/NotifyList'
import NotificationDetails from './components/pages/notify/notificationDetails/NotificationDetails'

// 共通
import SignIn from './components/pages/authentication/SignIn/SignIn'
import PasswordReset from './components/pages/authentication/PasswordReset/PasswordReset'
import ReissuePassword from './components/pages/authentication/ReissuePassword/ReissuePassword'
import ReissuePasswordSent from './components/pages/authentication/ReissuePasswordSent/ReissuePasswordSent'
import LightShipperRegisterNewAccount from './components/pages/authentication/LightShipperRegisterNewAccount/LightShipperRegisterNewAccount'
import LightFwdrRegisterNewAccount from './components/pages/authentication/LightFwdrRegisterNewAccount/LightFwdrRegisterNewAccount'
import LightAirlineRegisterNewAccount from './components/pages/authentication/LightAirlineRegisterNewAccount/LightAirlineRegisterNewAccount'
import LightDeleteAccount from './components/pages/authentication/LightDeleteAccount/LightDeleteAccount'
import LightDeleteAccountDone from './components/pages/authentication/LightDeleteAccountDone/LightDeleteAccountDone'

// エラーページ
import Error from './components/pages/others/error/Error'

// メンテナンスページ
import Maintenance from './components/pages/others/maintenance/Maintenance'

ReactDOM.createRoot(document.getElementById('root')).render(
  <BrowserRouter>
    <Switch>
      <PageContent>
        <Route exact path="/" component={SignIn} />

        {/* 荷主 */}
        <Route path={Paths.SHIPPER.TOP} component={ShipperTop} />
        <Route path={Paths.SHIPPER.BID_MGMT} component={ShipperBidMgmt} />
        <Route path={Paths.SHIPPER.BID_INFO} component={ShipperBidInfo} />
        <Route
          path={Paths.SHIPPER.SELECT_FORWARDER}
          component={ShipperSelectForwarder}
        />
        <Route path={Paths.SHIPPER.BID_DETAILS} component={ShipperBidDetails} />
        <Route
          path={Paths.SHIPPER.CONFIRMATION}
          component={ShipperConfirmation}
        />

        {/* 混載業者 */}
        <Route path={Paths.FWDR.TOP} component={FwdrTop} />
        <Route path={Paths.FWDR.BID_MGMT} component={FwdrBidMgmt} />
        <Route path={Paths.FWDR.BID_INFO} component={FwdrBidInfo} />
        <Route path={Paths.FWDR.BID_DETAILS} component={FwdrBidDetails} />
        <Route path={Paths.FWDR.CONFIRMATION} component={FwdrConfirmation} />
        <Route
          path={Paths.FWDR.LIGHT_BOOKING_LIST}
          component={LightFwdrBookingList}
        />
        <Route
          path={Paths.FWDR.LIGHT_BOOKING_DETAILS}
          component={LightFwdrBookingDetails}
        />
        <Route
          path={Paths.FWDR.LIGHT_BOOKING_REQUEST}
          component={LightFwdrBookingRequest}
        />
        <Route
          path={Paths.FWDR.LIGHT_SEARCH_RESULT}
          component={LightFwdrSearchResult}
        />

        {/* 航空会社 */}
        <Route path={Paths.AIRLINE.TOP} component={AirlineTop} />
        <Route
          path={Paths.AIRLINE.LIGHT_BOOKING_LIST}
          component={LightAirlineBookingList}
        />
        <Route
          path={Paths.AIRLINE.LIGHT_BOOKING_DETAILS}
          component={LightAirlineBookingDetails}
        />

        {/* マスター */}
        <Route
          path={Paths.MASTER.JH_COMPANY_INFOG}
          component={MasterJHCompanyInfoG}
        />
        <Route
          path={Paths.MASTER.JH_COMPANY_INFOM}
          component={MasterJHCompanyInfoM}
        />
        <Route
          path={Paths.MASTER.JH_MY_PROFILE}
          component={MasterJHMyProfile}
        />
        <Route
          path={Paths.MASTER.JH_ACCOUNT_INFO}
          component={MasterJHAccountInfo}
        />
        <Route
          path={Paths.MASTER.SHIP_COMPANY_INFOG}
          component={MasterShipCompanyInfoG}
        />
        <Route
          path={Paths.MASTER.SHIP_COMPANY_INFOM}
          component={MasterShipCompanyInfoM}
        />
        <Route
          path={Paths.MASTER.SHIP_MY_PROFILE}
          component={MasterShipMyProfile}
        />
        <Route
          path={Paths.MASTER.SHIP_MASTER_TOP}
          component={MasterShipMasterTop}
        />
        <Route path={Paths.MASTER.JH_TOP} component={JhTop} />
        <Route path={Paths.MASTER.JH_CLIENT_REG} component={JhClientReg} />
        <Route
          path={Paths.MASTER.LIGHT_JH_CLIENT_REG_SIGNUP}
          component={LightJhClientRegSignUp}
        />
        <Route
          path={Paths.MASTER.LIGHT_JH_CLIENT_REG_DELETE_ACCOUNT}
          component={LightJhClientRegDeleteAccount}
        />
        <Route
          path={Paths.MASTER.SHIP_CONSIGNEE_INFO}
          component={MasterShipConsigneeInfo}
        />
        <Route
          path={Paths.MASTER.SHIP_ACCOUNT_INFO}
          component={MasterShipAccountInfo}
        />
        <Route
          path={Paths.MASTER.FWDR_MASTER_TOP}
          component={MasterFwdrMasterTop}
        />
        <Route
          path={Paths.MASTER.CAR_MASTER_TOP}
          component={MasterCarMasterTop}
        />
        <Route
          path={Paths.MASTER.JH_MASTER_TOP}
          component={MasterJhMasterTop}
        />
        <Route
          path={Paths.MASTER.LIGHT_CAR_SELECT_FWDR}
          component={LightCarSelectForwarder}
        />
        <Route
          path={Paths.MASTER.LIGHT_CAR_FLIGHT_MGMT}
          component={LightCarFlightMgmt}
        />
        <Route
          path={Paths.MASTER.LIGHT_CAR_AIR_FREIGHT}
          component={LightCarAirFreight}
        />
        <Route
          path={Paths.MASTER.LIGHT_FWDR_COMPANY_INFO}
          component={LightFwdrCompanyInfo}
        />
        <Route
          path={Paths.MASTER.LIGHT_FWDR_ACCOUNT_INFO}
          component={LightFwdrAccountInfo}
        />
        <Route
          path={Paths.MASTER.LIGHT_FWDR_MY_PROFILE}
          component={LightFwdrMyProfile}
        />
        <Route
          path={Paths.MASTER.JH_LIGHT_CLIENT_LIST}
          component={LightJhClientList}
        />
        <Route
          path={Paths.MASTER.LIGHT_CAR_COMPANY_INFO}
          component={LightCarCompanyInfo}
        />
        <Route
          path={Paths.MASTER.LIGHT_CAR_MY_PROFILE}
          component={LightCarMyProfile}
        />
        <Route
          path={Paths.MASTER.LIGHT_CAR_ACCOUNT_INFO}
          component={LightCarAccountInfo}
        />

        {/* 通知 */}
        <Route path={Paths.NOTIFY.NOTIFY_LIST} component={NotifyList} />
        <Route
          path={Paths.NOTIFY.NOTIFICATION_DETAILS}
          component={NotificationDetails}
        />

        {/* 共通 */}
        <Route path={Paths.AUTHENTICATION.SIGN_IN} component={SignIn} />
        <Route
          path={Paths.AUTHENTICATION.PASSWORD_RESET}
          component={PasswordReset}
        />
        <Route
          path={Paths.AUTHENTICATION.REISSUE_PASSWORD}
          component={ReissuePassword}
        />
        <Route
          path={Paths.AUTHENTICATION.REISSUE_PASSWORD_SENT}
          component={ReissuePasswordSent}
        />
        <Route
          path={Paths.AUTHENTICATION.LIGHT_SHIP_REGISTER_NEW_ACCOUNT}
          component={LightShipperRegisterNewAccount}
        />
        <Route
          path={Paths.AUTHENTICATION.LIGHT_FWDR_REGISTER_NEW_ACCOUNT}
          component={LightFwdrRegisterNewAccount}
        />
        <Route
          path={Paths.AUTHENTICATION.LIGHT_AIRLINE_REGISTER_NEW_ACCOUNT}
          component={LightAirlineRegisterNewAccount}
        />
        <Route
          path={Paths.NOTIFY.LIGHT_DELETE_ACCOUNT}
          component={LightDeleteAccount}
        />
        <Route
          path={Paths.NOTIFY.LIGHT_DELETE_ACCOUNT_DONE}
          component={LightDeleteAccountDone}
        />

        {/* エラーページ */}
        <Route path={Paths.OTHERS.ERROR} component={Error} />

        {/* メンテナンスページ */}
        <Route path={Paths.OTHERS.MAINTENANCE} component={Maintenance} />
      </PageContent>
    </Switch>
  </BrowserRouter>
)
