/* eslint-disable prefer-template */
import React from 'react'
import MTableCell from '@material-ui/core/TableCell'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  tableCell: theme.tableCell,
}))

/**
 * @param {Object} props - 以下
 * @param {useStyles} className - スタイルを指定
 * @returns {JSX.Element} - TableCell
 */
const TableCell = (props) => {
  const classes = useStyles()
  // eslint-disable-next-line no-unused-vars
  const { className, ...rest } = props
  return (
    <MTableCell className={classes.tableCell + ' ' + props.className} {...rest}>
      {props.children}
    </MTableCell>
  )
}

export default TableCell
