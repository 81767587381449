import React from 'react'
import { useTranslation } from 'react-i18next'

import ReissueTitle from '../../../common/title/ReissueTitle'

const Title = () => {
  const { t } = useTranslation()

  return <ReissueTitle>{t('D501V0063')}</ReissueTitle>
}

export default Title
