import { combineReducers } from 'redux'

import ShippingDetails, { initShippingDetails } from './ShippingDetails'
import SelectBox, { initSelectBox } from './SelectBox'
import cargoDetailArr, { initTableData } from './Tables'
import Airport, { initAirport } from '../../common/Airport'

const initialState = {
  ShippingDetails: initShippingDetails,
  SelectBox: initSelectBox,
  cargoDetailArr: initTableData,
  Airport: initAirport,
}

export default combineReducers({
  ShippingDetails,
  SelectBox,
  cargoDetailArr,
  Airport,
})

export { initialState }
