import { combineReducers } from 'redux'

import companyinfo from './CompanyInfo'
import backup from './Backup'
import file from './File'

// すべてのreducerをここでまとめる
export default combineReducers({
  companyinfo,
  backup,
  file,
})
