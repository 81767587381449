/* eslint-disable prefer-template */
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.buttonSize.width.xsmall,
    height: theme.buttonSize.height.xsmall,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primary.main,
    fontSize: theme.fontSize.header,
    backgroundColor: theme.palette.info.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.fontColor.secondary,
    },
    '&:active': {
      backgroundColor: theme.palette.primary.light,
    },
    boxShadow: 'none',
    borderRadius: 25,
    border: '1px solid #595959',
    boxSizing: 'border-box',
    whiteSpace: 'nowrap',
    padding: '0px',
    textTransform: 'none',
  },
}))

const DeleteButton = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <Button
      variant="contained"
      disabled={props.disabled}
      name={props.name}
      className={classes.root + ' ' + props.addclass}
      onClick={props.onClick}
    >
      {t('D103V0072')}
    </Button>
  )
}

export default DeleteButton
