/**
 * @typedef {Object} DayObj 曜日情報
 * @property {Number} day 曜日の値
 * @property {String} label 表示名
 */

/** @type {DayObj[]} 曜日の配列 */
const days = [
  { day: 1, label: 'D1' },
  { day: 2, label: 'D2' },
  { day: 3, label: 'D3' },
  { day: 4, label: 'D4' },
  { day: 5, label: 'D5' },
  { day: 6, label: 'D6' },
  { day: 7, label: 'D7' },
]

/**
 * 曜日情報を取得する
 * @param {Number} day 取得する曜日の値
 * @returns {DayObj} 曜日
 */
const getDay = (day) => days.find((v) => v.day === day) ?? null

/**
 * @type {Object} 初期値
 * @property {DayObj[]} days 曜日情報一覧
 * @property {Function} getDay 空港情報取得関数
 */
const initDay = {
  days,
  getDay,
}

/**
 * 曜日情報を返す
 * @returns {Object} 曜日情報
 */
const Day = () => {
  return initDay
}

export default Day

export { initDay }
