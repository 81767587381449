import React, { useContext, useEffect, useMemo, useState } from 'react'
import Grid from '@material-ui/core/Grid'

import AppContext from '../../../../contexts/AppContext'
import { LIGHT_BOOKING_DETAILS } from '../../../../actions'
import CommonDialog from '../../../common/dialog/commonDialog/CommonDialog'
import Common from '../../../../constants/Common'
import FlightPopupTextField from './FlightPopupTextField'

/**
 * @returns {JSX} インターライン編集ポップアップ
 */
const FlightPopup = () => {
  const { dispatch, state } = useContext(AppContext)
  const [open, setOpen] = useState(false)
  const { inputError, interLine, valueName } = state.FlightPopup.interLineObj

  // ポップアップを開く関数をreducerに登録
  useEffect(() => {
    dispatch({
      type: LIGHT_BOOKING_DETAILS.FLIGHT_POPUP.SET_OPEN_FUNC,
      setOpen,
    })
  }, [dispatch])

  // インターライン入力欄
  const interLineTextField = useMemo(
    () => <FlightPopupTextField dispatch={dispatch} defaultValue={interLine} />,
    [dispatch, interLine]
  )

  return useMemo(() => {
    /**
     * インターライン欄を登録する
     * @param {String} interLine 入力されたインターライン
     * @returns {void} なし
     */
    const registInterLine = (interLine) => {
      dispatch({
        type: LIGHT_BOOKING_DETAILS.FLIGHT.EDIT_INTER_LINE,
        [valueName]: interLine,
      })
      setOpen(false)
    }

    // ダイアログのボタン設定
    const dialogFuncObj = {
      [Common.DIALOG.BUTTON_CATEGORY.DELETE]: {
        onClick: () => registInterLine(''),
      },
      [Common.DIALOG.BUTTON_CATEGORY.CANCEL]: { onClick: () => setOpen(false) },
      [Common.DIALOG.BUTTON_CATEGORY.OK]: {
        onClick: () => registInterLine(interLine),
        disabled: inputError,
      },
    }
    return (
      <>
        <CommonDialog
          open={open}
          closeFunc={() => setOpen(false)}
          dialogFuncObj={dialogFuncObj}
        >
          <Grid
            container
            direction="row"
            justify="center"
            alignContent="center"
            alignItems="center"
            spacing={2}
          >
            {interLineTextField}
          </Grid>
        </CommonDialog>
      </>
    )
  }, [open, dispatch, inputError, interLine, valueName]) //eslint-disable-line
}
export default FlightPopup
