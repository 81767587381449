/* eslint-disable no-extra-parens */
/* eslint-disable prefer-template */
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import Paper from '@material-ui/core/Paper'
import TableRow from '@material-ui/core/TableRow'
import Link from '@material-ui/core/Link'

import XsmallButton from '../../../common/buttons/XsmallButton'
import ApiPaths from '../../../../constants/ApiPaths'
import CommonFunc from '../../../common/function/CommonFunc'
import DeleteDialog from '../../../common/dialog/DeleteDialog'
import Paths from '../../../../constants/Paths'
import restFacade from '../../../../actions/rest-facade'

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    borderRadius: '5px',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    marginBottom: '1rem',
  },
  table: {
    '& td': {
      borderBottomColor: theme.palette.primary.main,
    },
    '& td:first-child': {
      ...theme.textBoldBLU,
      borderRight: '1px solid',
      textAlign: 'center',
      width: '220px',
    },
    '& td:last-child': theme.textNormalBLK,
    '& tr:last-child td': {
      borderBottom: '0px solid',
    },
  },
  contents: {
    height: '400px',
  },
  blu: theme.textNormalBLU,
  underline: {
    textDecoration: 'underline',
  },
}))

let infoReq = {}
let readReq = {}
let setInfoData = () => {}
let setData = () => {}
const readFunc = () => {}

/**
 * @param {Object} props - 以下
 * @param {Number} id 受け取ったId
 * @param {String} updatedAt 受け取った更新時間
 * @returns {*} - 検索結果の表コンポーネント
 */
const NotifyTable = (props) => {
  const { t, i18n } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const [info, setInfo] = useState({})
  const [linkLabel, setLinkLabel] = useState('')
  const [path, setPath] = useState({})
  const [date, setDate] = useState('')

  setInfoData = (res) => {
    // 取得できていた場合のみ更新
    if (res.data.results.name) {
      setInfo(res.data.results)
      getRelatedPage(res.data.results.page)
      getDate(res.data.results.date)
    }
  }

  // エラー処理、コールバック関数実行
  setData = (res, callback) => {
    CommonFunc.callbackFunc(res, callback, history)
  }

  infoReq = { params: { id: props.id } }
  readReq = { id: props.id, read: 1, updatedAt: props.updatedAt }

  useEffect(() => {
    // 各データ取得
    restFacade.get(
      ApiPaths.NOTIFICATIONS.NOTIFICATION_INFO,
      (res) => setData(res, setInfoData),
      infoReq
    )
    restFacade.put(
      ApiPaths.NOTIFICATIONS.NOTIFICATIONS,
      (res) => setData(res, readFunc),
      readReq
    )
  }, [])

  const getDate = (date) => {
    const tmp = CommonFunc.chgUTC2LocalTime(
      date,
      'YYYY-MM-DDTHH:mm:ss',
      'YYYY-MM-DD HH:mm'
    )
    setDate(tmp)
  }

  const getRelatedPage = (page) => {
    const profile = CommonFunc.getLoginUserData()
    let pathList = []
    if (profile.shipperJhId) {
      //荷主
      pathList = [
        { page: 1, path: Paths.SHIPPER.SHIPPING_DETAILS, name: 'BULK SEARCH' },
        { page: 2, path: Paths.SHIPPER.CARGO_TRACE, name: 'CARGO TRACE' },
        { page: 3, path: Paths.SHIPPER.BID_MGMT, name: 'BID Mgmt.' },
      ]
    } else if (profile.fwdrId) {
      //混載業者
      pathList = [
        { page: 2, path: Paths.FWDR.CARGO_TRACE, name: 'CARGO TRACE' },
        { page: 4, path: Paths.FWDR.BOOKING_LIST, name: 'BOOKING LIST' },
        {
          page: 5,
          path: Paths.FWDR.DOCS_PREPARATION,
          name: 'DOCS PREPARATION',
        },
        { page: 6, path: Paths.FWDR.BID_MGMT, name: 'BID Mgmt.' },
        { page: 10, path: Paths.FWDR.LIGHT_BOOKING_LIST, name: 'BOOKING LIST' },
      ]
    } else if (profile.carrierId) {
      //航空会社
      pathList = [
        { page: 2, path: Paths.AIRLINE.CARGO_TRACE, name: 'CARGO TRACE' },
        { page: 4, path: Paths.AIRLINE.BOOKING_LIST, name: 'BOOKING LIST' },
        { page: 7, path: Paths.AIRLINE.SPACE_CONTROL, name: 'SPACE CONTROL' },
        { page: 8, path: Paths.AIRLINE.BID_RATES, name: 'BID RATES' },
        { page: 9, path: Paths.AIRLINE.CASS_UPLOAD, name: 'CASS UPLOAD' },
        {
          page: 10,
          path: Paths.AIRLINE.LIGHT_BOOKING_LIST,
          name: 'BOOKING LIST',
        },
      ]
    }
    const path = pathList.find((v) => page === v.page)
    if (path) {
      setLinkLabel(path.name)
      setPath({ path: path.path })
    }
  }

  const deleteClick = () => {
    const onDelete = () => {
      // 削除成功時、NotifyList画面に遷移
      const clickProps = {
        path: Paths.NOTIFY.NOTIFY_LIST,
        state: props.sort,
      }
      CommonFunc.clickEvent(clickProps, history)
    }
    const req = { params: { id: info.id } }
    restFacade.delete(
      ApiPaths.NOTIFICATIONS.NOTIFICATIONS,
      (res) => CommonFunc.callbackFunc(res, onDelete),
      req
    )
  }

  /**
   * Contentsの中身を改行付きで作成
   * @param {String} contentsStr - DBから受け取ったContentsの文字列
   * @returns {JSX.Element} -
   */
  const createContents = (contentsStr) =>
    contentsStr?.split(/\r\n|\n/).map((content, index) => (
      <div key={index}>
        {content}
        <br />
      </div>
    ))

  return (
    <>
      <TableContainer
        component={Paper}
        elevation={0}
        className={classes.tableContainer}
      >
        <Table className={classes.table}>
          <TableBody>
            {/* Date */}
            <TableRow>
              <TableCell>{t('D602V0002')}</TableCell>
              <TableCell>
                {CommonFunc.convertDate(
                  date.split(' ')[0],
                  CommonFunc.getLang(i18n)
                ) +
                  ' ' +
                  date.split(' ')[1]}
              </TableCell>
            </TableRow>
            {/* Name */}
            <TableRow>
              <TableCell>{t('D602V0003')}</TableCell>
              <TableCell>
                {info.name ? info.name + '_' + info.lastName : ''}
              </TableCell>
            </TableRow>
            {/* Company */}
            <TableRow>
              <TableCell>{t('D602V0004')}</TableCell>
              <TableCell>{info.companyName}</TableCell>
            </TableRow>
            {/* Contents */}
            <TableRow>
              <TableCell>{t('D602V0005')}</TableCell>
              <TableCell className={classes.contents}>
                {createContents(info.content)}
              </TableCell>
            </TableRow>
            {/* Related Page */}
            <TableRow>
              <TableCell>{t('D602V0006')}</TableCell>
              <TableCell>
                <Link
                  className={classes.underline}
                  onClick={() => CommonFunc.clickEvent(path, history)}
                >
                  {linkLabel}
                </Link>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <DeleteDialog
        buttonPC={
          <XsmallButton addclass={classes.blu}>{t('D602V0007')}</XsmallButton>
        }
        onDelete={deleteClick}
      />
    </>
  )
}
export default NotifyTable
