import React from 'react'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  normalText: theme.textNormalBLK,
  underLine: { textDecoration: 'underline' },
}))

/**
 * 質問文と選択肢を表示
 * @param {Object} props - 以下
 * @param {string} text - 質問文
 * @returns {*} - Question
 */
const Question = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { texts } = props

  return (
    <Grid
      container
      item
      direction="column"
      justify="center"
      alignItems="center"
    >
      {texts.map((text, index) => (
        <Grid
          className={`${classes.normalText} ${classes.underLine}`}
          key={index}
        >
          {t(text)}
        </Grid>
      ))}
    </Grid>
  )
}

Question.propTypes = {
  texts: PropTypes.array.isRequired,
}

export default Question
