import { Grid } from '@material-ui/core'
import React from 'react'

import SearchSortLightCarFlightMgmt from './SearchSortLightCarFlightMgmt'
import TabLightCarFlightMgmt from './TabLightCarFlightMgmt'

/**
 * @return {JSX} サーチソートコンポーネント
 */
const TabsAndSearchSortLightCarFlightMgmt = () => {
  return (
    <Grid container justify="center">
      <Grid
        container
        item
        md={10}
        justify="space-between"
        alignItems="flex-end"
      >
        <TabLightCarFlightMgmt />
        <SearchSortLightCarFlightMgmt />
      </Grid>
    </Grid>
  )
}

export default TabsAndSearchSortLightCarFlightMgmt
