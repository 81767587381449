import React, { useContext } from 'react'
import { Grid, makeStyles } from '@material-ui/core'

import RequiredHeaderSetForwarderBidInfo from './RequiredHeaderSetForwarderBidInfo'
import HeaderSetForwarderBidInfo from './HeaderSetForwarderBidInfo'
import SelectSetForwarderBidInfo from './SelectSetForwarderBidInfo'
import SelectButtonBidInfo from './SelectButtonBidInfo'
import AppContext from '../../../../../contexts/AppContext'

const useStyles = makeStyles((theme) => ({
  childrenPadding: theme.childrenPaddingSmall,
}))

/**
 * @return {JSX} SetForwarderコンポーネント
 */
const SetForwarderBidInfo = () => {
  const { state } = useContext(AppContext)
  const { PageData } = state
  const { setFwdrFlg } = state.SetFwdr
  const classes = useStyles()

  const { bidStatusId } = PageData.bidStatusObj ?? {}
  // ステータスが未設定 → 新規追加or更新のどちらか
  const isEditMode = bidStatusId === null

  return (
    <Grid item container justify="flex-start">
      {isEditMode ? (
        <RequiredHeaderSetForwarderBidInfo />
      ) : (
        <HeaderSetForwarderBidInfo />
      )}
      <Grid
        item
        container
        justify="flex-start"
        className={classes.childrenPadding}
      >
        {setFwdrFlg && isEditMode ? <SelectButtonBidInfo /> : <></>}
        {setFwdrFlg ? <SelectSetForwarderBidInfo /> : <></>}
      </Grid>
    </Grid>
  )
}

export default SetForwarderBidInfo
