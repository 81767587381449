import React, { useContext } from 'react'

import FlightLightBookingDetails from '../../../../commonUseContext/LightBookingDetails/Flight/FlightLightBookingDetails'
import AppContext from '../../../../../contexts/AppContext'

/**
 * @param {Object} props 下記内容
 * @param {BeforePath} props.beforePath 遷移元画面
 * @return {JSX} Flightアコーディオン
 */
const FlightAirlineLightBookingDetails = () => {
  const { state } = useContext(AppContext)
  const editable = !(state.Status.isCancel || state.Status.isClose)
  return <FlightLightBookingDetails editable={editable} isShow={true} />
}

export default FlightAirlineLightBookingDetails
