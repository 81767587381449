import { makeStyles } from '@material-ui/core'
import React, { useContext } from 'react'

import Paths from '../../../../../constants/Paths'
import BackButton from '../../../../common/buttons/BackButton'
import AppContext from '../../../../../contexts/AppContext'

const useStyles = makeStyles((theme) => ({
  back: theme.backButtonCenter,
}))

/**
 * @return {JSX} バックボタンコンポーネント
 */
const BackButtonBidDetails = () => {
  const classes = useStyles()
  const { state } = useContext(AppContext)

  /** BidInfo画面に受け渡すデータ */
  const newData = {
    ...state.Data,
    bidDetailArr: state.Tables.editData,
  }

  return (
    <div className={classes.back}>
      <BackButton path={Paths.FWDR.BID_INFO} state={newData} />
    </div>
  )
}

export default BackButtonBidDetails
