import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  red: theme.textNormalRED,
  head: theme.textBoldBLU,
}))

/**
 * @param {object} props 下記内容
 * @param {object} props.contents 予約情報
 * @return {JSX} 合計
 */
const FscLightSearchResult = ({ contents }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { currency, disabled, cost } = contents

  const getCost = () => {
    if (!disabled) {
      return (
        <div className={classes.head}>
          {`${t('4th0012')}: ${currency} ${cost}`}
        </div>
      )
    } else {
      return <div className={classes.red}>{t('D009V0060')}</div>
    }
  }

  return <div>{getCost()}</div>
}

FscLightSearchResult.propTypes = {
  contents: PropTypes.object.isRequired,
}

export default FscLightSearchResult
