import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

import WithdrawalTitleLightJhClientRegDeleteAccount from './WithdrawalTitleLightJhClientRegDeleteAccount'
import WithdrawalCheckAnswerLightJhClientRegDeleteAccount from './WithdrawalCheckAnswerLightJhClientRegDeleteAccount'
import WithdrawalTextAnswerLightJhClientRegDeleteAccount from './WithdrawalTextAnswerLightJhClientRegDeleteAccount'
import ConstantsLightJhClientRegDeleteAccount from '../ConstantsLightJhClientRegDeleteAccount'

const useStyles = makeStyles((theme) => ({
  childrenPadding: theme.childrenPaddingSmall,
}))

/**
 * @param {Object} data 退会依頼情報オブジェクト
 * @return {JSX} 退会理由コンポーネント
 */
const WithdrawalLightJhClientRegDeleteAccount = ({ data }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { Q1, Q2, Q3: Q3Text, Q4: Q4Index } = data
  const { check: Q1CheckArr, text: Q1Text } = Q1
  const { check: Q2CheckArr, text: Q2Text } = Q2

  const { Q1_ARR, Q2_ARR, Q4_ARR } =
    ConstantsLightJhClientRegDeleteAccount.TEXT_SETTING

  return (
    <Grid
      container
      item
      xs={10}
      justify="center"
      className={classes.childrenPadding}
    >
      {/* Q1 */}
      <WithdrawalTitleLightJhClientRegDeleteAccount titleKey={'D401V0018'} />
      <WithdrawalCheckAnswerLightJhClientRegDeleteAccount
        checkArr={Q1CheckArr}
        checkTextArr={Q1_ARR}
      />
      <WithdrawalTextAnswerLightJhClientRegDeleteAccount text={Q1Text} />

      {/* Q2 */}
      <WithdrawalTitleLightJhClientRegDeleteAccount titleKey={'D401V0019'} />
      <WithdrawalCheckAnswerLightJhClientRegDeleteAccount
        checkArr={Q2CheckArr}
        checkTextArr={Q2_ARR}
      />
      <WithdrawalTextAnswerLightJhClientRegDeleteAccount text={Q2Text} />

      {/* Q3 */}
      <WithdrawalTitleLightJhClientRegDeleteAccount titleKey={'D401V0020'} />
      <WithdrawalTextAnswerLightJhClientRegDeleteAccount text={Q3Text} />

      {/* Q4 */}
      <WithdrawalTitleLightJhClientRegDeleteAccount titleKey={'D401V0021'} />
      <WithdrawalTextAnswerLightJhClientRegDeleteAccount
        text={t(Q4_ARR[Q4Index - 1])}
      />
    </Grid>
  )
}

WithdrawalLightJhClientRegDeleteAccount.propTypes = {
  data: PropTypes.object.isRequired,
}

export default WithdrawalLightJhClientRegDeleteAccount
