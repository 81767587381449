import { CURRENCY } from '../../actions'

/**
 * @typedef {Object} Currency 通貨情報
 * @property {Number} currencyId 通貨ID
 * @property {String} currency 通貨名
 * @property {Number} delFlg 削除フラグ
 * @property {String} createdAt 作成日時
 * @property {String} updatedAt 更新日時
 */

/**
 * @type {Object} 初期値
 * @property {Currency[]} carrierNames 航空会社名一覧
 * @property {Function} getCurrency 航空会社名情報取得関数
 */
const initCurrency = {
  currency: [],
  getCurrency: () => null,
}

/**
 * 通貨情報を保持
 * @param {Object} action action
 * @param {Currency[]} action.data 通貨情報の配列
 * @returns {Object} 通貨情報
 */
const setCurrency = (action) => {
  const { data } = action

  /**
   * currencyIdから通貨を取得
   * @param {Number} currencyId 通貨ID
   * @returns {Currency|null} 通貨
   */
  const getCurrency = (currencyId) =>
    data.find((v) => v.currencyId === currencyId) ?? null

  return {
    currency: data,
    getCurrency,
  }
}

/**
 * state: Currencyリストを保存
 * @param {Object} [state=initCurrency] - state
 * @param {Object} action - action
 * @returns {Object} - state
 */
const Currency = (state = initCurrency, action) => {
  switch (action.type) {
    case CURRENCY.SET:
      return setCurrency(action)
    default:
      return state
  }
}

export default Currency

export { initCurrency }
