import React, { useContext } from 'react'
import AppContext from '../../../../../contexts/AppContext'

import CommonTableContainer from '../../../../common/table/CommonTableContainer'
import HeaderBidDetails from '../../../../commonUseContext/Bid/Table/HeaderBidDetails'
import TableContentsBidDetails from './TableContentsBidDetails'
import { getTableArr, getIsShowColumn } from '../ConstantsBidDetails'

/**
 * @return {JSX} 表コンポーネント(全体)
 */
const TableBidDetails = () => {
  const { state } = useContext(AppContext)
  /** @type {Boolean} 表データがない場合true */
  const isNoResult = state.IsNew ? false : state.Tables.showData[0].length <= 0

  /**テーブル情報の配列 */
  const tableArr = getTableArr(state.IsNew)

  /**
   * 列を表示するか判定する
   * @param {string} checkboxName 関連するチェックボックス名
   * @returns {Boolean} 表示する場合true
   */
  const getIsShow = (checkboxName) =>
    getIsShowColumn(checkboxName, state.Checkbox)

  return (
    <CommonTableContainer isNoResult={isNoResult}>
      <HeaderBidDetails tableArr={tableArr} getIsShowColumn={getIsShow} />
      <TableContentsBidDetails />
    </CommonTableContainer>
  )
}

export default TableBidDetails
