/* eslint-disable prefer-template */
import React, { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import RadioGroup from '@material-ui/core/RadioGroup'

import { TABLES } from '../../../../../actions'
import PrimaryButton from '../../../../common/buttons/PrimaryButton'
import SecondaryButton from '../../../../common/buttons/SecondaryButton'
import XsmallButton from '../../../../common/buttons/XsmallButton'
import MobileRadio from '../../../../common/radio/MobileRadio'
import AddButton from '../../../../common/buttons/AddButton'
import Dialog from '../../../../common/dialog/Dialog'
import TextField from '../../../../common/text/TextField'
import AppContext from '../../../../../contexts/AppContext'
import CommonCalc from '../../../../common/function/CommonCalc'
import CommonValidation from '../../../../common/function/CommonValidation'
import Common from '../../../../../constants/Common'

const useStyles = makeStyles((theme) => ({
  text: theme.textDialogHead,
}))

let checkDisabled = () => {}

const EditDialog = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { dispatch } = useContext(AppContext)
  const [disabled, setDisabled] = useState(true)
  const initErrors = {
    pcs: false,
    grossWgt: false,
    length: false,
    width: false,
    height: false,
  }
  const [errors, setErrors] = useState(initErrors)
  const constants = {
    pcs: {
      id: 'pcs',
      validation: CommonValidation.pcs,
      helper: t(CommonValidation.pcs(false)),
    },
    grossWgt: {
      id: 'grossWgt',
      validation: CommonValidation.grossWgt,
      helper: t(CommonValidation.grossWgt(false)),
    },
    length: {
      id: 'length',
      validation: CommonValidation.cargoSize,
      helper: t(CommonValidation.cargoSize(false)),
    },
    width: {
      id: 'width',
      validation: CommonValidation.cargoSize,
      helper: t(CommonValidation.cargoSize(false)),
    },
    height: {
      id: 'height',
      validation: CommonValidation.cargoSize,
      helper: t(CommonValidation.cargoSize(false)),
    },
  }
  const initHelpers = {
    pcs: constants.pcs.helper,
    grossWgt: constants.grossWgt.helper,
    length: constants.length.helper,
    width: constants.width.helper,
    height: constants.height.helper,
  }
  const [helpers, setHelpers] = useState(initHelpers)
  const nonStackableItem =
    props.cargoDetail.nonStackable !== undefined
      ? props.cargoDetail.nonStackable
      : '0'
  const [nonStackable, setNonStackable] = useState(nonStackableItem)
  const hazardousItem =
    props.cargoDetail.hazardous !== undefined
      ? props.cargoDetail.hazardous
      : '0'
  const [hazardous, setHazardous] = useState(hazardousItem)

  const inputChange = (event) => {
    const value = event.target.value
    const data = constants[event.target.id]
    const check = data.validation(value)
    const error = errors
    const help = helpers
    help[data.id] = check.checkResult ? t(check.errMsg) : data.helper
    error[data.id] = check.checkResult
    setErrors({ ...error })
    setHelpers({ ...help })
  }

  checkDisabled = () => {
    const isError = Object.keys(errors).find((data) => errors[data] === true)
    const isInput = Object.keys(constants).find((data) => {
      if (document.getElementById(constants[data].id)) {
        return document.getElementById(constants[data].id).value === ''
      } else {
        return false
      }
    })
    const result = Boolean(
      isError ||
        isInput ||
        nonStackable === Common.CARGO_DETAILS_FLG.DEFAULT ||
        hazardous === Common.CARGO_DETAILS_FLG.DEFAULT
    )
    setDisabled(result)
    return result
  }

  const calcVolume = () => {
    const length = parseFloat(
      document.getElementById(constants.length.id).value
    )
    const width = parseFloat(document.getElementById(constants.width.id).value)
    const height = parseFloat(
      document.getElementById(constants.height.id).value
    )
    const pcs = parseInt(document.getElementById(constants.pcs.id).value)
    const vol = CommonCalc.volume(length, width, height, pcs)
    return vol
  }

  const clacChargeableWgt = () => {
    const length = parseFloat(
      document.getElementById(constants.length.id).value
    )
    const width = parseFloat(document.getElementById(constants.width.id).value)
    const height = parseFloat(
      document.getElementById(constants.height.id).value
    )
    const pcs = parseInt(document.getElementById(constants.pcs.id).value)
    const grossWgt = CommonCalc.ceil(
      document.getElementById(constants.grossWgt.id).value,
      99999.99
    )
    const chargeableWgt = CommonCalc.chargeableWeight(
      pcs,
      grossWgt,
      length,
      width,
      height
    )

    return chargeableWgt.toFixed(2)
  }

  useEffect(() => {
    checkDisabled()
  }, [errors, hazardous, nonStackable])

  const updateDetail = () => {
    if (!checkDisabled()) {
      if (props.add) {
        dispatch({
          type: TABLES.SET,
          cargoDetail: {
            pcs: parseInt(document.getElementById(constants.pcs.id).value),
            grossWgt: CommonCalc.ceil(
              document.getElementById(constants.grossWgt.id).value,
              99999.99
            ),
            length: parseInt(
              document.getElementById(constants.length.id).value
            ),
            width: parseInt(document.getElementById(constants.width.id).value),
            height: parseInt(
              document.getElementById(constants.height.id).value
            ),
            volume: calcVolume(),
            chargeableWgt: clacChargeableWgt(),
            nonStackable,
            hazardous,
          },
        })
      } else {
        dispatch({
          type: TABLES.EDIT,
          editIndex: props.no - 1,
          cargoDetail: {
            pcs: parseInt(document.getElementById(constants.pcs.id).value),
            grossWgt: CommonCalc.ceil(
              document.getElementById(constants.grossWgt.id).value,
              99999.99
            ),
            length: parseInt(
              document.getElementById(constants.length.id).value
            ),
            width: parseInt(document.getElementById(constants.width.id).value),
            height: parseInt(
              document.getElementById(constants.height.id).value
            ),
            volume: calcVolume(),
            chargeableWgt: clacChargeableWgt(),
            nonStackable,
            hazardous,
          },
        })
      }
      setDisabled(true)
    }
  }

  const deleteDetail = () => {
    dispatch({
      type: TABLES.CLEAR,
      editIndex: props.no - 1,
    })
  }

  const initialize = () => {
    setErrors(initErrors)
    setHelpers(initHelpers)
    setNonStackable(nonStackableItem)
    setHazardous(hazardousItem)
    if (props.add !== true) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }

  return (
    <Dialog
      buttonPC={
        props.add ? (
          <AddButton onClick={initialize} />
        ) : (
          <XsmallButton onClick={initialize}>{t('D004V0061')}</XsmallButton>
        )
      }
      primary={
        <PrimaryButton disabled={disabled} onClick={updateDetail}>
          {t('D004V0062')}
        </PrimaryButton>
      }
      secondary={
        <SecondaryButton onClick={props.add ? deleteDetail : null}>
          {t('D004V0063')}
        </SecondaryButton>
      }
      title={'No. ' + CommonCalc.addComma(props.no)}
      primaryDisabled={disabled}
      delete={deleteDetail}
    >
      <Grid item md={12} xs={12} className={classes.text}>
        {t('D004V0014')}
      </Grid>
      <Grid item md={5} xs={10}>
        <TextField
          id={constants.pcs.id}
          defaultValue={props.cargoDetail.pcs}
          error={errors.pcs}
          onChange={inputChange}
          helperText={helpers.pcs}
        />
      </Grid>
      <Grid item md={12} xs={12} className={classes.text}>
        {t('D004V0015')}
      </Grid>
      <Grid item md={5} xs={10}>
        <TextField
          id={constants.grossWgt.id}
          defaultValue={props.cargoDetail.grossWgt}
          error={errors.grossWgt}
          onChange={inputChange}
          helperText={helpers.grossWgt}
        />
      </Grid>
      <Grid item md={12} xs={12} className={classes.text}>
        {t('D004V0016')}
      </Grid>
      <Grid item md={5} xs={10}>
        <TextField
          id={constants.length.id}
          defaultValue={props.cargoDetail.length}
          error={errors.length}
          onChange={inputChange}
          helperText={helpers.length}
        />
      </Grid>
      <Grid item md={12} xs={12} className={classes.text}>
        {t('D004V0017')}
      </Grid>
      <Grid item md={5} xs={10}>
        <TextField
          id={constants.width.id}
          defaultValue={props.cargoDetail.width}
          error={errors.width}
          onChange={inputChange}
          helperText={helpers.width}
        />
      </Grid>
      <Grid item md={12} xs={12} className={classes.text}>
        {t('D004V0018')}
      </Grid>
      <Grid item md={5} xs={10}>
        <TextField
          id={constants.height.id}
          defaultValue={props.cargoDetail.height}
          error={errors.height}
          onChange={inputChange}
          helperText={helpers.height}
        />
      </Grid>
      <Grid item md={12} xs={12} className={classes.text}>
        {t('D004V0021')}
      </Grid>
      <Grid item>
        <RadioGroup
          row
          onChange={(event) => setNonStackable(event.target.value)}
          value={nonStackable}
        >
          <MobileRadio
            value={Common.CARGO_DETAILS_FLG.YES}
            label={t('D004V0058')}
          />
          <MobileRadio
            value={Common.CARGO_DETAILS_FLG.NO}
            label={t('D004V0059')}
          />
        </RadioGroup>
      </Grid>
      <Grid item md={12} xs={12} className={classes.text}>
        {t('D004V0022')}
      </Grid>
      <Grid item>
        <RadioGroup
          row
          onChange={(event) => setHazardous(event.target.value)}
          value={hazardous}
        >
          <MobileRadio
            value={Common.CARGO_DETAILS_FLG.YES}
            label={t('D004V0058')}
          />
          <MobileRadio
            value={Common.CARGO_DETAILS_FLG.NO}
            label={t('D004V0059')}
          />
        </RadioGroup>
      </Grid>
    </Dialog>
  )
}

export default EditDialog
