// Display + 画面名
const DisplayShipperShowDetails = {
  // 画面ID + 文言ID
  D008V0001: {
    en: 'Managing BID',
    ja: '入札管理',
  },
  D008V0002: {
    en: 'Offering BID',
    ja: '入札オファー',
  },
  D008V0003: {
    en: 'SHOW DETAILS',
    ja: '詳細を表示',
  },
  D008V0004: {
    en: 'Owner',
    ja: 'オーナー',
  },
  D008V0005: {
    en: 'Panasonic Corporation',
    ja: 'パナソニック株式会社',
  },
  D008V0006: {
    en: '1006, Oaza Kadoma, Kadoma-shi, Osaka 571-8501, Japan',
    ja: '日本 571-8501 大阪府門真市大字門真1006',
  },
  D008V0007: {
    en: 'Tel. ',
    ja: '電話番号：',
  },
  D008V0008: {
    en: '+81-6-6908-1121',
    ja: '+81-6-6908-1121',
  },
  D008V0009: {
    en: 'Representative',
    ja: '代表',
  },
  D008V0010: {
    en: 'Konosuke Matsushita',
    ja: '松下幸之助',
  },
  D008V0011: {
    en: 'Manager',
    ja: 'マネージャー',
  },
  D008V0012: {
    en: 'Supervisor',
    ja: 'スーパーバイザー',
  },
  D008V0013: {
    en: 'Leader',
    ja: 'リーダー',
  },
  D008V0014: {
    en: 'Lighting Dept.',
    ja: '照明部門',
  },
  D008V0015: {
    en: 'kmatsushita@panasonic.co.jp',
    ja: 'kmatsushita@panasonic.co.jp',
  },
  D008V0016: {
    en: 'Validity',
    ja: '有効',
  },
  D008V0017: {
    en: 'August 1, 2020 to January 31, 2021',
    ja: '2020年8月1日～2021年1月31日',
  },
  D008V0018: {
    en: 'Set FWDR',
    ja: '混載業者を設定',
  },
  D008V0019: {
    en: 'DZK/KNJ/EIK/NEC/YLK/KWE/HEI',
    ja: 'DZK/KNJ/EIK/NEC/YLK/KWE/HEI',
  },
  D008V0020: {
    en: 'Currency',
    ja: '通貨',
  },
  D008V0021: {
    en: 'JPY - Japanese Yen',
    ja: 'JPY - 日本円',
  },
  D008V0022: {
    en: '1st Deadline',
    ja: '一次締め切り',
  },
  D008V0023: {
    en: 'July 31, 2020',
    ja: '2020年7月31日',
  },
  D008V0024: {
    en: 'Special Notes',
    ja: '備考',
  },
  D008V0025: {
    en: '･Please follow the schedule strictly.',
    ja: '・スケジュールは厳守してください。',
  },
  D008V0026: {
    en: "・We'll inform you as soon as we decide.",
    ja: '・決定次第お知らせします。',
  },
  D008V0027: {
    en: 'Edit',
    ja: '編集',
  },
  D008V0028: {
    en: 'Request Lanes',
    ja: 'レーンをリクエスト',
  },
  D008V0029: {
    en: 'ORG/DST',
    ja: '行先/到着',
  },
  D008V0030: {
    en: 'Via',
    ja: '経由',
  },
  D008V0031: {
    en: 'TTL Wgt.',
    ja: '合計重量',
  },
  D008V0032: {
    en: 'TTL Vol.',
    ja: '合計容積',
  },
  D008V0033: {
    en: 'SHC',
    ja: 'SHC',
  },
  D008V0034: {
    en: '+45kg',
    ja: '+45kg',
  },
  D008V0035: {
    en: '+100kg',
    ja: '+100kg',
  },
  D008V0036: {
    en: '+300kg',
    ja: '+300kg',
  },
  D008V0037: {
    en: '+500kg',
    ja: '+500kg',
  },
  D008V0038: {
    en: '+1,000kg',
    ja: '+1,000kg',
  },
  D008V0039: {
    en: '--Select--',
    ja: '--選択--',
  },
  D008V0040: {
    en: 'Enter kg',
    ja: 'kgを入力',
  },
  D008V0041: {
    en: 'Enter m3',
    ja: 'm3を入力',
  },
  D008V0042: {
    en: '1. DZK Rates - FORECAST',
    ja: '1. DZKレート-予測',
  },
  D008V0043: {
    en: 'Select FWDR',
    ja: '混載業者を選択',
  },
  D008V0044: {
    en: 'NRT/MNL',
    ja: 'NRT/MNL',
  },
  D008V0045: {
    en: 'NRT/LON',
    ja: 'NRT/LON',
  },
  D008V0046: {
    en: 'NRT/ORD',
    ja: 'NRT/ORD',
  },
  D008V0047: {
    en: '-',
    ja: '-',
  },
  D008V0048: {
    en: 'FRA',
    ja: 'FRA',
  },
  D008V0049: {
    en: 'ANC',
    ja: 'ANC',
  },
  D008V0050: {
    en: '1,000kg - 3,000kg',
    ja: '1,000kg - 3,000kg',
  },
  D008V0051: {
    en: '300m3 - 500m3',
    ja: '300m3 - 500m3',
  },
  D008V0052: {
    en: 'GEN',
    ja: 'GEN',
  },
  D008V0053: {
    en: 'DG',
    ja: 'DG',
  },
  D008V0054: {
    en: 'ICE',
    ja: 'ICE',
  },
  D008V0055: {
    en: '800',
    ja: '800',
  },
  D008V0056: {
    en: '850',
    ja: '850',
  },
  D008V0057: {
    en: '650',
    ja: '650',
  },
  D008V0058: {
    en: '540',
    ja: '540',
  },
  D008V0059: {
    en: '820',
    ja: '820',
  },
  D008V0060: {
    en: '620',
    ja: '620',
  },
  D008V0061: {
    en: '510',
    ja: '510',
  },
  D008V0062: {
    en: '780',
    ja: '780',
  },
  D008V0063: {
    en: '580',
    ja: '580',
  },
  D008V0064: {
    en: '480',
    ja: '480',
  },
  D008V0065: {
    en: '720',
    ja: '720',
  },
  D008V0066: {
    en: '530',
    ja: '530',
  },
  D008V0067: {
    en: '680',
    ja: '680',
  },
  D008V0068: {
    en: '440',
    ja: '440',
  },
  D008V0069: {
    en: 'Select',
    ja: '選択',
  },
  D008V0070: {
    en: '2. KNJ Rates - FINAL',
    ja: '2. KNJレート-最終',
  },
  D008V0071: {
    en: '3. EIK Rates - FORECAST',
    ja: '3. EIKレート-予測',
  },
  D008V0072: {
    en: '4. NEC Rates - FINAL RVSD',
    ja: '4. NECレート-最終RVSD',
  },
  D008V0073: {
    en: 'Back',
    ja: '戻る',
  },
  D008V0074: {
    en: '/',
    ja: '/',
  },
  D008V0075: {
    en: 'Please click the box if you agree to be unable to cancel/change the selected lanes during the BID term.',
    ja: '入札期間中に選択したレーンのキャンセル・変更ができないことに同意する場合は、ボックスをクリックしてください。',
  },
  D008V0076: {
    en: 'Confirm',
    ja: '確認',
  },
  D008V0077: {
    en: 'Norikuni Inaba',
    ja: '稲葉憲邦',
  },
  D008V0078: {
    en: 'Air Export Manager',
    ja: '航空輸出マネージャー',
  },
  D008V0079: {
    en: 'ninaba@dzk.co.jp / +81-X-XXXX-XXXX',
    ja: 'ninaba@dzk.co.jp / +81-X-XXXX-XXXX',
  },
  D008V0080: {
    en: 'Unselect',
    ja: '選択解除',
  },
  D008V0081: {
    en: '2nd Deadline',
    ja: '二次締め切り',
  },
  D008V0082: {
    en: '3rd Deadline',
    ja: '三次締め切り',
  },
}
// Display + 画面名
export default DisplayShipperShowDetails
