import React, { useReducer, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import LayoutBox from '../../../common/layout/LayoutBox'
import AppContext from '../../../../contexts/AppContext'
import reducer, {
  initialState,
} from '../../../../reducers/master/lightMasterCarMyProfile'
import TitleLightCarMyProfile from './00TitleLightCarMyProfile/TitleLightCarMyProfile'
import TableLightCarMyProfile from './01TableLightCarMyProfile/TableLightCarMyProfile'
import ButtonLightCarMyProfile from './02ButtonLightCarMyProfile/ButtonLightCarMyProfile'
import CommonFunc from '../../../common/function/CommonFunc'
import ApiPaths from '../../../../constants/ApiPaths'
import {
  UPDATE_CAR_FIRST_NAME,
  UPDATE_CAR_LAST_NAME,
  UPDATE_CAR_MAIL,
  UPDATE_CAR_TEL,
  UPDATE_CAR_POSITION,
  UPDATE_CAR_PASSWORD,
  UPDATE_CAR_MAIL_SETTING,
  UPDATE_CAR_BACKUP,
  UPDATE_CAR_UPDATE_AT,
  UPDATE_CAR_CODE,
  UPDATE_CAR_GSA_FLG,
} from '../../../../actions'

/**
 * @return {JSX} Light版CarMyProfile画面コンポーネント
 */
const LightCarMyProfile = () => {
  const history = useHistory()
  // 扱いたいデータstateとデータを操作するための処理dispatch
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    ;(async () => {
      // API取得
      const apiInfoArr = [
        {
          key: 'companyInfoArr',
          method: 'get',
          path: ApiPaths.MASTER_CAR.LIGHT_MY_PROFILE,
        },
        { key: 'gsa', method: 'get', path: ApiPaths.MASTER_CAR.LIGHT_GSA },
      ]
      const resObj = await CommonFunc.execApiAsync(apiInfoArr)
      const isApiSuccessful = CommonFunc.checkApiResponseObj(resObj, history)
      if (isApiSuccessful) {
        const res = resObj.companyInfoArr.data.results
        const gsa = resObj.gsa.data.results

        dispatch({
          type: UPDATE_CAR_FIRST_NAME,
          name: res.name,
        })
        dispatch({
          type: UPDATE_CAR_LAST_NAME,
          lastName: res.lastName,
        })
        dispatch({
          type: UPDATE_CAR_MAIL,
          email: res.email,
        })
        dispatch({
          type: UPDATE_CAR_TEL,
          tel: res.tel,
        })
        dispatch({
          type: UPDATE_CAR_POSITION,
          position: res.position,
        })
        dispatch({
          type: UPDATE_CAR_CODE,
          department: res.department ?? '',
        })
        dispatch({
          type: UPDATE_CAR_PASSWORD,
          password: res.password,
        })
        dispatch({
          type: UPDATE_CAR_GSA_FLG,
          gsaFlg: gsa.gsaFlg,
        })
        dispatch({
          type: UPDATE_CAR_MAIL_SETTING,
          mailSetting: res.mailSetting,
        })
        dispatch({
          type: UPDATE_CAR_UPDATE_AT,
          updatedAt: res.updatedAt,
        })

        // 初回データをBackup
        dispatch({
          type: UPDATE_CAR_BACKUP,
          info: res,
        })

        // 先頭にスクロール
        window.scrollTo(0, 0)
      }
    })()
  }, []) // eslint-disable-line

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <LayoutBox>
        <TitleLightCarMyProfile />
        <TableLightCarMyProfile />
        <ButtonLightCarMyProfile />
      </LayoutBox>
    </AppContext.Provider>
  )
}

export default LightCarMyProfile
