import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import Title from '../../../../common/title/Title'

/**
 * @return {JSX} タイトルコンポーネント
 */
const TitleLightJhClientList = () => {
  const { t } = useTranslation()
  return useMemo(() => <Title>{t('D302V0001')}</Title>, [t])
}

export default TitleLightJhClientList
