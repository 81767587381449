// Display + 画面名
const DisplayShipperConfirmBooking = {
  // 画面ID + 文言ID
  D012V0001: {
    en: 'Bulk Search',
    ja: '一括検索',
  },
  D012V0002: {
    en: 'Choose Forwarder',
    ja: '混載業者を選択',
  },
  D012V0003: {
    en: 'Invoice',
    ja: 'インボイス',
  },
  D012V0004: {
    en: 'Packing List',
    ja: 'パッキングリスト',
  },
  D012V0005: {
    en: 'Confirm',
    ja: '確認',
  },
  D012V0006: {
    en: 'CONFIRM BOOKING',
    ja: '予約の確認',
  },
  D012V0007: {
    en: 'Shipment Details',
    ja: '発送詳細',
  },
  D012V0008: {
    en: 'Pcs.',
    ja: '個',
  },
  D012V0009: {
    en: 'Gross Wgt.(kg)',
    ja: '総重量(kg)',
  },
  D012V0010: {
    en: 'Vol.(m3)',
    ja: '容量(m3)',
  },
  D012V0011: {
    en: 'Chargeable Wgt.(kg)',
    ja: '搭載可能重量(kg)',
  },
  D012V0012: {
    en: 'ORG/DST',
    ja: '行先/到着',
  },
  D012V0013: {
    en: 'SHC Code',
    ja: 'SHCコード',
  },
  D012V0014: {
    en: '10',
    ja: '10',
  },
  D012V0015: {
    en: '300.00',
    ja: '300.00',
  },
  D012V0016: {
    en: '2.05',
    ja: '2.05',
  },
  D012V0017: {
    en: 'NRT/ORD',
    ja: 'NRT/ORD',
  },
  D012V0018: {
    en: 'COL/ICE',
    ja: 'COL/ICE',
  },
  D012V0019: {
    en: 'Flight#',
    ja: 'フライト#',
  },
  D012V0020: {
    en: 'UA 838 / NRT - SFO / Boeing 787',
    ja: 'UA 838 / NRT-SFO /ボーイング787',
  },
  D012V0021: {
    en: "16:00 Narita Int'l Airport (NRT)",
    ja: '16:00 成田国際空港（NRT）',
  },
  D012V0022: {
    en: "09:15 San Francisco Int'l Airport (SFO)",
    ja: '09:15 サンフランシスコ国際空港（SFO）',
  },
  D012V0023: {
    en: 'UA 2070 / SFO - ORD / Boeing 757',
    ja: 'UA 2070 / SFO-ORD /ボーイング757',
  },
  D012V0024: {
    en: "10:45 San Francisco Int'l Airport (SFO)",
    ja: '10:45 サンフランシスコ国際空港（SFO）',
  },
  D012V0025: {
    en: "17:07 O'Hare Int'l Airport (ORD)",
    ja: '17:07 オヘア国際空港（ORD）',
  },
  D012V0026: {
    en: 'Document',
    ja: '書類',
  },
  D012V0027: {
    en: 'SDS',
    ja: 'SDS',
  },
  D012V0028: {
    en: 'Certificate of Origin',
    ja: '原産地証明書',
  },
  D012V0029: {
    en: 'Quote',
    ja: '見積もり',
  },
  D012V0030: {
    en: 'Add',
    ja: '追加',
  },
  D012V0031: {
    en: 'Negotiate fees',
    ja: '交渉手数料',
  },
  D012V0032: {
    en: 'Not negotiate',
    ja: '交渉しない',
  },
  D012V0033: {
    en: 'Request spot rates',
    ja: 'スポットレートのリクエスト',
  },
  D012V0034: {
    en: 'Back',
    ja: '戻る',
  },
  D012V0035: {
    en: 'Booking Request',
    ja: '予約リクエスト',
  },
  D012V0036: {
    en: 'Delete',
    ja: '削除',
  },
  D012V0037: {
    en: 'Booking Request is complete.',
    ja: '予約申し込みが完了しました。',
  },
  D012V0038: {
    en: 'Booking Request is failed.',
    ja: '予約申し込みに失敗しました。',
  },
}
// Display + 画面名
export default DisplayShipperConfirmBooking
