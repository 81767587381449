import React, { useReducer, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { useHistory } from 'react-router-dom'

import LayoutBox from '../../../common/layout/LayoutBox'
import TitleLightDeleteAccount from './00TitleLightDeleteAccount/TitleLightDeleteAccount'
import WarningLightDeleteAccount from './01WarningLightDeleteAccount/WarningLightDeleteAccount'
import QuestionnaireLightDeleteAccount from './02QuestionnaireLightDeleteAccount/QuestionnaireLightDeleteAccount'
import ButtonLightDeleteAccount from './03ButtonLightDeleteAccount/ButtonLightDeleteAccount'
import AppContext from '../../../../contexts/AppContext'
import reducer, {
  initialState,
} from '../../../../reducers/master/lightDeleteAccount'
import CommonFunc from '../../../common/function/CommonFunc'
import ApiPaths from '../../../../constants/ApiPaths'
import { UPDATE_FWDR_GROUP } from '../../../../actions'

/**
 * @return {JSX} Light版DeleteAccount画面コンポーネント
 */
const LightDeleteAccount = () => {
  // 扱いたいデータstateとデータを操作するための処理dispatch
  const [state, dispatch] = useReducer(reducer, initialState)
  const history = useHistory()

  useEffect(() => {
    ;(async () => {
      const userData = CommonFunc.getLoginUserData()
      if (userData.fwdrId !== null) {
        //混載業者の時Groupを取得
        // API実行
        const apiInfoArr = [
          {
            key: 'resData',
            method: 'get',
            path: ApiPaths.MASTER_FWDR.FWDR_GROUP,
          },
        ]
        const resObj = await CommonFunc.execApiAsync(apiInfoArr)
        const isApiSuccessful = CommonFunc.checkApiResponseObj(resObj, history)
        if (isApiSuccessful) {
          const resData = resObj.resData
          dispatch({
            type: UPDATE_FWDR_GROUP,
            group: resData.data.results.fwdrGroup,
          })
        }
      }
    })()
  }, []) // eslint-disable-line

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <LayoutBox>
        <TitleLightDeleteAccount />
        <Grid container justify="center">
          <Grid container item direction="column" md={10} xs={10} spacing={8}>
            <Grid item>
              <WarningLightDeleteAccount />
            </Grid>
            <Grid item>
              <QuestionnaireLightDeleteAccount />
            </Grid>
            <Grid item>
              <ButtonLightDeleteAccount />
            </Grid>
          </Grid>
        </Grid>
      </LayoutBox>
    </AppContext.Provider>
  )
}

export default LightDeleteAccount
