import React, { useReducer, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import LayoutBox from '../../../common/layout/LayoutBox'
import reducer, {
  initialState,
} from '../../../../reducers/fwdr/lightFwdrBookingList'
import AppContext from '../../../../contexts/AppContext'
import ApiPaths from '../../../../constants/ApiPaths'
import CommonFunc from '../../../common/function/CommonFunc'
import { SEARCH_SORT, TABLES } from '../../../../actions'

import TitleLightFwdrBookingList from './00TitleLightFwdrBookingList/TitleLightFwdrBookingList'
import SearchSortLightFwdrBookingList from './01SearchSortLightFwdrBookingList/SearchSortLightFwdrBookingList'
import TableLightFwdrBookingList from './02TableLightFwdrBookingList/TableLightFwdrBookingList'
import PagingLightFwdrBookingList from './03PagingLightFwdrBookingList/PagingLightFwdrBookingList'
import BackButtonLightFwdrBookingList from './04BackButtonLightFwdrBookingList/BackButtonLightFwdrBookingList'
import ConstantsLightFwdrBookingList from './ConstantsLightFwdrBookingList'

const LightFwdrBookingList = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    ;(async () => {
      const apiInfoArr = [
        {
          key: 'bookingList',
          method: 'get',
          path: ApiPaths.FORWARDER.LIGHT_BOOKING_LIST,
        },
        {
          key: 'branch',
          method: 'get',
          path: ApiPaths.FORWARDER.DEFAULT_BRANCH,
        },
      ]
      const resObj = await CommonFunc.execApiAsync(apiInfoArr)
      const isApiSuccessful = CommonFunc.checkApiResponseObj(resObj, history)

      if (isApiSuccessful) {
        const { bookingList, branch } = resObj
        const { cityCode, searchCityCode } = branch.data.results
        // ソートの初期値は定数の最初の値で固定
        const initSort = ConstantsLightFwdrBookingList.SORT_ITEM_ARR[0].sortTerm

        //SearchSortの初期値を設定
        dispatch({
          type: SEARCH_SORT.SET,
          search: '',
          sort: initSort,
          defaultStr: `${t('D212V0003')} ${cityCode}`,
          defaultSearchWord: searchCityCode,
        })
        // テーブルの初期値を設定
        dispatch({
          type: TABLES.SET,
          tableData: bookingList.data.results,
          sort: initSort,
          defaultSearchWord: searchCityCode,
        })
      }
    })()
  }, []) //eslint-disable-line

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <LayoutBox>
        <TitleLightFwdrBookingList />
        <SearchSortLightFwdrBookingList />
        <TableLightFwdrBookingList />
        <PagingLightFwdrBookingList />
        <BackButtonLightFwdrBookingList />
      </LayoutBox>
    </AppContext.Provider>
  )
}

export default LightFwdrBookingList
