/* eslint-disable no-extra-parens */
import React from 'react'
import { useTranslation } from 'react-i18next'
import ButtonBase from '@material-ui/core/ButtonBase'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import WorkIcon from '@material-ui/icons/Work'
import { useHistory } from 'react-router-dom'

import CommonFunc from '../function/CommonFunc'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '174px',
    margin: '0 1rem',
    [theme.breakpoints.down('sm')]: {
      margin: '1rem 0px',
      height: '85px',
      maxWidth: 'none',
    },
    fontFamily: theme.typography.fontFamily,
    color: theme.fontColor.info,
  },
  card: {
    margin: 'auto',
    width: '100%',
    maxHeight: 175,
    height: 'calc(100vh - 400px)',
    minHeight: '85px',
    borderRadius: 20,
    backgroundColor: theme.palette.info.main,
    transition: '0.3s',
    boxShadow: '8px 8px 4px rgba(0,0,0,0.5)',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      height: '85px',
      minHeight: '85px',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.fontColor.main,
      boxShadow: '8px 8px 4px rgba(0,0,0,0.5)',
    },
  },
  cardDisabled: {
    margin: 'auto',
    width: '100%',
    maxHeight: 175,
    height: 'calc(100vh - 400px)',
    minHeight: '85px',
    borderRadius: 20,
    backgroundColor: theme.palette.info.dark,
    transition: '0.3s',
    boxShadow: '8px 8px 4px rgba(0,0,0,0.5)',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      height: '85px',
      minHeight: '85px',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  cardTitle: {
    position: 'relative',
    fontSize: theme.fontSize.regular,
    fontWeight: 'bold',
    [theme.breakpoints.up('md')]: {
      fontSize: theme.fontSize.regular,
    },
  },
  icon: {
    fontSize: '100px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '50px',
      margin: '1rem 0.5rem 1rem 1rem',
    },
  },
  titleMd: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  titleSm: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  text: {
    width: '100%',
  },
  imgChange: {
    '&:hover': {
      color: theme.palette.border.black,
      filter: 'grayscale(100%)',
    },
  },
  imgOn: {
    margin: '1rem',
  },
}))

/**
 * @param {Object} props - 以下
 * @param {string} card - 表示したいカード名
 * @param {string} path - 押下後の遷移先
 * @param {Event} clickevent - 押下後のイベント
 * @returns {JSX.Element} - detail(黄色)ボタンコンポーネント
 */
const MiniCardButton = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const card = {
    icon: '',
    title: '',
  }

  switch (props.card) {
    case 'weekly':
      card.icon = 'weeklyIcon'
      card.title = `${t('D401V0040')}`
      break
    case 'monthly':
      card.icon = 'monthlyIcon'
      card.title = `${t('D401V0041')}`
      break
    case 'quaterly':
      card.icon = 'quaterlyIcon'
      card.title = `${t('D401V0042')}`
      break
    case 'sales':
      card.icon = 'salesIcon'
      card.title = `${t('D401V0043')}`
      break
    case 'clientRevenue':
      card.icon = 'clientRevenueIcon'
      card.title = `${t('D401V0044')}`
      break
    case 'clientRanking':
      card.icon = 'clientRankingIcon'
      card.title = `${t('D401V0045')}`
      break
    case 'totalRevenue':
      card.icon = 'totalRevenueIcon'
      card.title = `${t('D401V0046')}`
      break
    case 'contract':
      card.icon = 'contractIcon'
      card.title = `${t('D401V0047')}`
      break
    case 'clientList':
      card.icon = 'clientListIcon'
      card.title = `${t('D401V0048')}`
      break
    case 'clientReg':
      card.icon = 'clientRegIcon'
      card.title = `${t('D401V0049')}`
      break
    default:
      break
  }

  // clickイベントとページ遷移
  const history = useHistory()
  const clickEvent = (props) => {
    CommonFunc.clickEvent(props, history)
  }

  return (
    <div className={classes.root}>
      <ButtonBase
        focusRipple
        className={
          props.disabled === true ? classes.cardDisabled : classes.card
        }
        onClick={() => clickEvent(props)}
        {...props}
      >
        {card.icon === 'workIcon' ? <WorkIcon className={classes.icon} /> : ''}
        {card.icon === 'weeklyIcon' ? (
          <div className={classes.imgChange}>
            <img
              className={classes.imgOn}
              src="./../images/topIcon/WeeklySales.svg"
              alt="weeklyIcon"
            />
          </div>
        ) : (
          ''
        )}
        {card.icon === 'monthlyIcon' ? (
          <div className={classes.imgChange}>
            <img
              className={classes.imgOn}
              src="./../images/topIcon/MonthlySales.svg"
              alt="monthlyIcon"
            />
          </div>
        ) : (
          ''
        )}
        {card.icon === 'quaterlyIcon' ? (
          <div className={classes.imgChange}>
            <img
              className={classes.imgOn}
              src="./../images/topIcon/QuaterlySales.svg"
              alt="quaterlyIcon"
            />
          </div>
        ) : (
          ''
        )}
        {card.icon === 'salesIcon' ? (
          <div className={classes.imgChange}>
            <img
              className={classes.imgOn}
              src="./../images/topIcon/SalesTarget.svg"
              alt="salesIcon"
            />
          </div>
        ) : (
          ''
        )}
        {card.icon === 'clientRevenueIcon' ? (
          <div className={classes.imgChange}>
            <img
              className={classes.imgOn}
              src="./../images/topIcon/ClientRevenue.svg"
              alt="clientRevenueIcon"
            />
          </div>
        ) : (
          ''
        )}
        {card.icon === 'clientRankingIcon' ? (
          <div className={classes.imgChange}>
            <img
              className={classes.imgOn}
              src="./../images/topIcon/ClientRanking.svg"
              alt="clientRankingIcon"
            />
          </div>
        ) : (
          ''
        )}
        {card.icon === 'totalRevenueIcon' ? (
          <div className={classes.imgChange}>
            <img
              className={classes.imgOn}
              src="./../images/topIcon/TotalRevenue.svg"
              alt="totalRevenueIcon"
            />
          </div>
        ) : (
          ''
        )}
        {card.icon === 'contractIcon' ? (
          <div className={classes.imgChange}>
            <img
              className={classes.imgOn}
              src="./../images/topIcon/RegularContract.svg"
              alt="contractIcon"
            />
          </div>
        ) : (
          ''
        )}
        {card.icon === 'clientListIcon' ? (
          <div className={classes.imgChange}>
            <img
              className={classes.imgOn}
              src="./../images/topIcon/ClientList.svg"
              alt="clientListIcon"
            />
          </div>
        ) : (
          ''
        )}
        {card.icon === 'clientRegIcon' ? (
          <div className={classes.imgChange}>
            <img
              className={classes.imgOn}
              src="./../images/topIcon/ClientReg.svg"
              alt="clientRegIcon"
            />
          </div>
        ) : (
          ''
        )}
        <div className={classes.text}>
          <Typography className={classes.cardTitle}>{card.title}</Typography>
        </div>
      </ButtonBase>
    </div>
  )
}

export default MiniCardButton
