import React, { useContext, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'

import AppContext from '../../../../../contexts/AppContext'

const useStyles = makeStyles((Theme) => ({
  companyName: Theme.textBoldBLK,
  fwdrTitle: Theme.textNormalBLU,
  fwdrCell: {
    backgroundColor: Theme.palette.info.main,
    border: '1px solid #357CA7',
    borderRadius: '5px',
    padding: '8px',
  },
}))

/**
 * @return {JSX} 混載業者名欄
 */
const ForwarderLightCarAirFreight = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { state } = useContext(AppContext)

  return useMemo(() => {
    return (
      <Grid container justify="center">
        <Grid item md={10} className={classes.fwdrCell}>
          <span className={classes.fwdrTitle}>{`${t('D301V0038')}: `}</span>
          <span className={classes.companyName}>{state.Forwarder}</span>
        </Grid>
      </Grid>
    )
  }, [state.Forwarder, classes, t])
}

export default ForwarderLightCarAirFreight
