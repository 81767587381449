import React from 'react'
import { useTranslation } from 'react-i18next'

import TableCell from '../TableCell'
import DetailButton from '../../buttons/DetailButton'

/**
 * @return {JSX} Draftボタンコンポーネント
 */
const DraftButtonCell = ({ ...rest }) => {
  const { t } = useTranslation()

  return (
    <TableCell>
      <DetailButton {...rest}>{t('3rd0005')}</DetailButton>
    </TableCell>
  )
}

export default DraftButtonCell
