import React, { useContext } from 'react'

import AppContext from '../../../../../contexts/AppContext'
import CommonDatepicker from '../../../../commonUseContext/Bid/DatePicker/CommonDatePicker'
import { DATEPICKER } from '../../../../../actions'

/**
 * @return {JSX} Validity終了日Datepickerコンポーネント
 */
const FinishDatePickerBidInfo = () => {
  const { state } = useContext(AppContext)

  return (
    <CommonDatepicker
      stateName={'Validity'}
      stateKey={'finishDate'}
      actionType={DATEPICKER.SET_START_FINISH}
      disablePast
      minDate={state.Validity.startDate}
    />
  )
}

export default FinishDatePickerBidInfo
