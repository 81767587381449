import { combineReducers } from 'redux'

import TablesLightJhClientReg, {
  initTablesLightJhClientReg,
} from '../../common/TablesLightJhClientReg'
import Tabs, { initTabs } from '../../common/Tabs'
import Paging, { initPaging } from '../../common/Paging'
import AcceptAndRejectDialogJhClientRegSignUp, {
  initAcceptAndRejectDialogJhClientRegSignUp,
} from './AcceptAndRejectDialogJhClientRegSignUp'

const initialState = {
  Tabs: initTabs,
  Tables: initTablesLightJhClientReg,
  Dialog: initAcceptAndRejectDialogJhClientRegSignUp,
  Paging: initPaging,
}

export default combineReducers({
  Tabs,
  Tables: TablesLightJhClientReg,
  Dialog: AcceptAndRejectDialogJhClientRegSignUp,
  Paging,
})

export { initialState }
