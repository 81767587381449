// Display + 画面名
// JH/SHIP/FWDR/AIRLINE共用で使用
const DisplayMasterClientReg = {
  // 画面ID + 文言ID

  D303V0001: {
    en: 'Client Reg.',
    ja: 'クライアント登録',
  },
  D303V0002: {
    en: 'SIGN UP',
    ja: '登録',
  },
  D303V0003: {
    en: 'DELETE ACCOUNT',
    ja: '削除',
  },
  D303V0004: {
    en: 'REQUEST',
    ja: '申請',
  },
}
// Display + 画面名
export default DisplayMasterClientReg
