import React, { useReducer, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import LayoutBox from '../../../common/layout/LayoutBox'
import reducer, {
  initialState,
} from '../../../../reducers/master/lightCarFlightMgmt'
import AppContext from '../../../../contexts/AppContext'

import TitleLightCarFlightMgmt from './00TitleLightCarFlightMgmt/TitleLightCarFlightMgmt'
import FlightInfoAccordionContainer from './03AccordionCarFlightMgmt/FlightInfoAccordionContainer'
import CommonFunc from '../../../common/function/CommonFunc'
import {
  TABLES,
  AIRPORT,
  TRANSIT_TIME,
  COUNTRY_CODE,
  CARRIER_NAME,
  GSA,
} from '../../../../actions'
import ApiPaths from '../../../../constants/ApiPaths'
import PagingCarFlightMgmt from './05PagingCarFlightMgmt/PagingCarFlightMgmt'
import ButtonCarFlightMgmt from './06ButtonCarFlightMgmt/ButtonCarFlightMgmt'
import TabsAndSearchSortLightCarFlightMgmt from './01SearchSortLightCarFlightMgmt/TabsAndSearchSortLightCarFlightMgmt'
import IconTableLightCarFlightMgmt from './02IconTableCarFlightMgmt/IconTableLightCarFlightMgmt'
import { TabName } from './ConstantsCarFlightMgmt'

const MasterCarFlightMgmt = () => {
  const history = useHistory()
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    ;(async () => {
      const apiInfoArr = [
        {
          key: 'flightInfo',
          method: 'get',
          path: ApiPaths.MASTER_CAR.LIGHT_FLIGHT_MANAGEMENT,
        },
        { key: 'airport', method: 'get', path: ApiPaths.COMMON_API.AIRPORT },
        { key: 'transitTime', method: 'get', path: ApiPaths.MASTER_JH.TT },
        {
          key: 'countryCode',
          method: 'get',
          path: ApiPaths.MASTER_JH.COUNTRY_CODE,
        },
        {
          key: 'cartwoCode',
          method: 'get',
          path: ApiPaths.MASTER_CARRIER.CAR_TWOCODE,
        },
        {
          key: 'carrierName',
          method: 'get',
          path: ApiPaths.AUTHENTICATION.CARRIER_NAME_WITHOUT_GSA,
        },
        { key: 'gsa', method: 'get', path: ApiPaths.MASTER_CAR.LIGHT_GSA },
      ]
      const resObj = await CommonFunc.execApiAsync(apiInfoArr)
      const isApiSuccessful = CommonFunc.checkApiResponseObj(resObj, history)

      if (isApiSuccessful) {
        const {
          flightInfo,
          airport,
          transitTime,
          countryCode,
          carrierName,
          cartwoCode,
          gsa,
        } = resObj
        dispatch({
          type: AIRPORT.SET,
          data: airport.data.results,
        })
        dispatch({
          type: TRANSIT_TIME.SET,
          data: transitTime.data.results,
        })
        dispatch({
          type: COUNTRY_CODE.SET,
          data: countryCode.data.results,
        })
        dispatch({
          type: CARRIER_NAME.SET,
          data: carrierName.data.results,
          carriertwoCode: cartwoCode.data.results.carriertwoCode,
        })
        dispatch({
          type: TABLES.SET,
          tableData: flightInfo.data.results,
        })
        dispatch({
          type: GSA.SET,
          gsa: gsa.data.results.gsaFlg,
        })
      }
    })()
  }, []) //eslint-disable-line

  const isOpenFlightInfoTab = state.Tabs === TabName.flightInfo

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <LayoutBox>
        <TitleLightCarFlightMgmt />
        <TabsAndSearchSortLightCarFlightMgmt />
        {isOpenFlightInfoTab ? (
          <FlightInfoAccordionContainer />
        ) : (
          <IconTableLightCarFlightMgmt />
        )}
        <PagingCarFlightMgmt />
        <ButtonCarFlightMgmt />
      </LayoutBox>
    </AppContext.Provider>
  )
}

export default MasterCarFlightMgmt
