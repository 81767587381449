import { Grid } from '@material-ui/core'
import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import AppContext from '../../../../contexts/AppContext'
import CommonValidation from '../../../common/function/CommonValidation'
import AccordionLightBookingDetails from '../Accordion/AccordionLightBookingDetails'
import TextFieldMawbNoLightBookingDetails from './TextFieldMawbNoLightBookingDetails'

const useStyles = makeStyles((Theme) => ({
  haifun: {
    ...Theme.textNormalBLK,
    lineHeight: '48px',
  },
}))

/**
 * @param {Object} props 下記内容
 * @param {boolean} props.disabled 非活性状態
 * @return {JSX} MawbNo欄
 */
const MawbNoLightBookingDetails = ({ disabled }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { dispatch, state } = useContext(AppContext)

  const { prefix, firstHalf, latterHalf } = state.MawbNumber.mawbNumberObj

  // prefix入力欄
  const prefixTextField = useMemo(
    () => (
      <TextFieldMawbNoLightBookingDetails
        valueName={'prefix'}
        defaultValue={prefix}
        dispatch={dispatch}
        validation={CommonValidation.prefix}
        disabled={disabled}
      />
    ),
    [prefix, dispatch, disabled]
  )

  // MAWB前半の入力欄
  const firstHalfTextField = useMemo(
    () => (
      <TextFieldMawbNoLightBookingDetails
        valueName={'firstHalf'}
        defaultValue={firstHalf}
        dispatch={dispatch}
        validation={CommonValidation.mawbNumber}
        disabled={disabled}
      />
    ),
    [firstHalf, dispatch, disabled]
  )

  // MAWB後半の入力欄
  const latterHalfTextField = useMemo(
    () => (
      <TextFieldMawbNoLightBookingDetails
        valueName={'latterHalf'}
        defaultValue={latterHalf}
        dispatch={dispatch}
        validation={CommonValidation.mawbNumber}
        disabled={disabled}
      />
    ),
    [latterHalf, dispatch, disabled]
  )

  // ハイフン欄
  const haifun = useMemo(
    () => (
      <Grid item className={classes.haifun}>
        -
      </Grid>
    ),
    [classes]
  )

  return useMemo(
    () => (
      <AccordionLightBookingDetails label={t('D301V0112')}>
        <Grid container spacing={1} alignItems="flex-start">
          {prefixTextField}
          {haifun}
          {firstHalfTextField}
          {latterHalfTextField}
        </Grid>
      </AccordionLightBookingDetails>
    ),
    [t, state.MawbNumber.mawbNumberObj, disabled]
  ) //eslint-disable-line
}
MawbNoLightBookingDetails.propTypes = {
  disabled: PropTypes.bool.isRequired,
}
export default MawbNoLightBookingDetails
