import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'
import Common from '../../../constants/Common'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100px',
    height: '100px',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.info.main,
    margin: 'auto',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.fontColor.secondary,
    },
    '&:active': {
      backgroundColor: theme.palette.primary.light,
    },
    borderRadius: 5,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.60)',
    border: '1px solid #595959',
    whiteSpace: 'nowrap',
    padding: 0,
  },
  inputFileBtnHide: {
    width: '100px',
    height: '100px',
    opacity: 0,
    appearance: 'none',
    position: 'absolute',
  },
}))

/**
 * @param {Object} props - 以下
 * @param {Event} handleChange - 押下後のイベント
 * @returns {JSX.Element} - backボタンコンポーネント
 */
const PlusButtonBig = (props) => {
  const classes = useStyles()

  const handleChange = props.handleChange

  return (
    <IconButton variant="contained" className={classes.root}>
      {/* 次期対応: スマホでファイル選択ダイアログがどう表示されるか不明(要確認) */}
      <input
        id="input_file"
        type="file"
        accept={Common.FILE_TYPE.IMAGE}
        className={classes.inputFileBtnHide}
        onChange={(e) => handleChange(e)}
      />
      <AddIcon fontSize="large" />
    </IconButton>
  )
}

export default PlusButtonBig
