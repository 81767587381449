import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import AppContext from '../../../../../contexts/AppContext'
import Common from '../../../../../constants/Common'
import ConstantsBidMgmt from '../ConstantsBidMgmt'
import NotifyCheckIconBidMgmt from './NotifyCheckIconBidMgmt'
import DetailsButtonCellBidMgmt from './DetailsButtonCellBidMgmt'
import TableCellBidMgmt from './TableCellBidMgmt'

/**
 * 定数で定義した表データのコンポーネントを作成
 * @param {Object} tableConstantsObj ConstantsBidMgmtで定義したテーブルの要素
 * @param {Number} index mapのindex
 * @param {Object} state state useContextのstate
 * @param {Number} apiIndexNum APIの番号
 * @return {JSX} TableCellコンポーネント(1個分)
 */
const createTableCell = (tableConstantsObj, index, state, apiIndexNum) => {
  // 親から受けとったapiIndexNumとstateからデータを表示用データを取得
  const tableContentObj = state.Tables.showData[state.Paging - 1].find(
    (tableObj) => tableObj.apiIndexNum === apiIndexNum
  )
  const { displayObj } = tableContentObj
  // map処理用のkeyを作成
  const key = `${apiIndexNum}${index}`
  // 定数に紐づいている表示用のcell要素を取り出す
  const { cellKey, cellCategory } = tableConstantsObj
  const cellElement = displayObj[cellKey]

  switch (cellCategory) {
    case Common.CELL_CATEGORY.NOTIFY:
      return <NotifyCheckIconBidMgmt key={key} apiIndexNum={apiIndexNum} />
    case Common.CELL_CATEGORY.DETAILS:
      return <DetailsButtonCellBidMgmt apiIndexNum={apiIndexNum} key={key} />
    default:
      return <TableCellBidMgmt key={key} displayStr={cellElement} />
  }
}

/**
 * 1行分の表データを作成
 * @param {Number} { apiIndexNum } APIの番号
 * @return {JSX} TableCellコンポーネント(1行分)
 */
const TableCellsBidMgmt = ({ apiIndexNum }) => {
  const { state } = useContext(AppContext)

  return ConstantsBidMgmt.TABLE_ARR.map((tableConstantsObj, index) =>
    createTableCell(tableConstantsObj, index, state, apiIndexNum)
  )
}

TableCellsBidMgmt.propTypes = {
  apiIndexNum: PropTypes.number.isRequired,
}

export default TableCellsBidMgmt
