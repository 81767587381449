import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Grid, RadioGroup } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { LIGHT_BOOKING_DETAILS } from '../../../../actions'
import Common from '../../../../constants/Common'
import AppContext from '../../../../contexts/AppContext'
import Radio from '../../../common/radio/Radio'
import TextFieldCancelBookingLightBookingDetails from './TextFieldCancelBookingLightBookingDetails'

const useStyles = makeStyles(() => ({
  radio: {
    '&.MuiFormControlLabel-root': {
      height: '24px !important',
    },
    marginRight: '24px',
  },
}))

/**
 * @param {Object} props 下記内容
 * @param {Boolean} props.disabled 非活性状態
 * @return {JSX} Cancel欄のラジオボタン
 */
const RadioButtonBookingLightBookingDetails = ({ disabled }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { state, dispatch } = useContext(AppContext)

  return useMemo(() => {
    /**
     * ラジオボタンの更新
     * @param {*} event イベント
     * @returns {void} なし
     */
    const onChange = (event) => {
      if (event.target.type === 'radio' && !disabled) {
        const value = parseInt(event.target.value)
        dispatch({
          type: LIGHT_BOOKING_DETAILS.CANCEL_BOOKING.EDIT,
          cancelFlg:
            value === state.CancelBooking.cancelFlg
              ? Common.CANCEL_FLG.NONE
              : value,
          reason: '',
        })
      }
    }

    return (
      <Grid item md={4}>
        <RadioGroup value={state.CancelBooking.cancelFlg}>
          <Grid item>
            <Radio
              value={Common.CANCEL_FLG.SUSPENSION_OF_SHIPMENT}
              className={classes.radio}
              onClick={onChange}
              label={t('D111V0108')}
              disabled={disabled}
            />
          </Grid>
          <Grid item>
            <Radio
              value={Common.CANCEL_FLG.POSTPONE}
              className={classes.radio}
              onClick={onChange}
              label={t('D111V0109')}
              disabled={disabled}
            />
          </Grid>
          <Grid container item md={12}>
            <Grid item>
              <Radio
                value={Common.CANCEL_FLG.OTHER}
                className={classes.radio}
                onClick={onChange}
                label={t('D111V0110')}
                disabled={disabled}
              />
            </Grid>
            <TextFieldCancelBookingLightBookingDetails
              defaultValue={state.CancelBooking.reason}
              dispatch={dispatch}
              disabled={!state.CancelBooking.isOther || disabled}
              isOther={state.CancelBooking.isOther}
            />
          </Grid>
        </RadioGroup>
      </Grid>
    )
  }, [dispatch, state.CancelBooking.cancelFlg, t, disabled]) //eslint-disable-line
}

RadioButtonBookingLightBookingDetails.propTypes = {
  disabled: PropTypes.bool.isRequired,
}

export default RadioButtonBookingLightBookingDetails
