import { LIGHT_REGISTER_NEW_ACCOUNT } from '../../../actions'

const initFwdrName = {
  value: '',
  require: true,
}

/**
 * 選択した混載業者IDを更新
 * @param {Object} action - dispatchで受け取ったaction
 * @param {Object} state - state
 * @return {Object} - state
 */
const updateFwdrName = (action, state) => {
  const { value } = action
  return {
    ...state,
    value,
  }
}

/**
 * 混載業者名を保持
 * @param {Object} [state=initFwdrName] - state
 * @param {Object} action - action
 * @return {Object} - state
 */
const FwdrName = (state = initFwdrName, action) => {
  switch (action.type) {
    case LIGHT_REGISTER_NEW_ACCOUNT.FWDR_NAME.UPDATE:
      return updateFwdrName(action, state)
    default:
      return state
  }
}

export default FwdrName

export { initFwdrName }
