import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

import ConstantsBidMgmt from '../ConstantsBidMgmt'
import TableCell from '../../../../common/table/TableCell'
import TableRow from '../../../../common/table/TableRow'

const useStyles = makeStyles((theme) => ({
  tableHead: theme.commonTableHeader,
}))

/**
 * 定数から作成するヘッダーコンポーネント(1列分)
 * 変更したい場合は定数ConstantsBidMgmtを操作する
 * @return {JSX} ヘッダーコンポーネント(1列分)
 */
const HeaderBidMgmt = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  /**
   * 定数で定めたヘッダーコンポーネント(1個分)を作成
   * @param {String} { headerKey } 定数に設定している文言key
   * @return {JSX} ヘッダーコンポーネント(1個分)
   */
  const createHeaderCell = ({ headerKey }) => {
    return (
      <TableCell key={headerKey} className={classes.tableHead}>
        {t(headerKey)}
      </TableCell>
    )
  }

  return <TableRow>{ConstantsBidMgmt.TABLE_ARR.map(createHeaderCell)}</TableRow>
}

export default HeaderBidMgmt
