import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.buttonSize.width.xsmall,
    height: theme.buttonSize.height.xsmall,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primary.main,
    fontSize: theme.fontSize.header,
    backgroundColor: theme.palette.info.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.fontColor.secondary,
    },
    '&:active': {
      backgroundColor: theme.palette.primary.light,
    },
    boxShadow: 'none',
    borderRadius: 25,
    border: '1px solid #595959',
    boxSizing: 'border-box',
    whiteSpace: 'nowrap',
    padding: '0px',
    textTransform: 'none',
  },
}))

/**
 * @param {string} { addclass, ...rest } デザインを変更したい場合はaddclassを追加
 * @return {JSX} Editボタンコンポーネント
 */
const EditButton = ({ addclass, ...rest }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const className = addclass ? `${classes.root} ${addclass}` : classes.root

  return (
    <Button variant="contained" className={className} {...rest}>
      {t('D004V0061')}
    </Button>
  )
}

EditButton.propTypes = {
  addclass: PropTypes.string,
}

export default EditButton
