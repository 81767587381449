// Display + 画面名
const DisplayApiSample = {
  // 画面ID + 文言ID
  D00V0001: {
    en: 'Log out',
    ja: 'ログアウト',
  },
  D00V0002: {
    en: 'Log out',
    ja: 'ログアウト',
  },
}
// Display + 画面名
export default DisplayApiSample
