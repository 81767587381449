import React, { useContext, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'

import BackButtonLightBookingDetails from './BackButtonLightBookingDetails'
import SaveButtonLightBookingDetails from './SaveButtonLightBookingDetails'
import AppContext from '../../../../../contexts/AppContext'

const useStyles = makeStyles(() => ({
  margin: {
    marginTop: '2rem',
  },
}))

/**
 * @return {JSX} BackボタンとSaveボタン
 */
const ButtonsLightBookingDetails = () => {
  const { state } = useContext(AppContext)
  const classes = useStyles()
  const editable = !(state.Status.isCancel || state.Status.isClose)
  return useMemo(
    () => (
      <Grid item md={10}>
        <Grid
          container
          item
          justify="center"
          className={classes.margin}
          spacing={2}
        >
          <BackButtonLightBookingDetails />
          {editable ? <SaveButtonLightBookingDetails /> : null}
        </Grid>
      </Grid>
    ),
    [classes, editable]
  )
}

export default ButtonsLightBookingDetails
