import Paths from '../../../constants/Paths'
import CommonFunc from '../../../components/common/function/CommonFunc'

const initTopPath = {
  path: null,
}

/**
 * state: Q1の回答を保持
 * @param {Object} [state = initTable] -
 * @returns {*} -
 */
// 各業者のTOP画面のパスを取得
const topPath = (state = initTopPath) => {
  const userData = CommonFunc.getLoginUserData()
  if (userData.shipperJhId !== null) {
    //荷主 (JHユーザーはこの画面に遷移しない)
    state.path = Paths.SHIPPER.TOP
  } else if (userData.fwdrId !== null) {
    //混載業者
    state.path = Paths.FWDR.TOP
  } else if (userData.carrierId !== null) {
    //航空会社
    state.path = Paths.AIRLINE.TOP
  }
  return state
}

export default topPath

export { initTopPath }
