import React, { useContext, useMemo } from 'react'
import Grid from '@material-ui/core/Grid'

import AppContext from '../../../../../contexts/AppContext'
import AccordionContainer from '../../../../common/accordion/AccordionContainer'
import CommonCalc from '../../../../common/function/CommonCalc'
import CommonNoResult from '../../../../common/table/CommonNoResult'
import FlightInfoAccordion from './FlightInfoAccordion'
import AddButtonLightFlightMgmt from './AddButtonLightFlightMgmt'
import FlightInfoPopup from '../04FlightInfoPopup/FlightInfoPopup'

/**
 * FlightInfoタブに表示する内容を返す
 * @returns {JSX} FlightInfoコンポーネント
 */
const FlightInfoAccordionContainer = () => {
  const { state } = useContext(AppContext)
  const { Tables, Paging } = state

  return useMemo(() => {
    /** @type {Number} showDataのインデックス */
    const page = CommonCalc.calcShowDataIndex(Paging, Tables.showData.length)

    /** @type {JSX} アコーディオン全体 */
    const accordionContainer = (
      <AccordionContainer>
        {Tables.showData[page].map((_, index) => (
          <FlightInfoAccordion index={index} key={index} />
        ))}
      </AccordionContainer>
    )

    return (
      <>
        <Grid container justify="center">
          <Grid item md={10} xs={10}>
            {Tables.isNoResult ? <CommonNoResult /> : accordionContainer}
          </Grid>
          <Grid container justify="flex-end" item md={10} xs={10}>
            {state.Auth ? <AddButtonLightFlightMgmt /> : null}
          </Grid>
        </Grid>
        <FlightInfoPopup />
      </>
    )
  }, [Tables, Paging, state.Auth])
}
export default FlightInfoAccordionContainer
