/* eslint-disable prefer-template */
import React from 'react'
import MTable from '@material-ui/core/Table'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  table: theme.table,
}))

/**
 * @param {Object} props - 以下
 * @param {useStyles} className - スタイルを指定
 * @returns {JSX.Element} - Table
 */
const Table = (props) => {
  const classes = useStyles()
  return (
    <MTable className={classes.table + ' ' + props.className} padding="none">
      {props.children}
    </MTable>
  )
}

export default Table
