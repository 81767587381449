/**
 * objectの子、孫要素まで定数を凍結
 * @param {Object} obj オブジェクト
 * @return {void}
 */
const deepFreeze = (obj) => {
  Object.freeze(obj)
  for (const key in obj) {
    const item = obj[key]
    // 子要素オブジェクト判定フラグ
    // eslint-disable-next-line no-prototype-builtins
    const childObjFlg =
      obj.hasOwnProperty(key) && typeof item === 'object' && item !== null
    // 凍結フラグ
    const froozenFlg = Object.isFrozen(item)
    // 子要素がオブジェクトで凍結されていない場合は再帰的に呼び出して凍結
    if (childObjFlg && !froozenFlg) {
      deepFreeze(item)
    }
  }
}

// sessionStorageに保存されるデータKey
const sessionStorageKeyObj = {
  BEFORE_PATH: 'beforePath',
  CURRENT_PATH: 'currentPath',
  ERROR_PATH: 'errorPath',
  // ページスクロール設定
  OVERFLOW_SETTING: 'overflowSetting',
}
// localStorageKeyObjに保存されるデータKey
const localStorageKeyObj = {
  TOKEN: 'token',
  USER_INFO: 'userInfo',
  CURRENT_STATE: 'currentState',
  ERROR_STATE: 'errorState',
  UTC_INFO: 'utcInfo',
  LANGUAGE_KEY: 'currentLanguage',
}

// AirFreight画面の必須入力レート名
const requiredRateNameObj = {
  MINRATE: 'minrate',
  NORMAL: 'normal',
}

// AirFreight画面の任意入力レート名
const optionalRateNameObj = {
  MIN: 'min',
  LIGHT: 'light',
  MEDIUM: 'medium',
  LARGE: 'large',
  MAX: 'max',
}

const Common = {
  PAGE: 15,
  // ソート選択肢
  SORT_TERMS: {
    LATERY: 'latery',
    PAST: 'past',
    LOW_PRICE: 'lowPrice',
    HIGH_PRICE: 'highPrice',
    BEST_FLIGHTS: 'bestFlights',
    BEST_FLIGHTS_ETD: 'bestFlightsEtd',
    TRANSIT_TIME: 'transitTime',
    MAWB_NO_ASCENDING: 'MAWB No. Ascending',
    MAWB_NO_DESCENDING: 'MAWB No. Descending',
    HAWB_NO_ASCENDING: 'hawbNoAscending',
    HAWB_NO_DESCENDING: 'hawbNoDescending',
    STATUS: 'status',
    DOCS_PREP: 'docsPrep',
    READYDATE_ORDER: 'readyDateOrder',
    MAX_WGT_ORDER: 'maxWgtOrder',
    MAX_VOL_ORDER: 'maxVolOrder',
    PCS_ORDER: 'Pieces',
    WGT_ORDER: 'Weight',
    VOL_ORDER: 'Volume',
    ORG_ORDER: 'Origin',
    DST_ORDER: 'Destination',
    CAR_ORDER: 'carOrder',
    FWDR_ORDER: 'fwdrOrder',
    COMPANYNAME_ORDER: 'Company',
    SHIPPER_ORDER: 'shipperOrder',
    STATUS_ORDER: 'Status',
    STATUS_ID_ORDER: 'statusId',
    INQUIRIES_ORDER: 'inquiriesOrder',
    CARRIER_INQUIRIES_ORDER: 'CarrierInquiriesOrder',
    SHIPPER_INQUIRIES_ORDER: 'ShipperInquiriesOrder',
    ETD_ASCENDING: 'etdAscending',
    ETD_DESCENDING: 'etdDescending',
    SHC_ORDER: 'shcOrder',
    FLIGHT_NUMBER_ORDER: 'FlightNumberOrder',
    FLIGHT_NAME_ORDER: 'FlightName',
    FLIGHT_DATE_ORDER: 'FlightDateOrder',
    CASS_DATA_DATE: 'cassDataDate',
    CASS_DATA_DATE_ASCENDING: 'cassDataDateAscending',
    TOTAL_USER: 'Total User',
    CLIENT_LIST_STATUS: 'clientStatus',
    CARGO_READY_ORDER: 'CargoReady',
    TAKE_OFF_DATE: 'takeoffDateOrder',
    TAKE_OFF_DATE_DESENDING: 'Past',
    CREATED_AT: 'CreatedAt',
    CREATED_AT_DESENDING: 'CreatedAtDesending',
    VALIDITY_ASCENDING: 'validityAscending',
    CONSIGNEE_ASCENDING: 'consigneeAscending',
    PIC_ASCENDING: 'picAscending',
    DEADLINE_ASCENDING: 'deadlineAscending',
    DETAILS_ASCENDING: 'detailsAscending',
    NOTIFY_ASCENDING: 'notifyAscending',
    SELECT_ORDER: 'selectOrder',
    NO_ORDER: 'noOrder',
    TRANSIT_TIME_ORDER: 'transitTimeOrder',
    TRANSIT_ORDER: 'transitOrder',
    FLIGHT_TYPE_ORDER: 'flightTypeOrder',
    COMMODITY_ORDER: 'commodityOrder',
    TERMS_ORDER: 'termsOrder',
    LT_ORDER: 'ltOrder',
    FSC_ORDER: 'fscOrder',
    FSC_CURRENCY_ORDER: 'fscCurrencyOrder',
    MINRATE_ORDER: 'minrateOrder',
    NORMAL_ORDER: 'normalOrder',
    MIN_ORDER: 'minOrder',
    LIGHT_ORDER: 'lightOrder',
    MEDIUM_ORDER: 'mediumOrder',
    LARGE_ORDER: 'largeOrder',
    MAX_ORDER: 'maxOrder',
    CURRENCY_ORDER: 'currencyOrder',
    REMARK_ORDER: 'remarkOrder',
    COMPANYNAME_ASCENDING: 'Ascending order',
    COMPANYNAME_DESCENDING: 'Descending order',
    CODE: 'code',
    SHIPPER_ASCENDING: 'shipperAscending',
    AIRLINE_ORDER: 'airlineOrder',
    RATE_ORDER: 'rateOrder',
    EMAIL_ASCENDING: 'emailAscending',
    TEL_ASCENDING: 'telAscending',
    PLAN_ORDER: 'plan order',
  },
  // StorageのKey
  KEY: {
    ...sessionStorageKeyObj,
    ...localStorageKeyObj,
  },
  SESSION_STORAGE_KEY_ARR: Object.values(sessionStorageKeyObj),
  LOCAL_STORAGE_KEY_ARR: Object.values(localStorageKeyObj),
  // API status code
  RESPONSE_STATUS_CODE: {
    OK: 200,
    NG: 400,
    ACCEPT: 202,
    CREATED: 201,
  },
  // error code
  ERROR_CODE: {
    NORMAL: 0,
    BAD_REQUEST: 1,
    NOT_UPDATED: 2,
    INVALID_PARAMS: 3,
    WRONG_PASSWORD: 4,
    FAIL_UPLOAD: 5,
    NOT_UPLOAD_FILE: 6,
    ALREADY_USER: 7,
    RELOAD_NEW_INFO: 8,
    ALREADY_REGISTERED_CNSL_CENTER: 9,
    ALREADY_REGISTERED_CNSL_BRANCH: 10,
    ALREADY_REGISTERED_FWDR_COMPANY: 11,
    ALREADY_REGISTERED_SERVICE_OFFICE: 12,
    ALREADY_REGISTERED_AIRPORT: 13,
    DUPLICATED_WITH_OTHER_USERS: 14,
    ALREADY_DELETE_ACCOUNT: 15,
    ALREADY_REGISTERED_EMAIL: 17,
    INVALID_USER: 18,
    MAXMUM_USER: 19,
    FWDR_FREE_PLAN: 20,
    FWDR_STANDARD_PLAN: 21,
    CAR_STANDARD_PLAN: 22,
    NO_TOKEN: 30,
    INVALID_TOKEN: 31,
    FORBIDDEN: 32,
    DELETED_USER: 33,
    PASSWORD_LIMIT: 34,
    INVALID_URL: 40,
    EXPIRED_URL: 41,
    MAIL_SYSTEM_ERROR: 97,
    TIMEOUT_ERROR: 98,
    NETWORK_ERROR: 99,
    SYSTEM_ERROR: 100,
    UNDER_MAINTENANCE: 999,
  },
  // CASSフラグ
  CASS_FLG: {
    OK: 0,
    NO_EDIT: 1,
    NO_FILE: 2,
  },
  // ページ分類
  // frontend\src\constants\Paths.jsと関連付け
  PAGE_CATEGORIES: {
    SHIPPER: 'CATEGORY_SHIPPER',
    FWDR: 'CATEGORY_FWDR',
    AIRLINE: 'CATEGORY_AIRLINE',
    JH: 'CATEGORY_JH',
    FREE_LOGIN: 'FREE_LOGIN',
    FREE_ACCESS: 'FREE_ACCESS',
    MANAGER_PAGE: 'MANAGER_PAGE',
    REGISTER_PAGE: 'REGISTER_PAGE',
  },
  USER_TYPE: {
    SHIPPER: 'SHIPPER',
    FWDR: 'FWDR',
    AIRLINE: 'AIRLINE',
    JH: 'JH',
  },
  //awbStatus
  AWB_STATUS: {
    ALREADY_USED: 1,
    UNUSED: 2,
    USE: 3,
    VOID: 4,
  },
  //Status
  STATUS: {
    QUOTE_REQUEST: {
      ID: 1,
      TEXT: 'Quote Request',
    },
    QUOTE_OFFERING: {
      ID: 2,
      TEXT: 'Quote Offering',
    },
    QUOTE_APPROVED: {
      ID: 3,
      TEXT: 'Quote Approved',
    },
    PENDING: {
      ID: 4,
      TEXT: 'Pending',
    },
    SPACE_REQUEST: {
      ID: 5,
      TEXT: 'Space Request',
    },
    SPACE_CONFIRMED: {
      ID: 6,
      TEXT: 'Space Confirmed',
    },
    CGO_READY: {
      ID: 7,
      TEXT: 'Cgo Ready',
    },
    BOND_IN: {
      ID: 8,
      TEXT: 'Bond-in',
    },
    BOOKED: {
      ID: 9,
      TEXT: 'Booked',
    },
    FBL: {
      ID: 10,
      TEXT: 'FBL',
    },
    IN_TRANSIT: {
      ID: 11,
      TEXT: 'In transit',
    },
    ARRIVED: {
      ID: 12,
      TEXT: 'Arrived',
    },
    BOND_OUT: {
      ID: 13,
      TEXT: 'Bond-out',
    },
    DELIVERED: {
      ID: 14,
      TEXT: 'Delivered',
    },
    OFF_LOADED: {
      ID: 15,
      TEXT: 'Off-loaded',
    },
    DOCUMENT_DEFICIENCY: {
      ID: 16,
      TEXT: 'Document Deficiency',
    },
    CARGO_DAMAGE: {
      ID: 17,
      TEXT: 'CargoDamage',
    },
    CANCEL: {
      ID: 18,
      TEXT: 'Cancel',
    },
    BOOKING_REQUEST: {
      ID: 19,
      TEXT: 'Booking Request',
    },
    LIGHT_SPACE_CONFIRMED: {
      ID: 20,
      TEXT: 'Space Confirmed',
    },
    CLOSE: {
      ID: 21,
      TEXT: 'Close',
    },
  },
  STATUS_ARR: [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
  ],
  /** Tracing画面で時間を表示するstatus一覧 */
  TRACING_STATUS_ARR: [
    'cgoReady',
    'bondIn',
    'loaded',
    'departed',
    'inTransit',
    'arrive',
    'unload',
    'bondOut',
  ],
  ORG_DST_FLG: {
    ORG: 0,
    DST: 1,
    OTHER: 2,
  },
  COMMODITY: {
    GEN: {
      ID: 1,
      TEXT: 'GEN',
    },
    DG: {
      ID: 2,
      TEXT: 'DG',
    },
    ICE: {
      ID: 3,
      TEXT: 'ICE',
    },
  },
  DEL_FLG: {
    ON: 1,
    OFF: 0,
  },
  LANGUAGE: {
    EN: 'en',
    JA: 'ja',
  },
  GROUP: {
    BRANCH: {
      ID: 0,
      TEXT: 'Branch',
    },
    CNSL_CENTER: {
      ID: 1,
      TEXT: 'CNSL Center',
    },
    CNSL_BRANCH: {
      ID: 2,
      TEXT: 'CNSL Branch',
    },
  },
  CNSL_REQ: {
    NONE: {
      ID: 0,
      TEXT: 'None',
    },
    REQUEST: {
      ID: 1,
      TEXT: 'Request',
    },
    APPROVE: {
      ID: 2,
      TEXT: 'Approve',
    },
    REJECT: {
      ID: 3,
      TEXT: 'Reject',
    },
  },
  UPDATED_BY: {
    NONE: {
      ID: 0,
      TEXT: 'None',
    },
    CARRIER: {
      ID: 1,
      TEXT: 'Carrier',
    },
    CNSL_CENTER: {
      ID: 2,
      TEXT: 'CNSL Center',
    },
    BRANCH: {
      ID: 3,
      TEXT: 'Branch',
    },
    DELETE_BY_CARRIER: {
      ID: 4,
      TEXT: 'Delete by Carrier',
    },
    DELETE_BY_CNSL_CENTER: {
      ID: 5,
      TEXT: 'Delete by CNSL Center',
    },
    DELETE_BY_BRANCH: {
      ID: 6,
      TEXT: 'Delete by Branch',
    },
  },
  SATISFACTION: {
    SATISFACTION: {
      ID: '1',
      TEXT: 'Satisfaction',
    },
    SOMEWHAT_SATISFIED: {
      ID: '2',
      TEXT: 'Somewhat Satisfaction',
    },
    USUALLY: {
      ID: '3',
      TEXT: 'Usually',
    },
    SOMEWHAT_DISSATISFIED: {
      ID: '4',
      TEXT: 'Somewhat dissatisfied',
    },
    DISSATISFACTION: {
      ID: '5',
      TEXT: 'Dissatisfaction',
    },
  },
  // FwdrAwbMgmtのタブ
  AWB_TAB: {
    MAWB: 0,
    HAWB: 1,
    CNSL: 2,
  },
  // 業者毎のタブ
  CLIENT_TAB: {
    SHIPPER: 0,
    FWDR: 1,
    CARRIER: 2,
  },
  // ドキュメント・画像アップロード時のファイルの種類
  FILE_TYPE: {
    IMAGE: '.jpg,.jpeg',
    DOC: '.pdf,.zip',
  },
  CLASS_NAME: {
    /** APIを投げている間、ページチェックが通るまでコンテンツを非表示にするため */
    PAGE_CONTENTS: 'pageContents',
  },
  YES_NO: {
    YES: 'yes',
    NO: 'no',
  },
  //Prepaid, Collect欄の定数
  PREPAID_COLLECT: {
    PREPAID: '0',
    COLLECT: '1',
  },
  PER: {
    KG: 'kg',
  },
  RATE: {
    TYPE: {
      GEN: 'gen',
      DG: 'dg',
      ICE: 'ice',
    },
    RATE_NAME: {
      ...requiredRateNameObj,
      ...optionalRateNameObj,
    },
    REQUIRED_NAME: requiredRateNameObj,
    OPTIONAL_NAME: optionalRateNameObj,
  },
  FSC: {
    INPUT_TYPE: {
      RATE: 'rate',
      CHECK: 'check',
    },
  },
  MAX_DOCUMENT: 5,
  //管理者権限
  AUTHORITY_FLG: {
    ON: 1,
    OFF: 0,
  },
  //TERMS
  TERMS: {
    PP: 'PP',
    CC: 'CC',
  },
  //HAWBSelect
  HAWB_SELECT: {
    HAWB_STOCK: '1',
    DIRECT_AWB: '2',
  },
  // Document
  DOCUMENT: {
    NAME: {
      MAWB: 'mawb',
      HAWB: 'hawb',
      INVOICE: 'invoice',
      PACKING_LIST: 'packingList',
    },
    TYPE: {
      DELETE: 'del',
      ADD: 'add',
    },
  },
  // Share・Addポップアップで使用する定数
  BTN_NAME: {
    SHIPPER: 'shipper',
    AIRLINE: 'airline',
    BOTH: 'both',
  },
  TO_SHIPPER_CARRIER_FLG: {
    ON: 1,
    OFF: 0,
  },
  // ドキュメント追加時定数
  ADD_DOCUMENT: {
    ID: 'input_file',
    TYPE: 'file',
  },
  UPLOAD_FILE_TYPE: {
    ZIP: 'zip',
    PDF: 'pdf',
  },
  SPECIAL_NOTE_ARR: [
    'specialNoteA',
    'specialNoteB',
    'specialNoteC',
    'specialNoteD',
    'specialNoteE',
  ],
  SPECIAL_NOTE_SETTING_ARR: [
    { placeholderKeyStr: '3rd0010' },
    { placeholderKeyStr: '3rd0011' },
    { placeholderKeyStr: '3rd0012' },
    { placeholderKeyStr: '' },
    { placeholderKeyStr: '' },
  ],
  //OtherPDFで使用するprefixの配列
  PDF_PREFIX_ARR: [
    'pdfAPrefix',
    'pdfBPrefix',
    'pdfCPrefix',
    'pdfDPrefix',
    'pdfEPrefix',
  ],
  //荷主のConfirmBooking画面で使用するpdfのリクエスト名の配列
  PDF_ARR: ['pdfA', 'pdfB', 'pdfC', 'pdfD', 'pdfE'],
  //DocumentのAuthorizationで使用するSelectフラグ
  SELECT_FLG: {
    ON: 1,
    OFF: 0,
  },
  FUEL_SUR_CHARGE: {
    NO_RATE: '0.00',
    SET_NO_RATE: 0,
  },
  //Document画面で使用するdueAgent, dueCarrierのocFlg
  DOCUMENT_OC_FLG: {
    DUE_AGENT: 1,
    DUE_CARRIER: 2,
  },
  //入力済みかのフラグ
  ENTERD_FLG: {
    ON: 1,
    OFF: 0,
  },
  //ClientList画面で使用するstatus
  USER_STATUS: {
    CLOSED: {
      ID: 1,
      TEXT: 'Closed',
    },
    DELETE_ACCOUNT_REQUEST: {
      ID: 2,
      TEXT: 'Delete account request',
    },
    IN_USE: {
      ID: 3,
      TEXT: 'In use',
    },
    NEW_ACCOUNT_REQUEST: {
      ID: 4,
      TEXT: 'New account request',
    },
  },
  AREA_CODE_ARR: ['', 'TC1', 'TC2', 'TC3'],
  //変換記号
  CONVERSION_SYM: {
    //改行記号
    NEW_LINE: {
      SYM: '!',
    },
  },
  // BidMgmt画面のDetailsのボタンの種類
  DETAILS_BUTTON: {
    CHECK: 'check',
    DRAFT: 'draft',
  },
  // TableCellコンポーネントの種類
  CELL_CATEGORY: {
    NO: 'no',
    CHECKBOX: {
      SELECT: 'checkboxSelect',
      NA: 'checkboxNA',
    },
    SELECT: {
      AIRPORT: 'selectAirport',
      AIRLINE: 'selectAirline',
      FLIGHT: 'selectFlight',
      COMMODITY: 'selectCommodity',
      TERMS: 'selectTerms',
      LT: 'selectLT',
      TT: 'selectTT',
      CURRENCY: 'selectCurrency',
    },
    TEXT_FIELD: {
      TTL_WGT: 'textFieldTtlWgt',
      TTL_VOL: 'textFieldTtlVol',
      REMARK: 'textFieldRemark',
      RATE: 'textFieldRate',
      FSC: 'textFieldFsc',
      DETAILS: 'textFieldDetails',
    },
    BUTTON: {
      DELETE: 'buttonDelete',
      SHOW: 'buttonShow',
      CHECK: 'buttonCheck',
      EDIT: 'buttonEdit',
    },
    NOTIFY: 'notify',
    DETAILS: 'details',
    TEXT: 'text',
    CHECK: 'check',
    STATUS: 'status',
    SEARCH_RESULT: {
      ICON: 'icon',
      DATE: 'date',
      AIRPORT: 'airport',
      RATE: 'rate',
      FSC: 'fsc',
      TOTAL: 'total',
    },
  },
  BID_STATUS: {
    REQUEST: {
      ID: 1,
      TEXT: 'Request',
    },
    UPDATE: {
      ID: 2,
      TEXT: 'Update',
    },
    CLOSE: {
      ID: 3,
      TEXT: 'Close',
    },
    LOST: {
      ID: 4,
      TEXT: 'Lost',
    },
    NA: {
      ID: 5,
      TEXT: 'N/A',
    },
  },
  // 既読状態
  READ_FLG: {
    READ: true,
    UNREAD: false,
  },
  DRAFT_FLG: {
    ON: 1,
    OFF: 0,
  },
  // 共通ダイアログのボタンカテゴリ
  DIALOG: {
    BUTTON_CATEGORY: {
      OK: 'okButton',
      CANCEL: 'cancelButton',
      DELETE: 'deleteButton',
      SAVE: 'saveButton',
      BACK: 'backButton',
    },
  },
  // ラジオボタンの選択肢
  RADIO_SELECT: {
    UNSPECIFIED: 'unspecified',
    SPECIFIED: 'specified',
  },
  SET_FWDR_FLG: {
    UNSPECIFIED: false,
    SPECIFIED: true,
  },
  FLIGHT_TYPE: {
    DIRECT: 1,
    TRANSIT: 2,
  },
  HIDE_FLG: {
    OFF: 0,
    ON: 1,
  },
  GSA_FLG: {
    CARRIER: 0,
    GSA: 1,
  },
  ALL_IN_RATE: {
    OFF: 0,
    ON: 1,
  },
  CANCEL_FLG: {
    NONE: 0,
    SUSPENSION_OF_SHIPMENT: 1,
    POSTPONE: 2,
    OTHER: 3,
  },
  CARGO_DETAILS_FLG: {
    DEFAULT: '0',
    YES: '1',
    NO: '2',
  },
  PASSWORD_LENGTH: {
    MIN: 8,
    MAX: 15,
  },
  ACCOUNT_TYPE: {
    SHIPPER: 1,
    FWDR: 2,
    CARRIER: 3,
    GSA: 4,
    JH: 5,
  },
  DISPLAY_FLG: {
    ON: 1,
    OFF: 0,
  },
}

// objectの子、孫要素まで定数化
deepFreeze(Common)

export default Common
