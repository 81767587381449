import React, { useContext, useEffect } from 'react'

import { PAGING } from '../../actions'
import AppContext from '../../contexts/AppContext'
import PagingComponent from '../common/buttons/Paging'
import CommonCalc from '../common/function/CommonCalc'

/**
 * 共通ページコンポーネント
 * @return {JSX} 共通ページコンポーネント
 */
const Paging = () => {
  const { state, dispatch } = useContext(AppContext)

  /** @type {Number} 合計ページ */
  const amountPageNum = state.Tables.showData.length
  /** @type {Number} 現在ページ */
  // 値を0~合計ページの範囲に収める
  const currentPageNum = CommonCalc.clamp(state.Paging, 0, amountPageNum)

  /**
   * ページ変更時のイベント
   * @param {Event} _ 使用しない
   * @param {Number} page 変更後のページ
   * @return {void}
   */
  const changeEvent = (_, page) => {
    // ページの先頭に移動
    window.scrollTo(0, 0)

    dispatch({
      type: PAGING.SET,
      page,
    })
  }

  // currentPageNumとstate.Pagingの値が不一致の場合にページを更新する
  useEffect(() => {
    if (currentPageNum !== state.Paging) {
      changeEvent(null, currentPageNum)
    }
  }, [currentPageNum]) //eslint-disable-line

  const pagingFactors = {
    changeEvent,
    amount: amountPageNum,
    page: currentPageNum,
  }

  return <PagingComponent pagingfactors={pagingFactors} />
}

export default Paging
