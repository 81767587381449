// Display + 画面名
const DisplayAirlineShowDetails = {
  // 画面ID + 文言ID
  D215V0001: {
    en: 'SHOW DETAILS',
    ja: '詳細を表示',
  },
  D215V0002: {
    en: 'Owner',
    ja: 'オーナー',
  },
  D215V0003: {
    en: 'Panasonic Corporation',
    ja: 'パナソニック株式会社',
  },
  D215V0004: {
    en: '1006, Oaza Kadoma, Kadoma-shi, Osaka 571-8501, Japan',
    ja: '571-8501大阪府門真市大字門真1006',
  },
  D215V0005: {
    en: 'Tel.',
    ja: '電話。',
  },
  D215V0006: {
    en: '+81-6-6908-1121',
    ja: '+81-6-6908-1121',
  },
  D215V0007: {
    en: 'Representative',
    ja: '代表',
  },
  D215V0008: {
    en: 'Konosuke Matsushita',
    ja: '松下幸之助',
  },
  D215V0009: {
    en: 'Manager',
    ja: 'マネージャー',
  },
  D215V0010: {
    en: 'Supervisor',
    ja: 'スーパーバイザー',
  },
  D215V0011: {
    en: 'Leader',
    ja: 'リーダー',
  },
  D215V0012: {
    en: '-',
    ja: '-',
  },
  D215V0013: {
    en: 'Lighting Dept.',
    ja: '照明部',
  },
  D215V0014: {
    en: 'kmatsushita@panasonic.co.jp / +81-X-XXX-XXXX',
    ja: 'kmatsushita@panasonic.co.jp / +81-X-XXX-XXXX',
  },
  D215V0015: {
    en: 'Validity',
    ja: '有効',
  },
  D215V0016: {
    en: 'August 1, 2020',
    ja: '2020年8月1日',
  },
  D215V0017: {
    en: 'to',
    ja: '～',
  },
  D215V0018: {
    en: 'January 31, 2021',
    ja: '2021年1月31日',
  },
  D215V0019: {
    en: 'Set FWDR',
    ja: 'FWDRを設定',
  },
  D215V0020: {
    en: 'DZK/KNJ/EIK/NEC/YLK/KWE/HEI',
    ja: 'DZK/KNJ/EIK/NEC/YLK/KWE/HEI',
  },
  D215V0021: {
    en: 'Currency',
    ja: '通貨',
  },
  D215V0022: {
    en: 'JPY - Japanese Yen',
    ja: 'JPY-日本円',
  },
  D215V0023: {
    en: '1st Deadline',
    ja: '1次締め切り',
  },
  D215V0024: {
    en: '2nd Deadline',
    ja: '2次締め切り',
  },
  D215V0025: {
    en: '3rd Deadline',
    ja: '3次締め切り',
  },
  D215V0026: {
    en: 'July 31, 2020',
    ja: '2020年7月31日',
  },
  D215V0027: {
    en: 'August 31, 2020',
    ja: '2020年8月31日',
  },
  D215V0028: {
    en: 'Special Notes',
    ja: '特記事項',
  },
  D215V0029: {
    en: '・Please follow the schedule strictly.',
    ja: '・スケジュールは厳守してください。',
  },
  D215V0030: {
    en: "・We'll inform you as soon as we decide.",
    ja: '・決定次第お知らせします。',
  },
  D215V0031: {
    en: '1. DZK Rates - FORECAST',
    ja: '1. DZKレート-予測',
  },
  D215V0032: {
    en: 'Norikuni Inaba',
    ja: '稲葉憲邦',
  },
  D215V0033: {
    en: 'Air Export Manager',
    ja: '航空輸出マネージャー',
  },
  D215V0034: {
    en: 'ninaba@dzk.co.jp / +81-X-XXXX-XXXX',
    ja: 'ninaba@dzk.co.jp / +81-X-XXXX-XXXX',
  },
  D215V0035: {
    en: 'ORG/DST',
    ja: '出発/到着',
  },
  D215V0036: {
    en: 'Via',
    ja: '経由',
  },
  D215V0037: {
    en: 'TTL Wgt.',
    ja: 'TTL Wgt.',
  },
  D215V0038: {
    en: 'TTL Vol.',
    ja: 'TTL Vol.',
  },
  D215V0039: {
    en: 'SHC',
    ja: 'SHC',
  },
  D215V0040: {
    en: '+45kg',
    ja: '+45kg',
  },
  D215V0041: {
    en: '+100kg',
    ja: '+100kg',
  },
  D215V0042: {
    en: '+300kg',
    ja: '+300kg',
  },
  D215V0043: {
    en: '+500kg',
    ja: '+500kg',
  },
  D215V0044: {
    en: '+1,000kg',
    ja: '+1,000kg',
  },
  D215V0045: {
    en: 'NRT/MNL',
    ja: 'NRT/MNL',
  },
  D215V0046: {
    en: 'NRT/LON',
    ja: 'NRT/LON',
  },
  D215V0047: {
    en: 'NRT/ORD',
    ja: 'NRT/ORD',
  },
  D215V0048: {
    en: 'FRA',
    ja: 'FRA',
  },
  D215V0049: {
    en: 'ANC',
    ja: 'ANC',
  },
  D215V0050: {
    en: '1,000kg - 3000kg',
    ja: '1,000kg - 3000kg',
  },
  D215V0051: {
    en: '300m3 - 500m3',
    ja: '300m3 - 500m2',
  },
  D215V0052: {
    en: 'GEN',
    ja: 'GEN',
  },
  D215V0053: {
    en: 'DG',
    ja: 'DG',
  },
  D215V0054: {
    en: 'ICE',
    ja: 'ICE',
  },
  D215V0055: {
    en: '600',
    ja: '600',
  },
  D215V0056: {
    en: '850',
    ja: '850',
  },
  D215V0057: {
    en: '650',
    ja: '650',
  },
  D215V0058: {
    en: '540',
    ja: '540',
  },
  D215V0059: {
    en: '820',
    ja: '820',
  },
  D215V0060: {
    en: '620',
    ja: '620',
  },
  D215V0061: {
    en: '510',
    ja: '510',
  },
  D215V0062: {
    en: '780',
    ja: '780',
  },
  D215V0063: {
    en: '580',
    ja: '580',
  },
  D215V0064: {
    en: '480',
    ja: '480',
  },
  D215V0065: {
    en: '720',
    ja: '720',
  },
  D215V0066: {
    en: '530',
    ja: '530',
  },
  D215V0067: {
    en: '680',
    ja: '680',
  },
  D215V0068: {
    en: '2. KNJ Rates - FINAL',
    ja: '2. KNJレート-最終',
  },
  D215V0069: {
    en: '3. EIK Rates - FORECAST',
    ja: '3. EIKレート-予測',
  },
  D215V0070: {
    en: '4.NEC Rates - FINAL RVSD',
    ja: '4.NECレート-最終RVSD',
  },
  D215V0071: {
    en: 'Please click the box if you agree to be unable to cancel/change the offered lanes during the BID term.',
    ja: 'BID期間中に提供されたレーンをキャンセルまたは変更できないことに同意する場合は、ボックスをクリックしてください。',
  },
  D215V0072: {
    en: 'Confirm',
    ja: '確認',
  },
  D215V0073: {
    en: '440',
    ja: '440',
  },
  D215V0074: {
    en: 'DZK',
    ja: 'DZK',
  },
  D215V0075: {
    en: 'NEC',
    ja: 'NEC',
  },
  D215V0076: {
    en: 'EIK',
    ja: 'EIK',
  },
  D215V0077: {
    en: 'etc.',
    ja: 'etc.',
  },
  D215V0078: {
    en: 'OK',
    ja: '決定',
  },
  D215V0079: {
    en: 'Cancel',
    ja: 'キャンセル',
  },
  D215V0080: {
    en: 'Edit',
    ja: '編集',
  },
  D215V0081: {
    en: 'Please input the specified rate.',
    ja: '指定されたレートを入力してください。',
  },
  D215V0082: {
    en: 'Currency : JPY',
    ja: '通貨：JPY',
  },
}
// Display + 画面名
export default DisplayAirlineShowDetails
