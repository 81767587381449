// Display + 画面名
const DisplayShipperShippingDetails = {
  // 画面ID + 文言ID

  D004V0001: {
    en: 'BULK SEARCH',
    ja: '一括検索',
  },
  D004V0002: {
    en: 'Cargo Ready Date',
    ja: '出荷日',
  },
  D004V0003: {
    en: 'Terms',
    ja: '貿易条件',
  },
  D004V0004: {
    en: 'Origin',
    ja: '出発空港',
  },
  D004V0005: {
    en: 'Destination',
    ja: '到着空港',
  },
  D004V0006: {
    en: 'Commodity',
    ja: '品物',
  },
  D004V0007: {
    en: 'SHC Code',
    ja: 'SHCコード',
  },
  D004V0008: {
    en: 'T/T',
    ja: 'T/T',
  },
  D004V0009: {
    en: 'Packing',
    ja: '梱包',
  },
  D004V0010: {
    en: 'Pickup',
    ja: '集荷',
  },
  D004V0011: {
    en: "Shipper's Address",
    ja: '荷送人の住所',
  },
  D004V0012: {
    en: "Consignee's Address",
    ja: '荷受人の住所',
  },
  D004V0013: {
    en: 'Cargo Details',
    ja: '貨物詳細',
  },
  D004V0014: {
    en: 'Pcs.',
    ja: '個数',
  },
  D004V0015: {
    en: 'Gross Wgt. (kg)',
    ja: '総重量(kg)',
  },
  D004V0016: {
    en: 'Length(cm)',
    ja: '縦(cm)',
  },
  D004V0017: {
    en: 'Width(cm)',
    ja: '横(cm)',
  },
  D004V0018: {
    en: 'Height(cm)',
    ja: '高さ(cm)',
  },
  D004V0019: {
    en: 'Volume(m3)',
    ja: '容積(m3)',
  },
  D004V0020: {
    en: 'Chargeable Wgt.(kg)',
    ja: '賃率適用重量(kg)',
  },
  D004V0021: {
    en: 'Non-stackable',
    ja: '段積み禁止',
  },
  D004V0022: {
    en: 'Hazardous',
    ja: '危険物',
  },
  D004V0023: {
    en: '1.',
    ja: '1.',
  },
  D004V0024: {
    en: '2.',
    ja: '2.',
  },
  D004V0025: {
    en: '3.',
    ja: '3.',
  },
  D004V0026: {
    en: '4.',
    ja: '4.',
  },
  D004V0027: {
    en: '5.',
    ja: '5.',
  },
  D004V0028: {
    en: 'Cargo Summary',
    ja: '貨物概要',
  },
  D004V0029: {
    en: 'Total',
    ja: '合計',
  },
  D004V0030: {
    en: 'G/W.',
    ja: 'G/W.',
  },
  D004V0031: {
    en: 'kg',
    ja: 'kg',
  },
  D004V0032: {
    en: 'Vol.',
    ja: '容量.',
  },
  D004V0033: {
    en: 'C/W.',
    ja: 'C/W.',
  },
  D004V0034: {
    en: 'm3',
    ja: 'm3',
  },
  D004V0035: {
    en: 'July 31,2020',
    ja: '2020/7/31',
  },
  D004V0036: {
    en: 'CIP - Carriage and Insurance Paid to',
    ja: 'CIP - 運送費・保険料込み',
  },
  D004V0037: {
    en: "KIX - Kansai Int'l AirPort",
    ja: 'KIX - 関西国際空港',
  },
  D004V0038: {
    en: "SFO - San Francisco Int'l Airport",
    ja: 'SFO - サンフランシスコ国際空港',
  },
  D004V0039: {
    en: '--Select--',
    ja: '--選択してください--',
  },
  D004V0040: {
    en: 'Enter comm describe',
    ja: '品物の説明を入力',
  },
  D004V0041: {
    en: 'COL,ICE',
    ja: 'COL,ICE',
  },
  D004V0042: {
    en: '1-2 Days',
    ja: '1-2日',
  },
  D004V0043: {
    en: 'In Case of ‘’Yes’’, there is a possibility of additional packing fee being charged.',
    ja: '「はい」の場合、追加梱包料が発生する可能性がございます。',
  },
  D004V0044: {
    en: '--Preferred Time--',
    ja: '--優先時間--',
  },
  D004V0045: {
    en: '--Pickup Address--',
    ja: ' -- 集荷場所 -- ',
  },
  D004V0046: {
    en: 'Panasonic Corporation',
    ja: 'パナソニック株式会社',
  },
  D004V0047: {
    en: '1006, Oaza Kadoma, Kadoma-shi, Osaka 571-8501, Japan',
    ja: '571-8501　大阪府 門真市 大字門真 1006',
  },
  D004V0048: {
    en: 'Tel. +81-6-6908-1121 Attn: Konosuke Matsushita',
    ja: 'Tel. 06-6908-1121　担当：松下幸之助',
  },
  D004V0049: {
    en: 'Panasonic R&D Company of America',
    ja: 'アメリカのパナソニックR＆Dカンパニー',
  },
  D004V0050: {
    en: '205 Ravendale Dr, Mountain View, CA 94043, United States',
    ja: '205 Ravendale Dr、Mountain View、CA 94043、United States',
  },
  D004V0051: {
    en: 'Tel. +1-408-861-3900 Attn: James Collins',
    ja: 'Tel. + 1-408-861-3900 担当：James Collins',
  },
  D004V0052: {
    en: 'XXX',
    ja: 'XXX',
  },
  D004V0053: {
    en: 'XXX.XX',
    ja: 'XXX.XX',
  },
  D004V0054: {
    en: 'X.XX',
    ja: 'X.XX',
  },
  D004V0055: {
    en: 'Select favorite',
    ja: 'お気に入り',
  },
  D004V0056: {
    en: 'Search',
    ja: '検索',
  },
  D004V0057: {
    en: 'Select favorite',
    ja: 'お気に入り選択',
  },
  D004V0058: {
    en: 'Yes',
    ja: 'はい',
  },
  D004V0059: {
    en: 'No',
    ja: 'いいえ',
  },
  D004V0060: {
    en: 'Delete',
    ja: '削除',
  },
  D004V0061: {
    en: 'Edit',
    ja: '編集',
  },
  D004V0062: {
    en: 'OK',
    ja: 'OK',
  },
  D004V0063: {
    en: 'Cancel',
    ja: 'キャンセル',
  },
  D004V0064: {
    en: '1006, Oaza Kadoma, Kadoma-shi',
    ja: '門真市 大字門真 1006',
  },
  D004V0065: {
    en: 'Osaka 571-8501, Japan',
    ja: '571-8501　大阪府 ',
  },
  D004V0066: {
    en: 'Please enter no more than 5 single-byte numbers.',
    ja: '半角数字5桁以下で入力してください。',
  },
  D004V0067: {
    en: 'Required field.',
    ja: '必須項目です',
  },
  D004V0068: {
    en: '3-4 Days',
    ja: '3-4日',
  },
  D004V0069: {
    en: '5-6 Days',
    ja: '5-6日',
  },
  D004V0070: {
    en: '7Days or more',
    ja: '7日以上',
  },
  D004V0071: {
    en: '8am-12pm',
    ja: '8時～12時',
  },
  D004V0072: {
    en: '12pm-14pm',
    ja: '12時～14時',
  },
  D004V0073: {
    en: '14pm-16pm',
    ja: '14時～16時',
  },
  D004V0074: {
    en: '16pm-18pm',
    ja: '16時～18時',
  },
  D004V0075: {
    en: '18pm-20pm',
    ja: '18時～20時',
  },
  D004V0076: {
    en: '20pm-22pm',
    ja: '20時～22時',
  },
  D004V0077: {
    en: '22pm-24pm',
    ja: '22時～24時',
  },
  D004V0078: {
    en: 'Decimals are valid to the third decimal place.',
    ja: '小数点以下は第三位まで有効です。',
  },
  D004V0079: {
    en: 'Are you sure?',
    ja: '本当によろしいですか？',
  },
  D004V0080: {
    en: "You don't have an address in your favorites.",
    ja: 'お気に入りに登録している住所がありません。',
  },
  D004V0081: {
    en: 'Contains invalid characters.',
    ja: '無効な文字が含まれています。',
  },
  D004V0082: {
    en: 'Please enter a number greater than 0.',
    ja: '0より大きい数値を入力してください。',
  },
  D004V0083: {
    en: 'is not registrated.',
    ja: 'は登録されていません。',
  },
  D004V0084: {
    en: 'Please ask administrator to registrate.',
    ja: '登録は管理者に依頼してください。',
  },
  D004V0085: {
    en: 'Back',
    ja: '戻る',
  },
  D004V0086: {
    en: 'Pickup Address',
    ja: '集荷場所',
  },
  D004V0087: {
    en: '--Preferred Date--',
    ja: '--集荷希望日--',
  },
  D004V0088: {
    en: 'Invalid Date Format',
    ja: '無効な日付です。',
  },
  D004V0089: {
    en: 'Date should not be before minimal date',
    ja: '日付が最小値を超えています。',
  },
  D004V0090: {
    en: 'Date should not be after maximal date',
    ja: '日付が最大値を超えています。',
  },
}
// Display + 画面名
export default DisplayShipperShippingDetails
