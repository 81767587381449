import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import AppContext from '../../../../../contexts/AppContext'
import Theme from '../../../../common/theme/Theme'
import RegisterNewAccountSendDialog from '../../../../common/dialog/RegisterNewAccountSendDialog'
import SecondaryButton from '../../../../common/buttons/SecondaryButton'
import Paths from '../../../../../constants/Paths'
import ApiPaths from '../../../../../constants/ApiPaths'
import CommonFunc from '../../../../common/function/CommonFunc'
import AuthenticationTableContainer from '../../../../common/table/AuthenticationTableContainer'
import Common from '../../../../../constants/Common'

const useStyles = makeStyles(() => ({
  section: Theme.signup.section,
  button: Theme.signup.button,
}))

/**
 * @returns {JSX} - ボタンコンポーネント
 */
const ButtonLightAirlineRegisterNewAccount = () => {
  const { state } = useContext(AppContext)
  const history = useHistory()
  const { t } = useTranslation()
  const classes = useStyles()
  const [errCode, setErrCode] = useState('')
  const [sendComp, setSendComp] = useState(false)

  /**
   * ボタン活性・非活性処理
   * @returns {boolean} - true: 非活性 false: 活性
   */
  const inputCheck = () => {
    // 必須チェック
    const requireList = Object.values(state).filter((obj) =>
      Object.prototype.hasOwnProperty.call(obj, 'require')
    )
    const isRequireCheck = requireList
      .filter((requireObj) => requireObj.require)
      .every((requireObj) => requireObj.value)
    if (!isRequireCheck) {
      return true
    }
    // Icon必須チェック
    if (
      state.CarrierName.gsaFlg === Common.GSA_FLG.CARRIER &&
      !state.Icon.iconData
    ) {
      return true
    }
    // エラーチェック
    const isErrorCheck = !Object.values(state).some(
      ({ errorObj }) => errorObj?.checkResult
    )
    if (!isErrorCheck) {
      return true
    }
    return false
  }

  /**
   * 送信完了イベント
   * @returns {void}
   */
  const sendComplete = () => {
    setSendComp(false)
  }

  const apiSignupRequest = async () => {
    const req = {
      carrierNameId: state.CarrierName.value,
      airportId: state.Airport.value,
      firstName: state.FirstName.value,
      lastName: state.LastName.value,
      position: state.Position?.value ?? '',
      mail: state.Email.value,
      tel: state.Tel.value,
      postalCode: state.PostalCode.value,
      address: state.Address.value,
      cityId: state.Branch.value,
      gsaFlg: state.CarrierName.gsaFlg,
      carPlanId: state.CarPlan.value,
    }
    // 航空会社のみ
    if (state.CarrierName.gsaFlg === Common.GSA_FLG.CARRIER) {
      req.iataOrCassNumber = state.IataCassNo.value
      req.affilation = state.Department.value
      req.file = state.Icon.value
    }
    const apiInfoArr = [
      {
        key: 'resData',
        method: 'post',
        path: ApiPaths.AUTHENTICATION.LIGHT_CAR_SIGN_UP_REQUEST,
        req,
      },
    ]
    const resObj = await CommonFunc.execApiAsync(apiInfoArr)
    const isApiSuccessful = CommonFunc.checkApiResponseObj(resObj, history)
    if (isApiSuccessful) {
      setSendComp(true)
      setErrCode(resObj.resData.data.info.ErrorCode)
    }
  }

  /** アカウント登録確認用Dialog用のprops */
  const sendFactors = {
    path: Paths.AUTHENTICATION.SIGN_IN,
    sendevent: apiSignupRequest,
    comp: sendComp,
    disabled: inputCheck(),
    errcode: errCode,
    compevent: sendComplete,
  }

  return (
    <AuthenticationTableContainer>
      <Grid container justify="center">
        <div className={classes.section}>
          <Grid>
            <RegisterNewAccountSendDialog
              sendFactors={sendFactors}
              setErrCode={setErrCode}
            />
          </Grid>
          <Grid>
            <SecondaryButton
              path={Paths.AUTHENTICATION.SIGN_IN}
              addclass={classes.button}
            >
              {t('D501V0051')}
            </SecondaryButton>
          </Grid>
        </div>
      </Grid>
    </AuthenticationTableContainer>
  )
}

export default ButtonLightAirlineRegisterNewAccount
