import { LIGHT_BOOKING_DETAILS } from '../../../actions'

/** @type {Object} Remarkポップアップの初期値 */
const initRemarkPopup = {
  setOpen: () => {},
  remarkObj: {
    remark: null,
    inputError: false,
    placeholder: '',
  },
}

/**
 * Remarkポップアップを開く関数を登録
 * @param {Object} action action
 * @param {Function} action.setOpen ポップアップを開く関数
 * @param {Object} state state
 * @returns {Object} state
 */
const setOpenFunc = (action, state) => {
  const { setOpen } = action
  return { ...state, setOpen }
}

/**
 * Remarkポップアップを開く
 * @param {Object} action action
 * @param {Object} state state
 * @returns {Object} state
 */
const open = (action, state) => {
  state.setOpen(true)
  const remarkObj = {
    ...action,
    inputError: action.remark === null,
  }
  return { ...state, remarkObj }
}

/**
 * Remarkを編集
 * @param {Object} action action
 * @param {Object} state state
 * @returns {Object} state
 */
const edit = (action, state) => {
  const { remark } = action
  state.remarkObj.remark = remark
  state.remarkObj.inputError = remark === null
  return { ...state }
}

/**
 * Remarkポップアップの編集データを保持
 * @param { Object } [state = initRemarkPopup] state
 * @param { Object } action action
 * @return { Object } state
 */
const RemarkPopup = (state = initRemarkPopup, action) => {
  const { type, ...rest } = action
  switch (type) {
    case LIGHT_BOOKING_DETAILS.REMARK_POPUP.SET_OPEN_FUNC:
      return setOpenFunc(rest, state)
    case LIGHT_BOOKING_DETAILS.REMARK_POPUP.OPEN:
      return open(rest, state)
    case LIGHT_BOOKING_DETAILS.REMARK_POPUP.EDIT:
      return edit(rest, state)
    default:
      return state
  }
}

export default RemarkPopup

export { initRemarkPopup }
