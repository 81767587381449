import React from 'react'

import SearchSort from '../../../../commonUseContext/SearchSort'
import ConstantsBidMgmt from '../ConstantsBidMgmt'

/**
 * @return {JSX} サーチソートコンポーネント
 */
const SearchSortBidMgmt = () => {
  return <SearchSort sortItemArr={ConstantsBidMgmt.SORT_ITEM_ARR} />
}

export default SearchSortBidMgmt
