// Display + 画面名
const DisplayShipperTop = {
  D003V0001: {
    en: 'BULK',
    ja: '一括',
  },
  D003V0002: {
    en: 'SEARCH',
    ja: '検索',
  },
  D003V0003: {
    en: 'when booking',
    ja: 'ご予約はこちらから',
  },
  D003V0004: {
    en: 'CARGO',
    ja: '貨物',
  },
  D003V0005: {
    en: 'TRACE',
    ja: '追跡',
  },
  D003V0006: {
    en: 'when tracking',
    ja: '追跡はこちらから',
  },
  D003V0007: {
    en: 'JOINING',
    ja: '入札に',
  },
  D003V0008: {
    en: 'BID',
    ja: '参加',
  },
  D003V0009: {
    en: 'when offering',
    ja: '入札はこちらから',
  },
  D003V0010: {
    en: 'Announcement',
    ja: 'お知らせ',
  },
  D003V0011: {
    en: 'Notification',
    ja: '通知',
  },
  D003V0012: {
    en: 'Member function',
    ja: '会員機能',
  },
  D003V0013: {
    en: 'Bulk search',
    ja: '一括検索',
  },
  D003V0014: {
    en: 'Cargo trace',
    ja: '貨物追跡',
  },
  D003V0015: {
    en: 'Joining bid',
    ja: '入札に参加',
  },
  D003V0016: {
    en: 'Other',
    ja: 'その他',
  },
  D003V0017: {
    en: 'Language switching',
    ja: '言語切り替え',
  },
  D003V0018: {
    en: 'Help',
    ja: 'ヘルプ',
  },
  D003V0019: {
    en: 'Sign out',
    ja: 'サインアウト',
  },
  D003V0020: {
    en: 'Sign in',
    ja: 'サインイン',
  },
  D003V0021: {
    en: 'Menu',
    ja: 'メニュー',
  },
}

// Display + 画面名
export default DisplayShipperTop
