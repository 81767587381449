import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { LIGHT_FLIGHT_MGMT } from '../../../../../actions'
import Common from '../../../../../constants/Common'
import AppContext from '../../../../../contexts/AppContext'
import CheckBox from '../../../../common/checkBox/CheckBox'
import Content from '../03AccordionCarFlightMgmt/Content'

/**
 * @returns {JSX} Routing全体のHide欄
 */
const Hide = () => {
  const { t } = useTranslation()
  const { state, dispatch } = useContext(AppContext)

  /** @type {Boolean} チェック状態 */
  const checked = Boolean(state.Popup.getFlightInfoParam('hide'))

  return useMemo(() => {
    /**
     * Hideチェック更新
     * @param {*} event イベント
     * @returns {void} なし
     */
    const onChange = (event) => {
      dispatch({
        type: LIGHT_FLIGHT_MGMT.EDIT.FLIGHT_INFO,
        hide: event.target.checked ? Common.HIDE_FLG.ON : Common.HIDE_FLG.OFF,
      })
    }

    return (
      <Content
        title={t('D301V0238')}
        content={
          <CheckBox
            checked={checked}
            onChange={onChange}
            disabled={!state.Auth}
          />
        }
        row
      />
    )
  }, [checked, dispatch, state.Auth, t])
}
export default Hide
