import React from 'react'

import ConstantsBidDetails from '../ConstantsBidDetails'
import BidCheckboxes from '../../../../commonUseContext/Bid/BidCheckboxes'

/**
 * checkboxを表示
 * @return {JSX} チェックボックスコンポーネント
 */
const CheckboxesBidDetails = () => {
  return <BidCheckboxes checkboxArr={ConstantsBidDetails.CHECKBOX_ARR} />
}

export default CheckboxesBidDetails
