import { combineReducers } from 'redux'

import Q1, { initQ1 } from './Q1'
import Q2, { initQ2 } from './Q2'
import Q3, { initQ3 } from './Q3'
import Q4, { initQ4 } from './Q4'
import FwdrGroup, { initFwdrGroup } from './FwdrGroup'

const initialState = {
  Q1: initQ1,
  Q2: initQ2,
  Q3: initQ3,
  Q4: initQ4,
  FwdrGroup: initFwdrGroup,
}

export default combineReducers({
  Q1,
  Q2,
  Q3,
  Q4,
  FwdrGroup,
})

export { initialState }
