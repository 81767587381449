import { makeStyles } from '@material-ui/core'
import React, { useReducer } from 'react'

import OkButtton from '../../../../common/buttons/OkButton'
import reducer, {
  initialState,
} from '../../../../../reducers/master/lightDeleteAccountDone'

const useStyles = makeStyles((theme) => ({
  ok: theme.backButtonCenter,
}))

const ButtonLightDeleteAccountDone = () => {
  const classes = useStyles()

  const [state] = useReducer(reducer, initialState)

  return (
    <div className={classes.ok}>
      <OkButtton path={state.topPath} checked={true} />
    </div>
  )
}

export default ButtonLightDeleteAccountDone
