import React, { useContext } from 'react'

import DocumentLightBookingDetails from '../../../../commonUseContext/LightBookingDetails/Document/DocumentLightBookingDetails'
import AppContext from '../../../../../contexts/AppContext'
import { DocumentArrName } from '../../../../../reducers/common/lightBookingDetails/Document'

/**
 * @return {JSX} Documentアコーディオン
 */
const DocumentFwdrLightBookingDetails = () => {
  const { state } = useContext(AppContext)
  const editable = !(state.Status.isCancel || state.Status.isClose)
  return (
    <DocumentLightBookingDetails
      documentArrName={DocumentArrName.fwdrDocumentArr}
      editable={editable}
    />
  )
}

export default DocumentFwdrLightBookingDetails
