import { createMuiTheme } from '@material-ui/core/styles'
// スマホ画面は対象外になったので一旦コメントアウト
// import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
// import { isMobile, isSafari } from 'react-device-detect'
// const breakpoints = createBreakpoints({})

const Theme = createMuiTheme({
  root: {
    margin: 0,
  },
  overrides: {
    '.MuiAppBar-root': {
      boxShadow: 'none',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      // スマホ画面は対象外になったので一旦コメントアウト
      // md: isMobile || (isSafari && document.ontouchstart !== 'undefined') ? 9999 : 960,
      md: 0,
      lg: 1440,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      light: '#5F7CB8',
      main: '#375CA7',
      dark: '#264074',
    },
    secondary: {
      light: '#FFD25D',
      main: '#FFC735',
      dark: '#B28B25',
    },
    info: {
      light: '#FBFBFB',
      main: '#FAFAFA',
      dark: '#AFAFAF',
    },
    back: {
      light: '#FBFBFB',
      main: '#FAFAFA',
      dark: '#AFAFAF',
    },
    border: {
      white: '#FAFAFA',
      black: '#595959',
      blue: '#375CA7',
    },
    reject: {
      light: '#FF3737',
      main: '#FF0606',
      dark: '#B20404',
    },
    required: '#FF0606',
    requiredDark: '#C80000',
    letter: '#595959',
  },
  fontColor: {
    main: '#595959',
    secondary: '#FAFAFA',
    tertiary: '#FFC735',
    info: '#375CA7',
  },
  fontSize: {
    paging: '18px',
    title: '48px',
    regular: '16px',
    header: '14px',
    stepbar: '12px',
    cardButton: '30px',
    cardButtonSm: '24px',
  },
  buttonSize: {
    width: {
      xsmall: '80px',
      small: '120px',
      medium: '180px',
      large: '220px',
    },
    height: {
      xsmall: '25px',
      small: '35px',
      medium: '40px',
      large: '45px',
    },
  },
  typography: {
    fontFamily: ['Helvetica Neue', 'MS PGothic'].join(','),
  },
  title: {
    margin: '62px auto 42px auto',
  },
  margin: {
    marginTop: '62px',
  },
  header: {
    height: 90,
  },
  titlePC: {
    fontFamily: ['Helvetica Neue', 'MS PGothic'].join(','),
    fontSize: 48,
    color: '#375CA7',
    fontWeight: 'bold',
  },
  titleMobile: {
    fontFamily: ['Helvetica Neue', 'MS PGothic'].join(','),
    fontSize: 24,
    color: '#375CA7',
    fontWeight: 'bold',
  },
  textNormalBLK: {
    fontFamily: ['Helvetica Neue', 'MS PGothic'].join(','),
    fontSize: 16,
    color: '#595959',
    lineHeight: 1.2,
  },
  textNormalBLU: {
    fontFamily: ['Helvetica Neue', 'MS PGothic'].join(','),
    fontSize: 16,
    color: '#375CA7',
    lineHeight: 1.2,
  },
  textNormalRED: {
    fontFamily: ['Helvetica Neue', 'MS PGothic'].join(','),
    fontSize: 16,
    color: '#FF0606',
    lineHeight: 1.2,
  },
  textSmallBLK: {
    fontFamily: ['Helvetica Neue', 'MS PGothic'].join(','),
    fontSize: 14,
    color: '#595959',
    lineHeight: 1.2,
  },
  textSmallBLU: {
    fontFamily: ['Helvetica Neue', 'MS PGothic'].join(','),
    fontSize: 14,
    color: '#375CA7',
    lineHeight: 1.2,
  },
  textSmallRED: {
    fontFamily: ['Helvetica Neue', 'MS PGothic'].join(','),
    fontSize: 14,
    color: '#FF0606',
    lineHeight: 1.2,
  },
  saveText: {
    fontFamily: ['Helvetica Neue', 'MS PGothic'].join(','),
    fontSize: 22,
    color: '#595959',
    lineHeight: 1.2,
  },
  textBoldBLU: {
    fontFamily: ['Helvetica Neue', 'MS PGothic'].join(','),
    fontSize: 16,
    fontWeight: 'bold',
    color: '#375CA7',
    lineHeight: 1.2,
  },
  textBoldBLK: {
    fontFamily: ['Helvetica Neue', 'MS PGothic'].join(','),
    fontSize: 16,
    fontWeight: 'bold',
    color: '#595959',
    lineHeight: 1.2,
  },
  textBoldRED: {
    fontFamily: ['Helvetica Neue', 'MS PGothic'].join(','),
    fontSize: 16,
    fontWeight: 'bold',
    color: '#FF0606',
    lineHeight: 1.2,
  },
  table: {
    borderCollapse: 'separate',
    minWidth: '1100px',
    '& td': {
      border: '0px solid',
      borderBottom: '1px solid',
      borderRight: '1px solid',
    },
    '& td:first-child': {
      borderLeft: '1px solid',
    },
    '& tr:last-child td:last-child': {
      borderRadius: '0px 0px 5px 0px',
    },
    '& tr:last-child td:first-child': {
      borderRadius: '0px 0px 0px 5px',
    },
    '& tr:first-child td:last-child': {
      borderRadius: '0px 5px 0px 0px',
    },
    '& tr:first-child td:first-child': {
      borderRadius: '5px 0px 0px 0px',
    },
    '& tr:first-child td': {
      borderTop: '1px solid',
    },
  },
  tableNone: {
    borderCollapse: 'separate',
    minWidth: '1100px',
    '& td': {
      border: '0px solid',
      borderBottom: '1px solid',
    },
    '& td:first-child': {
      borderLeft: '1px solid',
    },
    '& td:last-child': {
      borderRight: '1px solid',
    },
    '& tr:last-child td:last-child': {
      borderRadius: '0px 0px 5px 0px',
    },
    '& tr:last-child td:first-child': {
      borderRadius: '0px 0px 0px 5px',
    },
    '& tr:first-child td:last-child': {
      borderRadius: '0px 5px 0px 0px',
    },
    '& tr:first-child td:first-child': {
      borderRadius: '5px 0px 0px 0px',
    },
    '& tr:first-child td': {
      borderTop: '1px solid',
    },
  },
  tableCell: {
    color: '#375CA7',
    fontFamily: ['Helvetica Neue', 'MS PGothic'].join(','),
    fontSize: 16,
    border: '1px solid #375CA7',
    textAlign: 'center',
  },
  accordion: {
    width: '100%',
    fontFamily: ['Helvetica Neue', 'MS PGothic'].join(','),
    color: '#595959',
    marginBottom: '1rem',
    '& .MuiAccordion-root.Mui-expanded': {
      margin: '0px',
    },
    '& .MuiRadio-colorSecondary.Mui-checked': {
      color: '#375CA7',
    },
    '& .MuiAccordion-root:before': {
      position: 'unset',
    },
    '& .MuiAccordion-rounded': {
      borderRadius: '5px',
    },
    '& .MuiPaper-root': {
      background: 'transparent',
    },
  },
  accordionSummary: {
    border: '1px solid',
    borderRadius: 5,
    borderColor: '#375CA7',
    background: '#FAFAFA',
  },
  /** accordionのGrid設定 先頭の余白 */
  accordionGrid: {
    marginLeft: '3rem',
  },
  /** accordionDetailsのGrid設定の 必ず発生するaccordionSummaryとの右余白差分 */
  accordionDetailsGrid: {
    marginRight: '48px',
  },
  textField: {
    width: '100%',
    borderColor: '#375CA7',
    '& .MuiInputBase-input': {
      fontFamily: ['Helvetica Neue', 'MS PGothic'].join(','),
      paddingLeft: '1rem',
      color: '#595959 !important',
    },
    '& .MuiInputLabel-root': {
      fontFamily: ['Helvetica Neue', 'MS PGothic'].join(','),
      color: '#595959 !important',
    },
    '& .MuiFilledInput-root': {
      background: 'rgba(51, 51, 51, 0.06) !important',
      fontFamily: ['Helvetica Neue', 'MS PGothic'].join(','),
      color: '#595959 !important',
    },
  },
  textFieldBLU: {
    width: '100%',
    borderColor: '#375CA7',
    '& .MuiInputBase-input': {
      fontFamily: ['Helvetica Neue', 'MS PGothic'].join(','),
      paddingLeft: '1rem',
      color: '#375CA7 !important',
      height: 30,
    },
    '& .MuiInputLabel-root': {
      fontFamily: ['Helvetica Neue', 'MS PGothic'].join(','),
      color: '#375CA7 !important',
      height: 30,
    },
    '& .MuiFilledInput-root': {
      background: 'rgba(51, 51, 51, 0.06) !important',
      fontFamily: ['Helvetica Neue', 'MS PGothic'].join(','),
      color: '#375CA7 !important',
      height: 30,
    },
  },
  dialog: {
    '& .MuiDialogContent-root': {
      padding: 0,
      width: 'fit-content',
      margin: '10px auto',
    },
    '& .MuiDialogContent-root:first-child': {
      paddingBottom: 20,
    },
  },
  dialogContent: {
    border: '1px solid #375CA7',
    borderRadius: '5px',
    width: 'fit-content',
    padding: '0px',
    margin: '1rem',
    paddingBottom: '1rem',
    background: '#FAFAFA',
  },
  textDialogHead: {
    fontFamily: ['Helvetica Neue', 'MS PGothic'].join(','),
    fontSize: 16,
    fontWeight: 'bold',
    color: '#375CA7',
    lineHeight: 1.2,
    width: '100%',
    textAlign: 'center',
    paddingTop: 5,
    paddingBottom: 5,
  },
  hr: {
    borderWidth: 0,
    height: '1px',
    backgroundColor: '#375CA7',
  },
  center: {
    width: 'fit-content',
    margin: '0px auto',
  },
  noResult: {
    // center
    width: 'fit-content',
    margin: '0px auto',
    // textNormalBLK
    fontFamily: ['Helvetica Neue', 'MS PGothic'].join(','),
    fontSize: 16,
    color: '#595959',
    lineHeight: 1.2,
  },
  tooltip: {
    backgroundColor: '#FAFAFA',
    color: '#375CA7',
    border: '1px solid #375CA7',
    borderRadius: 0,
    fontSize: 14,
    fontFamily: ['Helvetica Neue', 'MS PGothic'].join(','),
  },
  paperBack: {
    flexGrow: 1,
    width: '1225px',
    backgroundColor: '#FAFAFA',
    borderColor: '#375CA7',
  },
  // TOP画面の配置
  topOuter: {
    width: '100%',
    // 画面全体からheader,footer,margin分を引いた高さ
    minHeight: 'calc(100vh - 180px - 1rem)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  // TOP画面の配置(ヘッダーフッター無し)
  topOuterNoHeader: {
    width: '100%',
    minHeight: 'calc(100vh - 1rem)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  topInner: {
    margin: '2rem auto',
  },
  //TextFieldの隣に表示する文言用のPadding
  textFieldCommentPadding: {
    paddingTop: '1rem',
  },
  //高さを文字1行、横幅を要素に合わせる
  fitContent: {
    width: 'fit-content',
    height: '1rem',
  },
  //横幅のみ要素に合わせる
  fitContentWidth: {
    width: 'fit-content',
  },
  //ポップアップの警告アイコン用のMarginを設定
  warningIcon: {
    marginRight: 10,
  },
  //影なしのEditボタン
  editButtonNoShadow: {
    boxShadow: 'none',
    fontFamily: ['Helvetica Neue', 'MS PGothic'].join(','),
    fontSize: 16,
    color: '#375CA7',
    lineHeight: 1.2,
  },
  //Document画面で使用するDivider用
  documentDivider: {
    borderTop: '1px solid #375CA7',
    marginTop: 50,
  },
  //Document画面で使用する見出しのタイトル用
  documentHeaderText: {
    marginTop: 30,
  },
  //開くダイアログのボタン用
  inputFileBtnHide: {
    width: '80px',
    height: '25px',
    opacity: 0,
    appearance: 'none',
    position: 'absolute',
  },
  //PDFボタン
  pdfButton: {
    width: 'fit-content',
    height: 'fit-content',
    fontFamily: ['Helvetica Neue', 'MS PGothic'].join(','),
    fontSize: '16px',
    color: '#375CA7',
    '&:hover': {
      color: '#264074',
    },
    '&:active': {
      color: '#5F7CB8',
    },
    borderRadius: 25,
  },
  //PDFボタンのアイコン
  pdfButtonIcon: {
    width: 38,
    height: 49,
    marginBottom: '-0.5rem',
  },
  // BulkSearch・Tracing画面のspecialNotesで使用するroot
  specialNotesRoot: {
    fontFamily: ['Helvetica Neue', 'MS PGothic'].join(','),
    fontSize: '14px',
    lineHeight: '16px',
  },
  // BulkSearch画面のSpecialNotesで使用するmargin
  specialNotesRootMargin: {
    marginTop: '12px',
  },
  // BulkSearch画面のspecialNotesの見出しで使用するdesktop
  specialNotesDesktop: {
    display: 'none',
  },
  // specialNotesの入力、編集欄で使用できるTextBox
  specialNotesTextBox: {
    overflowY: 'hidden',
    width: '100%',
    color: '#595959',
    backgroundColor: 'rgba(51, 51, 51, 0.06)',
    border: '1px solid',
    borderRadius: '5px',
    borderColor: '#375CA7',
    paddingTop: '4px',
    paddingLeft: '1rem',
    paddingRight: '1rem',
  },
  // PickupAddress画面で使用する下マージン
  pickupMarginBottom: {
    marginBottom: '2rem',
  },
  // specialNotesの表示で使用するTextFieldの非活性状態
  specialNotesDisabled: {
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'rgba(0, 0, 0, 1)',
    },
    width: '95%',
  },
  // Tracing画面のspecialNotesで使用するflex
  specialNotesFlex: {
    width: '100%',
    display: 'flex',
  },
  // 影有りのeditボタン
  editButtonShadow: {
    color: '#375CA7',
    marginTop: '20px',
    marginLeft: '10px',
  },
  // Tracing画面のspecialNotesDialogで使用するダイアログ内のwidth指定
  specialNotesDialogWidth: {
    width: '450px',
  },
  // Tracing画面のspecialNotesDialogで使用するTextFieldのwidth指定
  specialNotesEditTextField: {
    width: '85%',
  },
  // AirFreight画面・FSC欄で表示するAll-in Rate文言用
  allInRate: {
    fontFamily: ['Helvetica Neue', 'MS PGothic'].join(','),
    fontWeight: 'bold',
    color: '#595959',
    fontSize: 14,
  },
  // AirFreight画面・FSC欄で表示するAll-in Rateのチェックボックスの表示位置指定
  fscCheck: {
    marginLeft: '-20px',
  },
  // AirFreight画面・FSC欄で表示する入力欄Margin指定
  fscMargin: {
    marginTop: '47px',
    marginBottom: '5px',
  },
  //文字列を途中で改行する
  wordBreak: {
    wordBreak: 'break-word',
  },
  //非活性のチェックボックスでも文字だけ通常テキストにする
  disabledCheckBoxText: {
    '& .MuiFormControlLabel-label.Mui-disabled': {
      color: '#595959',
    },
  },
  //非活性のラジオボタンの色を#595959にする
  disabledRadioButtonColor: {
    '& .MuiRadio-colorPrimary.Mui-disabled': {
      color: '#595959',
    },
  },
  //背景色を透明にする
  transparentBackground: {
    backgroundColor: 'transparent',
  },
  //背景色をグレーにする
  grayBackground: {
    background: 'rgba(89, 89, 89, 0.4)',
  },
  //テーブル用、セルの右側の罫線を非表示にする
  noBorderRight: {
    borderRight: '0px solid !important',
  },
  //テーブル用、セルの下側の罫線を非表示にする
  noBorderBottom: {
    borderBottom: '0px solid !important',
  },
  //TariffRate画面用、注釈用のセル
  commentCell: {
    textAlign: 'left !important',
    padding: '0px 0.5rem !important',
  },
  //TariffRate画面用のテーブル
  tariffRateTable: {
    borderCollapse: 'separate',
    minWidth: '700px',
    '& th': {
      color: '#375CA7 !important',
      fontWeight: 'bold',
      borderTop: '1px solid',
      borderColor: '#375CA7',
    },
    '& td, th': {
      borderRight: '1px solid',
      fontSize: 16,
      color: '#595959',
      borderRightColor: '#375CA7',
      padding: '0px',
      textAlign: 'center',
    },
    '& td': {
      borderBottom: '1px solid',
      borderBottomColor: '#375CA7',
    },
    '& td:first-child, th:first-child': {
      borderLeft: '1px solid',
      borderColor: '#375CA7',
    },
    '& tr:last-child td:last-child': {
      borderRadius: '0px 0px 5px 0px',
    },
    '& tr:last-child td:first-child': {
      borderRadius: '0px 0px 0px 5px',
    },
    '& th:last-child': {
      borderRadius: '0px 5px 0px 0px',
    },
    '& th:first-child': {
      borderRadius: '5px 0px 0px 0px',
    },
  },
  //TariffRate画面用の背景黄色の入力欄
  yellowTextfield: {
    width: '75px',
    height: '24px',
    fontFamily: ['Helvetica Neue', 'MS PGothic'].join(','),
    fontSize: 12,
    backgroundColor: '#FFD25D',
  },
  //TariffRate画面のテーブル用、セルに表示している要素間のPadding
  tariffRateTablePadding: {
    paddingBottom: '0.5rem',
  },
  //TariffRate画面のポップアップで使用する区切り線
  tariffRateLineMargin: {
    marginTop: '40px',
    marginBottom: '20px',
  },
  //HAWB・MAWBプレビュー画面で使用するOtherCharges欄のTheme
  previewOtherCode: {
    borderRightStyle: 'hidden',
    paddingLeft: 20,
    verticalAlign: 'top',
  },
  previewVertivalAlignTop: {
    verticalAlign: 'top',
  },
  previewBorderRightStyleHidden: {
    borderRightStyle: 'hidden',
  },
  //確認ポップアップ用のマージン
  confirmPopupMargin: {
    margin: 10,
  },
  //確認ポップアップ用のマージントップ
  confirmPopupMarginTop: {
    marginTop: '2rem',
  },
  //確認ポップアップの枠と背景を透明にする
  confirmPopupTransparent: {
    background: 'transparent',
    border: '0px solid',
  },
  // FwdrTracing画面/Shareポップアップ・Addポップアップ内で表示する公開先ボタンのTheme
  shareSelect: {
    width: '180px',
    height: '40px',
    color: '#FAFAFA',
    backgroundColor: '#375CA7',
    '&:hover': {
      backgroundColor: '#375CA7',
      color: '#FAFAFA',
    },
    textTransform: 'none',
    fontSize: '16px',
    fontFamily: ['Helvetica Neue', 'MS PGothic'].join(','),
  },
  shareNone: {
    width: '180px',
    height: '40px',
    color: '#375CA7',
    borderColor: '#375CA7',
    backgroundColor: '#FAFAFA',
    '&:hover': {
      backgroundColor: '#FAFAFA',
      color: '#375CA7',
    },
    textTransform: 'none',
    fontSize: '16px',
    fontFamily: ['Helvetica Neue', 'MS PGothic'].join(','),
  },
  // ポップアップのTheme
  dialogTransparent: {
    background: 'transparent',
    border: '0px solid',
  },
  dialogGridMargin: {
    margin: 10,
  },
  dialogMarginTop: {
    marginTop: '2rem',
  },
  // FwdrTracingドキュメントAddボタン表示時のTheme
  addInputFileBtnHide: {
    width: '120px',
    height: '35px',
    opacity: 0,
    appearance: 'none',
    position: 'absolute',
  },
  btnGridMargin: {
    margin: '0, 5rem',
  },
  docAddGrid: {
    margin: 'auto',
  },
  //Document画面のAuthorizationのチェックボックスの長さ
  authorizationWidth: {
    width: '108px',
  },
  //Document画面のポップアップで使用、文字の改行と中央揃え
  whiteSpace: {
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
  },
  //AddProfit画面用の非活性時背景入力欄
  disableTextfield: {
    width: '75px',
    height: '24px',
    fontFamily: ['Helvetica Neue', 'MS PGothic'].join(','),
    fontSize: 12,
    backgroundColor: '#AFAFAF',
  },
  //AddProfit画面用のMargin
  addProfitTextMarginTop: {
    marginTop: '10px',
  },
  //AddProfit画面編集ポップアップ内のラインのMargin指定
  fscLineMargin: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  //AddProfit画面で使用するテーブル表示時の非活性チェックボックスの色指定
  disabledPrimaryCheck: {
    '& .MuiCheckbox-colorPrimary.Mui-disabled': {
      color: '#375CA7',
    },
  },
  //AddProfit画面FSC欄で使用するMargin指定
  fscCellMargin: {
    marginLeft: '5px',
  },
  // 表のヘッダー共通デザイン
  commonTableHeader: {
    fontWeight: 'bold',
  },
  // addボタンの上下余白
  addMargin: {
    margin: '1rem 0',
  },
  // addボタンの右寄せ
  addRight: {
    textAlign: 'right',
  },
  // バックボタン中央寄せ設定
  backButtonCenter: {
    width: 'fit-content',
    height: 'fit-content',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  // チェックボックスの左寄せ
  checkBoxLeft: {
    textAlign: 'left',
  },
  // BidInfo画面のConsigneeの表示
  consigneeBox: {
    background: 'rgba(51, 51, 51, 0.06)',
    height: 'fit-content',
    minHeight: '4rem',
    width: '100%',
    borderRadius: '5px 5px 0px 0px',
    borderBottom: '1px solid',
    borderBottomColor: '#595959',
    padding: '1rem',
  },
  // 直下の子要素のみ
  childrenPadding: {
    '& > *': {
      padding: '1rem 0',
    },
  },
  childrenPaddingSmall: {
    '& > *': {
      padding: '0.5rem 0',
    },
  },
  signup: {
    root: {
      '&.MuiFormControl-marginNormal': {
        marginTop: 15,
        marginBottom: 5,
      },
      '& .MuiFormLabel-asterisk ': {
        color: '#FF0606',
        position: 'absolute',
        left: -13,
      },
      '& label.Mui-focused': {
        color: '#375CA7',
        backgroundColor: 'transparent',
      },
      '& .MuiInputBase-input': {
        color: '#375CA7',
      },
      '& .MuiFormLabel-root': {
        color: '#375CA7',
        backgroundColor: 'transparent',
        position: 'absolute',
        left: 3,
      },
      '& .MuiFormLabel-root.Mui-error': {
        color: '#FF0606',
      },
      '& .MuiOutlinedInput-root': {
        borderRadius: 0,
        '& fieldset': {
          borderColor: '#375CA7',
        },
        '&.Mui-focused fieldset': {
          border: 0,
          borderBottom: `2px solid ${'#375CA7'}`,
          borderTopRightRadius: 5,
          borderTopLeftRadius: 5,
        },
        '&:hover fieldset': {
          borderColor: '#375CA7',
        },
        '& input': {
          backgroundColor: '#FAFAFA',
          color: '#375CA7',
        },
        '& input:valid': {
          color: '#375CA7',
          borderColor: '#375CA7',
          borderWidth: 2,
        },
        '& input:disabled': {
          backgroundColor: '#989898',
          border: 0,
        },
      },
    },
    inputLabel: {
      color: '#375CA7',
      '&.focused': {
        border: 0,
        color: '#375CA7',
      },
      '&.shrink': {
        backgroundColor: '#FAFAFA',
        transform: `translate(${10}px, ${1}px) scale(${0.75})`,
      },
    },
    container: {
      textAlign: 'center',
    },
    autoComp: {
      '& .MuiOutlinedInput-root': {
        backgroundColor: '#FAFAFA',
      },
    },
    asterisk: {
      color: '#FF0606',
    },
    iconTitle: {
      fontFamily: ['Helvetica Neue', 'MS PGothic'].join(','),
      fontSize: '16px',
      color: '#FAFAFA',
    },
    iconReq: {
      marginLeft: 10,
      color: '#FF0606',
      fontSize: '12px',
    },
    helper: {
      marginLeft: 10,
      color: '#FF0606',
      fontWeight: 'bold',
      fontSize: '14px',
    },
    check: {
      '& .MuiIconButton-root': {
        color: '#FAFAFA',
      },
      '& .MuiCheckbox-colorPrimary.Mui-checked': {
        color: '#FFC735',
      },
      marginRight: 0,
    },
    white: {
      display: 'block',
      width: 'fit-content',
      marginTop: '15px',
      color: '#FAFAFA',
      fontSize: '14px',
      fontFamily: ['Helvetica Neue', 'MS PGothic'].join(','),
    },
    section: {
      marginTop: 10,
      marginBottom: 20,
    },
    button: {
      marginTop: 20,
      border: 'none',
      width: '220px',
      height: '40px',
    },
  },
  tab: {
    // 基本設定
    baseSetting: {
      minWidth: '100%',
      minHeight: 30,
      textTransform: 'none',
      border: '1px solid',
      borderColor: '#375CA7',
      borderRadius: '5px 5px 0 0',
      opacity: 1,
    },
    // 通常時の色(半透明)
    normalColor: {
      backgroundColor: '#AFAFAF40',
      color: '#595959',
    },
    // 選択時の色(白)
    selectColor: {
      backgroundColor: '#FAFAFA',
      color: '#375CA7',
    },
    // 通常時の色(白)
    normalFillColor: {
      backgroundColor: '#FAFAFA',
      color: '#375CA7',
    },
    // 選択時の色(青)
    selectFillColor: {
      backgroundColor: '#375CA7',
      color: '#FAFAFA',
    },
  },
  badge: {
    // 横幅設定
    widthSetting: {
      minWidth: '100%',
    },
    // 形の設定
    forumsSetting: {
      // 円形
      height: '25px',
      width: '25px',
      borderRadius: '50%',
      fontWeight: 'bold',
      // 端から少しずらす
      right: 20,
    },
  },
  requestCard: {
    backgroundColor: '#ebeff5',
    borderColor: '#375CA7',
  },
  clientRegPaperBack: {
    borderRadius: '4px',
    border: '1px solid',
    margin: 0,
    backgroundColor: '#FAFAFA',
    borderColor: '#375CA7',
  },
  // cargoDetails用
  tableCargoDetails: {
    borderCollapse: 'separate',
    minWidth: '1100px',
    '& table': {
      background: 'transparent',
    },
    '& td': {
      border: '0px solid',
      borderBottom: '0px solid',
    },
    '& td:first-child': {
      borderLeft: '0px solid',
    },
    '& td:last-child': {
      borderRight: '0px solid',
    },
    '& tr:last-child td:last-child': {
      borderRadius: '0px 0px 5px 0px',
    },
    '& tr:last-child td:first-child': {
      borderRadius: '0px 0px 0px 5px',
    },
    '& tr:first-child td:last-child': {
      borderRadius: '0px 5px 0px 0px',
    },
    '& tr:first-child td:first-child': {
      borderRadius: '5px 0px 0px 0px',
    },
    '& tr:first-child td': {
      borderTop: '0px solid',
    },
  },
  //JhClientRegDeleteAccount画面で使用、文字の改行
  whiteSpaceLeft: {
    whiteSpace: 'pre-wrap',
  },
})

export default Theme
