import { Grid } from '@material-ui/core'
import React from 'react'

import Paths from '../../../../../constants/Paths'
import BackButton from '../../../../common/buttons/BackButton'

/**
 * @param {Object} props 下記内容
 * @param {Object} props.details 貨物情報のオブジェクト
 * @return {JSX} Backボタン
 */
const BackButtonLightFwdrSearchResult = (props) => {
  return (
    <Grid container justify="center">
      <Grid item>
        <BackButton
          path={Paths.FWDR.LIGHT_BOOKING_REQUEST}
          state={props.details}
        />
      </Grid>
    </Grid>
  )
}

export default BackButtonLightFwdrSearchResult
