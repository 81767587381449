import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'

import PrimaryButton from '../../buttons/PrimaryButton'

/**
 * @param {Object} props OKボタンに追加したい設定
 * @returns {JSX} 共通ダイアログOKボタンコンポーネント
 */
const OkButtonCommonDialog = (props) => {
  const { t } = useTranslation()

  return (
    <Grid container item xs justify="center">
      <Grid item>
        <PrimaryButton {...props}>{t('D004V0062')}</PrimaryButton>
      </Grid>
    </Grid>
  )
}

export default OkButtonCommonDialog
