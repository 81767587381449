import React, { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'

import Common from '../../../../constants/Common'
import AppContext from '../../../../contexts/AppContext'
import PdfButtonDocumentLightBookingDetails from './PdfButtonDocumentLightBookingDetails'
import { DocumentArrName } from '../../../../reducers/common/lightBookingDetails/Document'

/**
 * @param {Object} props 下記内容
 * @param {String} props.documentArrName ドキュメントの配列名
 * @param {boolean} props.editable 編集可能
 * @returns {JSX} ドキュメント欄に表示するPDFボタン
 */
const PdfButtonsDocumentLightBookingDetails = ({
  documentArrName,
  editable,
}) => {
  const { state, dispatch } = useContext(AppContext)

  const fwdrDocumentLength = state.Document.fwdrDocumentArr.filter(
    (v) => v.delFlg !== Common.DEL_FLG.ON
  ).length
  const carDocumentLength = state.Document.carDocumentArr.filter(
    (v) => v.delFlg !== Common.DEL_FLG.ON
  ).length

  return useMemo(() => {
    const isFwdr = documentArrName === DocumentArrName.fwdrDocumentArr
    const isCarrier = documentArrName === DocumentArrName.carDocumentArr

    /**
     * 航空会社ドキュメントのPDFボタンを作成する
     * @returns {JSX} 航空会社ドキュメントのPDFボタン
     */
    const createCarrierDocument = () =>
      state.Document.carDocumentArr.map((document, index) => {
        if (document.delFlg === Common.DEL_FLG.ON) {
          return null
        }
        return (
          <PdfButtonDocumentLightBookingDetails
            key={index}
            document={document}
            dispatch={dispatch}
            editable={isCarrier && editable}
            index={index}
            documentArrName={documentArrName}
          />
        )
      })

    /**
     * 混載業者ドキュメントのPDFボタンを作成する
     * @returns {JSX} 混載業者ドキュメントのPDFボタン
     */
    const createFwdrDocument = () =>
      state.Document.fwdrDocumentArr.map((document, index) => {
        if (document.delFlg === Common.DEL_FLG.ON) {
          return null
        }
        return (
          <PdfButtonDocumentLightBookingDetails
            key={index}
            document={document}
            dispatch={dispatch}
            editable={isFwdr && editable}
            index={index}
            documentArrName={documentArrName}
          />
        )
      })

    return (
      <>
        {isFwdr ? createCarrierDocument() : createFwdrDocument()}
        {isFwdr ? createFwdrDocument() : createCarrierDocument()}
      </>
    )
  }, [
    carDocumentLength,
    fwdrDocumentLength,
    editable,
    documentArrName,
    dispatch,
  ]) //eslint-disable-line
}
PdfButtonsDocumentLightBookingDetails.propTypes = {
  documentArrName: PropTypes.string.isRequired,
  editable: PropTypes.bool.isRequired,
}
export default PdfButtonsDocumentLightBookingDetails
