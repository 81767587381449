import { COUNTRY_CODE } from '../../actions'

/**
 * @typedef {Object} CountryCode 国情報
 * @property {Number} areaFlg エリアコード
 * @property {String} country 国名
 * @property {String} createdAt 登録日時
 * @property {Number} internationalCallCode 国際電話コード
 * @property {String} internationalCode 国際コード
 * @property {Number} internationalId 国際ID
 * @property {String} updatedAt 更新日時
 */

/**
 * @type {Object} 初期値
 * @property {CountryCode[]} countryCode 国情報一覧
 * @property {Function} getAreaCode エリアコード取得関数
 */
const initCountryCode = {
  countryCode: [],
  getAreaCode: () => {},
}

/**
 * 国情報を保持する
 * @param {Object} action action
 * @param {CountryCode[]} action.data 国情報の配列
 * @returns {Object} state
 */
const setCountryCode = (action) => {
  const { data } = action

  /**
   * internationalIdからエリアコードを取得
   * @param {Number} internationalId 国際ID
   * @returns {Number} エリアコード
   */
  const getAreaCode = (internationalId) =>
    data.find((v) => v.internationalId === internationalId)?.areaFlg ?? null

  return {
    countryCode: data,
    getAreaCode,
  }
}

/**
 * state: CountryCodeリストを保存
 * @param {Array} [state=initCountryCode] - state
 * @param {Object} action - action
 * @returns {Object} - state
 */
const CountryCode = (state = initCountryCode, action) => {
  switch (action.type) {
    case COUNTRY_CODE.SET:
      return setCountryCode(action)
    default:
      return state
  }
}

export default CountryCode

export { initCountryCode }
