import React from 'react'

import RemarkLightBookingDetails from '../../../../commonUseContext/LightBookingDetails/Remark/RemarkLightBookingDetails'

/**
 * @return {JSX} Remarkアコーディオン
 */
const RemarkAirlineLightBookingDetails = () => {
  return <RemarkLightBookingDetails editable={false} isShowOnlyEntered />
}

export default RemarkAirlineLightBookingDetails
