import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import TextField from '../../../common/text/TextField'
import AppContext from '../../../../contexts/AppContext'
import CommonValidation from '../../../common/function/CommonValidation'
import {
  UPDATE_JH_MAIL,
  UPDATE_JH_TEL,
  UPDATE_JH_MAIL_INPUTERR,
  UPDATE_JH_TEL_INPUTERR,
} from '../../../../actions'

const useStyles = makeStyles((theme) => ({
  head: theme.textBoldBLU,
  // eslint-disable-next-line camelcase
  text_blk: {
    ...theme.textNormalBLK,
    marginLeft: '1rem',
    wordWrap: 'break-word',
  },
  // eslint-disable-next-line camelcase
  text_blu: theme.textNormalBLU,
}))

const ContactInfo = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [inputErrs, setInputErrs] = useState({ mail: false, tel: false })
  const [helperTxts, setHelperTxts] = useState({ mail: '', tel: '' })
  const { state, dispatch } = useContext(AppContext)
  const representativeFlg = props.representativeFlg

  const handleChangeMail = (event) => {
    const value = event.target.value
    // 入力チェック
    const validationCheck = CommonValidation.mail(value)
    const err = validationCheck.checkResult
    setInputErrs({ ...inputErrs, mail: err })
    setHelperTxts({ ...helperTxts, mail: validationCheck.errMsg })

    dispatch({
      type: UPDATE_JH_MAIL,
      mail: value,
    })

    // 入力エラーの場合、Savaボタン無効
    dispatch({
      type: UPDATE_JH_MAIL_INPUTERR,
      inputErr: err,
    })
  }

  const handleChangeTel = (event) => {
    const value = event.target.value
    // 入力チェック
    const validationCheck = CommonValidation.telephone(value)
    const err = validationCheck.checkResult
    setInputErrs({ ...inputErrs, tel: err })
    setHelperTxts({ ...helperTxts, tel: validationCheck.errMsg })

    dispatch({
      type: UPDATE_JH_TEL,
      tel: value,
    })

    // 入力エラーの場合、Savaボタン無効
    dispatch({
      type: UPDATE_JH_TEL_INPUTERR,
      inputErr: err,
    })
  }

  return (
    <div className={classes.formControl}>
      <Grid container>
        <Grid item md={10} xs={10}>
          <div className={classes.head}>{t('D301V0009')}</div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={10} xs={10}>
          <div className={classes.text_blu}>{t('D301V0010')}</div>
        </Grid>
        {representativeFlg ? (
          <Grid item md={4} xs={10}>
            <TextField
              id="mail"
              error={inputErrs.mail}
              helperText={t(helperTxts.mail)}
              value={state.companyinfo.mail.value}
              onChange={handleChangeMail}
            />
          </Grid>
        ) : (
          <Grid item md={10} xs={10}>
            <div className={classes.text_blk}>
              {state.companyinfo.mail.value}
            </div>
          </Grid>
        )}
        <Grid item md={10} />
        <Grid item md={4} xs={10}>
          <div className={classes.text_blu}>{t('D301V0011')}</div>
          {representativeFlg ? (
            <TextField
              id="tel"
              error={inputErrs.tel}
              helperText={t(helperTxts.tel)}
              value={state.companyinfo.tel.value}
              onChange={handleChangeTel}
            />
          ) : (
            <div className={classes.text_blk}>
              {state.companyinfo.tel.value}
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  )
}

export default ContactInfo
