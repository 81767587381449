import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import TextField from '../../../common/text/TextField'
import AppContext from '../../../../contexts/AppContext'
import CommonValidation from '../../../common/function/CommonValidation'
import {
  UPDATE_SHIP_FIRST_NAME,
  UPDATE_SHIP_LAST_NAME,
  UPDATE_SHIP_FIRST_NAME_INPUTERR,
  UPDATE_SHIP_LAST_NAME_INPUTERR,
} from '../../../../actions'

const useStyles = makeStyles((theme) => ({
  head: theme.textBoldBLU,
}))

const Name = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { state, dispatch } = useContext(AppContext)
  const [inputErrs, setInputErrs] = useState({ name: false, lastName: false })
  const [helperTxts, setHelperTxts] = useState({ name: '', lastName: '' })

  const handleChangeLastName = (event) => {
    const value = event.target.value
    // 入力チェック
    const validationCheck = CommonValidation.name(value)
    const err = validationCheck.checkResult
    setInputErrs({ ...inputErrs, lastName: err })
    setHelperTxts({ ...helperTxts, lastName: validationCheck.errMsg })

    dispatch({
      type: UPDATE_SHIP_LAST_NAME,
      lastName: value,
    })

    // 入力エラーの場合、Savaボタン無効
    dispatch({
      type: UPDATE_SHIP_LAST_NAME_INPUTERR,
      inputErr: err,
    })
  }

  const handleChangeFirstName = (event) => {
    const value = event.target.value
    // 入力チェック
    const validationCheck = CommonValidation.name(value)
    const err = validationCheck.checkResult
    setInputErrs({ ...inputErrs, name: err })
    setHelperTxts({ ...helperTxts, name: validationCheck.errMsg })

    dispatch({
      type: UPDATE_SHIP_FIRST_NAME,
      name: value,
    })

    // 入力エラーの場合、Savaボタン無効
    dispatch({
      type: UPDATE_SHIP_FIRST_NAME_INPUTERR,
      inputErr: err,
    })
  }

  return (
    <div className={classes.formControl}>
      <Grid container>
        <Grid item md={4} xs={10}>
          <div className={classes.head}>{t('D301V0007')}</div>
          <TextField
            id="name"
            error={inputErrs.name}
            helperText={t(helperTxts.name)}
            value={state.myprofile.name.value}
            onChange={handleChangeFirstName}
            autoComplete="off"
          />
        </Grid>
        <Grid item md={1} />
        <Grid item md={4} xs={10}>
          <div className={classes.head}>{t('D301V0008')}</div>
          <TextField
            id="lastName"
            error={inputErrs.lastName}
            helperText={t(helperTxts.lastName)}
            value={state.myprofile.lastName.value}
            onChange={handleChangeLastName}
            autoComplete="off"
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default Name
