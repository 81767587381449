import React, { useContext, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { LIGHT_FLIGHT_MGMT } from '../../../../../../actions'
import AppContext from '../../../../../../contexts/AppContext'
import CommonValidation from '../../../../../common/function/CommonValidation'
import TextField from '../../../../../common/text/TextField'

/**
 * @param {*} props 下記内容のProps
 * @param {Number} props.routingIndex Routingのインデックス
 * @param {Number} props.index FlightScheduleのインデックス
 * @returns {JSX} ETD欄
 */
const Etd = (props) => {
  const { t } = useTranslation()
  const { routingIndex, index } = props
  const { state, dispatch } = useContext(AppContext)
  const [error, setError] = useState(false)
  const [helperText, setHelperText] = useState('')
  /** @type {Function} FlightScheduleのデータを取得する */
  const getScheduleParam = state.Popup.getScheduleParam
  /** @type {String} ETDの入力値 */
  const etd = getScheduleParam(routingIndex, index, 'etd')
  /** @type {Boolean} ハイドフラグ */
  const hideFlg = Boolean(getScheduleParam(routingIndex, index, 'hideFlg'))

  return useMemo(() => {
    /** @type {Boolean} 新規追加フラグ */
    const add = getScheduleParam(routingIndex, index, 'add')
    /** @type {Boolean} 非活性判定 */
    const disabled = !add || hideFlg

    /**
     * ETDを更新
     * @param {String} value 入力値
     * @returns {void} なし
     */
    const onChange = (value) => {
      const { checkResult, errMsg } = CommonValidation.timeOptional(value)
      setError(checkResult)
      setHelperText(t(errMsg))
      dispatch({
        type: LIGHT_FLIGHT_MGMT.EDIT.FLIGHT_SCHEDULE,
        routingIndex,
        index,
        etd: checkResult ? '' : value,
        error: checkResult,
      })
    }

    return (
      <TextField
        defaultValue={etd}
        onChange={(event) => onChange(event.target.value)}
        error={error}
        helperText={helperText}
        disabled={disabled}
      />
    )
  }, [
    etd,
    hideFlg,
    dispatch,
    error,
    helperText,
    index,
    routingIndex,
    t,
    getScheduleParam,
  ])
}
Etd.propTypes = {
  routingIndex: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
}
export default Etd
