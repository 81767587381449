import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import LayoutBox from '../../../common/layout/LayoutBox'
import Title from '../../../common/title/Title'
import Theme from '../../../common/theme/Theme'
import ClientRegButtons from './ClientRegButtons'
import BackButton from '../../../common/buttons/BackButton'
import Paths from '../../../../constants/Paths'

const useStyles = makeStyles(() => ({
  root: {
    [Theme.breakpoints.up('md')]: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translateY(-50%) translateX(-50%)',
    },
  },
  outer: {
    [Theme.breakpoints.up('md')]: {
      position: 'relative',
      width: '100%',
      height: `calc(100vh - ${Theme.header.height * 2}px - 15rem)`,
    },
  },
}))

const JhClientReg = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <LayoutBox>
      <Title>{t('D303V0001')}</Title>
      <div className={classes.outer}>
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.root}
        >
          <Grid item md={10} xs={10}>
            <ClientRegButtons
              signuppath={Paths.MASTER.LIGHT_JH_CLIENT_REG_SIGNUP}
              deletepath={Paths.MASTER.LIGHT_JH_CLIENT_REG_DELETE_ACCOUNT}
            />
          </Grid>
        </Grid>
      </div>
      <Grid container justify="center" direction="column" alignItems="center">
        <Grid item md={10} xs={10}>
          <BackButton path={Paths.JH.JH_TOP} />
        </Grid>
      </Grid>
    </LayoutBox>
  )
}

export default JhClientReg
