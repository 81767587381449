import React, { useContext } from 'react'
import AppContext from '../../../../../contexts/AppContext'

import CommonTableContainer from '../../../../common/table/CommonTableContainer'
import HeaderLightFwdrAccountInfo from './HeaderLightFwdrAccountInfo'
import TableContentsLightFwdrAccountInfo from './TableContentsLightFwdrAccountInfo'

/**
 * @return {JSX} 表コンポーネント(全体)
 */
const TableLightFwdrAccountInfo = () => {
  const { state } = useContext(AppContext)
  /** @type {Boolean} 表データがない場合true */
  const isNoResult = state.Tables.isNoResult

  return (
    <CommonTableContainer isNoResult={isNoResult}>
      <HeaderLightFwdrAccountInfo />
      <TableContentsLightFwdrAccountInfo />
    </CommonTableContainer>
  )
}

export default TableLightFwdrAccountInfo
