import { combineReducers } from 'redux'

import Search from './Search'
import Paging from './Paging'
import Tables from './Tables'

export default combineReducers({
  Search,
  Paging,
  Tables,
})
