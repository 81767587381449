import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  contents: {
    ...theme.textNormalBLK,
    ...theme.wordBreak,
  },
}))

/**
 * @param {Array} checkArr チェック状態配列
 * @param {Array} checkTextArr チェック表示テキスト配列
 * @return {JSX} 退会理由チェックコンポーネント
 */
const WithdrawalCheckAnswerLightJhClientRegDeleteAccount = ({
  checkArr,
  checkTextArr,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return checkArr.map((checkIndex) => (
    <Grid container item justify="center" key={checkIndex}>
      <Grid container item xs={1} className={classes.contents} justify="center">
        ･
      </Grid>
      <Grid item xs={11} className={classes.contents}>
        {`${t(checkTextArr[checkIndex])}`}
      </Grid>
    </Grid>
  ))
}

WithdrawalCheckAnswerLightJhClientRegDeleteAccount.propTypes = {
  checkArr: PropTypes.array.isRequired,
  checkTextArr: PropTypes.array.isRequired,
}

export default WithdrawalCheckAnswerLightJhClientRegDeleteAccount
