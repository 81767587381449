import React, { useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import {
  PAGING_DATA,
  GET_TABLE_DATA,
  SEARCH_SORT_DATA,
} from '../../../../actions'
import Grid from '@material-ui/core/Grid'

import AppContext from '../../../../contexts/AppContext'
import SearchBox from '../../../common/searchForm/SearchBox'
import NotifyTabs from './NotifyTabs'
import NotifyCheck from './NotifyCheck'
import SearchDialog from './SearchDialog'

const useStyles = makeStyles((theme) => ({
  tab: {
    width: 120,
    height: 35,
    textTransform: 'none',
    borderRadius: '10px 10px 0px 0px',
  },
  textBlu: theme.textNormalBLU,
  textBlk: theme.textNormalBLK,
  bottom: {
    paddingBottom: '0.5rem',
  },
}))

const NotifySearchSort = () => {
  const { t, i18n } = useTranslation()
  const classes = useStyles()
  const { state, dispatch } = useContext(AppContext)
  const [search, setSearch] = useState(state.Sort.search)

  // ソート時とページ移動時に検索ボックス内を現在の検索している値に直す
  useEffect(() => {
    setSearch(state.Sort.search)
  }, [state.Paging.page, state.Sort])

  // searchボタンクリック時の挙動
  const searchClick = () => {
    dispatch({
      type: SEARCH_SORT_DATA,
      sort: { search },
    })
    dispatch({
      type: GET_TABLE_DATA,
      sort: state.Sort,
      i18n,
    })
    dispatch({
      type: PAGING_DATA,
      page: 1,
    })
  }

  // searchの値変更時の挙動
  const searchChange = (event) => {
    setSearch(event.target.value)
  }

  // searchの✕ボタンクリック時の挙動
  const resetClick = () => {
    setSearch('')
    dispatch({
      type: SEARCH_SORT_DATA,
      sort: { search: '' },
    })
    dispatch({
      type: GET_TABLE_DATA,
      search: '',
      sort: state.Sort,
      i18n,
    })
    dispatch({
      type: PAGING_DATA,
      page: 1,
    })
  }

  const searchfactors = {
    placeholder: t('D601V0007'),
    clickEvent: searchClick,
    changeEvent: searchChange,
    resetClick,
    value: search,
  }

  return (
    <Grid container justify="space-between">
      <Grid container item xs={7} alignItems="flex-end">
        {/* All, Unreadタブ */}
        <Grid item>
          <NotifyTabs />
        </Grid>
        {/* TC1~3 チェックボックス */}
        <Grid item className={classes.bottom}>
          <NotifyCheck />
        </Grid>
      </Grid>
      <Grid container item xs={4} alignItems="flex-end">
        <Grid item xs className={classes.bottom}>
          <SearchBox searchfactors={searchfactors} />
        </Grid>
        <Grid item>
          <SearchDialog />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default NotifySearchSort
