import { SPECIAL_NOTES } from '../../actions'
import Common from '../../constants/Common'

const specialNotesCountNum = Common.SPECIAL_NOTE_SETTING_ARR.length

const initSpecialNotes = {
  // SpecialNotes一覧
  specialNoteArr: Array(specialNotesCountNum).fill(''),
  // ダイアログの開閉状態
  isOpenDialog: false,
  // Editボタンで選択中のSpecialNotesのindex
  selectIndexNum: null,
}

/**
 * 画面遷移時の初期state設定
 * @param {Object} action dispatchで受け取ったaction
 * @param {Object} state SpecialNotesのstate
 * @return {Object} SpecialNotes情報を保持したstate
 */
const createSpecialNotesObj = (action, state) => {
  const { data } = action

  return {
    ...state,
    specialNoteArr: data,
  }
}

/**
 * ダイアログの開閉
 * @param {Object} action dispatchで受け取ったaction
 * @param {Object} state SpecialNotesのstate
 * @return {Object} SpecialNotes情報を保持したstate
 */
const toggleDialog = (action, state) => {
  const { isOpenDialog, selectIndexNum } = action

  return {
    ...state,
    isOpenDialog,
    selectIndexNum,
  }
}

/**
 * ダイアログで編集した内容を画面に反映
 * @param {Object} action dispatchで受け取ったaction
 * @param {Object} state SpecialNotesのstate
 * @return {Object} SpecialNotes情報を保持したstate
 */
const updateText = (action, state) => {
  const { textStr } = action
  const { specialNoteArr, selectIndexNum } = state
  const newSpecialNotesArr = specialNoteArr.map(
    (currentTextStr, currentIndex) => {
      const isTargetIndex = selectIndexNum === currentIndex
      return isTargetIndex ? textStr : currentTextStr
    }
  )

  return {
    ...state,
    specialNoteArr: newSpecialNotesArr,
    isOpenDialog: false,
    selectIndexNum: null,
  }
}

/**
 * SpecialNotes情報を保持
 * @param {Object} [state=initSpecialNotes] SpecialNotesのstate
 * @param {Object} action dispatchで受け取ったaction
 * @return {Object} SpecialNotes情報を保持したstate
 */
const SpecialNotes = (state = initSpecialNotes, action) => {
  switch (action.type) {
    case SPECIAL_NOTES.INIT:
      return createSpecialNotesObj(action, state)
    case SPECIAL_NOTES.TOGGLE_DIALOG:
      return toggleDialog(action, state)
    case SPECIAL_NOTES.UPDATE_TEXT:
      return updateText(action, state)
    default:
      return state
  }
}

export default SpecialNotes

export { initSpecialNotes }
