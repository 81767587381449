import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles((theme) => ({
  head: theme.textBoldBLU,
  // eslint-disable-next-line camelcase
  text_blk: {
    ...theme.textNormalBLK,
    marginLeft: '1rem',
    wordWrap: 'break-word',
  },
  // eslint-disable-next-line camelcase
  text_blu: theme.textNormalBLU,
}))

const Representative = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.formControl}>
      <Grid container>
        <Grid item md={10} xs={10}>
          <div className={classes.head}>{t('D301V0006')}</div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={5} xs={10}>
          <div className={classes.text_blu}>{t('D301V0007')}</div>
          <div className={classes.text_blk}>{props.firstName}</div>
        </Grid>
        <Grid item md={5} xs={10}>
          <div className={classes.text_blu}>{t('D301V0008')}</div>
          <div className={classes.text_blk}>{props.lastName}</div>
        </Grid>
      </Grid>
    </div>
  )
}

export default Representative
