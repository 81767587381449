import React from 'react'

import CommonDatepicker from '../../../../commonUseContext/Bid/DatePicker/CommonDatePicker'
import { DATEPICKER } from '../../../../../actions'

/**
 * @return {JSX} Validity開始日Datepickerコンポーネント
 */
const StartDatepickerBidInfo = () => {
  return (
    <CommonDatepicker
      stateName={'Validity'}
      stateKey={'startDate'}
      actionType={DATEPICKER.SET_START_FINISH}
      disablePast
    />
  )
}

export default StartDatepickerBidInfo
