import React from 'react'
import PropTypes from 'prop-types'
import CheckButtonCell from './CheckButtonCell'
import DraftButtonCell from './DraftButtonCell'
import Common from '../../../../constants/Common'

/**
 * 表のDetailsに表示するボタンコンポーネント
 * @param {Object} { buttonType } 下記の値を渡す
 * @param {String} buttonType Common.DETAILS_BUTTONで定義しているボタンの種類
 * @return {JSX} Common.DETAILS_BUTTONで定義しているボタンコンポーネント
 */
const DetailsButtonCell = ({ buttonType, ...rest }) => {
  switch (buttonType) {
    case Common.DETAILS_BUTTON.CHECK:
      return <CheckButtonCell {...rest} />
    case Common.DETAILS_BUTTON.DRAFT:
      return <DraftButtonCell {...rest} />
    default:
      return <></>
  }
}

DetailsButtonCell.propTypes = {
  buttonType: PropTypes.string.isRequired,
}

export default DetailsButtonCell
