import {
  UPDATE_CAR_COMPANY_NAME,
  UPDATE_CAR_POSTAL_CODE,
  UPDATE_CAR_COMPANY_ADDRESS,
  UPDATE_CAR_DEPARTMENT,
  UPDATE_CAR_FIRST_NAME,
  UPDATE_CAR_LAST_NAME,
  UPDATE_CAR_POSITION,
  UPDATE_CAR_MAIL,
  UPDATE_CAR_TEL,
  UPDATE_CAR_TWO_LETTER_CODE,
  UPDATE_CAR_IATACASS_NO,
  UPDATE_CAR_ICON_PATH,
  UPDATE_CAR_UPDATE_AT,
  UPDATE_CAR_IS_DELETE_ICON,
  UPDATE_CAR_AIRPORT,
  UPDATE_CAR_REPRESENTATIVE_FLG,
  UPDATE_CAR_AUTHORITY_FLG,
  UPDATE_CAR_COUNTRY,
  UPDATE_CAR_POSTAL_CODE_INPUTERR,
  UPDATE_CAR_COMPANY_ADDRESS_INPUTERR,
  UPDATE_CAR_DEPARTMENT_INPUTERR,
  UPDATE_CAR_FIRST_NAME_INPUTERR,
  UPDATE_CAR_LAST_NAME_INPUTERR,
  UPDATE_CAR_POSITION_INPUTERR,
  UPDATE_CAR_MAIL_INPUTERR,
  UPDATE_CAR_TEL_INPUTERR,
  UPDATE_CAR_IATACASS_NO_INPUTERR,
  UPDATE_CAR_ICON_PATH_INPUTERR,
  UPDATE_CAR_GSA_FLG,
  UPDATE_CAR_IS_DELETE_SHIPPING_ICON,
  UPDATE_CAR_PLAN,
  UPDATE_CAR_PLAN_INPUTERR,
} from '../../../actions'

const initCompanyInfo = {
  companyName: {
    value: { code: '', name: '', two: '' },
  },
  affilation: { value: '', inputErr: false },
  carriertwoCode: { value: '', inputErr: false },
  postalCode: { value: '', inputErr: false },
  address: { value: '', inputErr: false },
  airport: { value: '' },
  country: { value: '' },
  representativeFlg: { value: '' },
  authorityFlg: { value: '' },
  gsaFlg: { value: '' },
  firstName: { value: '', inputErr: false },
  lastName: { value: '', inputErr: false },
  position: { value: '', inputErr: false },
  mail: { value: '', inputErr: false },
  tel: { value: '', inputErr: false },
  iataOrcassNumber: { value: '', inputErr: false },
  icon: { value: '', inputErr: false },
  iconPath: { value: '' },
  updatedAt: { value: '' },
  isDeleteIcon: { value: '' },
  carPlanId: { value: '', inputErr: false },
}

const Events = (state = {}, action) => {
  switch (action.type) {
    //------------------
    // companyNameを更新
    //------------------
    case UPDATE_CAR_COMPANY_NAME:
      return {
        ...state,
        companyName: {
          ...state.companyName,
          value: {
            code: action.carrierNameId,
            name: action.companyName,
            two: action.carriertwoCode,
          },
        },
      }
    //------------------
    // postalCodeを更新
    //------------------
    case UPDATE_CAR_POSTAL_CODE:
      return {
        ...state,
        postalCode: { ...state.postalCode, value: action.postalCode },
      }
    case UPDATE_CAR_POSTAL_CODE_INPUTERR:
      return {
        ...state,
        postalCode: { ...state.postalCode, inputErr: action.inputErr },
      }
    //------------------
    // addressを更新
    //------------------
    case UPDATE_CAR_COMPANY_ADDRESS:
      return { ...state, address: { ...state.address, value: action.address } }
    case UPDATE_CAR_COMPANY_ADDRESS_INPUTERR:
      return {
        ...state,
        address: { ...state.address, inputErr: action.inputErr },
      }
    //------------------
    // affilationを更新
    //------------------
    case UPDATE_CAR_DEPARTMENT:
      return {
        ...state,
        affilation: { ...state.affilation, value: action.affilation },
      }
    case UPDATE_CAR_DEPARTMENT_INPUTERR:
      return {
        ...state,
        affilation: { ...state.affilation, inputErr: action.inputErr },
      }
    //------------------
    // airportを更新
    //------------------
    case UPDATE_CAR_AIRPORT:
      return { ...state, airport: { ...state.airport, value: action.airport } }
    //------------------
    // countryを更新
    //------------------
    case UPDATE_CAR_COUNTRY:
      return { ...state, country: { ...state.country, value: action.country } }
    //------------------
    // representativeFlgを更新
    //------------------
    case UPDATE_CAR_REPRESENTATIVE_FLG:
      return {
        ...state,
        representativeFlg: {
          ...state.representativeFlg,
          value: action.representativeFlg,
        },
      }
    //------------------
    // authorityFlgを更新
    //------------------
    case UPDATE_CAR_AUTHORITY_FLG:
      return {
        ...state,
        authorityFlg: { ...state.authorityFlg, value: action.authorityFlg },
      }
    //------------------
    // gsaFlgを更新
    //------------------
    case UPDATE_CAR_GSA_FLG:
      return { ...state, gsaFlg: { ...state.gsaFlg, value: action.gsaFlg } }
    //------------------
    // firstNameを更新
    //------------------
    case UPDATE_CAR_FIRST_NAME:
      return {
        ...state,
        firstName: { ...state.firstName, value: action.firstName },
      }
    case UPDATE_CAR_FIRST_NAME_INPUTERR:
      return {
        ...state,
        firstName: { ...state.firstName, inputErr: action.inputErr },
      }
    //------------------
    // lastNameを更新
    //------------------
    case UPDATE_CAR_LAST_NAME:
      return {
        ...state,
        lastName: { ...state.lastName, value: action.lastName },
      }
    case UPDATE_CAR_LAST_NAME_INPUTERR:
      return {
        ...state,
        lastName: { ...state.lastName, inputErr: action.inputErr },
      }
    //------------------
    // positionを更新
    //------------------
    case UPDATE_CAR_POSITION:
      return {
        ...state,
        position: { ...state.position, value: action.position },
      }
    case UPDATE_CAR_POSITION_INPUTERR:
      return {
        ...state,
        position: { ...state.position, inputErr: action.inputErr },
      }
    //------------------
    // mailを更新
    //------------------
    case UPDATE_CAR_MAIL:
      return { ...state, mail: { ...state.mail, value: action.mail } }
    case UPDATE_CAR_MAIL_INPUTERR:
      return { ...state, mail: { ...state.mail, inputErr: action.inputErr } }
    //------------------
    // telを更新
    //------------------
    case UPDATE_CAR_TEL:
      return { ...state, tel: { ...state.tel, value: action.tel } }
    case UPDATE_CAR_TEL_INPUTERR:
      return { ...state, tel: { ...state.tel, inputErr: action.inputErr } }
    //------------------
    // carriertwoCodeを更新
    //------------------
    case UPDATE_CAR_TWO_LETTER_CODE:
      return {
        ...state,
        carriertwoCode: {
          ...state.carriertwoCode,
          value: action.carriertwoCode,
        },
      }
    //------------------
    // iataOrcassNoを更新
    //------------------
    case UPDATE_CAR_IATACASS_NO:
      return {
        ...state,
        iataOrcassNumber: {
          ...state.iataOrcassNumber,
          value: action.iataOrcassNumber,
        },
      }
    case UPDATE_CAR_IATACASS_NO_INPUTERR:
      return {
        ...state,
        iataOrcassNumber: {
          ...state.iataOrcassNumber,
          inputErr: action.inputErr,
        },
      }
    //------------------
    // iconを更新
    //------------------
    case UPDATE_CAR_ICON_PATH:
      return { ...state, icon: { ...state.icon, value: action.icon } }
    case UPDATE_CAR_ICON_PATH_INPUTERR:
      return { ...state, icon: { ...state.icon, inputErr: action.inputErr } }
    //------------------
    // iconPathを更新
    //------------------
    case UPDATE_CAR_IS_DELETE_SHIPPING_ICON:
      return {
        ...state,
        iconPath: { ...state.iconPath, value: action.iconPath },
      }
    //------------------
    // updatedAtを更新
    //------------------
    case UPDATE_CAR_UPDATE_AT:
      return {
        ...state,
        updatedAt: { ...state.updatedAt, value: action.updatedAt },
      }
    //------------------
    // isDeleteIconを更新
    //------------------
    case UPDATE_CAR_IS_DELETE_ICON:
      return {
        ...state,
        isDeleteIcon: { ...state.isDeleteIcon, value: action.isDeleteIcon },
      }
    //------------------
    // fwdrPlanIdを更新
    //------------------
    case UPDATE_CAR_PLAN:
      return {
        ...state,
        carPlanId: { ...state.carPlanId, value: action.carPlanId },
      }
    case UPDATE_CAR_PLAN_INPUTERR:
      return {
        ...state,
        carPlanId: { ...state.carPlanId, inputErr: action.inputErr },
      }
    default:
      return state
  }
}

export default Events

export { initCompanyInfo }
