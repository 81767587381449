import { Grid } from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'

import DaySelect from './DaySelect'
import Eta from './Eta'
import Etd from './Etd'
import Hide from './Hide'

/**
 * @param {*} props 下記内容のProps
 * @param {Number} props.routingIndex Routingのインデックス
 * @param {Number} props.index FlightScheduleのインデックス
 * @returns {JSX} FlightScheduleの1つ分の内容
 */
const FlightScheduleContent = (props) => {
  const { routingIndex, index } = props

  return (
    <Grid item container spacing={2} justify="space-between">
      <Grid item md={2}>
        <Hide routingIndex={routingIndex} index={index} />
      </Grid>
      <Grid item md={3}>
        <DaySelect routingIndex={routingIndex} index={index} />
      </Grid>
      <Grid item md={3}>
        <Etd routingIndex={routingIndex} index={index} />
      </Grid>
      <Grid item md={3}>
        <Eta routingIndex={routingIndex} index={index} />
      </Grid>
    </Grid>
  )
}
FlightScheduleContent.propTypes = {
  routingIndex: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
}
export default FlightScheduleContent
