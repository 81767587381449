import React, { useReducer, useEffect } from 'react'
import PropTypes from 'prop-types'

import LayoutBox from '../../../common/layout/LayoutBox'
import TitleConfirmation from './00TitleConfirmation/TitleConfirmation'
import TableConfirmation from './01TableConfirmation/TableConfirmation'
import PagingConfirmation from './02PagingConfirmation/PagingConfirmation'
import CheckboxConfirmation from './03CheckboxConfirmation/CheckboxConfirmation'
import ButtonConfirmation from './04ButtonConfirmation/ButtonConfirmation'
import AppContext from '../../../../contexts/AppContext'
import Common from '../../../../constants/Common'
import reducer, {
  initialState,
} from '../../../../reducers/fwdr/fwdrConfirmation'
import { FWDR_CONFIRMATION, TABLES } from '../../../../actions'

/**
 * @param {*} props 前画面から取得したデータ
 * @return {JSX} 荷主Confirmation画面コンポーネント
 */
const FwdrConfirmation = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const data = props.history.location.state?.state

  const hasRequestBid = data.bidStatusObj.bidStatusId.includes(
    Common.BID_STATUS.REQUEST.ID
  )
  const hasDraftBid = data.bidStatusObj.forwarderDraftFlg.includes(true)
  /** 新規入札・荷主回答後画面フラグ */
  const isNew = hasRequestBid || hasDraftBid

  useEffect(() => {
    //テーブルの初期値を設定
    dispatch({
      type: TABLES.INIT,
      data: data.bidDetailArr,
      sort: '',
    })

    //新規画面・混載業者選択画面フラグを設定
    dispatch({
      type: FWDR_CONFIRMATION.IS_NEW.SET,
      isNew,
    })

    dispatch({
      type: FWDR_CONFIRMATION.DATA.SET,
      data,
    })
  }, []) // eslint-disable-line

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <LayoutBox>
        <TitleConfirmation />
        <TableConfirmation />
        <PagingConfirmation />
        <CheckboxConfirmation />
        <ButtonConfirmation />
      </LayoutBox>
    </AppContext.Provider>
  )
}

FwdrConfirmation.propTypes = {
  history: PropTypes.object.isRequired,
}

export default FwdrConfirmation
