import { DATEPICKER } from '../../actions'

const initDatepicker = {
  // DatePickerで選択中の日付
  date: undefined,
  // 各項目のエラー状態
  errorObj: {
    date: false,
  },
  // 未選択 or errorObjのいずれかがエラーの場合エラー
  isError: false,
}

/**
 * Datepickerの日付を保持するオブジェクト作成
 * @param {Object} action dispatchで受け取ったaction
 * @param {Object} state PicBidInfoのstate
 * @return {Object} 選択日付とエラー判定
 */
const createDateObj = (action, state) => {
  // 新規の選択日付、エラー状態
  const { date, newErrorObj } = action
  const { errorObj: currentErrorObj } = state
  const errorObj = newErrorObj ?? currentErrorObj

  // DatePickerが空文字対応していないため空文字の場合はnullを入れて初期値を空にする
  const datePickerStr = date === '' ? null : date

  // エラー状態を保持 DatePickerのonErrorのエラー状態を反映
  const isEmpty = !datePickerStr
  const isError = errorObj.date || isEmpty

  return {
    date: datePickerStr,
    errorObj,
    isError,
  }
}

/**
 * Datepickerの日付を保持 format:'YYYY-MM-DD'
 * @param {Object} [state=initDatepicker] 選択日付とエラー判定
 * @param {Object} action 下記の値を渡す
 * @param {String} date 選択日付
 * @return {Object} 選択日付とエラー判定
 */
const Datepicker = (state = initDatepicker, action) => {
  switch (action.type) {
    case DATEPICKER.SET:
      return createDateObj(action, state)
    default:
      return state
  }
}

export default Datepicker

export { initDatepicker }
