import React from 'react'
import { Grid } from '@material-ui/core'

import SpecialNotesHeader from '../../../../commonUseContext/Text/SpecialNotes/Header/SpecialNotesHeader'
import DialogSpecialNotes from '../../../../commonUseContext/Text/SpecialNotes/Dialog/DialogSpecialNotes'
import SpecialNotesTextFields from '../../../../commonUseContext/Text/SpecialNotes/Text/SpecialNotesTextFields'

/**
 * @return {JSX} BidInfo画面のSpecialNotesコンポーネント
 */
const SpecialNotesBidInfo = () => {
  return (
    <Grid container justify="flex-start">
      <SpecialNotesHeader />
      <SpecialNotesTextFields />
      <DialogSpecialNotes />
    </Grid>
  )
}

export default SpecialNotesBidInfo
