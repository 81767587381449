import { DATEPICKER } from '../../actions'

const initDatepickerStartFinish = {
  // DatePickerで選択中の開始日付
  startDate: undefined,
  // DatePickerで選択中の終了日付
  finishDate: undefined,
  // 各項目のエラー状態
  errorObj: {
    startDate: false,
    finishDate: false,
  },
  // 未選択 or errorObjのいずれかがエラーの場合エラー
  isError: false,
}

/**
 * 開始終了のDatepickerの日付を保持するオブジェクト作成
 * @param {Object} action dispatchで受け取ったaction
 * @param {Object} state PicBidInfoのstate
 * @return {Object} 開始終了とエラー判定
 */
const createDateObj = (action, state) => {
  // 新規の開始日付、終了日付、エラー状態
  const { startDate, finishDate, newErrorObj } = action
  const { errorObj: currentErrorObj } = state
  const errorObj = newErrorObj ?? currentErrorObj

  // DatePickerが空文字対応していないため空文字の場合はnullを入れて初期値を空にする
  const datePickerStartDate = startDate === '' ? null : startDate
  const datePickerFinishDate = finishDate === '' ? null : finishDate

  // エラー状態を保持
  const { startDate: isErrorStartDate, finishDate: isErrorFinishDate } =
    errorObj
  const isEmpty = !(datePickerStartDate && datePickerFinishDate)
  const isError = isErrorStartDate || isErrorFinishDate || isEmpty

  return {
    startDate: datePickerStartDate,
    finishDate: datePickerFinishDate,
    errorObj,
    isError,
  }
}

/**
 * 開始終了のDatepickerの日付を保持 format:'YYYY-MM-DD'
 * @param {Object} [state=initDatepickerStartFinish] 開始終了とエラー判定
 * @param {Object} action 下記の値を渡す
 * @param {String} startDate 開始日付
 * @param {String} finishDate 終了日付
 * @return {Object} 開始終了とエラー判定
 */
const DatepickerStartFinish = (state = initDatepickerStartFinish, action) => {
  switch (action.type) {
    case DATEPICKER.SET_START_FINISH:
      return createDateObj(action, state)
    default:
      return state
  }
}

export default DatepickerStartFinish

export { initDatepickerStartFinish }
