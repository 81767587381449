import Common from '../../../../constants/Common'

/**
 * @typedef {Object} TableInfo テーブル情報
 * @property {string} headerKey ヘッダーに表示する文言番号
 * @property {string} cellKey セルに表示するプロパティ名
 * @property {string} cellCategory Common.CELL_CATEGORYの値
 */

/**
 * @typedef {Object} SortInfo 並び替え情報
 * @property {string} sortTerm Common.SORT_TERMSの値
 * @property {string} wordKey 並び替え欄に表示する文言
 * @property {string} sortKey 並び替えに使用するプロパティ名
 */

const ConstantsLightFwdrBookingList = {
  /** @type {Number} 1ページに表示する件数 */
  PAGE_AMOUNT: 15,

  /** @type {TableInfo[]} テーブル作成に必要な値 */
  TABLE_ARR: [
    {
      headerKey: 'D501V0005',
      cellKey: 'airline',
      cellCategory: Common.CELL_CATEGORY.TEXT,
    },
    {
      headerKey: 'D102V0006',
      cellKey: 'orgDstStr',
      cellCategory: Common.CELL_CATEGORY.TEXT,
    },
    {
      headerKey: 'D203V0007',
      cellKey: 'mawbNumber',
      cellCategory: Common.CELL_CATEGORY.TEXT,
    },
    {
      headerKey: 'D102V0005',
      cellKey: 'cargoStr',
      cellCategory: Common.CELL_CATEGORY.TEXT,
    },
    {
      headerKey: 'D103V0109',
      cellKey: 'shc',
      cellCategory: Common.CELL_CATEGORY.BUTTON.SHOW,
    },
    {
      headerKey: 'D102V0008',
      cellKey: 'status',
      cellCategory: Common.CELL_CATEGORY.TEXT,
    },
    {
      headerKey: '4th0010',
      cellKey: 'info',
      cellCategory: Common.CELL_CATEGORY.BUTTON.CHECK,
    },
  ],

  /** @type {SortInfo[]} 並び替えの値 */
  SORT_ITEM_ARR: [
    {
      sortTerm: Common.SORT_TERMS.CREATED_AT,
      wordKey: 'D005V0003',
      sortKey: 'createdAt',
    },
    {
      sortTerm: Common.SORT_TERMS.CREATED_AT_DESENDING,
      wordKey: 'D102V0054',
      sortKey: 'createdAt',
    },
    {
      sortTerm: Common.SORT_TERMS.AIRLINE_ORDER,
      wordKey: 'D501V0005',
      sortKey: 'airline',
    },
    {
      sortTerm: Common.SORT_TERMS.ORG_ORDER,
      wordKey: 'D102V0061',
      sortKey: 'orgAirportCode',
    },
    {
      sortTerm: Common.SORT_TERMS.DST_ORDER,
      wordKey: 'D102V0062',
      sortKey: 'dstAirportCode',
    },
    {
      sortTerm: Common.SORT_TERMS.MAWB_NO_ASCENDING,
      wordKey: 'D203V0007',
      sortKey: 'mawbNumber',
    },
    {
      sortTerm: Common.SORT_TERMS.PCS_ORDER,
      wordKey: 'D102V0058',
      sortKey: 'pcs',
    },
    {
      sortTerm: Common.SORT_TERMS.WGT_ORDER,
      wordKey: 'D102V0059',
      sortKey: 'grossWgt',
    },
    {
      sortTerm: Common.SORT_TERMS.VOL_ORDER,
      wordKey: 'D102V0060',
      sortKey: 'volume',
    },
    {
      sortTerm: Common.SORT_TERMS.SHC_ORDER,
      wordKey: 'D116V0036',
      sortKey: 'shc',
    },
    {
      sortTerm: Common.SORT_TERMS.STATUS_ID_ORDER,
      wordKey: 'D102V0063',
      sortKey: 'statusId',
    },
  ],
}

export default ConstantsLightFwdrBookingList
