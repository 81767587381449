import React, { useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import HeaderText from '../../common/text/HeaderText'
import AppContext from '../../../contexts/AppContext'

const useStyles = makeStyles((theme) => ({
  head: theme.textBoldBLU,
  textBlk: {
    ...theme.textNormalBLK,
    marginLeft: '1rem',
    wordWrap: 'break-word',
  },
}))

/**
 * @param {Object} props - 以下
 * @param {Array} bidInfo - 入札情報
 * @return {JSX} Shipperコンポーネント
 */
const ThreeLetterCode = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { state } = useContext(AppContext)

  return (
    <Grid container spacing={3}>
      <Grid container item spacing={2}>
        <Grid item md={12} xs={12}>
          <HeaderText className={classes.head}>{t('D301V0013')}</HeaderText>
          <div className={classes.textBlk}>
            {state.companyinfo.fwdrthreeCode.value}
          </div>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ThreeLetterCode
