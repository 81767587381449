import React from 'react'
import PropTypes from 'prop-types'

import EditDialog from './EditDialog'

/**
 * @param {object} props 下記内容
 * @param {Number} props.row 貨物詳細
 * @param {Number} props.index インデックス
 * @return {JSX} EDITボタン
 */
const EditLightBookingRequest = (props) => {
  const { row, index } = props
  return <EditDialog cargoDetail={row} no={index} />
}

EditLightBookingRequest.propTypes = {
  row: PropTypes.object.isRequired,
  index: PropTypes.number,
}

export default EditLightBookingRequest
