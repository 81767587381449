import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

import CommonCalc from '../../../../common/function/CommonCalc'

const useStyles = makeStyles((theme) => ({
  red: theme.textNormalRED,
}))

/**
 * @param {object} props 下記内容
 * @param {object} props.contents 予約情報
 * @return {JSX} レート情報
 */
const RateLightSearchResult = ({ contents }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  /**
   * All-in rateがある場合に*All-in rateを返す
   * @return {JSX} *All-in rateの文字列を返す
   */
  const createAllInRate = () => {
    return <div className={classes.red}>{`*${t('2nd0018')}`}</div>
  }

  return (
    <>
      <div>{`${t('4th0011')}: ${contents.currency} ${CommonCalc.addComma(contents.rate, true)} / ${t('D009V0053')}`}</div>
      {contents.allInRate ? createAllInRate() : <></>}
    </>
  )
}

RateLightSearchResult.propTypes = {
  contents: PropTypes.object.isRequired,
}

export default RateLightSearchResult
