/** 共通文言
 * ２次リリースより文言を一カ所で管理
 */
const CommonDisplay = {
  '2nd0001': {
    en: 'Upload',
    ja: 'アップロード',
  },
  '2nd0002': {
    en: 'HAWB No.',
    ja: 'ハウス番号',
  },
  '2nd0003': {
    en: 'HAWB No. Ascending',
    ja: 'ハウス番号昇順',
  },
  '2nd0004': {
    en: 'HAWB No. Descending',
    ja: 'ハウス番号降順',
  },
  '2nd0005': {
    en: 'Commodity',
    ja: '品物',
  },
  '2nd0006': {
    en: 'SpecialNotes',
    ja: '特筆事項',
  },
  '2nd0007': {
    en: 'Please check the box if you want to upload your own Invoice.',
    ja: '独自のインボイスをアップロードする場合は、チェックボックスをオンにしてください。',
  },
  '2nd0008': {
    en: 'Please uncheck the box on the previous screen if you want to create the Packing List.',
    ja: 'パッキングリストを作成する場合は、前の画面のチェックボックスをオフにしてください。',
  },
  '2nd0009': {
    en: 'Company Name',
    ja: '会社名',
  },
  '2nd0010': {
    en: 'PIC',
    ja: '担当者',
  },
  '2nd0011': {
    en: 'Tel.',
    ja: '電話番号',
  },
  '2nd0012': {
    en: 'Do you want to display with As Agreed?',
    ja: '「As Agreed」で表示しますか？',
  },
  '2nd0013': {
    en: 'Yes',
    ja: 'はい',
  },
  '2nd0014': {
    en: 'No',
    ja: 'いいえ',
  },
  '2nd0015': {
    en: 'Freight Charges',
    ja: '航空運賃',
  },
  '2nd0016': {
    en: 'Terms',
    ja: '条件',
  },
  '2nd0017': {
    en: 'FSC',
    ja: '燃油サーチャージ',
  },
  '2nd0018': {
    en: 'All-in rate',
    ja: '全てレートに含む',
  },
  '2nd0019': {
    en: 'ex. We would like to request for a Direct flight/Transit flight',
    ja: 'ex. We would like to request for a Direct flight/Transit flight',
  },
  '2nd0020': {
    en: 'ex. ///HANDLE WITH CARE/// Please attach a Shock-Watch/a Tilt-Watch label.',
    ja: 'ex. ///HANDLE WITH CARE/// Please attach a Shock-Watch/a Tilt-Watch label.',
  },
  '2nd0021': {
    en: 'ex. Please maintain temparature between 2-8 degree during transportation/storage.',
    ja: 'ex. Please maintain temparature between 2-8 degree during transportation/storage.',
  },
  '2nd0022': {
    en: 'ex. UNXXXX,3,III-XXPcs., UNXXXX,8,II-XXPcs.',
    ja: 'ex. UNXXXX,3,III-XXPcs., UNXXXX,8,II-XXPcs.',
  },
  '2nd0023': {
    en: 'ex. C/No.XX:Non-Stackable',
    ja: 'ex. C/No.XX:Non-Stackable',
  },
  '2nd0024': {
    en: 'MAWB No',
    ja: 'マスター番号',
  },
  '2nd0025': {
    en: 'MAWB No. Ascending',
    ja: 'マスター番号昇順',
  },
  '2nd0026': {
    en: 'MAWB No. Descending',
    ja: 'マスター番号降順',
  },
  '2nd0027': {
    en: 'Please enter the tariff rates.',
    ja: 'タリフ料金を入力してください',
  },
  '2nd0028': {
    en: 'TARIFF RATE',
    ja: 'タリフ料金',
  },
  '2nd0029': {
    en: 'Please select either Prepaid or Collect.',
    ja: '前払いまたは着払いのいずれかを選択してください。',
  },
  '2nd0030': {
    en: 'Prepaid',
    ja: '前払い',
  },
  '2nd0031': {
    en: 'Collect',
    ja: '着払い',
  },
  '2nd0032': {
    en: 'Share',
    ja: 'シェア',
  },
  '2nd0033': {
    en: 'Who will you Send that document to?',
    ja: '誰にドキュメントを送りますか？',
  },
  '2nd0034': {
    en: 'Both of Them',
    ja: '両方',
  },
  '2nd0035': {
    en: 'Are you sure you want to delete?',
    ja: '削除してもよろしいですか？',
  },
  '2nd0036': {
    en: 'Please upload again.',
    ja: 'もう一度アップロードしてください',
  },
  '2nd0037': {
    en: 'Direct AWB',
    ja: 'ダイレクトAWB',
  },
  '2nd0038': {
    en: 'Update CNSL',
    ja: '混載を更新',
  },
  '2nd0039': {
    en: 'Cancel CNSL',
    ja: '混載をキャンセル',
  },
  '2nd0040': {
    en: 'TARIFF',
    ja: 'タリフ',
  },
  '2nd0041': {
    en: 'RATE',
    ja: '料金',
  },
  '2nd0042': {
    en: 'Tariff Rate',
    ja: 'タリフ料金',
  },
  '2nd0043': {
    en: 'That file has already been uploaded. ',
    ja: 'そのファイルはすでにアップロードされています。',
  },
  '2nd0044': {
    en: 'Please save the file under a different name.',
    ja: '別のファイル名で保存してください。',
  },
  '2nd0045': {
    en: 'RATE Mgmt.',
    ja: 'レート管理',
  },
  '3rd0001': {
    en: 'This site is down for maintenance, please check back later.',
    ja: 'メンテナンスのため一時サービスを停止しております。今しばらくお待ちください。',
  },
  '3rd0002': {
    en: 'BID Mgmt.',
    ja: '入札管理',
  },
  '3rd0003': {
    en: 'Set Forwarder',
    ja: '設定混載業者',
  },
  '3rd0004': {
    en: 'Deadline',
    ja: '締め切り日',
  },
  '3rd0005': {
    en: 'Draft',
    ja: '下書き',
  },
  '3rd0006': {
    en: 'Notify',
    ja: '通知',
  },
  '3rd0007': {
    en: 'Validity',
    ja: '有効期間',
  },
  '3rd0008': {
    en: 'BID Info.',
    ja: '入札情報',
  },
  '3rd0009': {
    en: 'Next',
    ja: '次へ',
  },
  '3rd0010': {
    en: 'ex. The deadline for submitting is 2022/08/12.',
    ja: 'ex. The deadline for submitting is 2022/08/12.',
  },
  '3rd0011': {
    en: 'ex. The Term of contract is one year.',
    ja: 'ex. The Term of contract is one year.',
  },
  '3rd0012': {
    en: 'ex. Should you have any questions or need more information, please do not hesitate to get in touch.',
    ja: 'ex. Should you have any questions or need more information, please do not hesitate to get in touch.',
  },
  '3rd0014': {
    en: 'unspecified',
    ja: '指定しない',
  },
  '3rd0015': {
    en: 'specified',
    ja: '指定する',
  },
  '3rd0016': {
    en: 'Ascending order',
    ja: '昇順',
  },
  '3rd0017': {
    en: 'BID Details',
    ja: '入札詳細',
  },
  '3rd0018': {
    en: 'Transit',
    ja: '輸送',
  },
  '3rd0019': {
    en: 'L/T',
    ja: 'L/T',
  },
  '3rd0020': {
    en: 'TTL Wgt.',
    ja: '合計重量',
  },
  '3rd0021': {
    en: 'TTL Vol.',
    ja: '合計容積',
  },
  '3rd0022': {
    en: 'Rate Range',
    ja: 'レート範囲',
  },
  '3rd0023': {
    en: 'Remark',
    ja: '備考',
  },
  '3rd0024': {
    en: '--Select--',
    ja: '--選択--',
  },
  '3rd0025': {
    en: 'Enter kg',
    ja: 'kgを入力',
  },
  '3rd0026': {
    en: 'Enter m3',
    ja: 'm3を入力',
  },
  '3rd0027': {
    en: 'Confirmation',
    ja: '確認',
  },
  '3rd0028': {
    en: 'Are you sure you want to request toward forwarders?',
    ja: 'フォワーダーにリクエストしてもよろしいですか?',
  },
  '3rd0029': {
    en: 'Are you sure you want confirm the above?',
    ja: '上記を確認してもよろしいですか？',
  },
  '3rd0030': {
    en: 'Are you sure you want to submit toward shipper?',
    ja: '荷送人に送信してもよろしいですか?',
  },
  '3rd0031': {
    en: 'Descending order',
    ja: '降順',
  },
  '4th0001': {
    en: 'REQ.',
    ja: 'リクエスト',
  },
  '4th0002': {
    en: 'BILLING',
    ja: '請求',
  },
  '4th0003': {
    en: 'REPORT',
    ja: '一覧',
  },
  '4th0004': {
    en: 'when paying',
    ja: '支払い時',
  },
  '4th0005': {
    en: 'when charging',
    ja: '集計時',
  },
  '4th0006': {
    en: 'Effective Date',
    ja: '適用日',
  },
  '4th0007': {
    en: 'MGMT.',
    ja: '管理',
  },
  '4th0008': {
    en: 'Please note that the expiration date cannot be changed once renewed.',
    ja: '更新後の有効期限の変更はできませんのでご注意ください。',
  },
  '4th0009': {
    en: 'FLIGHT MANAGEMENT',
    ja: '航空機管理',
  },
  '4th0010': {
    en: 'Info.',
    ja: '情報',
  },
  '4th0011': {
    en: 'A/F',
    ja: '航空運賃',
  },
  '4th0012': {
    en: 'Total A/F',
    ja: '航空運賃合計',
  },
  '4th0013': {
    en: 'Special Handling Code',
    ja: 'SHC',
  },
  '4th0014': {
    en: 'e.g. C/No.XX:Non-Stackable',
    ja: 'e.g. C/No.XX:Non-Stackable',
  },
  '4th0015': {
    en: 'e.g. UNXXXX,3,III-XXPcs., UNXXXX,8,II-XXPcs.',
    ja: 'e.g. UNXXXX,3,III-XXPcs., UNXXXX,8,II-XXPcs.',
  },
  '4th0016': {
    en: 'e.g. Please maintain temparature between XX - XX degrees during transportation/storage.',
    ja: 'e.g. Please maintain temparature between XX - XX degrees during transportation/storage.',
  },
  '4th0017': {
    en: 'Enter remark',
    ja: 'コメントを入力',
  },
  '4th0018': {
    en: 'BOOKING DETAILS',
    ja: '予約詳細',
  },
  '4th0019': {
    en: 'BOOKING INFO',
    ja: '予約情報',
  },
  '4th0020': {
    en: 'Request',
    ja: 'リクエスト',
  },
  '4th0021': {
    en: 'BOOKING REQUEST',
    ja: '予約リクエスト',
  },
  '4th0022': {
    en: 'Please enter only numbers.',
    ja: '数字のみ入力してください。',
  },
  '4th0023': {
    en: 'Client Status',
    ja: '顧客ステータス',
  },
  '4th0024': {
    en: 'A/F is below a Minimum rate.',
    ja: 'A/Fが最低レートを下回っています。',
  },
  '5th0001': {
    en: 'Reset Password',
    ja: 'パスワードリセット',
  },
  '5th0002': {
    en: 'Your email and password does not match. Please try again.',
    ja: 'メールアドレスとパスワードが一致しません。もう一度試してください。',
  },
  '5th0003': {
    en: 'Plan',
    ja: 'プラン',
  },
  '5th0004': {
    en: 'The number of people who can register exceeds the limit.',
    ja: '登録できる人数が制限を超えています。',
  },
  '5th0005': {
    en: 'In case of changing to the FREE plan, please switch to 10 people on the Account Info before changing the plan.',
    ja: 'FREEプランに変更する場合は、プラン変更前にアカウント情報を10人に切り替えてください。',
  },
  '5th0006': {
    en: 'In case of changing to the SATNDARD plan, please switch to 30 people on the Account Info before changing the plan.',
    ja: 'SATNDARDプランに変更する場合は、プラン変更前にアカウント情報を30人に切り替えてください。',
  },
  '5th0007': {
    en: 'In case of changing to the SATNDARD plan, please switch to 5 people on the Account Info before changing the plan.',
    ja: 'SATNDARDプランに変更する場合は、プラン変更前にアカウント情報を5人に切り替えてください。',
  },
  '5th0008': {
    en: 'The Invalid user includes in the choices.',
    ja: '無効なユーザーが選択肢に含まれています。',
  },
}
export default CommonDisplay
