import React from 'react'
import { useTranslation } from 'react-i18next'

import PrimaryButton from './PrimaryButton'

/**
/**
 * @param {Object} props - 以下
 * @param {String} addclass - 追加で適用するデザイン
 * @param {string} path 押下後の遷移先
 * @param {Event} clickevent - 押下後のイベント
 * @param {object} state - 遷移先に渡すデータ
 * @returns {JSX.Element} - Acceptボタンコンポーネント
 */
const AcceptButton = (props) => {
  const { t } = useTranslation()

  return <PrimaryButton {...props}>{t('D401V0017')}</PrimaryButton>
}

export default AcceptButton
