import {
  INIT_JH_TABLE,
  SEARCH_JH_TABLE,
  ADD_JH_TABLE,
  UPDATE_JH_TABLE,
  DELETE_JH_TABLE,
} from '../../../actions'
import CommonFunc from '../../../components/common/function/CommonFunc'

/** APIから抽出するデータを配列で保持 */
const keyArr = [
  { key: 'delFlg', initVal: 0 },
  'name',
  'lastName',
  'email',
  'tel',
  { key: 'signPicturePath', initVal: null },
  'authorityFlg',
  'userId',
]

/**
 * state: 表のデータを保持
 * @param {Object} [state={}] 表のデータとAPI
 * @param {Object} action 表データの検索に必要な値
 * @return {Object} 表データとAPI
 */
const Tables = (state = {}, action) => {
  switch (action.type) {
    case INIT_JH_TABLE:
      return CommonFunc.initCreateTable(action, keyArr)
    case SEARCH_JH_TABLE:
      return CommonFunc.searchTable(action, state, keyArr)
    case ADD_JH_TABLE:
      return CommonFunc.addTable(action, state)
    case UPDATE_JH_TABLE:
      return CommonFunc.updateTable(action, state)
    case DELETE_JH_TABLE:
      return CommonFunc.deleteTable(action, state)
    default:
      return state
  }
}

export default Tables
