import React, { useContext, useMemo } from 'react'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'

import BackButton from '../../../../common/buttons/BackButton'
import AppContext from '../../../../../contexts/AppContext'
import { BeforePath } from '../ConstantsLightBookingDetails'

/**
 * @param {Object} bookingInfo SearchResult画面から受け取った内容
 * @return {JSX} Backボタン
 */
const BackButtonLightBookingDetails = ({ bookingInfo }) => {
  const { state } = useContext(AppContext)
  const beforePath = state.ShippingDetails.beforePath

  return useMemo(
    () => (
      <Grid item>
        <BackButton
          path={beforePath}
          state={beforePath === BeforePath.SearchResult ? bookingInfo : null}
        />
      </Grid>
    ),
    [beforePath, bookingInfo]
  )
}

BackButtonLightBookingDetails.propTypes = {
  bookingInfo: PropTypes.object,
}

export default BackButtonLightBookingDetails
