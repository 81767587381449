/* eslint-disable brace-style */
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Paper from '@material-ui/core/Paper'
import InputBase from '@material-ui/core/InputBase'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'
import Divider from '@material-ui/core/Divider'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '5px 0px',
    display: 'flex',
    width: '100%',
    border: '1px solid',
    borderColor: theme.palette.border.white,
    borderBottomColor: theme.palette.border.black,
    borderRadius: 0,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: 5,
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    '& .MuiInputBase-input': {
      color: theme.fontColor.main,
    },
  },
  iconButton: {
    padding: 10,
  },
}))

/**
 * サーチボックス
 * @param {Object} props 下記を要素とするオブジェクトsearchfactors
 * @param {String} placeholder 空白時の表示
 * @param {Event} clickEvent 検索クリック時のevent
 * @param {Event} changeEvent 検索入力時のevent
 * @param {Event} resetClick ✕ボタン押下時のevent
 * @param {Object} inputProps 入力制限など
 * @returns {JSX.Element} - サーチボックスコンポーネント
 */
const SearchBox = (props) => {
  const classes = useStyles()

  let value = ''
  let placeholder = 'searchfactorsの対応お願いします。'
  let inputProps = {}
  let clickEvent = () => {}
  let changeEvent = () => {}
  let resetClick = () => {}

  if (props.searchfactors) {
    // 入力時の表示
    value = props.searchfactors.value ? props.searchfactors.value : ''
    // 空白時の表示
    placeholder = props.searchfactors.placeholder
      ? props.searchfactors.placeholder
      : ''
    // 入力制限など
    inputProps = props.searchfactors.inputProps
      ? props.searchfactors.inputProps
      : {}
    // 検索クリック時のevent
    clickEvent = props.searchfactors.clickEvent
      ? () => props.searchfactors.clickEvent()
      : () => {}
    // 検索入力時のevent
    changeEvent = props.searchfactors.changeEvent
      ? (e) => props.searchfactors.changeEvent(e)
      : () => {}
    // ✕ボタン押下時のevent
    resetClick = props.searchfactors.resetClick
      ? (e) => props.searchfactors.resetClick(e)
      : () => {}
  }

  return (
    <Paper className={classes.root}>
      <IconButton
        className={classes.iconButton}
        aria-label="search"
        onClick={() => clickEvent()}
      >
        <SearchIcon />
      </IconButton>
      <InputBase
        id="searchForm"
        value={value}
        className={classes.input}
        inputProps={inputProps}
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            clickEvent()
          }
        }}
        placeholder={placeholder}
        onChange={(e) => changeEvent(e)}
        autoComplete="off"
      />
      <IconButton
        className={classes.iconButton}
        aria-label="reset"
        onClick={() => resetClick()}
      >
        <ClearIcon />
      </IconButton>
      <Divider />
    </Paper>
  )
}

export default SearchBox
