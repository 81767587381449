import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'

import PrimaryButton from '../../../common/buttons/PrimaryButton'
import SecondaryButton from '../../../common/buttons/SecondaryButton'
import DeleteSmallButton from '../../../common/buttons/DeleteSmallButton'
import Dialog from '../../../common/dialog/Dialog'
import AppContext from '../../../../contexts/AppContext'
import {
  SEARCH_SHIP_TABLE,
  DELETE_SHIP_TABLE,
  UPDATE_SHIP_PAGING_DATA,
} from '../../../../actions'

const useStyles = makeStyles((theme) => ({
  head: theme.textNormalBLU,
  hr: theme.hr,
  icon: {
    fontSize: 24,
    color: theme.palette.required,
  },
  marginTop: {
    marginTop: '2rem',
  },
  saveText: theme.saveText,
  text: theme.textNormalBLK,
}))

/**
 * @param {Object} props - 以下
 * @param {array} row 削除するデータ
 * @param {number} index 行数
 * @returns {*} - DeleteDialog
 */
const DeleteDialog = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { state, dispatch } = useContext(AppContext)
  const [oldConsignee, setOldConsignee] = useState('')

  // OKボタン処理
  const updateTable = () => {
    dispatch({
      type: DELETE_SHIP_TABLE,
      index: state.Tables.editData.findIndex(
        (editData) => editData.consignee === oldConsignee
      ),
    })
    dispatch({
      type: SEARCH_SHIP_TABLE,
      data: state.Tables.editData,
      amount: state.Tables.contentNum,
    })

    if (state.Tables.showData[state.Paging.page].length === 1) {
      if (state.Paging.page !== 0) {
        // ページ内のデータがなくなったらページを1つ前に戻す
        dispatch({
          type: UPDATE_SHIP_PAGING_DATA,
          page: state.Paging.page - 1,
        })
      }
    }
  }

  // ダイアログ表示時 初期化処理
  const initialize = () => {
    setOldConsignee(props.row.consignee)
  }

  return (
    <Dialog
      buttonPC={<DeleteSmallButton onClick={initialize} />}
      buttonMobile={<DeleteSmallButton onClick={initialize} />}
      primary={
        <PrimaryButton onClick={updateTable}>{t('D004V0062')}</PrimaryButton>
      }
      secondary={<SecondaryButton>{t('D004V0063')}</SecondaryButton>}
      noborder
    >
      <div>
        {/* タイトル */}
        <Grid container item justify="center" alignItems="center">
          <WarningRoundedIcon className={classes.icon} />
        </Grid>
        {/* 本文 */}
        <Grid
          container
          justify="center"
          alignContent="center"
          spacing={1}
          className={classes.marginTop}
        >
          <Grid item style={{ textAlign: 'center' }} xs={12}>
            <div className={classes.saveText}>{t('D004V0079')}</div>
          </Grid>
          <Grid item style={{ marginBottom: '2rem' }} md={10} xs={10}>
            <hr className={classes.hr} />
          </Grid>
          <Grid item className={classes.text} style={{ textAlign: 'left' }}>
            {props.row.companyName}
            <br />
            {props.row.address} {props.row.postalCode}, {props.row.country}
            <br />
            Tel. {props.row.tel} Attn: {props.row.firstName}{' '}
            {props.row.lastName}
          </Grid>
        </Grid>
      </div>
    </Dialog>
  )
}

export default DeleteDialog
