import { Grid } from '@material-ui/core'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { CHECKBOX } from '../../../../../actions'
import AppContext from '../../../../../contexts/AppContext'
import CheckBox from '../../../../common/checkBox/CheckBox'

/**
 * checkboxを表示
 * @return {JSX} チェックボックスコンポーネント
 */
const CheckboxConfirmation = () => {
  const { state, dispatch } = useContext(AppContext)
  const { t } = useTranslation()

  const label = t('3rd0030')

  const onChange = (checked) => {
    dispatch({
      type: CHECKBOX.SET,
      checked,
    })
  }

  return (
    <Grid item container justify="center" md={12}>
      <Grid item>
        <CheckBox
          label={label}
          checked={state.Checkbox}
          onChange={(event) => onChange(event.target.checked)}
        />
      </Grid>
    </Grid>
  )
}

export default CheckboxConfirmation
