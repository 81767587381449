import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import CommonFunc from '../../../../common/function/CommonFunc'
import Common from '../../../../../constants/Common'
import ConstantsClientReg from './ConstantsClientReg'

const { SHIPPER, FWDR, CARRIER } = ConstantsClientReg.CARD_SETTING.ORDER
/** @type {Object} 各業者タブの表示設定 */
const contentsSetting = {
  [Common.CLIENT_TAB.SHIPPER]: SHIPPER,
  [Common.CLIENT_TAB.FWDR]: FWDR,
  [Common.CLIENT_TAB.CARRIER]: CARRIER,
}

const useStyles = makeStyles((theme) => ({
  title: {
    ...theme.textBoldBLU,
    ...theme.wordBreak,
  },
  contents: {
    ...theme.textNormalBLK,
    ...theme.wordBreak,
  },
}))

/**
 * リクエストカードの表示
 * @param {Object} data APIから受け取ったデータ
 * @param {Number} selectTabIndex 現在選択中のタブindex
 * @return {JSX} リクエストカードの表示コンポーネント
 */
const RequestCardContentsClientReg = ({ data, selectTabIndex }) => {
  const classes = useStyles()
  const { t, i18n } = useTranslation()

  /**
   * 各項目の表示を作成
   * @param {String} key APIオブジェクトの表示キー
   * @param {Boolean} isDate 日付判定
   * @return {String} 各項目の表示文字列
   */
  const createContents = (key, isDate) => {
    // 登録されていない場合は‐を表示
    const contents = data[key] ? data[key] : '-'
    // 変換処理がある場合は変換
    const convertContents = isDate
      ? CommonFunc.convertUtcToLocalStr(contents, i18n)
      : contents
    return convertContents
  }

  // 現在選択中の業者をすべて表示
  const contentsArr = contentsSetting[selectTabIndex]
  return contentsArr.map(
    ({
      title,
      key,
      titleGrid = { xs: 4 },
      contentGrid = { xs: 6 },
      isDate,
    }) => (
      <Grid container item xs={11} spacing={1} justify="flex-end" key={title}>
        <Grid item {...titleGrid} className={classes.title}>
          {t(title)}
        </Grid>
        <Grid item {...contentGrid} className={classes.contents}>
          {createContents(key, isDate)}
        </Grid>
      </Grid>
    )
  )
}

RequestCardContentsClientReg.propTypes = {
  data: PropTypes.object.isRequired,
  selectTabIndex: PropTypes.number.isRequired,
}

export default RequestCardContentsClientReg
