import React from 'react'
import MAccordion from '@material-ui/core/Accordion'

/**
 * @param {Object} props - 以下
 * @param {useStyles} className - スタイルを指定
 * @returns {JSX.Element} - Accordion
 */
const Accordion = (props) => {
  return (
    <MAccordion elevation={0} className={props.className} {...props}>
      {props.children}
    </MAccordion>
  )
}

export default Accordion
