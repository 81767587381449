import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import AppContext from '../../../../../../contexts/AppContext'
import InputSelect from '../../../../../common/select/InputSelect'
import Content from '../../03AccordionCarFlightMgmt/Content'
import { LIGHT_FLIGHT_MGMT } from '../../../../../../actions'

/**
 * @param {*} props 下記内容のProps
 * @param {Boolean} props.org ORGの場合true
 * @param {Number} props.index Routingのインデックス
 * @returns {JSX} 空港のセレクト欄
 */
const AirportSelect = (props) => {
  const { org, index } = props
  const { t } = useTranslation()
  const { state, dispatch } = useContext(AppContext)

  /** @type{Function} Routingの情報を取得する */
  const getRoutingParam = state.Popup.getRoutingParam
  /** @type{Function} FlightInfoの情報を取得する */
  const getFlightInfoParam = state.Popup.getFlightInfoParam

  /** @type{String} Airport欄の更新判定用の文字列 */
  const airportIdStr = getFlightInfoParam('routingArr')
    .map(({ orgId, dstId }) => `${orgId}${dstId}`)
    .join('')

  return useMemo(() => {
    /** @type {Boolean} 自動選択フラグ */
    const isAutoSelect = org && index > 0
    /** @type {Number} Routingのインデックス */
    const paramIndex = isAutoSelect ? index - 1 : index
    /** @type {String} 取得データ名 */
    const paramName = isAutoSelect || !org ? 'dstId' : 'orgId'
    /** @type {Number} 選択中の空港ID */
    const airportId = getRoutingParam(paramIndex, paramName)
    /** @type {Object} 選択中の空港情報 */
    const selectedAirport = state.Airport.getAirportInfo(airportId)
    /** @type {Boolean} 非活性状態 */
    const disabled = isAutoSelect || !state.Popup.add

    /** @type {Number[]} 他の選択肢で選択中の空港IDの配列 */
    const selectedAirportIdArr = getFlightInfoParam('routingArr')
      .map(({ orgId, dstId }, routingIndex) => {
        return index === routingIndex ? [org ? dstId : orgId] : [orgId, dstId]
      })
      .flat()
      .filter((v) => v)

    /** @type {Object[]} 選択肢に表示する空港情報の配列 */
    const options = disabled
      ? [selectedAirport]
      : state.Airport.airports.filter(
          ({ airportId }) => !selectedAirportIdArr.includes(airportId)
        )

    /**
     * 空港更新
     * @param {Object} value 選択した空港
     * @returns {void} なし
     */
    const onChange = (value) => {
      dispatch({
        type: LIGHT_FLIGHT_MGMT.EDIT.ROUTING,
        index,
        [org ? 'orgId' : 'dstId']: value?.airportId ?? null,
      })
    }

    return (
      <Content
        title={t(org ? 'D301V0223' : 'D301V0224')}
        content={
          <InputSelect
            options={options}
            getOptionLabel={(option) => option.airportCode}
            value={selectedAirport}
            onChange={(_, value) => onChange(value)}
            disabled={disabled}
          />
        }
      />
    )
    // eslint-disable-next-line
  }, [
    airportIdStr,
    dispatch,
    index,
    org,
    state.Airport,
    t,
    state.Popup.add,
    getRoutingParam,
    getFlightInfoParam,
  ])
}
AirportSelect.propTypes = {
  org: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
}
export default AirportSelect
