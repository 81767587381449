import React from 'react'
import { Grid } from '@material-ui/core'

import CommoditySelectLightBookingRequest from './CommoditySelectLightBookingRequest'
import AirportSelectLightBookingRequest from './AirportSelectLightBookingRequest'

/**
 * @returns {JSX} 発地着地とCommodityセレクトボックス
 */
const SelectBoxLightBookingRequest = () => {
  return (
    <Grid container justify="center">
      <Grid
        container
        item
        md={10}
        justify="space-between"
        alignItems="flex-end"
      >
        <AirportSelectLightBookingRequest />
        <CommoditySelectLightBookingRequest />
      </Grid>
    </Grid>
  )
}
export default SelectBoxLightBookingRequest
