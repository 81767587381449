import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

import AppContext from '../../../../../contexts/AppContext'
import AccordionLightBookingDetails from '../../../../commonUseContext/LightBookingDetails/Accordion/AccordionLightBookingDetails'

const useStyles = makeStyles((Theme) => ({
  label: Theme.textBoldBLK,
}))

/**
 * @return {JSX} MawbNoアコーディオン
 */
const MawbNoAirlineLightBookingDetails = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { state } = useContext(AppContext)

  const { prefix, firstHalf, latterHalf } = state.MawbNumber.mawbNumberObj
  const mawbNumber =
    prefix === '' ? '-' : `${prefix}-${firstHalf} ${latterHalf}`

  return useMemo(
    () => (
      <AccordionLightBookingDetails label={t('D301V0112')}>
        <div className={classes.label}>{mawbNumber}</div>
      </AccordionLightBookingDetails>
    ),
    [mawbNumber, classes, t]
  )
}

export default MawbNoAirlineLightBookingDetails
