import { Grid } from '@material-ui/core'
import React, { useContext } from 'react'

import BackButtonBidDetails from './BackButtonBidDetails'
import SaveAsDraftButtonBidDetails from './SaveAsDraftButtonBidDetails'
import ConfirmButtonBidDetails from './ConfirmButtonBidDetails'
import AppContext from '../../../../../contexts/AppContext'

import Common from '../../../../../constants/Common'
import ConstantsBidDetails from '../ConstantsBidDetails'
import CommonValidation from '../../../../common/function/CommonValidation'

/**
 * @return {JSX} ボタンコンポーネント
 */
const ButtonBidDetails = () => {
  const { state } = useContext(AppContext)

  /** 必須項目のcellKeyの配列 */
  const requiredKeyArr = ConstantsBidDetails.TABLE_ARR.filter(
    (table) => table.required
  )
    .map((table) => table.cellKey.split(','))
    .flat()

  /** 入力チェックを行うデータの配列 */
  const checkDataArr = state.Tables.editData.filter(
    ({ delFlg }) => delFlg !== Common.DEL_FLG.ON
  )

  /**
   * 必須項目に空欄があるか確認
   * @returns {boolean} 空欄があればtrue
   */
  const getHasEmptyCell = () => {
    /** 各行に未入力欄があるかの配列 */
    const hasEmptyCells = checkDataArr.map((data) =>
      // 未入力欄があればtrueを返す
      requiredKeyArr.map((cellKey) => !data[cellKey]).includes(true)
    )
    /** 1行でも未入力欄がある行があればtrue */
    const hasEmptyCell = hasEmptyCells.includes(true)
    return hasEmptyCell
  }

  /**
   * エラーの入力欄があるか確認
   * @returns {boolean} エラーがあればtrue
   */
  const getHasErrorCell = () => {
    /** ORG/DST/Commodity欄の値の配列 */
    const selectStrArr = []
    /** エラーの入力欄があるか */
    let isErr = false

    // 削除されていないテーブルの全データを確認
    checkDataArr.map((data) => {
      //既にエラーの場合は判定処理を飛ばす
      if (isErr) {
        return null
      }

      const {
        orgId,
        dstId,
        commId,
        ttlMaxVol,
        ttlMaxWgt,
        ttlMinVol,
        ttlMinWgt,
        remark,
      } = data
      //ORG/DST/Commodity欄の確認
      if (orgId && dstId && commId) {
        selectStrArr.push(`${orgId}${dstId}${commId}`)
      }
      // 各入力欄のバリデーション確認
      const ttlMaxVolErr = CommonValidation.ttlWgtVol(ttlMaxVol)
      const ttlMinVolErr = CommonValidation.ttlWgtVol(ttlMinVol)
      const ttlMaxWgtErr = CommonValidation.ttlWgtVol(ttlMaxWgt)
      const ttlMinWgtErr = CommonValidation.ttlWgtVol(ttlMinWgt)
      const remarkErr = CommonValidation.remark(remark)

      isErr =
        ttlMaxVolErr.checkResult ||
        ttlMinVolErr.checkResult ||
        ttlMaxWgtErr.checkResult ||
        ttlMinWgtErr.checkResult ||
        remarkErr.checkResult
      return null
    })

    // バリデーションエラーの入力欄がなかった場合
    // ORG/DST/Commodity欄の値の配列に重複があるか確認
    if (!isErr) {
      /** 重複を取り除いた配列の長さ */
      const fixedLength = [...new Set(selectStrArr)].length
      // 配列の長さが異なれば重複あり
      isErr = fixedLength !== selectStrArr.length
    }
    return isErr
  }

  /**
   * TtlWgtとttlVolのMinとMaxの値を確認
   * @returns {boolean} Min > Maxの値があればtrue
   */
  const getTtlValidation = () => {
    const ttlValidation = checkDataArr
      .map(({ ttlMinWgt, ttlMaxWgt, ttlMinVol, ttlMaxVol }) => {
        const volCheck = parseFloat(ttlMinVol) > parseFloat(ttlMaxVol)
        const wgtCheck = parseFloat(ttlMinWgt) > parseFloat(ttlMaxWgt)
        return volCheck || wgtCheck
      })
      .includes(true)
    return ttlValidation
  }

  /**
   * Confirmボタンの非活性判定
   * @returns {boolean} 非活性判定
   */
  const getConfirmDisabled = () => {
    let isDisabled = false
    // 新規登録時の非活性判定
    if (state.IsNew) {
      /** 未入力欄があるか確認 */
      const hasEmptyCell = getHasEmptyCell()
      /** エラーの入力欄があるか確認 */
      const hasErrorCell = getHasErrorCell()
      /** TTLWgt, TTLVol欄のバリデーションチェック */
      const ttlValidation = getTtlValidation()

      // 1つでもtrueがあれば非活性
      isDisabled = hasEmptyCell || hasErrorCell || ttlValidation
    } else {
      // フォワーダー選定時の非活性判定
      // Select欄に１つもチェックが無い場合非活性
      isDisabled =
        state.Tables.editData.findIndex(({ statusId, statusStr }) => {
          const isShowCheckbox = statusId === Common.BID_STATUS.UPDATE.ID
          const isChecked =
            isShowCheckbox && statusStr === Common.BID_STATUS.CLOSE.TEXT
          return isChecked
        }) === -1
    }

    return isDisabled
  }

  /**
   *SaveAsDraftボタンの非活性判定
   * @returns {boolean} 非活性判定
   */
  const getSaveAsDraftDisabled = () => {
    /** エラーの入力欄があるか確認 */
    const hasErrorCell = getHasErrorCell()
    /** TTLWgt, TTLVol欄のバリデーションチェック */
    const ttlValidation = getTtlValidation()

    // 1つでもtrueがあれば非活性
    return hasErrorCell || ttlValidation
  }

  return (
    <Grid item container justify="center" md={12} spacing={2}>
      <Grid item>
        <BackButtonBidDetails />
      </Grid>
      {state.IsNew ? (
        <Grid item>
          <SaveAsDraftButtonBidDetails disabled={getSaveAsDraftDisabled()} />
        </Grid>
      ) : null}
      <Grid item>
        <ConfirmButtonBidDetails disabled={getConfirmDisabled()} />
      </Grid>
    </Grid>
  )
}

export default ButtonBidDetails
