import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff'

const useStyles = makeStyles(() => ({
  noIcon: {
    color: 'rgba(0,0,0,0)',
  },
}))

const FlightTakeOff = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {props.index === props.sample ? (
        <FlightTakeoffIcon />
      ) : (
        <FlightTakeoffIcon className={classes.noIcon} />
      )}
    </div>
  )
}

export default FlightTakeOff
