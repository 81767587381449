import { TABLES } from '../../../actions'
import CommonFunc from '../../../components/common/function/CommonFunc'
import Common from '../../../constants/Common'
import ConstantsBidDetails from '../../../components/pages/shipper/shipperBidDetails/ConstantsBidDetails'
import CommonCalc from '../../../components/common/function/CommonCalc'

/** 初期表示ページ */
const initTablesBidDetails = {
  // APIの元のデータを保持
  apiDataArr: [],
  // 表示用のデータを保持
  showData: [[]],
  editData: [],
}

/**
 * 表示用データを作成
 * @param {Object} bidDataObj 入札のAPIデータ
 * @param {Number} apiIndexNum APIの番号
 * @return {Object} 表示用データ
 */
const createDisplayObj = (bidDataObj, apiIndexNum) => {
  const {
    airlineStr,
    commStr,
    currencyStr,
    dstStr,
    flightTypeStr,
    forwarderStr,
    fsc,
    large,
    leadTimeStr,
    light,
    max,
    medium,
    min,
    minrate,
    normal,
    orgStr,
    remark,
    statusStr,
    termsStr,
    transitStr,
    ttStr,
    ttlMaxVol,
    ttlMaxWgt,
    ttlMinVol,
    ttlMinWgt,
    statusId,
  } = bidDataObj

  //ttlWgtとttlVolを修正
  const ttlVol = `${CommonCalc.addComma(ttlMinVol, true)} m3 - ${CommonCalc.addComma(ttlMaxVol, true)} m3`
  const ttlWgt = `${CommonCalc.addComma(ttlMinWgt, true)} kg - ${CommonCalc.addComma(ttlMaxWgt, true)} kg`

  //apiIndexNumから検索用のNo欄の文字列を取得
  const apiIndexNumStr = `${apiIndexNum + 1}.`

  //各数値にカンマを付ける
  const numberObj = { fsc, minrate, normal, min, light, medium, large, max }
  const addedCommaNumberObj = {}
  Object.keys(numberObj).map((key) => {
    addedCommaNumberObj[`${key}Str`] = CommonCalc.addComma(numberObj[key], true)
    return null
  })

  const fixedStatusId =
    statusId !== null ? statusId : Common.BID_STATUS.REQUEST.ID
  const fixedStatusStr =
    statusStr !== '' ? statusStr : Common.BID_STATUS.REQUEST.TEXT

  //select欄並び替え用の値
  const isShowCheckbox = fixedStatusId === Common.BID_STATUS.UPDATE.ID
  const isChecked =
    isShowCheckbox && fixedStatusStr === Common.BID_STATUS.CLOSE.TEXT
  let sortSelectId = -1
  if (isChecked) {
    sortSelectId = 0 //チェック付きが最初
  } else if (isShowCheckbox) {
    sortSelectId = 1 //チェック無しが次
  } else {
    sortSelectId = 2 // チェック欄無しが最後
  }

  return {
    apiIndexNum,
    apiIndexNumStr,
    airlineStr,
    commStr,
    currencyStr,
    dstStr,
    flightTypeStr,
    forwarderStr,
    leadTimeStr,
    orgStr,
    remark,
    statusStr: fixedStatusStr,
    termsStr,
    transitStr,
    ttStr,
    ttlVol,
    ttlWgt,
    sortSelectId,
    ...addedCommaNumberObj,
  }
}

/**
 * 定数のソート一覧からソートに必要なデータを抜き出して作成
 * @param {Object} bidDataObj 入札のAPIデータ
 * @return {Object} ソート用データ
 */
const createSortObj = (bidDataObj) => {
  const sortArr = ConstantsBidDetails.SORT_ITEM_ARR.map(({ sortKey }) => {
    const apiVal = bidDataObj[sortKey]
    return [sortKey, apiVal]
  })
  return Object.fromEntries(sortArr)
}

/**
 * 表示用のデータに変換
 * @param {Array} apiDataArr 入札のAPIデータ
 * @returns {Array} 表示、並び替え用情報配列
 */
const convertShowCargoDataArr = (apiDataArr) =>
  apiDataArr.map((bidDataObj, apiIndexNum) => {
    const displayObj = createDisplayObj(bidDataObj, apiIndexNum)
    const sortObj = createSortObj(bidDataObj)
    delete sortObj.sortSelectId
    delete sortObj.statusStr

    return {
      // 表示用
      ...displayObj,
      // 並び替え用
      ...sortObj,
      // API特定用番号
      apiIndexNum: bidDataObj.apiIndexNum ?? apiIndexNum,
    }
  })

/**
 *
 * @param {Array} apiDataArr 入札のAPIデータ
 * @param {String} search 検索ワード
 * @param {String} sort ソート
 * @return {Object} 更新したTablesBidDetailsのstate
 */
const createCommonTableDataObj = (apiDataArr, search, sort) => {
  // sort名から並び替えの対象となるkeyを取得
  const sortDataName = ConstantsBidDetails.SORT_ITEM_ARR.find(
    ({ sortTerm }) => sortTerm === sort
  ).sortKey
  // 検索、並び替え用にAPIの値を変換した後表示用データを作成
  const showCargoDataArr = convertShowCargoDataArr(apiDataArr)
  const { showData } = CommonFunc.searchSortData(
    showCargoDataArr,
    search,
    sort,
    sortDataName,
    ConstantsBidDetails.PAGE_AMOUNT
  )

  const editData = apiDataArr.map((data, index) => ({
    ...data,
    apiIndexNum: index,
    statusStr:
      data.statusStr !== '' ? data.statusStr : Common.BID_STATUS.REQUEST.TEXT,
    statusId:
      data.statusId !== null ? data.statusId : Common.BID_STATUS.REQUEST.ID,
  }))

  // 表示用データとAPIの値全部と必須に分けて保持
  return {
    // 表示用データ
    showData,
    // APIデータ
    apiDataArr,
    // 編集用データ
    editData,
  }
}

/**
 * TablesBidDetailsのstateの初期設定を作成
 * @param {Object} action dispatchで受け取ったaction
 * @return {Object} 初期設定のTablesBidDetailsのstate
 */
const createInitTableDataObj = (action) => {
  const { data, sort } = action
  const { apiDataArr, editData } = createCommonTableDataObj(data, '', sort)

  // 削除データを取り除いた表示用データを作成する
  const showData = createEditedShowData(editData)

  // 表示用データとAPIの値を全部と必須に分けて保持
  return {
    // 表示用データ
    showData,
    // APIデータ
    apiDataArr,
    // 編集用データ
    editData,
  }
}

/**
 * 変更された検索ワード、ソートからTablesBidDetailsのstateを作成
 * @param {Object} action dispatchで受け取ったaction
 * @param {Object} state TablesBidDetailsのstate
 * @return {Object} 表示のみ更新したTablesBidDetailsのstate
 */
const createSetTableDataObj = (action, state) => {
  const { search, sort } = action
  const { editData } = state
  const { showData } = createCommonTableDataObj(editData, search, sort)

  // 表示用データとAPIの値を全部と必須に分けて保持
  return {
    ...state,
    // 表示用データ
    showData,
  }
}

/**
 * データ追加・削除後のShowDataを作成して返す
 * @param {Array} editedData 編集後のeditData
 * @returns {Array} showData
 */
const createEditedShowData = (editedData) => {
  const dataArr = editedData.filter(
    ({ delFlg }) => delFlg !== Common.DEL_FLG.ON
  )
  // 追加・削除するのは新規画面のみのため、sortはNo.順固定
  const { showData } = createCommonTableDataObj(
    dataArr,
    '',
    ConstantsBidDetails.SORT_ITEM_ARR[ConstantsBidDetails.DEFAULT_SORT_TERM]
      .sortTerm
  )
  return showData
}

/**
 * 編集時の処理
 * @param {Object} action dispatchで受け取ったaction
 * @param {Object} state TablesBidDetailsのstate
 * @return {Object} 表示のみ更新したTablesBidDetailsのstate
 */
const editTableDataObj = (action, state) => {
  const {
    index,
    cellKey,
    id = undefined,
    str = undefined,
    value = undefined,
    checked = undefined,
    isAdd = undefined,
  } = action

  //新規行追加
  if (isAdd) {
    const length = state.editData.length
    const newApiIndexNum =
      state.editData.reduce((max, data) => Math.max(max, data.apiIndexNum), 0) +
      1
    state.editData[length] = ConstantsBidDetails.CREATE_INIT_DATA_OBJ()
    state.editData[length].apiIndexNum = newApiIndexNum
    //追加後のshowDataを作成
    state.showData = createEditedShowData(state.editData)
  }
  //選択肢のidを更新
  if (id !== undefined) {
    state.editData[index][cellKey] = id
  }
  //選択肢の文字列を更新
  if (str !== undefined) {
    const strCellKey = `${cellKey.split('Id')[0]}Str`
    state.editData[index][strCellKey] = str
  }
  //テキストを更新
  if (value !== undefined) {
    state.editData[index][cellKey] = value
  }
  //Selectを更新
  if (checked !== undefined) {
    const { orgId, dstId, commId } = state.editData[index]
    state.editData.map((data) => {
      /** orgId, dstId, commIdが一致しているか判定 */
      const isSameLane =
        data.orgId === orgId && data.dstId === dstId && data.commId === commId
      /**画面表示時のステータスIDがN/A以外か判定 */
      const isNotNA = data.statusId !== Common.BID_STATUS.NA.ID
      // 更新対象のデータの場合
      if (isSameLane && isNotNA) {
        if (checked) {
          //チェックを付けた場合Lostに変更
          data[cellKey] = Common.BID_STATUS.LOST.TEXT
        } else {
          //チェックを外した場合元のステータスに変更
          data[cellKey] = Object.entries(Common.BID_STATUS).find(
            (status) => status[1].ID === data.statusId
          )[1].TEXT
        }
      }
      return data
    })
    //チェックを変更した入札をClose/Updateにする
    state.editData[index][cellKey] = checked
      ? Common.BID_STATUS.CLOSE.TEXT
      : Common.BID_STATUS.UPDATE.TEXT
  }

  return { ...state }
}

/**
 * テーブル削除時の処理
 * @param {Object} action dispatchで受け取ったaction
 * @param {Object} state TablesBidDetailsのstate
 * @return {Object} テーブル削除時の処理後のTablesBidDetailsのstate
 */
const clearTableDataObj = (action, state) => {
  const { index } = action
  const { bidDetailsId = undefined } = state.editData[index]
  const length = state.editData.filter(
    ({ delFlg }) => delFlg !== Common.DEL_FLG.ON
  ).length

  if (length === 1) {
    // 1行しかない場合、初期化する
    if (bidDetailsId === undefined) {
      // 未登録データの場合
      state.editData[index] = ConstantsBidDetails.CREATE_INIT_DATA_OBJ()
      const newApiIndexNum =
        state.editData.reduce(
          (max, data) => Math.max(max, data.apiIndexNum),
          0
        ) + 1
      state.editData[index].apiIndexNum = newApiIndexNum
    } else {
      // 登録済みデータの場合
      state.editData[index] = {
        ...ConstantsBidDetails.CREATE_INIT_DATA_OBJ(),
        apiIndexNum: state.editData[index].apiIndexNum,
        bidDetailsId: state.editData[index].bidDetailsId,
        laneInfoArr: state.editData[index].laneInfoArr,
        updatedAt: state.editData[index].updatedAt,
      }
    }
  } else if (bidDetailsId !== undefined) {
    // 登録済みデータの場合、delFlgを立てる
    state.editData[index].delFlg = Common.DEL_FLG.ON
  } else {
    // 未登録のデータの場合、物理削除
    state.editData.splice(index, 1)
  }

  // 削除後のshowDataを作成
  state.showData = createEditedShowData(state.editData)

  return { ...state }
}

/**
 * 表データを保持
 * @param {Object} [state=initTablesBidDetails] 表データ
 * @param {Object} action 表データの検索に必要な値
 * @return {Object} 表データ
 */
const TablesBidDetails = (state = initTablesBidDetails, action) => {
  switch (action.type) {
    case TABLES.INIT:
      return createInitTableDataObj(action)
    case TABLES.SET:
      return createSetTableDataObj(action, state)
    case TABLES.CLEAR:
      return clearTableDataObj(action, state)
    case TABLES.EDIT:
      return editTableDataObj(action, state)
    default:
      return state
  }
}

export default TablesBidDetails

export { initTablesBidDetails }
