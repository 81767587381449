import { LIGHT_BOOKING_DETAILS } from '../../../actions'
import Common from '../../../constants/Common'

/** @type {Object} Statusの初期値 */
const initStatus = {
  statusId: null,
  isCancel: false,
  isClose: false,
  isBookingRequest: false,
  isSpaceConfirmed: false,
}

/**
 * Statusを保持
 * @param {Object} action action
 * @param {Number} action.statusId statusId
 * @return {Object} state
 */
const set = (action) => {
  const { statusId = null } = action
  const isCancel = statusId === Common.STATUS.CANCEL.ID
  const isClose = statusId === Common.STATUS.CLOSE.ID
  const isBookingRequest = statusId === Common.STATUS.BOOKING_REQUEST.ID
  const isSpaceConfirmed = statusId === Common.STATUS.LIGHT_SPACE_CONFIRMED.ID
  return { statusId, isCancel, isClose, isBookingRequest, isSpaceConfirmed }
}

/**
 * 貨物のStatus情報を保持
 * @param { Object } [state = initStatus] state
 * @param { Object } action action
 * @return { Object } state
 */
const Status = (state = initStatus, action) => {
  const { type, ...rest } = action
  switch (type) {
    case LIGHT_BOOKING_DETAILS.STATUS.SET:
      return set(rest)
    default:
      return state
  }
}

export default Status

export { initStatus }
