// Display + 画面名
const DisplayFwdrCompleteBooking = {
  // 画面ID + 文言ID
  D103V0001: {
    en: 'COMPLETE BOOKING',
    ja: '予約完了',
  },
  D103V0002: {
    en: 'Shipping Details',
    ja: '発送詳細',
  },
  D103V0003: {
    en: 'Pcs.',
    ja: '個数',
  },
  D103V0004: {
    en: 'Gross Wgt.(kg)',
    ja: '総重量(kg)',
  },
  D103V0005: {
    en: 'Vol.(m3)',
    ja: '容量(m3)',
  },
  D103V0006: {
    en: 'Chargeable Wgt.(kg)',
    ja: '積載可能重量(kg)',
  },
  D103V0007: {
    en: 'ORG/DST',
    ja: '出発/到着',
  },
  D103V0008: {
    en: 'SHC Code',
    ja: 'SHCコード',
  },
  D103V0009: {
    en: 'Current Status',
    ja: 'ステータス',
  },
  D103V0010: {
    en: '10',
    ja: '10',
  },
  D103V0011: {
    en: '300.00',
    ja: '300.00',
  },
  D103V0012: {
    en: '2.05',
    ja: '2.05',
  },
  D103V0013: {
    en: 'NRT/ORD',
    ja: 'NRT/ORD',
  },
  D103V0014: {
    en: 'COL/ICE',
    ja: 'COL/ICE',
  },
  D103V0015: {
    en: 'Booking Request',
    ja: '予約リクエスト',
  },
  D103V0016: {
    en: 'Measure&Weight',
    ja: '実測',
  },
  D103V0017: {
    en: 'Please enter the accurate DIM & G/W.',
    ja: '正確な寸法＆総重量を入力してください。',
  },
  D103V0018: {
    en: 'G/W(kg)',
    ja: '総重量(kg)',
  },
  D103V0019: {
    en: 'Length(cm)',
    ja: '縦(cm)',
  },
  D103V0020: {
    en: 'Width(cm)',
    ja: '幅(cm)',
  },
  D103V0021: {
    en: 'Height(cm)',
    ja: '高さ(cm)',
  },
  D103V0022: {
    en: 'XX kg',
    ja: 'XX kg',
  },
  D103V0023: {
    en: 'XX cm',
    ja: 'XX cm',
  },
  D103V0024: {
    en: 'Edit',
    ja: '編集',
  },
  D103V0025: {
    en: 'Update',
    ja: '更新',
  },
  D103V0026: {
    en: 'Flight#',
    ja: 'フライト#',
  },
  D103V0027: {
    en: 'UA 838 / NRT - SFO / Boeing 787',
    ja: 'UA 838 / NRT - SFO / ボーイング787',
  },
  D103V0028: {
    en: "16:00 Narita Int'l Airport(NRT)",
    ja: '16:00 成田国際空港(NRT)',
  },
  D103V0029: {
    en: "09:15 San Francisco Int'l Airport(SFO)",
    ja: '09:15 サンフランシスコ国際空港(SFO)',
  },
  D103V0030: {
    en: 'UA 2070 / SFO - ORD / Boeing 757',
    ja: 'UA 2070 / SFO - ORD / ボーイング757',
  },
  D103V0031: {
    en: "10:45 San Francisco Int'l Airport(SFO)",
    ja: '10:45 サンフランシスコ国際空港(SFO)',
  },
  D103V0032: {
    en: "17:07 O'Hare Int'l Airport(ORD)",
    ja: '17:07 オヘア国際空港(ORD)',
  },
  D103V0033: {
    en: 'Document',
    ja: '書類',
  },
  D103V0034: {
    en: 'Invoice',
    ja: 'インボイス',
  },
  D103V0035: {
    en: 'Packing List',
    ja: 'パッキングリスト',
  },
  D103V0036: {
    en: 'SDS',
    ja: 'SDS',
  },
  D103V0037: {
    en: 'Certificate of Origin',
    ja: '原産地証明書',
  },
  D103V0038: {
    en: 'Quote',
    ja: '見積書',
  },
  D103V0039: {
    en: 'Air Freight',
    ja: '航空運賃',
  },
  D103V0040: {
    en: 'Not negotiate',
    ja: '交渉しない',
  },
  D103V0041: {
    en: 'Offer spot rates',
    ja: 'スポットレート申請',
  },
  D103V0042: {
    en: 'ex. JPY XXX/kg++',
    ja: '例 JPY XXX/kg++',
  },
  D103V0043: {
    en: 'HAWB#',
    ja: 'ハウス番号',
  },
  D103V0044: {
    en: 'HAWB Stock',
    ja: 'ハウス番号を選択',
  },
  D103V0045: {
    en: 'Enter HAWB#',
    ja: 'ハウス番号を入力',
  },
  D103V0046: {
    en: '--Select--',
    ja: '--Select--',
  },
  D103V0047: {
    en: 'ex. XXX-XXXX XXXX',
    ja: 'ex. XXX-XXXXXX XXXX',
  },
  D103V0048: {
    en: 'MAWB#',
    ja: 'マスター番号',
  },
  D103V0049: {
    en: 'MAWB Stock',
    ja: 'マスター番号を選択',
  },
  D103V0050: {
    en: 'Enter MAWB#',
    ja: 'マスター番号を入力',
  },
  D103V0051: {
    en: 'Priority High',
    ja: '優先度高',
  },
  D103V0052: {
    en: 'M: ',
    ja: 'M: ',
  },
  D103V0053: {
    en: 'H:',
    ja: 'H:',
  },
  D103V0054: {
    en: 'H.',
    ja: 'H.',
  },
  D103V0055: {
    en: '016-XXXX XXXX',
    ja: '016-XXXX XXXX',
  },
  D103V0056: {
    en: 'XXX-XXXX XXXX',
    ja: 'XXX-XXXX XXXX',
  },
  D103V0057: {
    en: '10pcs. / 625.00kg / 6.60m3',
    ja: '10pcs. / 625.00kg / 6.60m3',
  },
  D103V0058: {
    en: '2pcs. / 125.00kg / 1.32m3',
    ja: '2pcs. / 125.00kg / 1.32m3',
  },
  D103V0059: {
    en: 'August 13, 2020',
    ja: '2020年8月13日',
  },
  D103V0060: {
    en: 'August 12, 2020',
    ja: '2020年8月12日',
  },
  D103V0061: {
    en: 'Non-Haz',
    ja: 'Non-Haz',
  },
  D103V0062: {
    en: 'Details',
    ja: '詳細',
  },
  D103V0063: {
    en: '14pcs. / 550.00kg / 4.69m3',
    ja: '14pcs. / 550.00kg / 4.69m3',
  },
  D103V0064: {
    en: '10pcs. / 300.00kg / 2.05m3',
    ja: '10pcs. / 300.00kg / 2.05m3',
  },
  D103V0065: {
    en: 'August 14, 2020',
    ja: '2020年8月14日',
  },
  D103V0066: {
    en: 'August 15, 2020',
    ja: '2020年8月15日',
  },
  D103V0067: {
    en: 'Are you sure you want to book space toward Carrier? ',
    ja: 'キャリアに向けてスペースを予約してもよろしいですか？',
  },
  D103V0068: {
    en: 'Back',
    ja: '戻る',
  },
  D103V0069: {
    en: 'Send',
    ja: '送信',
  },
  D103V0070: {
    en: 'OK',
    ja: '確認',
  },
  D103V0071: {
    en: 'Cancel',
    ja: 'キャンセル',
  },
  D103V0072: {
    en: 'Delete',
    ja: '削除',
  },
  D103V0073: {
    en: 'x',
    ja: 'x',
  },
  D103V0074: {
    en: 'L x W x H',
    ja: 'L x W x H',
  },
  D103V0075: {
    en: 'L :',
    ja: 'L :',
  },
  D103V0076: {
    en: 'W :',
    ja: 'W :',
  },
  D103V0077: {
    en: 'H :',
    ja: 'H :',
  },
  D103V0078: {
    en: 'No',
    ja: 'No',
  },
  D103V0079: {
    en: 'Pick Up',
    ja: '集荷',
  },
  D103V0080: {
    en: 'Preferred Time',
    ja: '希望時間',
  },
  D103V0081: {
    en: 'Address',
    ja: '住所',
  },
  D103V0082: {
    en: '〒XXX-XXX',
    ja: '〒XXX-XXX',
  },
  D103V0083: {
    en: 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX',
    ja: 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX',
  },
  D103V0084: {
    en: '18:00～20:00',
    ja: '18:00～20:00',
  },
  D103V0085: {
    en: 'REJECT',
    ja: '拒否',
  },
  D103V0086: {
    en: 'Are you sure you want to cancel your booking? ',
    ja: '予約をキャンセルしてもよろしいですか？',
  },
  D103V0087: {
    en: 'Enter in the format of XXX-XXXX XXXX',
    ja: 'XXX-XXXX XXXXの形式で入力してください',
  },
  D103V0088: {
    en: 'Consolidate',
    ja: '統合',
  },
  D103V0089: {
    en: 'Quote#',
    ja: '見積書番号',
  },
  D103V0090: {
    en: 'Valid until',
    ja: '有効期限',
  },
  D103V0091: {
    en: 'Origin Charges',
    ja: '原産地料金',
  },
  D103V0092: {
    en: 'Rate',
    ja: '割合',
  },
  D103V0093: {
    en: 'Per',
    ja: 'PER',
  },
  D103V0094: {
    en: 'Amount',
    ja: '料金',
  },
  D103V0095: {
    en: 'Sub Total',
    ja: '小計',
  },
  D103V0096: {
    en: 'Freight Charges',
    ja: '航空運賃',
  },
  D103V0097: {
    en: 'Destination Charges',
    ja: '到着地料金',
  },
  D103V0098: {
    en: 'Total',
    ja: '合計',
  },
  D103V0099: {
    en: 'Terms and Conditions',
    ja: '取引条件',
  },
  D103V0100: {
    en: 'Weight',
    ja: '重量',
  },
  D103V0101: {
    en: 'Save as Draft',
    ja: '下書きとして保存',
  },
  D103V0102: {
    en: 'Please enter at the specified rate.',
    ja: '指定されたレートで入力してください。',
  },
  D103V0103: {
    en: 'Currency',
    ja: '通貨',
  },
  D103V0104: {
    en: 'Please enter no more than 20 single-byte numbers.',
    ja: '半角数字20桁以下で入力してください。',
  },
  D103V0105: {
    en: 'You have an unselected cargo as "HAWB".',
    ja: '「HAWB」として選択されていない貨物があります。',
  },
  D103V0106: {
    en: 'Please select HAWB No for the cargo.',
    ja: '貨物にはハウス番号を選択してください。',
  },
  D103V0107: {
    en: 'Select HAWB No',
    ja: 'ハウス番号を選択',
  },
  D103V0108: {
    en: 'Show',
    ja: '表示',
  },
  D103V0109: {
    en: 'SHC',
    ja: 'SHC',
  },
  D103V0110: {
    en: 'Add SHC',
    ja: 'SHCを追加',
  },
  D103V0111: {
    en: 'GENERAL CARGO',
    ja: 'GENERAL CARGO',
  },
  D103V0112: {
    en: 'DANGEROUS GOODS',
    ja: 'DANGEROUS GOODS',
  },
  D103V0113: {
    en: 'DRY ICE',
    ja: 'DRY ICE',
  },
  D103V0114: {
    en: 'Please enter the appropriate RATE if it contains such as dangerous goods.',
    ja: '危険物などが含まれている場合は、適切な料金を入力してください。',
  },
  D103V0115: {
    en: 'Offering',
    ja: '提供',
  },
  D103V0116: {
    en: 'Are you sure?',
    ja: '本当によろしいですか？',
  },
  D103V0117: {
    en: 'Preferred Date',
    ja: '集荷希望日',
  },
  D103V0118: {
    en: 'Destination Branch',
    ja: '着地側支店',
  },
  D103V0119: {
    en: 'REQUEST',
    ja: 'リクエスト',
  },
  D103V0120: {
    en: 'APPROVE',
    ja: '承認',
  },
  D103V0121: {
    en: 'REJECT',
    ja: '拒否',
  },
  D103V0122: {
    en: 'Deadline for Bond-In',
    ja: '納入期限',
  },
}
// Display + 画面名
export default DisplayFwdrCompleteBooking
