import { makeStyles } from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'

import Paths from '../../../../../constants/Paths'
import BackButton from '../../../../common/buttons/BackButton'

const useStyles = makeStyles((theme) => ({
  ok: theme.backButtonCenter,
}))

/**
 * @param {Object} props - 以下
 * @param {Array} fwdrArr - 混載業者情報
 * @return {JSX} Backボタンコンポーネント
 */
const BackButtonSelectForwarder = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.ok}>
      <BackButton
        path={Paths.SHIPPER.BID_INFO}
        state={props.fwdrArr.history.location.state?.state}
      />
    </div>
  )
}

BackButtonSelectForwarder.propTypes = {
  fwdrArr: PropTypes.object.isRequired,
}

export default BackButtonSelectForwarder
