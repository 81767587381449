import { Grid, makeStyles } from '@material-ui/core'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import AppContext from '../../../../../contexts/AppContext'
import TextField from '../../../../common/text/TextField'
import SpecialNotesEditButton from './SpecialNotesEditButton'

const useStyles = makeStyles((theme) => ({
  rootMargin: theme.specialNotesRootMargin,
}))

/**
 * @param {Object} props 以下
 * @param {Number} index SpecialNotesのindex
 * @param {Object} templateObj 各SpecialNotes設定オブジェクト
 * @returns {JSX} SpecialNotesテキストコンポーネント(1行分)
 */
const SpecialNotesTextField = ({ index, templateObj }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { state } = useContext(AppContext)

  const specialNoteStr = state.SpecialNotes.specialNoteArr[index]
  const { placeholderKeyStr } = templateObj
  const placeholderStr = t(placeholderKeyStr)

  return (
    <>
      <Grid item xs={11}>
        <TextField
          className={classes.rootMargin}
          value={specialNoteStr}
          placeholder={placeholderStr}
          disabled
        />
      </Grid>
      <Grid
        container
        item
        alignItems="center"
        justify="center"
        xs={1}
        className={classes.rootMargin}
      >
        <SpecialNotesEditButton index={index} />
      </Grid>
    </>
  )
}

SpecialNotesTextField.propTypes = {
  index: PropTypes.number.isRequired,
  templateObj: PropTypes.exact({
    placeholderKeyStr: PropTypes.string.isRequired,
  }),
}

export default SpecialNotesTextField
