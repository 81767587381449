import React from 'react'
import { Grid } from '@material-ui/core'

import BackButtonBidInfo from './BackButtonBidInfo'
import NextButtonBidInfo from './NextButtonBidInfo'

/**
 * @return {JSX} Next,Backボタンコンポーネント
 */
const ButtonsBidInfo = () => {
  return (
    <Grid container item justify="center" spacing={4}>
      <BackButtonBidInfo />
      <NextButtonBidInfo />
    </Grid>
  )
}

export default ButtonsBidInfo
