/**
 * 共通計算方法
 * @returns {Object} - 計算関数
 */
const CommonCalc = () => {
  /**
   * ChargeableWgtを計算
   * @param {Number} pcs - 貨物の個数
   * @param {Number} grossWgt - 貨物の重量
   * @param {Number} length - 貨物の長さ
   * @param {Number} width - 貨物の幅
   * @param {Number} height - 貨物の高さ
   * @returns {Number} - chargeableWgt
   */
  const chargeableWeight = (pcs, grossWgt, length, width, height) => {
    const totalWgt = grossWgt * pcs //総重量
    // eslint-disable-next-line no-extra-parens
    let volWgt = ((length * width * height) / 6000) * pcs //容積重量

    // 容積重量を0.5単位で切り上げ
    const decimal = volWgt - Math.floor(volWgt) // 容積重量の小数点部分を取得
    if (decimal < 0.5 && decimal > 0) {
      volWgt = Math.floor(volWgt) + 0.5
    } else if (decimal > 0.5) {
      volWgt = Math.floor(volWgt) + 1.0
    }

    // 比較して重い方を返す
    return totalWgt > volWgt ? totalWgt : volWgt
  }

  /**
   * Volumeを計算
   * @param {Number} length - 貨物の長さ
   * @param {Number} width - 貨物の幅
   * @param {Number} height - 貨物の高さ
   * @param {Number} pcs - 貨物の個数
   * @returns {Number} - volume
   */
  const volume = (length, width, height, pcs) => {
    // eslint-disable-next-line no-extra-parens
    let vol = ((length * width * height) / 1000000) * pcs //容積
    vol = ceil(vol) //小数点第三位で切り上げ
    return vol
  }

  /**
   * Amountを計算
   * @param {Number} rate - レート
   * @param {String} per - 単位
   * @param {Number} grossWgt - 貨物の重量
   * @returns {Number|String} - amountまたは'Contact later'
   */
  const quoteAmount = (rate, per, grossWgt) => {
    let amount = ''
    switch (per) {
      case 'kg':
        amount = rate * grossWgt
        break
      case 'HAWB':
      case 'Shipment':
        amount = rate
        break
      default:
        // 上記以外の時Contact laterを表示
        amount = 'Contact later'
    }
    if (amount !== 'Contact later') {
      amount = Math.round(amount) //amountを四捨五入
    }
    return amount
  }

  // 荷主と混載業者でやりとりする際の料金
  // Chargeable Weight×混載業者のレート）+ FuelSurCharge

  // 載業者と航空会社でやりとりする際の料金
  // Chargeable Weight×航空会社のレート）+ FuelSurCharge

  /**
   * RateClassを計算
   * @param {Number} chargeableWeight - 貨物のChargeable Weight
   * @param {Number} minrate - 最低料金
   * @param {Number} normal - normalレート
   * @param {Number} min - minレート
   * @param {Number} light - lightレート
   * @param {Number} medium - mediumレート
   * @param {Number} large - largeレート
   * @param {Number} max - maxレート
   * @return {String} - rateClass
   */
  const rateClass = (
    chargeableWeight,
    minrate,
    normal,
    min,
    light,
    medium,
    large,
    max
  ) => {
    //レートが設定されていない場合、一つ下のレートを適用する
    min = min ?? normal
    light = light ?? min
    medium = medium ?? light
    large = large ?? medium
    max = max ?? large

    let rate = ''
    if (chargeableWeight < 45) {
      rate = normal
    } else if (chargeableWeight < 100) {
      rate = min
    } else if (chargeableWeight < 300) {
      rate = light
    } else if (chargeableWeight < 500) {
      rate = medium
    } else if (chargeableWeight < 1000) {
      rate = large
    } else {
      rate = max
    }

    const amount = chargeableWeight * rate
    if (amount <= minrate) {
      return 'M'
    } else if (chargeableWeight < 45) {
      return 'N'
    } else {
      return 'Q'
    }
  }

  /**
   * Rateを計算
   * @param {Number} chargeableWeight - 貨物のChargeable Weight
   * @param {Number} normal normalレート
   * @param {Number} min - minレート
   * @param {Number} light - lightレート
   * @param {Number} medium - mediumレート
   * @param {Number} large - largeレート
   * @param {Number} max - maxレート
   * @param {Boolean} [isRawRate=false] - そのままのrateを返すかどうか
   * @return {Number} - rate
   */
  const rate = (
    chargeableWeight,
    normal,
    min,
    light,
    medium,
    large,
    max,
    isRawRate = false
  ) => {
    //レートが設定されていない場合、一つ下のレートを適用する
    if (!isRawRate) {
      min = min ?? normal
      light = light ?? min
      medium = medium ?? light
      large = large ?? medium
      max = max ?? large
    }

    let rate = ''
    // chargeableWeightによってレートを割り当てる
    if (chargeableWeight < 45) {
      rate = parseFloat(normal)
    } else if (chargeableWeight < 100) {
      rate = parseFloat(min)
    } else if (chargeableWeight < 300) {
      rate = parseFloat(light)
    } else if (chargeableWeight < 500) {
      rate = parseFloat(medium)
    } else if (chargeableWeight < 1000) {
      rate = parseFloat(large)
    } else {
      rate = parseFloat(max)
    }

    if (isRawRate && isNaN(rate)) {
      rate = null
    }

    return rate
  }

  /**
   * 航空料金を計算する
   * @param {Number} chargeableWeight - chargeableWeight
   * @param {Number} rate - rate
   * @param {Number} minRate - minRate
   * @returns {Number} - cost
   */
  const cost = (chargeableWeight, rate, minRate) => {
    //chargeableWeight*rateとminRateで大きい方を航空料金として返す
    return Math.max(chargeableWeight * parseFloat(rate), parseFloat(minRate))
  }

  /**
   * 小数点以下第3位で切り上げ
   * @param {Number|String} value - 切り上げる値
   * @param {Number} maxValue - 最大値(任意)
   * @return {String} - 切り上げ後の値
   */
  const ceil = (value, maxValue = null) => {
    const fixedValue = parseFloat(value) * 100
    // eslint-disable-next-line no-extra-parens
    let ceildValue = Math.ceil(fixedValue) / 100

    if (maxValue !== null && ceildValue > maxValue) {
      ceildValue = maxValue
    }
    return ceildValue.toFixed(2)
  }

  /**
   *
   * 数値にカンマを付ける
   * @param {Value} value カンマを付ける値(Number or String)
   * @param {Boolean} isFloat 小数点付きの時true(任意)
   * @returns {String} カンマ付きの値
   */
  const addComma = (value, isFloat = false) => {
    //valueに値が無かった場合
    if (value === undefined || value === null) {
      return '0'
    }
    //valueが数値でなかった場合そのまま返す
    if (isNaN(parseInt(value))) {
      return value
    }

    if (isFloat) {
      return parseFloat(value)
        .toFixed(2)
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
    } else {
      return value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
    }
  }

  /**
   * ValueをMinとMaxの範囲に収める
   * @param {Number} value 判定する数値
   * @param {Number} min 最小値
   * @param {Number} max 最大値
   * @returns {Number} MinとMaxの範囲に収めた値
   */
  const clamp = (value, min, max) => {
    if (value < min) {
      return min
    } else if (value > max) {
      return max
    } else {
      return value
    }
  }

  /**
   * 0埋めする
   * @param {String|Number} value 0埋めする値
   * @param {Number} length 桁数
   * @returns {String} 0埋めした値
   */
  const zeroPadding = (value, length) => {
    /** @type {String} 0の文字列 */
    const zeroStr = Array(length).join('0')
    // 指定された長さにして返す
    return (zeroStr + value).slice(-length)
  }

  /**
   * state.PagingからshowDataのインデックスを計算
   * @param {Number} paging state.Pagingの値
   * @param {Number} showDataLength showData.lengthの値
   * @returns {Number} showDataのインデックス
   */
  const calcShowDataIndex = (paging, showDataLength) =>
    clamp(paging - 1, 0, showDataLength)

  return {
    chargeableWeight,
    volume,
    quoteAmount,
    rateClass,
    rate,
    ceil,
    addComma,
    cost,
    clamp,
    zeroPadding,
    calcShowDataIndex,
  }
}

export default CommonCalc()
