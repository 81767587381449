import React from 'react'
import PropTypes from 'prop-types'
import ClearIcon from '@material-ui/icons/Clear'
import IconButton from '@material-ui/core/IconButton'

import { TABLES } from '../../../../../actions'

/**
 * 表に表示する削除ボタンコンポーネント
 * @param {Number} index editDataのインデックス
 * @param {Object} apiRequiredObj dispatchを含むオブジェクト
 * @return {JSX} 削除ボタン
 */
const DeleteCellBidDetails = (index, apiRequiredObj) => {
  const { dispatch } = apiRequiredObj
  /**
   * 選択された時のイベント
   * @returns {void} なし
   */
  const onClick = () => {
    dispatch({
      type: TABLES.CLEAR,
      index,
    })
  }

  return (
    <IconButton onClick={onClick}>
      <ClearIcon />
    </IconButton>
  )
}

DeleteCellBidDetails.propTypes = {
  index: PropTypes.number.isRequired,
  apiRequiredObj: PropTypes.object.isRequired,
}

export default DeleteCellBidDetails
