import React, { useReducer, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'

import LayoutBox from '../../../common/layout/LayoutBox'
import AppContext from '../../../../contexts/AppContext'
import ApiPaths from '../../../../constants/ApiPaths'
import CommonFunc from '../../../common/function/CommonFunc'
import reducer, {
  initialState,
} from '../../../../reducers/fwdr/lightFwdrBookingDetails'
import { remarkNameArr } from '../../../../reducers/common/lightBookingDetails/Remark'
import { LIGHT_BOOKING_DETAILS } from '../../../../actions'
import { BeforePath } from './ConstantsLightBookingDetails'
import Common from '../../../../constants/Common'

import TitleLightBookingDetails from './00TitleLightBookingDetails/TitleLightBookingDetails'
import ShippingDetailsLightBookingDetails from './01ShippingDetailsLightBookingDetails/ShippingDetailsFwdrLightBookingDetails'
import MeasureAndWeightLightBookingDetails from './02MeasureAndWeightLightBookingDetails/MeasureAndWeightFwdrLightBookingDetails'
import FlightLightBookingDetails from './03FlightLightBookingDetails/FlightFwdrLightBookingDetails'
import MawbNoLightBookingDetails from './04MawbNoLightBookingDetails/MawbNoFwdrLightBookingDetails'
import SpecialHandlingCodeLightBookingDetails from './05SpecialHandlingCodeLightBookingDetails/SpecialHandlingCodeFwdrLightBookingDetails'
import DocumentLightBookingDetails from './06DocumentLightBookingDetails/DocumentFwdrLightBookingDetails'
import RemarkLightBookingDetails from './07RemarkLightBookingDetails/RemarkFwdrLightBookingDetails'
import CancelBookingLightBookingDetails from './08CancelBookingLightBookingDetails/CancelFwdrLightBookingDetails'
import ButtonsLightBookingDetails from './09ButtonLightBookingDetails/ButtonsLightBookingDetails'

/**
 * @param {Object} props 下記内容
 * @param {Number} props.cargoId 貨物ID (BookingList画面のみ)
 * @param {Object} props.bookingInfo 貨物情報のオブジェクト(SearchResult画面のみ)
 * @return {JSX} 混載業者BookingDetails画面コンポーネント
 */
const LightFwdrBookingDetails = (props) => {
  const history = useHistory()
  const [state, dispatch] = useReducer(reducer, initialState)

  const path = CommonFunc.getStorage(Common.KEY.BEFORE_PATH)

  /** @type {BeforePath} 遷移元画面 */
  const beforePath =
    path === BeforePath.BookingList
      ? BeforePath.BookingList
      : BeforePath.SearchResult

  const cargoId =
    beforePath === BeforePath.BookingList
      ? props.history.location.state?.state
      : null
  const bookingInfo =
    beforePath === BeforePath.SearchResult
      ? props.history.location.state?.state
      : null

  useEffect(() => {
    ;(async () => {
      const req = { params: { cargoId } }
      const apiInfoArr = [
        {
          key: 'bookingDetails',
          method: 'get',
          path: ApiPaths.FORWARDER.LIGHT_BOOKING_DETAILS,
          req,
        },
        { key: 'shc', method: 'get', path: ApiPaths.MASTER_JH.SHC_CODE },
      ]
      if (beforePath === BeforePath.SearchResult) {
        apiInfoArr.shift()
      }
      const resObj = await CommonFunc.execApiAsync(apiInfoArr)
      const isApiSuccessful = CommonFunc.checkApiResponseObj(resObj, history)

      if (isApiSuccessful) {
        const { bookingDetails, shc } = resObj
        const results = bookingDetails?.data.results ?? bookingInfo
        const {
          org,
          dst,
          commodity,
          cargoDetailArr,
          laneObj,
          flightObj,
          mawbObj,
          shcArr,
          documentObj,
          cancelObj,
          bookingId,
          bookingUpdatedAt,
          cargoUpdatedAt,
          statusId,
          commId,
        } = results

        dispatch({
          type: LIGHT_BOOKING_DETAILS.SHIPPING_DETAILS.SET,
          org,
          dst,
          commodity,
          commId,
          bookingId,
          cargoId,
          bookingUpdatedAt,
          cargoUpdatedAt,
          beforePath,
          statusId,
        })
        dispatch({
          type: LIGHT_BOOKING_DETAILS.CARGO.SET_CARGO_DETAILS,
          cargoDetails: cargoDetailArr,
        })
        dispatch({
          type: LIGHT_BOOKING_DETAILS.RATE.SET_RATE,
          rateObj: laneObj,
        })
        dispatch({
          type: LIGHT_BOOKING_DETAILS.FLIGHT.SET,
          ...flightObj,
        })
        dispatch({
          type: LIGHT_BOOKING_DETAILS.MAWB.SET,
          ...mawbObj,
        })
        dispatch({
          type: LIGHT_BOOKING_DETAILS.SPECIAL_HANDLING_CODE.SET,
          shcs: shc.data.results,
        })
        dispatch({
          type: LIGHT_BOOKING_DETAILS.SHC_ARR.SET,
          shcArr,
        })
        dispatch({
          type: LIGHT_BOOKING_DETAILS.REMARK.SET,
          ...remarkNameArr.reduce(
            (obj, remarkName) => ({
              ...obj,
              [remarkName]: results[remarkName] ?? '',
            }),
            {}
          ),
        })
        dispatch({
          type: LIGHT_BOOKING_DETAILS.DOCUMENT.SET,
          ...documentObj,
        })
        dispatch({
          type: LIGHT_BOOKING_DETAILS.CANCEL_BOOKING.SET,
          ...cancelObj,
        })
        dispatch({
          type: LIGHT_BOOKING_DETAILS.STATUS.SET,
          statusId: results.statusId,
        })
      }
    })()
  }, []) //eslint-disable-line

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <LayoutBox>
        <TitleLightBookingDetails />
        <Grid container justify="center">
          <ShippingDetailsLightBookingDetails />
          <MeasureAndWeightLightBookingDetails />
          <FlightLightBookingDetails />
          <MawbNoLightBookingDetails />
          <SpecialHandlingCodeLightBookingDetails />
          <DocumentLightBookingDetails />
          <RemarkLightBookingDetails />
          <CancelBookingLightBookingDetails />
          <ButtonsLightBookingDetails bookingInfo={bookingInfo} />
        </Grid>
      </LayoutBox>
    </AppContext.Provider>
  )
}

LightFwdrBookingDetails.propTypes = {
  cargoId: PropTypes.number,
  bookingInfo: PropTypes.object,
}

export default LightFwdrBookingDetails
