/* eslint-disable no-nested-ternary */
/* eslint-disable no-extra-parens */
import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import MenuIcon from '@material-ui/icons/Menu'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import NotificationsNoneRoundedIcon from '@material-ui/icons/NotificationsNoneRounded'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import TranslateIcon from '@material-ui/icons/Translate'
import LocalAirportIcon from '@material-ui/icons/LocalAirport'
import WorkIcon from '@material-ui/icons/Work'
import GavelIcon from '@material-ui/icons/Gavel'

const useStyles = makeStyles((theme) => ({
  drawer: {
    '& .MuiPaper-root': {
      backgroundColor: theme.palette.info.main,
    },
  },
  listItem: {
    padding: '0 0 0 1rem',
    border: '1px solid',
    borderColor: theme.fontColor.main,
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.main,
    },
    '& .MuiListItemText-primary': {
      marginLeft: '-1rem',
    },
  },
  listItem2: {
    padding: '0 0 0 1rem',
    border: '1px solid',
    borderColor: theme.fontColor.main,
    '& .MuiSvgIcon-root': {
      color: theme.palette.secondary.main,
    },
    '& .MuiListItemText-primary': {
      marginLeft: '-1rem',
    },
  },
  list: {
    width: '188px',
    backgroundColor: theme.palette.info.main,
    color: theme.fontColor.main,
    padding: 0,
  },
  fullList: {
    width: 'auto',
  },
  buttonIcon: {
    fontSize: 25,
    color: theme.palette.info.main,
  },
  button: {
    border: '1px solid',
    borderColor: theme.palette.info.main,
    borderRadius: 3,
    maxWidth: '30px',
    maxHeight: '30px',
    minWidth: '30px',
    minHeight: '30px',
    padding: 0,
  },
  contentName: {
    fontFamily: theme.typography.fontFamily,
    height: 25,
    backgroundColor: theme.fontColor.main,
    color: theme.palette.info.main,
    fontSize: theme.fontSize.regular,
    paddingLeft: '1rem',
    paddingTop: '5px',
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.fontSize.regular,
    backgroundColor: theme.palette.primary.main,
    paddingLeft: '1rem',
    paddingTop: '7px',
    paddingBottom: '7px',
    color: theme.palette.info.main,
  },
  count: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.fontSize.header,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.info.main,
    borderRadius: '50px',
    height: '25px',
    width: '25px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '0.5rem',
  },
}))

const LANGUAGE_KEY = 'currentLanguage'

const MobileSubHeader = () => {
  const classes = useStyles()
  const { t, i18n } = useTranslation()

  // ローカルストレージに保存されている言語を使用
  // ない場合はブラウザ設定の言語を使用(ブラウザ設定が日本語以外の場合は英語を使用)
  // 次期対応: 仕様確認中
  const appCurrentLanguage = localStorage.getItem(LANGUAGE_KEY)
  const browserLanguage =
    window.navigator.language === 'ja' ? window.navigator.language : 'en'
  const settingLanguage = appCurrentLanguage
    ? appCurrentLanguage
    : browserLanguage
  const text = settingLanguage === 'ja' ? 'ENGLISH' : 'JAPANESE'
  /**
   * 言語切り替え
   * @returns {void}
   */
  const handleChangeLanguageSelect = () => {
    const la = text === 'JAPANESE' ? 'ja' : 'en'
    i18n.changeLanguage(la)
    // ローカルストレージに言語を保存
    localStorage.setItem(LANGUAGE_KEY, la)
  }

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    setState({ ...state, [anchor]: open })
  }

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className={classes.title}>{t('D003V0021')}</div>
      <div className={classes.contentName}>{t('D003V0010')}</div>
      <List className={classes.list}>
        {[t('D003V0011')].map((text) => (
          <ListItem button key={text} className={classes.listItem2}>
            <ListItemIcon>
              <NotificationsNoneRoundedIcon />
            </ListItemIcon>
            <ListItemText primary={text} />
            <div className={classes.count}>99+</div>
          </ListItem>
        ))}
      </List>
      <div className={classes.contentName}>{t('D003V0012')}</div>
      <List className={classes.list}>
        {[t('D003V0013'), t('D003V0014'), t('D003V0015'), t('D003V0019')].map(
          (text, index) => (
            <ListItem button key={text} className={classes.listItem}>
              <ListItemIcon>
                {index === 0 ? (
                  <WorkIcon />
                ) : index === 1 ? (
                  <LocalAirportIcon />
                ) : index === 2 ? (
                  <ShoppingCartIcon />
                ) : index === 3 ? (
                  <GavelIcon />
                ) : (
                  <ExitToAppIcon />
                )}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          )
        )}
      </List>
      <div className={classes.contentName}>{t('D003V0016')}</div>
      <List className={classes.list}>
        {[t('D003V0017'), t('D003V0018')].map((text, index) => (
          <ListItem
            button
            key={text}
            className={classes.listItem}
            onClick={index === 0 ? handleChangeLanguageSelect : <></>}
          >
            <ListItemIcon>
              {index === 0 ? <TranslateIcon /> : <HelpOutlineIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </div>
  )

  return (
    <div>
      <Button onClick={toggleDrawer('right', true)} className={classes.button}>
        <MenuIcon className={classes.buttonIcon} />
      </Button>
      <SwipeableDrawer
        anchor="right"
        open={state['right']}
        onClose={toggleDrawer('right', false)}
        onOpen={toggleDrawer('right', true)}
        className={classes.drawer}
      >
        {list('right')}
      </SwipeableDrawer>
    </div>
  )
}

export default MobileSubHeader
