import React, { useReducer, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import reducer, {
  initialState,
} from '../../../../reducers/master/lightMasterFwdrCompanyInfo'
import LayoutBox from '../../../common/layout/LayoutBox'
import AppContext from '../../../../contexts/AppContext'
import TitleLightFwdrCompanyInfo from './00TitleLightFwdrCompanyInfo/TitleLightFwdrCompanyInfo'
import TableLightFwdrCompanyInfo from './01TableLightFwdrCompanyInfo/TableLightFwdrCompanyInfo'
import ButtonLightFwdrCompanyInfo from './02ButtonLightFwdrCompanyInfo/ButtonLightFwdrCompanyInfo'
import CommonFunc from '../../../common/function/CommonFunc'
import ApiPaths from '../../../../constants/ApiPaths'
import {
  UPDATE_FWDR_COMPANY_NAME,
  UPDATE_FWDR_POSTAL_CODE,
  UPDATE_FWDR_COMPANY_ADDRESS,
  UPDATE_FWDR_FIRST_NAME,
  UPDATE_FWDR_LAST_NAME,
  UPDATE_FWDR_POSITION,
  UPDATE_FWDR_MAIL,
  UPDATE_FWDR_TEL,
  UPDATE_FWDR_ICON_PATH,
  UPDATE_FWDR_IATACASS_NO,
  UPDATE_FWDR_BACKUP,
  UPDATE_FWDR_THREE_LETTER_CODE,
  UPDATE_FWDR_UPDATE_AT,
  UPDATE_FWDR_FILE,
  UPDATE_BOND_IN_ADDRESS,
  UPDATE_FWDR_AUTHORITY_FLG,
  UPDATE_FWDR_BRANCH,
  UPDATE_FWDR_BRANCH_CODE,
  UPDATE_FWDR_AIRPORT,
  UPDATE_FWDR_REPRESENTATIVE_FLG,
  UPDATE_FWDR_IS_DELETE_SHIPPING_ICON,
  LIGHT_REGISTER_NEW_ACCOUNT,
  UPDATE_FWDR_PLAN,
  TOTAL_ACCOUNT,
} from '../../../../actions'

const LightFwdrCompanyInfo = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const history = useHistory()

  useEffect(() => {
    ;(async () => {
      // API取得
      const apiInfoArr = [
        {
          key: 'companyInfoArr',
          method: 'get',
          path: ApiPaths.MASTER_FWDR.LIGHT_FWDR_COMPANY_INFO,
        },
        {
          key: 'planArr',
          method: 'get',
          path: ApiPaths.COMMON_API.LIGHT_FWDR_PLAN,
        },
        {
          key: 'userCount',
          method: 'get',
          path: ApiPaths.COMMON_API.LIGHT_TOTAL_ACCOUNT,
        },
      ]
      const resObj = await CommonFunc.execApiAsync(apiInfoArr)
      const isApiSuccessful = CommonFunc.checkApiResponseObj(resObj, history)
      if (isApiSuccessful) {
        const res = resObj.companyInfoArr.data.results
        const plan = resObj.planArr.data.results
        const userCount = resObj.userCount.data.results

        dispatch({
          type: UPDATE_FWDR_COMPANY_NAME,
          companyName: res.companyName,
          fwdrNameId: res.fwdrNameId,
          fwdrthreeCode: res.fwdrthreeCode,
        })
        dispatch({
          type: UPDATE_FWDR_POSTAL_CODE,
          postalCode: res.postalCode,
        })
        dispatch({
          type: UPDATE_FWDR_COMPANY_ADDRESS,
          address: res.address,
        })
        dispatch({
          type: UPDATE_BOND_IN_ADDRESS,
          address: res.bondInAddress ?? '',
        })
        dispatch({
          type: UPDATE_FWDR_BRANCH,
          cityName: res.cityName,
        })
        dispatch({
          type: UPDATE_FWDR_BRANCH_CODE,
          cityCode: res.cityCode,
        })
        dispatch({
          type: UPDATE_FWDR_AIRPORT,
          airport: res.airport,
        })
        dispatch({
          type: UPDATE_FWDR_REPRESENTATIVE_FLG,
          representativeFlg: res.representativeFlg,
        })
        dispatch({
          type: UPDATE_FWDR_AUTHORITY_FLG,
          authorityFlg: res.authorityFlg,
        })
        dispatch({
          type: UPDATE_FWDR_FIRST_NAME,
          firstName: res.firstName,
        })
        dispatch({
          type: UPDATE_FWDR_LAST_NAME,
          lastName: res.lastName,
        })
        dispatch({
          type: UPDATE_FWDR_POSITION,
          position: res.position,
        })
        dispatch({
          type: UPDATE_FWDR_MAIL,
          mail: res.mail,
        })
        dispatch({
          type: UPDATE_FWDR_TEL,
          tel: res.tel,
        })
        dispatch({
          type: UPDATE_FWDR_IATACASS_NO,
          iataOrcassNumber: res.iataOrcassNumber,
        })
        dispatch({
          type: UPDATE_FWDR_ICON_PATH,
          icon: res.icon,
        })
        dispatch({
          type: UPDATE_FWDR_IS_DELETE_SHIPPING_ICON,
          iconPath: res.iconPath,
        })
        dispatch({
          type: UPDATE_FWDR_THREE_LETTER_CODE,
          fwdrthreeCode: res.fwdrthreeCode,
        })
        dispatch({
          type: UPDATE_FWDR_UPDATE_AT,
          updatedAt: res.updatedAt,
        })
        dispatch({
          type: UPDATE_FWDR_FILE,
          file: res.file,
        })
        dispatch({
          type: UPDATE_FWDR_PLAN,
          fwdrPlanId: res.fwdrPlanId,
        })

        // 初回データをBackup
        dispatch({
          type: UPDATE_FWDR_BACKUP,
          info: res,
        })

        dispatch({
          type: LIGHT_REGISTER_NEW_ACCOUNT.PLAN.INIT,
          planArr: plan,
        })
        dispatch({
          type: TOTAL_ACCOUNT.SET,
          userCount,
        })

        // 先頭にスクロール
        window.scrollTo(0, 0)
      }
    })()
  }, []) // eslint-disable-line

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <LayoutBox>
        <TitleLightFwdrCompanyInfo />
        <TableLightFwdrCompanyInfo />
        <ButtonLightFwdrCompanyInfo />
      </LayoutBox>
    </AppContext.Provider>
  )
}

export default LightFwdrCompanyInfo
