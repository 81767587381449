import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import AppContext from '../../../../../contexts/AppContext'
import Common from '../../../../../constants/Common'
import TableCell from '../../../../common/table/TableCell'
import ConstantsLightFwdrSearchResult from '../ConstantsLightFwdrSearchResult'
import DateLightSearchResult from './DateLightSearchResult'
import AirportLightSearchResult from './AirportLightSearchResult'
import RateLightSearchResult from './RateLightSearchResult'
import FscLightSearchResult from './FscLightSearchResult'
import TotalLightSearchResult from './TotalLightSearchResult'
import SelectFlightSearchResult from './SelectFlightSearchResult'

/** @typedef {import('../ConstantsLightFwdrSearchResult').TableInfo} TableInfo テーブル情報 */

const useStyles = makeStyles({
  icon: {
    width: '70px',
    height: '70px',
  },
})

/**
 * 1行分の表データを作成
 * @param {Object} props 下記内容
 * @param {Number} props.page 表示中のページ番号
 * @param {Number} props.index ページ上のインデックス
 * @return {JSX} TableCellコンポーネント(1行分)
 */
const TableCellsLightFwdrSearchResult = ({ page, index }) => {
  const { state } = useContext(AppContext)
  const classes = useStyles()

  /** @type {Object} 1行分のデータ */
  const contents = state.Tables.showData[page][index]

  /**
   * 定数で定義した表データのコンポーネントを作成
   * @param {TableInfo} tableInfo テーブル情報
   * @param {Number} index mapのindex
   * @return {JSX} TableCellコンポーネント(1個分)
   */
  const createTableCell = (tableInfo, index) => {
    const { cellKey, cellCategory } = tableInfo
    const defaultIconPath = '../../../../images/CargoLabo_logo_color.svg'

    /** @type {*} セルに表示する内容 */
    let displayContent = null

    switch (cellCategory) {
      case Common.CELL_CATEGORY.SEARCH_RESULT.ICON:
        displayContent = (
          <img
            className={classes.icon}
            src={contents[cellKey] ? contents[cellKey] : defaultIconPath}
            alt="logo"
          />
        )
        break
      case Common.CELL_CATEGORY.SEARCH_RESULT.DATE:
        displayContent = <DateLightSearchResult contents={contents} />
        break
      case Common.CELL_CATEGORY.SEARCH_RESULT.AIRPORT:
        displayContent = <AirportLightSearchResult contents={contents} />
        break
      case Common.CELL_CATEGORY.SEARCH_RESULT.RATE:
        displayContent = <RateLightSearchResult contents={contents} />
        break
      case Common.CELL_CATEGORY.SEARCH_RESULT.FSC:
        displayContent = <FscLightSearchResult contents={contents} />
        break
      case Common.CELL_CATEGORY.SEARCH_RESULT.TOTAL:
        displayContent = <TotalLightSearchResult contents={contents} />
        break
      case Common.CELL_CATEGORY.BUTTON.CHECK:
        displayContent = <SelectFlightSearchResult contents={contents} />
        break
      default:
        displayContent = null
    }
    return <TableCell key={index}>{displayContent}</TableCell>
  }

  return ConstantsLightFwdrSearchResult.TABLE_ARR.map(createTableCell)
}

TableCellsLightFwdrSearchResult.propTypes = {
  page: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
}

export default TableCellsLightFwdrSearchResult
