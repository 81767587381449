/* eslint-disable no-extra-parens */
import React, { useState, useEffect, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { useHistory } from 'react-router-dom'

import AppContext from '../../../../contexts/AppContext'
import reducer from '../../../../reducers/master/masterShipMyProfile'
import {
  UPDATE_SHIP_FIRST_NAME,
  UPDATE_SHIP_LAST_NAME,
  UPDATE_SHIP_MAIL,
  UPDATE_SHIP_TEL,
  UPDATE_SHIP_POSITION,
  UPDATE_SHIP_DEPARTMENT,
  UPDATE_SHIP_PASSWORD,
  UPDATE_SHIP_SIGNATURE,
  UPDATE_SHIP_MAIL_SETTING,
  UPDATE_SHIP_CURRENTPASS_ERR,
  UPDATE_SHIP_PASS_VERIFY_ERR,
  UPDATE_SHIP_UPDATE_AT,
  UPDATE_SHIP_BACKUP,
  UPDATE_SHIP_FILE,
  UPDATE_SHIP_PASS_DIGIT_ERR,
  UPDATE_SHIP_CURRENTPASS,
  UPDATE_SHIP_NEWPASS,
  UPDATE_SHIP_NEWPASS_VERIFY,
} from '../../../../actions'
import Paths from '../../../../constants/Paths'
import Title from '../../../common/title/Title'
import SaveAndBack from '../../../common/buttons/SaveAndBack'
import LayoutBox from '../../../common/layout/LayoutBox'
import Name from './Name'
import ContactInfo from './ContactInfo'
import Positions from './Positions'
import Password from './Password'
import Signature from './Signature'
import MailSetting from './MailSetting'
import Theme from '../../../common/theme/Theme'
import ApiPaths from '../../../../constants/ApiPaths'
import CommonFunc from '../../../common/function/CommonFunc'
import restFacade from '../../../../actions/rest-facade'

const useStyles = makeStyles(() => ({
  buttons: {
    justifyContent: 'center',
    marginBottom: 30,
    marginTop: 30,
  },
  down: {
    marginBottom: 30,
    margin: 'auto',
  },
  hr: Theme.hr,
}))

const MasterShipMyProfile = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const [saveComp, setSaveComp] = useState(false)
  const [errCode, setErrCode] = useState(0)
  const initialState = {
    myprofile: {
      name: { value: '', inputErr: false },
      lastName: { value: '', inputErr: false },
      email: { value: '', inputErr: false },
      tel: { value: '', inputErr: false },
      position: { value: '', inputErr: false },
      department: { value: '', inputErr: false },
      password: { value: '' },
      currentPassword: { value: '', inputErr: false },
      newPassword: { value: '', inputErr: false },
      newPassVerify: { value: '', inputErr: false },
      signPicture: { value: '', inputErr: false },
      mailSetting: { value: false },
      passwordErr: { current: false, verify: false, digit: false },
      updatedAt: { value: '' },
      isDeleteIcon: { value: '' },
    },
    backup: {
      name: '',
      lastName: '',
      email: '',
      tel: '',
      position: '',
      department: '',
      signPicture: '',
      mailSetting: false,
    },
    file: {
      file: '',
    },
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  // Password変更入力判定
  const chkPassword = (res) => {
    const sta = state.myprofile
    const errcd = res === undefined ? 0 : res.data.info.ErrorCode
    let passerr = 0 // Errorなし

    if (
      sta.currentPassword.value !== '' ||
      sta.newPassword.value !== '' ||
      sta.newPassVerify.value !== ''
    ) {
      // newPasswordチェック
      if (
        sta.newPassword.value !== sta.newPassVerify.value ||
        (sta.newPassword.value === '' && sta.newPassVerify.value === '')
      ) {
        passerr |= 0x02 // 確認用パスワード不一致
      }
      // currentPasswordチェック
      if (sta.currentPassword.value === '') {
        passerr |= 0x01 // 現在パスワード不一致(空の場合はフロントで弾く。入力がある場合はバックエンドで判定)
      }
    }
    if (errcd === 4) {
      passerr |= 0x01 // 現在パスワード不一致(バックエンドからの不一致通知)
    }
    if (
      sta.newPassword.value !== '' &&
      (sta.newPassword.value.length < 8 || sta.newPassword.value.length > 15)
    ) {
      passerr |= 0x04 // 新パスワード桁数エラー
    }

    return passerr
  }
  // Password入力エラー状態更新()
  const updatePasswordErrState = (res) => {
    const passerr = chkPassword(res)

    if (passerr & 0x01) {
      dispatch({
        type: UPDATE_SHIP_CURRENTPASS_ERR,
        errflg: true,
      })
    }
    if (passerr & 0x02) {
      dispatch({
        type: UPDATE_SHIP_PASS_VERIFY_ERR,
        errflg: true,
      })
    }
    if (passerr & 0x04) {
      dispatch({
        type: UPDATE_SHIP_PASS_DIGIT_ERR,
        errflg: true,
      })
    }
  }

  // Saveボタンの有効無効判定
  const chkSaveDisable = () => {
    if (
      state.myprofile.name.inputErr === true ||
      state.myprofile.lastName.inputErr === true ||
      state.myprofile.email.inputErr === true ||
      state.myprofile.tel.inputErr === true ||
      state.myprofile.position.inputErr === true ||
      state.myprofile.department.inputErr === true ||
      state.myprofile.currentPassword.inputErr === true ||
      state.myprofile.newPassword.inputErr === true ||
      state.myprofile.newPassVerify.inputErr === true ||
      state.myprofile.signPicture.inputErr === true ||
      state.myprofile.signPicture.value === null
    ) {
      return true
    }
    return false
  }
  // 入力変更ありなし判定
  const chkInputChg = () => {
    const sta = state.myprofile
    const bk = state.backup
    if (
      bk.name !== sta.name.value ||
      bk.lastName !== sta.lastName.value ||
      bk.email !== sta.email.value ||
      bk.tel !== sta.tel.value ||
      bk.position !== sta.position.value ||
      bk.department !== sta.department.value ||
      sta.currentPassword.value !== '' ||
      sta.newPassword.value !== '' ||
      sta.newPassVerify.value !== '' ||
      bk.signPicture !== sta.signPicture.value ||
      bk.mailSetting !== sta.mailSetting.value
    ) {
      return true
    }
    return false
  }

  // 保存完了後の処理
  const saveComplete = () => {
    setSaveComp(false)
    if (errCode === 0) {
      // 保存に成功したらデータ再取得
      restFacade.get(ApiPaths.MASTER_SHIPPER.MY_PROFILE, (res) =>
        setData(res, setMyProfileCB)
      )
      // エラー状態クリア
      dispatch({
        type: UPDATE_SHIP_CURRENTPASS_ERR,
        errflg: false,
      })
      dispatch({
        type: UPDATE_SHIP_PASS_VERIFY_ERR,
        errflg: false,
      })
      dispatch({
        type: UPDATE_SHIP_PASS_DIGIT_ERR,
        errflg: false,
      })
      // 再表示の際、パスワードを空にしておく
      dispatch({
        type: UPDATE_SHIP_CURRENTPASS,
        currentPassword: '',
      })
      dispatch({
        type: UPDATE_SHIP_NEWPASS,
        newPassword: '',
      })
      dispatch({
        type: UPDATE_SHIP_NEWPASS_VERIFY,
        newPassVerify: '',
      })
    }
  }

  // 保存完了時のコールバック
  const saveDataCB = (response) => {
    setSaveComp(true)
    updatePasswordErrState(response)
    setErrCode(response.data.info.ErrorCode)
  }

  // 保存処理
  const saveData = () => {
    const req = {
      name: state.myprofile.name.value,
      lastName: state.myprofile.lastName.value,
      email: state.myprofile.email.value,
      tel: state.myprofile.tel.value,
      position: state.myprofile.position.value,
      department: state.myprofile.department.value,
      mailSetting: state.myprofile.mailSetting.value,
      currentPassword: state.myprofile.currentPassword.value,
      newPassword: state.myprofile.newPassword.value,
      updatedAt: state.myprofile.updatedAt.value,
      file: state.file.file,
      isDeleteIcon: state.myprofile.isDeleteIcon.value,
    }
    restFacade.post(
      ApiPaths.MASTER_SHIPPER.MY_PROFILE,
      (res) => CommonFunc.callbackFunc(res, saveDataCB, history),
      req
    )
  }

  const setMyProfileCB = (response) => {
    const res = response.data.results
    dispatch({
      type: UPDATE_SHIP_FIRST_NAME,
      name: res.name,
    })
    dispatch({
      type: UPDATE_SHIP_LAST_NAME,
      lastName: res.lastName,
    })
    dispatch({
      type: UPDATE_SHIP_MAIL,
      email: res.email,
    })
    dispatch({
      type: UPDATE_SHIP_TEL,
      tel: res.tel,
    })
    dispatch({
      type: UPDATE_SHIP_POSITION,
      position: res.position,
    })
    dispatch({
      type: UPDATE_SHIP_DEPARTMENT,
      department: res.department,
    })
    dispatch({
      type: UPDATE_SHIP_PASSWORD,
      password: res.password,
    })
    dispatch({
      type: UPDATE_SHIP_SIGNATURE,
      signPicture: res.signPicture,
    })
    dispatch({
      type: UPDATE_SHIP_MAIL_SETTING,
      mailSetting: res.mailSetting,
    })
    dispatch({
      type: UPDATE_SHIP_UPDATE_AT,
      updatedAt: res.updatedAt,
    })
    dispatch({
      type: UPDATE_SHIP_FILE,
      file: res.file,
    })

    // 初回データをBackup
    dispatch({
      type: UPDATE_SHIP_BACKUP,
      info: res,
    })

    // 先頭にスクロール
    window.scrollTo(0, 0)
  }

  // エラー処理、コールバック関数実行
  const setData = (res, callback) => {
    CommonFunc.callbackFunc(res, callback, history)
  }

  // データ取得
  useEffect(() => {
    restFacade.get(ApiPaths.MASTER_SHIPPER.MY_PROFILE, (res) =>
      setData(res, setMyProfileCB)
    )
  }, []) // eslint-disable-line

  // Backコンポーネントに渡したいものをまとめる
  const backFactors = {
    path: Paths.MASTER.SHIP_MASTER_TOP,
    inputchg: chkInputChg(),
    clickevent: '',
  }

  // Saveコンポーネントに渡したいものをまとめる
  const saveFactors = {
    path: Paths.MASTER.SHIP_MY_PROFILE,
    saveevent: saveData,
    comp: saveComp,
    compevent: saveComplete,
    disabled: chkSaveDisable(),
    errcode: errCode,
    passerr: chkPassword(),
    passerrevent: updatePasswordErrState,
  }

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <LayoutBox>
        <Title>{t('D301V0022')}</Title>
        <Grid container justify="center">
          <Grid item style={{ marginBottom: '3rem' }} md={10} xs={10}>
            <Name />
          </Grid>
          <Grid item style={{ marginBottom: '5rem' }} md={10} xs={10}>
            <ContactInfo />
          </Grid>
          <Grid item style={{ marginBottom: '5rem' }} md={10} xs={10}>
            <Positions />
          </Grid>
          <Grid item style={{ marginBottom: '3rem' }} md={10} xs={10}>
            <hr className={classes.hr} />
          </Grid>
          <Grid item style={{ marginBottom: '5rem' }} md={10} xs={10}>
            <Password />
          </Grid>
          <Grid item style={{ marginBottom: '3rem' }} md={10} xs={10}>
            <hr className={classes.hr} />
          </Grid>
          <Grid item style={{ marginBottom: '5rem' }} md={10} xs={10}>
            <Signature />
          </Grid>
          <Grid item style={{ marginBottom: '3rem' }} md={10} xs={10}>
            <hr className={classes.hr} />
          </Grid>
          <Grid item style={{ marginBottom: '5rem' }} md={10} xs={10}>
            <MailSetting />
          </Grid>
        </Grid>
        <Grid
          container
          justify="center"
          direction="column"
          alignItems="center"
          className={classes.down}
        >
          <Grid item md={10} xs={10} className={classes.buttons}>
            <SaveAndBack backFactors={backFactors} saveFactors={saveFactors} />
          </Grid>
        </Grid>
      </LayoutBox>
    </AppContext.Provider>
  )
}

export default MasterShipMyProfile
