import React from 'react'
import { makeStyles } from '@material-ui/core'
import Badge from '@material-ui/core/Badge'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  badge: {
    ...theme.badge.widthSetting,
    '& .MuiBadge-badge': {
      ...theme.badge.forumsSetting,
      fontSize: theme.fontSize.stepbar,
      fontFamily: theme.typography.fontFamily,
    },
  },
}))

/**
 * タブ用バッジコンポーネント
 * @param {Number} notificationCount 通知数 0もしくは渡していない場合はバッジ非表示
 * @param {JSX} children バッジを表示したい要素
 * @return {JSX} タブ用バッジコンポーネント
 */
const CommonTabBadge = ({ notificationCount, children }) => {
  const classes = useStyles()

  return (
    <Badge
      color="error"
      badgeContent={notificationCount}
      className={classes.badge}
    >
      {children}
    </Badge>
  )
}

CommonTabBadge.propTypes = {
  notificationCount: PropTypes.number,
  children: PropTypes.node.isRequired,
}

export default CommonTabBadge
