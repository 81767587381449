import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import CheckBoxCell from '../../../../common/table/CheckBoxCell'
import AppContext from '../../../../../contexts/AppContext'
import { CHECKBOX } from '../../../../../actions'
import ConstantsSelectForwarder from '../ConstantsSelectForwarder'

/**
 * @param {Object} props - 以下
 * @param {Number} apiIndexNum - データのインデックス
 * @return {JSX} チェックボックスコンポーネント
 */
const CheckBoxSelectForwarder = (props) => {
  const { apiIndexNum } = props
  const { state, dispatch } = useContext(AppContext)

  // チェックボックスON
  const checked = state.CheckBox.indexOf(String(apiIndexNum)) !== -1
  // チェックボックスの制限
  const limit = ConstantsSelectForwarder.LIMIT
  const isLimit = state.CheckBox.length >= limit
  // disabledの状態を判定
  const disabled = isLimit && !checked

  //   テーブルのチェックボックスの値をStateに保持
  const handleChange = (event) => {
    dispatch({
      type: CHECKBOX.TOGGLE,
      index: event.target.value,
    })
  }

  return (
    <CheckBoxCell
      checked={checked}
      onChange={handleChange}
      value={apiIndexNum}
      disabled={disabled}
    />
  )
}

CheckBoxSelectForwarder.propTypes = {
  apiIndexNum: PropTypes.number.isRequired,
}

export default CheckBoxSelectForwarder
