import { combineReducers } from 'redux'

import myprofile from './MyProfile'
import backup from './Backup'
import file from './File'

// すべてのreducerをここでまとめる
export default combineReducers({
  myprofile,
  backup,
  file,
})
