import Common from '../../../../constants/Common'

/**
 * @typedef {Object} TableInfo テーブル情報
 * @property {string} headerKey ヘッダーに表示する文言番号
 * @property {string} cellKey セルに表示するプロパティ名
 * @property {string} cellCategory Common.CELL_CATEGORYの値
 * @property {Type} type 表示する航空会社の種類
 */

/**
 * @typedef {Object} SortInfo 並び替え情報
 * @property {string} sortTerm Common.SORT_TERMSの値
 * @property {string} wordKey 並び替え欄に表示する文言
 * @property {string} sortKey 並び替えに使用するプロパティ名
 * @property {Type} type 表示する航空会社の種類
 */

/** @enum {String} 表示する航空会社の種類 */
const Type = {
  Gsa: 'gsa',
  Airline: 'airline',
  Both: 'both',
}

/** @type {TableInfo[]} テーブル作成に必要な値 */
const TABLE_ARR = [
  {
    headerKey: 'D501V0005',
    cellKey: 'airline',
    cellCategory: Common.CELL_CATEGORY.TEXT,
    type: Type.Gsa,
  },
  {
    headerKey: 'D102V0006',
    cellKey: 'orgDstStr',
    cellCategory: Common.CELL_CATEGORY.TEXT,
    type: Type.Both,
  },
  {
    headerKey: 'D203V0007',
    cellKey: 'mawbNumber',
    cellCategory: Common.CELL_CATEGORY.TEXT,
    type: Type.Both,
  },
  {
    headerKey: 'D102V0005',
    cellKey: 'cargoStr',
    cellCategory: Common.CELL_CATEGORY.TEXT,
    type: Type.Both,
  },
  {
    headerKey: 'D203V0010',
    cellKey: 'fwdrthreeCode',
    cellCategory: Common.CELL_CATEGORY.TEXT,
    type: Type.Both,
  },
  {
    headerKey: 'D103V0109',
    cellKey: 'shc',
    cellCategory: Common.CELL_CATEGORY.BUTTON.SHOW,
    type: Type.Both,
  },
  {
    headerKey: 'D102V0008',
    cellKey: 'status',
    cellCategory: Common.CELL_CATEGORY.TEXT,
    type: Type.Both,
  },
  {
    headerKey: '4th0010',
    cellKey: 'info',
    cellCategory: Common.CELL_CATEGORY.BUTTON.CHECK,
    type: Type.Both,
  },
]

/** @type {SortInfo[]} 並び替えの値 */
const SORT_ITEM_ARR = [
  {
    sortTerm: Common.SORT_TERMS.CREATED_AT,
    wordKey: 'D005V0003',
    sortKey: 'createdAt',
    type: Type.Both,
  },
  {
    sortTerm: Common.SORT_TERMS.CREATED_AT_DESENDING,
    wordKey: 'D102V0054',
    sortKey: 'createdAt',
    type: Type.Both,
  },
  {
    sortTerm: Common.SORT_TERMS.AIRLINE_ORDER,
    wordKey: 'D501V0005',
    sortKey: 'airline',
    type: Type.Gsa,
  },
  {
    sortTerm: Common.SORT_TERMS.ORG_ORDER,
    wordKey: 'D102V0061',
    sortKey: 'orgAirportCode',
    type: Type.Both,
  },
  {
    sortTerm: Common.SORT_TERMS.DST_ORDER,
    wordKey: 'D102V0062',
    sortKey: 'dstAirportCode',
    type: Type.Both,
  },
  {
    sortTerm: Common.SORT_TERMS.MAWB_NO_ASCENDING,
    wordKey: 'D203V0007',
    sortKey: 'mawbNumber',
    type: Type.Both,
  },
  {
    sortTerm: Common.SORT_TERMS.PCS_ORDER,
    wordKey: 'D102V0058',
    sortKey: 'pcs',
    type: Type.Both,
  },
  {
    sortTerm: Common.SORT_TERMS.WGT_ORDER,
    wordKey: 'D102V0059',
    sortKey: 'grossWgt',
    type: Type.Both,
  },
  {
    sortTerm: Common.SORT_TERMS.VOL_ORDER,
    wordKey: 'D102V0060',
    sortKey: 'volume',
    type: Type.Both,
  },
  {
    sortTerm: Common.SORT_TERMS.FWDR_ORDER,
    wordKey: 'D203V0010',
    sortKey: 'fwdrthreeCode',
    type: Type.Both,
  },
  {
    sortTerm: Common.SORT_TERMS.SHC_ORDER,
    wordKey: 'D116V0036',
    sortKey: 'shc',
    type: Type.Both,
  },
  {
    sortTerm: Common.SORT_TERMS.STATUS_ID_ORDER,
    wordKey: 'D102V0063',
    sortKey: 'statusId',
    type: Type.Both,
  },
]

const ConstantsLightAirlineBookingList = {
  /** @type {Number} 1ページに表示する件数 */
  PAGE_AMOUNT: 15,

  /** @type {TableInfo[]} GSAユーザのテーブル作成に必要な値 */
  GSA_TABLE_ARR: TABLE_ARR.filter(
    ({ type }) => type === Type.Both || type === Type.Gsa
  ),
  /** @type {TableInfo[]} 航空会社ユーザのテーブル作成に必要な値 */
  AIRLINE_TABLE_ARR: TABLE_ARR.filter(
    ({ type }) => type === Type.Both || type === Type.Airline
  ),

  /** @type {SortInfo[]} GSAユーザの並び替えの値 */
  GSA_SORT_ITEM_ARR: SORT_ITEM_ARR.filter(
    ({ type }) => type === Type.Both || type === Type.Gsa
  ),
  /** @type {SortInfo[]} 航空会社ユーザの並び替えの値 */
  AIRLINE_SORT_ITEM_ARR: SORT_ITEM_ARR.filter(
    ({ type }) => type === Type.Both || type === Type.Airline
  ),
}

export default ConstantsLightAirlineBookingList
