import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import HeaderText from '../../common/text/HeaderText'
import TextField from '../../common/text/TextField'
import CommonValidation from '../../common/function/CommonValidation'
import {
  UPDATE_FWDR_FIRST_NAME,
  UPDATE_FWDR_LAST_NAME,
  UPDATE_CAR_FIRST_NAME,
  UPDATE_CAR_LAST_NAME,
  UPDATE_FWDR_FIRST_NAME_INPUTERR,
  UPDATE_FWDR_LAST_NAME_INPUTERR,
  UPDATE_CAR_FIRST_NAME_INPUTERR,
  UPDATE_CAR_LAST_NAME_INPUTERR,
} from '../../../actions'

const useStyles = makeStyles((theme) => ({
  head: theme.textBoldBLU,
  textBlk: {
    ...theme.textNormalBLK,
    marginLeft: '1rem',
    wordWrap: 'break-word',
  },
  textBlu: theme.textNormalBLU,
}))

/**
 * コンポーネントを更新するか判定する
 * @param {Object} prev 前回の値
 * @param {Object} next 最新の値
 * @returns {Boolean} falseの場合更新する
 */
const areEqual = (prev, next) => {
  // 各入力欄の値を比較
  // 一致していれば更新無し
  const firstNameValue =
    prev.companyinfo.firstName.value === next.companyinfo.firstName.value
  const lastNameValue =
    prev.companyinfo.lastName.value === next.companyinfo.lastName.value
  const representativeFlgValue =
    prev.companyinfo.representativeFlg.value ===
    next.companyinfo.representativeFlg.value

  return firstNameValue && lastNameValue && representativeFlgValue
}

const Representative = React.memo(({ dispatch, companyinfo, carrier }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { firstName, lastName, representativeFlg } = companyinfo
  const representativeFlgValue = representativeFlg.value
  const [inputErrs, setInputErrs] = useState({
    firstName: false,
    lastName: false,
  })
  const [helperTxts, setHelperTxts] = useState({ firstName: '', lastName: '' })

  const handleChangeLastName = (event) => {
    const value = event.target.value
    // 入力チェック
    const validationCheck = CommonValidation.name(value)
    const err = validationCheck.checkResult
    setInputErrs({ ...inputErrs, lastName: err })
    setHelperTxts({ ...helperTxts, lastName: validationCheck.errMsg })

    dispatch({
      type: carrier ? UPDATE_CAR_LAST_NAME : UPDATE_FWDR_LAST_NAME,
      lastName: value,
    })

    // 入力エラーの場合、Savaボタン無効
    dispatch({
      type: carrier
        ? UPDATE_CAR_LAST_NAME_INPUTERR
        : UPDATE_FWDR_LAST_NAME_INPUTERR,
      inputErr: err,
    })
  }
  const handleChangeFirstName = (event) => {
    const value = event.target.value
    // 入力チェック
    const validationCheck = CommonValidation.name(value)
    const err = validationCheck.checkResult
    setInputErrs({ ...inputErrs, firstName: err })
    setHelperTxts({ ...helperTxts, firstName: validationCheck.errMsg })

    dispatch({
      type: carrier ? UPDATE_CAR_FIRST_NAME : UPDATE_FWDR_FIRST_NAME,
      firstName: value,
    })

    // 入力エラーの場合、Savaボタン無効
    dispatch({
      type: carrier
        ? UPDATE_CAR_FIRST_NAME_INPUTERR
        : UPDATE_FWDR_FIRST_NAME_INPUTERR,
      inputErr: err,
    })
  }

  // 編集用
  const createTextField = (data) => {
    const { id, inputErrs, helperTxts, value, onChange } = data
    return (
      <TextField
        id={id}
        error={inputErrs}
        helperText={t(helperTxts)}
        value={value}
        onChange={onChange}
      />
    )
  }

  // 表示用
  const createTextLabel = (value) => {
    return <div className={classes.textBlk}>{value}</div>
  }

  const firstNameData = {
    id: String(firstName),
    inputErrs: inputErrs.firstName,
    helperTxts: helperTxts.firstName,
    value: firstName.value,
    onChange: handleChangeFirstName,
  }

  const lastNameData = {
    id: String(lastName),
    inputErrs: inputErrs.lastName,
    helperTxts: helperTxts.lastName,
    value: lastName.value,
    onChange: handleChangeLastName,
  }

  return (
    <div className={classes.formControl}>
      <Grid container>
        <Grid item md={10} xs={10}>
          <HeaderText className={classes.head}>{t('D301V0006')}</HeaderText>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={4} xs={10}>
          <div className={classes.textBlu}>{t('D301V0007')}</div>
          {representativeFlgValue
            ? createTextField(firstNameData)
            : createTextLabel(firstName.value)}
        </Grid>
        <Grid item md={1} />
        <Grid item md={4} xs={10}>
          <div className={classes.textBlu}>{t('D301V0008')}</div>
          {representativeFlgValue
            ? createTextField(lastNameData)
            : createTextLabel(lastName.value)}
        </Grid>
      </Grid>
    </div>
  )
}, areEqual)

Representative.displayName = 'Representative'

Representative.propTypes = {
  dispatch: PropTypes.func.isRequired,
  companyinfo: PropTypes.object.isRequired,
  carrier: PropTypes.bool,
}

export default Representative
