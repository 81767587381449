import { FWDR_CONFIRMATION } from '../../../actions'

/** 初期データ */
const initData = {}

/**
 * 前画面の情報を保持
 * @param {Boolean} [state=initData] 初期データ
 * @param {Object} action isNew
 * @return {Object} 前画面の情報
 */
const Data = (state = initData, action) => {
  const { type } = action
  switch (type) {
    case FWDR_CONFIRMATION.DATA.SET:
      return action.data
    default:
      return state
  }
}

export default Data

export { initData }
