/* eslint-disable prefer-template */
/* eslint-disable no-extra-parens */
import React from 'react'
import { useTranslation } from 'react-i18next'
import ButtonBase from '@material-ui/core/ButtonBase'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import HowToReg from '@material-ui/icons/HowToReg'
import PersonAddDisabled from '@material-ui/icons/PersonAddDisabled'

import CommonFunc from '../function/CommonFunc'
import Paths from '../../../constants/Paths'
import { Grid } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '224px',
    margin: '0 1rem',
    [theme.breakpoints.down('sm')]: {
      margin: '1rem 0px',
      height: '85px',
      maxWidth: 'none',
    },
    fontFamily: theme.typography.fontFamily,
    color: theme.fontColor.info,
  },
  card: {
    margin: 'auto',
    width: '100%',
    maxHeight: 310,
    height: 'calc(100vh - 400px)',
    minHeight: '256px',
    borderRadius: 20,
    backgroundColor: theme.palette.info.main,
    transition: '0.3s',
    boxShadow: '8px 8px 4px rgba(0,0,0,0.5)',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      height: '85px',
      minHeight: '85px',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.fontColor.secondary,
      boxShadow: '8px 8px 4px rgba(0,0,0,0.5)',
      '& $imgOn': {
        filter: 'saturate(0) brightness(0.06) invert(1)',
      },
    },
  },
  cardDisabled: {
    margin: 'auto',
    width: '100%',
    maxHeight: 310,
    height: 'calc(100vh - 400px)',
    minHeight: '256px',
    borderRadius: 20,
    backgroundColor: theme.palette.info.dark,
    transition: '0.3s',
    boxShadow: '8px 8px 4px rgba(0,0,0,0.5)',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      height: '85px',
      minHeight: '85px',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  cardTitle: {
    position: 'relative',
    fontSize: theme.fontSize.cardButton,
    fontWeight: 'bold',
    lineHeight: '1em',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.fontSize.cardButtonSm,
    },
  },
  cardDetail: {
    position: 'relative',
    fontSize: theme.fontSize.regular,
    fontWeight: 'bold',
    [theme.breakpoints.up('md')]: {
      marginTop: '0.5rem',
      fontSize: theme.fontSize.header,
    },
  },
  icon: {
    fontSize: '100px',
    marginBottom: '1rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '50px',
      margin: '1rem 0.5rem 1rem 1rem',
    },
  },
  titleMd: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  titleSm: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  text: {
    width: '100%',
  },
  imgOn: {},
  iconContainer: {
    marginBottom: '1rem',
    height: '97px',
  },
}))

/**
 * @param {Object} props - 以下
 * @param {string} path - 押下後の遷移先
 * @param {Event} clickevent - 押下後のイベント
 * @returns {JSX.Element} - detail(黄色)ボタンコンポーネント
 */
const CardButton = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  /** @type {String} アイコン名 */
  const ICON_NAME = {
    BID: 'bid',
    BOOKING_LIST: 'bookingList',
    CARGO_TRACE: 'cargoTrace',
    CARGO_READY: 'cargoReady',
    SIGNUP: 'signup',
    DELETE_ACCOUNT: 'deleteAccount',
    COMPANY_INFO: 'companyInfo',
    MY_PROFILE: 'myProfile',
    ACCOUNT_INFO: 'accountInfo',
    CONSIGNEE_INFO: 'consigneeInfo',
    FLIGHT_MGMT: 'flightMgmt',
    AIR_FREIGHT_MGMT: 'airFreightMgmt',
    BILLING_REPORT: 'billingReport',
  }

  /** @type {Object} カードボタン情報 */
  const card = {
    icon: '',
    titleFirst: '',
    titleSecond: '',
    contents: '',
  }

  switch (props.path) {
    case Paths.SHIPPER.BID_MGMT:
      card.icon = ICON_NAME.BID
      card.titleFirst = `${t('D003V0007')}`
      card.titleSecond = `${t('D003V0008')}`
      card.contents = `${t('D003V0009')}`
      break
    case Paths.FWDR.LIGHT_BOOKING_LIST:
    case Paths.AIRLINE.LIGHT_BOOKING_LIST:
      card.icon = ICON_NAME.BOOKING_LIST
      card.titleFirst = `${t('D202V0001')}`
      card.titleSecond = `${t('D202V0002')}`
      card.contents = `${t('D202V0003')}`
      break
    case Paths.FWDR.BID_MGMT:
      card.icon = ICON_NAME.BID
      card.titleFirst = `${t('D101V0009')}`
      card.titleSecond = `${t('D101V0010')}`
      card.contents = `${t('D101V0011')}`
      break
    case Paths.JH.LIGHT_JH_CLIENT_REG_SIGNUP:
      card.icon = ICON_NAME.SIGNUP
      card.titleFirst = `${t('D303V0002')}`
      card.titleSecond = `${t('D303V0004')}`
      break
    case Paths.JH.LIGHT_JH_CLIENT_REG_DELETE_ACCOUNT:
      card.icon = ICON_NAME.DELETE_ACCOUNT
      card.titleFirst = `${t('D303V0003')}`
      card.titleSecond = `${t('D303V0004')}`
      break
    case Paths.MASTER_JH.JH_COMPANY_INFOM:
    case Paths.MASTER_JH.JH_COMPANY_INFOG:
    case Paths.MASTER_SHIPPER.SHIP_COMPANY_INFOM:
    case Paths.MASTER_SHIPPER.SHIP_COMPANY_INFOG:
    case Paths.MASTER_FWDR.LIGHT_FWDR_COMPANY_INFO:
    case Paths.MASTER_AIRLINE.LIGHT_CAR_COMPANY_INFO:
      card.icon = ICON_NAME.COMPANY_INFO
      card.titleFirst = `${t('D301V0168')}`
      card.titleSecond = `${t('D301V0169')}`
      break
    case Paths.MASTER_JH.JH_MY_PROFILE:
    case Paths.MASTER_SHIPPER.SHIP_MY_PROFILE:
    case Paths.MASTER_FWDR.LIGHT_FWDR_MY_PROFILE:
    case Paths.MASTER_AIRLINE.LIGHT_CAR_MY_PROFILE:
      card.icon = ICON_NAME.MY_PROFILE
      card.titleFirst = `${t('D301V0022')}`.split(' ')[0]
      card.titleSecond = `${t('D301V0022')}`.split(' ')[1]
      break
    case Paths.MASTER_JH.JH_ACCOUNT_INFO:
    case Paths.MASTER_SHIPPER.SHIP_ACCOUNT_INFO:
    case Paths.MASTER_FWDR.LIGHT_FWDR_ACCOUNT_INFO:
    case Paths.MASTER_AIRLINE.LIGHT_CAR_ACCOUNT_INFO:
      card.icon = ICON_NAME.ACCOUNT_INFO
      card.titleFirst = `${t('D301V0170')}`
      card.titleSecond = `${t('D301V0169')}`
      break
    case Paths.MASTER_SHIPPER.SHIP_CONSIGNEE_INFO:
      card.icon = ICON_NAME.CONSIGNEE_INFO
      card.titleFirst = `${t('D301V0173')}`
      card.titleSecond = `${t('D301V0169')}`
      break
    case Paths.MASTER_AIRLINE.LIGHT_CAR_FLIGHT_MGMT:
      card.icon = ICON_NAME.FLIGHT_MGMT
      card.titleFirst = `${t('D301V0193')}`
      card.titleSecond = `${t('4th0007')}`
      break
    case Paths.MASTER_AIRLINE.LIGHT_CAR_SELECT_FWDR:
      card.icon = ICON_NAME.AIR_FREIGHT_MGMT
      card.titleFirst = `${t('D301V0233')}`
      card.titleSecond = `${t('4th0007')}`
      break
    case Paths.FWDR.LIGHT_BILLING_REPORT:
      card.icon = ICON_NAME.BILLING_REPORT
      card.titleFirst = `${t('4th0002')}`
      card.titleSecond = `${t('4th0003')}`
      card.contents = `${t('4th0004')}`
      break
    case Paths.AIRLINE.LIGHT_BILLING_REPORT:
      card.icon = ICON_NAME.BILLING_REPORT
      card.titleFirst = `${t('4th0002')}`
      card.titleSecond = `${t('4th0003')}`
      card.contents = `${t('4th0005')}`
      break
    case Paths.FWDR.LIGHT_BOOKING_REQUEST:
      card.icon = ICON_NAME.FLIGHT_MGMT
      card.titleFirst = `${t('D101V0001')}`
      card.titleSecond = `${t('4th0001')}`
      card.contents = `${t('D003V0003')}`
      break
    default:
      break
  }

  // clickイベントとページ遷移
  const history = useHistory()
  const clickEvent = (props) => {
    CommonFunc.clickEvent(props, history)
  }

  /**
   * ICON_NAMEからアイコンを取得する
   * @param {String} iconName ICON_NAMEの値
   * @returns {*} アイコン
   */
  const getIcon = (iconName) => {
    let icon = null
    switch (iconName) {
      case ICON_NAME.BID:
        icon = (
          <img
            className={classes.imgOn}
            src="./../images/topIcon/Bid.svg"
            alt="bid"
          />
        )
        break
      case ICON_NAME.BOOKING_LIST:
        icon = (
          <img
            className={classes.imgOn}
            src="./../images/topIcon/BookingList.svg"
            alt="booking"
          />
        )
        break
      case ICON_NAME.CARGO_READY:
        icon = (
          <img
            className={classes.imgOn}
            src="./../images/topIcon/CargoReady.svg"
            alt="cargoReady"
          />
        )
        break
      case ICON_NAME.SIGNUP:
        icon = <HowToReg className={classes.icon} />
        break
      case ICON_NAME.DELETE_ACCOUNT:
        icon = <PersonAddDisabled className={classes.icon} />
        break
      case ICON_NAME.COMPANY_INFO:
        icon = (
          <img
            className={classes.imgOn}
            src="./../images/topIcon/CompanyInfo.svg"
            alt="companyInfo"
          />
        )
        break
      case ICON_NAME.MY_PROFILE:
        icon = (
          <img
            className={classes.imgOn}
            src="./../images/topIcon/MyProfile.svg"
            alt="myProfile"
          />
        )
        break
      case ICON_NAME.ACCOUNT_INFO:
        icon = (
          <img
            className={classes.imgOn}
            src="./../images/topIcon/AccountInfo.svg"
            alt="accountInfo"
          />
        )
        break
      case ICON_NAME.CONSIGNEE_INFO:
        icon = (
          <img
            className={classes.imgOn}
            src="./../images/topIcon/ConsigneeInfo.svg"
            alt="consigneeInfo"
          />
        )
        break
      case ICON_NAME.CARGO_TRACE:
        icon = (
          <img
            className={classes.imgOn}
            src="./../images/topIcon/FlightMgmt.svg"
            alt="cargoTrace"
          />
        )
        break
      case ICON_NAME.FLIGHT_MGMT:
        icon = (
          <img
            className={classes.imgOn}
            src="./../images/topIcon/FlightMgmt.svg"
            alt="flightMgmt"
          />
        )
        break
      case ICON_NAME.AIR_FREIGHT_MGMT:
        icon = (
          <img
            className={classes.imgOn}
            src="./../images/topIcon/AirFreight.svg"
            alt="airFreight"
          />
        )
        break
      case ICON_NAME.BILLING_REPORT:
        icon = (
          <img
            className={classes.imgOn}
            src="./../images/topIcon/BillingReport_blue.svg"
            alt="BillingReport_blue"
          />
        )
        break
      default:
        break
    }

    return (
      <Grid
        container
        justify="center"
        alignContent="center"
        className={classes.iconContainer}
      >
        <Grid item>{icon}</Grid>
      </Grid>
    )
  }

  return (
    <div className={classes.root}>
      <ButtonBase
        focusRipple
        className={
          props.disabled === true ? classes.cardDisabled : classes.card
        }
        onClick={() => clickEvent(props)}
        {...props}
      >
        {getIcon(card.icon)}
        <div className={classes.text}>
          <div className={classes.titleMd}>
            <Typography className={classes.cardTitle}>
              {card.titleFirst}
            </Typography>
            <Typography className={classes.cardTitle}>
              {card.titleSecond}
            </Typography>
          </div>
          <Typography className={classes.cardTitle + ' ' + classes.titleSm}>
            {card.titleFirst} {card.titleSecond}
          </Typography>
          <Typography className={classes.cardDetail}>
            {card.contents}
          </Typography>
        </div>
      </ButtonBase>
    </div>
  )
}

export default CardButton
