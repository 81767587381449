const ConstantsLightDeleteAccountDone = {
  // テーブル作成に必要な値
  TABLE_ARR: [
    { headerKey: 'D501V0131' },
    { headerKey: 'D501V0132' },
    { headerKey: 'D501V0133' },
  ],
}

export default ConstantsLightDeleteAccountDone
