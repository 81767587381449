import React, { useContext } from 'react'

import MeasureAndWeightLightBookingDetails from '../../../../commonUseContext/LightBookingDetails/MeasureAndWeight/MeasureAndWeightLightBookingDetails'
import AppContext from '../../../../../contexts/AppContext'

/**
 * @return {JSX} Measure&Weightアコーディオン
 */
const MeasureAndWeightFwdrLightBookingDetails = () => {
  const { state } = useContext(AppContext)
  const { isSpaceConfirmed, isBookingRequest } = state.Status
  const editable = isSpaceConfirmed || isBookingRequest
  return <MeasureAndWeightLightBookingDetails editable={editable} />
}

export default MeasureAndWeightFwdrLightBookingDetails
