import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

import TableCell from '../../../../common/table/TableCell'
import TableRow from '../../../../common/table/TableRow'
import ConstantsLightJhClientList, {
  TabName,
} from '../ConstantsLightJhClientList'
import AppContext from '../../../../../contexts/AppContext'

const useStyles = makeStyles((theme) => ({
  tableHead: theme.commonTableHeader,
}))

/**
 * 定数から作成するヘッダーコンポーネント(1列分)
 * 変更したい場合は定数ConstantsLightJhClientListを操作する
 * @return {JSX} ヘッダーコンポーネント(1列分)
 */
const HeaderLightJhClientList = () => {
  const { tableHead } = useStyles()
  const { t } = useTranslation()
  const { state } = useContext(AppContext)

  const { FWDR_TABLE_ARR, CARRIER_TABLE_ARR } = ConstantsLightJhClientList

  const tableArr =
    state.Tabs === TabName.forwarder ? FWDR_TABLE_ARR : CARRIER_TABLE_ARR

  return (
    <TableRow>
      {tableArr.map(({ headerKey }, index) => (
        <TableCell key={index} className={tableHead}>
          {t(headerKey)}
        </TableCell>
      ))}
    </TableRow>
  )
}

export default HeaderLightJhClientList
