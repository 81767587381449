import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'

import Tabs from '../../../../common/tab/CommonTabs'
import { LIGHT_FLIGHT_MGMT, PAGING, TABS } from '../../../../../actions'
import AppContext from '../../../../../contexts/AppContext'
import { TabName } from '../ConstantsCarFlightMgmt'

const useStyles = makeStyles(() => ({
  root: {
    marginLeft: '4px',
  },
}))

const TabLightCarFlightMgmt = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { state, dispatch } = useContext(AppContext)
  return useMemo(() => {
    const TabSize = 6

    /**
     * タブ変更時の処理
     * @param {TabName} select 選択したタブの値
     * @returns {void} 無し
     */
    const onChange = (select) => {
      dispatch({
        type: PAGING.SET,
        page: 1,
      })
      dispatch({
        type: TABS.SET,
        select,
      })
      dispatch({
        type: LIGHT_FLIGHT_MGMT.TABLES.CHANGE_SHOW_DATA,
        tab: select,
      })
    }

    const tabSettingArr = [
      {
        label: t('D301V0127'),
        girdSetting: {
          md: TabSize,
          onClick: () => onChange(TabName.flightInfo),
        },
      },
      {
        label: t('D301V0019'),
        girdSetting: { md: TabSize, onClick: () => onChange(TabName.icon) },
      },
    ]

    return (
      <Grid
        item
        md={4}
        container
        alignItems="flex-end"
        className={classes.root}
      >
        {state.Gsa ? (
          <Tabs
            selectTabIndex={state.Tabs}
            tabSettingArr={tabSettingArr}
            fill
          />
        ) : null}
      </Grid>
    )
  }, [state.Tabs, dispatch, classes, state.Gsa, t])
}

export default TabLightCarFlightMgmt
