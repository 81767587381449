import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import Paths from '../../../../../constants/Paths'
import PrimaryButton from '../../../../common/buttons/PrimaryButton'
import AppContext from '../../../../../contexts/AppContext'

const useStyles = makeStyles((theme) => ({
  size: {
    width: theme.buttonSize.width.large,
    height: theme.buttonSize.height.large,
  },
}))

/**
 * @param {*} props 下記内容
 * @param {Object} data BidInfo画面から受け取ったデータ
 * @param {Boolean} disabled 非活性判定
 * @return {JSX} Confirmボタンコンポーネント
 */
const ConfirmButtonBidDetails = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { state } = useContext(AppContext)
  const { disabled } = props

  /** Confirmation画面に受け渡すデータ */
  const newData = {
    ...state.Data,
    bidDetailArr: state.Tables.editData,
  }

  return (
    <div className={classes.back}>
      <PrimaryButton
        addclass={classes.size}
        path={Paths.SHIPPER.CONFIRMATION}
        state={newData}
        disabled={disabled}
      >
        {t('D104V0059')}
      </PrimaryButton>
    </div>
  )
}

ConfirmButtonBidDetails.propTypes = {
  disabled: PropTypes.bool.isRequired,
}

export default ConfirmButtonBidDetails
