import React, { useReducer, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import LayoutLog from '../../../common/layout/LayoutLog'
import AppContext from '../../../../contexts/AppContext'
import CommonFunc from '../../../common/function/CommonFunc'
import ApiPaths from '../../../../constants/ApiPaths'
import reducer, {
  initialState,
} from '../../../../reducers/authentication/lightAirlineRegisterNewAccount'
import { LIGHT_REGISTER_NEW_ACCOUNT } from '../../../../actions'
import TitleLightAirlineRegisterNewAccount from './00TitleLightAirlineRegisterNewAccount/TitleLightAirlineRegisterNewAccount'
import CompanyLightAirlineRegisterNewAccount from './01CompanyLightAirlineRegisterNewAccount/CompanyLightAirlineRegisterNewAccount'
import IataCassNoLightAirlineRegisterNewAccount from './02IataCassNoLightAirlineRegisterNewAccount/IataCassNoLightAirlineRegisterNewAccount'
import BranchLightAirlineRegisterNewAccount from './03BranchLightAirlineRegisterNewAccount/BranchLightAirlineRegisterNewAccount'
import DepartmentLightAirlineRegisterNewAccount from './04DepartmentLightAirlineRegisterNewAccount/DepartmentLightAirlineRegisterNewAccount'
import HubsLightFwdrRegisterNewAccount from './05HubsLightAirlineRegisterNewAccount/HubsLightFwdrRegisterNewAccount'
import FirstNameLightAirlineRegisterNewAccount from './06FirstNameLightAirlineRegisterNewAccount/FirstNameLightAirlineRegisterNewAccount'
import LastNameLightAirlineRegisterNewAccount from './07LastNameLightAirlineRegisterNewAccount/LastNameLightAirlineRegisterNewAccount'
import PositionLightFwdrRegisterNewAccount from './08PositionLightAirlineRegisterNewAccount/PositionLightAirlineRegisterNewAccount'
import EmailLightAirlineRegisterNewAccount from './09EmailLightAirlineRegisterNewAccount/EmailLightAirlineRegisterNewAccount'
import TelLightAirlineRegisterNewAccount from './10TelLightAirlineRegisterNewAccount/TelLightAirlineRegisterNewAccount'
import PostalCodeLightAirlineRegisterNewAccount from './11PostalCodeLightAirlineRegisterNewAccount/PostalCodeLightAirlineRegisterNewAccount'
import AddressLightAirlineRegisterNewAccount from './12AddressLightAirlineRegisterNewAccount/AddressLightAirlineRegisterNewAccount'
import IconLightAirlineRegisterNewAccount from './13IconLightAirlineRegisterNewAccount/IconLightAirlineRegisterNewAccount'
import RequireLightAirlineRegisterNewAccount from './14RequireLightAirlineRegisterNewAccount/RequireLightAirlineRegisterNewAccount'
import CheckLightAirlineRegisterNewAccount from './15CheckLightAirlineRegisterNewAccount/CheckLightAirlineRegisterNewAccount'
import ButtonLightAirlineRegisterNewAccount from './16ButtonLightAirlineRegisterNewAccount/ButtonLightAirlineRegisterNewAccount'
import PlanLightAirlineRegisterNewAccount from './17PlanLightAirlineRegisterNewAccount/PlanLightAirlineRegisterNewAccount'

const LightAirlineRegisterNewAccount = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const history = useHistory()

  /** 初期処理 */
  useEffect(() => {
    ;(async () => {
      const apiInfoArr = [
        {
          key: 'companyArr',
          method: 'get',
          path: ApiPaths.AUTHENTICATION.CARRIER_NAME,
        },
        { key: 'cityArr', method: 'get', path: ApiPaths.MASTER_JH.CITY_CODE },
        { key: 'airportArr', method: 'get', path: ApiPaths.MASTER_JH.AIRPORT },
        {
          key: 'planArr',
          method: 'get',
          path: ApiPaths.COMMON_API.LIGHT_CAR_PLAN,
        },
      ]
      const resObj = await CommonFunc.execApiAsync(apiInfoArr)
      const isApiSuccessful = CommonFunc.checkApiResponseObj(resObj, history)

      if (isApiSuccessful) {
        const { planArr } = resObj
        const resArr = Object.entries(resObj).map(([keyStr, res]) => [
          keyStr,
          res.data.results,
        ])
        const stateObj = Object.fromEntries(resArr)
        dispatch({
          type: LIGHT_REGISTER_NEW_ACCOUNT.SELECT_DATA.INIT,
          data: stateObj,
        })
        dispatch({
          type: LIGHT_REGISTER_NEW_ACCOUNT.PLAN.INIT,
          planArr: planArr.data.results,
        })
      }
    })()
  }, []) // eslint-disable-line

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <LayoutLog>
        <TitleLightAirlineRegisterNewAccount />
        <PlanLightAirlineRegisterNewAccount />
        <CompanyLightAirlineRegisterNewAccount />
        <IataCassNoLightAirlineRegisterNewAccount />
        <BranchLightAirlineRegisterNewAccount />
        <DepartmentLightAirlineRegisterNewAccount />
        <HubsLightFwdrRegisterNewAccount />
        <FirstNameLightAirlineRegisterNewAccount />
        <LastNameLightAirlineRegisterNewAccount />
        <PositionLightFwdrRegisterNewAccount />
        <EmailLightAirlineRegisterNewAccount />
        <TelLightAirlineRegisterNewAccount />
        <PostalCodeLightAirlineRegisterNewAccount />
        <AddressLightAirlineRegisterNewAccount />
        <IconLightAirlineRegisterNewAccount />
        <RequireLightAirlineRegisterNewAccount />
        <CheckLightAirlineRegisterNewAccount />
        <ButtonLightAirlineRegisterNewAccount />
      </LayoutLog>
    </AppContext.Provider>
  )
}

export default LightAirlineRegisterNewAccount
