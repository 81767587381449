import React, { useReducer, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import LayoutBox from '../../../common/layout/LayoutBox'
import AppContext from '../../../../contexts/AppContext'
import reducer, {
  initialState,
} from '../../../../reducers/master/lightMasterFwdrMyProfile'
import TitleLightFwdrMyProfile from './00TitleLightFwdrMyProfile/TitleLightFwdrMyProfile'
import TableLightFwdrMyProfile from './01TableLightFwdrMyProfile/TableLightFwdrMyProfile'
import ButtonLightFwdrMyProfile from './02ButtonLightFwdrMyProfile/ButtonLightFwdrMyProfile'
import CommonFunc from '../../../common/function/CommonFunc'
import ApiPaths from '../../../../constants/ApiPaths'
import {
  UPDATE_FWDR_FIRST_NAME,
  UPDATE_FWDR_LAST_NAME,
  UPDATE_FWDR_MAIL,
  UPDATE_FWDR_TEL,
  UPDATE_FWDR_POSITION,
  UPDATE_FWDR_PASSWORD,
  UPDATE_FWDR_MAIL_SETTING,
  UPDATE_FWDR_BACKUP,
  UPDATE_FWDR_UPDATE_AT,
} from '../../../../actions'

/**
 * @return {JSX} Light版FwdrMyProfile画面コンポーネント
 */
const LightFwdrMyProfile = () => {
  const history = useHistory()
  // 扱いたいデータstateとデータを操作するための処理dispatch
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    ;(async () => {
      // API取得
      const apiInfoArr = [
        {
          key: 'companyInfoArr',
          method: 'get',
          path: ApiPaths.MASTER_FWDR.LIGHT_MY_PROFILE,
        },
      ]
      const resObj = await CommonFunc.execApiAsync(apiInfoArr)
      const isApiSuccessful = CommonFunc.checkApiResponseObj(resObj, history)
      if (isApiSuccessful) {
        const res = resObj.companyInfoArr.data.results

        dispatch({
          type: UPDATE_FWDR_FIRST_NAME,
          name: res.name,
        })
        dispatch({
          type: UPDATE_FWDR_LAST_NAME,
          lastName: res.lastName,
        })
        dispatch({
          type: UPDATE_FWDR_MAIL,
          email: res.email,
        })
        dispatch({
          type: UPDATE_FWDR_TEL,
          tel: res.tel,
        })
        dispatch({
          type: UPDATE_FWDR_POSITION,
          position: res.position,
        })
        dispatch({
          type: UPDATE_FWDR_PASSWORD,
          password: res.password,
        })
        dispatch({
          type: UPDATE_FWDR_MAIL_SETTING,
          mailSetting: res.mailSetting,
        })
        dispatch({
          type: UPDATE_FWDR_UPDATE_AT,
          updatedAt: res.updatedAt,
        })

        // 初回データをBackup
        dispatch({
          type: UPDATE_FWDR_BACKUP,
          info: res,
        })

        // 先頭にスクロール
        window.scrollTo(0, 0)
      }
    })()
  }, []) // eslint-disable-line

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <LayoutBox>
        <TitleLightFwdrMyProfile />
        <TableLightFwdrMyProfile />
        <ButtonLightFwdrMyProfile />
      </LayoutBox>
    </AppContext.Provider>
  )
}

export default LightFwdrMyProfile
