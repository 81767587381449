import React, { useContext, useEffect, useMemo, useState } from 'react'
import Grid from '@material-ui/core/Grid'

import AppContext from '../../../../contexts/AppContext'
import { LIGHT_BOOKING_DETAILS } from '../../../../actions'
import CommonDialog from '../../../common/dialog/commonDialog/CommonDialog'
import Common from '../../../../constants/Common'
import RemarkPopupTextField from './RemarkPopupTextField'

/**
 * @returns {JSX} Remarkポップアップ
 */
const RemarkPopup = () => {
  const { dispatch, state } = useContext(AppContext)
  const [open, setOpen] = useState(false)
  const { inputError, remark, placeholder } = state.RemarkPopup.remarkObj

  // ポップアップを開く関数をreducerに登録
  useEffect(() => {
    dispatch({
      type: LIGHT_BOOKING_DETAILS.REMARK_POPUP.SET_OPEN_FUNC,
      setOpen,
    })
  }, [dispatch])

  // Remark入力欄
  const remarkTextField = useMemo(
    () => (
      <RemarkPopupTextField
        dispatch={dispatch}
        defaultValue={remark}
        placeholder={placeholder}
      />
    ),
    [dispatch, remark, placeholder]
  )

  return useMemo(() => {
    /**
     * Remarkを登録
     * @param {String} remark 入力されたremark
     * @returns {void} なし
     */
    const registRemark = (remark) => {
      dispatch({
        type: LIGHT_BOOKING_DETAILS.REMARK.EDIT,
        [state.RemarkPopup.remarkObj.remarkName]: remark,
      })
      setOpen(false)
    }

    // ダイアログのボタン設定
    const dialogFuncObj = {
      [Common.DIALOG.BUTTON_CATEGORY.DELETE]: {
        onClick: () => registRemark(''),
      },
      [Common.DIALOG.BUTTON_CATEGORY.CANCEL]: { onClick: () => setOpen(false) },
      [Common.DIALOG.BUTTON_CATEGORY.OK]: {
        onClick: () => registRemark(state.RemarkPopup.remarkObj.remark),
        disabled: inputError,
      },
    }
    return (
      <>
        <CommonDialog
          open={open}
          closeFunc={() => setOpen(false)}
          dialogFuncObj={dialogFuncObj}
        >
          <Grid
            container
            direction="row"
            justify="center"
            alignContent="center"
            alignItems="center"
            spacing={2}
          >
            {remarkTextField}
          </Grid>
        </CommonDialog>
      </>
    )
  }, [open, dispatch, inputError, state.RemarkPopup.remarkObj]) //eslint-disable-line
}
export default RemarkPopup
