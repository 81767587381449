import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import ConstantsLightBookingRequest from '../ConstantsLightBookingRequest'
import TableCell from '../../../../common/table/TableCell'
import TableRow from '../../../../common/table/TableRow'
import Common from '../../../../../constants/Common'

/** @typedef {import('../ConstantsLightBookingRequest').TableInfo} TableInfo テーブル情報 */

const useStyles = makeStyles((theme) => ({
  tableHead: theme.commonTableHeader,
  red: theme.textBoldRED,
}))

/**
 * 定数から作成するヘッダーコンポーネント(1列分)
 * 変更したい場合は定数ConstantsLightBookingRequestを操作する
 * @return {JSX} ヘッダーコンポーネント(1列分)
 */
const HeaderLightBookingRequest = () => {
  const { tableHead, red } = useStyles()
  const { t } = useTranslation()

  /**
   * 定数で定めたヘッダーコンポーネント(1個分)を作成
   * @param {String} tableInfo  定数に設定テーブルデータ
   * @return {JSX} ヘッダーコンポーネント(1個分)
   */
  const createHeaderCell = (tableInfo) => {
    const { headerKey, cellCategory } = tableInfo
    switch (cellCategory) {
      case Common.CELL_CATEGORY.TEXT_FIELD.DETAILS:
        return (
          <TableCell key={headerKey} className={tableHead}>
            {' '}
            {t(headerKey)}{' '}
          </TableCell>
        )
      case Common.CELL_CATEGORY.TEXT:
        return (
          <TableCell key={headerKey} className={tableHead}>
            <span className={red}>*</span>
            {t(headerKey)}
          </TableCell>
        )
      default:
        return (
          <TableCell key={headerKey} className={tableHead}>
            {' '}
          </TableCell>
        )
    }
  }

  return (
    <TableRow color={'transparent'}>
      {ConstantsLightBookingRequest.TABLE_ARR.map(createHeaderCell)}
    </TableRow>
  )
}

export default HeaderLightBookingRequest
