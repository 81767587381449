import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import CommonFunc from '../components/common/function/CommonFunc'
import Common from '../constants/Common'
import ApiPaths from '../constants/ApiPaths'
import Paths from '../constants/Paths'

import restFacade from '../actions/rest-facade'

/**
 * @export 全ページ共通処理
 * @param {Object} props children表示したい要素
 * @return {JSX} 全ページ共通処理コンポーネント
 */
export default function PageContent(props) {
  const history = useHistory()
  const { t } = useTranslation()
  const isFreeLogin = CommonFunc.checkAccessPage(
    Common.PAGE_CATEGORIES.FREE_LOGIN
  )
  // 画面表示フラグ、サインイン確認任意なら最初から表示
  const [showFlg, setShowFlg] = useState(isFreeLogin)
  let target = ''

  // ページを閉じたりしたときの確認アラート表示
  window.onbeforeunload = () => ''

  document.onclick = (e) => {
    if (target === '' || target !== e.target) {
      target = e.target
      // シングルクリックを受理、500ms間だけダブルクリック判定を残す
      setTimeout(() => {
        target = ''
      }, 500)
      return
    } else {
      // 同じものをクリックしたとき
      e.stopImmediatePropagation()
      return
    }
  }

  document.oncontextmenu = (e) => {
    e.preventDefault()
  }
  document.ondragstart = (e) => {
    e.preventDefault()
  }

  document.onkeyup = (e) => {
    const keyCode = e.keyCode ? e.keyCode : e.which
    if (keyCode === 44) {
      stopPrntScr()
    }
  }

  window.onpopstate = () => {
    const beforePath = CommonFunc.getStorage(Common.KEY.CURRENT_PATH)
    const errorState = JSON.parse(
      CommonFunc.getStorage(Common.KEY.ERROR_STATE)
        ? CommonFunc.getStorage(Common.KEY.ERROR_STATE)
        : '{}'
    )
    const beforeState = JSON.parse(
      CommonFunc.getStorage(Common.KEY.CURRENT_STATE)
        ? CommonFunc.getStorage(Common.KEY.CURRENT_STATE)
        : '{}'
    )

    // 遷移先か遷移元がエラーページでない場合
    if (beforePath !== Paths.OTHERS.ERROR) {
      // stateをpushして遷移先に情報を引き継ぐ
      history.push(beforePath, { state: beforeState })
    } else {
      // エラーページの場合、stateとerrorをpushする
      history.push(beforePath, { state: beforeState, error: errorState })
    }
    // ブラウザバック禁止機能に必要なためエラーを無視
    // eslint-disable-next-line no-alert
    alert(t('BROWSER_BACK'))
  }

  const styles = {
    WebkitUserSelect: 'none' /* Chrome all / Safari all */,
    MozUserSelect: 'none' /* Firefox all */,
    msUserSelect: 'none' /* IE 10+ */,
    userSelect: 'none',
    /*Mobile*/
    WebkitTouchCallout: 'none',
  }

  // エラー処理、コールバック関数実行
  const setData = (res, callback) => {
    CommonFunc.callbackFunc(res, callback, history)
  }

  /**
   * サインインの確認がOKだった場合の処理
   * @param {Object} res APIのレス
   * @return {void}
   */
  const successConfirmSignIn = (res) => {
    // サインイン済みの場合はユーザ情報をストレージに保存
    const userData = {
      ...res.data.results,
      userType: CommonFunc.getLoginUserType(res.data.results),
    }
    CommonFunc.setStorage(Common.KEY.USER_INFO, JSON.stringify(userData))

    if (isFreeLogin && window.location.pathname !== '/error') {
      // サインイン不要画面の場合
      // 既にサインインしているのであればTop画面に遷移
      CommonFunc.topTranslation(history)
    } else {
      // サインイン必要画面の場合
      // ページチェック
      CommonFunc.checkPageTransition(history)

      // ページを表示
      setShowFlg(true)
      // UTC情報取得
      if (!CommonFunc.getStorage(Common.KEY.UTC_INFO)) {
        restFacade.get(ApiPaths.COMMON_API.UTC, (res) =>
          setData(res, CommonFunc.setUtcData)
        )
      }
    }
  }

  /**
   * サインインの確認がNGだった場合の処理
   * サインイン不要画面はNGでも表示許可として204が帰ってくるため
   * @return {void}
   */
  const acceptConfirmSignIn = () => {
    // サインイン不要画面の場合
    // 画面を表示
    setShowFlg(true)
    // ページチェック
    CommonFunc.checkPageTransition(history)
    // setShowFlg(false)
  }

  /**
   * サインインの確認がNGだった場合の処理
   * @param {Object} res APIのレス
   * @return {void}
   */
  const failConfirmSignIn = (res) => {
    // サインイン必要画面の場合
    // 未サインイン状態の場合はサインイン画面に遷移
    const errorCode = res.data.info.ErrorCode
    CommonFunc.errorHandling(errorCode)
    // ネットワークエラーの場合サインイン画面に遷移してもエラーになるのでエラーページに遷移
    // メンテナンス中の場合、メンテナンス画面に遷移
    // ログインユーザーが削除された場合、トークンを削除しサインイン画面に遷移
    if (
      errorCode === Common.ERROR_CODE.NETWORK_ERROR ||
      errorCode === Common.ERROR_CODE.UNDER_MAINTENANCE ||
      errorCode === Common.ERROR_CODE.DELETED_USER
    ) {
      CommonFunc.callbackFunc(res, '', history)
      setShowFlg(true)
    } else {
      CommonFunc.clickEvent({ path: Paths.AUTHENTICATION.SIGN_IN }, history)
    }
  }

  /**
   * サインイン状態をチェック
   * @param {Object} res confirmSignInAPIのレスポンス
   * @return {void}
   */
  const confirmSignIn = (res) => {
    switch (res.data.status) {
      case Common.RESPONSE_STATUS_CODE.OK:
        successConfirmSignIn(res)
        return
      case Common.RESPONSE_STATUS_CODE.ACCEPT:
        acceptConfirmSignIn()
        return
      case Common.RESPONSE_STATUS_CODE.NG:
      case Common.RESPONSE_STATUS_CODE.CREATED:
        failConfirmSignIn(res)
        return
      default:
        return
    }
  }

  // サインインが必要かどうかによってAPIの動きが変わるため追加
  // サインイン状態を毎回確認
  restFacade.post(ApiPaths.AUTHENTICATION.CONFIRM_SIGN_IN, (res) =>
    confirmSignIn(res)
  )

  const storageCurrentPath =
    CommonFunc.getStorage(Common.KEY.CURRENT_PATH) || ''
  const storageBeforePath = CommonFunc.getStorage(Common.KEY.BEFORE_PATH) || ''

  const isReload = storageCurrentPath === window.location.pathname
  // ()ありの方が見やすくなるので警告を一旦無効化
  // eslint-disable-next-line no-extra-parens
  const isBack =
    storageCurrentPath !== window.location.pathname &&
    storageBeforePath === window.location.pathname
  // eslint-disable-next-line no-extra-parens
  const isURL =
    storageCurrentPath !== window.location.pathname &&
    storageBeforePath !== window.location.pathname
  const isBackButtonClicked = isBack && !isReload && !isURL

  useEffect(() => {
    // bodyの子要素の変化を監視して子要素が増減するたびにダイアログを開いているかチェック
    const observer = new MutationObserver(() => {
      /** エラーダイアログを表示しているかどうか */
      const isHiddenErrorDialog =
        document.getElementById('error-dialog')?.hidden
      const isShowErrorDialog =
        isHiddenErrorDialog === undefined ? false : !isHiddenErrorDialog
      /** 他にダイアログを開いているかどうか */
      const isDialog = CommonFunc.getIsDialog()

      // ダイアログを開いている場合スクロールを非表示
      if (isShowErrorDialog || isDialog) {
        CommonFunc.hideScroll()
      } else {
        CommonFunc.showScroll()
      }
    })
    observer.observe(document.body, {
      childList: true,
    })

    // アンマウント時に監視を停止
    return observer.disconnect
  }, [])

  return (
    <div style={styles}>
      {showFlg && !isBackButtonClicked ? props.children : ''}
    </div>
  )
}
