import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import Common from './Common'

// 各画面の文言一覧をインポート
// ２次リリース以降は共通文言で管理
import CommonDisplay from './common/CommonDisplay'
import DisplayApiSample from './DisplayApiSample'
// 荷主
import DisplayShipperManagingBid from './shipper/DisplayShipperManagingBid'
import DisplayShipperCargoTrace from './shipper/DisplayShipperCargoTrace'
import DisplayShipperShowDetails from './shipper/DisplayShipperShowDetails'
import DisplayShipperOfferingBid from './shipper/DisplayShipperOfferingBid'
import DisplayShipperShippingDetails from './shipper/DisplayShipperShippingDetails'
import DisplayShipperTop from './shipper/DisplayShipperTop'
import DisplayShipperConfirmBooking from './shipper/DisplayShipperConfirmBooking'
import DisplayShipperRegister from './shipper/DisplayShipperRegister'
import DisplayShipperSignIn from './shipper/DisplayShipperSignIn'
import DisplayShipperSearchResults from './shipper/DisplayShipperSearchResults'
import DisplayShipperInvoice from './shipper/DisplayShipperInvoice'
import DisplayShipperPackingList from './shipper/DisplayShipperPackingList'
import DisplayShipperTracing from './shipper/DisplayShipperTracing'
import DisplayShipperConfirmBid from './shipper/DisplayShipperConfirmBid'
import DisplayShipperQuote from './shipper/DisplayShipperQuote'

// 混載業者
import DisplayFwdrTop from './fwdr/DisplayFwdrTop'
import DisplayFwdrBookingList from './fwdr/DisplayFwdrBookingList'
import DisplayFwdrCompleteBooking from './fwdr/DisplayFwdrCompleteBooking'
import DisplayFwdrQuote from './fwdr/DisplayFwdrQuote'
import DisplayFwdrDocsPreparation from './fwdr/DisplayFwdrDocsPreparation'
import DisplayFwdrPrivateBid from './fwdr/DisplayFwdrPrivateBid'
import DisplayFwdrCargoTrace from './fwdr/DisplayFwdrCargoTrace'
import DisplayFwdrTracing from './fwdr/DisplayFwdrTracing'
import DisplayFwdrConfirmBidCarrier from './fwdr/DisplayFwdrConfirmBidCarrier'
import DisplayFwdrConfirmBid from './fwdr/DisplayFwdrConfirmBid'
import DisplayFwdrDocuments from './fwdr/DisplayFwdrDocuments'
import DisplayFwdrDocumentsConfirm from './fwdr/DisplayFwdrDocumentsConfirm'
import DisplayFwdrBidDetailsCarrier from './fwdr/DisplayFwdrBidDetailsCarrier'
import DisplayFwdrBidDetailsShipper from './fwdr/DisplayFwdrBidDetailsShipper'
import DisplayFwdrPublicBid from './fwdr/DisplayFwdrPublicBid'

// 航空会社
import DisplayAirlineTop from './airline/DisplayAirlineTop'
import DisplayAirlineRegister from './airline/DisplayAirlineRegister'
import DisplayAirlineBookingList from './airline/DisplayAirlineBookingList'
import DisplayAirlineConfirmBooking from './airline/DisplayAirlineConfirmBooking'
import DisplayAirlineSpaceCheck from './airline/DisplayAirlineSpaceCheck'
import DisplayAirlineSpaceControl from './airline/DisplayAirlineSpaceControl'
import DisplayAirlineAwbDetails from './airline/DisplayAirlineAwbDetails'
import DisplayAirlineUldAssign from './airline/DisplayAirlineUldAssign'
import DisplayAirlineSetFlight from './airline/DisplayAirlineSetFlight'
import DisplayAirlineCargoManifest from './airline/DisplayAirlineCargoManifest'
import DisplayAirlineCargoTrace from './airline/DisplayAirlineCargoTrace'
import DisplayAirlineTracing from './airline/DisplayAirlineTracing'
import DisplayAirlineBidRates from './airline/DisplayAirlineBidRates'
import DisplayAirlineBidComfirm from './airline/DisplayAirlineBidConfirm'
import DisplayAirlineShowDetails from './airline/DisplayAirlineShowDetails'
import DisplayAirlineCassDetails from './airline/DisplayAirlineCassDetails'
import DisplayAirlineRecordDetails from './airline/DisplayAirlineRecordDetails'
import DisplayAirlineCassUpload from './airline/DisplayAirlineCassUpload'
import DisplayAirlineSpecialLoad from './airline/DisplayAirlineSpecialLoad'

// マスタメンテ
import DisplayMasterCompanyInfo from './master/DisplayMasterCompanyInfo'
import DisplayMasterClient from './master/DisplayMasterClient'
import DisplayMasterClientReg from './master/DisplayMasterClientReg'
import DisplayJH from './master/DisplayJH'

// 共通
import DisplayAuthentication from './authentication/DisplayAuthentication'
import CommonMessage from './CommonMessage'
// 入力制約
import ValidationCheckMessage from './ValidationCheckMessage'
// 通知
import DisplayNotifyList from './notify/DisplayNotifyList'
import DisplayNotificationDetails from './notify/DisplayNotificationDetails'

// 単語一覧のひな形
const resources = {
  en: {
    translation: {},
  },
  ja: {
    translation: {},
  },
}

// 各画面の文言一覧の収納場所
const translationsArr = []

// 各画面の文言一覧の収納
translationsArr.push(CommonDisplay)
translationsArr.push(DisplayApiSample)
//荷主
translationsArr.push(DisplayShipperManagingBid)
translationsArr.push(DisplayShipperShowDetails)
translationsArr.push(DisplayShipperCargoTrace)
translationsArr.push(DisplayShipperOfferingBid)
translationsArr.push(DisplayShipperShippingDetails)
translationsArr.push(DisplayShipperTop)
translationsArr.push(DisplayShipperConfirmBooking)
translationsArr.push(DisplayShipperRegister)
translationsArr.push(DisplayShipperSignIn)
translationsArr.push(DisplayShipperSearchResults)
translationsArr.push(DisplayShipperInvoice)
translationsArr.push(DisplayShipperPackingList)
translationsArr.push(DisplayShipperTracing)
translationsArr.push(DisplayShipperConfirmBid)
translationsArr.push(DisplayShipperQuote)

//混載業者
translationsArr.push(DisplayFwdrTop)
translationsArr.push(DisplayFwdrBookingList)
translationsArr.push(DisplayFwdrCompleteBooking)
translationsArr.push(DisplayFwdrQuote)
translationsArr.push(DisplayFwdrDocsPreparation)
translationsArr.push(DisplayFwdrPrivateBid)
translationsArr.push(DisplayFwdrCargoTrace)
translationsArr.push(DisplayFwdrTracing)
translationsArr.push(DisplayFwdrConfirmBidCarrier)
translationsArr.push(DisplayFwdrConfirmBid)
translationsArr.push(DisplayFwdrDocuments)
translationsArr.push(DisplayFwdrDocumentsConfirm)
translationsArr.push(DisplayFwdrBidDetailsCarrier)
translationsArr.push(DisplayFwdrBidDetailsShipper)
translationsArr.push(DisplayFwdrPublicBid)

//航空会社
translationsArr.push(DisplayAirlineTop)
translationsArr.push(DisplayAirlineRegister)
translationsArr.push(DisplayAirlineBookingList)
translationsArr.push(DisplayAirlineConfirmBooking)
translationsArr.push(DisplayAirlineSpaceCheck)
translationsArr.push(DisplayAirlineSpaceControl)
translationsArr.push(DisplayAirlineAwbDetails)
translationsArr.push(DisplayAirlineUldAssign)
translationsArr.push(DisplayAirlineSetFlight)
translationsArr.push(DisplayAirlineCargoManifest)
translationsArr.push(DisplayAirlineCargoTrace)
translationsArr.push(DisplayAirlineTracing)
translationsArr.push(DisplayAirlineBidRates)
translationsArr.push(DisplayAirlineBidComfirm)
translationsArr.push(DisplayAirlineShowDetails)
translationsArr.push(DisplayAirlineCassDetails)
translationsArr.push(DisplayAirlineRecordDetails)
translationsArr.push(DisplayAirlineCassUpload)
translationsArr.push(DisplayAirlineSpecialLoad)

//マスタメンテ
translationsArr.push(DisplayMasterCompanyInfo)
translationsArr.push(DisplayMasterClient)
translationsArr.push(DisplayMasterClientReg)
translationsArr.push(DisplayJH)

//共通
translationsArr.push(DisplayAuthentication)
translationsArr.push(CommonMessage)
// 入力制約
translationsArr.push(ValidationCheckMessage)
// 共通
translationsArr.push(DisplayAuthentication)

//通知
translationsArr.push(DisplayNotifyList)
translationsArr.push(DisplayNotificationDetails)

// 初期値のundefinedに対応
const undefunedWord = {
  undefined: {
    en: '',
    ja: '',
  },
}
translationsArr.push(undefunedWord)

// 登録した単語を成形
for (const translations of translationsArr) {
  for (const wordName in translations) {
    for (const language in translations[wordName]) {
      resources[language]['translation'][wordName] =
        translations[wordName][language]
    }
  }
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: Common.LANGUAGE.EN,
    debug: false,

    interpolation: {
      escapeValue: false,
    },
  })
i18n.changeLanguage(Common.LANGUAGE.EN)

export default i18n
