import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import AppContext from '../../../../../contexts/AppContext'
import Content from '../03AccordionCarFlightMgmt/Content'

/**
 * エリアコード欄
 * @returns {JSX.Element} エリアコード欄
 */
const Area = () => {
  const { t } = useTranslation()
  const { state } = useContext(AppContext)
  /** @type {Number} 最後のDstId */
  const dstId = state.Popup.editData.routingArr.slice(-1)[0].dstId

  return useMemo(() => {
    /** @type {Object} 空港情報 */
    const airport = state.Airport.getAirportInfo(dstId)

    /** @type {String} エリアコード */
    const areaCode = state.CountryCode.getAreaCode(airport?.internationalId)

    return (
      <Content
        title={t('D301V0134')}
        content={areaCode ? `TC${areaCode}` : null}
      />
    )
  }, [dstId, state.Airport, state.CountryCode, t])
}
export default Area
