import { LIGHT_BOOKING_REQUEST } from '../../../actions'

/** @type {Object} 初期表示のテーブル */
const initCargoDetail = []

/**
 * テーブルデータを保持
 * @param { Object } [state = initCargoDetail] テーブルデータ
 * @param { Object } action action
 * @return { Object } テーブルデータ
 */
const Tables = (state = initCargoDetail, action) => {
  switch (action.type) {
    case LIGHT_BOOKING_REQUEST.CARGO_DETAILS.SET:
      return action.cargoDetailArr
    default:
      return state
  }
}

export default Tables

export { initCargoDetail }
