import { Grid } from '@material-ui/core'
import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import { LIGHT_FLIGHT_MGMT } from '../../../../../../actions'
import AppContext from '../../../../../../contexts/AppContext'
import AddButton from '../../../../../common/buttons/AddButton'
import Content from '../../03AccordionCarFlightMgmt/Content'
import FlightScheduleContent from './FlightScheduleContent'
import FlightScheduleHeader from './FlightScheduleHeader'

const useStyles = makeStyles((theme) => ({
  remarkText: theme.textNormalRED,
}))

/**
 * @param {*} props 下記内容のProps
 * @param {Number} props.routingIndex Routingのインデックス
 * @returns {JSX} Routing1つ分のFlightSchedule欄
 */
const FlightSchedule = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { routingIndex } = props
  const { state, dispatch } = useContext(AppContext)
  /** @type {Object[]} FlightSchedule情報の配列 */
  const flightScheduleArr = state.Popup.getRoutingParam(
    routingIndex,
    'flightScheduleArr'
  )

  return useMemo(() => {
    /**
     * FlightScheduleを1行新規追加
     * @returns {void} なし
     */
    const addFlightSchedule = () => {
      dispatch({
        type: LIGHT_FLIGHT_MGMT.ADD.FLIGHT_SCHEDULE,
        routingIndex,
      })
    }

    return (
      <Content
        title={
          <>
            <div>{t('D301V0198')}</div>
            <div className={classes.remarkText}>*{t('D301V0251')}</div>
          </>
        }
        content={
          <Grid
            container
            spacing={2}
            justify="space-between"
            alignContent="center"
            alignItems="center"
          >
            <FlightScheduleHeader />
            {flightScheduleArr.map((_, index) => (
              <FlightScheduleContent
                routingIndex={routingIndex}
                index={index}
                key={index}
              />
            ))}
            <Grid container item justify="flex-end">
              {state.Auth ? <AddButton onClick={addFlightSchedule} /> : null}
            </Grid>
          </Grid>
        }
      />
    )
    //eslint-disable-next-line
  }, [flightScheduleArr.length, dispatch, routingIndex, t])
}

FlightSchedule.propTypes = {
  routingIndex: PropTypes.number.isRequired,
}
export default FlightSchedule
