import { combineReducers } from 'redux'

import Paging from './Paging'
import SelectBox from './SelectBox'
import Tables from './Tables'

export default combineReducers({
  Paging,
  SelectBox,
  Tables,
})
