import React from 'react'
import Grid from '@material-ui/core/Grid'

import Alert from './Alert'
// import Help from './Help'
import Account from './Account'

const Menu = () => {
  return (
    <Grid container justify="flex-end" alignItems="center">
      <Grid item>
        <Account />
        {/* <Help /> */}
        <Alert />
      </Grid>
    </Grid>
  )
}

export default Menu
