import { LIGHT_BOOKING_DETAILS } from '../../../actions'
import CommonCalc from '../../../components/common/function/CommonCalc'
import Common from '../../../constants/Common'

/**
 * @typedef {Object} Rate レート情報
 * @property {Number} laneId
 * @property {Number} minrate
 * @property {Number} normal
 * @property {Number} min
 * @property {Number} light
 * @property {Number} medium
 * @property {Number} large
 * @property {Number} max
 * @property {Number} fuelSurCharge
 * @property {Number} rate
 * @property {Number} allInRate
 * @property {String} updatedAt
 */

/** @type{Object} 重量帯と文言のオブジェクト */
const rateNameKey = {
  max: { wgt: 1000, key: 'D301V0095' },
  large: { wgt: 500, key: 'D301V0094' },
  medium: { wgt: 300, key: 'D301V0093' },
  light: { wgt: 100, key: 'D301V0092' },
  min: { wgt: 45, key: 'D301V0091' },
  normal: { wgt: 0, key: 'D301V0089' },
}

/**
 * 重量帯の文言キーを取得する
 * @param {Number} chargeableWgt 貨物のChargeableWgt
 * @returns {String} 重量帯の文言キー
 */
const getRateNameKey = (chargeableWgt) => {
  return Object.values(rateNameKey).find(({ wgt }) => chargeableWgt >= wgt).key
}

/**
 * 各関数を取得する
 * @param {Rate} rateObj レート情報
 * @returns {Object} 各関数を格納したオブジェクト
 */
const getFunctions = (rateObj) => {
  const {
    minrate = 0.0,
    normal = 0.0,
    min = null,
    light = null,
    medium = null,
    large = null,
    max = null,
    fuelSurCharge = 0.0,
    rate: inputRate = null,
  } = rateObj

  /**
   * 適用レートを取得
   * @param {Number} chargeableWgt 貨物のChargeableWgt
   * @returns {Number} 適用レート(数値)
   */
  const getApplyRate = (chargeableWgt) => {
    const isInputRate = inputRate !== null
    return isInputRate ? inputRate : getRate(chargeableWgt)
  }
  /**
   * 適用レートを取得
   * @param {Number} chargeableWgt 貨物のChargeableWgt
   * @returns {String} 適用レート(文字列)
   */
  const getApplyRateStr = (chargeableWgt) => {
    const applyRate = getApplyRate(chargeableWgt)
    return CommonCalc.addComma(applyRate, true)
  }
  /**
   * レートを取得
   * @param {Number} chargeableWgt 貨物のChargeableWgt
   * @returns {Number} レート(数値)
   */
  const getRate = (chargeableWgt) => {
    return CommonCalc.rate(
      chargeableWgt,
      normal,
      min,
      light,
      medium,
      large,
      max
    )
  }
  /**
   * レートを取得
   * @param {Number} chargeableWgt 貨物のChargeableWgt
   * @returns {String} レート(文字列)
   */
  const getRateStr = (chargeableWgt) => {
    const rate = getRate(chargeableWgt)
    return CommonCalc.addComma(rate, true)
  }
  /**
   * 合計レートを取得
   * @param {Number} chargeableWgt 貨物のChargeableWgt
   * @returns {String} 合計レート(文字列)
   */
  const getTotalRateStr = (chargeableWgt) => {
    const isInputRate = inputRate !== null
    let rate = 0
    if (isInputRate) {
      // A/Fが手入力されている場合、手入力A/F * TotalC/W + FSC * TotalC/W
      rate = inputRate * chargeableWgt + fuelSurCharge * chargeableWgt
    } else {
      // A/Fが手入力されていない場合、A/F * TotalC/W と Min料金の大きい方を計算
      // 上記の計算結果 + FSC * TotalC/Wを計算
      const airFreight = getApplyRate(chargeableWgt) * chargeableWgt
      rate = Math.max(airFreight, minrate) + chargeableWgt * fuelSurCharge
    }
    return CommonCalc.addComma(rate, true)
  }

  /**
   * TotalA/Fが10桁を超えているか判定を返す
   * @param {Number} chargeableWgt 貨物のChargeableWgt
   * @returns {Boolean} 超えている場合true
   */
  const getIsOverTotalAirFreightDigit = (chargeableWgt) => {
    const totalAirFreight = getTotalRateStr(chargeableWgt)
    const totalAirFreightLength = totalAirFreight
      .replaceAll(',', '')
      .split('.')[0].length
    return totalAirFreightLength > 10
  }

  /**
   * A/FがMin料金より小さいか判定
   * @param {Number} chargeableWgt 貨物のChargeableWgt
   * @returns {Boolean} Min料金より小さい場合true
   */
  const getIsLessThanMinRate = (chargeableWgt) => {
    const isInputRate = inputRate !== null
    if (isInputRate) {
      const airFreight = inputRate * chargeableWgt
      return airFreight < minrate
    } else {
      return false
    }
  }

  return {
    getApplyRate,
    getApplyRateStr,
    getRate,
    getRateStr,
    getTotalRateStr,
    getIsOverTotalAirFreightDigit,
    getIsLessThanMinRate,
  }
}

/** @type {Object} ポップアップの初期データ */
const initRate = {
  rateObj: {},
  ...getFunctions({}),
  getRateNameKey,
}

/**
 * レートを数値と文字列に直す
 * @param {String} rateName レート名
 * @param {Number|String} rate レート
 * @returns {Object} 数値と文字列に直したレート
 */
const fixRate = (rateName, rate) => {
  let num = null
  if (typeof rate === 'string' && rate !== '') {
    num = rate.replaceAll(',', '')
    num = parseFloat(num)
  }

  let str = ''
  if (typeof num === 'number') {
    str = parseFloat(rate)
    str = CommonCalc.addComma(rate, true)
  }

  return { [rateName]: num, [`${rateName}Str`]: str }
}

/**
 * レートオブジェクトの中身を数値と文字列に直す
 * @param {Rate} rateObj レートオブジェクト
 * @returns {Object} 数値と文字列に直したレートオブジェクト
 */
const fixRateObj = (rateObj) => {
  const rateNameArr = [
    'minrate',
    'normal',
    'min',
    'light',
    'medium',
    'large',
    'max',
    'rate',
    'fuelSurCharge',
  ]
  const fixedObj = rateNameArr.reduce(
    (obj, rateName) => ({ ...obj, ...fixRate(rateName, rateObj[rateName]) }),
    {}
  )

  return {
    ...fixedObj,
    laneId: rateObj.laneId,
    currency: rateObj.currency,
    allInRate: rateObj.allInRate,
  }
}

/**
 * レートを保持
 * @param {Object} action 編集したいデータ
 * @param {Object} state stateの値
 * @returns {Object} レート情報
 */
const setRate = (action, state) => {
  const { rateObj } = action
  const fixedRateObj = fixRateObj(rateObj)
  const isAllInRate = rateObj.allInRate === Common.ALL_IN_RATE.ON
  return {
    ...state,
    isAllInRate,
    rateObj: fixedRateObj,
    ...getFunctions(fixedRateObj),
  }
}

/**
 * レートを編集
 * @param {Object} action 編集したいデータ
 * @param {Object} state stateの値
 * @returns {Object} state
 */
const editRate = (action, state) => {
  // 編集する
  const inputRateNum = Object.entries(action).reduce(
    (obj, [key, rate]) => ({ ...obj, ...fixRate(key, rate) }),
    {}
  )

  state.rateObj = { ...state.rateObj, ...inputRateNum }

  const inputError = Object.values(action).some((v) => v === null)

  return { ...state, ...getFunctions(state.rateObj), inputError }
}

/**
 * レートの編集データを保持
 * @param { Object } [state = initRate] state
 * @param { Object } action action
 * @return { Object } state
 */
const Rate = (state = initRate, action) => {
  const { type, ...rest } = action
  switch (type) {
    case LIGHT_BOOKING_DETAILS.RATE.SET_RATE:
      return setRate(rest, state)
    case LIGHT_BOOKING_DETAILS.RATE.EDIT_RATE:
      return editRate(rest, state)
    default:
      return state
  }
}

export default Rate

export { initRate }
