import { TRANSIT_TIME } from '../../actions'

/**
 * @typedef {Object} TransitTime 輸送期間情報
 * @property {String} createdAt 登録日時
 * @property {Number} delFlg 削除フラグ
 * @property {String} time 輸送期間
 * @property {Number} timeId 輸送期間ID
 * @property {String} updatedAt 更新日時
 */

// exportするために先に定義
let getTotalTransitTime = () => {}

/**
 * @type {Object} 初期値
 * @property {TransitTime[]} transitTime 航空会社名一覧
 * @property {Function} getTotalTransitTime 輸送期間の合計を取得する関数
 * @property {Function} getTransitTime 輸送期間を取得する関数
 */
const initTransitTime = {
  transitTime: [],
  getTotalTransitTime,
  getTransitTime: () => {},
}

/**
 * 輸送期間情報を保持する
 * @param {Object} action action
 * @param {TransitTime[]} action.data 輸送期間情報の配列
 * @returns {Object} state
 */
const setTransitTime = (action) => {
  const { data } = action

  /**
   * TransitTimeの合計値を取得
   * @param {Number[]} timeIdArr TransitTimeIdの配列
   * @returns {TransitTime} 適用するTransitTime情報
   */
  getTotalTransitTime = (timeIdArr) => {
    /** @type {RegExp} 数値の正規表現 */
    const regex = /[^0-9]/g

    /** @type {Number[]} 所要日数の配列 */
    const days = timeIdArr.map((timeId) => {
      /** @type {String}  所要日数の文字列から数字のみ抽出した値 */
      const day = getTransitTime(timeId)?.time.replace(regex, '')
      return parseInt(day?.slice(-1))
    })

    /** @type {Number} 合計所要日数 */
    const totalDays = days.reduce((sum, day) => sum + day, 0)

    // 適用する所要日数情報を取得して返す
    return data.find((v) => v.time.includes(totalDays)) ?? data[data.length - 1]
  }

  /**
   * timeIdから所要日数を取得
   * @param {Number} timeId 所要日数ID
   * @returns {TransitTime} 所要日数情報
   */
  const getTransitTime = (timeId) =>
    data.find((v) => v.timeId === timeId) ?? null

  return {
    transitTime: data,
    getTotalTransitTime,
    getTransitTime,
  }
}

/**
 * state: TransitTimeリストを保存
 * @param {Array} [state=initTransitTime] - state
 * @param {Object} action - action
 * @returns {Object} - state
 */
const TransitTime = (state = initTransitTime, action) => {
  switch (action.type) {
    case TRANSIT_TIME.SET:
      return setTransitTime(action)
    default:
      return state
  }
}

export default TransitTime

export { initTransitTime, getTotalTransitTime }
