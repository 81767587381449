import { combineReducers } from 'redux'

import ShippingDetails, { initShippingDetails } from './ShippingDetails'
import cargoDetailArr, { initCargoDetail } from './CargoDetail'
import Tables, { initTableData } from './Tables'
import Paging, { initPaging } from '../../common/Paging'
import SearchSort, { initSearchSort } from '../../common/SearchSort'

const initialState = {
  ShippingDetails: initShippingDetails,
  cargoDetailArr: initCargoDetail,
  Tables: initTableData,
  Paging: initPaging,
  SearchSort: initSearchSort,
}

export default combineReducers({
  ShippingDetails,
  cargoDetailArr,
  Tables,
  Paging,
  SearchSort,
})

export { initialState }
