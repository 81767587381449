import { LIGHT_BOOKING_DETAILS } from '../../../actions'

/** @type {Object} 初期表示 */
const initFlight = {
  flightArr: [],
  flightChangeInfoA: undefined,
  flightChangeInfoB: undefined,
  flightChangeInfoC: undefined,
}

/**
 * 航空便情報を保持
 * @param {Object} action action
 * @param {Object} state state
 * @return {Object} 航空便情報
 */
const setFlight = (action, state) => {
  const {
    flightArr,
    flightChangeInfoA = null,
    flightChangeInfoB = null,
    flightChangeInfoC = null,
  } = action

  const fixedFlightArr = flightArr.map((flightInfo) => {
    const {
      flightNumber,
      orgCode,
      dstCode,
      eta,
      etd,
      orgAirport,
      dstAirport,
      iconPath,
    } = flightInfo

    /** @type {String} 航空会社の2桁コード */
    const twoCode = flightNumber.slice(0, 2)
    /** @type {String} 航空便番号 */
    const number = flightNumber.slice(2)

    const info = `${twoCode} ${number} / ${orgCode} - ${dstCode}`

    /** @type {String[]} Flight欄のタイムラインに表示する文字列の配列 */
    const line = [
      `${etd} ${orgAirport} (${orgCode})`,
      `${eta} ${dstAirport} (${dstCode})`,
    ]
    return { line, info, iconPath }
  })

  const orgId = flightArr[0].orgId
  const dstId = flightArr.at(-1).dstId
  const viaOneId = flightArr[1]?.orgId ?? null
  const viaTwoId = flightArr[2]?.orgId ?? null
  const flightIdA = flightArr[0].flightId
  const flightIdB = flightArr[1]?.flightId ?? null
  const flightIdC = flightArr[2]?.flightId ?? null

  return {
    ...state,
    flightIdA,
    flightIdB,
    flightIdC,
    orgId,
    dstId,
    viaOneId,
    viaTwoId,
    flightChangeInfoA: flightChangeInfoA === null ? '' : flightChangeInfoA,
    flightChangeInfoB: flightChangeInfoB === null ? '' : flightChangeInfoB,
    flightChangeInfoC: flightChangeInfoC === null ? '' : flightChangeInfoC,
    flightArr: fixedFlightArr,
  }
}

/**
 * 航空便情報を保持
 * @param {Boolean} [state=initFlight] state
 * @param {Object} action action
 * @return {Boolean} チェックボックス状態
 */
const Flight = (state = initFlight, action) => {
  const { type, ...rest } = action
  switch (type) {
    case LIGHT_BOOKING_DETAILS.FLIGHT.SET:
      return setFlight(rest, state)
    case LIGHT_BOOKING_DETAILS.FLIGHT.EDIT_INTER_LINE:
      return { ...state, ...rest }
    default:
      return state
  }
}

export default Flight

export { initFlight }
