import React, { useContext } from 'react'

import AppContext from '../../../../../contexts/AppContext'
import TableRow from '../../../../common/table/TableRow'
import TableCellsLightBookingRequest from './TableCellsLightBookingRequest'

/**
 * useContextのstateから表全体のデータを作成
 * @return {JSX} TableRow ~ TableCellまでのコンポーネント
 */
const TableContentsLightBookingRequest = () => {
  const { state } = useContext(AppContext)

  return state.cargoDetailArr.map((_, index) => (
    <TableRow key={index} color={'transparent'}>
      <TableCellsLightBookingRequest index={index} />
    </TableRow>
  ))
}

export default TableContentsLightBookingRequest
