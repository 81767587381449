import React from 'react'
import { useTranslation } from 'react-i18next'

import ConstantsSelectForwarder from '../ConstantsSelectForwarder'
import TableCell from '../../../../common/table/TableCell'
import TableRow from '../../../../common/table/TableRow'

/**
 * 定数から作成するヘッダーコンポーネント(1列分)
 * 変更したい場合は定数ConstantsBidMgmtを操作する
 * @return {JSX} ヘッダーコンポーネント(1列分)
 */
const HeaderSelectForwarder = () => {
  const { t } = useTranslation()

  /**
   * 定数で定めたヘッダーコンポーネント(1個分)を作成
   * @param {String} { headerKey } 定数に設定している文言key
   * @return {JSX} ヘッダーコンポーネント(1個分)
   */
  const createHeaderCell = ({ headerKey }) => {
    return <TableCell key={headerKey}> {t(headerKey)} </TableCell>
  }

  return (
    <TableRow>
      {ConstantsSelectForwarder.TABLE_ARR.map(createHeaderCell)}
    </TableRow>
  )
}

export default HeaderSelectForwarder
