import { TABS } from '../../actions'

/** 初期表示ページ */
const initTabs = 0

const setTabsIndex = (action) => {
  const { select } = action
  return select
}

/**
 * 表データとAPIを保持
 * @param {Object} [state=initTablesLightJhClientRegSignUp] 表データとAPI
 * @param {Object} action 表データの検索に必要な値
 * @return {Object} 表データとAPI
 */
const Tabs = (state = initTabs, action) => {
  switch (action.type) {
    case TABS.SET:
      return setTabsIndex(action)
    default:
      return state
  }
}

export default Tabs

export { initTabs }
