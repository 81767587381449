import { combineReducers } from 'redux'

import Paging, { initPaging } from '../../common/Paging'
import Tables, { initTableData } from './Tables'
import Airport, { initAirport } from '../../common/Airport'
import TransitTime, { initTransitTime } from '../../common/TransitTime'
import CountryCode, { initCountryCode } from '../../common/CountryCode'
import Popup, { initPopup } from './Popup'
import Day, { initDay } from '../../common/Day'
import Tabs, { initTabs } from '../../common/Tabs'
import SearchSort, { initSearchSort } from '../../common/SearchSort'
import Auth, { initAuth } from '../../common/Auth'
import Gsa, { initGsa } from '../../common/Gsa'
import CarrierName, { initCarrierName } from '../../common/CarrierName'
import Checkbox, { initCheckbox } from './Checkbox'
import IconPopup, { initIconPopup } from './IconPopup'

const initialState = {
  Paging: initPaging,
  Tables: initTableData,
  Airport: initAirport,
  TransitTime: initTransitTime,
  CountryCode: initCountryCode,
  Popup: initPopup,
  Day: initDay,
  Tabs: initTabs,
  SearchSort: initSearchSort,
  Auth: initAuth,
  Gsa: initGsa,
  CarrierName: initCarrierName,
  Checkbox: initCheckbox,
  IconPopup: initIconPopup,
}

export default combineReducers({
  Paging,
  Tables,
  Airport,
  TransitTime,
  CountryCode,
  Popup,
  Day,
  Tabs,
  SearchSort,
  Auth,
  Gsa,
  CarrierName,
  Checkbox,
  IconPopup,
})

export { initialState }
