import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import AppContext from '../../../contexts/AppContext'
import HeaderText from '../../common/text/HeaderText'

const useStyles = makeStyles((theme) => ({
  head: theme.textBoldBLU,
  textBlk: {
    ...theme.textNormalBLK,
    marginLeft: '1rem',
    wordWrap: 'break-word',
  },
}))

const BranchCode = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { state } = useContext(AppContext)
  const { companyinfo } = state
  const cityCode = companyinfo.cityCode.value

  return (
    <Grid container spacing={3}>
      <Grid container item spacing={2}>
        <Grid item md={12} xs={12}>
          <HeaderText className={classes.head}>{t('D301V0012')}</HeaderText>
          <div className={classes.textBlk}>{cityCode}</div>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default BranchCode
