/* eslint-disable no-extra-parens */
import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { useHistory } from 'react-router-dom'

import AppContext from '../../../../../contexts/AppContext'
import {
  UPDATE_FWDR_COMPANY_NAME,
  UPDATE_FWDR_POSTAL_CODE,
  UPDATE_FWDR_COMPANY_ADDRESS,
  UPDATE_FWDR_FIRST_NAME,
  UPDATE_FWDR_LAST_NAME,
  UPDATE_FWDR_POSITION,
  UPDATE_FWDR_MAIL,
  UPDATE_FWDR_TEL,
  UPDATE_FWDR_ICON_PATH,
  UPDATE_FWDR_IATACASS_NO,
  UPDATE_FWDR_BACKUP,
  UPDATE_FWDR_THREE_LETTER_CODE,
  UPDATE_FWDR_UPDATE_AT,
  UPDATE_FWDR_FILE,
  UPDATE_BOND_IN_ADDRESS,
  UPDATE_FWDR_BRANCH,
  UPDATE_FWDR_BRANCH_CODE,
  UPDATE_FWDR_AIRPORT,
  UPDATE_FWDR_REPRESENTATIVE_FLG,
  UPDATE_FWDR_AUTHORITY_FLG,
} from '../../../../../actions'
import Paths from '../../../../../constants/Paths'
import SaveAndBack from '../../../../common/buttons/SaveAndBack'
import BackButton from '../../../../common/buttons/BackButton'
import ApiPaths from '../../../../../constants/ApiPaths'
import CommonFunc from '../../../../common/function/CommonFunc'
import Theme from '../../../../common/theme/Theme'

const useStyles = makeStyles(() => ({
  buttons: {
    justifyContent: 'center',
    marginBottom: 30,
    marginTop: 30,
  },
  down: {
    marginBottom: 30,
    margin: 'auto',
  },
  red: Theme.textNormalRED,
}))

const ButtonLightFwdrCompanyInfo = () => {
  const classes = useStyles()
  const history = useHistory()
  const [saveComp, setSaveComp] = useState(false)
  const [errCode, setErrCode] = useState(0)
  const { state, dispatch } = useContext(AppContext)
  const representativeFlg = state.companyinfo.representativeFlg.value
  const authorityFlg = state.companyinfo.authorityFlg.value

  // Saveボタンの有効無効判定
  const chkSaveDisable = () =>
    Object.values(state.companyinfo).some(({ inputErr }) => inputErr === true)

  // 入力変更ありなし判定
  const chkInputChg = () => {
    const sta = state.companyinfo
    const bk = state.backup
    if (
      bk.companyName !== sta.companyName.value.name ||
      bk.postalCode !== sta.postalCode.value ||
      bk.address !== sta.address.value ||
      bk.bondInAddress !== sta.bondInAddress.value ||
      bk.firstName !== sta.firstName.value ||
      bk.lastName !== sta.lastName.value ||
      bk.position !== sta.position.value ||
      bk.mail !== sta.mail.value ||
      bk.tel !== sta.tel.value ||
      bk.iataOrcassNumber !== sta.iataOrcassNumber.value ||
      bk.icon !== sta.icon.value ||
      bk.fwdrPlanId !== sta.fwdrPlanId.value
    ) {
      return true
    }
    return false
  }

  // 保存完了後の処理
  const saveComplete = async () => {
    setSaveComp(false)
    // 保存に成功したらデータ再取得
    const apiInfoArr = [
      {
        key: 'companyInfoArr',
        method: 'get',
        path: ApiPaths.MASTER_FWDR.LIGHT_FWDR_COMPANY_INFO,
      },
    ]
    const resObj = await CommonFunc.execApiAsync(apiInfoArr)
    const isApiSuccessful = CommonFunc.checkApiResponseObj(resObj, history)
    if (isApiSuccessful) {
      const res = resObj.companyInfoArr.data.results
      setCompanyInfoCB(res)
    }
  }

  // 保存処理
  const saveData = async () => {
    let iataChanged = state.companyinfo.iataOrcassNumber.value
    if (state.backup.iataOrcassNumber === iataChanged) {
      iataChanged = ''
    }
    const req = {
      fwdrNameId: state.companyinfo.companyName.value.code,
      fwdrthreeCode: state.companyinfo.fwdrthreeCode.value,
      postalCode: state.companyinfo.postalCode.value,
      address: state.companyinfo.address.value,
      bondInAddress: state.companyinfo.bondInAddress.value,
      tel: state.companyinfo.tel.value,
      lastName: state.companyinfo.lastName.value,
      firstName: state.companyinfo.firstName.value,
      position: state.companyinfo.position.value,
      mail: state.companyinfo.mail.value,
      iataOrcassNumber: iataChanged,
      fwdrPlanId: state.companyinfo.fwdrPlanId.value,
      updatedAt: state.companyinfo.updatedAt.value,
      file: state.file.file,
      isDeleteIcon: state.companyinfo.isDeleteIcon.value,
      url: state.companyinfo.iconPath.value,
      representativeFlg,
    }
    // API実行
    const apiInfoArr = [
      {
        key: 'resData',
        method: 'post',
        path: ApiPaths.MASTER_FWDR.LIGHT_FWDR_COMPANY_INFO,
        req,
      },
    ]
    const resObj = await CommonFunc.execApiAsync(apiInfoArr)
    const isApiSuccessful = CommonFunc.checkApiResponseObj(resObj, history)
    setErrCode(resObj.resData.data.info.ErrorCode)
    if (isApiSuccessful) {
      setSaveComp(true)
    }
  }

  const setCompanyInfoCB = (res) => {
    dispatch({
      type: UPDATE_FWDR_COMPANY_NAME,
      companyName: res.companyName,
      fwdrNameId: res.fwdrNameId,
      fwdrthreeCode: res.fwdrthreeCode,
    })
    dispatch({
      type: UPDATE_FWDR_POSTAL_CODE,
      postalCode: res.postalCode,
    })
    dispatch({
      type: UPDATE_FWDR_COMPANY_ADDRESS,
      address: res.address,
    })
    dispatch({
      type: UPDATE_BOND_IN_ADDRESS,
      address: res.bondInAddress ?? '',
    })
    dispatch({
      type: UPDATE_FWDR_BRANCH,
      cityName: res.cityName,
    })
    dispatch({
      type: UPDATE_FWDR_BRANCH_CODE,
      cityCode: res.cityCode,
    })
    dispatch({
      type: UPDATE_FWDR_AIRPORT,
      airport: res.airport,
    })
    dispatch({
      type: UPDATE_FWDR_REPRESENTATIVE_FLG,
      representativeFlg: res.representativeFlg,
    })
    dispatch({
      type: UPDATE_FWDR_AUTHORITY_FLG,
      authorityFlg: res.authorityFlg,
    })
    dispatch({
      type: UPDATE_FWDR_FIRST_NAME,
      firstName: res.firstName,
    })
    dispatch({
      type: UPDATE_FWDR_LAST_NAME,
      lastName: res.lastName,
    })
    dispatch({
      type: UPDATE_FWDR_POSITION,
      position: res.position,
    })
    dispatch({
      type: UPDATE_FWDR_MAIL,
      mail: res.mail,
    })
    dispatch({
      type: UPDATE_FWDR_TEL,
      tel: res.tel,
    })
    dispatch({
      type: UPDATE_FWDR_IATACASS_NO,
      iataOrcassNumber: res.iataOrcassNumber,
    })
    dispatch({
      type: UPDATE_FWDR_ICON_PATH,
      icon: res.icon,
    })
    dispatch({
      type: UPDATE_FWDR_THREE_LETTER_CODE,
      fwdrthreeCode: res.fwdrthreeCode,
    })
    dispatch({
      type: UPDATE_FWDR_UPDATE_AT,
      updatedAt: res.updatedAt,
    })
    dispatch({
      type: UPDATE_FWDR_FILE,
      file: res.file,
    })

    // 初回データをBackup
    dispatch({
      type: UPDATE_FWDR_BACKUP,
      info: res,
    })

    // 先頭にスクロール
    window.scrollTo(0, 0)
  }

  // Backコンポーネントに渡したいものをまとめる
  const backFactors = {
    path: Paths.MASTER.FWDR_MASTER_TOP,
    inputchg: chkInputChg(),
    clickevent: '',
  }

  // Saveコンポーネントに渡したいものをまとめる
  const saveFactors = {
    path: Paths.MASTER.LIGHT_FWDR_COMPANY_INFO,
    saveevent: saveData,
    comp: saveComp,
    compevent: saveComplete,
    disabled: chkSaveDisable(),
    errcode: errCode,
  }

  return (
    <Grid
      container
      justify="center"
      direction="column"
      alignItems="center"
      className={classes.down}
    >
      {authorityFlg ? (
        <Grid item md={10} xs={10} className={classes.buttons}>
          <SaveAndBack backFactors={backFactors} saveFactors={saveFactors} />
        </Grid>
      ) : (
        <Grid item md={10} xs={10} className={classes.buttons}>
          <BackButton path={Paths.MASTER.FWDR_MASTER_TOP} />
        </Grid>
      )}
    </Grid>
  )
}

export default ButtonLightFwdrCompanyInfo
