import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { useHistory } from 'react-router-dom'

import Paths from '../../../../constants/Paths'
import Title from '../../../common/title/Title'
import BackButton from '../../../common/buttons/BackButton'
import LayoutBox from '../../../common/layout/LayoutBox'
import CompanyName from './CompanyName'
import PostalCode from './PostalCode'
import CompanyAddress from './CompanyAddress'
import Representative from './Representative'
import ContactInfo from './ContactInfo'
import Icon from './Icon'
import ApiPaths from '../../../../constants/ApiPaths'
import CommonFunc from '../../../common/function/CommonFunc'
import restFacade from '../../../../actions/rest-facade'

const useStyles = makeStyles(() => ({
  buttons: {
    justifyContent: 'center',
    marginBottom: 30,
    marginTop: 30,
  },
  down: {
    marginBottom: 30,
    margin: 'auto',
  },
}))

const MasterJHCompanyInfoG = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const [companyName, setCompanyName] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [address, setAddress] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [mail, setMail] = useState('')
  const [tel, setTel] = useState('')
  const [iconPath, setIconPath] = useState('')

  let setData = () => {}

  const setCompanyInfoCB = (response) => {
    const companyName = response.data.results.companyName
    const postalCode = response.data.results.postalCode
    const address = response.data.results.address
    const firstName = response.data.results.firstName
    const lastName = response.data.results.lastName
    const mail = response.data.results.mail
    const tel = response.data.results.tel
    const iconPath = response.data.results.icon

    setCompanyName(companyName)
    setPostalCode(postalCode)
    setAddress(address)
    setFirstName(firstName)
    setLastName(lastName)
    setMail(mail)
    setTel(tel)
    setIconPath(iconPath)
  }

  // エラー処理、コールバック関数実行
  setData = (res, callback) => {
    CommonFunc.callbackFunc(res, callback, history)
  }

  // データ取得
  useEffect(() => {
    restFacade.get(ApiPaths.MASTER_JH.COMPANY_INFO, (res) =>
      setData(res, setCompanyInfoCB)
    )
  }, [])

  return (
    <LayoutBox>
      <Title>{t('D301V0001')}</Title>
      <Grid container justify="center">
        <Grid item style={{ marginBottom: '3rem' }} md={10} xs={10}>
          <CompanyName companyName={companyName} />
        </Grid>
        <Grid item style={{ marginBottom: '3rem' }} md={10} xs={10}>
          <PostalCode postalCode={postalCode} />
        </Grid>
        <Grid item style={{ marginBottom: '3rem' }} md={10} xs={10}>
          <CompanyAddress address={address} />
        </Grid>
        <Grid item style={{ marginBottom: '3rem' }} md={10} xs={10}>
          <Representative firstName={firstName} lastName={lastName} />
        </Grid>
        <Grid item style={{ marginBottom: '3rem' }} md={10} xs={10}>
          <ContactInfo mail={mail} tel={tel} />
        </Grid>
        <Grid item style={{ marginBottom: '3rem' }} md={10} xs={10}>
          <Icon iconPath={iconPath} />
        </Grid>
      </Grid>
      <Grid
        container
        justify="center"
        direction="column"
        alignItems="center"
        className={classes.down}
      >
        <Grid item md={10} xs={10} className={classes.buttons}>
          <BackButton path={Paths.MASTER.JH_MASTER_TOP} />
        </Grid>
      </Grid>
    </LayoutBox>
  )
}

export default MasterJHCompanyInfoG
