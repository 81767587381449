import { PAGING_DATA } from '../../../actions'

/**
 * state: 現在のページ番号を保存
 * @param {Object} [state={}] -
 * @param {Object} action -
 * @returns {*} -
 */
const Paging = (state = {}, action) => {
  switch (action.type) {
    //項目表示件数
    case PAGING_DATA:
      // eslint-disable-next-line no-case-declarations
      const data = { page: action.page }
      return data
    default:
      return state
  }
}

export default Paging
