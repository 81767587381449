import React from 'react'
import PropTypes from 'prop-types'
import { Grid, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  text: theme.textNormalBLK,
  wordBreak: theme.wordBreak,
}))

/**
 * @param {Object} props - 以下
 * @param {String} text - 一行分のテキスト
 * @return {JSX} 荷主アドレス欄の一行分のテキストコンポーネント
 */
const DetailsCompanyInfoText = ({ text }) => {
  const classes = useStyles()

  return (
    <Grid item md={12} xs={12}>
      <div className={`${classes.text} ${classes.wordBreak}`}>{text}</div>
    </Grid>
  )
}

DetailsCompanyInfoText.propTypes = {
  text: PropTypes.string.isRequired,
}

export default DetailsCompanyInfoText
