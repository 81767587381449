import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import Paths from '../../../../../constants/Paths'
import AppContext from '../../../../../contexts/AppContext'
import XsmallButton from '../../../../common/buttons/XsmallButton'
import CommonFunc from '../../../../common/function/CommonFunc'

const useStyles = makeStyles((Theme) => ({
  button: {
    color: Theme.palette.primary.main,
    borderColor: Theme.palette.primary.main,
  },
}))

/**
 * @param {object} props 下記内容
 * @param {Number} fwdrNameId 混載業者名Id
 * @return {JSX} EditボタンまたはShowボタン
 */
const TableButtonLightCarSelectForwarder = (props) => {
  const history = useHistory()
  const classes = useStyles()
  const { t } = useTranslation()
  const { state } = useContext(AppContext)

  /**
   * ボタン押下時のイベント
   * @returns {void} なし
   */
  const onClick = () => {
    const clickEventObj = {
      path: Paths.MASTER.LIGHT_CAR_AIR_FREIGHT,
      state: props,
    }
    CommonFunc.clickEvent(clickEventObj, history)
  }

  /** @type {String} ボタンに表示するテキスト */
  const text = state.Auth ? t('D004V0061') : t('D301V0240')

  return (
    <XsmallButton addclass={classes.button} onClick={onClick}>
      {text}
    </XsmallButton>
  )
}

TableButtonLightCarSelectForwarder.propTypes = {
  fwdrNameId: PropTypes.number.isRequired,
}

export default TableButtonLightCarSelectForwarder
