import Common from '../../../../constants/Common'

/**
 * @typedef {Object} TableInfo テーブル情報
 * @property {string} headerKey ヘッダーに表示する文言番号
 * @property {string} cellKey セルに表示するプロパティ名
 * @property {string} cellCategory Common.CELL_CATEGORYの値
 */

const ConstantsLightFwdrSearchResult = {
  /** @type {TableInfo[]} テーブル作成に必要な値 */
  TABLE_ARR: [
    { cellKey: 'icon', cellCategory: Common.CELL_CATEGORY.SEARCH_RESULT.ICON },
    { cellKey: 'date', cellCategory: Common.CELL_CATEGORY.SEARCH_RESULT.DATE },
    {
      cellKey: 'airport',
      cellCategory: Common.CELL_CATEGORY.SEARCH_RESULT.AIRPORT,
    },
    { cellKey: 'rate', cellCategory: Common.CELL_CATEGORY.SEARCH_RESULT.RATE },
    { cellKey: 'fsc', cellCategory: Common.CELL_CATEGORY.SEARCH_RESULT.FSC },
    {
      cellKey: 'total',
      cellCategory: Common.CELL_CATEGORY.SEARCH_RESULT.TOTAL,
    },
    { cellKey: 'select', cellCategory: Common.CELL_CATEGORY.BUTTON.CHECK },
  ],
  /** @type {SortInfo[]} 並び替えの値 */
  SORT_ITEM_ARR: [
    {
      sortTerm: Common.SORT_TERMS.BEST_FLIGHTS_ETD,
      wordKey: 'D009V0011',
      sortKey: 'bestFlights',
    },
    {
      sortTerm: Common.SORT_TERMS.TAKE_OFF_DATE,
      wordKey: 'D104V0068',
      sortKey: 'sortFlight',
    },
    {
      sortTerm: Common.SORT_TERMS.TRANSIT_TIME,
      wordKey: 'D301V0073',
      sortKey: '',
    },
    {
      sortTerm: Common.SORT_TERMS.FLIGHT_NUMBER_ORDER,
      wordKey: 'D206V0072',
      sortKey: 'flightNumber',
    },
    {
      sortTerm: Common.SORT_TERMS.ORG_ORDER,
      wordKey: 'D203V0072',
      sortKey: 'org',
    },
    {
      sortTerm: Common.SORT_TERMS.DST_ORDER,
      wordKey: 'D203V0073',
      sortKey: 'dst',
    },
    {
      sortTerm: Common.SORT_TERMS.RATE_ORDER,
      wordKey: '4th0011',
      sortKey: 'rate',
    },
    {
      sortTerm: Common.SORT_TERMS.FSC_CURRENCY_ORDER,
      wordKey: '2nd0017',
      sortKey: 'fuelSurcharge',
    },
    {
      sortTerm: Common.SORT_TERMS.LOW_PRICE,
      wordKey: '4th0012',
      sortKey: 'cost',
    },
  ],
  CARGO_FLG: {
    YES: 'Yes',
    NO: 'No',
  },
  PAGE_AMOUNT: 7,
}

export default ConstantsLightFwdrSearchResult
