import React, { useContext, useMemo } from 'react'

import ConstantsLightJhClientList from '../ConstantsLightJhClientList'
import Sort from '../../../../common/sort/Sort'
import AppContext from '../../../../../contexts/AppContext'
import { SEARCH_SORT, TABLES, PAGING } from '../../../../../actions'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

/**
 * @return {JSX} ソートコンポーネント
 */
const SortLightJhClientList = () => {
  const { t } = useTranslation()
  const { state, dispatch } = useContext(AppContext)

  const sortItemArr = ConstantsLightJhClientList.SORT_ITEM_ARR.map(
    ({ sortTerm, wordKey }) => ({ value: sortTerm, word: t(wordKey) })
  )

  /**
   * ソート変更時の動作
   * @param {*} event イベント
   * @returns {void} なし
   */
  const onChangeSort = (event) => {
    dispatch({
      type: SEARCH_SORT.SET,
      sort: event.target.value,
    })
    dispatch({
      type: TABLES.SET,
      search: state.SearchSort.search,
      sort: event.target.value,
    })
    dispatch({
      type: PAGING.SET,
      page: 1,
    })
  }

  /** @type {Object} sortコンポーネントに渡したいものをまとめる */
  const sortFactors = {
    sortItems: sortItemArr,
    value: state.SearchSort.sort,
    changeEvent: onChangeSort,
  }

  return useMemo(
    () => (
      <Grid item md={3} xs={12}>
        <Sort sortfactors={sortFactors} />
      </Grid>
    ),
    [sortFactors]
  )
}

export default SortLightJhClientList
