import PLAN from '../../../constants/CommonPlan'
import Paths from '../../../constants/Paths'
import CommonFunc from './CommonFunc'
import Common from '../../../constants/Common'

/**
 * planNameIdからplanNameを取得する
 * @param {Number} planNameId プラン名ID
 * @returns {String|null} プラン名 見つからない場合はnull
 */
const getPlanNameById = (planNameId) => {
  /** @type {Object}  IDに一致するプラン */
  const planObj = Object.values(PLAN).find(({ ID }) => ID === planNameId)
  // プランの名前を返す 見つからない場合はnullを返す
  return planObj?.NAME ?? null
}

/**
 * 利用可能なページか判定する
 * @param {String} path 判定するページパス
 * @param {Common.USER_TYPE} userType 判定する業種名
 * @param {String|null} planName プラン名 プランが無い場合はnull
 * @returns {Boolean} 利用可能な場合true
 */
const checkPlan = (path, userType, planName) => {
  /** @type {Boolean} JHか判定 */
  const isJH = userType === Common.USER_TYPE.JH
  /** @type {string[]} 利用可能なページパスの配列 */
  const pathArr = isJH
    ? Paths.AVAILABLE_PAGE[userType]
    : Paths.AVAILABLE_PAGE[userType][planName.toUpperCase()]
  // pathArr内に判定するページパスが含まれているか判定して結果を返す
  return pathArr.includes(path)
}

/**
 * 表示中ページが利用可能なページか判定する
 * @returns {Boolean} 利用可能な場合true
 */
const checkCurrentPagePlan = () => {
  // ストレージからプラン名IDと業種を取得
  const { planNameId, userType } = CommonFunc.getLoginUserData()
  /** @type {string} 画面パス */
  const path = `/${window.location.pathname.split('/').filter((v) => v)[0]}`
  /** @type {string} プラン名 */
  const planName = getPlanNameById(planNameId)
  // 利用可否を判定して結果を返す
  return checkPlan(path, userType, planName)
}

export default {
  checkCurrentPagePlan,
  checkPlan,
  getPlanNameById,
}
