import { combineReducers } from 'redux'

import SelectData, { initData } from './SelectData'
import FwdrName, { initFwdrName } from '../common/FwdrName'
import IataCassNo, { initIataCassNo } from '../common/IataCassNo'
import FirstName, { initFirstName } from '../common/FirstName'
import LastName, { initLastName } from '../common/LastName'
import Position, { initPosition } from '../common/Position'
import Email, { initEmail } from '../common/Email'
import Tel, { initTel } from '../common/Tel'
import PostalCode, { initPostalCode } from '../common/PostalCode'
import Address, { initAddress } from '../common/Address'
import BondInAddress, { initBondInAddress } from '../common/BondInAddress'
import Branch, { initBranch } from '../common/Branch'
import Airport, { initAirport } from '../common/Airport'
import Icon, { initIcon } from '../common/Icon'
import AgreeCheck, { initIsAgreeChecked } from '../common/AgreeCheck'
import Plan, { initPlan } from '../common/Plan'
import FwdrPlan, { initFwdrPlan } from '../common/FwdrPlan'

const initialState = {
  FwdrName: initFwdrName,
  IataCassNo: initIataCassNo,
  FirstName: initFirstName,
  LastName: initLastName,
  Position: initPosition,
  Email: initEmail,
  Tel: initTel,
  PostalCode: initPostalCode,
  Address: initAddress,
  BondInAddress: initBondInAddress,
  Branch: initBranch,
  SelectData: initData,
  Airport: initAirport,
  Icon: initIcon,
  AgreeCheck: initIsAgreeChecked,
  FwdrPlan: initFwdrPlan,
  Plan: initPlan,
}

export default combineReducers({
  FwdrName,
  IataCassNo,
  FirstName,
  LastName,
  Position,
  Email,
  Tel,
  PostalCode,
  Address,
  BondInAddress,
  Branch,
  Airport,
  Icon,
  AgreeCheck,
  FwdrPlan,
  SelectData,
  Plan,
})

export { initialState }
