import {
  UPDATE_SHIP_SEARCH_WORD,
  UPDATE_SHIP_SEARCH_AUTH,
} from '../../../actions'

/**
 * 検索ワードと管理者のチェックボックスを管理
 * @param {Object} [state={}] 検索ワードと管理者のチェックボックス
 * @param {Object} action 検索ワードと管理者のチェックボックスに必要な値
 * @return {Object} 検索ワードと管理者のチェックボックス
 */
const Search = (state = {}, action) => {
  switch (action.type) {
    // 検索ワード・ソート情報を保存
    case UPDATE_SHIP_SEARCH_WORD:
      return { ...state, word: action.word }
    case UPDATE_SHIP_SEARCH_AUTH:
      return { ...state, authority: action.authority }
    default:
      return state
  }
}

export default Search
