/* eslint-disable prefer-template */
import React from 'react'
import MAccordionDetails from '@material-ui/core/AccordionDetails'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: theme.accordionSummary,
}))

/**
 * @param {Object} props - 以下
 * @param {useStyles} className - スタイルを指定
 * @returns {JSX.Element} - AccordionDetails
 */
const AccordionDetails = (props) => {
  const classes = useStyles()
  return (
    <MAccordionDetails className={classes.root + ' ' + props.className}>
      {props.children}
    </MAccordionDetails>
  )
}

export default AccordionDetails
