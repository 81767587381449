import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import CommonValidation from '../../../common/function/CommonValidation'
import TextField from '../../../common/text/TextField'
import TitleShippingDetailsLightBookingDetails from './TitleShippingDetailsLightBookingDetails'
import ContentShippingDetailsLightBookingDetails from './ContentShippingDetailsLightBookingDetails'
import { LIGHT_BOOKING_DETAILS } from '../../../../actions'

const useStyles = makeStyles((Theme) => ({
  arrow: Theme.textNormalBLU,
  width: { width: '212px' },
  center: { textAlign: 'center' },
  helperText: {
    '& .MuiFormHelperText-root': {
      color: `${Theme.palette.required} !important`,
    },
  },
}))

/**
 * @param {Object} props 下記内容
 * @param {string} props.title タイトル文言
 * @param {string} props.content 表示内容
 * @param {Function} props.dispatch dispatch
 * @param {string} props.defaultValue 初期値
 * @param {boolean} props.disabled 非活性状態
 * @param {boolean} props.isLessThanMinRate A/FがMin料金より小さい場合true
 * @return {JSX} A/F欄とA/F入力欄
 */
const AirFreightShippingDetailsLightBookingDetails = ({
  title,
  content,
  dispatch,
  defaultValue,
  disabled,
  isLessThanMinRate,
}) => {
  const [error, setError] = useState(false)
  const [helperText, setHelperText] = useState('')
  const classes = useStyles()
  const { t } = useTranslation()

  // isLessThanMinRateの値に応じてhelperTextを更新
  useEffect(() => {
    if (isLessThanMinRate && !disabled) {
      // A/Fが入力可能かつMin料金より小さい場合警告文を表示
      setHelperText(t('4th0024'))
    } else if (!error) {
      // A/FがMin料金以上になった場合、エラーが無ければhelperText無し
      setHelperText('')
    }
  }, [isLessThanMinRate, error, t, disabled])

  /**
   * A/Fの値を更新
   * @param {String} value 入力値
   * @returns {void} なし
   */
  const onChange = (value) => {
    const { checkResult, errMsg } = CommonValidation.priceOptional(value)

    setError(checkResult)
    const newHelperText = isLessThanMinRate ? t('4th0024') : ''
    setHelperText(checkResult ? t(errMsg) : newHelperText)
    dispatch({
      type: LIGHT_BOOKING_DETAILS.RATE.EDIT_RATE,
      rate: checkResult ? null : value,
    })
  }

  /**
   * タイトル文言とA/Fの表示を作成
   * @returns {JSX} タイトルとA/F欄
   */
  const createTextLabel = () => {
    return (
      <Grid item>
        <TitleShippingDetailsLightBookingDetails label={title} />
        <ContentShippingDetailsLightBookingDetails label={content} />
      </Grid>
    )
  }

  /**
   * テキストボックス付きのA/F欄を作成
   * @returns {JSX} テキストボックス付きのA/F欄
   */
  const createTextField = () => {
    return (
      <Grid item>
        <Grid container direction="column" alignItems="center" item>
          {createTextLabel()}
          <Grid item className={classes.arrow}>
            ↓
          </Grid>
          <Grid item>
            <TextField
              inputProps={{ className: classes.center }}
              className={`${classes.width} ${classes.helperText}`}
              defaultValue={defaultValue?.toString() ?? ''}
              error={error}
              onChange={(event) => onChange(event.target.value)}
              disabled={disabled}
              helperText={helperText}
              placeholder={disabled ? '' : t('4th0022')}
            />
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return defaultValue !== undefined ? createTextField() : createTextLabel()
}

AirFreightShippingDetailsLightBookingDetails.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool.isRequired,
  isLessThanMinRate: PropTypes.bool.isRequired,
}

export default AirFreightShippingDetailsLightBookingDetails
