import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

import TextField from '../../../common/text/TextField'
import CommonValidation from '../../../common/function/CommonValidation'
import { LIGHT_BOOKING_DETAILS } from '../../../../actions'

const useStyles = makeStyles(() => ({
  TextField: {
    '& .MuiFilledInput-input': {
      paddingTop: '8px',
    },
  },
}))

/**
 * @param {Object} props 下記内容
 * @param {Boolean} props.disabled 非活性状態
 * @param {String} props.defaultValue 初期値
 * @param {Function} props.dispatch dispatch
 * @param {Boolean} props.isOther Other欄の選択状態
 * @returns {JSX} アイコン表示欄
 */
const TextFieldCancelBookingLightBookingDetails = ({
  disabled,
  defaultValue,
  dispatch,
  isOther,
}) => {
  const [error, setError] = useState(false)
  const [helperText, setHelperText] = useState('')
  const [value, setValue] = useState('')
  const { t } = useTranslation()
  const classes = useStyles()

  // Otherの選択状態が変化した時に入力内容を空にする
  useEffect(() => {
    if (!isOther) {
      setError(false)
      setValue('')
      setHelperText('')
    }
  }, [isOther])

  // 入力欄の初期値を設定する
  useEffect(() => {
    if (defaultValue !== undefined && defaultValue !== null) {
      setValue(defaultValue)
    }
  }, [defaultValue])

  /**
   * 入力値の更新
   * @param {String} value 入力値
   * @returns {void} なし
   */
  const onChange = (value) => {
    const { checkResult, errMsg } = CommonValidation.cancelReason(value)
    setError(checkResult)
    setHelperText(checkResult ? t(errMsg) : '')
    setValue(value)
    dispatch({
      type: LIGHT_BOOKING_DETAILS.CANCEL_BOOKING.EDIT,
      reason: checkResult ? null : value,
    })
  }

  return (
    <Grid item md={6}>
      <TextField
        className={classes.TextField}
        value={value}
        error={error}
        helperText={helperText}
        onChange={(event) => onChange(event.target.value)}
        disabled={disabled}
        placeholder={t('D111V0111')}
      />
    </Grid>
  )
}

TextFieldCancelBookingLightBookingDetails.propTypes = {
  disabled: PropTypes.bool.isRequired,
  defaultValue: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  isOther: PropTypes.bool.isRequired,
}

export default TextFieldCancelBookingLightBookingDetails
