import React, { useContext, useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'

import AppContext from '../../../../../../contexts/AppContext'
import TableCell from '../../../../../common/table/TableCell'
import TextField from '../../../../../common/text/TextField'
import CommonCalc from '../../../../../common/function/CommonCalc'
import CommonValidation from '../../../../../common/function/CommonValidation'
import { TABLES } from '../../../../../../actions'

/**
 * @param {object} props 下記内容
 * @param {Number} props.index ページ上のインデックス
 * @param {String} props.rateType レートタイプ
 * @param {String} props.rateName レート名
 * @return {JSX} レート入力欄の1つ分
 */
const RateCellLightCarAirFreight = (props) => {
  const { index, rateType, rateName } = props
  const { state, dispatch } = useContext(AppContext)
  const [inputError, setInputError] = useState(false)
  const [rateError, setRateError] = useState(false)
  const [focus, setForcus] = useState(false)

  /** @type {Number} showDataのインデックス */
  const page = CommonCalc.calcShowDataIndex(
    state.Paging,
    state.Tables.showData.length
  )
  /** @type {String} 入力されているレート */
  const rate = state.Tables.showData[page][index].rateObj[rateType]?.[rateName]
  /** @type {Boolean} レートの必須項目でのエラー状態 */
  const error = state.Tables.checkRateError(
    state.Tables.showData[page][index],
    rateType,
    rateName
  )

  // レートのエラー状態を更新
  useEffect(() => {
    const { checkResult } = CommonValidation.priceOptional(rate)
    setInputError(checkResult)
    setRateError(error)
  }, [error, rate])

  return useMemo(() => {
    /**
     * レート更新
     * @param {String} value 入力値
     * @returns {void} なし
     */
    const onChange = (value) => {
      dispatch({
        type: TABLES.EDIT,
        page,
        index,
        rateType,
        rateName,
        value: value === '' ? null : value,
      })
    }

    return (
      <TableCell>
        <TextField
          value={focus ? (rate ?? '') : (rate ?? '-')}
          onChange={(event) => onChange(event.target.value)}
          error={inputError || rateError}
          disabled={!state.Auth}
          onFocus={() => setForcus(true)}
          onBlur={() => setForcus(false)}
        />
      </TableCell>
    )
  }, [
    dispatch,
    index,
    inputError,
    page,
    rate,
    rateError,
    rateName,
    rateType,
    state.Auth,
    focus,
  ])
}

RateCellLightCarAirFreight.propTypes = {
  index: PropTypes.number.isRequired,
  rateType: PropTypes.string.isRequired,
  rateName: PropTypes.string.isRequired,
}

export default RateCellLightCarAirFreight
