import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import AppContext from '../../../../../contexts/AppContext'
import CommonTableContainer from '../../../../common/table/CommonTableContainer'
import TableContentsLightCarSelectFwdr from './TableContentsLightCarSelectFwdr'
import ApiPaths from '../../../../../constants/ApiPaths'
import CommonFunc from '../../../../common/function/CommonFunc'
import { TABLES } from '../../../../../actions'

/**
 * @return {JSX} 表コンポーネント(全体)
 */
const TableLightCarSelectForwarder = () => {
  const history = useHistory()
  const { state, dispatch } = useContext(AppContext)

  /** テーブル情報を取得 */
  useEffect(() => {
    ;(async () => {
      const apiInfoArr = [
        {
          key: 'tableData',
          method: 'get',
          path: ApiPaths.MASTER_CAR.LIGHT_FORWARDER,
        },
      ]
      const resObj = await CommonFunc.execApiAsync(apiInfoArr)
      const isApiSuccessful = CommonFunc.checkApiResponseObj(resObj, history)

      if (isApiSuccessful) {
        const { tableData } = resObj
        dispatch({
          type: TABLES.SET,
          tableData: tableData.data.results,
        })
      }
    })()
  }, []) // eslint-disable-line

  return (
    <CommonTableContainer isNoResult={state.Tables.isNoResult}>
      <TableContentsLightCarSelectFwdr />
    </CommonTableContainer>
  )
}

export default TableLightCarSelectForwarder
