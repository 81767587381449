import React, { useReducer, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

import LayoutBox from '../../../common/layout/LayoutBox'
import AppContext from '../../../../contexts/AppContext'
import reducer, {
  initialState,
} from '../../../../reducers/fwdr/lightFwdrBookingRequest'
import ApiPaths from '../../../../constants/ApiPaths'
import CommonFunc from '../../../common/function/CommonFunc'
import { LIGHT_BOOKING_REQUEST, AIRPORT, TABLES } from '../../../../actions'

import TitleLightBookingRequest from './00TitleLightBookingRequest/TitleLightBookingRequest'
import SelectBoxLightBookingRequest from './01SelectBoxLightBookingRequest/SelectBoxLightBookingRequest'
import CargoDetailsLightBookingRequest from './02CargoDetailsLightBookingRequest/CargoDetailsLightBookingRequest'
import CargoSummaryLightBookingRequest from './03CargoSummaryLightBookingRequest/CargoSummaryLightBookingRequest'
import ButtonsLightBookingRequest from './04ButtunLightBookingRequest/ButtonsLightBookingRequest'

/**
 * @param {Object} props 下記内容
 * @param {Number} props.ShippingDetails airport/commodity入力値
 * @param {Number} props.cargoDetailArr cargoDetail入力値
 * @return {JSX} 混載業者BookingRequest画面コンポーネント
 */
const LightFwdrBookingRequest = (props) => {
  const history = useHistory()
  const [state, dispatch] = useReducer(reducer, initialState)
  const cargoDetailArr = props.history.location.state.state?.cargoDetailArr
  const ShippingDetails = props.history.location.state.state

  useEffect(() => {
    ;(async () => {
      const apiInfoArr = [
        { key: 'airport', method: 'get', path: ApiPaths.MASTER_JH.AIRPORT },
        { key: 'commodity', method: 'get', path: ApiPaths.MASTER_JH.COMMODITY },
      ]
      const resObj = await CommonFunc.execApiAsync(apiInfoArr)
      const isApiSuccessful = CommonFunc.checkApiResponseObj(resObj, history)

      if (isApiSuccessful) {
        const { airport, commodity } = resObj
        dispatch({
          type: AIRPORT.SET,
          data: airport.data.results,
        })
        dispatch({
          type: LIGHT_BOOKING_REQUEST.COMMODITY.SET,
          data: commodity.data.results,
        })
        if (cargoDetailArr) {
          dispatch({
            type: TABLES.INIT,
            cargoDetail: cargoDetailArr,
          })
        }
        if (ShippingDetails) {
          dispatch({
            type: LIGHT_BOOKING_REQUEST.SELECT.SET,
            org: ShippingDetails.orgId,
            dst: ShippingDetails.dstId,
            commodity: ShippingDetails.commId,
          })
        }
      }
    })()
  }, []) //eslint-disable-line

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <LayoutBox>
        <TitleLightBookingRequest />
        <SelectBoxLightBookingRequest />
        <CargoDetailsLightBookingRequest />
        <CargoSummaryLightBookingRequest />
        <ButtonsLightBookingRequest />
      </LayoutBox>
    </AppContext.Provider>
  )
}

LightFwdrBookingRequest.propTypes = {
  ShippingDetails: PropTypes.object,
  cargoDetailArr: PropTypes.array,
}

export default LightFwdrBookingRequest
