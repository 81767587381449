import React from 'react'

import Paging from '../../../../commonUseContext/Paging'

/**
 * @return {JSX} LightJhClientRegDeleteAccount画面ページングコンポーネント
 */
const PagingLightJhClientRegDeleteAccount = () => {
  return <Paging />
}

export default PagingLightJhClientRegDeleteAccount
