import { FWDR_BID_DETAILS } from '../../../actions'

/** 初期表示ページ */
const initIsNew = true

/**
 * ページ状態を保持
 * @param {Boolean} [state=initPaging] 初期表示ページ
 * @param {Object} action isNew
 * @return {Boolean} ページ状態
 */
const IsNew = (state = initIsNew, action) => {
  const { type } = action
  switch (type) {
    case FWDR_BID_DETAILS.IS_NEW.SET:
      return action.isNew
    default:
      return state
  }
}

export default IsNew

export { initIsNew }
