// Display + 画面名
const DisplayFwdrPublicBid = {
  // 画面ID + 文言ID
  D120V0001: {
    en: 'Private',
    ja: 'プライベート',
  },
  D120V0002: {
    en: 'Public',
    ja: 'パブリック',
  },
  D120V0003: {
    en: 'PUBLIC BID',
    ja: '公開入札',
  },
  D120V0004: {
    en: 'Key words',
    ja: 'キーワード',
  },
  D120V0005: {
    en: 'Lately',
    ja: '最近',
  },
  D120V0006: {
    en: 'Validity',
    ja: '有効',
  },
  D120V0007: {
    en: 'Company',
    ja: '会社',
  },
  D120V0008: {
    en: '1st Deadline',
    ja: '一次締め切り',
  },
  D120V0009: {
    en: '2nd Deadline',
    ja: '二次締め切り',
  },
  D120V0010: {
    en: '3rd Deadline',
    ja: '三次締め切り',
  },
  D120V0011: {
    en: 'Status',
    ja: '状態',
  },
  D120V0012: {
    en: 'From SHP',
    ja: 'SHPから',
  },
  D120V0013: {
    en: 'From Carrier',
    ja: 'キャリアから',
  },
  D120V0014: {
    en: 'Sep 1 2020',
    ja: '2020年9月1日',
  },
  D120V0015: {
    en: 'Aug 31, 2020',
    ja: '2020年8月31日',
  },
  D120V0016: {
    en: ' - ',
    ja: ' - ',
  },
  D120V0017: {
    en: ' to ',
    ja: 'から',
  },
  D120V0018: {
    en: 'XXXXXX Corporation',
    ja: 'XXXXXX Corporation',
  },
  D120V0019: {
    en: 'Jul 31, 2020',
    ja: '2020年7月31日',
  },
  D120V0020: {
    en: 'Under contract',
    ja: '契約中',
  },
  D120V0021: {
    en: 'Closed',
    ja: 'クローズ',
  },
  D120V0022: {
    en: 'Aug 14, 2020',
    ja: '2020年8月14日',
  },
  D120V0023: {
    en: 'Not contract',
    ja: '契約しない',
  },
  D120V0024: {
    en: 'Aug 28, 2020',
    ja: '2020年8月28日',
  },
  D120V0025: {
    en: 'New BID',
    ja: '新規入札',
  },
  D120V0026: {
    en: 'was updated',
    ja: '更新されました',
  },
  D120V0027: {
    en: 'Requesting',
    ja: '要求',
  },
  D120V0028: {
    en: 'Decline',
    ja: '辞退',
  },
  D120V0029: {
    en: 'Check',
    ja: '確認',
  },
  D120V0030: {
    en: 'Done',
    ja: '完了',
  },
  D120V0031: {
    en: 'September 1, 2020',
    ja: '2020年9月1日',
  },
  D120V0032: {
    en: 'August 31, 2020',
    ja: '2020年8月31日',
  },
  D120V0033: {
    en: 'July 31, 2020',
    ja: '2020年7月31日',
  },
  D120V0034: {
    en: 'August 14, 2020',
    ja: '2020年8月14日',
  },
}
// Display + 画面名
export default DisplayFwdrPublicBid
