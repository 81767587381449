import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'

import CommonFunc from '../../common/function/CommonFunc'
import HeaderText from '../../common/text/HeaderText'

const useStyles = makeStyles((theme) => ({
  head: theme.textNormalBLU,
  text: theme.textNormalBLK,
  wordBreak: theme.wordBreak,
}))

/**
 * @param {Object} props - 以下
 * @param {Array} bidInfo - 入札情報
 * @return {JSX} Deadlineコンポーネント
 */
const Deadline = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { i18n } = useTranslation()
  const deadline = props.bidInfo.history.location.state?.state.bidInfoObj ?? []
  const lang = CommonFunc.getLang(i18n)
  const deadlineStr = CommonFunc.convertDate(deadline.firstDead, lang)

  return (
    <Grid container spacing={3}>
      <Grid container item spacing={2}>
        <Grid item md={12} xs={12}>
          <HeaderText className={classes.head}>{t('3rd0004')}</HeaderText>
          <div className={classes.text}>{deadlineStr}</div>
        </Grid>
      </Grid>
    </Grid>
  )
}

Deadline.propTypes = {
  bidInfo: PropTypes.object.isRequired,
}

export default Deadline
