import { LIGHT_REGISTER_NEW_ACCOUNT } from '../../../actions'
import Common from '../../../constants/Common'

const initCarrierName = {
  value: '',
  gsaFlg: Common.GSA_FLG.CARRIER,
  require: true,
}

/**
 * 選択した航空会社IDを更新
 * @param {Object} action - dispatchで受け取ったaction
 * @param {Object} state - state
 * @return {Object} - state
 */
const updateCarrierName = (action, state) => {
  const { value, gsaFlg } = action
  return {
    ...state,
    value,
    gsaFlg,
  }
}

/**
 * 航空会社名を保持
 * @param {Object} [state=initCarrierName] - state
 * @param {Object} action - action
 * @return {Object} - state
 */
const CarrierName = (state = initCarrierName, action) => {
  switch (action.type) {
    case LIGHT_REGISTER_NEW_ACCOUNT.CARRIER_NAME.UPDATE:
      return updateCarrierName(action, state)
    default:
      return state
  }
}

export default CarrierName

export { initCarrierName }
