import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'

import Line from './Line'
import AddButton from '../../../../../common/buttons/AddButton'
import AppContext from '../../../../../../contexts/AppContext'
import ConstantsCarFlightMgmt from '../../ConstantsCarFlightMgmt'
import { LIGHT_FLIGHT_MGMT } from '../../../../../../actions'

const useStyles = makeStyles((theme) => ({
  titleText: theme.textBoldBLU,
}))

/**
 * @param {*} props 下記内容のProps
 * @param {Number} props.index Routingのインデックス
 * @returns {JSX} Routing欄1つ分
 */
const Add = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { state, dispatch } = useContext(AppContext)
  const { index } = props

  return useMemo(() => {
    /** @type {String} Routingの見出しテキスト */
    const nextRoutingTitle = `${t('D301V0222')}${index + ConstantsCarFlightMgmt.ROUTING.NEXT}`

    /**
     * Addボタン押下時の動作
     * 新しいRoutingを追加する
     * @returns {void} なし
     */
    const addRouting = () => {
      dispatch({
        type: LIGHT_FLIGHT_MGMT.ADD.ROUTING,
      })
    }

    return (
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Line />
        </Grid>
        <Grid item className={classes.titleText}>
          {nextRoutingTitle}
        </Grid>
        <Grid container item justify="flex-end">
          <AddButton onClick={addRouting} />
        </Grid>
      </Grid>
    )
  }, [classes, dispatch, index, state.Popup.add, t])
}
Add.propTypes = {
  index: PropTypes.number.isRequired,
}
export default Add
