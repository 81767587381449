import Common from '../../../../constants/Common'

const ConstantsLightDeleteAccount = {
  TITLE_TABLE_ARR: [{ headerKey: 'D501V0094' }],
  TABLE_ARR: [
    { headerKey: 'D501V0095' },
    { headerKey: 'D501V0096' },
    { headerKey: 'D501V0097' },
  ],
  // questionsに必要な値
  QUESTIONS_Q1_ARR: [
    'D501V0102',
    'D501V0103',
    'D501V0104',
    'D501V0105',
    'D501V0106',
    'D501V0107',
    'D501V0108',
    'D501V0109',
    'D501V0110',
  ],
  QUESTIONS_Q2_ARR: ['D501V0113', 'D501V0114', 'D501V0115', 'D501V0110'],
  QUESTIONS_Q4_ARR: [
    { text: 'D501V0118', value: Common.SATISFACTION.SATISFACTION.ID },
    { text: 'D501V0119', value: Common.SATISFACTION.SOMEWHAT_SATISFIED.ID },
    { text: 'D501V0120', value: Common.SATISFACTION.USUALLY.ID },
    { text: 'D501V0121', value: Common.SATISFACTION.SOMEWHAT_DISSATISFIED.ID },
    { text: 'D501V0122', value: Common.SATISFACTION.DISSATISFACTION.ID },
  ],
  CHECK: {
    Yes: 1,
    No: 0,
  },
  DISSATISFIED: 1,
}

export default ConstantsLightDeleteAccount
