import React from 'react'
import PropTypes from 'prop-types'
import CheckIcon from '@material-ui/icons/Check'

/**
 * @param {object} props 下記内容
 * @param {Number} props.authorityFlg authorityFlg
 * @return {JSX} アイコン
 */
const CheckLightFwdrAccountInfo = ({ authorityFlg }) => {
  return authorityFlg ? <CheckIcon /> : '-'
}

CheckLightFwdrAccountInfo.propTypes = {
  authorityFlg: PropTypes.number.isRequired,
}

export default CheckLightFwdrAccountInfo
