import {
  UPDATE_FWDR_FIRST_NAME,
  UPDATE_FWDR_LAST_NAME,
  UPDATE_FWDR_MAIL,
  UPDATE_FWDR_TEL,
  UPDATE_FWDR_POSITION,
  UPDATE_FWDR_PASSWORD,
  UPDATE_FWDR_CURRENTPASS,
  UPDATE_FWDR_NEWPASS,
  UPDATE_FWDR_NEWPASS_VERIFY,
  UPDATE_FWDR_MAIL_SETTING,
  UPDATE_FWDR_CURRENTPASS_ERR,
  UPDATE_FWDR_PASS_VERIFY_ERR,
  UPDATE_FWDR_FIRST_NAME_INPUTERR,
  UPDATE_FWDR_LAST_NAME_INPUTERR,
  UPDATE_FWDR_MAIL_INPUTERR,
  UPDATE_FWDR_TEL_INPUTERR,
  UPDATE_FWDR_POSITION_INPUTERR,
  UPDATE_FWDR_CURRENTPASS_INPUTERR,
  UPDATE_FWDR_NEWPASS_INPUTERR,
  UPDATE_FWDR_NEWPASS_VERIFY_INPUTERR,
  UPDATE_FWDR_UPDATE_AT,
  UPDATE_FWDR_PASS_DIGIT_ERR,
} from '../../../actions'

const initMyProfile = {
  name: { value: '', inputErr: false },
  lastName: { value: '', inputErr: false },
  email: { value: '', inputErr: false },
  tel: { value: '', inputErr: false },
  position: { value: '', inputErr: false },
  password: { value: '' },
  currentPassword: { value: '', inputErr: false },
  newPassword: { value: '', inputErr: false },
  newPassVerify: { value: '', inputErr: false },
  mailSetting: { value: false },
  passwordErr: { current: false, verify: false, digit: false },
  updatedAt: { value: '' },
}

const Events = (state = initMyProfile, action) => {
  switch (action.type) {
    //------------------
    // firstNameを更新
    //------------------
    case UPDATE_FWDR_FIRST_NAME:
      return { ...state, name: { ...state.name, value: action.name } }
    case UPDATE_FWDR_FIRST_NAME_INPUTERR:
      return { ...state, name: { ...state.name, inputErr: action.inputErr } }
    //------------------
    // lastNameを更新
    //------------------
    case UPDATE_FWDR_LAST_NAME:
      return {
        ...state,
        lastName: { ...state.lastName, value: action.lastName },
      }
    case UPDATE_FWDR_LAST_NAME_INPUTERR:
      return {
        ...state,
        lastName: { ...state.lastName, inputErr: action.inputErr },
      }
    //------------------
    // emailを更新
    //------------------
    case UPDATE_FWDR_MAIL:
      return { ...state, email: { ...state.email, value: action.email } }
    case UPDATE_FWDR_MAIL_INPUTERR:
      return { ...state, email: { ...state.email, inputErr: action.inputErr } }
    //------------------
    // telを更新
    //------------------
    case UPDATE_FWDR_TEL:
      return { ...state, tel: { ...state.tel, value: action.tel } }
    case UPDATE_FWDR_TEL_INPUTERR:
      return { ...state, tel: { ...state.tel, inputErr: action.inputErr } }
    //------------------
    // positionを更新
    //------------------
    case UPDATE_FWDR_POSITION:
      return {
        ...state,
        position: { ...state.position, value: action.position },
      }
    case UPDATE_FWDR_POSITION_INPUTERR:
      return {
        ...state,
        position: { ...state.position, inputErr: action.inputErr },
      }
    //------------------
    // passwordを更新
    //------------------
    case UPDATE_FWDR_PASSWORD:
      return {
        ...state,
        password: { ...state.password, value: action.password },
      }
    //------------------
    // currentPasswordを更新
    //------------------
    case UPDATE_FWDR_CURRENTPASS:
      return {
        ...state,
        currentPassword: {
          ...state.currentPassword,
          value: action.currentPassword,
        },
      }
    case UPDATE_FWDR_CURRENTPASS_INPUTERR:
      return {
        ...state,
        currentPassword: {
          ...state.currentPassword,
          inputErr: action.inputErr,
        },
      }
    //------------------
    // newPasswordを更新
    //------------------
    case UPDATE_FWDR_NEWPASS:
      return {
        ...state,
        newPassword: { ...state.newPassword, value: action.newPassword },
      }
    case UPDATE_FWDR_NEWPASS_INPUTERR:
      return {
        ...state,
        newPassword: { ...state.newPassword, inputErr: action.inputErr },
      }
    //------------------
    // newPassVerifyを更新
    //------------------
    case UPDATE_FWDR_NEWPASS_VERIFY:
      return {
        ...state,
        newPassVerify: { ...state.newPassVerify, value: action.newPassVerify },
      }
    case UPDATE_FWDR_NEWPASS_VERIFY_INPUTERR:
      return {
        ...state,
        newPassVerify: { ...state.newPassVerify, inputErr: action.inputErr },
      }
    //------------------
    // mailSettingを更新
    //------------------
    case UPDATE_FWDR_MAIL_SETTING:
      return {
        ...state,
        mailSetting: { ...state.mailSetting, value: action.mailSetting },
      }
    //------------------
    // passwordErrを更新
    //------------------
    case UPDATE_FWDR_CURRENTPASS_ERR:
      return {
        ...state,
        passwordErr: { ...state.passwordErr, current: action.errflg },
      }
    case UPDATE_FWDR_PASS_VERIFY_ERR:
      return {
        ...state,
        passwordErr: { ...state.passwordErr, verify: action.errflg },
      }
    case UPDATE_FWDR_PASS_DIGIT_ERR:
      return {
        ...state,
        passwordErr: { ...state.passwordErr, digit: action.errFlg },
      }
    //------------------
    // updatedAtを更新
    //------------------
    case UPDATE_FWDR_UPDATE_AT:
      return {
        ...state,
        updatedAt: { ...state.updatedAt, value: action.updatedAt },
      }
    default:
      return state
  }
}

export default Events

export { initMyProfile }
