import React, { useReducer, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import LayoutBox from '../../../common/layout/LayoutBox'
import TitleBidMgmt from './00TitleBidMgmt/TitleBidMgmt'
import SearchSortBidMgmt from './01SearchSortBidMgmt/SearchSortBidMgmt'
import TableBidMgmt from './02TableBidMgmt/TableBidMgmt'
import AddBidMgmt from './03AddButtonBidMgmt/AddBidMgmt'
import PagingBidMgmt from './04PagingBidMgmt/PagingBidMgmt'
import BackButtonBidMgmt from './05BackButtonBidMgmt/BackButtonBidMgmt'
import AppContext from '../../../../contexts/AppContext'
import reducer, {
  initialState,
} from '../../../../reducers/shipper/shipperBidMgmt'
import ConstantsBidMgmt from './ConstantsBidMgmt'
import { TABLES, SEARCH_SORT } from '../../../../actions'
import CommonFunc from '../../../common/function/CommonFunc'
import ApiPaths from '../../../../constants/ApiPaths'

/**
 * @return {JSX} 荷主BidMgmt画面コンポーネント
 */
const ShipperBidMgmt = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { i18n } = useTranslation()
  const history = useHistory()

  useEffect(() => {
    ;(async () => {
      // 入札情報を取得
      const apiInfoArr = [
        { key: 'bidMgmtArr', method: 'get', path: ApiPaths.SHIPPER.BID_MGMT },
      ]
      const resObj = await CommonFunc.execApiAsync(apiInfoArr)
      const isApiSuccessful = CommonFunc.checkApiResponseObj(resObj, history)

      if (isApiSuccessful) {
        const { bidMgmtArr } = resObj

        // ソートの初期値は定数の最初の値で固定
        const initSort = ConstantsBidMgmt.SORT_ITEM_ARR[0].sortTerm

        // APIの値からテーブルデータを設定
        dispatch({
          type: TABLES.INIT,
          data: bidMgmtArr.data.results,
          sort: initSort,
          i18n,
        })

        // SearchSortの初期値を設定
        dispatch({
          type: SEARCH_SORT.SET,
          search: '',
          sort: initSort,
        })
      }
    })()
  }, []) // eslint-disable-line

  // 監視対象 表示データ、ページ、検索、ソートのどれかに変更があった場合通知をセット
  const pageIndex = state.Paging - 1
  const showDataArr = state.Tables?.showData[pageIndex]
  const { apiDataArr } = state.Tables
  const showDataCount = showDataArr.length
  const { search, sort } = state.SearchSort
  useEffect(() => {
    // 必須項目が全てセットされた場合通知状態取得、セット処理を行う
    if (showDataCount && sort) {
      CommonFunc.setNotifyAndDraft(
        apiDataArr,
        showDataArr,
        ApiPaths.SHIPPER.BID_MGMT_NOTIFY,
        ApiPaths.SHIPPER.BID_MGMT_DRAFT,
        dispatch,
        history
      )
    }
  }, [showDataCount, pageIndex, search, sort]) // eslint-disable-line

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <LayoutBox>
        <TitleBidMgmt />
        <SearchSortBidMgmt />
        <TableBidMgmt />
        <AddBidMgmt />
        <PagingBidMgmt />
        <BackButtonBidMgmt />
      </LayoutBox>
    </AppContext.Provider>
  )
}

export default ShipperBidMgmt
