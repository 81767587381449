/* eslint-disable no-extra-parens */
/* eslint-disable prefer-template */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unneeded-ternary */
import React, { useEffect, useState } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Grid from '@material-ui/core/Grid'
import Badge from '@material-ui/core/Badge'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import NotificationsNoneRoundedIcon from '@material-ui/icons/NotificationsNoneRounded'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import GavelIcon from '@material-ui/icons/Gavel'
import PriorityHighIcon from '@material-ui/icons/PriorityHigh'

import ApiPaths from '../../../constants/ApiPaths'
import Paths from '../../../constants/Paths'
import Common from '../../../constants/Common'
import CommonFunc from '../../common/function/CommonFunc'
import RegisterAgainDialog from '../dialog/RegisterAgainDialog'

import restFacade from '../../../actions/rest-facade'

const StyleBadge = withStyles((theme) => ({
  badge: {
    right: 5,
    top: 35,
    padding: '0 4px',
    color: theme.palette.info.main,
    '&.MuiBadge-colorError': {
      backgroundColor: theme.palette.required,
    },
  },
}))(Badge)

const CountBadge = withStyles((theme) => ({
  badge: {
    right: 10,
    top: 10,
    padding: '0 4px',
    color: theme.palette.info.main,
    borderColor: theme.palette.info.main,
    border: '1px solid',
    // '&.MuiBadge-colorError': {
    //   backgroundColor: 'transparent'
    // }
  },
}))(Badge)

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    fontFamily: theme.typography.fontFamily,
    color: theme.fontColor.main,
    '& .MuiAccordion-root': {
      backgroundColor: theme.palette.info.main,
      margin: '0px',
    },
    '& .MuiAccordion-root.Mui-expanded': {
      margin: '0px',
    },
    '& .MuiRadio-colorSecondary.Mui-checked': {
      color: theme.palette.primary.main,
    },
    '& .MuiAccordion-root:before': {
      position: 'unset',
    },
    '& .MuiAccordionDetails-root': {
      padding: 0,
    },
  },
  icon: {
    '& .MuiSvgIcon-root': {
      fill: theme.palette.info.main,
      fontSize: theme.fontSize.cardButtonSm,
    },
  },
  popover: {
    borderColor: '0px solid',
    marginTop: '20px',
    maxHeight: '80%',
    position: 'relative',
    '& .MuiPopover-paper': {
      borderRadius: '15px',
      border: '0px solid',
      borderColor: 'rgba(89, 89, 89, 0.5)',
      backgroundColor: theme.palette.info.main,
      height: 'fix-content',
      width: '400px',
      '& .MuiGrid-spacing-xs-2': {
        margin: '0px',
        width: 'auto',
      },
    },
  },
  title: theme.textBoldBLK,
  text: theme.textSmallBLK,
  blu: theme.textNormalBLU,
  underline: {
    textDecoration: 'underline',
  },
  bold: {
    fontWeight: 'bold',
  },
  row: {
    padding: '0.5rem 0',
  },
  focus: {
    backgroundColor: 'rgba(211, 218, 233, 1)',
  },
  avatarIcon: {
    padding: '0 0.5rem',
    width: 40,
    height: 40,
  },
  iconButton: {
    width: 24,
    height: 24,
  },
  button: {
    textTransform: 'none',
    width: 200,
    height: 40,
  },
  statusIcon: {
    width: 12,
    height: 12,
    color: theme.palette.info.main,
    fill: theme.palette.info.main,
  },
  titleAreaGray: {
    position: 'relative',
    overflow: 'hidden',
    height: '2rem',
    width: 'calc(100% - 0.5rem)',
    '&:before': {
      position: 'absolute',
      background: 'rgba(211, 218, 233, 1)',
      // eslint-disable-next-line quotes
      content: "'...'",
      bottom: 0,
      right: 0,
    },
    '&:after': {
      // eslint-disable-next-line quotes
      content: "''",
      height: '100%',
      width: '100%',
      background: 'rgba(211, 218, 233, 1)',
      position: 'absolute',
    },
  },
  titleAreaWhite: {
    position: 'relative',
    overflow: 'hidden',
    height: '2rem',
    width: 'calc(100% - 0.5rem)',
    '&:before': {
      position: 'absolute',
      background: theme.palette.info.main,
      // eslint-disable-next-line quotes
      content: "'...'",
      bottom: 0,
      right: 0,
    },
    '&:after': {
      // eslint-disable-next-line quotes
      content: "''",
      height: '100%',
      width: '100%',
      background: theme.palette.info.main,
      position: 'absolute',
    },
  },
}))

let setNotificationsData = () => {}
let setCompanyNameData = () => {}

const Alert = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const [anchorEl, setAnchorEl] = useState(null)
  const [menu, setMenu] = useState(null)
  const [notifications, setNotifications] = useState({
    D202V0031: [],
    D202V0032: [],
    D202V0033: [],
    D202V0034: [],
    D202V0035: [],
    D202V0036: [],
  })
  const titles = [
    'D202V0031',
    'D202V0032',
    'D202V0033',
    'D202V0034',
    'D202V0035',
    'D202V0036',
  ]
  const open = Boolean(anchorEl)
  const openMenu = Boolean(menu)
  const [id, setId] = useState()
  const [readFlg, setReadFlg] = useState()
  const [updatedAt, setUpdatedAt] = useState()
  const [companyName, setCompanyName] = useState()
  const [unreadCount, setUnreadCount] = useState(0)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const openMenuClick = (event, notification) => {
    setId(notification.id)
    setUpdatedAt(notification.updatedAt)
    setReadFlg(notification.readFlg)
    setMenu(event.currentTarget)
  }
  const closeMenuClick = () => {
    setMenu(null)
  }

  const getTime = (date) => {
    let type = ''
    const nowDate = new Date()
    const dataDate = new Date(date)
    const msTime = nowDate.getTime() - dataDate.getTime()
    let time = Math.floor(msTime / (1000 * 60))
    if (time < 60) {
      type = 'm'
    } else if (time < 1440) {
      time = Math.floor(time / 60)
      type = 'h'
    } else {
      time = Math.floor(time / 1440)
      type = 'd'
    }
    const log = String(time) + type
    return log
  }

  // 通知一覧情報取得時の処理
  setNotificationsData = (res) => {
    const unreadCount = res.data.results.filter((v) => v.readFlg === 0).length //未読カウント
    const result = {
      D202V0031: [],
      D202V0032: [],
      D202V0033: [],
      D202V0034: [],
      D202V0035: [],
      D202V0036: [],
    }
    const today = new Date() //現在の日付
    const yesterday = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 1
    ) //昨日の日付
    const weekStart = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - (today.getDay() - 1)
    ) //今週の月曜

    res.data.results.forEach((data) => {
      data.date = CommonFunc.chgUTC2LocalTime(
        data.date,
        'YYYY-MM-DD HH:mm:ss',
        'YYYY/MM/DD HH:mm'
      )
      const dataDate = new Date(data.date) //通知の日付
      //通知の日付から今週の初日を引いた値が0以上なら今週の日付
      const week = dataDate - weekStart >= 0 //今週の通知か？
      const min = Math.floor((today - dataDate) / (1000 * 60)) //日付から経過時間(分)を計算
      let target = ''

      if (min <= 34) {
        target = 'D202V0031' //34分以内はNew
      } else if (
        today.getFullYear() === dataDate.getFullYear() &&
        today.getMonth() === dataDate.getMonth() &&
        today.getDate() === dataDate.getDate()
      ) {
        target = 'D202V0032' //Today
      } else if (
        yesterday.getFullYear() === dataDate.getFullYear() &&
        yesterday.getMonth() === dataDate.getMonth() &&
        yesterday.getDate() === dataDate.getDate()
      ) {
        target = 'D202V0033' //Yesterday
      } else if (week === true) {
        target = 'D202V0034' //This Week
      } else if (
        today.getFullYear() === dataDate.getFullYear() &&
        today.getMonth() === dataDate.getMonth()
      ) {
        target = 'D202V0035' //This Month
      } else {
        target = 'D202V0036' //Earlier
      }
      result[target].push(data)
    })

    //新しい順にソート
    Object.keys(result).map((key) =>
      result[key].sort((a, b) => new Date(b.date) - new Date(a.date))
    )

    setNotifications(result)
    setUnreadCount(unreadCount)
  }

  // 会社名取得時の処理
  setCompanyNameData = (res) => {
    setCompanyName(res.data.results.companyName)
  }

  useEffect(() => {
    // ユーザタイプから会社名APIを振り分け
    const userType = CommonFunc.getLoginUserData().userType
    const companyInfoApiPath = (() => {
      switch (userType) {
        case Common.USER_TYPE.SHIPPER:
          return ApiPaths.MASTER_SHIPPER.SHIP_COMPANY_INFO
        case Common.USER_TYPE.FWDR:
          return ApiPaths.MASTER_FWDR.FWDR_COMPANY_INFO
        case Common.USER_TYPE.AIRLINE:
          return ApiPaths.MASTER_CARRIER.CAR_COMPANY_INFO
        case Common.USER_TYPE.JH:
          return ApiPaths.MASTER_JH.COMPANY_INFO
        default:
          return ''
      }
    })()

    /**
     * APIを呼び出す
     * @param {String} companyInfoApiPath 会社情報のAPIパス
     * @returns {void} なし
     */
    const callApi = async (companyInfoApiPath) => {
      /** 呼び出すAPI一覧 */
      const apiInfoArr = [
        {
          key: 'popup',
          method: 'get',
          path: ApiPaths.NOTIFICATIONS.POPUP_NOTIFICATIONS,
        },
        { key: 'company', method: 'get', path: companyInfoApiPath },
      ]
      //UTCが未取得の場合はUTCもまとめて取得
      if (!CommonFunc.getStorage(Common.KEY.UTC_INFO)) {
        apiInfoArr.push({
          key: 'utc',
          method: 'get',
          path: ApiPaths.COMMON_API.UTC,
        })
      }

      const resObj = await CommonFunc.execApiAsync(apiInfoArr)
      const isApiSuccessful = CommonFunc.checkApiResponseObj(resObj, history)
      if (isApiSuccessful) {
        const { popup, company, utc } = resObj
        // UTC情報を取得している場合は先にストレージに保持する
        if (utc) {
          CommonFunc.setUtcData(utc)
        }
        // 通知と会社名情報を保持する
        setNotificationsData(popup)
        setCompanyNameData(company)
      }
    }

    // サインイン中のみAPIを投げる
    if (companyInfoApiPath) {
      callApi(companyInfoApiPath)
    }
  }, []) //eslint-disable-line

  const clickProps = {
    path: Paths.NOTIFY.NOTIFY_LIST,
  }

  const readClick = () => {
    const read = () => {
      restFacade.get(ApiPaths.NOTIFICATIONS.POPUP_NOTIFICATIONS, (res) =>
        CommonFunc.callbackFunc(res, setNotificationsData)
      )
    }
    const req = { id, read: 1, updatedAt }
    restFacade.put(
      ApiPaths.NOTIFICATIONS.NOTIFICATIONS,
      (res) => CommonFunc.callbackFunc(res, read),
      req
    )
    closeMenuClick()
  }

  const deleteClick = () => {
    const deleted = () => {
      restFacade.get(ApiPaths.NOTIFICATIONS.POPUP_NOTIFICATIONS, (res) =>
        CommonFunc.callbackFunc(res, setNotificationsData)
      )
    }
    const req = { id, hide: 1, updatedAt }
    restFacade.put(
      ApiPaths.NOTIFICATIONS.NOTIFICATIONS,
      (res) => CommonFunc.callbackFunc(res, deleted),
      req
    )
    closeMenuClick()
  }

  const getStatusIcon = (page, title) => {
    //アイコンなしの通知タイトル
    const noIconTitles = [
      'Approved the user registration requests',
      'Non-acceptance of the user registration requests',
      'Approved the delete account requests',
      'Non-acceptance of the delete account requests',
      'Request for update Timetable',
      'Regarding the delete account requests',
    ]
    if (noIconTitles.includes(title)) {
      return null
    }

    switch (page) {
      case 1: //BULKSEARCH
      case 4: //BOOKINGLIST
        // eslint-disable-next-line no-extra-parens
        return (
          <img
            height="12"
            width="12"
            src="./../images/topIcon/BookingListAlert.svg"
            alt="booking"
          />
        )
      case 2: //CARGOTRACE
        // eslint-disable-next-line no-extra-parens
        return (
          <img
            height="12"
            width="12"
            src="./../images/topIcon/FlightMgmtAlert.svg"
            alt="cargoTrace"
          />
        )
      case 3: //JOININGBID
      case 6: //BIDSEARCH
      case 8: //BIDRATES
        // eslint-disable-next-line no-extra-parens
        return <GavelIcon style={{ fontSize: 12 }} />
      case 5: //CARGOREADY
        // eslint-disable-next-line no-extra-parens
        return (
          <img
            height="12"
            width="12"
            src="./../images/topIcon/CargoReadyAlert.svg"
            alt="cargoReady"
          />
        )
      case 7: //SPACECONTROL
        // eslint-disable-next-line no-extra-parens
        return (
          <img
            height="12"
            width="12"
            src="./../images/topIcon/SpaceControlAlert.svg"
            alt="spaceControl"
          />
        )
      case 9: //CASSUPLOAD
        // eslint-disable-next-line no-extra-parens
        return (
          <img
            height="12"
            width="12"
            src="./../images/topIcon/CassUploadAlert.svg"
            alt="cassUpload"
          />
        )
      default: //JH
        // eslint-disable-next-line no-extra-parens
        return <PriorityHighIcon style={{ fontSize: 12 }} />
    }
  }

  const getStatusColor = (name, page) => {
    if (name === companyName) {
      return 'primary' //同じ会社の時、青色
    } else if (page === null) {
      return 'error' //pageがnullの時JH、赤色
    } else {
      return 'secondary' //違う会社の時、黄色
    }
  }

  const pageTransition = () => {
    handleClose()
    CommonFunc.clickEvent(clickProps, history)
  }

  const topPath = Paths.NOTIFY.NOTIFY_LIST

  return (
    <>
      <CountBadge badgeContent={unreadCount} color="primary">
        <IconButton className={classes.icon} onClick={handleClick}>
          <NotificationsNoneRoundedIcon />
        </IconButton>
      </CountBadge>
      <Popover
        className={classes.popover}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Grid container justify="center" spacing={2}>
          <div className={`${classes.root} ${Common.CLASS_NAME.PAGE_CONTENTS}`}>
            {titles.map((title, index) => (
              <Accordion key={index} defaultExpanded={index < 2 ? true : false}>
                <AccordionSummary
                  expandIcon={index === 0 ? null : <ExpandMoreIcon />}
                  className={classes.accordion}
                >
                  <Grid container justify="space-between">
                    {/* アコーディオンタイトル */}
                    <Grid item>
                      <div className={classes.title}>{t(title)}</div>
                    </Grid>
                    {index === 0 ? (
                      CommonFunc.checkRegisterPage() ? (
                        <RegisterAgainDialog
                          path={topPath}
                          buttonPC={
                            <Grid item>
                              {/* NotifyListリンク */}
                              <div
                                className={
                                  classes.blu + ' ' + classes.underline
                                }
                              >
                                {t('D202V0075') + ' >'}
                              </div>
                            </Grid>
                          }
                        />
                      ) : (
                        <div
                          className={classes.blu + ' ' + classes.underline}
                          onClick={pageTransition}
                        >
                          {t('D202V0075') + ' >'}
                        </div>
                      )
                    ) : (
                      <></>
                    )}
                  </Grid>
                </AccordionSummary>
                <AccordionDetails className={classes.accordion}>
                  <Grid container>
                    {notifications[title].map((notification, index) => (
                      <Grid
                        container
                        justify="space-between"
                        key={index}
                        className={
                          notification.readFlg === 0
                            ? classes.row + ' ' + classes.focus
                            : classes.row
                        }
                      >
                        {/* アイコン */}
                        <Grid item xs={2}>
                          <StyleBadge
                            color={getStatusColor(
                              notification.companyName,
                              notification.page
                            )}
                            badgeContent={getStatusIcon(
                              notification.page,
                              notification.title
                            )}
                          >
                            {notification.icon && (
                              <img
                                className={classes.avatarIcon}
                                src={notification.icon}
                                alt="icon"
                              />
                            )}
                          </StyleBadge>
                        </Grid>
                        {/* 名前と会社名、タイトル */}
                        <Grid item xs={9} className={classes.text}>
                          <div
                            className={
                              notification.readFlg === 0
                                ? classes.titleAreaGray
                                : classes.titleAreaWhite
                            }
                          >
                            <span className={classes.bold}>
                              {notification.name +
                                ' ' +
                                notification.lastName +
                                ' ' +
                                notification.companyName}
                            </span>
                            {' ' + notification.title}
                          </div>
                        </Grid>
                        {/* 経過時間と3点リーダー */}
                        <Grid item xs={1} className={classes.text}>
                          {getTime(notification.date)}
                          <IconButton
                            className={classes.iconButton}
                            onClick={(event) =>
                              openMenuClick(event, notification)
                            }
                          >
                            <MoreHorizIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </Grid>
      </Popover>

      {/* 3点リーダーポップオーバー */}
      <Popover
        open={openMenu}
        anchorEl={menu}
        onClose={closeMenuClick}
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <ButtonGroup
          orientation="vertical"
          className={Common.CLASS_NAME.PAGE_CONTENTS}
        >
          {!readFlg ? (
            <Button
              onClick={readClick}
              className={classes.button + ' ' + classes.text}
            >
              {t('D202V0073')}
            </Button>
          ) : null}
          <Button
            onClick={deleteClick}
            className={classes.button + ' ' + classes.text}
          >
            {t('D202V0074')}
          </Button>
        </ButtonGroup>
      </Popover>
    </>
  )
}

export default Alert
