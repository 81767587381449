import { Grid } from '@material-ui/core'
import React from 'react'
import Paths from '../../../../../constants/Paths'

import BackButton from '../../../../common/buttons/BackButton'

/**
 * @return {JSX} Backボタン
 */
const BackButtonLightCarSelectForwarder = () => {
  return (
    <Grid container justify="center">
      <Grid item>
        <BackButton path={Paths.MASTER_AIRLINE.CAR_MASTER_TOP} />
      </Grid>
    </Grid>
  )
}

export default BackButtonLightCarSelectForwarder
