/* eslint-disable prefer-template */
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: theme.accordion,
}))

/**
 * @param {Object} props - 以下
 * @param {useStyles} className - スタイルを指定
 * @returns {JSX.Element} - AccordionContainer
 */
const AccordionContainer = (props) => {
  const classes = useStyles()
  return (
    <div className={classes.root + ' ' + props.className}>{props.children}</div>
  )
}

export default AccordionContainer
