import { LIGHT_REGISTER_NEW_ACCOUNT } from '../../../actions'

const initData = {
  companyArr: [],
  cityArr: [],
  airportArr: [],
}

/**
 * セレクトボックスの初期設定を作成
 * @param {Object} action dispatchで受け取ったaction
 * @param {Object} state state
 * @return {Object} セレクトボックスオブジェクト
 */
const createInitDataObj = (action, state) => {
  return {
    ...state,
    companyArr: action.data.companyArr,
    cityArr: action.data.cityArr,
    airportArr: action.data.airportArr,
  }
}

/**
 * ページ状態を保持
 * @param {Object} [state=initData] -
 * @param {Object} action - dispatchで受け取ったaction
 * @return {Object} - セレクトボックスオブジェクト
 */
const SelectData = (state = initData, action) => {
  const { type } = action
  switch (type) {
    case LIGHT_REGISTER_NEW_ACCOUNT.SELECT_DATA.INIT:
      return createInitDataObj(action, state)
    default:
      return state
  }
}

export default SelectData

export { initData }
