/* eslint-disable no-extra-parens */
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import { useTranslation } from 'react-i18next'
import FlightTakeOff from '../icon/FlightTakeOff'

const useStyles = makeStyles((theme) => ({
  root: {
    iconTextColor: theme.fontColor.info,
    textAlign: 'center',
    '& .MuiPaper-root': {
      backgroundColor: theme.palette.primary.main,
    },
    '& .MuiStepper-root': {
      padding: 10,
      color: theme.palette.info.main,
    },
    '& .MuiStepLabel-label': {
      marginTop: 5,
      fontSize: theme.fontSize.stepbar,
      color: theme.palette.info.main,
    },
    '& .MuiStepIcon-root': {
      color: theme.palette.info.main,
      '& .MuiStepIcon-text': {
        fill: theme.fontColor.info,
      },
    },
    '& .MuiStepIcon-completed': {
      color: theme.palette.secondary.main,
    },
    '& .MuiStepIcon-active': {
      color: theme.palette.secondary.main,
    },
    '& .MuiStepConnector-line': {
      marginTop: '2rem',
    },
  },
}))

const Stepbar = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const steps = getSteps()

  /**
   * @return {Array} -
   */
  function getSteps() {
    const S1 = <>{t('D012V0001')}</>
    const S2 = <>{t('D012V0002')}</>
    const S3 = <>{t('D012V0003')}</>
    const S4 = <>{t('D012V0004')}</>
    const S5 = <>{t('D012V0005')}</>

    return [S1, S2, S3, S4, S5]
  }

  return (
    <div className={classes.root}>
      <Stepper activeStep={props.activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={index}>
            <FlightTakeOff index={index} sample={props.activeStep} />
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  )
}

export default Stepbar
