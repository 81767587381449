import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  title: theme.textNormalBLU,
  contentBold: theme.textBoldBLK,
  contentNormal: theme.textNormalBLK,
}))

/**
 * @param {*} props 下記内容のProps
 * @param {Boolean} [props.row=false] 横に並べる
 * @param {*} props.title タイトル内容
 * @param {*} props.content 表示内容
 * @param {Boolean} [props.bold] contentを太字にする
 * @returns {JSX} タイトルと内容を合わせて返す
 */
const Content = (props) => {
  const classes = useStyles()
  const { row = false, title, content, bold = false } = props
  return useMemo(() => {
    const contentClass = bold ? classes.contentBold : classes.contentNormal

    /**
     * タイトルと内容を横並びで作成
     * @returns {JSX.Element} タイトルと内容
     */
    const createRowContent = () => (
      <>
        <span className={classes.title}>{title}</span>
        <span className={contentClass}>{content}</span>
      </>
    )

    /**
     * タイトルと内容を縦並びで作成
     * @returns {JSX.Element} タイトルと内容
     */
    const createColumnContent = () => (
      <>
        <div className={classes.title}>{title}</div>
        <div className={contentClass}>{content}</div>
      </>
    )

    return row ? createRowContent() : createColumnContent()
  }, [row, title, content, bold, classes])
}
Content.propTypes = {
  row: PropTypes.bool,
  title: PropTypes.any.isRequired,
  content: PropTypes.any,
  bold: PropTypes.bool,
}

export default Content
