import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import TextField from '../../common/text/TextField'
import CommonValidation from '../../common/function/CommonValidation'
import {
  UPDATE_FWDR_POSITION,
  UPDATE_FWDR_POSITION_INPUTERR,
  UPDATE_CAR_POSITION,
  UPDATE_CAR_POSITION_INPUTERR,
} from '../../../actions'

const useStyles = makeStyles((theme) => ({
  head: theme.textBoldBLU,
}))

/**
 * コンポーネントを更新するか判定する
 * @param {Object} prev 前回の値
 * @param {Object} next 最新の値
 * @returns {Boolean} falseの場合更新する
 */
const areEqual = (prev, next) => {
  // 各入力欄の値を比較
  // 一致していれば更新無し
  return prev.position.value === next.position.value
}

const Position = React.memo(({ dispatch, position, carrier }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [inputErrs, setInputErrs] = useState({ position: false })
  const [helperTxts, setHelperTxts] = useState({ position: '' })

  const handleChangePosition = (event) => {
    const value = event.target.value
    // 入力チェック
    const validationCheck = CommonValidation.position(value)
    const err = validationCheck.checkResult
    setInputErrs({ ...inputErrs, position: err })
    setHelperTxts({ ...helperTxts, position: validationCheck.errMsg })

    dispatch({
      type: carrier ? UPDATE_CAR_POSITION : UPDATE_FWDR_POSITION,
      position: value,
    })

    // 入力エラーの場合、Savaボタン無効
    dispatch({
      type: carrier
        ? UPDATE_CAR_POSITION_INPUTERR
        : UPDATE_FWDR_POSITION_INPUTERR,
      inputErr: err,
    })
  }

  return (
    <div className={classes.formControl}>
      <Grid container>
        <Grid item md={4} xs={10}>
          <div className={classes.head}>{t('D301V0023')}</div>
          <TextField
            id="position"
            error={inputErrs.position}
            helperText={t(helperTxts.position)}
            value={position.value}
            onChange={handleChangePosition}
            autoComplete="off"
          />
        </Grid>
      </Grid>
    </div>
  )
}, areEqual)

Position.displayName = 'Position'

Position.propTypes = {
  dispatch: PropTypes.func.isRequired,
  position: PropTypes.object.isRequired,
  carrier: PropTypes.bool,
}
export default Position
