import React from 'react'

import Paging from '../../../../commonUseContext/Paging'

/**
 * @return {JSX} ページコンポーネント
 */
const PagingLightFwdrBookingList = () => {
  return <Paging />
}

export default PagingLightFwdrBookingList
