import React, { useReducer, useEffect } from 'react'
import PropTypes from 'prop-types'

import LayoutBox from '../../../common/layout/LayoutBox'
import TitleBidDetails from './00TitleBidDetails/TitleBidDetails'
import CheckboxesBidDetails from './01CheckboxBidDetails/CheckboxesBidDetails'
import SearchSortBidDetails from './02SearchSortBidDetails/SearchSortBidDetails'
import TableBidDetails from './03TableBidDetails/TableBidDetails'
import PagingBidDetails from './04PagingBidDetails/PagingBidDetails'
import ButtonBidDetails from './05ButtonBidDetails/ButtonBidDetails'
import AppContext from '../../../../contexts/AppContext'
import reducer, { initialState } from '../../../../reducers/fwdr/fwdrBidDetails'
import ConstantsBidDetails from './ConstantsBidDetails'
import {
  FWDR_BID_DETAILS,
  TABLES,
  SEARCH_SORT,
  API_DATA,
} from '../../../../actions'
import Common from '../../../../constants/Common'

/**
 * @param {*} props 前画面から取得したデータ
 * @return {JSX} 混載業者BidDetails画面コンポーネント
 */
const FwdrBidDetails = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const data = props.history.location.state?.state

  const hasRequestBid = data.bidStatusObj.bidStatusId.includes(
    Common.BID_STATUS.REQUEST.ID
  )
  const hasDraftBid = data.bidStatusObj.forwarderDraftFlg.includes(true)
  /** 新規入札・荷主回答後画面フラグ */
  const isNew = hasRequestBid || hasDraftBid

  useEffect(() => {
    dispatch({
      type: API_DATA.DELETE,
    })

    //荷主回答後画面の場合、空欄のTransitを'-'に変更する
    if (!isNew) {
      data.bidDetailArr = data.bidDetailArr.map((data) => ({
        ...data,
        transitStr: data.transitStr === '' ? '-' : data.transitStr,
      }))
    }

    //テーブルの初期値を設定
    dispatch({
      type: TABLES.INIT,
      data: data.bidDetailArr,
      sort: ConstantsBidDetails.SORT_ITEM_ARR[
        ConstantsBidDetails.DEFAULT_SORT_TERMS
      ].sortTerm,
      isNew,
    })

    //新規画面・混載業者選択画面フラグを設定
    dispatch({
      type: FWDR_BID_DETAILS.IS_NEW.SET,
      isNew,
    })

    //SearchSortの初期値を設定
    dispatch({
      type: SEARCH_SORT.SET,
      search: '',
      sort: ConstantsBidDetails.SORT_ITEM_ARR[
        ConstantsBidDetails.DEFAULT_SORT_TERMS
      ].sortTerm,
    })

    dispatch({
      type: FWDR_BID_DETAILS.DATA.SET,
      data,
    })
  }, []) // eslint-disable-line

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <LayoutBox>
        <TitleBidDetails />
        <CheckboxesBidDetails />
        <SearchSortBidDetails />
        <TableBidDetails />
        <PagingBidDetails />
        <ButtonBidDetails />
      </LayoutBox>
    </AppContext.Provider>
  )
}

FwdrBidDetails.propTypes = {
  history: PropTypes.object.isRequired,
}

export default FwdrBidDetails
