// Display + 画面名
const DisplayAirlineSpaceCheck = {
  // 画面ID + 文言ID
  D204V0001: {
    en: 'SPACE CHECK',
    ja: 'スペースチェック',
  },
  D204V0002: {
    en: 'ORG/DST',
    ja: '出発/到着',
  },
  D204V0003: {
    en: 'Flight#',
    ja: 'フライト＃',
  },
  D204V0004: {
    en: 'ETD/ETA',
    ja: 'ETD/ETA',
  },
  D204V0005: {
    en: 'Aircraft',
    ja: '航空機',
  },
  D204V0006: {
    en: 'TTL Wgt./Max Wgt.(kg)',
    ja: '合計総重量/最大搭載重量(kg)',
  },
  D204V0007: {
    en: 'TTL Vol./Max Vol.(m3)',
    ja: '合計容積/最大搭載容積(m3)',
  },
  D204V0008: {
    en: 'Status',
    ja: 'ステータス',
  },
  D204V0009: {
    en: 'NRT/ORD',
    ja: 'NRT/ORD',
  },
  D204V0010: {
    en: 'KZXXX/XXAUG',
    ja: 'KZXXX/XXAUG',
  },
  D204V0011: {
    en: '11:25/16:30',
    ja: '11:25/16:30',
  },
  D204V0012: {
    en: 'Boeing747-8F',
    ja: 'ボーイング747-8F',
  },
  D204V0013: {
    en: '-178.17',
    ja: '-178.17',
  },
  D204V0014: {
    en: '/',
    ja: '/',
  },
  D204V0015: {
    en: '134,000.00',
    ja: '134,000.00',
  },
  D204V0016: {
    en: '3.54 / 858.10',
    ja: '3.54 / 858.10',
  },
  D204V0017: {
    en: '-',
    ja: '-',
  },
  D204V0018: {
    en: 'MAWB No.',
    ja: 'マスター番号',
  },
  D204V0019: {
    en: 'Pcs./Wgt./Vol.',
    ja: '個数/重量/容積',
  },
  D204V0020: {
    en: 'Description',
    ja: '説明',
  },
  D204V0021: {
    en: 'SHC Code',
    ja: 'SHCコード',
  },
  D204V0022: {
    en: 'FWDR',
    ja: '混載業者',
  },
  D204V0023: {
    en: 'Allotment',
    ja: 'アロットメント',
  },
  D204V0024: {
    en: 'Stn.',
    ja: 'Stn.',
  },
  D204V0025: {
    en: '933-XXXX XXXX',
    ja: '933-XXXX XXXX',
  },
  D204V0026: {
    en: 'XXpcs./XXX.XXkg/XX.XXm3',
    ja: 'XXpcs./XXX.XXkg/XX.XXm3',
  },
  D204V0027: {
    en: 'BEEF',
    ja: 'BEEF',
  },
  D204V0028: {
    en: 'PER',
    ja: 'PER',
  },
  D204V0029: {
    en: 'YLK',
    ja: 'YLK',
  },
  D204V0030: {
    en: 'ASAHAN',
    ja: 'ASAHAN',
  },
  D204V0031: {
    en: 'Space Confirmed',
    ja: 'スペース確保',
  },
  D204V0032: {
    en: 'NRT',
    ja: 'NRT',
  },
  D204V0033: {
    en: 'Details',
    ja: '詳細',
  },
  D204V0034: {
    en: 'CONSOL',
    ja: 'CONSOL',
  },
  D204V0035: {
    en: 'NEC',
    ja: 'NEC',
  },
  D204V0036: {
    en: 'FRO/ICE',
    ja: 'FRO/ICE',
  },
  D204V0037: {
    en: 'RLM/CAO',
    ja: 'RLM/CAO',
  },
  D204V0038: {
    en: 'KWE',
    ja: 'KWE',
  },
  D204V0039: {
    en: 'RESIN',
    ja: 'RESIN',
  },
  D204V0040: {
    en: 'RLF',
    ja: 'RLF',
  },
  D204V0041: {
    en: 'Booked',
    ja: '予約確定',
  },
  D204V0042: {
    en: 'HEA/VOL',
    ja: 'HEA/VOL',
  },
  D204V0043: {
    en: 'NRT/ORD/DFW',
    ja: 'NRT/ORD/DFW',
  },
  D204V0044: {
    en: '2P1C',
    ja: '2P1C',
  },
  D204V0045: {
    en: 'KKS',
    ja: 'KKS',
  },
  D204V0046: {
    en: 'KNJ',
    ja: 'KNJ',
  },
  D204V0047: {
    en: 'STBY TL XXAUG',
    ja: 'STBY TL XXAUG',
  },
  D204V0048: {
    en: 'COL',
    ja: 'COL',
  },
  D204V0049: {
    en: 'RMD',
    ja: 'RMD',
  },
  D204V0050: {
    en: 'CEV',
    ja: 'CEV',
  },
  D204V0051: {
    en: 'OHG',
    ja: 'OHG',
  },
  D204V0052: {
    en: 'NRT/ORD/ATL',
    ja: 'NRT/ORD/ATL',
  },
  D204V0053: {
    en: 'HEA',
    ja: 'HEA',
  },
  D204V0054: {
    en: 'HEI',
    ja: 'HEI',
  },
  D204V0055: {
    en: 'NRT/ORD/IAH',
    ja: 'NRT/ORD/IAH',
  },
  D204V0056: {
    en: 'MUST GO',
    ja: 'MUST GO',
  },
  D204V0057: {
    en: 'NGO',
    ja: 'NGO',
  },
  D204V0058: {
    en: 'NUS',
    ja: 'NUS',
  },
  D204V0059: {
    en: 'KIX',
    ja: 'KIX',
  },
  D204V0060: {
    en: '18.75',
    ja: '18.75',
  },
  D204V0061: {
    en: '9.43 / 858.10',
    ja: '9.43 / 858.10',
  },
  D204V0062: {
    en: '58.20',
    ja: '58.20',
  },
  D204V0063: {
    en: '79.45 / 858.10',
    ja: '79.45 / 858.10',
  },
  D204V0064: {
    en: 'Back',
    ja: '戻る',
  },
  D204V0065: {
    en: 'FUK',
    ja: 'FUK',
  },
  D204V0066: {
    en: 'HND',
    ja: 'HND',
  },
}
// Display + 画面名
export default DisplayAirlineSpaceCheck
