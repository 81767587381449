import React, { useContext } from 'react'
import AppContext from '../../../../../contexts/AppContext'

import CommonTableContainer from '../../../../common/table/CommonTableContainer'
import HeaderLightAirlineBookingList from './HeaderLightAirlineBookingList'
import ShcDialog from '../../../../common/dialog/commonDesignDialog/ShcDialog'
import TableContentsLightAirlineBookingList from './TableContentsLightAirlineBookingList'

/**
 * @return {JSX} 表コンポーネント(全体)
 */
const TableLightAirlineBookingList = () => {
  const { state } = useContext(AppContext)

  return (
    <CommonTableContainer isNoResult={state.Tables.isNoResult}>
      <HeaderLightAirlineBookingList />
      <TableContentsLightAirlineBookingList />
      <ShcDialog />
    </CommonTableContainer>
  )
}

export default TableLightAirlineBookingList
