import React, { useContext } from 'react'

import MawbNoLightBookingDetails from '../../../../commonUseContext/LightBookingDetails/MawbNo/MawbNoLightBookingDetails'
import AppContext from '../../../../../contexts/AppContext'

/**
 * @return {JSX} MawbNoアコーディオン
 */
const MawbNoFwdrLightBookingDetails = () => {
  const { state } = useContext(AppContext)
  const disabled = !state.Status.isSpaceConfirmed
  return <MawbNoLightBookingDetails disabled={disabled} />
}

export default MawbNoFwdrLightBookingDetails
