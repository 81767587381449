/* eslint-disable no-extra-parens */
import React, { useState, useEffect, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'

import LayoutBox from '../../../common/layout/LayoutBox'
import Title from '../../../common/title/Title'
import ConsigneeInfoTable from './ConsigneeInfoTable'
import AddDialog from './AddDialog'
import SaveAndBack from '../../../common/buttons/SaveAndBack'
import BackDialog from '../../../common/dialog/BackDialog'
import Paging from '../../../common/buttons/Paging'
import CommonFunc from '../../../common/function/CommonFunc'
import Paths from '../../../../constants/Paths'
import reducer from '../../../../reducers/master/masterShipConsigneeInfo'
import AppContext from '../../../../contexts/AppContext'
import ApiPaths from '../../../../constants/ApiPaths'
import {
  INIT_SHIP_TABLE,
  SEARCH_SHIP_TABLE,
  UPDATE_SHIP_PAGING_DATA,
  UPDATE_SHIP_SELECT_LIST,
} from '../../../../actions'
import restFacade from '../../../../actions/rest-facade'

const useStyles = makeStyles(() => ({
  buttons: {
    justifyContent: 'center',
    marginBottom: 30,
    marginTop: 30,
  },
  down: {
    marginBottom: 30,
    margin: 'auto',
  },
}))

const MasterShipConsigneeInfo = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const [saveComp, setSaveComp] = useState(false)
  const [errCode, setErrCode] = useState(0)
  const contentNum = 7 // 1ページに表示する行数

  const initialState = {
    Tables: {
      initData: [],
      showData: [[]],
      editData: [],
      contentNum,
    },
    SelectBox: {
      selData: [],
    },
    Paging: { page: 0 },
  }
  const [state, dispatch] = useReducer(reducer, initialState)

  // テーブルデータを取得
  const setConsigneeTablesData = (res) => {
    const result = res.data.results

    dispatch({
      type: INIT_SHIP_TABLE,
      data: result,
      amount: contentNum,
    })

    // ページの先頭に戻す
    window.scrollTo(0, 0)
  }

  // セレクトボックスデータを取得
  const setShipListData = (res) => {
    const result = res.data.results

    dispatch({
      type: UPDATE_SHIP_SELECT_LIST,
      data: result,
    })
  }

  // エラー処理、コールバック関数実行
  const setData = (res, callback) => {
    CommonFunc.callbackFunc(res, callback, history)
  }

  useEffect(() => {
    // 各データ取得
    restFacade.get(ApiPaths.MASTER_SHIPPER.CONSIGNEE_ADDRESS, (res) =>
      setData(res, setConsigneeTablesData)
    )
    restFacade.get(ApiPaths.MASTER_SHIPPER.SHIP_LIST, (res) =>
      setData(res, setShipListData)
    )
  }, []) // eslint-disable-line

  // Page切り替え時の動作
  const pageChange = (_e, value) => {
    window.scrollTo(0, 0)
    const index = value - 1
    dispatch({
      type: UPDATE_SHIP_PAGING_DATA,
      page: index,
    })
    dispatch({
      type: SEARCH_SHIP_TABLE,
      data: state.Tables.editData,
      amount: state.Tables.contentNum,
    })
  }

  // データ変更ありなし判定
  const chkInputChg = () => {
    const edit = JSON.stringify(state.Tables.editData)
    const init = JSON.stringify(state.Tables.initData)

    if (edit !== init) {
      return true
    }
    return false
  }

  // 保存完了後の処理
  const saveComplete = () => {
    setSaveComp(false)
    // データ再取得
    restFacade.get(ApiPaths.MASTER_SHIPPER.CONSIGNEE_ADDRESS, (res) =>
      setData(res, setConsigneeTablesData)
    )
    restFacade.get(ApiPaths.MASTER_SHIPPER.SHIP_LIST, (res) =>
      setData(res, setShipListData)
    )
  }

  // 保存完了時のコールバック
  const saveDataCB = (response) => {
    setSaveComp(true)
    setErrCode(response.data.info.ErrorCode)
  }

  // 保存処理
  const saveData = () => {
    const consigneeArray = state.Tables.editData
    const initArray = state.Tables.initData
    const initConsignee = initArray.map((init) => {
      return init.shipperJhId
    })

    const editConsignee = consigneeArray.filter((edit, index) => {
      if (!edit.shipperJhId) {
        return true
      }
      // 追加して保存せず削除した場合、リクエストに渡さない
      const check = initConsignee.find((value) => value === edit.shipperJhId)
      if (!check && edit.delFlg === 1) {
        return false
      }
      return (
        JSON.stringify(edit) !== JSON.stringify(state.Tables.initData[index])
      )
    })
    const req = {
      consigneeData: editConsignee,
      shipperJhId: CommonFunc.getLoginUserData().shipperJhId,
    }
    restFacade.post(
      ApiPaths.MASTER_SHIPPER.CONSIGNEE_ADDRESS,
      (res) => CommonFunc.callbackFunc(res, saveDataCB, history),
      req,
      req.consigneeData
    )
  }

  // Pagingコンポーネントに渡したいものをまとめる
  const pagingFactors = {
    totalPage: state.Tables.showData.length,
    pageAmount: state.Tables.contentNum,
    changeEvent: pageChange,
    page: state.Paging.page + 1,
  }

  // Backコンポーネントに渡したいものをまとめる
  const backFactors = {
    path: Paths.MASTER.SHIP_MASTER_TOP,
    inputchg: chkInputChg(),
  }

  // Saveコンポーネントに渡したいものをまとめる
  const saveFactors = {
    path: Paths.MASTER.SHIP_CONSIGNEE_INFO,
    saveevent: saveData,
    comp: saveComp,
    compevent: saveComplete,
    errcode: errCode,
  }

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <LayoutBox>
        <Title>{t('D301V0085')}</Title>
        <Grid
          container
          style={{ marginBottom: '3rem' }}
          justify="center"
          className={classes.table}
        >
          <Grid item gmd={10} xs={10}>
            <ConsigneeInfoTable rows={state.Tables.showData} />
          </Grid>
        </Grid>
        <Grid container justify="flex-end">
          <Grid item style={{ marginRight: '12%' }}>
            {CommonFunc.getLoginUserData().authorityFlg === 1 && (
              <AddDialog
                label={t('D301V0081')}
                option={state.SelectBox.selData}
                multiline
              />
            )}
          </Grid>
        </Grid>
        <Paging pagingfactors={pagingFactors} />
        <Grid
          container
          justify="center"
          direction="column"
          alignItems="center"
          className={classes.down}
        >
          <Grid item md={10} xs={10} className={classes.buttons}>
            {CommonFunc.getLoginUserData().authorityFlg === 1 ? (
              <SaveAndBack
                backFactors={backFactors}
                saveFactors={saveFactors}
              />
            ) : (
              <BackDialog backFactors={backFactors} />
            )}
          </Grid>
        </Grid>
      </LayoutBox>
    </AppContext.Provider>
  )
}

export default MasterShipConsigneeInfo
