import Common from '../../../../constants/Common'

/** @enum {Number} 表示ユーザの値 */
const ShowUser = {
  gsa: 0, // GSAのみ
  both: 1, // CarrierとGSA両方
}

const ConstantsLightCarAirFreight = {
  /** @type {Number} 1ページに表示する件数 */
  PAGE_AMOUNT: 5, //3行*5件=15行表示

  // テーブル作成に必要な値
  TABLE_HEADER_ARR: [
    { key: 'D501V0005', showUser: ShowUser.gsa },
    { key: 'D301V0087', showUser: ShowUser.both },
    { key: '2nd0017', showUser: ShowUser.both },
    { key: 'D301V0234', showUser: ShowUser.both },
    { key: 'D301V0089', showUser: ShowUser.both },
    { key: 'D301V0090', showUser: ShowUser.both },
    { key: 'D301V0091', showUser: ShowUser.both },
    { key: 'D301V0092', showUser: ShowUser.both },
    { key: 'D301V0093', showUser: ShowUser.both },
    { key: 'D301V0094', showUser: ShowUser.both },
    { key: 'D301V0095', showUser: ShowUser.both },
  ],

  // Gsaユーザに合わせたテーブルヘッダーを返す
  getHeaderKeyArr: (gsa) =>
    ConstantsLightCarAirFreight.TABLE_HEADER_ARR.filter(({ showUser }) => {
      const isBoth = showUser === ShowUser.both
      const isGsaUser = gsa === Common.GSA_FLG.GSA
      const isGsa = showUser === ShowUser.gsa && isGsaUser
      return isBoth || isGsa
    }).map(({ key }) => key),
}

export default ConstantsLightCarAirFreight
export { ShowUser }
