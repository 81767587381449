/* eslint-disable no-extra-parens */
/* eslint-disable no-unneeded-ternary */
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import CardButton from '../../../common/buttons/CardButton'
import CommonFunc from '../../../common/function/CommonFunc'
import Common from '../../../../constants/Common'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    [theme.breakpoints.up('md')]: {
      height: `calc(100vh - ${theme.header.height} * 2 - 1rem)`,
      minHeight: '310px',
    },
  },
  center: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '257px',
      margin: '0 auto',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
    },
  },
  desktop: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))

/**
 * @param {Object} props - 以下
 * @param {Object} paths - 各画面へのパスを持ったオブジェクト
 * @returns {*} - MasterFwdrTopButtons
 */
const MasterFwdrTopButtons = (props) => {
  const classes = useStyles()
  const auth = CommonFunc.getLoginUserData().authorityFlg

  return (
    <Grid container className={classes.root}>
      {/* 1行目 */}
      <Grid container justify="center" alignItems="center">
        <Grid item md={3} xs={12}>
          <div className={classes.center}>
            <CardButton path={props.paths.companyInfo} />
          </div>
        </Grid>
        <Grid item md={3} xs={12}>
          <div className={classes.center}>
            <CardButton path={props.paths.myProfile} />
          </div>
        </Grid>
        <Grid item md={3} xs={12}>
          <div className={classes.center}>
            <CardButton
              path={props.paths.accountInfo}
              disabled={auth === Common.AUTHORITY_FLG.OFF}
            />
          </div>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default MasterFwdrTopButtons
