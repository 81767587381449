import { TOTAL_ACCOUNT } from '../../actions'

const initTotalAccount = null

/**
 * ユーザー数を保持
 * @param {Object} [state=initTotalAccount] -
 * @param {Object} action - dispatchで受け取ったaction
 * @return {Object} - セレクトボックスオブジェクト
 */
const TotalAccount = (state = initTotalAccount, action) => {
  const { type, userCount } = action
  switch (type) {
    case TOTAL_ACCOUNT.SET:
      return userCount
    default:
      return state
  }
}

export default TotalAccount

export { initTotalAccount }
