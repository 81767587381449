const ConstantsLightJhClientRegDeleteAccount = {
  TEXT_SETTING: {
    Q1_ARR: [
      'D501V0102',
      'D501V0103',
      'D501V0104',
      'D501V0105',
      'D501V0106',
      'D501V0107',
      'D501V0108',
      'D501V0109',
      'D501V0110',
    ],
    Q2_ARR: ['D501V0113', 'D501V0114', 'D501V0115', 'D501V0110'],
    Q4_ARR: ['D501V0118', 'D501V0119', 'D501V0120', 'D501V0121', 'D501V0122'],
  },
}

export default ConstantsLightJhClientRegDeleteAccount
