import React, { useContext } from 'react'
import AppContext from '../../../../../contexts/AppContext'

import CommonTableContainer from '../../../../common/table/CommonTableContainer'
import HeaderBidMgmt from './HeaderBidMgmt'
import TableContentsBidMgmt from './TableContentsBidMgmt'

/**
 * @return {JSX} 表コンポーネント(全体)
 */
const TableBidMgmt = () => {
  const { state } = useContext(AppContext)
  /** @type {Boolean} 表データがない場合true */
  const isNoResult = state.Tables.showData[0].length <= 0

  return (
    <CommonTableContainer isNoResult={isNoResult}>
      <HeaderBidMgmt />
      <TableContentsBidMgmt />
    </CommonTableContainer>
  )
}

export default TableBidMgmt
