import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  noResult: theme.noResult,
}))

/**
 * 表のデータがない時のNo Requestコンポーネント
 * @return {JSX} No Requestコンポーネント
 */
const CommonNoRequest = ({ show }) => {
  const classes = useStyles()

  return show && <div className={classes.noResult}>No Request</div>
}

export default CommonNoRequest
