/* eslint-disable prefer-template */
import React, { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Divider from '@material-ui/core/Divider'

import {
  SEARCH_SORT_DATA,
  GET_TABLE_DATA,
  PAGING_DATA,
} from '../../../../actions'
import PrimaryButton from '../../../common/buttons/PrimaryButton'
import SecondaryButton from '../../../common/buttons/SecondaryButton'
import Dialog from '../../../common/dialog/Dialog'
import EditIconButton from '../../../common/buttons/EditIconButton'
import AppContext from '../../../../contexts/AppContext'
import Checkbox from '../../../common/checkBox/CheckBox'
import InputSelect from '../../../common/select/InputSelect'
import DatePicker from '../../../common/datePicker/DatePicker'
import CommonFunc from '../../../common/function/CommonFunc'
import ApiPaths from '../../../../constants/ApiPaths'
import restFacade from '../../../../actions/rest-facade'

const useStyles = makeStyles((theme) => ({
  headText: theme.textDialogHead,
  text: theme.textNormalBLK,
  companyName: {
    marginLeft: '1rem',
    paddingTop: '12px',
    height: '36px',
  },
  refinedText: theme.textSmallBLU,
  divider: {
    background: theme.palette.primary.main,
  },
  shadow: {
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
  },
}))

let loginUserData = {}
let setData = () => {}

const SearchDialog = () => {
  const { t, i18n } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const { state, dispatch } = useContext(AppContext)

  const [companyName, setCompanyName] = useState('')
  const [sameCompany, setSameCompany] = useState(false)
  const [acdigi, setAcdigi] = useState(false)
  const [fwdr, setFwdr] = useState(null)
  const [shipper, setShipper] = useState(null)
  const [carrier, setCarrier] = useState(null)
  const [date, setDate] = useState(null)
  const [error, setError] = useState(false)

  const [fwdrItem, setFwdrItem] = useState([])
  const [shipperItem, setShipperItem] = useState([])
  const [carrierItem, setCarrierItem] = useState([])
  loginUserData = CommonFunc.getLoginUserData()

  //セレクトの中身(会社名)と現在の値を取得
  const setItems = () => {
    let shipper = state.Tables.initData
      .filter((v) => v.sender === 1)
      .map((v) => v.companyName)
    let fwdr = state.Tables.initData
      .filter((v) => v.sender === 2)
      .map((v) => v.companyName)
    let carrier = state.Tables.initData
      .filter((v) => v.sender === 3)
      .map((v) => v.companyName)

    const setItem = (array) => {
      const result = array
        .sort((a, b) => (a > b ? 1 : -1))
        .filter((v) => v)
        .map((v) => ({ name: v }))
      result.unshift({ name: 'All' })
      return result
    }

    fwdr = setItem(Array.from(new Set(fwdr)))
    shipper = setItem(Array.from(new Set(shipper)))
    carrier = setItem(Array.from(new Set(carrier)))

    const getCurrentItem = (array, value) => {
      if (value === '') {
        return null
      }
      const item = array.find((v) => v.name === value)
      return item ? item : null
    }

    setFwdr(getCurrentItem(fwdr, state.Sort.fwdr))
    setShipper(getCurrentItem(shipper, state.Sort.shipper))
    setCarrier(getCurrentItem(carrier, state.Sort.carrier))
    setFwdrItem(fwdr)
    setShipperItem(shipper)
    setCarrierItem(carrier)
  }

  //初期化
  const initialize = () => {
    setItems()
    setAcdigi(state.Sort.acdigi)
    setSameCompany(state.Sort.sameCompany !== '')
    setDate(state.Sort.date ? new Date(state.Sort.date) : null)
  }

  //search押下時のイベント
  const search = () => {
    let searchDate = ''
    // 日付を検索用にフォーマットを変更
    if (date !== null) {
      const fixedDate = `00${date.getDate()}`.slice(-2)
      const fixedMonth = `00${date.getMonth() + 1}`.slice(-2)
      searchDate = `${date.getFullYear()}-${fixedMonth}-${fixedDate}`
    }

    // 検索結果がセレクトの結果と重複しないようにSameCompanyの値を変更
    let fixedSameCompany = sameCompany ? companyName : ''
    if (fixedSameCompany !== '') {
      if (shipper !== null && loginUserData.shipperJhId !== null) {
        if (shipper.name === 'All' || shipper.name === companyName) {
          fixedSameCompany = ''
        }
      } else if (fwdr !== null && loginUserData.fwdrId !== null) {
        if (fwdr.name === 'All' || fwdr.name === companyName) {
          fixedSameCompany = ''
        }
      } else if (carrier !== null && loginUserData.carrierId !== null) {
        if (carrier.name === 'All' || carrier.name === companyName) {
          fixedSameCompany = ''
        }
      }
    }

    dispatch({
      type: SEARCH_SORT_DATA,
      sort: {
        sameCompany: fixedSameCompany,
        acdigi,
        fwdr: fwdr !== null ? fwdr.name : '',
        shipper: shipper !== null ? shipper.name : '',
        carrier: carrier !== null ? carrier.name : '',
        date: searchDate,
      },
    })
    dispatch({
      // 表示
      type: GET_TABLE_DATA,
      sort: state.Sort,
      i18n,
    })
    dispatch({
      type: PAGING_DATA,
      page: 1,
    })
  }

  //clear押下時のイベント
  const clearClick = () => {
    setSameCompany(false)
    setAcdigi(false)
    setFwdr(fwdrItem[0])
    setShipper(shipperItem[0])
    setCarrier(carrierItem[0])
    setDate('')
    dispatch({
      type: SEARCH_SORT_DATA,
      sort: {
        sameCompany: '',
        acdigi: false,
        fwdr: '',
        shipper: '',
        carrier: '',
        date: '',
      },
    })
    dispatch({
      // 表示
      type: GET_TABLE_DATA,
      sort: state.Sort,
      i18n,
    })
    dispatch({
      type: PAGING_DATA,
      page: 1,
    })
  }

  // 会社名取得時の処理
  const setCompanyNameData = (res) => {
    setCompanyName(res.data.results.companyName)
  }

  // エラー処理、コールバック関数実行
  setData = (res, callback) => {
    CommonFunc.callbackFunc(res, callback, history)
  }

  useEffect(() => {
    //会社名取得
    if (loginUserData.shipperJhId !== null) {
      if (loginUserData.shipperJhFlg === 1) {
        restFacade.get(ApiPaths.MASTER_JH.COMPANY_INFO, (res) =>
          setData(res, setCompanyNameData)
        )
      } else {
        restFacade.get(ApiPaths.MASTER_SHIPPER.SHIP_COMPANY_INFO, (res) =>
          setData(res, setCompanyNameData)
        )
      }
    } else if (loginUserData.fwdrId !== null) {
      restFacade.get(ApiPaths.MASTER_FWDR.FWDR_COMPANY_INFO, (res) =>
        setData(res, setCompanyNameData)
      )
    } else if (loginUserData.carrierId !== null) {
      restFacade.get(ApiPaths.MASTER_CARRIER.CAR_COMPANY_INFO, (res) =>
        setData(res, setCompanyNameData)
      )
    }
  }, [])

  const onError = (value) => {
    setError(Boolean(value))
  }

  return (
    <Dialog
      buttonPC={
        <IconButton onClick={initialize}>
          <div>
            <img
              className={classes.shadow}
              src="../../../../../../images/RefinedSearch.svg"
              alt="refined search"
            />
            <div className={classes.refinedText + ' ' + classes.shadow}>
              {t('D601V0008')}
            </div>
          </div>
        </IconButton>
      }
      buttonMobile={<EditIconButton onClick={initialize} />}
      primary={
        <PrimaryButton onClick={search} disabled={error}>
          {t('D601V0023')}
        </PrimaryButton>
      }
      secondary={<SecondaryButton>{t('D601V0022')}</SecondaryButton>}
      detail={
        <SecondaryButton onClick={clearClick}>{t('D601V0021')}</SecondaryButton>
      }
      primaryDisabled={error}
    >
      {/* Sender */}
      <Grid container item md={10} xs={10} className={classes.headText}>
        {t('D601V0015')}
      </Grid>

      {/* Same Company */}
      <Grid
        container
        item
        md={10}
        xs={10}
        justify="flex-end"
        alignItems="center"
      >
        <Grid item md={6} xs={6} className={classes.text}>
          {t('D601V0016')}
        </Grid>
        <Grid item md={5} xs={5}>
          <Checkbox
            checked={sameCompany}
            onChange={(_event, value) => setSameCompany(value)}
          />
        </Grid>
      </Grid>

      {/* Air Cargo Sharing Digital Platform */}
      <Grid
        container
        item
        md={10}
        xs={10}
        justify="flex-end"
        alignItems="center"
      >
        <Grid item md={6} xs={6} className={classes.text}>
          {t('D601V0017')}
        </Grid>
        <Grid item md={5} xs={5}>
          <Checkbox
            checked={acdigi}
            onChange={(_event, value) => setAcdigi(value)}
          />
        </Grid>
      </Grid>

      {/* Shipper */}
      <Grid
        container
        item
        md={10}
        xs={10}
        justify="flex-end"
        alignItems="center"
      >
        <Grid item md={4} xs={4} className={classes.text}>
          {t('D601V0020')}
        </Grid>
        <Grid item md={7} xs={7}>
          {loginUserData.shipperJhId === null ? (
            <InputSelect
              value={shipper}
              options={shipperItem}
              getOptionLabel={(option) => option.name}
              onChange={(_event, value) => setShipper(value)}
            />
          ) : (
            <div className={classes.text + ' ' + classes.companyName}>
              {companyName}
            </div>
          )}
        </Grid>
      </Grid>

      {/* FWDR */}
      <Grid
        container
        item
        md={10}
        xs={10}
        justify="flex-end"
        alignItems="center"
      >
        <Grid item md={4} xs={4} className={classes.text}>
          {t('D601V0018')}
        </Grid>
        <Grid item md={7} xs={7}>
          {loginUserData.fwdrId === null ? (
            <InputSelect
              value={fwdr}
              options={fwdrItem}
              getOptionLabel={(option) => option.name}
              onChange={(_event, value) => setFwdr(value)}
            />
          ) : (
            <div className={classes.text + ' ' + classes.companyName}>
              {companyName}
            </div>
          )}
        </Grid>
      </Grid>

      {/* Carrier */}
      <Grid
        container
        item
        md={10}
        xs={10}
        justify="flex-end"
        alignItems="center"
      >
        <Grid item md={4} xs={4} className={classes.text}>
          {t('D601V0019')}
        </Grid>
        <Grid item md={7} xs={7}>
          {loginUserData.carrierId === null ? (
            <InputSelect
              value={carrier}
              options={carrierItem}
              getOptionLabel={(option) => option.name}
              onChange={(_event, value) => setCarrier(value)}
            />
          ) : (
            <div className={classes.text + ' ' + classes.companyName}>
              {companyName}
            </div>
          )}
        </Grid>
      </Grid>

      <Grid item md={11} xs={11}>
        <Divider className={classes.divider} />
      </Grid>

      {/* Date */}
      <Grid container item md={10} xs={10} className={classes.headText}>
        {t('D601V0009')}
      </Grid>
      <Grid container item md={10} xs={10} justify="center">
        <Grid item md={7} xs={7}>
          <DatePicker
            defaultValue={date}
            onChange={(value) => setDate(value)}
            disableFuture
            onError={onError}
          />
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default SearchDialog
