import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'

import AccordionDetails from '../../../../common/accordion/AccordionDetails'
import Content from './Content'
import CommonCalc from '../../../../common/function/CommonCalc'
import AppContext from '../../../../../contexts/AppContext'
import EditButtonLightFlightMgmt from './EditButtonLightFlightMgmt'

/**
 * @param {*} props 下記内容のProps
 * @param {Number} props.index 1行分の航空便データ
 * @returns {JSX} FlightInfoアコーディオンの内容
 */
const FlightInfoAccordionDetails = (props) => {
  const { t } = useTranslation()
  const { state } = useContext(AppContext)
  const { index } = props

  /** @type {Number} showDataのインデックス */
  const page = CommonCalc.calcShowDataIndex(
    state.Paging,
    state.Tables.showData.length
  )

  return useMemo(() => {
    const { displayRouting, displayTransitTime, effectiveDate } =
      state.Tables.showData[page][index]

    return (
      <AccordionDetails>
        <Grid
          container
          justify="space-evenly"
          alignItems="center"
          alignContent="flex-end"
        >
          <Grid item md={4}>
            <Content title={t('4th0006')} content={effectiveDate} bold />
          </Grid>
          <Grid item md={3}>
            <Content title={t('D301V0222')} content={displayRouting} bold />
          </Grid>
          <Grid item md={3}>
            <Content title={t('D301V0073')} content={displayTransitTime} bold />
          </Grid>
          <Grid item>
            <EditButtonLightFlightMgmt index={index} />
          </Grid>
        </Grid>
      </AccordionDetails>
    )
  }, [page, index, t, state.Tables.showData])
}

FlightInfoAccordionDetails.propTypes = {
  index: PropTypes.number.isRequired,
}
export default FlightInfoAccordionDetails
