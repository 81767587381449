/* eslint-disable no-extra-parens */
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineDot from '@material-ui/lab/TimelineDot'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    fontWeight: 'bold',
    '& .MuiTimelineDot-defaultGrey': {
      color: theme.palette.info.main,
      borderColor: theme.palette.letter,
      backgroundColor: theme.palette.info.main,
      flexDirection: 'row',
    },
    '& .MuiTimelineConnector-root': {
      backgroundColor: theme.palette.letter,
    },
    '& .MuiTimelineContent-root': {
      padding: '3px 16px',
    },
    '& .MuiTimelineItem-missingOppositeContent:before': {
      flex: 0,
      padding: 0,
    },
  },
}))

const Separator = () => {
  return (
    <TimelineSeparator>
      <TimelineDot />
      <TimelineConnector />
    </TimelineSeparator>
  )
}

const TimeLine = (props) => {
  const classes = useStyles()
  return (
    <Timeline className={classes.root}>
      {props.line.map((TimeLineTitle, index) => (
        <TimelineItem key={index}>
          {index === props.line.length - 1 ? <TimelineDot /> : Separator()}
          <TimelineContent>{TimeLineTitle}</TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  )
}
export default TimeLine
