import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(() => ({
  loadingBack: {
    position: 'fixed',
    backgroundColor: 'rgba(10,10,10,0.5)',
    height: '100%',
    width: '100%',
    top: '0',
    zIndex: '99999',
  },
  loading: {
    position: 'fixed',
    top: 'calc(50% - 40px/2)',
    left: 'calc(50% - 40px/2)',
    zIndex: '99999',
  },
}))

/**
 * ローダー
 * @returns {JSX.Element} - Loading
 */
const Loading = () => {
  const classes = useStyles()
  return (
    <div id="loading" hidden className={classes.loadingBack}>
      <CircularProgress className={classes.loading} />
    </div>
  )
}

export default Loading
