import {
  UPDATE_SHIP_FIRST_NAME,
  UPDATE_SHIP_LAST_NAME,
  UPDATE_SHIP_MAIL,
  UPDATE_SHIP_TEL,
  UPDATE_SHIP_POSITION,
  UPDATE_SHIP_DEPARTMENT,
  UPDATE_SHIP_PASSWORD,
  UPDATE_SHIP_CURRENTPASS,
  UPDATE_SHIP_NEWPASS,
  UPDATE_SHIP_NEWPASS_VERIFY,
  UPDATE_SHIP_SIGNATURE,
  UPDATE_SHIP_MAIL_SETTING,
  UPDATE_SHIP_CURRENTPASS_ERR,
  UPDATE_SHIP_PASS_VERIFY_ERR,
  UPDATE_SHIP_UPDATE_AT,
  UPDATE_SHIP_FIRST_NAME_INPUTERR,
  UPDATE_SHIP_LAST_NAME_INPUTERR,
  UPDATE_SHIP_MAIL_INPUTERR,
  UPDATE_SHIP_TEL_INPUTERR,
  UPDATE_SHIP_POSITION_INPUTERR,
  UPDATE_SHIP_DEPARTMENT_INPUTERR,
  UPDATE_SHIP_CURRENTPASS_INPUTERR,
  UPDATE_SHIP_NEWPASS_INPUTERR,
  UPDATE_SHIP_NEWPASS_VERIFY_INPUTERR,
  UPDATE_SHIP_IS_DELETE_ICON,
  UPDATE_SHIP_PASS_DIGIT_ERR,
  UPDATE_SHIP_SIGNATURE_INPUTERR,
} from '../../../actions'

const Events = (state = {}, action) => {
  switch (action.type) {
    //------------------
    // firstNameを更新
    //------------------
    case UPDATE_SHIP_FIRST_NAME:
      return { ...state, name: { ...state.name, value: action.name } }
    case UPDATE_SHIP_FIRST_NAME_INPUTERR:
      return { ...state, name: { ...state.name, inputErr: action.inputErr } }
    //------------------
    // lastNameを更新
    //------------------
    case UPDATE_SHIP_LAST_NAME:
      return {
        ...state,
        lastName: { ...state.lastName, value: action.lastName },
      }
    case UPDATE_SHIP_LAST_NAME_INPUTERR:
      return {
        ...state,
        lastName: { ...state.lastName, inputErr: action.inputErr },
      }
    //------------------
    // mailを更新
    //------------------
    case UPDATE_SHIP_MAIL:
      return { ...state, email: { ...state.email, value: action.email } }
    case UPDATE_SHIP_MAIL_INPUTERR:
      return { ...state, email: { ...state.email, inputErr: action.inputErr } }
    //------------------
    // telを更新
    //------------------
    case UPDATE_SHIP_TEL:
      return { ...state, tel: { ...state.tel, value: action.tel } }
    case UPDATE_SHIP_TEL_INPUTERR:
      return { ...state, tel: { ...state.tel, inputErr: action.inputErr } }
    //------------------
    // positionを更新
    //------------------
    case UPDATE_SHIP_POSITION:
      return {
        ...state,
        position: { ...state.position, value: action.position },
      }
    case UPDATE_SHIP_POSITION_INPUTERR:
      return {
        ...state,
        position: { ...state.position, inputErr: action.inputErr },
      }
    //------------------
    // departmentを更新
    //------------------
    case UPDATE_SHIP_DEPARTMENT:
      return {
        ...state,
        department: { ...state.department, value: action.department },
      }
    case UPDATE_SHIP_DEPARTMENT_INPUTERR:
      return {
        ...state,
        department: { ...state.department, inputErr: action.inputErr },
      }
    //------------------
    // passwordを更新
    //------------------
    case UPDATE_SHIP_PASSWORD:
      return {
        ...state,
        password: { ...state.password, value: action.password },
      }
    //------------------
    // currentPassを更新
    //------------------
    case UPDATE_SHIP_CURRENTPASS:
      return {
        ...state,
        currentPassword: {
          ...state.currentPassword,
          value: action.currentPassword,
        },
      }
    case UPDATE_SHIP_CURRENTPASS_INPUTERR:
      return {
        ...state,
        currentPassword: {
          ...state.currentPassword,
          inputErr: action.inputErr,
        },
      }
    //------------------
    // newPassを更新
    //------------------
    case UPDATE_SHIP_NEWPASS:
      return {
        ...state,
        newPassword: { ...state.newPassword, value: action.newPassword },
      }
    case UPDATE_SHIP_NEWPASS_INPUTERR:
      return {
        ...state,
        newPassword: { ...state.newPassword, inputErr: action.inputErr },
      }
    //------------------
    // newPassVerifyを更新
    //------------------
    case UPDATE_SHIP_NEWPASS_VERIFY:
      return {
        ...state,
        newPassVerify: { ...state.newPassVerify, value: action.newPassVerify },
      }
    case UPDATE_SHIP_NEWPASS_VERIFY_INPUTERR:
      return {
        ...state,
        newPassVerify: { ...state.newPassVerify, inputErr: action.inputErr },
      }
    //------------------
    // signPictureを更新
    //------------------
    case UPDATE_SHIP_SIGNATURE:
      return {
        ...state,
        signPicture: { ...state.signPicture, value: action.signPicture },
      }
    case UPDATE_SHIP_SIGNATURE_INPUTERR:
      return {
        ...state,
        signPicture: { ...state.signPicture, inputErr: action.inputErr },
      }
    //------------------
    // mailSettingを更新
    //------------------
    case UPDATE_SHIP_MAIL_SETTING:
      return {
        ...state,
        mailSetting: { ...state.mailSetting, value: action.mailSetting },
      }
    //------------------
    // passwordErrを更新
    //------------------
    case UPDATE_SHIP_CURRENTPASS_ERR:
      return {
        ...state,
        passwordErr: { ...state.passwordErr, current: action.errflg },
      }
    case UPDATE_SHIP_PASS_VERIFY_ERR:
      return {
        ...state,
        passwordErr: { ...state.passwordErr, verify: action.errflg },
      }
    case UPDATE_SHIP_PASS_DIGIT_ERR:
      return {
        ...state,
        passwordErr: { ...state.passwordErr, digit: action.errflg },
      }
    //------------------
    // updatedAtを更新
    //------------------
    case UPDATE_SHIP_UPDATE_AT:
      return {
        ...state,
        updatedAt: { ...state.updatedAt, value: action.updatedAt },
      }
    //------------------
    // isDeleteIconを更新
    //------------------
    case UPDATE_SHIP_IS_DELETE_ICON:
      return {
        ...state,
        isDeleteIcon: { ...state.isDeleteIcon, value: action.isDeleteIcon },
      }
    default:
      return state
  }
}

export default Events
