import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'

import AppContext from '../../../../../contexts/AppContext'
import CommonCalc from '../../../../common/function/CommonCalc'

const useStyles = makeStyles((theme) => ({
  bold: theme.textBoldBLU,
  text: theme.textNormalBLU,
  margin: { marginTop: '1rem' },
  divider: {
    marginTop: '1rem',
    background: 'rgba(89, 89, 89, 0.6)',
  },
}))

const CargoSummaryLightBookingRequest = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { state } = useContext(AppContext)

  const pcsTotal = state.cargoDetailArr.reduce(
    (acc, cargo) => acc + parseInt(cargo.pcs),
    0
  )
  const gwTotal = state.cargoDetailArr.reduce(
    (acc, cargo) => acc + parseFloat(cargo.grossWgt) * parseInt(cargo.pcs),
    0
  )
  const volTotal = state.cargoDetailArr.reduce(
    (acc, cargo) => acc + parseFloat(cargo.volume),
    0
  )
  const cwTotal = state.cargoDetailArr.reduce(
    (acc, cargo) => acc + parseFloat(cargo.chargeableWgt),
    0
  )

  return (
    <Grid container justify="center">
      <Grid container item justify="center">
        <Grid item md={10} xs={10}>
          <Divider className={classes.divider} />
        </Grid>
      </Grid>
      <Grid
        container
        item
        className={classes.margin}
        justify="flex-start"
        md={10}
        xs={10}
        spacing={2}
      >
        <Grid item className={classes.bold}>
          {`${t('D004V0028')}:`}
        </Grid>
        <Grid item className={classes.text}>
          {`${t('D004V0029')} ${t('D004V0014')} `}
          <span className={classes.bold}>{CommonCalc.addComma(pcsTotal)}</span>
        </Grid>
        <Grid item className={classes.text}>
          {`${t('D004V0029')} ${t('D004V0030')} `}
          <span className={classes.bold}>
            {CommonCalc.addComma(gwTotal, true)}
          </span>
          {t('D004V0031')}
        </Grid>
        <Grid item className={classes.text}>
          {`${t('D004V0029')} ${t('D004V0032')} `}
          <span className={classes.bold}>
            {CommonCalc.addComma(volTotal, true)}
          </span>
          {t('D004V0034')}
        </Grid>
        <Grid item className={classes.text}>
          {`${t('D004V0029')} ${t('D004V0033')} `}
          <span className={classes.bold}>
            {CommonCalc.addComma(cwTotal, true)}
          </span>
          {t('D004V0031')}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CargoSummaryLightBookingRequest
