/* eslint-disable prefer-template */
import React from 'react'
import MTextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: theme.textField,
  rootBlue: theme.textFieldBLU,
  input: {
    '&:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 1000px rgba(238,238,238,1) inset',
      WebkitTextFillColor: '#595959',
    },
  },
  inputBlue: {
    '&:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 1000px rgba(238,238,238,1) inset',
      WebkitTextFillColor: '#375CA7',
    },
  },
}))

/**
 * @param {Object} props - 以下
 * @param {String} id - idを指定
 * @param {String} label - labelを指定
 * @param {useStyles} className - スタイルを指定
 * @param {String} placeholder - placeholderを指定
 * @param {Boolean} disabled - 入力不可にする
 * @param {Boolean} blue - 青文字にする
 * @param {*} etc... - その他Material-UI TextFieldを参照してください
 * @returns {JSX.Element} - TextField
 */
const TextField = (props) => {
  const classes = useStyles()
  // eslint-disable-next-line no-unused-vars
  const { blue, className, ...rest } = props
  return (
    <MTextField
      id={props.id}
      label={props.label}
      disabled={props.disabled}
      className={
        blue
          ? classes.rootBlue + ' ' + props.className
          : classes.root + ' ' + props.className
      }
      variant="filled"
      size="small"
      inputProps={{ className: blue ? classes.inputBlue : classes.input }}
      {...rest}
    />
  )
}

export default TextField
