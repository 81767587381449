import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import Title from '../../../../common/title/Title'

/**
 * @return {JSX} タイトルコンポーネント
 */
const TitleLightCarFlightMgmt = () => {
  const { t } = useTranslation()
  return useMemo(() => {
    return <Title>{t('4th0009')}</Title>
  }, [t])
}

export default TitleLightCarFlightMgmt
