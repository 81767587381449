// Display + 画面名
const DisplayFwdrBookingList = {
  // 画面ID + 文言ID
  D102V0001: {
    en: 'BOOKING LIST',
    ja: '予約一覧',
  },
  D102V0002: {
    en: 'Default: KIX',
    ja: 'デフォルト：KIX',
  },
  D102V0003: {
    en: 'Shipper',
    ja: '荷主',
  },
  D102V0004: {
    en: 'HAWB No.',
    ja: 'ハウス番号',
  },
  D102V0005: {
    en: 'Pcs./Wgt./Vol.',
    ja: '個数/重量/容積',
  },
  D102V0006: {
    en: 'ORG/DST',
    ja: '出発/到着',
  },
  D102V0007: {
    en: 'Ready Date',
    ja: '出荷日',
  },
  D102V0008: {
    en: 'Status',
    ja: 'ステータス',
  },
  D102V0009: {
    en: 'Confirm',
    ja: '確認',
  },
  D102V0010: {
    en: 'XXXXXX Corporation',
    ja: 'XXXXXX Corporation',
  },
  D102V0011: {
    en: '-',
    ja: '-',
  },
  D102V0012: {
    en: 'XXX-XXXX XXXXX',
    ja: 'XXX-XXXX XXXXX',
  },
  D102V0013: {
    en: '10pcs/300kg/2.05m3',
    ja: '10pcs/300kg/2.05m3',
  },
  D102V0014: {
    en: 'KIX/SYD',
    ja: 'KIX/SYD',
  },
  D102V0015: {
    en: 'KIX/YYZ',
    ja: 'KIX/YYZ',
  },
  D102V0016: {
    en: 'KIX/MNL',
    ja: 'KIX/MNL',
  },
  D102V0017: {
    en: 'HND/SFO',
    ja: 'HND/SFO',
  },
  D102V0018: {
    en: 'KIX/HKG',
    ja: 'KIX/HKG',
  },
  D102V0019: {
    en: 'NRT/CDG',
    ja: 'NRT/CDG',
  },
  D102V0020: {
    en: 'NRT/SVO',
    ja: 'NRT/SVO',
  },
  D102V0021: {
    en: 'KIX/BKK',
    ja: 'KIX/BKK',
  },
  D102V0022: {
    en: 'KIX/PVG',
    ja: 'KIX/PVG',
  },
  D102V0023: {
    en: 'FUK/TPE',
    ja: 'FUK/TPE',
  },
  D102V0024: {
    en: 'KIX/JFK',
    ja: 'KIX/JFK',
  },
  D102V0025: {
    en: 'HND/YUL',
    ja: 'HND/YUL',
  },
  D102V0026: {
    en: 'HND/MUC',
    ja: 'HND/MUC',
  },
  D102V0027: {
    en: 'KIX/HNL',
    ja: 'KIX/HNL',
  },
  D102V0028: {
    en: 'KIX/DMM',
    ja: 'KIX/DMM',
  },
  D102V0029: {
    en: 'KIX/DUB',
    ja: 'KIX/DUB',
  },
  D102V0030: {
    en: 'August 11,2020',
    ja: '2020/8/11',
  },
  D102V0031: {
    en: 'August 10,2020',
    ja: '2020/8/10',
  },
  D102V0032: {
    en: 'August 9,2020',
    ja: '2020/8/9',
  },
  D102V0033: {
    en: 'August 8,2020',
    ja: '2020/8/8',
  },
  D102V0034: {
    en: 'August 6,2020',
    ja: '2020/8/6',
  },
  D102V0035: {
    en: 'August 5,2020',
    ja: '2020/8/5',
  },
  D102V0036: {
    en: 'August 3,2020',
    ja: '2020/8/3',
  },
  D102V0037: {
    en: 'August 1,2020',
    ja: '2020/8/1',
  },
  D102V0038: {
    en: 'July 31,2020',
    ja: '2020/7/31',
  },
  D102V0039: {
    en: 'July 30,2020',
    ja: '2020/7/30',
  },
  D102V0040: {
    en: 'NEW Booking',
    ja: '新規予約',
  },
  D102V0041: {
    en: 'Space Request',
    ja: 'スペースリクエスト',
  },
  D102V0042: {
    en: 'Pending',
    ja: '保留中',
  },
  D102V0043: {
    en: 'Cancelled',
    ja: 'キャンセル',
  },
  D102V0044: {
    en: 'Rejected',
    ja: '予約不可',
  },
  D102V0045: {
    en: 'Booked',
    ja: '予約確定',
  },
  D102V0046: {
    en: 'In transit',
    ja: '輸送中',
  },
  D102V0047: {
    en: 'Arrived',
    ja: '到着済',
  },
  D102V0048: {
    en: 'Delivered',
    ja: '配送済',
  },
  D102V0049: {
    en: 'Not Done',
    ja: '未確認',
  },
  D102V0050: {
    en: 'TBD',
    ja: '未定',
  },
  D102V0051: {
    en: 'Done',
    ja: '終了',
  },
  D102V0052: {
    en: 'Shipper, ORG/DST, etc...',
    ja: '荷主、出発/到着など…',
  },
  D102V0053: {
    en: 'Lately',
    ja: '最近',
  },
  D102V0054: {
    en: 'Past',
    ja: '過去',
  },
  D102V0055: {
    en: 'SHIPPER',
    ja: '荷主順',
  },
  D102V0056: {
    en: 'HAWB No. Ascending',
    ja: 'ハウス番号昇順',
  },
  D102V0057: {
    en: 'HAWB No. Descending',
    ja: 'ハウス番号降順',
  },
  D102V0058: {
    en: 'Pieces',
    ja: '個数順',
  },
  D102V0059: {
    en: 'Weight',
    ja: '重量順',
  },
  D102V0060: {
    en: 'Volume',
    ja: '容積順',
  },
  D102V0061: {
    en: 'Origin',
    ja: '出発空港順',
  },
  D102V0062: {
    en: 'Destination',
    ja: '到着空港順',
  },
  D102V0063: {
    en: 'Status',
    ja: 'ステータス順',
  },
}
// Display + 画面名
export default DisplayFwdrBookingList
