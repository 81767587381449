import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import PdfButton from '../../../common/buttons/PdfButton'
import CommonFunc from '../../../common/function/CommonFunc'
import DeleteSmallButton from '../../../common/buttons/DeleteSmallButton'
import { LIGHT_BOOKING_DETAILS } from '../../../../actions'

const useStyles = makeStyles((theme) => ({
  fit: theme.fitContentWidth,
  center: theme.center,
}))

/**
 * @param {Object} props 下記内容
 * @param {Object} props.document ドキュメント情報
 * @param {Function} props.dispatch dispatch
 * @param {boolean} props.editable 編集可能
 * @param {Number} props.index 配列のインデックス
 * @param {String} props.documentArrName ドキュメントの配列名
 * @returns {JSX} PDFボタン(１つ分)
 */
const PdfButtonDocumentLightBookingDetails = ({
  document,
  dispatch,
  editable,
  index,
  documentArrName,
}) => {
  const classes = useStyles()
  const { documentPath, url } = document
  const fileName = CommonFunc.getFileName(documentPath)

  /**
   * ドキュメント削除
   * @returns {void} なし
   */
  const onDelete = () => {
    dispatch({
      type: LIGHT_BOOKING_DETAILS.DOCUMENT.DELETE,
      documentArrName,
      index,
    })
  }

  /**
   * Deleteボタンを作成する
   * @returns {JSX} Deleteボタン
   */
  const createDeleteButton = () => (
    <div className={classes.center}>
      <DeleteSmallButton onClick={onDelete} />
    </div>
  )

  return (
    <div className={classes.fit}>
      <div className={classes.center}>
        <PdfButton
          onClick={(isDownload) =>
            CommonFunc.openPdfFile(url, fileName, isDownload)
          }
        >
          {fileName}
        </PdfButton>
      </div>
      {editable ? createDeleteButton() : null}
    </div>
  )
}
PdfButtonDocumentLightBookingDetails.propTypes = {
  document: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  editable: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  documentArrName: PropTypes.string.isRequired,
}

export default PdfButtonDocumentLightBookingDetails
