import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import AppContext from '../../../../contexts/AppContext'
import CommonFunc from '../../../common/function/CommonFunc'
import DatePicker from '../../../common/datePicker/DatePicker'

/**
 * frontend\src\reducers\commonのDatepickerもしくはDatepickerStartFinishと同時に使用
 * @param {Object} props 以下
 * @param {String} stateName DatepickerのStateを保持しているKey名
 * @param {String} stateKey DatepickerのStateの時間を保持しているKey名
 * @param {String} actionType frontend\src\actions\index.jsに保存している日付変更時に行うイベント
 * @returns {JSX} AccordionContainer
 */
const CommonDatepicker = (props) => {
  const { stateName, stateKey, actionType, ...datepickerSetting } = props
  const { state, dispatch } = useContext(AppContext)

  // stateで保持している時間を取得
  const stateObj = state[stateName]
  const dateStr = state[stateName][stateKey]
  // 未定義の場合はDatepickerを表示しない
  const hasDateStr = dateStr !== undefined
  const defaultDateObj = dateStr === null ? dateStr : new Date(dateStr)
  // エラー状態
  const { errorObj } = stateObj

  /**
   * 日付選択時にstateを更新
   * @param {Date} dateObj DatePickerのChangeイベントで受け取る選択したDateObject
   * @return {void}
   */
  const updateState = (dateObj) => {
    const isNotInvalidDate = dateObj?.toString() !== 'Invalid Date'
    const convertedDateStr =
      dateObj && isNotInvalidDate
        ? CommonFunc.convertFormatDate(dateObj, 'YYYY-MM-DD', false)
        : null
    const newStateObj = {
      ...stateObj,
      [stateKey]: convertedDateStr,
    }
    dispatch({
      type: actionType,
      ...newStateObj,
    })
  }

  /**
   * エラー状態の更新
   * @param {Boolean} isError 現在のエラー状態
   * @return {void}
   */
  const updateErrorState = (isError) => {
    const newErrorObj = {
      ...errorObj,
      [stateKey]: isError,
    }
    const newStateObj = {
      ...stateObj,
      newErrorObj,
    }
    dispatch({
      type: actionType,
      ...newStateObj,
    })
  }

  /**
   * エラー時のイベント
   * @param {*} error onErrorで受け取るエラー内容
   * @return {void}
   */
  const setError = (error) => {
    // 現在のエラーとonErrorのイベントの新規エラーが一致しない時のみエラーを更新
    const isErrorCurrent = errorObj[stateKey]
    const isErrorNew = Boolean(error)
    if (isErrorCurrent !== isErrorNew) {
      updateErrorState(isErrorNew)
    }
  }

  return (
    <>
      {hasDateStr && (
        <DatePicker
          onChange={updateState}
          onError={setError}
          defaultValue={defaultDateObj}
          {...datepickerSetting}
        />
      )}
    </>
  )
}

CommonDatepicker.propTypes = {
  stateName: PropTypes.string.isRequired,
  stateKey: PropTypes.string.isRequired,
  actionType: PropTypes.string.isRequired,
}

export default CommonDatepicker
