import { SHC_DIALOG } from '../../actions'

/** @type {Object} SHCポップアップの初期値 */
const initShcDialog = {
  setOpen: () => {},
  shcArr: [],
}

/**
 * SHCポップアップを開く関数を登録
 * @param {Object} action action
 * @param {Function} action.setOpen ポップアップを開く関数
 * @param {Object} state state
 * @returns {Object} state
 */
const setOpenFunc = (action, state) => {
  const { setOpen } = action
  return { ...state, setOpen }
}

/**
 * SHCポップアップを開く
 * @param {Object} action action
 * @param {Function} action.shcArr 表示するSHCの配列
 * @param {Object} state state
 * @returns {Object} state
 */
const open = (action, state) => {
  const { shcArr } = action
  state.setOpen(true)
  return { ...state, shcArr }
}

/**
 * SHCポップアップのデータを保持
 * @param { Object } [state = initShcDialog] state
 * @param { Object } action action
 * @return { Object } state
 */
const ShcDialog = (state = initShcDialog, action) => {
  const { type } = action
  switch (type) {
    case SHC_DIALOG.SET_OPEN_FUNC:
      return setOpenFunc(action, state)
    case SHC_DIALOG.OPEN:
      return open(action, state)
    default:
      return state
  }
}

export default ShcDialog

export { initShcDialog }
