import React, { useContext, useMemo } from 'react'

import AppContext from '../../../../../contexts/AppContext'
import { LIGHT_FLIGHT_MGMT } from '../../../../../actions'
import AddButton from '../../../../common/buttons/AddButton'

/**
 * @return {JSX} Addボタン
 */
const AddButtonLightFlightMgmt = () => {
  const { state, dispatch } = useContext(AppContext)
  const { setOpen, createInitData } = state.Popup

  return useMemo(() => {
    /**
     * ボタン押下時のイベント
     * @returns {void} なし
     */
    const onClick = () => {
      // ポップアップに編集データをセット
      dispatch({
        type: LIGHT_FLIGHT_MGMT.EDIT.INIT,
        targetData: createInitData(state.Gsa),
        add: true,
      })

      // ポップアップを開く
      setOpen(true)
    }
    return <AddButton onClick={onClick} />
  }, [setOpen, createInitData, dispatch, state.Gsa])
}

export default AddButtonLightFlightMgmt
