import React from 'react'

import Common from '../../../../../constants/Common'
import SpecialNotesTextField from './SpecialNotesTextField'

/**
 * @returns {JSX} SpecialNotesテキストコンポーネント(全行分)
 */
const SpecialNotesTextFields = () => {
  return Common.SPECIAL_NOTE_SETTING_ARR.map((templateObj, index) => {
    return (
      <SpecialNotesTextField
        key={index}
        index={index}
        templateObj={templateObj}
      />
    )
  })
}

export default SpecialNotesTextFields
