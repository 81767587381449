import React, { useContext } from 'react'

import AppContext from '../../../../../contexts/AppContext'
import CancelBookingLightBookingDetails from '../../../../commonUseContext/LightBookingDetails/CancelBooking/CancelBookingLightBookingDetails'

/**
 * @return {JSX} CancelBookingアコーディオン
 */
const CancelAirlineLightBookingDetails = () => {
  const { state } = useContext(AppContext)
  const disabled = state.Status.isCancel || state.Status.isClose
  return <CancelBookingLightBookingDetails disabled={disabled} />
}

export default CancelAirlineLightBookingDetails
