import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import Check from '@material-ui/icons/Check'

import TableCell from '../table/TableCell'

/**
 * 表の通知のチェックアイコン
 * @param {Object} { hasNotify } 下記の値を渡す
 * @param {Boolean} hasNotify 通知有りの場合true
 * @return {JSX} チェックアイコンコンポーネント
 */
const NotifyCheckIcon = ({ hasNotify, ...rest }) => (
  <TableCell {...rest}>
    <Grid container justify="center">
      <Grid item>{hasNotify ? <Check /> : <></>}</Grid>
    </Grid>
  </TableCell>
)

NotifyCheckIcon.propTypes = {
  hasNotify: PropTypes.bool.isRequired,
}

export default NotifyCheckIcon
