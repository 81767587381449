// Display + 画面名
const DisplayAirlineRegister = {
  // 画面ID + 文言ID
  D201V0001: {
    en: 'Register New Account',
    ja: 'Register New Account',
  },
  D201V0002: {
    en: 'A confirmation e-mail will be sent automatically to your e-mail address.',
    ja: 'A confirmation e-mail will be sent automatically to your e-mail address.',
  },
  D201V0003: {
    en: 'Company',
    ja: 'Company',
  },
  D201V0004: {
    en: 'IATA/CASS NO.',
    ja: 'IATA/CASS NO.',
  },
  D201V0005: {
    en: 'First Name',
    ja: 'First Name',
  },
  D201V0006: {
    en: 'Last Name',
    ja: 'Last Name',
  },
  D201V0007: {
    en: 'Position',
    ja: 'Position',
  },
  D201V0008: {
    en: 'E-mail',
    ja: 'E-mail',
  },
  D201V0009: {
    en: 'Telephone',
    ja: 'Telephone',
  },
  D201V0010: {
    en: 'Required fields',
    ja: 'Required fields',
  },
  D201V0011: {
    en: 'Send',
    ja: 'Send',
  },
  D201V0012: {
    en: 'Company Name Required',
    ja: 'Company Name Required',
  },
  D201V0013: {
    en: 'Company Name must be less than 50 characters',
    ja: 'Company Name must be less than 50 characters',
  },
  D201V0014: {
    en: 'Company Name must only include letters',
    ja: 'Company Name must only include letters',
  },
  D201V0015: {
    en: 'Branch Name Required',
    ja: 'Branch Name Required',
  },
  D201V0016: {
    en: 'Branch Name must be less than 50 characters',
    ja: 'Branch Name must be less than 50 characters',
  },
  D201V0017: {
    en: 'Branch Name must only include letters',
    ja: 'Branch Name must only include letters',
  },
  D201V0018: {
    en: 'First Name Required',
    ja: 'First Name Required',
  },
  D201V0019: {
    en: 'First Name must be less than 50 characters',
    ja: 'First Name must be less than 50 characters',
  },
  D201V0020: {
    en: 'First Name must only include letters',
    ja: 'First Name must only include letters',
  },
  D201V0021: {
    en: 'Last Name Required',
    ja: 'Last Name Required',
  },
  D201V0022: {
    en: 'Last Name must be less than 50 characters',
    ja: 'Last Name must be less than 50 characters',
  },
  D201V0023: {
    en: 'Last Name must only include letters',
    ja: 'Last Name must only include letters',
  },
  D201V0024: {
    en: 'Email Required',
    ja: 'Email Required',
  },
  D201V0025: {
    en: 'Email must be less than 20 characters',
    ja: 'Email must be less than 20 characters',
  },
  D201V0026: {
    en: 'Incorrect entry.',
    ja: 'Incorrect entry.',
  },
  D201V0027: {
    en: 'Telephone Required',
    ja: 'Telephone Required',
  },
  D201V0028: {
    en: 'Telephone must be less than 50 characters',
    ja: 'Telephone must be less than 50 characters',
  },
  D201V0029: {
    en: 'Telephone must only include numbers',
    ja: 'Telephone must only include numbers',
  },
  D201V0030: {
    en: 'Country',
    ja: 'Country',
  },
  D201V0031: {
    en: 'IATA/CASS NO. Required',
    ja: 'IATA/CASS NO. Required',
  },
  D201V0032: {
    en: 'IATA/CASS NO. must be less than 50 characters',
    ja: 'IATA/CASS NO. must be less than 50 characters',
  },
  D201V0033: {
    en: 'IATA/CASS NO. must only include letters',
    ja: 'IATA/CASS NO. must only include letters',
  },
  D201V0034: {
    en: 'Branch',
    ja: 'Branch',
  },
  D201V0035: {
    en: 'Airport',
    ja: 'Airport',
  },
  D201V0036: {
    en: 'Airport Required',
    ja: 'Airport Required',
  },
}
// Display + 画面名
export default DisplayAirlineRegister
