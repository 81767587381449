import React from 'react'
import Pagination from '@material-ui/lab/Pagination'
import { makeStyles } from '@material-ui/core/styles'

//import Common from '../../../constants/Common'

const useStyles = makeStyles((theme) => ({
  root: {
    width: 'fit-content',
    margin: '1rem auto',
    '& .MuiButtonBase-root': {
      color: theme.fontColor.main,
    },
    '& .MuiPaginationItem-textPrimary.Mui-selected': {
      color: theme.fontColor.secondary,
    },
  },
  desktop: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobile: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}))

/**
 * ページ
 * @param {Object} props - 下記を要素とするオブジェクトpagingfactors
 * @param {Number} totalPage - 総ページ数
 * @param {Event} changeEvent - ページ変更時のevent
 * @param {Number} page - 現在ページ
 * @returns {JSX.Element} - ページコンポーネント
 */
const Paging = (props) => {
  const classes = useStyles()

  let totalPage = 10
  let changeEvent = () => {}
  let page = 1

  if (props.pagingfactors) {
    // 総ページ数
    if (props.pagingfactors.amount) {
      totalPage = props.pagingfactors.amount
    } else if (props.pagingfactors.totalPage) {
      totalPage = props.pagingfactors.totalPage
    } else {
      totalPage = 1
    }
    // チェンジイベント
    changeEvent = props.pagingfactors.changeEvent
      ? (e, val) => props.pagingfactors.changeEvent(e, val)
      : () => {}
    // 現在ページ
    page = props.pagingfactors.page ? props.pagingfactors.page : 1
  }

  return (
    <div className={classes.root}>
      {/* PC表示 */}
      <Pagination
        count={totalPage}
        color="primary"
        className={classes.desktop}
        onChange={(e, val) => changeEvent(e, val)}
        page={page}
      />
      {/* スマホ表示 */}
      <Pagination
        count={totalPage}
        siblingCount={1}
        boundaryCount={1}
        hideNextButton
        hidePrevButton
        color="primary"
        className={classes.mobile}
        onChange={(e, val) => changeEvent(e, val)}
        page={page}
      />
    </div>
  )
}

export default Paging
