import React from 'react'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'

import CommonTab from './CommonTab'
import CommonTabBadge from '../badge/CommonTabBadge'

/**
 * 共通バッジ付きタブコンポーネント
 * @param {Array} tabSettingArr 以下3つをオブジェクトで設定
 * @param {String} label タブに表示する文字列
 * @param {Number} notificationCount タブの通知数 0もしくは渡していない場合はバッジ非表示
 * @param {Object} girdSetting タブのGridコンポーネント設定
 * @param {Number} selectTabIndex 現在選択中のタブindex
 * @param {Any} restProps その他のTabコンポーネント設定
 * @return {JSX} 共通タブコンポーネント
 */
const CommonTabs = ({ tabSettingArr, selectTabIndex, ...restProps }) => {
  return tabSettingArr.map(
    ({ label, notificationCount, girdSetting }, index) => (
      <Grid key={label} item {...girdSetting}>
        <CommonTabBadge notificationCount={notificationCount}>
          <CommonTab
            label={label}
            index={index}
            selectTabIndex={selectTabIndex}
            {...restProps}
          />
        </CommonTabBadge>
      </Grid>
    )
  )
}

CommonTabs.propTypes = {
  tabSettingArr: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string.isRequired,
      notificationCount: PropTypes.number,
      girdSetting: PropTypes.object,
    })
  ),
  selectTabIndex: PropTypes.number.isRequired,
}

export default CommonTabs
