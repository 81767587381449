import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import EditDialog from './EditDialog'
import AppContext from '../../../../contexts/AppContext'
import CommonCalc from '../../../common/function/CommonCalc'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  black: theme.textNormalBLK,
}))

const AddButton = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { state } = useContext(AppContext)

  // delFlgがないもの
  const dataLength = state.Tables.editData.filter((data) => !data.delFlg)

  return (
    <div className={classes.root}>
      <Grid container>
        {/* Total表示 */}
        <Grid item style={{ marginLeft: '10%' }}>
          <div className={classes.black}>
            {t('D301V0036')} {CommonCalc.addComma(dataLength.length)}
          </div>
        </Grid>
        <Grid item xs />
        {/* ADDボタン */}
        <Grid item style={{ marginRight: '10%' }}>
          <EditDialog add />
        </Grid>
      </Grid>
    </div>
  )
}

export default AddButton
