import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import AuthenticationText from '../../../../commonUseContext/Authentication/AuthenticationText'
import AppContext from '../../../../../contexts/AppContext'
import { LIGHT_REGISTER_NEW_ACCOUNT } from '../../../../../actions'
import Common from '../../../../../constants/Common'

const DepartmentLightAirlineRegisterNewAccount = () => {
  const { state } = useContext(AppContext)
  const { t } = useTranslation()
  const { checkResult, errMsg } = state.Department.errorObj

  return (
    <AuthenticationText
      label={t('D501V0058')}
      required={state.CarrierName.gsaFlg === Common.GSA_FLG.CARRIER}
      disabled={state.CarrierName.gsaFlg === Common.GSA_FLG.GSA}
      error={checkResult}
      helperText={t(errMsg)}
      value={state.Department.value}
      dispatchType={LIGHT_REGISTER_NEW_ACCOUNT.DEPARTMENT.UPDATE}
    />
  )
}

export default DepartmentLightAirlineRegisterNewAccount
