import { combineReducers } from 'redux'

import SelectData, { initData } from './SelectData'
import Company, { initCompany } from '../common/Company'
import ShipperConsigneeId, {
  initShipperConsigneeId,
} from '../common/ShipperConsigneeId'
import Branch, { initBranch } from '../common/Branch'
import FirstName, { initFirstName } from '../common/FirstName'
import LastName, { initLastName } from '../common/LastName'
import Position, { initPosition } from '../common/Position'
import Email, { initEmail } from '../common/Email'
import Tel, { initTel } from '../common/Tel'
import PostalCode, { initPostalCode } from '../common/PostalCode'
import Address, { initAddress } from '../common/Address'
import Airport, { initAirport } from '../common/Airport'
import Icon, { initIcon } from '../common/Icon'
import AgreeCheck, { initIsAgreeChecked } from '../common/AgreeCheck'

const initialState = {
  Company: initCompany,
  ShipperConsigneeId: initShipperConsigneeId,
  Branch: initBranch,
  FirstName: initFirstName,
  LastName: initLastName,
  Position: initPosition,
  Email: initEmail,
  SelectData: initData,
  Tel: initTel,
  PostalCode: initPostalCode,
  Address: initAddress,
  Airport: initAirport,
  Icon: initIcon,
  AgreeCheck: initIsAgreeChecked,
}

export default combineReducers({
  Company,
  ShipperConsigneeId,
  Branch,
  FirstName,
  LastName,
  Position,
  Email,
  Tel,
  PostalCode,
  Address,
  Airport,
  Icon,
  AgreeCheck,
  SelectData,
})

export { initialState }
