import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

import TableCell from '../../../common/table/TableCell'

const useStyles = makeStyles((theme) => ({
  grayCell: theme.grayBackground,
  noRightBorder: theme.noBorderRight,
  noBorder: {
    borderColor: 'transparent !important',
  },
  margin: {
    margin: '0.5rem',
  },
}))

/**
 * @param {Any} displayContent 表示する要素
 * @param {Boolean} [isGrayOut=false] グレーアウトするか
 * @param {Boolean} [rightBorder=false] 右側の罫線を表示するか
 * @param {noBorder} [noBorder=false] 罫線なし
 * @return {JSX} 表の通常のTableCell
 */
const TableCellBidDetails = ({
  displayContent,
  isGrayOut = false,
  rightBorder = false,
  noBorder = false,
}) => {
  const classes = useStyles()

  /** 背景のスタイル */
  const backgroundStyle = isGrayOut ? classes.grayCell : null

  /** 右側の罫線のスタイル */
  const rightBorderStyle = rightBorder ? null : classes.noRightBorder

  /** 罫線なしのスタイル(TTL Wgt. TTL Volで使用) */
  const noBorderStyle = noBorder ? classes.noBorder : null

  /** セルに適用するスタイルを作成 */
  const style = `${backgroundStyle} ${rightBorderStyle} ${noBorderStyle} ${classes.margin}`

  return (
    <TableCell className={style}>
      <div className={classes.margin}>{displayContent}</div>
    </TableCell>
  )
}

TableCellBidDetails.propTypes = {
  displayContent: PropTypes.any,
  isGrayOut: PropTypes.bool,
  rightBorder: PropTypes.bool,
  noBorder: PropTypes.bool,
}

export default TableCellBidDetails
