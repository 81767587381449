import React from 'react'

import SearchSort from '../../../../commonUseContext/SearchSort'
import ConstantsSelectForwarder from '../ConstantsSelectForwarder'

/**
 * @return {JSX} サーチソートコンポーネント
 */
const SearchSortSelectForwarder = () => {
  return <SearchSort sortItemArr={ConstantsSelectForwarder.SORT_ITEM_ARR} />
}

export default SearchSortSelectForwarder
