import React, { useContext, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import PropTypes from 'prop-types'

import AppContext from '../../../contexts/AppContext'
import Theme from '../../common/theme/Theme'
import AuthenticationTableContainer from '../../common/table/AuthenticationTableContainer'

const useStyles = makeStyles(() => ({
  inputLabel: Theme.signup.inputLabel,
  root: Theme.signup.root,
  container: Theme.signup.container,
}))

const AuthenticationText = (props) => {
  const { label, required, error, helperText, dispatchType, disabled, value } =
    props
  const classes = useStyles()
  const { dispatch } = useContext(AppContext)

  return useMemo(() => {
    /**
     * テキスト更新
     * @param {*} e - event
     * @returns {void}
     */
    const updateText = (e) => {
      dispatch({
        type: dispatchType,
        value: e.target.value,
      })
    }

    return (
      <AuthenticationTableContainer>
        <form noValidate>
          <TextField
            InputLabelProps={{
              classes: {
                root: classes.inputLabel,
                focused: 'focused',
                shrink: 'shrink',
              },
            }}
            className={classes.root}
            variant="outlined"
            margin="normal"
            fullWidth
            label={label}
            required={required}
            error={error}
            helperText={helperText}
            disabled={disabled}
            onChange={updateText}
            value={value}
          />
        </form>
      </AuthenticationTableContainer>
    )
  }, [
    dispatch,
    classes.inputLabel,
    classes.root,
    label,
    required,
    error,
    helperText,
    dispatchType,
    disabled,
    value,
  ])
}

AuthenticationText.propTypes = {
  dispatchType: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  error: PropTypes.bool.isRequired,
  helperText: PropTypes.string.isRequired,
}

export default AuthenticationText
