/* eslint-disable prefer-template */
import React from 'react'
import MAccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: theme.accordionSummary,
}))

/**
 * @param {Object} props - 以下
 * @param {useStyles} className - スタイルを指定
 * @returns {JSX.Element} - AccordionSummary
 */
const AccordionSummary = (props) => {
  const classes = useStyles()
  return (
    <MAccordionSummary
      expandIcon={<ExpandMoreIcon />}
      className={classes.root + ' ' + props.className}
    >
      {props.children}
    </MAccordionSummary>
  )
}

export default AccordionSummary
