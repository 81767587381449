import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

import TextField from '../../../../common/text/TextField'

const useStyles = makeStyles({
  textBox: {
    width: '500px',
  },
  textField: {
    '& .MuiFilledInput-inputMultiline': {
      minHeight: '137px',
    },
    '& .MuiFilledInput-root': {
      marginLeft: '2rem',
    },
  },
})

/**
 * テキストボックスを表示
 * @param {Object} props MUIのTextFieldの設定
 * @returns {*} - テキストボックス
 */
const TextBox = (props) => {
  const classes = useStyles()
  const { ...restProps } = props

  return (
    <Grid container item className={classes.textBox}>
      <Grid item xs={12}>
        <TextField className={classes.textField} multiline {...restProps} />
      </Grid>
    </Grid>
  )
}

export default TextBox
