import React, { useContext } from 'react'

import SpecialHandlingCodeLightBookingDetails from '../../../../commonUseContext/LightBookingDetails/SpecialHandlingCode/SpecialHandlingCodeLightBookingDetails'
import AppContext from '../../../../../contexts/AppContext'

/**
 * @return {JSX} SpecialHandlingCodeアコーディオン
 */
const SpecialHandlingCodeFwdrLightBookingDetails = () => {
  const { state } = useContext(AppContext)
  const editable = !(state.Status.isCancel || state.Status.isClose)
  return <SpecialHandlingCodeLightBookingDetails editable={editable} />
}

export default SpecialHandlingCodeFwdrLightBookingDetails
