import { combineReducers } from 'redux'

import SearchSort, { initSearchSort } from '../../common/SearchSort'
import TableLightFwdrAccountInfo, {
  initTableData,
} from './TableLightFwdrAccountInfo'
import Paging, { initPaging } from '../../common/Paging'
import UserPlan, { initUserPlan } from '../../common/UserPlan'

const initialState = {
  SearchSort: initSearchSort,
  Tables: initTableData,
  Paging: initPaging,
  UserPlan: initUserPlan,
}

export default combineReducers({
  SearchSort,
  Tables: TableLightFwdrAccountInfo,
  Paging,
  UserPlan,
})

export { initialState }
