// Display + 画面名
const DisplayAirlineTop = {
  // 画面ID + 文言ID
  D202V0001: {
    en: 'BOOKING',
    ja: '予約',
  },
  D202V0002: {
    en: 'LIST',
    ja: '一覧',
  },
  D202V0003: {
    en: 'when confirming',
    ja: '確認はこちらから',
  },
  D202V0004: {
    en: 'SPACE',
    ja: 'スペース',
  },
  D202V0005: {
    en: 'CONTROL',
    ja: '管理',
  },
  D202V0006: {
    en: 'when preparing',
    ja: '準備はこちらから',
  },
  D202V0007: {
    en: 'CARGO',
    ja: '貨物',
  },
  D202V0008: {
    en: 'TRACE',
    ja: '追跡',
  },
  D202V0009: {
    en: 'when tracking',
    ja: '追跡はこちらから',
  },
  D202V0010: {
    en: 'BID',
    ja: '入札',
  },
  D202V0011: {
    en: 'RATES',
    ja: 'レート',
  },
  D202V0012: {
    en: 'when dealing',
    ja: '取引はこちらから',
  },
  D202V0013: {
    en: 'Norikuni Inaba',
    ja: '稲葉 憲邦',
  },
  D202V0014: {
    en: 'Master Maint.',
    ja: 'マスタメンテナンス',
  },
  D202V0015: {
    en: 'My Profile',
    ja: 'プロフィール',
  },
  D202V0016: {
    en: 'Sign out',
    ja: 'サインアウト',
  },
  D202V0017: {
    en: 'President & CEO - Japan Hyperiner Inc.',
    ja: 'President & CEO - Japan Hyperiner Inc.',
  },
  D202V0018: {
    en: 'Hi. How can we help?',
    ja: 'こんにちは。お手伝いできますか？',
  },
  D202V0019: {
    en: "We'll take care of all inquiry from reporting Bugs and question to feedback.",
    ja: 'バグの報告から質問のフィードバックまで、すべてのお問い合わせをお受けします。',
  },
  D202V0020: {
    en: 'Search ex. XXXXX, XXXXX, XXXXX',
    ja: '検索 例. XXXXX, XXXXX, XXXXX',
  },
  D202V0021: {
    en: 'Troubleshooting-related Topics:',
    ja: 'トラブルシューティング関連のトピック:',
  },
  D202V0022: {
    en: 'XXXXX',
    ja: 'XXXXX',
  },
  D202V0023: {
    en: ',',
    ja: ',',
  },
  D202V0024: {
    en: 'Getting Started',
    ja: 'はじめに',
  },
  D202V0025: {
    en: 'How to use?',
    ja: '使い方',
  },
  D202V0026: {
    en: 'Account & Admin',
    ja: 'アカウントと管理者',
  },
  D202V0027: {
    en: 'Analytics',
    ja: 'アナリティクス',
  },
  D202V0028: {
    en: 'Payment',
    ja: '支払い方法',
  },
  D202V0029: {
    en: 'Tips & more',
    ja: 'ヒント & その他',
  },
  D202V0030: {
    en: '#',
    ja: '#',
  },
  D202V0031: {
    en: 'New',
    ja: '新着',
  },
  D202V0032: {
    en: 'Today',
    ja: '今日',
  },
  D202V0033: {
    en: 'Yesterday',
    ja: '昨日',
  },
  D202V0034: {
    en: 'This Week',
    ja: '今週',
  },
  D202V0035: {
    en: 'This Month',
    ja: '今月',
  },
  D202V0036: {
    en: 'Earlier',
    ja: '以前',
  },
  D202V0037: {
    en: 'requested new booking KIX-MNL.',
    ja: 'が新規予約KIX-MNLをリクエストしました。',
  },
  D202V0038: {
    en: 'requested new booking KIX-CEB',
    ja: 'が新規予約KIX-CEBをリクエストしました。',
  },
  D202V0039: {
    en: 'edited an invoice.',
    ja: 'が請求書を編集しました。',
  },
  D202V0040: {
    en: 'offered spot rates KIX-DXB.',
    ja: 'がスポットレートKIX-DXBを提供しました。',
  },
  D202V0041: {
    en: 'confirmed booking M: XXX-XXXXXXXX',
    ja: 'がM: XXX-XXXXXXXXを予約確認しました。',
  },
  D202V0042: {
    en: 'completed cargo ready M: XXX-XXXXXXXX',
    ja: 'が荷物M: XXX-XXXXXXXXの準備が完了しました。',
  },
  D202V0043: {
    en: 'cancelled booking M: XXX-XXXXXXXX',
    ja: 'が予約M: XXX-XXXXXXXXをキャンセルしました。',
  },
  D202V0044: {
    en: 'During the maintenance process,',
    ja: 'メンテナンス実施のため、',
  },
  D202V0045: {
    en: 'our system wii be temporarity unavailable between 3 p.m. and 5 p.m.',
    ja: 'システムは3p.m.から5p.m.の間は一時的にご利用できません',
  },
  D202V0046: {
    en: 'offered BID Rate.',
    ja: 'がBIDレートを提供しました。',
  },
  D202V0047: {
    en: 'Yuki Yanagiko_XXXXXX Corporation',
    ja: 'Yuki Yanagiko_XXXXXX Corporation',
  },
  D202V0048: {
    en: 'Kazuki Yamamoto_XXXXX Corporation',
    ja: 'Kazuki Yamamoto_XXXXX Corporation',
  },
  D202V0049: {
    en: 'Masashi  Noda_XXXXXX Corporation',
    ja: 'Masashi  Noda_XXXXXX Corporation',
  },
  D202V0050: {
    en: 'Shinobu Tasaka_XXXXXXX Corporation',
    ja: 'Shinobu Tasaka_XXXXXXX Corporation',
  },
  D202V0051: {
    en: 'XXXX XXXX_KIXJL',
    ja: 'XXXX XXXX_KIXJL',
  },
  D202V0052: {
    en: 'XXXX XXXX_OSAFR',
    ja: 'XXXX XXXX_OSAFR',
  },
  D202V0053: {
    en: 'XXXX XXXX_NRTFSNH',
    ja: 'XXXX XXXX_NRTFSNH',
  },
  D202V0054: {
    en: 'XXXX XXXX_OSAKZ',
    ja: 'XXXX XXXX_OSAKZ',
  },
  D202V0055: {
    en: 'Attention:',
    ja: '注意:',
  },
  D202V0056: {
    en: 'XXXX XXXX_XXXXXX Corporation',
    ja: 'XXXX XXXX_XXXXXX Corporation',
  },
  D202V0057: {
    en: '5m',
    ja: '5分',
  },
  D202V0058: {
    en: '10m',
    ja: '10分',
  },
  D202V0059: {
    en: '15m',
    ja: '15分',
  },
  D202V0060: {
    en: '30m',
    ja: '30分',
  },
  D202V0061: {
    en: '35m',
    ja: '35分',
  },
  D202V0062: {
    en: '50m',
    ja: '50分',
  },
  D202V0063: {
    en: '2h',
    ja: '2時間',
  },
  D202V0064: {
    en: '3h',
    ja: '3時間',
  },
  D202V0065: {
    en: '4h',
    ja: '4時間',
  },
  D202V0066: {
    en: '8h',
    ja: '8時間',
  },

  D209V0067: {
    en: 'YY',
    ja: 'YY',
  },
  D209V0068: {
    en: 'KY',
    ja: 'KY',
  },
  D209V0069: {
    en: 'MN',
    ja: 'MN',
  },
  D209V0070: {
    en: 'ST',
    ja: 'ST',
  },
  D209V0071: {
    en: 'XX',
    ja: 'XX',
  },
  D209V0072: {
    en: 'エカデジ',
    ja: 'エカデジ',
  },
  D202V0073: {
    en: 'Read',
    ja: '読む',
  },
  D202V0074: {
    en: 'Hide',
    ja: '非表示',
  },
  D202V0075: {
    en: 'Notify List',
    ja: '通知リスト',
  },
  D202V0076: {
    en: 'CASS',
    ja: 'CASS',
  },
  D202V0077: {
    en: 'UPLOAD',
    ja: 'アップロード',
  },
  D202V0078: {
    en: 'when billing',
    ja: 'CASSはこちらから',
  },
  D202V0079: {
    en: 'Are you sure?',
    ja: '本当によろしいですか？',
  },
  D202V0080: {
    en: 'OK',
    ja: 'OK',
  },
  D202V0081: {
    en: 'Cancel',
    ja: 'キャンセル',
  },
}
// Display + 画面名
export default DisplayAirlineTop
