import Common from '../../../../constants/Common'
import CommonFunc from '../../../common/function/CommonFunc'

const convertDisplayFunc = (type) => CommonFunc.convertTables.display[type]

const ConstantsSelectForwarder = {
  // テーブル作成に必要な値
  TABLE_ARR: [
    {
      headerKey: '',
      cellKey: 'checkBoxStr',
      convertFunc: convertDisplayFunc('checkBox'),
      cellCategory: Common.CELL_CATEGORY.CHECKBOX.SELECT,
    },
    {
      headerKey: 'D207V0065',
      cellKey: 'codeStr',
      convertFunc: convertDisplayFunc('fwdrthreeCode'),
    },
    {
      headerKey: 'D002V0003',
      cellKey: 'companyStr',
      convertFunc: convertDisplayFunc('companyName'),
    },
  ],
  // 並び替えに必要な値
  SORT_ITEM_ARR: [
    {
      sortTerm: Common.SORT_TERMS.COMPANYNAME_ASCENDING,
      wordKey: '3rd0016',
      sortKey: 'companyName',
    },
    {
      sortTerm: Common.SORT_TERMS.COMPANYNAME_DESCENDING,
      wordKey: '3rd0031',
      sortKey: 'companyName',
    },
    {
      sortTerm: Common.SORT_TERMS.CODE,
      wordKey: 'D207V0065',
      sortKey: 'fwdrthreeCode',
    },
    {
      sortTerm: Common.SORT_TERMS.COMPANYNAME_ORDER,
      wordKey: 'D002V0003',
      sortKey: 'companyName',
    },
  ],
  // チェックボックスの最大
  LIMIT: 10,
}

export default ConstantsSelectForwarder
