import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import AppContext from '../../../../../contexts/AppContext'
import Common from '../../../../../constants/Common'
import TableCell from '../../../../common/table/TableCell'
import ConstantsLightFwdrAccountInfo from '../ConstantsLightFwdrAccountInfo'
import CheckLightFwdrAccountInfo from './CheckLightFwdrAccountInfo'
import EditLightFwdrAccountInfo from './EditLightFwdrAccountInfo'

/** @typedef {import('../ConstantsLightFwdrAccountInfo').TableInfo} TableInfo テーブル情報 */

/**
 * 1行分の表データを作成
 * @param {Object} props 下記内容
 * @param {Number} props.page 表示中のページ番号
 * @param {Number} props.index ページ上のインデックス
 * @return {JSX} TableCellコンポーネント(1行分)
 */
const TableCellsLightFwdrAccountInfo = ({ page, index }) => {
  const { state } = useContext(AppContext)
  /** @type {Object} 1行分のデータ */
  const contents = state.Tables.showData[page][index]
  const editIndex = state.Tables.editData.findIndex(
    (data) => data.id === contents.id
  )

  /**
   * 定数で定義した表データのコンポーネントを作成
   * @param {TableInfo} tableInfo テーブル情報
   * @param {Number} index mapのindex
   * @return {JSX} TableCellコンポーネント(1個分)
   */
  const createTableCell = (tableInfo, index) => {
    const { cellKey, cellCategory } = tableInfo
    const { authorityFlg } = contents

    /** @type {*} セルに表示する内容 */
    let displayContent = null

    switch (cellCategory) {
      case Common.CELL_CATEGORY.TEXT:
        displayContent = contents[cellKey]
        break
      case Common.CELL_CATEGORY.CHECK:
        displayContent = (
          <CheckLightFwdrAccountInfo authorityFlg={authorityFlg} />
        )
        break
      case Common.CELL_CATEGORY.BUTTON.EDIT:
        displayContent = (
          <EditLightFwdrAccountInfo
            row={contents}
            index={contents.id}
            editIndex={editIndex}
          />
        )
        break
      default:
        displayContent = null
    }
    return <TableCell key={index}>{displayContent}</TableCell>
  }

  return ConstantsLightFwdrAccountInfo.TABLE_ARR.map(createTableCell)
}

TableCellsLightFwdrAccountInfo.propTypes = {
  page: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
}

export default TableCellsLightFwdrAccountInfo
