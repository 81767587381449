import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import TextField from '../../common/text/TextField'
import CommonValidation from '../../common/function/CommonValidation'
import {
  UPDATE_FWDR_IATACASS_NO,
  UPDATE_FWDR_IATACASS_NO_INPUTERR,
  UPDATE_CAR_IATACASS_NO,
  UPDATE_CAR_IATACASS_NO_INPUTERR,
} from '../../../actions'

const useStyles = makeStyles((theme) => ({
  head: theme.textBoldBLU,
  textBlk: {
    ...theme.textNormalBLK,
    marginLeft: '1rem',
    wordWrap: 'break-word',
  },
}))

/**
 * コンポーネントを更新するか判定する
 * @param {Object} prev 前回の値
 * @param {Object} next 最新の値
 * @returns {Boolean} falseの場合更新する
 */
const areEqual = (prev, next) => {
  // 各入力欄の値を比較
  // 一致していれば更新無し
  const iataOrcassNumberValue =
    prev.companyinfo.iataOrcassNumber.value ===
    next.companyinfo.iataOrcassNumber.value
  const authorityFlgValue =
    prev.companyinfo.authorityFlg.value === next.companyinfo.authorityFlg.value

  return iataOrcassNumberValue && authorityFlgValue
}

const IataCassNo = React.memo(({ dispatch, companyinfo, carrier }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [inputErr, setInputErr] = useState(false)
  const [helperTxt, setHelperTxt] = useState('')
  const { iataOrcassNumber, authorityFlg } = companyinfo
  const authorityFlgValue = authorityFlg.value

  const handleChange = (event) => {
    const value = event.target.value
    // 入力チェック
    const validationCheck = CommonValidation.iataCassNo(value)
    const err = validationCheck.checkResult
    setHelperTxt(validationCheck.errMsg)
    setInputErr(err)

    dispatch({
      type: carrier ? UPDATE_CAR_IATACASS_NO : UPDATE_FWDR_IATACASS_NO,
      iataOrcassNumber: value,
    })

    // 入力エラーの場合、Saveボタン無効
    dispatch({
      type: carrier
        ? UPDATE_CAR_IATACASS_NO_INPUTERR
        : UPDATE_FWDR_IATACASS_NO_INPUTERR,
      inputErr: err,
    })
  }

  // 編集用
  const createTextField = () => {
    return (
      <Grid item md={4} xs={10}>
        <TextField
          id="iataOrcassNumber"
          error={inputErr}
          helperText={t(helperTxt)}
          value={iataOrcassNumber.value}
          onChange={handleChange}
          placeholder={t('D301V0249')}
        />
      </Grid>
    )
  }

  // 表示用
  const createTextLabel = () => {
    return (
      <Grid item md={4} xs={10}>
        <div className={classes.textBlk}>{iataOrcassNumber.value}</div>
      </Grid>
    )
  }

  return (
    <div className={classes.formControl}>
      <Grid container>
        <Grid item md={10} xs={10}>
          <div className={classes.head}>{t('D301V0016')}</div>
        </Grid>
        {authorityFlgValue ? createTextField() : createTextLabel()}
      </Grid>
    </div>
  )
}, areEqual)

IataCassNo.displayName = 'IataCassNo'

IataCassNo.propTypes = {
  dispatch: PropTypes.func.isRequired,
  companyinfo: PropTypes.object.isRequired,
  carrier: PropTypes.bool,
}

export default IataCassNo
