import React, { useContext } from 'react'

import AppContext from '../../../../../contexts/AppContext'
import CommonCalc from '../../../../common/function/CommonCalc'
import TableRow from '../../../../common/table/TableRow'
import ConstantsBidDetails from '../ConstantsBidDetails'
import TableCellsBidDetails from './TableCellsBidDetails'

/**
 * useContextのstateから表全体のデータを作成
 * @return {JSX} TableRow ~ TableCellまでのコンポーネント
 */
const TableContentsBidDetails = () => {
  const { state } = useContext(AppContext)
  /** state.showDataのインデックス */
  const page = CommonCalc.clamp(
    state.Paging - 1,
    0,
    state.Tables.showData.length - 1
  )

  /** 現在表示しているページのデータ配列 */
  const currentPageShowDataArr = state.Tables.showData[page]

  return currentPageShowDataArr.map((row, index) => {
    const { apiIndexNum } = row
    const editIndex = state.Tables.editData.findIndex(
      (tableObj) => tableObj.apiIndexNum === apiIndexNum
    )
    const tableContentObj = state.Tables.editData[editIndex]

    const showIndex = state.IsNew
      ? ConstantsBidDetails.PAGE_AMOUNT * page + index
      : editIndex

    return (
      <TableRow key={showIndex}>
        <TableCellsBidDetails
          showIndex={showIndex}
          index={editIndex}
          tableContentObj={{ ...row, ...tableContentObj }}
        />
      </TableRow>
    )
  })
}

export default TableContentsBidDetails
