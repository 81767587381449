import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import PrimaryButton from '../../../../common/buttons/PrimaryButton'
import Paths from '../../../../../constants/Paths'

/**
 * @param {object} props 下記内容
 * @param {Number} props.cargoId 貨物ID
 * @return {JSX} アイコンボタン
 */
const CheckButtonLightBookingList = ({ cargoId }) => {
  const { t } = useTranslation()

  return (
    <PrimaryButton path={Paths.FWDR.LIGHT_BOOKING_DETAILS} state={cargoId}>
      {t('D203V0021')}
    </PrimaryButton>
  )
}

CheckButtonLightBookingList.propTypes = {
  cargoId: PropTypes.number.isRequired,
}

export default CheckButtonLightBookingList
