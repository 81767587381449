import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import {
  PAGING_DATA,
  GET_TABLE_DATA,
  SEARCH_SORT_DATA,
} from '../../../../actions'
import AppContext from '../../../../contexts/AppContext'
import Checkbox from '../../../common/checkBox/CheckBox'

const NotifyCheck = () => {
  const { t, i18n } = useTranslation()
  const { state, dispatch } = useContext(AppContext)

  const CONSTANTS = { AREA1: 'area1', AREA2: 'area2', AREA3: 'area3' }

  const checkChange = (value, area) => {
    dispatch({
      type: SEARCH_SORT_DATA,
      sort: { [area]: value },
    })
    getTable()
  }

  const getTable = () => {
    dispatch({
      type: GET_TABLE_DATA,
      search: state.Sort.search,
      sort: state.Sort,
      i18n,
    })
    dispatch({
      type: PAGING_DATA,
      page: 1,
    })
  }

  return (
    <>
      <Checkbox
        checked={state.Sort.area1}
        onChange={(event, value) => checkChange(value, CONSTANTS.AREA1)}
        label={t('D601V0004')}
      />
      <Checkbox
        checked={state.Sort.area2}
        onChange={(event, value) => checkChange(value, CONSTANTS.AREA2)}
        label={t('D601V0005')}
      />
      <Checkbox
        checked={state.Sort.area3}
        onChange={(event, value) => checkChange(value, CONSTANTS.AREA3)}
        label={t('D601V0006')}
      />
    </>
  )
}

export default NotifyCheck
