import React, { useContext } from 'react'

import AppContext from '../../../../../contexts/AppContext'
import InputSelect from '../../../../common/select/InputSelect'
import { PIC } from '../../../../../actions'

/**
 * @return {JSX} PICダイアログのセレクトボックスコンポーネント
 */
const DialogSelectBoxPicBidInfo = () => {
  const { state, dispatch } = useContext(AppContext)
  const { editPicObj, accountInfoArr, userIdArr } = state.Pic

  /**
   * PIC選択時にstateを更新
   * @param {Event} _ 不使用
   * @param {Object} accountInfoObj 選択したPICオブジェクト
   * @return {void}
   */
  const handleChange = (_, accountInfoObj) => {
    dispatch({
      type: PIC.SELECT,
      accountInfoObj: accountInfoObj ?? {},
    })
  }

  /**
   * 一覧に表示する内容を作成
   * @param {Object} accountInfoObj PICオブジェクト
   * @return {String} 登録されている名前
   */
  const createOption = (accountInfoObj) => {
    const { name } = accountInfoObj
    return name ?? ''
  }

  /**
   * optionとvalueの同一性チェック
   * @param {Object} optionObj 一覧に登録されている情報
   * @param {Object} valueObj 選択した情報
   * @return {Boolean} 同一の場合true
   */
  const checkSelect = (optionObj, valueObj) => {
    if (valueObj.userId) {
      // ユーザIDが一致する場合は同一
      return optionObj.userId === valueObj.userId
    } else {
      // まだ選択していないときは常にtrue
      return true
    }
  }

  /**
   * セレクトボックスの選択肢の非活性判定
   * 既に選択済みのユーザIDは非活性
   * ただし編集中のユーザIDは活性化
   * @param {Object} option 選択肢の詳細
   * @return {Boolean} 非活性の場合true
   */
  const checkDisabled = (option) => {
    const { userId } = option
    const isEditUserId = editPicObj.userId === userId
    const isSelected = userIdArr.includes(userId)
    return !isEditUserId && isSelected
  }

  return (
    <InputSelect
      defaultValue={editPicObj}
      options={accountInfoArr}
      getOptionLabel={createOption}
      getOptionSelected={checkSelect}
      getOptionDisabled={checkDisabled}
      onChange={handleChange}
    />
  )
}

export default DialogSelectBoxPicBidInfo
