import { Grid } from '@material-ui/core'
import React from 'react'

import Paths from '../../../../../constants/Paths'
import BackButton from '../../../../common/buttons/BackButton'

/**
 * @return {JSX} タイトルコンポーネント
 */
const BackButtonBidInfo = () => {
  return (
    <Grid item>
      <BackButton path={Paths.FWDR.BID_MGMT} />
    </Grid>
  )
}

export default BackButtonBidInfo
