import React from 'react'
import { Grid } from '@material-ui/core'

import CurrencySelectLightCarAirFreight from './CurrencySelectLightCarAirFreight'
import SearchLightCarAirFreight from './SearchLightCarAirFreight'

/**
 * @returns {JSX} 検索欄と通貨選択欄
 */
const SearchAndCurrencySelectLightCarAirFreight = () => {
  return (
    <Grid container justify="center">
      <Grid
        container
        item
        md={10}
        justify="space-between"
        alignItems="flex-end"
      >
        <SearchLightCarAirFreight />
        <CurrencySelectLightCarAirFreight />
      </Grid>
    </Grid>
  )
}
export default SearchAndCurrencySelectLightCarAirFreight
