// Display + 画面名
const DisplayAirlineCargoTrace = {
  // 画面ID + 文言ID
  D212V0001: {
    en: 'CARGO TRACE',
    ja: '貨物追跡',
  },
  D212V0002: {
    en: 'Shipper, ORG/DST, etc…',
    ja: '荷送人, 出発/到着など…',
  },
  D212V0003: {
    en: 'Default: ',
    ja: 'デフォルト: ',
  },
  D212V0004: {
    en: 'KIX',
    ja: 'KIX',
  },
  D212V0005: {
    en: 'Sort by:',
    ja: '並び替え: ',
  },
  D212V0006: {
    en: 'Lately',
    ja: '最近',
  },
  D212V0007: {
    en: 'Flight#',
    ja: 'フライト#',
  },
  D212V0008: {
    en: 'MAWB No.',
    ja: 'マスター番号',
  },
  D212V0009: {
    en: 'Pcs./Wgt./Vol.',
    ja: '個数/重量/容積',
  },
  D212V0010: {
    en: 'ORG/DST',
    ja: '出発/到着',
  },
  D212V0011: {
    en: 'SHC',
    ja: 'SHC',
  },
  D212V0012: {
    en: 'Status',
    ja: 'ステータス',
  },
  D212V0013: {
    en: 'Inquires',
    ja: 'お問い合わせ',
  },
  D212V0014: {
    en: 'Tracing',
    ja: 'トレーシング',
  },
  D212V0015: {
    en: 'KZXXX/XXSEP',
    ja: 'KZXXX/XXSEP',
  },
  D212V0016: {
    en: 'XXX-XXXX XXXXX',
    ja: 'XXX-XXXX XXXXX',
  },
  D212V0017: {
    en: '10pcs/300kg/2.05m3',
    ja: '10pcs/300kg/2.05m3',
  },
  D212V0018: {
    en: 'NRT/ORD',
    ja: 'NRT/ORD',
  },
  D212V0019: {
    en: '-',
    ja: '-',
  },
  D212V0020: {
    en: 'COL/ICE',
    ja: 'COL/ICE',
  },
  D212V0021: {
    en: 'COL',
    ja: 'COL',
  },
  D212V0022: {
    en: 'RFL/CAO',
    ja: 'RFL/CAO',
  },
  D212V0023: {
    en: 'ROX',
    ja: 'ROX',
  },
  D212V0024: {
    en: 'BUP',
    ja: 'BUP',
  },
  D212V0025: {
    en: 'HEA/VOL',
    ja: 'HEA/VOL',
  },
  D212V0026: {
    en: 'AVI',
    ja: 'AVI',
  },
  D212V0027: {
    en: 'OHG',
    ja: 'OHG',
  },
  D212V0028: {
    en: 'RMD',
    ja: 'RMD',
  },
  D212V0029: {
    en: 'FRO/ICE',
    ja: 'FRO/ICE',
  },
  D212V0030: {
    en: 'RNG',
    ja: 'RNG',
  },
  D212V0031: {
    en: 'FBL',
    ja: 'FBL',
  },
  D212V0032: {
    en: 'Loaded',
    ja: '搭載済',
  },
  D212V0033: {
    en: 'Off-loaded',
    ja: 'オフロード',
  },
  D212V0034: {
    en: 'Departed',
    ja: '出発済',
  },
  D212V0035: {
    en: 'In transit',
    ja: '輸送中',
  },
  D212V0036: {
    en: 'Arrived',
    ja: '到着済',
  },
  D212V0037: {
    en: 'Delivered',
    ja: '配送済',
  },
  D212V0038: {
    en: 'FWDR',
    ja: '混載業者',
  },
  D212V0039: {
    en: 'Check',
    ja: 'チェック',
  },
  D212V0040: {
    en: 'Flight Name',
    ja: 'フライト名順',
  },
  D212V0041: {
    en: 'Flight Date',
    ja: 'フライト日順',
  },
  D212V0042: {
    en: 'MAWB No. Ascending',
    ja: 'MawbNo 昇順',
  },
  D212V0043: {
    en: 'MAWB No. Descending',
    ja: 'MawbNo　降順',
  },
  D212V0044: {
    en: 'Pieces',
    ja: '個数順',
  },
  D212V0045: {
    en: 'Weight',
    ja: '重量順',
  },
  D212V0046: {
    en: 'Volume',
    ja: '容積順',
  },
  D212V0047: {
    en: 'Origin',
    ja: '出発空港順',
  },
  D212V0048: {
    en: 'Destination',
    ja: '到着空港順',
  },
  D212V0049: {
    en: 'SHC',
    ja: 'SHC順',
  },
  D212V0050: {
    en: 'Status',
    ja: 'ステータス順',
  },
  D212V0051: {
    en: 'Inquiry',
    ja: '問い合わせ順',
  },
  D212V0052: {
    en: 'Past',
    ja: '登録日時が古い順',
  },
}
// Display + 画面名
export default DisplayAirlineCargoTrace
