import { CARRIER_NAME } from '../../actions'

/**
 * @typedef {Object} CarrierName 航空会社名情報
 * @property {Number} carrierNameId 航空会社名ID
 * @property {String} carriertwoCode 2桁コード
 * @property {String} companyName 航空会社名
 */

// exportするために先に定義
let getCarrierNameInfo = () => {}

/**
 * @type {Object} 初期値
 * @property {CarrierName[]} carrierNames 航空会社名一覧
 * @property {Function} getCarrierNameInfo 航空会社名情報取得関数
 */
const initCarrierName = {
  carrierNames: [],
  getCarrierNameInfo,
}

/**
 * 航空会社名情報を保持
 * @param {Object} action action
 * @param {CarrierName[]} action.data 航空会社名情報の配列
 * @param {String} [carriertwoCode=''] ログインユーザの航空会社2桁コード
 * @returns {Object} 航空会社名情報
 */
const setCarrierName = (action) => {
  const { data, carriertwoCode = '' } = action

  /**
   * carrierNameIdから航空会社名を取得
   * @param {Number} carrierNameId 航空会社名ID
   * @returns {CarrierName} 航空会社名
   */
  getCarrierNameInfo = (carrierNameId) =>
    data.find((v) => v.carrierNameId === carrierNameId)

  /** @type {CarrierName} ログインユーザの航空会社情報 */
  const userCarrierNameInfo =
    data.find((v) => v.carriertwoCode === carriertwoCode) ?? null

  return {
    carrierNames: data,
    getCarrierNameInfo,
    userCarrierNameInfo,
  }
}

/**
 * state: CarrierNameリストを保存
 * @param {Object} [state=initCarrierName] - state
 * @param {Object} action - action
 * @returns {Object} - state
 */
const CarrierName = (state = initCarrierName, action) => {
  switch (action.type) {
    case CARRIER_NAME.SET:
      return setCarrierName(action)
    default:
      return state
  }
}

export default CarrierName

export { initCarrierName, getCarrierNameInfo }
