// Display + 画面名
const DisplayShipperTracing = {
  D010V0001: {
    en: 'TRACING',
    ja: 'トレーシング',
  },
  D010V0002: {
    en: 'MAWB No.',
    ja: 'マスター番号',
  },
  D010V0003: {
    en: 'Pcs.',
    ja: '個数',
  },
  D010V0004: {
    en: 'G/W (kg)',
    ja: '総重量 (kg)',
  },
  D010V0005: {
    en: 'Vol. (m3)',
    ja: '容積 (m3)',
  },
  D010V0006: {
    en: 'C/W (kg)',
    ja: '賃率適用重量 (kg)',
  },
  D010V0007: {
    en: 'ORG/DST',
    ja: '出発/到着',
  },
  D010V0008: {
    en: 'Current Status',
    ja: 'ステータス',
  },
  D010V0009: {
    en: 'XXX-XXXX XXXX',
    ja: 'XXX-XXXX XXXX',
  },
  D010V0010: {
    en: '10',
    ja: '10',
  },
  D010V0011: {
    en: '300.00',
    ja: '300.00',
  },
  D010V0012: {
    en: '2.05',
    ja: '2.05',
  },
  D010V0013: {
    en: 'NRT/FRA',
    ja: 'NRT/FRA',
  },
  D010V0014: {
    en: 'In transit',
    ja: '輸送中',
  },
  D010V0015: {
    en: 'CGO READY',
    ja: '貨物出荷',
  },
  D010V0016: {
    en: 'BOND-IN',
    ja: '搬入',
  },
  D010V0017: {
    en: 'LOAD',
    ja: '搭載中',
  },
  D010V0018: {
    en: 'DEPART',
    ja: '出発',
  },
  D010V0019: {
    en: 'IN TRANSIT',
    ja: '輸送中',
  },
  D010V0020: {
    en: 'ARRIVE',
    ja: '到着',
  },
  D010V0021: {
    en: 'UNLOAD',
    ja: '降載',
  },
  D010V0022: {
    en: 'BOND-OUT',
    ja: '搬出',
  },
  D010V0023: {
    en: 'DELIVER',
    ja: '配送',
  },
  D010V0024: {
    en: 'LOADED',
    ja: '搭載済',
  },
  D010V0025: {
    en: 'DEPARTED',
    ja: '出発済',
  },
  D010V0026: {
    en: 'IN TRANSITED',
    ja: '移行済',
  },
  D010V0027: {
    en: 'ARRIVED',
    ja: '到着済',
  },
  D010V0028: {
    en: 'UNLOADED',
    ja: '降載済',
  },
  D010V0029: {
    en: 'DELIVERED',
    ja: '発送済',
  },
  D010V0030: {
    en: 'NRT',
    ja: 'NRT',
  },
  D010V0031: {
    en: 'FRA',
    ja: 'FRA',
  },
  D010V0032: {
    en: 'Aug. 11',
    ja: '8月11日',
  },
  D010V0033: {
    en: 'Aug. 12',
    ja: '8月12日',
  },
  D010V0034: {
    en: 'Aug. 13',
    ja: '8月13日',
  },
  D010V0035: {
    en: 'Aug. 14',
    ja: '8月14日',
  },
  D010V0036: {
    en: '22:05',
    ja: '22:05',
  },
  D010V0037: {
    en: '17:55',
    ja: '17:55',
  },
  D010V0038: {
    en: '21:03',
    ja: '21:03',
  },
  D010V0039: {
    en: 'Flight#',
    ja: 'フライト#',
  },
  D010V0040: {
    en: 'Carrier',
    ja: '航空会社',
  },
  D010V0041: {
    en: 'Aircraft',
    ja: '航空機',
  },
  D010V0042: {
    en: 'ULD#',
    ja: 'ULD#',
  },
  D010V0043: {
    en: 'ETD / ETA',
    ja: 'ETD / ETA',
  },
  D010V0044: {
    en: 'ATD / ATA',
    ja: 'ATD / ATA',
  },
  D010V0045: {
    en: 'JAPAN AIRLINES',
    ja: '日本航空',
  },
  D010V0046: {
    en: 'JL407',
    ja: 'JL407',
  },
  D010V0047: {
    en: 'Boeing787-900',
    ja: 'ボーイング787-900',
  },
  D010V0048: {
    en: 'PMCXXXXXJL',
    ja: 'PMCXXXXXJL',
  },
  D010V0049: {
    en: '11:25 / 16:30',
    ja: '11:25 / 16:30',
  },
  D010V0050: {
    en: '11:20 / -',
    ja: '11:20 / -',
  },
  D010V0051: {
    en: 'Show History',
    ja: '履歴を表示',
  },
  D010V0052: {
    en: 'Shipping details',
    ja: '発送詳細',
  },
  D010V0053: {
    en: '-',
    ja: '-',
  },
  D010V0054: {
    en: 'Shipping details - Selected Origin Airport "Kansai Int\'l Airport"',
    ja: '発送詳細 - 選択した出発空港「関西国際空港」',
  },
  D010V0055: {
    en: 'XXXXXX Corporation, Osaka Branch',
    ja: 'XXXXXX株式会社大阪支店',
  },
  D010V0056: {
    en: '2020/08/04',
    ja: '2020/8/4',
  },
  D010V0057: {
    en: 'XX:XX',
    ja: 'XX:XX',
  },
  D010V0058: {
    en: 'Document',
    ja: '書類',
  },
  D010V0059: {
    en: 'Invoice',
    ja: 'インボイス',
  },
  D010V0060: {
    en: 'Packing List',
    ja: 'パッキングリスト',
  },
  D010V0061: {
    en: 'SDS',
    ja: 'SDS',
  },
  D010V0062: {
    en: 'Certificate of Origin',
    ja: '原産地証明書',
  },
  D010V0063: {
    en: 'Quote',
    ja: '見積書',
  },
  D010V0064: {
    en: 'Edit',
    ja: '編集',
  },
  D010V0065: {
    en: 'Delete',
    ja: '削除',
  },
  D010V0066: {
    en: 'Cancel Booking',
    ja: '予約キャンセル',
  },
  D010V0067: {
    en: 'Please tell us the reason why you want to cancel as below;',
    ja: 'キャンセルする理由を以下にご記入ください。',
  },
  D010V0068: {
    en: 'Once you cancel, it needs to re-book from the beginning again.',
    ja: 'キャンセル後は、最初から再度予約する必要があります。',
  },
  D010V0069: {
    en: 'Suspension of shipment',
    ja: '出荷中止',
  },
  D010V0070: {
    en: 'Postpone',
    ja: '出荷延期',
  },
  D010V0071: {
    en: 'Other',
    ja: 'その他',
  },
  D010V0072: {
    en: 'Enter reason',
    ja: '理由を入力',
  },
  D010V0073: {
    en: 'Cancel',
    ja: 'キャンセル',
  },
  D010V0074: {
    en: 'Cargo status was updated as below.',
    ja: '貨物の状態を以下のように更新しました。',
  },
  D010V0075: {
    en: 'Document deficiency',
    ja: '文書の不備',
  },
  D010V0076: {
    en: 'Specific Content',
    ja: '特定のコンテンツ',
  },
  D010V0077: {
    en: 'DGD - Proper Shipping Name UNXXXX',
    ja: 'DGD-配送名を提供UNXXXX',
  },
  D010V0078: {
    en: 'Status',
    ja: '状態',
  },
  D010V0079: {
    en: 'Select and delete',
    ja: '選択して削除',
  },
  D010V0080: {
    en: 'HAWB No.',
    ja: 'ハウス番号',
  },
  D010V0081: {
    en: 'PickUp Time',
    ja: '集荷時間',
  },
  D010V0082: {
    en: '18：00～20：00',
    ja: '18：00～20：00',
  },
  D010V0083: {
    en: 'Change Flight',
    ja: 'フライト変更',
  },
  D010V0084: {
    en: 'Save',
    ja: '保存',
  },
  D010V0085: {
    en: 'Routing',
    ja: 'ルーティング',
  },
  D010V0086: {
    en: 'Via',
    ja: '経由地',
  },
  D010V0087: {
    en: 'No History',
    ja: '履歴はありません',
  },
  D010V0088: {
    en: 'Approve',
    ja: '承認',
  },
  D010V0089: {
    en: 'Quote unapproved',
    ja: '見積り未承認',
  },
  D010V0090: {
    en: 'HAWB',
    ja: 'HAWB',
  },
}
// Display + 画面名
export default DisplayShipperTracing
