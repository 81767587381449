import React, { useContext, useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'

import AppContext from '../../../../../contexts/AppContext'
import { LIGHT_BOOKING_DETAILS } from '../../../../../actions'
import CommonDialog from '../../../../common/dialog/commonDialog/CommonDialog'
import Common from '../../../../../constants/Common'
import CommonValidation from '../../../../common/function/CommonValidation'
import LabelEditPopup from './LabelEditPopup'
import TextFieldEditPopup from './TextFieldEditPopup'

/**
 * @returns {JSX} MeasureAndWeight編集ポップアップ
 */
const MeasureAndWeightEditPopup = () => {
  const { t } = useTranslation()
  const { dispatch, state } = useContext(AppContext)
  const [open, setOpen] = useState(false)

  const { cargoDetail, index, inputError } = state.MeasureAndWeightPopup
  const { pcs, grossWgt, length, width, height } = cargoDetail

  const pcsValidation = CommonValidation.pcs
  const grossWgtValidation = CommonValidation.grossWgtZeroTol
  const cargoValidation = CommonValidation.cargoSizeZeroTol

  // pcs入力欄
  const pcsTextField = useMemo(() => {
    return (
      <TextFieldEditPopup
        dispatch={dispatch}
        valueName="pcs"
        defaultValue={pcs}
        validation={pcsValidation}
      />
    )
  }, [dispatch, pcs, pcsValidation])

  // grossWgt入力欄
  const grossWgtTextField = useMemo(
    () => (
      <TextFieldEditPopup
        dispatch={dispatch}
        valueName="grossWgt"
        defaultValue={grossWgt}
        validation={grossWgtValidation}
      />
    ),
    [dispatch, grossWgt, grossWgtValidation]
  )

  // length入力欄
  const lengthTextField = useMemo(
    () => (
      <TextFieldEditPopup
        dispatch={dispatch}
        valueName="length"
        defaultValue={length}
        validation={cargoValidation}
      />
    ),
    [dispatch, length, cargoValidation]
  )

  // width入力欄
  const widthTextField = useMemo(
    () => (
      <TextFieldEditPopup
        dispatch={dispatch}
        valueName="width"
        defaultValue={width}
        validation={cargoValidation}
      />
    ),
    [dispatch, width, cargoValidation]
  )

  // height入力欄
  const heightTextField = useMemo(
    () => (
      <TextFieldEditPopup
        dispatch={dispatch}
        valueName="height"
        defaultValue={height}
        validation={cargoValidation}
      />
    ),
    [dispatch, height, cargoValidation]
  )

  // ポップアップを開く関数をreducerに登録
  useEffect(() => {
    dispatch({
      type: LIGHT_BOOKING_DETAILS.MEASURE_AND_WEIGHT_POPUP.SET_OPEN_FUNC,
      setOpen,
    })
  }, [dispatch])

  return useMemo(() => {
    /**
     * 貨物情報を登録する
     * @returns {void} なし
     */
    const registCargoDetail = () => {
      dispatch({
        type: LIGHT_BOOKING_DETAILS.CARGO.EDIT_CARGO_DETAILS,
        index,
        cargoDetail,
      })
      setOpen(false)
    }
    // ダイアログのボタン設定
    const dialogFuncObj = {
      [Common.DIALOG.BUTTON_CATEGORY.CANCEL]: { onClick: () => setOpen(false) },
      [Common.DIALOG.BUTTON_CATEGORY.OK]: {
        onClick: registCargoDetail,
        disabled: inputError,
      },
    }
    return (
      <CommonDialog
        open={open}
        closeFunc={() => setOpen(false)}
        dialogFuncObj={dialogFuncObj}
      >
        <Grid
          container
          direction="row"
          justify="center"
          alignContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item md={7}>
            <LabelEditPopup label={t('D103V0003')} />
          </Grid>
          <Grid item md={7}>
            {pcsTextField}
          </Grid>
          <Grid item md={7}>
            <LabelEditPopup label={t('D103V0018')} />
          </Grid>
          <Grid item md={7}>
            {grossWgtTextField}
          </Grid>
          <Grid item md={7}>
            <LabelEditPopup label={t('D103V0019')} />
          </Grid>
          <Grid item md={7}>
            {lengthTextField}
          </Grid>
          <Grid item md={7}>
            <LabelEditPopup label={t('D103V0020')} />
          </Grid>
          <Grid item md={7}>
            {widthTextField}
          </Grid>
          <Grid item md={7}>
            <LabelEditPopup label={t('D103V0021')} />
          </Grid>
          <Grid item md={7}>
            {heightTextField}
          </Grid>
        </Grid>
      </CommonDialog>
    )
  }, [open, inputError, dispatch, t, index, cargoDetail]) //eslint-disable-line
}
export default MeasureAndWeightEditPopup
