// Display + 画面名
const DisplayShipperInvoice = {
  // 画面ID + 文言ID
  D014V0001: {
    en: 'Invoice',
    ja: 'インボイス',
  },
  D014V0002: {
    en: 'No.',
    ja: '番号',
  },
  D014V0003: {
    en: 'Enter Invoice No.',
    ja: 'インボイス番号を入力',
  },
  D014V0004: {
    en: 'Date',
    ja: '日付',
  },
  D014V0005: {
    en: '2020/07/31',
    ja: '2020年7月31日',
  },
  D014V0006: {
    en: 'Place',
    ja: '場所',
  },
  D014V0007: {
    en: 'Osaka, Japan',
    ja: '大阪、日本',
  },
  D014V0008: {
    en: 'Shipper',
    ja: '荷送人',
  },
  D014V0009: {
    en: 'Panasonic Corporation',
    ja: 'パナソニック株式会社',
  },
  D014V0010: {
    en: '1006, Oaza Kadoma, Kadoma-shi, Osaka 571-8501, Japan',
    ja: '571-8501 大阪府門真市大字門真1006番地',
  },
  D014V0011: {
    en: 'Tel.',
    ja: '電話番号',
  },
  D014V0012: {
    en: '81-6-6908-1121',
    ja: '81-6-6908-1121',
  },
  D014V0013: {
    en: 'Attn',
    ja: '担当者',
  },
  D014V0014: {
    en: 'Konosuke Matsushita',
    ja: '松下幸之助',
  },
  D014V0015: {
    en: 'Consignee',
    ja: '荷受人',
  },
  D014V0016: {
    en: 'Panasonic R&D Company of America',
    ja: 'パナソニックR&Dカンパニーオブアメリカ',
  },
  D014V0017: {
    en: '206 Ravendale Dr, Mountain View, CA 94043, United States',
    ja: '206 Ravendale Dr, Mountain View, CA 94043, United States',
  },
  D014V0018: {
    en: '1-408-861-3900',
    ja: '1-408-861-3900',
  },
  D014V0019: {
    en: 'James Collins',
    ja: 'James Collins',
  },
  D014V0020: {
    en: 'By Aircraft',
    ja: '航空機で',
  },
  D014V0021: {
    en: 'On or About',
    ja: '搭載予定日',
  },
  D014V0022: {
    en: 'From',
    ja: '出発',
  },
  D014V0023: {
    en: "KIX - Kansai Int'l Airport",
    ja: 'KIX - 関西国際空港',
  },
  D014V0024: {
    en: 'To',
    ja: '到着',
  },
  D014V0025: {
    en: "SFO - San Francisco Int'l Airport",
    ja: 'SFO - サンフランシスコ国際空港',
  },
  D014V0026: {
    en: 'Trade Terms',
    ja: '取引条件',
  },
  D014V0027: {
    en: 'CIP - Carriage and Insurance Paid to',
    ja: 'CIP - 運送と保険の支払い',
  },
  D014V0028: {
    en: 'Payment Terms',
    ja: '支払い条件',
  },
  D014V0029: {
    en: 'T.T Remittance',
    ja: 'T.T送金',
  },
  D014V0030: {
    en: 'Description of Goods',
    ja: '商品概要',
  },
  D014V0031: {
    en: 'Unit Price',
    ja: '単価',
  },
  D014V0032: {
    en: 'Amount',
    ja: '合計金額',
  },
  D014V0033: {
    en: 'Enter Pcs',
    ja: '個数を入力',
  },
  D014V0034: {
    en: 'Select',
    ja: '選択',
  },
  D014V0035: {
    en: 'of',
    ja: 'の',
  },
  D014V0036: {
    en: 'Enter Goods description',
    ja: '商品概要を入力',
  },
  D014V0037: {
    en: 'US$ XX,XXX.XX',
    ja: 'US$ XX,XXX.XX',
  },
  D014V0038: {
    en: 'Add',
    ja: '追加',
  },
  D014V0039: {
    en: 'Total',
    ja: '合計',
  },
  D014V0040: {
    en: 'Country of Origin',
    ja: '原産国',
  },
  D014V0041: {
    en: 'XXXX',
    ja: 'XXXX',
  },
  D014V0042: {
    en: 'Signature',
    ja: '署名',
  },
  D014V0043: {
    en: 'Upload',
    ja: 'アップロード',
  },
  D014V0044: {
    en: 'Back',
    ja: '戻る',
  },
  D014V0045: {
    en: 'Delete',
    ja: '削除',
  },
  D014V0046: {
    en: 'PCS',
    ja: '個',
  },
  D014V0047: {
    en: 'Shipped Per',
    ja: '輸送方法',
  },
  D014V0048: {
    en: 'OK',
    ja: 'OK',
  },
  D014V0049: {
    en: 'Cancel',
    ja: 'キャンセル',
  },
  D014V0050: {
    en: 'Edit',
    ja: '編集',
  },
  D014V0051: {
    en: 'Enter Unit price',
    ja: '単価を入力',
  },
  D014V0052: {
    en: 'Please enter no more than 10 single-byte numbers.',
    ja: '半角数字10桁以下で入力してください。',
  },
  D014V0053: {
    en: 'Page',
    ja: 'ページ',
  },
  D014V0054: {
    en: 'Please enter no more than 5 single-byte numbers.',
    ja: '半角数字5桁以下で入力してください。',
  },
}
// Display + 画面名
export default DisplayShipperInvoice
