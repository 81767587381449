import { combineReducers } from 'redux'

import Tables, { initTable } from './Tables'
import Auth, { initAuth } from '../../common/Auth'
import Paging, { initPaging } from '../../common/Paging'
import SearchSort, { initSearchSort } from '../../common/SearchSort'
import Gsa, { initGsa } from '../../common/Gsa'
import Currency, { initCurrency } from '../../common/Currency'
import CurrencyValue, { initCurrencyValue } from './CurrencyValue'
import Forwarder, { initForwarder } from './Forwarder'

const initialState = {
  Tables: initTable,
  Auth: initAuth,
  SearchSort: initSearchSort,
  Paging: initPaging,
  Gsa: initGsa,
  Currency: initCurrency,
  CurrencyValue: initCurrencyValue,
  Forwarder: initForwarder,
}

export default combineReducers({
  Tables,
  Auth,
  SearchSort,
  Paging,
  Gsa,
  Currency,
  CurrencyValue,
  Forwarder,
})

export { initialState }
