// Display + 画面名
const DisplayFwdrTracing = {
  // 画面ID + 文言ID
  D111V0001: {
    en: 'TRACING',
    ja: 'トレーシング',
  },
  D111V0002: {
    en: 'MAWB No.',
    ja: 'マスター番号',
  },
  D111V0003: {
    en: 'Pcs.',
    ja: '個数',
  },
  D111V0004: {
    en: 'G/W (kg)',
    ja: '総重量(kg)',
  },
  D111V0005: {
    en: 'Vol.(m3)',
    ja: '容積(m3)',
  },
  D111V0006: {
    en: 'C/W (kg)',
    ja: '賃率適用重量(kg)',
  },
  D111V0007: {
    en: 'ORG/DST',
    ja: '出発/到着',
  },
  D111V0008: {
    en: 'Current Status',
    ja: 'ステータス',
  },
  D111V0009: {
    en: 'XXX-XXXX XXXX',
    ja: 'XXX-XXXX XXXX',
  },
  D111V0010: {
    en: '10',
    ja: '10',
  },
  D111V0011: {
    en: '300.00',
    ja: '300.00',
  },
  D111V0012: {
    en: '2.05',
    ja: '2.05',
  },
  D111V0013: {
    en: 'KIX/DMM',
    ja: 'KIX/DMM',
  },
  D111V0014: {
    en: 'Off-Loaded due to ACL Over',
    ja: 'ACLオーバーによるオフロード',
  },
  D111V0015: {
    en: 'CGO READY',
    ja: '貨物出荷',
  },
  D111V0016: {
    en: 'BOND-IN',
    ja: '搬入',
  },
  D111V0017: {
    en: 'LOAD',
    ja: '搭載中',
  },
  D111V0018: {
    en: 'DEPART',
    ja: '出発',
  },
  D111V0019: {
    en: 'IN TRANSIT',
    ja: '輸送中',
  },
  D111V0020: {
    en: 'ARRIVE',
    ja: '到着',
  },
  D111V0021: {
    en: 'UNLOAD',
    ja: '降載',
  },
  D111V0022: {
    en: 'BOND-OUT',
    ja: '搬出',
  },
  D111V0023: {
    en: 'DELIVER',
    ja: '配送',
  },
  D111V0024: {
    en: 'LOADED',
    ja: '搭載済',
  },
  D111V0025: {
    en: 'DEPARTED',
    ja: '出発済',
  },
  D111V0026: {
    en: 'IN TRANSITED',
    ja: '移行済',
  },
  D111V0027: {
    en: 'ARRIVED',
    ja: '到着済',
  },
  D111V0028: {
    en: 'UNLOADED',
    ja: '降載済',
  },
  D111V0029: {
    en: 'DELIVERD',
    ja: '配送済',
  },
  D111V0030: {
    en: 'KIX',
    ja: 'NRT',
  },
  D111V0031: {
    en: 'FRA',
    ja: 'FRA',
  },
  D111V0032: {
    en: 'Aug 11',
    ja: '8月11日',
  },
  D111V0033: {
    en: 'Aug 12',
    ja: '8月12日',
  },
  D111V0034: {
    en: 'Aug 13',
    ja: '8月13日',
  },
  D111V0035: {
    en: 'Aug 14',
    ja: '8月14日',
  },
  D111V0036: {
    en: '22:05',
    ja: '22:05',
  },
  D111V0037: {
    en: 'Flight#',
    ja: 'フライト＃',
  },
  D111V0038: {
    en: 'Carrier',
    ja: '航空会社',
  },
  D111V0039: {
    en: 'JAPAN AIRLINES',
    ja: '日本航空',
  },
  D111V0040: {
    en: 'JL407/12AUG',
    ja: 'JL407/11AUG',
  },
  D111V0041: {
    en: '→',
    ja: '→',
  },
  D111V0042: {
    en: 'JL407/13AUG',
    ja: 'JL407/12AUG',
  },
  D111V0043: {
    en: 'Aircraft',
    ja: '航空機',
  },
  D111V0044: {
    en: 'Boeing787-900',
    ja: 'ボーイング787-900',
  },
  D111V0045: {
    en: 'ULD#',
    ja: 'ULD＃',
  },
  D111V0046: {
    en: 'PMCXXXXXJL',
    ja: 'PMCXXXXXJL',
  },
  D111V0047: {
    en: 'ETD/ETA',
    ja: 'ETD/ETA',
  },
  D111V0048: {
    en: '11:25/16:30',
    ja: '11:25/16:30',
  },
  D111V0049: {
    en: 'ATD/ATA',
    ja: 'ATD/ATA',
  },
  D111V0050: {
    en: '-/-',
    ja: '-/-',
  },
  D111V0051: {
    en: 'Show History',
    ja: '履歴を表示',
  },
  D111V0052: {
    en: 'CONSOL BOOKING - Enter HAWB#"YLK-XXXX XXXX"',
    ja: 'CONSOL BOOKING - Enter HAWB#"YLK-XXXX XXXX"',
  },
  D111V0053: {
    en: 'LOADED - KZXXX/16AUG',
    ja: 'LOADED - KZXXX/16AUG',
  },
  D111V0054: {
    en: 'Yusen Logistics, Osaka Branch',
    ja: '郵船ロジスティクス大阪支店',
  },
  D111V0055: {
    en: 'Yusen Logistics, Tokyo Branch',
    ja: '郵船ロジスティクス東京支店',
  },
  D111V0056: {
    en: "Nippon Cargo Airlines, Narita Int'l Airport",
    ja: '日本貨物航空・成田国際空港',
  },
  D111V0057: {
    en: '2020/08/04',
    ja: '2020/08/04',
  },
  D111V0058: {
    en: 'XX:XX',
    ja: 'XX:XX',
  },
  D111V0059: {
    en: 'NINABA',
    ja: 'NINABA',
  },
  D111V0060: {
    en: 'YYANAGIKO',
    ja: 'YYANAGIKO',
  },
  D111V0061: {
    en: 'Document',
    ja: '書類',
  },
  D111V0062: {
    en: 'HAWB',
    ja: 'HAWB',
  },
  D111V0063: {
    en: 'House Manifest',
    ja: 'House Manifest',
  },
  D111V0064: {
    en: 'MAWB',
    ja: 'MAWB',
  },
  D111V0065: {
    en: 'DGD',
    ja: 'DGD',
  },
  D111V0066: {
    en: 'Label & Mark',
    ja: 'ラベル＆マーク',
  },
  D111V0067: {
    en: 'Invoice',
    ja: 'インボイス',
  },
  D111V0068: {
    en: 'Packing List',
    ja: 'パッキングリスト',
  },
  D111V0069: {
    en: 'SDS',
    ja: 'SDS',
  },
  D111V0070: {
    en: 'Certificate of Origin',
    ja: '原産地証明書',
  },
  D111V0071: {
    en: 'Quote',
    ja: '見積書',
  },
  D111V0072: {
    en: 'Edit',
    ja: '編集',
  },
  D111V0073: {
    en: 'Delete',
    ja: '削除',
  },
  D111V0074: {
    en: 'Routing',
    ja: 'ルーティング',
  },
  D111V0075: {
    en: 'Routing due to airline for certain reasons',
    ja: '特定の理由により航空会社が変更する場合があります。',
  },
  D111V0076: {
    en: 'ORG',
    ja: '出発',
  },
  D111V0077: {
    en: 'DST',
    ja: '到着',
  },
  D111V0078: {
    en: 'Allotment',
    ja: 'アロットメント',
  },
  D111V0079: {
    en: 'NRT',
    ja: 'NRT',
  },
  D111V0080: {
    en: 'ORD',
    ja: 'ORD',
  },
  D111V0081: {
    en: 'UA',
    ja: 'UA',
  },
  D111V0082: {
    en: 'TRK',
    ja: 'TRK',
  },
  D111V0083: {
    en: 'XXX/30AUG',
    ja: 'XXX/30AUG',
  },
  D111V0084: {
    en: '-',
    ja: '-',
  },
  D111V0085: {
    en: 'XXX/31AUG',
    ja: 'XXX/31AUG',
  },
  D111V0086: {
    en: '5',
    ja: '5',
  },
  D111V0087: {
    en: '15',
    ja: '15',
  },
  D111V0088: {
    en: '5.00',
    ja: '5.00',
  },
  D111V0089: {
    en: '500.00',
    ja: '500.00',
  },
  D111V0090: {
    en: '750.00',
    ja: '750.00',
  },
  D111V0091: {
    en: '7.25',
    ja: '7.25',
  },
  D111V0092: {
    en: 'MUST GO H-XXXXXXXX',
    ja: 'MUST GO H-XXXXXXXX',
  },
  D111V0093: {
    en: 'STBY TL 30AUG H-XXXXXXXX',
    ja: 'STBY TL 30AUG H-XXXXXXXX',
  },
  D111V0094: {
    en: 'Allot',
    ja: 'アロットメント',
  },
  D111V0095: {
    en: 'Total',
    ja: '合計',
  },
  D111V0096: {
    en: '20',
    ja: '20',
  },
  D111V0097: {
    en: '1250.00',
    ja: '1250.00',
  },
  D111V0098: {
    en: '12.25',
    ja: '12.25',
  },
  D111V0099: {
    en: 'Status',
    ja: 'ステータス',
  },
  D111V0100: {
    en: 'Cargo status was updated as below.',
    ja: '貨物ステータスが以下にて更新されました。',
  },
  D111V0101: {
    en: 'Document dificiency',
    ja: '文書不備',
  },
  D111V0102: {
    en: 'Specific Content',
    ja: '特定のコンテンツ',
  },
  D111V0103: {
    en: 'DGD-Proper Shipping Name UNXXXX',
    ja: 'DGD-Proper Shipping Name UNXXXX',
  },
  D111V0104: {
    en: 'Correct',
    ja: '訂正',
  },
  D111V0105: {
    en: 'Cancel Booking',
    ja: '予約キャンセル',
  },
  D111V0106: {
    en: 'Please tell us the reason why you want to cancel as below;',
    ja: 'キャンセルする理由を以下にご記入ください。',
  },
  D111V0107: {
    en: 'Once you cancel, it needs to re-book from the beginning again.',
    ja: 'キャンセル後は、最初から再度予約する必要があります。',
  },
  D111V0108: {
    en: 'Suspension of shipment',
    ja: '出荷中止',
  },
  D111V0109: {
    en: 'Postpone',
    ja: '出荷延期',
  },
  D111V0110: {
    en: 'Other',
    ja: 'その他',
  },
  D111V0111: {
    en: 'Enter reason',
    ja: '理由を入力',
  },
  D111V0112: {
    en: 'Cancel',
    ja: 'キャンセル',
  },
  D111V0113: {
    en: 'Wgt.',
    ja: '重量',
  },
  D111V0114: {
    en: 'Vol.',
    ja: '容積',
  },
  D111V0115: {
    en: "NRT - Narita Int'l Airport",
    ja: 'NRT-成田国際空港',
  },
  D111V0116: {
    en: 'Select and Delete',
    ja: '選択して削除',
  },
  D111V0117: {
    en: 'Split AWB',
    ja: 'AWBを分ける',
  },
  D111V0118: {
    en: 'Yes',
    ja: 'はい',
  },
  D111V0119: {
    en: 'No',
    ja: 'いいえ',
  },
  D111V0120: {
    en: 'HAWB No.',
    ja: 'ハウス番号',
  },
  D111V0121: {
    en: 'PickUp Time',
    ja: '集荷時間',
  },
  D111V0122: {
    en: '18:00～20:00',
    ja: '18:00～20:00',
  },
  D111V0123: {
    en: 'Delivered',
    ja: '配送完了',
  },
  D111V0124: {
    en: 'Show',
    ja: 'Show',
  },
  D111V0125: {
    en: '- / -',
    ja: '- / -',
  },
  D111V0126: {
    en: 'No history',
    ja: '履歴がありません',
  },
  D111V0127: {
    en: 'Contains invalid characters.',
    ja: '無効な文字が含まれています。',
  },
  D111V0128: {
    en: 'Cargo Manifest',
    ja: 'Cargo Manifest',
  },
  D111V0129: {
    en: 'Notoc',
    ja: 'Notoc',
  },
  D111V0130: {
    en: '※ Those displayed in red letters have been cancelled',
    ja: '※ 赤い文字で表示されているものはキャンセルされました',
  },
  D111V0131: {
    en: 'Wetting, crushing, Scratch, etc.',
    ja: 'ぬれ、つぶれ、引っかき傷など',
  },
  D111V0132: {
    en: 'Enter other status',
    ja: '他のステータスを入力してください',
  },
  D111V0133: {
    en: 'Via',
    ja: '経由地',
  },
}
// Display + 画面名
export default DisplayFwdrTracing
