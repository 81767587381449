import React, { useContext } from 'react'
import Grid from '@material-ui/core/Grid'

import Question from './Question'
import Radios from './Radios'
import AppContext from '../../../../../contexts/AppContext'
import { UPDATE_QUESTION4 } from '../../../../../actions'
/**
 * 質問文と選択肢を表示
 * @returns {*} - Question
 */
const Q4 = () => {
  const { state, dispatch } = useContext(AppContext)
  const { Q4 } = state
  const { texts, radios, check } = Q4

  const onSatisfactionChange = (_event, value) => {
    dispatch({
      type: UPDATE_QUESTION4,
      answer: value,
    })
  }

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      spacing={2}
    >
      {/* 質問文 */}
      <Question texts={texts} />
      <Radios radios={radios} value={check} onChange={onSatisfactionChange} />
    </Grid>
  )
}

export default Q4
