import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import TextField from '../../common/text/TextField'
import CommonValidation from '../../common/function/CommonValidation'
import {
  UPDATE_FWDR_TEL,
  UPDATE_FWDR_TEL_INPUTERR,
  UPDATE_CAR_TEL,
  UPDATE_CAR_TEL_INPUTERR,
} from '../../../actions'

const useStyles = makeStyles((theme) => ({
  head: theme.textBoldBLU,
}))

/**
 * コンポーネントを更新するか判定する
 * @param {Object} prev 前回の値
 * @param {Object} next 最新の値
 * @returns {Boolean} falseの場合更新する
 */
const areEqual = (prev, next) => {
  // 各入力欄の値を比較
  // 一致していれば更新無し
  return prev.tel.value === next.tel.value
}

const Telephone = React.memo(({ dispatch, tel, carrier }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [inputErrs, setInputErrs] = useState({ tel: false })
  const [helperTxts, setHelperTxts] = useState({ tel: '' })

  const handleChangeTel = (event) => {
    const value = event.target.value
    // 入力チェック
    const validationCheck = CommonValidation.telephone(value)
    const err = validationCheck.checkResult
    setInputErrs({ ...inputErrs, tel: err })
    setHelperTxts({ ...helperTxts, tel: validationCheck.errMsg })

    dispatch({
      type: carrier ? UPDATE_CAR_TEL : UPDATE_FWDR_TEL,
      tel: value,
    })

    // 入力エラーの場合、Savaボタン無効
    dispatch({
      type: carrier ? UPDATE_CAR_TEL_INPUTERR : UPDATE_FWDR_TEL_INPUTERR,
      inputErr: err,
    })
  }

  return (
    <div className={classes.formControl}>
      <Grid container>
        <Grid item md={4} xs={10}>
          <div className={classes.head}>{t('D301V0011')}</div>
          <TextField
            id="tel"
            error={inputErrs.tel}
            helperText={t(helperTxts.tel)}
            value={tel.value}
            onChange={handleChangeTel}
            autoComplete="off"
          />
        </Grid>
      </Grid>
    </div>
  )
}, areEqual)

Telephone.displayName = 'Telephone'

Telephone.propTypes = {
  dispatch: PropTypes.func.isRequired,
  tel: PropTypes.object.isRequired,
  carrier: PropTypes.bool,
}

export default Telephone
