import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import PrimaryButton from '../../../../common/buttons/PrimaryButton'
import AppContext from '../../../../../contexts/AppContext'
import Paths from '../../../../../constants/Paths'
import CommonFunc from '../../../../common/function/CommonFunc'

/**
 * @return {JSX} SetForwarderSelectボタンコンポーネント
 */
const SelectButtonBidInfo = () => {
  const history = useHistory()
  const { state } = useContext(AppContext)
  const { t } = useTranslation()

  const transitionPage = () =>
    CommonFunc.shipperBidInfoTransitionPage(
      state,
      history,
      Paths.SHIPPER.SELECT_FORWARDER
    )

  return (
    <Grid container item alignItems="center" justify="center" xs={2}>
      <Grid item>
        <PrimaryButton onClick={transitionPage}>{t('D014V0034')}</PrimaryButton>
      </Grid>
    </Grid>
  )
}

export default SelectButtonBidInfo
