import Paths from '../../../../../constants/Paths'

const ConstantsClientReg = {
  CARD_SETTING: {
    AMOUNT_SETTING: {
      [Paths.JH.LIGHT_JH_CLIENT_REG_SIGNUP]: 4,
      [Paths.JH.LIGHT_JH_CLIENT_REG_DELETE_ACCOUNT]: 2,
    },
    ORDER: {
      SHIPPER: [
        {
          title: 'D401V0006',
          key: 'date',
          titleGrid: { xs: 2 },
          contentGrid: { xs: 10 },
          isDate: true,
        },
        { title: 'D401V0007', key: 'company' },
        { title: 'D401V0052', key: 'shipperConsigneeId' },
        { title: 'D401V0008', key: 'branch' },
        { title: 'D401V0010', key: 'firstName' },
        { title: 'D401V0011', key: 'lastName' },
        { title: 'D401V0012', key: 'position' },
        { title: 'D401V0013', key: 'mail' },
        { title: 'D401V0014', key: 'tel' },
        { title: 'D401V0053', key: 'postalCode' },
        { title: 'D401V0015', key: 'address' },
        { title: 'D401V0051', key: 'airport' },
      ],
      FWDR: [
        {
          title: 'D401V0006',
          key: 'date',
          titleGrid: { xs: 2 },
          contentGrid: { xs: 10 },
          isDate: true,
        },
        { title: 'D401V0007', key: 'company' },
        { title: 'D401V0054', key: 'iataCassNo' },
        { title: 'D401V0008', key: 'branch' },
        { title: 'D401V0057', key: 'airport' },
        { title: 'D401V0010', key: 'firstName' },
        { title: 'D401V0011', key: 'lastName' },
        { title: 'D401V0012', key: 'position' },
        { title: 'D401V0013', key: 'mail' },
        { title: 'D401V0014', key: 'tel' },
        { title: 'D401V0053', key: 'postalCode' },
        { title: 'D401V0015', key: 'address' },
      ],
      CARRIER: [
        {
          title: 'D401V0006',
          key: 'date',
          titleGrid: { xs: 2 },
          contentGrid: { xs: 10 },
          isDate: true,
        },
        { title: 'D401V0007', key: 'company' },
        { title: 'D401V0054', key: 'iataCassNo' },
        { title: 'D401V0008', key: 'branch' },
        { title: 'D401V0055', key: 'department' },
        { title: 'D401V0056', key: 'hubs' },
        { title: 'D401V0010', key: 'firstName' },
        { title: 'D401V0011', key: 'lastName' },
        { title: 'D401V0012', key: 'position' },
        { title: 'D401V0013', key: 'mail' },
        { title: 'D401V0014', key: 'tel' },
        { title: 'D401V0053', key: 'postalCode' },
        { title: 'D401V0015', key: 'address' },
      ],
    },
  },
}

export default ConstantsClientReg
