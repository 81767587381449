import React from 'react'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'

import TitleShippingDetailsLightBookingDetails from './TitleShippingDetailsLightBookingDetails'
import ContentShippingDetailsLightBookingDetails from './ContentShippingDetailsLightBookingDetails'

/**
 * @param {Object} props 下記内容
 * @param {string} props.title タイトル文言
 * @param {string} props.content 表示内容
 * @return {JSX} ShippingDetailsのCommodity欄
 */
const CommodityShippingDetailsLightBookingDetails = ({ title, content }) => {
  return (
    <Grid item md={12}>
      <TitleShippingDetailsLightBookingDetails label={title} />
      <ContentShippingDetailsLightBookingDetails label={content} />
    </Grid>
  )
}

CommodityShippingDetailsLightBookingDetails.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
}

export default CommodityShippingDetailsLightBookingDetails
