import React from 'react'
import { useTranslation } from 'react-i18next'

import Title from '../../../../common/title/Title'

/**
 * @return {JSX} タイトルコンポーネント
 */
const TitleLightBookingRequest = () => {
  const { t } = useTranslation()
  return <Title>{t('4th0021')}</Title>
}

export default TitleLightBookingRequest
