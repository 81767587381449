import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import AppContext from '../../../../../contexts/AppContext'
import XsmallButton from '../../../../common/buttons/XsmallButton'
import { SHC_DIALOG } from '../../../../../actions'

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
}))

/**
 * @param {object} props 下記内容
 * @param {String[]} props.shcArr SHCの配列
 * @return {JSX} SHCのShowボタン
 */
const ShcButtonLightAirlineBookingList = ({ shcArr }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { dispatch } = useContext(AppContext)

  /**
   * ボタン押下時のイベント
   * @returns {void} なし
   */
  const onClick = () => {
    // ポップアップを開く
    dispatch({
      type: SHC_DIALOG.OPEN,
      shcArr,
    })
  }

  return (
    <XsmallButton addclass={classes.button} onClick={onClick}>
      {t('D111V0124')}
    </XsmallButton>
  )
}

ShcButtonLightAirlineBookingList.propTypes = {
  shcArr: PropTypes.array.isRequired,
}

export default ShcButtonLightAirlineBookingList
