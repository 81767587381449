import { LIGHT_BOOKING_DETAILS } from '../../../actions'

/** @type{Object} 初期表示 */
const initMawbNumber = {
  mawbId: null,
  inputError: false,
  updatedAt: null,
  mawbNumberObj: {
    firstHalf: undefined,
    latterHalf: undefined,
    prefix: undefined,
  },
}

/**
 * リクエストデータを作成する
 * @param {Number|null} mawbId mawbId
 * @param {String|null} updatedAt updatedAt
 * @param {Object} mawbNumberObj mawbNumber情報
 * @returns {Object} リクエストデータ
 */
const createRequestData = (mawbId, updatedAt, mawbNumberObj) => {
  const mawbNumber = `${mawbNumberObj.firstHalf}${mawbNumberObj.latterHalf}`
  const prefix = mawbNumberObj.prefix
  return mawbNumber !== '' ? { mawbNumber, prefix, mawbId, updatedAt } : {}
}

/**
 * MawbNoを保持
 * @param {Object} action action
 * @param {Object} state state
 * @return {Object} MawbNo
 */
const setMawbNumber = (action, state) => {
  const {
    mawbNumber = '',
    prefix = '',
    mawbId = null,
    updatedAt = null,
  } = action

  const mawbNumberObj = {
    firstHalf: mawbNumber.slice(0, 4), //前半4桁
    latterHalf: mawbNumber.slice(4), //後半4桁
    prefix,
  }
  const getRequestData = () =>
    createRequestData(mawbId, updatedAt, mawbNumberObj)
  return { ...state, mawbId, updatedAt, mawbNumberObj, getRequestData }
}

/**
 * MawbNoを編集
 * @param {Object} action action
 * @param {Object} state state
 * @return {Object} MawbNo
 */
const editMawbNumber = (action, state) => {
  const { valueName, value } = action
  state.mawbNumberObj[valueName] = value
  const error = Object.values(state.mawbNumberObj).some(
    (value) => value === null
  )
  const isAnyInput = Object.values(state.mawbNumberObj).some(
    (value) => value !== '' && value !== null
  )
  const isEmpty = Object.values(state.mawbNumberObj).some(
    (value) => value === ''
  )
  const emptyError = isAnyInput && isEmpty
  const inputError = error || emptyError
  return { ...state, inputError }
}

/**
 * MawbNoを保持
 * @param {Boolean} [state=initMawbNumber] state
 * @param {Object} action action
 * @return {Boolean} MawbNo
 */
const MawbNumber = (state = initMawbNumber, action) => {
  const { type, ...rest } = action
  switch (type) {
    case LIGHT_BOOKING_DETAILS.MAWB.SET:
      return setMawbNumber(rest, state)
    case LIGHT_BOOKING_DETAILS.MAWB.EDIT:
      return editMawbNumber(rest, state)
    default:
      return state
  }
}

export default MawbNumber

export { initMawbNumber }
