import { UPDATE_FWDR_BACKUP } from '../../../actions'

const initBackup = {
  companyName: '',
  postalCode: '',
  address: '',
  bondInAddress: '',
  cityName: '',
  cityCode: '',
  airport: '',
  representativeFlg: '',
  firstName: '',
  lastName: '',
  position: '',
  mail: '',
  tel: '',
  iataOrcassNumber: '',
  icon: '',
  fwdrPlanId: '',
}
const Events = (state = {}, action) => {
  switch (action.type) {
    //------------------
    // backupを更新
    //------------------
    case UPDATE_FWDR_BACKUP:
      return { ...state, ...action.info }
    default:
      return state
  }
}

export default Events

export { initBackup }
