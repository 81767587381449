/* eslint-disable prefer-template */
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import MDialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'

import PrimaryButton from '../buttons/PrimaryButton'
import SecondaryButton from '../buttons/SecondaryButton'
import DetailButton from '../buttons/DetailButton'
import CommonFunc from '../function/CommonFunc'

const useStyles = makeStyles((theme) => ({
  root: theme.dialog,
  dialog: theme.dialogContent,
  transparent: {
    background: 'transparent',
    border: '0px solid',
  },
  icon: {
    fontSize: 24,
    color: theme.palette.required,
    margin: 20,
  },
  text: theme.saveText,
  mobile: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  desktop: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  margin: {
    margin: 10,
  },
  marginTop: {
    marginTop: '2rem',
  },
}))

/**
 * @param {Object} props - 以下
 * @param {*} buttonPC - ボタンコンポーネントを指定
 * @param {Event} onClose - close時のイベントを指定
 * @returns {JSX.Element} - Dialog
 */
const RegisterAgainDialog = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [scroll, setScroll] = useState('body')
  const [pageX, setPageX] = useState(0)

  const handleClickOpen = (scrollType) => () => {
    // windowのスクロール設定を記録してから非表示
    CommonFunc.hideScroll()
    setOpen(true)
    setScroll(scrollType)
  }

  const pageTranslation = () => {
    // windowのスクロール設定を戻す
    CommonFunc.showScroll()
    setOpen(false)
  }

  /**
   * ダイアログを閉じる
   * @param {Event} event - onCloseのイベント
   * @param {Boolean} isDialogBackGround - ダイアログ背景をクリックしているかの判定
   * @returns {void}
   */
  const handleClose = (event, isDialogBackGround) => {
    /** 現在クリック位置が要素外(スクロール部分)をクリックしているか判定 */
    const isScrollClick = isDialogBackGround
      ? event.target.clientWidth - pageX < 0
      : false
    if (isScrollClick) {
      // スクロール部分をクリックしている場合は何もしない
      return
    } else {
      // windowのスクロール設定を戻す
      CommonFunc.showScroll()
      // ダイアログを閉じる
      setOpen(false)
    }
  }

  /**
   * クリックした瞬間の位置を保存
   * @param {Event} event - event onMousedownのイベント
   * @returns {void}
   */
  const savePageXPosition = (event) => setPageX(event.pageX)

  return (
    <>
      {/* ボタン */}
      <div onClick={handleClickOpen('body')}>
        {props.buttonPC ? (
          props.buttonPC
        ) : (
          <DetailButton>{t('D004V0060')}</DetailButton>
        )}
      </div>

      {/* ダイアログ */}
      <MDialog
        open={open}
        scroll={scroll}
        onClose={(e) => handleClose(e, true)}
        onMouseDown={savePageXPosition}
        className={classes.root + ' ' + props.className}
      >
        <Grid container item justify="center" alignItems="center">
          <WarningRoundedIcon className={classes.icon} />
        </Grid>
        {/* ダイアログ本文 */}
        <DialogContent>
          <div className={classes.dialog + ' ' + classes.transparent}>
            {/* タイトル */}
            <Grid container item justify="center" alignItems="center"></Grid>
            {/* 本文 */}
            <Grid
              container
              justify="center"
              alignContent="center"
              spacing={1}
              className={classes.marginTop}
            >
              <Grid item xs={12}>
                <div className={classes.text}>{t('D501V0087')}</div>
                <div className={classes.text}>{t('D501V0088')}</div>
              </Grid>
            </Grid>
          </div>
        </DialogContent>

        {/* ダイアログ下部のボタン */}
        <DialogActions>
          {/* スマホ */}
          <Grid
            container
            direction="column"
            alignContent="center"
            className={classes.mobile + ' ' + classes.margin}
            spacing={3}
          >
            <Grid item md={12} xs={12}>
              <div className={classes.center}>
                <div onClick={pageTranslation}>
                  <PrimaryButton path={props.path}>
                    {t('D202V0080')}
                  </PrimaryButton>
                </div>
              </div>
            </Grid>
            <Grid item md={12} xs={12}>
              <div className={classes.center}>
                <div onClick={handleClose}>
                  <SecondaryButton>{t('D202V0081')}</SecondaryButton>
                </div>
              </div>
            </Grid>
          </Grid>
          {/* PC */}
          <Grid
            container
            justify="center"
            spacing={3}
            className={classes.desktop + ' ' + classes.margin}
          >
            <Grid item>
              <div className={classes.center}>
                <div onClick={handleClose}>
                  <SecondaryButton>{t('D202V0081')}</SecondaryButton>
                </div>
              </div>
            </Grid>
            <Grid item>
              <div className={classes.center}>
                <div onClick={pageTranslation}>
                  <PrimaryButton path={props.path}>
                    {t('D202V0080')}
                  </PrimaryButton>
                </div>
              </div>
            </Grid>
          </Grid>
        </DialogActions>
      </MDialog>
    </>
  )
}

export default RegisterAgainDialog
