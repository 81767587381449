import React from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import HeaderTextRequired from '../../../../common/text/HeaderTextRequired'

/**
 * @return {JSX} PICヘッダーコンポーネント
 */
const HeaderPicBidInfo = () => {
  const { t } = useTranslation()

  return (
    <Grid item md={12} xs={12}>
      <HeaderTextRequired>{t('D302V0033')}</HeaderTextRequired>
    </Grid>
  )
}

export default HeaderPicBidInfo
