import { UPDATE_FWDR_GROUP } from '../../../actions'

const initFwdrGroup = null

/**
 * 混載業者区分を管理
 * @param {Object} [state={}] 混載業者区分
 * @param {Object} action 混載業者区分の更新に必要な値
 * @return {Object} 混載業者区分
 */
const FwdrGroup = (state = initFwdrGroup, action) => {
  switch (action.type) {
    case UPDATE_FWDR_GROUP:
      return action.group
    default:
      return state
  }
}

export default FwdrGroup

export { initFwdrGroup }
