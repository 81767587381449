/* eslint-disable no-unneeded-ternary */
/* eslint-disable prefer-template */
import React from 'react'
import { makeStyles } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

import TextField from '../text/TextField'

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiInputBase-root': {
      height: 48,
      padding: '6px 0px 7px 16px',
    },
  },
}))

/**
 * @param {Object} props - 以下
 * @param {Style} className - スタイルを指定
 * @param {Style} inputClass - 入力フォームのスタイルを指定
 * @param {String} id - idを指定
 * @param {String} label - labelを指定
 * @param {String} placeholder - placeholderを指定
 * @param {object} options - セレクトの中身を指定
 * @param {getOptionLabel} getOptionLabel - セレクトの文言を指定
 * @param {event} onClick - クリック時のイベントを指定
 * @param {event} onFocus - フォーカス時のイベントを指定
 * @param {event} onChange - 値変更時のイベントを指定
 * @param {Boolean} disabled - trueを指定すると無効化されます
 * @param {Boolean} multiple - trueを指定すると複数選択を可にします
 * @param {Boolean} blue - 青文字にします
 * @returns {JSX.Element} - InputSelect
 */
const InputSelect = (props) => {
  const classes = useStyles()
  // eslint-disable-next-line no-unused-vars
  const {
    className,
    inputClass,
    blue,
    error,
    helperText,
    inputProps,
    ...rest
  } = props
  return (
    <Autocomplete
      className={classes.root + ' ' + props.className}
      id={props.id}
      value={props.value}
      inputValue={props.inputValue}
      options={props.options}
      getOptionLabel={props.getOptionLabel}
      onClick={props.onClick}
      onFocus={props.onFocus}
      onChange={props.onChange}
      onOpen={props.onOpen}
      onInputChange={props.onInputChange}
      disabled={props.disabled === true ? true : false}
      multiple={props.multiple === true ? true : false}
      disableClearable={props.disableClearable === true ? true : false}
      freeSolo={props.freeSolo}
      defaultValue={props.defaultValue}
      renderInput={(params) => (
        <TextField
          className={props.inputClass}
          label={props.label}
          onClick={props.onClick}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          placeholder={props.placeholder}
          error={props.error}
          helperText={props.helperText}
          blue={props.blue}
          {...params}
          inputProps={{ ...props.inputProps, ...params.inputProps }}
        />
      )}
      {...rest}
    />
  )
}

export default InputSelect
