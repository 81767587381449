import { LIGHT_REGISTER_NEW_ACCOUNT } from '../../../actions'

const initBranch = {
  value: '',
  require: true,
}

/**
 * 内容を保持
 * @param {Object} action - dispatchで受け取ったaction
 * @param {Object} state - state
 * @return {Object} - state
 */
const updateText = (action, state) => {
  const { value } = action
  return {
    ...state,
    value,
  }
}

/**
 * Branch情報を保持
 * @param {Object} [state=initBranch] - state
 * @param {Object} action - action
 * @return {Object} - state
 */
const Branch = (state = initBranch, action) => {
  switch (action.type) {
    case LIGHT_REGISTER_NEW_ACCOUNT.BRANCH.UPDATE_TEXT:
      return updateText(action, state)
    default:
      return state
  }
}

export default Branch

export { initBranch }
