import React, { useContext } from 'react'

import ShippingDetailsLightBookingDetails from '../../../../commonUseContext/LightBookingDetails/ShippingDetails/ShippingDetailsLightBookingDetails'
import AppContext from '../../../../../contexts/AppContext'

/**
 * @return {JSX} ShippingDetailsアコーディオン
 */
const ShippingDetailsAirlineLightBookingDetails = () => {
  const { state } = useContext(AppContext)
  const disabled = state.Status.isCancel || state.Status.isClose
  return <ShippingDetailsLightBookingDetails disabled={disabled} isShowRate />
}

export default ShippingDetailsAirlineLightBookingDetails
