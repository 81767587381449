import React from 'react'
import Grid from '@material-ui/core/Grid'

import LayoutBox from '../../../common/layout/LayoutBox'
import AppContext from '../../../../contexts/AppContext'
import TitleLightDeleteAccountDone from './00TitleLightDeleteAccountDone/TitleLightDeleteAccountDone'
import TableLightDeleteAccountDone from './01MessageLightDeleteAccountDone/TableLightDeleteAccountDone'
import InfomationLightDeleteAccountDone from './02InfomationLightDeleteAccountDone/InfomationLightDeleteAccountDone'
import ButtonLightDeleteAccountDone from './03ButtonLightDeleteAccountDone/ButtonLightDeleteAccountDone'

const LightDeleteAccountDone = () => {
  return (
    <AppContext.Provider>
      <LayoutBox>
        <TitleLightDeleteAccountDone />
        <Grid container justify="center">
          <Grid container item direction="column" md={10} xs={10} spacing={8}>
            <Grid item>
              <TableLightDeleteAccountDone />
            </Grid>
            <Grid item>
              <InfomationLightDeleteAccountDone />
            </Grid>
            <Grid item>
              <ButtonLightDeleteAccountDone />
            </Grid>
          </Grid>
        </Grid>
      </LayoutBox>
    </AppContext.Provider>
  )
}

export default LightDeleteAccountDone
