import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core'

import Checkbox from '../../common/checkBox/CheckBox'
import AppContext from '../../../contexts/AppContext'
import { CHECKBOX } from '../../../actions'

const useStyles = makeStyles((theme) => ({
  checkbox: {
    width: 120,
    margin: 0,
    ...theme.textNormalBLK,
  },
}))

/**
 * 入札画面のcheckboxを表示
 * @param {*} props 下記内容
 * @param {Array} checkboxArr 作成するチェックボックス情報の配列
 * @return {JSX} チェックボックスコンポーネント
 */
const BidCheckboxes = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { checkboxArr } = props
  const { state, dispatch } = useContext(AppContext)

  /**
   * 変更時の処理
   * @param {string} name checkboxArr.nameの値
   * @param {boolean} checked チェック状態
   * @returns {void} なし
   */
  const onChange = (name, checked) => {
    dispatch({
      type: CHECKBOX.SET,
      name,
      checked,
    })
  }

  /**
   * checkboxArrからチェックボックスをまとめて作成する
   * @returns {JSX} チェックボックス
   */
  const createCheckboxes = () => {
    return (
      <Grid container justify="center">
        <Grid container item alignContent="center" alignItems="center" md={11}>
          {checkboxArr.map(({ name, label }, index) => (
            <Grid item key={index}>
              <Checkbox
                className={classes.checkbox}
                id={index.toString()}
                checked={state.Checkbox[name]}
                label={t(label)}
                onChange={(event) => onChange(name, event.target.checked)}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container justify="center">
      <Grid container item md={10}>
        {createCheckboxes()}
      </Grid>
    </Grid>
  )
}

BidCheckboxes.propTypes = {
  checkboxArr: PropTypes.array.isRequired,
}

export default BidCheckboxes
