// Display + 画面名
const DisplayNotifyList = {
  D601V0001: {
    en: 'Notify List',
    ja: '通知リスト',
  },
  D601V0002: {
    en: 'All',
    ja: '全て',
  },
  D601V0003: {
    en: 'Unread',
    ja: '未読',
  },
  D601V0004: {
    en: 'TC1',
    ja: 'TC1',
  },
  D601V0005: {
    en: 'TC2',
    ja: 'TC2',
  },
  D601V0006: {
    en: 'TC3',
    ja: 'TC3',
  },
  D601V0007: {
    en: 'keyword etc…',
    ja: 'キーワードなど',
  },
  D601V0008: {
    en: 'Filter by',
    ja: '絞り込み検索',
  },
  D601V0009: {
    en: 'Date',
    ja: '日付',
  },
  D601V0010: {
    en: 'Name',
    ja: '名前',
  },
  D601V0011: {
    en: 'Company',
    ja: '会社',
  },
  D601V0012: {
    en: 'Contents',
    ja: '内容',
  },
  D601V0013: {
    en: 'Check',
    ja: 'チェック',
  },
  D601V0014: {
    en: 'Back',
    ja: '戻る',
  },
  D601V0015: {
    en: 'Sender',
    ja: '送信者',
  },
  D601V0016: {
    en: 'Coworker',
    ja: '同一会社',
  },
  D601V0017: {
    en: 'Air Cargo Sharing Digital Platform',
    ja: 'Air Cargo Sharing Digital Platform',
  },
  D601V0018: {
    en: 'Forwarder',
    ja: '混載業者',
  },
  D601V0019: {
    en: 'Carrier',
    ja: '航空会社',
  },
  D601V0020: {
    en: 'Shipper',
    ja: '荷主',
  },
  D601V0021: {
    en: 'Clear',
    ja: 'クリア',
  },
  D601V0022: {
    en: 'Cancel',
    ja: 'キャンセル',
  },
  D601V0023: {
    en: 'Search',
    ja: '検索',
  },
}
// Display + 画面名
export default DisplayNotifyList
