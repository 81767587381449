/** @enum {Number} タブの値 */
const TabName = {
  flightInfo: 0,
  icon: 1,
}

const ConstantsCarFlightMgmt = {
  /** @type {Number} 1ページに表示する件数 */
  PAGE_AMOUNT: 15,

  /** @type {Number} Effective Dateの最小日数 */
  EFFECTIVE_DATE_PERIOD: 7,

  /** @type {Number} 追加できるRoutingの最大数 */
  MAX_ROUTING_COUNT: 3,

  ROUTING: {
    CURRENT: 1,
    NEXT: 2,
  },
}

export default ConstantsCarFlightMgmt
export { TabName }
