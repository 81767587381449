import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((Theme) => ({
  text: Theme.textNormalBLK,
}))

/**
 * @return {JSX} Cancel欄に表示する文言
 */
const LabelBookingLightBookingDetails = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Grid item md={12} className={classes.text}>
      <div>{t('D111V0106')}</div>
      <div>{t('D111V0107')}</div>
    </Grid>
  )
}

export default LabelBookingLightBookingDetails
