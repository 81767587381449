import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

import BackButtonBidInfo from './BackButtonBidInfo'
import NextButtonBidInfo from './NextButtonBidInfo'

const ButtonsBidInfo = ({ pageDataObj }) => {
  return (
    <Grid container justify="center">
      <Grid container item xs={10} justify="center" spacing={4}>
        <BackButtonBidInfo />
        <NextButtonBidInfo pageDataObj={pageDataObj} />
      </Grid>
    </Grid>
  )
}

ButtonsBidInfo.propTypes = {
  pageDataObj: PropTypes.object.isRequired,
}

export default ButtonsBidInfo
