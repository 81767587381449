import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'

import Common from '../../../../../constants/Common'
import TableCellBidDetails from '../../../../commonUseContext/Bid/Table/TableCellBidDetails'
import AppContext from '../../../../../contexts/AppContext'

/**
 * コンポーネントを更新するか判定する
 * @param {Object} prev 前回の値
 * @param {Object} next 最新の値
 * @returns {Boolean} falseの場合更新する
 */
const areEqual = (prev, next) => {
  /** ページを比較 */
  return prev.page === next.page
}

/**
 * 定数で定義した表データのコンポーネントを作成
 * @param {*} props 下記内容
 * @param {Object} tableConstantsObj 表示するテーブルの1行分の要素
 * @param {Object} cellRequiredObj 列を表示するか
 * @param {Boolean} isGrayOut グレーアウトするか
 * @return {JSX} TableCellコンポーネント(1個分)
 */
const CeateTableCell = React.memo((props) => {
  // 定数に紐づいている表示用のcell要素を取り出す
  const { tableConstantsObj, cellRequiredObj, isGrayOut } = props
  const { cellKey, cellCategory, rightBorder } = tableConstantsObj
  const { index, cellValue } = cellRequiredObj
  const key = `${index}${cellKey}`

  /** セルに表示する内容 */
  let displayContent = null

  // cellCategoryから各セルの内容を取得
  switch (cellCategory) {
    case Common.CELL_CATEGORY.NO:
      displayContent = `${index + 1}.`
      break
    case Common.CELL_CATEGORY.SELECT.AIRPORT:
      displayContent = (
        <Typography noWrap align="center">
          {cellValue === '' ? '-' : cellValue}
        </Typography>
      )
      break
    case Common.CELL_CATEGORY.TEXT:
      displayContent = (
        <Typography noWrap align="center">
          {cellValue}
        </Typography>
      )
      break
    default:
      break
  }

  return (
    <TableCellBidDetails
      key={key}
      displayContent={displayContent}
      isGrayOut={isGrayOut}
      rightBorder={rightBorder}
    />
  )
}, areEqual)

CeateTableCell.displayName = 'CeateTableCell'

/**
 * 1行分の表データを作成
 * @param {*} props 下記要素
 * @param {Object} tableContentObj 表示するテーブルの1行分の要素
 * @param {Number} index showDataの番号
 * @param {Boolean} isGrayOut グレーアウトするか
 * @param {Array} tableArr 表示するテーブル情報の配列
 * @return {JSX} TableCellコンポーネント(1行分)
 */
const TableCellsConfirmation = (props) => {
  const { tableContentObj, index, isGrayOut, tableArr } = props
  const { state } = useContext(AppContext)

  return tableArr.map((tableConstantsObj, tableIndex) => {
    const cellKey = tableConstantsObj.cellKey
    // cellKeyから値を取得して格納する
    const cellValue = tableContentObj[cellKey]
    // 主要な値をオブジェクトにまとめる
    const cellRequiredObj = { cellValue, index }

    return (
      <CeateTableCell
        key={`createTableCell${index}${tableIndex}`}
        tableConstantsObj={tableConstantsObj}
        cellRequiredObj={cellRequiredObj}
        isGrayOut={isGrayOut}
        page={state.Paging}
      />
    )
  })
}

TableCellsConfirmation.propTypes = {
  tableContentObj: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  isGrayOut: PropTypes.bool.isRequired,
  tableArr: PropTypes.array.isRequired,
}

CeateTableCell.propTypes = {
  tableConstantsObj: PropTypes.object.isRequired,
  cellRequiredObj: PropTypes.object.isRequired,
  isGrayOut: PropTypes.bool.isRequired,
}

export default TableCellsConfirmation
