import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 'auto',
    fontFamily: theme.typography.fontFamily,
    backgroundColor: 'transparent',
    color: theme.fontColor.main,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  title: {
    color: theme.fontColor.main,
    width: 'fit-content',
    textAlign: 'end',
    marginBottom: 3,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      textAlign: 'end',
    },
  },
  sort: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: 10,
    },
  },
}))

/**
 * ソート
 * @param {Object} props - 下記を要素とするオブジェクトsortfactors
 * @param {String} value - 選択しているsort
 * @param {Event} changeEvent - sort変更時のevent
 * @param {Array} sortItems - sortの選択肢オブジェクトを要素とする配列
 * @returns {JSX.Element} - ソートコンポーネント
 */
const Sort = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  let value = ''
  let changeEvent = () => {}
  let sortItems = []

  if (props.sortfactors) {
    // 選択しているsort
    value = props.sortfactors.value ? props.sortfactors.value : ''
    // チェンジイベント
    changeEvent = props.sortfactors.changeEvent
      ? (e) => props.sortfactors.changeEvent(e)
      : () => {}
    // sortの選択肢
    sortItems = props.sortfactors.sortItems ? props.sortfactors.sortItems : []
  }

  return (
    <Grid
      className={classes.sort}
      container
      direction="row"
      alignItems="center"
      justify="space-between"
    >
      <Grid item>
        <Typography className={classes.title}>{t('D005V0064')}：</Typography>
      </Grid>
      <Grid item md xs>
        <Select
          disableUnderline
          className={classes.root}
          variant="standard"
          color="primary"
          value={value}
          onChange={(e) => changeEvent(e)}
        >
          {sortItems.map((sortItem, i) => (
            <MenuItem key={i} value={sortItem.value}>
              {sortItem.word}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  )
}

export default Sort
