import { makeStyles } from '@material-ui/core'
import React, { useContext } from 'react'

import Paths from '../../../../../constants/Paths'
import BackButton from '../../../../common/buttons/BackButton'
import AppContext from '../../../../../contexts/AppContext'

const useStyles = makeStyles((theme) => ({
  back: theme.backButtonCenter,
}))

/**
 * @return {JSX} バックボタンコンポーネント
 */
const BackButtonConfirmation = () => {
  const classes = useStyles()
  const { state } = useContext(AppContext)

  return (
    <div className={classes.back}>
      <BackButton path={Paths.SHIPPER.BID_DETAILS} state={state.Data} />
    </div>
  )
}

export default BackButtonConfirmation
