import { combineReducers } from 'redux'

import Checkbox, { initCheckbox } from './Checkbox'
import SearchSort, { initSearchSort } from '../../common/SearchSort'
import TablesBidDetails, { initTablesBidDetails } from './TablesBidDetails'
import IsNew, { initIsNew } from './IsNew'
import ApiData, { initApiData } from './ApiData'
import Paging, { initPaging } from '../../common/Paging'
import Error, { initErrorData } from './Error'
import Data, { initData } from './Data'

const initialState = {
  Checkbox: initCheckbox,
  SearchSort: initSearchSort,
  Tables: initTablesBidDetails,
  IsNew: initIsNew,
  ApiData: initApiData,
  Paging: initPaging,
  Error: initErrorData,
  Data: initData,
}

export default combineReducers({
  Checkbox,
  SearchSort,
  Tables: TablesBidDetails,
  IsNew,
  ApiData,
  Paging,
  Error,
  Data,
})

export { initialState }
