import { UPDATE_JH_BACKUP } from '../../../actions'

const Events = (state = {}, action) => {
  switch (action.type) {
    //------------------
    // backupを更新
    //------------------
    case UPDATE_JH_BACKUP:
      state.companyName = action.info.companyName
      state.postalCode = action.info.postalCode
      state.address = action.info.address
      state.firstName = action.info.firstName
      state.lastName = action.info.lastName
      state.mail = action.info.mail
      state.tel = action.info.tel
      state.icon = action.info.icon
      return { ...state }
    default:
      return state
  }
}

export default Events
