import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

import TableCell from '../../../../common/table/TableCell'
import IconButtonLightFlightMgmt from './IconButtonLightFlightMgmt'
import AppContext from '../../../../../contexts/AppContext'
import CommonCalc from '../../../../common/function/CommonCalc'

const useStyles = makeStyles((Theme) => ({
  companyName: Theme.textBoldBLK,
  airlineCell: {
    textAlign: 'left',
    borderRight: '0px solid !important',
  },
  buttonCell: {
    textAlign: 'right',
  },
}))

/**
 * 1行分の表データを作成
 * @param {*} props 下記要素
 * @param {Number} props.index ページ上のインデックス
 * @return {JSX} TableCellコンポーネント(1行分)
 */
const IconTableCellsLightCarFlightMgmt = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { index } = props
  const { state } = useContext(AppContext)

  /** @type {Number} state.showDataのインデックス */
  const page = CommonCalc.calcShowDataIndex(
    state.Paging,
    state.Tables.showData.length
  )
  const { airline } = state.Tables.showData[page][index]

  return (
    <>
      <TableCell padding="default" className={classes.airlineCell}>
        <span>{`${t('D501V0005')}: `}</span>
        <span className={classes.companyName}>{airline}</span>
      </TableCell>
      <TableCell className={classes.buttonCell}>
        <IconButtonLightFlightMgmt index={index} />
      </TableCell>
    </>
  )
}

IconTableCellsLightCarFlightMgmt.propTypes = {
  index: PropTypes.number.isRequired,
}

export default IconTableCellsLightCarFlightMgmt
