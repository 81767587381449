import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  img: {
    color: theme.palette.info.main,
    [theme.breakpoints.down('sm')]: {
      width: 50,
      height: 50,
    },
  },
}))

const StarIcon = () => {
  const classes = useStyles()

  return (
    <img
      className={classes.img}
      src="./../images/passwordIcon/KiraKira.svg"
      alt="KiraKira"
    />
  )
}

export default StarIcon
