import React, { useContext } from 'react'

import FlightLightBookingDetails from '../../../../commonUseContext/LightBookingDetails/Flight/FlightLightBookingDetails'
import { BeforePath } from '../ConstantsLightBookingDetails'
import AppContext from '../../../../../contexts/AppContext'

/**
 * @param {Object} props 下記内容
 * @param {BeforePath} props.beforePath 遷移元画面
 * @return {JSX} Flightアコーディオン
 */
const FlightFwdrLightBookingDetails = () => {
  const { state } = useContext(AppContext)
  const beforePath = state.ShippingDetails.beforePath
  const isShow = beforePath === BeforePath.BookingList
  return <FlightLightBookingDetails editable={false} isShow={isShow} />
}

export default FlightFwdrLightBookingDetails
