import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import TextField from '../../../common/text/TextField'
import { LIGHT_BOOKING_DETAILS } from '../../../../actions'
import CommonValidation from '../../../common/function/CommonValidation'

/**
 * @param {Object} props 下記内容
 * @param {Function} props.dispatch dispatch
 * @param {String} props.defaultValue 初期値
 * @returns {JSX} インターライン編集ポップアップのテキストボックス
 */
const FlightPopupTextField = ({ dispatch, defaultValue }) => {
  const { t } = useTranslation()
  const [error, setError] = useState(false)
  const [helperText, setHelperText] = useState(
    CommonValidation.interLine(false)
  )

  /**
   * 入力を更新
   * @param {String} value 入力値
   * @returns {void} なし
   */
  const onChange = (value) => {
    const { checkResult, errMsg } = CommonValidation.interLine(value)
    setError(checkResult)
    setHelperText(checkResult ? errMsg : CommonValidation.interLine(false))
    dispatch({
      type: LIGHT_BOOKING_DETAILS.FLIGHT_POPUP.EDIT,
      interLine: checkResult ? null : value,
    })
  }

  return (
    <TextField
      defaultValue={defaultValue}
      error={error}
      helperText={t(helperText)}
      onChange={(event) => onChange(event.target.value)}
    />
  )
}
FlightPopupTextField.propTypes = {
  dispatch: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
}
export default FlightPopupTextField
