import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'

import TitleShippingDetailsLightBookingDetails from './TitleShippingDetailsLightBookingDetails'
import ContentShippingDetailsLightBookingDetails from './ContentShippingDetailsLightBookingDetails'

/**
 * @param {Object} props 下記内容
 * @param {string} props.title タイトル文言
 * @param {string} props.content 表示内容
 * @returns {JSX} タイトルと表示内容のセット
 */
const TitleAndContentShippingDetailsLightBookingDetails = ({
  title,
  content,
}) => {
  return (
    <Grid item>
      <TitleShippingDetailsLightBookingDetails label={title} />
      <ContentShippingDetailsLightBookingDetails label={content} />
    </Grid>
  )
}
TitleAndContentShippingDetailsLightBookingDetails.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
}
export default TitleAndContentShippingDetailsLightBookingDetails
