import { PAGING } from '../../actions'

/** 初期表示ページ */
const initPaging = 1

/**
 * ページを保持
 * @param {Number} [state=initPaging] 初期表示ページ
 * @param {Object} action page: 現在ページ
 * @return {Number} 現在ページ
 */
const Paging = (state = initPaging, action) => {
  // ページの指定が無い場合は初期ページ
  const { type, page = initPaging } = action
  switch (type) {
    case PAGING.SET:
      return page
    default:
      return state
  }
}

export default Paging

export { initPaging }
