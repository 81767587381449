// Display + 画面名
const DisplayFwdrQuote = {
  // 画面ID + 文言ID
  D112V0001: {
    en: 'Air Freight Quote',
    ja: '航空貨物見積書',
  },
  D112V0002: {
    en: 'ADDRESS',
    ja: '住所',
  },
  D112V0003: {
    en: 'XXXX Corporation',
    ja: 'XXXX株式会社',
  },
  D112V0004: {
    en: '1-8-3 Takakuradai, Minami-ku, Sakai-shi, Osaka, 590-0117 Japan',
    ja: '日本大阪府大阪市堺市南区高倉台1-8-3、590-0117',
  },
  D112V0005: {
    en: 'To',
    ja: '送付先',
  },
  D112V0006: {
    en: '327 St. Margarets Street, Charlotte, NC 28205 United States of America',
    ja: '327 St. Margarets Street, Charlotte, NC 28205 United States of America',
  },
  D112V0007: {
    en: 'Tel: +1-1-43-58-55-34 Attn: James Colins',
    ja: '電話番号: +1-1-43-58-55-34 連絡先: James Colins',
  },
  D112V0008: {
    en: 'Flight',
    ja: 'フライト',
  },
  D112V0009: {
    en: "UAXXX/XXSEP *Narita Int'l Airport to Charlotte Douglas Int'l Airport",
    ja: '成田国際空港からシャーロットダグラス国際空港まで',
  },
  D112V0010: {
    en: 'Quote prepared by',
    ja: '見積書の作成者',
  },
  D112V0011: {
    en: 'Norikuni Inaba, Air Export Manager, XXXXXX Logistics',
    ja: '稲葉憲邦、航空輸出マネージャー、XXXXXXロジスティクス',
  },
  D112V0012: {
    en: 'DATE',
    ja: '日付',
  },
  D112V0013: {
    en: 'Quote',
    ja: '見積もり',
  },
  D112V0014: {
    en: '7/31/2020',
    ja: '2020年7月31日',
  },
  D112V0015: {
    en: '2020090101',
    ja: '2020090101',
  },
  D112V0016: {
    en: 'Origin Charges',
    ja: '原産地料金',
  },
  D112V0017: {
    en: 'Rate',
    ja: '割合',
  },
  D112V0018: {
    en: 'Per',
    ja: 'PER',
  },
  D112V0019: {
    en: 'Amount',
    ja: '料金',
  },
  D112V0020: {
    en: 'Pickup charge *From: 1-8-3 Takakuradai, Minami-kuSakai-shi, Osaka 590-0117 Japan',
    ja: '送迎料金 *日本大阪府大阪市堺市南区高倉台1-8-3、590-0117から',
  },
  D112V0021: {
    en: 'Packing Fee *Dim (LxWxH): XXXcm x XXXcm x XXXcm',
    ja: '梱包料金 *寸法(LxWxH): XXXcm x XXXcm x XXXcm',
  },
  D112V0022: {
    en: 'Airline Registration Fee',
    ja: '航空会社登録料',
  },
  D112V0023: {
    en: 'Airline Handling Fee',
    ja: '航空会社手数料',
  },
  D112V0024: {
    en: 'House Air Waybill Data Transmission Fee',
    ja: 'HAWBデータ送信料',
  },
  D112V0025: {
    en: 'Labeling Fee',
    ja: 'ラベル貼付費',
  },
  D112V0026: {
    en: 'Inspection Fee',
    ja: '検査料',
  },
  D112V0027: {
    en: '$XX, XXX',
    ja: '$XX, XXX',
  },
  D112V0028: {
    en: 'kg',
    ja: 'kg',
  },
  D112V0029: {
    en: 'Contact later',
    ja: '後で連絡する',
  },
  D112V0030: {
    en: 'Shipment',
    ja: '発送',
  },
  D112V0031: {
    en: 'HAWB',
    ja: 'HAWB',
  },
  D112V0032: {
    en: 'Subtotal',
    ja: '小計',
  },
  D112V0033: {
    en: 'Freight Charges',
    ja: '運送費用',
  },
  D112V0034: {
    en: 'Air Freight *Chargeable Weight XXX.XXkg',
    ja: '航空貨物*有料重量XXX.XXkg',
  },
  D112V0035: {
    en: 'Fuel Surcharge *Chargeable Weight XXX.XXkg',
    ja: '燃油サーチャージ*有料重量XXX.XXkg',
  },
  D112V0036: {
    en: 'Screening Fee',
    ja: '選考料',
  },
  D112V0037: {
    en: 'Destination Charges',
    ja: '到着地料金',
  },
  D112V0038: {
    en: 'Import Customs Clearance Charges',
    ja: '輸入通関料金',
  },
  D112V0039: {
    en: 'Transfer Fee',
    ja: '転送料',
  },
  D112V0040: {
    en: 'Handover Fee *Over X,XXXkg',
    ja: '引渡手数料*XXXkg 以上',
  },
  D112V0041: {
    en: 'Collect Charge Fee',
    ja: '集荷料金',
  },
  D112V0042: {
    en: 'Warehouse Storage Fee',
    ja: '倉庫保管料',
  },
  D112V0043: {
    en: 'Delivery Charge *To: 327 St. Margarets Street, Charlotte, NC 28205 United States of America',
    ja: '配送料 *To: 327 St. Margarets Street, Charlotte, NC 28205 United States of Americaまで',
  },
  D112V0044: {
    en: 'TERMS AND CONDITIONS',
    ja: '取引条件',
  },
  D112V0045: {
    en: '1. XXXXXXXXXXXXXXXXXXX',
    ja: '1. XXXXXXXXXXXXXXXXXXX',
  },
  D112V0046: {
    en: 'To accept this quotation, please sign here and return:',
    ja: 'この見積もりを受け入れるには、ここに署名して返送してください：',
  },
  D112V0047: {
    en: 'If you have any questions about this quotation, please contact: Norikuni Inaba',
    ja: 'この見積もりについてご不明な点がございましたら、稲葉紀邦にご連絡ください',
  },
  D112V0048: {
    en: 'Air Export Manager - XXXXXX Logistics, +81-0-0000-0000.',
    ja: '航空輸出マネージャー、XXXXXXロジスティクス',
  },
  D112V0049: {
    en: 'Back',
    ja: '戻る',
  },
  D112V0055: {
    en: 'Tel: +81-72-220-5143 Attn: Norikuni Inaba',
    ja: '電話番号: +81-72-220-5143 連絡先: 稲葉憲邦',
  },
  D112V0056: {
    en: 'Valid until.',
    ja: '有効期限',
  },
  D112V0057: {
    en: '2. XXXXXXXXXXXXXXXXXXX',
    ja: '2. XXXXXXXXXXXXXXXXXXX',
  },
  D112V0058: {
    en: '3. XXXXXXXXXXXXXXXXXXX',
    ja: '3. XXXXXXXXXXXXXXXXXXX',
  },
  D112V0059: {
    en: 'Total',
    ja: '会計',
  },
}
// Display + 画面名
export default DisplayFwdrQuote
