import { useReducer, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { TABLES } from '../../../../../actions'

import ApiPaths from '../../../../../constants/ApiPaths'
import reducer, {
  initialState,
} from '../../../../../reducers/master/lightMasterJhClientReg.DeleteAccount'
import CommonFunc from '../../../../common/function/CommonFunc'

/**
 * 初期データ取得用カスタムフック
 * @return {Array} LightJhClientRegDeleteAccount用のstateとdispatch
 */
const useFetchData = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const history = useHistory()

  /** 初期処理 */
  useEffect(() => {
    ;(async () => {
      const apiInfoArr = [
        {
          key: 'clientDeleteAccountInfoObj',
          method: 'get',
          path: ApiPaths.JH.LIGHT_WITHDRAWAL_INFO,
        },
      ]
      const resObj = await CommonFunc.execApiAsync(apiInfoArr)
      const isApiSuccessful = CommonFunc.checkApiResponseObj(resObj, history)

      if (isApiSuccessful) {
        const { clientDeleteAccountInfoObj } = resObj

        // リクエスト申請情報をセット
        dispatch({
          type: TABLES.INIT,
          data: clientDeleteAccountInfoObj.data.results,
          tabs: state.Tabs,
        })
      }
    })()
  }, []) //eslint-disable-line

  return [state, dispatch]
}

export default useFetchData
