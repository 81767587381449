/* eslint-disable prefer-template */
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import MDialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'

import Paths from '../../../../constants/Paths'
import CommonFunc from '../../../common/function/CommonFunc'
import PrimaryButton from '../../../common/buttons/PrimaryButton'

const useStyles = makeStyles((theme) => ({
  root: theme.dialog,
  dialog: {
    border: '1px solid #375CA7',
    borderRadius: '5px',
    width: '500px',
    height: '50px',
    padding: '0px',
    margin: '1rem',
    paddingBottom: '1rem',
    background: '#FAFAFA',
  },
  transparent: {
    background: 'transparent',
    border: '0px solid',
  },
  icon: {
    fontSize: 24,
    color: theme.palette.required,
  },
  textFirst: {
    textAlign: 'center',
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.fontSize.paging,
    color: theme.palette.required,
    marginTop: '50px',
  },
  textSecond: {
    textAlign: 'center',
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.fontSize.paging,
    color: theme.palette.required,
  },
  mobile: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  desktop: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  margin: {
    margin: 10,
  },
  marginTop: {
    marginTop: '2rem',
  },
  button: {
    marginBottom: '30px',
  },
  primary: {
    width: theme.buttonSize.width.large,
    height: theme.buttonSize.height.large,
    border: 'none',
  },
}))

const Expired = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const [open, setOpen] = useState(false)
  const [scroll, setScroll] = useState('body')
  const [pageX, setPageX] = useState(0)

  useEffect(() => {
    // windowのスクロール設定を記録してから非表示
    CommonFunc.hideScroll()
    setOpen(true)
    setScroll('body')
  }, [])

  /**
   * ダイアログを閉じる
   * @param {Event} event - onCloseのイベント
   * @param {Boolean} isDialogBackGround - ダイアログ背景をクリックしているかの判定
   * @returns {void}
   */
  const handleClose = (event, isDialogBackGround) => {
    /** 現在クリック位置が要素外(スクロール部分)をクリックしているか判定 */
    const isScrollClick = isDialogBackGround
      ? event.target.clientWidth - pageX < 0
      : false
    if (isScrollClick) {
      // スクロール部分をクリックしている場合は何もしない
      return
    } else {
      // windowのスクロール設定を戻す
      CommonFunc.showScroll()
      // ReissuePassword画面へ遷移
      CommonFunc.clickEvent(
        { path: Paths.AUTHENTICATION.REISSUE_PASSWORD },
        history
      )
    }
  }

  /**
   * クリックした瞬間の位置を保存
   * @param {Event} event - event onMousedownのイベント
   * @returns {void}
   */
  const savePageXPosition = (event) => setPageX(event.pageX)

  return (
    <>
      <MDialog
        open={open}
        scroll={scroll}
        onClose={(e) => handleClose(e, true)}
        onMouseDown={savePageXPosition}
        className={classes.root + ' ' + props.className}
      >
        {/* ダイアログ本文 */}
        <DialogContent>
          <div className={classes.dialog + ' ' + classes.transparent}>
            {/* 本文 */}
            <Grid
              container
              justify="center"
              alignContent="center"
              className={classes.marginTop}
            >
              <Grid item xs={12}>
                <div className={classes.textFirst}>{t('D501V0073')}</div>
                <div className={classes.textSecond}>{t('D501V0074')}</div>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            justify="center"
            className={classes.desktop + ' ' + classes.margin}
          >
            <Grid item>
              <div className={classes.button}>
                <PrimaryButton addclass={classes.primary} onClick={handleClose}>
                  {t('D501V0070')}
                </PrimaryButton>
              </div>
            </Grid>
          </Grid>
        </DialogActions>
      </MDialog>
    </>
  )
}

export default Expired
