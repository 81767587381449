import React from 'react'

import LayoutLog from '../../../common/layout/LayoutLog'
import SignInTitle from '../../../common/title/SignInTitle'
import ResetMessage from './ResetMessage'
import Text from './Text'

const CommonSignIn = (props) => {
  // resetFlg受け取る
  let resetFlg = props.history.location.state
  if (resetFlg) {
    resetFlg = resetFlg.state === true
  }

  return (
    <>
      <LayoutLog>
        {resetFlg ? <ResetMessage /> : <></>}
        <SignInTitle />
        <Text />
      </LayoutLog>
    </>
  )
}

export default CommonSignIn
