import CommonFunc from '../components/common/function/CommonFunc'
import Common from '../constants/Common'
import Paths from '../constants/Paths'
import axiosBase from 'axios'

const RestFacade = () => {
  const CONSTANTS = {
    METHOD: {
      GET: 'get',
      POST: 'post',
      PUT: 'put',
      DELETE: 'delete',
    },
    REQ: 'req',
    /** タイムアウトが必要になった場合はここに時間を追加(単位はms) */
    TIMEOUT: 0,
    ERROR_MESSAGE: {
      SYSTEM_ERROR: 'System Error',
      NETWORK_ERROR: 'Network Error',
      TIMEOUT_ERROR: 'Timeout',
    },
    MAIL_SYSTEM_ERROR_ARR: [
      'ConfigurationSetDoesNotExist',
      'IncompleteSignature',
      'InternalFailure',
      'InvalidAction',
      'InvalidClientTokenId',
      'InvalidParameterCombination',
      'InvalidParameterValue',
      'InvalidQueryParameter',
      'MailFromDomainNotVerified',
      'MalformedQueryString',
      'MessageRejected',
      'MissingAction',
      'MissingAuthenticationToken',
      'MissingParameter',
      'OptInRequired',
      'RequestExpired',
      'ServiceUnavailable',
      'Throttling',
    ],
    FILE_KEY: {
      SINGLE_ARR: ['file', 'invoiceFile', 'packingListFile'],
    },
  }

  const testData = {}

  const transitionData = {}
  const dummyData = {
    ...testData,
    ...transitionData,
  }
  const env = import.meta.env.NODE_ENV

  if (env === 'development') {
    // 開発用に必要なためESLintのエラーを無視
    // eslint-disable-next-line no-console
    console.log(`仮データ${Object.keys(dummyData).length}個使用中`)
  }

  // APIを呼び出された回数をカウント
  let count = 0

  /**
   * API完了後にもう1回APIを投げられた時の対策
   * @return {void}
   */
  let loaderTimeout = () => {}

  console.log(import.meta.env.VITE_API_ENDPOINT)
  // axios を require してインスタンスを生成する
  const axios = axiosBase.create({
    baseURL: import.meta.env.VITE_API_ENDPOINT,
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    },
    responseType: 'json',
  })

  const axiosImg = axiosBase.create({
    baseURL: import.meta.env.VITE_API_ENDPOINT,
    headers: {
      'Content-Type': 'multipart/form-data',
      'X-Requested-With': 'XMLHttpRequest',
    },
    responseType: 'json',
  })

  // フロントでAPIを送る必要がない場合の成功レスポンス
  const SUCCESS_RESPONSE = {
    data: {
      status: Common.RESPONSE_STATUS_CODE.OK,
      info: {
        ErrorCode: Common.ERROR_CODE.NORMAL,
        ErrorMessage: '',
      },
    },
  }

  /**
   * 認証系の情報をリクエストに追加
   * @param {object} req フロントエンドから受け取ったリクエスト
   * @return {void}
   */
  const addAuth = (req) => {
    // tokenの追加
    const token = CommonFunc.getStorage(Common.KEY.TOKEN)
    if (token) {
      req.headers = { 'x-access-token': token }
    }
  }

  /**
   * loadingを表示
   * @return {void}
   */
  const showLoading = () => {
    // 閉じる処理の遅延をリセット
    clearTimeout(loaderTimeout)
    // APIを初めて呼び出した場合はLoading表示、ページの中身を非表示
    if (count >= 0) {
      if (document.getElementById('loading')) {
        document.getElementById('loading').hidden = false
        CommonFunc.changePageContentDisplay(true)
      }
    }
  }

  /**
   * loading削除
   * @return {void}
   */
  const hideLoading = () => {
    // APIの呼び出し回数をカウント
    count--
    // 呼び出したAPIの回数分処理が終了したらLoadingを非表示、ページの中身を表示
    if (count === 0) {
      // 後からもう1回APIを投げるときがあるので閉じるまで少し遅延させる
      loaderTimeout = setTimeout(() => {
        if (document.getElementById('loading')) {
          document.getElementById('loading').hidden = true
          CommonFunc.changePageContentDisplay(false)
        }
      }, 80)
    }
  }

  /**
   * API実行前の準備
   * @param {object} req フロントエンドから受け取ったリクエスト
   * @return {void}
   */
  const startProcess = (req) => {
    // Loadingを表示
    showLoading()

    // 認証系の情報追加
    addAuth(req)

    // APIの呼び出し回数をカウント
    count++
  }

  /**
   * ファイルをリクエストに追加
   * @param {File} file ファイル
   * @param {string} fileKey オブジェクトのプロパティ
   * @param {Object} params リクエスト
   * @return {void}
   */
  const appendFile = (file, fileKey, params) => {
    // ファイルが存在するときのみ追加
    if (file) {
      const appendKey = CONSTANTS.FILE_KEY.SINGLE_ARR.includes(fileKey)
        ? fileKey
        : 'files'
      params.append(
        appendKey,
        file,
        CommonFunc.convertFileName(file.fileName ?? file.name, fileKey)
      )
    }
  }

  /**
   * PUTとPOSTの画像がある場合の設定
   * @param {Object} req リクエスト
   * @return {Object} axiosとparamsのObject
   */
  const setAxios = (req) => {
    // 基本設定
    let axiosMode = axios
    let params = req

    // 画像がある場合
    if (req.file || req.files) {
      axiosMode = axiosImg
      // FormDataにリクエストをセット
      params = new FormData()
      for (const key in req) {
        // 複数ファイルを送る場合
        if (key === 'files') {
          const files = req[key]
          Object.keys(files).forEach((fileKey) => {
            appendFile(files[fileKey], fileKey, params)
          })
        } else {
          // 単体ファイルを送る場合
          // eslint-disable-next-line no-lonely-if
          if (CONSTANTS.FILE_KEY.SINGLE_ARR.includes(key)) {
            appendFile(req[key], key, params)
          } else {
            // null以外のオブジェクト、配列の場合はこちらから文字列化して送信
            const param =
              typeof req[key] === 'object' && req[key] !== null
                ? JSON.stringify(req[key])
                : req[key]
            // nullの場合は空白を渡す
            params.append(key, param ?? '')
          }
        }
      }
    }
    return { axios: axiosMode, params }
  }

  /**
   * 成功時の処理
   * @param {function} callback フロントエンドから受け取ったコールバック関数
   * @param {object} response API成功時に返却されたレスポンス
   * @return {Object} レスポンス
   */
  const successProcess = (callback, response) => {
    // 返ってきたレスポンスはそのまま加工せずに callback で呼び出し元へ渡す
    callback(response)
    return response
  }

  /**
   * エラーの状態を取得
   * @param {String} message エラーメッセージの内容
   * @param {String} errType CONSTANTSで振り分けられているエラータイプ
   * @return {Boolean} エラーの時はtrue
   */
  const getIsErr = (message, errType) =>
    message.indexOf(CONSTANTS.ERROR_MESSAGE[errType].toLowerCase()) !== -1

  /**
   * メールシステムエラーをチェック
   * @param {String} errorCode バックエンドから返ってくるエラーコード
   * @return {Boolean} メールシステムエラーの時はtrue
   */
  const getIsMailSystemErr = (errorCode) =>
    CONSTANTS.MAIL_SYSTEM_ERROR_ARR.includes(errorCode)

  /**
   * 失敗時の処理
   * @param {function} callback フロントエンドから受け取ったコールバック関数
   * @param {object} error API失敗時に返却されたエラーレスポンス
   * @return {Object} API失敗時に返却されたエラーレスポンス
   */
  const errorProcess = (callback, error) => {
    if (!error.response) {
      const resMessage = error.toString().toLowerCase()
      const isNetworkErr = getIsErr(resMessage, 'NETWORK_ERROR')
      const isTimeOutErr = getIsErr(resMessage, 'TIMEOUT_ERROR')
      // 下の条件に当てはまらない場合はシステムエラー扱い
      // フロントのコールバック関数のエラーの可能性もあり
      let ErrorCode = Common.ERROR_CODE.SYSTEM_ERROR
      let ErrorMessage = CONSTANTS.ERROR_MESSAGE.SYSTEM_ERROR

      if (isNetworkErr) {
        // バックエンドが起動しておらずレスポンスが帰ってこない場合
        ErrorCode = Common.ERROR_CODE.NETWORK_ERROR
        ErrorMessage = CONSTANTS.ERROR_MESSAGE.NETWORK_ERROR
      } else if (isTimeOutErr) {
        // 設定したタイムアウトエラーの時間が経過した場合
        ErrorCode = Common.ERROR_CODE.TIMEOUT_ERROR
        ErrorMessage = `${CONSTANTS.ERROR_MESSAGE.TIMEOUT_ERROR} Error`
      }

      error.response = {
        data: {
          status: Common.RESPONSE_STATUS_CODE.NG,
          info: {
            ErrorCode,
            ErrorMessage,
          },
        },
      }
    }

    const errorCode = error.response.data.info.ErrorCode

    // メールシステムエラーの場合エラー内容を上書き
    const isMailSystemErr = getIsMailSystemErr(errorCode)
    if (isMailSystemErr) {
      /** 元々のエラーコード、メッセージ両方とも確認できる状態にしたいので合成 */
      const newErrorMessage = `${errorCode}: ${error.response.data.info.ErrorMessage}`
      // 上書き
      error.response.data.info.ErrorCode = Common.ERROR_CODE.MAIL_SYSTEM_ERROR
      error.response.data.info.ErrorMessage = newErrorMessage
    }

    // 共通エラー処理
    CommonFunc.errorHandling(errorCode)
    callback(error.response)
    return error.response
  }

  /**
   * 成功、失敗関係なく実行する処理
   * @return {void}
   */
  const finallyProcess = () => {
    // Loading削除
    hideLoading()
  }

  /**
   * 共通処理 すぐに成功を返したい場合はresponseを返す
   * @param {*} method APIのメソッド CONSTANTS.METHOD
   * @param {*} url API のURL
   * @param {*} callback 呼び出し元のコールバック処理
   * @param {*} req リクエスト
   * @param {string} [editData=''] 編集データがある場合追加 空の配列、オブジェクトもしくはundefinedのとき必ず成功
   * @returns {*}  すぐに成功を返したい場合はresponse、それ以外はfalseを返す
   */
  const commonProcess = (method, url, callback, req, editData = '') => {
    // 更新データが空の場合APIを投げる必要が無いので成功を返す
    if (method === CONSTANTS.METHOD.POST || method === CONSTANTS.METHOD.PUT) {
      // eslint-disable-next-line no-extra-parens
      if (
        (editData.length === 0 && typeof editData === 'object') ||
        editData === undefined
      ) {
        return SUCCESS_RESPONSE
      }
    }

    // 仮データのリクエストチェック
    checkReq(method, req, url)
    // 仮データが設定されている場合は仮データで成功を返す
    const testDataObj = env === 'development' ? dummyData[url]?.[method] : false
    return testDataObj
      ? createTestDataResponse(testDataObj, url, method)
      : false
  }

  /**
   * 仮データの必須リクエストのチェック 必須項目が無かった場合はアラート
   * @param {String} method APIのメソッド CONSTANTS.METHOD
   * @param {Object} req リクエスト
   * @param {String} url API のURL
   * @return {Function} methodで指定した処理
   */
  const checkReq = (method, req, url) => {
    const checkReqObj = dummyData[url]?.[CONSTANTS.REQ]
    if (checkReqObj) {
      Object.keys(checkReqObj).forEach((key) => {
        if (checkReqObj[key] && !req[key]) {
          // 必須判定があり、値が入ってない場合こちらで通知する
          // 開発用に必要なためESLintのエラーを無視
          // eslint-disable-next-line no-alert
          alert(`[${method}]${url}の必須のリクエスト${key}が入っていません`)
        }
      })
    }
  }

  /**
   * 仮データのレスポンス作成 development環境でのみテストデータを返す
   * @param {Object} testDataObj APIで設定している仮データオブジェクト
   * @param {String} url API のURL
   * @param {String} method APIのメソッド CONSTANTS.METHOD
   * @return {*} 開発中で仮データがある場合はレスポンス、それ以外はfalse
   */
  const createTestDataResponse = (testDataObj, url, method) => {
    // 開発用に必要なためESLintのエラーを無視
    // eslint-disable-next-line no-console
    console.log(`[${method}]${url}で仮データ使用中`)
    const testDataRes = {
      data: {
        status: Common.RESPONSE_STATUS_CODE.OK,
        info: {
          ErrorCode: Common.ERROR_CODE.NORMAL,
          ErrorMessage: '',
        },
        results: testDataObj,
      },
    }
    return env === 'development' ? testDataRes : false
  }

  /**
   * axiosの実行方法を取得
   * @param {String} method APIのメソッド CONSTANTS.METHOD
   * @param {String} url API のURL
   * @param {Object} req リクエスト
   * @return {Function} methodで指定した処理
   */
  const getAxiosMethod = (method, url, req) => {
    if (method === CONSTANTS.METHOD.POST || method === CONSTANTS.METHOD.PUT) {
      // POST,PUTの場合はfileがあるかないかで使い分け
      const axiosSetting = setAxios(req)
      return axiosSetting.axios[method](url, axiosSetting.params, {
        headers: {
          'x-access-token': CommonFunc.getStorage(Common.KEY.TOKEN),
        },
        timeout: CONSTANTS.TIMEOUT,
      })
    } else {
      // GET,DELETEの場合
      req.timeout = CONSTANTS.TIMEOUT
      return axios[method](url, req)
    }
  }

  /**
   * axiosでAPIを実行
   * @param {String} method APIのメソッド CONSTANTS.METHOD
   * @param {String} url API のURL
   * @param {Function} [callback=() => { }] 呼び出し元のコールバック処理
   * @param {Object} [req={}] リクエスト
   * @param {string} [editData=''] 編集データがある場合追加
   * @return {Function} methodで指定した処理
   */
  const execAxios = (
    method,
    url,
    callback = () => {},
    req = {},
    editData = ''
  ) => {
    // エラーページ、メンテナンスページの場合APIを投げない
    const currentPath = window.location.pathname
    if (currentPath === Paths.OTHERS.ERROR) return
    if (currentPath === Paths.OTHERS.MAINTENANCE) return
    // コールバック関数が空の場合も対応
    callback = typeof callback === 'function' ? callback : () => {}
    // 共通処理
    const commonProcessResult = commonProcess(
      method,
      url,
      callback,
      req,
      editData
    )
    if (commonProcessResult) {
      return successProcess(callback, commonProcessResult)
    }

    // API呼び出し前の準備
    startProcess(req)

    // axios を使って引数で指定された url に対してリクエストを投げる
    return getAxiosMethod(method, url, req)
      .then((response) => {
        // 成功時の処理
        return successProcess(callback, response)
      })
      .catch((error) => {
        // 失敗時の処理
        return errorProcess(callback, error)
      })
      .finally(() => {
        // 必ず実行する処理
        return finallyProcess()
      })
  }

  return {
    /**
     * APIを実行
     * @param {String} url API のURL
     * @param {Function} [callback=() => { }] 呼び出し元のコールバック処理
     * @param {Object} [req={}] リクエスト
     * @return {Function} GET処理
     */
    get: (url, callback = () => {}, req = {}) =>
      execAxios(CONSTANTS.METHOD.GET, url, callback, req, ''),
    /**
     * APIを実行
     * @param {String} url API のURL
     * @param {Function} [callback=() => { }] 呼び出し元のコールバック処理
     * @param {Object} [req={}] リクエスト
     * @param {string} [editData=''] 編集データがある場合追加 空の配列、オブジェクトもしくはundefinedのとき必ず成功
     * @return {Function} POST処理
     */
    post: (url, callback = () => {}, req = {}, editData = '') =>
      execAxios(CONSTANTS.METHOD.POST, url, callback, req, editData),
    /**
     * APIを実行
     * @param {String} url API のURL
     * @param {Function} [callback=() => { }] 呼び出し元のコールバック処理
     * @param {Object} [req={}] リクエスト
     * @param {string} [editData=''] 編集データがある場合追加 空の配列、オブジェクトもしくはundefinedのとき必ず成功
     * @return {Function} PUT処理
     */
    put: (url, callback = () => {}, req = {}, editData = '') =>
      execAxios(CONSTANTS.METHOD.PUT, url, callback, req, editData),
    /**
     * APIを実行
     * @param {String} url API のURL
     * @param {Function} [callback=() => { }] 呼び出し元のコールバック処理
     * @param {Object} [req={}] リクエスト
     * @return {Function} DELETE処理
     */
    delete: (url, callback = () => {}, req = {}) =>
      execAxios(CONSTANTS.METHOD.DELETE, url, callback, req, ''),
  }
}
export default RestFacade()
