import { LIGHT_REGISTER_NEW_ACCOUNT } from '../../../actions'

const initPlan = {
  planArr: [],
}

/**
 * セレクトボックスの初期設定を作成
 * @param {Object} action dispatchで受け取ったaction
 * @param {Object} state state
 * @return {Object} セレクトボックスオブジェクト
 */
const setPlan = (action, state) => {
  return {
    ...state,
    planArr: action.planArr,
  }
}

/**
 * ページ状態を保持
 * @param {Object} [state=initPlan] -
 * @param {Object} action - dispatchで受け取ったaction
 * @return {Object} - セレクトボックスオブジェクト
 */
const Plan = (state = initPlan, action) => {
  const { type } = action
  switch (type) {
    case LIGHT_REGISTER_NEW_ACCOUNT.PLAN.INIT:
      return setPlan(action, state)
    default:
      return state
  }
}

export default Plan

export { initPlan }
