import React, { useContext } from 'react'

import SearchSort from '../../../../commonUseContext/SearchSort'
import ConstantsBidDetails from '../ConstantsBidDetails'
import AppContext from '../../../../../contexts/AppContext'

/**
 * @return {JSX} サーチソートコンポーネント
 */
const SearchSortBidDetails = () => {
  const { state } = useContext(AppContext)
  return state.IsNew ? null : (
    <SearchSort sortItemArr={ConstantsBidDetails.SORT_ITEM_ARR} />
  )
}

export default SearchSortBidDetails
