import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

import AppContext from '../../../../../contexts/AppContext'
import CommonFunc from '../../../../common/function/CommonFunc'
import ApiPaths from '../../../../../constants/ApiPaths'
import { API_DATA, FWDR_BID_DETAILS, TABLES } from '../../../../../actions'
import ConstantsBidDetails from '../ConstantsBidDetails'
import Common from '../../../../../constants/Common'
import SaveDialog from '../../../../common/dialog/SaveDialog'
import SaveCompleteDialog from '../../../../common/dialog/SaveCompleteDialog'
import Paths from '../../../../../constants/Paths'

/**
 * @param {*} props 下記内容
 * @param {Object} data BidInfo画面から受け取ったデータ
 * @param {Boolean} disabled 非活性判定
 * @return {JSX} SaveAsDraftボタンコンポーネント
 */
const SaveAsDraftButtonBidDetails = (props) => {
  const history = useHistory()
  const { t } = useTranslation()
  const { state, dispatch } = useContext(AppContext)
  const { disabled } = props
  const [errCode, setErrCode] = useState(0)
  const [isErr, setIsErr] = useState(false)
  const [comp, setComp] = useState(false)

  /**
   * bidDetailのリクエストデータを作成する
   * @returns {Array} リクエストデータ
   */
  const createBidDetailArr = () =>
    state.Tables.editData
      .filter((data) => {
        //ステータスがRequest/Update/NAのデータのみリクエストする
        const isRequest = data.statusId === Common.BID_STATUS.REQUEST.ID
        const isUpdate = data.statusId === Common.BID_STATUS.UPDATE.ID
        const isNa = data.statusId === Common.BID_STATUS.NA.ID
        return isRequest || isUpdate || isNa
      })
      .map((data) => {
        const {
          bidLaneId,
          bidLaneUpdatedAt,
          ttId,
          transitId,
          statusStr,
          disclosesId,
          disclosesUpdatedAt,
          orgId,
          airlineId,
          laneId,
          laneUpdatedAt,
          dstId,
          commId,
          fsc,
          minrate,
          normal,
          min,
          light,
          medium,
          large,
          max,
          currencyId,
          laneUpdateStatusId,
          laneUpdateStatusUpdatedAt,
        } = data
        /** 編集後のステータスID */
        let bidStatusId = Object.entries(Common.BID_STATUS).find(
          (status) => status[1].TEXT === statusStr
        )[1].ID
        if (bidStatusId === Common.BID_STATUS.REQUEST.ID) {
          bidStatusId = Common.BID_STATUS.UPDATE.ID
        }
        return {
          bidLaneId,
          bidLaneUpdatedAt,
          transitTimeId: ttId,
          transitId,
          bidStatusId,
          disclosesId,
          disclosesUpdatedAt,
          orgId,
          airlineId,
          laneId,
          laneUpdatedAt,
          dstId,
          commId,
          fsc,
          minrate,
          normal,
          min,
          light,
          medium,
          large,
          max,
          currencyId,
          laneUpdateStatusId,
          laneUpdateStatusUpdatedAt,
        }
      })

  /**
   * stateを登録後の情報に更新する
   * @param {Object} res レスポンス
   * @returns {void} なし
   */
  const setData = (res) => {
    //APIから取得したセレクトの選択肢を再取得するため全削除する
    dispatch({
      type: API_DATA.DELETE,
    })
    const resultArr = res.data.results
    /** 登録後の情報に更新 */
    const newArr = state.Tables.editData.map((data) => {
      //bidLaneIdからeditDataとresultArrを紐づける
      const resultData = resultArr.find(
        ({ bidLaneId }) => bidLaneId === data.bidLaneId
      )
      //resultArrにデータがある場合、editDataを更新、なければ更新無し
      return resultData ? { ...data, ...resultData } : data
    })

    /** laneBidInfoのupdatedAtObjの配列 */
    const laneBidInfoUpdatedAtArr = newArr
      .filter(({ bidLaneId }) => bidLaneId)
      .map(({ bidLaneId, bidLaneUpdatedAt }) =>
        CommonFunc.createUpdatedAtObj(
          't_lane_bid_info',
          'bidLaneId',
          bidLaneId,
          bidLaneUpdatedAt
        )
      )
    /** laneInfoのupdatedAtObjの配列 */
    const laneInfoUpdatedAtArr = newArr
      .filter(({ laneId }) => laneId)
      .map(({ laneId, laneUpdatedAt }) =>
        CommonFunc.createUpdatedAtObj(
          't_lane_info',
          'laneId',
          laneId,
          laneUpdatedAt
        )
      )
    /** releaseFwdrのupdatedAtObjの配列 */
    const disclosesUpdatedAtArr = newArr
      .filter(({ disclosesId }) => disclosesId)
      .map(({ disclosesId, disclosesUpdatedAt }) =>
        CommonFunc.createUpdatedAtObj(
          't_release_fwdr',
          'disclosesId',
          disclosesId,
          disclosesUpdatedAt
        )
      )
    /** laneUpdateStatusのupdatedAtObjの配列 */
    const laneUpdateStatusUpdatedAtArr = newArr
      .filter(({ laneUpdateStatusId }) => laneUpdateStatusId)
      .map(({ laneUpdateStatusId, laneUpdateStatusUpdatedAt }) =>
        CommonFunc.createUpdatedAtObj(
          't_lane_update_status',
          'laneUpdateStatusId',
          laneUpdateStatusId,
          laneUpdateStatusUpdatedAt
        )
      )

    const updatedAtArr = [
      laneBidInfoUpdatedAtArr,
      laneInfoUpdatedAtArr,
      disclosesUpdatedAtArr,
      laneUpdateStatusUpdatedAtArr,
    ].flat()

    // updatedAtArrを更新
    dispatch({
      type: FWDR_BID_DETAILS.DATA.SET,
      data: {
        ...state.Data,
        updatedAtArr,
      },
    })

    // テーブル情報を更新
    dispatch({
      type: TABLES.INIT,
      data: newArr,
      sort: ConstantsBidDetails.SORT_ITEM_ARR[2].sortTerm,
    })

    setComp(true)
  }

  /**
   * クリック時のイベント
   * @returns {void} なし
   */
  const onClick = async () => {
    const req = {
      bidDetailArr: createBidDetailArr(),
    }
    const apiInfoArr = [
      {
        key: 'resData',
        method: 'post',
        path: ApiPaths.FORWARDER.FWDR_BID_DETAILS,
        req,
      },
    ]
    const resObj = await CommonFunc.execApiAsync(apiInfoArr)
    const isApiSuccessful = CommonFunc.checkApiResponseObj(resObj, history)

    // 使用しない共通ダイアログが表示されるので非表示に変更
    document.getElementById('error-dialog').hidden = true

    setErrCode(resObj.resData.data.info.ErrorCode)
    setIsErr(!isApiSuccessful)
    if (isApiSuccessful) {
      const { resData } = resObj
      // 画面を再表示
      setData(resData)
    }
  }

  const saveFactors = {
    errcode: errCode,
    comp,
    compevent: () => setComp(false),
    passerr: null,
    path: null,
    saveevent: onClick,
    text: t('D103V0101'),
    detailsButton: true,
    disabled,
  }

  return (
    <div>
      <SaveDialog saveFactors={saveFactors} />
      <SaveCompleteDialog
        savecomp={isErr.toString()}
        path={Paths.FWDR.BID_MGMT}
        errcode={errCode}
      />
    </div>
  )
}

SaveAsDraftButtonBidDetails.propTypes = {
  disabled: PropTypes.bool.isRequired,
}

export default SaveAsDraftButtonBidDetails
