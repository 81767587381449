import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import MiniCardButton from '../../../common/buttons/MiniCardButton'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    [theme.breakpoints.up('md')]: {
      height: `calc(100vh - ${theme.header.height} * 2 - 1rem)`,
      minHeight: '310px',
    },
  },
  center: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '257px',
      margin: '0 auto',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
    },
  },
  desktop: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))

/**
 * @param {Object} props - 以下
 * @param {string} clientListPath clientList画面へのパス
 * @param {string} clientRegPath clientReg画面へのパス
 * @returns {*} - JHのTOP画面のボタン
 */
const JhTopButtons = (props) => {
  const classes = useStyles()

  return (
    <Grid container className={classes.root}>
      {/* 1行目 */}
      <Grid container justify="center" alignItems="center">
        <Grid item md={3} xs={12}>
          <div className={classes.center}>
            <MiniCardButton card="weekly" disabled={true} />
          </div>
        </Grid>
        <Grid item md={3} xs={12}>
          <div className={classes.center}>
            <MiniCardButton card="monthly" disabled={true} />
          </div>
        </Grid>
        <Grid item md={3} xs={12}>
          <div className={classes.center}>
            <MiniCardButton card="quaterly" disabled={true} />
          </div>
        </Grid>
        <Grid item md={3} xs={12}>
          <div className={classes.center}>
            <MiniCardButton card="sales" disabled={true} />
          </div>
        </Grid>
      </Grid>
      {/* PC版のみ行間調整 */}
      <Grid
        container
        style={{ marginBottom: '3rem' }}
        className={classes.desktop}
      />
      {/* 2行目 */}
      <Grid container justify="center" alignItems="center">
        <Grid item md={3} xs={12}>
          <div className={classes.center}>
            <MiniCardButton card="clientRevenue" disabled={true} />
          </div>
        </Grid>
        <Grid item md={3} xs={12}>
          <div className={classes.center}>
            <MiniCardButton card="clientRanking" disabled={true} />
          </div>
        </Grid>
        <Grid item md={3} xs={12}>
          <div className={classes.center}>
            <MiniCardButton card="totalRevenue" disabled={true} />
          </div>
        </Grid>
        <Grid item md={3} xs={12}>
          <div className={classes.center}>
            <MiniCardButton card="contract" disabled={true} />
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        style={{ marginBottom: '3rem' }}
        className={classes.desktop}
      />
      {/* 3行目 */}
      <Grid container justify="flex-start" alignItems="center">
        <Grid item md={3} xs={12}>
          <div className={classes.center}>
            <MiniCardButton card="clientList" path={props.clientlistpath} />
          </div>
        </Grid>
        <Grid item md={3} xs={12}>
          <div className={classes.center}>
            <MiniCardButton card="clientReg" path={props.clientregpath} />
          </div>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default JhTopButtons
