import React from 'react'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'

import TextField from '../../../common/text/TextField'

/**
 * @param {Object} props 下記内容
 * @param {string} props.value インターラインの値
 * @returns {JSX} インターライン欄
 */
const InterLineLightBookingDetails = ({ value }) => {
  return (
    <Grid item md={10}>
      <TextField value={value ?? ''} disabled />
    </Grid>
  )
}
InterLineLightBookingDetails.propTypes = {
  value: PropTypes.string,
}
export default InterLineLightBookingDetails
