import React from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import TitleLightBookingRequest from './TitleLightBookingRequest'
import CommonTableContainer from '../../../../common/table/CommonTableContainerNone'
import HeaderLightBookingRequest from './HeaderLightBookingRequest'
import TableContentsLightBookingRequest from './TableContentsLightBookingRequest'
import AddLightBookingRequest from './AddLightBookingRequest'

const useStyles = makeStyles((theme) => ({
  tableHead: theme.tableCargoDetails,
}))

/**
 * @return {JSX} 表コンポーネント(全体)
 */
const CargoDetailsLightBookingRequest = () => {
  const classes = useStyles()

  return (
    <Grid container>
      <TitleLightBookingRequest />
      <CommonTableContainer isNoResult={false} className={classes.tableHead}>
        <HeaderLightBookingRequest />
        <TableContentsLightBookingRequest />
      </CommonTableContainer>
      <AddLightBookingRequest />
    </Grid>
  )
}

export default CargoDetailsLightBookingRequest
