import React, { useContext, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { Grid } from '@material-ui/core'

import AreYouSureDialog from '../../../../common/dialog/commonDesignDialog/AreYouSureDialog'
import SavedChangesDialog from '../../../../common/dialog/commonDesignDialog/SavedChangesDialog'
import BackButton from '../../../../common/buttons/BackButton'
import Paths from '../../../../../constants/Paths'
import Common from '../../../../../constants/Common'
import SaveButton from '../../../../common/buttons/SaveButton'
import AppContext from '../../../../../contexts/AppContext'
import {
  LIGHT_AIR_FREIGHT,
  PAGING,
  SEARCH_SORT,
  TABLES,
} from '../../../../../actions'
import ApiPaths from '../../../../../constants/ApiPaths'
import CommonFunc from '../../../../common/function/CommonFunc'

/**
 * @param {Number} fwdrNameId 混載業者名ID
 * @return {JSX} ボタンコンポーネント
 */
const ButtonCarAirFreight = ({ fwdrNameId }) => {
  const history = useHistory()
  const [open, setOpen] = useState(false)
  const [savedChangesOpen, setSavedChangesOpen] = useState(false)
  const { state, dispatch } = useContext(AppContext)

  /** @type {Function} リクエストデータを取得する関数 */
  const getRequestData = state.Tables.getRequestData

  /** @type {Boolean} Currencyを選択しているか */
  const isSelectedCurrency = state.CurrencyValue.currencyId !== null
  /** @type {Boolean} エラーが発生しているか */
  const error = state.Tables.checkAnyError() || !isSelectedCurrency
  /** @type {Boolean} 画面上の何れかを編集しているか */
  const isAnyEdited = state.Tables.hasEditedData || state.CurrencyValue.edited
  const isAnyInput = state.Tables.checkAnyInput()
  /** @type {Boolean} 非活性状態 */
  const disabled = !isAnyEdited || error || !isAnyInput

  return useMemo(() => {
    /** @type {Boolean} Saveボタンの表示判定 */
    const isShowSaveButton = state.Auth

    /**
     * 編集内容を保存
     * @returns {void} なし
     */
    const save = async () => {
      setOpen(false)
      /** @type {Object} リクエストデータ */
      const req = {
        fwdrNameId,
        currencyId: state.CurrencyValue.currencyId,
        rateArr: getRequestData(state.CurrencyValue.edited),
      }

      // API呼び出し
      const apiInfoArr = [
        {
          key: 'airFreight',
          method: 'post',
          path: ApiPaths.MASTER_CAR.LIGHT_AIR_FREIGHT,
          req,
        },
      ]
      const resObj = await CommonFunc.execApiAsync(apiInfoArr)
      const isApiSuccessful = CommonFunc.checkApiResponseObj(resObj, history)
      if (isApiSuccessful) {
        const { airFreight } = resObj
        const { rateArr } = airFreight.data.results
        // テーブルを初期化
        dispatch({
          type: TABLES.SET,
          tableData: rateArr,
          search: '',
        })
        // 通貨を設定
        dispatch({
          type: LIGHT_AIR_FREIGHT.CURRENCY.SET,
          currencyId: state.CurrencyValue.currencyId,
        })
        // スクロールを一番上に戻す
        window.scrollTo(0, 0)
        // 検索ワードを空文字に設定
        dispatch({
          type: SEARCH_SORT.SET,
          search: '',
        })
        // ページを戻す
        dispatch({
          type: PAGING.SET,
          page: 0,
        })
        setSavedChangesOpen(true)
      }
    }
    /** @type {Object} ダイアログのボタン設定 */
    const dialogFuncObj = {
      [Common.DIALOG.BUTTON_CATEGORY.CANCEL]: { onClick: () => setOpen(false) },
      [Common.DIALOG.BUTTON_CATEGORY.OK]: { onClick: save },
    }

    /** @type {Object} SavedChangesダイアログのボタン設定 */
    const savedDialogFuncObj = {
      [Common.DIALOG.BUTTON_CATEGORY.OK]: {
        onClick: () => setSavedChangesOpen(false),
      },
    }

    // Saveボタン
    const saveButton = (
      <Grid item>
        <SaveButton onClick={() => setOpen(true)} disabled={disabled} />
        <AreYouSureDialog
          open={open}
          dialogFuncObj={dialogFuncObj}
          closeFunc={() => setOpen(false)}
        />
      </Grid>
    )

    return (
      <>
        <SavedChangesDialog
          open={savedChangesOpen}
          closeFunc={() => setSavedChangesOpen(false)}
          dialogFuncObj={savedDialogFuncObj}
        />
        <Grid container justify="center">
          <Grid container item justify="center" md={10} spacing={2}>
            <Grid item>
              <BackButton path={Paths.MASTER_AIRLINE.LIGHT_CAR_SELECT_FWDR} />
            </Grid>
            {isShowSaveButton ? saveButton : null}
          </Grid>
        </Grid>
      </>
    )
  }, [
    state.Auth,
    getRequestData,
    state.CurrencyValue,
    open,
    dispatch,
    fwdrNameId,
    history,
    savedChangesOpen,
    disabled,
  ])
}

ButtonCarAirFreight.propTypes = {
  fwdrNameId: PropTypes.number.isRequired,
}

export default ButtonCarAirFreight
