import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AirplanemodeActiveIcon from '@material-ui/icons/AirplanemodeActive'

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: '100px',
    color: theme.palette.info.main,
    transform: 'rotate(45deg)',
    [theme.breakpoints.down('sm')]: {
      fontSize: '50px',
      margin: '1rem 0.5rem 1rem 1rem',
    },
  },
}))

const Icon = () => {
  const classes = useStyles()

  return <AirplanemodeActiveIcon className={classes.icon} />
}

export default Icon
