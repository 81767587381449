import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  paper: {
    backgroundImage: `url(${'/images/background_airplane.svg'})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    minHeight: '100vh',
    minWidth: '100vw',
    height: 'fit-content',
  },
}))

const BackgroundAirplane = (props) => {
  const classes = useStyles()

  return (
    <>
      <div className={classes.paper}>{props.children}</div>
    </>
  )
}

export default BackgroundAirplane
