import { UPDATE_CAR_BACKUP } from '../../../actions'

const initBackup = {
  companyName: '',
  postalCode: '',
  address: '',
  airport: '',
  affilation: '',
  carriertwoCode: '',
  representativeFlg: '',
  gsaFlg: '',
  firstName: '',
  lastName: '',
  position: '',
  mail: '',
  tel: '',
  iataOrcassNumber: '',
  icon: '',
  carPlanId: '',
}
const Events = (state = {}, action) => {
  switch (action.type) {
    //------------------
    // backupを更新
    //------------------
    case UPDATE_CAR_BACKUP:
      return { ...state, ...action.info }
    default:
      return state
  }
}

export default Events

export { initBackup }
