import React from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'

import CommonFunc from '../function/CommonFunc'

const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.buttonSize.width.small,
    height: theme.buttonSize.height.small,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.fontSize.regular,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
    '&:active': {
      backgroundColor: theme.palette.secondary.light,
    },
    borderRadius: 25,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    border: '1px solid',
    borderColor: theme.palette.secondary,
    boxSizing: 'border-box',
    padding: 0,
    whiteSpace: 'nowrap',
    '& .MuiButton-label': {
      height: '25px',
    },
  },
}))

/**
 * @param {Object} props - 以下
 * @param {string} path - 押下後の遷移先
 * @param {Event} clickevent - 押下後のイベント
 * @returns {JSX.Element} - detail(黄色)ボタンコンポーネント
 */
const DetailButton = (props) => {
  /**
   * @param {*} className - DetailButton側で設定されたclassName
   * @param {*} addClassName - 呼び出し側で追加されたclassName
   * @returns {String} - classNameを統合した文字列
   */
  const getClass = (className, addClassName) => {
    let returnClassName = className
    // addClassNameがある場合は統合
    if (addClassName) {
      // eslint-disable-next-line prefer-template
      returnClassName += ' ' + addClassName
    }
    return returnClassName
  }
  const classes = useStyles()

  // clickイベントとページ遷移
  const history = useHistory()
  const clickEvent = (props) => {
    CommonFunc.clickEvent(props, history)
  }

  // Buttonタグに関数を渡せないのでスプレッド構文で分離
  // eslint-disable-next-line no-unused-vars
  const { clickevent, ...rest } = props

  return (
    <Button
      variant="contained"
      className={getClass(classes.root, props.addclass)}
      onClick={() => clickEvent(props)}
      {...rest}
    >
      {props.children}
    </Button>
  )
}

export default DetailButton
