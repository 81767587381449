import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MaterialCheckBox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: theme.typography.fontFamily,
    color: theme.fontColor.main,
    margin: 0,
  },
}))

const CheckBox = (props) => {
  const classes = useStyles()
  return (
    <FormControlLabel
      className={`${classes.root} ${props.className}`}
      control={
        <MaterialCheckBox
          id={props.id}
          color="primary"
          disabled={props.disabled}
          checked={props.checked}
        />
      }
      {...props}
    />
  )
}

export default CheckBox
