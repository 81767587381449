// Display + 画面名
const DisplayAirlineSpaceControl = {
  // 画面ID + 文言ID
  D206V0001: {
    en: 'Flight Prep.',
    ja: '飛行準備',
  },
  D206V0002: {
    en: 'CargoMFST',
    ja: 'CargoMFST',
  },
  D206V0003: {
    en: 'NOTOC',
    ja: 'NOTOC',
  },
  D206V0004: {
    en: 'ULD Assign',
    ja: 'ULD割り当て',
  },
  D206V0005: {
    en: 'Set Flight',
    ja: 'フライト設定',
  },
  D206V0006: {
    en: 'MAWB No., ORG/DST, etc.',
    ja: 'マスター番号, 出発/到着など',
  },
  D206V0007: {
    en: 'Default',
    ja: 'デフォルト',
  },
  D206V0008: {
    en: 'KIX',
    ja: 'KIX',
  },
  D206V0009: {
    en: 'Sort by',
    ja: '並び替え',
  },
  D206V0010: {
    en: 'Lately',
    ja: '最近',
  },
  D206V0011: {
    en: 'Space Control',
    ja: 'スペース管理',
  },
  D206V0012: {
    en: 'ORG/DST',
    ja: '出発/到着',
  },
  D206V0013: {
    en: 'Flight#',
    ja: 'フライト番号',
  },
  D206V0014: {
    en: 'ETD/ETA',
    ja: 'ETD/ETA',
  },
  D206V0015: {
    en: 'Aircraft',
    ja: '航空機',
  },
  D206V0016: {
    en: 'TTL Wgt./MAX Wgt.(kg)',
    ja: '合計総重量/最大搭載重量(kg)',
  },
  D206V0017: {
    en: 'TTL Vol./MAX Vol.(m3)',
    ja: '合計容積/最大搭載容積(m3)',
  },
  D206V0018: {
    en: 'Status',
    ja: 'ステータス',
  },
  D206V0019: {
    en: 'NRT/ORD/DFW',
    ja: 'NRT/ORD/DFW',
  },
  D206V0020: {
    en: 'KZXXX/04SEP',
    ja: 'KZXXX/04SEP',
  },
  D206V0021: {
    en: '11:25/16:30',
    ja: '11:25/16:30',
  },
  D206V0022: {
    en: 'Boeing747-8F',
    ja: 'Boeing747-8F',
  },
  D206V0023: {
    en: '18.75/134,000.00',
    ja: '18.75/134,000.00',
  },
  D206V0024: {
    en: '9.43/858.10',
    ja: '9.43/858.10',
  },
  D206V0025: {
    en: 'FBL',
    ja: 'FBL',
  },
  D206V0026: {
    en: 'MAWB No.',
    ja: 'マスター番号',
  },
  D206V0027: {
    en: 'Pcs./Wgt./Vol.',
    ja: '個数/重量/容積',
  },
  D206V0028: {
    en: 'Description',
    ja: '説明',
  },
  D206V0029: {
    en: 'SHC Code',
    ja: 'SHCコード',
  },
  D206V0030: {
    en: 'FWDR',
    ja: '混載業者',
  },
  D206V0031: {
    en: 'Allotment',
    ja: 'アロットメント',
  },
  D206V0032: {
    en: 'Stn.',
    ja: 'Stn.',
  },
  D206V0033: {
    en: '933-XXXX XXXX',
    ja: '933-XXXX XXXX',
  },
  D206V0034: {
    en: 'XXpcs./XXX.XXkg/XX.XXm3',
    ja: 'XXpcs./XXX.XXkg/XX.XXm3',
  },
  D206V0035: {
    en: 'BEEF',
    ja: 'BEEF',
  },
  D206V0036: {
    en: 'PER',
    ja: 'PER',
  },
  D206V0037: {
    en: '79.45/858.10',
    ja: '79.45/858.10',
  },
  D206V0038: {
    en: 'NRT/ORD',
    ja: 'NRT/ORD',
  },
  D206V0039: {
    en: 'ASAHAN',
    ja: 'ASAHAN',
  },
  D206V0040: {
    en: 'Space Confirmed',
    ja: 'スペース確保',
  },
  D206V0041: {
    en: 'Details',
    ja: '詳細',
  },
  D206V0042: {
    en: 'CONSOL',
    ja: 'CONSOL',
  },
  D206V0043: {
    en: 'Back',
    ja: '戻る',
  },
  D206V0044: {
    en: 'Booked',
    ja: '予約確定',
  },
  D206V0045: {
    en: 'NRT',
    ja: 'NRT',
  },
  D206V0046: {
    en: 'FRO/ICE',
    ja: 'FRO/ICE',
  },
  D206V0047: {
    en: 'RLM/CAO',
    ja: 'RLM/CAO',
  },
  D206V0048: {
    en: 'KWE',
    ja: 'KWE',
  },
  D206V0049: {
    en: 'NEC',
    ja: 'NEC',
  },
  D206V0050: {
    en: 'RESIN',
    ja: 'RESIN',
  },
  D206V0051: {
    en: 'RFL',
    ja: 'RFL',
  },
  D206V0052: {
    en: 'HEA/VOL',
    ja: 'HEA/VOL',
  },
  D206V0053: {
    en: 'YLK',
    ja: 'YLK',
  },
  D206V0054: {
    en: '2P1C',
    ja: '2P1C',
  },
  D206V0055: {
    en: 'KKS',
    ja: 'KKS',
  },
  D206V0056: {
    en: 'KNJ',
    ja: 'KNJ',
  },
  D206V0057: {
    en: 'STBY TL XXAUG',
    ja: 'STBY TL XXAUG',
  },
  D206V0058: {
    en: 'NRT/HKG',
    ja: 'NRT/HKG',
  },
  D206V0059: {
    en: '58.20/134,000.00',
    ja: '58.20/134,000.00',
  },
  D206V0060: {
    en: 'Add MAWB',
    ja: 'MAWBを追加',
  },
  D206V0061: {
    en: 'Edit',
    ja: '編集',
  },
  D206V0062: {
    en: 'Pcs.',
    ja: '個数',
  },
  D206V0063: {
    en: 'Wgt.',
    ja: '重量',
  },
  D206V0064: {
    en: 'Vol.',
    ja: '体積',
  },
  D206V0065: {
    en: 'ORG',
    ja: 'ORG',
  },
  D206V0066: {
    en: 'DST',
    ja: 'DST',
  },
  D206V0067: {
    en: 'Default: KIX',
    ja: 'デフォルト: KIX',
  },
  D206V0068: {
    en: 'COM',
    ja: 'COM',
  },
  D206V0069: {
    en: 'Past',
    ja: '過去',
  },
  D206V0070: {
    en: 'Origin',
    ja: '出発空港順',
  },
  D206V0071: {
    en: 'Destination',
    ja: '到着空港順',
  },
  D206V0072: {
    en: 'Flight No.',
    ja: 'フライト番号順',
  },
  D206V0073: {
    en: 'Aircraft',
    ja: '航空機順',
  },
  D206V0074: {
    en: 'TTL Weight',
    ja: '合計重量順',
  },
  D206V0075: {
    en: 'MAX Weight',
    ja: '最大搭載重量順',
  },
  D206V0076: {
    en: 'TTL Volume',
    ja: '合計容積順',
  },
  D206V0077: {
    en: 'MAX Volume',
    ja: '最大搭載容積順',
  },
  D206V0078: {
    en: 'Status',
    ja: 'ステータス順',
  },
  D206V0079: {
    en: 'Save',
    ja: '保存',
  },
}
// Display + 画面名
export default DisplayAirlineSpaceControl
