import PLAN from './../../../constants/CommonPlan'
/**
 * 共通バリデーションチェック方法
 * @returns {object} バリデーションチェック関数
 */
const CommonValidation = () => {
  const initObj = {
    checkResult: false,
    errMsg: 'VALIDATION000',
  }

  // 1KBあたりのバイト数(1000 or 1024)
  const byteSize = 1000

  const CONSTANTS = {
    CHAR_TYPE: {
      STR: 'str', // 半角英字
      NUM: 'num', // 半角数字
      STR_NUM: 'strNum', // 半角英字記号
      UP_STR_NUM: 'upStrNum', //大文字半角英数字
      NUM_SYM: 'numSym', // 半角数字記号
      STR_NUM_SYM: 'strNumSym', // 半角英数記号
      STR_NUM_SYM_LIN: 'strNumSymLin', // 半角英数記号改行
      NUM_ZERO_ACCEPT: 'numZeroAccept', // 半角数字0入力許容
      STR_NUM_SYM_SPA: 'strNumSymSpa', // 半角英数記号スペース
      MAIL: 'mail', //メールアドレス
    },
    CHAR_COUNT_TYPE: {
      INT_DEC: 'intDec', // 整数部分5桁小数第3位
      DEFAULT: 'default', // MIN 以上 MAX以内
    },
    FILE_SETTINGS: {
      IMG: {
        SIZE: 1 * byteSize * byteSize, // 1MB
        MIME: 'image/jpeg',
        VALIDATION_NO_SIZE: '024',
        VALIDATION_NO_TYPE: '046',
      },
      DOC: {
        SIZE: 5 * byteSize * byteSize, // 5MB
        MIME: 'application/pdf,application/x-zip-compressed',
        VALIDATION_NO_SIZE: '025',
        VALIDATION_NO_TYPE: '047',
      },
    },
    RESTRICED_WORDS: {
      // 使用を制限する単語
      AT_SIGN: '@',
      FULL_WIDTH_SPACE: '　',
      SPACE: ' ',
      MINUS: '-',
      POINT: '.',
      COLON: ':',
    },
  }

  /**
   * 半角英数記号のチェック
   * @param {String} value チェックする文字列
   * @return {boolean} .match 結果
   */
  const checkStrNumSym = (value) => {
    return value.match(/^[\x20-\x7e]*$/)
  }

  /**
   * 半角数字記号のチェック
   * @param {String} value チェックする文字列
   * @return {boolean} .match 結果
   */
  const checkNumSym = (value) => {
    return value.match(/^[0-9\x20-\x2f\x3a-\x40\x5b-\x60\x7b-\x7e]*$/)
  }

  /**
   * 半角英数のチェック
   * @param {String} value チェックする文字列
   * @return {boolean} .match 結果
   */
  const checkStrNum = (value) => {
    return value.match(/^[0-9a-zA-Z\x20]*$/)
  }

  /**
   * 半角英数(大文字)のチェック
   * @param {String} value チェックする文字列
   * @return {boolean} .match 結果
   */
  const checkUpStrNum = (value) => {
    return value.match(/^[0-9A-Z\x20]*$/)
  }

  /**
   * 半角英のチェック
   * @param {String} value チェックする文字列
   * @return {boolean} .match 結果
   */
  const checkStr = (value) => {
    return value.match(/^[a-zA-Z\x20]*$/)
  }

  /**
   * 数値であるかと半角数字のチェック
   * @param {String} value チェックする文字列
   * @return {boolean} .match 結果
   */
  const checkNum = (value) => {
    //+か-が含まれている場合は許容しない
    if (value.includes('+') || value.includes('-')) {
      return false
    }
    return !isNaN(value)
  }

  /**
   * 半角英数記号改行のチェック
   * @param {String} value チェックする文字列
   * @return {boolean} .match 結果
   */
  const checkStrNumSymLin = (value) => {
    return value.match(/^[\x20-\x7e\n]*$/)
  }

  /**
   * メールアドレスのチェック
   * @param {String} value チェックする文字列
   * @returns {boolean} 結果
   */
  const checkMail = (value) => {
    return value.match(
      /^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/
    )
  }

  /**
   * Required 必須項目チェック
   * @param {Striung} value チェックする文字列
   * @return {Object} validationCheck オブジェクトを返却
   * @return {boolean} validationCheck.checkResult - チェックに引っかかる場合true
   * @return {string} validationCheck.errMsg - エラーメッセージの文言番号 frontend\src\constants\ValidationCheckMessage.js
   */
  const checkRequired = (value) => {
    const validationCheck = {
      checkResult: true,
      errMsg: 'VALIDATION011',
    }
    return value.length === 0 ? validationCheck : {}
  }

  /**
   * 0入力のチェック
   * @param {Striung} value チェックする文字列
   * @return {Object} validationCheck オブジェクトを返却
   * @return {boolean} validationCheck.checkResult - チェックに引っかかる場合true
   * @return {string} validationCheck.errMsg - エラーメッセージの文言番号 frontend\src\constants\ValidationCheckMessage.js
   */
  const checkZero = (value) => {
    const validationCheck = {
      zeroObj: {
        checkResult: true,
        errMsg: 'VALIDATION053',
      },
    }

    // 0のみ、又は0.0と入力された場合エラー
    return Number(value) === 0 ? validationCheck.zeroObj : {}
  }

  /**
   * 数値のチェック 小数点、マイナス
   * @param {Striung} value チェックする文字列
   * @param {String} charType 文字種類チェック CONSTANTS.CHAR_TYPE
   * @param {String} charCountType 文字数チェック CONSTANTS.CHAR_COUNT_TYPE
   * @return {Object} validationCheck オブジェクトを返却
   * @return {boolean} validationCheck.checkResult - チェックに引っかかる場合true
   * @return {string} validationCheck.errMsg - エラーメッセージの文言番号 frontend\src\constants\ValidationCheckMessage.js
   */
  const checkNumber = (value, charType, charCountType) => {
    const decPointValidationCheck = {
      checkResult: true,
      errMsg: 'VALIDATION031',
    }
    const minusValidationCheck = {
      checkResult: true,
      errMsg: 'VALIDATION032',
    }
    const pointStartValidationCheck = {
      checkResult: true,
      errMsg: 'VALIDATION064',
    }

    // 数値の場合
    if (
      charType === CONSTANTS.CHAR_TYPE.NUM ||
      charType === CONSTANTS.CHAR_TYPE.NUM_ZERO_ACCEPT
    ) {
      // マイナスを含む場合エラー
      const minusCheck = value.match(CONSTANTS.RESTRICED_WORDS.MINUS)
        ? minusValidationCheck
        : {}
      // 小数値以外で小数点を含む場合エラーを返す
      const decPointCheck =
        value.match(new RegExp(`\\${CONSTANTS.RESTRICED_WORDS.POINT}`)) &&
        charCountType !== CONSTANTS.CHAR_COUNT_TYPE.INT_DEC
          ? decPointValidationCheck
          : {}
      // 0のみで入力された場合エラーを返す
      const zeroCheck =
        charType === CONSTANTS.CHAR_TYPE.NUM_ZERO_ACCEPT ? {} : checkZero(value)
      // 先頭に小数点を入力された場合エラーを返す
      const pointCheck = value.startsWith('.') ? pointStartValidationCheck : {}
      return { ...decPointCheck, ...minusCheck, ...zeroCheck, ...pointCheck }
    } else {
      // 数値以外の場合は判定不要
      return {}
    }
  }

  /**
   * スペースの必須チェック
   * @param {Striung} value チェックする文字列
   * @return {Object} validationCheck オブジェクトを返却
   * @return {boolean} validationCheck.checkResult - チェックに引っかかる場合true
   * @return {string} validationCheck.errMsg - エラーメッセージの文言番号 frontend\src\constants\ValidationCheckMessage.js
   */
  const checkRequiredSpace = (value) => {
    const validationCheck = {
      checkResult: true,
      errMsg: 'VALIDATION026',
    }
    // スペースを含む場合エラーを返す
    return value.match(
      new RegExp(
        `${CONSTANTS.RESTRICED_WORDS.FULL_WIDTH_SPACE}|${CONSTANTS.RESTRICED_WORDS.SPACE}`
      )
    )
      ? validationCheck
      : {}
  }

  /**
   * 不要なスペースのチェック
   * @param {Striung} value チェックする文字列
   * @return {Object} validationCheck オブジェクトを返却
   * @return {boolean} validationCheck.checkResult - チェックに引っかかる場合true
   * @return {string} validationCheck.errMsg - エラーメッセージの文言番号 frontend\src\constants\ValidationCheckMessage.js
   */
  const checkSpace = (value) => {
    const validationCheck = {
      fullWidthSpaceObj: {
        checkResult: true,
        errMsg: 'VALIDATION030',
      },
      firstSpaceObj: {
        checkResult: true,
        errMsg: 'VALIDATION027',
      },
      lastSpaceObj: {
        checkResult: true,
        errMsg: 'VALIDATION028',
      },
      multiSpaceObj: {
        checkResult: true,
        errMsg: 'VALIDATION029',
      },
    }

    // 全角スペースを使用した場合
    const fullWidthSpaceObj = value.match(
      CONSTANTS.RESTRICED_WORDS.FULL_WIDTH_SPACE
    )
      ? validationCheck.fullWidthSpaceObj
      : {}

    // 最初、最後にスペースを入力した場合
    const firstSpaceObj = value
      .slice(0, 1)
      .match(CONSTANTS.RESTRICED_WORDS.SPACE)
      ? validationCheck.firstSpaceObj
      : {}
    const lastSpaceObj = value.slice(-1).match(CONSTANTS.RESTRICED_WORDS.SPACE)
      ? validationCheck.lastSpaceObj
      : {}

    // スペースが続く場合
    const multiSpaceObj = value.match(
      new RegExp(`${CONSTANTS.RESTRICED_WORDS.SPACE}{2}`)
    )
      ? validationCheck.multiSpaceObj
      : {}
    // スペースを含む場合エラーを返す
    return {
      ...multiSpaceObj,
      ...lastSpaceObj,
      ...firstSpaceObj,
      ...fullWidthSpaceObj,
    }
  }

  /**
   * validationCheckの初期値を返却
   * @return {Object} validationCheck オブジェクトを返却
   * @return {boolean} validationCheck.checkResult - false
   * @return {string} validationCheck.errMsg - 空文字
   */
  const initValue = () => {
    return initObj
  }

  /**
   * 共通バリデーションチェック
   * @param {String} value チェックする文字列 falseを入力した場合
   * @param {String} charType 文字種類チェック CONSTANTS.CHAR_TYPE
   * @param {String} charCountType 文字数チェック CONSTANTS.CHAR_COUNT_TYPE
   * @param {Array} charCount [min, max]最小最大文字数の配列 [整数桁数, 小数桁数]制限文字数の配列
   * @param {Boolean} required 必須かどうか
   * @param {String} validation エラーメッセージの文言番号 frontend\src\constants\ValidationCheckMessage.js
   * @param {Boolean} spaceFlg 空白を制限するかどうか trueの場合制限あり
   * @return {Object} validationCheck オブジェクトを返却
   * @return {boolean} validationCheck.checkResult - チェックに引っかかる場合true
   * @return {string} validationCheck.errMsg - エラーメッセージの文言番号 frontend\src\constants\ValidationCheckMessage.js
   */
  const checkValidation = (
    value,
    charType,
    charCountType,
    charCount,
    required,
    validation,
    spaceFlg
  ) => {
    // valueをfalseで指定した場合はエラーメッセージのみ返却
    if (value === false) {
      // eslint-disable-next-line prefer-template
      return 'VALIDATION' + validation
    }

    /** 文字列に変換した値 */
    let valueStr = ''
    if (value !== null && value !== undefined) {
      valueStr = value.toString()
    }

    const requiredObj = required ? checkRequired(valueStr) : {}
    // 文字が空白の場合必須チェックのみ
    if (!valueStr) {
      return { ...initObj, ...requiredObj }
    }
    const checkNumObj = checkNumber(valueStr, charType, charCountType)
    const requiredSpaceObj = spaceFlg ? checkRequiredSpace(valueStr) : {}
    const checkSpaceObj =
      charType === CONSTANTS.CHAR_TYPE.STR_NUM_SYM_SPA
        ? {}
        : checkSpace(valueStr)
    const checkObj =
      checkCharType(valueStr, charType) ||
      checkCharCount(valueStr, charCountType, charCount)
        ? {
            checkResult: true,
            // eslint-disable-next-line prefer-template
            errMsg: 'VALIDATION' + validation,
          }
        : {}

    return {
      ...initObj,
      ...checkObj,
      ...checkSpaceObj,
      ...requiredSpaceObj,
      ...checkNumObj,
      ...requiredObj,
    }
  }

  /**
   * 文字種類チェック
   * @param {String} value チェックする文字列
   * @param {String} type チェックの種類 CONSTANTS.CHAR_TYPE
   * @returns {boolean} チェックに引っかかる場合と設定されていないtypeを入力されたときtrue
   */
  const checkCharType = (value, type) => {
    switch (type) {
      case CONSTANTS.CHAR_TYPE.STR:
        return !checkStr(value)
      case CONSTANTS.CHAR_TYPE.NUM:
      case CONSTANTS.CHAR_TYPE.NUM_ZERO_ACCEPT:
        return !checkNum(value)
      case CONSTANTS.CHAR_TYPE.STR_NUM:
        return !checkStrNum(value)
      case CONSTANTS.CHAR_TYPE.UP_STR_NUM:
        return !checkUpStrNum(value)
      case CONSTANTS.CHAR_TYPE.NUM_SYM:
        return !checkNumSym(value)
      case CONSTANTS.CHAR_TYPE.STR_NUM_SYM:
      case CONSTANTS.CHAR_TYPE.STR_NUM_SYM_SPA:
        return !checkStrNumSym(value)
      case CONSTANTS.CHAR_TYPE.STR_NUM_SYM_LIN:
        return !checkStrNumSymLin(value)
      case CONSTANTS.CHAR_TYPE.MAIL:
        return !checkMail(value)
      default:
        return true
    }
  }

  /**
   * 文字数チェック
   * @param {String} value チェックする文字列
   * @param {String} type チェックの種類 CONSTANTS.CHAR_COUNT_TYPE
   * @param {Array} count [min, max]最小最大文字数の配列 or [整数桁数, 小数桁数]制限文字数の配列
   * @returns {boolean} チェックに引っかかる場合と設定されていないtypeを入力されたときtrue
   */
  const checkCharCount = (value, type, count) => {
    switch (type) {
      case CONSTANTS.CHAR_COUNT_TYPE.INT_DEC:
        return checkIntDec(value, count)
      case CONSTANTS.CHAR_COUNT_TYPE.DEFAULT:
        return !(count[0] <= value.length && value.length <= count[1])
      default:
        return true
    }
  }

  /**
   * 整数部分小数部分のチェック
   * @param {*} value チェックする文字列
   * @param {Array} count [整数桁数, 小数桁数]制限文字数の配列
   * @returns {boolean} チェックに引っかかる場合true
   */
  const checkIntDec = (value, count) => {
    const length = value.length
    const intLength = value.split('.')[0] ? value.split('.')[0].length : 0
    const decLength = value.split('.')[1] ? value.split('.')[1].length : 0

    // eslint-disable-next-line yoda
    return (
      !(0 <= length && length <= count[0] + count[1] + 1) ||
      intLength > count[0] ||
      decLength > count[1]
    )
  }

  /**
   * ファイルチェック(サイズとファイルタイプ)
   * @param {File} file 画像、ドキュメントファイルオブジェクト
   * @param {Object} fileSetting 各画像設定 CONSTANTS.FILE_SETTINGS
   * @return {Object} オブジェクトを返却
   * @return {boolean} checkResult - チェックに引っかかる場合true
   * @return {string} errMsg - エラーメッセージの文言番号 frontend\src\constants\ValidationCheckMessage.js
   */
  const fileSize = (file, fileSetting) => {
    const size = file.size
    const type = file.type

    // 成功時の初期値
    let checkResult = false
    let validationNo = '000'

    // 優先度はMIMEチェック＞サイズチェック
    if (!fileSetting.MIME.split(',').includes(type)) {
      // MIMEチェック
      checkResult = true
      validationNo = fileSetting.VALIDATION_NO_TYPE
    } else if (size > fileSetting.SIZE) {
      // サイズチェック
      checkResult = true
      validationNo = fileSetting.VALIDATION_NO_SIZE
    }

    return {
      checkResult,
      errMsg: `VALIDATION${validationNo}`,
    }
  }

  /**
   * アットマークのチェック
   * @param {Striung} value チェックする文字列
   * @return {Object} validationCheck オブジェクトを返却
   * @return {boolean} validationCheck.checkResult - チェックに引っかかる場合true
   * @return {string} validationCheck.errMsg - エラーメッセージの文言番号 frontend\src\constants\ValidationCheckMessage.js
   */
  const checkAtSign = (value) => {
    const validationCheck = {
      firstAtSignObj: {
        checkResult: true,
        errMsg: 'VALIDATION048',
      },
      lastAtSignObj: {
        checkResult: true,
        errMsg: 'VALIDATION049',
      },
      noAtSignObj: {
        checkResult: true,
        errMsg: 'VALIDATION050',
      },
      countAtSignObj: {
        checkResult: true,
        errMsg: 'VALIDATION051',
      },
    }

    // 最初、最後に＠を入力した場合
    const firstAtSignObj = value
      .slice(0, 1)
      .match(CONSTANTS.RESTRICED_WORDS.AT_SIGN)
      ? validationCheck.firstAtSignObj
      : {}
    const lastAtSignObj = value
      .slice(-1)
      .match(CONSTANTS.RESTRICED_WORDS.AT_SIGN)
      ? validationCheck.lastAtSignObj
      : {}

    // ＠がない場合
    const noAtSignObj = value.match(CONSTANTS.RESTRICED_WORDS.AT_SIGN)
      ? {}
      : validationCheck.noAtSignObj

    // ＠が2つ以上ある場合
    const count =
      value.match(new RegExp(CONSTANTS.RESTRICED_WORDS.AT_SIGN, 'g'))?.length ??
      0
    const countAtSignObj = count > 1 ? validationCheck.countAtSignObj : {}

    // @を含む場合エラーを返す
    const result = {
      ...countAtSignObj,
      ...lastAtSignObj,
      ...noAtSignObj,
      ...firstAtSignObj,
    }
    return result.checkResult
      ? result
      : checkValidation(
          value,
          CONSTANTS.CHAR_TYPE.MAIL,
          CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
          [0, 100],
          true,
          '002',
          true
        )
  }

  /**
   * 時刻の入力チェック
   * @param {Striung} value チェックする文字列
   * @param {Boolean} required 必須入力フラグ
   * @return {Object} validationCheck オブジェクトを返却
   * @return {boolean} validationCheck.checkResult - チェックに引っかかる場合true
   * @return {string} validationCheck.errMsg - エラーメッセージの文言番号 frontend\src\constants\ValidationCheckMessage.js
   */
  const checkTime = (value, required) => {
    const validationCheck = {
      requiredErrorObj: {
        checkResult: true,
        errMsg: 'VALIDATION011', // 値が空文字のとき
      },
      formatErrorObj: {
        checkResult: true,
        errMsg: 'VALIDATION067', // 入力形式がXX:XXではなかったとき
      },
      timeOverErrorObj: {
        checkResult: true,
        errMsg: 'VALIDATION066', // 23:59以上入力された時
      },
    }

    //値が空文字のとき
    if (value === '') {
      // 必須入力の時エラーを返す、任意入力の時エラーなしを返す
      return required ? validationCheck.requiredErrorObj : initObj
    }

    const COLON = CONSTANTS.RESTRICED_WORDS.COLON

    // 入力値を時間と分に分ける
    const [hoursStr, minutesStr] = value.split(COLON)
    const hoursNum = parseInt(hoursStr)
    const minutesNum = parseInt(minutesStr)

    // 時間のバリデーションチェック 必須 数値のみ １~２文字以内
    const { checkResult: hoursError } = checkValidation(
      hoursStr,
      CONSTANTS.CHAR_TYPE.NUM_ZERO_ACCEPT,
      CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
      [1, 2],
      true,
      '000',
      true
    )

    // 分のバリデーションチェック 必須 数値のみ １~２文字以内
    const { checkResult: minutesError } = checkValidation(
      minutesStr,
      CONSTANTS.CHAR_TYPE.NUM_ZERO_ACCEPT,
      CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
      [1, 2],
      true,
      '000',
      true
    )

    // 時間のエラー または 分のエラー または :が2つ以上入力された場合エラーを返す
    if (hoursError || minutesError || value.split(COLON).length > 2) {
      return validationCheck.formatErrorObj
    }

    // 時間が24以上または分が60以上入力された場合エラーを返す
    if (hoursNum > 23 || minutesNum > 59) {
      return validationCheck.timeOverErrorObj
    }

    // エラーなし
    return initObj
  }

  /**
   * プランの入力チェック
   * @param {Striung} value プランID
   * @param {Striung} maxAcount 最大人数
   * @param {Boolean} userLength 現在のユーザー数
   * @param {Boolean} planNameId プランID
   * @return {Object} validationCheck オブジェクトを返却
   * @return {boolean} validationCheck.checkResult - チェックに引っかかる場合true
   * @return {string} validationCheck.errMsg - エラーメッセージの文言番号 frontend\src\constants\ValidationCheckMessage.js
   */
  const checkFwdrPlan = (value, maxAcount, userLength, planNameId) => {
    const validationCheck = {
      freeErrorObj: {
        checkResult: true,
        errMsg: 'VALIDATION068',
      },
      standardErrorObj: {
        checkResult: true,
        errMsg: 'VALIDATION069',
      },
    }

    if (userLength > maxAcount) {
      if (planNameId === PLAN.FREE.ID) {
        // Standard / Professional / EnterpriseからFreeプランに変更する場合
        return validationCheck.freeErrorObj
      } else if (planNameId === PLAN.STANDARD.ID) {
        // Professional / EnterpriseからStandardプランに変更する場合
        return validationCheck.standardErrorObj
      } else {
        // エラーなし
        return checkRequired(value)
      }
    }

    // エラーなし
    return checkRequired(value)
  }

  /**
   * プランの入力チェック
   * @param {Striung} value プランID
   * @param {Striung} maxAcount 最大人数
   * @param {Boolean} userLength 現在のユーザー数
   * @param {Boolean} planNameId プランID
   * @return {Object} validationCheck オブジェクトを返却
   * @return {boolean} validationCheck.checkResult - チェックに引っかかる場合true
   * @return {string} validationCheck.errMsg - エラーメッセージの文言番号 frontend\src\constants\ValidationCheckMessage.js
   */
  const checkCarPlan = (value, maxAcount, userLength, planNameId) => {
    const validationCheck = {
      standardErrorObj: {
        checkResult: true,
        errMsg: 'VALIDATION070',
      },
    }

    if (userLength > maxAcount) {
      if (planNameId === PLAN.STANDARD.ID) {
        // Professional / EnterpriseからStandardプランに変更する場合
        return validationCheck.standardErrorObj
      } else {
        // エラーなし
        return checkRequired(value)
      }
    }

    // エラーなし
    return checkRequired(value)
  }

  return {
    // 画像ファイルサイズ
    imgSize: (value) => fileSize(value, CONSTANTS.FILE_SETTINGS.IMG),
    // ドキュメントファイルサイズ
    docSize: (value) => fileSize(value, CONSTANTS.FILE_SETTINGS.DOC),

    // 初期値
    initValue,

    // 会社名 半角英数記号 0文字以上255文字以内 必須 半角スペース制限なし
    company: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.STR_NUM_SYM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 255],
        true,
        '001',
        false
      ),
    // Shipper/ConsigneeID 半角英数記号 0文字以上50文字以内 必須 半角スペース制限なし
    shipConId: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.STR_NUM_SYM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 50],
        true,
        '003',
        false
      ),
    // IATA/CASSNo 半角数字 11文字以上11文字以内 必須 半角スペース制限あり
    iataCassNo: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.NUM_ZERO_ACCEPT,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [11, 11],
        true,
        '041',
        true
      ),
    // Department 半角英数 0文字以上255文字以内 任意 半角スペース制限なし
    department: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.STR_NUM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 255],
        false,
        '009',
        false
      ),
    // DepartmentCode 半角英数 2文字以上2文字以内 任意 半角スペース制限あり
    departmentCode: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.STR_NUM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [2, 2],
        false,
        '052',
        true
      ),
    // FirstName・LastName 半角英数 0文字以上50文字以内 必須 半角スペース制限あり
    name: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.STR_NUM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 50],
        true,
        '005',
        true
      ),
    // Position 半角英数 0文字以上100文字以内 任意 半角スペース制限なし
    position: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.STR_NUM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 100],
        false,
        '004',
        false
      ),
    // mail 半角英数記号 0文字以上100文字以内 必須 半角スペース制限あり
    mail: (value) => checkAtSign(value),
    // Telephone 半角数字 0文字以上15文字以内 必須 半角スペース制限あり
    telephone: (value) =>
      checkValidation(
        value.charAt(0) === '+' ? value.slice(1) : value,
        CONSTANTS.CHAR_TYPE.NUM_ZERO_ACCEPT,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 15],
        true,
        '040',
        true
      ),
    // PostalCode 半角英数記号 0文字以上9文字以内 必須 半角スペース制限あり
    postalCode: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.STR_NUM_SYM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 9],
        true,
        '042',
        true
      ),
    // Address 半角英数記号 0文字以上255文字以内 必須 半角スペース制限なし
    address: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.STR_NUM_SYM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 255],
        true,
        '001',
        false
      ),
    // Pcs 半角数字 0文字以上5文字以内 必須 半角スペース制限あり
    pcs: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.NUM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 5],
        true,
        '010',
        true
      ),
    // GrossWgt 半角数字 整数5桁小数第3位まで 必須 半角スペース制限あり
    grossWgt: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.NUM,
        CONSTANTS.CHAR_COUNT_TYPE.INT_DEC,
        [5, 3],
        true,
        '043',
        true
      ),
    // 貨物サイズWidthLengthHeight 半角数字 0文字以上5文字以内 必須 半角スペース制限あり
    cargoSize: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.NUM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 5],
        true,
        '010',
        true
      ),
    // volume 半角数字 整数5桁小数第3位まで 必須 半角スペース制限あり
    volume: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.NUM,
        CONSTANTS.CHAR_COUNT_TYPE.INT_DEC,
        [5, 3],
        true,
        '043',
        true
      ),
    // PackingList画面のvolume 半角数字 整数5桁小数第3位まで 任意 半角スペース制限あり
    packingListVolume: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.NUM,
        CONSTANTS.CHAR_COUNT_TYPE.INT_DEC,
        [5, 3],
        false,
        '043',
        true
      ),
    // CommDescribe 半角英数記号 0文字以上50文字以内 必須 半角スペース制限なし
    commDescribe: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.STR_NUM_SYM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 50],
        true,
        '003',
        false
      ),
    // ULDID 半角英数(大文字) 0文字以上5文字以内 必須 半角スペース制限あり
    uldId: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.UP_STR_NUM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 10],
        true,
        '034',
        true
      ),
    // UldNumber 半角数字 4文字以上5文字以内 必須 半角スペース制限あり
    uldNumber: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.NUM_ZERO_ACCEPT,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [4, 5],
        true,
        '039',
        true
      ),
    // NumberofPackages 半角数字 0文字以上5文字以内 必須 半角スペース制限あり
    numberOfPackages: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.NUM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 5],
        true,
        '010',
        true
      ),
    // quantity 半角数字 整数5桁小数第3位まで 必須 半角スペース制限あり
    quantity: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.NUM,
        CONSTANTS.CHAR_COUNT_TYPE.INT_DEC,
        [5, 3],
        true,
        '043',
        true
      ),
    // ContentsAndDescription 半角英数記号 0文字以上80文字以内 必須 半角スペース制限なし
    contentsAndDescription: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.STR_NUM_SYM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 80],
        true,
        '044',
        false
      ),
    // SupplementaryInformation 半角英数記号 0文字以上90文字以内 必須 半角スペース制限なし
    supplementaryInfomation: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.STR_NUM_SYM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 90],
        true,
        '061',
        false
      ),
    // POS. 半角英数(大文字) 2文字以上2文字以内 任意 半角スペース制限あり
    pos: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.UP_STR_NUM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [2, 2],
        false,
        '014',
        true
      ),
    // Accounting 半角英数記号 0文字以上255文字以内 任意 半角スペース制限なし
    accounting: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.STR_NUM_SYM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 255],
        false,
        '001',
        false
      ),
    // Handling 半角英数記号 0文字以上242文字以内 任意 半角スペース制限なし
    handling: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.STR_NUM_SYM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 242],
        false,
        '063',
        false
      ),
    // NatureQuantity 半角英数記号 0文字以上84文字以内 必須 半角スペース制限なし
    natureQuantity: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.STR_NUM_SYM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 84],
        false,
        '055',
        false
      ),
    // specialGoods 半角英数記号改行 0文字以上255文字以内 任意 半角スペース制限なし
    specialGoods: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.STR_NUM_SYM_LIN,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 255],
        false,
        '001',
        false
      ),
    // Documents画面Quantity 半角数字 整数5桁小数第3位まで 任意 半角スペース制限あり
    documentsQuantity: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.NUM,
        CONSTANTS.CHAR_COUNT_TYPE.INT_DEC,
        [5, 3],
        false,
        '043',
        true
      ),
    // DeclaredPrice 半角英数 0文字以上10文字以内 任意 半角スペース制限なし
    declaredPrice: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.STR_NUM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 10],
        false,
        '006',
        false
      ),
    // CustomsPrice 半角英数 0文字以上10文字以内 任意 半角スペース制限なし
    customsPrice: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.STR_NUM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 10],
        false,
        '006',
        false
      ),
    // HTSCode 半角英数記号 0文字以上100文字以内 任意 半角スペース制限なし
    htsCode: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.STR_NUM_SYM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 100],
        false,
        '002',
        false
      ),
    // TechnicalName 半角英数記号 0文字以上100文字以内 任意 半角スペース制限なし
    technicalName: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.STR_NUM_SYM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 100],
        false,
        '002',
        false
      ),
    // PackingInst 半角英数記号 0文字以上10文字以内 任意 半角スペース制限なし
    packingInst: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.STR_NUM_SYM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 10],
        false,
        '015',
        false
      ),
    // AdditionalType 半角英数記号改行 0文字以上25文字以内 任意 半角スペース制限なし
    additionalType: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.STR_NUM_SYM_LIN,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 25],
        false,
        '057',
        false
      ),
    // AdditionalHandlingInfo 半角英数記号 0文字以上255文字以内 必須 半角スペース制限なし
    additionalHandlingInfoRequired: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.STR_NUM_SYM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 255],
        true,
        '001',
        false
      ),
    // AdditionalHandlingInfo 半角英数記号 0文字以上255文字以内 任意 半角スペース制限なし
    additionalHandlingInfo: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.STR_NUM_SYM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 255],
        false,
        '001',
        false
      ),
    // DGDName 半角英数記号 0文字以上100文字以内 必須 半角スペース制限なし
    dgdNameRequired: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.STR_NUM_SYM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 100],
        true,
        '002',
        false
      ),
    // DGDName 半角英数記号 0文字以上100文字以内 任意 半角スペース制限なし
    dgdName: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.STR_NUM_SYM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 100],
        false,
        '002',
        false
      ),
    // DGDTitleOrDept 半角英数記号 0文字以上100文字以内 必須 半角スペース制限なし
    dgdTitleOrDeptRequired: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.STR_NUM_SYM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 100],
        false,
        '002',
        false
      ),
    // DGDTitleOrDept 半角英数記号 0文字以上100文字以内 任意 半角スペース制限なし
    dgdTitleOrDept: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.STR_NUM_SYM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 100],
        false,
        '002',
        false
      ),
    // FlightNumber 半角数字 0文字以上50文字以内 必須 半角スペース制限なし
    flightNumber: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.NUM_ZERO_ACCEPT,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 50],
        true,
        '007',
        false
      ),
    // Allotment 半角英数記号 0文字以上80文字以内 必須 半角スペース制限なし
    allotment: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.STR_NUM_SYM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 80],
        true,
        '044',
        false
      ),
    // Password 半角英数記号 8文字以上15文字以内 必須 半角スペース制限あり
    password: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.STR_NUM_SYM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [8, 15],
        true,
        '016',
        true
      ),
    // SpecialNotes 半角英数記号 0文字以上100文字以内 必須 半角スペース制限なし
    specialNotes: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.STR_NUM_SYM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 100],
        true,
        '002',
        false
      ),
    // SpecialNotes 半角英数記号 0文字以上100文字以内 任意 半角スペース制限なし
    specialNotesOptional: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.STR_NUM_SYM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 100],
        false,
        '002',
        false
      ),
    // Per% 半角数字 整数2桁小数第2位まで 必須 半角スペース制限あり
    per: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.NUM,
        CONSTANTS.CHAR_COUNT_TYPE.INT_DEC,
        [2, 2],
        true,
        '060',
        true
      ),
    // rateOfExchange為替レート 半角数字 整数5桁小数第5位まで 必須 半角スペース制限あり
    rateOfExchange: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.NUM,
        CONSTANTS.CHAR_COUNT_TYPE.INT_DEC,
        [5, 5],
        true,
        '058',
        true
      ),
    // Commodity 半角英数 0文字以上10文字以内 必須 半角スペース制限なし
    commodity: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.STR_NUM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 10],
        true,
        '006',
        false
      ),
    // price 料金 半角数字 整数10桁小数第2位まで 必須 半角スペース制限あり
    price: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.NUM,
        CONSTANTS.CHAR_COUNT_TYPE.INT_DEC,
        [10, 2],
        true,
        '059',
        true
      ),
    // price 料金 半角数字 整数10桁小数第2位まで 任意 半角スペース制限あり
    priceOptional: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.NUM,
        CONSTANTS.CHAR_COUNT_TYPE.INT_DEC,
        [10, 2],
        false,
        '059',
        true
      ),
    // GoodsDescription 半角英数記号 0文字以上65文字以内 必須 半角スペース制限なし
    goodsDescription: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.STR_NUM_SYM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 65],
        true,
        '021',
        false
      ),
    // InvoiceNumber 半角英数記号 0文字以上10文字以内 必須 半角スペース制限なし
    invoiceNumber: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.STR_NUM_SYM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 10],
        true,
        '015',
        false
      ),
    // awbNumber前半 半角英数 3文字以上3文字以内 必須 半角スペース制限あり
    awbNumberFirstHarf: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.STR_NUM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [3, 3],
        true,
        '022',
        true
      ),
    // awbNumber後半 半角数字 4文字以上4文字以内 必須 半角スペース制限あり
    awbNumberSecondHalf: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.NUM_ZERO_ACCEPT,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [4, 4],
        true,
        '023',
        true
      ),
    // QuoteNumber 半角英数記号 0文字以上50文字以内 任意 半角スペース制限なし
    quoteNumber: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.STR_NUM_SYM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 50],
        false,
        '003',
        false
      ),
    // QuoteValidUntil 半角英数記号 0文字以上50文字以内 任意 半角スペース制限なし
    quoteValidUntil: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.STR_NUM_SYM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 50],
        false,
        '003',
        false
      ),
    // QuoteAmount 半角数字 整数10桁小数第2位まで 任意 半角スペース制限あり
    quoteAmount: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.NUM,
        CONSTANTS.CHAR_COUNT_TYPE.INT_DEC,
        [10, 2],
        false,
        '059',
        true
      ),
    // TotalAmount 半角数字 整数10桁小数第2位まで 必須 半角スペース制限あり
    totalAmount: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.NUM,
        CONSTANTS.CHAR_COUNT_TYPE.INT_DEC,
        [10, 2],
        true,
        '059',
        true
      ),
    // TermsAndConditions 半角英数記号 0文字以上140文字以内 任意 半角スペース制限なし
    termsAndConditions: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.STR_NUM_SYM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 140],
        false,
        '062',
        false
      ),
    // statusReason 半角英数記号 0文字以上255文字以内 必須 半角スペース制限なし
    statusReason: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.STR_NUM_SYM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 255],
        true,
        '001',
        false
      ),
    // cancelReason 半角英数記号 0文字以上255文字以内 必須 半角スペース制限なし
    cancelReason: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.STR_NUM_SYM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 255],
        true,
        '001',
        false
      ),
    // ETD/ETA/FlightTime 半角数字記号 0文字以上5文字以内 必須 半角スペース制限なし
    time: (value) => checkTime(value, true),
    // ETD/ETA/FlightTime 半角数字記号 0文字以上5文字以内 必須 半角スペース制限なし
    timeOptional: (value) => checkTime(value, false),
    // RegNumber 半角英数 0文字以上50文字以内 必須 半角スペース制限あり
    regNumber: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.STR_NUM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 50],
        true,
        '005',
        true
      ),
    // FHLVersion/FWBVersion 半角数字 0文字以上2文字以内 必須 半角スペース制限あり
    version: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.NUM_ZERO_ACCEPT,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 2],
        true,
        '036',
        true
      ),
    // netQuantity 半角英数 0文字以上8文字以内 必須 半角スペース制限なし
    netQuantity: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.STR_NUM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 8],
        true,
        '038',
        false
      ),
    // natureOfContentsRequired 半角英数記号 0文字以上25文字以内 必須 半角スペース制限なし
    natureOfContentsRequired: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.STR_NUM_SYM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 25],
        true,
        '057',
        false
      ),
    // natureOfContents 半角英数記号 0文字以上25文字以内 任意 半角スペース制限なし
    natureOfContents: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.STR_NUM_SYM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 25],
        false,
        '057',
        false
      ),
    // mainDeck/lowerDeck 半角数字 0文字以上4文字以内 必須 半角スペース制限あり
    deck: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.NUM_ZERO_ACCEPT,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 4],
        true,
        '019',
        true
      ),
    // mawbNumber 半角数字 8文字以上8文字以内 必須 半角スペース制限あり
    mawb: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.NUM_ZERO_ACCEPT,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [8, 8],
        true,
        '045',
        true
      ),
    // myProfilePassword 半角英数記号 8文字以上15文字以内 任意 半角スペース制限あり
    myProfilePassword: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.STR_NUM_SYM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [8, 15],
        false,
        '016',
        true
      ),
    // departmentCarrier 半角英数 0文字以上255文字以内 必須 半角スペース制限なし
    departmentCarrier: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.STR_NUM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 255],
        true,
        '009',
        false
      ),
    // record 半角英数記号 250文字以上250文字以内 必須 半角スペース制限なし
    record: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.STR_NUM_SYM_SPA,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [250, 250],
        true,
        '054',
        false
      ),
    // GrossWgt 半角数字 0入力可 整数5桁小数第3位まで 必須 半角スペース制限あり
    grossWgtZeroTol: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.NUM_ZERO_ACCEPT,
        CONSTANTS.CHAR_COUNT_TYPE.INT_DEC,
        [5, 3],
        true,
        '043',
        true
      ),
    // 貨物サイズWidthLengthHeight 半角数字 0文字以上5文字以内 必須 半角スペース制限あり
    cargoSizeZeroTol: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.NUM_ZERO_ACCEPT,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 5],
        true,
        '010',
        true
      ),
    // withdrawalReason 半角英数記号改行 0文字以上255文字以内 必須 半角スペース制限なし
    withdrawalReason: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.STR_NUM_SYM_LIN,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 255],
        true,
        '001',
        false
      ),
    // 割り振るMAWBの数 numOfMawb 半角数字 0文字以上4文字以内 必須 半角スペース制限あり
    numOfMawb: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.NUM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 4],
        true,
        '019',
        true
      ),
    // AWBRecord 半角英数記号 251文字以上251文字以内 必須 半角スペース制限なし
    awbRecord: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.STR_NUM_SYM_SPA,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [251, 251],
        true,
        '056',
        false
      ),
    // PIC 半角英数 0文字以上100文字以内 必須 半角スペース制限なし
    pic: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.STR_NUM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 100],
        true,
        '004',
        false
      ),
    // Remark 半角英数 0文字以上255文字以内 必須 半角スペース制限なし
    remark: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.STR_NUM_SYM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 255],
        false,
        '001',
        false
      ),
    // BidDetails画面TTLWgtとTTLVol 半角数字 整数5桁小数第2位まで 任意 半角スペース制限あり
    ttlWgtVol: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.NUM,
        CONSTANTS.CHAR_COUNT_TYPE.INT_DEC,
        [5, 2],
        false,
        '043',
        true
      ),
    // fsc料金 半角数字 整数10桁小数第2位まで 任意 半角スペース制限あり 0許容
    fscZeroAccept: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.NUM_ZERO_ACCEPT,
        CONSTANTS.CHAR_COUNT_TYPE.INT_DEC,
        [10, 2],
        false,
        '059',
        true
      ),
    // InterLine 半角英数記号 0文字以上255文字以内 任意 半角スペース制限なし
    interLine: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.STR_NUM_SYM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [0, 255],
        false,
        '001',
        false
      ),
    // prefix 半角英数記号 3文字以上3文字以内 任意 半角スペース制限あり
    prefix: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.UP_STR_NUM,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [3, 3],
        false,
        '065',
        true
      ),
    // MAWBNo 半角数字 4文字以上4文字以内 任意 半角スペース制限あり
    mawbNumber: (value) =>
      checkValidation(
        value,
        CONSTANTS.CHAR_TYPE.NUM_ZERO_ACCEPT,
        CONSTANTS.CHAR_COUNT_TYPE.DEFAULT,
        [4, 4],
        false,
        '023',
        true
      ),
    // Plan 現在のユーザー数が最大人数より多い場合
    fwdrPlan: (value, maxAccount, totalAccount, planNameId) =>
      checkFwdrPlan(value, maxAccount, totalAccount, planNameId),
    carPlan: (value, maxAccount, totalAccount, planNameId) =>
      checkCarPlan(value, maxAccount, totalAccount, planNameId),
  }
}

export default CommonValidation()
