/* eslint-disable prefer-template */
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MaterialRadio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import MobileRadio from './MobileRadio'

const useStyles = makeStyles((theme) => ({
  desktop: {
    fontFamily: theme.typography.fontFamily,
    margin: '-0.5rem 0rem',
    color: theme.fontColor.main,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobile: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}))

const Radio = (props) => {
  const classes = useStyles()
  return (
    <>
      <FormControlLabel
        id={props.id}
        name={props.name}
        label={props.label}
        value={props.value}
        onClick={props.onClick}
        disabled={props.disabled}
        className={classes.desktop + ' ' + props.className}
        labelPlacement={props.labelPlacement ?? 'end'}
        control={<MaterialRadio color="primary" />}
      />
      <MobileRadio
        id={props.id}
        name={props.name}
        label={props.label}
        value={props.value}
        disabled={props.disabled}
        className={classes.mobile + ' ' + props.className}
      />
    </>
  )
}

export default Radio
