// Display + 画面名
const DisplayAirlineBookingList = {
  // 画面ID + 文言ID
  D203V0001: {
    en: 'Booking List',
    ja: '予約一覧',
  },
  D203V0002: {
    en: 'MAWB No., ORG/DST, etc.',
    ja: 'マスター番号, 出発/到着など',
  },
  D203V0003: {
    en: 'Default',
    ja: 'デフォルト',
  },
  D203V0004: {
    en: 'KIX',
    ja: 'KIX',
  },
  D203V0005: {
    en: 'Sort by',
    ja: '並び替え',
  },
  D203V0006: {
    en: 'Lately',
    ja: '最近',
  },
  D203V0007: {
    en: 'MAWB No.',
    ja: 'マスター番号',
  },
  D203V0008: {
    en: 'Pcs./Wgt./Vol.',
    ja: '個数/重量/容積',
  },
  D203V0009: {
    en: 'SHC',
    ja: 'SHC',
  },
  D203V0010: {
    en: 'FWDR',
    ja: '混載業者',
  },
  D203V0011: {
    en: 'ORG/DST',
    ja: '出発/到着',
  },
  D203V0012: {
    en: 'Status',
    ja: 'ステータス',
  },
  D203V0013: {
    en: 'Space Check',
    ja: 'スペース確認',
  },
  D203V0014: {
    en: 'Confirm',
    ja: '詳細確認',
  },
  D203V0015: {
    en: '933-XXXX XXXX',
    ja: '933-XXXX XXXX',
  },
  D203V0016: {
    en: '10pcs/300kg/2.05m3',
    ja: '10pcs/300kg/2.05m3',
  },
  D203V0017: {
    en: 'Yusen Logistics',
    ja: '郵船ロジスティクス',
  },
  D203V0018: {
    en: 'NRT/ORD',
    ja: 'NRT/ORD',
  },
  D203V0019: {
    en: 'NEW Booking',
    ja: '新規予約',
  },
  D203V0020: {
    en: 'KZXXX/XXAUG',
    ja: 'KZXXX/XXAUG',
  },
  D203V0021: {
    en: 'Check',
    ja: '確認',
  },
  D203V0022: {
    en: 'COL/ICE',
    ja: 'COL/ICE',
  },
  D203V0023: {
    en: 'COL',
    ja: 'COL',
  },
  D203V0024: {
    en: 'RFL/CAO',
    ja: 'RFL/CAO',
  },
  D203V0025: {
    en: 'ROX',
    ja: 'ROX',
  },
  D203V0026: {
    en: 'BUP',
    ja: 'BUP',
  },
  D203V0027: {
    en: 'HEA/VOL',
    ja: 'HEA/VOL',
  },
  D203V0028: {
    en: 'AVI',
    ja: 'AVI',
  },
  D203V0029: {
    en: 'OHG',
    ja: 'OHG',
  },
  D203V0030: {
    en: 'RMD',
    ja: 'RMD',
  },
  D203V0031: {
    en: 'FRO/ICE',
    ja: 'FRO/ICE',
  },
  D203V0032: {
    en: 'RNG',
    ja: 'RNG',
  },
  D203V0033: {
    en: 'Nippon Express',
    ja: '日通',
  },
  D203V0034: {
    en: 'Expeditors',
    ja: 'エクスペダイターズジャパン',
  },
  D203V0035: {
    en: 'Panalpina',
    ja: 'パナルピナ',
  },
  D203V0036: {
    en: 'DB Schenker',
    ja: 'DBシェンカー',
  },
  D203V0037: {
    en: 'Hanshin Hankyu',
    ja: '阪神阪急',
  },
  D203V0038: {
    en: 'NNR Global Logistics',
    ja: 'NNRグローバルロジスティクス',
  },
  D203V0039: {
    en: 'DHL Global Forwarding',
    ja: 'DHLグローバルフォワーディング',
  },
  D203V0040: {
    en: 'Kuehne + Nagel',
    ja: 'キューネ＋ナーゲル',
  },
  D203V0041: {
    en: 'CEVA Logistics',
    ja: 'CEVAロジスティクス',
  },
  D203V0042: {
    en: 'Kintetsu Express',
    ja: '近鉄エクスプレス',
  },
  D203V0043: {
    en: 'Nisshin',
    ja: '日清',
  },
  D203V0044: {
    en: 'K-Line Logistics',
    ja: 'K-Lineロジスティクス',
  },
  D203V0045: {
    en: 'NRT/HKG',
    ja: 'NRT/HKG',
  },
  D203V0046: {
    en: 'NRT/AMS',
    ja: 'NRT/AMS',
  },
  D203V0047: {
    en: 'NRT/DFW',
    ja: 'NRT/DFW',
  },
  D203V0048: {
    en: 'NRT/SIN',
    ja: 'NRT/SIN',
  },
  D203V0049: {
    en: 'NRT/BKK',
    ja: 'NRT/BKK',
  },
  D203V0050: {
    en: 'NRT/PVG',
    ja: 'NRT/PVG',
  },
  D203V0051: {
    en: 'NRT/TPE',
    ja: 'NRT/TPE',
  },
  D203V0052: {
    en: 'NRT/MXP',
    ja: 'NRT/MXP',
  },
  D203V0053: {
    en: 'NRT/JFK',
    ja: 'NRT/JFK',
  },
  D203V0054: {
    en: 'NRT/SFO',
    ja: 'NRT/SFO',
  },
  D203V0055: {
    en: 'NRT/LAX',
    ja: 'NRT/LAX',
  },
  D203V0056: {
    en: '*Rate Request',
    ja: 'レートリクエスト',
  },
  D203V0057: {
    en: 'Pending',
    ja: '保留中',
  },
  D203V0058: {
    en: 'Rate Request',
    ja: 'レートーリクエスト',
  },
  D203V0059: {
    en: 'Cancelled',
    ja: 'キャンセル',
  },
  D203V0060: {
    en: 'Rejected',
    ja: '拒否',
  },
  D203V0061: {
    en: 'Space Confirmed',
    ja: 'スペース確認済み',
  },
  D203V0062: {
    en: 'Back',
    ja: '戻る',
  },
  D203V0063: {
    en: 'Lately',
    ja: '最近',
  },
  D203V0064: {
    en: 'Past',
    ja: '過去',
  },
  D203V0065: {
    en: 'MAWB No. Ascending',
    ja: 'マスター番号昇順',
  },
  D203V0066: {
    en: 'MAWB No. Descending',
    ja: 'マスター番号降順',
  },
  D203V0067: {
    en: 'Pieces',
    ja: '個数順',
  },
  D203V0068: {
    en: 'Weight',
    ja: '重量順',
  },
  D203V0069: {
    en: 'Volume',
    ja: '容積順',
  },
  D203V0070: {
    en: 'SHC',
    ja: 'SHC順',
  },
  D203V0071: {
    en: 'FWDR',
    ja: '混載業者順',
  },
  D203V0072: {
    en: 'Origin',
    ja: '出発空港順',
  },
  D203V0073: {
    en: 'Destination',
    ja: '到着空港順',
  },
  D203V0074: {
    en: 'Status',
    ja: 'ステータス順',
  },
}
// Display + 画面名
export default DisplayAirlineBookingList
