import React from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import HeaderText from '../HeaderText'
import HeaderTextRequired from '../HeaderTextRequired'

/**
 * @param {Object} props - 以下
 * @param {Array} required - trueの場合必須のマークを付ける
 * @return {JSX} 荷主アドレス欄のヘッダーコンポーネント
 */
const HeaderCompanyInfoText = ({ required }) => {
  const { t } = useTranslation()

  return (
    <Grid item md={12} xs={12}>
      {required ? (
        <HeaderTextRequired>{t('D014V0008')}</HeaderTextRequired>
      ) : (
        <HeaderText>{t('D014V0008')}</HeaderText>
      )}
    </Grid>
  )
}

HeaderCompanyInfoText.propTypes = {
  required: PropTypes.bool,
}

export default HeaderCompanyInfoText
