// Display + 画面名
const DisplayPackingList = {
  // 画面ID + 文言ID
  D013V0001: {
    en: 'Packing List',
    ja: 'Packing List',
  },
  D013V0002: {
    en: 'No.',
    ja: '番号',
  },
  D013V0003: {
    en: 'Enter Packing List No.',
    ja: 'Enter Packing List No.',
  },
  D013V0004: {
    en: 'Date',
    ja: '日付',
  },
  D013V0005: {
    en: '2020/07/31',
    ja: '2020年7月31日',
  },
  D013V0006: {
    en: 'Place',
    ja: '場所',
  },
  D013V0007: {
    en: 'Osaka, Japan',
    ja: '大阪、日本',
  },
  D013V0008: {
    en: 'Shipper',
    ja: '荷主',
  },
  D013V0009: {
    en: 'Panasonic Corporation',
    ja: 'パナソニック株式会社',
  },
  D013V0010: {
    en: '1006, Oaza Kadoma, Kadoma-shi, Oska 571-8501, Japan',
    ja: '571-8501 大阪府門真市大字門真1006番地',
  },
  D013V0011: {
    en: 'tel.',
    ja: '電話番号',
  },
  D013V0012: {
    en: '81-6-6908-1121',
    ja: '81-6-6908-1121',
  },
  D013V0013: {
    en: 'Attn',
    ja: '連絡先',
  },
  D013V0014: {
    en: 'Konosuke Matsushita',
    ja: '松下幸之助',
  },
  D013V0015: {
    en: 'Consignee',
    ja: '荷受人',
  },
  D013V0016: {
    en: 'Panasonic R&D Company of America',
    ja: 'パナソニックR&Dカンパニーオブアメリカ',
  },
  D013V0017: {
    en: '206 Ravendale Dr, Mountain View, CA 94043, United States',
    ja: '206 Ravendale Dr, Mountain View, CA 94043, United States',
  },
  D013V0018: {
    en: '1-408-861-3900',
    ja: '1-408-861-3900',
  },
  D013V0019: {
    en: 'James Collins',
    ja: ' James Collins',
  },
  D013V0020: {
    en: 'By Aircraft',
    ja: '航空機で',
  },
  D013V0021: {
    en: 'On or About',
    ja: 'およそ',
  },
  D013V0022: {
    en: 'From',
    ja: '出発',
  },
  D013V0023: {
    en: "KIX - Kansai Int'l Airport",
    ja: 'KIX - 関西国際空港',
  },
  D013V0024: {
    en: 'To',
    ja: '到着',
  },
  D013V0025: {
    en: "SFO - San Francisco Int'l Airport",
    ja: 'SFO - サンフランシスコ国際空港',
  },
  D013V0026: {
    en: 'Trade Terms',
    ja: '取引条件',
  },
  D013V0027: {
    en: 'CIP - Carriage and Insurance Paid to',
    ja: 'CIP - 運送と保険の支払い',
  },
  D013V0028: {
    en: 'Payment Terms',
    ja: '支払い条件',
  },
  D013V0029: {
    en: 'T.T Remittance',
    ja: 'T.T送金',
  },
  D013V0030: {
    en: 'No. of Pkg',
    ja: 'パッケージ数',
  },
  D013V0031: {
    en: 'Description of Goods',
    ja: '商品の説明',
  },
  D013V0032: {
    en: 'Quantity',
    ja: '量',
  },
  D013V0033: {
    en: 'Net Weight',
    ja: '正味重量',
  },
  D013V0034: {
    en: 'Gross Weight',
    ja: '総重量',
  },
  D013V0035: {
    en: 'Volume',
    ja: '容量',
  },
  D013V0036: {
    en: 'XXXXXX',
    ja: 'XXXXXX',
  },
  D013V0037: {
    en: 'Enter Goods details',
    ja: '商品の説明を入力',
  },
  D013V0038: {
    en: 'Enter Pcs.',
    ja: '個数を入力',
  },
  D013V0039: {
    en: 'Enter Net Wgt.',
    ja: '正味重量を入力',
  },
  D013V0040: {
    en: 'Enter Gross Wgt.',
    ja: '総重量を入力',
  },
  D013V0041: {
    en: 'Enter Vol.',
    ja: '容量を入力',
  },
  D013V0042: {
    en: 'Total',
    ja: '合計',
  },
  D013V0043: {
    en: 'Add',
    ja: '追加',
  },
  D013V0044: {
    en: 'Country of Origin',
    ja: '原産国',
  },
  D013V0045: {
    en: 'Signature',
    ja: '署名',
  },
  D013V0046: {
    en: 'Back ',
    ja: 'アップロード',
  },
  D013V0047: {
    en: 'Upload',
    ja: '戻る',
  },
  D013V0048: {
    en: 'xxxxxxxxxxxx',
    ja: 'xxxxxxxxxxxx',
  },
  D013V0049: {
    en: 'X',
    ja: 'X',
  },
  D013V0050: {
    en: 'pcs',
    ja: '個',
  },
  D013V0051: {
    en: 'kg',
    ja: 'kg',
  },
  D013V0052: {
    en: 'm3',
    ja: 'm3',
  },
  D013V0053: {
    en: 'Box No.',
    ja: 'Box No.',
  },
  D013V0054: {
    en: 'SIGN',
    ja: 'サイン',
  },
  D013V0055: {
    en: 'Enter Box details',
    ja: '梱包の説明を入力',
  },
  D013V0056: {
    en: 'Category',
    ja: 'カテゴリ',
  },
  D013V0057: {
    en: 'Page',
    ja: 'ページ',
  },
}
// Display + 画面名
export default DisplayPackingList
