import React, { useReducer, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import reducer, {
  initialState,
} from '../../../../reducers/master/lightMasterCarCompanyInfo'
import LayoutBox from '../../../common/layout/LayoutBox'
import AppContext from '../../../../contexts/AppContext'
import TitleLightCarCompanyInfo from './00TitleLightCarCompanyInfo/TitleLightCarCompanyInfo'
import TableLightCarCompanyInfo from './01TableLightCarCompanyInfo/TableLightCarCompanyInfo'
import ButtonLightCarCompanyInfo from './02ButtonLightCarCompanyInfo/ButtonLightCarCompanyInfo'
import CommonFunc from '../../../common/function/CommonFunc'
import ApiPaths from '../../../../constants/ApiPaths'
import {
  UPDATE_CAR_COMPANY_NAME,
  UPDATE_CAR_TWO_LETTER_CODE,
  UPDATE_CAR_POSTAL_CODE,
  UPDATE_CAR_COMPANY_ADDRESS,
  UPDATE_CAR_DEPARTMENT,
  UPDATE_CAR_FIRST_NAME,
  UPDATE_CAR_LAST_NAME,
  UPDATE_CAR_POSITION,
  UPDATE_CAR_MAIL,
  UPDATE_CAR_BRANCH,
  UPDATE_CAR_ICON_PATH,
  UPDATE_CAR_FILE,
  UPDATE_CAR_TEL,
  UPDATE_CAR_IATACASS_NO,
  UPDATE_CAR_BACKUP,
  UPDATE_CAR_UPDATE_AT,
  UPDATE_CAR_REPRESENTATIVE_FLG,
  UPDATE_CAR_AUTHORITY_FLG,
  UPDATE_CAR_AIRPORT,
  UPDATE_CAR_COUNTRY,
  UPDATE_CAR_GSA_FLG,
  LIGHT_REGISTER_NEW_ACCOUNT,
  UPDATE_CAR_IS_DELETE_SHIPPING_ICON,
  UPDATE_CAR_PLAN,
  TOTAL_ACCOUNT,
} from '../../../../actions'

const LightCarCompanyInfo = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const history = useHistory()

  useEffect(() => {
    ;(async () => {
      // API取得
      const apiInfoArr = [
        {
          key: 'companyInfoArr',
          method: 'get',
          path: ApiPaths.MASTER_CAR.LIGHT_CAR_COMPANY_INFO,
        },
        { key: 'gsa', method: 'get', path: ApiPaths.MASTER_CAR.LIGHT_GSA },
        {
          key: 'planArr',
          method: 'get',
          path: ApiPaths.COMMON_API.LIGHT_CAR_PLAN,
        },
        {
          key: 'userCount',
          method: 'get',
          path: ApiPaths.COMMON_API.LIGHT_TOTAL_ACCOUNT,
        },
      ]
      const resObj = await CommonFunc.execApiAsync(apiInfoArr)
      const isApiSuccessful = CommonFunc.checkApiResponseObj(resObj, history)
      if (isApiSuccessful) {
        const res = resObj.companyInfoArr.data.results
        const gsa = resObj.gsa.data.results
        const plan = resObj.planArr.data.results
        const userCount = resObj.userCount.data.results

        dispatch({
          type: UPDATE_CAR_COMPANY_NAME,
          companyName: res.companyName,
          carrierNameId: res.carrierNameId,
          carriertwoCode: res.carriertwoCode,
        })
        dispatch({
          type: UPDATE_CAR_COMPANY_NAME,
          companyName: res.companyName,
          carrierNameId: res.carrierNameId,
          carriertwoCode: res.carriertwoCode,
        })
        dispatch({
          type: UPDATE_CAR_TWO_LETTER_CODE,
          carriertwoCode: res.carriertwoCode,
        })
        dispatch({
          type: UPDATE_CAR_POSTAL_CODE,
          postalCode: res.postalCode,
        })
        dispatch({
          type: UPDATE_CAR_REPRESENTATIVE_FLG,
          representativeFlg: res.representativeFlg,
        })
        dispatch({
          type: UPDATE_CAR_AUTHORITY_FLG,
          authorityFlg: res.authorityFlg,
        })
        dispatch({
          type: UPDATE_CAR_GSA_FLG,
          gsaFlg: gsa.gsaFlg,
        })
        dispatch({
          type: UPDATE_CAR_COMPANY_ADDRESS,
          address: res.address,
        })
        dispatch({
          type: UPDATE_CAR_DEPARTMENT,
          affilation: res.affilation,
        })
        dispatch({
          type: UPDATE_CAR_AIRPORT,
          airport: res.airport,
        })
        dispatch({
          type: UPDATE_CAR_COUNTRY,
          country: res.country,
        })
        dispatch({
          type: UPDATE_CAR_FIRST_NAME,
          firstName: res.firstName,
        })
        dispatch({
          type: UPDATE_CAR_LAST_NAME,
          lastName: res.lastName,
        })
        dispatch({
          type: UPDATE_CAR_POSITION,
          position: res.position,
        })
        dispatch({
          type: UPDATE_CAR_MAIL,
          mail: res.mail,
        })
        dispatch({
          type: UPDATE_CAR_TEL,
          tel: res.tel,
        })
        dispatch({
          type: UPDATE_CAR_IATACASS_NO,
          iataOrcassNumber: res.iataOrcassNumber,
        })
        dispatch({
          type: UPDATE_CAR_UPDATE_AT,
          updatedAt: res.updatedAt,
        })
        dispatch({
          type: UPDATE_CAR_BRANCH,
          cityName: res.cityName,
          cityId: res.cityId,
        })
        dispatch({
          type: UPDATE_CAR_ICON_PATH,
          icon: res.icon,
        })
        dispatch({
          type: UPDATE_CAR_IS_DELETE_SHIPPING_ICON,
          iconPath: res.iconPath,
        })
        dispatch({
          type: UPDATE_CAR_FILE,
          file: res.file,
        })
        dispatch({
          type: UPDATE_CAR_PLAN,
          carPlanId: res.carPlanId,
        })

        // 初回データをBackup
        dispatch({
          type: UPDATE_CAR_BACKUP,
          info: res,
        })

        dispatch({
          type: LIGHT_REGISTER_NEW_ACCOUNT.PLAN.INIT,
          planArr: plan,
        })
        dispatch({
          type: TOTAL_ACCOUNT.SET,
          userCount,
        })

        // 先頭にスクロール
        window.scrollTo(0, 0)
      }
    })()
  }, []) // eslint-disable-line

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <LayoutBox>
        <TitleLightCarCompanyInfo />
        <TableLightCarCompanyInfo />
        <ButtonLightCarCompanyInfo />
      </LayoutBox>
    </AppContext.Provider>
  )
}

export default LightCarCompanyInfo
