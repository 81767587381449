import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import TextField from '../../../common/text/TextField'
import AppContext from '../../../../contexts/AppContext'
import CommonValidation from '../../../common/function/CommonValidation'
import {
  UPDATE_JH_FIRST_NAME,
  UPDATE_JH_LAST_NAME,
  UPDATE_JH_FIRST_NAME_INPUTERR,
  UPDATE_JH_LAST_NAME_INPUTERR,
} from '../../../../actions'

const useStyles = makeStyles((theme) => ({
  head: theme.textBoldBLU,
  // eslint-disable-next-line camelcase
  text_blk: {
    ...theme.textNormalBLK,
    marginLeft: '1rem',
    wordWrap: 'break-word',
  },
  // eslint-disable-next-line camelcase
  text_blu: theme.textNormalBLU,
}))

const Representative = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { state, dispatch } = useContext(AppContext)
  const [inputErrs, setInputErrs] = useState({
    firstName: false,
    lastName: false,
  })
  const [helperTxts, setHelperTxts] = useState({ firstName: '', lastName: '' })
  const representativeFlg = props.representativeFlg

  const handleChangeLastName = (event) => {
    const value = event.target.value
    // 入力チェック
    const validationCheck = CommonValidation.name(value)
    const err = validationCheck.checkResult
    setInputErrs({ ...inputErrs, lastName: err })
    setHelperTxts({ ...helperTxts, lastName: validationCheck.errMsg })

    dispatch({
      type: UPDATE_JH_LAST_NAME,
      lastName: value,
    })

    // 入力エラーの場合、Savaボタン無効
    dispatch({
      type: UPDATE_JH_LAST_NAME_INPUTERR,
      inputErr: err,
    })
  }
  const handleChangeFirstName = (event) => {
    const value = event.target.value
    // 入力チェック
    const validationCheck = CommonValidation.name(value)
    const err = validationCheck.checkResult
    setInputErrs({ ...inputErrs, firstName: err })
    setHelperTxts({ ...helperTxts, firstName: validationCheck.errMsg })

    dispatch({
      type: UPDATE_JH_FIRST_NAME,
      firstName: value,
    })

    // 入力エラーの場合、Savaボタン無効
    dispatch({
      type: UPDATE_JH_FIRST_NAME_INPUTERR,
      inputErr: err,
    })
  }

  return (
    <div className={classes.formControl}>
      <Grid container>
        <Grid item md={10} xs={10}>
          <div className={classes.head}>{t('D301V0006')}</div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={4} xs={10}>
          <div className={classes.text_blu}>{t('D301V0007')}</div>
          {representativeFlg ? (
            <TextField
              id="firstName"
              error={inputErrs.firstName}
              helperText={t(helperTxts.firstName)}
              value={state.companyinfo.firstName.value}
              onChange={handleChangeFirstName}
            />
          ) : (
            <div className={classes.text_blk}>
              {state.companyinfo.firstName.value}
            </div>
          )}
        </Grid>
        <Grid item md={1} />
        <Grid item md={4} xs={10}>
          <div className={classes.text_blu}>{t('D301V0008')}</div>
          {representativeFlg ? (
            <TextField
              id="lastName"
              error={inputErrs.lastName}
              helperText={t(helperTxts.lastName)}
              value={state.companyinfo.lastName.value}
              onChange={handleChangeLastName}
            />
          ) : (
            <div className={classes.text_blk}>
              {state.companyinfo.lastName.value}
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  )
}

export default Representative
