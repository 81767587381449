import React, { useReducer, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import LayoutBox from '../../../common/layout/LayoutBox'
import AppContext from '../../../../contexts/AppContext'
import reducer, {
  initialState,
} from '../../../../reducers/master/lightFwdrAccountInfo'
import ApiPaths from '../../../../constants/ApiPaths'
import CommonFunc from '../../../common/function/CommonFunc'
import { SEARCH_SORT, TABLES, PLAN } from '../../../../actions'
import ConstantsLightFwdrAccountInfo from './ConstantsLightFwdrAccountInfo'
import TitleLightFwdrAccountInfo from './00TitleLightFwdrAccountInfo/TitleLightFwdrAccountInfo'
import SearchSortLightFwdrAccountInfo from './01SearchSortLightFwdrAccountInfo/SearchSortLightFwdrAccountInfo'
import TableLightFwdrAccountInfo from './02TableLightFwdrAccountInfo/TableLightFwdrAccountInfo'
import PagingLightFwdrAccountInfo from './03PagingLightFwdrAccountInfo/PagingLightFwdrAccountInfo'
import ButtonLightFwdrAccountInfo from './04ButtonLightFwdrAccountInfo/ButtonLightFwdrAccountInfo'
import TotalAndAddLightFwdrAccountInfo from './05TotalAndAddLightFwdrAccountInfo/TotalAndAddLightFwdrAccountInfo'

const LightFwdrAccountInfo = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const [state, dispatch] = useReducer(reducer, initialState)
  useEffect(() => {
    ;(async () => {
      const apiInfoArr = [
        {
          key: 'accountInfo',
          method: 'get',
          path: ApiPaths.MASTER_FWDR.LIGHT_ACCOUNT_INFO,
        },
        {
          key: 'planInfo',
          method: 'get',
          path: ApiPaths.COMMON_API.LIGHT_PLAN_DATA,
        },
      ]
      const resObj = await CommonFunc.execApiAsync(apiInfoArr)
      const isApiSuccessful = CommonFunc.checkApiResponseObj(resObj, history)

      if (isApiSuccessful) {
        const { accountInfo, planInfo } = resObj

        // ソートの初期値は定数の最初の値で固定
        const initSort = ConstantsLightFwdrAccountInfo.SORT_ITEM_ARR[0].sortTerm

        //SearchSortの初期値を設定
        dispatch({
          type: SEARCH_SORT.SET,
          search: '',
          sort: initSort,
          defaultStr: `${t('D301V0034')}`,
          defaultSearchWord: 'authority',
          defaultChecked: false,
        })
        // テーブルの初期値を設定
        dispatch({
          type: TABLES.SET,
          tableData: accountInfo.data.results,
          sort: initSort,
        })
        // プランの初期値を設定
        dispatch({
          type: PLAN.SET,
          planData: planInfo.data.results,
        })
      }
    })()
  }, []) //eslint-disable-line
  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <LayoutBox>
        <TitleLightFwdrAccountInfo />
        <SearchSortLightFwdrAccountInfo />
        <TableLightFwdrAccountInfo />
        <TotalAndAddLightFwdrAccountInfo />
        <PagingLightFwdrAccountInfo />
        <ButtonLightFwdrAccountInfo />
      </LayoutBox>
    </AppContext.Provider>
  )
}

export default LightFwdrAccountInfo
