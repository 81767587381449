import { SEARCH_SORT } from '../../actions'

/** 検索・ソート欄の初期表示 */
const initSearchSort = {
  search: '',
  sort: '',
  defaultChecked: true,
  defaultStr: '',
  defaultSearchWord: '',
}

/**
 * 検索ワード・ソートを保持
 * @param {Object} [state=initSearchSort] 検索ワード・ソートの初期値Object
 * @param {Object} action 下記の値を渡す
 * @param {String} action.search: 検索ワード
 * @param {String} action.sort: ソート
 * @param {Boolean} action.defaultChecked デフォルト欄の初期値
 * @param {String} action.defaultStr デフォルト欄に表示する文言
 * @param {String} action.defaultSearchWord デフォルト欄チェック時の検索ワード
 * @return {Object} 検索ワード・ソートのObject
 */
const SearchSort = (state = initSearchSort, action) => {
  const { type, ...rest } = action
  // 検索ワード・ソート情報を保存
  switch (type) {
    case SEARCH_SORT.SET:
      return { ...state, ...rest }
    default:
      return state
  }
}

export default SearchSort

export { initSearchSort }
