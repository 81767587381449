import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  title: theme.textBoldBLU,
  red: theme.textBoldRED,
  margin: {
    marginBottom: '1rem',
  },
}))

/**
 * @return {JSX} タイトルコンポーネント
 */
const TitleLightBookingRequest = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <Grid container justify="center">
      <Grid item md={10} xs={10}>
        <span className={classes.red}>*</span>
        <span className={classes.title}>{t('D004V0013')}</span>
      </Grid>
    </Grid>
  )
}

export default TitleLightBookingRequest
