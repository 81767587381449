import React from 'react'
import { useTranslation } from 'react-i18next'

import TableCell from '../TableCell'
import PrimaryButton from '../../buttons/PrimaryButton'

/**
 * @return {JSX} Checkボタンコンポーネント
 */
const CheckButtonCell = ({ ...rest }) => {
  const { t } = useTranslation()

  return (
    <TableCell>
      <PrimaryButton {...rest}>{t('D005V0061')}</PrimaryButton>
    </TableCell>
  )
}

export default CheckButtonCell
