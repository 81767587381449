import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import TextField from '../../../../common/text/TextField'
import { LIGHT_BOOKING_DETAILS } from '../../../../../actions'

/**
 * @param {Object} props 下記内容
 * @param {string} props.defaultValue 初期値
 * @param {string} props.valueName 値の名前
 * @param {Function} props.validation バリデーション
 * @param {Function} props.dispatch dispatch
 * @returns {JSX} MeasureAndWeightポップアップのテキストボックス
 */
const TextFieldEditPopup = ({
  defaultValue,
  valueName,
  validation,
  dispatch,
}) => {
  const { t } = useTranslation()
  const [helperText, setHelperText] = useState(validation(false))
  const [error, setError] = useState(false)

  /**
   * 値を更新する
   * @param {String} value 入力値
   * @returns {void} なし
   */
  const onChange = (value) => {
    const { checkResult, errMsg } = validation(value)
    setError(checkResult)
    setHelperText(checkResult ? errMsg : validation(false))
    dispatch({
      type: LIGHT_BOOKING_DETAILS.MEASURE_AND_WEIGHT_POPUP.EDIT,
      [valueName]: checkResult ? null : value,
    })
  }

  return (
    <TextField
      defaultValue={defaultValue}
      helperText={t(helperText)}
      error={error}
      onChange={(event) => onChange(event.target.value)}
    />
  )
}
TextFieldEditPopup.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  valueName: PropTypes.string.isRequired,
  validation: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
}
export default TextFieldEditPopup
