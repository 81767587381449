import { LIGHT_REGISTER_NEW_ACCOUNT } from '../../../actions'

const initFwdrPlan = {
  value: '',
  require: true,
}

/**
 * 選択した混載業者プランIDを更新
 * @param {Object} action - dispatchで受け取ったaction
 * @param {Object} state - state
 * @return {Object} - state
 */
const updateFwdrPlan = (action, state) => {
  const { value } = action
  return {
    ...state,
    value,
  }
}

/**
 * 混載業者プランを保持
 * @param {Object} [state=initFwdrPlan] - state
 * @param {Object} action - action
 * @return {Object} - state
 */
const FwdrPlan = (state = initFwdrPlan, action) => {
  switch (action.type) {
    case LIGHT_REGISTER_NEW_ACCOUNT.FWDR_PLAN.UPDATE:
      return updateFwdrPlan(action, state)
    default:
      return state
  }
}

export default FwdrPlan

export { initFwdrPlan }
