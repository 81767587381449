import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import AppContext from '../../../../../contexts/AppContext'
import NotifyCheckIcon from '../../../../common/icon/NotifyCheckIcon'

/**
 * 表の通知のチェックアイコン
 * @param {Object} props 下記の値を渡す
 * @param {Boolean} apiIndexNum APIの番号
 * @return {JSX} チェックアイコンコンポーネント
 */
const NotifyCheckIconBidMgmt = (props) => {
  const { apiIndexNum, ...restProps } = props

  // 通知有りの一覧をstateから取得
  const { state } = useContext(AppContext)
  const { notifyArr } = state.Tables
  const hasNotify = notifyArr.includes(apiIndexNum)

  return <NotifyCheckIcon {...restProps} hasNotify={hasNotify} />
}

NotifyCheckIconBidMgmt.propTypes = {
  apiIndexNum: PropTypes.number.isRequired,
}

export default NotifyCheckIconBidMgmt
