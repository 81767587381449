import { TABLES } from '../../../actions'
import CommonFunc from '../../../components/common/function/CommonFunc'
import CommonCalc from '../../../components/common/function/CommonCalc'
import ConstantsLightAirlineBookingList from '../../../components/pages/airline/LightAirlineBookingList/ConstantsLightAirlineBookingList'

/** @type {Object} 初期表示のテーブル */
const initTableData = {
  initData: [],
  showData: [[]],
  isNoResult: true,
  gsa: false,
}

/**
 * テーブルに表示する形式に変更
 * @param {Array} tableData - apiから受け取ったテーブルデータ
 * @return {Array} - テーブルに表示する形式のデータ
 */
const editData = (tableData) =>
  tableData.map((data) => {
    const {
      orgAirportCode,
      dstAirportCode,
      pcs,
      grossWgt,
      volume,
      shc,
      ...rest
    } = data

    // Pcs, Wgt, Volにカンマを付ける
    const addCommaPcs = CommonCalc.addComma(pcs)
    const addCommaWgt = CommonCalc.addComma(grossWgt, true)
    const addCommaVol = CommonCalc.addComma(volume, true)

    /** @type {String} ORG/DST欄に表示する文字列 */
    const orgDstStr = `${orgAirportCode}/${dstAirportCode}`

    /** @type {String} Pcs/Wgt/Vol欄に表示する文字列 */
    const cargoStr = `${addCommaPcs}pcs/${addCommaWgt}kg/${addCommaVol}m3`

    /** @type {String} SHCを/で繋げた文字列(検索用) */
    const shcStr = shc.join('/ ') //表示に合わせるため、/の後ろに半角スペース付けています

    return {
      ...rest,
      orgAirportCode,
      dstAirportCode,
      pcs,
      grossWgt,
      volume,
      orgDstStr,
      cargoStr,
      shc,
      shcStr,
    }
  })

/**
 * テーブルデータを保持
 * @param {Object} action action
 * @param {Object[]} action.tableData 登録するテーブルデータ
 * @param {String} [action.search=''] 検索ワード
 * @param {String} [action.sort=''] 並び替え条件
 * @param {String} [action.defaultSearchWord=''] デフォルト欄用の検索ワード
 * @param {String} [action.gsa=state.gsa] GSAフラグ
 * @param {Object} state state
 * @returns {Object} テーブルデータ
 */
const setTableData = (action, state) => {
  const {
    tableData,
    search = '',
    sort = '',
    defaultSearchWord = '',
    gsa = state.gsa,
  } = action
  const { PAGE_AMOUNT, GSA_SORT_ITEM_ARR, AIRLINE_SORT_ITEM_ARR } =
    ConstantsLightAirlineBookingList
  // 並び替え条件の配列
  const sortItemArr = gsa ? GSA_SORT_ITEM_ARR : AIRLINE_SORT_ITEM_ARR

  // sort名から並び替えの対象となるkeyを取得
  const { sortKey } = sortItemArr.find(({ sortTerm }) => sortTerm === sort)

  /** @type {Array} 表示用に編集後のデータ */
  const editedData = tableData ? editData(tableData) : state.initData

  // データを検索して結果をページごとに分割する
  const { showData, initData } = CommonFunc.searchSortDefaultData(
    editedData,
    defaultSearchWord,
    search,
    sort,
    sortKey,
    PAGE_AMOUNT
  )

  /** @type {Boolean} NoResult判定 */
  const isNoResult = showData[0].length <= 0

  return {
    ...state,
    showData,
    initData,
    isNoResult,
    gsa,
  }
}

/**
 * テーブルデータを保持
 * @param { Object } [state = initTableData] テーブルデータ
 * @param { Object } action action
 * @return { Object } テーブルデータ
 */
const Tables = (state = initTableData, action) => {
  switch (action.type) {
    case TABLES.SET:
      return setTableData(action, state)
    default:
      return state
  }
}

export default Tables

export { initTableData }
