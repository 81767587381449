// Display + 画面名
const DisplayAirlineBidRates = {
  // 画面ID + 文言ID
  D214V0001: {
    en: 'BID RATES',
    ja: '入札率',
  },
  D214V0002: {
    en: 'Key words',
    ja: 'キーワード',
  },
  D214V0003: {
    en: 'Lately',
    ja: '最近',
  },
  D214V0004: {
    en: 'Validity',
    ja: '有効',
  },
  D214V0005: {
    en: 'Company',
    ja: '会社',
  },
  D214V0006: {
    en: 'FWDR',
    ja: '混載業者',
  },
  D214V0007: {
    en: 'Deadline',
    ja: '締め切り',
  },
  D214V0008: {
    en: 'Days left',
    ja: '残り日数',
  },
  D214V0009: {
    en: 'Status',
    ja: '状態',
  },
  D214V0010: {
    en: 'Details',
    ja: '詳細',
  },
  D214V0011: {
    en: 'September 1, 2020',
    ja: '2020年9月1日',
  },
  D214V0012: {
    en: 'August 1, 2020',
    ja: '2020年8月1日',
  },
  D214V0013: {
    en: 'XXXXXX Corporation',
    ja: 'XXXXXX Corporation',
  },
  D214V0014: {
    en: 'Panasonic Corporation',
    ja: 'パナソニック株式会社',
  },
  D214V0015: {
    en: 'Undisclosed',
    ja: '非公開',
  },
  D214V0016: {
    en: 'DZK/KNJ/EIK/CEV/DSV/UPS',
    ja: 'DZK/KNJ/EIK/CEV/DSV/UPS',
  },
  D214V0017: {
    en: 'NEC/TLK/KWE/HEI/NNR',
    ja: 'NEC/TLK/KWE/HEI/NNR',
  },
  D214V0018: {
    en: '-',
    ja: '-',
  },
  D214V0019: {
    en: 'July 31, 2020',
    ja: '2020年7月31日',
  },
  D214V0020: {
    en: '3Days',
    ja: '3日',
  },
  D214V0021: {
    en: 'New BID',
    ja: '新しい入札',
  },
  D214V0022: {
    en: 'was updated',
    ja: '更新されました',
  },
  D214V0023: {
    en: 'Requesting',
    ja: '要求',
  },
  D214V0024: {
    en: 'Under contact',
    ja: '連絡中',
  },
  D214V0025: {
    en: 'Decline',
    ja: '辞退',
  },
  D214V0026: {
    en: 'Check',
    ja: '確認',
  },
  D214V0027: {
    en: 'Done',
    ja: '完了',
  },
  D214V0028: {
    en: 'to',
    ja: '～',
  },
  D214V0029: {
    en: 'August 31, 2020',
    ja: '2020年8月31日',
  },
  D214V0030: {
    en: 'January 31, 2020',
    ja: '2020年1月31日',
  },
}
// Display + 画面名
export default DisplayAirlineBidRates
