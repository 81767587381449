import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'

import ApiPaths from '../../../../../constants/ApiPaths'
import AppContext from '../../../../../contexts/AppContext'
import SaveAndBack from '../../../../common/buttons/SaveAndBack'
import CommonFunc from '../../../../common/function/CommonFunc'
import Common from '../../../../../constants/Common'
import Paths from '../../../../../constants/Paths'
import ConstantsLightCarAccountInfo from '../ConstantsLightCarAccountInfo'
import { TABLES, SEARCH_SORT, PAGING } from '../../../../../actions'

const useStyles = makeStyles(() => ({
  buttons: {
    justifyContent: 'center',
    marginBottom: 30,
    marginTop: 30,
  },
  down: {
    marginBottom: 30,
    margin: 'auto',
  },
}))

const ButtonLightCarAccountInfo = () => {
  const classes = useStyles()
  const history = useHistory()
  const [saveComp, setSaveComp] = useState(false)
  const [errCode, setErrCode] = useState(Common.ERROR_CODE.NORMAL)
  const [errEmails, setErrEmails] = useState('')
  const { state, dispatch } = useContext(AppContext)

  const setAccountInfoData = (res) => {
    // ソートの初期値は定数の最初の値で固定
    const initSort = ConstantsLightCarAccountInfo.SORT_ITEM_ARR[0].sortTerm
    dispatch({
      type: TABLES.SET,
      tableData: res.resData,
      sort: initSort,
    })
    // スクロールを先頭に戻す
    window.scrollTo(0, 0)
    dispatch({
      type: SEARCH_SORT.SET,
      search: '',
      defaultChecked: false,
    })
    dispatch({
      type: PAGING.SET,
      page: 0,
    })
  }

  // データ変更ありなし判定
  const chkInputChg = () => {
    // 判定用の不要なデータを削除する
    const editDataArr = state.Tables.editData.map((data) => {
      // eslint-disable-next-line no-unused-vars
      const { id, ...rest } = data
      return { ...rest }
    })

    const edit = JSON.stringify(editDataArr)
    const init = JSON.stringify(state.Tables.apiDataArr)
    if (edit !== init) {
      return true
    }
    return false
  }

  // 保存完了後の処理
  const saveComplete = (res) => {
    setSaveComp(false)
    if (res) {
      setAccountInfoData(res)
    }
  }

  // 保存処理
  const saveData = async () => {
    const accountArray = state.Tables.editData
    const accountEditDatas = accountArray.filter((edit, index) => {
      if (!edit.userId) {
        return !edit.delFlg
      }
      return (
        JSON.stringify(edit) !== JSON.stringify(state.Tables.apiDataArr[index])
      )
    })
    const req = {
      userDatas: accountEditDatas,
    }
    // API実行
    const apiInfoArr = [
      {
        key: 'resData',
        method: 'post',
        path: ApiPaths.MASTER_CAR.LIGHT_ACCOUNT_INFO,
        req,
      },
    ]
    const resObj = await CommonFunc.execApiAsync(apiInfoArr)
    const isApiSuccessful = CommonFunc.checkApiResponseObj(
      resObj,
      history,
      resObj.resData.data.info.ErrorEmails
    )
    setErrCode(resObj.resData.data.info.ErrorCode)
    setErrEmails(resObj.resData.data.info.ErrorEmails)
    if (isApiSuccessful) {
      saveComplete(resObj.resData.data.results)
      setSaveComp(true)
    }
  }

  // Backコンポーネントに渡したいものをまとめる
  const backFactors = {
    path: Paths.MASTER.CAR_MASTER_TOP,
    inputchg: chkInputChg(),
    clickevent: '',
  }

  // Saveコンポーネントに渡したいものをまとめる
  const saveFactors = {
    path: Paths.MASTER.LIGHT_CAR_ACCOUNT_INFO,
    saveevent: saveData,
    comp: saveComp,
    compevent: saveComplete,
    errcode: errCode,
    erremail: errEmails,
  }

  return (
    <Grid
      container
      justify="center"
      direction="column"
      alignItems="center"
      className={classes.down}
    >
      <Grid item md={10} xs={10} className={classes.buttons}>
        <SaveAndBack backFactors={backFactors} saveFactors={saveFactors} />
      </Grid>
    </Grid>
  )
}

export default ButtonLightCarAccountInfo
