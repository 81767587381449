import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

import LayoutBox from '../../../common/layout/LayoutBox'
import Theme from '../../../common/theme/Theme'
import Paths from '../../../../constants/Paths'
import MasterShipTopButtons from './MasterShipTopButtons'

const useStyles = makeStyles(() => ({
  root: Theme.topInner,
  outer: Theme.topOuter,
}))

const MasterShipMasterTop = () => {
  const classes = useStyles()
  const paths = {
    companyInfoG: Paths.MASTER.SHIP_COMPANY_INFOG,
    companyInfoM: Paths.MASTER.SHIP_COMPANY_INFOM,
    myProfile: Paths.MASTER.SHIP_MY_PROFILE,
    accountInfo: Paths.MASTER.SHIP_ACCOUNT_INFO,
    consigneeInfo: Paths.MASTER.SHIP_CONSIGNEE_INFO,
  }

  return (
    <LayoutBox>
      <div className={classes.outer}>
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.root}
        >
          <Grid item md={10} xs={10}>
            <MasterShipTopButtons paths={paths} />
          </Grid>
        </Grid>
      </div>
    </LayoutBox>
  )
}

export default MasterShipMasterTop
