import { LIGHT_REGISTER_NEW_ACCOUNT } from '../../../actions'

const initCarPlan = {
  value: '',
  require: true,
}

/**
 * 選択した航空会社プランIDを更新
 * @param {Object} action - dispatchで受け取ったaction
 * @param {Object} state - state
 * @return {Object} - state
 */
const updateCarPlan = (action, state) => {
  const { value } = action
  return {
    ...state,
    value,
  }
}

/**
 * 航空会社プランを保持
 * @param {Object} [state=initCarPlan] - state
 * @param {Object} action - action
 * @return {Object} - state
 */
const CarPlan = (state = initCarPlan, action) => {
  switch (action.type) {
    case LIGHT_REGISTER_NEW_ACCOUNT.CAR_PLAN.UPDATE:
      return updateCarPlan(action, state)
    default:
      return state
  }
}

export default CarPlan

export { initCarPlan }
