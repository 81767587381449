import React, { useReducer, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import LayoutBox from '../../../common/layout/LayoutBox'
import AppContext from '../../../../contexts/AppContext'
import reducer, {
  initialState,
} from '../../../../reducers/master/lightCarAccountInfo'
import ApiPaths from '../../../../constants/ApiPaths'
import CommonFunc from '../../../common/function/CommonFunc'
import { SEARCH_SORT, TABLES, GSA, PLAN } from '../../../../actions'
import ConstantsLightCarAccountInfo from './ConstantsLightCarAccountInfo'
import TitleLightCarAccountInfo from './00TitleLightCarAccountInfo/TitleLightCarAccountInfo'
import SearchSortLightCarAccountInfo from './01SearchSortLightCarAccountInfo/SearchSortLightCarAccountInfo'
import TableLightCarAccountInfo from './02TableLightCarAccountInfo/TableLightCarAccountInfo'
import PagingLightCarAccountInfo from './03PagingLightCarAccountInfo/PagingLightCarAccountInfo'
import ButtonLightCarAccountInfo from './04ButtonLightCarAccountInfo/ButtonLightCarAccountInfo'
import TotalAndAddLightCarAccountInfo from './05TotalAndAddLightCarAccountInfo/TotalAndAddLightCarAccountInfo'

const LightCarAccountInfo = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const [state, dispatch] = useReducer(reducer, initialState)
  useEffect(() => {
    ;(async () => {
      const apiInfoArr = [
        {
          key: 'accountInfo',
          method: 'get',
          path: ApiPaths.MASTER_CAR.LIGHT_ACCOUNT_INFO,
        },
        { key: 'gsa', method: 'get', path: ApiPaths.MASTER_CAR.LIGHT_GSA },
        {
          key: 'planInfo',
          method: 'get',
          path: ApiPaths.COMMON_API.LIGHT_PLAN_DATA,
        },
      ]
      const resObj = await CommonFunc.execApiAsync(apiInfoArr)
      const isApiSuccessful = CommonFunc.checkApiResponseObj(resObj, history)

      if (isApiSuccessful) {
        const { accountInfo, gsa, planInfo } = resObj
        // ソートの初期値は定数の最初の値で固定
        const initSort = ConstantsLightCarAccountInfo.SORT_ITEM_ARR[0].sortTerm

        //SearchSortの初期値を設定
        dispatch({
          type: SEARCH_SORT.SET,
          search: '',
          sort: initSort,
          defaultStr: `${t('D301V0034')}`,
          defaultSearchWord: 'authority',
          defaultChecked: false,
        })
        // テーブルの初期値を設定
        dispatch({
          type: TABLES.SET,
          tableData: accountInfo.data.results.results,
          sort: initSort,
        })
        // テーブルの初期値を設定
        dispatch({
          type: GSA.SET,
          gsa: gsa.data.results.gsaFlg,
        })
        // プランの初期値を設定
        dispatch({
          type: PLAN.SET,
          planData: planInfo.data.results,
        })
      }
    })()
  }, []) //eslint-disable-line

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <LayoutBox>
        <TitleLightCarAccountInfo />
        <SearchSortLightCarAccountInfo />
        <TableLightCarAccountInfo />
        <TotalAndAddLightCarAccountInfo />
        <PagingLightCarAccountInfo />
        <ButtonLightCarAccountInfo />
      </LayoutBox>
    </AppContext.Provider>
  )
}

export default LightCarAccountInfo
