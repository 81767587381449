import React from 'react'
import PropTypes from 'prop-types'

import TableCell from '../../../../common/table/TableCell'

/**
 * @param {String} { displayStr } 表示したい文字列
 * @return {JSX} 表の通常のTableCell
 */
const TableCellBidMgmt = ({ displayStr }) => {
  return <TableCell>{displayStr}</TableCell>
}

TableCellBidMgmt.propTypes = {
  displayStr: PropTypes.string.isRequired,
}

export default TableCellBidMgmt
