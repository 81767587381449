// Display + 画面名
const DisplayFwdrDocuments = {
  // 画面ID + 文言ID
  D118V0001: {
    en: 'Docs Prep.',
    ja: '書類作成',
  },
  D118V0002: {
    en: 'Documents',
    ja: '書類',
  },
  D118V0003: {
    en: 'Confirm',
    ja: '確認',
  },
  D118V0004: {
    en: 'HAWB&MFST',
    ja: 'HAWB&MFST',
  },
  D118V0005: {
    en: 'Accounting Information',
    ja: '会計情報',
  },
  D118V0006: {
    en: "ex. 'FREIGHT PREPAID'",
    ja: "例： 'FREIGHT PREPAID'",
  },
  D118V0007: {
    en: 'Enter Accounting Information',
    ja: '会計情報を入力',
  },
  D118V0008: {
    en: 'Add',
    ja: '追加',
  },
  D118V0009: {
    en: 'Currency',
    ja: '通貨',
  },
  D118V0010: {
    en: 'Select',
    ja: '選択',
  },
  D118V0011: {
    en: 'Handling Information',
    ja: '取扱情報',
  },
  D118V0012: {
    en: 'Enter Handling Information',
    ja: '取扱情報を入力',
  },
  D118V0013: {
    en: 'ex. PLEASE NOTIFY CONSIGNEE IMMEDIATELY ON ARRIVAL OF GOODS',
    ja: '例：PLEASE NOTIFY CONSIGNEE IMMEDIATELY ON ARRIVAL OF GOODS',
  },
  D118V0014: {
    en: 'Nature and Quantity of Goods',
    ja: '商品の性質と量',
  },
  D118V0015: {
    en: '(Incl. Dimensions or Volume)',
    ja: '（寸法または体積を含む）',
  },
  D118V0016: {
    en: 'ex. Used Auto Parts',
    ja: '例：Used Auto Parts',
  },
  D118V0017: {
    en: 'ex. 3pcs-50x50x50cm',
    ja: '例：3pcs-50x50x50cm',
  },
  D118V0018: {
    en: 'ex. 0.375m3',
    ja: '例： 0.375m3',
  },
  D118V0019: {
    en: 'Enter Goods Details',
    ja: '商品詳細を入力',
  },
  D118V0020: {
    en: 'MAWB',
    ja: 'MAWB',
  },
  D118V0021: {
    en: 'ex. Dangerous Goods as per attached DGD -Cargo Aircraft Only',
    ja: '例：Dangerous Goods as per attached DGD -Cargo Aircraft Only',
  },
  D118V0022: {
    en: 'Nature and Quantity of Goods (Incl. Dimensions of Volume)',
    ja: '商品の性質と量（容量の寸法を含む）',
  },
  D118V0023: {
    en: 'ex. Used Auto Parts, Consolidation as per attached',
    ja: '例：Used Auto Parts, Consolidation as per attached',
  },
  D118V0024: {
    en: 'ex. Frozen Fish',
    ja: '例：Frozen Fish',
  },
  D118V0025: {
    en: 'UN1845',
    ja: 'UN1845',
  },
  D118V0026: {
    en: 'Carbon Dioxide, solid',
    ja: 'Carbon Dioxide, solid',
  },
  D118V0027: {
    en: '2 x 40kg',
    ja: '2 x 40kg',
  },
  D118V0028: {
    en: 'ex.',
    ja: '例：',
  },
  D118V0029: {
    en: 'ex. Not Restricted',
    ja: '例：Not Restricted',
  },
  D118V0030: {
    en: 'Signature',
    ja: '署名',
  },
  D118V0031: {
    en: 'DGD',
    ja: 'DGD',
  },
  D118V0032: {
    en: 'This shipment is within the limitations prescribed for: (delete non-applicabled)',
    ja: 'この発送は、次の規定された制限内です。（該当しないものを削除）',
  },
  D118V0033: {
    en: 'CARGO AIRCRAFT ONLY',
    ja: '貨物機のみ',
  },
  D118V0034: {
    en: 'PASSENGERS AND CARGO AIRCRAFT',
    ja: '乗客および貨物航空機',
  },
  D118V0035: {
    en: 'Shipment type (delete non-applicabled)',
    ja: '配送タイプ（該当しないものを削除）',
  },
  D118V0036: {
    en: 'NON-RADIOACTIVE',
    ja: '非放射性',
  },
  D118V0037: {
    en: 'RADIOACTIVE',
    ja: '放射性',
  },
  D118V0038: {
    en: 'Cargo Details',
    ja: '貨物詳細',
  },
  D118V0039: {
    en: 'UN or ID NO.',
    ja: 'UNまたはID番号',
  },
  D118V0040: {
    en: 'Proper Shipping Name',
    ja: '適切な輸送名',
  },
  D118V0041: {
    en: 'Authorization',
    ja: '認可',
  },
  D118V0042: {
    en: 'Enter ex. SP',
    ja: 'SPを入力',
  },
  D118V0043: {
    en: 'Edit',
    ja: '編集',
  },
  D118V0044: {
    en: 'Quantity and type of packing',
    ja: '梱包の数量とタイプ',
  },
  D118V0045: {
    en: 'Pcs.',
    ja: '個',
  },
  D118V0046: {
    en: 'Type of content',
    ja: '内容の種類',
  },
  D118V0047: {
    en: 'Steel drum',
    ja: 'スチールドラム',
  },
  D118V0048: {
    en: 'Plastic Jerricans',
    ja: 'プラスチック製のジェリカン',
  },
  D118V0049: {
    en: 'Vol.',
    ja: '容量',
  },
  D118V0050: {
    en: 'Unit',
    ja: '単位',
  },
  D118V0051: {
    en: 'L',
    ja: 'L',
  },
  D118V0052: {
    en: 'kg',
    ja: 'kg',
  },
  D118V0053: {
    en: 'Packing type',
    ja: '梱包のタイプ',
  },
  D118V0054: {
    en: 'case',
    ja: '入物',
  },
  D118V0055: {
    en: 'Additional Handling Information',
    ja: '取扱情報の追加',
  },
  D118V0056: {
    en: '24-hours emergency telephone number:',
    ja: '24時間対応の緊急電話番号：',
  },
  D118V0057: {
    en: '(including country and area code)',
    ja: '（国および市外局番を含む）',
  },
  D118V0058: {
    en: 'ex. Air Crew Protective Breathing Equipment (Smoke hood) in accordance with Special provision A144.',
    ja: '例： Air Crew Protective Breathing Equipment (Smoke hood) in accordance with Special provision A144.',
  },
  D118V0059: {
    en: 'ex. This shipment may be carried on passenger aircraft outside of XX jurisdiction.',
    ja: '例： This shipment may be carried on passenger aircraft outside of XX jurisdiction.',
  },
  D118V0060: {
    en: 'ex. President&CEO Norikuni Inaba +81-72-220-5143',
    ja: '例：President&CEO Norikuni Inaba +81-72-220-5143',
  },
  D118V0061: {
    en: 'Name of Signatory',
    ja: '署名者の名前',
  },
  D118V0062: {
    en: 'Enter your name',
    ja: '名前を入力',
  },
  D118V0063: {
    en: 'Enter your title or dept.',
    ja: '役職または部署を入力',
  },
  D118V0064: {
    en: 'Label&Mark',
    ja: 'ラベル＆マーク',
  },
  D118V0065: {
    en: 'Quote',
    ja: '見積もり',
  },
  D118V0066: {
    en: 'Origin Charges',
    ja: '原産地料金',
  },
  D118V0067: {
    en: 'Rate',
    ja: '割合',
  },
  D118V0068: {
    en: 'Per',
    ja: '単位',
  },
  D118V0069: {
    en: 'Amount',
    ja: '料金',
  },
  D118V0070: {
    en: 'Pickup charge *From: 1-8-3 Takakuradai, Minami-ku, Sakai-shi, Osaka 590-0117 Japan',
    ja: '送迎料金 *日本大阪府大阪市堺市南区高倉台1-8-3、590-0117から',
  },
  D118V0071: {
    en: 'Packing Fee *Dim (LxWxH): XXX cm x XXX cm',
    ja: '梱包料金 *寸法(LxWxH): XXXcm x XXXcm x XXXcm',
  },
  D118V0072: {
    en: 'Airline Registration Fee',
    ja: '航空会社登録料',
  },
  D118V0073: {
    en: 'Airline Handling Fee',
    ja: '航空会社手数料',
  },
  D118V0074: {
    en: 'House Air Waybill Data Transmission Fee',
    ja: 'HAWBデータ送信料',
  },
  D118V0075: {
    en: 'Labeling Fee',
    ja: 'ラベル貼付費',
  },
  D118V0076: {
    en: 'Inspection Fee',
    ja: '検査料',
  },
  D118V0077: {
    en: 'XXX',
    ja: 'XXX',
  },
  D118V0078: {
    en: 'XX,XXX',
    ja: 'XX,XXX',
  },
  D118V0079: {
    en: 'Contact later',
    ja: '後で連絡する',
  },
  D118V0080: {
    en: 'Shipment',
    ja: '発送',
  },
  D118V0081: {
    en: 'HAWB',
    ja: 'HAWB',
  },
  D118V0082: {
    en: 'Subtotal',
    ja: '小計',
  },
  D118V0083: {
    en: 'Destination Charges',
    ja: '到着地料金',
  },
  D118V0084: {
    en: 'Air Handling Fee',
    ja: '航空運賃',
  },
  D118V0085: {
    en: 'Import Custom Clearance Charges',
    ja: '輸入通関料金',
  },
  D118V0086: {
    en: 'Transfer Fee',
    ja: '転送料',
  },
  D118V0087: {
    en: 'Handover Fee *Over X,XXXkg',
    ja: '引渡手数料*XXXkg 以上',
  },
  D118V0088: {
    en: 'Collect Charges Fee',
    ja: '集荷料金',
  },
  D118V0089: {
    en: 'Warehouse Storage Fee',
    ja: '倉庫保管料',
  },
  D118V0090: {
    en: 'Delivery Charge *To: 327 St. Margarets Street, Charlotte, NC 28205 United States of America',
    ja: '配送料 *To: 327 St. Margarets Street, Charlotte, NC 28205 United States of Americaまで',
  },
  D118V0091: {
    en: 'Total',
    ja: '合計',
  },
  D118V0092: {
    en: 'Delete',
    ja: '削除',
  },
  D118V0093: {
    en: 'Cancel',
    ja: 'キャンセル',
  },
  D118V0094: {
    en: 'OK',
    ja: 'OK',
  },
  D118V0095: {
    en: 'Back',
    ja: '戻る',
  },
  D118V0096: {
    en: 'Marking',
    ja: 'マーキング',
  },
  D118V0097: {
    en: 'Cargo Label',
    ja: '貨物ラベル',
  },
  D118V0098: {
    en: 'Label',
    ja: 'ラベル',
  },
  D118V0099: {
    en: 'House AWB & MFST',
    ja: 'House AWB & MFST',
  },
  D118V0100: {
    en: 'PackingInst',
    ja: '包装基準',
  },
  D118V0101: {
    en: 'Quantity',
    ja: '量',
  },
  D118V0102: {
    en: 'Additional Type',
    ja: '追記事項',
  },
  D118V0103: {
    en: 'Valid until',
    ja: '有効期限',
  },
  D118V0104: {
    en: 'Freight Charges',
    ja: '運送費用',
  },
  D118V0105: {
    en: 'Terms and Conditions',
    ja: '利用規約',
  },
  D118V0106: {
    en: 'Declared Value for Carriage',
    ja: '運送申告価格',
  },
  D118V0107: {
    en: 'Declared Value for Custom',
    ja: '税関申告価格',
  },
  D118V0108: {
    en: 'Please enter at the specified rate.',
    ja: '指定されたレートで入力してください。',
  },
  D118V0109: {
    en: 'Weight',
    ja: '重量',
  },
  D118V0110: {
    en: 'HTS Code',
    ja: 'HTSコード',
  },
  D118V0111: {
    en: 'Class or div.',
    ja: 'クラス',
  },
  D118V0112: {
    en: 'Packing Group',
    ja: '梱包グループ',
  },
  D118V0113: {
    en: 'Enter Technical Name',
    ja: 'テクニカルネームを入力',
  },
  D118V0114: {
    en: 'Nature of contents',
    ja: '放射性物質内容',
  },
  D118V0115: {
    en: 'Other Charges',
    ja: 'その他手数料',
  },
  D118V0116: {
    en: 'Due Agent',
    ja: '混載業者用',
  },
  D118V0117: {
    en: 'Due Carrier',
    ja: '航空会社用',
  },
  D118V0118: {
    en: 'Charges Name',
    ja: '手数料詳細',
  },
  D118V0119: {
    en: 'Up to 12 can be registered.',
    ja: 'その他手数料は合計12個まで登録可能です。',
  },
}
// Display + 画面名
export default DisplayFwdrDocuments
