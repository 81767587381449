// Display + 画面名
const DisplayAirlineAwbDetails = {
  // 画面ID + 文言ID
  D207V0001: {
    en: 'AWB DETAILS',
    ja: 'AWB詳細',
  },
  D207V0002: {
    en: 'MAWB No.',
    ja: 'MAWB No.',
  },
  D207V0003: {
    en: 'Issued by',
    ja: '発行元',
  },
  D207V0004: {
    en: 'Issued date',
    ja: '発行日',
  },
  D207V0005: {
    en: 'Status',
    ja: '状態',
  },
  D207V0006: {
    en: '933-XXXX XXXX',
    ja: '933-XXXX XXXX',
  },
  D207V0007: {
    en: 'KZ - Nippon Cargo Airlines',
    ja: 'KZ - 日本貨物航空',
  },
  D207V0008: {
    en: 'September 1, 2020',
    ja: '2020年9月1日',
  },
  D207V0009: {
    en: 'Space Confirmed',
    ja: 'スペースが確認されました',
  },
  D207V0010: {
    en: 'ORG/DST',
    ja: '出発/到着',
  },
  D207V0011: {
    en: 'NRT/ANC/ORD/DFW',
    ja: 'NRT/ANC/ORD/DFW',
  },
  D207V0012: {
    en: 'Shipper',
    ja: '荷送人',
  },
  D207V0013: {
    en: 'Panasonic Corporation',
    ja: 'パナソニック株式会社',
  },
  D207V0014: {
    en: '1006, Oaza Kadoma, Kadoma-shi, Osaka 571-8501, Japan',
    ja: '571-8501大阪府門真市大字門真1006',
  },
  D207V0015: {
    en: 'Tel.',
    ja: '電話番号:',
  },
  D207V0016: {
    en: '+81-6-6908-1121',
    ja: '+81-6-6908-1120',
  },
  D207V0017: {
    en: 'Attn:',
    ja: '対象:',
  },
  D207V0018: {
    en: 'Konosuke Matsushita',
    ja: '松下幸之助',
  },
  D207V0019: {
    en: 'Consignee',
    ja: '荷受人',
  },
  D207V0020: {
    en: 'Panasonic R&D Company of America',
    ja: 'アメリカのパナソニックR＆Dカンパニー',
  },
  D207V0021: {
    en: '205 Ravendale Dr, Mountain View, CA 94043, United States',
    ja: '205 Ravendale Dr, Mountain View, CA 94043, United States',
  },
  D207V0022: {
    en: '+1-408-861-3900',
    ja: '+1-408-861-3899',
  },
  D207V0023: {
    en: 'James Collins',
    ja: 'ジェームズコリンズ',
  },
  D207V0024: {
    en: 'FWDR',
    ja: '混載業者',
  },
  D207V0025: {
    en: 'Yusen Logistics - NRT',
    ja: '郵船ロジスティクス - NRT',
  },
  D207V0026: {
    en: 'Pcs./Wgt./Vol.',
    ja: '個/重量/体積',
  },
  D207V0027: {
    en: 'XXXpcs./XXX.XXkg/XX.XXm3',
    ja: 'XXXpcs./XXX.XXkg/XX.XXm3',
  },
  D207V0028: {
    en: 'Chargeable Wgt.',
    ja: '搭載可能重量',
  },
  D207V0029: {
    en: 'XXX.XXkg',
    ja: 'XXX.XXkg',
  },
  D207V0030: {
    en: 'Description',
    ja: '説明',
  },
  D207V0031: {
    en: 'Consolidation',
    ja: '統合',
  },
  D207V0032: {
    en: 'Hazardous',
    ja: '危険',
  },
  D207V0033: {
    en: '-',
    ja: '-',
  },
  D207V0034: {
    en: 'SHC',
    ja: 'SHC',
  },
  D207V0035: {
    en: 'COL/ICE',
    ja: 'COL/ICE',
  },
  D207V0036: {
    en: 'Routing',
    ja: 'ルーティング',
  },
  D207V0037: {
    en: 'ORG',
    ja: '出発',
  },
  D207V0038: {
    en: 'DST',
    ja: '行先',
  },
  D207V0039: {
    en: 'Carrier',
    ja: 'キャリア',
  },
  D207V0040: {
    en: 'Flight#',
    ja: 'フライト＃',
  },
  D207V0041: {
    en: 'Pcs.',
    ja: '個',
  },
  D207V0042: {
    en: 'Wgt.',
    ja: '重量',
  },
  D207V0043: {
    en: 'Vol.',
    ja: '容量',
  },
  D207V0044: {
    en: 'Allotment',
    ja: '割当て',
  },
  D207V0045: {
    en: 'rate',
    ja: 'レート',
  },
  D207V0046: {
    en: 'Amount',
    ja: '料金',
  },
  D207V0047: {
    en: 'UA',
    ja: 'UA',
  },
  D207V0048: {
    en: 'XXX/XXSEP',
    ja: 'XXX/XXSEP',
  },
  D207V0049: {
    en: '5',
    ja: '5',
  },
  D207V0050: {
    en: '500.00',
    ja: '500.00',
  },
  D207V0051: {
    en: '5.00',
    ja: '5.00',
  },
  D207V0052: {
    en: 'KEEP COL DRY ICE 30KG',
    ja: 'KEEP COL DRY ICE 30KG',
  },
  D207V0053: {
    en: 'Edit',
    ja: '編集',
  },
  D207V0054: {
    en: 'SplitAWB',
    ja: 'AWBを分ける',
  },
  D207V0055: {
    en: 'Special Notes',
    ja: '特記事項',
  },
  D207V0056: {
    en: 'HMFST',
    ja: 'HMFST',
  },
  D207V0057: {
    en: 'Dim.',
    ja: 'Dim',
  },
  D207V0058: {
    en: 'Weight Charge:',
    ja: '重量料金:',
  },
  D207V0059: {
    en: 'Currency:',
    ja: '通貨:',
  },
  D207V0060: {
    en: 'Class',
    ja: 'クラス',
  },
  D207V0061: {
    en: 'Rate',
    ja: '割合',
  },
  D207V0062: {
    en: 'Amount',
    ja: '料金',
  },
  D207V0063: {
    en: 'Other Charge due FWDR',
    ja: '混載業者によるその他の請求',
  },
  D207V0064: {
    en: 'Other Charge due Carrier',
    ja: 'その他の運送費',
  },
  D207V0065: {
    en: 'Code',
    ja: 'コード',
  },
  D207V0066: {
    en: 'Charge',
    ja: '料金',
  },
  D207V0067: {
    en: 'CC',
    ja: 'CC',
  },
  D207V0068: {
    en: 'X,XXX',
    ja: 'X,XXX',
  },
  D207V0069: {
    en: 'CG',
    ja: 'CG',
  },
  D207V0070: {
    en: 'XXX',
    ja: 'XXX',
  },
  D207V0071: {
    en: 'RAA',
    ja: 'RAA',
  },
  D207V0072: {
    en: 'Total Charge',
    ja: '合計料金',
  },
  D207V0073: {
    en: 'Booked',
    ja: '予約済み',
  },
  D207V0074: {
    en: 'No.',
    ja: '番号',
  },
  D207V0075: {
    en: 'G/W(kg)',
    ja: 'G/W(kg)',
  },
  D207V0076: {
    en: 'Length(cm)',
    ja: '長さ(cm)',
  },
  D207V0077: {
    en: 'Width(cm)',
    ja: '幅(cm)',
  },
  D207V0078: {
    en: 'Height(cm)',
    ja: '身長(cm)',
  },
  D207V0079: {
    en: 'XXXkg',
    ja: 'XXXkg',
  },
  D207V0080: {
    en: 'XXXcm',
    ja: 'XXXcm',
  },
  D207V0081: {
    en: 'x',
    ja: 'x',
  },
  D207V0082: {
    en: 'Close',
    ja: '閉じる',
  },
  D207V0083: {
    en: 'Yes',
    ja: 'はい',
  },
  D207V0084: {
    en: 'No',
    ja: 'いいえ',
  },
  D207V0085: {
    en: "ORD - Chicago O'Hare Int'l Airport",
    ja: 'ORD - シカゴオヘア国際空港',
  },
  D207V0086: {
    en: 'XX - XX',
    ja: 'XX - XX',
  },
  D207V0087: {
    en: 'OK',
    ja: '決定',
  },
  D207V0088: {
    en: 'Cancel',
    ja: 'キャンセル',
  },
  D207V0089: {
    en: 'Total',
    ja: '合計',
  },
  D207V0090: {
    en: 'L x W x H',
    ja: 'L x W x H',
  },
  D207V0091: {
    en: 'HAWB No',
    ja: 'ハウス番号',
  },
  D207V0092: {
    en: 'Save',
    ja: '保存',
  },
}
// Display + 画面名
export default DisplayAirlineAwbDetails
