import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import AppContext from '../../../../contexts/AppContext'
import { remarkNameArr } from '../../../../reducers/common/lightBookingDetails/Remark'
import AccordionLightBookingDetails from '../Accordion/AccordionLightBookingDetails'
import EditButtonRemarkLightBookingDetails from './EditButtonRemarkLightBookingDetails'
import RemarkPopup from './RemarkPopup'
import RemarkTextFieldLightBookingDetails from './RemarkTextFieldLightBookingDetails'

const useStyles = makeStyles((theme) => ({
  label: theme.textNormalBLK,
}))

/**
 * @param {Object} props 下記内容
 * @param {Boolean} props.editable 編集可能
 * @return {JSX} Remark欄
 */
const RemarkLightBookingDetails = ({ editable, isShowOnlyEntered }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { state, dispatch } = useContext(AppContext)

  return useMemo(() => {
    /** @type{string[]} placeholderの配列 */
    const placeholderArr = [
      '4th0014',
      '4th0015',
      '4th0016',
      '4th0017',
      '4th0017',
    ]

    const isAnyInput = Object.values(state.Remark).some((v) => v !== '')
    const isNA = isShowOnlyEntered && !isAnyInput

    /**
     * Editボタンを作成する
     * @param {String} remarkName 値の名前
     * @param {Number} index 配列のインデックス
     * @returns {JSX} Editボタン
     */
    const createEditButton = (remarkName, index) => {
      return (
        <Grid item>
          <EditButtonRemarkLightBookingDetails
            remarkName={remarkName}
            remark={state.Remark[remarkName]}
            dispatch={dispatch}
            placeholder={t(placeholderArr[index])}
          />
        </Grid>
      )
    }
    return (
      <AccordionLightBookingDetails label={t('3rd0023')}>
        <Grid container justify="space-between" alignItems="center" spacing={1}>
          {remarkNameArr.map((remarkName, index) => {
            if (isShowOnlyEntered && state.Remark[remarkName] === '') {
              return null
            }
            return (
              <React.Fragment key={index}>
                <Grid item md={10}>
                  <RemarkTextFieldLightBookingDetails
                    remark={state.Remark[remarkName]}
                    placeholder={t(placeholderArr[index])}
                  />
                </Grid>
                {editable ? createEditButton(remarkName, index) : null}
              </React.Fragment>
            )
          })}
          {isNA ? (
            <Grid item className={classes.label}>
              {t('D301V0248')}
            </Grid>
          ) : null}
        </Grid>
        <RemarkPopup />
      </AccordionLightBookingDetails>
    )
  }, [t, state.Remark, dispatch, editable, isShowOnlyEntered, classes])
}
RemarkLightBookingDetails.propTypes = {
  editable: PropTypes.bool.isRequired,
}
export default RemarkLightBookingDetails
