import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { Divider } from '@material-ui/core'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'

import CellLightDeleteAccount from './CellLightDeleteAccount'
import constants from '../ConstantsLightDeleteAccount'

const useStyles = makeStyles((theme) => ({
  box: {
    ...theme.textNormalRED,
    backgroundColor: theme.palette.info.main,
    padding: '1rem',
    border: '1px solid',
    borderColor: theme.palette.required,
  },
  divider: { backgroundColor: theme.palette.required },
  width: { width: '100%' },
}))

const WarningLightDeleteAccount = () => {
  const classes = useStyles()

  return (
    <Grid container justify="center">
      <Grid
        container
        item
        className={classes.box}
        direction="column"
        justify="center"
        alignItems="center"
        spacing={2}
      >
        <Grid container item justify="center" alignItems="center" spacing={2}>
          <Grid item>
            <WarningRoundedIcon fontSize="large" />
          </Grid>
          <CellLightDeleteAccount table={constants.TITLE_TABLE_ARR} />
        </Grid>
        <Grid item className={classes.width}>
          <Divider className={classes.divider} />
        </Grid>
        <CellLightDeleteAccount table={constants.TABLE_ARR} />
      </Grid>
    </Grid>
  )
}

export default WarningLightDeleteAccount
