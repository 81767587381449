import React, { useMemo } from 'react'

import SearchSort from '../../../../commonUseContext/SearchSort'

/**
 * @return {JSX} サーチソートコンポーネント
 */
const SearchSortLightCarFlightMgmt = () => {
  return useMemo(() => {
    return <SearchSort noGridContainer />
  }, [])
}

export default SearchSortLightCarFlightMgmt
