import {
  UPDATE_FWDR_COMPANY_NAME,
  UPDATE_FWDR_POSTAL_CODE,
  UPDATE_FWDR_COMPANY_ADDRESS,
  UPDATE_FWDR_FIRST_NAME,
  UPDATE_FWDR_LAST_NAME,
  UPDATE_FWDR_POSITION,
  UPDATE_FWDR_MAIL,
  UPDATE_FWDR_TEL,
  UPDATE_FWDR_THREE_LETTER_CODE,
  UPDATE_FWDR_IATACASS_NO,
  UPDATE_FWDR_ICON_PATH,
  UPDATE_FWDR_UPDATE_AT,
  UPDATE_FWDR_IS_DELETE_ICON,
  UPDATE_FWDR_POSTAL_CODE_INPUTERR,
  UPDATE_FWDR_COMPANY_ADDRESS_INPUTERR,
  UPDATE_FWDR_FIRST_NAME_INPUTERR,
  UPDATE_FWDR_LAST_NAME_INPUTERR,
  UPDATE_FWDR_POSITION_INPUTERR,
  UPDATE_FWDR_MAIL_INPUTERR,
  UPDATE_FWDR_TEL_INPUTERR,
  UPDATE_FWDR_THREE_LETTER_CODE_INPUTERR,
  UPDATE_FWDR_IATACASS_NO_INPUTERR,
  UPDATE_FWDR_ICON_PATH_INPUTERR,
  UPDATE_BOND_IN_ADDRESS,
  UPDATE_BOND_IN_ADDRESS_INPUTERR,
  UPDATE_FWDR_BRANCH,
  UPDATE_FWDR_BRANCH_CODE,
  UPDATE_FWDR_AIRPORT,
  UPDATE_FWDR_REPRESENTATIVE_FLG,
  UPDATE_FWDR_AUTHORITY_FLG,
  UPDATE_FWDR_IS_DELETE_SHIPPING_ICON,
  UPDATE_FWDR_PLAN,
  UPDATE_FWDR_PLAN_INPUTERR,
} from '../../../actions'

const initCompanyInfo = {
  companyName: {
    value: { code: '', name: '', three: '' },
    inputErr: { inputErr: false },
  },
  fwdrthreeCode: { value: '', inputErr: false },
  postalCode: { value: '', inputErr: false },
  address: { value: '', inputErr: false },
  bondInAddress: { value: '', inputErr: false },
  cityName: { value: '', inputErr: false },
  cityCode: { value: '', inputErr: false },
  airport: { value: '', inputErr: false },
  representativeFlg: { value: '', inputErr: false },
  authorityFlg: { value: '', inputErr: false },
  firstName: { value: '', inputErr: false },
  lastName: { value: '', inputErr: false },
  position: { value: '', inputErr: false },
  mail: { value: '', inputErr: false },
  tel: { value: '', inputErr: false },
  iataOrcassNumber: { value: '', inputErr: false },
  icon: { value: '', inputErr: false },
  iconPath: { value: '' },
  updatedAt: { value: '' },
  isDeleteIcon: { value: '' },
  fwdrPlanId: { value: '', inputErr: false },
}

const Events = (state = {}, action) => {
  switch (action.type) {
    //------------------
    // companyNameを更新
    //------------------
    case UPDATE_FWDR_COMPANY_NAME:
      return {
        ...state,
        companyName: {
          ...state.companyName,
          value: {
            code: action.fwdrNameId,
            name: action.companyName,
            three: action.fwdrthreeCode,
          },
        },
      }
    //------------------
    // postalCodeを更新
    //------------------
    case UPDATE_FWDR_POSTAL_CODE:
      return {
        ...state,
        postalCode: { ...state.postalCode, value: action.postalCode },
      }
    case UPDATE_FWDR_POSTAL_CODE_INPUTERR:
      return {
        ...state,
        postalCode: { ...state.postalCode, inputErr: action.inputErr },
      }
    //------------------
    // addressを更新
    //------------------
    case UPDATE_FWDR_COMPANY_ADDRESS:
      return { ...state, address: { ...state.address, value: action.address } }
    case UPDATE_FWDR_COMPANY_ADDRESS_INPUTERR:
      return {
        ...state,
        address: { ...state.address, inputErr: action.inputErr },
      }
    //------------------
    // bondInAddressを更新
    //------------------
    case UPDATE_BOND_IN_ADDRESS:
      return {
        ...state,
        bondInAddress: { ...state.bondInAddress, value: action.address },
      }
    case UPDATE_BOND_IN_ADDRESS_INPUTERR:
      return {
        ...state,
        bondInAddress: { ...state.bondInAddress, inputErr: action.inputErr },
      }
    //------------------
    // cityNameを更新
    //------------------
    case UPDATE_FWDR_BRANCH:
      return {
        ...state,
        cityName: { ...state.cityName, value: action.cityName },
      }
    //------------------
    // cityCodeを更新
    //------------------
    case UPDATE_FWDR_BRANCH_CODE:
      return {
        ...state,
        cityCode: { ...state.cityCode, value: action.cityCode },
      }
    //------------------
    // airportを更新
    //------------------
    case UPDATE_FWDR_AIRPORT:
      return { ...state, airport: { ...state.airport, value: action.airport } }
    //------------------
    // representativeFlgを更新
    //------------------
    case UPDATE_FWDR_REPRESENTATIVE_FLG:
      return {
        ...state,
        representativeFlg: {
          ...state.representativeFlg,
          value: action.representativeFlg,
        },
      }
    //------------------
    // authorityFlgを更新
    //------------------
    case UPDATE_FWDR_AUTHORITY_FLG:
      return {
        ...state,
        authorityFlg: { ...state.authorityFlg, value: action.authorityFlg },
      }
    //------------------
    // firstNameを更新
    //------------------
    case UPDATE_FWDR_FIRST_NAME:
      return {
        ...state,
        firstName: { ...state.firstName, value: action.firstName },
      }
    case UPDATE_FWDR_FIRST_NAME_INPUTERR:
      return {
        ...state,
        firstName: { ...state.firstName, inputErr: action.inputErr },
      }
    //------------------
    // lastNameを更新
    //------------------
    case UPDATE_FWDR_LAST_NAME:
      return {
        ...state,
        lastName: { ...state.lastName, value: action.lastName },
      }
    case UPDATE_FWDR_LAST_NAME_INPUTERR:
      return {
        ...state,
        lastName: { ...state.lastName, inputErr: action.inputErr },
      }
    //------------------
    // positionを更新
    //------------------
    case UPDATE_FWDR_POSITION:
      return {
        ...state,
        position: { ...state.position, value: action.position },
      }
    case UPDATE_FWDR_POSITION_INPUTERR:
      return {
        ...state,
        position: { ...state.position, inputErr: action.inputErr },
      }
    //------------------
    // mailを更新
    //------------------
    case UPDATE_FWDR_MAIL:
      return { ...state, mail: { ...state.mail, value: action.mail } }
    case UPDATE_FWDR_MAIL_INPUTERR:
      return { ...state, mail: { ...state.mail, inputErr: action.inputErr } }
    //------------------
    // telを更新
    //------------------
    case UPDATE_FWDR_TEL:
      return { ...state, tel: { ...state.tel, value: action.tel } }
    case UPDATE_FWDR_TEL_INPUTERR:
      return { ...state, tel: { ...state.tel, inputErr: action.inputErr } }
    //------------------
    // threeLetterCodeを更新
    //------------------
    case UPDATE_FWDR_THREE_LETTER_CODE:
      return {
        ...state,
        fwdrthreeCode: { ...state.fwdrthreeCode, value: action.fwdrthreeCode },
      }
    case UPDATE_FWDR_THREE_LETTER_CODE_INPUTERR:
      return {
        ...state,
        fwdrthreeCode: { ...state.fwdrthreeCode, inputErr: action.inputErr },
      }
    //------------------
    // iataOrcassNoを更新
    //------------------
    case UPDATE_FWDR_IATACASS_NO:
      return {
        ...state,
        iataOrcassNumber: {
          ...state.iataOrcassNumber,
          value: action.iataOrcassNumber,
        },
      }
    case UPDATE_FWDR_IATACASS_NO_INPUTERR:
      return {
        ...state,
        iataOrcassNumber: {
          ...state.iataOrcassNumber,
          inputErr: action.inputErr,
        },
      }
    //------------------
    // iconを更新
    //------------------
    case UPDATE_FWDR_ICON_PATH:
      return { ...state, icon: { ...state.icon, value: action.icon } }
    case UPDATE_FWDR_ICON_PATH_INPUTERR:
      return { ...state, icon: { ...state.icon, inputErr: action.inputErr } }
    //------------------
    // iconPathを更新
    //------------------
    case UPDATE_FWDR_IS_DELETE_SHIPPING_ICON:
      return {
        ...state,
        iconPath: { ...state.iconPath, value: action.iconPath },
      }
    //------------------
    // updatedAtを更新
    //------------------
    case UPDATE_FWDR_UPDATE_AT:
      return {
        ...state,
        updatedAt: { ...state.updatedAt, value: action.updatedAt },
      }
    //------------------
    // isDeleteIconを更新
    //------------------
    case UPDATE_FWDR_IS_DELETE_ICON:
      return {
        ...state,
        isDeleteIcon: { ...state.isDeleteIcon, value: action.isDeleteIcon },
      }
    //------------------
    // fwdrPlanIdを更新
    //------------------
    case UPDATE_FWDR_PLAN:
      return {
        ...state,
        fwdrPlanId: { ...state.fwdrPlanId, value: action.fwdrPlanId },
      }
    case UPDATE_FWDR_PLAN_INPUTERR:
      return {
        ...state,
        fwdrPlanId: { ...state.fwdrPlanId, inputErr: action.inputErr },
      }
    default:
      return state
  }
}

export default Events

export { initCompanyInfo }
