import { TABLES } from '../../../actions'

/** @type {Object} 初期表示のテーブル */
const initTableData = []

/**
 * cargoDetailのテーブルデータを更新
 * @param {Object} action dispatchで受け取ったaction
 * @param {Object} state reducer内で保管しているstate
 * @return {Object} 更新後のテーブルデータのオブジェクト
 */
const updateTable = (action, state) => {
  const { cargoDetail, editIndex } = action
  state[editIndex] = cargoDetail // editIndex番目の要素をcargoDetailsに置き換える
  return [...state] //新しい配列にして返す
}

/**
 * cargoDetailのテーブルデータを削除
 * @param {Object} action dispatchで受け取ったaction
 * @param {Object} state reducer内で保管しているstate
 * @return {Object} 削除後のテーブルデータのオブジェクト
 */
const deleteTable = (action, state) => {
  const { editIndex } = action
  state.splice(editIndex, 1) // editIndex番目の要素を1つ削除
  return [...state] // 新しい配列にして返す
}

/**
 * テーブルデータを保持
 * @param { Object } [state = initTableData] テーブルデータ
 * @param { Object } action action
 * @return { Object } テーブルデータ
 */
const Tables = (state = initTableData, action) => {
  switch (action.type) {
    case TABLES.SET:
      return [...state, action.cargoDetail]
    case TABLES.INIT:
      return action.cargoDetail
    case TABLES.EDIT:
      return updateTable(action, state)
    case TABLES.CLEAR:
      return deleteTable(action, state)
    default:
      return state
  }
}

export default Tables

export { initTableData }
