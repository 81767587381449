import React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'

import CompanyInfoText from '../../../../common/text/CompanyInfoText/CompanyInfoText'
import HeaderText from '../../../../common/text/HeaderText'
import Validity from '../../../../common/bidInfo/Validity'
import Deadline from '../../../../common/bidInfo/Deadline'
import SpecialNotes from '../../../../common/bidInfo/SpecialNotes'

const useStyles = makeStyles(() => ({
  margin: {
    marginBottom: '2rem',
  },
}))

/**
 * @param {Object} props - 以下
 * @param {Array} bidInfo - 入札情報
 * @return {JSX} 表コンポーネント(全体)
 */
const TableBidInfo = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid container justify="center">
      <Grid item md={10} xs={10} className={classes.margin}>
        <CompanyInfoText textArr={['***']} />
      </Grid>
      <Grid item md={10} xs={10} className={classes.margin}>
        <Grid container spacing={3}>
          <Grid container item>
            <Grid item md={12} xs={12}>
              <HeaderText className={classes.head}>{t('D302V0033')}</HeaderText>
            </Grid>
            <Grid item md={3} xs={12}>
              <div className={`${classes.text} ${classes.wordBreak}`}>***</div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={10} xs={10} className={classes.margin}>
        <Validity bidInfo={props.bidInfo} />
      </Grid>
      <Grid item md={10} xs={10} className={classes.margin}>
        <Deadline bidInfo={props.bidInfo} />
      </Grid>
      <Grid item md={10} xs={10} className={classes.margin}>
        <SpecialNotes bidInfo={props.bidInfo} />
      </Grid>
    </Grid>
  )
}

TableBidInfo.propTypes = {
  bidInfo: PropTypes.object.isRequired,
}

export default TableBidInfo
