import React, { useContext, useMemo } from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

import { LIGHT_BOOKING_REQUEST } from '../../../../../actions'
import AppContext from '../../../../../contexts/AppContext'
import InputSelect from '../../../../common/select/InputSelect'

const useStyles = makeStyles((theme) => ({
  title: theme.textBoldBLU,
  red: theme.textBoldRED,
  margin: {
    marginBottom: '1rem',
  },
}))

/**
 * @returns {JSX} AirportSelect欄
 */
const AirportSelectLightBookingRequest = () => {
  const { state, dispatch } = useContext(AppContext)
  const { t } = useTranslation()
  const classes = useStyles()

  /** @type {Number} 選択中の空港ID */
  const orgId = state.ShippingDetails.org
  const dstId = state.ShippingDetails.dst

  return useMemo(() => {
    /** @type{Object} 選択中の空港 */
    const orgValue = state.Airport.getAirportInfo(orgId) ?? null
    const dstValue = state.Airport.getAirportInfo(dstId) ?? null

    /**
     * 空港更新
     * @param {Object} value 選択した値
     * @returns {void} なし
     */
    const onChangeOrg = (value) => {
      dispatch({
        type: LIGHT_BOOKING_REQUEST.SELECT.SET,
        org: value?.airportId ?? null,
      })
    }
    const onChangeDst = (value) => {
      dispatch({
        type: LIGHT_BOOKING_REQUEST.SELECT.SET,
        dst: value?.airportId ?? null,
      })
    }

    return (
      <Grid container>
        <Grid item md={4}>
          <span className={classes.red}>*</span>
          <span className={classes.title}>{t('D004V0004')}</span>
          <InputSelect
            className={classes.margin}
            options={
              dstId
                ? state.Airport.airports.filter((v) => v.airportId !== dstId)
                : state.Airport.airports
            }
            getOptionLabel={(option) =>
              `${option.airportCode} - ${option.airport}`
            }
            value={orgValue}
            onChange={(_, value) => onChangeOrg(value)}
            disabled={false}
          />
        </Grid>
        <Grid item md={2} />
        <Grid item md={4}>
          <span className={classes.red}>*</span>
          <span className={classes.title}>{t('D004V0005')}</span>
          <InputSelect
            className={classes.margin}
            options={
              orgId
                ? state.Airport.airports.filter((v) => v.airportId !== orgId)
                : state.Airport.airports
            }
            getOptionLabel={(option) =>
              `${option.airportCode} - ${option.airport}`
            }
            value={dstValue}
            onChange={(_, value) => onChangeDst(value)}
            disabled={false}
          />
        </Grid>
      </Grid>
    )
  }, [dispatch, orgId, dstId, state.Airport, classes, t])
}
export default AirportSelectLightBookingRequest
