import { makeStyles } from '@material-ui/core'
import React from 'react'

import Paths from '../../../../../constants/Paths'
import BackButton from '../../../../common/buttons/BackButton'

const useStyles = makeStyles((theme) => ({
  ok: theme.backButtonCenter,
}))

/**
 * @return {JSX} Backボタンコンポーネント
 */
const BackButtonSelectForwarder = () => {
  const classes = useStyles()

  return (
    <div className={classes.ok}>
      <BackButton path={Paths.FWDR.TOP} />
    </div>
  )
}

export default BackButtonSelectForwarder
