import React, { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'

import AppContext from '../../../../../contexts/AppContext'
import TableCell from '../../../../common/table/TableCell'
import TextField from '../../../../common/text/TextField'
import CommonCalc from '../../../../common/function/CommonCalc'

/**
 * @param {object} props 下記内容
 * @param {Number} props.index ページ上のインデックス
 * @return {JSX} 表のAirlineセル
 */
const AirLineCellLightCarAirFreight = ({ index }) => {
  const { state } = useContext(AppContext)

  /** @type {Number} showDataのインデックス */
  const page = CommonCalc.calcShowDataIndex(
    state.Paging,
    state.Tables.showData.length
  )
  /** @type {String} 航空会社の2桁コード */
  const { carriertwoCode } = state.Tables.showData[page][index]

  return useMemo(
    () => (
      <TableCell>
        <TextField value={carriertwoCode ?? ''} disabled />
      </TableCell>
    ),
    [carriertwoCode]
  )
}

AirLineCellLightCarAirFreight.propTypes = {
  index: PropTypes.number.isRequired,
}

export default AirLineCellLightCarAirFreight
