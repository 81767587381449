import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import TextField from '../../common/text/TextField'
import CommonValidation from '../../common/function/CommonValidation'
import { UPDATE_CAR_CODE, UPDATE_CAR_CODE_INPUTERR } from '../../../actions'

const useStyles = makeStyles((theme) => ({
  head: theme.textBoldBLU,
}))

/**
 * コンポーネントを更新するか判定する
 * @param {Object} prev 前回の値
 * @param {Object} next 最新の値
 * @returns {Boolean} falseの場合更新する
 */
const areEqual = (prev, next) => {
  // 各入力欄の値を比較
  // 一致していれば更新無し
  return prev.department.value === next.department.value
}

const Department = React.memo(({ dispatch, department }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [inputErrs, setInputErrs] = useState({ department: false })
  const [helperTxts, setHelperTxts] = useState({ department: '' })

  const handleChangedDepartment = (event) => {
    const value = event.target.value
    // 入力チェック
    const validationCheck = CommonValidation.departmentCode(value)
    const err = validationCheck.checkResult
    setInputErrs({ ...inputErrs, department: err })
    setHelperTxts({ ...helperTxts, department: validationCheck.errMsg })

    dispatch({
      type: UPDATE_CAR_CODE,
      department: value,
    })

    // 入力エラーの場合、Savaボタン無効
    dispatch({
      type: UPDATE_CAR_CODE_INPUTERR,
      inputErr: err,
    })
  }

  return (
    <div className={classes.formControl}>
      <Grid container>
        <Grid item md={4} xs={10}>
          <div className={classes.head}>{t('D301V0209')}</div>
          <TextField
            id="department"
            error={inputErrs.department}
            helperText={t(helperTxts.department)}
            value={department.value}
            onChange={handleChangedDepartment}
            autoComplete="off"
          />
        </Grid>
      </Grid>
    </div>
  )
}, areEqual)

Department.displayName = 'Department'

Department.propTypes = {
  department: PropTypes.object.isRequired,
}
export default Department
