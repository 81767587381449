import React from 'react'
import { useTranslation } from 'react-i18next'

import SubTitle from '../../../../common/subTitle/SubTitle'

/**
 * @return {JSX} LightJhClientRegDeleteAccount画面サブタイトルコンポーネント
 */
const SubTitleLightJhClientRegDeleteAccount = () => {
  const { t } = useTranslation()

  return <SubTitle>{t('D401V0022')}</SubTitle>
}

export default SubTitleLightJhClientRegDeleteAccount
