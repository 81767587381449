import { LIGHT_BOOKING_DETAILS } from '../../../actions'
import Common from '../../../constants/Common'
import { getShc } from './Shc'

/** @typedef {import('./Shc').Shc} Shc Shc情報 */

/** @type {Object} SHCの初期値 */
const initShcObj = {
  shcArr: [],
  shcLinkingObjArr: [],
}

/**
 * リクエストデータを作成する
 * @param {Shc[]} shcArr 登録したSHCの配列
 * @param {Object[]} shcLinkingObjArr shcLinkingIdとupdatedAtの配列
 * @returns {Object[]} リクエストデータ
 */
const createRequestData = (shcArr, shcLinkingObjArr) => {
  /** @type {Object[]} 削除データ */
  const deleteArr = shcLinkingObjArr.map((shc) => ({
    ...shc,
    delFlg: Common.DEL_FLG.ON,
  }))
  /** @type {Object[]} 更新・登録データ */
  const editArr = shcArr.map((shc) => {
    const { shcId } = shc
    const { shcLinkingId = null, updatedAt = null } = deleteArr.shift() ?? {}
    return { shcId, shcLinkingId, updatedAt }
  })
  return editArr.concat(deleteArr)
}

/**
 * Shcを保持
 * @param {Object} action action
 * @param {Object[]} action.shcArr shcIdが含まれるオブジェクトの配列
 * @return {Object} state
 */
const set = (action) => {
  const { shcArr = [] } = action
  const fixedShcArr = shcArr.map(({ shcId }) => getShc(shcId))
  const shcLinkingObjArr = shcArr.map(({ shcLinkingId, updatedAt }) => ({
    shcLinkingId,
    updatedAt,
  }))
  const getRequestData = () => createRequestData(fixedShcArr, shcLinkingObjArr)
  return { shcArr: fixedShcArr, shcLinkingObjArr, getRequestData }
}

/**
 * Shcを編集
 * @param {Object} action action
 * @param {Object} action.shc 追加するShc
 * @param {Number} action.index インデックス
 * @param {Object} state state
 * @return {Object} state
 */
const edit = (action, state) => {
  const { shc = null, index = null } = action
  if (index === null) {
    state.shcArr.push(shc) //追加
  } else if (shc) {
    state.shcArr[index] = shc //編集
  } else {
    state.shcArr.splice(index, 1) //削除
  }
  return { ...state }
}

/**
 * Shcの編集データを保持
 * @param { Object } [state = initShcObj] state
 * @param { Object } action action
 * @return { Object } state
 */
const ShcObj = (state = initShcObj, action) => {
  const { type, ...rest } = action
  switch (type) {
    case LIGHT_BOOKING_DETAILS.SHC_ARR.SET:
      return set(rest)
    case LIGHT_BOOKING_DETAILS.SHC_ARR.EDIT:
      return edit(rest, state)
    default:
      return state
  }
}

export default ShcObj

export { initShcObj }
