import { TABLES } from '../../../actions'
import CommonFunc from '../../../components/common/function/CommonFunc'
import ConstantsConfirmation from '../../../components/pages/fwdr/fwdrConfirmation/ConstantsConfirmation'
import CommonCalc from '../../../components/common/function/CommonCalc'

/** 初期表示ページ */
const initTablesConfirmation = {
  // APIの元のデータを保持
  apiDataArr: [],
  // 表示用のデータを保持
  showData: [[]],
}

/**
 * 表示用データを作成
 * @param {Object} bidDataObj 入札のAPIデータ
 * @param {Number} apiIndexNum APIの番号
 * @return {Object} 表示用データ
 */
const createDisplayObj = (bidDataObj, apiIndexNum) => {
  const {
    airlineStr,
    commStr,
    currencyStr,
    dstStr,
    flightTypeStr,
    forwarderStr,
    fsc,
    large,
    leadTimeStr,
    light,
    max,
    medium,
    min,
    minrate,
    normal,
    orgStr,
    remark,
    statusStr,
    termsStr,
    transitStr,
    ttStr,
    ttlMaxVol,
    ttlMaxWgt,
    ttlMinVol,
    ttlMinWgt,
  } = bidDataObj

  //ttlWgtとttlVolを修正
  const ttlVol = `${CommonCalc.addComma(ttlMinVol, true)} m3 - ${CommonCalc.addComma(ttlMaxVol, true)} m3`
  const ttlWgt = `${CommonCalc.addComma(ttlMinWgt, true)} kg - ${CommonCalc.addComma(ttlMaxWgt, true)} kg`

  //各数値にカンマを付ける
  const numberObj = { fsc, minrate, normal, min, light, medium, large, max }
  const addedCommaNumberObj = {}
  Object.keys(numberObj).map((key) => {
    addedCommaNumberObj[`${key}Str`] = CommonCalc.addComma(numberObj[key], true)
    return null
  })

  return {
    apiIndexNum,
    airlineStr,
    commStr,
    currencyStr,
    dstStr,
    flightTypeStr,
    forwarderStr,
    leadTimeStr,
    orgStr,
    remark,
    statusStr,
    termsStr,
    transitStr,
    ttStr,
    ttlVol,
    ttlWgt,
    ...addedCommaNumberObj,
  }
}

/**
 * 表示用のデータに変換
 * @param {Array} apiDataArr 入札のAPIデータ
 * @returns {Array} 表示、並び替え用情報配列
 */
const convertShowCargoDataArr = (apiDataArr) =>
  apiDataArr.map((bidDataObj, apiIndexNum) => {
    const displayObj = createDisplayObj(bidDataObj, apiIndexNum)

    return {
      // 表示用
      ...displayObj,
      // API特定用番号
      apiIndexNum: bidDataObj.apiIndexNum ?? apiIndexNum,
    }
  })

/**
 *
 * @param {Array} apiDataArr 入札のAPIデータ
 * @param {String} search 検索ワード
 * @param {String} sort ソート
 * @return {Object} 更新したTablesConfirmationのstate
 */
const createCommonTableDataObj = (apiDataArr, search, sort) => {
  // 検索、並び替え用にAPIの値を変換した後表示用データを作成
  const showCargoDataArr = convertShowCargoDataArr(apiDataArr)
  const { showData } = CommonFunc.searchSortData(
    showCargoDataArr,
    search,
    sort,
    '',
    ConstantsConfirmation.PAGE_AMOUNT
  )

  // 表示用データとAPIの値全部と必須に分けて保持
  return {
    // 表示用データ
    showData,
    // APIデータ
    apiDataArr,
  }
}

/**
 * TablesConfirmationのstateの初期設定を作成
 * @param {Object} action dispatchで受け取ったaction
 * @return {Object} 初期設定のTablesConfirmationのstate
 */
const createInitTableDataObj = (action) => {
  const { data, sort } = action
  const { showData, apiDataArr } = createCommonTableDataObj(data, '', sort)

  // 表示用データとAPIの値を全部と必須に分けて保持
  return {
    // 表示用データ
    showData,
    // APIデータ
    apiDataArr,
  }
}

/**
 * 表データを保持
 * @param {Object} [state=initTablesConfirmation] 表データ
 * @param {Object} action 表データの検索に必要な値
 * @return {Object} 表データ
 */
const TablesConfirmation = (state = initTablesConfirmation, action) => {
  switch (action.type) {
    case TABLES.INIT:
      return createInitTableDataObj(action)
    default:
      return state
  }
}

export default TablesConfirmation

export { initTablesConfirmation }
