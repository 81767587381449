export const CREATE_EVENT = 'CREATE_EVENT'
export const DELETE_EVENT = 'DELETE_EVENT'
export const DELETE_ALL_EVENTS = 'DELETE_ALL_EVENTS'

export const ADD_OPERATION_LOG = 'ADD_OPERATION_LOG'
export const DELETE_ALL_OPERATION_LOGS = 'DELETE_ALL_OPERATION_LOGS'

// サンプル用
export const CREATE_EVENT_SAMPLE = 'CREATE_EVENT_SAMPLE'
export const DELETE_EVENT_SAMPLE = 'DELETE_EVENT_SAMPLE'
export const DELETE_ALL_EVENTS_SAMPLE = 'DELETE_ALL_EVENTS_SAMPLE'
export const SET_EVENTS_DATA_SAMPLE = 'SET_EVENTS_DATA_SAMPLE'

export const ADD_OPERATION_LOG_SAMPLE = 'ADD_OPERATION_LOG_SAMPLE'
export const DELETE_ALL_OPERATION_LOGS_SAMPLE =
  'DELETE_ALL_OPERATION_LOGS_SAMPLE'

export const SHOW_LOADING_SAMPLE = 'SHOW_LOADING_SAMPLE'
export const HIDE_LOADING_SAMPLE = 'HIDE_LOADING_SAMPLE'

//ShipperShippingDetails
export const UPDATE_CARGO_DETAIL = 'UPDATE_CARGO_DETAIL'
export const DELETE_CARGO_DETAIL = 'DELETE_CARGO_DETAIL'

export const UPDATE_SIPPER_ADDRESS = 'UPDATE_SHIPPER_ADDRESS'
export const UPDATE_CONSIGNEE_ADDRESS = 'UPDATE_CONSIGNEE_ADDRESS'

export const UPDATE_READY_DATE = 'UPDATE_READY_DATE'
export const UPDATE_TERMS = 'UPDATE_TERMS'
export const UPDATE_ORIGIN = 'UPDATE_ORIGIN'
export const UPDATE_DESTINATION = 'UPDATE_DESTINATION'
export const UPDATE_COMM_CATEGORY = 'UPDATE_COMM_CATEGORY'
export const UPDATE_COMM_DESCRIBE = 'UPDATE_COMM_DESCRIBE'
export const UPDATE_SHC = 'UPDATE_SHC'
export const UPDATE_TT = 'UPDATE_TT'
export const UPDATE_PACKING = 'UPDATE_PACKING'
export const UPDATE_PICKUP_CHECK = 'UPDATE_PICKUP_CHECK'
export const UPDATE_PICKUP_DATE = 'UPDATE_PICKUP_DATE'
export const UPDATE_PICKUP_TIME = 'UPDATE_PICKUP_TIME'
export const UPDATE_PICKUP_ADDRESS = 'UPDATE_PICKUP_ADDRESS'
export const UPDATE_NOTES = 'UPDATE_NOTES'
export const UPDATE_NOTES_ERRORARR = 'UPDATE_NOTES_ERRORARR'

// shipperCargoTrace fwdrCargoTrace
export const GET_TABLE_DATA = 'GET_TABLE_DATA'
export const SEARCH_SORT_DATA = 'SEARCH_SORT_DATA'
export const PAGING_DATA = 'PAGING_DATA'
export const GET_DOCUMENT = 'GET_DOCUMEMNT'
export const GET_FLIGHT = 'GET_FLIGHT'
export const GET_HISTORY = 'GET_HISTORY'
export const GET_INFORMATION = 'GET_INFORMATION'
export const GET_STEPPER = 'GET_STEPPER'

// airlineCassDetails
export const GET_FILE_DATA = 'GET_FILE_DATA'
export const UPDATE_START_DATE = 'UPDATE_START_DATE'
export const UPDATE_PERSENTAGE = 'UPDATE_PERSENTAGE'
export const UPDATE_RATE = 'UPDATE_RATE'
export const UPDATE_DOWNLOAD_DATE = 'UPDATE_DOWNLOAD_DATE'
export const GET_DATE_LIST = 'GET_DATE_LIST'
export const UPDATE_UPDATED_AT = 'UPDATE_UPDATED_AT'

// AirlineRecordDetails
export const UPDATE_RECORD = 'UPDATE_RECORD'

// shipperInvoice
export const UPDATE_GOODS_DESCRIPTION_DATA = 'UPDATE_GOODS_DESCRIPTION_DATA'
export const DELETE_GOODS_DESCRIPTION_DATA = 'DELETE_GOODS_DESCRIPTION_DATA'
export const UPDATE_COUNTRY_OF_ORIGIN = 'UPDATE_COUNTRY_OF_ORIGIN'
export const UPDATE_INVOICE_NO = 'UPDATE_INVOICE_NO'
export const UPDATE_SIGN_PATH = 'UPDATE_SIGN_PATH'
export const UPDATE_IS_UPLOAD_PDF = 'UPDATE_IS_UPLOAD_PDF'

// shipperQuote
export const UPDATE_QUOTE_INFO = 'UPDATE_QUOTE_INFO'

// JHCompanyInfoM
// Master JH
export const UPDATE_JH_COMPANY_NAME = 'UPDATE_JH_COMPANY_NAME'
export const UPDATE_JH_COMPANY_NAME_INPUTERR = 'UPDATE_JH_COMPANY_NAME_INPUTERR'
export const UPDATE_JH_COMPANY_ADDRESS = 'UPDATE_JH_COMPANY_ADDRESS'
export const UPDATE_JH_COMPANY_ADDRESS_INPUTERR =
  'UPDATE_JH_COMPANY_ADDRESS_INPUTERR'
export const UPDATE_JH_FIRST_NAME = 'UPDATE_JH_FIRST_NAME'
export const UPDATE_JH_FIRST_NAME_INPUTERR = 'UPDATE_JH_FIRST_NAME_INPUTERR'
export const UPDATE_JH_LAST_NAME = 'UPDATE_JH_LAST_NAME'
export const UPDATE_JH_LAST_NAME_INPUTERR = 'UPDATE_JH_LAST_NAME_INPUTERR'
export const UPDATE_JH_MAIL = 'UPDATE_JH_MAIL'
export const UPDATE_JH_MAIL_INPUTERR = 'UPDATE_JH_MAIL_INPUTERR'
export const UPDATE_JH_TEL = 'UPDATE_JH_TEL'
export const UPDATE_JH_TEL_INPUTERR = 'UPDATE_JH_TEL_INPUTERR'
export const UPDATE_JH_POSITION = 'UPDATE_JH_POSITION'
export const UPDATE_JH_POSITION_INPUTERR = 'UPDATE_JH_POSITION_INPUTERR'
export const UPDATE_JH_CURRENTPASS = 'UPDATE_JH_CURRENTPASS'
export const UPDATE_JH_CURRENTPASS_INPUTERR = 'UPDATE_JH_CURRENTPASS_INPUTERR'
export const UPDATE_JH_NEWPASS = 'UPDATE_JH_NEWPASS'
export const UPDATE_JH_NEWPASS_INPUTERR = 'UPDATE_JH_NEWPASS_INPUTERR'
export const UPDATE_JH_NEWPASS_VERIFY = 'UPDATE_JH_NEWPASS_VERIFY'
export const UPDATE_JH_NEWPASS_VERIFY_INPUTERR =
  'UPDATE_JH_NEWPASS_VERIFY_INPUTERR'
export const UPDATE_JH_PASSWORD = 'UPDATE_JH_PASSWORD'
export const UPDATE_JH_ICON_PATH = 'UPDATE_JH_ICON_PATH'
export const UPDATE_JH_BACKUP = 'UPDATE_JH_BACKUP'
export const UPDATE_JH_UPDATE_AT = 'UPDATE_JH_UPDATE_AT'
export const UPDATE_JH_FILE = 'UPDATE_JH_FILE'
export const UPDATE_JH_IS_DELETE_ICON = 'UPDATE_JH_IS_DELETE_ICON'
export const UPDATE_JH_PASS_DIGIT_ERR = 'UPDATE_JH_PASS_DIGIT_ERR'
export const UPDATE_JH_POSTAL_CODE = 'UPDATE_JH_POSTAL_CODE'
export const UPDATE_JH_POSTAL_CODE_INPUTERR = 'UPDATE_JH_POSTAL_CODE_INPUTERR'
export const UPDATE_JH_ICON_PATH_INPUTERR = 'UPDATE_JH_ICON_PATH_INPUTERR'
export const UPDATE_JH_SIGNATURE_INPUTERR = 'UPDATE_JH_SIGNATURE_INPUTERR'

// JHClientList
export const UPDATE_PLAN = 'UPDATE_PLAN'
export const FWDR_TABLE_DATA = 'FWDR_TABLE_DATA'
export const CAR_TABLE_DATA = 'CAR_TABLE_DATA'
export const UPDATE_JH_SIGNATURE = 'UPDATE_JH_SIGNATURE'
export const UPDATE_JH_MAIL_SETTING = 'UPDATE_JH_MAIL_SETTING'
export const UPDATE_JH_CURRENTPASS_ERR = 'UPDATE_JH_CURRENTPASS_ERR'
export const UPDATE_JH_PASS_VERIFY_ERR = 'UPDATE_JH_PASS_VERIFY_ERR'
export const UPDATE_JH_SEARCH_WORD = 'UPDATE_JH_SEARCH_WORD'
export const UPDATE_JH_SEARCH_AUTH = 'UPDATE_JH_SEARCH_AUTH'
export const INIT_JH_TABLE = 'INIT_JH_TABLE'
export const SEARCH_JH_TABLE = 'SEARCH_JH_TABLE'
export const ADD_JH_TABLE = 'ADD_JH_TABLE'
export const UPDATE_JH_TABLE = 'UPDATE_JH_TABLE'
export const DELETE_JH_TABLE = 'DELETE_JH_TABLE'
export const UPDATE_JH_PAGING_DATA = 'UPDATE_JH_PAGING_DATA'
export const INIT_STATUS = 'INIT_STATUS'

// AirlineAwbDetails
export const UPDATE_AWB_INFO = 'UPDATE_AWB_INFO'
export const ADD_ROUTING = 'ADD_ROUTING '
export const UPDATE_SPCIALNOTES = 'UPDATE_SPCIALNOTES'
export const DELETE_SPCIALNOTES = 'DELETE_SPCIALNOTES'
export const UPDATE_DEFAULT_SPLIT_AWB = 'UPDATE_DEFAULT_SPLIT_AWB'

// AirlineSpaceControl
export const UPDATE_CHECKBOX = 'UPDATE_CHECKBOX'
export const UPDATE_STATUS = 'UPDATE_STATUS'
export const DELETE_SHC = 'DELETE_SHC'
export const UPDATE_COMAT = 'UPDATE_COMAT'
export const UPDATE_INFO = 'UPDATE_INFO'
export const UPDATE_PDF = 'UPDATE_PDF'

// AirlineCargoManifest
export const GET_CARGOMANIFEST = 'GET_CARGOMANIFEST'

// airportUldAssign
export const UPDATE_ULD = 'UPDATE_ULD'
export const DELETE_ULD = 'DELETE_ULD'
export const ADD_ULD_NUMBER = 'ADD_ULD_NUMBER'
export const UPDATE_ULD_NUMBER = 'UPDATE_ULD_NUMBER'
export const UPDATE_ULD_TAB = 'UPDATE_ULD_TAB'
export const UPDATE_TTL_WGT = 'UPDATE_TTL_WGT'
export const UPDATE_TTL_VOL = 'UPDATE_TTL_VOL'

// airlineConfirmBooking
export const UPDATE_ROUTING = 'UPDATE_ROUTING'
export const DELETE_ROUTING = 'DELETE_ROUTING'
export const DELETE_SPLIT_ROUTING = 'DELETE_SPLIT_ROUTING'
export const UPDATE_SPLIT_ROUTING = 'UPDATE_SPLIT_ROUTING'
export const UPDATE_ROUTING_ALLOTMENT = 'UPDATE_ROUTING_ALLOTMENT'
export const UPDATE_ROUTINGID_LIST = 'UPDATE_ROUTINGID_LIST'
export const UPDATE_AIR_FREIGHT = 'UPDATE_AIR_FREIGHT'
export const UPDATE_CHECK = 'UPDATE_CHECK'
export const UPDATE_SHIPPING_DETAILS = 'UPDATE_SHIPPING_DETAILS'
export const UPDATE_HAWB_LIST = 'UPDATE_HAWB_LIST'
export const UPDATE_MEASURE_AND_WEIGHT = 'UPDATE_MEASURE_AND_WEIGHT'
export const UPDATE_SPLIT_AWB = 'UPDATE_SPLIT_AWB'
export const UPDATE_IS_INITIALIZE = 'UPDATE_IS_INITIALIZE'
export const UPDATE_ROUTING_CARGO_LIST = 'UPDATE_ROUTING_CARGO_LIST'

// FwdrTracing
export const UPDATE_CANCELBOOKING = 'UPDATE_CANCELBOOKING'
export const UPDATE_ERROR = 'UPDATE_ERROR'
export const ADD_CHECK = 'ADD_CHECK'
export const UPDATE_SHARE_DOCUMENT = 'UPDATE_SHARE_DOCUMENT'

// airlineSpecialLoad
export const UPDATE_LOADED = 'UPDATE_LOADED'
export const DELETE_LOADED = 'DELETE_LOADED'
export const SET_ROWS = 'SET_ROWS'

// airlineSetFlight
export const UPDATE_NAMES = 'UPDATE_NAMES'
export const DELETE_NAMES = 'DELETE_NAMES'
export const UPDATE_ATD = 'UPDATE_ATD'
export const TAB_SWITCHING = 'TAB_SWITCHING'
export const SHIP_LIST_DATA = 'SHIP_LIST_DATA'
export const FWDR_LIST_DATA = 'FWDR_LIST_DATA'
export const CAR_LIST_DATA = 'CAR_LIST_DATA'

//fwdrCompleteBooking
export const UPDATE_QUOTE = 'UPDATE_QUOTE'
export const DELETE_QUOTE = 'DELETE_QUOTE'
export const UPDATE_HAWB = 'UPDATE_HAWB'
export const UPDATE_CONSOL = 'UPDATE_CONSOL'
export const UPDATE_CONFIRM_CHECK = 'UPDATE_CONFIRM_CHECK'
export const DELETE_TERMS = 'DELETE_TERMS'
export const UPDATE_QUOTE_NUMBER = 'UPDATE_QUOTE_NUMBER'
export const UPDATE_VALID_UNTIL = 'UPDATE_VALID_UNTIL'
export const UPDATE_QUOTE_UPDATED_AT = 'UPDATE_QUOTE_UPDATED_AT'
export const UPDATE_AMOUNT_INFO = 'UPDATE_AMOUNT_INFO'
export const UPDATE_DESTINATION_BRANCH = 'UPDATE_DESTINATION_BRANCH'
export const UPDATE_IS_STRAIGHT = 'UPDATE_IS_STRAIGHT'
export const UPDATE_DEADLINE_FOR_BOND_IN = 'UPDATE_DEADLINE_FOR_BOND_IN'
export const UPDATE_TOTAL = 'UPDATE_TOTAL'
export const UPDATE_CHARGEABLE_WEIGHT = 'UPDATE_CHARGEABLE_WEIGHT'
export const UPDATE_CANCEL_CNSL = 'UPDATE_CANCEL_CNSL'

// shipperConfirmBooking
export const UPDATE_NEGOTIATE_FEES = 'UPDATE_NEGOTIATE_FEES'
export const ADD_FILE_PATH = 'ADD_FILE_PATH'
export const DELETE_FILE_PATH = 'DELETE_FILE_PATH'

// Master Ship
export const UPDATE_SHIP_COMPANY_NAME = 'UPDATE_SHIP_COMPANY_NAME'
export const UPDATE_SHIP_COMPANY_NAME_INPUTERR =
  'UPDATE_SHIP_COMPANY_NAME_INPUTERR'
export const UPDATE_SHIP_COMPANY_ADDRESS = 'UPDATE_SHIP_COMPANY_ADDRESS'
export const UPDATE_SHIP_COMPANY_ADDRESS_INPUTERR =
  'UPDATE_SHIP_COMPANY_ADDRESS_INPUTERR'
export const UPDATE_SHIP_COUNTRY = 'UPDATE_SHIP_COUNTRY'
export const UPDATE_SHIP_COUNTRY_INPUTERR = 'UPDATE_SHIP_COUNTRY_INPUTERR'
export const UPDATE_SHIP_FIRST_NAME = 'UPDATE_SHIP_FIRST_NAME'
export const UPDATE_SHIP_FIRST_NAME_INPUTERR = 'UPDATE_SHIP_FIRST_NAME_INPUTERR'
export const UPDATE_SHIP_LAST_NAME = 'UPDATE_SHIP_LAST_NAME'
export const UPDATE_SHIP_LAST_NAME_INPUTERR = 'UPDATE_SHIP_LAST_NAME_INPUTERR'
export const UPDATE_SHIP_MAIL = 'UPDATE_SHIP_MAIL'
export const UPDATE_SHIP_MAIL_INPUTERR = 'UPDATE_SHIP_MAIL_INPUTERR'
export const UPDATE_SHIP_TEL = 'UPDATE_SHIP_TEL'
export const UPDATE_SHIP_TEL_INPUTERR = 'UPDATE_SHIP_TEL_INPUTERR'
export const UPDATE_SHIP_ICON_PATH = 'UPDATE_SHIP_ICON_PATH'
export const UPDATE_SHIP_NEWPASS = 'UPDATE_SHIP_NEWPASS'
export const UPDATE_SHIP_NEWPASS_INPUTERR = 'UPDATE_SHIP_NEWPASS_INPUTERR'
export const UPDATE_SHIP_NEWPASS_VERIFY = 'UPDATE_SHIP_NEWPASS_VERIFY'
export const UPDATE_SHIP_NEWPASS_VERIFY_INPUTERR =
  'UPDATE_SHIP_NEWPASS_VERIFY_INPUTERR'
export const UPDATE_SHIP_PASS_VERIFY_ERR = 'UPDATE_SHIP_PASS_VERIFY_ERR'
export const UPDATE_SHIP_CURRENTPASS = 'UPDATE_SHIP_CURRENTPASS'
export const UPDATE_SHIP_CURRENTPASS_INPUTERR =
  'UPDATE_SHIP_CURRENTPASS_INPUTERR'
export const UPDATE_SHIP_CURRENTPASS_ERR = 'UPDATE_SHIP_CURRENTPASS_ERR'
export const UPDATE_SHIP_PASSWORD = 'UPDATE_SHIP_PASSWORD'
export const UPDATE_SHIP_MAIL_SETTING = 'UPDATE_SHIP_MAIL_SETTING'
export const UPDATE_SHIP_POSITION = 'UPDATE_SHIP_POSITION'
export const UPDATE_SHIP_POSITION_INPUTERR = 'UPDATE_SHIP_POSITION_INPUTERR'
export const UPDATE_SHIP_SIGNATURE = 'UPDATE_SHIP_SIGNATURE'
export const UPDATE_SHIP_DEPARTMENT = 'UPDATE_SHIP_DEPARTMENT'
export const UPDATE_SHIP_DEPARTMENT_INPUTERR = 'UPDATE_SHIP_DEPARTMENT_INPUTERR'
export const UPDATE_SHIP_UPDATE_AT = 'UPDATE_SHIP_UPDATE_AT'
export const UPDATE_SHIP_BACKUP = 'UPDATE_SHIP_BACKUP'
export const INIT_SHIP_TABLE = 'INIT_SHIP_TABLE'
export const SEARCH_SHIP_TABLE = 'SEARCH_SHIP_TABLE'
export const ADD_SHIP_TABLE = 'ADD_SHIP_TABLE'
export const DELETE_SHIP_TABLE = 'DELETE_SHIP_TABLE'
export const UPDATE_SHIP_TABLE = 'UPDATE_SHIP_TABLE'
export const UPDATE_SHIP_SELECT_LIST = 'UPDATE_SHIP_SELECT_LIST'
export const UPDATE_SHIP_PAGING_DATA = 'UPDATE_SHIP_PAGING_DATA'
export const UPDATE_SHIP_SHIPCON_ID = 'UPDATE_SHIP_SHIPCON_ID'
export const UPDATE_SHIP_SHIPCON_ID_INPUTERR = 'UPDATE_SHIP_SHIPCON_ID_INPUTERR'
export const UPDATE_SHIP_POSTAL_CODE = 'UPDATE_SHIP_POSTAL_CODE'
export const UPDATE_SHIP_POSTAL_CODE_INPUTERR =
  'UPDATE_SHIP_POSTAL_CODE_INPUTERR'
export const UPDATE_SHIP_BRANCH = 'UPDATE_SHIP_BRANCH'
export const UPDATE_SHIP_BRANCH_INPUTERR = 'UPDATE_SHIP_BRANCH_INPUTERR'
export const UPDATE_SHIP_AIRPORT = 'UPDATE_SHIP_AIRPORT'
export const UPDATE_SHIP_AIRPORT_INPUTERR = 'UPDATE_SHIP_AIRPORT_INPUTERR'
export const UPDATE_SHIP_SEARCH_AUTH = ' UPDATE_SHIP_SEARCH_AUTH'
export const UPDATE_SHIP_SEARCH_WORD = 'UPDATE_SHIP_SEARCH_WORD'
export const UPDATE_SHIP_FILE = 'UPDATE_SHIP_FILE'
export const UPDATE_SHIP_IS_DELETE_ICON = 'UPDATE_SHIP_IS_DELETE_ICON'
export const UPDATE_SHIP_PASS_DIGIT_ERR = 'UPDATE_SHIP_PASS_DIGIT_ERR'
export const UPDATE_SHIP_BRANCH_ID = 'UPDATE_SHIP_BRANCH_ID'
export const UPDATE_SHIP_AIRPORT_ID = 'UPDATE_SHIP_AIRPORT_ID'
export const UPDATE_SHIP_ICON_PATH_INPUTERR = 'UPDATE_SHIP_ICON_PATH_INPUTERR'
export const UPDATE_SHIP_SIGNATURE_INPUTERR = 'UPDATE_SHIP_SIGNATURE_INPUTERR'
export const UPDATE_SHIP_PLAN = 'UPDATE_SHIP_PLAN'
export const UPDATE_SHIP_PLAN_INPUTERR = 'UPDATE_SHIP_PLAN_INPUTERR'

// Master FWDR
export const UPDATE_FWDR_COMPANY_NAME = 'UPDATE_FWDR_COMPANY_NAME'
export const UPDATE_FWDR_COMPANY_NAME_INPUTERR =
  'UPDATE_FWDR_COMPANY_NAME_INPUTERR'
export const UPDATE_FWDR_POSTAL_CODE = 'UPDATE_FWDR_POSTAL_CODE'
export const UPDATE_FWDR_POSTAL_CODE_INPUTERR =
  'UPDATE_FWDR_POSTAL_CODE_INPUTERR'
export const UPDATE_FWDR_COMPANY_ADDRESS = 'UPDATE_FWDR_COMPANY_ADDRESS'
export const UPDATE_FWDR_COMPANY_ADDRESS_INPUTERR =
  'UPDATE_FWDR_COMPANY_ADDRESS_INPUTERR'
export const UPDATE_FWDR_BRANCH = 'UPDATE_FWDR_BRANCH'
export const UPDATE_FWDR_BRANCH_INPUTERR = 'UPDATE_FWDR_BRANCH_INPUTERR'
export const UPDATE_FWDR_COUNTRY = 'UPDATE_FWDR_COUNTRY'
export const UPDATE_FWDR_COUNTRY_INPUTERR = 'UPDATE_FWDR_COUNTRY_INPUTERR'
export const UPDATE_FWDR_AIRPORT = 'UPDATE_FWDR_AIRPORT'
export const UPDATE_FWDR_AIRPORT_INPUTERR = 'UPDATE_FWDR_AIRPORT_INPUTERR'
export const UPDATE_FWDR_FIRST_NAME = 'UPDATE_FWDR_FIRST_NAME'
export const UPDATE_FWDR_FIRST_NAME_INPUTERR = 'UPDATE_FWDR_FIRST_NAME_INPUTERR'
export const UPDATE_FWDR_LAST_NAME = 'UPDATE_FWDR_LAST_NAME'
export const UPDATE_FWDR_LAST_NAME_INPUTERR = 'UPDATE_FWDR_LAST_NAME_INPUTERR'
export const UPDATE_FWDR_POSITION = 'UPDATE_FWDR_POSITION'
export const UPDATE_FWDR_POSITION_INPUTERR = 'UPDATE_FWDR_POSITION_INPUTERR'
export const UPDATE_FWDR_MAIL = 'UPDATE_FWDR_MAIL'
export const UPDATE_FWDR_MAIL_INPUTERR = 'UPDATE_FWDR_MAIL_INPUTERR'
export const UPDATE_FWDR_TEL = 'UPDATE_FWDR_TEL'
export const UPDATE_FWDR_TEL_INPUTERR = 'UPDATE_FWDR_TEL_INPUTERR'
export const UPDATE_FWDR_BRANCH_CODE = 'UPDATE_FWDR_BRANCH_CODE'
export const UPDATE_FWDR_BRANCH_CODE_INPUTERR =
  'UPDATE_FWDR_BRANCH_CODE_INPUTERR'
export const UPDATE_FWDR_THREE_LETTER_CODE = 'UPDATE_FWDR_THREE_LETTER_CODE'
export const UPDATE_FWDR_THREE_LETTER_CODE_INPUTERR =
  'UPDATE_FWDR_THREE_LETTER_CODE_INPUTERR'
export const UPDATE_FWDR_PICKUP_TIME_A = 'UPDATE_FWDR_PICKUP_TIME_A'
export const UPDATE_FWDR_PICKUP_TIME_B = 'UPDATE_FWDR_PICKUP_TIME_B'
export const UPDATE_FWDR_PICKUP_TIME_C = 'UPDATE_FWDR_PICKUP_TIME_C'
export const UPDATE_FWDR_PICKUP_TIME_D = 'UPDATE_FWDR_PICKUP_TIME_D'
export const UPDATE_FWDR_PICKUP_TIME_E = 'UPDATE_FWDR_PICKUP_TIME_E'
export const UPDATE_FWDR_PICKUP_TIME_F = 'UPDATE_FWDR_PICKUP_TIME_F'
export const UPDATE_FWDR_PICKUP_TIME_G = 'UPDATE_FWDR_PICKUP_TIME_G'
export const UPDATE_FWDR_IATACASS_NO = 'UPDATE_FWDR_IATACASS_NO'
export const UPDATE_FWDR_IATACASS_NO_INPUTERR =
  'UPDATE_FWDR_IATACASS_NO_INPUTERR'
export const UPDATE_FWDR_ICON_PATH = 'UPDATE_FWDR_ICON_PATH'
export const UPDATE_FWDR_NEWPASS = 'UPDATE_FWDR_NEWPASS'
export const UPDATE_FWDR_NEWPASS_INPUTERR = 'UPDATE_FWDR_NEWPASS_INPUTERR'
export const UPDATE_FWDR_NEWPASS_VERIFY = 'UPDATE_FWDR_NEWPASS_VERIFY'
export const UPDATE_FWDR_NEWPASS_VERIFY_INPUTERR =
  'UPDATE_FWDR_NEWPASS_VERIFY_INPUTERR'
export const UPDATE_FWDR_PASS_VERIFY_ERR = 'UPDATE_FWDR_PASS_VERIFY_ERR'
export const UPDATE_FWDR_CURRENTPASS = 'UPDATE_FWDR_CURRENTPASS'
export const UPDATE_FWDR_CURRENTPASS_INPUTERR =
  'UPDATE_FWDR_CURRENTPASS_INPUTERR'
export const UPDATE_FWDR_CURRENTPASS_ERR = 'UPDATE_FWDR_CURRENTPASS_ERR'
export const UPDATE_FWDR_PASSWORD = 'UPDATE_FWDR_PASSWORD'
export const UPDATE_FWDR_MAIL_SETTING = 'UPDATE_FWDR_MAIL_SETTING'
export const UPDATE_FWDR_SIGNATURE = 'UPDATE_FWDR_SIGNATURE'
export const UPDATE_FWDR_BACKUP = 'UPDATE_FWDR_BACKUP'
export const INIT_FWDR_TABLE = 'INIT_FWDR_TABLE'
export const SEARCH_FWDR_TABLE = 'SEARCH_FWDR_TABLE'
export const DELETE_FWDR_TABLE = 'DELETE_FWDR_TABLE'
export const UPDATE_FWDR_TABLE = 'UPDATE_FWDR_TABLE'
export const ADD_FWDR_TABLE = 'ADD_FWDR_TABLE'
export const UPDATE_FWDR_PAGING_DATA = 'UPDATE_FWDR_PAGING_DATA'
export const UPDATE_FWDR_TAB = 'UPDATE_FWDR_TAB'
export const UPDATE_FWDR_SEARCH_WORD = 'UPDATE_FWDR_SEARCH_WORD'
export const UPDATE_FWDR_CURRENCY = 'UPDATE_FWDR_CURRENCY'
export const UPDATE_FWDR_PER = 'UPDATE_FWDR_PER'
export const UPDATE_FWDR_ICON_PATH_INPUTERR = 'UPDATE_FWDR_ICON_PATH_INPUTERR'
export const UPDATE_FWDR_SIGNATURE_INPUTERR = 'UPDATE_FWDR_SIGNATURE_INPUTERR'
export const UPDATE_FWDR_SELECT_CURRENCY = 'UPDATE_FWDR_SELECT_CURRENCY'
export const UPDATE_FWDR_OWN_BRANCH = 'UPDATE_FWDR_OWN_BRANCH'
export const UPDATE_FWDR_GROUP = 'UPDATE_FWDR_GROUP'
export const UPDATE_FWDR_REPRESENTATIVE_FLG = 'UPDATE_REPRESENTATIVE_FLG'
export const UPDATE_FWDR_AUTHORITY_FLG = 'UPDATE_FWDR_AUTHORITY_FLG'
export const UPDATE_FWDR_PLAN = 'UPDATE_FWDR_PLAN'
export const UPDATE_FWDR_PLAN_INPUTERR = 'UPDATE_FWDR_PLAN_INPUTERR'

// 共通
export const UPDATE_BRANCH = 'UPDATE_BRANCH'
// ShipperPackingList
export const UPDATE_PACKING_LIST = 'UPDATE_PACKING_LIST'
export const DELETE_PACKING_LIST = 'DELETE_PACKING_LIST'
export const ADD_PACKING_LIST = 'ADD_PACKING_LIST'
export const UPDATE_GENRE = 'UPDATE_GENRE'
export const UPDATE_FWDR_SEARCH_AUTH = 'UPDATE_FWDR_SEARCH_AUTH'
export const UPDATE_FWDR_AWBSTATUS = 'UPDATE_FWDR_AWBSTATUS'
export const UPDATE_FWDR_UPDATE_AT = 'UPDATE_FWDR_UPDATE_AT'
export const UPDATE_FWDR_FILE = 'UPDATE_FWDR_FILE'
export const UPDATE_FWDR_IS_DELETE_ICON = 'UPDATE_FWDR_IS_DELETE_ICON'
export const UPDATE_FWDR_IS_DELETE_SHIPPING_ICON =
  'UPDATE_FWDR_IS_DELETE_SHIPPING_ICON'
export const UPDATE_FWDR_PASS_DIGIT_ERR = 'UPDATE_FWDR_PASS_DIGIT_ERR'
export const DELETE_TRACING_TRANSITION_INVOICE =
  'DELETE_TRACING_TRANSITION_INVOICE'
export const DELETE_TRACING_TRANSITION_PACKING =
  'DELETE_TRACING_TRANSITION_PACKING'
export const UPDATE_GENRE_TRACING = 'UPDATE_GENRE_TRACING'

// fwdrDocuments
export const UPDATE_HMFST_DATA = 'UPDATE_HMFST_DATA'
export const UPDATE_SIGNATURE_DATA = 'UPDATE_SIGNATURE_DATA'
export const UPDATE_MAWB_DATA = 'UPDATE_MAWB_DATA'
export const UPDATE_DGD_DATA = 'UPDATE_DGD_DATA'
export const UPDATE_CURRENCY_DATA = 'UPDATE_CURRENCY_DATA'
export const UPDATE_ALL_DATA = 'UPDATE_ALL_DATA'
export const UPDATE_HMDST_OTHER_CHARGES = 'UPDATE_HMDST_OTHER_CHARGES'

// Master Car
export const UPDATE_CAR_FIRST_NAME = 'UPDATE_CAR_FIRST_NAME'
export const UPDATE_CAR_FIRST_NAME_INPUTERR = 'UPDATE_CAR_FIRST_NAME_INPUTERR'
export const UPDATE_CAR_LAST_NAME = 'UPDATE_CAR_LAST_NAME'
export const UPDATE_CAR_LAST_NAME_INPUTERR = 'UPDATE_CAR_LAST_NAME_INPUTERR'
export const UPDATE_CAR_MAIL = 'UPDATE_CAR_MAIL'
export const UPDATE_CAR_MAIL_INPUTERR = 'UPDATE_CAR_MAIL_INPUTERR'
export const UPDATE_CAR_TEL = 'UPDATE_CAR_TEL'
export const UPDATE_CAR_TEL_INPUTERR = 'UPDATE_CAR_TEL_INPUTERR'
export const UPDATE_CAR_ICON_PATH = 'UPDATE_CAR_ICON_PATH'
export const UPDATE_CAR_NEWPASS = 'UPDATE_CAR_NEWPASS'
export const UPDATE_CAR_NEWPASS_INPUTERR = 'UPDATE_CAR_NEWPASS_INPUTERR'
export const UPDATE_CAR_NEWPASS_VERIFY = 'UPDATE_CAR_NEWPASS_VERIFY'
export const UPDATE_CAR_NEWPASS_VERIFY_INPUTERR =
  'UPDATE_SCAR_NEWPASS_VERIFY_INPUTERR'
export const UPDATE_CAR_PASS_VERIFY_ERR = 'UPDATE_CAR_PASS_VERIFY_ERR'
export const UPDATE_CAR_CURRENTPASS = 'UPDATE_CAR_CURRENTPASS'
export const UPDATE_CAR_CURRENTPASS_INPUTERR = 'UPDATE_CAR_CURRENTPASS_INPUTERR'
export const UPDATE_CAR_CURRENTPASS_ERR = 'UPDATE_CAR_CURRENTPASS_ERR'
export const UPDATE_CAR_PASSWORD = 'UPDATE_CAR_PASSWORD'
export const UPDATE_CAR_MAIL_SETTING = 'UPDATE_CAR_MAIL_SETTING'
export const UPDATE_CAR_POSITION = 'UPDATE_CAR_POSITION'
export const UPDATE_CAR_POSITION_INPUTERR = 'UPDATE_CAR_POSITION_INPUTERR'
export const UPDATE_CAR_SIGNATURE = 'UPDATE_CAR_SIGNATURE'
export const UPDATE_CAR_CODE = 'UPDATE_CAR_CODE'
export const UPDATE_CAR_CODE_INPUTERR = 'UPDATE_CAR_CODE_INPUTERR'
export const UPDATE_CAR_BACKUP = 'UPDATE_FWDR_BACKUP'
export const UPDATE_CAR_COMPANY_NAME = 'UPDATE_CAR_COMPANY_NAME'
export const UPDATE_CAR_COMPANY_NAME_INPUTERR =
  'UPDATE_CAR_COMPANY_NAME_INPUTERR'
export const UPDATE_CAR_TWO_LETTER_CODE = 'UPDATE_CAR_TWO_LETTER_CODE'
export const UPDATE_CAR_TWO_LETTER_CODE_INPUTERR =
  'UPDATE_CAR_TWO_LETTER_CODE_INPUTERR'
export const UPDATE_CAR_POSTAL_CODE = 'UPDATE_CAR_POSTAL_CODE'
export const UPDATE_CAR_POSTAL_CODE_INPUTERR = 'UPDATE_CAR_POSTAL_CODE_INPUTERR'
export const UPDATE_CAR_COMPANY_ADDRESS = 'UPDATE_CAR_COMPANY_ADDRESS'
export const UPDATE_CAR_COMPANY_ADDRESS_INPUTERR =
  'UPDATE_CAR_COMPANY_ADDRESS_INPUTERR'
export const UPDATE_CAR_DEPARTMENT = 'UPDATE_CAR_DEPARTMENT'
export const UPDATE_CAR_DEPARTMENT_INPUTERR = 'UPDATE_CAR_DEPARTMENT_INPUTERR'
export const UPDATE_CAR_IATACASS_NO = 'UPDATE_CAR_IATACASS_NO'
export const UPDATE_CAR_IATACASS_NO_INPUTERR = 'UPDATE_CAR_IATACASS_NO_INPUTERR'
export const UPDATE_CAR_DOCUMENT_ICON_PATH = 'UPDATE_CAR_DOCUMENT_ICON_PATH'
export const UPDATE_CAR_SHIPPING_ICON_PATH = 'UPDATE_CAR_SHIPPING_ICON_PATH'
export const UPDATE_CAR_ACCORDION = 'UPDATE_CAR_ACCORDION'
export const UPDATE_CAR_AIRPORT_CODE = 'UPDATE_CAR_AIRPORT_CODE'
export const UPDATE_CAR_AIRPORT_CODE_INPUTERR =
  'UPDATE_CAR_AIRPORT_CODE_INPUTERR'
export const UPDATE_CAR_COUNTRY = 'UPDATE_CAR_COUNTRY'
export const UPDATE_CAR_COUNTRY_INPUTERR = 'UPDATE_CAR_COUNTRY_INPUTERR'
export const UPDATE_CAR_BRANCH = 'UPDATE_CAR_BRANCH'
export const UPDATE_CAR_BRANCH_INPUTERR = 'UPDATE_CAR_BRANCH_INPUTERR'
export const INIT_CAR_TABLE = 'INIT_CAR_TABLE'
export const SEARCH_CAR_TABLE = 'SEARCH_CAR_TABLE'
export const DELETE_CAR_TABLE = 'DELETE_CAR_TABLE'
export const UPDATE_CAR_TABLE = 'UPDATE_CAR_TABLE'
export const ADD_CAR_TABLE = 'ADD_CAR_TABLE'
export const UPDATE_CAR_PAGING_DATA = 'UPDATE_CAR_PAGING_DATA'
export const UPDATE_CAR_SEARCH_WORD = 'UPDATE_CAR_SEARCH_WORD'
export const UPDATE_CAR_SEARCH_DEFAULT = 'UPDATE_CAR_SEARCH_DEFAULT'
export const UPDATE_CAR_SEARCH_CHECKED = 'UPDATE_CAR_SEARCH_CHECKED'
export const UPDATE_CAR_AIRPORT = 'UPDATE_CAR_AIRPORT'
export const UPDATE_CAR_AIRPORT_INPUTERR = 'UPDATE_CAR_AIRPORT_INPUTERR'
export const UPDATE_CAR_TT = 'UPDATE_CAR_TT'
export const UPDATE_CAR_AIRCRAFT = 'UPDATE_CAR_AIRCRAFT'
export const UPDATE_CAR_TAB = 'UPDATE_CAR_TAB'
export const UPDATE_CAR_CURRENCY = 'UPDATE_CAR_CURRENCY'
export const UPDATE_CAR_PER = 'UPDATE_CAR_PER'
export const UPDATE_CAR_SEARCH_AUTH = 'UPDATE_CAR_SEARCH_AUTH'
export const UPDATE_CAR_FORWARDER = 'UPDATE_CAR_FORWARDER'
export const UPDATE_CAR_VOIDMAWB = 'UPDATE_CAR_VOIDMAWB'
export const GET_FREIGHT_TABLE = 'GET_FREIGHT_TABLE'
export const GET_CRAFT_TABLE = 'GET_CRAFT_TABLE'
export const COMBINE_DATA = 'COMBINE_DATA'
export const ADD_FREIGHT_TABLE = 'ADD_FREIGHT_TABLE'
export const UPDATE_SELECT = 'UPDATE_SELECT'
export const SELECT_ORG_DST = 'SERECT_ORG_DST'
export const UPDATE_MIN_RATE = 'UPDATE_MIN_RATE'
export const UPDATE_NORMAL = 'UPDATE_NORMAL'
export const UPDATE_MIN = 'UPDATE_MIN'
export const UPDATE_LIGHT = 'UPDATE_LIGHT'
export const UPDATE_MEDIUM = 'UPDATE_MEDIUM'
export const UPDATE_LARGE = 'UPDATE_LARGE'
export const UPDATE_MAX = 'UPDATE_MAX'
export const UPDATE_CURRENCY = 'UPDATE_CURRENCY'
export const UPDATE_CLASS = 'UPDATE_CLASS'
export const INIT_ORG_DST_DATA = 'INIT_ORG_DST_DATA'
export const UPDATE_ORG_DST_DATA = 'UPDATE_ORG_DST_DATA'
export const UPDATE_SHOW_DATA = 'UPDATE_SHOW_DATA'
export const UPDATE_TEXT = 'UPDATE_TEXT'
export const GET_ROUTING = 'GET_ROUTING'
export const GET_DIALOG_DATA = 'GET_DIALOG_DATA'
export const UPDATE_FLIGHT_TIME = 'UPDATE_FLIGHT_TIME'
export const UPDATE_FLIGHT_NUMBER = 'UPDATE_FLIGHT_NUMBER'
export const UPDATE_REG_NUMBER = 'UPDATE_REG_NUMBER'
export const UPDATE_AIRCRAFT = 'UPDATE_AIRCRAFT'
export const UPDATE_ETD = 'UPDATE_ETD'
export const UPDATE_ETA = 'UPDATE_ETA'
export const UPDATE_ORG = 'UPDATE_ORG'
export const UPDATE_DST = 'UPDATE_DST'
export const UPDATE_TOTAL_DAYS = 'UPDATE_TOTAL_DAYS'
export const UPDATE_END_DATE = 'UPDATE_END_DATE'
export const UPDATE_SCHEDULE = 'UPDATE_SCHEDULE'
export const UPDATE_ERRORS = 'UPDATE_ERRORS'
export const UPDATE_VERSION = 'UPDATE_VERSION'
export const UPDATE_RELOAD_PAGE = 'UPDATE_RELOAD_PAGE'

export const UPDATE_CAR_UPDATE_AT = 'UPDATE_CAR_UPDATE_AT'
export const UPDATE_CAR_FILE = 'UPDATE_CAR_FILE'
export const UPDATE_CAR_IS_DELETE_ICON = 'UPDATE_CAR_IS_DELETE_ICON'
export const UPDATE_CAR_PASS_DIGIT_ERR = 'UPDATE_CAR_PASS_DIGIT_ERR'
export const UPDATE_CAR_PREFIX = 'UPDATE_CAR_PREFIX'
export const UPDATE_CAR_IS_DELETE_DOCUMENT_ICON =
  'UPDATE_CAR_IS_DELETE_DOCUMENT_ICON'
export const UPDATE_CAR_IS_DELETE_SHIPPING_ICON =
  'UPDATE_CAR_IS_DELETE_SHIPPING_ICON'
export const UPDATE_CAR_DOCUMENT_FILE = 'UPDATE_CAR_DOCUMENT_FILE'
export const UPDATE_CAR_SHIPPING_FILE = 'UPDATE_CAR_SHIPPING_FILE'
export const UPDATE_CAR_DOCUMENT_ICON_PATH_INPUTERR =
  'UPDATE_CAR_DOCUMENT_ICON_PATH_INPUTERR'
export const UPDATE_CAR_SHIPPING_ICON_PATH_INPUTERR =
  'UPDATE_CAR_SHIPPING_ICON_PATH_INPUTERR'
export const UPDATE_CAR_AIRCRAFT_DATA = 'UPDATE_CAR_AIRCRAFT_DATA'
export const UPDATE_DAY_CHECK = 'UPDATE_DAY_CHECK'
export const INIT_FILE_PATH = 'INIT_FILE_PATH'
export const UPDATE_CAR_SIGNATURE_INPUTERR = 'UPDATE_CAR_SIGNATURE_INPUTERR'
export const UPDATE_BOND_IN_ADDRESS = 'UPDATE_BOND_IN_ADDRESS'
export const UPDATE_BOND_IN_ADDRESS_INPUTERR = 'UPDATE_BOND_IN_ADDRESS_INPUTERR'
export const UPDATE_SERVICE_OFFICE = 'UPDATE_SERVICE_OFFICE'
export const UPDATE_SERVICE_OFFICE_IDS = 'UPDATE_SERVICE_OFFICE_IDS'
export const UPDATE_CAR_ACCORDION_EXPANDED = 'UPDATE_CAR_ACCORDION_EXPANDED'
export const UPDATE_CAR_REPRESENTATIVE_FLG = 'UPDATE_REPRESENTATIVE_FLG'
export const UPDATE_CAR_AUTHORITY_FLG = 'UPDATE_CAR_AUTHORITY_FLG'
export const UPDATE_CAR_ICON_PATH_INPUTERR = 'UPDATE_CAR_ICON_PATH_INPUTERR'
export const UPDATE_CAR_GSA_FLG = 'UPDATE_CAR_GSA_FLG'
export const UPDATE_CAR_PLAN = 'UPDATE_CAR_PLAN'
export const UPDATE_CAR_PLAN_INPUTERR = 'UPDATE_CAR_PLAN_INPUTERR'

export const UPDATE_FSC = 'UPDATE_FSC'

//deleteAccount
export const UPDATE_QUESTION1 = 'UPDATE_QUESTION1'
export const UPDATE_QUESTION2 = 'UPDATE_QUESTION2'
export const INITIALIZE_QUESTION2_TEXT = 'INITIALIZE_QUESTION2_TEXT'
export const UPDATE_QUESTION3 = 'UPDATE_QUESTION3'
export const UPDATE_QUESTION4 = 'UPDATE_QUESTION4'

// shipperTracing
export const INIT_NOTE = 'INIT_NOTE'
export const UPDATE_NOTE = 'UPDATE_NOTE'

export const SEARCH_SORT = {
  SET: 'SET_SEARCH_SORT',
}

export const SEARCH_BOX = {
  SET: 'SET_SEARCH_BOX',
}

export const TABLES = {
  INIT: 'INIT_TABLES',
  SET: 'SET_TABLES',
  EDIT: 'EDIT_TABLES',
  CLEAR: 'CLEAR_TABLES',
  SET_NOTIFY: 'SET_NOTIFY_TABLES',
}

export const PAGING = {
  SET: 'SET_PAGING',
}

export const CHECKBOX = {
  SET: 'SET_CHECKBOX',
  TOGGLE: 'TOGGLE_CHECKBOX',
}

export const SHIPPER_BID_DETAILS = {
  IS_NEW: {
    SET: 'SET_IS_NEW',
  },
  DATA: {
    SET: 'SET_DATA',
  },
}

export const SHIPPER_CONFIRMATION = {
  IS_NEW: {
    SET: 'SET_IS_NEW',
  },
  DATA: {
    SET: 'SET_DATA',
  },
}

export const FWDR_BID_DETAILS = {
  IS_NEW: {
    SET: 'SET_IS_NEW',
  },
  DATA: {
    SET: 'SET_DATA',
  },
}

export const FWDR_CONFIRMATION = {
  IS_NEW: {
    SET: 'SET_IS_NEW',
  },
  DATA: {
    SET: 'SET_DATA',
  },
}

export const API_DATA = {
  SET: 'SET_API_DATA',
  DELETE: 'DELETE_API_DATA',
}

export const ERROR = {
  SET: 'SET_ERROR',
}

export const SHIPPER_ADDRESS = {
  INIT: 'SHIPPER_ADDRESS_INIT',
}

export const PIC = {
  INIT: 'PIC_INIT',
  SELECT: 'PIC_SELECT',
  OPEN_ADD_DIALOG: 'PIC_OPEN_ADD_DIALOG',
  OPEN_EDIT_DIALOG: 'PIC_OPEN_EDIT_DIALOG',
  CLOSE_DIALOG: 'PIC_CLOSE_DIALOG',
  UPDATE: 'PIC_UPDATE',
  ADD: 'PIC_ADD',
  DELETE: 'PIC_DELETE',
}

export const CONSIGNEE = {
  SET_LIST: 'CONSIGNEE_SET_LIST',
  INIT: 'CONSIGNEE_INIT',
  SET: 'CONSIGNEE_SET',
}

export const DATEPICKER = {
  SET: 'SET_DATEPICKER',
  SET_START_FINISH: 'SET_DATEPICKER_START_FINISH',
}

export const SET_FWDR = {
  INIT: 'SET_FWDR_INIT',
  SELECT_RADIO: 'SET_FWDR_SELECT_RADIO',
}

export const SPECIAL_NOTES = {
  INIT: 'SPECIAL_NOTES_INIT',
  TOGGLE_DIALOG: 'SPECIAL_NOTES_TOGGLE_DIALOG',
  UPDATE_TEXT: 'SPECIAL_NOTES_UPDATE_TEXT',
}

export const PAGE_DATA = {
  INIT: 'PAGE_DATA_INIT',
}

export const LIGHT_REGISTER_NEW_ACCOUNT = {
  SELECT_DATA: {
    INIT: 'LIGHT_REGISTER_NEW_ACCOUNT_INIT',
  },
  COMPANY: {
    UPDATE_TEXT: 'COMPANY_UPDATE_TEXT',
  },
  SHIPPER_CONSIGNEE_ID: {
    UPDATE_TEXT: 'SHIPPER_CONSIGNEE_ID_UPDATE_TEXT',
  },
  BRANCH: {
    UPDATE_TEXT: 'BRANCH_UPDATE_TEXT',
  },
  FIRST_NAME: {
    UPDATE_TEXT: 'FIRST_NAME_UPDATE_TEXT',
  },
  LAST_NAME: {
    UPDATE_TEXT: 'LAST_NAME_UPDATE_TEXT',
  },
  POSITION: {
    UPDATE_TEXT: 'POSITION_UPDATE_TEXT',
  },
  EMAIL: {
    UPDATE_TEXT: 'EMAIL_UPDATE_TEXT',
  },
  TEL: {
    UPDATE_TEXT: 'TEL_UPDATE_TEXT',
  },
  POSTAL_CODE: {
    UPDATE_TEXT: 'POSTAL_CODE_UPDATE_TEXT',
  },
  ADDRESS: {
    UPDATE_TEXT: 'ADDRESS_UPDATE_TEXT',
  },
  AIRPORT: {
    UPDATE_TEXT: 'AIRPORT_UPDATE_TEXT',
  },
  ICON: {
    UPDATE_ICON: 'ICON_UPDATE',
    DELETE_ICON: 'ICON_DELETE',
    GSA: 'ICON_GSA',
    CARRIER: 'ICON_CARRIER',
  },
  AGREE_CHECK: {
    UPDATE: 'AGREE_CHECK_UPDATE',
  },
  FWDR_NAME: {
    UPDATE: 'FWDR_NAME_UPDATE',
  },
  IATA_CASS_NO: {
    UPDATE: 'IATA_CASS_NO_UPDATE',
    GSA: 'IATA_CASS_NO_GSA',
    CARRIER: 'IATA_CASS_NO_CARRIER',
  },
  BOND_IN_ADDRESS: {
    UPDATE: 'BOND_IN_ADDRESS_UPDATE',
  },
  CARRIER_NAME: {
    UPDATE: 'CARRIER_NAME_UPDATE',
  },
  DEPARTMENT: {
    UPDATE: 'DEPARTMENT_UPDATE',
    GSA: 'DEPARTMENT_GSA',
    CARRIER: 'DEPARTMENT_CARRIER',
  },
  PLAN: {
    INIT: 'PLAN_INIT',
  },
  FWDR_PLAN: {
    UPDATE: 'FWDR_PLAN_UPDATE',
  },
  CAR_PLAN: {
    UPDATE: 'CAR_PLAN_UPDATE',
  },
  SHIP_PLAN: {
    UPDATE: 'SHIP_PLAN_UPDATE',
  },
}

export const AIRPORT = {
  SET: 'SET_AIRPORT',
}

export const TRANSIT_TIME = {
  SET: 'SET_TRANSIT_TIME',
}

export const COUNTRY_CODE = {
  SET: 'SET_COUNTRY_CODE',
}

export const TABS = {
  SET: 'SET_TABS',
}

export const LIGHT_FLIGHT_MGMT = {
  EDIT: {
    INIT: 'LIGHT_FLIGHT_MGMT_INIT',
    ROUTING: 'LIGHT_FLIGHT_MGMT_EDIT_ROUTING',
    FLIGHT_SCHEDULE: 'LIGHT_FLIGHT_MGMT_EDIT_FLIGHT_SCHEDULE',
    FLIGHT_INFO: 'LIGHT_FLIGHT_MGMT_EDIT_FLIGHT_INFO',
  },
  ADD: {
    ROUTING: 'LIGHT_FLIGHT_MGMT_ADD_ROUTING',
    FLIGHT_SCHEDULE: 'LIGHT_FLIGHT_MGMT_ADD_FLIGHT_SCHEDULE',
  },
  TAB: {
    SET: 'LIGHT_FLIGHT_MGMT_SET_TAB',
  },
  ICON_TABLE: {
    SET: 'LIGHT_FLIGHT_MGMT_SET_ICON_TABLE',
    EDIT_ICON_TABLES: 'LIGHT_FLIGHT_MGMT_EDIT_ICON_TABLES',
  },
  TABLES: {
    CHANGE_SHOW_DATA: 'LIGHT_FLIGHT_MGMT_CHANGE_SHOW_DATA',
    SET_OPEN: 'LIGHT_FLIGHT_MGMT_SET_OPEN_TABLES',
  },
  ICON_POPUP: {
    SET: 'LIGHT_FLIGHT_MGMT_SET_ICON_POPUP',
    EDIT: 'LIGHT_FLIGHT_MGMT_EDIT_ICON_POPUP',
    SET_OPEN: 'LIGHT_FLIGHT_MGMT_SET_OPEN_ICON_POPUP',
  },
}

export const DIALOG = {
  OPEN_REJECT: 'OPEN_REJECT_DIALOG',
  OPEN_SUCCESS: 'OPEN_SUCCESS_DIALOG',
  CLOSE: 'CLOSE_DIALOG',
}

export const GSA = {
  SET: 'SET_GSA',
}

export const CARRIER_NAME = {
  SET: 'SET_CARRIER_NAME',
}

export const CURRENCY = {
  SET: 'SET_CURRENCY',
}

export const LIGHT_AIR_FREIGHT = {
  FORWARDER: {
    SET: 'LIGHT_AIR_FREIGHT_SET_FORWARDER',
  },
  CURRENCY: {
    SET: 'LIGHT_AIR_FREIGHT_SET_CURRENCY',
    EDIT: 'LIGHT_AIR_FREIGHT_EDIT_CURRENCY',
  },
}

export const SHC_DIALOG = {
  SET_OPEN_FUNC: 'SHC_DIALOG_SET_OPEN_FUNC',
  OPEN: 'SHC_DIALOG_OPEN',
}

export const LIGHT_BOOKING_DETAILS = {
  SHIPPING_DETAILS: {
    SET: 'LIGHT_BOOKING_DETAILS_SET_SHIPPING_DETAILS',
  },
  MEASURE_AND_WEIGHT_POPUP: {
    SET_OPEN_FUNC:
      'LIGHT_BOOKING_DETAILS_MEASURE_AND_WEIGHT_POPUP_SET_OPEN_FUNC',
    OPEN: 'LIGHT_BOOKING_DETAILS_MEASURE_AND_WEIGHT_POPUP_OPEN',
    EDIT: 'LIGHT_BOOKING_DETAILS_MEASURE_AND_WEIGHT_POPUP_EDIT',
  },
  FLIGHT: {
    SET: 'LIGHT_BOOKING_DETAILS_FLIGHT_SET',
    EDIT_INTER_LINE: 'LIGHT_BOOKING_DETAILS_FLIGHT_EDIT_INTER_LINE',
  },
  FLIGHT_POPUP: {
    SET_OPEN_FUNC: 'LIGHT_BOOKING_DETAILS_FLIGHT_POPUP_SET_OPEN_FUNC',
    OPEN: 'LIGHT_BOOKING_DETAILS_FLIGHT_POPUP_OPEN',
    EDIT: 'LIGHT_BOOKING_DETAILS_FLIGHT_POPUP_EDIT',
  },
  MAWB: {
    SET: 'LIGHT_BOOKING_DETAILS_MAWB_SET',
    EDIT: 'LIGHT_BOOKING_DETAILS_MAWB_EDIT',
  },
  SHC_POPUP: {
    SET_OPEN_FUNC: 'LIGHT_BOOKING_DETAILS_SHC_POPUP_SET_OPEN_FUNC',
    OPEN: 'LIGHT_BOOKING_DETAILS_SHC_POPUP_OPEN',
    EDIT: 'LIGHT_BOOKING_DETAILS_SHC_POPUP_EDIT',
  },
  SHC_ARR: {
    SET: 'LIGHT_BOOKING_DETAILS_SHC_ARR_SET',
    EDIT: 'LIGHT_BOOKING_DETAILS_SHC_ARR_EDIT',
  },
  REMARK_POPUP: {
    SET_OPEN_FUNC: 'LIGHT_BOOKING_DETAILS_REMARK_POPUP_SET_OPEN_FUNC',
    OPEN: 'LIGHT_BOOKING_DETAILS_REMARK_POPUP_OPEN',
    EDIT: 'LIGHT_BOOKING_DETAILS_REMARK_POPUP_EDIT',
  },
  REMARK: {
    SET: 'LIGHT_BOOKING_DETAILS_REMARK_SET',
    EDIT: 'LIGHT_BOOKING_DETAILS_REMARK_EDIT',
  },
  DOCUMENT: {
    SET: 'LIGHT_BOOKING_DETAILS_DOCUMENT_SET',
    ADD: 'LIGHT_BOOKING_DETAILS_DOCUMENT_ADD',
    DELETE: 'LIGHT_BOOKING_DETAILS_DOCUMENT_DELETE',
  },
  CANCEL_BOOKING: {
    SET: 'LIGHT_BOOKING_DETAILS_CANCEL_BOOKING_SET',
    EDIT: 'LIGHT_BOOKING_DETAILS_CANCEL_BOOKING_EDIT',
  },
  STATUS: {
    SET: 'LIGHT_BOOKING_DETAILS_STATUS_SET',
  },
  CARGO: {
    SET_CARGO_DETAILS: 'SET_CARGO_DETAILS',
    EDIT_CARGO_DETAILS: 'EDIT_CARGO_DETAILS',
  },
  RATE: {
    SET_RATE: 'SET_RATE',
    EDIT_RATE: 'EDIT_RATE',
  },
  SPECIAL_HANDLING_CODE: {
    SET: 'SPECIAL_HANDLING_CODE_SET',
  },
}

export const LIGHT_BOOKING_REQUEST = {
  SELECT: {
    SET: 'SET_SELECT',
  },
  AIRPORT: {
    SET: 'SET_AIRPORT',
    EDIT: 'EDIT_AIRPORT',
  },
  COMMODITY: {
    SET: 'SET_COMMODITY',
    EDIT: 'EDIT_COMMODITY',
  },
  CARGO_DETAILS: {
    SET: 'SET_CARGO_DETAILS',
  },
}

export const LIGHT_JH_CLIENT_LIST = {
  TABLES: {
    CHANGE_SHOW_DATA: 'LIGHT_JH_CLIENT_LIST_TABLES_CHANGE_SHOW_DATA',
  },
}

export const PLAN = {
  SET: 'SET_PLAN',
}

export const TOTAL_ACCOUNT = {
  SET: 'SET_TOTAL_ACCOUNT',
}
