import React, { useContext } from 'react'

import AppContext from '../../../../../contexts/AppContext'
import CommonTableContainer from '../../../../common/table/CommonTableContainer'
import IconTableContentsLightCarFlightMgmt from './IconTableContentsLightCarFlightMgmt'

/**
 * @return {JSX} 表コンポーネント(全体)
 */
const IconTableLightCarFlightMgmt = () => {
  const { state } = useContext(AppContext)

  return (
    <CommonTableContainer isNoResult={state.Tables.isNoResult}>
      <IconTableContentsLightCarFlightMgmt />
    </CommonTableContainer>
  )
}

export default IconTableLightCarFlightMgmt
