import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import TextField from '../../common/text/TextField'
import CommonValidation from '../../common/function/CommonValidation'
import {
  UPDATE_CAR_COMPANY_ADDRESS,
  UPDATE_CAR_COMPANY_ADDRESS_INPUTERR,
} from '../../../actions'

const useStyles = makeStyles((theme) => ({
  head: theme.textBoldBLU,
  textBlk: {
    ...theme.textNormalBLK,
    marginLeft: '1rem',
    wordWrap: 'break-word',
  },
}))

/**
 * コンポーネントを更新するか判定する
 * @param {Object} prev 前回の値
 * @param {Object} next 最新の値
 * @returns {Boolean} falseの場合更新する
 */
const areEqual = (prev, next) => {
  // 各入力欄の値を比較
  const addressValue =
    prev.companyinfo.address.value === next.companyinfo.address.value
  const authorityFlgValue =
    prev.companyinfo.authorityFlg.value === next.companyinfo.authorityFlg.value

  // 全て一致していれば更新無し
  return addressValue && authorityFlgValue
}

const CompanyAddress = React.memo(({ dispatch, companyinfo }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [inputErr, setInputErr] = useState(false)
  const [helperTxt, setHelperTxt] = useState('')
  const { address, authorityFlg } = companyinfo
  const authorityFlgValue = authorityFlg.value

  const handleChange = (event) => {
    const value = event.target.value
    // 入力チェック
    const validationCheck = CommonValidation.address(value)
    const err = validationCheck.checkResult
    setHelperTxt(validationCheck.errMsg)
    setInputErr(err)
    dispatch({
      type: UPDATE_CAR_COMPANY_ADDRESS,
      address: value,
    })

    // 入力エラーの場合、Saveボタン無効
    dispatch({
      type: UPDATE_CAR_COMPANY_ADDRESS_INPUTERR,
      inputErr: err,
    })
  }

  // 編集用
  const createTextField = () => {
    return (
      <Grid item md={9} xs={10}>
        <TextField
          id="address"
          error={inputErr}
          helperText={t(helperTxt)}
          value={address.value}
          onChange={handleChange}
        />
      </Grid>
    )
  }

  // 表示用
  const createTextLabel = () => {
    return (
      <Grid item md={10} xs={10}>
        <div className={classes.textBlk}>{address.value}</div>
      </Grid>
    )
  }

  return (
    <div className={classes.formControl}>
      <Grid container>
        <Grid item md={10} xs={10}>
          <div className={classes.head}>{t('D301V0003')}</div>
        </Grid>
        {authorityFlgValue ? createTextField() : createTextLabel()}
      </Grid>
    </div>
  )
}, areEqual)

CompanyAddress.displayName = 'CompanyAddress'

CompanyAddress.propTypes = {
  dispatch: PropTypes.func.isRequired,
  companyinfo: PropTypes.object.isRequired,
}

export default CompanyAddress
