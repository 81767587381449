import React from 'react'
import PropTypes from 'prop-types'

import TextField from '../../../common/text/TextField'

/**
 * @param {Object} props 下記内容
 * @param {String} props.remark remarkの値
 * @param {String} props.placeholder placeholder
 * @return {JSX} Remark入力欄
 */
const RemarkTextFieldLightBookingDetails = ({ remark, placeholder }) => {
  return <TextField value={remark ?? ''} disabled placeholder={placeholder} />
}
RemarkTextFieldLightBookingDetails.propTypes = {
  remark: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
}
export default RemarkTextFieldLightBookingDetails
