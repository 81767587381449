import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import IconBox from '../../../common/info/IconBox'

const useStyles = makeStyles((theme) => ({
  head: theme.textBoldBLU,
  // eslint-disable-next-line camelcase
  text_blk: theme.textNormalBLK,
}))

const Icon = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.formControl}>
      <Grid container>
        <Grid item md={10} xs={10}>
          <div className={classes.head}>{t('D301V0019')}</div>
        </Grid>
        <Grid item md={10} xs={10}>
          <IconBox btnflg={false} iconPath={props.iconPath} />
        </Grid>
      </Grid>
    </div>
  )
}

export default Icon
