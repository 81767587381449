import React, { useReducer, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import LayoutBox from '../../../common/layout/LayoutBox'
import reducer, {
  initialState,
} from '../../../../reducers/airline/lightAirlineBookingList'
import AppContext from '../../../../contexts/AppContext'
import ApiPaths from '../../../../constants/ApiPaths'
import CommonFunc from '../../../common/function/CommonFunc'
import { GSA, SEARCH_SORT, TABLES } from '../../../../actions'

import TitleLightAirlineBookingList from './00TitleLightAirlineBookingList/TitleLightAirlineBookingList'
import SearchSortLightAirlineBookingList from './01SearchSortLightAirlineBookingList/SearchSortLightAirlineBookingList'
import TableLightAirlineBookingList from './02TableLightAirlineBookingList/TableLightAirlineBookingList'
import PagingLightAirlineBookingList from './03PagingLightAirlineBookingList/PagingLightAirlineBookingList'
import BackButtonLightAirlineBookingList from './04BackButtonLightAirlineBookingList/BackButtonLightAirlineBookingList'
import ConstantsLightAirlineBookingList from './ConstantsLightAirlineBookingList'

const LightAirlineBookingList = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    ;(async () => {
      const apiInfoArr = [
        {
          key: 'bookingList',
          method: 'get',
          path: ApiPaths.AIRLINE.LIGHT_BOOKING_LIST,
        },
        {
          key: 'defaultAirport',
          method: 'get',
          path: ApiPaths.AIRLINE.CAR_DEFAULT_AIRPORT,
        },
        { key: 'gsa', method: 'get', path: ApiPaths.MASTER_CAR.LIGHT_GSA },
      ]
      const resObj = await CommonFunc.execApiAsync(apiInfoArr)
      const isApiSuccessful = CommonFunc.checkApiResponseObj(resObj, history)

      if (isApiSuccessful) {
        const { bookingList, defaultAirport, gsa } = resObj
        const { airportCode } = defaultAirport.data.results[0]
        const { gsaFlg } = gsa.data.results

        const { GSA_SORT_ITEM_ARR, AIRLINE_SORT_ITEM_ARR } =
          ConstantsLightAirlineBookingList
        const sortItemArr = gsaFlg ? GSA_SORT_ITEM_ARR : AIRLINE_SORT_ITEM_ARR

        // ソートの初期値は定数の最初の値で固定
        const initSort = sortItemArr[0].sortTerm

        //SearchSortの初期値を設定
        dispatch({
          type: SEARCH_SORT.SET,
          search: '',
          sort: initSort,
          defaultStr: `${t('D212V0003')} ${airportCode}`,
          defaultSearchWord: airportCode,
        })
        // テーブルの初期値を設定
        dispatch({
          type: TABLES.SET,
          tableData: bookingList.data.results,
          sort: initSort,
          defaultSearchWord: airportCode,
          gsa: gsaFlg,
        })
        // GSAフラグを保持
        dispatch({
          type: GSA.SET,
          gsa: gsaFlg,
        })
      }
    })()
  }, []) //eslint-disable-line

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <LayoutBox>
        <TitleLightAirlineBookingList />
        <SearchSortLightAirlineBookingList />
        <TableLightAirlineBookingList />
        <PagingLightAirlineBookingList />
        <BackButtonLightAirlineBookingList />
      </LayoutBox>
    </AppContext.Provider>
  )
}

export default LightAirlineBookingList
