import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'

import SecondaryButton from '../../buttons/SecondaryButton'

/**
 * @param {Object} props Cancelボタンに追加したい設定
 * @returns {JSX} 共通ダイアログキャンセルボタンコンポーネント
 */
const CancelButtonCommonDialog = (props) => {
  const { t } = useTranslation()

  return (
    <Grid container item xs justify="center">
      <Grid item>
        <SecondaryButton {...props}>{t('D004V0063')}</SecondaryButton>
      </Grid>
    </Grid>
  )
}

export default CancelButtonCommonDialog
