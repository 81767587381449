import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles((theme) => ({
  box: {
    ...theme.textNormalRED,
    backgroundColor: theme.palette.info.main,
    padding: '0.5rem',
    borderColor: theme.palette.required,
  },
}))

/**
 * 定数から作成するヘッダーコンポーネント(1列分)
 * 変更したい場合は定数ConstantsLightDeleteAccountDoneを操作する
 * @param {Array} tableArr tableArr
 * @return {JSX} ヘッダーコンポーネント(1列分)
 */
const CellLightDeleteAccount = (tableArr) => {
  const classes = useStyles()
  const { t } = useTranslation()

  /**
   * 定数で定めたヘッダーコンポーネント(1個分)を作成
   * @param {String} { headerKey } 定数に設定している文言key
   * @return {JSX} ヘッダーコンポーネント(1個分)
   */
  const createHeaderCell = ({ headerKey }) => {
    return (
      <Grid key={headerKey} className={classes.box}>
        {t(headerKey)}
      </Grid>
    )
  }

  return tableArr.table.map((data, index) => {
    return <Grid key={index}>{createHeaderCell(data)}</Grid>
  })
}

export default CellLightDeleteAccount
