import { LIGHT_BOOKING_DETAILS } from '../../../actions'
import CommonCalc from '../../../components/common/function/CommonCalc'

/**
 * @typedef {Object} CargoDetails 貨物情報
 * @property {Number} cargoDetailsId
 * @property {Number} pcs
 * @property {Number} grossWgt
 * @property {Number} length
 * @property {Number} width
 * @property {Number} height
 * @property {Number} volume
 * @property {String} pcsStr
 * @property {String} grossWgtStr
 * @property {String} lengthStr
 * @property {String} widthStr
 * @property {String} heightStr
 * @property {String} volumeStr
 * @property {String} updatedAt
 * @property {Number} nonStackable
 * @property {Number} hazardous
 */

/**
 * 貨物の各値を合算する
 * @param {CargoDetails[]} cargoDetails 貨物の配列
 * @returns {Object} 合算した値が入ったオブジェクト
 */
const calcTotal = (cargoDetails) => {
  const totalPcs = cargoDetails.reduce((total, { pcs }) => total + pcs, 0)
  const totalGrossWgt = cargoDetails.reduce(
    (total, { grossWgt, pcs }) => total + grossWgt * pcs,
    0
  )
  const totalVolume = cargoDetails.reduce(
    (total, { volume }) => total + volume,
    0
  )
  const totalChargeableWgt = cargoDetails.reduce(
    (total, { chargeableWgt }) => total + chargeableWgt,
    0
  )
  return {
    totalPcs,
    totalGrossWgt,
    totalVolume,
    totalChargeableWgt,
    totalPcsStr: CommonCalc.addComma(totalPcs),
    totalGrossWgtStr: CommonCalc.addComma(totalGrossWgt, true),
    totalVolumeStr: CommonCalc.addComma(totalVolume, true),
    totalChargeableWgtStr: CommonCalc.addComma(totalChargeableWgt, true),
  }
}

/** @type {Object} 貨物の初期データ */
const initCargo = {
  cargoDetails: [],
  ...calcTotal([]),
}

/**
 * 貨物の値を修正する
 * @param {Object} cargoDetail 貨物情報
 * @returns {CargoDetails} 修正後の貨物情報
 */
const fixCargoDetail = (cargoDetail) => {
  const {
    length,
    width,
    height,
    pcs,
    grossWgt,
    cargoDetailsId,
    updatedAt,
    nonStackable,
    hazardous,
  } = cargoDetail
  const pcsNum = parseInt(pcs)
  const widthNum = parseInt(width)
  const heightNum = parseInt(height)
  const lengthNum = parseInt(length)
  const grossWgtNum = parseFloat(CommonCalc.ceil(grossWgt, 99999.99))
  const chargeableWgt = CommonCalc.chargeableWeight(
    pcsNum,
    grossWgtNum,
    lengthNum,
    widthNum,
    heightNum
  )
  const volume = parseFloat(
    CommonCalc.volume(lengthNum, widthNum, heightNum, pcsNum)
  )
  return {
    pcs: pcsNum,
    grossWgt: grossWgtNum,
    length: lengthNum,
    width: widthNum,
    height: heightNum,
    volume,
    chargeableWgt,
    pcsStr: CommonCalc.addComma(pcsNum),
    grossWgtStr: CommonCalc.addComma(grossWgtNum, true),
    lengthStr: CommonCalc.addComma(lengthNum),
    widthStr: CommonCalc.addComma(widthNum),
    heightStr: CommonCalc.addComma(heightNum),
    volumeStr: CommonCalc.addComma(volume, true),
    chargeableWgtStr: CommonCalc.addComma(chargeableWgt, true),
    cargoDetailsId,
    updatedAt,
    nonStackable,
    hazardous,
  }
}

/**
 * リクエストデータを作成する
 * @param {CargoDetails[]} cargoDetails 貨物情報の配列
 * @param {CargoDetails[]} initCargoDetails 編集前の貨物情報の配列
 * @returns {Object[]} リクエストデータ
 */
const createRequestData = (cargoDetails, initCargoDetails) =>
  cargoDetails
    .map((cargoDetail, index) => {
      /** @type {String[]} 編集後と編集前を比較する値名 */
      const paramNameArr = ['pcs', 'grossWgt', 'length', 'width', 'height']
      /** @type {Boolean} 編集されたか */
      const isEdited = paramNameArr.some(
        (name) => cargoDetail[name] !== initCargoDetails[index][name]
      )
      // 編集されている、または新規登録の貨物は登録
      if (isEdited || !cargoDetail.cargoDetailsId) {
        const {
          cargoDetailsId = null,
          pcs,
          grossWgt,
          length,
          width,
          height,
          volume,
          updatedAt = null,
          nonStackable,
          hazardous,
        } = cargoDetail
        return {
          cargoDetailsId,
          pcs,
          grossWgt,
          length,
          width,
          height,
          volume,
          updatedAt,
          nonStackableFlg: nonStackable,
          hazardusFlg: hazardous,
        }
      } else {
        //編集されていない貨物は登録しない
        return null
      }
    })
    .filter((v) => v)

/**
 * 貨物情報を保持する
 * @param {Object} action action
 * @param {Object} state state
 * @returns {Object} state
 */
const setCargoDetails = (action, state) => {
  const cargoDetails = action.cargoDetails.map(fixCargoDetail)
  const initCargoDetails = action.cargoDetails.map(fixCargoDetail)
  const getRequestData = () => createRequestData(cargoDetails, initCargoDetails)
  return {
    ...state,
    cargoDetails,
    initCargoDetails,
    getRequestData,
    ...calcTotal(cargoDetails),
  }
}

/**
 * 貨物情報を編集する
 * @param {Object} action action
 * @param {Object} state state
 * @returns {Object} state
 */
const editCargoDetails = (action, state) => {
  const { index, cargoDetail } = action
  state.cargoDetails[index] = fixCargoDetail(cargoDetail)
  return { ...state, ...calcTotal(state.cargoDetails) }
}

/**
 * 貨物の編集データを保持
 * @param { Object } [state = initIconPopup] state
 * @param { Object } action action
 * @return { Object } state
 */
const Cargo = (state = initCargo, action) => {
  const { type, ...rest } = action
  switch (type) {
    case LIGHT_BOOKING_DETAILS.CARGO.SET_CARGO_DETAILS:
      return setCargoDetails(rest, state)
    case LIGHT_BOOKING_DETAILS.CARGO.EDIT_CARGO_DETAILS:
      return editCargoDetails(rest, state)
    default:
      return state
  }
}

export default Cargo

export { initCargo }
