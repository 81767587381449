import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import TextField from '../../../common/text/TextField'
import AppContext from '../../../../contexts/AppContext'
import CommonValidation from '../../../common/function/CommonValidation'
import { UPDATE_SHIP_TEL, UPDATE_SHIP_TEL_INPUTERR } from '../../../../actions'

const useStyles = makeStyles((theme) => ({
  head: theme.textBoldBLU,
}))

const ContactInfo = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [inputErrs, setInputErrs] = useState({ tel: false })
  const [helperTxts, setHelperTxts] = useState({ tel: '' })
  const { state, dispatch } = useContext(AppContext)

  const handleChangeTel = (event) => {
    const value = event.target.value
    // 入力チェック
    const validationCheck = CommonValidation.telephone(value)
    const err = validationCheck.checkResult
    setInputErrs({ ...inputErrs, tel: err })
    setHelperTxts({ ...helperTxts, tel: validationCheck.errMsg })

    dispatch({
      type: UPDATE_SHIP_TEL,
      tel: value,
    })

    // 入力エラーの場合、Savaボタン無効
    dispatch({
      type: UPDATE_SHIP_TEL_INPUTERR,
      inputErr: err,
    })
  }

  return (
    <div className={classes.formControl}>
      <Grid container>
        <Grid item md={4} xs={10}>
          <div className={classes.head}>{t('D301V0011')}</div>
          <TextField
            id="tel"
            error={inputErrs.tel}
            helperText={t(helperTxts.tel)}
            value={state.myprofile.tel.value}
            onChange={handleChangeTel}
            autoComplete="off"
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default ContactInfo
