import { CHECKBOX } from '../../../actions'

/** 初期表示 */
const initCheckbox = false

/**
 * チェックボックス状態を保持
 * @param {Boolean} [state=initCheckbox] 初期表示
 * @param {Object} action isNew
 * @return {Boolean} ページ状態
 */
const Checkbox = (state = initCheckbox, action) => {
  const { type } = action
  switch (type) {
    case CHECKBOX.SET:
      return action.checked
    default:
      return state
  }
}

export default Checkbox

export { initCheckbox }
