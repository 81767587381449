// Display + 画面名
const DisplayShipperOfferingBid = {
  // 画面ID + 文言ID
  D006V0001: {
    en: 'Owner ',
    ja: 'オーナー',
  },
  D006V0002: {
    en: 'Panasonic Corporation',
    ja: 'パナソニック株式会社',
  },
  D006V0003: {
    en: '1006,Oaza Kadoma,Kadoma-shi,Osaka 571-8501,Japan',
    ja: '日本 571-8501 大阪府門真市大字門1006番地',
  },
  D006V0004: {
    en: 'Tel.',
    ja: '電話番号：',
  },
  D006V0005: {
    en: '+81-6-6908-1121',
    ja: '+81-6-6908-1121',
  },
  D006V0006: {
    en: 'Representative',
    ja: '代表者',
  },
  D006V0007: {
    en: 'Enter your name',
    ja: '名前を入力してください',
  },
  D006V0008: {
    en: 'Position ',
    ja: '役職',
  },
  D006V0009: {
    en: 'Department',
    ja: '部門',
  },
  D006V0010: {
    en: 'E-mail',
    ja: '電子メール',
  },
  D006V0011: {
    en: 'Validty',
    ja: '有効性',
  },
  D006V0012: {
    en: 'yy/MM/dd',
    ja: '年/月/日',
  },
  D006V0013: {
    en: 'Set FWDR',
    ja: '混載業者を決める',
  },
  D006V0014: {
    en: 'Unspecified',
    ja: '不特定',
  },
  D006V0015: {
    en: 'Specified',
    ja: '特定',
  },
  D006V0016: {
    en: '--Select--',
    ja: '--選択--',
  },
  D006V0017: {
    en: 'DZK',
    ja: 'DZK',
  },
  D006V0018: {
    en: 'KNJ',
    ja: 'KNJ',
  },
  D006V0019: {
    en: 'YLK',
    ja: 'YLK',
  },
  D006V0020: {
    en: 'NEC',
    ja: 'NEC',
  },
  D006V0021: {
    en: 'EIK',
    ja: 'EIK',
  },
  D006V0022: {
    en: 'KWE',
    ja: 'KWE',
  },
  D006V0023: {
    en: 'HEI',
    ja: 'HEI',
  },
  D006V0024: {
    en: 'DZK/YLK/HEI/KNJ/EIK/KWE',
    ja: 'DZK/YLK/HEI/KNJ/EIK/KWE',
  },
  D006V0025: {
    en: 'Currency',
    ja: '通貨',
  },
  D006V0026: {
    en: 'JPY',
    ja: '日本円',
  },
  D006V0027: {
    en: 'US$',
    ja: '米ドル',
  },
  D006V0028: {
    en: '1st Deadline',
    ja: '一次締め切り',
  },
  D006V0029: {
    en: 'Special Notes',
    ja: '備考',
  },
  D006V0030: {
    en: 'Request Lanes',
    ja: 'レートをリクエスト',
  },
  D006V0031: {
    en: 'ORG/DST',
    ja: '行先/到着',
  },
  D006V0032: {
    en: 'Via',
    ja: '経由',
  },
  D006V0033: {
    en: 'TTL Wgt',
    ja: '重さ',
  },
  D006V0034: {
    en: 'TTL Vol',
    ja: 'サイズ',
  },
  D006V0035: {
    en: 'SHC',
    ja: 'SHC',
  },
  D006V0036: {
    en: '+45kg',
    ja: '+45kg',
  },
  D006V0037: {
    en: '+100kg',
    ja: '+100kg',
  },
  D006V0038: {
    en: '+300kg',
    ja: '+300kg',
  },
  D006V0039: {
    en: '+500kg',
    ja: '+500kg',
  },
  D006V0040: {
    en: '+1,000kg',
    ja: '+1,000kg',
  },
  D006V0041: {
    en: 'Enter kg',
    ja: 'kgを入力',
  },
  D006V0042: {
    en: 'Enter m3',
    ja: 'm3を入力',
  },
  D006V0043: {
    en: 'Please click the box if you agree the above conditions.',
    ja: '上記の条件に同意する場合はクリックしてください。',
  },
  D006V0044: {
    en: 'Back',
    ja: '戻る',
  },
  D006V0045: {
    en: 'Save as Draft',
    ja: '下書きとして保存',
  },
  D006V0046: {
    en: 'BID',
    ja: '入札',
  },
  D006V0047: {
    en: '-',
    ja: '-',
  },
  D006V0048: {
    en: '/',
    ja: '/',
  },
  D006V0049: {
    en: 'Delete',
    ja: '削除',
  },
  D006V0050: {
    en: 'Add',
    ja: '追加',
  },
  D006V0051: {
    en: 'Managing BID',
    ja: '入札管理',
  },
  D006V0052: {
    en: 'Offering BID',
    ja: '入札オファー',
  },
  D006V0053: {
    en: 'OFFERING BID',
    ja: '入札オファー',
  },
  D006V0054: {
    en: 'to',
    ja: '～',
  },
  D006V0055: {
    en: 'Tel No.',
    ja: '電話番号',
  },
  D006V0056: {
    en: 'Disclose',
    ja: '公開',
  },
  D006V0057: {
    en: 'Undisclose',
    ja: '非公開',
  },
  D006V0058: {
    en: '2nd Deadline',
    ja: '二次締め切り',
  },
  D006V0059: {
    en: '3rd Deadline',
    ja: '三次締め切り',
  },
  D006V0060: {
    en: 'OK',
    ja: '決定',
  },
  D006V0061: {
    en: 'Cancel',
    ja: 'キャンセル',
  },
  D006V0062: {
    en: 'Select',
    ja: '選択',
  },
  D006V0063: {
    en: 'Edit',
    ja: '編集',
  },
}
// Display + 画面名
export default DisplayShipperOfferingBid
