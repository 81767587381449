import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

import AppContext from '../../../contexts/AppContext'
import Theme from '../../common/theme/Theme'
import CommonValidation from '../../common/function/CommonValidation'
import { LIGHT_REGISTER_NEW_ACCOUNT } from '../../../actions'
import IconBox from '../../common/info/IconBox'
import DeleteSmallDialog from '../../common/dialog/DeleteSmallDialog'
import AuthenticationTableContainer from '../../common/table/AuthenticationTableContainer'

const useStyles = makeStyles(() => ({
  asterisk: Theme.signup.asterisk,
  iconTitle: Theme.signup.iconTitle,
  iconReq: Theme.signup.iconReq,
}))

/**
 * @returns {JSX} - 会社アイコンコンポーネント
 */
const CompanyIcon = () => {
  const { state, dispatch } = useContext(AppContext)
  const { t } = useTranslation()
  const classes = useStyles()

  return useMemo(() => {
    /**
     * アイコンアップロード
     * @param {*} e - event
     * @returns {void}
     */
    const uploadIcon = (e) => {
      const files = e.target.files
      const file = files[0]
      const validationResultObj = CommonValidation.imgSize(file)

      if (!validationResultObj.checkResult && files.length > 0) {
        const reader = new FileReader()
        reader.onload = (e) => {
          dispatch({
            type: LIGHT_REGISTER_NEW_ACCOUNT.ICON.UPDATE_ICON,
            iconData: e.target.result,
            value: file,
            errorObj: {
              checkResult: validationResultObj.checkResult,
              errMsg: validationResultObj.errMsg,
            },
          })
        }
        reader.readAsDataURL(file)
      } else {
        dispatch({
          type: LIGHT_REGISTER_NEW_ACCOUNT.ICON.UPDATE_ICON,
          iconData: '',
          iconObj: '',
          errorObj: {
            checkResult: validationResultObj.checkResult,
            errMsg: validationResultObj.errMsg,
          },
        })
      }
    }

    /**
     * アイコン削除
     * @returns {void}
     */
    const deleteIcon = () => {
      dispatch({
        type: LIGHT_REGISTER_NEW_ACCOUNT.ICON.DELETE_ICON,
      })
    }

    return (
      <AuthenticationTableContainer>
        <span className={classes.asterisk}>*</span>
        <span className={classes.iconTitle}>{t('D501V0081')}</span>
        <span className={classes.iconReq}>{t(state.Icon.errorObj.errMsg)}</span>
        <IconBox
          register={true}
          btnflg={true}
          iconPath={state.Icon.iconData}
          onAddIcon={uploadIcon}
        />
        {state.Icon.iconData === '' || state.Icon.iconData === null ? (
          ''
        ) : (
          <DeleteSmallDialog onDelete={deleteIcon} />
        )}
      </AuthenticationTableContainer>
    )
  }, [
    dispatch,
    classes.asterisk,
    classes.iconTitle,
    classes.iconReq,
    state.Icon.iconData,
    state.Icon.errorObj.errMsg,
    t,
  ])
}

export default CompanyIcon
