import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily,
    color: '#333333',
    textAlign: 'center',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
  },
}))

const SubTitle = (props) => {
  const classes = useStyles()
  return (
    <>
      <Typography className={classes.text}>{props.children}</Typography>
    </>
  )
}

export default SubTitle
