import React from 'react'
import { useTranslation } from 'react-i18next'

import LayoutLog from '../../../common/layout/LayoutLog'
import RegisterTitle from '../../../common/title/RegisterTitle'
import Password from './Password'

const PasswordReset = () => {
  const { t } = useTranslation()

  return (
    <>
      <LayoutLog>
        <RegisterTitle>{t('D501V0067')}</RegisterTitle>
        <Password />
      </LayoutLog>
    </>
  )
}
export default PasswordReset
