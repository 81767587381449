import { Grid } from '@material-ui/core'
import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import AppContext from '../../../../../contexts/AppContext'
import AccordionSummary from '../../../../common/accordion/AccordionSummary'
import CommonCalc from '../../../../common/function/CommonCalc'
import Content from './Content'

/**
 * @param {*} props 下記内容のProps
 * @param {Number} props.index 1行分の航空便データ
 * @returns {JSX} FlightInfoアコーディオンのタイトル部分
 */
const FlightInfoAccordionSummary = (props) => {
  const { t } = useTranslation()
  const { state } = useContext(AppContext)
  const { index } = props

  /** @type {Number} showDataのインデックス */
  const page = CommonCalc.calcShowDataIndex(
    state.Paging,
    state.Tables.showData.length
  )

  return useMemo(() => {
    /** @type {String} 航空便番号 */
    const flightNumber = state.Tables.showData[page][index].flightNumber

    /** @type {String} 'Flight Number: 'の文字列 */
    const title = <>{t('D301V0131')}:&nbsp;</>

    return (
      <AccordionSummary>
        <Grid container>
          <Grid item>
            <Content title={title} content={flightNumber} row bold />
          </Grid>
        </Grid>
      </AccordionSummary>
    )
  }, [page, index, state.Tables.showData, t])
}

FlightInfoAccordionSummary.propTypes = {
  index: PropTypes.number.isRequired,
}
export default FlightInfoAccordionSummary
