import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import TableCell from '../../../common/table/TableCell'
import TableRow from '../../../common/table/TableRow'

const useStyles = makeStyles((theme) => ({
  tableHead: theme.commonTableHeader,
  noRightBorder: theme.noBorderRight,
  required: {
    color: theme.palette.required,
  },
  margin: {
    margin: '0.5rem',
  },
}))

/**
 * 定数から作成するヘッダーコンポーネント(1列分)
 * @param {*} props 下記内容
 * @param {Array} tableArr 表示するテーブル情報の配列
 * @param {Function} getIsShowColumn 表示するかを判定する関数
 * @return {JSX} ヘッダーコンポーネント(1列分)
 */
const HeaderBidDetails = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { tableArr, getIsShowColumn } = props

  /**
   * 定数で定めたヘッダーコンポーネント(1個分)を作成
   * @param {String} headerKey 定数に設定している文言key
   * @param {String} checkboxName  ConstantsBidDetails.TABLE_ARR.checkboxNameの値
   * @param {Boolean} rightBorder 右側の罫線を表示するか
   * @param {Boolean} required 必須項目か
   * @return {JSX} ヘッダーコンポーネント(1個分)
   */
  const createHeaderCell = ({ headerKey, rightBorder, required }) => {
    /** 適用するスタイル */
    const style = `${classes.tableHead} ${rightBorder ? null : classes.noRightBorder}`

    /** 必須項目の*印 */
    const requiredText = required ? (
      <span className={classes.required}>*</span>
    ) : null

    /** ヘッダーコンポーネント */
    return (
      <TableCell key={headerKey} className={style}>
        <div className={classes.margin}>
          {requiredText}
          {t(headerKey)}
        </div>
      </TableCell>
    )
  }

  return (
    <TableRow>
      {tableArr.map((tableObj) =>
        getIsShowColumn(tableObj.checkboxName)
          ? createHeaderCell(tableObj)
          : null
      )}
    </TableRow>
  )
}

HeaderBidDetails.propTypes = {
  tableArr: PropTypes.array.isRequired,
  getIsShowColumn: PropTypes.func.isRequired,
}

export default HeaderBidDetails
