import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import SaveDialog from '../../../../common/dialog/SaveDialog'
import Paths from '../../../../../constants/Paths'
import AppContext from '../../../../../contexts/AppContext'
import CommonFunc from '../../../../common/function/CommonFunc'
import ApiPaths from '../../../../../constants/ApiPaths'
import Common from '../../../../../constants/Common'
import SaveCompleteDialog from '../../../../common/dialog/SaveCompleteDialog'

/**
 * @return {JSX} Okボタンコンポーネント
 */
const OkButtonConfirmation = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const { state } = useContext(AppContext)
  const [errCode, setErrCode] = useState(0)
  const [comp, setComp] = useState(false)
  const [isErr, setIsErr] = useState(false)

  /** 非活性状態 */
  const disabled = !state.Checkbox

  /**
   * bidDetailのリクエストデータを作成する
   * @returns {Object} リクエストデータ
   */
  const createBidDetailArr = () =>
    state.Data.bidDetailArr
      .filter((data) => {
        //ステータスがRequest/Update/NAのデータのみリクエストする
        const isRequest = data.statusId === Common.BID_STATUS.REQUEST.ID
        const isUpdate = data.statusId === Common.BID_STATUS.UPDATE.ID
        const isNa = data.statusId === Common.BID_STATUS.NA.ID
        return isRequest || isUpdate || isNa
      })
      .map((data) => {
        const {
          bidLaneId,
          bidLaneUpdatedAt,
          ttId,
          transitId,
          statusStr,
          disclosesId,
          disclosesUpdatedAt,
          orgId,
          airlineId,
          laneId,
          laneUpdatedAt,
          dstId,
          commId,
          fsc,
          minrate,
          normal,
          min,
          light,
          medium,
          large,
          max,
          currencyId,
          laneUpdateStatusId,
          laneUpdateStatusUpdatedAt,
        } = data
        /** 編集後のステータスID */
        let bidStatusId = Object.entries(Common.BID_STATUS).find(
          (status) => status[1].TEXT === statusStr
        )[1].ID
        if (bidStatusId === Common.BID_STATUS.REQUEST.ID) {
          bidStatusId = Common.BID_STATUS.UPDATE.ID
        }
        return {
          bidLaneId,
          bidLaneUpdatedAt,
          transitTimeId: ttId,
          transitId,
          bidStatusId,
          disclosesId,
          disclosesUpdatedAt,
          orgId,
          airlineId,
          laneId,
          laneUpdatedAt,
          dstId,
          commId,
          fsc,
          minrate,
          normal,
          min,
          light,
          medium,
          large,
          max,
          currencyId,
          laneUpdateStatusId,
          laneUpdateStatusUpdatedAt,
        }
      })

  /**
   * クリック時のイベント
   * @returns {void} なし
   */
  const onClick = async () => {
    const req = {
      bidDetailArr: createBidDetailArr(),
      shipperUserId: state.Data.bidInfoObj.picArr[0].userId,
    }

    const apiInfoArr = [
      {
        key: 'resData',
        method: 'post',
        path: ApiPaths.FORWARDER.CONFIRMATION,
        req,
      },
    ]
    const resObj = await CommonFunc.execApiAsync(apiInfoArr)
    const isApiSuccessful = CommonFunc.checkApiResponseObj(resObj, history)

    // 使用しない共通ダイアログが表示されるので非表示に変更
    document.getElementById('error-dialog').hidden = true

    setErrCode(resObj.resData.data.info.ErrorCode)
    setIsErr(!isApiSuccessful)
    if (isApiSuccessful) {
      setComp(true)
    }
  }

  const saveFactors = {
    errcode: errCode,
    comp,
    compevent: () => setComp(false),
    passerr: null,
    path: Paths.FWDR.BID_MGMT,
    saveevent: onClick,
    text: t('D004V0062'),
    disabled,
  }

  return (
    <div>
      <SaveDialog saveFactors={saveFactors} />
      <SaveCompleteDialog
        savecomp={isErr.toString()}
        path={Paths.FWDR.BID_MGMT}
        errcode={errCode}
      />
    </div>
  )
}

export default OkButtonConfirmation
