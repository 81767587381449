import React, { useContext } from 'react'
import { Grid, makeStyles } from '@material-ui/core'

import AppContext from '../../../../contexts/AppContext'
import DetailsCompanyInfoText from '../../../common/text/CompanyInfoText/DetailsCompanyInfoText'

const useStyles = makeStyles((theme) => ({
  box: theme.consigneeBox,
}))

/**
 * @return {JSX} セレクトボックスで選択したConsignee文字列コンポーネント
 */
const SelectConsignee = () => {
  const { state } = useContext(AppContext)
  const { displayArr } = state.Consignee
  const classes = useStyles()

  return (
    <Grid item md={12} xs={12}>
      <Grid item container justify="flex-start" className={classes.box}>
        {displayArr.map((text, index) => (
          <DetailsCompanyInfoText key={index} text={text} />
        ))}
      </Grid>
    </Grid>
  )
}

export default SelectConsignee
