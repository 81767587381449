import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import AppContext from '../../../../../contexts/AppContext'
import EditDialog from './EditDialog'

const useStyles = makeStyles((theme) => ({
  marginTotal: {
    marginTop: '0.5rem',
  },
  marginAdd: {
    marginTop: '0.5rem',
  },
  black: theme.textNormalBLK,
}))

/**
 * @return {JSX} Addボタン
 */
const AddLightBookingRequest = () => {
  const classes = useStyles()
  const { state } = useContext(AppContext)

  return (
    <Grid container justify="center">
      <Grid item md={10} xs={10} className={classes.marginAdd}>
        <EditDialog add no={state.cargoDetailArr.length + 1} cargoDetail={{}} />
      </Grid>
    </Grid>
  )
}

export default AddLightBookingRequest
