// Display + 画面名
const DisplayAirlineSetFlight = {
  // 画面ID + 文言ID
  D209V0001: {
    en: 'SET FLIGHT',
    ja: 'フライト設定',
  },
  D209V0002: {
    en: 'FHL',
    ja: 'FHL',
  },
  D209V0003: {
    en: 'FWB',
    ja: 'FWB',
  },
  D209V0004: {
    en: 'FWB/10 //RVSD//',
    ja: 'FWB/10 //RVSD//',
  },
  D209V0005: {
    en: '020-53971282ATLJNB/T1K3265.10',
    ja: '020-53971282ATLJNB/T1K3265.10',
  },
  D209V0006: {
    en: 'FLT/LH0005/02',
    ja: 'FLT/LH0005/02',
  },
  D209V0007: {
    en: 'RTG/FRALH/JNBLH',
    ja: 'RTG/FRALH/JNBLH',
  },
  D209V0008: {
    en: 'SHP',
    ja: 'SHP',
  },
  D209V0009: {
    en: '/DHL GLOBAL FORWARDING /2501-A SULLIVAN ROAD /COLLEGE PARK/GA',
    ja: '/DHL GLOBAL FORWARDING /2500-A SULLIVAN ROAD /COLLEGE PARK/GA',
  },
  D209V0010: {
    en: '/US/30337',
    ja: '/US/30337',
  },
  D209V0011: {
    en: 'CNE',
    ja: 'CNE',
  },
  D209V0012: {
    en: '/DHL GLOBAL FORWARDING SA',
    ja: '/DHL GLOBAL FORWARDING SA',
  },
  D209V0013: {
    en: '/107 ANDRE GREYVENSTEIN ROAD',
    ja: '/107 ANDRE GREYVENSTEIN ROAD',
  },
  D209V0014: {
    en: '/ISANDO',
    ja: '/ISANDO',
  },
  D209V0015: {
    en: '/ZA/1600',
    ja: '/ZA/1600',
  },
  D209V0016: {
    en: 'AGT//3351595/0204/CAG',
    ja: 'AGT//3351595/0204/CAG',
  },
  D209V0017: {
    en: '/DHL GLOBAL FORWARDING INC',
    ja: '/DHL GLOBAL FORWARDING INC',
  },
  D209V0018: {
    en: '/COLLEGE PARK',
    ja: '/COLLEGE PARK',
  },
  D209V0019: {
    en: 'SSR/KEEP COOL UPON ARRIVAL.',
    ja: 'SSR/KEEP COOL UPON ARRIVAL.',
  },
  D209V0020: {
    en: 'ACC/GEN',
    ja: 'ACC/GEN',
  },
  D209V0021: {
    en: 'CVD/USD//PP/NVD/NCV/XXX',
    ja: 'CVD/USD//PP/NVD/NCV/XXX',
  },
  D209V0022: {
    en: 'RTD/1/P1/K3265.9/CQ/W3266/R3.93/T12835.38',
    ja: 'RTD/1/P1/K3265.9/CQ/W3266/R3.93/T12835.38',
  },
  D209V0023: {
    en: '/NC/CONSOLDATION',
    ja: '/NC/CONSOLDATION',
  },
  D209V0024: {
    en: '/2/NG/PER ATTACHED MANIFEST',
    ja: '/2/NG/PER ATTACHED MANIFEST',
  },
  D209V0025: {
    en: '/3/ND/120X120X120',
    ja: '/3/ND/120X120X120',
  },
  D209V0026: {
    en: '/4/NV/9.05M3',
    ja: '/4/NV/9.05M3',
  },
  D209V0027: {
    en: '/5/NG/CarBon DIOXIDE,SOLID UN1845 DRY ICE1 X 90K',
    ja: '/5/NG/CarBon DIOXIDE,SOLID UN1845 DRY ICE1 X 90K',
  },
  D209V0028: {
    en: 'OTH/P/MYC4082.38SCC653,18CCC10 PPD/WT12835.39',
    ja: 'OTH/P/MYC4082.38SCC653,18CCC10 PPD/WT12835.39',
  },
  D209V0029: {
    en: '/OC4745.56/CT17580.94',
    ja: '/OC4745.56/CT17580.94',
  },
  D209V0030: {
    en: 'CER/ON BEHALF OF DGF',
    ja: 'CER/ON BEHALF OF DGF',
  },
  D209V0031: {
    en: 'ISU/09SEEP20/ATLANTA/DHL GLOBAL FWD',
    ja: 'ISU/09SEEP20/ATLANTA/DHL GLOBAL FWD',
  },
  D209V0032: {
    en: 'SPH/HEA',
    ja: 'SPH/HEA',
  },
  D209V0033: {
    en: 'Departed',
    ja: '出発済',
  },
  D209V0034: {
    en: 'Name of Representative',
    ja: '代表者名',
  },
  D209V0035: {
    en: 'E-mail address',
    ja: 'メールアドレス',
  },
  D209V0036: {
    en: 'Actual Time of Departure (ATD)',
    ja: '実際の出発時刻 (ATD)',
  },
  D209V0037: {
    en: 'FHL/10 //RVSD//',
    ja: 'FHL/10 //RVSD//',
  },
  D209V0038: {
    en: 'FWB Version',
    ja: 'FWB バージョン',
  },
  D209V0039: {
    en: 'FHL Version',
    ja: 'FHL バージョン',
  },
  D209V0040: {
    en: 'Save',
    ja: '保存',
  },
}
// Display + 画面名
export default DisplayAirlineSetFlight
