import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import AppContext from '../../../../contexts/AppContext'
import { SHC_DIALOG } from '../../../../actions'
import CommonDialog from '../commonDialog/CommonDialog'
import Common from '../../../../constants/Common'

const useStyles = makeStyles((theme) => ({
  titleText: theme.textBoldBLU,
  text: {
    ...theme.textNormalBLK,
    ...theme.wordBreak,
    textAlign: 'center',
  },
  box: {
    border: '1px solid',
    borderRadius: '5px',
    borderColor: theme.palette.primary.main,
    width: '100%',
  },
}))

/**
 * @returns {JSX} SHCポップアップ
 */
const ShcDialog = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { dispatch, state } = useContext(AppContext)
  const [open, setOpen] = useState(false)

  // ポップアップを開く関数をreducerに登録
  useEffect(() => {
    dispatch({
      type: SHC_DIALOG.SET_OPEN_FUNC,
      setOpen,
    })
  }, [dispatch])

  // ダイアログのボタン設定
  const dialogFuncObj = {
    [Common.DIALOG.BUTTON_CATEGORY.BACK]: { onClick: () => setOpen(false) },
  }

  const shcArr = state.ShcDialog.shcArr

  const displayStr = shcArr.length > 0 ? shcArr.join('/ ') : '-'

  return (
    <CommonDialog
      open={open}
      closeFunc={() => setOpen(false)}
      dialogFuncObj={dialogFuncObj}
    >
      <Grid
        container
        direction="column"
        alignContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item className={classes.titleText}>
          {t('D103V0109')}
        </Grid>
        <Grid item md={12} className={`${classes.text} ${classes.box}`}>
          {displayStr}
        </Grid>
      </Grid>
    </CommonDialog>
  )
}
export default ShcDialog
