import React from 'react'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

import Q1 from './Q1'
import Q2 from './Q2'
import Q3 from './Q3'
import Q4 from './Q4'

const useStyles = makeStyles((theme) => ({
  headText: theme.textBoldBLU,
  normalText: theme.textNormalBLK,
}))

const QuestionnaireLightDeleteAccount = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid container justify="center" alignItems="center">
      <Grid
        container
        item
        direction="column"
        justify="center"
        alignItems="center"
        spacing={4}
      >
        <Grid
          container
          item
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Grid item className={classes.headText}>
            {t('D501V0099')}
          </Grid>
          <Grid item className={classes.normalText}>
            {t('D501V0100')}
          </Grid>
        </Grid>
        <Grid item>
          <Q1 />
        </Grid>
        <Grid item>
          <Q2 />
        </Grid>
        <Grid item>
          <Q3 />
        </Grid>
        <Grid item>
          <Q4 />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default QuestionnaireLightDeleteAccount
