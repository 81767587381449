import React from 'react'
import WarningRounded from '@material-ui/icons/WarningRounded'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  red: {
    color: theme.palette.reject.main,
  },
}))

/**
 * @return {JSX} 赤色の警告アイコン
 */
const WarningRoundedIcon = () => {
  const classes = useStyles()

  return <WarningRounded className={classes.red} />
}

export default WarningRoundedIcon
