/* eslint-disable no-extra-parens */
import React, { useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import Paper from '@material-ui/core/Paper'
import TableRow from '@material-ui/core/TableRow'

import PrimaryButton from '../../../common/buttons/PrimaryButton'
import ApiPaths from '../../../../constants/ApiPaths'
import CommonFunc from '../../../common/function/CommonFunc'
import AppContext from '../../../../contexts/AppContext'
import { GET_TABLE_DATA } from '../../../../actions'
import Paths from '../../../../constants/Paths'
import restFacade from '../../../../actions/rest-facade'

const useStyles = makeStyles((theme) => ({
  table: {
    '& th': {
      background: theme.palette.info.main,
    },
    '& td, th': {
      minWidth: 'fit-content',
      whiteSpace: 'nowrap',
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
    '& tr:last-child td': {
      borderBottom: '0px solid',
    },
  },
  text: theme.textNormalBLK,
  unreadText: theme.textBoldBLK,
  headText: theme.textBoldBLU,
  unreadRow: {
    background: 'rgba(55,92,167,0.2)',
    '& td': theme.textBoldBLK,
  },
  readRow: {
    background: theme.palette.info.main,
    '& td': theme.textNormalBLK,
  },
  icon: {
    width: '70px',
    height: '70px',
  },
  paper: {
    border: '1px solid',
    color: theme.palette.primary.main,
    '&.MuiPaper-rounded': {
      borderRadius: '0px 5px 5px 5px',
    },
  },
  underline: {
    textDecoration: 'underline',
    textDecorationColor: theme.palette.primary.main,
  },
  noResult: theme.noResult,
  title: {
    maxWidth: '300px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}))

let setNotificationsData = () => {}
let setData = () => {}

/**
 * @returns {JSX} 検索結果の表コンポーネント
 */
const NotifyTable = () => {
  const { t, i18n } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const { state, dispatch } = useContext(AppContext)
  const heads = [
    t('D601V0009'),
    t('D601V0010'),
    t('D601V0011'),
    t('D601V0012'),
    '',
  ]

  setNotificationsData = (res) => {
    const results = res.data.results.map((v, index) => ({
      ...v,
      searchDate: getDate(v.date).split(' ')[0], //日付詳細検索用
      showDate: getDate(v.date), //検索用
      index, //createTableCells関数でapiDataArrとの紐づけに使用します
    }))
    dispatch({
      // 表示
      type: GET_TABLE_DATA,
      data: results,
      sort: state.Sort,
      i18n,
    })
  }
  // エラー処理、コールバック関数実行
  setData = (res, callback) => {
    CommonFunc.callbackFunc(res, callback, history)
  }

  useEffect(() => {
    // 各データ取得
    restFacade.get(ApiPaths.NOTIFICATIONS.NOTIFICATIONS, (res) =>
      setData(res, setNotificationsData)
    )
  }, [])

  // Dateを変換して取得
  const getDate = (notifyDate) => {
    return CommonFunc.chgUTC2LocalTime(
      notifyDate,
      'YYYY-MM-DDTHH:mm:ss',
      'YYYY-MM-DD HH:mm'
    )
  }

  /**
   * テーブルデータを表示する
   * @param {Object} showDataObj 表示データ
   * @param {Number} index index
   * @returns {JSX} テーブルデータ
   */
  const createTableCells = () =>
    state.Tables.showData[state.Paging.page - 1].map((showDataObj) => {
      const { index } = showDataObj
      const row = state.Tables.apiDataArr[index]
      return (
        <TableRow
          key={index}
          className={row.readFlg === 1 ? classes.readRow : classes.unreadRow}
        >
          <TableCell align="center">
            {`${CommonFunc.convertDate(row.showDate.split(' ')[0], CommonFunc.getLang(i18n))} ${row.showDate.split(' ')[1]}`}
          </TableCell>
          <TableCell align="center">{`${row.name}_${row.lastName}`}</TableCell>
          <TableCell align="center">{row.companyName}</TableCell>
          <TableCell align="center" className={classes.title}>
            {row.title}
          </TableCell>
          <TableCell align="center">
            <PrimaryButton
              path={Paths.NOTIFY.NOTIFICATION_DETAILS}
              state={{ id: row.id, updatedAt: row.updatedAt, sort: state.Sort }}
            >
              {t('D601V0013')}
            </PrimaryButton>
          </TableCell>
        </TableRow>
      )
    })

  return (
    <>
      {state.Tables.showData[state.Paging.page - 1].length > 0 ? (
        <TableContainer
          component={Paper}
          elevation={0}
          className={classes.paper}
        >
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {heads.map((head, index) => (
                  <TableCell
                    align="center"
                    className={classes.headText}
                    key={index}
                  >
                    {head}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>{createTableCells()}</TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div className={classes.noResult}>No Result</div>
      )}
    </>
  )
}
export default NotifyTable
