import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'

import AccordionLightBookingDetails from '../Accordion/AccordionLightBookingDetails'
import AddButtonSpecialHandlingCode from './AddButtonSpecialHandlingCode'
import ShcChipsSpecialHandlingCodeLightBookingDetails from './ShcChipsSpecialHandlingCodeLightBookingDetails'
import SpecialHandlingCodePopup from './SpecialHandlingCodePopup'

/**
 * @param {Object} props 下記内容
 * @param {Boolean} props.editable 編集可能
 * @return {JSX} SpecialHandlingCode欄
 */
const SpecialHandlingCodeLightBookingDetails = ({ editable }) => {
  const { t } = useTranslation()

  return useMemo(
    () => (
      <AccordionLightBookingDetails label={t('4th0013')}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item md={10}>
            <ShcChipsSpecialHandlingCodeLightBookingDetails
              editable={editable}
            />
          </Grid>
          {editable ? (
            <Grid item>
              <AddButtonSpecialHandlingCode />
            </Grid>
          ) : null}
        </Grid>
        {editable ? <SpecialHandlingCodePopup /> : null}
      </AccordionLightBookingDetails>
    ),
    [t, editable]
  )
}
SpecialHandlingCodeLightBookingDetails.propTypes = {
  editable: PropTypes.bool.isRequired,
}
export default SpecialHandlingCodeLightBookingDetails
