import React from 'react'
import { Grid } from '@material-ui/core'
import CheckBox from '../../../../common/checkBox/CheckBox'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  text: theme.textNormalBLU,
  marginBottom: '2rem',
  marginLeft: '5%',
}))

/**
 * @param {Object} props - 以下
 * @param {String} data - 項目名
 * @param {*} etc... - その他Material-UI CheckBoxを参照してください
 * @returns {JSX.Element} - Authority
 */
const Authority = (props) => {
  const classes = useStyles()
  const { data, ...rest } = props
  return (
    <Grid container justify="center" spacing={3}>
      <Grid item md={10} xs={10} />
      <Grid item md={10} xs={10}>
        <div className={classes.text}>
          {data}
          <CheckBox {...rest} />
        </div>
      </Grid>
    </Grid>
  )
}
export default Authority
