import { LIGHT_AIR_FREIGHT } from '../../../actions'

/** @type{Object} 選択通貨の初期値 */
const initCurrencyValue = {
  currencyId: null,
  edited: false,
}

/**
 * 選択通貨情報
 * @param {Object} [state=initCurrencyValue] - state
 * @param {Object} action - action
 * @return {Object} - state
 */
const CurrencyValue = (state = initCurrencyValue, action) => {
  switch (action.type) {
    case LIGHT_AIR_FREIGHT.CURRENCY.SET:
      return {
        currencyId: action.currencyId,
        apiCurrencyId: action.currencyId,
        edited: false,
      }
    case LIGHT_AIR_FREIGHT.CURRENCY.EDIT:
      return {
        ...state,
        currencyId: action.currencyId,
        edited: action.currencyId !== state.apiCurrencyId,
      }
    default:
      return state
  }
}

export default CurrencyValue

export { initCurrencyValue }
