import React, { useContext } from 'react'

import ShippingDetailsLightBookingDetails from '../../../../commonUseContext/LightBookingDetails/ShippingDetails/ShippingDetailsLightBookingDetails'
import { BeforePath } from '../ConstantsLightBookingDetails'
import AppContext from '../../../../../contexts/AppContext'

/**
 * @return {JSX} ShippingDetailsアコーディオン
 */
const ShippingDetailsFwdrLightBookingDetails = () => {
  const { state } = useContext(AppContext)
  const beforePath = state.ShippingDetails.beforePath
  const isShowRate = beforePath === BeforePath.BookingList
  return <ShippingDetailsLightBookingDetails disabled isShowRate={isShowRate} />
}

export default ShippingDetailsFwdrLightBookingDetails
