import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import Common from '../../../../../../constants/Common'
import TypeCellLightCarAirFreight from './TypeCellLightCarAirFreight'
import RateCellLightCarAirFreight from './RateCellLightCarAirFreight'

/**
 * Type～1000kgまでの1行分の表データを作成
 * @param {Object} props 下記要素
 * @param {Number} props.index ページ上のインデックス
 * @param {String} props.rateType レートタイプ
 * @return {JSX} Type～1000kgまでの1行分
 */
const RateCellsLightCarAirFreight = ({ index, rateType }) => {
  return useMemo(() => {
    return (
      <>
        <TypeCellLightCarAirFreight rateType={rateType} />
        {Object.values(Common.RATE.RATE_NAME).map((rateName, key) => (
          <RateCellLightCarAirFreight
            key={key}
            index={index}
            rateName={rateName}
            rateType={rateType}
          />
        ))}
      </>
    )
  }, [rateType, index])
}

RateCellsLightCarAirFreight.propTypes = {
  index: PropTypes.number.isRequired,
  rateType: PropTypes.string.isRequired,
}

export default RateCellsLightCarAirFreight
