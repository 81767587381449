import Common from '../../../../constants/Common'

/**
 * @typedef {Object} TableInfo テーブル情報
 * @property {string} headerKey ヘッダーに表示する文言番号
 * @property {string} cellKey セルに表示するプロパティ名
 * @property {string} cellCategory Common.CELL_CATEGORYの値
 */

const ConstantsLightBookingRequest = {
  /** @type {TableInfo[]} テーブル作成に必要な値 */
  TABLE_ARR: [
    {
      headerKey: 'D014V0002',
      cellKey: 'no',
      cellCategory: Common.CELL_CATEGORY.NO,
    },
    {
      headerKey: 'D004V0014',
      cellKey: 'pcs',
      cellCategory: Common.CELL_CATEGORY.TEXT_FIELD.DETAILS,
    },
    {
      headerKey: 'D004V0015',
      cellKey: 'grossWgt',
      cellCategory: Common.CELL_CATEGORY.TEXT_FIELD.DETAILS,
    },
    {
      headerKey: 'D004V0016',
      cellKey: 'length',
      cellCategory: Common.CELL_CATEGORY.TEXT_FIELD.DETAILS,
    },
    {
      headerKey: 'D004V0017',
      cellKey: 'width',
      cellCategory: Common.CELL_CATEGORY.TEXT_FIELD.DETAILS,
    },
    {
      headerKey: 'D004V0018',
      cellKey: 'height',
      cellCategory: Common.CELL_CATEGORY.TEXT_FIELD.DETAILS,
    },
    {
      headerKey: 'D103V0005',
      cellKey: 'volume',
      cellCategory: Common.CELL_CATEGORY.TEXT_FIELD.DETAILS,
    },
    {
      headerKey: 'D004V0020',
      cellKey: 'chargeableWgt',
      cellCategory: Common.CELL_CATEGORY.TEXT_FIELD.DETAILS,
    },
    {
      headerKey: 'D004V0021',
      cellKey: 'nonStackable',
      cellCategory: Common.CELL_CATEGORY.TEXT,
    },
    {
      headerKey: 'D004V0022',
      cellKey: 'hazardous',
      cellCategory: Common.CELL_CATEGORY.TEXT,
    },
    {
      headerKey: 'D004V0061',
      cellKey: 'edit',
      cellCategory: Common.CELL_CATEGORY.BUTTON.EDIT,
    },
  ],
  CARGO_FLG: {
    YES: 'Yes',
    NO: 'No',
  },
}

export default ConstantsLightBookingRequest
