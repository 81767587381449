import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { useHistory } from 'react-router-dom'

import AppContext from '../../../../../contexts/AppContext'
import {
  UPDATE_CAR_COMPANY_NAME,
  UPDATE_CAR_TWO_LETTER_CODE,
  UPDATE_CAR_POSTAL_CODE,
  UPDATE_CAR_COMPANY_ADDRESS,
  UPDATE_CAR_DEPARTMENT,
  UPDATE_CAR_FIRST_NAME,
  UPDATE_CAR_LAST_NAME,
  UPDATE_CAR_POSITION,
  UPDATE_CAR_MAIL,
  UPDATE_CAR_BRANCH,
  UPDATE_CAR_ICON_PATH,
  UPDATE_CAR_FILE,
  UPDATE_CAR_TEL,
  UPDATE_CAR_IATACASS_NO,
  UPDATE_CAR_BACKUP,
  UPDATE_CAR_UPDATE_AT,
  UPDATE_CAR_REPRESENTATIVE_FLG,
  UPDATE_CAR_AUTHORITY_FLG,
  UPDATE_CAR_AIRPORT,
  UPDATE_CAR_COUNTRY,
  UPDATE_CAR_GSA_FLG,
} from '../../../../../actions'
import Paths from '../../../../../constants/Paths'
import SaveAndBack from '../../../../common/buttons/SaveAndBack'
import BackButton from '../../../../common/buttons/BackButton'
import ApiPaths from '../../../../../constants/ApiPaths'
import CommonFunc from '../../../../common/function/CommonFunc'
import Theme from '../../../../common/theme/Theme'
import Common from '../../../../../constants/Common'

const useStyles = makeStyles(() => ({
  buttons: {
    justifyContent: 'center',
    marginBottom: 30,
    marginTop: 30,
  },
  down: {
    marginBottom: 30,
    margin: 'auto',
  },
  red: Theme.textNormalRED,
}))

const ButtonLightCarCompanyInfo = () => {
  const classes = useStyles()
  const history = useHistory()
  const [saveComp, setSaveComp] = useState(false)
  const [errCode, setErrCode] = useState(Common.ERROR_CODE.NORMAL)
  const { state, dispatch } = useContext(AppContext)
  const representativeFlg = state.companyinfo.representativeFlg.value
  const authorityFlg = state.companyinfo.authorityFlg.value

  // Saveボタンの有効無効判定
  const chkSaveDisable = () =>
    Object.values(state.companyinfo).some(({ inputErr }) => inputErr === true)

  // 入力変更ありなし判定
  const chkInputChg = () => {
    const sta = state.companyinfo
    const bk = state.backup
    return Object.keys(bk).some((key) => bk[key] !== sta[key].value)
  }

  // 保存完了後の処理
  const saveComplete = async (res) => {
    setSaveComp(false)
    if (errCode === Common.ERROR_CODE.NORMAL) {
      setCompanyInfoCB(res)
    }
  }

  // 保存処理
  const saveData = async () => {
    let iataChanged = state.companyinfo.iataOrcassNumber.value
    if (state.backup.iataOrcassNumber === iataChanged) {
      iataChanged = ''
    }

    const req = {
      carrierNameId: state.companyinfo.companyName.value.code,
      postalCode: state.companyinfo.postalCode.value,
      address: state.companyinfo.address.value,
      affilation: state.companyinfo.affilation.value,
      firstName: state.companyinfo.firstName.value,
      lastName: state.companyinfo.lastName.value,
      position: state.companyinfo.position.value,
      mail: state.companyinfo.mail.value,
      tel: state.companyinfo.tel.value,
      iataOrcassNumber: iataChanged ? iataChanged : null,
      updatedAt: state.companyinfo.updatedAt.value,
      url: state.companyinfo.iconPath.value,
      isDeleteShippingIcon: state.companyinfo.icon.value,
      file: state.file.file,
      carPlanId: state.companyinfo.carPlanId.value,
      representativeFlg,
    }
    // API実行
    const apiInfoArr = [
      {
        key: 'resData',
        method: 'post',
        path: ApiPaths.MASTER_CAR.LIGHT_CAR_COMPANY_INFO,
        req,
      },
    ]
    const resObj = await CommonFunc.execApiAsync(apiInfoArr)
    const isApiSuccessful = CommonFunc.checkApiResponseObj(resObj, history)
    setErrCode(resObj.resData.data.info.ErrorCode)
    if (isApiSuccessful) {
      const res = resObj.resData.data.results
      saveComplete(res)
      setSaveComp(true)
    }
  }

  const setCompanyInfoCB = (res) => {
    if (res) {
      dispatch({
        type: UPDATE_CAR_COMPANY_NAME,
        companyName: res.companyName,
        carrierNameId: res.carrierNameId,
        carriertwoCode: res.carriertwoCode,
      })
      dispatch({
        type: UPDATE_CAR_COMPANY_NAME,
        companyName: res.companyName,
        carrierNameId: res.carrierNameId,
        carriertwoCode: res.carriertwoCode,
      })
      dispatch({
        type: UPDATE_CAR_TWO_LETTER_CODE,
        carriertwoCode: res.carriertwoCode,
      })
      dispatch({
        type: UPDATE_CAR_POSTAL_CODE,
        postalCode: res.postalCode,
      })
      dispatch({
        type: UPDATE_CAR_REPRESENTATIVE_FLG,
        representativeFlg: res.representativeFlg,
      })
      dispatch({
        type: UPDATE_CAR_AUTHORITY_FLG,
        authorityFlg: res.authorityFlg,
      })
      dispatch({
        type: UPDATE_CAR_GSA_FLG,
        gsaFlg: res.gsaFlg,
      })
      dispatch({
        type: UPDATE_CAR_COMPANY_ADDRESS,
        address: res.address,
      })
      dispatch({
        type: UPDATE_CAR_DEPARTMENT,
        affilation: res.affilation,
      })
      dispatch({
        type: UPDATE_CAR_AIRPORT,
        airport: res.airport,
      })
      dispatch({
        type: UPDATE_CAR_COUNTRY,
        country: res.country,
      })
      dispatch({
        type: UPDATE_CAR_FIRST_NAME,
        firstName: res.firstName,
      })
      dispatch({
        type: UPDATE_CAR_LAST_NAME,
        lastName: res.lastName,
      })
      dispatch({
        type: UPDATE_CAR_POSITION,
        position: res.position,
      })
      dispatch({
        type: UPDATE_CAR_MAIL,
        mail: res.mail,
      })
      dispatch({
        type: UPDATE_CAR_TEL,
        tel: res.tel,
      })
      dispatch({
        type: UPDATE_CAR_IATACASS_NO,
        iataOrcassNumber: res.iataOrcassNumber,
      })
      dispatch({
        type: UPDATE_CAR_UPDATE_AT,
        updatedAt: res.updatedAt,
      })
      dispatch({
        type: UPDATE_CAR_BRANCH,
        cityName: res.cityName,
        cityId: res.cityId,
      })
      dispatch({
        type: UPDATE_CAR_ICON_PATH,
        icon: res.icon,
      })
      dispatch({
        type: UPDATE_CAR_FILE,
        file: res.file,
      })

      // 初回データをBackup
      dispatch({
        type: UPDATE_CAR_BACKUP,
        info: res,
      })

      // 先頭にスクロール
      window.scrollTo(0, 0)
    }
  }

  // Backコンポーネントに渡したいものをまとめる
  const backFactors = {
    path: Paths.MASTER.CAR_MASTER_TOP,
    inputchg: chkInputChg(),
    clickevent: '',
  }

  // Saveコンポーネントに渡したいものをまとめる
  const saveFactors = {
    path: Paths.MASTER.LIGHT_CAR_COMPANY_INFO,
    saveevent: saveData,
    comp: saveComp,
    compevent: saveComplete,
    disabled: chkSaveDisable(),
    errcode: errCode,
  }

  const saveAndBackButton = () => {
    return (
      <Grid item md={10} xs={10} className={classes.buttons}>
        <SaveAndBack backFactors={backFactors} saveFactors={saveFactors} />
      </Grid>
    )
  }

  const backButton = () => {
    return (
      <Grid item md={10} xs={10} className={classes.buttons}>
        <BackButton path={Paths.MASTER.CAR_MASTER_TOP} />
      </Grid>
    )
  }

  return (
    <Grid
      container
      justify="center"
      direction="column"
      alignItems="center"
      className={classes.down}
    >
      {authorityFlg ? saveAndBackButton() : backButton()}
    </Grid>
  )
}

export default ButtonLightCarCompanyInfo
