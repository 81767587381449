import { combineReducers } from 'redux'

import Tables, { initTable } from './Tables'
import Auth, { initAuth } from '../../common/Auth'
import Paging, { initPaging } from '../../common/Paging'
import SearchSort, { initSearchSort } from '../../common/SearchSort'

const initialState = {
  Tables: initTable,
  Auth: initAuth,
  SearchSort: initSearchSort,
  Paging: initPaging,
}

export default combineReducers({
  Tables,
  Auth,
  SearchSort,
  Paging,
})

export { initialState }
