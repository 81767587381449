import { TABLES } from '../../../actions'
import CommonFunc from '../../../components/common/function/CommonFunc'
import ConstantsSelectForwarder from '../../../components/pages/shipper/shipperSelectForwarder/ConstantsSelectForwarder'

/** 初期表示ページ */
const initTablesSelectForwarder = {
  // APIの素のデータを保持
  apiDataArr: [],
  // 表示用のデータを保持
  showData: [[]],
}

/**
 * 表示用データを作成
 * @param {Object} bidDataObj 入札のAPIデータ
 * @param {*} i18n useTranslationのi18n
 * @param {Number} apiIndexNum APIの番号
 * @return {Object} 表示用データ
 */
const createDisplayObj = (bidDataObj, i18n, apiIndexNum) => {
  const displayArr = ConstantsSelectForwarder.TABLE_ARR.map(
    ({ convertFunc, cellKey }) => {
      return convertFunc
        ? convertFunc(bidDataObj, cellKey, apiIndexNum, i18n)
        : ['', '']
    }
  )
  return Object.fromEntries(displayArr)
}

/**
 * 定数のソート一覧からソートに必要なデータを抜き出して作成
 * @param {Object} bidDataObj 入札のAPIデータ
 * @return {Object} ソート用データ
 */
const createSortObj = (bidDataObj) => {
  const sortArr = ConstantsSelectForwarder.SORT_ITEM_ARR.map(
    ({ sortKey, convertFunc }) => {
      const apiVal = bidDataObj[sortKey]
      const sortVal = convertFunc ? convertFunc(apiVal) : apiVal
      return [sortKey, sortVal]
    }
  )
  return Object.fromEntries(sortArr)
}

/**
 * 表示用のデータに変換
 * @param {Array} apiDataArr 入札のAPIデータ
 * @param {*} i18n useTranslationのi18n
 * @returns {Array} 表示、並び替え用情報配列
 */
const convertShowCargoDataArr = (apiDataArr, i18n) =>
  apiDataArr.map((bidDataObj, apiIndexNum) => {
    const displayObj = createDisplayObj(bidDataObj, i18n, apiIndexNum)
    const sortObj = createSortObj(bidDataObj)

    return {
      // 表示用
      displayObj,
      // 並び替え用
      ...sortObj,
      // API特定用番号
      apiIndexNum,
    }
  })

/**
 *
 * @param {Array} apiDataArr 入札のAPIデータ
 * @param {String} search 検索ワード
 * @param {String} sort ソート
 * @param {*} i18n useTranslationのi18n
 * @return {Object} 更新したTablesSelectForwarderのstate
 */
const createCommonTableDataObj = (apiDataArr, search, sort, i18n) => {
  // sort名から並び替えの対象となるkeyを取得
  const sortDataName = ConstantsSelectForwarder.SORT_ITEM_ARR.find(
    ({ sortTerm }) => sortTerm === sort
  ).sortKey
  // 検索、並び替え用にAPIの値を変換した後表示用データを作成
  const showCargoDataArr = convertShowCargoDataArr(apiDataArr, i18n)
  const { showData } = CommonFunc.searchSortData(
    showCargoDataArr,
    search,
    sort,
    sortDataName
  )

  // 表示用データとAPIの値全部と必須に分けて保持
  return {
    // 表示用データ
    showData,
    // APIデータ
    apiDataArr,
  }
}

/**
 * TablesSelectForwarderのstateの初期設定を作成
 * @param {Object} action dispatchで受け取ったaction
 * @return {Object} 初期設定のTablesSelectForwarderのstate
 */
const createInitTableDataObj = (action) => {
  const { data, sort, i18n } = action
  const { showData, apiDataArr } = createCommonTableDataObj(
    data,
    '',
    sort,
    i18n
  )

  // 表示用データとAPIの値を全部と必須に分けて保持
  return {
    // 表示用データ
    showData,
    // APIデータ
    apiDataArr,
  }
}

/**
 * 変更された検索ワード、ソートからTablesSelectForwarderのstateを作成
 * @param {Object} action dispatchで受け取ったaction
 * @param {Object} state TablesSelectForwarderのstate
 * @return {Object} 表示のみ更新したTablesSelectForwarderのstate
 */
const createSetTableDataObj = (action, state) => {
  const { search, sort, i18n } = action
  const { apiDataArr } = state
  const { showData } = createCommonTableDataObj(apiDataArr, search, sort, i18n)

  // 表示用データとAPIの値を全部と必須に分けて保持
  return {
    ...state,
    // 表示用データ
    showData,
  }
}

/**
 * 表データとAPIを保持
 * @param {Object} [state=initTablesSelectForwarder] 表データとAPI
 * @param {Object} action 表データの検索に必要な値
 * @return {Object} 表データとAPI
 */
const TablesSelectForwarder = (state = initTablesSelectForwarder, action) => {
  switch (action.type) {
    case TABLES.INIT:
      return createInitTableDataObj(action)
    case TABLES.SET:
      return createSetTableDataObj(action, state)
    default:
      return state
  }
}

export default TablesSelectForwarder

export { initTablesSelectForwarder }
