// Display + 画面名
const DisplayFwdrBidDetailsCarrier = {
  // 画面ID + 文言ID
  D116V0001: {
    en: 'BID DETAILS',
    ja: '入札詳細',
  },
  D116V0002: {
    en: 'Owner',
    ja: '荷主',
  },
  D116V0003: {
    en: 'XXXXXX Corporation',
    ja: 'XXXXXX株式会社',
  },
  D116V0004: {
    en: '1006, Oaza Kadoma, Kadoma-shi, Osaka 571-8501, Japan',
    ja: '571-8501大阪府門真市大字門真1006',
  },
  D116V0005: {
    en: 'Tel.',
    ja: '電話.',
  },
  D116V0006: {
    en: '+81-6-6908-1121',
    ja: '+81-6-6908-1121',
  },
  D116V0007: {
    en: 'Representative',
    ja: '担当者',
  },
  D116V0008: {
    en: 'Konosuke Matsushita',
    ja: '松下幸之助',
  },
  D116V0009: {
    en: 'Manager',
    ja: 'マネージャー',
  },
  D116V0010: {
    en: 'Supervisor',
    ja: 'スーパーバイザー',
  },
  D116V0011: {
    en: 'Leader',
    ja: 'リーダー',
  },
  D116V0012: {
    en: '-',
    ja: '-',
  },
  D116V0013: {
    en: 'Lighting Dept.',
    ja: '照明部門',
  },
  D116V0014: {
    en: 'kmatsushita@panasonic.co.jp / +81-X-XXX-XXXX',
    ja: 'kmatsushita@panasonic.co.jp / +81-X-XXX-XXXX',
  },
  D116V0015: {
    en: 'Validity',
    ja: '有効期間',
  },
  D116V0016: {
    en: 'Aug 1, 2020',
    ja: '2020年8月1日',
  },
  D116V0017: {
    en: 'to',
    ja: '～',
  },
  D116V0018: {
    en: 'Jan 31, 2021',
    ja: '2021年1月31日',
  },
  D116V0019: {
    en: 'Set FWDR',
    ja: '参加FWDR',
  },
  D116V0020: {
    en: 'Undisclosed',
    ja: '非公開',
  },
  D116V0021: {
    en: 'Set Carrier',
    ja: '航空会社を設定',
  },
  D116V0022: {
    en: 'Unspecified',
    ja: '指定しない',
  },
  D116V0023: {
    en: 'Speciafied',
    ja: '指定する',
  },
  D116V0024: {
    en: 'Currency',
    ja: '通貨',
  },
  D116V0025: {
    en: 'JPY - Japanese Yen',
    ja: 'JPY-日本円',
  },
  D116V0026: {
    en: '1st Deadline',
    ja: '一次締め切り',
  },
  D116V0027: {
    en: 'July 31, 2020',
    ja: '2020年7月31日',
  },
  D116V0028: {
    en: 'Special Notes',
    ja: '特記事項',
  },
  D116V0029: {
    en: '・Please follow the schedule strictly.',
    ja: '・スケジュールは厳守してください。',
  },
  D116V0030: {
    en: "・We'll inform you as soon as we decide.",
    ja: '・決定次第お知らせします。',
  },
  D116V0031: {
    en: 'Request Lanes',
    ja: '依頼レーン',
  },
  D116V0032: {
    en: 'ORG/DST',
    ja: '出発/到着',
  },
  D116V0033: {
    en: 'Via',
    ja: '経由地',
  },
  D116V0034: {
    en: 'TTL Wgt.',
    ja: '合計総重量(kg)',
  },
  D116V0035: {
    en: 'TTL Vol.',
    ja: '合計容積(m3)',
  },
  D116V0036: {
    en: 'SHC',
    ja: 'SHC',
  },
  D116V0037: {
    en: '+45kg',
    ja: '+45kg',
  },
  D116V0038: {
    en: '+100kg',
    ja: '+100kg',
  },
  D116V0039: {
    en: '+300kg',
    ja: '+300kg',
  },
  D116V0040: {
    en: '+500kg',
    ja: '+500kg',
  },
  D116V0041: {
    en: '+1,000kg',
    ja: '+1,000kg',
  },
  D116V0042: {
    en: 'NRT/MNL',
    ja: 'NRT/MNL',
  },
  D116V0043: {
    en: 'NRT/LON',
    ja: 'NRT/LON',
  },
  D116V0044: {
    en: 'NRT/ORD',
    ja: 'NRT/ORD',
  },
  D116V0045: {
    en: '1,000kg - 3000kg',
    ja: '1,000kg - 3000kg',
  },
  D116V0046: {
    en: '300m3 - 500m3',
    ja: '300m3 - 500m3',
  },
  D116V0047: {
    en: 'GEN',
    ja: 'GEN',
  },
  D116V0048: {
    en: 'DG',
    ja: 'DG',
  },
  D116V0049: {
    en: 'ICE',
    ja: 'ICE',
  },
  D116V0050: {
    en: '--Select--',
    ja: '--選択--',
  },
  D116V0051: {
    en: 'Disclose',
    ja: '公開',
  },
  D116V0052: {
    en: 'Undisclosed',
    ja: '非公開',
  },
  D116V0053: {
    en: 'Please click the box if you agree to be unable to cancel/change the input rates during the BID term.',
    ja: 'BID期間中に入力レートをキャンセルまたは変更できないことに同意する場合は、ボックスをクリックしてください。',
  },
  D116V0054: {
    en: 'Confirm',
    ja: '確認',
  },
  D116V0055: {
    en: 'OK',
    ja: '決定',
  },
  D116V0056: {
    en: 'Cancel',
    ja: 'キャンセル',
  },
  D116V0057: {
    en: '2nd Deadline',
    ja: '二次締め切り',
  },
  D116V0058: {
    en: '3rd Deadline',
    ja: '三次締め切り',
  },
  D116V0059: {
    en: 'August 31, 2020',
    ja: '2020年8月31日',
  },
}
// Display + 画面名
export default DisplayFwdrBidDetailsCarrier
