import React, { useContext } from 'react'
import { Grid } from '@material-ui/core'

import AddButton from '../../../../common/buttons/AddButton'
import AppContext from '../../../../../contexts/AppContext'
import { PIC } from '../../../../../actions'
import ConstantsBidInfo from '../ConstantsBidInfo'

/**
 * @return {JSX} Addボタンコンポーネント
 */
const AddButtonPicBidInfo = () => {
  const { state, dispatch } = useContext(AppContext)

  const dialogOpen = () => {
    dispatch({
      type: PIC.OPEN_ADD_DIALOG,
    })
  }

  // 5個以上選択している場合はAddボタンを非活性
  const { filterPicArr } = state.Pic
  const isDisabled = filterPicArr.length >= ConstantsBidInfo.PIC.LIMIT_COUNT

  return (
    <Grid container item justify="flex-end" spacing={2}>
      <Grid container item xs={1} justify="center" alignItems="center">
        <Grid item>
          <AddButton onClick={dialogOpen} disabled={isDisabled} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AddButtonPicBidInfo
