// Display + 画面名
// JH/SHIP/FWDR/AIRLINE共用で使用
const DisplayJH = {
  // 画面ID + 文言ID
  D401V0001: {
    en: 'Client Reg.',
    ja: '顧客情報',
  },
  D401V0002: {
    en: 'Sign Up Request',
    ja: '新規リクエスト',
  },
  D401V0003: {
    en: 'Shipper',
    ja: '荷主',
  },
  D401V0004: {
    en: 'FWDR',
    ja: '混載業者',
  },
  D401V0005: {
    en: 'Carrier',
    ja: '航空会社',
  },
  D401V0006: {
    en: 'Date',
    ja: '日付',
  },
  D401V0007: {
    en: 'Company',
    ja: '会社',
  },
  D401V0008: {
    en: 'Branch',
    ja: '拠点',
  },
  D401V0009: {
    en: 'Country',
    ja: '国',
  },
  D401V0010: {
    en: 'First Name',
    ja: '名',
  },
  D401V0011: {
    en: 'Last Name',
    ja: '姓',
  },
  D401V0012: {
    en: 'Position',
    ja: '役職',
  },
  D401V0013: {
    en: 'E-mail',
    ja: 'メールアドレス',
  },
  D401V0014: {
    en: 'Telephone',
    ja: '電話番号',
  },
  D401V0015: {
    en: 'Address',
    ja: '住所',
  },
  D401V0016: {
    en: 'Reject',
    ja: '拒否',
  },
  D401V0017: {
    en: 'Accept',
    ja: '承認',
  },
  D401V0018: {
    en: 'Q1.',
    ja: 'Q1.',
  },
  D401V0019: {
    en: 'Q2.',
    ja: 'Q2.',
  },
  D401V0020: {
    en: 'Q3.',
    ja: 'Q3.',
  },
  D401V0021: {
    en: 'Q4.',
    ja: 'Q4.',
  },
  D401V0022: {
    en: 'Delete Account Request',
    ja: '退会リクエスト',
  },
  D401V0040: {
    en: 'Weekly Sales Pref.',
    ja: '週間売り上げ',
  },
  D401V0041: {
    en: 'Monthly Sales Pref.',
    ja: '月間売り上げ',
  },
  D401V0042: {
    en: 'Quaterly Sales Pref.',
    ja: '四半期売り上げ',
  },
  D401V0043: {
    en: 'Sales Target',
    ja: '売り上げ目標',
  },
  D401V0044: {
    en: 'Client Revenue',
    ja: '顧客収益',
  },
  D401V0045: {
    en: 'Client Ranking',
    ja: '顧客ランキング',
  },
  D401V0046: {
    en: 'Total Revenue',
    ja: '総収益',
  },
  D401V0047: {
    en: 'Regular Contract',
    ja: '通常契約',
  },
  D401V0048: {
    en: 'Client List',
    ja: '顧客リスト',
  },
  D401V0049: {
    en: 'Client Reg.',
    ja: '顧客登録',
  },
  D401V0050: {
    en: 'Are you sure?',
    ja: '承認してもよろしいですか？',
  },
  D401V0051: {
    en: 'The nearest airport',
    ja: '最寄り空港',
  },
  D401V0052: {
    en: 'Shipper/ConsigneeID',
    ja: '荷送人/荷受人ID',
  },
  D401V0053: {
    en: 'Postal Code',
    ja: '郵便番号',
  },
  D401V0054: {
    en: 'IATA/CASS No.',
    ja: 'IATA/CASS 番号.',
  },
  D401V0055: {
    en: 'Department',
    ja: '部門.',
  },
  D401V0056: {
    en: 'Hubs',
    ja: '拠点空港',
  },
  D401V0057: {
    en: 'Airport',
    ja: '空港',
  },
  D401V0058: {
    en: 'Forwarder',
    ja: '混載業者',
  },
  D401V0059: {
    en: 'Group',
    ja: 'グループ',
  },
}
// Display + 画面名
export default DisplayJH
