import React, { useContext, useMemo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import AppContext from '../../../../../contexts/AppContext'
import TableCell from '../../../../common/table/TableCell'
import TextField from '../../../../common/text/TextField'
import CommonCalc from '../../../../common/function/CommonCalc'
import Common from '../../../../../constants/Common'
import CommonValidation from '../../../../common/function/CommonValidation'
import { TABLES } from '../../../../../actions'

/**
 * @param {object} props 下記内容
 * @param {Number} props.index ページ上のインデックス
 * @return {JSX} Fscセル
 */
const FscCellLightCarAirFreight = ({ index }) => {
  const { state, dispatch } = useContext(AppContext)
  const [inputError, setInputError] = useState(false)
  const [rateError, setRateError] = useState(false)

  /** @type {Number} showDataのインデックス */
  const page = CommonCalc.calcShowDataIndex(
    state.Paging,
    state.Tables.showData.length
  )

  const { fuelSurCharge, allInRate } = state.Tables.showData[page][index]

  /** @type {Boolean} レートの必須項目でのエラー状態 */
  const error = state.Tables.checkFscError(state.Tables.showData[page][index])

  // レートのエラー状態を更新
  useEffect(() => {
    const { checkResult } = CommonValidation.priceOptional(fuelSurCharge)
    setInputError(checkResult)
    setRateError(error)
  }, [error, fuelSurCharge])

  return useMemo(() => {
    const disabled = allInRate === Common.ALL_IN_RATE.ON || !state.Auth

    /**
     * FSCを更新
     * @param {string} value 入力値
     * @return {void} なし
     */
    const onChange = (value) => {
      dispatch({
        type: TABLES.EDIT,
        page,
        index,
        fuelSurCharge: value,
      })
    }

    return (
      <TableCell>
        <TextField
          value={fuelSurCharge}
          onChange={(event) => onChange(event.target.value)}
          error={inputError || rateError}
          disabled={disabled}
        />
      </TableCell>
    )
  }, [
    fuelSurCharge,
    allInRate,
    dispatch,
    inputError,
    rateError,
    index,
    page,
    state.Auth,
  ])
}
FscCellLightCarAirFreight.propTypes = {
  index: PropTypes.number.isRequired,
}

export default FscCellLightCarAirFreight
