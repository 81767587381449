import React, { useContext } from 'react'

import AppContext from '../../../../../contexts/AppContext'
import CommonCalc from '../../../../common/function/CommonCalc'
import TableRow from '../../../../common/table/TableRow'
import TableCellsLightCarAccountInfo from './TableCellsLightCarAccountInfo'

/**
 * useContextのstateから表全体のデータを作成
 * @return {JSX} TableRow ~ TableCellまでのコンポーネント
 */
const TableContentsLightCarAccountInfo = () => {
  const { state } = useContext(AppContext)

  /** @type {Number} ShowDataのインデックス */
  const page = CommonCalc.calcShowDataIndex(
    state.Paging,
    state.Tables.showData.length - 1
  )
  return state.Tables.showData[page].map((_, index) => (
    <TableRow key={index}>
      <TableCellsLightCarAccountInfo page={page} index={index} />
    </TableRow>
  ))
}

export default TableContentsLightCarAccountInfo
