import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow'

import PasswordField from '../../common/text/PasswordField'
import CommonValidation from '../../common/function/CommonValidation'
import {
  UPDATE_FWDR_CURRENTPASS,
  UPDATE_FWDR_NEWPASS,
  UPDATE_FWDR_NEWPASS_VERIFY,
  UPDATE_FWDR_CURRENTPASS_INPUTERR,
  UPDATE_FWDR_NEWPASS_INPUTERR,
  UPDATE_FWDR_NEWPASS_VERIFY_INPUTERR,
  UPDATE_FWDR_PASS_VERIFY_ERR,
  UPDATE_FWDR_CURRENTPASS_ERR,
  UPDATE_CAR_CURRENTPASS,
  UPDATE_CAR_NEWPASS,
  UPDATE_CAR_NEWPASS_VERIFY,
  UPDATE_CAR_CURRENTPASS_INPUTERR,
  UPDATE_CAR_NEWPASS_INPUTERR,
  UPDATE_CAR_NEWPASS_VERIFY_INPUTERR,
  UPDATE_CAR_PASS_VERIFY_ERR,
  UPDATE_CAR_CURRENTPASS_ERR,
} from '../../../actions'

const useStyles = makeStyles((theme) => ({
  head: theme.textBoldBLU,
  textBlu: theme.textNormalBLU,
  icon: {
    transform: 'rotate(90deg)',
    fontSize: 50,
    color: theme.palette.primary.main,
  },
}))
/**
 * コンポーネントを更新するか判定する
 * @param {Object} prev 前回の値
 * @param {Object} next 最新の値
 * @returns {Boolean} falseの場合更新する
 */
const areEqual = (prev, next) => {
  // /** 各入力欄の値を比較 */
  const currentPasswordValue =
    prev.currentPassword.value === next.currentPassword.value
  const newPassVerifyValue =
    prev.newPassVerify.value === next.newPassVerify.value
  const newPasswordValue = prev.newPassword.value === next.newPassword.value
  const passwordValue = prev.password.value === next.password.value

  // /** パスワードエラー比較 */
  const passwordErrValue =
    prev.passwordErr.current === next.passwordErr.current &&
    prev.passwordErr.digit === next.passwordErr.digit &&
    prev.passwordErr.verify === next.passwordErr.verify

  // 全て一致していれば更新無し
  return (
    currentPasswordValue &&
    newPassVerifyValue &&
    newPasswordValue &&
    passwordValue &&
    passwordErrValue
  )
}

const Password = React.memo(
  ({
    dispatch,
    currentPassword,
    newPassVerify,
    newPassword,
    passwordErr,
    password,
    carrier,
  }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const [inputErrs, setInputErrs] = useState({
      currentPassword: false,
      newPassword: false,
      newPassVerify: false,
    })
    const [helperTxts, setHelperTxts] = useState({
      currentPassword: '',
      newPassword: '',
      newPassVerify: '',
    })

    // 混載業者・航空会社で取得するactionを変更する
    const currentPass = carrier
      ? UPDATE_CAR_CURRENTPASS
      : UPDATE_FWDR_CURRENTPASS
    const newPass = carrier ? UPDATE_CAR_NEWPASS : UPDATE_FWDR_NEWPASS
    const verify = carrier
      ? UPDATE_CAR_NEWPASS_VERIFY
      : UPDATE_FWDR_NEWPASS_VERIFY
    const currentPassInputErr = carrier
      ? UPDATE_CAR_CURRENTPASS_INPUTERR
      : UPDATE_FWDR_CURRENTPASS_INPUTERR
    const newPassInputErr = carrier
      ? UPDATE_CAR_NEWPASS_INPUTERR
      : UPDATE_FWDR_NEWPASS_INPUTERR
    const verifyInputErr = carrier
      ? UPDATE_CAR_NEWPASS_VERIFY_INPUTERR
      : UPDATE_FWDR_NEWPASS_VERIFY_INPUTERR
    const verifyErr = carrier
      ? UPDATE_CAR_PASS_VERIFY_ERR
      : UPDATE_FWDR_PASS_VERIFY_ERR
    const currentPassErr = carrier
      ? UPDATE_CAR_CURRENTPASS_ERR
      : UPDATE_FWDR_CURRENTPASS_ERR

    const handleChangeCurrentPass = (event) => {
      const value = event.target.value
      // 入力チェック
      const validationCheck = CommonValidation.myProfilePassword(value)
      const err = validationCheck.checkResult
      setInputErrs({ ...inputErrs, currentPassword: err })
      setHelperTxts({ ...helperTxts, currentPassword: validationCheck.errMsg })

      dispatch({
        type: currentPass,
        currentPassword: value,
      })
      dispatch({
        type: currentPassErr,
        errflg: false,
      })

      // 入力エラーの場合、Savaボタン無効
      dispatch({
        type: currentPassInputErr,
        inputErr: err,
      })
      // 入力エラー、またはパスワード一致したら不一致エラー解除
      if (err === true || value === password.value) {
        dispatch({
          type: currentPassErr,
          errflg: false,
        })
      }

      // currentPasswordには値が入っていてエラーチェックがfalseでも
      // NewPassword・RetypeNewPasswordに値がない場合、値が入っていないものにエラーをtrueで入れる
      if (value && !err) {
        if (!newPassword.value && !newPassVerify.value) {
          dispatch({
            type: newPassInputErr,
            inputErr: true,
          })
          dispatch({
            type: verifyInputErr,
            inputErr: true,
          })
        } else if (!newPassword.value) {
          dispatch({
            type: newPassInputErr,
            inputErr: true,
          })
        } else if (!newPassVerify.value) {
          dispatch({
            type: verifyInputErr,
            inputErr: true,
          })
        }
      }

      // 一度入力後、入力内容を削除した場合にNewPasswordかRetypeNewPasswordに値が入っていた場合エラーをtrueにする
      if (!value) {
        // どちらかに値がある場合、currentPasswordにエラーtrueを設定
        if (newPassword.value || newPassVerify.value) {
          dispatch({
            type: newPassInputErr,
            inputErr: true,
          })
          // どちらも空の場合、入力時にどちらもエラーtrueで設定しているのでfalseに設定
        } else if (!newPassword.value && !newPassVerify.value) {
          dispatch({
            type: newPassInputErr,
            inputErr: false,
          })
          dispatch({
            type: verifyInputErr,
            inputErr: false,
          })
        } else if (!newPassword.value) {
          dispatch({
            type: newPassInputErr,
            inputErr: false,
          })
        } else if (!newPassVerify.value) {
          dispatch({
            type: verifyInputErr,
            inputErr: false,
          })
        }
      }
    }

    const handleChangeNewPass = (event) => {
      const value = event.target.value
      // 入力チェック
      const validationCheck = CommonValidation.myProfilePassword(value)
      const err = validationCheck.checkResult
      setInputErrs({ ...inputErrs, newPassword: err })
      setHelperTxts({ ...helperTxts, newPassword: validationCheck.errMsg })

      dispatch({
        type: newPass,
        newPassword: value,
      })

      // 入力エラーの場合、Savaボタン無効
      dispatch({
        type: newPassInputErr,
        inputErr: err,
      })

      // NewPasswordには値が入っていてエラーチェックがfalseでも
      // CurrentPassword・RetypeNewPasswordに値がない場合、値が入っていないものにエラーをtrueで入れる
      if (value && !err) {
        if (!currentPassword.value && !newPassVerify.value) {
          dispatch({
            type: currentPassErr,
            inputErr: true,
          })
          dispatch({
            type: verifyInputErr,
            inputErr: true,
          })
        } else if (!currentPassword.value) {
          dispatch({
            type: currentPassErr,
            inputErr: true,
          })
        } else if (!newPassVerify.value) {
          dispatch({
            type: verifyInputErr,
            inputErr: true,
          })
        }
      }

      // 一度入力後、入力内容を削除した場合にCurrentPasswordかRetypeNewPasswordに値が入っていた場合エラーをtrueにする
      if (!value) {
        // どちらかに値がある場合、newPasswordにエラーtrueを設定
        if (currentPassword.value || newPassVerify.value) {
          dispatch({
            type: newPassInputErr,
            inputErr: true,
          })
          // どちらも空の場合、入力時に値があるかのチェックでどちらもエラーtrueで設定しているのでfalseに設定
        } else if (!currentPassword.value && !newPassVerify.value) {
          dispatch({
            type: currentPassErr,
            inputErr: false,
          })
          dispatch({
            type: verifyInputErr,
            inputErr: false,
          })
        } else if (!currentPassword.value) {
          dispatch({
            type: newPassInputErr,
            inputErr: false,
          })
        } else if (!newPassVerify.value) {
          dispatch({
            type: verifyInputErr,
            inputErr: false,
          })
        }
      }
    }

    const handleChangeNewPassVerify = (event) => {
      const value = event.target.value
      // 入力チェック
      const validationCheck = CommonValidation.myProfilePassword(value)
      const err = validationCheck.checkResult
      setInputErrs({ ...inputErrs, newPassVerify: err })
      setHelperTxts({ ...helperTxts, newPassVerify: validationCheck.errMsg })

      dispatch({
        type: verify,
        newPassVerify: value,
      })

      // 入力エラーの場合、Savaボタン無効
      dispatch({
        type: verifyInputErr,
        inputErr: err,
      })
      // 入力エラー、またはパスワード一致したら不一致エラー解除
      if (err === true || value === newPassword.value) {
        dispatch({
          type: verifyErr,
          errflg: false,
        })
      }

      // RetypeNewPasswordには値が入っていてエラーチェックがfalseでも
      // CurrentPassword・NewPasswordに値がない場合、値が入っていないものにエラーをtrueで入れる
      if (value && !err) {
        if (!currentPassword.value && !newPassword.value) {
          dispatch({
            type: currentPassErr,
            inputErr: true,
          })
          dispatch({
            type: newPassInputErr,
            inputErr: true,
          })
        } else if (!currentPassword.value) {
          dispatch({
            type: currentPassErr,
            inputErr: true,
          })
        } else if (!newPassword.value) {
          dispatch({
            type: newPassInputErr,
            inputErr: true,
          })
        }
      }

      // 一度入力後、入力内容を削除した場合にCurrentPasswordかNewPasswordに値が入っていた場合エラーをtrueにする
      if (!value) {
        // どちらかに値がある場合、RetypeNewPasswordにエラーtrueを設定
        if (currentPassword.value || newPassword.value) {
          dispatch({
            type: verifyInputErr,
            inputErr: true,
          })
          // どちらも空の場合、入力時に値があるかのチェックでどちらもエラーをtrueで設定しているのでfalseに設定
        } else if (!currentPassword.value && !newPassword.value) {
          dispatch({
            type: currentPassErr,
            inputErr: false,
          })
          dispatch({
            type: newPassInputErr,
            inputErr: false,
          })
        } else if (!currentPassword.value) {
          dispatch({
            type: currentPassErr,
            inputErr: false,
          })
        } else if (!newPassword.value) {
          dispatch({
            type: newPassInputErr,
            inputErr: false,
          })
        }
      }
    }

    return (
      <form>
        {' '}
        {/* 警告解消のためformタグを追加 */}
        <div className={classes.formControl}>
          <Grid container>
            <Grid item md={10} xs={10}>
              <div className={classes.head}>{t('D301V0026')}</div>
            </Grid>
          </Grid>
          <Grid container>
            {/* 警告解消のため非表示のe-mail欄を追加 */}
            <input
              type="text"
              name="email"
              autoComplete="username email"
              hidden
            />
            <Grid item style={{ marginBottom: '3rem' }} md={4} xs={10}>
              <div className={classes.textBlu}>{t('D301V0027')}</div>
              <PasswordField
                id="currentPassword"
                err={passwordErr.current || inputErrs.currentPassword}
                helperTxt={
                  passwordErr.current
                    ? t('D301V0160')
                    : t(helperTxts.currentPassword)
                }
                value={currentPassword.value}
                handleChange={handleChangeCurrentPass}
                autoComplete="new-password"
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            md={4}
            xs={10}
            alignItems="center"
            justify="center"
          >
            <Grid item>
              <DoubleArrowIcon className={classes.icon} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item style={{ marginBottom: '3rem' }} md={10} xs={10} />
            <Grid item md={4} xs={10}>
              <div className={classes.textBlu}>{t('D301V0028')}</div>
              <PasswordField
                id="newPassword"
                err={passwordErr.digit || inputErrs.newPassword}
                helperTxt={
                  passwordErr.digit ? t('D031V0210') : t(helperTxts.newPassword)
                }
                value={newPassword.value}
                handleChange={handleChangeNewPass}
                autoComplete="new-password"
              />
            </Grid>
            <Grid item style={{ marginBottom: '3rem' }} md={10} xs={10} />
            <Grid item md={4} xs={10}>
              <div className={classes.textBlu}>{t('D301V0029')}</div>
              <PasswordField
                id="newPassVerify"
                err={passwordErr.verify || inputErrs.newPassVerify}
                helperTxt={
                  passwordErr.verify
                    ? t('D301V0161')
                    : t(helperTxts.newPassVerify)
                }
                value={newPassVerify.value}
                handleChange={handleChangeNewPassVerify}
                autoComplete="new-password"
              />
            </Grid>
          </Grid>
        </div>
      </form>
    )
  },
  areEqual
)

Password.displayName = 'Password'

Password.propTypes = {
  dispatch: PropTypes.func.isRequired,
  currentPassword: PropTypes.object.isRequired,
  newPassVerify: PropTypes.object.isRequired,
  newPassword: PropTypes.object.isRequired,
  passwordErr: PropTypes.object.isRequired,
  password: PropTypes.object.isRequired,
  carrier: PropTypes.bool,
}

export default Password
