import CommonFunc from '../../components/common/function/CommonFunc'
import Common from '../../constants/Common'

/** @type {Boolean} 管理者権限の有無 */
const initAuth =
  CommonFunc.getLoginUserData().authorityFlg === Common.AUTHORITY_FLG.ON

/**
 * 管理者権限情報を保持
 * @return {Boolean} - 管理者権限情報
 */
const Auth = () => {
  return CommonFunc.getLoginUserData().authorityFlg === Common.AUTHORITY_FLG.ON
}

export default Auth

export { initAuth }
