// Display + 画面名
const DisplayAirlineUldAssign = {
  // 画面ID + 文言ID
  D208V0001: {
    en: 'Flight Prep.',
    ja: '飛行準備',
  },
  D208V0002: {
    en: 'CargoMFST',
    ja: 'CargoMFST',
  },
  D208V0003: {
    en: 'NOTOC',
    ja: 'NOTOC',
  },
  D208V0004: {
    en: 'ULD Assign',
    ja: 'ULD割り当て',
  },
  D208V0005: {
    en: 'Set Flight',
    ja: 'フライト設定',
  },
  D208V0006: {
    en: 'Assigned',
    ja: '割り当てられた',
  },
  D208V0007: {
    en: 'ORG/DST',
    ja: 'ORG/DST',
  },
  D208V0008: {
    en: 'Flight',
    ja: 'フライト',
  },
  D208V0009: {
    en: 'ETD/ETA',
    ja: 'ETD/ETA',
  },
  D208V0010: {
    en: 'Aircraft',
    ja: '航空機',
  },
  D208V0011: {
    en: 'TTL Wgt./MAX Wgt. (kg)',
    ja: 'TTL Wgt./MAX Wgt. (kg)',
  },
  D208V0012: {
    en: 'TTL Vol./MAX Vol.(m3)',
    ja: 'TTL Vol./MAX Vol.(m3)',
  },
  D208V0013: {
    en: 'Status',
    ja: '状態',
  },
  D208V0014: {
    en: 'NRT/ORD/DFW',
    ja: 'NRT/ORD/DFW',
  },
  D208V0015: {
    en: 'KZXXX/04SEP',
    ja: 'KZXXX/04SEP',
  },
  D208V0016: {
    en: '11:25/16:30',
    ja: '11:25/16:30',
  },
  D208V0017: {
    en: 'Boeing747-8F',
    ja: 'Boeing747-8F',
  },
  D208V0018: {
    en: '18.75/134,000.00',
    ja: '18.75/134,000.00',
  },
  D208V0019: {
    en: '9.43/858.10',
    ja: '9.43/858.10',
  },
  D208V0020: {
    en: 'FBL',
    ja: 'FBL',
  },
  D208V0021: {
    en: 'MAWB No.',
    ja: 'MAWB番号',
  },
  D208V0022: {
    en: 'Pcs./Wgt./Vol.',
    ja: '個数/重量/体積',
  },
  D208V0023: {
    en: 'Commodity',
    ja: '商品',
  },
  D208V0024: {
    en: 'SHC Code',
    ja: 'SHCコード',
  },
  D208V0025: {
    en: 'FWDR',
    ja: '混載業者',
  },
  D208V0026: {
    en: 'Allotment',
    ja: '割当て',
  },
  D208V0027: {
    en: 'Stn.',
    ja: 'Stn.',
  },
  D208V0028: {
    en: '933-XXXX XXXX',
    ja: '933-XXXX XXXX',
  },
  D208V0029: {
    en: 'XXpcs./XXX.XXkg/XX.XXm3',
    ja: 'XXpcs./XXX.XXkg/XX.XXm3',
  },
  D208V0030: {
    en: 'BEEF',
    ja: 'BEEF',
  },
  D208V0031: {
    en: 'PER',
    ja: 'PER',
  },
  D208V0032: {
    en: '79.45/858.10',
    ja: '79.45/858.10',
  },
  D208V0033: {
    en: 'NRT/ORD',
    ja: 'NRT/ORD',
  },
  D208V0034: {
    en: 'Details',
    ja: '詳細',
  },
  D208V0035: {
    en: 'CONSOL',
    ja: 'CONSOL',
  },
  D208V0036: {
    en: 'Back',
    ja: '戻る',
  },
  D208V0037: {
    en: 'NRT',
    ja: 'NRT',
  },
  D208V0038: {
    en: 'FRO/ICE',
    ja: 'FRO/ICE',
  },
  D208V0039: {
    en: 'RLM/CAO',
    ja: 'RLM/CAO',
  },
  D208V0040: {
    en: 'KWE',
    ja: 'KWE',
  },
  D208V0041: {
    en: 'NEC',
    ja: 'NEC',
  },
  D208V0042: {
    en: 'RESIN',
    ja: 'RESIN',
  },
  D208V0043: {
    en: 'RFL',
    ja: 'RFL',
  },
  D208V0044: {
    en: 'HEA/VOL',
    ja: 'HEA/VOL',
  },
  D208V0045: {
    en: 'YLK',
    ja: 'YLK',
  },
  D208V0046: {
    en: '2P1C',
    ja: '2P1C',
  },
  D208V0047: {
    en: 'KKS',
    ja: 'KKS',
  },
  D208V0048: {
    en: 'KNJ',
    ja: 'KNJ',
  },
  D208V0049: {
    en: 'STBY TL XXAUG',
    ja: 'STBY TL XXAUG',
  },
  D208V0050: {
    en: 'NRT/HKG',
    ja: 'NRT/HKG',
  },
  D208V0051: {
    en: 'Lower',
    ja: '下位',
  },
  D208V0052: {
    en: 'Main',
    ja: '主要',
  },
  D208V0053: {
    en: 'All',
    ja: '全て',
  },
  D208V0054: {
    en: 'ULD Type',
    ja: 'ULDタイプ',
  },
  D208V0055: {
    en: 'ULD No.',
    ja: 'ULD番号',
  },
  D208V0056: {
    en: 'ULD Owner',
    ja: 'ULD所有者',
  },
  D208V0057: {
    en: 'Limitation',
    ja: '制限',
  },
  D208V0058: {
    en: 'Alloc',
    ja: 'アロック',
  },
  D208V0059: {
    en: 'Load',
    ja: '積載',
  },
  D208V0060: {
    en: 'SHC',
    ja: 'SHC',
  },
  D208V0061: {
    en: 'Contour',
    ja: '輪郭',
  },
  D208V0062: {
    en: 'PMC',
    ja: 'PMC',
  },
  D208V0063: {
    en: '12345',
    ja: '12345',
  },
  D208V0064: {
    en: 'KZ',
    ja: 'KZ',
  },
  D208V0065: {
    en: '6,804kg',
    ja: '6,804kg',
  },
  D208V0066: {
    en: '300kg',
    ja: '300kg',
  },
  D208V0067: {
    en: '400kg',
    ja: '400kg',
  },
  D208V0068: {
    en: 'COL',
    ja: 'COL',
  },
  D208V0069: {
    en: '70/100pcs.',
    ja: '70/100個',
  },
  D208V0070: {
    en: '700.00/1,000.00kg',
    ja: '700.00/1,000.00kg',
  },
  D208V0071: {
    en: 'XX.XXm3',
    ja: 'XX.XXm3',
  },
  D208V0072: {
    en: 'XXXX',
    ja: 'XXXX',
  },
  D208V0073: {
    en: 'XXpcs.',
    ja: 'XXpcs.',
  },
  D208V0074: {
    en: 'XXX.XXkg',
    ja: 'XXX.XXkg',
  },
  D208V0075: {
    en: 'AKE',
    ja: 'AKE',
  },
  D208V0076: {
    en: '1,587kg',
    ja: '1,587kg',
  },
  D208V0077: {
    en: 'Pcs.',
    ja: '個数',
  },
  D208V0078: {
    en: 'Wgt.',
    ja: '重量',
  },
  D208V0079: {
    en: 'Vol.',
    ja: '体積',
  },
  D208V0080: {
    en: '1C PURE',
    ja: '1C PURE',
  },
  D208V0081: {
    en: '2P1C PURE',
    ja: '2P1C PURE',
  },
  D208V0082: {
    en: 'RFL/CAO',
    ja: 'RFL/CAO',
  },
  D208V0083: {
    en: '11,340kg',
    ja: '11,340kg',
  },
  D208V0084: {
    en: 'Confirm',
    ja: '確認',
  },
  D208V0085: {
    en: 'LD3',
    ja: 'LD3',
  },
  D208V0086: {
    en: 'Q6',
    ja: 'Q6',
  },
  D208V0087: {
    en: 'Q7',
    ja: 'Q7',
  },
  D208V0088: {
    en: '20ft',
    ja: '20ft',
  },
  D208V0089: {
    en: 'ULD Mgmt',
    ja: 'ULD 管理',
  },
  D208V0090: {
    en: 'Edit',
    ja: '編集',
  },
  D208V0091: {
    en: 'Destination Airport',
    ja: '目的地の空港',
  },
  D208V0092: {
    en: 'Add',
    ja: '追加',
  },
  D2080093: {
    en: 'ULDs contained in a separate tab cannot be manipulated.',
    ja: '別のタブに含まれるULDは操作できません。',
  },
  D208094: {
    en: 'Alloc is over limitation.',
    ja: 'アロックが制限重量を超えています。',
  },
  D208095: {
    en: 'Pcs. is over the total Pcs. of MAWB.',
    ja: 'MAWBの合計個数を超えています。',
  },
  D208096: {
    en: 'Wgt. is over the total Wgt. of MAWB.',
    ja: 'MAWBの合計重量を超えています。',
  },
  D208097: {
    en: 'Vol is over the total Vol. of MAWB.',
    ja: 'MAWBの合計体積を超えています。',
  },
}
// Display + 画面名
export default DisplayAirlineUldAssign
