import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import TableCell from '../../../../../common/table/TableCell'

/**
 * @param {object} props 下記内容
 * @param {String} props.rateType レートタイプ
 * @return {JSX} Typeセルを作成
 */
const TypeCellLightCarAirFreight = ({ rateType }) => {
  return useMemo(
    () => <TableCell>{rateType.toUpperCase()}</TableCell>,
    [rateType]
  )
}

TypeCellLightCarAirFreight.propTypes = {
  rateType: PropTypes.string.isRequired,
}

export default TypeCellLightCarAirFreight
