import React, { useContext } from 'react'

import Common from '../../../../../constants/Common'
import AppContext from '../../../../../contexts/AppContext'
import { DIALOG, TABLES } from '../../../../../actions'
import SavedChangesDialog from '../../../../common/dialog/commonDesignDialog/SavedChangesDialog'

/**
 * @return {JSX} 保存完了ダイアログコンポーネント
 */
const SavedChangesDialogLightJhClientRegSignUp = () => {
  const { state, dispatch } = useContext(AppContext)

  /**
   * @returns {void} 新規リクエスト情報をセットした後ダイアログを閉じる
   */
  const closeDialog = () => {
    dispatch({
      type: TABLES.INIT,
      data: state.Dialog.response,
      tabs: state.Tabs,
    })
    dispatch({
      type: DIALOG.CLOSE,
    })
  }

  const dialogFuncObj = {
    [Common.DIALOG.BUTTON_CATEGORY.OK]: { onClick: closeDialog },
  }

  const { isOpenSuccess } = state.Dialog

  return (
    <SavedChangesDialog
      open={isOpenSuccess}
      closeFunc={closeDialog}
      dialogFuncObj={dialogFuncObj}
    />
  )
}

export default SavedChangesDialogLightJhClientRegSignUp
