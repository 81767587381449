import React from 'react'
import PropTypes from 'prop-types'

import CompanyInfoText from '../text/CompanyInfoText/CompanyInfoText'
import CommonFunc from '../function/CommonFunc'

/**
 * @param {Object} props - 以下
 * @param {Array} bidInfo - 入札情報
 * @return {JSX} Shipperコンポーネント
 */
const Shipper = (props) => {
  const shipper = props.bidInfo.history.location.state?.state.bidInfoObj ?? []

  const {
    shipperAddress,
    shipperCompanyName,
    shipperTel,
    shipperFirstName,
    shipperLastName,
  } = shipper
  const textArr = CommonFunc.createAddressArr(
    shipperAddress,
    shipperCompanyName,
    shipperTel,
    shipperFirstName,
    shipperLastName
  )

  return <CompanyInfoText textArr={textArr} />
}

Shipper.propTypes = {
  bidInfo: PropTypes.object.isRequired,
}

export default Shipper
