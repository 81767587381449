import React, { useContext } from 'react'

import AppContext from '../../../../../contexts/AppContext'
import CancelBookingLightBookingDetails from '../../../../commonUseContext/LightBookingDetails/CancelBooking/CancelBookingLightBookingDetails'
import { BeforePath } from '../ConstantsLightBookingDetails'

/**
 * @return {JSX} CancelBookingアコーディオン
 */
const CancelFwdrLightBookingDetails = () => {
  const { state } = useContext(AppContext)
  const disabled = state.Status.isCancel || state.Status.isClose
  const beforePath = state.ShippingDetails.beforePath
  const isShow = beforePath === BeforePath.BookingList
  return isShow ? (
    <CancelBookingLightBookingDetails disabled={disabled} />
  ) : null
}

export default CancelFwdrLightBookingDetails
