import React from 'react'

import SearchSort from '../../../../commonUseContext/SearchSort'
import ConstantsLightFwdrSearchResult from '../ConstantsLightFwdrSearchResult'
import { useTranslation } from 'react-i18next'

/**
 * @return {JSX} サーチソートコンポーネント
 */
const SearchSortLightFwdrSearchResult = () => {
  const { t } = useTranslation()
  return (
    <SearchSort
      placeholder={t('D301V0188')}
      sortItemArr={ConstantsLightFwdrSearchResult.SORT_ITEM_ARR}
    />
  )
}

export default SearchSortLightFwdrSearchResult
