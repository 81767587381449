import React, { useContext } from 'react'

import AppContext from '../../../../../contexts/AppContext'
import TableRow from '../../../../common/table/TableRow'
import IconTableCellsLightCarFlightMgmt from './IconTableCellsLightCarFlightMgmt'
import CommonCalc from '../../../../common/function/CommonCalc'
import IconPopup from './IconPopup/IconPopup'

/**
 * useContextのstateから表全体のデータを作成
 * @return {JSX} TableRow ~ TableCellまでのコンポーネント
 */
const IconTableContentsLightCarFlightMgmt = () => {
  const { state } = useContext(AppContext)
  const { showData } = state.Tables

  /** @type {Number} state.showDataのインデックス */
  const page = CommonCalc.calcShowDataIndex(
    state.Paging,
    state.Tables.showData.length
  )

  return (
    <>
      {showData[page].map((_, index) => (
        <TableRow key={index}>
          <IconTableCellsLightCarFlightMgmt index={index} />
        </TableRow>
      ))}
      <IconPopup />
    </>
  )
}

export default IconTableContentsLightCarFlightMgmt
