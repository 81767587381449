import React, { useContext } from 'react'
import AppContext from '../../../../../contexts/AppContext'

import CommonTableContainer from '../../../../common/table/CommonTableContainer'
import HeaderLightCarAccountInfo from './HeaderLightCarAccountInfo'
import TableContentsLightCarAccountInfo from './TableContentsLightCarAccountInfo'

/**
 * @return {JSX} 表コンポーネント(全体)
 */
const TableLightCarAccountInfo = () => {
  const { state } = useContext(AppContext)
  /** @type {Boolean} 表データがない場合true */
  const isNoResult = state.Tables.isNoResult

  return (
    <CommonTableContainer isNoResult={isNoResult}>
      <HeaderLightCarAccountInfo />
      <TableContentsLightCarAccountInfo />
    </CommonTableContainer>
  )
}

export default TableLightCarAccountInfo
