/* eslint-disable no-extra-parens */
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import InputSelect from '../../../common/select/InputSelect'
import AppContext from '../../../../contexts/AppContext'
import {
  UPDATE_SHIP_BRANCH,
  UPDATE_SHIP_BRANCH_INPUTERR,
} from '../../../../actions'

const useStyles = makeStyles((theme) => ({
  head: theme.textBoldBLU,
  // eslint-disable-next-line camelcase
  text_blk: theme.textNormalBLK,
}))

const Branch = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { state, dispatch } = useContext(AppContext)

  const handleChangeSelect = (_event, newValue) => {
    const cityName = newValue ? newValue.name : ''
    const cityId = newValue ? newValue.code : null
    dispatch({
      type: UPDATE_SHIP_BRANCH,
      cityName,
      cityId,
    })

    // eslint-disable-next-line no-unneeded-ternary
    const err = newValue === null ? true : false

    // 入力エラーの場合、Savaボタン無効
    dispatch({
      type: UPDATE_SHIP_BRANCH_INPUTERR,
      inputErr: err,
    })
  }

  const select = state.companyinfo.cityName.value

  return (
    <div className={classes.formControl}>
      <Grid container>
        <Grid item md={10} xs={10}>
          <div className={classes.head}>{t('D301V0004')}</div>
        </Grid>
        <Grid item md={4} xs={10}>
          <InputSelect
            id={'cityName'}
            options={props.cityName}
            value={select.name ? select : null}
            getOptionLabel={(option) => option.name}
            onChange={handleChangeSelect}
            getOptionSelected={(option, value) => option.name === value.name}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default Branch
