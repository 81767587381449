import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import Common from '../../../../../constants/Common'
import ConstantsBidDetails, {
  getIsShowColumn,
  getTableArr,
} from '../ConstantsBidDetails'
import SelectCellBidDetails from '../../../../commonUseContext/Bid/Table/SelectCellBidDetails'
import TextFieldCellBidDetails from '../../../../commonUseContext/Bid/Table/TextFieldCellBidDetails'
import TableCellBidDetails from '../../../../commonUseContext/Bid/Table/TableCellBidDetails'
import DeleteCellBidDetails from './DeleteCellBidDetails'
import CheckboxCellBidDetails from './CheckboxCellBidDetails'
import TtlTextFieldCellBidDetails from './TtlTextFieldCellBidDetails'
import CommonValidation from '../../../../common/function/CommonValidation'
import AppContext from '../../../../../contexts/AppContext'

/**
 * コンポーネントを更新するか判定する
 * @param {Object} prev 前回の値
 * @param {Object} next 最新の値
 * @returns {Boolean} falseの場合更新する
 */
const areEqual = (prev, next) => {
  /** 1.セルの値を比較 */
  let cellValue = false
  if (Array.isArray(next.cellRequiredObj.cellValue)) {
    //配列の比較
    //不一致がある場合true
    cellValue = next.cellRequiredObj.cellValue
      ?.map(
        (_, index) =>
          prev.cellRequiredObj.cellValue[index] ===
          next.cellRequiredObj.cellValue[index]
      )
      .includes(false)
    //値を逆にする
    cellValue = !cellValue
  } else {
    //文字列・数値・nullの比較
    const relationValue =
      prev.cellRequiredObj.relationValue === next.cellRequiredObj.relationValue
    cellValue =
      prev.cellRequiredObj.cellValue === next.cellRequiredObj.cellValue &&
      relationValue
  }

  /** 2.ステータスの値を比較 */
  const statusId = prev.editedStatusId === next.editedStatusId

  /** 3.ページ数・検索・ソート・APIデータ数を比較 */
  const checkUpdateStr = prev.checkUpdateStr === next.checkUpdateStr

  /** 4.インデックスの値を比較 */
  const index = prev.cellRequiredObj.index === next.cellRequiredObj.index

  /** 5.エラー判定 */
  const isError = prev.isError === next.isError

  //1.2.3.4.5.が全て一致していれば更新無し
  return cellValue && checkUpdateStr && statusId && index && isError
}

/**
 * 定数で定義した表データのコンポーネントを作成
 * @param {Object} tableConstantsObj ConstantsBidDetailsで定義したテーブルの1要素
 * @param {Object} tableContentObj 表示するテーブルの1行分の要素
 * @param {Object} stateApiData state.ApiDataの値
 * @param {Object} cellRequiredObj 列を表示するか
 * @param {Number} editedStatusId 編集後のstatusId
 * @param {Boolean} isError エラー状態
 * @return {JSX} TableCellコンポーネント(1個分)
 */
const CeateTableCell = React.memo((props) => {
  // 定数に紐づいている表示用のcell要素を取り出す
  const {
    tableConstantsObj,
    tableContentObj,
    stateApiData,
    cellRequiredObj,
    editedStatusId,
    isError,
  } = props
  const { cellKey, cellCategory, rightBorder } = tableConstantsObj
  const { statusId = -1 } = tableContentObj
  const { index, showIndex } = cellRequiredObj
  const key = `${index}${cellKey}`
  const API_DATA = ConstantsBidDetails.API_DATA

  /** セルに表示する内容 */
  let displayContent = null

  // cellCategoryから各セルの内容を取得
  switch (cellCategory) {
    case Common.CELL_CATEGORY.CHECKBOX.SELECT:
      displayContent = CheckboxCellBidDetails(cellRequiredObj, statusId, key)
      break
    case Common.CELL_CATEGORY.NO:
      displayContent = `${showIndex + 1}.`
      break
    case Common.CELL_CATEGORY.SELECT.AIRPORT:
      displayContent = SelectCellBidDetails(
        cellRequiredObj,
        stateApiData,
        API_DATA.AIRPORT,
        isError
      )
      break
    case Common.CELL_CATEGORY.SELECT.FLIGHT:
      displayContent = SelectCellBidDetails(
        cellRequiredObj,
        stateApiData,
        API_DATA.FLIGHT_TYPE
      )
      break
    case Common.CELL_CATEGORY.SELECT.COMMODITY:
      displayContent = SelectCellBidDetails(
        cellRequiredObj,
        stateApiData,
        API_DATA.COMMODITY,
        isError
      )
      break
    case Common.CELL_CATEGORY.SELECT.TERMS:
      displayContent = SelectCellBidDetails(
        cellRequiredObj,
        stateApiData,
        API_DATA.TERMS
      )
      break
    case Common.CELL_CATEGORY.SELECT.LT:
      displayContent = SelectCellBidDetails(
        cellRequiredObj,
        stateApiData,
        API_DATA.LT
      )
      break
    case Common.CELL_CATEGORY.SELECT.CURRENCY:
      displayContent = SelectCellBidDetails(
        cellRequiredObj,
        stateApiData,
        API_DATA.CURRENCY
      )
      break
    case Common.CELL_CATEGORY.TEXT_FIELD.TTL_WGT:
      displayContent = TtlTextFieldCellBidDetails(
        cellRequiredObj,
        '3rd0025',
        key
      )
      break
    case Common.CELL_CATEGORY.TEXT_FIELD.TTL_VOL:
      displayContent = TtlTextFieldCellBidDetails(
        cellRequiredObj,
        '3rd0026',
        key
      )
      break
    case Common.CELL_CATEGORY.TEXT_FIELD.REMARK:
      displayContent = TextFieldCellBidDetails(
        cellRequiredObj,
        CommonValidation.remark,
        ''
      )
      break
    case Common.CELL_CATEGORY.BUTTON.DELETE:
      displayContent = DeleteCellBidDetails(
        index,
        cellRequiredObj.apiRequiredObj
      )
      break
    default:
      displayContent = (
        <Typography noWrap align="center">
          {cellRequiredObj.cellValue}
        </Typography>
      )
  }

  return (
    <TableCellBidDetails
      key={key}
      displayContent={displayContent}
      isGrayOut={ConstantsBidDetails.GRAY_OUT_STATUS_ID_ARR.includes(
        editedStatusId
      )}
      rightBorder={rightBorder}
    />
  )
}, areEqual)

CeateTableCell.displayName = 'CeateTableCell'

/**
 * セレクト欄の重複を確認する
 * @param {Object} tableContentObj 1行分のデータ
 * @param {Array} editData 全ての表データ
 * @returns {Boolean} 表内に重複しているデータがあるか
 */
const checkDuplication = (tableContentObj, editData) => {
  const { orgId, dstId, commId, apiIndexNum } = tableContentObj
  const result = editData
    .filter(({ delFlg }) => delFlg !== Common.DEL_FLG.ON)
    .find((data) => {
      const isOrgId = data.orgId === orgId
      const isDstId = data.dstId === dstId
      const isCommId = data.commId === commId
      const isApiIndexNum = data.apiIndexNum !== apiIndexNum
      const isInput = orgId && dstId && commId
      return isOrgId && isDstId && isCommId && isApiIndexNum && isInput
    })
  return Boolean(result)
}

/**
 * 1行分の表データを作成
 * @param {*} props 下記要素
 * @param {Object} tableContentObj 表示するテーブルの1行分の要素
 * @param {Number} index showDataの番号
 * @return {JSX} TableCellコンポーネント(1行分)
 */
const TableCellsBidDetails = (props) => {
  const { tableContentObj, index, showIndex } = props
  const { statusStr, apiIndexNum } = tableContentObj
  const { state, dispatch } = useContext(AppContext)
  const history = useHistory()

  /** 編集後のstatusId */
  const editedStatusId = Object.entries(Common.BID_STATUS).find(
    (status) => status[1].TEXT === statusStr
  )[1].ID

  /**テーブル情報の配列 */
  const tableArr = getTableArr(state.IsNew)

  /** APIの更新判定用 */
  const apiDataKeyLength = Object.keys(state.ApiData).length

  /** 更新判定用の値をまとめた文字列 */
  const checkUpdateStr = `${state.SearchSort.search}${state.SearchSort.sort}${state.Paging}${apiDataKeyLength}`

  /** APIの呼び出しに必要なオブジェクト */
  const apiRequiredObj = { dispatch, history }

  const isError = checkDuplication(tableContentObj, state.Tables.editData)

  return tableArr.map((tableConstantsObj, tableIndex) => {
    /** 表示判定結果 */
    const isShowColumn = getIsShowColumn(
      tableConstantsObj.checkboxName,
      state.Checkbox
    )
    if (isShowColumn) {
      const cellKey = tableConstantsObj.cellKey
      const relationCellKey = tableConstantsObj.relationCellKey
      // Max, Minの値があるものはcellKeyが'MaxVol,MinVol'のようになっているため分離する
      const cellKeyArr = cellKey.split(',')
      // cellKeyから値を取得して格納する
      const cellValue =
        cellKeyArr.length === 1
          ? tableContentObj[cellKey]
          : cellKeyArr.map((cellKey) => tableContentObj[cellKey])
      const relationValue = tableContentObj[relationCellKey]
      // 主要な値をオブジェクトにまとめる
      const cellRequiredObj = {
        apiIndexNum,
        cellKey,
        relationValue,
        cellValue,
        apiRequiredObj,
        index,
        showIndex,
      }
      return (
        <CeateTableCell
          key={`createTableCell${index}${tableIndex}`}
          tableConstantsObj={tableConstantsObj}
          tableContentObj={tableContentObj}
          stateApiData={state.ApiData}
          cellRequiredObj={cellRequiredObj}
          editedStatusId={editedStatusId}
          checkUpdateStr={checkUpdateStr}
          isError={isError}
        />
      )
    }
    return null
  })
}

TableCellsBidDetails.propTypes = {
  tableContentObj: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  showIndex: PropTypes.number.isRequired,
}

CeateTableCell.propTypes = {
  tableConstantsObj: PropTypes.object.isRequired,
  tableContentObj: PropTypes.object.isRequired,
  stateApiData: PropTypes.object.isRequired,
  cellRequiredObj: PropTypes.object.isRequired,
  editedStatusId: PropTypes.number.isRequired,
  isError: PropTypes.bool.isRequired,
}

export default TableCellsBidDetails
