import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CommonFunc from '../function/CommonFunc'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    width: 'fit-content',
    margin: 'auto',
    fontFamily: theme.typography.fontFamily,
  },
  red: {
    fontSize: 16,
    textTransform: 'none',
    background: theme.palette.border.black,
    border: '1px solid',
    borderRadius: 20,
    width: theme.buttonSize.width.small,
    height: theme.buttonSize.height.small,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    [theme.breakpoints.down('sm')]: {
      width: theme.buttonSize.width.small,
      height: theme.buttonSize.height.small,
    },
    color: theme.fontColor.secondary,
    backgroundColor: theme.palette.required,
    fontColor: theme.palette.info.main,
    '&:hover': {
      backgroundColor: theme.palette.reject.dark,
    },
    '&:active': {
      backgroundColor: theme.palette.reject.light,
    },
  },
}))

/**
 * @param {Object} props - 以下
 * @param {String} addclass - 追加で適用するデザイン
 * @param {string} path 押下後の遷移先
 * @param {Event} clickevent - 押下後のイベント
 * @param {object} state - 遷移先に渡すデータ
 * @returns {JSX.Element} - 赤色ボタンコンポーネント
 */
const RedButton = (props) => {
  /**
   * @param {*} className - RedButton側で設定されたclassName
   * @param {*} addClassName - 呼び出し側で追加されたclassName
   * @returns {String} - classNameを統合した文字列
   */
  const getClass = (className, addClassName) => {
    let returnClassName = className
    // addClassNameがある場合は統合
    if (addClassName) {
      // eslint-disable-next-line prefer-template
      returnClassName += ' ' + addClassName
    }
    return returnClassName
  }
  const classes = useStyles()

  // clickイベントとページ遷移
  const history = useHistory()
  const clickEvent = (props) => {
    CommonFunc.clickEvent(props, history)
  }

  return (
    <Button
      variant="contained"
      className={getClass(classes.red, props.addclass)}
      onClick={() => clickEvent(props)}
      {...props}
    >
      {props.children}
    </Button>
  )
}

export default RedButton
