import Common from '../../../../constants/Common'

/**
 * @typedef {Object} TableInfo テーブル情報
 * @property {string} headerKey ヘッダーに表示する文言番号
 * @property {string} cellKey セルに表示するプロパティ名
 * @property {string} cellCategory Common.CELL_CATEGORYの値
 */

/**
 * @typedef {Object} SortInfo 並び替え情報
 * @property {string} sortTerm Common.SORT_TERMSの値
 * @property {string} wordKey 並び替え欄に表示する文言
 * @property {string} sortKey 並び替えに使用するプロパティ名
 */

/** @enum {Number} タブの値 */
const TabName = {
  forwarder: 0,
  carrier: 1,
}

const ConstantsLightJhClientList = {
  /** @type {Number} 1ページに表示する件数 */
  PAGE_AMOUNT: 15,

  /** @type {TableInfo[]} テーブル作成に必要な値 */
  FWDR_TABLE_ARR: [
    {
      headerKey: 'D302V0032',
      cellKey: 'companyName',
      cellCategory: Common.CELL_CATEGORY.TEXT,
    },
    {
      headerKey: 'D302V0033',
      cellKey: 'representative',
      cellCategory: Common.CELL_CATEGORY.TEXT,
    },
    {
      headerKey: 'D302V0007',
      cellKey: 'tel',
      cellCategory: Common.CELL_CATEGORY.TEXT,
    },
    {
      headerKey: 'D302V0008',
      cellKey: 'mail',
      cellCategory: Common.CELL_CATEGORY.TEXT,
    },
    {
      headerKey: '5th0003',
      cellKey: 'planName',
      cellCategory: Common.CELL_CATEGORY.TEXT,
    },
    {
      headerKey: 'D302V0009',
      cellKey: 'userCount',
      cellCategory: Common.CELL_CATEGORY.TEXT,
    },
    {
      headerKey: 'D302V0010',
      cellKey: 'conStatus',
      cellCategory: Common.CELL_CATEGORY.STATUS,
    },
  ],

  /** @type {TableInfo[]} テーブル作成に必要な値 */
  CARRIER_TABLE_ARR: [
    {
      headerKey: 'D302V0003',
      cellKey: 'companyName',
      cellCategory: Common.CELL_CATEGORY.TEXT,
    },
    {
      headerKey: 'D302V0033',
      cellKey: 'representative',
      cellCategory: Common.CELL_CATEGORY.TEXT,
    },
    {
      headerKey: 'D302V0007',
      cellKey: 'tel',
      cellCategory: Common.CELL_CATEGORY.TEXT,
    },
    {
      headerKey: 'D302V0008',
      cellKey: 'mail',
      cellCategory: Common.CELL_CATEGORY.TEXT,
    },
    {
      headerKey: '5th0003',
      cellKey: 'planName',
      cellCategory: Common.CELL_CATEGORY.TEXT,
    },
    {
      headerKey: 'D302V0009',
      cellKey: 'userCount',
      cellCategory: Common.CELL_CATEGORY.TEXT,
    },
    {
      headerKey: 'D302V0010',
      cellKey: 'conStatus',
      cellCategory: Common.CELL_CATEGORY.STATUS,
    },
  ],

  /** @type {SortInfo[]} 並び替えの値 */
  SORT_ITEM_ARR: [
    {
      sortTerm: Common.SORT_TERMS.CREATED_AT,
      wordKey: 'D005V0003',
      sortKey: 'createdAt',
    },
    {
      sortTerm: Common.SORT_TERMS.CREATED_AT_DESENDING,
      wordKey: 'D102V0054',
      sortKey: 'createdAt',
    },
    {
      sortTerm: Common.SORT_TERMS.COMPANYNAME_ORDER,
      wordKey: 'D301V0002',
      sortKey: 'companyName',
    },
    {
      sortTerm: Common.SORT_TERMS.PIC_ASCENDING,
      wordKey: 'D302V0033',
      sortKey: 'representative',
    },
    {
      sortTerm: Common.SORT_TERMS.TEL_ASCENDING,
      wordKey: 'D302V0007',
      sortKey: 'tel',
    },
    {
      sortTerm: Common.SORT_TERMS.EMAIL_ASCENDING,
      wordKey: 'D302V0008',
      sortKey: 'mail',
    },
    {
      sortTerm: Common.SORT_TERMS.PLAN_ORDER,
      wordKey: '5th0003',
      sortKey: 'fee',
    },
    {
      sortTerm: Common.SORT_TERMS.TOTAL_USER,
      wordKey: 'D302V0009',
      sortKey: 'userCount',
    },
    {
      sortTerm: Common.SORT_TERMS.CLIENT_LIST_STATUS,
      wordKey: '4th0023',
      sortKey: 'status',
    },
  ],
}

export default ConstantsLightJhClientList
export { TabName }
