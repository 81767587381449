import React from 'react'
import { Grid } from '@material-ui/core'

import FinishDatePickerBidInfo from './FinishDatePickerBidInfo'
import StartDatepickerBidInfo from './StartDatepickerBidInfo'

/**
 * @return {JSX} ValidityのDatepickerコンポーネント
 */
const DatePickerValidityBidInfo = () => {
  return (
    <Grid container item md={6} xs={6}>
      <Grid item md={5} xs={5}>
        <StartDatepickerBidInfo />
      </Grid>
      <Grid item container alignItems="center" justify="center" md={1} xs={1}>
        <Grid item>-</Grid>
      </Grid>
      <Grid item md={5} xs={5}>
        <FinishDatePickerBidInfo />
      </Grid>
    </Grid>
  )
}

export default DatePickerValidityBidInfo
