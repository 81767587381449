import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import AccordionSummary from '../../../common/accordion/AccordionSummary'

const useStyles = makeStyles((Theme) => ({
  label: {
    ...Theme.textBoldBLU,
    fontSize: Theme.fontSize.cardButtonSm,
  },
}))

/**
 * @param {Object} props 下記内容
 * @param {String} props.label 表示文言
 * @return {JSX} アコーディオンタイトル部分
 */
const AccordionSummaryLightBookingDetails = ({ label }) => {
  const classes = useStyles()
  return <AccordionSummary className={classes.label}>{label}</AccordionSummary>
}

AccordionSummaryLightBookingDetails.propTypes = {
  label: PropTypes.string.isRequired,
}

export default AccordionSummaryLightBookingDetails
