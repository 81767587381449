import React, { useReducer } from 'react'
import PropTypes from 'prop-types'

import LayoutBox from '../../../common/layout/LayoutBox'
import TitleBidInfo from './00TitleBidInfo/TitleBidInfo'
import TableBidInfo from './01TableBidInfo/TableBidInfo'
import ButtonsBidInfo from './02ButtonsBidInfo/ButtonsBidInfo'
import AppContext from '../../../../contexts/AppContext'
import reducer, { initialState } from '../../../../reducers/fwdr/fwdrBidInfo'

/**
 * @param {Object} props - 以下
 * @param {Object} history 前ページから受け取った情報
 * @return {JSX} 混載業者BidInfo画面コンポーネント
 */
const FwdrBidInfo = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const pageDataObj = props.history.location.state?.state

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <LayoutBox>
        <TitleBidInfo />
        <TableBidInfo bidInfo={props} />
        <ButtonsBidInfo pageDataObj={pageDataObj} />
      </LayoutBox>
    </AppContext.Provider>
  )
}

FwdrBidInfo.propTypes = {
  history: PropTypes.object.isRequired,
}

export default FwdrBidInfo
