import { combineReducers } from 'redux'

import TablesConfirmation, {
  initTablesConfirmation,
} from './TablesConfirmation'
import IsNew, { initIsNew } from './IsNew'
import Paging, { initPaging } from '../../common/Paging'
import Data, { initData } from './Data'
import Checkbox, { initCheckbox } from './Checkbox'

const initialState = {
  Tables: initTablesConfirmation,
  IsNew: initIsNew,
  Paging: initPaging,
  Data: initData,
  Checkbox: initCheckbox,
}

export default combineReducers({
  Tables: TablesConfirmation,
  IsNew,
  Paging,
  Data,
  Checkbox,
})

export { initialState }
