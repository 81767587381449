/* eslint-disable prefer-template */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-extra-parens */
import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Autocomplete from '@material-ui/lab/Autocomplete'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import PrimaryButton from '../../../common/buttons/PrimaryButton'
import SecondaryButton from '../../../common/buttons/SecondaryButton'
import AddButton from '../../../common/buttons/AddButton'
import Dialog from '../../../common/dialog/Dialog'
import TextField from '../../../common/text/TextField'
import Theme from '../../../common/theme/Theme'
import AppContext from '../../../../contexts/AppContext'
import { SEARCH_SHIP_TABLE, ADD_SHIP_TABLE } from '../../../../actions'

const useStyles = makeStyles((theme) => ({
  // eslint-disable-next-line camelcase
  selectbox_pc: {
    '& .MuiInputBase-root': {
      padding: '6px 0px 7px 16px',
      width: 400,
    },
  },
  // eslint-disable-next-line camelcase
  selectbox_mobile: {
    '& .MuiInputBase-root': {
      padding: '6px 0px 7px 16px',
      width: 230,
    },
  },
  head: theme.textNormalBLU,
}))

/**
 * @param {Object} props - 以下
 * @param {array} option セレクトボックスに表示するデータ
 * @returns {*} - AddDialog
 */
const AddDialog = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = Theme
  const matches = useMediaQuery(theme.breakpoints.down('sm'))
  const [selData, setSelData] = useState({})
  const { state, dispatch } = useContext(AppContext)

  // OKボタン処理
  const updateTable = () => {
    if (selData !== null && Object.keys(selData).length) {
      // 新規追加するものがeditData内にあるか判定
      const addData = state.Tables.editData.filter((edit) => {
        return edit.shipperJhId === selData.shipperJhId
      })
      // あった場合（1度追加後、削除して再度追加の場合）delFlgを削除
      if (addData.length) {
        addData[0].delFlg = 0
      }
      selData.consignee = selData.shipperJhId
      dispatch({
        type: ADD_SHIP_TABLE,
        row: selData,
      })
      dispatch({
        type: SEARCH_SHIP_TABLE,
        data: state.Tables.editData,
        amount: state.Tables.contentNum,
      })
    }
  }

  // セレクトボックス変更時
  const chgSelectBox = (_e, value) => {
    setSelData(value)
  }

  // ダイアログ表示時 初期化処理
  const initialize = () => {
    setSelData({})
  }

  // セレクトボックス表示データ作成
  const getOptionLabel = (option) => {
    // eslint-disable-next-line prefer-template
    return (
      option.companyName +
      '\n' +
      option.address +
      ' ' +
      option.postalCode +
      ', tel. ' +
      option.tel +
      ' \nAttn: ' +
      option.firstName +
      ' ' +
      option.lastName
    )
  }

  const getOptionDisabled = (option) => {
    for (let loop = 0; loop < state.Tables.editData.length; loop++) {
      if (
        state.Tables.editData[loop].companyName === option.companyName &&
        state.Tables.editData[loop].address === option.address &&
        state.Tables.editData[loop].tel === option.tel &&
        state.Tables.editData[loop].delFlg !== 1
      ) {
        // 既にテーブルに追加されている場合、セレクトボックスの該当行を非活性にする
        return true
      }
    }
    return false
  }

  /** 件数100件超えるか */
  const isOverHundredInfos =
    state.Tables.editData.filter((v) => !v.delFlg).length >= 100

  return (
    <Dialog
      buttonPC={
        <AddButton onClick={initialize} disabled={isOverHundredInfos} />
      }
      buttonMobile={
        <AddButton onClick={initialize} disabled={isOverHundredInfos} />
      }
      primary={
        <PrimaryButton onClick={updateTable}>{t('D004V0062')}</PrimaryButton>
      }
      secondary={<SecondaryButton>{t('D004V0063')}</SecondaryButton>}
      buttonDisabled={isOverHundredInfos}
      noborder
    >
      <div className={classes.formControl}>
        <Grid container>
          <div className={classes.head}>{props.label}</div>
        </Grid>
        <Grid container item md={12} xs={12}>
          {/* MultiLine対応のため、common/InputSelectは使用しない */}
          <Autocomplete
            className={
              (matches ? classes.selectbox_mobile : classes.selectbox_pc) +
              ' ' +
              props.className
            }
            id={props.id}
            options={props.option}
            getOptionLabel={getOptionLabel}
            getOptionDisabled={getOptionDisabled}
            onChange={chgSelectBox}
            disabled={props.disabled === true ? true : false}
            multiple={props.multiple === true ? true : false}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={props.placeholder}
                multiline={props.multiline === true ? true : false}
              />
            )}
          />
        </Grid>
      </div>
    </Dialog>
  )
}

export default AddDialog
