import { UPDATE_QUESTION2, INITIALIZE_QUESTION2_TEXT } from '../../../actions'
import ConstantsLightDeleteAccount from '../../../components/pages/authentication/LightDeleteAccount/ConstantsLightDeleteAccount'
import CommonValidation from '../../../components/common/function/CommonValidation'

/** @type {Number} 定数で設定しているQ2の質問の個数 */
const checkCount = ConstantsLightDeleteAccount.QUESTIONS_Q2_ARR.length

const initQ2 = {
  check: Array(checkCount).fill(0),
  text: '',
  texts: ['D501V0111', 'D501V0112'],
  choices: ['D501V0113', 'D501V0114', 'D501V0115', 'D501V0110'],
  errors: false,
  helpers: '',
}

const QuestionData = (state, action) => {
  if (action.index !== null) {
    //チェックボックスが変更された時
    const check = [...state.check]
    check[action.index] = action.answer
    if (Number(action.index) === state.choices.length - 1) {
      const errors = false
      const helpers = ''
      const text = ''
      return { ...state, check, text, errors, helpers }
    } else {
      return { ...state, check }
    }
  } else {
    //テキストが変更された時
    const check = CommonValidation.withdrawalReason(action.answer)
    const errors = check.checkResult
    const helpers = check.checkResult ? check.errMsg : ''
    const text = action.answer
    return { ...state, text, errors, helpers }
  }
}

const QuestionTextData = (state) => {
  const errors = false
  const helpers = ''
  const text = ''
  const checks = [...state.check]
  const check = checks.map((data) => {
    if (data === true) {
      return false
    } else {
      return data
    }
  })
  return { ...state, check, text, errors, helpers }
}

/**
 * state: Q2の回答を保持
 * @param {Object} [state={}] -
 * @param {Object} action -
 * @returns {*} -
 */
const Q2 = (state = initQ2, action) => {
  switch (action.type) {
    case UPDATE_QUESTION2:
      return QuestionData(state, action)
    case INITIALIZE_QUESTION2_TEXT:
      return QuestionTextData(state)
    default:
      return state
  }
}

export default Q2

export { initQ2 }
