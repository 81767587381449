import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import SaveDialog from '../../../../common/dialog/SaveDialog'
import Paths from '../../../../../constants/Paths'
import AppContext from '../../../../../contexts/AppContext'
import CommonFunc from '../../../../common/function/CommonFunc'
import ApiPaths from '../../../../../constants/ApiPaths'
import Common from '../../../../../constants/Common'
import SaveCompleteDialog from '../../../../common/dialog/SaveCompleteDialog'

/**
 * @return {JSX} Okボタンコンポーネント
 */
const OkButtonConfirmation = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const { state } = useContext(AppContext)
  const [errCode, setErrCode] = useState(0)
  const [isErr, setIsErr] = useState(false)
  const [comp, setComp] = useState(false)

  /** 非活性状態 */
  const disabled = !state.Checkbox

  /**
   * bidDetailのリクエストデータを作成する
   * @returns {Array} リクエストデータ
   */
  const createBidDetailArr = () =>
    state.Data.bidDetailArr.map((data) => {
      const {
        bidDetailsId,
        orgId,
        dstId,
        flightTypeId,
        commId,
        termsId,
        leadTimeId,
        ttlMinWgt,
        ttlMaxWgt,
        ttlMinVol,
        ttlMaxVol,
        currencyId,
        remark,
        delFlg,
        updatedAt,
        laneInfoArr,
      } = data
      let reqObj = {}
      if (delFlg === Common.DEL_FLG.ON) {
        // 削除時は異常値が含まれる可能性があるため削除に必要なデータのみ渡す
        reqObj = {
          bidDetailsId,
          delFlg,
          updatedAt,
          laneInfoArr: laneInfoArr ?? [],
          bidStatusId: Common.BID_STATUS.REQUEST.ID,
        }
      } else {
        reqObj = {
          bidDetailsId,
          orgId,
          dstId,
          flightTypeId,
          commId,
          termsId,
          leadTimeId,
          ttlMinWgt,
          ttlMaxWgt,
          ttlMinVol,
          ttlMaxVol,
          currencyId,
          remark,
          delFlg,
          updatedAt,
          laneInfoArr: laneInfoArr ?? [],
          bidStatusId: Common.BID_STATUS.REQUEST.ID,
        }
      }
      return reqObj
    })

  /**
   * フォワーダー選定時のリクエストデータを作成する
   * @returns {Array} リクエストデータ
   */
  const createLaneBidInfoArr = () => {
    /** 更新データ 変更があるデータのみ */
    const dataArr = state.Data.bidDetailArr.filter(
      ({ statusId, statusStr }) => {
        // statusIdがUpdate, Requestかつ、表示ステータスがClose, Lostのデータ
        const isUpdate = statusId === Common.BID_STATUS.UPDATE.ID
        const isRequest = statusId === Common.BID_STATUS.REQUEST.ID
        const isClose = statusStr === Common.BID_STATUS.CLOSE.TEXT
        const isLost = statusStr === Common.BID_STATUS.LOST.TEXT
        return (isUpdate || isRequest) && (isClose || isLost)
      }
    )

    return dataArr.map((data) => {
      const {
        bidLaneId,
        laneUpdateStatusId,
        bidLaneUpdatedAt,
        laneUpdateStatusUpdatedAt,
        statusStr,
        forwarderDraftFlg,
        laneId,
        laneUpdatedAt,
      } = data
      const bidStatusId =
        statusStr === Common.BID_STATUS.CLOSE.TEXT
          ? Common.BID_STATUS.CLOSE.ID
          : Common.BID_STATUS.LOST.ID
      return {
        bidLaneId,
        laneUpdateStatusId,
        bidLaneUpdatedAt,
        laneUpdateStatusUpdatedAt,
        bidStatusId,
        forwarderDraftFlg,
        laneId,
        laneUpdatedAt,
      }
    })
  }

  /**
   * bidInfoのリクエストデータを作成する
   * @returns {Object} リクエストデータ
   */
  const createBidInfoObj = () => {
    return {
      ...state.Data.bidInfoObj,
      shipperDraftFlg: Common.DRAFT_FLG.OFF,
      setFwdrFlg: state.Data.selectFwdrObj.setFwdrFlg,
    }
  }

  /**
   * selectForwarderのリクエストデータを作成する
   * @returns {Object} リクエストデータ
   */
  const createSelectFwdrObj = () => {
    const selectFwdrArr = state.Data.selectFwdrObj.selectFwdrArr
    const setFwdrFlg = state.Data.selectFwdrObj.setFwdrFlg
    return { selectFwdrArr, setFwdrFlg }
  }

  /**
   * クリック時のイベント
   * @returns {void} なし
   */
  const onClick = async () => {
    const req = {
      bidDetailArr: state.IsNew ? createBidDetailArr() : createLaneBidInfoArr(),
      bidInfoObj: state.IsNew ? createBidInfoObj() : {},
      selectFwdrObj: state.IsNew ? createSelectFwdrObj() : {},
      isNew: state.IsNew,
    }
    const apiInfoArr = [
      {
        key: 'resData',
        method: 'post',
        path: ApiPaths.SHIPPER.CONFIRMATION,
        req,
      },
    ]
    const resObj = await CommonFunc.execApiAsync(apiInfoArr)
    const isApiSuccessful = CommonFunc.checkApiResponseObj(resObj, history)

    // 使用しない共通ダイアログが表示されるので非表示に変更
    document.getElementById('error-dialog').hidden = true

    setErrCode(resObj.resData.data.info.ErrorCode)
    setIsErr(!isApiSuccessful)
    if (isApiSuccessful) {
      setComp(true)
    }
  }

  const saveFactors = {
    errcode: errCode,
    comp,
    compevent: () => setComp(false),
    passerr: null,
    path: Paths.SHIPPER.BID_MGMT,
    saveevent: onClick,
    text: t('D004V0062'),
    disabled,
  }

  return (
    <div>
      <SaveDialog saveFactors={saveFactors} />
      <SaveCompleteDialog
        savecomp={isErr.toString()}
        path={Paths.SHIPPER.BID_MGMT}
        errcode={errCode}
      />
    </div>
  )
}

export default OkButtonConfirmation
