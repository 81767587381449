/* eslint-disable no-unneeded-ternary */
import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'

import CheckBox from '../../../common/checkBox/CheckBox'
import AppContext from '../../../../contexts/AppContext'
import { UPDATE_SHIP_MAIL_SETTING } from '../../../../actions'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '1rem 0',
  },
  head: theme.textBoldBLU,
}))

const MailSetting = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { state, dispatch } = useContext(AppContext)

  const handleChange = (event) => {
    dispatch({
      type: UPDATE_SHIP_MAIL_SETTING,
      mailSetting: event.target.checked ? 1 : 0,
    })
  }

  return (
    <div className={classes.formControl}>
      <Grid container>
        <Grid item md={10} xs={10}>
          <div className={classes.head}>{t('D301V0031')}</div>
        </Grid>
      </Grid>
      <Grid container className={classes.root}>
        <Grid item>
          <CheckBox
            label={t('D301V0032')}
            checked={state.myprofile.mailSetting.value ? true : false}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default MailSetting
