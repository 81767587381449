import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import CommonDialog from '../commonDialog/CommonDialog'
import WarningRoundedIcon from '../../icon/WarningRoundedIcon'

const useStyles = makeStyles((theme) => ({
  text: theme.saveText,
}))

/**
 * 保存完了ダイアログ
 * @param {Any} props CommonDialogと同じ以下を設定
 * @param {Boolean} open ダイアログの開閉状態
 * @param {Function} closeFunc ダイアログ外をクリックした時のcloseイベント
 * @param {Object} dialogFuncObj ダイアログのボタン設定
 * @return {JSX} 保存完了ダイアログコンポーネント
 */
const FileDuplicateDialog = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <CommonDialog {...props}>
      {/* 保存完了文章 */}
      <Grid container>
        {/* 警告アイコン */}
        <Grid container item xs={12} justify="center">
          <WarningRoundedIcon />
        </Grid>
        <Grid container item xs={12} justify="center" className={classes.text}>
          <div>{t('2nd0043')}</div>
          <div>{t('2nd0044')}</div>
        </Grid>
      </Grid>
    </CommonDialog>
  )
}

FileDuplicateDialog.propTypes = {
  // 以下CommonDialogと同様の設定
  open: PropTypes.bool.isRequired,
  closeFunc: PropTypes.func.isRequired,
  dialogFuncObj: PropTypes.objectOf(PropTypes.object).isRequired,
}

export default FileDuplicateDialog
