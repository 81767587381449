import { TABLES, LIGHT_FLIGHT_MGMT } from '../../../actions'
import CommonFunc from '../../../components/common/function/CommonFunc'
import ConstantsCarFlightMgmt, {
  TabName,
} from '../../../components/pages/master/LightCarFlightMgmt/ConstantsCarFlightMgmt'
import { getTotalTransitTime } from '../../common/TransitTime'
import { getRoutingAirportCode } from '../../common/Airport'
import { getCarrierNameInfo } from '../../common/CarrierName'
import CommonCalc from '../../../components/common/function/CommonCalc'
import Common from '../../../constants/Common'

/** @type {Object} 初期表示のテーブル */
const initTableData = {
  initData: [],
  iconInitData: [],
  showData: [[]],
  editData: [],
  iconShowData: [],
  flightInfoShowData: [],
  tab: TabName.flightInfo,
}

/**
 * FlightInfoテーブルに表示する形式に変更
 * @param {Array} tableData - apiから受け取ったテーブルデータ
 * @return {Array} - テーブルに表示する形式のデータ
 */
const editFlightInfoData = (tableData) =>
  tableData.map((data, initDataIndex) => {
    const { routingArr, effectivedateEnd, effectivedateStart } = data

    /** @type {Number[]} 空港IDの配列 */
    const airportIdArr = routingArr
      .map(({ orgId }) => orgId)
      .concat(routingArr[routingArr.length - 1].dstId)
    /** @type {String} Routing欄に表示する文字列 */
    const displayRouting = getRoutingAirportCode(airportIdArr)

    /** @type {Number[]} transitTimeIdの配列 */
    const transitTimeIdArr = routingArr.map(({ timeId }) => timeId)
    /** @type {String} T/T欄に表示する文字列*/
    const displayTransitTime = getTotalTransitTime(transitTimeIdArr).time

    /** @type {Object[]} 表示用に整形したRoutingArr */
    const fixedRoutingArr = routingArr.map((routing) => {
      // FlightTimeをXX:XXの形に整形
      const [flightTimeHours, flightTimeMinutes] = routing.flightTime.split(':')
      routing.flightTime = `${CommonCalc.zeroPadding(flightTimeHours, 2)}:${CommonCalc.zeroPadding(flightTimeMinutes, 2)}`
      // EtdとEtaをXX:XXの形に整形
      routing.flightScheduleArr = routing.flightScheduleArr.map(
        (flightSchedule) => {
          const [etdHours, etdMinutes] = flightSchedule.etd.split(':')
          const [etaHours, etaMinutes] = flightSchedule.eta.split(':')
          flightSchedule.etd = `${CommonCalc.zeroPadding(etdHours, 2)}:${CommonCalc.zeroPadding(etdMinutes, 2)}`
          flightSchedule.eta = `${CommonCalc.zeroPadding(etaHours, 2)}:${CommonCalc.zeroPadding(etaMinutes, 2)}`
          return flightSchedule
        }
      )
      return routing
    })

    return {
      ...data,
      flightNumber: routingArr
        .map(({ flightNumber }) => flightNumber)
        .join(' / '),
      effectiveDate: `${effectivedateStart} - ${effectivedateEnd}`,
      effectivedateStart,
      effectivedateEnd,
      routingArr: fixedRoutingArr,
      displayRouting,
      displayTransitTime,
      initDataIndex,
    }
  })

/**
 * Iconテーブルに表示する形式に変更
 * @param {Array} tableData - apiから受け取ったテーブルデータ
 * @return {Array} - テーブルに表示する形式のデータ
 */
const editIconData = (tableData) => {
  /** @type {Number[]} 航空会社名IDの配列 */
  const carrierNameIdArr = tableData
    .map(({ iconObj }) => iconObj?.carrierNameId)
    .filter((v) => v)
  /** @type {Number[]} 重複を取り除いた航空会社名の配列 */
  const uniqueCarrierNameIdArr = Array.from(new Set(carrierNameIdArr))

  return uniqueCarrierNameIdArr.map((carrierNameId) => {
    /** @type {Object} アイコン情報 */
    const iconObj = tableData.find(
      ({ iconObj }) => iconObj?.carrierNameId === carrierNameId
    ).iconObj
    const { carriertwoCode, companyName } =
      getCarrierNameInfo(carrierNameId) ?? {}
    return {
      ...iconObj,
      carriertwoCode,
      companyName,
      airline: `${companyName} (${carriertwoCode})`,
    }
  })
}

/**
 * リクエストデータを作成して返す
 * @param {Object[]} initData state.initDataの値
 * @returns {Object[]} リクエストデータ
 */
const getRequestData = (initData) =>
  initData
    .filter(({ isEditedData }) => isEditedData)
    .map((flightInfo) => {
      // 不要なデータを分離
      // eslint-disable-next-line
      const {
        effectiveDate,
        flightNumber,
        id,
        isEditedData,
        routing,
        initDataIndex,
        ...rest
      } = flightInfo

      // ETD/ETAをDate型に変換
      const effectivedateStart = new Date(flightInfo.effectivedateStart)
      const effectivedateEnd = new Date(flightInfo.effectivedateEnd)

      return { ...rest, effectivedateStart, effectivedateEnd }
    })

/**
 * テーブルデータを保持
 * @param {Object} action action
 * @param {Object[]} action.tableData 登録するテーブルデータ
 * @param {String} action.search 検索ワード
 * @param {Object} state state
 * @returns {Object} テーブルデータ
 */
const setTableData = (action, state) => {
  const { tableData, search = '' } = action
  /** @type {Number} 1ページに表示する件数 */
  const pageAmount = ConstantsCarFlightMgmt.PAGE_AMOUNT
  /** @type {String} FlightInfoの並び順 */
  const flightInfoSortTerms = Common.SORT_TERMS.FLIGHT_NAME_ORDER
  /** @type {String} Iconの並び順 */
  const iconSortTerms = Common.SORT_TERMS.COMPANYNAME_ASCENDING
  /** @type {String} FlightInfoの並び替え参照データ名 */
  const flightInfoSortDataName = 'flightNumber'
  /** @type {String} Iconの並び替え参照データ名 */
  const iconSortDataName = 'companyName'

  /** @type {Array} 表示用に編集後のFlightInfoデータ */
  const editedFlightInfoData = tableData
    ? editFlightInfoData(tableData)
    : state.initData
  // FlightInfoデータを検索して結果をページごとに分割する
  const { showData: flightInfoShowData, initData } = CommonFunc.searchSortData(
    editedFlightInfoData,
    search,
    flightInfoSortTerms,
    flightInfoSortDataName,
    pageAmount
  )

  /** @type {Array} 表示用に編集後のIconデータ */
  const editedIconData = tableData
    ? editIconData(tableData)
    : state.iconInitData

  // Iconデータを検索して結果をページごとに分割する
  const { showData: iconShowData, initData: iconInitData } =
    CommonFunc.searchSortData(
      editedIconData,
      search,
      iconSortTerms,
      iconSortDataName,
      pageAmount
    )

  // タブから表示データを判定
  const showData =
    state.tab === TabName.flightInfo ? flightInfoShowData : iconShowData
  /** @type {Boolean} NoResult判定 */
  const isNoResult = showData[0].length <= 0

  return {
    ...state,
    showData,
    iconShowData,
    flightInfoShowData,
    initData,
    iconInitData,
    isNoResult,
    getRequestData: () => getRequestData(initData),
  }
}

/**
 * テーブルデータを編集
 * @param {Object} action action
 * @param {Object} action.data ポップアップで編集後のデータ
 * @param {String} action.defaultTwoCode ログインユーザの航空会社2桁コード
 * @param {Object} state state
 * @returns {Object} 編集後のテーブルデータ
 */
const editTableData = (action, state) => {
  const { data, defaultTwoCode, add, page } = action
  // effectiveDateを修正
  if (add) {
    const dateStart = data.effectivedateStart
    const dateEnd = data.effectivedateEnd
    const startMonth = CommonCalc.zeroPadding(dateStart.getMonth() + 1, 2)
    const endMonth = CommonCalc.zeroPadding(dateEnd.getMonth() + 1, 2)
    const startDate = CommonCalc.zeroPadding(dateStart.getDate(), 2)
    const endDate = CommonCalc.zeroPadding(dateEnd.getDate(), 2)
    data.effectivedateStart = `${dateStart.getFullYear()}/${startMonth}/${startDate}`
    data.effectivedateEnd = `${dateEnd.getFullYear()}/${endMonth}/${endDate}`
  }

  // アイコンが既に登録されている航空会社の場合はデータに反映
  if (data.iconObj) {
    data.iconObj =
      state.iconInitData.find(
        (v) => v.carrierNameId === data.iconObj.carrierNameId
      ) ?? data.iconObj
  }

  /** @type {String} 航空会社の2桁コード */
  const twoCode =
    getCarrierNameInfo(data.iconObj?.carrierNameId)?.carriertwoCode ??
    defaultTwoCode

  data.routingArr.map(({ flightNumber }, index) => {
    // flightNumberの先頭に2桁コードを追加
    data.routingArr[index].flightNumber = `${twoCode}${flightNumber}`
    // routing2以降のorgIdを設定
    if (index > 0) {
      data.routingArr[index].orgId = data.routingArr[index - 1].dstId
    }
    // 未入力かつDB未登録のflightScheduleを削除
    data.routingArr[index].flightScheduleArr = data.routingArr[
      index
    ].flightScheduleArr.filter((v) => {
      const isNotRegistered = !v.flightScheduleId
      const isNotInput = v.etd === ''
      return !(isNotRegistered && isNotInput)
    })
    return null
  })

  // 編集フラグを立てる
  data.isEditedData = true

  // initDataIndexを控えておく
  const initDataIndex = data.initDataIndex

  /** @type{Object[]} 表示用データに変換したデータ */
  const editedData = editFlightInfoData([data])

  // initDataとshowDataにデータを反映する
  if (add) {
    // 新規追加データの場合
    editedData[0].initDataIndex = state.initData.length
    state.initData.push(editedData[0])
    if (state.showData.at(-1).length < ConstantsCarFlightMgmt.PAGE_AMOUNT) {
      state.showData.at(-1).push(editedData[0])
    } else {
      state.showData.push([editedData[0]])
    }
  } else {
    // 編集データの場合
    editedData[0].initDataIndex = initDataIndex
    state.initData[initDataIndex] = editedData[0]
    const index = state.showData[page].findIndex(
      (data) => data.initDataIndex === initDataIndex
    )
    state.showData[page][index] = editedData[0]
  }

  /** @type {Boolean} NoResult判定 */
  const isNoResult = state.showData[0].length <= 0

  return {
    ...state,
    initData: [...state.initData],
    showData: [...state.showData],
    flightInfoShowData: [...state.showData],
    isNoResult,
    getRequestData: () => getRequestData([...state.initData]),
  }
}

/**
 * アイコンテーブルを更新する
 * @param {Object} action action
 * @param {String} action.search 検索ワード
 * @param {Object} action.data 更新データ
 * @param {Object} state state
 * @returns {Object} state
 */
const editIconTableData = (action, state) => {
  const { search, data } = action
  const editedIconData = state.iconInitData

  /** @type {Number} 編集対象のインデックス */
  const index = editedIconData.findIndex(
    (v) => v.contractId === data.contractId
  )

  /** @type {Number} 1ページに表示する件数 */
  const pageAmount = ConstantsCarFlightMgmt.PAGE_AMOUNT
  /** @type {String} 並び順 */
  const sortTerms = Common.SORT_TERMS.COMPANYNAME_ASCENDING
  /** @type {String} 並び替え参照データ名 */
  const sortDataName = 'companyName'

  // 編集する
  Object.keys(data).map((key) => (editedIconData[index][key] = data[key]))

  // Iconデータを検索して結果をページごとに分割する
  const { showData: iconShowData, initData: iconInitData } =
    CommonFunc.searchSortData(
      editedIconData,
      search,
      sortTerms,
      sortDataName,
      pageAmount
    )

  return { ...state, showData: iconShowData, iconShowData, iconInitData }
}

/**
 * ShowDataを切り替える
 * @param {Object} action action
 * @param {TabName} action.tab タブの値
 * @param {Object} state state
 * @returns {Object} state
 */
const changeShowData = (action, state) => {
  const { tab } = action
  const { flightInfoShowData, iconShowData } = state
  const showData =
    tab === TabName.flightInfo ? flightInfoShowData : iconShowData
  /** @type {Boolean} NoResult判定 */
  const isNoResult = showData[0].length <= 0
  return {
    ...state,
    showData,
    isNoResult,
    tab,
  }
}

/**
 * テーブルデータを保持
 * @param { Object } [state = initTableData] テーブルデータ
 * @param { Object } action action
 * @return { Object } テーブルデータ
 */
const Tables = (state = initTableData, action) => {
  switch (action.type) {
    case TABLES.SET:
      return setTableData(action, state)
    case TABLES.EDIT:
      return editTableData(action, state)
    case LIGHT_FLIGHT_MGMT.TABLES.CHANGE_SHOW_DATA:
      return changeShowData(action, state)
    case LIGHT_FLIGHT_MGMT.ICON_TABLE.EDIT_ICON_TABLES:
      return editIconTableData(action, state)
    default:
      return state
  }
}

export default Tables

export { initTableData }
