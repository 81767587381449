/* eslint-disable prefer-template */
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import IconButton from '@material-ui/core/IconButton'
import PersonIcon from '@material-ui/icons/Person'
import Popover from '@material-ui/core/Popover'
import ButtonBase from '@material-ui/core/ButtonBase'
import Divider from '@material-ui/core/Divider'
import SettingsIcon from '@material-ui/icons/Settings'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { useHistory } from 'react-router-dom'

import CommonFunc from '../../common/function/CommonFunc'
import SignOutDialog from '../../common/dialog/SignOutDialog'
import ApiPaths from '../../../constants/ApiPaths'
import Common from '../../../constants/Common'
import RegisterAgainDialog from '../dialog/RegisterAgainDialog'
import Paths from '../../../constants/Paths'
import restFacade from '../../../actions/rest-facade'

const useStyles = makeStyles((theme) => ({
  popover: {
    marginTop: '20px',
    position: 'relative',
    '& .MuiPaper-root': {
      borderRadius: '15px',
      border: '1px solid',
      borderColor: 'rgba(89, 89, 89, 0.5)',
      backgroundColor: theme.palette.info.main,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  bold: {
    color: theme.fontColor.main,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.fontSize.paging,
    fontWeight: 'bold',
    height: 'fit-content',
  },
  userData: {
    color: theme.fontColor.main,
    fontFamily: theme.typography.fontFamily,
    fontSize: '10px',
    height: 'fit-content',
  },
  text: {
    color: theme.fontColor.main,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.fontSize.regular,
    width: '100%',
    height: '60px',
  },
  iconButton: {
    '& .MuiSvgIcon-root': {
      fill: theme.palette.info.main,
      fontSize: theme.fontSize.cardButtonSm,
    },
  },
  icon: {
    marginRight: '0.5rem',
  },
  noicon: {
    marginRight: '1rem',
    color: 'rgba(0, 0, 0, 0)',
  },
  root: {
    width: '290px',
  },
  name: {
    width: '234px',
    height: 'fit-content',
    padding: '28px 1rem',
    marginLeft: '31px',
    overflowWrap: 'break-word',
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '1rem',
    padding: '1rem',
  },
}))

let setData = () => {}
let setLastNameData = () => {}
let setNameData = () => {}
let setCompanyNameData = () => {}
let setPositionData = () => {}

const Account = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const [companyName, setCompanyName] = useState('')
  const [position, setPosition] = useState('')
  const [name, setName] = useState('')
  const [lastName, setLastName] = useState('')
  const userType = CommonFunc.getLoginUserData().userType

  // エラー処理、コールバック関数実行
  setData = (res, callback) => {
    CommonFunc.callbackFunc(res, callback, history)
  }

  // 姓取得の処理
  setLastNameData = (res) => {
    setLastName(res.data.results.lastName)
  }

  // 名取得の処理
  setNameData = (res) => {
    setName(res.data.results.name)
  }

  // 会社名取得時の処理
  setCompanyNameData = (res) => {
    setCompanyName(res.data.results.companyName)
  }

  // 役職名取得の処理
  setPositionData = (res) => {
    setPosition(res.data.results.position)
  }

  useEffect(() => {
    switch (userType) {
      case Common.USER_TYPE.JH:
        restFacade.get(ApiPaths.MASTER_JH.MY_PROFILE, (res) =>
          setData(res, setLastNameData)
        )
        restFacade.get(ApiPaths.MASTER_JH.MY_PROFILE, (res) =>
          setData(res, setNameData)
        )
        restFacade.get(ApiPaths.MASTER_JH.COMPANY_INFO, (res) =>
          setData(res, setCompanyNameData)
        )
        restFacade.get(ApiPaths.MASTER_JH.MY_PROFILE, (res) =>
          setData(res, setPositionData)
        )
        break
      case Common.USER_TYPE.SHIPPER:
        restFacade.get(ApiPaths.MASTER_SHIPPER.MY_PROFILE, (res) =>
          setData(res, setLastNameData)
        )
        restFacade.get(ApiPaths.MASTER_SHIPPER.MY_PROFILE, (res) =>
          setData(res, setNameData)
        )
        restFacade.get(ApiPaths.MASTER_SHIPPER.SHIP_COMPANY_INFO, (res) =>
          setData(res, setCompanyNameData)
        )
        restFacade.get(ApiPaths.MASTER_SHIPPER.MY_PROFILE, (res) =>
          setData(res, setPositionData)
        )
        break
      case Common.USER_TYPE.FWDR:
        restFacade.get(ApiPaths.MASTER_FWDR.MY_PROFILE, (res) =>
          setData(res, setLastNameData)
        )
        restFacade.get(ApiPaths.MASTER_FWDR.MY_PROFILE, (res) =>
          setData(res, setNameData)
        )
        restFacade.get(ApiPaths.MASTER_FWDR.FWDR_COMPANY_INFO, (res) =>
          setData(res, setCompanyNameData)
        )
        restFacade.get(ApiPaths.MASTER_FWDR.MY_PROFILE, (res) =>
          setData(res, setPositionData)
        )
        break
      case Common.USER_TYPE.AIRLINE:
        restFacade.get(ApiPaths.MASTER_CAR.MY_PROFILE, (res) =>
          setData(res, setLastNameData)
        )
        restFacade.get(ApiPaths.MASTER_CAR.MY_PROFILE, (res) =>
          setData(res, setNameData)
        )
        restFacade.get(ApiPaths.MASTER_CARRIER.CAR_COMPANY_INFO, (res) =>
          setData(res, setCompanyNameData)
        )
        restFacade.get(ApiPaths.MASTER_CAR.MY_PROFILE, (res) =>
          setData(res, setPositionData)
        )
        break
      default:
        break
    }
  }, [userType])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const history = useHistory()
  const topTranslation = () => {
    handleClose()
    CommonFunc.masterTopTranslation(history)
  }

  let topPath = ''
  switch (userType) {
    case Common.USER_TYPE.SHIPPER:
      topPath = Paths.MASTER.SHIP_MASTER_TOP
      break
    case Common.USER_TYPE.FWDR:
      topPath = Paths.MASTER.FWDR_MASTER_TOP
      break
    case Common.USER_TYPE.AIRLINE:
      topPath = Paths.MASTER.CAR_MASTER_TOP
      break
    case Common.USER_TYPE.JH:
      topPath = Paths.MASTER.JH_MASTER_TOP
      break
    default:
      break
  }

  return (
    <>
      <IconButton className={classes.iconButton} onClick={handleClick}>
        <PersonIcon />
      </IconButton>
      <Popover
        className={classes.popover}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={`${classes.root} ${Common.CLASS_NAME.PAGE_CONTENTS}`}>
          <div className={classes.name}>
            <div className={classes.bold}>{name + ' ' + lastName}</div>
            {position ? (
              <div className={classes.userData}>
                {position + ' - ' + companyName}
              </div>
            ) : (
              <div className={classes.userData}>{companyName}</div>
            )}
          </div>
          <Divider />
          {CommonFunc.checkRegisterPage() ? (
            <RegisterAgainDialog
              path={topPath}
              buttonPC={
                <ButtonBase className={classes.text}>
                  <div className={classes.button}>
                    <SettingsIcon className={classes.icon} />
                    {t('D202V0014')}
                  </div>
                </ButtonBase>
              }
            />
          ) : (
            <ButtonBase className={classes.text} onClick={topTranslation}>
              <div className={classes.button}>
                <SettingsIcon className={classes.icon} />
                {t('D202V0014')}
              </div>
            </ButtonBase>
          )}
          <Divider />
          <SignOutDialog
            buttonPC={
              <ButtonBase className={classes.text}>
                <div className={classes.button}>
                  <ExitToAppIcon className={classes.icon} />
                  {t('D202V0016')}
                </div>
              </ButtonBase>
            }
          />
        </div>
      </Popover>
    </>
  )
}

export default Account
