import React from 'react'
import { useTranslation } from 'react-i18next'

import AirportSelect from '../../../../commonUseContext/Authentication/AirportSelect'

const HubsLightFwdrRegisterNewAccount = () => {
  const { t } = useTranslation()
  return <AirportSelect label={t('D501V0061')} />
}

export default HubsLightFwdrRegisterNewAccount
