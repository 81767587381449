import React, { useState, useEffect, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'

import LayoutBox from '../../../common/layout/LayoutBox'
import Title from '../../../common/title/Title'
import SearchAndCheck from '../../../common/searchForm/SearchAndCheck'
import SaveAndBack from '../../../common/buttons/SaveAndBack'
import Paging from '../../../common/buttons/Paging'
import Theme from '../../../common/theme/Theme'
import CommonFunc from '../../../common/function/CommonFunc'
import Paths from '../../../../constants/Paths'
import AccountInfoTable from './AccountInfoTable'
import TotalAndAdd from './TotalAndAdd'
import reducer from '../../../../reducers/master/masterShipAccountInfo'
import AppContext from '../../../../contexts/AppContext'
import ApiPaths from '../../../../constants/ApiPaths'
import {
  INIT_SHIP_TABLE,
  SEARCH_SHIP_TABLE,
  UPDATE_SHIP_SEARCH_AUTH,
  UPDATE_SHIP_SEARCH_WORD,
  UPDATE_SHIP_PAGING_DATA,
} from '../../../../actions'

import restFacade from '../../../../actions/rest-facade'
import Common from '../../../../constants/Common'

const useStyles = makeStyles(() => ({
  buttons: {
    justifyContent: 'center',
    marginBottom: 30,
    marginTop: 30,
  },
  down: {
    marginBottom: 30,
    margin: 'auto',
  },
  desktop: {
    [Theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobile: {
    [Theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}))

const MasterShipAccountInfo = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const [saveComp, setSaveComp] = useState(false)
  const [errCode, setErrCode] = useState(0)
  const [errEmails, setErrEmails] = useState('')
  const contentNum = 15 // 1ページに表示する行数

  const initialState = {
    Tables: {
      showData: [[]],
      editData: [],
      contentNum,
      apiDataArr: [],
    },
    Search: {
      word: '',
      authority: false,
    },
    Paging: { page: 0 },
  }
  const [state, dispatch] = useReducer(reducer, initialState)

  const setAccountInfoData = (res) => {
    const result = res.data.results

    dispatch({
      type: INIT_SHIP_TABLE,
      data: result,
      amount: contentNum,
    })
    // スクロールを先頭に戻す
    window.scrollTo(0, 0)
  }

  // エラー処理、コールバック関数実行
  const setData = (res, callback) => {
    CommonFunc.callbackFunc(res, callback, history)
  }

  useEffect(() => {
    // 各データ取得
    restFacade.get(ApiPaths.MASTER_SHIPPER.ACCOUNT_INFO, (res) =>
      setData(res, setAccountInfoData)
    )
  }, []) // eslint-disable-line

  // 検索実行(虫眼鏡 or Enterキー押下)時の動作
  const searchEvent = () => {
    dispatch({
      type: UPDATE_SHIP_PAGING_DATA,
      page: 0,
    })
    dispatch({
      type: SEARCH_SHIP_TABLE,
      word: state.Search.word,
      authority: state.Search.authority,
    })
  }

  // 検索テキストボックス入力変化時の動作
  const searchChgEvent = (e) => {
    const value = e.target.value
    dispatch({
      type: UPDATE_SHIP_SEARCH_WORD,
      word: value,
    })
  }

  // 検索リセット(Xボタン押下)時の動作
  const resetClick = () => {
    dispatch({
      type: UPDATE_SHIP_PAGING_DATA,
      page: 0,
    })
    dispatch({
      type: UPDATE_SHIP_SEARCH_WORD,
      word: '',
    })
    dispatch({
      type: SEARCH_SHIP_TABLE,
      word: '',
      authority: state.Search.authority,
    })
  }

  // Page切り替え時の動作
  const pageChange = (e, value) => {
    window.scrollTo(0, 0)
    const index = value - 1
    dispatch({
      type: UPDATE_SHIP_PAGING_DATA,
      page: index,
    })
    dispatch({
      type: SEARCH_SHIP_TABLE,
      word: state.Search.word,
      authority: state.Search.authority,
    })
  }

  // Authorityチェックボックス押下時の動作
  const authorityClick = (e) => {
    const check = e.target.checked
    dispatch({
      type: UPDATE_SHIP_PAGING_DATA,
      page: 0,
    })
    dispatch({
      type: UPDATE_SHIP_SEARCH_AUTH,
      authority: check,
    })
    dispatch({
      type: SEARCH_SHIP_TABLE,
      word: state.Search.word,
      authority: check,
    })
  }

  // データ変更ありなし判定
  const chkInputChg = () => {
    const edit = JSON.stringify(state.Tables.editData)
    const init = JSON.stringify(state.Tables.apiDataArr)

    if (edit !== init) {
      return true
    }
    return false
  }

  // 保存完了後の処理
  const saveComplete = () => {
    setSaveComp(false)
    if (errCode === 0) {
      // 保存に成功したらデータ再取得
      restFacade.get(ApiPaths.MASTER_SHIPPER.ACCOUNT_INFO, (res) =>
        setData(res, setAccountInfoData)
      )
    }
  }

  // 保存完了時のコールバック
  const saveDataCB = (response) => {
    setSaveComp(true)
    setErrCode(response.data.info.ErrorCode)
    setErrEmails(response.data.info.ErrorEmails)
    dispatch({
      type: UPDATE_SHIP_SEARCH_WORD,
      word: '',
    })
    dispatch({
      type: UPDATE_SHIP_SEARCH_AUTH,
      authority: Boolean(Common.AUTHORITY_FLG.OFF),
    })
  }

  // 保存処理
  const saveData = () => {
    const accountArray = state.Tables.editData
    const accountEditDatas = accountArray.filter((edit, index) => {
      if (!edit.userId) {
        return !edit.delFlg
      }
      return (
        JSON.stringify(edit) !== JSON.stringify(state.Tables.apiDataArr[index])
      )
    })
    const req = {
      userDatas: accountEditDatas,
    }
    restFacade.post(
      ApiPaths.MASTER_SHIPPER.ACCOUNT_INFO,
      (res) =>
        CommonFunc.callbackFunc(
          res,
          saveDataCB,
          history,
          res.data.info.ErrorEmails
        ),
      req,
      req.userDatas
    )
  }

  // SearchBoxコンポーネントに渡したいものをまとめる
  const searchFactors = {
    placeholder: t('D301V0162'),
    clickEvent: searchEvent,
    changeEvent: searchChgEvent,
    resetClick,
    value: state.Search.word,
  }

  // CheckBoxコンポーネントに渡したいものをまとめる
  const checkFactors = {
    label: t('D301V0034'),
    handleChange: authorityClick,
    checked: state.Search.authority,
  }

  // Pagingコンポーネントに渡したいものをまとめる
  const pagingFactors = {
    totalPage: state.Tables.showData.length,
    pageAmount: state.Tables.contentNum,
    changeEvent: pageChange,
    page: state.Paging.page + 1,
  }

  // Backコンポーネントに渡したいものをまとめる
  const backFactors = {
    path: Paths.MASTER.SHIP_MASTER_TOP,
    inputchg: chkInputChg(),
  }

  // Saveコンポーネントに渡したいものをまとめる
  const saveFactors = {
    path: Paths.MASTER.SHIP_ACCOUNT_INFO,
    saveevent: saveData,
    comp: saveComp,
    compevent: saveComplete,
    errcode: errCode,
    erremail: errEmails,
  }

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <LayoutBox>
        <Title>{t('D301V0033')}</Title>
        <Grid container>
          <SearchAndCheck
            searchfactors={searchFactors}
            checkfactors={checkFactors}
          />
        </Grid>
        <Grid
          container
          style={{ marginBottom: '3rem' }}
          justify="center"
          className={classes.table}
        >
          {/* PC */}
          <Grid item gmd={10} xs={10} className={classes.desktop}>
            <AccountInfoTable
              rows={state.Tables.showData}
              contentNum={contentNum}
            />
          </Grid>
          {/* 次期対応: (未対応. PC版と同じにしておく) */}
          <Grid item md={10} xs={10} className={classes.mobile}>
            <AccountInfoTable rows={state.Tables.showData} />
          </Grid>
        </Grid>
        <TotalAndAdd />
        <Paging pagingfactors={pagingFactors} />
        <Grid
          container
          justify="center"
          direction="column"
          alignItems="center"
          className={classes.down}
        >
          <Grid item md={10} xs={10} className={classes.buttons}>
            <SaveAndBack backFactors={backFactors} saveFactors={saveFactors} />
          </Grid>
        </Grid>
      </LayoutBox>
    </AppContext.Provider>
  )
}

export default MasterShipAccountInfo
