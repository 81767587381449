import React, { useContext, useState, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { Grid } from '@material-ui/core'

import AreYouSureDialog from '../../../../common/dialog/commonDesignDialog/AreYouSureDialog'
import SavedChangesDialog from '../../../../common/dialog/commonDesignDialog/SavedChangesDialog'
import BackButton from '../../../../common/buttons/BackButton'
import Paths from '../../../../../constants/Paths'
import Common from '../../../../../constants/Common'
import SaveButton from '../../../../common/buttons/SaveButton'
import CheckboxCarFlightMgmt from './CheckboxCarFlightMgmt'
import AppContext from '../../../../../contexts/AppContext'
import { CHECKBOX, PAGING, SEARCH_SORT, TABLES } from '../../../../../actions'
import ApiPaths from '../../../../../constants/ApiPaths'
import CommonFunc from '../../../../common/function/CommonFunc'
import { TabName } from '../ConstantsCarFlightMgmt'

/**
 * @return {JSX} ページコンポーネント
 */
const ButtonCarFlightMgmt = () => {
  const history = useHistory()
  const [open, setOpen] = useState(false)
  const [savedChangesOpen, setSavedChangesOpen] = useState(false)
  const { state, dispatch } = useContext(AppContext)

  return useMemo(() => {
    /** @type {Boolean} FlightInfoTabが開かれているか */
    const isOpenFlightInfoTab = state.Tabs === TabName.flightInfo

    /** @type {Boolean} Saveボタンの表示判定 */
    const isShowSaveButton = isOpenFlightInfoTab && state.Auth

    /**
     * 編集内容を保存
     * @returns {void} なし
     */
    const save = async () => {
      setOpen(false)
      /** @type {Object} リクエストデータ */
      const req = { flightInfoArr: state.Tables.getRequestData() }
      // リクエストがある場合API呼び出し
      if (req.flightInfoArr.length > 0) {
        const apiInfoArr = [
          {
            key: 'flightInfo',
            method: 'post',
            path: ApiPaths.MASTER_CAR.LIGHT_FLIGHT_MANAGEMENT,
            req,
          },
        ]
        const resObj = await CommonFunc.execApiAsync(apiInfoArr)
        const isApiSuccessful = CommonFunc.checkApiResponseObj(resObj, history)
        if (isApiSuccessful) {
          const { flightInfo } = resObj
          // テーブルを初期化
          dispatch({
            type: TABLES.SET,
            tableData: flightInfo.data.results,
            search: '',
          })
          setSavedChangesOpen(true)
        }
      } else {
        setSavedChangesOpen(true)
        // テーブルを初期化
        dispatch({
          type: TABLES.SET,
          search: '',
        })
      }
      window.scrollTo(0, 0)
      dispatch({
        type: SEARCH_SORT.SET,
        search: '',
      })
      dispatch({
        type: PAGING.SET,
        page: 0,
      })

      // 画面下部のチェックボックスの状態を元に戻す
      dispatch({
        type: CHECKBOX.SET,
        checked: false,
      })
    }

    /** @type {Object} ダイアログのボタン設定 */
    const dialogFuncObj = {
      [Common.DIALOG.BUTTON_CATEGORY.CANCEL]: { onClick: () => setOpen(false) },
      [Common.DIALOG.BUTTON_CATEGORY.OK]: { onClick: save },
    }

    /** @type {Object} SavedChangesダイアログのボタン設定 */
    const savedDialogFuncObj = {
      [Common.DIALOG.BUTTON_CATEGORY.OK]: {
        onClick: () => setSavedChangesOpen(false),
      },
    }

    // Saveボタン
    const saveButton = (
      <Grid item>
        <SaveButton onClick={() => setOpen(true)} disabled={!state.Checkbox} />
        <AreYouSureDialog
          open={open}
          dialogFuncObj={dialogFuncObj}
          closeFunc={() => setOpen(false)}
        />
      </Grid>
    )

    return (
      <>
        <SavedChangesDialog
          open={savedChangesOpen}
          closeFunc={() => setSavedChangesOpen(false)}
          dialogFuncObj={savedDialogFuncObj}
        />
        <Grid container justify="center">
          <Grid container item justify="center" md={10} spacing={2}>
            {isShowSaveButton ? (
              <Grid item md={12}>
                <CheckboxCarFlightMgmt />
              </Grid>
            ) : null}
            <Grid item>
              <BackButton path={Paths.MASTER_AIRLINE.CAR_MASTER_TOP} />
            </Grid>
            {isShowSaveButton ? saveButton : null}
          </Grid>
        </Grid>
      </>
    )
  }, [
    state.Checkbox,
    open,
    dispatch,
    history,
    state.Tables,
    state.Tabs,
    savedChangesOpen,
    state.Auth,
  ])
}

export default ButtonCarFlightMgmt
