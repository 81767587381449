const ValidationCheckMessage = {
  VALIDATION000: {
    en: '',
    ja: '',
  },
  VALIDATION001: {
    en: 'Please enter less than 255 half-width alphanumeric and symbols.',
    ja: '半角英数字・記号、255字以内で入力してください。',
  },
  VALIDATION002: {
    en: 'Please enter less than 100 half-width alphanumeric and symbols.',
    ja: '半角英数字・記号、100字以内で入力してください。',
  },
  VALIDATION003: {
    en: 'Please enter less than 50 half-width alphanumeric and symbols.',
    ja: '半角英数字・記号、50字以内で入力してください。',
  },
  VALIDATION004: {
    en: 'Please enter less than 100 half-width alphanumeric.',
    ja: '半角英数字、100字以内で入力してください。',
  },
  VALIDATION005: {
    en: 'Please enter less than 50 half-width alphanumeric.',
    ja: '半角英数字、50字以内で入力してください。',
  },
  VALIDATION006: {
    en: 'Please enter less than 10 half-width alphanumeric.',
    ja: '半角英数字、10字以内で入力してください。',
  },
  VALIDATION007: {
    en: 'Please enter less than 50 half-width numbers.',
    ja: '半角数字、50字以内で入力してください。',
  },
  VALIDATION008: {
    en: 'Please enter less than 20 half-width numbers.',
    ja: '半角数字、20字以内で入力してください。',
  },
  VALIDATION009: {
    en: 'Please enter less than 255 half-width alphanumeric.',
    ja: '半角英数字、255字以内で入力してください。',
  },
  VALIDATION010: {
    en: 'Please enter less than 5 half-width numbers.',
    ja: '半角数字、5字以内で入力してください。',
  },
  VALIDATION011: {
    en: 'Required field.',
    ja: '必須項目です。',
  },
  VALIDATION012: {
    en: 'Decimals are valid to the third decimal place.',
    ja: '小数点以下は第三位まで有効です。',
  },
  VALIDATION013: {
    en: 'Contains invalid characters.',
    ja: '無効な文字が含まれています。',
  },
  VALIDATION014: {
    en: 'Please enter 2 uppercase half-width alphanumeric.',
    ja: '大文字の半角英数字、2字で入力してください。',
  },
  VALIDATION015: {
    en: 'Please enter less than 10 half-width alphanumeric and symbols.',
    ja: '半角英数字・記号、10字以内で入力してください。',
  },
  VALIDATION016: {
    en: 'Please enter between 8 and 15 alphanumeric and symbols',
    ja: '半角英数字・記号、8文字以上15文字以内で入力してください。',
  },
  VALIDATION017: {
    en: 'Please enter alphanumeric and symbols.',
    ja: '半角英数字・記号で入力してください。',
  },
  VALIDATION018: {
    en: 'Please enter less than 8 half-width numbers.',
    ja: '半角数字、8字以内で入力してください。',
  },
  VALIDATION019: {
    en: 'Please enter less than 4 half-width numbers.',
    ja: '半角数字、4字以内で入力してください。',
  },
  VALIDATION020: {
    en: 'Please enter less than 10 half-width numbers.',
    ja: '半角数字、10字以内で入力してください。',
  },
  VALIDATION021: {
    en: 'Please enter less than 65 half-width alphanumeric and symbols.',
    ja: '半角英数字・記号、65字以内で入力してください。',
  },
  VALIDATION022: {
    en: 'Please enter 3 half-width alphanumeric.',
    ja: '半角英数字、3字で入力してください。',
  },
  VALIDATION023: {
    en: 'Please enter 4 half-width numbers.',
    ja: '半角数字、4字で入力してください。',
  },
  VALIDATION024: {
    en: 'The file size should be 1MB or less.',
    ja: 'ファイルサイズは1MB以下にしてください。',
  },
  VALIDATION025: {
    en: 'The file size should be 5MB or less.',
    ja: 'ファイルサイズは5MB以下にしてください。',
  },
  VALIDATION026: {
    en: 'Spaces cannot be entered.',
    ja: 'スペースは入力できません。',
  },
  VALIDATION027: {
    en: 'Space cannot be entered at the begining.',
    ja: '先頭のスペースは入力できません。',
  },
  VALIDATION028: {
    en: 'Space cannot be entered at the end.',
    ja: '末尾のスペースは入力できません。',
  },
  VALIDATION029: {
    en: 'Consecutive spaces cannot be entered.',
    ja: '連続したスペースは入力できません。',
  },
  VALIDATION030: {
    en: 'Full-width spaces cannot be entered.',
    ja: '全角スペースは入力できません。',
  },
  VALIDATION031: {
    en: 'Please enter an integer.',
    ja: '整数を入力してください。',
  },
  VALIDATION032: {
    en: 'Minus cannot be entered.',
    ja: 'マイナスは入力できません。',
  },
  VALIDATION033: {
    en: 'Please enter less than 5 numbers and less than 1 decimal point.',
    ja: '半角数字、整数5桁以内、小数第一位までで入力してください。',
  },
  VALIDATION034: {
    en: 'Please enter less than 10 uppercase half-width alphanumeric.',
    ja: '大文字の半角英数字、10字以内で入力してください。',
  },
  VALIDATION035: {
    en: 'Please enter less than 5 half-width numbers and symbols.',
    ja: '半角数字・記号、5字以内で入力してください。',
  },
  VALIDATION036: {
    en: 'Please enter less than 2 half-width numbers.',
    ja: '半角数字、2字以内で入力してください。',
  },
  VALIDATION037: {
    en: 'Already registered.',
    ja: '既に登録されています。',
  },
  VALIDATION038: {
    en: 'Please enter less than 8 half-width alphanumeric.',
    ja: '半角英数字、8字以内で入力してください。',
  },
  VALIDATION039: {
    en: 'Please enter 4 to 5 half-width numbers.',
    ja: '半角数字、4~5文字で入力してください。',
  },
  VALIDATION040: {
    en: 'Please enter less than 15 half-width numbers.',
    ja: '半角数字、15字以内で入力してください。',
  },
  VALIDATION041: {
    en: 'Please enter 11 half-width numbers.',
    ja: '半角数字、11字で入力してください。',
  },
  VALIDATION042: {
    en: 'Please enter less than 9 half-width alphanumeric and symbols.',
    ja: '半角英数字・記号、9字以内で入力してください。',
  },
  VALIDATION043: {
    en: 'Please enter less than 5 numbers and less than 3 decimal point.',
    ja: '半角数字、整数5桁以内、小数第3位までで入力してください。',
  },
  VALIDATION044: {
    en: 'Please enter less than 80 half-width alphanumeric and symbols.',
    ja: '半角英数字・記号、80字以内で入力してください。',
  },
  VALIDATION045: {
    en: 'Please enter 8 half-width numbers.',
    ja: '半角数字、8字で入力してください。',
  },
  VALIDATION046: {
    en: 'Please use images with a file format of .jpeg or .jpg.',
    ja: 'ファイル形式が.jpeg,.jpgの画像を使用してください。',
  },
  VALIDATION047: {
    en: 'Please use documents with a file format of .pdf or .zip .',
    ja: 'ファイル形式が.pdfまたは.zipのドキュメントを使用してください。',
  },
  VALIDATION048: {
    en: '@ cannot be entered at the begining.',
    ja: '先頭に@は入力できません。',
  },
  VALIDATION049: {
    en: '@ cannot be entered at the end.',
    ja: '末尾に@は入力できません。',
  },
  VALIDATION050: {
    en: 'Please enter @ between.',
    ja: '間に@を入力してください。',
  },
  VALIDATION051: {
    en: 'Please enter one @.',
    ja: '@は1つ入力してください。',
  },
  VALIDATION052: {
    en: 'Please enter 2 half-width alphanumeric.',
    ja: '半角英数字、2字で入力してください。',
  },
  VALIDATION053: {
    en: 'Cannot be entered with only 0.',
    ja: '0のみで入力することはできません。',
  },
  VALIDATION054: {
    en: 'Please enter 250 half-width alphanumeric and symbols.',
    ja: '半角英数字・記号、250字で入力してください。',
  },
  VALIDATION055: {
    en: 'Please enter less than 84 half-width alphanumeric and symbols.',
    ja: '半角英数字・記号、84字以内で入力してください。',
  },
  VALIDATION056: {
    en: 'Please enter 251 half-width alphanumeric and symbols.',
    ja: '半角英数字・記号、251字で入力してください。',
  },
  VALIDATION057: {
    en: 'Please enter less than 25 half-width alphanumeric and symbols.',
    ja: '半角英数字・記号、25字以内で入力してください。',
  },
  VALIDATION058: {
    en: 'Please enter less than 5 numbers and less than 5 decimal point.',
    ja: '半角数字、整数5桁以内、小数第五位までで入力してください。',
  },
  VALIDATION059: {
    en: 'Please enter less than 10 numbers and less than 2 decimal point.',
    ja: '半角数字、整数10桁以内、小数第二位までで入力してください。',
  },
  VALIDATION060: {
    en: 'Please enter less than 2 numbers and less than 2 decimal point.',
    ja: '半角数字、整数2桁以内、小数第二位までで入力してください。',
  },
  VALIDATION061: {
    en: 'Please enter less than 90 half-width alphanumeric and symbols.',
    ja: '半角英数字・記号、90字以内で入力してください。',
  },
  VALIDATION062: {
    en: 'Please enter less than 140 half-width alphanumeric and symbols.',
    ja: '半角英数字・記号、140字以内で入力してください。',
  },
  VALIDATION063: {
    en: 'Please enter less than 242 half-width alphanumeric and symbols.',
    ja: '半角英数字・記号、242字以内で入力してください。',
  },
  VALIDATION064: {
    en: 'Decimal point cannot be entered at the begining.',
    ja: '先頭の小数点は入力できません。',
  },
  VALIDATION065: {
    en: 'Please enter 3 uppercase half-width alphanumeric.',
    ja: '大文字の半角英数字、3字で入力してください。',
  },
  VALIDATION066: {
    en: 'Please enter within 23:59.',
    ja: '23:59以内で入力してください。',
  },
  VALIDATION067: {
    en: 'Please enter in the format xx:xx.',
    ja: 'xx:xxの形式で入力してください',
  },
  VALIDATION068: {
    en: 'In case of changing to the FREE plan, please switch to 10 people on the Account Info before changing the plan.',
    ja: 'FREEプランに変更する場合は、プラン変更前にアカウント情報を10人に切り替えてください。',
  },
  VALIDATION069: {
    en: 'In case of changing to the SATNDARD plan, please switch to 30 people on the Account Info before changing the plan.',
    ja: 'SATNDARDプランに変更する場合は、プラン変更前にアカウント情報を30人に切り替えてください。',
  },
  VALIDATION070: {
    en: 'In case of changing to the SATNDARD plan, please switch to 5 people on the Account Info before changing the plan.',
    ja: 'SATNDARDプランに変更する場合は、プラン変更前にアカウント情報を5人に切り替えてください。',
  },
}

export default ValidationCheckMessage
