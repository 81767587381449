/** @type {Object} プランのIDと名前の定数 */
const PLAN = {
  FREE: {
    ID: 1,
    NAME: 'Free',
  },
  STANDARD: {
    ID: 2,
    NAME: 'Standard',
  },
  PROFESSIONAL: {
    ID: 3,
    NAME: 'Professional',
  },
  ENTERPRISE: {
    ID: 4,
    NAME: 'Enterprise',
  },
}

export default PLAN
