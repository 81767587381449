// Display + 画面名
const DisplayAirlineCassUpload = {
  // 画面ID + 文言ID
  D220V0001: {
    en: 'CASS UPLOAD',
    ja: 'CASS アップロード',
  },
  D220V0002: {
    en: 'Date Period',
    ja: '期間',
  },
  D220V0003: {
    en: 'Lately',
    ja: '最近',
  },
  D220V0004: {
    en: 'Past',
    ja: '過去',
  },
  D220V0005: {
    en: 'Details',
    ja: '詳細',
  },
  D220V0006: {
    en: 'No history',
    ja: '履歴はありません',
  },
  D220V0007: {
    en: 'Back',
    ja: 'Back',
  },
}
// Display + 画面名
export default DisplayAirlineCassUpload
