import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import AppContext from '../../../../../../contexts/AppContext'
import XsmallButton from '../../../../../common/buttons/XsmallButton'
import { LIGHT_FLIGHT_MGMT } from '../../../../../../actions'
import AreYouSureDialog from '../../../../../common/dialog/commonDesignDialog/AreYouSureDialog'
import Common from '../../../../../../constants/Common'

/**
 * @return {JSX} Deleteボタン
 */
const DeleteButtonIconPopup = () => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const { state, dispatch } = useContext(AppContext)

  /**
   * ボタン押下時のイベント
   * @returns {void} なし
   */
  const onClick = () => {
    // アイコンパスを削除
    dispatch({
      type: LIGHT_FLIGHT_MGMT.ICON_POPUP.EDIT,
      iconPath: '',
    })
    setOpen(false)
  }

  /** @type {Object} ダイアログのボタン設定 */
  const dialogFuncObj = {
    [Common.DIALOG.BUTTON_CATEGORY.CANCEL]: { onClick: () => setOpen(false) },
    [Common.DIALOG.BUTTON_CATEGORY.OK]: { onClick },
  }

  const deleteButton = (
    <>
      <XsmallButton onClick={() => setOpen(true)}>
        {t('D301V0221')}
      </XsmallButton>
      <AreYouSureDialog
        open={open}
        dialogFuncObj={dialogFuncObj}
        closeFunc={() => setOpen(false)}
      />
    </>
  )

  return state.IconPopup.noIcon || !state.Auth ? null : deleteButton
}

export default DeleteButtonIconPopup
