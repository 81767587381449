import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

import Language from '../buttons/LanguageButton'

const useStyles = makeStyles((theme) => ({
  root: {
    height: theme.header.height,
  },
  footer: {
    height: theme.header.height,
    padding: '1rem',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-end',
    },
  },
  logo: {
    width: '310px',
    height: '65px',
    fill: theme.palette.info.main,
    color: theme.palette.info.main,
  },
}))

const Footer = () => {
  const classes = useStyles()
  return (
    <AppBar color="primary" position="relative" className={classes.root}>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className={classes.footer}
      >
        {/* 1次リリースで非表示となったためisHiddenで非表示 */}
        <Grid item>
          <Language isHidden />
        </Grid>
        <Grid item></Grid>
      </Grid>
    </AppBar>
  )
}

export default Footer
