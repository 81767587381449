import React, { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'

import { LIGHT_FLIGHT_MGMT } from '../../../../../../actions'
import Common from '../../../../../../constants/Common'
import AppContext from '../../../../../../contexts/AppContext'
import CheckBox from '../../../../../common/checkBox/CheckBox'

/**
 * @param {Object} props 下記内容のProps
 * @param {Number} props.routingIndex Routingのインデックス
 * @param {Number} props.index FlightScheduleのインデックス
 * @returns {JSX} FlightScheduleのHide欄
 */
const Hide = (props) => {
  const { state, dispatch } = useContext(AppContext)
  const { routingIndex, index } = props
  /** @type {Number} ハイドフラグ */
  const hideFlg = state.Popup.getScheduleParam(routingIndex, index, 'hideFlg')
  /** @type {Boolean} チェック状態 */
  const checked = hideFlg === Common.HIDE_FLG.OFF

  return useMemo(() => {
    /**
     * Hideチェック更新
     * @param {Object} event イベント
     * @returns {void} なし
     */
    const onChange = (event) => {
      dispatch({
        type: LIGHT_FLIGHT_MGMT.EDIT.FLIGHT_SCHEDULE,
        hideFlg: event.target.checked
          ? Common.HIDE_FLG.OFF
          : Common.HIDE_FLG.ON,
        routingIndex,
        index,
      })
    }

    return (
      <CheckBox checked={checked} onChange={onChange} disabled={!state.Auth} />
    )
  }, [checked, state.Auth, routingIndex, index, dispatch])
}

Hide.propTypes = {
  routingIndex: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
}
export default Hide
