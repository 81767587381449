import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'

import LayoutLog from '../../../common/layout/LayoutLog'
import Theme from '../../../common/theme/Theme'
import StarIcon from './StarIcon'
import Title from './Title'
import Icon from './Icon'
import Button from './Button'

const useStyles = makeStyles(() => ({
  outer: Theme.topOuterNoHeader,
  title: {
    // 飛行機アイコン分ずらす
    paddingTop: '100px',
  },
  startIcon: {
    // 飛行機アイコン+タイトル分ずらす
    paddingTop: 'calc(100px + 48px)',
  },
}))

const ReissuePasswordSent = () => {
  const classes = useStyles()

  return (
    <>
      <LayoutLog>
        <div className={classes.outer}>
          <Grid container justify="center">
            <Grid item className={classes.startIcon}>
              <StarIcon />
            </Grid>
            <Grid item className={classes.title}>
              <Title />
            </Grid>
            <Grid item>
              <Icon />
            </Grid>
            <Grid item md={12}>
              <Button />
            </Grid>
          </Grid>
        </div>
      </LayoutLog>
    </>
  )
}

export default ReissuePasswordSent
