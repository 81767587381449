// 一般ページ
const shipper = {
  TOP: '/shipperTop',
  BID_MGMT: '/shipperBidMgmt',
  BID_INFO: '/shipperBidInfo',
  SELECT_FORWARDER: '/shipperSelectForwarder',
  BID_DETAILS: '/shipperBidDetails',
  CONFIRMATION: '/shipperConfirmation',
}
const fwdr = {
  TOP: '/fwdrTop',
  BID_MGMT: '/fwdrBidMgmt',
  BID_INFO: '/fwdrBidInfo',
  BID_DETAILS: '/fwdrBidDetails',
  CONFIRMATION: '/fwdrConfirmation',
  LIGHT_BILLING_REPORT: '/fwdrLightBillingReport',
  LIGHT_BOOKING_LIST: '/fwdrLightBookingList',
  LIGHT_BOOKING_DETAILS: '/lightFwdrBookingDetails',
  LIGHT_BOOKING_REQUEST: '/lightBookingRequest',
  LIGHT_SEARCH_RESULT: '/lightFwdrSearchResult',
}
const airline = {
  TOP: '/airlineTop',
  LIGHT_BILLING_REPORT: '/airlineLightBillingReport',
  LIGHT_BOOKING_LIST: '/airlineLightBookingList',
  LIGHT_BOOKING_DETAILS: '/lightAirlineBookingDetails',
}
const jh = {
  JH_TOP: '/jhTop',
  JH_CLIENT_REG: '/JhClientReg',
  LIGHT_JH_CLIENT_REG_SIGNUP: '/lightJhClientRegSignUp',
  LIGHT_JH_CLIENT_REG_DELETE_ACCOUNT: '/lightJhClientRegDeleteAccount',
  JH_LIGHT_CLIENT_LIST: '/LightJhClientList',
}

// マスターページ
const masterShipper = {
  SHIP_COMPANY_INFOG: '/masterShipCompanyInfoG',
  SHIP_COMPANY_INFOM: '/masterShipCompanyInfoM',
  SHIP_MY_PROFILE: '/masterShipMyProfile',
  SHIP_MASTER_TOP: '/masterShipMasterTop',
  SHIP_CONSIGNEE_INFO: '/masterShipConsigneeInfo',
  SHIP_ACCOUNT_INFO: '/masterShipAccountInfo',
}
const masterFwdr = {
  FWDR_MASTER_TOP: '/masterFwdrMasterTop',
  LIGHT_FWDR_COMPANY_INFO: '/LightFwdrCompanyInfo',
  LIGHT_FWDR_ACCOUNT_INFO: '/lightFwdrAccountInfo',
  LIGHT_FWDR_MY_PROFILE: '/lightFwdrMyProfile',
}
const masterAirline = {
  CAR_MASTER_TOP: '/masterCarMasterTop',
  LIGHT_CAR_SELECT_FWDR: '/airlineLightSelectForwarder',
  LIGHT_CAR_FLIGHT_MGMT: '/lightMasterCarFlightMgmt',
  LIGHT_CAR_AIR_FREIGHT: '/airlineLightAirFreight',
  LIGHT_CAR_COMPANY_INFO: '/LightCarCompanyInfo',
  LIGHT_CAR_MY_PROFILE: '/lightCarMyProfile',
  LIGHT_CAR_ACCOUNT_INFO: '/lightCarAccountInfo',
}
const masterJh = {
  JH_COMPANY_INFOG: '/masterJhCompanyInfoG',
  JH_COMPANY_INFOM: '/masterJhCompanyInfoM',
  JH_MY_PROFILE: '/masterJhMyProfile',
  JH_ACCOUNT_INFO: '/masterJhAccountInfo',
  JH_MASTER_TOP: '/masterJhMasterTop',
}

// 通知系ページ
const notify = {
  NOTIFY_LIST: '/notifyList',
  NOTIFICATION_DETAILS: '/notificationDetails',
  LIGHT_DELETE_ACCOUNT: '/lightDeleteAccount', //どの業者でもアクセス可能・ログイン必要
  LIGHT_DELETE_ACCOUNT_DONE: '/lightDeleteAccountDone',
}

// 認証系ページ
const authentication = {
  LIGHT_SHIP_REGISTER_NEW_ACCOUNT: '/lightShipperRegisterNewAccount',
  LIGHT_AIRLINE_REGISTER_NEW_ACCOUNT: '/lightAirlineRegisterNewAccount',
  LIGHT_FWDR_REGISTER_NEW_ACCOUNT: '/lightFwdrRegisterNewAccount',
  REISSUE_PASSWORD_SENT: '/reissuePasswordSent',
  SIGN_IN: '/signIn',
  PASSWORD_RESET: '/passwordReset',
  REISSUE_PASSWORD: '/reissuePassword',
}

// その他 エラーページや開発用ページ等
const others = {
  ERROR: '/error',
  MAINTENANCE: '/maintenance',
}

/**********************************************************************************************************************************************************/
// 特殊な条件のページ 上とは別にもう一回パスを書く必要あり

// 権限系の画面 権限のあるユーザのみ入れるページ
const managerPage = [
  // 一般ユーザはInfoG画面を開くため
  '/masterShipCompanyInfoM',
  '/masterFwdrCompanyInfoM',
  '/masterCarCompanyInfoM',
  '/masterFwdrCompanyInfoM',
  '/masterJhCompanyInfoM',

  // AccountInfoは管理者のみ使用可能のため
  '/masterShipAccountInfo',
  '/masterFwdrAccountInfo',
  '/masterCarAccountInfo',
  '/masterJhAccountInfo',
]

// 登録系の画面 メッセージを出す必要があるので追加
const registerPage = [
  // 荷主
  '/shipperShippingDetails',
  '/shipperSearchResults',
  '/shipperQuote',
  '/shipperInvoice',
  '/shipperInvoiceConfirm',
  '/shipperPackingList',
  '/shipperPackingListConfirm',
  '/shipperConfirmBooking',
  '/shipperTracing',
  '/masterShipCompanyInfoM',
  '/masterShipMyProfile',
  '/masterShipAccountInfo',
  '/masterShipConsigneeInfo',
  '/masterShipPickupAddress',
  // 混載業者
  '/fwdrCompleteBooking',
  '/fwdrDocuments',
  '/fwdrDocumentsConfirm',
  '/fwdrMfst',
  '/fwdrHouseAwb',
  '/fwdrMasterAwb',
  '/fwdrDangerousGoodsDecl',
  '/fwdrTracing',
  '/masterFwdrCompanyInfoM',
  '/masterFwdrMyProfile',
  '/masterFwdrAccountInfo',
  '/masterFwdrOtherCharges',
  '/masterFwdrAwbMgmt',
  '/masterFwdrAddProfit',
  '/masterFwdrTariffRate',
  // 航空会社
  '/airlineTracing',
  '/airlineSpaceControl',
  '/airlineAwbDetails',
  '/airlineCargoManifest',
  '/airlineSpecialLoad',
  '/airlineUldAssign',
  '/airlineSetFlight',
  '/airlineConfirmBooking',
  '/airlineCassUpload',
  '/airlineCassDetails',
  '/airlineRecordDetails',
  '/masterCarCompanyInfoM',
  '/masterCarMyProfile',
  '/masterCarAccountInfo',
  '/masterCarFlightMgmt',
  //'/masterCarFuelSurCharge',
  '/masterCarMawbMgmt',
  '/masterCarAirFreight',
  // JH
  '/masterJhCompanyInfoM',
  '/masterJhMyProfile',
  '/masterJhAccountInfo',
  '/deleteAccount',
]

// 全業者共通 フリープランで遷移できる画面
const CommonFreePlanPage = [...Object.values(notify), ...Object.values(others)]

// JHで遷移できる画面
const JhPage = [
  ...CommonFreePlanPage,
  ...Object.values(jh),
  ...Object.values(masterJh),
]

// 荷主フリープランで遷移できる画面
const ShipperFreePlanPage = [
  ...CommonFreePlanPage,
  ...Object.values(shipper),
  ...Object.values(masterShipper),
]

// 荷主スタンダードプランで遷移できる画面
const ShipperStandardPlanPage = [...ShipperFreePlanPage]

// 荷主プロフェッショナルプランで遷移できる画面
const ShipperProfessionalPlanPage = [...ShipperStandardPlanPage]

// 荷主エンタープライズプランで遷移できる画面
const ShipperEnterprisePlanPage = [...ShipperProfessionalPlanPage]

// 混載業者フリープランで遷移できる画面
const FwdrFreePlanPage = [
  ...CommonFreePlanPage,
  ...Object.values(masterFwdr),
  '/fwdrTop',
  '/fwdrLightBillingReport',
  '/fwdrLightBookingList',
  '/lightFwdrBookingDetails',
  '/lightBookingRequest',
  '/lightFwdrSearchResult',
]

// 混載業者Standardプランで遷移できる画面
const FwdrStandardPlanPage = [
  ...FwdrFreePlanPage,
  '/fwdrBidMgmt',
  '/fwdrBidInfo',
  '/fwdrBidDetails',
  '/fwdrConfirmation',
]

// 混載業者Professionalプランで遷移できる画面
const FwdrProfessionalPlanPage = [...FwdrStandardPlanPage]

// 混載業者Enterpriseプランで遷移できる画面
const FwdrEnterprisePlanPage = [...FwdrProfessionalPlanPage]

// 航空会社フリープランで遷移できる画面
const CarrierFreePlanPage = [...CommonFreePlanPage]

// 航空会社スタンダードプランで遷移できる画面
const CarrierStandardPlanPage = [
  ...CarrierFreePlanPage,
  ...Object.values(airline),
  ...Object.values(masterAirline),
]

// 航空会社プロフェッショナルプランで遷移できる画面
const CarrierProfessionalPlanPage = [...CarrierStandardPlanPage]

// 航空会社エンタープライズプランで遷移できる画面
const CarrierEnterprisePlanPage = [...CarrierProfessionalPlanPage]

const Paths = {
  /**********************************************************************************************************************************************************/
  // ページ遷移用のパス
  SHIPPER: {
    ...shipper,
  },
  FWDR: {
    ...fwdr,
  },
  AIRLINE: {
    ...airline,
  },
  JH: {
    ...jh,
  },
  MASTER: {
    ...masterShipper,
    ...masterFwdr,
    ...masterAirline,
    ...masterJh,
    ...jh,
  },
  MASTER_SHIPPER: {
    ...masterShipper,
  },
  MASTER_FWDR: {
    ...masterFwdr,
  },
  MASTER_AIRLINE: {
    ...masterAirline,
  },
  MASTER_JH: {
    ...masterJh,
  },
  NOTIFY: {
    ...notify,
  },
  AUTHENTICATION: {
    ...authentication,
  },
  OTHERS: {
    ...others,
  },

  /**********************************************************************************************************************************************************/
  // ページカテゴリー用のパス
  // frontend\src\constants\Common.jsのPAGE_CATEGORIESと関連付け

  // 業者別カテゴリー
  CATEGORY_SHIPPER: {
    ...shipper,
    ...masterShipper,
  },
  CATEGORY_FWDR: {
    ...fwdr,
    ...masterFwdr,
  },
  CATEGORY_AIRLINE: {
    ...airline,
    ...masterAirline,
  },
  CATEGORY_JH: {
    ...jh,
    ...masterJh,
  },

  // 業者関係なく入れるページ
  FREE_ACCESS: {
    ...notify,
    ...authentication,
    ...others,
  },
  // ログインしなくてもアクセス可能(URL直打ち)なページ
  FREE_LOGIN: {
    ...authentication,
    ...others,
  },
  // 権限系
  MANAGER_PAGE: managerPage,
  // 登録系
  REGISTER_PAGE: registerPage,
  // 利用可能ページ
  AVAILABLE_PAGE: {
    JH: JhPage,
    SHIPPER: {
      FREE: ShipperFreePlanPage,
      STANDARD: ShipperStandardPlanPage,
      PROFESSIONAL: ShipperProfessionalPlanPage,
      ENTERPRISE: ShipperEnterprisePlanPage,
    },
    FWDR: {
      FREE: FwdrFreePlanPage,
      STANDARD: FwdrStandardPlanPage,
      PROFESSIONAL: FwdrProfessionalPlanPage,
      ENTERPRISE: FwdrEnterprisePlanPage,
    },
    AIRLINE: {
      FREE: CarrierFreePlanPage,
      STANDARD: CarrierStandardPlanPage,
      PROFESSIONAL: CarrierProfessionalPlanPage,
      ENTERPRISE: CarrierEnterprisePlanPage,
    },
  },
}

export default Paths
