import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

import SearchBox from '../../common/searchForm/SearchBox'
import CheckBox from '../../common/checkBox/CheckBox'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '1rem',
    '& .MuiTypography-body1': {
      color: theme.fontColor.main,
    },
  },
  desktop: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobile: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}))

/**
 * サーチボックス+チェックボックス
 * @param {Object} props searchfactorsとcheckfactors要素とするオブジェクト
 * @param {Object} searchfactors 下記を要素とするオブジェクト
 * @param {String} placeholder 空白時の表示
 * @param {Event} clickEvent 検索クリック時のevent
 * @param {Event} changeEvent 検索入力時のevent
 * @param {Event} resetClick ✕ボタン押下時のevent
 * @param {Object} inputProps 入力制限など
 * @param {Object} checkfactors 下記を要素とするオブジェクト
 * @param {String} label チェックボックス横に表示する文字列
 * @param {Event} handleChange チェックボックス押下時のevent
 * @param {Boolean} checked チェック状態
 * @return {JSX} サーチボックス+チェックボックスコンポーネント
 */
const SearchAndCheck = (props) => {
  const classes = useStyles()

  return (
    <Grid container justify="center" className={classes.root}>
      <Grid container item md={10} xs={10} alignItems="flex-end">
        <Grid item md={4} xs={12}>
          <SearchBox searchfactors={props.searchfactors} />
        </Grid>
        <Grid item md={4} xs={12} className={classes.desktop}>
          <CheckBox
            label={props.checkfactors.label}
            onChange={props.checkfactors.handleChange}
            checked={props.checkfactors.checked}
          />
        </Grid>
        <Grid
          item
          container
          justify="center"
          xs={12}
          className={classes.mobile}
        >
          <Grid item>
            <CheckBox
              label={props.checkfactors.label}
              onChange={props.checkfactors.handleChange}
              checked={props.checkfactors.checked}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SearchAndCheck
