import React from 'react'
import { useTranslation } from 'react-i18next'

import Title from '../../../../common/title/Title'

/**
 * @return {JSX} LightJhClientRegDeleteAccount画面タイトルコンポーネント
 */
const TitleLightJhClientRegDeleteAccount = () => {
  const { t } = useTranslation()

  return <Title>{t('D401V0001')}</Title>
}

export default TitleLightJhClientRegDeleteAccount
