import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import TranslateIcon from '@material-ui/icons/Translate'
import Button from '@material-ui/core/Button'

import Common from '../../../constants/Common'
import CommonFunc from '../function/CommonFunc'

const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.buttonSize.width.medium,
    height: theme.buttonSize.height.medium,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.fontSize.regular,
    borderRadius: 25,
    border: '1px solid',
    borderColor: theme.palette.info.main,
    color: theme.fontColor.secondary,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '0 1rem',
  },
}))

/**
 * 言語切り替えボタン
 * 1次リリースで非表示となったためisHiddenあり
 * @param {Object} props - 以下
 * @param {Boolean} isHidden - trueで言語切り替えボタン非表示
 * @returns {JSX.Element} - 言語切り替えボタン
 */
const LanguageButton = (props) => {
  const classes = useStyles()
  const { i18n } = useTranslation()

  const { isHidden } = props

  // 現在言語設定をストレージに保存
  const currentLanguage = i18n.language
  CommonFunc.setStorage(
    Common.KEY.LANGUAGE_KEY,
    currentLanguage ?? Common.LANGUAGE.EN
  )
  const text = currentLanguage === 'ja' ? 'ENGLISH' : 'JAPANESE'

  /**
   * 言語切り替え
   * @returns {void}
   */
  const handleChangeLanguageSelect = () => {
    const la = text === 'JAPANESE' ? 'ja' : 'en'
    i18n.changeLanguage(la)
    // ローカルストレージに言語を保存
    CommonFunc.setStorage(Common.KEY.LANGUAGE_KEY, la)
  }

  /**
   * 言語切り替えボタン作成
   * @returns {JSX.Element} - 言語切り替えボタン
   */
  const createLanguageButton = () => {
    return (
      <Button
        className={classes.root}
        variant="outlined"
        onClick={() => handleChangeLanguageSelect(true)}
      >
        <div className={classes.flex}>
          <TranslateIcon />
          {text}
        </div>
      </Button>
    )
  }

  return isHidden ? '' : createLanguageButton()
}

export default LanguageButton
