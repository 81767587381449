import { makeStyles } from '@material-ui/core'
import React from 'react'

import AppContext from '../../../../contexts/AppContext'
import LayoutBox from '../../../common/layout/LayoutBox'
import TitleLightJhClientRegSignUp from './00Title/TitleLightJhClientRegSignUp'
import SubTitleLightJhClientRegSignUp from './01SubTitle/SubTitleLightJhClientRegSignUp'
import RequestTableLightJhClientRegSignUp from './02RequestTable/RequestTableLightJhClientRegSignUp'
import PagingLightJhClientRegSignUp from './03Paging/PagingLightJhClientRegSignUp'
import BackButtonLightJhClientRegSignUp from './04BackButton/BackButtonLightJhClientRegSignUp'
import useFetchData from './Hook/useFetchData'
import Theme from '../../../common/theme/Theme'

const useStyles = makeStyles(() => ({
  padding: Theme.childrenPadding,
}))

const LightJhClientRegSignUp = () => {
  // 初期表示の値のセット
  const [state, dispatch] = useFetchData()
  const classes = useStyles()

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <LayoutBox>
        <div className={classes.padding}>
          <TitleLightJhClientRegSignUp />
          <SubTitleLightJhClientRegSignUp />
          <RequestTableLightJhClientRegSignUp />
          <PagingLightJhClientRegSignUp />
          <BackButtonLightJhClientRegSignUp />
        </div>
      </LayoutBox>
    </AppContext.Provider>
  )
}

export default LightJhClientRegSignUp
