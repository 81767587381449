import React, { useContext, useMemo } from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

import { LIGHT_AIR_FREIGHT } from '../../../../../actions'
import AppContext from '../../../../../contexts/AppContext'
import InputSelect from '../../../../common/select/InputSelect'

const useStyles = makeStyles((Theme) => ({
  title: Theme.textNormalBLU,
  margin: {
    marginBottom: '1rem',
  },
}))

/**
 * @returns {JSX} CarrierNameSelect欄
 */
const CurrencySelectLightCarAirFreight = () => {
  const { state, dispatch } = useContext(AppContext)
  const { t } = useTranslation()
  const classes = useStyles()

  /** @type {Number} 選択中の通貨ID */
  const currencyId = state.CurrencyValue.currencyId

  return useMemo(() => {
    /** @type{Object} 選択中の通貨 */
    const value = state.Currency.getCurrency(currencyId)

    /**
     * 通貨更新
     * @param {Object} value 選択した値
     * @returns {void} なし
     */
    const onChange = (value) => {
      dispatch({
        type: LIGHT_AIR_FREIGHT.CURRENCY.EDIT,
        currencyId: value?.currencyId ?? null,
      })
    }

    return (
      <Grid item md={2}>
        <div className={classes.title}>{t('D301V0061')}</div>
        <InputSelect
          className={classes.margin}
          options={state.Currency.currency}
          getOptionLabel={(option) => option.currency}
          value={value}
          onChange={(_, value) => onChange(value)}
          disabled={!state.Auth}
        />
      </Grid>
    )
  }, [state.Auth, dispatch, state.Currency, currencyId, classes, t])
}
export default CurrencySelectLightCarAirFreight
