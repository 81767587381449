import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import TextField from '../../../common/text/TextField'
import { LIGHT_BOOKING_DETAILS } from '../../../../actions'

/**
 * @param {Object} props 下記内容
 * @param {string} props.valueName 値の名前
 * @param {string} props.defaultValue 初期値
 * @param {Function} props.dispatch dispatch
 * @param {Function} props.validation バリデーション関数
 * @param {boolean} props.disabled 非活性状態
 * @returns {JSX} MAWBNo入力欄
 */
const TextFieldMawbNoLightBookingDetails = ({
  valueName,
  defaultValue,
  dispatch,
  validation,
  disabled,
}) => {
  const [error, setError] = useState(false)
  const [helperText, setHelperText] = useState('')
  const { t } = useTranslation()

  if (defaultValue === undefined) {
    return null
  }

  /**
   * 入力内容を更新
   * @param {String} value 入力値
   * @returns {void} なし
   */
  const onChange = (value) => {
    const { checkResult, errMsg } = validation(value)
    setError(checkResult)
    setHelperText(t(errMsg))
    dispatch({
      type: LIGHT_BOOKING_DETAILS.MAWB.EDIT,
      value: checkResult ? null : value,
      valueName,
    })
  }

  return (
    <Grid item md={2}>
      <TextField
        defaultValue={defaultValue}
        error={error}
        helperText={helperText}
        onChange={(event) => onChange(event.target.value)}
        disabled={disabled}
      />
    </Grid>
  )
}
TextFieldMawbNoLightBookingDetails.propTypes = {
  valueName: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  validation: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
}
export default TextFieldMawbNoLightBookingDetails
