import React from 'react'
import { Grid } from '@material-ui/core'

import CommonDatepicker from '../../../../commonUseContext/Bid/DatePicker/CommonDatePicker'
import { DATEPICKER } from '../../../../../actions'

/**
 * @return {JSX} DeadlineのDatepickerコンポーネント
 */
const DatePickerDeadlineBidInfo = () => {
  return (
    <Grid container item md={6} xs={6}>
      <Grid item md={5} xs={5}>
        <CommonDatepicker
          stateName={'Deadline'}
          stateKey={'date'}
          actionType={DATEPICKER.SET}
          disablePast
        />
      </Grid>
    </Grid>
  )
}

export default DatePickerDeadlineBidInfo
