import React, { useContext } from 'react'
import AppContext from '../../../../../contexts/AppContext'

import CommonTableContainerNone from '../../../../common/table/CommonTableContainerNone'
import TableContentsLightFwdrSearchResult from './TableContentsLightFwdrSearchResult'

/**
 * @return {JSX} 表コンポーネント(全体)
 */
const TableLightFwdrSearchResult = () => {
  const { state } = useContext(AppContext)

  return (
    <CommonTableContainerNone isNoResult={state.Tables.isNoResult}>
      <TableContentsLightFwdrSearchResult />
    </CommonTableContainerNone>
  )
}

export default TableLightFwdrSearchResult
