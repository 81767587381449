import Common from '../../../../constants/Common'

/**
 * @typedef {Object} TableInfo テーブル情報
 * @property {string} headerKey ヘッダーに表示する文言番号
 * @property {string} cellKey セルに表示するプロパティ名
 * @property {string} cellCategory Common.CELL_CATEGORYの値
 */

const ConstantsLightCarAccountInfo = {
  /** @type {TableInfo[]} テーブル作成に必要な値 */
  TABLE_ARR: [
    {
      headerKey: 'D301V0007',
      cellKey: 'name',
      cellCategory: Common.CELL_CATEGORY.TEXT,
    },
    {
      headerKey: 'D301V0008',
      cellKey: 'lastName',
      cellCategory: Common.CELL_CATEGORY.TEXT,
    },
    {
      headerKey: 'D301V0010',
      cellKey: 'email',
      cellCategory: Common.CELL_CATEGORY.TEXT,
    },
    {
      headerKey: 'D301V0011',
      cellKey: 'tel',
      cellCategory: Common.CELL_CATEGORY.TEXT,
    },
    {
      headerKey: 'D301V0023',
      cellKey: 'position',
      cellCategory: Common.CELL_CATEGORY.TEXT,
    },
    {
      headerKey: 'D301V0209',
      cellKey: 'department',
      cellCategory: Common.CELL_CATEGORY.TEXT,
    },
    {
      headerKey: 'D301V0034',
      cellKey: 'authorityFlg',
      cellCategory: Common.CELL_CATEGORY.CHECK,
    },
    {
      headerKey: 'D301V0035',
      cellKey: 'edit',
      cellCategory: Common.CELL_CATEGORY.BUTTON.EDIT,
    },
  ],
  /** @type {TableInfo[]} テーブル作成に必要な値 */
  GSA_TABLE_ARR: [
    {
      headerKey: 'D301V0007',
      cellKey: 'name',
      cellCategory: Common.CELL_CATEGORY.TEXT,
    },
    {
      headerKey: 'D301V0008',
      cellKey: 'lastName',
      cellCategory: Common.CELL_CATEGORY.TEXT,
    },
    {
      headerKey: 'D301V0010',
      cellKey: 'email',
      cellCategory: Common.CELL_CATEGORY.TEXT,
    },
    {
      headerKey: 'D301V0011',
      cellKey: 'tel',
      cellCategory: Common.CELL_CATEGORY.TEXT,
    },
    {
      headerKey: 'D301V0023',
      cellKey: 'position',
      cellCategory: Common.CELL_CATEGORY.TEXT,
    },
    {
      headerKey: 'D301V0034',
      cellKey: 'authorityFlg',
      cellCategory: Common.CELL_CATEGORY.CHECK,
    },
    {
      headerKey: 'D301V0035',
      cellKey: 'edit',
      cellCategory: Common.CELL_CATEGORY.BUTTON.EDIT,
    },
  ],
  // 並び替えに必要な値
  SORT_ITEM_ARR: [
    {
      sortTerm: Common.SORT_TERMS.CAR_ORDER,
      wordKey: 'D005V0003',
      sortKey: 'name',
    },
  ],
  //1ページに表示する件数
  PAGE_AMOUNT: 15,
}

export default ConstantsLightCarAccountInfo
