// Display + 画面名
const DisplayShipperConfirmBid = {
  // 画面ID + 文言ID
  D011V0001: {
    en: 'CONFIRM BID',
    ja: '入札確定',
  },
  D011V0002: {
    en: 'Owner',
    ja: 'オーナー',
  },
  D011V0003: {
    en: 'Panasonic Corporation',
    ja: 'パナソニック株式会社',
  },
  D011V0004: {
    en: '1006, Oaza Kadoma, Kadoma-shi, Osaka 571-8501, Japan',
    ja: '日本 571-8501 大阪府門真市大字門真1006',
  },
  D011V0005: {
    en: 'Tel. ',
    ja: '電話番号：',
  },
  D011V0006: {
    en: '+81-6-6908-1121',
    ja: '+81-6-6908-1121',
  },
  D011V0007: {
    en: 'Representative',
    ja: '代表',
  },
  D011V0008: {
    en: 'Konosuke Matsushita',
    ja: '松下幸之助',
  },
  D011V0009: {
    en: 'Manager',
    ja: 'マネージャー',
  },
  D011V0010: {
    en: 'Supervisor',
    ja: 'スーパーバイザー',
  },
  D011V0011: {
    en: 'Leader',
    ja: 'リーダー',
  },
  D011V0012: {
    en: 'Lighting Dept.',
    ja: '照明部門',
  },
  D011V0013: {
    en: 'kmatsushita@panasonic.co.jp',
    ja: 'kmatsushita@panasonic.co.jp',
  },
  D011V0014: {
    en: 'Validity',
    ja: '有効',
  },
  D011V0015: {
    en: 'August 1, 2020 to January 31, 2021',
    ja: '2020年8月1日~2021年1月31日',
  },
  D011V0016: {
    en: 'Set FWDR',
    ja: '混載業者を設定',
  },
  D011V0017: {
    en: 'DZK/KNJ/EIK/NEC/YLK/KWE/HEI',
    ja: 'DZK/KNJ/EIK/NEC/YLK/KWE/HEI',
  },
  D011V0018: {
    en: 'Currency',
    ja: '通貨',
  },
  D011V0019: {
    en: 'JPY - Japanese Yen',
    ja: 'JPY - 日本円',
  },
  D011V0020: {
    en: '1st Deadline',
    ja: '一次締め切り',
  },
  D011V0021: {
    en: 'July 31, 2020',
    ja: '2020年7月31日',
  },
  D011V0022: {
    en: 'Special Notes',
    ja: '備考',
  },
  D011V0023: {
    en: '･Please follow the schedule strictly.',
    ja: '・スケジュールは厳守してください。',
  },
  D011V0024: {
    en: "・We'll inform you as soon as we decide.",
    ja: '・決定次第お知らせします。',
  },
  D011V0025: {
    en: 'ORG/DST',
    ja: '行先/到着',
  },
  D011V0026: {
    en: 'Via',
    ja: '経由',
  },
  D011V0027: {
    en: 'TTL Wgt.',
    ja: '合計重量',
  },
  D011V0028: {
    en: 'TTL Vol.',
    ja: '合計容積',
  },
  D011V0029: {
    en: 'SHC',
    ja: 'SHC',
  },
  D011V0030: {
    en: '+45kg',
    ja: '+45kg',
  },
  D011V0031: {
    en: '+100kg',
    ja: '+100kg',
  },
  D011V0032: {
    en: '+300kg',
    ja: '+300kg',
  },
  D011V0033: {
    en: '+500kg',
    ja: '+500kg',
  },
  D011V0034: {
    en: '+1,000kg',
    ja: '+1,000kg',
  },
  D011V0035: {
    en: '1. DZK Rates - FORECAST',
    ja: '1. DZKレート-予測',
  },
  D011V0036: {
    en: 'Select FWDR',
    ja: '混載業者を選択',
  },
  D011V0037: {
    en: 'NRT/MNL',
    ja: 'NRT/MNL',
  },
  D011V0038: {
    en: 'NRT/LON',
    ja: 'NRT/LON',
  },
  D011V0039: {
    en: 'NRT/ORD',
    ja: 'NRT/ORD',
  },
  D011V0040: {
    en: '-',
    ja: '-',
  },
  D011V0041: {
    en: 'FRA',
    ja: 'FRA',
  },
  D011V0042: {
    en: 'ANC',
    ja: 'ANC',
  },
  D011V0043: {
    en: '1,000kg - 3,000kg',
    ja: '1,000kg - 3,000kg',
  },
  D011V0044: {
    en: '300m3 - 500m3',
    ja: '300m3 - 500m3',
  },
  D011V0045: {
    en: 'GEN',
    ja: 'GEN',
  },
  D011V0046: {
    en: 'DG',
    ja: 'DG',
  },
  D011V0047: {
    en: 'ICE',
    ja: 'ICE',
  },
  D011V0048: {
    en: '800',
    ja: '800',
  },
  D011V0049: {
    en: '850',
    ja: '850',
  },
  D011V0050: {
    en: '650',
    ja: '650',
  },
  D011V0051: {
    en: '540',
    ja: '540',
  },
  D011V0052: {
    en: '820',
    ja: '820',
  },
  D011V0053: {
    en: '620',
    ja: '620',
  },
  D011V0054: {
    en: '510',
    ja: '510',
  },
  D011V0055: {
    en: '780',
    ja: '780',
  },
  D011V0056: {
    en: '580',
    ja: '580',
  },
  D011V0057: {
    en: '480',
    ja: '480',
  },
  D011V0058: {
    en: '720',
    ja: '720',
  },
  D011V0059: {
    en: '530',
    ja: '530',
  },
  D011V0060: {
    en: '680',
    ja: '680',
  },
  D011V0061: {
    en: '440',
    ja: '440',
  },
  D011V0062: {
    en: 'Select',
    ja: '選択',
  },
  D011V0063: {
    en: '2. KNJ Rates - FINAL',
    ja: '2. KNJレート-最終',
  },
  D011V0064: {
    en: '3. EIK Rates - FORECAST',
    ja: '3. EIKレート-予測',
  },
  D011V0065: {
    en: '4. NEC Rates - FINAL RVSD',
    ja: '4. NECレート-最終RVSD',
  },
  D011V0066: {
    en: 'Back',
    ja: '戻る',
  },
  D011V0067: {
    en: '/',
    ja: '/',
  },
  D011V0068: {
    en: 'Please click the box if you agree the above conditions.',
    ja: '上記の条件に同意される場合は、チェックボックスをクリックしてください。',
  },
  D011V0069: {
    en: 'BID',
    ja: '入札',
  },
  D011V0070: {
    en: 'Norikuni Inaba',
    ja: '稲葉憲邦',
  },
  D011V0071: {
    en: 'Air Export Manager',
    ja: '航空輸出マネージャー',
  },
  D011V0072: {
    en: 'ninaba@dzk.co.jp / +81-X-XXXX-XXXX',
    ja: 'ninaba@dzk.co.jp / +81-X-XXXX-XXXX',
  },
  D011V0073: {
    en: 'Unselect',
    ja: '選択解除',
  },
  D011V0074: {
    en: '2nd Deadline',
    ja: '二次締め切り',
  },
  D011V0075: {
    en: '3rd Deadline',
    ja: '三次締め切り',
  },
  D011V0076: {
    en: '+81-X-XXXX-XXXX',
    ja: '+81-X-XXXX-XXXX',
  },
}
// Display + 画面名
export default DisplayShipperConfirmBid
