import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: theme.fontSize.header,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 'bold',
    color: theme.palette.info.main,
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
}))

const RegisterSubTitle = (props) => {
  const classes = useStyles()
  return (
    <>
      <Typography className={classes.text}>{props.children}</Typography>
    </>
  )
}

export default RegisterSubTitle
