import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'
import RadioGroup from '@material-ui/core/RadioGroup'
import PropTypes from 'prop-types'

import Radio from '../../../../common/radio/Radio'

const useStyles = makeStyles({
  radioGroup: {
    width: '700px',
  },
})

/**
 * ラジオボタンを表示
 * @param {Object} props - 以下
 * @param {Array} radios - ラジオボタンの配列
 * @param {string} value - 各コンポーネントの値
 * @param {Event} onChange - onChangeイベント
 * @returns {*} - ラジオボタン
 */
const Radios = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { radios, value, onChange } = props

  return (
    <RadioGroup
      row
      className={classes.radioGroup}
      value={value}
      onChange={onChange}
    >
      <Grid container item justify="space-between" spacing={2}>
        {radios.map((radio, index) => (
          <Grid item key={index}>
            <Radio
              labelPlacement="bottom"
              value={radio.value}
              label={t(radio.text)}
            />
          </Grid>
        ))}
      </Grid>
    </RadioGroup>
  )
}

Radios.propTypes = {
  radios: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default Radios
