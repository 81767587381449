import React, { useContext } from 'react'
import { Grid } from '@material-ui/core'

import AppContext from '../../../../../contexts/AppContext'
import RequestCardBackground from '../../../../common/RequestCard/RequestCardBackground'
import RequestCardContentsClientReg from '../../JhClientReg/common/RequestCardContentsClientReg'
import BlueDivider from '../../../../common/divider/BlueDivider'
import RequestCardButtonsLightJhClientRegDeleteAccount from './RequestCardButtonsLightJhClientRegDeleteAccount'
import WithdrawalLightJhClientRegDeleteAccount from './WithdrawalLightJhClientRegDeleteAccount'
import CommonCalc from '../../../../common/function/CommonCalc'

/**
 * @return {JSX} リクエストカードコンポーネント
 */
const RequestCardLightJhClientRegDeleteAccount = () => {
  const { state } = useContext(AppContext)

  const { Tabs } = state

  const currentPage = CommonCalc.calcShowDataIndex(
    state.Paging,
    state.Tables.showData.length - 1
  )
  const currentDataArr = state.Tables.showData[currentPage]
  return currentDataArr.map((data) => (
    <Grid key={data.companyId} item xs={5}>
      <RequestCardBackground>
        <RequestCardContentsClientReg data={data} selectTabIndex={Tabs} />
        <Grid container item xs={11} justify="center">
          <BlueDivider />
        </Grid>
        <WithdrawalLightJhClientRegDeleteAccount data={data} />
        <RequestCardButtonsLightJhClientRegDeleteAccount data={data} />
      </RequestCardBackground>
    </Grid>
  ))
}

export default RequestCardLightJhClientRegDeleteAccount
