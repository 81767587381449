import React, { useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { LIGHT_FLIGHT_MGMT } from '../../../../../../actions'
import AppContext from '../../../../../../contexts/AppContext'
import CommonValidation from '../../../../../common/function/CommonValidation'
import TextField from '../../../../../common/text/TextField'
import Content from '../../03AccordionCarFlightMgmt/Content'

/**
 * @param {*} props 下記内容のProps
 * @param {Number} props.index Routingのインデックス
 * @returns {JSX} RegNumber入力欄
 */
const RegNumber = (props) => {
  const { t } = useTranslation()
  const { index } = props
  const { state, dispatch } = useContext(AppContext)
  const [error, setError] = useState(false)
  const [helperText, setHelperText] = useState('')
  /** @type {Function} Routingの情報を取得する */
  const getRoutingParam = state.Popup.getRoutingParam

  return useMemo(() => {
    /** @type {String} RegNumber */
    const regNumber = getRoutingParam(index, 'regNumber')

    /**
     * RegNumber更新
     * @param {String} value 入力値
     * @returns {void} なし
     */
    const onChange = (value) => {
      const { checkResult, errMsg } = CommonValidation.regNumber(value)
      setError(checkResult)
      setHelperText(t(errMsg))
      dispatch({
        type: LIGHT_FLIGHT_MGMT.EDIT.ROUTING,
        index,
        regNumber: checkResult ? '' : value,
      })
    }

    return (
      <Content
        title={t('D301V0132')}
        content={
          <TextField
            defaultValue={regNumber}
            onChange={(event) => onChange(event.target.value)}
            error={error}
            helperText={helperText}
            disabled={!state.Auth}
          />
        }
      />
    )
  }, [dispatch, helperText, error, index, t, getRoutingParam, state.Auth])
}
RegNumber.propTypes = {
  index: PropTypes.number.isRequired,
}
export default RegNumber
