import React, { useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

import AppContext from '../../../../../contexts/AppContext'
import Name from '../../../../common/myProfile/Name'
import Telephone from '../../../../common/myProfile/Telephone'
import Position from '../../../../common/myProfile/Position'
import Department from '../../../../common/myProfile/Department'
import Email from '../../../../common/myProfile/Email'
import Password from '../../../../common/myProfile/Password'
import MailSetting from '../../../../common/myProfile/MailSetting'
import Theme from '../../../../common/theme/Theme'

const useStyles = makeStyles(() => ({
  hr: Theme.hr,
}))

const TableLightCarMyProfile = () => {
  const classes = useStyles()
  const { state, dispatch } = useContext(AppContext)
  const gsaFlg = state.myprofile.gsaFlg.value

  const departmentData = () => {
    return (
      <Grid item md={10} xs={10}>
        <Department
          dispatch={dispatch}
          department={state.myprofile.department}
        />
      </Grid>
    )
  }

  return (
    <Grid container justify="center">
      <Grid container item justify="center" spacing={4} md={12} xs={12}>
        <Grid item md={10} xs={10}>
          <Name
            dispatch={dispatch}
            name={state.myprofile.name}
            lastName={state.myprofile.lastName}
            carrier
          />
        </Grid>
        <Grid item md={10} xs={10}>
          <Telephone dispatch={dispatch} tel={state.myprofile.tel} carrier />
        </Grid>
        <Grid item md={10} xs={10}>
          <Position
            dispatch={dispatch}
            position={state.myprofile.position}
            carrier
          />
        </Grid>
        {!gsaFlg ? departmentData() : false}
        <Grid item md={10} xs={10}>
          <Email dispatch={dispatch} email={state.myprofile.email} carrier />
        </Grid>
        <Grid item md={10} xs={10}>
          <hr className={classes.hr} />
        </Grid>
        <Grid item md={10} xs={10}>
          <Password
            dispatch={dispatch}
            password={state.myprofile.password}
            passwordErr={state.myprofile.passwordErr}
            currentPassword={state.myprofile.currentPassword}
            newPassword={state.myprofile.newPassword}
            newPassVerify={state.myprofile.newPassVerify}
            carrier
          />
        </Grid>
        <Grid item md={10} xs={10}>
          <hr className={classes.hr} />
        </Grid>
        <Grid item md={10} xs={10}>
          <MailSetting
            dispatch={dispatch}
            mailSetting={state.myprofile.mailSetting}
            carrier
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default TableLightCarMyProfile
