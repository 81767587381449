import { UPDATE_JH_BACKUP } from '../../../actions'

const Events = (state = {}, action) => {
  switch (action.type) {
    //------------------
    // backupを更新
    //------------------
    case UPDATE_JH_BACKUP:
      state.name = action.info.name
      state.lastName = action.info.lastName
      state.email = action.info.email
      state.tel = action.info.tel
      state.position = action.info.position
      state.signPicture = action.info.signPicture
      state.mailSetting = action.info.mailSetting
      return { ...state }
    default:
      return state
  }
}

export default Events
