import React from 'react'
import PropTypes from 'prop-types'

import Accordion from '../../../../common/accordion/Accordion'
import FlightInfoAccordionSummary from './FlightInfoAccordionSummary'
import FlightInfoAccordionDetails from './FlightInfoAccordionDetails'

/**
 * @param {*} props 下記内容のProps
 * @param {Number} props.index 1行分の航空便データ
 * @returns {JSX} FlightInfoのアコーディオン1行分
 */
const FlightInfoAccordion = (props) => {
  const { index } = props
  return (
    <Accordion>
      <FlightInfoAccordionSummary index={index} />
      <FlightInfoAccordionDetails index={index} />
    </Accordion>
  )
}

FlightInfoAccordion.propTypes = {
  index: PropTypes.number.isRequired,
}

export default FlightInfoAccordion
