import { combineReducers } from 'redux'

import ShipperAddressBidInfo, {
  initShipperAddressBidInfo,
} from './ShipperAddressBidInfo'
import PicBidInfo, { initPicBidInfo } from './PicBidInfo'
// import ConsigneeBidInfo, { initConsigneeBidInfo } from './ConsigneeBidInfo'
import Consignee, { initConsignee } from '../../common/Consignee'
import DatepickerStartFinish, {
  initDatepickerStartFinish,
} from '../../common/DatepickerStartFinish'
import SetFwdrBidInfo, { initSetFwdrBidInfo } from './SetFwdrBidInfo'
import Datepicker, { initDatepicker } from '../../common/Datepicker'
import SpecialNotes, { initSpecialNotes } from '../../common/SpecialNotes'
import PageDataBidInfo, { initPageDataBidInfo } from './PageDataBidInfo'

const initialState = {
  ShipperAddress: initShipperAddressBidInfo,
  Pic: initPicBidInfo,
  Consignee: initConsignee,
  Validity: initDatepickerStartFinish,
  SetFwdr: initSetFwdrBidInfo,
  Deadline: initDatepicker,
  SpecialNotes: initSpecialNotes,
  PageData: initPageDataBidInfo,
}

export default combineReducers({
  ShipperAddress: ShipperAddressBidInfo,
  Pic: PicBidInfo,
  Consignee,
  Validity: DatepickerStartFinish,
  SetFwdr: SetFwdrBidInfo,
  Deadline: Datepicker,
  SpecialNotes,
  PageData: PageDataBidInfo,
})

export { initialState }
