import { AIRPORT } from '../../actions'

/**
 * @typedef {Object} Airport 空港情報
 * @property {String} airport 空港名
 * @property {String} airportCode 空港コード
 * @property {Number} airportId 空港ID
 * @property {String} country 国名
 * @property {Number} internationalId 国際ID
 */

// exportするために先に定義
let getRoutingAirportCode = () => {}

/**
 * @type {Object} 初期値
 * @property {Airport[]} airports 航空会社名一覧
 * @property {Function} getAirportInfo 空港情報取得関数
 * @property {Function} getRoutingAirportCode ORG/Via1/DSTの形式で空港の3桁コードを取得する
 */
const initAirport = {
  airports: [],
  getAirportInfo: () => {},
  getRoutingAirportCode,
}

/**
 * 空港情報を保持する
 * @param {Object} action action
 * @param {Airport[]} action.data 航空会社名情報の配列
 * @returns {Object} state
 */
const setAirport = (action) => {
  const { data } = action

  /**
   * airportIdから空港を取得
   * @param {Number} airportId 空港ID
   * @returns {Airport} 空港情報
   */
  const getAirportInfo = (airportId) =>
    data.find((v) => v.airportId === airportId) ?? null

  /**
   * ORG/Via1/DSTの形式で空港の3桁コードを取得
   * @param {Array} airportIdArr 空港IDの配列
   * @returns {String} 例：KIX/SFO/KUL
   */
  getRoutingAirportCode = (airportIdArr) => {
    const airportCodeArr = airportIdArr.map(
      (airportId) => getAirportInfo(airportId)?.airportCode
    )
    return airportCodeArr.join('/')
  }

  return {
    airports: data.sort((a, b) => (a.airportCode > b.airportCode ? 1 : -1)),
    getAirportInfo,
    getRoutingAirportCode,
  }
}

/**
 * state: Airportリストを保存
 * @param {Array} [state=initAirport] - state
 * @param {Object} action - action
 * @returns {Object} - state
 */
const Airport = (state = initAirport, action) => {
  switch (action.type) {
    case AIRPORT.SET:
      return setAirport(action)
    default:
      return state
  }
}

export default Airport

export { initAirport, getRoutingAirportCode }
