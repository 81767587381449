import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import AppContext from '../../../../../../contexts/AppContext'
import { LIGHT_FLIGHT_MGMT } from '../../../../../../actions'
import CommonDialog from '../../../../../common/dialog/commonDialog/CommonDialog'
import SavedChangesDialog from '../../../../../common/dialog/commonDesignDialog/SavedChangesDialog'
import Common from '../../../../../../constants/Common'
import DeleteButtonIconPopup from './DeleteButtonIconPopup'
import IconBoxIconPopup from './IconBoxIconPopup'
import ApiPaths from '../../../../../../constants/ApiPaths'
import CommonFunc from '../../../../../common/function/CommonFunc'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  titleText: theme.textBoldBLU,
}))

/**
 * @returns {JSX} アイコンポップアップ
 */
const IconPopup = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const { dispatch, state } = useContext(AppContext)
  const [open, setOpen] = useState(false)
  const [savedChangesOpen, setSavedChangesOpen] = useState(false)

  // ポップアップを開く関数をreducerに登録
  useEffect(() => {
    dispatch({
      type: LIGHT_FLIGHT_MGMT.ICON_POPUP.SET_OPEN,
      setOpen,
    })
  }, [dispatch])

  /**
   * アイコンを登録
   * @returns {void} なし
   */
  const registIcon = async () => {
    // ポップアップを閉じる
    state.IconPopup.setOpen(false)

    // ファイル変更時のみDBに登録
    if (state.IconPopup.editData.file) {
      const { contractId, updatedAt } = state.IconPopup.editData
      /** @type {Object} リクエスト */
      const req = {
        contractId,
        updatedAt,
        files: { iconFile: state.IconPopup.editData.file },
      }
      const apiInfoArr = [
        {
          key: 'iconObj',
          method: 'post',
          path: ApiPaths.MASTER_CAR.LIGHT_FLIGHT_ICON,
          req,
        },
      ]
      const resObj = await CommonFunc.execApiAsync(apiInfoArr)
      const isApiSuccessful = CommonFunc.checkApiResponseObj(resObj, history)
      if (isApiSuccessful) {
        const { iconObj } = resObj
        setSavedChangesOpen(true)
        dispatch({
          type: LIGHT_FLIGHT_MGMT.ICON_TABLE.EDIT_ICON_TABLES,
          search: state.SearchSort.search,
          data: iconObj.data.results,
        })
      }
    }
  }

  // ダイアログのボタン設定
  const dialogFuncObj = {
    [Common.DIALOG.BUTTON_CATEGORY.CANCEL]: {
      onClick: () => state.IconPopup.setOpen(false),
    },
    [Common.DIALOG.BUTTON_CATEGORY.SAVE]: {
      onClick: registIcon,
      disabled: state.IconPopup.noIcon,
    },
  }

  // 一般ユーザはOKボタン非表示
  if (!state.Auth) {
    delete dialogFuncObj[Common.DIALOG.BUTTON_CATEGORY.SAVE]
  }

  /** @type {Object} SavedChangesダイアログのボタン設定 */
  const savedDialogFuncObj = {
    [Common.DIALOG.BUTTON_CATEGORY.OK]: {
      onClick: () => setSavedChangesOpen(false),
    },
  }

  return (
    <>
      <SavedChangesDialog
        open={savedChangesOpen}
        closeFunc={() => setSavedChangesOpen(false)}
        dialogFuncObj={savedDialogFuncObj}
      />
      <CommonDialog
        open={open}
        closeFunc={() => state.IconPopup.setOpen(false)}
        dialogFuncObj={dialogFuncObj}
      >
        <Grid
          container
          direction="column"
          alignContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item className={classes.titleText}>
            {t('D301V0019')}
          </Grid>
          <Grid item>
            <IconBoxIconPopup />
            <DeleteButtonIconPopup />
          </Grid>
        </Grid>
      </CommonDialog>
    </>
  )
}
export default IconPopup
