import { makeStyles } from '@material-ui/core'
import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import Paths from '../../../../../constants/Paths'
import OkButtton from '../../../../common/buttons/OkButton'
import AppContext from '../../../../../contexts/AppContext'
import Common from '../../../../../constants/Common'

const useStyles = makeStyles((theme) => ({
  ok: theme.backButtonCenter,
}))

/**
 * 混載業者データを格納
 * @param {Number} bidLaneId 入札レーンID
 * @param {Object} fwdrdatas 混載業者データ
 * @param {Boolean} delFlg 削除フラグ
 * @return {Object} 混載業者データ
 */
const getResData = (bidLaneId, fwdrdatas, delFlg) => {
  return {
    bidLaneId,
    fwdrNameId: fwdrdatas.fwdrNameId,
    fwdrthreeCode: fwdrdatas.fwdrthreeCode,
    companyName: fwdrdatas.companyName,
    delFlg,
  }
}

/**
 * @param {Object} props - 以下
 * @param {Array} fwdrArr - 混載業者情報
 * @return {JSX} OKボタンコンポーネント
 */
const OkButtonSelectForwarder = (props) => {
  const classes = useStyles()
  const { state } = useContext(AppContext)
  // 次画面に渡すデータの整形
  const selectFwdrArr =
    props.fwdrArr.history.location.state?.state.selectFwdrObj.selectFwdrArr ??
    []
  const apiDataArr = state.Tables.apiDataArr
  const beforeResData = props.fwdrArr.history.location.state?.state
  const fwdrDatas = []
  const changeFwdrDataArr = []

  // fwdrNameIdを配列で取得
  const fwdrNameIds = selectFwdrArr.map((selectFwdr) => {
    return selectFwdr.fwdrNameId
  })

  // indexを取得
  const allFwdrDataArr = apiDataArr.map((row, index) => {
    return {
      fwdrNameId: row.fwdrNameId,
      fwdrthreeCode: row.fwdrthreeCode,
      companyName: row.companyName,
      index,
    }
  })

  // 編集データor追加データ
  for (const allFwdrData of allFwdrDataArr) {
    for (const check of state.CheckBox) {
      if (allFwdrData.index === Number(check)) {
        if (fwdrNameIds.indexOf(allFwdrData.fwdrNameId) !== -1) {
          changeFwdrDataArr.push(allFwdrData.fwdrNameId)
        } else {
          // 追加
          fwdrDatas.push(getResData(null, allFwdrData, Common.DEL_FLG.OFF))
        }
      }
    }
  }

  // 編集データ
  for (const selectFwdr of selectFwdrArr) {
    if (changeFwdrDataArr.indexOf(selectFwdr.fwdrNameId) !== -1) {
      fwdrDatas.push(
        getResData(selectFwdr.bidLaneId, selectFwdr, Common.DEL_FLG.OFF)
      )
    } else if (selectFwdr.bidLaneId) {
      fwdrDatas.push(
        getResData(selectFwdr.bidLaneId, selectFwdr, Common.DEL_FLG.ON)
      )
    }
  }

  // fwdrDatasをCompanyNameの昇順で並び替え
  fwdrDatas.sort((a, b) => {
    if (a.companyName < b.companyName) return -1
    if (a.companyName > b.companyName) return 1
    return 0
  })

  // フロントに渡すデータを格納
  const resData = {
    ...beforeResData,
    selectFwdrObj: {
      selectFwdrArr: fwdrDatas,
      setFwdrFlg:
        props.fwdrArr.history.location.state?.state.selectFwdrObj.setFwdrFlg,
    },
  }
  return (
    <div className={classes.ok}>
      <OkButtton
        path={Paths.SHIPPER.BID_INFO}
        checked={state.CheckBox.length !== 0}
        state={resData}
      />
    </div>
  )
}

OkButtonSelectForwarder.propTypes = {
  fwdrArr: PropTypes.object.isRequired,
}

export default OkButtonSelectForwarder
