import { makeStyles } from '@material-ui/core'
import React from 'react'

import Paths from '../../../../../constants/Paths'
import BackButton from '../../../../common/buttons/BackButton'

const useStyles = makeStyles((theme) => ({
  back: theme.backButtonCenter,
}))

/**
 * @return {JSX} バックボタンコンポーネント
 */
const BackButtonBidMgmt = () => {
  const classes = useStyles()

  return (
    <div className={classes.back}>
      <BackButton path={Paths.FWDR.TOP} />
    </div>
  )
}

export default BackButtonBidMgmt
