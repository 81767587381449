import { TABLES } from '../../actions'
import CommonFunc from '../../components/common/function/CommonFunc'
import ConstantsClientReg from '../../components/pages/master/JhClientReg/common/ConstantsClientReg'
import Common from '../../constants/Common'

// ClientRegで使用するタブ数
const tabAmount = Object.keys(Common.CLIENT_TAB).length

/** 初期表示ページ */
const initTablesLightJhClientReg = {
  // 各業者のリクエストを保持
  showDataArr: Array(tabAmount).fill([[]]),
  // 表示用のデータ 現在のタブのデータを表示
  showData: [[]],
  // 各業者のリクエスト数
  pageAmountArr: Array(tabAmount).fill(0),
}

/**
 * 各業者のリクエスト情報の初期値を作成
 * @param {Object} action dispatchで受け取ったaction
 * @param {Object} state TablesLightJhClientRegのstate
 * @return {Object} 各業者のリクエスト情報の初期値を保持したstate
 */
const createInitTableDataObj = (action, state) => {
  const { data, tabs } = action
  const { shipper, fwdr, carrier } = data

  // 現在ページに合わせてカード数を調整
  const cardAmount =
    ConstantsClientReg.CARD_SETTING.AMOUNT_SETTING[window.location.pathname]

  // APIの値を表示用に変換
  const { showData: shipperShowData } = CommonFunc.searchSortData(
    shipper,
    '',
    '',
    '',
    cardAmount
  )
  const { showData: fwdrShowData } = CommonFunc.searchSortData(
    fwdr,
    '',
    '',
    '',
    cardAmount
  )
  const { showData: carrierShowData } = CommonFunc.searchSortData(
    carrier,
    '',
    '',
    '',
    cardAmount
  )
  const showDataArr = [shipperShowData, fwdrShowData, carrierShowData]
  const showData = showDataArr[tabs]

  const pageAmountArr = [shipper.length, fwdr.length, carrier.length]

  return {
    ...state,
    showDataArr,
    showData,
    pageAmountArr,
  }
}

/**
 * 選択したタブのデータに変更
 * @param {Object} action dispatchで受け取ったaction
 * @param {Object} state TablesLightJhClientRegのstate
 * @return {Object} 各業者のリクエスト情報を保持したstate
 */
const setTableDataObj = (action, state) => {
  const { select } = action
  const { showDataArr } = state

  const showData = showDataArr[select]

  return {
    ...state,
    showData,
  }
}

/**
 * 各業者のリクエスト情報を保持
 * @param {Object} [state=initTablesLightJhClientReg] 各業者のリクエスト情報
 * @param {Object} action 各業者のリクエスト情報に必要な値 現在タブなど
 * @return {Object} 各業者のリクエスト情報
 */
const TablesLightJhClientReg = (state = initTablesLightJhClientReg, action) => {
  switch (action.type) {
    case TABLES.INIT:
      return createInitTableDataObj(action, state)
    case TABLES.SET:
      return setTableDataObj(action, state)
    default:
      return state
  }
}

export default TablesLightJhClientReg

export { initTablesLightJhClientReg }
