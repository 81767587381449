import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { LIGHT_FLIGHT_MGMT } from '../../../../../../actions'
import AppContext from '../../../../../../contexts/AppContext'
import InputSelect from '../../../../../common/select/InputSelect'
import Content from '../../03AccordionCarFlightMgmt/Content'

/**
 * @param {*} props 下記内容のProps
 * @param {Number} props.index Routingのインデックス
 * @returns {JSX} TransitTimeのセレクト欄
 */
const TransitTimeSelect = (props) => {
  const { t } = useTranslation()
  const { state, dispatch } = useContext(AppContext)
  const { index } = props
  /** @type {Number} 選択中のTimeId */
  const timeId = state.Popup.getRoutingParam(index, 'timeId')

  return useMemo(() => {
    /** @type {Object} 選択中のTransitTime情報 */
    const selectedTransitTime = state.TransitTime.getTransitTime(timeId)

    /**
     * TransitTime更新
     * @param {Object} value 選択したTransitTime
     * @returns {void} なし
     */
    const onChange = (value) => {
      dispatch({
        type: LIGHT_FLIGHT_MGMT.EDIT.ROUTING,
        index,
        timeId: value?.timeId ?? null,
      })
    }

    return (
      <Content
        title={t('D301V0073')}
        content={
          <InputSelect
            options={state.TransitTime.transitTime}
            getOptionLabel={(option) => option.time}
            value={selectedTransitTime}
            onChange={(_, value) => onChange(value)}
            disabled={!state.Auth}
          />
        }
      />
    )
  }, [timeId, state.TransitTime, state.Auth, dispatch, index, t])
}
TransitTimeSelect.propTypes = {
  index: PropTypes.number.isRequired,
}
export default TransitTimeSelect
