/* eslint-disable no-extra-parens */
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import AppContext from '../../../../contexts/AppContext'
import InputSelect from '../../../common/select/InputSelect'
import {
  UPDATE_SHIP_AIRPORT,
  UPDATE_SHIP_AIRPORT_INPUTERR,
} from '../../../../actions'

const useStyles = makeStyles((theme) => ({
  head: theme.textBoldBLU,
  // eslint-disable-next-line camelcase
  text_blk: theme.textNormalBLK,
}))

const Airport = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { state, dispatch } = useContext(AppContext)

  const handleChangeSelect = (_event, newValue) => {
    const airport = newValue ? newValue.name : ''
    const airportId = newValue ? newValue.code : null
    dispatch({
      type: UPDATE_SHIP_AIRPORT,
      airport,
      airportId,
    })

    // eslint-disable-next-line no-unneeded-ternary
    const err = newValue === null ? true : false

    // 入力エラーの場合、Savaボタン無効
    dispatch({
      type: UPDATE_SHIP_AIRPORT_INPUTERR,
      inputErr: err,
    })
  }

  const select = state.companyinfo.airport.value

  return (
    <div className={classes.formControl}>
      <Grid container>
        <Grid item md={10} xs={10}>
          <div className={classes.head}>{t('D301V0196')}</div>
        </Grid>
        <Grid item md={4} xs={10}>
          <InputSelect
            id={'airport'}
            options={props.airport}
            value={select.name ? select : null}
            getOptionLabel={(option) => option.name}
            onChange={handleChangeSelect}
            getOptionSelected={(option, value) => option.name === value.name}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default Airport
