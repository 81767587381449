import { LIGHT_BOOKING_DETAILS } from '../../../actions'

/** @type {Object} Flightポップアップの初期値 */
const initFlightPopup = {
  setOpen: () => {},
  interLineObj: {
    interLine: null,
    inputError: false,
    placeholder: '',
  },
}

/**
 * Flightポップアップを開く関数を登録
 * @param {Object} action action
 * @param {Function} action.setOpen ポップアップを開く関数
 * @param {Object} state state
 * @returns {Object} state
 */
const setOpenFunc = (action, state) => {
  const { setOpen } = action
  return { ...state, setOpen }
}

/**
 * Flightポップアップを開く
 * @param {Object} action action
 * @param {Object} state state
 * @returns {Object} state
 */
const open = (action, state) => {
  state.setOpen(true)
  const interLineObj = {
    ...action,
    inputError: action.interLine === null,
  }
  return { ...state, interLineObj }
}

/**
 * Flightポップアップを開く
 * @param {Object} action action
 * @param {String|null} action.interLine 入力されたインターライン
 * @param {Object} state state
 * @returns {Object} state
 */
const edit = (action, state) => {
  const { interLine } = action
  state.interLineObj.interLine = interLine
  state.interLineObj.inputError = interLine === null
  return { ...state }
}

/**
 * Flightポップアップの編集データを保持
 * @param { Object } [state = initFlightPopup] state
 * @param { Object } action action
 * @return { Object } state
 */
const FlightPopup = (state = initFlightPopup, action) => {
  const { type, ...rest } = action
  switch (type) {
    case LIGHT_BOOKING_DETAILS.FLIGHT_POPUP.SET_OPEN_FUNC:
      return setOpenFunc(rest, state)
    case LIGHT_BOOKING_DETAILS.FLIGHT_POPUP.OPEN:
      return open(rest, state)
    case LIGHT_BOOKING_DETAILS.FLIGHT_POPUP.EDIT:
      return edit(rest, state)
    default:
      return state
  }
}

export default FlightPopup

export { initFlightPopup }
