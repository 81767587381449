// Display + 画面名
const DisplayShipperSearchResults = {
  // 画面ID + 文言ID
  D009V0001: {
    en: 'SEARCH RESULTS',
    ja: '検索結果',
  },
  D009V0002: {
    en: 'ORG:',
    ja: '出発:',
  },
  D009V0003: {
    en: 'DST:',
    ja: '到着:',
  },
  D009V0004: {
    en: 'Terms:',
    ja: '条件:',
  },
  D009V0005: {
    en: 'C/W:',
    ja: 'C/W:',
  },
  D009V0006: {
    en: 'GENERAL CARGO',
    ja: '一般貨物',
  },
  D009V0007: {
    en: 'COL',
    ja: 'COL',
  },
  D009V0008: {
    en: 'ICE',
    ja: 'ICE',
  },
  D009V0009: {
    en: 'Enter key words',
    ja: 'キーワードを入力してください',
  },
  D009V0010: {
    en: 'Sort by:',
    ja: '並び替え',
  },
  D009V0011: {
    en: 'Best Flights',
    ja: 'ベストフライト',
  },
  D009V0012: {
    en: '17:30 - 19:58 +1',
    ja: '17:30 - 19:58 +1',
  },
  D009V0013: {
    en: 'Days',
    ja: '日',
  },
  D009V0014: {
    en: 'AC6 / AC7597',
    ja: 'AC6 / AC7597',
  },
  D009V0015: {
    en: 'NRT/YUL/ORD',
    ja: 'NRT/YUL/ORD',
  },
  D009V0016: {
    en: 'JPY',
    ja: 'JPY',
  },
  D009V0017: {
    en: 'FWDR:',
    ja: '混載業者:',
  },
  D009V0018: {
    en: '¥ X,XXX,XXX',
    ja: '¥ X,XXX,XXX',
  },
  D009V0019: {
    en: 'Space Available',
    ja: '利用可能なスペース',
  },
  D009V0020: {
    en: 'Quote',
    ja: '見積書',
  },
  D009V0021: {
    en: 'Select Flight',
    ja: 'フライトを選択',
  },
  D009V0022: {
    en: '16:00 - 17:07 +1',
    ja: '16:00 - 17:07 +1',
  },
  D009V0023: {
    en: 'UA838 / UA2070',
    ja: 'UA838 / UA2070',
  },
  D009V0024: {
    en: 'NRT/SFO/ORD',
    ja: 'NRT/SFO/ORD',
  },
  D009V0025: {
    en: 'FWDR:  EIK',
    ja: '混載業者:  EIK',
  },
  D009V0026: {
    en: '18:30 - 21:05 +1',
    ja: '18:30 - 21:05 +1',
  },
  D009V0027: {
    en: 'AA60 / AA2881',
    ja: 'AA60 / AA2881',
  },
  D009V0028: {
    en: 'NRT/DFW/ORD',
    ja: 'NRT/DFW/ORD',
  },
  D009V0029: {
    en: 'FWDR: DSV',
    ja: '混載業者: DSV',
  },
  D009V0030: {
    en: '14:25 - 14:05 +1',
    ja: '14:25 - 14:05 +1',
  },
  D009V0031: {
    en: 'N + 3 - 4 Days',
    ja: 'N + 3 - 4 日',
  },
  D009V0032: {
    en: 'AM57 / AM686',
    ja: 'AM57 / AM686',
  },
  D009V0033: {
    en: 'NRT/MEX/ORD',
    ja: 'NRT/MEX/ORD',
  },
  D009V0034: {
    en: 'FWDR: CEV',
    ja: '混載業者: CEV',
  },
  D009V0035: {
    en: '20:40 - 20:45 +1',
    ja: '20:40 - 20:45 +1',
  },
  D009V0036: {
    en: 'BR195 / BR56',
    ja: 'BR195 / BR56',
  },
  D009V0037: {
    en: 'NRT/TPE/ORD',
    ja: 'NRT/TPE/ORD',
  },
  D009V0038: {
    en: 'FWDR: NEC',
    ja: '混載業者: NEC',
  },
  D009V0039: {
    en: '22:30 - 13:45 +1',
    ja: '22:30 - 13:45 +1',
  },
  D009V0040: {
    en: 'QR807 / QR725',
    ja: 'QR807 / QR725',
  },
  D009V0041: {
    en: 'NRT/DOH/ORD',
    ja: 'NRT/DOH/ORD',
  },
  D009V0042: {
    en: 'FWDR: PNA',
    ja: '混載業者: PNA',
  },
  D009V0043: {
    en: '23:00 - 22:50 +1',
    ja: '23:00 - 22:50 +1',
  },
  D009V0044: {
    en: 'N + 5 - 6 Days',
    ja: 'N + 5 - 6 日',
  },
  D009V0045: {
    en: 'KZ134',
    ja: 'KZ134',
  },
  D009V0046: {
    en: 'NRT/ANC/ORD',
    ja: 'NRT/ANC/ORD',
  },
  D009V0047: {
    en: 'FWDR: YLK',
    ja: '混載業者: YLK',
  },
  D009V0048: {
    en: 'Space Unavailable',
    ja: '利用できないスペース',
  },
  D009V0049: {
    en: '*Due to W/H congestion',
    ja: '*W/H混雑のため',
  },
  D009V0050: {
    en: 'Back',
    ja: '戻る',
  },
  D009V0051: {
    en: '*Forecast',
    ja: '*未確定',
  },
  D009V0052: {
    en: 'Total Air Freight',
    ja: '航空貨物の合計',
  },
  D009V0053: {
    en: 'kg',
    ja: 'kg',
  },
  D009V0054: {
    en: 'LowPrice',
    ja: '安い順',
  },
  D009V0055: {
    en: 'HighPrice',
    ja: '高い順',
  },
  D009V0056: {
    en: 'TransitTime',
    ja: '輸送期間が短い順',
  },
  D009V0057: {
    en: 'Flight Number',
    ja: 'フライト番号',
  },
  D009V0058: {
    en: 'Aircraft Reg.',
    ja: '機体記号',
  },
  D009V0059: {
    en: 'The displayed charges are the Air Freight for GENERAL CARGO.',
    ja: '表示料金は、一般貨物の航空運賃です。',
  },
  D009V0060: {
    en: 'Not Available',
    ja: '利用不可',
  },
}
// Display + 画面名
export default DisplayShipperSearchResults
