import React from 'react'

import MeasureAndWeightLightBookingDetails from '../../../../commonUseContext/LightBookingDetails/MeasureAndWeight/MeasureAndWeightLightBookingDetails'

/**
 * @return {JSX} Measure&Weightアコーディオン
 */
const MeasureAndWeightAirlineLightBookingDetails = () => {
  return <MeasureAndWeightLightBookingDetails editable={false} />
}

export default MeasureAndWeightAirlineLightBookingDetails
